import {defineMessages} from 'react-intl';

export const downloadErrorStrings = defineMessages({
  otherError: {
    defaultMessage: `Something went wrong. Try again later.`,
    description: 'Error message when an unknown error occurs',
    id: 'P33QYe',
  },
  tooManyFilesError: {
    defaultMessage: `Can’t download more than 10,000 files at once.`,
    description: 'Error message when the user is trying to download too many files',
    id: 'IeA1lM',
  },
  tooMuchDataError: {
    defaultMessage: `Content of zipped file must be less than 250 GB to download.`,
    description: 'Error message when the user is trying to download too much data',
    id: '9OGKB6',
  },
  noFilesFoundError: {
    defaultMessage: `No files meet the selected criteria.`,
    description:
      "Error message when the user's selected folder and download options result in zero files to download",
    id: '6dllit',
  },
});
