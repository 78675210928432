import type {FormEvent} from 'react';
import React from 'react';

import {useSetAtom} from 'jotai';
import chunk from 'lodash/chunk';
import {PAP_Upload_Batch} from 'pap-events/manual_upload/upload_batch';
import type {Upload_File} from 'pap-events/manual_upload/upload_file';
import {PAP_Upload_File} from 'pap-events/manual_upload/upload_file';

import {useUploadDrawerUploadsAndProgress} from '~/components/upload_drawer/use_upload_drawer_uploads_and_progress';
import type {AddToProjectResponse} from '~/lib/api';
import {
  addToProject,
  createEmptyProjects,
  createFoldersFromPaths,
  deleteProject,
  EmptyProjectCreationStatus,
  sendNewFilesAddedNotifications,
  UserOverQuotaError,
} from '~/lib/api';
import {getCurrentUser} from '~/lib/client';
import {useFeatureIsOn} from '~/lib/growthbook';
import {
  AUDIO_EXTENSIONS,
  getExtension,
  getMediaType,
  IMAGE_EXTENSIONS,
  useValidExtensions,
} from '~/lib/helpers';
import parallelFileUpload from '~/lib/uploads/parallel_file_uploader';
import type {
  DirectUpload,
  FileExt,
  FileUpload,
  MappedDirectUploads,
  StreamableFile,
  UploadInfo,
  UploadsProgress,
} from '~/lib/uploads/types';
import {
  FileUploadState,
  MAX_CREATE_EMPTY_PROJECT_BATCH,
  UPLOAD_FINAL_FILE_STATES,
  UPLOAD_FINAL_STATES,
  UploadErrorType,
  UploadState,
  UploadTypes,
} from '~/lib/uploads/types';
import {generateRandomId} from '~/lib/utils';
import withRetries from '~/lib/with_retries';
import {refetchableUserSpaceAtom} from '~/state/user';

import {reportBadContextUseError} from '../lib/error_reporting';
import type {
  AddMediaClickSourceType,
  AddNewVersionClickSourceType,
  MediaSourceType,
} from '../lib/logging/logger_types';
import {useLoggingClient} from '../lib/use_logging_client';

type FilesPickHandler = (files: FileList) => void;
type UploadStartHandler = (uploads: DirectUpload[]) => void;
type ProgressHandler = (uploadId: string, percentage: number) => void;
type FileCompleteHandler = (upload: DirectUpload, videoId: string, videoVersionId: string) => void;
type AllCompleteHandler = (uploads: DirectUpload[]) => void;
type FileErrorHandler = (upload: DirectUpload) => void;
type ErrorHandler = (uploadId: string) => void;
type CreateVersionHandler = (fileId: string) => Promise<{
  projectId?: string;
  videoId?: string;
  videoVersionId?: string;
  videoIdForAmplitude?: string;
  versionIdForAmplitude?: string;
  fileSize?: number;
}>;

type UploadRef = {
  filesPickHandler?: FilesPickHandler;
  progressHandler?: ProgressHandler;
  fileCompleteHandler?: FileCompleteHandler;
  allCompleteHandler?: AllCompleteHandler;
  fileErrorHandler?: FileErrorHandler;
  errorHandler?: ErrorHandler;
  onCreateVersionHandler?: CreateVersionHandler;
  onAddFileHandler?: AddFileHandler;
};

type ChooseFilesProps =
  | {
      onFilesPick: FilesPickHandler;
      uploadType: 'logo';
    }
  | {
      onFilesPick: FilesPickHandler;
      uploadType: 'captions';
    }
  | {
      multiple: boolean;
      onFilesPick: FilesPickHandler;
      uploadType: 'version' | 'project';
      isFolder?: boolean;
    };

type UploadProjectProps = {
  files: FileExt[];
  currentFolderId: string;
  onUploadStart?: UploadStartHandler;
  onProgress?: ProgressHandler;
  onFileUploadComplete?: FileCompleteHandler;
  onAllUploadsComplete?: (
    uploads: (File | StreamableFile)[],
    folderId: string,
    isFolder: boolean,
  ) => void;
  onFileError?: FileErrorHandler;
  onError?: ErrorHandler;
  folderUpload: boolean;
  isDragAndDrop?: boolean;
  clickSource: AddMediaClickSourceType | AddMediaClickSourceType | AddNewVersionClickSourceType;
};

type UploadVersionProps = {
  onCreateVersion: CreateVersionHandler;
  file: FileExt;
  nsId: number;
  ownerUid?: string;
  onUploadStart?: UploadStartHandler;
  onProgress?: ProgressHandler;
  onFileUploadComplete?: FileCompleteHandler;
  onFileError?: FileErrorHandler;
  clickSource: AddMediaClickSourceType | AddMediaClickSourceType | AddNewVersionClickSourceType;
};

type AddFileHandler = (fileId: string) => Promise<void>;

type UploadFileProps = {
  file: File;
  nsId: number;
  onFileUpload?: AddFileHandler;
  onUploadStart?: UploadStartHandler;
  onProgress?: ProgressHandler;
  onFileUploadComplete?: FileCompleteHandler;
  onFileError?: FileErrorHandler;
};

export type UploadContext = {
  chooseFiles: (chooseFilesProps: ChooseFilesProps) => void;
  uploadCaptions: (uploadFileProps: UploadFileProps) => void;
  uploadLogo: (uploadFileProps: UploadFileProps) => void;
  uploadProject: (uploadProjectProps: UploadProjectProps) => void;
  uploadVersion: (uploadVersionProps: UploadVersionProps) => void;
  uploadState: UploadState;
  retryUpload: (uploadId: string, upload: DirectUpload) => void;
  uploadSnackbarOpen: boolean;
  setUploadSnackbarOpen: (open: boolean) => void;
};

const UploadContext = React.createContext<UploadContext | null>(null);

type UploadType = 'version' | 'project' | 'captions' | 'logo';

declare module 'react' {
  interface InputHTMLAttributes<T> extends HTMLAttributes<T> {
    directory?: string;
    webkitdirectory?: string;
    mozdirectory?: string;
  }
}

export const UploadProvider = (props: React.PropsWithChildren<{}>) => {
  const [uploadType, setUploadType] = React.useState<UploadType | null>(null);
  const [uploadState, setUploadState] = React.useState(UploadState.Init);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const loggingClient = useLoggingClient();
  const validExtensionsList = useValidExtensions();
  const refreshUserSpaceAction = useSetAtom(refetchableUserSpaceAtom);

  const uploadRef = React.useRef<UploadRef>();
  const folderRef = React.useRef<string>();

  const singleImageFileInput = React.useRef<HTMLInputElement>(null);
  const singleCaptionsFileInput = React.useRef<HTMLInputElement>(null);
  const singleMediaFileInput = React.useRef<HTMLInputElement>(null);
  const multipleMediaFileInput = React.useRef<HTMLInputElement>(null);
  const folderFileInput = React.useRef<HTMLInputElement>(null);
  const streamingUploaderIsOn = useFeatureIsOn('replay_2024_09_24_streaming_uploader');

  const {
    cancelUpload,
    getActionSurface,
    getLoggingData,
    getUploads,
    getUploadsProgress,
    handleUpdateCurrentUploadBatchIds,
    handleUpdateProgress,
    handleUpdateUploads,
    isUploadCanceled,
    updateLoggingData,
    updateMappedUploadAsError,
    updateUpload,
    updateUploadProgress,
    uploads,
    uploadsProgress,
  } = useUploadDrawerUploadsAndProgress<MappedDirectUploads>();

  const updateUploadLink = React.useCallback(
    (uploadId: string, projectId: string, videoId: string, versionId?: string) => {
      let link = `/project/${projectId}/video/${videoId}`;

      if (versionId) {
        link += `?video_version_id=${versionId}`;
      }

      updateUpload(uploadId, {
        link,
        projectId,
        versionId,
        videoId,
      });
    },
    [updateUpload],
  );

  const updateUploadError = React.useCallback(
    (uploadId: string, error: UploadErrorType) => {
      updateUpload(uploadId, {
        error,
      });

      loggingClient.logPap(
        PAP_Upload_File({
          ...getLoggingData()[uploadId],
          fileCategory: 'other',
          eventState: 'failed',
          failureType: `${error}`,
        }),
      );
    },
    [getLoggingData, loggingClient, updateUpload],
  );

  const clearUploadError = React.useCallback(
    (uploadId: string) => {
      updateUpload(uploadId, {
        error: undefined,
      });
    },
    [updateUpload],
  );

  const addUploadToProject = React.useCallback(
    async (uploadId: string, projectId: string, fileId: string) => {
      let response: AddToProjectResponse;
      try {
        response = await addToProject(projectId, fileId, false);
      } catch (err) {
        if (err instanceof UserOverQuotaError) throw err;
        // If the upload fails, try again excluding xmp metadata
        response = await withRetries(addToProject, 1)(projectId, fileId, true);
      }
      const videoId = response.videoId;
      const videoVersionId = response.videoVersionId;
      const videoIdForAmplitude = response.videoIdForAmplitude;
      const versionIdForAmplitude = response.versionIdForAmplitude;
      const fileSize = response.fileSize;

      updateUploadLink(uploadId, projectId, videoId);
      return {
        videoId,
        videoVersionId,
        versionIdForAmplitude,
        videoIdForAmplitude,
        fileSize,
      };
    },
    [updateUploadLink],
  );

  const addUploadToVersion = React.useCallback(
    async (uploadId: string, fileId: string, originalProjectId?: string) => {
      const {
        projectId,
        videoId,
        videoVersionId,
        versionIdForAmplitude,
        videoIdForAmplitude,
        fileSize,
      } = await uploadRef.current!.onCreateVersionHandler!(fileId);

      if (projectId && videoId) {
        updateUploadLink(uploadId, projectId, videoId, videoVersionId);
        // If the upload is successfully added to a version, we can remove the (now empty)
        // project that was created to allow users to upload versions to projects they didn't own.
        // Note: Adding extra safety net to ensure we don't delete the wrong (still valid) project,
        // if by any chance they end up being the same.
        if (originalProjectId && projectId !== originalProjectId) {
          deleteProject(originalProjectId);
        }
      }
      return {
        projectId,
        videoId,
        videoVersionId,
        versionIdForAmplitude,
        videoIdForAmplitude,
        fileSize,
      };
    },
    [updateUploadLink],
  );

  const handleFileInput = React.useCallback((event: FormEvent) => {
    const files = (event.target as HTMLInputElement).files;

    if (files) {
      uploadRef.current?.filesPickHandler?.(files);
    }
  }, []);

  const handleFileError = React.useCallback(
    (uploadId: string, errorType: UploadErrorType) => {
      updateUploadProgress(uploadId, {status: FileUploadState.Error, percentage: 100});
      updateUploadError(uploadId, errorType);
      uploadRef.current!.fileErrorHandler?.(getUploads()[uploadId]);
    },
    [getUploads, updateUploadError, updateUploadProgress],
  );

  const handleUploadComplete = React.useCallback(
    async (
      uploadId: string,
      fileId: string,
      isRetry?: boolean,
      currentLoggingInfo?: Upload_File['properties'],
    ) => {
      const upload = getUploads()[uploadId];
      let projectId = upload.projectId;
      let videoId = upload.videoId;
      let videoVersionId = '';
      let videoIdForAmplitude = '';
      let versionIdForAmplitude = '';
      let fileSize = 0;

      if (isUploadCanceled(uploadId)) {
        uploadRef.current!.fileCompleteHandler?.(
          {...upload, projectId, videoId},
          fileId,
          videoVersionId,
        );
        return;
      }

      const type = uploadType ?? upload.type;
      try {
        switch (type) {
          case 'version': {
            const result = await addUploadToVersion(uploadId, fileId, projectId);
            projectId = result.projectId;
            videoId = result.videoId;
            videoVersionId = result.videoVersionId || '';
            videoIdForAmplitude = result.videoIdForAmplitude || '';
            versionIdForAmplitude = result.versionIdForAmplitude || '';
            fileSize = result.fileSize || 0;
            break;
          }
          case 'project': {
            const result = await addUploadToProject(uploadId, projectId!, fileId);
            videoId = result.videoId;
            videoVersionId = result.videoVersionId;
            videoIdForAmplitude = result.videoIdForAmplitude || '';
            versionIdForAmplitude = result.versionIdForAmplitude || '';
            fileSize = result.fileSize || 0;
            break;
          }
          case 'logo':
          case 'captions': {
            await uploadRef?.current?.onAddFileHandler?.(fileId);
            break;
          }
          default: {
            // n.a.
          }
        }

        updateUploadProgress(uploadId, {status: FileUploadState.Complete, percentage: 100});

        uploadRef.current!.fileCompleteHandler?.(
          {...upload, projectId, videoId},
          fileId,
          videoVersionId,
        );

        loggingClient.logPap(
          PAP_Upload_File({
            ...getLoggingData()[uploadId],
            fileSize: fileSize,
            eventState: 'success',
            videoId: videoIdForAmplitude,
            videoVersionId: versionIdForAmplitude,
            startType: isRetry ? 'retry' : 'initial',
          }),
        );
        refreshUserSpaceAction();
      } catch (error) {
        if (type === 'project' && projectId) {
          deleteProject(projectId);
        }
        loggingClient.logPap(
          PAP_Upload_File({
            ...getLoggingData()[uploadId],
            fileCategory: 'other',
            eventState: 'failed',
            failureType: `${error}`,
          }),
        );
        if (error instanceof UserOverQuotaError) {
          updateMappedUploadAsError(uploadId, upload, UploadErrorType.USAGE_QUOTA_EXCEEDED_ERROR);
        }

        handleFileError(uploadId, UploadErrorType.UploadFailed);
      }
    },
    [
      getUploads,
      isUploadCanceled,
      uploadType,
      updateUploadProgress,
      loggingClient,
      getLoggingData,
      addUploadToVersion,
      addUploadToProject,
      handleFileError,
      updateMappedUploadAsError,
      refreshUserSpaceAction,
    ],
  );

  const startParallelUpload = React.useCallback(
    (fileUploads: FileUpload[], isRetry?: boolean) => {
      parallelFileUpload({
        kind: streamingUploaderIsOn ? 'streaming' : 'synchronous',
        isUploadCanceled: (uploadId: string) => isUploadCanceled(uploadId),
        uploads: fileUploads,
        onStart: (uploadId) => {
          if (!isUploadCanceled(uploadId)) {
            updateUploadProgress(uploadId, {
              status: FileUploadState.Uploading,
              percentage: 0,
              uploadedSize: 0,
            });
          }
        },
        onProgress: (uploadId, percentage, uploadedSize) => {
          if (!isUploadCanceled(uploadId)) {
            updateUploadProgress(uploadId, {
              status: FileUploadState.Uploading,
              percentage,
              uploadedSize,
            });
          }
        },
        onComplete: (uploadId: string, fileId: string) => {
          if (!isUploadCanceled(uploadId)) {
            handleUploadComplete(uploadId, fileId, isRetry);
          }
        },
        onError: handleFileError,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- PR 894
    [
      isUploadCanceled,
      getUploadsProgress,
      handleFileError,
      handleUploadComplete,
      updateUploadProgress,
    ],
  );

  // Once UploadState is Ready the actual uploads can start
  React.useEffect(() => {
    if (uploadState === UploadState.Ready) {
      setUploadState(UploadState.Uploading);
      setSnackbarOpen(true);
      const activeUploads = Object.entries(uploads).filter(([_, upload]) => {
        const uploadId = upload.uploadId;
        const uploadProgress = getUploadsProgress()[uploadId];
        return (
          !upload.canceled &&
          upload.mediaSourceType === 'direct_upload' &&
          uploadProgress.status === FileUploadState.Ready
        );
      });

      // Create uploads input for parallel file uploader with active uploads
      const fileUploads = activeUploads.map(([uploadId, upload]) => ({
        uploadId,
        nsId: upload.nsId!,
        file: upload.file,
      }));

      startParallelUpload(fileUploads);
    }
  }, [startParallelUpload, uploadState, getUploads, getUploadsProgress, uploads, uploadsProgress]);

  // During upload, watch for all uploads to be complete
  React.useEffect(() => {
    const sendUploadNotification = async () => {
      const completeUploads = Object.values(uploads).filter((upload) => {
        const uploadProgress = getUploadsProgress()[upload.uploadId];
        return (
          !upload.canceled &&
          upload.mediaSourceType === 'direct_upload' &&
          uploadProgress.status === FileUploadState.Complete
        );
      });

      const [firstNotificationInfo, secondNotificationInfo] = completeUploads
        .slice(0, 2)
        .map((upload) => ({
          title: upload.file.name,
          video_id: upload.videoId,
          is_audio: AUDIO_EXTENSIONS.includes(getExtension(upload.file.name)),
        }));

      if (completeUploads.length > 0) {
        await sendNewFilesAddedNotifications(
          folderRef.current ?? '',
          completeUploads.length,
          firstNotificationInfo,
          secondNotificationInfo,
        );
      }
    };

    (async () => {
      const progresses = Object.values(uploadsProgress);

      if (progresses.length > 0 && !UPLOAD_FINAL_STATES.includes(uploadState)) {
        const allFinal = progresses.every(
          ({status}) => status && UPLOAD_FINAL_FILE_STATES.includes(status),
        );
        const hadError = progresses.some(({status}) => status === FileUploadState.Error);

        if (allFinal && uploadState !== UploadState.Starting && uploadRef.current) {
          setUploadState(hadError ? UploadState.Error : UploadState.Complete);
          uploadRef.current!.allCompleteHandler?.(Object.values(uploads));

          // Other upload types have their own snackbars
          setSnackbarOpen(uploadType === 'project');

          if (uploadType === 'project') {
            await sendUploadNotification();
          }
        }
      }
    })();
  }, [
    uploadType,
    uploadRef,
    uploadState,
    getUploads,
    getUploadsProgress,
    uploads,
    uploadsProgress,
  ]);

  const createEmptyProjectBatch = React.useCallback(
    async (
      uploadsChunk: [string, DirectUpload][],
      currentFolderId: string,
      fileIdToParentFolderId?: Record<string, string>,
    ) => {
      const uploadIdToTitle: Record<string, string> = {};
      const mappedEntriesWithEmptyProjects: MappedDirectUploads = {};
      const uploadIdToPath: Record<string, string> = {};
      let createEmptyProjectsResponse;
      uploadsChunk.forEach(([uploadId, upload]) => {
        const {canceled, file, nsId, projectId} = upload;

        mappedEntriesWithEmptyProjects[uploadId] = upload;

        // If the upload is canceled or has already been attached to an empty project, then continue
        if (canceled || nsId || projectId) {
          return;
        }

        const title = file.name.substring(0, file.name.lastIndexOf('.')) || file.name;

        uploadIdToTitle[uploadId] = title;
        uploadIdToPath[uploadId] = file?.relativePath || file.webkitRelativePath;
      });

      try {
        createEmptyProjectsResponse = await withRetries(createEmptyProjects, 2)(
          uploadIdToTitle,
          fileIdToParentFolderId,
          currentFolderId,
        );
      } catch (e) {
        const newMappedUploads: MappedDirectUploads = {};

        uploadsChunk.forEach(([uploadId, upload]) => {
          newMappedUploads[uploadId] = updateMappedUploadAsError(
            uploadId,
            upload,
            e,
          ) as DirectUpload;
        });

        return newMappedUploads;
      }

      Object.entries(createEmptyProjectsResponse).forEach(([uploadId, response]) => {
        if (
          !response.nsId ||
          !response.projectId ||
          response.status !== EmptyProjectCreationStatus.Complete
        ) {
          mappedEntriesWithEmptyProjects[uploadId] = updateMappedUploadAsError(
            uploadId,
            mappedEntriesWithEmptyProjects[uploadId],
          ) as DirectUpload;
        } else {
          mappedEntriesWithEmptyProjects[uploadId] = {
            ...mappedEntriesWithEmptyProjects[uploadId],
            nsId: response.nsId,
            projectId: response.projectId,
            canceled: false,
          };
        }
      });

      return mappedEntriesWithEmptyProjects;
    },
    [updateMappedUploadAsError],
  );

  const addEmptyProjectsToMappedUploads = React.useCallback(
    async (
      mappedUploads: MappedDirectUploads,
      fileIdToParentFolderId?: Record<string, string>,
    ): Promise<MappedDirectUploads> => {
      const uploadEntries: [string, DirectUpload][] = Object.entries(mappedUploads);
      const chunkedUploads = chunk(uploadEntries, MAX_CREATE_EMPTY_PROJECT_BATCH);
      let newMappedUploads: MappedDirectUploads = {};

      for (let i = 0; i < chunkedUploads.length; i++) {
        const mappedUploadsWithEmptyProjects = await createEmptyProjectBatch(
          chunkedUploads[i],
          folderRef.current!,
          fileIdToParentFolderId!,
        );

        newMappedUploads = {
          ...newMappedUploads,
          ...mappedUploadsWithEmptyProjects,
        };
      }

      return newMappedUploads;
    },
    [createEmptyProjectBatch],
  );

  const retryUpload = React.useCallback(
    async (uploadId: string, upload: DirectUpload) => {
      clearUploadError(uploadId);
      handleUpdateCurrentUploadBatchIds([uploadId]);
      updateUploadProgress(uploadId, {
        status: FileUploadState.Uploading,
        percentage: 0,
        uploadedSize: 0,
      });
      setUploadState(UploadState.Uploading);

      const newUpload: DirectUpload = {
        ...upload,
        nsId: undefined,
        projectId: undefined,
        canceled: false,
        error: undefined,
      };

      const fileIdToPaths: Record<string, string> = {};
      const hasValidExtension = validExtensionsList.includes(getExtension(upload.file.name));
      if (hasValidExtension) {
        fileIdToPaths[uploadId] = upload.file.relativePath || upload.file.webkitRelativePath;
      }

      const createFoldersFromPathsResult =
        Object.keys(fileIdToPaths).length !== 0 && upload.parentFolderId
          ? await createFoldersFromPaths(upload.parentFolderId, fileIdToPaths)
          : {};
      const fileIdToParentFolderId = createFoldersFromPathsResult.file_id_to_parent_folder_id ?? {};

      const mappedUploads = await addEmptyProjectsToMappedUploads(
        {
          [uploadId]: newUpload,
        },
        fileIdToParentFolderId,
      );

      handleUpdateUploads(mappedUploads);

      newUpload.nsId = mappedUploads[uploadId].nsId;
      newUpload.projectId = mappedUploads[uploadId].projectId;

      if (!newUpload.nsId || !newUpload.projectId) {
        handleFileError(uploadId, UploadErrorType.UploadFailed);
        return;
      }

      const fileUpload = {
        uploadId: uploadId,
        nsId: newUpload.nsId,
        file: newUpload.file,
      };

      updateLoggingData(uploadId, {actionSurface: getActionSurface()});
      loggingClient.logPap(
        PAP_Upload_File({
          ...getLoggingData()[uploadId],
          eventState: 'start',
          startType: 'retry',
        }),
      );
      startParallelUpload([fileUpload], true);
    },
    [
      addEmptyProjectsToMappedUploads,
      clearUploadError,
      getActionSurface,
      getLoggingData,
      handleFileError,
      handleUpdateCurrentUploadBatchIds,
      handleUpdateUploads,
      loggingClient,
      startParallelUpload,
      updateLoggingData,
      updateUploadProgress,
      validExtensionsList,
    ],
  );

  const chooseFiles = React.useCallback((props: ChooseFilesProps) => {
    uploadRef.current = {filesPickHandler: props.onFilesPick};
    const input =
      props.uploadType === 'logo'
        ? singleImageFileInput
        : props.uploadType === 'captions'
        ? singleCaptionsFileInput
        : props.isFolder
        ? folderFileInput
        : props.multiple
        ? multipleMediaFileInput
        : singleMediaFileInput;
    input.current?.click();
  }, []);

  const uploadProject = React.useCallback(
    async ({
      files,
      currentFolderId,
      onUploadStart = () => {},
      onProgress = () => {},
      onFileUploadComplete = () => {},
      onAllUploadsComplete = () => {},
      onFileError = () => {},
      onError = () => {},
      folderUpload,
      isDragAndDrop = false,
      clickSource,
    }: UploadProjectProps) => {
      setUploadType('project');
      setUploadState(UploadState.Starting);
      // We only want this to open when 'clickSource' is not drag_and_drop
      setSnackbarOpen(clickSource !== 'drag_and_drop');

      const batchId = generateRandomId();

      folderRef.current = currentFolderId;
      uploadRef.current = {
        progressHandler: onProgress,
        fileCompleteHandler: onFileUploadComplete,
        allCompleteHandler: (uploads: DirectUpload[]) => {
          onAllUploadsComplete(
            uploads.map(({file}) => file),
            currentFolderId,
            folderUpload,
          );
        },
        fileErrorHandler: onFileError,
        errorHandler: onError,
      };

      const mappedUploads: MappedDirectUploads = {};
      let fileIdToParentFolderId: Record<string, string> = {};
      const fileIdToPaths: Record<string, string> = {};

      if (files.length > 1) {
        //Batch uploads require only essential attributes; we do not need to include basic logging data in this context
        loggingClient.logPap(
          PAP_Upload_Batch({
            itemCount: files.length,
            isFolder: folderUpload,
            batchIdString: batchId,
            actionSource: clickSource,
          }),
        );
      }

      files.forEach((file) => {
        const hasValidExtension = validExtensionsList.includes(getExtension(file.name));
        const randomId = generateRandomId();

        updateLoggingData(randomId, {
          actionSource: clickSource,
          uploadMethod: 'direct_upload',
          contentType: folderUpload ? 'folder' : 'file',
          startType: 'initial',
          uploadId: randomId,
          batchIdString: batchId,
          eventState: 'start',
          fileCategory: getMediaType(getExtension(file.name)),
          fileExtension: getExtension(file.name),
        });

        loggingClient.logPap(PAP_Upload_File(getLoggingData()[randomId]));

        if (hasValidExtension) {
          fileIdToPaths[randomId] = file.relativePath || file.webkitRelativePath;
        }
        mappedUploads[randomId] = {
          file,
          canceled: !hasValidExtension,
          error: !hasValidExtension ? UploadErrorType.InvalidType : undefined,
          mediaSourceType: 'direct_upload',
          type: UploadTypes.PROJECT,
          uploadId: randomId,
          onRetry: (upload) => retryUpload(upload.uploadId, upload as DirectUpload),
          onCancel: (upload) => cancelUpload(upload.uploadId),
          parentFolderId: currentFolderId,
        };
      });

      const createFoldersFromPathsResult =
        Object.keys(fileIdToPaths).length !== 0
          ? await createFoldersFromPaths(currentFolderId, fileIdToPaths)
          : {};
      fileIdToParentFolderId = createFoldersFromPathsResult.file_id_to_parent_folder_id ?? {};

      const progress: UploadsProgress = {};

      Object.entries(mappedUploads).forEach(([uploadId]) => {
        const {canceled, file} = mappedUploads[uploadId];

        progress[uploadId] = {
          percentage: 0,
          status: canceled ? FileUploadState.Error : FileUploadState.Ready,
          uploadedSize: 0,
          totalSize: file.size,
        };
      });

      onUploadStart(Object.values(mappedUploads));

      handleUpdateUploads(mappedUploads);
      handleUpdateProgress(progress);

      setSnackbarOpen(clickSource !== 'drag_and_drop');

      // Get empty project info and apply to mapped uploads data
      const mappedUploadsWithProjects = await addEmptyProjectsToMappedUploads(
        mappedUploads,
        fileIdToParentFolderId,
      );

      handleUpdateUploads(mappedUploadsWithProjects);
      setUploadState(UploadState.Ready);
    },
    [
      addEmptyProjectsToMappedUploads,
      cancelUpload,
      getLoggingData,
      handleUpdateProgress,
      handleUpdateUploads,
      loggingClient,
      retryUpload,
      updateLoggingData,
      validExtensionsList,
    ],
  );

  /**
   * Upload a new version of a video
   *
   * In order to allow users to upload versions to projects they don't own (but have edit access),
   * we create an empty project in the user's namespace and upload the asset to such project.
   * Once the upload is complete, we attach the asset to the version and finally, delete the empty project.
   * See `addUploadToVersion()` for more details.
   * Note: If the original uploader is the same as the current user, we skip the empty project creation step.
   */
  const uploadVersion = React.useCallback(
    async ({
      file,
      nsId,
      ownerUid,
      onCreateVersion,
      onUploadStart = () => {},
      onProgress = () => {},
      onFileUploadComplete = () => {},
      onFileError = () => {},
      clickSource,
    }: UploadVersionProps) => {
      setUploadType('version');
      setUploadState(UploadState.Starting);
      uploadRef.current = {
        progressHandler: onProgress,
        fileCompleteHandler: onFileUploadComplete,
        fileErrorHandler: onFileError,
        onCreateVersionHandler: onCreateVersion,
      };

      const uploadId = generateRandomId();
      const batchId = generateRandomId();

      updateLoggingData(uploadId, {
        actionSource: clickSource,
        uploadMethod: 'direct_upload',
        contentType: 'file',
        startType: 'initial',
        batchIdString: batchId,
        uploadId: uploadId,
        eventState: 'start',
        fileCategory: getMediaType(getExtension(file.name)),
        actionElement: 'version',
        fileExtension: getExtension(file.name),
      });

      loggingClient.logPap(PAP_Upload_File(getLoggingData()[uploadId]));

      const currentUser = getCurrentUser();
      const isOriginalUploader = !!currentUser && String(currentUser?.id) === ownerUid;

      let mappedUploads = {
        [uploadId]: {
          canceled: false,
          file,
          mediaSourceType: 'direct_upload' as MediaSourceType,
          nsId,
          type: UploadTypes.VERSION,
          uploadId,
          onRetry: (upload: UploadInfo) => retryUpload(upload.uploadId, upload as DirectUpload),
          onCancel: (upload: UploadInfo) => {
            cancelUpload(upload.uploadId);
            // If upload is cancelled and it required an empty project, delete it now
            if (!isOriginalUploader && upload.projectId) {
              deleteProject(upload.projectId);
            }
          },
        } as DirectUpload,
      };

      if (!isOriginalUploader) {
        mappedUploads[uploadId].nsId = undefined; // Clean up the nsId that doesn't belong to the current user
        mappedUploads = await addEmptyProjectsToMappedUploads(mappedUploads, {});
      }

      const progress = {
        [uploadId]: {
          percentage: 0,
          status: FileUploadState.Ready,
          uploadedSize: 0,
          totalSize: file.size,
        },
      };

      onUploadStart(Object.values(mappedUploads));

      handleUpdateUploads(mappedUploads as MappedDirectUploads);
      handleUpdateProgress(progress);

      setUploadState(UploadState.Ready);
      setSnackbarOpen(true);
    },
    [
      addEmptyProjectsToMappedUploads,
      updateLoggingData,
      loggingClient,
      getLoggingData,
      handleUpdateUploads,
      handleUpdateProgress,
      retryUpload,
      cancelUpload,
    ],
  );

  const uploadCaptions = React.useCallback(
    async ({file, nsId, onFileUpload}: UploadFileProps) => {
      setUploadType('captions');
      setUploadState(UploadState.Starting);
      uploadRef.current = {
        onAddFileHandler: onFileUpload,
      };
      const uploadId = generateRandomId();
      const batchId = generateRandomId();

      updateLoggingData(uploadId, {
        uploadMethod: 'direct_upload',
        startType: 'initial',
        batchIdString: batchId,
        eventState: 'start',
        uploadId: uploadId,
        fileCategory: 'other',
        actionElement: UploadTypes.CAPTIONS,
        fileExtension: getExtension(file.name),
      });

      loggingClient.logPap(PAP_Upload_File(getLoggingData()[uploadId]));

      const mappedUploads = {
        [uploadId]: {
          canceled: false,
          file,
          mediaSourceType: 'direct_upload' as MediaSourceType,
          nsId,
          type: UploadTypes.CAPTIONS,
          uploadId,
          onRetry: (upload: DirectUpload) => retryUpload(upload.uploadId, upload as DirectUpload),
          onCancel: (upload: DirectUpload) => cancelUpload(upload.uploadId),
        },
      };
      const progress = {
        [uploadId]: {
          percentage: 0,
          status: FileUploadState.Ready,
          uploadedSize: 0,
          totalSize: file.size,
        },
      };
      handleUpdateUploads(mappedUploads as MappedDirectUploads);
      handleUpdateProgress(progress);

      setUploadState(UploadState.Ready);
      setSnackbarOpen(true);
    },
    [
      updateLoggingData,
      loggingClient,
      getLoggingData,
      handleUpdateUploads,
      handleUpdateProgress,
      retryUpload,
      cancelUpload,
    ],
  );

  const uploadLogo = React.useCallback(
    async ({file, nsId, onFileUpload, onFileError}: UploadFileProps) => {
      setUploadType('logo');
      setUploadState(UploadState.Starting);
      uploadRef.current = {
        onAddFileHandler: onFileUpload,
        fileErrorHandler: onFileError,
      };
      const uploadId = generateRandomId();
      const batchId = generateRandomId();

      updateLoggingData(uploadId, {
        uploadMethod: 'direct_upload',
        startType: 'initial',
        batchIdString: batchId,
        eventState: 'start',
        uploadId: uploadId,
        fileCategory: 'other',
        actionElement: UploadTypes.LOGO,
        fileExtension: getExtension(file.name),
      });

      loggingClient.logPap(PAP_Upload_File(getLoggingData()[uploadId]));

      const mappedUploads = {
        [uploadId]: {
          canceled: false,
          hideDrawer: true,
          file,
          mediaSourceType: 'direct_upload' as MediaSourceType,
          nsId,
          type: UploadTypes.LOGO,
          uploadId,
          batchId,
        },
      };
      const progress = {
        [uploadId]: {
          percentage: 0,
          status: FileUploadState.Ready,
          uploadedSize: 0,
          totalSize: file.size,
        },
      };
      handleUpdateUploads(mappedUploads as MappedDirectUploads);
      handleUpdateProgress(progress);

      setUploadState(UploadState.Ready);
      setSnackbarOpen(true);
    },
    [updateLoggingData, loggingClient, getLoggingData, handleUpdateUploads, handleUpdateProgress],
  );

  const value: UploadContext = React.useMemo(
    () => ({
      chooseFiles,
      uploadCaptions,
      uploadLogo,
      uploadProject,
      uploadVersion,
      uploadState,
      retryUpload,
      uploadSnackbarOpen: snackbarOpen,
      setUploadSnackbarOpen: setSnackbarOpen,
    }),
    [
      chooseFiles,
      uploadCaptions,
      uploadLogo,
      uploadProject,
      uploadVersion,
      uploadState,
      retryUpload,
      snackbarOpen,
    ],
  );

  const acceptList = validExtensionsList.join(',');

  return (
    <UploadContext.Provider value={value}>
      <input
        accept={IMAGE_EXTENSIONS.join(',')}
        data-testid="logo-input"
        onClick={(e) => ((e.target as HTMLInputElement).value = '')}
        onInput={handleFileInput}
        ref={singleImageFileInput}
        style={{display: 'none'}}
        type="file"
      />
      <input
        accept=".srt"
        onClick={(e) => ((e.target as HTMLInputElement).value = '')}
        onInput={handleFileInput}
        ref={singleCaptionsFileInput}
        style={{display: 'none'}}
        type="file"
      />
      <input
        accept={acceptList}
        data-testid="single-media-file-input"
        onClick={(e) => ((e.target as HTMLInputElement).value = '')}
        onInput={handleFileInput}
        ref={singleMediaFileInput}
        style={{display: 'none'}}
        type="file"
      />
      <input
        accept={acceptList}
        multiple
        onClick={(e) => ((e.target as HTMLInputElement).value = '')}
        onInput={handleFileInput}
        ref={multipleMediaFileInput}
        style={{display: 'none'}}
        type="file"
      />
      <input
        directory=""
        mozdirectory=""
        multiple
        onClick={(e) => ((e.target as HTMLInputElement).value = '')}
        onInput={handleFileInput}
        ref={folderFileInput}
        style={{display: 'none'}}
        type="file"
        webkitdirectory=""
      />
      {props.children}
    </UploadContext.Provider>
  );
};

export const useUpload = () => {
  const uploadContext = React.useContext(UploadContext);

  if (uploadContext === null) {
    const error = new Error('useUpload must be used within a UploadProvider');
    reportBadContextUseError(error);
    throw error;
  }

  return uploadContext;
};
