import {defineMessages} from 'react-intl';

export const commonText = defineMessages({
  cancelText: {
    defaultMessage: 'Cancel',
    id: 'hHNj31',
    description: 'Cancel button text',
  },
  saveText: {
    defaultMessage: 'Save',
    id: 'RT8KNi',
    description: 'Save button text',
  },
  savingText: {
    defaultMessage: 'Saving...',
    id: 'HnpJR/',
    description: 'Save button text while saving the settings is in progress.',
  },
});
