import React from 'react';

import {useAtomValue} from 'jotai';
import {PAP_Select_ModalAction} from 'pap-events/replay/select_modal_action';
import {PAP_Select_WorkspaceAction} from 'pap-events/replay/select_workspace_action';
import {PAP_Shown_Modal} from 'pap-events/replay/shown_modal';
import {useIntl} from 'react-intl';

import type {reel} from '@dropbox/api-v2-client';

import {DownloadButton} from '~/components/download/download_button';
import {DownloadOptionsModal} from '~/components/download/download_options_modal';
import {useErrorSnackbar} from '~/components/snackbar/error_snackbar';
import {
  getZipVideoDownloadLink,
  instanceOfNoFilesFound,
  instanceOfTooManyFiles,
  instanceOfTooMuchData,
} from '~/lib/api';
import {download} from '~/lib/download';
import {useGetPapAccessType} from '~/lib/use_get_pap_access_type';
import {useLoggingClient} from '~/lib/use_logging_client';
import {useFoldersSharedState} from '~/pages/browse_page/folders_context';
import {selectedProjectIdsAtom} from '~/state/browse';

import {downloadErrorStrings} from './strings';

export const BulkDownloadFilesButtonAndModal = () => {
  const intl = useIntl();
  const {openErrorSnackbar} = useErrorSnackbar();
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const selectedItems = useAtomValue(selectedProjectIdsAtom);
  const {currentFolder, breadcrumbPath} = useFoldersSharedState();
  const papAccessType = useGetPapAccessType({
    accessLevel: currentFolder?.accessLevel['.tag'] ?? null,
  });
  const logger = useLoggingClient();

  // Log Modal Shown event
  React.useEffect(() => {
    if (modalOpen) {
      logger.logPap(
        PAP_Shown_Modal({
          modal: 'download',
          actionElement: 'action_bar',
          accessType: papAccessType,
        }),
      );
    }
  }, [logger, modalOpen, papAccessType]);

  const onDownloadButtonClick = React.useCallback(() => {
    logger.logPap(
      PAP_Select_WorkspaceAction({
        deviceId: logger.deviceId,
        contentType: 'asset',
        workspaceAction: 'download',
        actionElement: 'action_bar',
        folderLevel: breadcrumbPath?.length,
        accessType: papAccessType,
      }),
    );
    setModalOpen(true);
  }, [breadcrumbPath, logger, papAccessType]);

  const onModalClose = React.useCallback(() => {
    logger.logPap(
      PAP_Select_ModalAction({
        deviceId: logger.deviceId,
        accessType: papAccessType,
        modal: 'download',
        modalAction: 'cancel',
      }),
    );
    setModalOpen(false);
  }, [logger, papAccessType]);

  const onModalSubmit = React.useCallback(
    (option: reel.DownloadOption) => {
      setModalOpen(false);

      // Log modal action
      logger.logPap(
        PAP_Select_ModalAction({
          deviceId: logger.deviceId,
          accessType: papAccessType,
          modal: 'download',
          modalAction: 'download',
          actionDetail: option['.tag'] !== 'other' ? option['.tag'] : undefined,
        }),
      );
      getZipVideoDownloadLink({
        assetIds: selectedItems,
        downloadOption: option,
        analyticsData: {
          papAccessType: papAccessType,
          logger: logger,
        },
      })
        .then(({link}) => {
          download({url: link});
        })
        .catch((e) => {
          let message = intl.formatMessage(downloadErrorStrings.otherError);
          if (e?.error?.error && typeof e.error.error === 'object') {
            const error: Object = e.error.error;
            if (instanceOfNoFilesFound(error)) {
              message = intl.formatMessage(downloadErrorStrings.noFilesFoundError);
            } else if (instanceOfTooMuchData(error)) {
              message = intl.formatMessage(downloadErrorStrings.tooMuchDataError);
            } else if (instanceOfTooManyFiles(error)) {
              message = intl.formatMessage(downloadErrorStrings.tooManyFilesError);
            }
          }
          openErrorSnackbar(message);
        });
    },
    [intl, logger, openErrorSnackbar, papAccessType, selectedItems],
  );

  return (
    <>
      <DownloadButton
        buttonVariant="opacity"
        displayType="expanded"
        onClick={onDownloadButtonClick}
      />
      <DownloadOptionsModal
        downloadOptions={[{'.tag': 'latest_files'}, {'.tag': 'all_files'}]}
        downloadType="files"
        onClose={onModalClose}
        onSubmit={onModalSubmit}
        open={modalOpen}
      />
    </>
  );
};
