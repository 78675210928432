import React from 'react';

import {useIntl} from 'react-intl';
import styled from 'styled-components';

import {UIIcon} from '@dropbox/dig-icons';
import {PlayCircleFill} from '@dropbox/dig-icons/assets';

import {IconButton} from '~/components/button';
import type {LoggingClient} from '~/lib/logging/logger';

const OverlayContainer = styled.div<{$visible: boolean}>`
  visibility: ${({$visible}) => ($visible ? 'visible' : 'hidden')};
  transition: ${({$visible}) =>
    $visible ? 'opacity 0.3s linear' : 'visibility 0s 0.3s, opacity 0.3s linear'};
  opacity: ${({$visible}) => ($visible ? '1' : '0')};
  position: absolute;
  width: 100%;
  height: 338px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;

const VideoOverlay = styled.div`
  background-color: var(--dig-color__background__raised);
  opacity: 0.6;
  width: 100%;
  height: inherit;
  z-index: 1;
  position: absolute;
`;

export const WelcomeVideo = ({
  logEvent,
  videoRef,
}: {
  logEvent: LoggingClient['logEvent'];
  videoRef: React.RefObject<HTMLVideoElement>;
}) => {
  const intl = useIntl();
  const playButtonText = intl.formatMessage({
    defaultMessage: 'Play',
    id: '5B9h8w',
    description: 'Button aria label for play button on Welcome video.',
  });

  const [isPlaying, setIsPlaying] = React.useState(false);

  React.useEffect(() => {
    const currentVideoRef = videoRef?.current;
    currentVideoRef?.addEventListener('play', (event) => setIsPlaying(true));
    currentVideoRef?.addEventListener('pause', (event) => {
      !currentVideoRef.seeking && setIsPlaying(false);
    });
    currentVideoRef?.addEventListener('ended', (event) =>
      // eslint-disable-next-line deprecation/deprecation
      logEvent('welcome_video_played_until_end'),
    );

    return () => {
      currentVideoRef?.removeEventListener('play', () => {});
      currentVideoRef?.removeEventListener('pause', () => {});
      currentVideoRef?.removeEventListener('ended', () => {});
    };
  }, [videoRef, logEvent]);

  const playVideo = () => {
    videoRef?.current?.play();
    // eslint-disable-next-line deprecation/deprecation
    logEvent('select_welcome_video_play');
  };

  return (
    <div>
      <OverlayContainer $visible={!isPlaying} onClick={playVideo}>
        <IconButton
          shape="circular"
          size="large"
          style={{zIndex: '9', width: '64px', height: '64px'}}
          variant="transparent"
        >
          <UIIcon
            aria-label={playButtonText}
            size="large"
            src={PlayCircleFill}
            style={{width: '64px', height: '64px'}}
          />
        </IconButton>
        <VideoOverlay />
      </OverlayContainer>
      <video
        controls={isPlaying}
        controlsList="nodownload noremoteplayback noplaybackrate"
        disablePictureInPicture
        poster="https://assets.dropbox.com/documents/en-us/Replay/replay-welcome.png"
        ref={videoRef}
        src="https://assets.dropbox.com/documents/en-us/Replay/replay-walkthrough.mp4"
        width="100%"
      />
    </div>
  );
};
