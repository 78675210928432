import React from 'react';

import {type IntlShape, useIntl} from 'react-intl';

import {Button} from '~/components/button';
import {
  DROPBOX_VIEW_PLANS_PAGE,
  type ProvisionUserVariant,
  useGetUserVariant,
} from '~/lib/provisions';
import {
  SEND_TO_ADDON_PURCHASE_QUERY_PARAM,
  SEND_TO_ADDON_PURCHASE_QUERY_VALUE,
} from '~/lib/url_utils';
import {useBrowseLogEventMaker} from '~/pages/browse_page/browse_logging_context';
import type {UserQuota} from '~/state/user';

import {Banner} from './banner';

const useBannerText = (
  intl: IntlShape,
  userVariant: ProvisionUserVariant,
  userQuota: UserQuota,
) => {
  const {isAtQuota, hasLargeStorage} = userQuota;
  switch (userVariant) {
    case 'managed_team_member':
    case 'managed_team_admin':
    case 'self_serve_team_member':
    case 'standalone_free_member':
    case 'standalone_paid_member':
      return isAtQuota
        ? intl.formatMessage({
            defaultMessage: 'Your storage is full. Contact your admin to get more storage.',
            id: 'rprfip',
            description: 'Message in banner informing user their storage is full',
          })
        : hasLargeStorage
        ? intl.formatMessage({
            defaultMessage:
              'Your team has less than 300 GB of storage available. Contact your admin to get more storage.',
            id: 'axOgrc',
            description: 'Message in banner informing user their storage is almost full',
          })
        : intl.formatMessage({
            defaultMessage: 'Your storage is 90% full. Contact your admin to get more storage.',
            id: 'pKvoVP',
            description: 'Message in banner informing user their storage is almost full',
          });
    case 'standalone_free_admin':
      return isAtQuota
        ? intl.formatMessage({
            defaultMessage: 'Your storage is full. Buy Dropbox Replay to get more storage.',
            id: 'JMp6EJ',
            description: 'Message in banner informing user their storage is full',
          })
        : intl.formatMessage({
            defaultMessage: 'Your storage is 90% full. Buy Dropbox Replay to get more storage.',
            id: '9sH1kr',
            description: 'Message in banner informing user their storage is almost full',
          });
    case 'standalone_paid_admin':
      return isAtQuota
        ? intl.formatMessage({
            defaultMessage: 'Your storage is full. Buy more storage.',
            id: '/aFmf1',
            description: 'Message in banner informing user their storage is full',
          })
        : hasLargeStorage
        ? intl.formatMessage({
            defaultMessage: 'You have less than 300 GB of storage available. Buy more storage.',
            id: 'yLF/MT',
            description: 'Message in banner informing user their storage is almost full',
          })
        : intl.formatMessage({
            defaultMessage: 'Your storage is 90% full. Buy more storage.',
            id: 'e4Kwi3',
            description: 'Message in banner informing user their storage is almost full',
          });
    default:
      return isAtQuota
        ? intl.formatMessage({
            defaultMessage:
              'Your storage is full. Upgrade your plan to buy more storage or delete some files.',
            id: 'm8GQdE',
            description: 'Message in banner informing user their storage is full',
          })
        : hasLargeStorage
        ? intl.formatMessage({
            defaultMessage:
              'You have less than 300 GB of storage available. You can pick a plan with more storage or delete some files.',
            id: 'BPmADi',
            description: 'Message in banner informing user their storage is almost full',
          })
        : intl.formatMessage({
            defaultMessage:
              'Your storage is 90% full. Upgrade your plan to get more storage or delete some files.',
            id: 'nONjsZ',
            description: 'Message in banner informing user their storage is almost full',
          });
  }
};

const useBannerCta = (intl: IntlShape, userVariant: ProvisionUserVariant) => {
  switch (userVariant) {
    case 'managed_team_member':
    case 'managed_team_admin':
    case 'self_serve_team_member':
    case 'standalone_paid_member':
    case 'standalone_free_member':
      return null;
    case 'standalone_free_admin':
      return intl.formatMessage({
        defaultMessage: 'Buy Replay',
        id: 'lJoiEI',
        description: 'Button label to buy Dropbox Replay paid standalone to get more storage',
      });
    case 'standalone_paid_admin':
      return intl.formatMessage({
        defaultMessage: 'Buy storage',
        id: '+b0Wz5',
        description: 'Button label to buy the storage add-on to get more storage',
      });
    default:
      return intl.formatMessage({
        defaultMessage: 'View Plans',
        id: '2g4wW3',
        description: 'Button label to direct to Dropbox plans',
      });
  }
};

const useBannerCtaLink = (userVariant: ProvisionUserVariant) => {
  // Send standalone admins to /buy so they get redirected to either the
  // standalone checkout page or the storage add-on checkout page. Send
  // everyone else to the plans page to upgrade their FSS.
  switch (userVariant) {
    case 'standalone_paid_admin':
    case 'standalone_free_admin':
      return `/buy?${SEND_TO_ADDON_PURCHASE_QUERY_PARAM}=${SEND_TO_ADDON_PURCHASE_QUERY_VALUE}`;
    default:
      return DROPBOX_VIEW_PLANS_PAGE;
  }
};

interface StorageQuotaBannerProps {
  fullWidth?: boolean;
  userQuota: UserQuota;
}

export const StorageQuotaBanner = ({userQuota, fullWidth}: StorageQuotaBannerProps) => {
  const intl = useIntl();
  const userVariant = useGetUserVariant();
  const makeLogEvent = useBrowseLogEventMaker();
  const logEvent = React.useMemo(() => makeLogEvent({reelObjectType: 'folder'}), [makeLogEvent]);

  const {isAtQuota} = userQuota;
  const bannerText = useBannerText(intl, userVariant, userQuota);
  const bannerCta = useBannerCta(intl, userVariant);
  const bannerCtaLink = useBannerCtaLink(userVariant);

  React.useEffect(() => {
    // eslint-disable-next-line deprecation/deprecation
    logEvent('shown.alert_banner', {
      message_type: isAtQuota ? 'over_storage_limit' : 'near_storage_limit',
      cta_location: 'storage_banner',
    });
  }, [isAtQuota, logEvent]);

  return (
    <Banner $fullWidth={fullWidth} type={isAtQuota ? 'alert' : 'warning'} withLeftIcon={true}>
      <Banner.Message>{bannerText}</Banner.Message>
      {bannerCta && (
        <Banner.Actions>
          <Button
            href={bannerCtaLink}
            onClick={() => {
              // eslint-disable-next-line deprecation/deprecation
              logEvent('select.cta_link', {
                link_type: 'view_plans',
                cta_location: 'storage_banner',
              });
            }}
            variant="transparent"
          >
            {bannerCta}
          </Button>
        </Banner.Actions>
      )}
    </Banner>
  );
};
