import type {ForwardRefExoticComponent, RefAttributes} from 'react';
import React from 'react';

import styled, {keyframes} from 'styled-components';

import type {TextProps, TitleProps} from '@dropbox/dig-components/typography';
import {Text, Title} from '@dropbox/dig-components/typography';
import {Box} from '@dropbox/dig-foundations';

const CircleAnimationContent = styled(Box)`
  inset: 0px;
  position: absolute;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
`;

const loading = (dashoffset: number) => keyframes`
  from {
    stroke-dashoffset: ${dashoffset}px;
  }

  to {
    stroke-dashoffset: 0;
  }
`;

const Circle = styled.circle<{$size: number; $strokeWidth: number}>`
  cx: ${(props) => props.$size / 2}px;
  cy: ${(props) => props.$size / 2}px;
  fill: none;
  r: ${(props) => (props.$size - props.$strokeWidth) / 2}px;
  stroke: currentcolor;
  stroke-dasharray: ${(props) => props.$size * 3.14}px;
  stroke-linecap: round;
  stroke-width: ${(props) => props.$strokeWidth}px;
  animation: ${(props) => loading(props.$size * 3.14 * 0.75)} 0.5s linear;
`;

interface CircleAnimationProps {
  size: number;
  strokeWidth: number;
  ariaLabel: string;
  fontType:
    | ForwardRefExoticComponent<TextProps<HTMLElement> & RefAttributes<HTMLElement>>
    | React.FC<TitleProps>;
}

export const CircleAnimation = ({size, strokeWidth, fontType, ariaLabel}: CircleAnimationProps) => {
  return (
    <Box display="inline-flex" position="relative">
      <Box
        aria-label={ariaLabel}
        aria-valuenow={75}
        color="Warning Base"
        role="progressbar"
        style={{
          width: size,
          height: size,
          display: 'inline-block',
          transform: 'rotate(-90deg)',
        }}
      >
        <svg viewBox={`0 0 ${size} ${size}`}>
          <Circle $size={size} $strokeWidth={strokeWidth} />
        </svg>
      </Box>
      <CircleAnimationContent>
        {fontType === Text ? (
          <Text isBold={true} size="large" variant="label">
            100%
          </Text>
        ) : (
          <Title size="large" weightVariant="emphasized">
            100%
          </Title>
        )}
      </CircleAnimationContent>
    </Box>
  );
};
