/**
 * Either narrows the given argument to the expected type or throws an error.
 * Should only be used when we know situationally that a value must be of a
 * certain type but need the narrower type.
 */
export function unionAs<
  Discriminant extends string,
  Union extends {type: Discriminant},
  Expected extends Discriminant,
>(union: Union, type: Expected) {
  if (union.type !== type) {
    throw new Error(`expected '${type}', but received: '${union.type}'`);
  }

  // Our powers are too strong for typescript and we must cast
  // https://github.com/microsoft/TypeScript/issues/46899
  return union as Extract<Union, {type: Expected}>;
}

/** Runtime check that either narrows to expected type or throws */
export function check<T>(t: T | undefined | null, msg?: string): T {
  if (t === undefined || t === null) {
    throw new Error(msg ?? 'unexpected null or undefined');
  }
  return t;
}
