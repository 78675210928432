import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';

import {Text} from '@dropbox/dig-components/typography';

const SkipToContentButton = styled.a`
  background-color: #254072;
  border: 4px dashed var(--color__inverse__core__accent);
  box-shadow: var(--boxshadow__elevation1);
  left: calc(50% - 100px);
  padding: 10px;
  position: absolute;
  text-align: center;
  text-decoration: none;
  transform: translateY(-150%);
  width: 200px;
  z-index: 20;

  &:focus {
    transform: translateY(0%);
  }
`;

export const SkipToContent = () => {
  return (
    <SkipToContentButton href="#main">
      <Text color="standard">
        <FormattedMessage
          defaultMessage="Skip to content"
          description="Text on a button that allows navigation to main content area for keyboard users"
          id="yEx5PO"
        />
      </Text>
    </SkipToContentButton>
  );
};
