import {useCallback, useMemo} from 'react';

import path from 'path-browserify';
import {defineMessages, FormattedMessage} from 'react-intl';
import {useIntl} from 'react-intl';
import styled from 'styled-components';

import type {reel} from '@dropbox/api-v2-client';
import {Badge} from '@dropbox/dig-components/badge';
import {ProgressBar} from '@dropbox/dig-components/progress_indicators';
import {Tooltip} from '@dropbox/dig-components/tooltips';
import {Truncate} from '@dropbox/dig-components/truncate';
import {Text} from '@dropbox/dig-components/typography';
import {Box, Split} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {
  CheckmarkCircleLine,
  ClockLine,
  FailLine,
  LinkLine,
  SyncingLine,
} from '@dropbox/dig-icons/assets';

import {Button, IconButton} from '~/components/button';
import type {ButtonVariant} from '~/components/button';
import {spacing} from '~/components/styled';
import {useShareUploadRow} from '~/components/upload_drawer/use_share_upload_row';
import type {ProvisionUserVariant} from '~/lib/provisions';
import {
  FileUploadState,
  UploadDrawerStatus,
  UploadErrorType,
  UploadTypes,
} from '~/lib/uploads/types';
import type {UploadProgress} from '~/lib/uploads/types';
import type {UploadInfo} from '~/lib/uploads/types';
import {formatBytes} from '~/lib/utils';

const IconBox = styled(Box)`
  margin-top: calc(-1 * ${spacing('8')});
`;

const RowBadge = styled(Badge)`
  margin-right: ${spacing('8')};
`;

type UploadRowProps = {
  drawerUploadStatus: UploadDrawerStatus;
  upload: UploadInfo;
  uploadDrawerWidth: number;
  uploadProgress: UploadProgress;
  userVariant: ProvisionUserVariant;
  versionSummaries: reel.VersionSummary[];
  handleViewNewUpload?: (upload: UploadInfo) => void;
};

const UploadRow = ({
  drawerUploadStatus,
  upload,
  uploadDrawerWidth,
  uploadProgress,
  userVariant,
  versionSummaries,
  handleViewNewUpload = () => {},
}: UploadRowProps) => {
  const intl = useIntl();

  const versionNumber = useMemo(() => {
    const matching = (versionSummaries || []).find(
      (version) => version.video_version_id === upload.versionId,
    );
    return matching ? matching.version_num : 0;
  }, [versionSummaries, upload]);

  const {handleOpenShareModal} = useShareUploadRow({
    upload,
    versionSummaries,
  });

  const {isButtonDisabled, statusIcon, buttonCopy, buttonVariant, subtitleCopy} = useMemo(() => {
    let statusIcon;
    let buttonCopy;
    let buttonVariant: ButtonVariant = 'outline';
    let isButtonDisabled = false;
    let subtitleCopy;

    switch (uploadProgress.status) {
      case FileUploadState.Cancelled:
        statusIcon = FailLine;
        buttonVariant = 'outline';
        subtitleCopy = intlMessages.canceled;

        if (upload.onRetry) {
          buttonCopy = intlMessages.buttonRetry;

          if (drawerUploadStatus === UploadDrawerStatus.UPLOADING) {
            isButtonDisabled = true;
          }
        }
        break;
      case FileUploadState.Error:
        statusIcon = FailLine;
        buttonVariant = 'outline';
        subtitleCopy = intlMessages.errorOccurred;

        const isTeamMember = [
          'managed_user',
          'managed_team_admin',
          'self_serve_user',
          'self_serve_team_admin',
        ].includes(userVariant);

        if (uploadProgress.error === UploadErrorType.FILE_QUOTA_EXCEEDED_ERROR) {
          if (isTeamMember) {
            subtitleCopy = intlMessages.limitReachedTeamMember;
          } else {
            subtitleCopy = intlMessages.limitReachedBasic;
          }
        }

        if (uploadProgress.error === UploadErrorType.USAGE_QUOTA_EXCEEDED_ERROR) {
          if (isTeamMember) {
            subtitleCopy = intlMessages.usageLimitReachedTeamMember;
          } else {
            subtitleCopy = intlMessages.usageLimitReachedBasic;
          }
        }

        if (upload.onRetry) {
          buttonCopy = intlMessages.buttonRetry;

          if (drawerUploadStatus === UploadDrawerStatus.UPLOADING) {
            isButtonDisabled = true;
          }
        }
        break;
      case FileUploadState.Complete:
        statusIcon = CheckmarkCircleLine;
        buttonCopy = intlMessages.buttonViewLink;
        buttonVariant = 'outline';

        switch (upload.type) {
          case UploadTypes.PROJECT:
            subtitleCopy = intlMessages.addedProject;
            break;
          case UploadTypes.CAPTIONS:
            subtitleCopy = intlMessages.addedCaption;
            buttonCopy = undefined;
            break;
          case UploadTypes.VERSION:
            subtitleCopy = {
              ...intlMessages.addedVersion,
              values: {
                versionNumber,
              },
            };
            break;
          default:
            subtitleCopy = intlMessages.addedGeneric;
        }
        break;
      case FileUploadState.Uploading:
        statusIcon = SyncingLine;
        buttonVariant = 'opacity';
        const isFinalizing = Math.floor(uploadProgress.percentage || 0) === 100;

        if (upload.onCancel) {
          buttonCopy = intlMessages.buttonCancel;
        }

        if (isFinalizing) {
          subtitleCopy = intlMessages.finalizing;
          isButtonDisabled = true;
        } else if (
          uploadProgress.hasOwnProperty('totalSize') &&
          uploadProgress.hasOwnProperty('uploadedSize')
        ) {
          const {totalSize = 0, uploadedSize = 0} = uploadProgress;
          subtitleCopy = {
            ...intlMessages.uploadingBytesLeft,
            values: {
              totalSize: formatBytes(totalSize, intl),
              uploadedSize: formatBytes(uploadedSize > totalSize ? totalSize : uploadedSize, intl),
            },
          };
        } else {
          subtitleCopy = intlMessages.uploadingGeneric;
        }
        break;
      case FileUploadState.Ready:
        statusIcon = ClockLine;
        subtitleCopy = intlMessages.queued;
        break;
      default:
        statusIcon = SyncingLine;
    }

    return {
      buttonCopy,
      buttonVariant,
      isButtonDisabled,
      statusIcon,
      subtitleCopy,
    };
  }, [
    uploadProgress,
    upload.onRetry,
    upload.type,
    upload.onCancel,
    drawerUploadStatus,
    userVariant,
    versionNumber,
    intl,
  ]);

  const {fileName, fileExtension} = useMemo(() => {
    const parsed = path.parse(upload.file.name);
    const fileName = parsed.name;
    const fileExtension = parsed.ext.substring(1).toUpperCase();

    return {
      fileExtension,
      fileName,
    };
  }, [upload]);

  const maxFileNameWidth = useMemo(() => {
    const paddingX = 32;
    const iconWidth = 24;
    const buttonWidth = 62;
    const spacingBetween = 16;
    return uploadDrawerWidth - paddingX - iconWidth - buttonWidth - spacingBetween;
  }, [uploadDrawerWidth]);

  const handleOnClickButton = useCallback(() => {
    switch (uploadProgress.status) {
      case FileUploadState.Cancelled:
      case FileUploadState.Error:
        if (upload.onRetry) {
          upload.onRetry(upload);
        }
        break;
      case FileUploadState.Complete:
        handleViewNewUpload(upload);
        break;
      case FileUploadState.Uploading:
        if (upload.onCancel) {
          upload.onCancel(upload);
        }
        break;
      default:
        return;
    }
  }, [uploadProgress.status, upload, handleViewNewUpload]);

  if (!upload || !uploadProgress) {
    return null;
  }

  const shareButtonLabel = intl.formatMessage(intlMessages.buttonShare);

  return (
    <Box paddingBottom="16" paddingX="16" width="100%">
      <Split alignY="center" direction="horizontal" gap="8" paddingBottom="10">
        <Split.Item>
          <IconBox color="Text Subtle">
            <UIIcon color="inherit" src={statusIcon} />
          </IconBox>
        </Split.Item>

        <Split.Item width="fill">
          <Box display="flex" flexDirection="column" width="100%">
            <Text isBold size="small">
              <Truncate
                alwaysShowTooltip
                maxWidth={maxFileNameWidth}
                tooltipControlProps={{auto: true, placement: 'top'}}
              >
                {fileName}
              </Truncate>
            </Text>

            <Box alignItems="center" display="flex" marginTop="4">
              <RowBadge>{fileExtension}</RowBadge>
              <Text color="faint" size="xsmall">
                <FormattedMessage {...subtitleCopy} />
              </Text>
            </Box>
          </Box>
        </Split.Item>

        <Split.Item>
          {upload.link && upload.videoId && versionSummaries && (
            <Tooltip title={shareButtonLabel}>
              <IconButton
                aria-label={shareButtonLabel}
                onClick={handleOpenShareModal}
                size="small"
                variant="outline"
              >
                <UIIcon src={LinkLine} />
              </IconButton>
            </Tooltip>
          )}
        </Split.Item>

        <Split.Item>
          {buttonVariant && buttonCopy && (
            <Button
              disabled={isButtonDisabled}
              onClick={handleOnClickButton}
              size="small"
              variant={buttonVariant as ButtonVariant}
            >
              <FormattedMessage {...buttonCopy} />
            </Button>
          )}
        </Split.Item>
      </Split>

      <ProgressBar
        aria-label={intl.formatMessage({
          defaultMessage: 'Upload progress',
          id: 'dWwZl2',
          description: 'Label for progress bar indicating file upload status',
        })}
        in={uploadProgress.status === FileUploadState.Uploading}
        value={uploadProgress.percentage}
      />
    </Box>
  );
};

export {UploadRow};

const intlMessages = defineMessages({
  uploadingGeneric: {
    defaultMessage: 'Uploading...',
    id: 'k+ZTKu',
    description: 'Description for row saying file is uploading',
  },
  uploadingBytesLeft: {
    defaultMessage: 'Uploading {uploadedSize} / {totalSize}...',
    id: '+9I3Do',
    description: 'Description for row saying how many bytes have been uploaded',
  },
  addedTo: {
    defaultMessage: 'Added to {addDestination}',
    id: 'GZ1cd8',
    description: 'Description for row saying where a file has been uploaded to successfully',
  },
  addedGeneric: {
    defaultMessage: 'Added',
    id: 'Kd33h1',
    description:
      'Description for row saying where a file has been uploaded to successfully to a generic location',
  },
  addedProject: {
    defaultMessage: 'Added project',
    id: 'gg7kC9',
    description: 'Description for row saying a project was added',
  },
  addedVersion: {
    defaultMessage: 'Added V{versionNumber}',
    id: '+nqnGr',
    description: 'Description for row saying a version was added',
  },
  addedCaption: {
    defaultMessage: 'Added caption to project version',
    id: 'YE16qu',
    description: 'Description for row saying a caption was added',
  },
  errorOccurred: {
    defaultMessage: 'An error has occurred, please try again',
    id: '+TDKe/',
    description: 'Description for row saying an error has occurred',
  },
  finalizing: {
    defaultMessage: 'Finalizing...',
    id: 'z+XzzI',
    description: 'Description for row for finalizing',
  },
  canceled: {
    defaultMessage: 'Canceled',
    id: 'AwbLWU',
    description: 'Description for row for canceled upload',
  },
  queued: {
    defaultMessage: 'In queue...',
    id: 'Zqdmo/',
    description: 'Description for row for queued upload',
  },
  oneUploadLeft: {
    defaultMessage: 'You have 1 upload left',
    id: 'nAHWKc',
    description: 'Banner saying user has 1 upload left',
  },
  limitReachedTeamMember: {
    defaultMessage: 'Reached upload limit, contact admin to buy add-on.',
    id: 'ZtXJfF',
    description: 'Message saying user has reached limit and to purchase add-on',
  },
  limitReachedBasic: {
    defaultMessage: 'Reached upload limit, buy add-on and try again.',
    id: 'NJwfuY',
    description: 'Message saying user has reached limit and to purchase add-on',
  },
  usageLimitReachedBasic: {
    defaultMessage: 'Out of storage space, upgrade and try again.',
    id: 'wc4eee',
    description: 'Message saying user has reached storage limit and to purchase more space',
  },
  usageLimitReachedTeamMember: {
    defaultMessage: 'Out of storage space, contact admin and try again.',
    id: 'TqRC6H',
    description: 'Message saying user has reached storage limit and to purchase more space',
  },
  buttonViewLink: {
    defaultMessage: 'View',
    id: '1fTn6F',
    description: 'Button option to view project',
  },
  buttonRetry: {
    defaultMessage: 'Retry',
    id: '38h6nW',
    description: 'Button option to retry uploading file',
  },
  buttonCancel: {
    defaultMessage: 'Cancel',
    id: 'BEJXx6',
    description: 'Button option to cancel uploading file',
  },
  buttonShare: {
    defaultMessage: 'Share',
    id: 'Y6+D07',
    description: 'Button option to share uploaded file',
  },
});
