// Series of utilities to provide an auth token from Dropbox
// Based on Adobe UXP Plugin usage

import {redCarpetError} from './error_reporting';
import {enforceExhaustive} from './helpers';
import {DBX_CLIENT_DOMAIN, DBX_CLIENT_ID} from '../context_utils';

export const DROPBOX_WEBSERVER_URL =
  DBX_CLIENT_DOMAIN && DBX_CLIENT_DOMAIN.includes('dev.corp')
    ? `https://meta-${DBX_CLIENT_DOMAIN}`
    : 'https://dropbox.com';

export const CREATE_CODES_URL =
  DBX_CLIENT_DOMAIN && DBX_CLIENT_DOMAIN.includes('dev.corp')
    ? `https://api-${DBX_CLIENT_DOMAIN}/oauth2/device/codes`
    : 'https://api.dropbox.com/oauth2/device/codes';

export const TOKEN_POLL_URL =
  DBX_CLIENT_DOMAIN && DBX_CLIENT_DOMAIN.includes('dev.corp')
    ? `https://api-${DBX_CLIENT_DOMAIN}/oauth2/token`
    : 'https://api.dropbox.com/oauth2/token';

const MILLISECONDS_PER_MINUTE = 60000;

const OAUTH2_DEVICE_FLOW_POLLING_TIMEOUT_MS = 30 * MILLISECONDS_PER_MINUTE; // Polling sanity time out of 30 minutes (matching current TTL of device & user code tokens)
const POLLING_FREQUENCY = 4000; // Poll exactly every 4 seconds.

export type TokenData = {
  accessToken: string;
  refreshToken: string;
  userId: string;
  accountId: string;
};

type CreateCodesResponse = {
  device_code: string;
  expires: number;
  user_code: string;
  verification_uri: string;
  verification_uri_complete: string;
};

export type DeviceFlowSession = {
  deviceCode: string;
  userCode: string;
  verificationUriComplete: string;
};

export type TokenPollApiResponse =
  | {
      access_token: string;
      account_id: string;
      expires_in: number;
      refresh_token: string;
      token_type: string;
      uid: string;
    }
  | {
      error: string;
    };

export const enum TokenPollResultState {
  ERROR = 'ERROR',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
}

export type TokenPollResult =
  | {state: TokenPollResultState.ERROR}
  | {state: TokenPollResultState.PENDING}
  | {
      state: TokenPollResultState.SUCCESS;
      data: {accessToken: string; refreshToken: string; userId: string; accountId: string};
    };

export const parseVerificationUrl = (url: string) => {
  const parsedUrl = new URL(url, DROPBOX_WEBSERVER_URL);
  if (parsedUrl.origin === DROPBOX_WEBSERVER_URL) {
    return parsedUrl.toString();
  } else {
    const newUrl = new URL(parsedUrl.pathname, DROPBOX_WEBSERVER_URL);
    parsedUrl.searchParams.forEach((v, k) => {
      newUrl.searchParams.append(k, v);
    });
    return newUrl.toString();
  }
};

export const newDeviceFlowSession = async (): Promise<DeviceFlowSession> => {
  const createCodesEndpoint = `${CREATE_CODES_URL}?client_id=${DBX_CLIENT_ID}`;
  try {
    const response = await fetch(createCodesEndpoint, {method: 'POST'});
    const body: CreateCodesResponse = await response.json();
    if (body.device_code && body.user_code && body.verification_uri_complete) {
      const verificationUriComplete = parseVerificationUrl(body.verification_uri_complete);
      return {
        deviceCode: body.device_code,
        userCode: body.user_code,
        verificationUriComplete,
      };
    }
    throw new Error('Invalid response received from device code endpoint');
  } catch (e) {
    throw new Error('Invalid response received from device code endpoint');
  }
};

export const finishDeviceFlowSession = async (deviceCode: string): Promise<TokenPollResult> => {
  const fullTokenUrl = new URL(
    `${TOKEN_POLL_URL}?grant_type=device_code&client_id=${DBX_CLIENT_ID}`,
  );
  fullTokenUrl.searchParams.append('device_code', deviceCode);

  try {
    const response = await fetch(fullTokenUrl.toString(), {method: 'POST'});
    const body: TokenPollApiResponse = await response.json();
    // Return the token or an error indicator. This is based on the data in the response body, rather than the status code
    if ('error' in body) {
      // There are other possible explicit errors that can be returned here (access_denied; expired_token), but for our
      // purposes, at least for now, we only care if the request is pending (keep polling) or failed (show error and
      // allow a retry).
      if (body.error === 'authorization_pending') {
        return {
          state: TokenPollResultState.PENDING,
        };
      }

      return {
        state: TokenPollResultState.ERROR,
      };
    }

    if ('access_token' in body) {
      return {
        state: TokenPollResultState.SUCCESS,
        data: {
          accessToken: body.access_token,
          refreshToken: body.refresh_token,
          userId: body.uid,
          accountId: body.account_id,
        },
      };
    }
    return {state: TokenPollResultState.ERROR};
  } catch (e) {
    return {state: TokenPollResultState.ERROR};
  }
};

export function pollForCompletedDeviceFlow(deviceCode: string) {
  return new Promise<TokenData>((resolve, reject) => {
    const startTimeMs = Date.now();

    const intervalId = setInterval(() => {
      checkLoggedIn().catch((e) => redCarpetError(e, ['auth_failed']));
    }, POLLING_FREQUENCY);

    async function checkLoggedIn() {
      // Already cleared
      if (!intervalId) {
        return;
      }

      if (Date.now() - startTimeMs > OAUTH2_DEVICE_FLOW_POLLING_TIMEOUT_MS) {
        // Time out
        clearInterval(intervalId);
        reject(new Error('Time out'));
        return;
      }

      const response: TokenPollResult = await finishDeviceFlowSession(deviceCode);
      if (!intervalId) {
        return;
      }
      switch (response.state) {
        case TokenPollResultState.PENDING:
          return;
        case TokenPollResultState.ERROR:
          clearInterval(intervalId);
          reject(new Error(response.state));
          return;
        case TokenPollResultState.SUCCESS:
          clearInterval(intervalId);
          resolve(response.data);
          return;
        default:
          enforceExhaustive(response);
      }
    }
  });
}
