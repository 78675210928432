import {useEffect} from 'react';

import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import {DropboxResponseError} from '@dropbox/api-v2-client';

import {CurrentEnvironment, getCurrentEnvironment} from '../environment';
import type {StormcrowAssignments} from '../stormcrow';

let isSentryEnabled = true;

export const setSentryEnabled = (enabled: boolean) => {
  isSentryEnabled = enabled;
};

if (getCurrentEnvironment() === CurrentEnvironment.Production) {
  Sentry.init({
    dsn: 'https://9ce27b74784d707c33a25234ce834d3e@d.dropbox.com/4506748859187200',
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
        shouldCreateSpanForRequest(url) {
          return !isLongPollingEndpoint(url);
        },
      }),
    ],
    ignoreErrors: [
      // list shared with dash:
      // https://dropbox.sourcegraphcloud.com/github.com/dropbox-internal/dash@fb9a1186cd0fb984c4103f8b16ba23937e67486d/-/blob/packages/mirage/packages/webapp/helpers/sentry.ts?L38
      'TypeError: Failed to fetch',
      'TypeError: NetworkError when attempting to fetch resource.',
      'TypeError: Load failed',
      'DropboxResponseError',
      'Response failed with a 409 code',
      'Response failed with a 401 code',
      'Missing access token',
      'DOMException',
    ],
    beforeSend(event, hint) {
      if (!isSentryEnabled) {
        return null;
      }
      // A known issue with PAP that we can't do anything about
      // https://dropbox.slack.com/archives/C037P5SFZV2/p1725924562150949?thread_ts=1725917478.190229&cid=C037P5SFZV2
      const exception = hint.originalException;
      if (exception instanceof DropboxResponseError && exception.status === 400) {
        if (typeof exception.error === 'string') {
          // PAP endpoints that are known to throw 400s
          if (
            exception.error.includes('client_metrics/record') ||
            exception.error.includes('pap_event_logging/log_events')
          ) {
            return null;
          }
        }
      }

      return event;
    },
    tracesSampleRate: 1, // 100% of transactions
  });
}

function isLongPollingEndpoint(url?: string) {
  switch (url) {
    case 'https://bolt.dropbox.com/2/notify/subscribe':
    case 'https://thunder.dropbox.com/2/payloads/subscribe':
      return true;
    default:
      return false;
  }
}

export function setStormcrowTags(assignments: StormcrowAssignments) {
  const entries = Object.entries(assignments).map(([key, value]) => [`stormcrow.${key}`, value]);
  Sentry.setTags(Object.fromEntries(entries));
}
