import amplitude from 'amplitude-js';
import type {PAPEvent} from 'pap-events/base/event';
import {
  type Exposure_Stormcrow,
  PAP_Exposure_Stormcrow,
} from 'pap-events/experimentation/exposure_stormcrow';

import {getCurrentUser} from '~/lib/client';
import type {
  AttributeArg,
  BrowseLocationType,
  EventAttributes,
  MainBundleVersion,
  ReelObjectType,
  RuntimeBundleVersion,
  SafeForAmplitudeId,
  UserEventAttributes,
} from '~/lib/logging/logger_types';

import {globalLoggingSwitch} from './global_logging_switch';
import {EventAttributesMap, SurfaceType} from './logger_types';
import {papLogger} from './pap_client_logger';
import {PaperAmplitudeLoggingClient} from './paper_amplitude_logger';
import {ProxiedPapLogger} from './proxied_pap_logger';
import type {ProvisionTierInterface, ProvisionUserVariant} from '../provisions';
import {getMainBundleVersion, getRuntimeBundleVersion} from '../utils';

const isTestEnv = process.env.NODE_ENV === 'test';

class ConsoleLoggingClient {
  logEvent<E extends keyof typeof EventAttributesMap>(
    eventAttributes: EventAttributes,
    eventType: E,
    attributes:
      | undefined
      | {
          [key: string]: string | number | undefined;
        },
  ) {
    if (!isTestEnv) {
      console.debug(
        `${!globalLoggingSwitch.canUseStorage() ? '[nostorage] ' : ''}[ConsoleLoggingClient]`,
        eventType,
        eventAttributes,
        attributes,
      );
    }
  }
}

// ids for logging must begin with this prefix, which indicates they have been
// obfuscated by the server
const ATTRIBUTE_NAME_SAFETY_PREFIX = 'safe_for_amplitude:';

const verifyAttributeNamePrefix = (attr?: string): attr is SafeForAmplitudeId => {
  return Boolean(attr && attr.startsWith(ATTRIBUTE_NAME_SAFETY_PREFIX));
};

// Only log script names that have the following forms
const RUNTIME_BUNDLE_SAFETY_PREFIX = 'https://replay.dropbox.com/runtime.';
const MAIN_BUNDLE_SAFETY_PREFIX = 'https://replay.dropbox.com/main.';

export const verifyMainBundle = (mainBundle: string): mainBundle is MainBundleVersion => {
  return Boolean(mainBundle && mainBundle.startsWith(MAIN_BUNDLE_SAFETY_PREFIX));
};

export const verifyRuntimeBundle = (
  runtimeBundle: string,
): runtimeBundle is RuntimeBundleVersion => {
  return Boolean(runtimeBundle && runtimeBundle.startsWith(RUNTIME_BUNDLE_SAFETY_PREFIX));
};

/**
 * The LoggingClient is the main interface for logging events in the Replay
 * It provides a stable interface for logging events on multiple transports
 */
export class LoggingClient {
  private activeSurface: SurfaceType;
  private activeVideoId: SafeForAmplitudeId | null;
  private activeProjectId: SafeForAmplitudeId | null;
  private activeBrowseLocation: BrowseLocationType | null;
  private runtimeBundleVersion: RuntimeBundleVersion | null;
  private mainBundleVersion: MainBundleVersion | null;
  // These attributes need to be readonly so that we can verify they have the
  // 'safe for logging' prefix once in the constructor
  private readonly activeUserAttributes: UserEventAttributes;
  private isLiveReview: 'true' | 'false' = 'false';
  private reelObjectType: ReelObjectType | null;

  /* eslint-disable deprecation/deprecation */
  private amplitudeClient: PaperAmplitudeLoggingClient;
  private proxiedPapClient: ProxiedPapLogger;
  /* eslint-enable deprecation/deprecation */

  private consoleClient: ConsoleLoggingClient;
  public readonly deviceId?: string;
  public readonly sessionId?: string;

  constructor(
    stormcrows: Record<string, string>,
    provisionTier?: ProvisionTierInterface['provision_tier'],
    provisionUserVariant?: ProvisionUserVariant,
  ) {
    const currentUser = getCurrentUser();
    this.activeSurface = SurfaceType.Unknown;
    this.activeVideoId = null;
    this.activeProjectId = null;
    this.reelObjectType = null;
    this.activeBrowseLocation = null;
    this.runtimeBundleVersion = null;
    this.mainBundleVersion = null;
    this.deviceId = amplitude.getInstance('pap').getDeviceId();
    this.sessionId = String(amplitude.getInstance('pap').getSessionId());

    const activeUserAttributes: UserEventAttributes = {
      isTestUser: currentUser?.email.endsWith('dbx51.com') ?? false,
      isTeamAdmin: currentUser?.is_team_admin ?? false,
      isDropboxer: currentUser?.is_dropboxer ?? false,
      userAgent: navigator?.userAgent ?? '',
      userId: null,
      teamId: null,
      activeTeamSize: currentUser?.active_team_size ?? 0,
      skuName: currentUser?.sku_name ?? 'none',
      locale: currentUser?.locale ?? 'en-US',
      stormcrows: stormcrows,
      storageEnabled: globalLoggingSwitch.canUseStorage() ? 'true' : 'false',
      provision_tier: provisionTier ? provisionTier : null,
      provision_user_variant: provisionUserVariant ? provisionUserVariant : null,
    };

    if (verifyAttributeNamePrefix(currentUser?.amplitude_safe_user_id)) {
      activeUserAttributes.userId = currentUser?.amplitude_safe_user_id || null;
    }

    if (verifyAttributeNamePrefix(currentUser?.amplitude_safe_team_id)) {
      activeUserAttributes.teamId = currentUser?.amplitude_safe_team_id || null;
    }

    this.activeUserAttributes = activeUserAttributes;

    /* eslint-disable deprecation/deprecation */
    this.amplitudeClient = new PaperAmplitudeLoggingClient(this.activeUserAttributes);
    this.proxiedPapClient = new ProxiedPapLogger(this.activeUserAttributes);
    /* eslint-enable deprecation/deprecation */

    this.consoleClient = new ConsoleLoggingClient();
    this.mainBundleVersion = getMainBundleVersion();
    this.runtimeBundleVersion = getRuntimeBundleVersion();
  }

  makeEventAttributes(): EventAttributes {
    return {
      userAttributes: this.activeUserAttributes,
      videoId: this.activeVideoId ?? null,
      projectId: this.activeProjectId ?? null,
      surface: this.activeSurface,
      reelType: this.reelObjectType,
      isLiveReview: this.isLiveReview,
      browseLocation: this.activeBrowseLocation,
      runtimeBundleVersion: this.runtimeBundleVersion,
      mainBundleVersion: this.mainBundleVersion,
    };
  }

  setReelObjectType(reelObjectType: ReelObjectType | null) {
    this.reelObjectType = reelObjectType;
  }

  setSurface(surfaceType: SurfaceType) {
    this.activeSurface = surfaceType;
  }

  setLocation(location: BrowseLocationType | null) {
    this.activeBrowseLocation = location;
  }

  setActiveVideoId(videoId: string) {
    if (verifyAttributeNamePrefix(videoId)) {
      this.activeVideoId = videoId;
    }
  }

  setActiveProjectId(projectId: string) {
    if (verifyAttributeNamePrefix(projectId)) {
      this.activeProjectId = projectId;
    }
  }

  setIsLiveReview(status: boolean) {
    this.isLiveReview = status ? 'true' : 'false';
  }

  logPap(event: PAPEvent) {
    if (this.activeSurface && event.properties && !event.properties.actionSurface) {
      event.properties.actionSurface = this.activeSurface;
    }

    // This is the supported location to call this method
    // eslint-disable-next-line deprecation/deprecation
    papLogger.logEvent(event);
  }

  logStormcrowExposure(properties: Exposure_Stormcrow['properties']) {
    this.logPap(PAP_Exposure_Stormcrow(properties));
  }

  /**
   * Log events using ingest_lenient to PAP and to Amplitude via
   * the Paper Amplitude Project.
   *
   * @deprecated New events should use PAP {@link logPap}
   */
  logEvent<E extends keyof typeof EventAttributesMap>(
    eventType: E,
    // This wonky type expression makes the second argument optional only if it is marked
    // `undefined` in the attributes map
    ...attributes: (typeof EventAttributesMap)[E] extends undefined
      ? [undefined?]
      : [AttributeArg<E>]
  ) {
    if (isTestEnv) return;

    const eventAttributes = this.makeEventAttributes();
    try {
      if (!Object.keys(EventAttributesMap).includes(eventType)) {
        // Make sure we only log pre-defined events and nothing dynamic which
        // could be generated from user data
        throw new Error('Invalid event type name');
      }
      if (attributes[0] !== undefined) {
        // Make sure that all attributes are associated with a validator class
        // and that they pass validation
        Object.entries(attributes[0]).forEach(([attrName, attrValue]) => {
          // @ts-ignore
          const ValidatorClass = EventAttributesMap[eventType][attrName];
          if (!ValidatorClass.validate(attrValue)) {
            throw new Error('Validation error');
          }
        });
      }
      this.consoleClient.logEvent(eventAttributes, eventType, attributes[0]);

      this.amplitudeClient.logEvent(eventAttributes, eventType, attributes[0]);
      this.proxiedPapClient.logEvent(eventAttributes, eventType, attributes[0]);
    } catch (e) {
      // Do not log values that cannot be validated
      console.error(
        `logging of event type ${eventType} failed because its attributes failed validation: ${e}`,
      );
    }
  }
}
