import React from 'react';

import {PAP_Select_TooltipTour} from 'pap-events/onboarding/select_tooltip_tour';
import {PAP_View_TooltipTour} from 'pap-events/onboarding/view_tooltip_tour';

import type {OverlayPlacement} from '@dropbox/dig-components/overlay';

import {SettingsHasMovedTooltipComponent} from '~/components/tooltips/settings_has_moved_tooltip_component';
import {useReelAppGlobalState} from '~/context';
import {USER_METADATA_FIELD} from '~/lib/provisions';
import {useLoggingClient} from '~/lib/use_logging_client';

type AdminHasMovedTooltipProps = {
  placement: OverlayPlacement;
  triggerRef: React.RefObject<HTMLDivElement>;
  actionSurface: string;
};

export const AdminHasMovedTooltip: React.FC<AdminHasMovedTooltipProps> = ({
  placement,
  triggerRef,
  actionSurface,
}) => {
  const session = useReelAppGlobalState();
  const [showTooltip, setShowTooltip] = React.useState(false);
  const logger = useLoggingClient();

  const onClose = React.useCallback(() => {
    logger.logPap(
      PAP_Select_TooltipTour({
        actionSurface: actionSurface,
        deviceId: logger.deviceId,
        tooltipName: 'replay_settings',
      }),
    );
    setShowTooltip(false);
  }, [actionSurface, logger]);

  const onTooltipDisplayed = React.useCallback(() => {
    logger.logPap(
      PAP_View_TooltipTour({
        actionSurface: actionSurface,
        deviceId: logger.deviceId,
        tooltipName: 'replay_settings',
      }),
    );
  }, [actionSurface, logger]);

  React.useEffect(() => {
    if (session.status !== 'logged in') {
      return;
    }
    const isStandaloneUser = session.currentAccount.is_standalone_user || false;
    if (session.metadata.REPLAY_SEEN_ADMIN_MOVED_TOOLTIP !== true && !isStandaloneUser) {
      session.setMetadata(USER_METADATA_FIELD.REPLAY_SEEN_ADMIN_MOVED_TOOLTIP, true);
      setShowTooltip(true);
    }
  }, [session]);

  return (
    <SettingsHasMovedTooltipComponent
      admin={true}
      onClose={onClose}
      onTooltipDisplayed={onTooltipDisplayed}
      open={showTooltip}
      placement={placement}
      triggerRef={triggerRef}
    />
  );
};
