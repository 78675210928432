import React from 'react';

import {Checkbox} from '@dropbox/dig-components/controls';
import {Menu} from '@dropbox/dig-components/menu';

export const FilterMenuItem = (props: {
  label: string;
  value: string;
  isChecked: boolean;
  callback: (key: string) => void;
  withRightAccessory?: React.ReactElement;
}): React.ReactElement => {
  return (
    <Menu.ActionItem
      aria-checked={props.isChecked}
      aria-label={props.label}
      key={props.value}
      onClick={() => {
        props.callback(props.value);
      }}
      role="menuitemcheckbox"
      value={props.value}
      withLeftAccessory={<Checkbox checked={props.isChecked} readOnly tabIndex={-1} />}
      withRightAccessory={props.withRightAccessory}
    >
      {props.label}
    </Menu.ActionItem>
  );
};
