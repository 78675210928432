import React from 'react';

import {useAtomValue, useSetAtom} from 'jotai/index';
import styled, {css} from 'styled-components';

import {FolderContextMenu} from '~/components/context_menu';
import {color, colorTransition, spacing} from '~/components/styled';
import {useBrowseItemsViewContext} from '~/pages/browse_page/browse_items_view_context';
import type {BrowseUploadedFolderProps} from '~/pages/browse_page/components/common';
import {SelectCheckbox} from '~/pages/browse_page/components/select_checkbox';
import {TileRoot} from '~/pages/browse_page/components/tiles/tile';
import {folderSelectionStateAtom, noItemsSelectedAtom, selectRangeAtom} from '~/state/browse';
import {useKeyboard} from '~/state/keyboard';

const ContextWrapper = styled.div`
  z-index: 1;
  position: absolute;
  top: ${spacing('Micro Medium')};

  display: flex;
  transition: ${colorTransition('opacity')};
  opacity: 0;

  @media (hover: none) {
    opacity: 1;
  }

  ${TileRoot}:hover &,
  ${TileRoot}:focus-within & {
    opacity: 1;
  }
`;

const CheckboxWrapper = styled(ContextWrapper)<{$isChecked: boolean}>`
  ${TileRoot}[aria-selected='true'] & {
    display: flex;
  }

  position: absolute;
  left: ${spacing('Micro Medium')};
  background: ${color('Utility Overlay')};
  border-radius: var(--dig-radius__medium, 0);

  @media (hover: none) {
    opacity: 1;
  }

  ${({$isChecked}) =>
    $isChecked &&
    css`
      opacity: 1;
    `}
`;

export const ContextMenuWrapper = styled(ContextWrapper)`
  right: ${spacing('Micro Medium')};
  border-radius: var(--dig-radius__medium, 0);
`;

export interface FolderTileContextMenuProps extends BrowseUploadedFolderProps {
  isSelectable: boolean;
  isSelected: boolean;
  isTeamProject: boolean;
}

export const FolderTileContextMenu = ({
  accessLevel,
  folderId,
  isSelectable,
  isSelected,
  name,
  onFolderRename,
  onFoldersDelete,
  openAddPeopleModal,
  openManagePeopleModal,
  updateModifyItemSnackbar,
  allowSuperAdminRights,
  shareRecipients,
  isTeamProject,
}: FolderTileContextMenuProps) => {
  const setFolderSelectionState = useSetAtom(folderSelectionStateAtom);
  const noItemsSelected = useAtomValue(noItemsSelectedAtom);
  const selectRange = useSetAtom(selectRangeAtom);
  const {currentFolderPermission} = useBrowseItemsViewContext();

  const keyboard = useKeyboard();
  // Prevent click from navigating to media
  const handleCheckboxClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const handleSelectFolder = (isSelected: boolean) => {
    if (keyboard.Shift) {
      selectRange(folderId);
    } else {
      setFolderSelectionState({folderId, selected: isSelected});
    }
  };

  const shouldRenderContextMenu = currentFolderPermission !== 'reviewer' && noItemsSelected;

  return (
    <>
      {isSelectable && (
        <CheckboxWrapper $isChecked={isSelected}>
          <SelectCheckbox
            isChecked={isSelected}
            onChange={({target: {checked}}) => handleSelectFolder(checked)}
            onClick={handleCheckboxClick}
          />
        </CheckboxWrapper>
      )}

      {shouldRenderContextMenu && (
        <ContextMenuWrapper>
          <FolderContextMenu
            accessLevel={accessLevel ? accessLevel : 'none'}
            allowSuperAdminRights={
              allowSuperAdminRights &&
              shareRecipients &&
              shareRecipients !== 'loading' &&
              shareRecipients.length > 1
            }
            folderId={folderId}
            isTeamProject={isTeamProject}
            name={name}
            onDelete={onFoldersDelete}
            onRename={onFolderRename}
            openAddPeopleModal={openAddPeopleModal}
            openManagePeopleModal={openManagePeopleModal}
            updateModifyItemSnackbar={updateModifyItemSnackbar}
          />
        </ContextMenuWrapper>
      )}
    </>
  );
};
