import React from 'react';

import {useAtomValue, useSetAtom} from 'jotai';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';

import {Banner} from '@dropbox/dig-components/banner';
import {Checkbox} from '@dropbox/dig-components/controls';
import {Modal} from '@dropbox/dig-components/modal';
import {Tabs} from '@dropbox/dig-components/tabs';
import {Text, Title} from '@dropbox/dig-components/typography';
import {LockLine} from '@dropbox/dig-icons/assets';

import {Button} from '~/components/button';
import {useShareSettingStrings} from '~/components/share_modal/share_modal_text';
import {
  basicShareLinksForItemAtom,
  currentBasicLinkAtom,
  getShareItemAtom,
  getShareModalLoggingInfoAtom,
  isViewOnlyLink,
  shareModalContentsAtom,
  sharingIsRestrictedByTeamPolicyAtom,
  updateShareModalPermissionAtom,
  watermarkLinksForItemAtom,
} from '~/components/share_modal/share_state';
import {commonText} from '~/lib/common_text';
import {useLinkActions} from '~/lib/use_link_actions';

import {useReelAppGlobalState} from '../../context';
import {ReplayError, ReplayErrorCategory, reportException} from '../../lib/error_reporting';
import type {LoggingClient} from '../../lib/logging/logger';
import {useReelProvisioningEnabled} from '../../lib/utils';
import {PremiumTooltip} from '../../pages/layout/components/premium_tooltip';
import {BasicAddOnUpsellModal} from '../add_on_upsell_modal';
import type {LinkInfo} from '../password_controls';
import {PasswordControlsV2} from '../password_controls';
import {useViewport} from '../viewport_context';

const SettingsWrapper = styled.div`
  margin-top: 24px;
`;

const SettingRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 10px 10px 10px 0px;
  > * {
    margin-right: 10px;
  }
`;
const SettingText = styled.div`
  display: flex;
  flex-direction: column;
`;

const PasswordText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const PasswordSetting = styled.div`
  display: flex;
  width: 100%;
  margin-left: 30px;
`;

export const ModalErrorText = styled.div`
  color: 'var(--dig-color__warning__on-surface)';
  text-align: end;
`;

export const TabTitleContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const PasswordRowTitle = ({
  userCanSetPasswords,
  userCanRemovePasswords,
  passwordToggleTitle,
  logEvent,
  showUpsellModal,
}: {
  userCanSetPasswords: boolean;
  userCanRemovePasswords: boolean;
  passwordToggleTitle: string;
  logEvent: LoggingClient['logEvent'];
  showUpsellModal: boolean;
}) => {
  const [showTooltip, setShowTooltip] = React.useState<boolean>(false);
  const [tooltipTimeout, setTooltipTimeout] = React.useState<NodeJS.Timeout>();
  const triggerRef = React.useRef(null);
  const isPasswordUnlocked = userCanSetPasswords || userCanRemovePasswords;
  const pwTextColor = isPasswordUnlocked || showUpsellModal ? 'standard' : 'disabled';

  return (
    <PasswordText
      onMouseEnter={() => {
        const timeout = setTimeout(() => setShowTooltip(true), 200);

        setTooltipTimeout(timeout);
      }}
      onMouseLeave={() => clearTimeout(tooltipTimeout)}
      ref={triggerRef}
    >
      <Text color={pwTextColor}>{passwordToggleTitle}</Text>
      {!isPasswordUnlocked && !showUpsellModal && <LockLine width={20} />}
      {!showUpsellModal && (
        <PremiumTooltip
          onClose={() => setShowTooltip(false)}
          open={showTooltip && !isPasswordUnlocked}
          placement="bottom"
          triggerRef={triggerRef}
        />
      )}
    </PasswordText>
  );
};

const PwBannerContainer = styled.div`
  margin-bottom: 24px;
`;

const PasswordRemovalBanner = () => {
  return (
    <PwBannerContainer>
      <Banner type="attention">
        <Banner.Message>
          <FormattedMessage
            defaultMessage="If you remove this file’s password, you will not be able to reset it later."
            description="Banner text describing how a paid feature will become unavailable if it is disabled."
            id="YEq1GW"
          />
        </Banner.Message>
      </Banner>
    </PwBannerContainer>
  );
};

type ShareModalSettingsProps = {
  canCreateLinks: boolean;
  showShareHome: () => void;
};

export const ShareModalSettings = (props: ShareModalSettingsProps) => {
  const {canCreateLinks, showShareHome} = props;
  const [errorText, setErrorText] = React.useState('');
  const [isSaving, setIsSaving] = React.useState(false);

  const shareItem = useAtomValue(getShareItemAtom);
  const currentLinkInfo = useAtomValue(currentBasicLinkAtom);
  const shareModalLoggingInfo = useAtomValue(getShareModalLoggingInfoAtom);
  const shareModalState = useAtomValue(shareModalContentsAtom);
  const viewOnly = isViewOnlyLink(shareModalState);
  const setSelectedPermission = useSetAtom(updateShareModalPermissionAtom);
  const logEvent = shareModalLoggingInfo.logEvent;
  const setBasicLinkInfo = useSetAtom(basicShareLinksForItemAtom);
  const setWatermarkLinkInfo = useSetAtom(watermarkLinksForItemAtom);
  const sharingIsRestrictedByTeamPolicy = useAtomValue(sharingIsRestrictedByTeamPolicyAtom);

  const text = useShareSettingStrings(shareItem?.name ?? '');

  const {getExistingLinks, updateBasicLink, createShareLink} = useLinkActions({
    logEvent: logEvent,
    linkItemId: shareItem?.id ?? '',
    linkItemType: shareItem?.type ?? 'file',
    versionSummaries: shareItem?.type === 'file' ? shareItem?.versionSummaries : [],
    location: shareModalLoggingInfo.location,
  });

  const [passwordEnabled, setPasswordEnabled] = React.useState(
    currentLinkInfo?.hasPassword ?? false,
  );
  const [password, setPassword] = React.useState('');
  const [downloadsDisabled, setDownloadsDisabled] = React.useState(
    currentLinkInfo?.downloadsDisabled ?? true,
  );
  const [linkDisabled, setLinkDisabled] = React.useState<boolean>(
    currentLinkInfo?.expired ?? false,
  );

  const [loggedOutAccessDisabled, setLoggedOutAccessDisabled] = React.useState<boolean>(
    currentLinkInfo?.loggedOutAccessDisabled ?? false,
  );

  const [viewPreviousCommentsDisabled, setViewPreviousCommentsDisabled] = React.useState<boolean>(
    currentLinkInfo?.viewPreviousCommentsDisabled ?? false,
  );

  const togglePassword = () => {
    setErrorText('');
    setPasswordEnabled((prevState) => !prevState);
  };

  const toggleDownloads = () => {
    setErrorText('');
    setDownloadsDisabled((prevState) => !prevState);
  };

  const toggleLinkDisabled = () => {
    setErrorText('');
    setLinkDisabled((prevState) => !prevState);
  };

  const toggleLoggedOutAccessDisabled = () => {
    setErrorText('');
    setLoggedOutAccessDisabled((prev) => !prev);
  };

  const updatePassword = (updatedPassword: string) => {
    setErrorText('');
    setPassword(updatedPassword);
  };

  const toggleViewPreviousCommentsDisabled = () => {
    setErrorText('');
    setViewPreviousCommentsDisabled((prev) => !prev);
  };

  React.useEffect(() => {
    // When selected permission changes, we should update the displayed settings to reflect the currentLinkInfo
    if (!isSaving) {
      setPasswordEnabled(currentLinkInfo?.hasPassword ?? false);
      setDownloadsDisabled(currentLinkInfo?.downloadsDisabled ?? true);
      setLinkDisabled(currentLinkInfo?.expired ?? false);
      setViewPreviousCommentsDisabled(currentLinkInfo?.viewPreviousCommentsDisabled ?? false);

      //If sharing is restricted by team policy, we must require a Dropbox log in
      const requireLogin =
        sharingIsRestrictedByTeamPolicy || (currentLinkInfo?.loggedOutAccessDisabled ?? false);
      setLoggedOutAccessDisabled(requireLogin);
    }
  }, [currentLinkInfo, isSaving, shareModalState, sharingIsRestrictedByTeamPolicy]);

  React.useEffect(() => {
    if (!passwordEnabled) {
      setPassword('');
    }
  }, [passwordEnabled]);

  const hasOriginalSettings = () => {
    const downloadSettingsMatch = currentLinkInfo?.downloadsDisabled === downloadsDisabled;
    const passwordSettingsMatch =
      currentLinkInfo?.hasPassword === passwordEnabled && password.length === 0;
    const linkDisabledMatch = currentLinkInfo?.expired === linkDisabled;
    const dropboxAccountMatch =
      currentLinkInfo?.loggedOutAccessDisabled === loggedOutAccessDisabled;
    const previousCommentsMatch =
      currentLinkInfo?.viewPreviousCommentsDisabled === viewPreviousCommentsDisabled;
    return (
      downloadSettingsMatch &&
      passwordSettingsMatch &&
      linkDisabledMatch &&
      dropboxAccountMatch &&
      previousCommentsMatch
    );
  };

  if (shareItem === null || shareModalState?.type !== 'basic-link-settings') {
    return null;
  }

  const onSave = async () => {
    if (passwordEnabled && !password.trim()) {
      setErrorText(text.emptyPasswordError);
      return;
    }
    setIsSaving(true);

    try {
      if (currentLinkInfo) {
        // Only update the expiry time if the setting has changed
        const setLinkDisabled = linkDisabled !== currentLinkInfo?.expired;
        await updateBasicLink(
          currentLinkInfo?.shareToken,
          shareItem.type === 'folder',
          viewOnly,
          downloadsDisabled,
          passwordEnabled,
          password,
          loggedOutAccessDisabled,
          viewPreviousCommentsDisabled,
          setLinkDisabled,
          linkDisabled,
        );
      } else {
        await createShareLink({
          isFolder: shareItem.type === 'folder',
          viewOnly,
          downloadsDisabled,
          passwordEnabled,
          password,
          loggedOutAccessDisabled,
          viewPreviousCommentsDisabled,
          versionNumber: shareModalState.selectedVersion,
          disableLink: linkDisabled,
        });
      }
      const refreshedLinks = await getExistingLinks();
      setBasicLinkInfo(refreshedLinks.basicLinks);
      setWatermarkLinkInfo(refreshedLinks.watermarkLinks);
      showShareHome();
    } catch (e) {
      reportException(
        new ReplayError({
          severity: 'non-critical',
          error: e,
          message: 'Failed to update link settings',
          // Label this as uncaught since the error may have been from the API
          // or from preparing the request
          category: ReplayErrorCategory.UncaughtException,
        }),
      );
      setErrorText(text.shareLinkError);
      setIsSaving(false);
    }
  };

  return (
    <>
      <Modal.Header hasBottomSpacing="title-standard">
        <Title size="medium">{text.settingsTitle}</Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs
          onSelection={(value) => {
            if (value === 'view-only' || value === 'review') {
              setSelectedPermission(value);
            }
          }}
          selectedTab={shareModalState?.selectedPermission}
        >
          <Tabs.Group justified>
            <Tabs.Tab id="review" style={{maxWidth: '50%'}}>
              <TabTitleContent>{text.commentTabTitle}</TabTitleContent>
            </Tabs.Tab>
            <Tabs.Tab id="view-only">
              <TabTitleContent>{text.viewTabTitle}</TabTitleContent>
            </Tabs.Tab>
          </Tabs.Group>
          <Tabs.Panel tabId="review">
            <LinkSettings
              canCreateLinks={canCreateLinks}
              currentLinkInfo={currentLinkInfo ?? null}
              downloadSettingProps={{
                downloadsDisabled,
                toggleDownloads,
              }}
              isSaving={isSaving}
              linkDisabled={linkDisabled}
              loggedOutAccessDisabled={loggedOutAccessDisabled}
              passwordEnabled={passwordEnabled}
              toggleLinkDisabled={toggleLinkDisabled}
              toggleLoggedOutAccessDisabled={toggleLoggedOutAccessDisabled}
              togglePassword={togglePassword}
              toggleViewPreviousCommentsDisabled={toggleViewPreviousCommentsDisabled}
              updatePassword={updatePassword}
              viewOnly={viewOnly}
              viewPreviousCommentsDisabled={viewPreviousCommentsDisabled}
            />
          </Tabs.Panel>
          <Tabs.Panel tabId="view-only">
            <LinkSettings
              canCreateLinks={canCreateLinks}
              currentLinkInfo={currentLinkInfo ?? null}
              downloadSettingProps={{
                downloadsDisabled,
                toggleDownloads,
              }}
              isSaving={isSaving}
              linkDisabled={linkDisabled}
              loggedOutAccessDisabled={loggedOutAccessDisabled}
              passwordEnabled={passwordEnabled}
              toggleLinkDisabled={toggleLinkDisabled}
              toggleLoggedOutAccessDisabled={toggleLoggedOutAccessDisabled}
              togglePassword={togglePassword}
              toggleViewPreviousCommentsDisabled={toggleViewPreviousCommentsDisabled}
              updatePassword={updatePassword}
              viewOnly={viewOnly}
              viewPreviousCommentsDisabled={viewPreviousCommentsDisabled}
            />
          </Tabs.Panel>
        </Tabs>
        {errorText && <ModalErrorText>{errorText}</ModalErrorText>}
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            // eslint-disable-next-line deprecation/deprecation
            logEvent('cancel_reel_share_settings', {
              access_type: viewOnly ? 'view_only' : 'review',
              link_item_type: shareModalLoggingInfo.linkItemType,
            });
            showShareHome();
          }}
          size="standard"
          type="button"
          variant="opacity"
        >
          <FormattedMessage
            defaultMessage="Cancel"
            description="Cancel button for the Share Link Settings modal."
            id="PFzhWE"
          />
        </Button>
        <Button disabled={hasOriginalSettings()} onClick={onSave} type="submit" variant="primary">
          {isSaving ? (
            <FormattedMessage {...commonText.savingText} />
          ) : (
            <FormattedMessage
              defaultMessage="Save"
              description="Save button for the Share Link Settings modal."
              id="b36ncv"
            />
          )}
        </Button>
      </Modal.Footer>
    </>
  );
};

type LinkSettingsProps = {
  canCreateLinks: boolean;
  passwordEnabled: boolean;
  isSaving: boolean;
  currentLinkInfo: LinkInfo;
  viewOnly: boolean;
  linkDisabled: boolean;
  loggedOutAccessDisabled: boolean;
  togglePassword: () => void;
  updatePassword: (updatedPassword: string) => void;
  toggleLinkDisabled: () => void;
  toggleLoggedOutAccessDisabled: () => void;
  downloadSettingProps:
    | false
    | {
        downloadsDisabled: boolean;
        toggleDownloads: () => void;
      };
  viewPreviousCommentsDisabled: boolean;
  toggleViewPreviousCommentsDisabled: () => void;
};

export const LinkSettings = (props: LinkSettingsProps) => {
  const {
    canCreateLinks,
    currentLinkInfo,
    downloadSettingProps,
    passwordEnabled,
    linkDisabled,
    loggedOutAccessDisabled,
    togglePassword,
    updatePassword,
    toggleLinkDisabled,
    toggleLoggedOutAccessDisabled,
    isSaving,
    viewOnly,
    viewPreviousCommentsDisabled,
    toggleViewPreviousCommentsDisabled,
  } = props;
  // eslint-disable-next-line deprecation/deprecation
  const {isSmallScreenSize, isMobileDevice} = useViewport();
  const showUpsellModal = !isMobileDevice;
  const provisionsEnabled = useReelProvisioningEnabled();
  const sessionState = useReelAppGlobalState();
  const shareModalLoggingInfo = useAtomValue(getShareModalLoggingInfoAtom);
  const shareItem = useAtomValue(getShareItemAtom);
  const sharingIsRestrictedByTeamPolicy = useAtomValue(sharingIsRestrictedByTeamPolicyAtom);

  const logEvent = shareModalLoggingInfo.logEvent;
  const [showPwRemoveBanner, setShowPwRemoveBanner] = React.useState<boolean>(false);
  const text = useShareSettingStrings(shareItem?.name ?? '');
  const userProvisionedForPasswords =
    !provisionsEnabled ||
    (sessionState.status === 'logged in' && sessionState.provisions.password_protected_links);
  const [upsellModalIsOpen, setUpsellModalIsOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!userProvisionedForPasswords && passwordEnabled) {
      setShowPwRemoveBanner(true);
    }
  }, [userProvisionedForPasswords, passwordEnabled]);

  const userCanSetPassword = userProvisionedForPasswords;
  const userCanRemovePassword = userProvisionedForPasswords || passwordEnabled;
  const passwordControlsDisabled =
    isSaving || !(userCanSetPassword || userCanRemovePassword || showUpsellModal) || linkDisabled;

  return (
    <SettingsWrapper>
      {/* Link Access Setting */}
      <SettingRow>
        <Checkbox
          aria-label={text.enableLinkTitle}
          checked={!linkDisabled}
          disabled={isSaving}
          onChange={() => {
            // eslint-disable-next-line deprecation/deprecation
            logEvent('select_toggle_link', {enabled_state: linkDisabled ? 'off' : 'on'});
            toggleLinkDisabled();
          }}
        />
        <Text>{text.enableLinkTitle}</Text>
      </SettingRow>
      <SettingRow>
        <Checkbox
          aria-label={text.hidePastCommentsTitle}
          checked={viewPreviousCommentsDisabled}
          disabled={isSaving}
          onChange={() => {
            // eslint-disable-next-line deprecation/deprecation
            logEvent('view_previous_versions_disabled', {
              enabled_state: viewPreviousCommentsDisabled ? 'off' : 'on',
            });
            toggleViewPreviousCommentsDisabled();
          }}
        />
        <Text>{text.hidePastCommentsTitle}</Text>
      </SettingRow>
      {/* Download setting */}
      {downloadSettingProps && (
        <SettingRow>
          <Checkbox
            aria-label={text.enableDownloadsToggleTitle}
            checked={!downloadSettingProps.downloadsDisabled}
            disabled={isSaving || linkDisabled}
            onChange={() => {
              // eslint-disable-next-line deprecation/deprecation
              logEvent('select_toggle_downloads_enabled', {
                link_item_type: shareModalLoggingInfo.linkItemType,
                downloads_permission: downloadSettingProps.downloadsDisabled
                  ? 'enabled'
                  : 'disabled', // Opposite because the toggle is being switched
              });
              downloadSettingProps.toggleDownloads();
            }}
          />
          <Text>{text.enableDownloadsToggleTitle}</Text>
        </SettingRow>
      )}
      {/* Password Setting */}
      <SettingRow>
        <Checkbox
          aria-label={text.passwordToggleTitle}
          checked={passwordEnabled}
          disabled={passwordControlsDisabled}
          onChange={() => {
            if (showUpsellModal && !userProvisionedForPasswords) {
              setUpsellModalIsOpen(true);
            } else {
              // eslint-disable-next-line deprecation/deprecation
              logEvent('select_toggle_password_required', {
                access_type: viewOnly ? 'view_only' : 'review',
                link_item_type: shareModalLoggingInfo.linkItemType,
                password_required: !passwordEnabled ? 'enabled' : 'disabled', // Opposite because the toggle is being switched
              });
              togglePassword();
            }
          }}
        />
        <PasswordRowTitle
          logEvent={logEvent}
          passwordToggleTitle={text.passwordToggleTitle}
          showUpsellModal={showUpsellModal}
          userCanRemovePasswords={userCanRemovePassword}
          userCanSetPasswords={userCanSetPassword}
        />
        {showUpsellModal && !userProvisionedForPasswords ? (
          <BasicAddOnUpsellModal
            clickSource="share_modal"
            onClose={() => setUpsellModalIsOpen(false)}
            open={upsellModalIsOpen}
            variant="password_protected_links"
          />
        ) : (
          <PasswordSetting>
            {canCreateLinks && isSmallScreenSize && passwordEnabled && (
              <PasswordControlsV2
                disabled={passwordControlsDisabled}
                hasExistingPassword={currentLinkInfo?.hasPassword ?? false}
                onEdit={() => {
                  // eslint-disable-next-line deprecation/deprecation
                  logEvent('select_edit_password', {
                    access_type: viewOnly ? 'view_only' : 'review',
                    link_item_type: shareModalLoggingInfo.linkItemType,
                  });
                }}
                updatePassword={updatePassword}
                userCanSetPassword={userCanSetPassword}
              />
            )}
            {!isSmallScreenSize && passwordEnabled && (
              <PasswordControlsV2
                disabled={passwordControlsDisabled}
                hasExistingPassword={currentLinkInfo?.hasPassword ?? false}
                onEdit={() => {
                  // eslint-disable-next-line deprecation/deprecation
                  logEvent('select_edit_password', {
                    access_type: viewOnly ? 'view_only' : 'review',
                    link_item_type: shareModalLoggingInfo.linkItemType,
                  });
                }}
                updatePassword={updatePassword}
                userCanSetPassword={userCanSetPassword}
              />
            )}
          </PasswordSetting>
        )}
      </SettingRow>
      {showPwRemoveBanner && <PasswordRemovalBanner />}
      {/* Login Access Setting */}
      {/* If sharing is restricted by a team policy, don't show this setting as "Require Dropbox Login" loses meaning here. They must log in so that we can restrict sharing as needed */}
      {!sharingIsRestrictedByTeamPolicy && shareItem?.type === 'file' && (
        <SettingRow>
          <Checkbox
            aria-label={text.requireDropboxLoginTitle}
            checked={loggedOutAccessDisabled}
            disabled={isSaving || linkDisabled}
            onChange={() => {
              // eslint-disable-next-line deprecation/deprecation
              logEvent('select_toggle_link', {
                enabled_state: loggedOutAccessDisabled ? 'on' : 'off',
              });
              toggleLoggedOutAccessDisabled();
            }}
          />
          <SettingText>
            <Text>{text.requireDropboxLoginTitle}</Text>
          </SettingText>
        </SettingRow>
      )}
    </SettingsWrapper>
  );
};
