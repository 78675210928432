import React from 'react';

import {useIntl} from 'react-intl';
import {useNavigate} from 'react-router';

import {ProgressBar} from '@dropbox/dig-components/progress_indicators';
import {Snackbar} from '@dropbox/dig-components/snackbar';

import {Button} from '~/components/button';
import {ReelSnackbar} from '~/components/snackbar';
import type {Marker, MarkersData} from '~/components/upload_active_sequence_modal';
import {UploadActiveSequence} from '~/components/upload_active_sequence_modal';

import {useExtensionContext} from './extension_context';
import {useReelAppGlobalState} from '../context';
import {redCarpetError, reportBadContextUseError} from '../lib/error_reporting';
import type {RedCarpetModeType} from '../lib/logging/logger_types';
import type {DirectUpload, StreamableFile, UploadFilePickHandler} from '../lib/uploads/types';
import {generateRandomId} from '../lib/utils';
import {CommentsMiddleware} from '../pages/viewer_page/comments_middleware';
import type {
  ReplayComment,
  ReplayThread,
  ReplayUser,
} from '../pages/viewer_page/comments_view_types.d';
import {ReplayThreadType} from '../pages/viewer_page/comments_view_types.d';
import {isInAdobeExtension, RedCarpetMode, SendMessageType} from '../use_extensions';

// there are 254016000000 ticks per second. source: https://ppro-scripting.docsforadobe.dev/other/time.html#time
const TickPerSecond = 254016000000;

export enum RenderState {
  /** Render has not been started */
  Init = 'init',
  /** Render is in progress */
  InProgress = 'inProgress',
  /** Render process is complete*/
  Complete = 'complete',
  /** Render process was cancel */
  Cancel = 'cancel',
  /** Render process is complete with errors */
  Error = 'error',
}

type LinkVideoToAdobeVideoParams = {
  compId?: string; // Used for LinkVideoToComposition in AEFT
  projectId: string;
  videoId: string;
  videoVersionId: string;
  autoSyncComments: boolean;
  markersData?: Pick<MarkersData, 'range'>;
};

export type RenderContext = {
  renderState: RenderState;
  loggingRedCarpetMode: RedCarpetModeType;
  linkVideoToAdobeVideo: (params: LinkVideoToAdobeVideoParams) => void;
  importMarkersAsComments: (
    projectId: string,
    fileId: string,
    videoVersionId: string,
    markersData: MarkersData,
  ) => void;
  openModal: (
    cb: (file: File | StreamableFile, autoSyncComments: boolean, markersData?: MarkersData) => void,
  ) => void;
  onUploadActiveSequence: (params: {
    onUploadFilePick: UploadFilePickHandler;
    loggingClickSource: 'add_files_menu' | 'add_new_version_menu' | 'context_menu';
  }) => void;
  onUploadQueuedCompositions: (params: {
    onUploadFilePick: UploadFilePickHandler;
    loggingClickSource: 'add_files_menu';
  }) => void;
};

type QueuedCompositionData = {
  id: string;
  name: string;
  fileName: string;
  outputFilePath: string;
  range: {
    start: number;
    end: number;
  };
  markers: Marker[];
};

const RenderContext = React.createContext<RenderContext | null>(null);

export const RenderProvider = (props: React.PropsWithChildren<{}>) => {
  const {redCarpet, loggingClient, loadActiveSequenceData, sendMessage} = useExtensionContext();
  const {getClient, createCommentFromMarker} = useCreateCommentsAsyncPPro();
  const {
    renderInProgressMessage,
    renderInProgressErrorMessage,
    dismissSnackbarLabel,
    noItemsInQueueErrorMessage,
    renderQueueDataErrorMessage,
    noRequireErrorMessage,
    duplicateOutputErrorMessage,
  } = useStrings();
  const navigate = useNavigate();
  const [requestCloseSnackbar, setRequestCloseSnackbar] = React.useState(false);
  const [showErrorMessage, setShowErrorMessage] = React.useState(false);
  const [showUploadActiveSequenceModal, setShowUploadActiveSequenceModal] = React.useState(false);
  const [renderState, setRenderState] = React.useState(RenderState.Init);
  const [errorMessage, setErrorMessage] = React.useState<string>();
  const renderSuccessRef = React.useRef<{
    successCallback:
      | undefined
      | ((
          file: StreamableFile | File,
          autoSyncComments: boolean,
          markersData: MarkersData,
        ) => void);
  }>({
    successCallback: undefined,
  });
  let loggingRedCarpetMode: RedCarpetModeType = 'unknown';

  if (redCarpet.mode === RedCarpetMode.PPRO) {
    loggingRedCarpetMode = 'PPRO';
  } else if (redCarpet.mode === RedCarpetMode.AEFT) {
    loggingRedCarpetMode = 'AEFT';
  }

  const onUploadModalCloseCallback = React.useCallback(() => {
    setShowUploadActiveSequenceModal(false);
    setRenderState(RenderState.Cancel);
  }, []);

  const onRenderInProgressCallback = React.useCallback(() => {
    setRenderState(RenderState.InProgress);
    setShowUploadActiveSequenceModal(false);
    setRequestCloseSnackbar(false);
  }, []);

  const openModal = React.useCallback(
    (
      successCallback: (
        file: StreamableFile | File,
        autoSyncComments: boolean,
        markersData: MarkersData,
      ) => void,
    ) => {
      setRenderState((prev) => {
        if (prev === RenderState.InProgress) {
          // TODO: allow to have multiple renders in parallel
          setErrorMessage(renderInProgressErrorMessage);
          setShowErrorMessage(true);
          return prev;
        }

        return RenderState.Init;
      });
      setShowUploadActiveSequenceModal(true);
      renderSuccessRef.current.successCallback = successCallback;
    },
    [renderInProgressErrorMessage],
  );

  const onRenderComplete = React.useCallback(
    (file: StreamableFile | File, autoSyncComments: boolean, markersData: MarkersData) => {
      setShowUploadActiveSequenceModal(false);
      setRenderState(RenderState.Complete);
      renderSuccessRef.current.successCallback?.(file, autoSyncComments, markersData);
    },
    [],
  );

  const closeSnackbarCallback = React.useCallback(() => {
    setRequestCloseSnackbar(true);
  }, []);

  const closeErrorSnackbarCallback = React.useCallback(() => {
    setShowErrorMessage(false);
  }, []);

  const onErrorCallback = React.useCallback((errorMessage: string) => {
    setErrorMessage(errorMessage);
    setShowErrorMessage(true);
    setRenderState(RenderState.Error);
    redCarpetError(new Error(errorMessage));
  }, []);

  const onRenderCanceled = React.useCallback(() => {
    setRenderState(RenderState.Cancel);
  }, []);

  const linkVideoToAdobeVideo = React.useCallback(
    ({
      compId,
      projectId,
      videoId,
      videoVersionId,
      autoSyncComments,
      markersData,
    }: LinkVideoToAdobeVideoParams) => {
      let message: SendMessageType;

      if (redCarpet.mode === RedCarpetMode.PPRO) {
        message = SendMessageType.LinkVideoToActiveSequence;
      } else if (redCarpet.mode === RedCarpetMode.AEFT) {
        if (redCarpet.features.linkVideoToComposition) {
          message = SendMessageType.LinkVideoToComposition;
        } else {
          message = SendMessageType.LinkVideoToActiveSequence;
        }
      } else {
        throw new Error('Unsupported app');
      }

      sendMessage?.(message, {
        compId: compId || '', // Used for LinkVideoToComposition in AEFT
        projectId,
        videoId,
        versionId: videoVersionId,
        autoSyncComments: autoSyncComments,
        rangeIn: markersData && markersData.range ? markersData.range.start : undefined,
        rangeOut: markersData && markersData.range ? markersData.range.end : undefined,
      });
      loadActiveSequenceData();
    },
    [
      redCarpet.mode,
      redCarpet.features.linkVideoToComposition,
      sendMessage,
      loadActiveSequenceData,
    ],
  );

  const importMarkersAsComments = React.useCallback(
    (projectId: string, videoId: string, videoVersionId: string, markersData: MarkersData) => {
      if (isInAdobeExtension() && markersData.importMarkers && markersData.markers) {
        const client = getClient(videoVersionId);
        markersData.markers
          .filter((marker) => {
            return (
              marker.start &&
              markersData.range &&
              marker.start.seconds >= markersData.range.start &&
              marker.start.seconds <= markersData.range.end
            );
          })
          .forEach((marker) => {
            // calculate new marker ticks based on range selected
            // there are 254016000000 ticks per second. source: https://ppro-scripting.docsforadobe.dev/other/time.html#time
            const startTicks =
              (marker.start.seconds - (markersData.range.start ?? 0)) * TickPerSecond;
            const endSeconds =
              marker.end.seconds > markersData.range.end
                ? markersData.range.end
                : marker.end.seconds;
            const endTicks = (endSeconds - marker.start.seconds) * TickPerSecond;
            const newMarker = {
              ...marker,
              start: {
                ...marker.start,
                ticks: startTicks,
              },
              end: {
                seconds: endSeconds,
                ticks: endTicks,
              },
            };

            createCommentFromMarker(client, newMarker);
          });
      }
    },
    [getClient, createCommentFromMarker],
  );

  const onUploadActiveSequence: RenderContext['onUploadActiveSequence'] = React.useCallback(
    ({onUploadFilePick, loggingClickSource}) => {
      if (redCarpet.mode === RedCarpetMode.PPRO) {
        // eslint-disable-next-line deprecation/deprecation
        loggingClient.logEvent('select_upload_active_sequence', {
          click_source: loggingClickSource,
        });
      } else if (redCarpet.mode === RedCarpetMode.AEFT) {
        // eslint-disable-next-line deprecation/deprecation
        loggingClient.logEvent('select_upload_active_composition', {
          click_source: loggingClickSource,
        });
      }
      openModal((file, autoSyncComments, markersData) => {
        if (file) {
          onUploadFilePick([file] as File[] | StreamableFile[], {
            onFileUploadCompleteCallback: (
              upload: DirectUpload,
              fileId: string,
              videoVersionId: string,
            ) => {
              if (upload.projectId && upload.videoId && videoVersionId) {
                linkVideoToAdobeVideo({
                  projectId: upload.projectId,
                  videoId: upload.videoId,
                  videoVersionId,
                  autoSyncComments,
                  markersData,
                });

                importMarkersAsComments(
                  upload.projectId,
                  upload.videoId,
                  videoVersionId,
                  markersData,
                );

                if (redCarpet.mode === RedCarpetMode.PPRO) {
                  // eslint-disable-next-line deprecation/deprecation
                  loggingClient.logEvent('upload_active_sequence_complete');
                } else if (redCarpet.mode === RedCarpetMode.AEFT) {
                  // eslint-disable-next-line deprecation/deprecation
                  loggingClient.logEvent('upload_active_composition_complete');
                }

                navigate(`/project/${upload.projectId}/video/${upload.videoId}`);
              }
            },
          });
        }
      });
    },
    [
      redCarpet.mode,
      openModal,
      loggingClient,
      linkVideoToAdobeVideo,
      importMarkersAsComments,
      navigate,
    ],
  );

  const onUploadQueuedCompositions: RenderContext['onUploadQueuedCompositions'] = React.useCallback(
    async ({onUploadFilePick, loggingClickSource}) => {
      if (redCarpet.mode === RedCarpetMode.AEFT) {
        // eslint-disable-next-line deprecation/deprecation
        loggingClient.logEvent('select_upload_queued_compositions', {
          click_source: loggingClickSource,
        });
      }

      setRequestCloseSnackbar(false);
      setRenderState(RenderState.InProgress);

      if (!window.require) {
        onErrorCallback(noRequireErrorMessage);
        return;
      } else if (renderState === RenderState.InProgress) {
        // TODO: allow to have multiple renders in pararel
        onErrorCallback(renderInProgressErrorMessage);
        return;
      }

      // Check that there are items in the queue to render
      const {hasItems} = await sendMessage?.(SendMessageType.HasQueuedCompositions);

      if (!hasItems) {
        onErrorCallback(noItemsInQueueErrorMessage);
        return;
      }

      const response = await sendMessage?.(SendMessageType.UploadQueuedCompositions);

      if (response.error) {
        onErrorCallback(renderQueueDataErrorMessage);
        return;
      }

      const data = JSON.parse(response.data);

      if (data.error) {
        onErrorCallback(duplicateOutputErrorMessage);
        return;
      } else if (data.compositions.length === 0) {
        onErrorCallback(noItemsInQueueErrorMessage);
        return;
      }

      const fs = window.require('fs');
      const compositions = data.compositions as QueuedCompositionData[];
      const fileNameToComp = compositions.reduce(
        (acc: {[fileName: string]: QueuedCompositionData}, comp) => ({
          ...acc,
          [comp.fileName]: comp,
        }),
        {},
      );
      const files = await Promise.all(
        compositions.map((comp: QueuedCompositionData) => {
          return new Promise<File>((resolve, reject) => {
            fs.readFile(comp.outputFilePath, (err: Error, data: Buffer) => {
              if (err) {
                console.error(err);
                reject(null);
              } else {
                resolve(new File([data], comp.fileName));
              }
            });
          });
        }),
      );

      setRenderState(RenderState.Complete);

      onUploadFilePick(files, {
        onAllUploadsCompleteCallback: () => {
          if (redCarpet.mode === RedCarpetMode.AEFT) {
            // eslint-disable-next-line deprecation/deprecation
            loggingClient.logEvent('upload_queued_compositions_complete');
          }
        },
        onFileUploadCompleteCallback: (
          upload: DirectUpload,
          fileId: string,
          videoVersionId: string,
        ) => {
          if (upload.projectId && upload.videoId && videoVersionId) {
            const comp = fileNameToComp[upload.file.name];

            linkVideoToAdobeVideo({
              compId: comp.id,
              projectId: upload.projectId,
              videoId: upload.videoId,
              videoVersionId,
              autoSyncComments: true,
              markersData: {
                range: {
                  start: comp.range.start,
                  end: comp.range.end,
                },
              },
            });

            importMarkersAsComments(upload.projectId, upload.videoId, videoVersionId, {
              importMarkers: true,
              range: {
                start: comp.range.start,
                end: comp.range.end,
              },
              rangeType: 'ENCODE_WORKAREA',
              markers: comp.markers,
            });
          }
        },
      });
    },
    [
      redCarpet.mode,
      renderState,
      sendMessage,
      loggingClient,
      onErrorCallback,
      noRequireErrorMessage,
      renderInProgressErrorMessage,
      noItemsInQueueErrorMessage,
      renderQueueDataErrorMessage,
      duplicateOutputErrorMessage,
      linkVideoToAdobeVideo,
      importMarkersAsComments,
    ],
  );

  const value: RenderContext = React.useMemo(
    () => ({
      renderState,
      openModal,
      loggingRedCarpetMode,
      linkVideoToAdobeVideo,
      importMarkersAsComments,
      onUploadActiveSequence,
      onUploadQueuedCompositions,
    }),
    [
      renderState,
      openModal,
      loggingRedCarpetMode,
      linkVideoToAdobeVideo,
      importMarkersAsComments,
      onUploadActiveSequence,
      onUploadQueuedCompositions,
    ],
  );

  return (
    <RenderContext.Provider value={value}>
      {props.children}
      {isInAdobeExtension() && (
        <>
          <UploadActiveSequence
            onError={onErrorCallback}
            onRenderCanceled={onRenderCanceled}
            onRenderInProgress={onRenderInProgressCallback}
            onUpload={onRenderComplete}
            open={showUploadActiveSequenceModal}
            requestClose={onUploadModalCloseCallback}
          ></UploadActiveSequence>
          <ReelSnackbar
            open={renderState === RenderState.InProgress && !requestCloseSnackbar}
            timeout={0}
          >
            <Snackbar.Message>
              {' '}
              {renderInProgressMessage}
              <ProgressBar in={true} isIndeterminate />
            </Snackbar.Message>
            <Snackbar.Actions>
              <Button inverse onClick={closeSnackbarCallback} variant="transparent">
                {dismissSnackbarLabel}
              </Button>
            </Snackbar.Actions>
          </ReelSnackbar>
          <ReelSnackbar onRequestClose={closeErrorSnackbarCallback} open={showErrorMessage}>
            <Snackbar.Message>{errorMessage}</Snackbar.Message>
            <Snackbar.Actions>
              <Button inverse onClick={closeErrorSnackbarCallback} variant="transparent">
                {dismissSnackbarLabel}
              </Button>
            </Snackbar.Actions>
          </ReelSnackbar>
        </>
      )}
    </RenderContext.Provider>
  );
};

export const useRenderContext = () => {
  const renderContext = React.useContext(RenderContext);

  if (renderContext === null) {
    const error = new Error('useRenderContext must be used within a RenderProvider');
    reportBadContextUseError(error);
    throw error;
  }

  return renderContext;
};

const useStrings = () => {
  const intl = useIntl();
  return {
    renderInProgressMessage: intl.formatMessage({
      defaultMessage: 'Render is in progress...',
      id: 'oSOOTC',
      description: 'snackbar message for a render in progress in replay',
    }),
    renderInProgressErrorMessage: intl.formatMessage({
      defaultMessage: "A render is already in progress, try again when it's completed",
      id: 'x9xGtI',
      description: 'snackbar error message for a render already in progress in replay',
    }),
    dismissSnackbarLabel: intl.formatMessage({
      defaultMessage: 'Dismiss',
      id: 'MN8zAY',
      description: 'Label for snackbar dismiss button',
    }),
    noItemsInQueueErrorMessage: intl.formatMessage({
      defaultMessage:
        'No queued compositions. Please queue at least one item in the render queue and check that they have an output set and try again',
      id: '+VgqnX',
      description: 'snackbar error message when attempting to render with no items in the queue',
    }),
    renderQueueDataErrorMessage: intl.formatMessage({
      defaultMessage: 'Error getting data from render queue, please reload After Effects',
      id: 'VH0T3u',
      description: 'snackbar error message when getting render queue data from after effects',
    }),
    noRequireErrorMessage: intl.formatMessage({
      defaultMessage: 'Error reading files, please reload After Effects',
      id: '9RzyqJ',
      description: 'snackbar error message when the "require" object does not exist',
    }),
    duplicateOutputErrorMessage: intl.formatMessage({
      defaultMessage:
        'There are items in the render queue that have the same output location and file name. Please choose a different location, name, or remove the conflicting item from the queue and try again.',
      id: 'jkeqXA',
      description:
        'snackbar error message when there are duplicate output file paths in the render queue',
    }),
  };
};

export const useCreateCommentsAsyncPPro = () => {
  const sessionState = useReelAppGlobalState();

  const currentReplayUser = React.useMemo(() => {
    let replayUser: ReplayUser | undefined;
    if (sessionState.status === 'logged in') {
      const loggedInUser = sessionState.currentAccount;
      replayUser = {
        id: loggedInUser.account_id,
        displayName: loggedInUser.name.display_name,
        initials: loggedInUser.name.abbreviated_name,
        photoURL: loggedInUser.photo_url || '',
        isCurrentUser: true,
      };
    }
    return replayUser;
  }, [sessionState]);

  const getClient = React.useCallback(
    (videoVersionId: string) => {
      const middlewareOptions = {
        sessionStatus: sessionState.status,
        videoVersionId: videoVersionId,
      };
      return new CommentsMiddleware(middlewareOptions);
    },
    [sessionState],
  );

  const createCommentFromMarker = React.useCallback(
    (client: CommentsMiddleware, marker: Marker) => {
      if (!currentReplayUser) {
        return;
      }
      const pendingThreadId = `pending-${generateRandomId()}`;
      const markerOutTimeSecs =
        marker.end.ticks !== marker.start.ticks ? marker.end.seconds : undefined;
      const pendingComment: ReplayComment = {
        id: `pending-${generateRandomId()}`,
        author: {
          id: currentReplayUser.id,
          displayName: currentReplayUser.displayName,
          initials: currentReplayUser.initials,
          photoURL: currentReplayUser.photoURL,
          isCurrentUser: currentReplayUser.isCurrentUser,
        },
        timestamp: new Date(),
        content: marker.comments,
        isDeleted: false,
        drawingJSON: undefined,
        timeRange: {in: marker.start.seconds, out: markerOutTimeSecs},
        threadID: pendingThreadId,
        isThreadResolved: false,
        mentions: [],
      };

      const pendingThread: ReplayThread | null = {
        id: pendingThreadId,
        author: pendingComment.author,
        comments: [pendingComment],
        isRead: true,
        isReadOnly: false,
        isResolved: false,
        drawingJSON: undefined,
        timeRange: {in: marker.start.seconds, out: marker.end.seconds},
        type: ReplayThreadType.FRAME_LEVEL,
        isNew: true,
        isQuickReaction: false,
        isPinned: false,
        isPrivate: false,
        versionNum: 1,
      };

      client.createThreadRequest(pendingThread);
    },
    [currentReplayUser],
  );

  return {
    createCommentFromMarker,
    getClient,
  };
};
