import {atom} from 'jotai';

import type {Task} from '~/lib/api';

export type AsyncTaskState =
  | {isLoading: true}
  | {isLoading: false; videoId: string; task: Task | null};

// Task state is shared between the share modal and the video viewer, so using a jotai atom to store it
export const currentTaskStateAtom = atom<AsyncTaskState>({isLoading: true});

// Write-only atom that clears the current task state atom
export const clearTaskStateAtom = atom(null, (get, set, videoId: string) => {
  set(currentTaskStateAtom, {isLoading: false, videoId: videoId, task: null});
});

// Write-only atom that sets the task state atom to be loading
export const loadingTaskStateAtom = atom(null, (get, set) => {
  set(currentTaskStateAtom, {isLoading: true});
});
