import React from 'react';

import {useAtomValue} from 'jotai';
import {FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';

import {Text} from '@dropbox/dig-components/typography';
import {UIIcon} from '@dropbox/dig-icons';
import {FolderLine, PersonCircleLine} from '@dropbox/dig-icons/assets';

import {FolderContextMenu} from '~/components/context_menu';
import {ReelLink} from '~/components/link';
import {
  CommentsInfoCol,
  CreatedTimeCol,
  OverflowMenuCol,
  PrimaryCol,
  StatusCol,
  UpdatedTimeCol,
  UsageCol,
} from '~/components/list-table';
import {useReelAppGlobalState} from '~/context';
import {StormcrowIsOn} from '~/lib/stormcrow';
import {getTimeSinceForDisplay} from '~/lib/time';
import {formatBytes} from '~/lib/utils';
import {browseFiltersAtom} from '~/state/browse';

import {BrowseTeamInfoType} from './common';
import type {BrowseTeamInfoItem, FolderItem} from './common';
import {useBrowseItemsViewContext} from '../browse_page/browse_items_view_context';
import {
  FolderInfoWrapper,
  IconAndTextWrapper,
  IconWrapper,
  TextStyled,
  TextsWrapper,
} from '../browse_page/components/project_list_view';
import {adminFolderShouldRenderBasedOnFilters} from '../browse_page/filter_utils';

const RowContainer = styled.div<{$opacity?: number; $isHovered: boolean}>`
  ${({$opacity}) => ($opacity ? `opacity: ${$opacity};` : '')}
  align-items: center;
  box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.12);
  display: flex;
  height: 64px;
  background: ${({$isHovered}) => ($isHovered ? '#242121' : 'transparent')};
`;

const CheckboxCol = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex: 0 0 35px;
  height: 64px;
  white-space: nowrap;
`;

export type ListTeamInfoRendererProps = {
  teamInfoItem: BrowseTeamInfoItem;
};

function noop() {}

export const ListTeamInfoRenderer = React.memo((props: ListTeamInfoRendererProps) => {
  const {teamInfoItem} = props;

  const intl = useIntl();
  const {timeAgoLocale} = useReelAppGlobalState();
  const isReplayQuotaCountingEnabled = StormcrowIsOn.useReplayFssQuotaCounting();
  const {editActionsProps, onFoldersDelete} = useBrowseItemsViewContext();
  const {folderName, folderCount, type, updatedTime, createdTime, teamQuotaUsage} = teamInfoItem;
  const [isHovered, setIsHovered] = React.useState(false);
  const filters = useAtomValue(browseFiltersAtom);
  const visible = adminFolderShouldRenderBasedOnFilters(filters, teamInfoItem.type);

  const folderCountMessage = (
    <FormattedMessage
      defaultMessage="{folderCount} {folderCount, plural, one {file} other {files}}"
      description="Text for how many projects are in the folder / user namespace."
      id="5+Zmji"
      values={{folderCount: folderCount}}
    />
  );

  const isProject = type === BrowseTeamInfoType.TeamProject;

  const renderFolderActions = (folder: FolderItem) => {
    const onFolderDelete = () => {
      onFoldersDelete([folder.rawFolder.id]);
    };

    const onFolderRename = (folderName: string) => {
      if (editActionsProps.editActionsEnabled) {
        editActionsProps.onFolderRename(folder.rawFolder.id, folderName);
      }
    };

    const openAddPeopleModal = (folderId: string, folderName: string) => {
      if (editActionsProps.editActionsEnabled) {
        editActionsProps.openAddPeopleModal(folderId, folderName);
      }
    };

    const openManagePeopleModal = (folderId: string, folderName: string) => {
      if (editActionsProps.editActionsEnabled) {
        editActionsProps.openManagePeopleModal(folderId, folderName);
      }
    };

    return (
      <FolderContextMenu
        accessLevel="super_admin"
        allowSuperAdminRights={true}
        folderId={folder.rawFolder.id}
        hideLeaveProjectOption={true}
        hideShareOption={true}
        isTeamProject={isProject}
        name={folder.folderName}
        onDelete={onFolderDelete}
        onRename={onFolderRename}
        openAddPeopleModal={openAddPeopleModal}
        openManagePeopleModal={openManagePeopleModal}
        updateModifyItemSnackbar={noop}
      />
    );
  };

  let reelLinkTo: string | undefined;
  if (isProject && teamInfoItem.rawFolder) {
    reelLinkTo = `/folder/${teamInfoItem.rawFolder.id}`;
  } else if (!isProject && teamInfoItem.userAccountId) {
    reelLinkTo = `/admin/user/${teamInfoItem.userPublicId}`;
  } else {
    reelLinkTo = '/admin';
  }

  /**
   * See here for what we're going for:
   * https://www.figma.com/file/6BbaH68gsyh8WHsJE2Y9eQ/Counting-files-against-FSS-storage?type=design&node-id=2067-36790&mode=design&t=53RM3pKYEX2tQJoO-0
   *
   * We want:
   * - Name
   * - Type
   * - Usage
   * - Updated
   * - Created
   * - Owner (if applicable)
   */

  return (
    <div style={{display: visible ? 'block' : 'none'}}>
      <ReelLink
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        to={reelLinkTo}
      >
        <RowContainer $isHovered={isHovered}>
          {/* Checkbox col is empty, but we want to match the browse / share page */}
          <CheckboxCol />
          {/* Name Column */}
          <PrimaryCol>
            <FolderInfoWrapper>
              <TextsWrapper>
                <TextStyled isBold tagName="p">
                  {folderName}
                </TextStyled>
                <TextStyled color="faint" size="small" tagName="span">
                  {folderCountMessage}
                </TextStyled>
              </TextsWrapper>
            </FolderInfoWrapper>
          </PrimaryCol>

          {/* Empty status col -> replaced with Type column */}
          <StatusCol>
            {isReplayQuotaCountingEnabled && (
              <IconAndTextWrapper style={{alignItems: 'center'}}>
                <IconWrapper style={{marginTop: '2px'}}>
                  <UIIcon
                    size="standard"
                    src={type === BrowseTeamInfoType.TeamProject ? FolderLine : PersonCircleLine}
                  />
                </IconWrapper>
                <TextsWrapper>
                  <Text>
                    {type === BrowseTeamInfoType.TeamProject ? (
                      <FormattedMessage
                        defaultMessage="Team"
                        description="Text if the type of entity is a team folder"
                        id="2JnQv+"
                      />
                    ) : (
                      <FormattedMessage
                        defaultMessage="Member"
                        description="Text if the type of entity is a team member"
                        id="wjWNQP"
                      />
                    )}
                  </Text>
                </TextsWrapper>
              </IconAndTextWrapper>
            )}
          </StatusCol>

          {/* Empty status col -> replaced with Type column */}
          {isReplayQuotaCountingEnabled && (
            <UsageCol>
              {/* For some reason, doing teamQuotaUsage && teamQuotaUsage !== 0 was still rendering a 0 */}
              {teamQuotaUsage !== null && teamQuotaUsage !== undefined && teamQuotaUsage !== 0 && (
                <Text>{formatBytes(teamQuotaUsage, intl)}</Text>
              )}
            </UsageCol>
          )}

          {/* Updated Time Column */}
          <UpdatedTimeCol>
            <Text>{updatedTime && getTimeSinceForDisplay(updatedTime, intl, timeAgoLocale)}</Text>
          </UpdatedTimeCol>

          {/* Creation Time Column */}
          <CreatedTimeCol>
            <Text>{createdTime && getTimeSinceForDisplay(createdTime, intl, timeAgoLocale)}</Text>
          </CreatedTimeCol>

          {/* Empty comments info col -> replaced with Owner at root */}
          <CommentsInfoCol>
            {teamInfoItem.type === BrowseTeamInfoType.TeamProject &&
              teamInfoItem.folderOwner &&
              isReplayQuotaCountingEnabled && <Text>{teamInfoItem.folderOwner}</Text>}
          </CommentsInfoCol>

          {/* Admin Actions Column */}
          {/* Override the flex-start so that we have some more room */}
          <OverflowMenuCol style={{justifyContent: 'flex-start'}}>
            {isProject && teamInfoItem.rawFolder && renderFolderActions(teamInfoItem)}
          </OverflowMenuCol>
        </RowContainer>
      </ReelLink>
    </div>
  );
});
