import React from 'react';

import {useAtomValue, useSetAtom} from 'jotai';
import {FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';

import {Spinner} from '@dropbox/dig-components/progress_indicators';
import {Snackbar} from '@dropbox/dig-components/snackbar';
import {Text} from '@dropbox/dig-components/typography';
import {UIIcon} from '@dropbox/dig-icons';
import {CheckmarkCircleLine, FailLine, WarningLine} from '@dropbox/dig-icons/assets';

import {Button} from '~/components/button';
import {clearCopyRequestAtom, copyRequestAtom} from '~/components/share_modal/share_state';
import {ReelSnackbar, SnackbarCloseMessage} from '~/components/snackbar';

import {getRestrictedContentErrorTextFromType} from '../restricted_content_messages';

const SnackbarTextContainer = styled.div`
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  column-gap: 5px;
  word-break: break-word;
`;

const SnackbarContentContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  align-items: center;
`;

type ConfirmationSnackbarContentProps = {
  link: string;
  onRequestClose: () => void;
};

type CopyErrorSnackbarContentProps = {
  link: string;
  onRequestClose: () => void;
};

type ErrorSnackbarContentProps = {
  type:
    | 'error'
    | 'irrecoverable_error'
    | 'sharing_is_restricted'
    | 'dmca_requester'
    | 'email_not_verified';
  onRequestClose: () => void;
  dmca_requester: string | undefined;
};

type SnackbarContentWithLinkProps = {
  link: string;
};

const InProgressSnackbarContent = () => {
  return (
    <>
      <Spinner size="small" />
      <Snackbar.Message>
        <FormattedMessage
          defaultMessage="Getting the link..."
          description="Snackbar message when the user is trying to copy a share link but the link is still loading"
          id="j2y3H+"
        />
      </Snackbar.Message>
    </>
  );
};

const SnackbarContentWithLink = (props: React.PropsWithChildren<SnackbarContentWithLinkProps>) => {
  const {children, link} = props;

  const selectNode = React.useCallback((el: HTMLElement | null) => {
    if (el) {
      const range = document.createRange();
      range.selectNodeContents(el);
      const selection = window.getSelection();
      if (selection) {
        selection.removeAllRanges();
        selection.addRange(range);
      }
    }
  }, []);

  return (
    <>
      <SnackbarTextContainer>
        <Text inverse>{children}</Text>
        <Text inverse ref={selectNode} size="small">
          {link}
        </Text>
      </SnackbarTextContainer>
    </>
  );
};

const ConfirmationSnackbarContent = (props: ConfirmationSnackbarContentProps) => {
  const {link, onRequestClose} = props;

  return (
    <SnackbarContentContainer>
      <UIIcon src={CheckmarkCircleLine} />
      <Snackbar.Message>
        <SnackbarContentWithLink link={link}>
          <FormattedMessage
            defaultMessage="Copied to clipboard."
            description="Snackbar message informing the user that the chosen text has been copied to the clipboard."
            id="HiO4uH"
          />
        </SnackbarContentWithLink>
      </Snackbar.Message>
      <Snackbar.Actions>
        <Button inverse onClick={onRequestClose} variant="transparent">
          <SnackbarCloseMessage />
        </Button>
      </Snackbar.Actions>
    </SnackbarContentContainer>
  );
};

const CopyErrorSnackbarContent = (props: CopyErrorSnackbarContentProps) => {
  const {link, onRequestClose} = props;

  return (
    <SnackbarContentContainer>
      <UIIcon src={WarningLine} />
      <Snackbar.Message>
        <SnackbarContentWithLink link={link}>
          <FormattedMessage
            defaultMessage="Couldn’t copy the link. Try again."
            description="Text for snackbar when the user tries to copy a share link and fetching the link succeeds but the copy action fails"
            id="tar3j0"
          />
        </SnackbarContentWithLink>
      </Snackbar.Message>
      <Snackbar.Actions>
        <Button inverse onClick={onRequestClose} variant="transparent">
          <SnackbarCloseMessage />
        </Button>
      </Snackbar.Actions>
    </SnackbarContentContainer>
  );
};

const ErrorSnackbarContent = (props: ErrorSnackbarContentProps) => {
  const {type, onRequestClose, dmca_requester} = props;
  const intl = useIntl();

  const retryableMsg = intl.formatMessage({
    defaultMessage: 'Couldn’t get the link. You may want to try again.',
    id: 'aSaWg+',
    description: 'Snackbar message when the user tries to copy a share link and it fails',
  });

  let msg = retryableMsg;
  if (type === 'irrecoverable_error') {
    const irrecoverableMsg = intl.formatMessage({
      defaultMessage:
        'Couldn’t get the link. This file’s sharing settings in Dropbox may not be compatible with Replay.',
      id: 'BZHc3q',
      description:
        "Snackbar message when the user tries to copy a share link and it fails, and we know it won't work to just try again",
    });
    msg = irrecoverableMsg;
  } else if (
    type === 'sharing_is_restricted' ||
    type === 'dmca_requester' ||
    type === 'email_not_verified'
  ) {
    msg = getRestrictedContentErrorTextFromType(intl, type, dmca_requester);
  }

  return (
    <>
      <UIIcon src={FailLine} />
      <Snackbar.Message>{msg}</Snackbar.Message>
      <Snackbar.Actions>
        <Button inverse onClick={onRequestClose} variant="transparent">
          <SnackbarCloseMessage />
        </Button>
      </Snackbar.Actions>
    </>
  );
};

export const CopyLinkSnackbar = () => {
  const copyRequestState = useAtomValue(copyRequestAtom);
  const request = copyRequestState.request;
  const handleSnackbarClose = useSetAtom(clearCopyRequestAtom);

  return (
    <ReelSnackbar open={request.type !== 'none'} timeout={0}>
      {request.type === 'success' ? (
        <ConfirmationSnackbarContent link={request.result} onRequestClose={handleSnackbarClose} />
      ) : request.type === 'copy_error' ? (
        <CopyErrorSnackbarContent link={request.result} onRequestClose={handleSnackbarClose} />
      ) : request.type === 'error' ||
        request.type === 'irrecoverable_error' ||
        request.type === 'sharing_is_restricted' ||
        request.type === 'dmca_requester' ||
        request.type === 'email_not_verified' ? (
        <ErrorSnackbarContent
          dmca_requester={request.type === 'dmca_requester' ? request.dmca_requester : undefined}
          onRequestClose={handleSnackbarClose}
          type={request.type}
        />
      ) : request.type === 'in_progress' ? (
        <InProgressSnackbarContent />
      ) : null}
    </ReelSnackbar>
  );
};
