import {Select} from '~/components/form/text_fields';
import {useWatermarkSettingsText} from '~/components/share_modal/share_modal_text';

export const WatermarkPositions = [
  'center',
  'top',
  'bottom',
  'top-left',
  'top-right',
  'bottom-left',
  'bottom-right',
];

export type WatermarkPositionsType = (typeof WatermarkPositions)[number];

export const PositionSelector = () => {
  const watermarkSettingsText = useWatermarkSettingsText();

  return (
    <Select id="position" name="position">
      <Select.Option value="top">{watermarkSettingsText.topText}</Select.Option>
      <Select.Option value="center">{watermarkSettingsText.centeredText}</Select.Option>
      <Select.Option value="bottom">{watermarkSettingsText.bottomText}</Select.Option>
      <Select.Option value="top-left">{watermarkSettingsText.topLeftText}</Select.Option>
      <Select.Option value="top-right">{watermarkSettingsText.topRightText}</Select.Option>
      <Select.Option value="bottom-left">{watermarkSettingsText.bottomLeftText}</Select.Option>
      <Select.Option value="bottom-right">{watermarkSettingsText.bottomRightText}</Select.Option>
    </Select>
  );
};
