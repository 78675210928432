import exportFromJSON from 'export-from-json';
import type {IntlShape} from 'react-intl';

import type {MediaProp} from './api';
import {convertToTimeFormat, isFractionalFrameRate, TimeDisplayFormat} from './time';
import {generateRandomId} from './utils';
import type {
  ReplayComment,
  ReplayThread,
  ReplayUser,
} from '../pages/viewer_page/comments_view_types.d';

export const commentExportFormats = ['json', 'csv', 'xml', 'fcpxml', 'txt', 'avid-txt'] as const;
export type CommentsExportType = (typeof commentExportFormats)[number];

export type FlattenedComment = Omit<ReplayComment, 'isThreadResolved' | 'author' | 'mentions'> & {
  author: Omit<ReplayUser, 'isCurrentUser'>;
  isResolved: boolean;
  hasAnnotation: boolean;
  timecode: string | undefined;
  timecodeOut: string | undefined;
};

export const flattenComments = (
  threads: ReplayThread[],
  metadata: MediaProp,
  timeDisplayFormat: TimeDisplayFormat,
  timecodeSecondsOffset?: number,
) => {
  return threads.flatMap((thread) => {
    return thread.comments.map((comment) => {
      const {isThreadResolved, author, mentions, ...restOfComment} = comment;
      const {isCurrentUser, ...restOfAuthor} = author;
      return {
        ...restOfComment,
        author: restOfAuthor,
        isResolved: isThreadResolved,
        hasAnnotation: thread.drawingJSON !== undefined,
        timecode: thread.timeRange
          ? convertToTimeFormat(
              thread.timeRange.in,
              metadata.mediaMetadata.frameRate,
              timeDisplayFormat,
              timecodeSecondsOffset,
            )
          : undefined,
        timecodeOut: thread.timeRange?.out
          ? convertToTimeFormat(
              thread.timeRange.out,
              metadata.mediaMetadata.frameRate,
              timeDisplayFormat,
              timecodeSecondsOffset,
            )
          : undefined,
      };
    });
  });
};

export function floatToFraction(float: number): [number, number] {
  // convert float to fraction
  const tolerance = 1.0e-8;
  let h1 = 1;
  let h2 = 0;
  let k1 = 0;
  let k2 = 1;
  let b = float;
  do {
    const a = Math.floor(b);
    let aux = h1;
    h1 = a * h1 + h2;
    h2 = aux;
    aux = k1;
    k1 = a * k1 + k2;
    k2 = aux;
    b = 1 / (b - a);
  } while (Math.abs(h1 / k1 - float) > tolerance);

  // Make sure denominator is 1000 or greater
  while (k1 < 1000) {
    h1 *= 10;
    k1 *= 10;
  }

  return [h1, k1];
}

export const formatThreadForFCP = (
  thread: ReplayThread,
  frameRateNumerator: number,
  frameRateDenominator: number,
) => {
  const timeIn = thread.timeRange?.in ?? 0.0;
  const [timeInNumerator, timeInDenominator] = floatToFraction(timeIn);
  const [durationNumerator, durationDenominator] = thread.timeRange?.out
    ? floatToFraction(thread.timeRange.out - timeIn)
    : [frameRateNumerator, frameRateDenominator];

  const content = thread.comments
    .map((comment) => {
      return comment.author.displayName + ': ' + comment.content;
    })
    .join('. ');

  const inp = document.createElement('marker');
  inp.setAttribute('start', `${timeInNumerator}/${timeInDenominator}s`);
  inp.setAttribute('duration', `${durationNumerator}/${durationDenominator}s`);
  inp.setAttribute('value', thread.author.displayName);
  inp.setAttribute('completed', '0');
  inp.setAttribute('note', content);
  const serializer = new XMLSerializer();
  const out = serializer.serializeToString(inp);

  // XML serializer will include a namespace in the output as it assumes we are
  // outputting an entire xml document. Until we use an XML serializer for the
  // entire FCP output, we make do by only using it for comments to ensure
  // comments are escaped.
  return out.replace('xmlns="http://www.w3.org/1999/xhtml" ', '');
};

export const formatForFCP = (metadata: MediaProp, threads: ReplayThread[]) => {
  const uidString = generateRandomId();

  const [durationNumerator, durationDenominator] = floatToFraction(
    metadata.mediaMetadata.durationPrecise,
  );

  const preciseFrameRate = metadata.mediaMetadata.frameRate;
  const roundedFrameRate = Math.round(preciseFrameRate);

  let frameRateNumerator = 1000;
  let frameRateDenominator = preciseFrameRate * 1000;

  if (isFractionalFrameRate(preciseFrameRate)) {
    frameRateNumerator = 1001;
    frameRateDenominator = roundedFrameRate * 1000;
  }

  // NOTE: A good explanation of the FCPXML format can be found here:
  // https://fcp.co/final-cut-pro/tutorials/1912-demystifying-final-cut-pro-xmls-by-philip-hodgetts-and-gregory-clarke

  const threadsXML = threads
    .map((thread) => formatThreadForFCP(thread, frameRateNumerator, frameRateDenominator))
    .join('\n\t\t');

  return `
  <fcpxml version="1.10">
  <import-options>
      <option key="copy assets" value="1"></option>
      <option key="suppress warnings" value="1"></option>
  </import-options>
  <resources>
      <format id="r1" frameDuration="${frameRateNumerator}/${frameRateDenominator}s" width="${metadata.mediaMetadata.resolutionWidth}" height="${metadata.mediaMetadata.resolutionHeight}" colorSpace="1-1-1 (Rec. 709)"></format>
      <effect id="r2" name="Timecode" uid=".../Generators.localized/Elements.localized/Timecode.localized/Timecode.motn"></effect>
      <media id="r3" name="${metadata.projectName}" uid="${uidString}">
          <sequence format="r1">
              <spine lane="0" offset="0s">
                <video ref="r2" lane="0" offset="0s" name="Timecode" duration="${durationNumerator}/${durationDenominator}s" start="0s"
                  enabled="1">
                  <param name="Timecode Base" key="9999/10128/10112/2/507/501" value="13 (Source)"/>
                  <param name="Label" key="9999/10128/10112/2/507/505" value=""/>
                  <param name="Size" key="9999/10128/10112/5/10115/3" value="54"/>
                  <param name="Font" key="9999/10128/10112/5/10115/83" value="30 0"/>
                  <param name="Center" key="9999/999664071/999664069/3/999664094/1" value="0.5 0.5" />
                  ${threadsXML}
                </video>
              </spine>
          </sequence>
      </media>
  </resources>
  <ref-clip lane="0" offset="0s" name="Replay Comments" duration="${durationNumerator}/${durationDenominator}s" enabled="1" ref="r3" srcEnable="all" useAudioSubroles="0">
    ${threadsXML}
  </ref-clip>
</fcpxml>
`;
};

export const exportComments = (
  metadata: MediaProp,
  threads: ReplayThread[],
  intl: IntlShape,
  currentUser: ReplayUser,
  timeDisplayFormat: TimeDisplayFormat,
  type: CommentsExportType,
  condensed: boolean,
  timecodeSecondsOffset?: number,
) => {
  const flattenedComments: FlattenedComment[] = flattenComments(
    threads,
    metadata,
    timeDisplayFormat,
    timecodeSecondsOffset,
  );
  const condensedComments = condensed
    ? flattenedComments.map((comment) => {
        const condensedComment = {
          comment: comment.content,
          author: comment.author.displayName,
          timecode: comment.timecode,
          timecodeOut: comment.timecodeOut,
          timestamp: comment.timestamp,
          version: metadata.versionNum,
          resolved: comment.isResolved,
          hasAnnotation: comment.hasAnnotation,
        };
        return condensedComment;
      })
    : flattenedComments;

  const fileName =
    metadata.projectName + '_' + new Date().toLocaleString() + (condensed ? '-condensed' : '');
  switch (type) {
    case 'txt':
      const stringArray: Array<String> = [];
      stringArray.push('Dropbox Replay');
      stringArray.push(`${metadata.projectName} - V${metadata.versionNum}`);
      stringArray.push(currentUser.displayName);
      stringArray.push('');
      stringArray.push('');
      stringArray.push(
        intl.formatMessage({
          defaultMessage: 'Comments',
          id: 'OKuzUM',
          description: 'Header for text file containing comments',
        }),
      );
      stringArray.push('');
      let count = 1;
      flattenedComments.forEach((comment) => {
        stringArray.push(`${count} - ${comment.author.displayName} - ${comment.timestamp}`);
        const inTime = comment.timeRange
          ? convertToTimeFormat(
              comment.timeRange.in,
              metadata.mediaMetadata.frameRate,
              TimeDisplayFormat.TIMECODE,
              timecodeSecondsOffset,
            )
          : '';
        const outTime =
          comment.timeRange?.out !== undefined
            ? convertToTimeFormat(
                comment.timeRange.out,
                metadata.mediaMetadata.frameRate,
                TimeDisplayFormat.TIMECODE,
                timecodeSecondsOffset,
              )
            : undefined;
        const displayTime = outTime !== undefined ? `${inTime} - ${outTime}: ` : `${inTime}: `;
        const annotationText = intl.formatMessage({
          defaultMessage: 'Annotation',
          id: 'NWkxF4',
          description: 'Text indicating annotation present in comment',
        });
        const annotation = comment.hasAnnotation ? `[${annotationText}] ` : '';
        stringArray.push(displayTime + annotation + comment.content);
        stringArray.push('');
        stringArray.push('');
        count = count + 1;
      });
      const joinedString = stringArray.join('\n');
      exportFromJSON({
        data: joinedString,
        fileName: fileName,
        exportType: type,
      });
      break;
    case 'csv':
      exportFromJSON({
        data: condensedComments,
        fileName: fileName,
        exportType: type,
      });
      break;
    case 'json':
      exportFromJSON({
        data: threads,
        fileName: fileName,
        exportType: type,
      });
      break;
    case 'xml':
      exportFromJSON({
        data: threads,
        fileName: fileName,
        exportType: type,
      });
      break;
    case 'fcpxml':
      const fcpXML = formatForFCP(metadata, threads);
      exportFromJSON({
        data: fcpXML,
        fileName: fileName,
        extension: 'fcpxml',
        exportType: 'txt',
      });
      break;
    case 'avid-txt': {
      // avid requires comments with an hour offset
      const avidOffset = 3600;

      const fps = metadata.mediaMetadata.frameRate;
      const data = threads
        .map((thread, i) => {
          const startTimestamp = convertToTimeFormat(
            thread.timeRange?.in,
            metadata.mediaMetadata.frameRate,
            TimeDisplayFormat.TIMECODE,
            avidOffset,
          );

          const inTime = thread.timeRange?.in ?? 0;
          const outTime = thread.timeRange?.out ?? inTime + 1 / fps;

          // Only use the first comment in a thread as the text format can't
          // handle threads, nor can it handle newlines
          const comment = thread.comments[0];
          const commentContent = comment.content.replace('\n', ' ');

          return [
            // Marker name
            `dropbox-replay-${i}`,

            // 01:00:01:14 (14 frames into the second 1)
            startTimestamp,

            // Media Composer Track
            'V1',

            // Marker color
            'red',

            // Text comment for comment
            `${comment.author.displayName}: ${commentContent}`,

            // Duration of marker in number of frames
            Math.round((outTime - inTime) * fps),
          ].join('\t');
        })
        .join('\n');

      exportFromJSON({
        data,
        fileName: fileName,
        exportType: 'txt',
      });
      break;
    }
  }
};
