import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';

import {UIIcon} from '@dropbox/dig-icons';
import {SendLine} from '@dropbox/dig-icons/assets';

import type {ButtonProps} from '~/components/button';
import {Button} from '~/components/button';

export const CANNY_URL = 'https://dropbox-replay.canny.io/community';

const ButtonWrapper = styled.div`
  position: fixed;
  right: var(--spacing__unit--3);
  bottom: var(--spacing__unit--2);
`;

const StyledButton = styled(Button)<ButtonProps>`
  background-color: var(--dig-color__secondary__base);

  &&:before {
    background: var(--dig-color__opacity__surface);
  }
`;

export const CannyButton = () => {
  return (
    <ButtonWrapper>
      <StyledButton
        href={CANNY_URL}
        rel="noopener noreferrer"
        size="standard"
        target="_blank"
        variant="primary"
        withIconStart={<UIIcon role="presentation" src={SendLine} />}
      >
        <FormattedMessage
          defaultMessage="Feedback about Replay"
          description="Text on a button that takes you to a form where you can submit feedback about the product."
          id="Kccg1N"
        />
      </StyledButton>
    </ButtonWrapper>
  );
};

export const CannyButtonBuffer = () => {
  // Buffer to add to bottom of page so Canny Button does not block important content
  return <div style={{height: '32px', marginTop: 'var(--spacing__unit--2)'}} />;
};
