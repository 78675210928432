import React, {useEffect, useMemo, useState} from 'react';

import {defineMessages, FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';

import type {reel} from '@dropbox/api-v2-client/types/dropbox_types';
import {Menu} from '@dropbox/dig-components/menu';
import {Modal} from '@dropbox/dig-components/modal';
import {Tabs} from '@dropbox/dig-components/tabs';
import {Box} from '@dropbox/dig-foundations';

import {Button} from '~/components/button';
import {Loader} from '~/components/loading';
import {spacing} from '~/components/styled';
import type {AudioStreamMetadata, MediaProp, VideoStreamMetadata} from '~/lib/api';
import {MediaType} from '~/lib/api';
import {
  useGetEntityMetadataQuery,
  useGetVersionSummariesQuery,
  useGetVideoQuery,
} from '~/lib/api_queries';
import {getCreativeDocSpecificDataFields, getImageSpecificDataFields} from '~/lib/image_metadata';
import {
  getDocumentSpecificDataFields,
  makeAudioStreamFields,
  makeFileInfoFields,
  makeFolderInfoFields,
  makeVideoStreamFields,
  MetadataFields,
} from '~/pages/viewer_page/components/info_utils';

const TabsPanelWrapper = styled(Tabs.Panel)`
  height: 288px;
  overflow: auto;
`;

const StreamSeperator = styled.div`
  margin-top: ${spacing('Micro Small')};
`;

const StreamSection = styled.div`
  margin-top: ${spacing('Micro Small')};
`;

interface InfoModalBaseProps {
  open: boolean;
  requestClose: () => void;
}

interface FolderInfoModalProps extends InfoModalBaseProps {
  folderId: string;
}

export const FolderInfoModal = ({open, folderId, requestClose}: FolderInfoModalProps) => {
  const intl = useIntl();

  const metadata = useGetEntityMetadataQuery(folderId, 'team_project', open);
  const entityMetadata = metadata.data;
  const isLoading = metadata.isLoading || !entityMetadata || entityMetadata.type !== 'folder';

  return (
    <Modal
      isCentered
      onRequestClose={() => {
        requestClose();
      }}
      open={open}
      withCloseButton={intl.formatMessage(modalStrings.closeLabel)}
    >
      <Modal.Header>
        <Modal.Title>{intl.formatMessage(modalStrings.projectTitle)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Box paddingBottom="Macro XLarge">
          {isLoading ? (
            <Loader />
          ) : (
            <MetadataFields
              fields={makeFolderInfoFields(
                entityMetadata.name,
                entityMetadata.numFiles,
                entityMetadata.size,
                intl,
              )}
            />
          )}
        </Box>
      </Modal.Body>
    </Modal>
  );
};

interface AssetInfoModalProps extends InfoModalBaseProps {
  assetId: string;
  assetVersionId?: string;
}

export const AssetInfoModal = ({
  open,
  assetId,
  assetVersionId,
  requestClose,
}: AssetInfoModalProps) => {
  const intl = useIntl();
  const [versionId, setVersionId] = useState<string | undefined>(assetVersionId);
  const video = useGetVideoQuery(assetId, versionId, open);

  const mediaMetadata = video.data;
  const isLoading = video.isLoading || !mediaMetadata;

  const handleSelected = (videoVersionId: string) => {
    setVersionId(videoVersionId);
  };

  return (
    <Modal
      isCentered
      onRequestClose={() => {
        requestClose();
      }}
      open={open}
      withCloseButton={intl.formatMessage(modalStrings.closeLabel)}
    >
      <Modal.Header>
        <Modal.Title>
          <Box display="flex" flexDirection="row">
            <Box paddingTop="Micro XSmall">
              <FormattedMessage {...modalStrings.assetTitle} />
            </Box>
            <AssetVersionMenu
              assetId={assetId}
              assetVersionId={assetVersionId}
              onSelection={handleSelected}
            />
          </Box>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? <Loader /> : <VideoVersionInfoTabs mediaMetadata={mediaMetadata} />}
      </Modal.Body>
    </Modal>
  );
};

interface AssetVersionMenuProps {
  assetId: string;
  assetVersionId?: string;
  onSelection: (videoVersionId: string) => void;
}

const AssetVersionMenu = ({assetId, assetVersionId, onSelection}: AssetVersionMenuProps) => {
  const versionSummaries = useGetVersionSummariesQuery({
    params: {type: 'video_id', videoId: assetId},
  });
  const [selected, setSelected] = React.useState<reel.VersionSummary | null>(null);
  const versions = useMemo(
    () => (versionSummaries.data ?? []).sort((a, b) => b.version_num - a.version_num),
    [versionSummaries],
  );

  useEffect(() => {
    if (!selected && versions.length > 0) {
      const defaultVersion = versions[0];
      const assetVersion = assetVersionId
        ? versions.find(({video_version_id}) => video_version_id === assetVersionId)
        : defaultVersion;

      setSelected(assetVersion ?? defaultVersion);
    }
  }, [selected, versions, assetId, assetVersionId]);

  const handleSelected = (selection: reel.VersionSummary) => {
    setSelected(selection);
    onSelection(selection.video_version_id);
  };

  if (!versions || !selected || versions.length < 1) return null;

  return (
    <Menu.Wrapper onSelection={handleSelected}>
      {({getContentProps, getTriggerProps}) => (
        <Box paddingLeft="Micro Small">
          <Button {...getTriggerProps()} variant="opacity" withDropdownIcon>
            {'V' + selected.version_num}
          </Button>
          <Menu.Content {...getContentProps()} placement="bottom-end">
            <Menu.Segment>
              {versions.map((version) => (
                <Menu.SelectItem
                  key={version.video_version_id}
                  selected={version === selected}
                  value={version}
                >
                  {'V' + version?.version_num}
                </Menu.SelectItem>
              ))}
            </Menu.Segment>
          </Menu.Content>
        </Box>
      )}
    </Menu.Wrapper>
  );
};

const VideoVersionInfoTabs = ({mediaMetadata}: {mediaMetadata: MediaProp}) => {
  const [selectedTab, setSelectedTab] = React.useState('file_info');
  const intl = useIntl();
  let videoStreamMetadata, audioStreamMetadata;
  if ('videoStreamMetadata' in mediaMetadata) {
    videoStreamMetadata = mediaMetadata.videoStreamMetadata?.map(
      (videoStream: VideoStreamMetadata) => {
        return makeVideoStreamFields(videoStream, intl);
      },
    );
  }
  if ('audioStreamMetadata' in mediaMetadata) {
    audioStreamMetadata = mediaMetadata.audioStreamMetadata?.map(
      (audioStream: AudioStreamMetadata) => {
        return makeAudioStreamFields(audioStream, intl);
      },
    );
  }

  let fileInfoMetadata;
  if (mediaMetadata.mediaType === MediaType.Image) {
    fileInfoMetadata = getImageSpecificDataFields(mediaMetadata, intl);
  } else if (mediaMetadata.mediaType === MediaType.CreativeDoc) {
    fileInfoMetadata = getCreativeDocSpecificDataFields(mediaMetadata, intl);
  } else if (mediaMetadata.mediaType === MediaType.Document) {
    fileInfoMetadata = getDocumentSpecificDataFields(mediaMetadata, intl);
  } else {
    fileInfoMetadata = makeFileInfoFields(mediaMetadata, intl);
  }

  return (
    <Tabs onSelection={setSelectedTab} selectedTab={selectedTab}>
      <Tabs.Group>
        <Tabs.Tab id="file_info">
          <FormattedMessage
            defaultMessage="File info"
            description="Text on tab indicating details in this view are about the file information"
            id="44Hjq+"
          />
        </Tabs.Tab>
        {videoStreamMetadata && videoStreamMetadata.length >= 1 && (
          <Tabs.Tab id="video_streams">
            <FormattedMessage
              defaultMessage="Video Streams"
              description="Text on tab indicating details in this view are about the video streams in the file"
              id="yxfXn1"
            />
          </Tabs.Tab>
        )}
        {audioStreamMetadata && audioStreamMetadata.length >= 1 && (
          <Tabs.Tab id="audio_streams">
            <FormattedMessage
              defaultMessage="Audio Streams"
              description="Text on tab indicating details in this view are about the audio streams in the file"
              id="5qLKwu"
            />
          </Tabs.Tab>
        )}
      </Tabs.Group>
      <TabsPanelWrapper className="Tabs-scrollingTabPanel" tabId="file_info" tabIndex={0}>
        <StreamSection>
          <MetadataFields fields={fileInfoMetadata} />
        </StreamSection>
      </TabsPanelWrapper>
      {videoStreamMetadata && videoStreamMetadata.length >= 1 ? (
        <TabsPanelWrapper tabId="video_streams">
          <StreamSection>
            {videoStreamMetadata.map((videoStream, idx) => {
              return (
                <div key={idx}>
                  <MetadataFields fields={videoStream} />
                  <StreamSeperator />
                </div>
              );
            })}
          </StreamSection>
        </TabsPanelWrapper>
      ) : null}
      {audioStreamMetadata && audioStreamMetadata.length >= 1 ? (
        <TabsPanelWrapper tabId="audio_streams">
          <StreamSection>
            {audioStreamMetadata.map((audioStream, idx) => {
              return (
                <div key={idx}>
                  <MetadataFields fields={audioStream} />
                  <StreamSeperator />
                </div>
              );
            })}
          </StreamSection>
        </TabsPanelWrapper>
      ) : null}
    </Tabs>
  );
};

const modalStrings = defineMessages({
  projectTitle: {
    defaultMessage: 'Project info',
    description: 'Title text on project information modal',
    id: '4tXByh',
  },
  assetTitle: {
    defaultMessage: 'File info',
    description: 'Title text on modal indicating these are details are about the file information',
    id: '6FtGvx',
  },
  closeLabel: {
    defaultMessage: 'Close information window',
    description: 'Text on close button in modal',
    id: '61uRU+',
  },
});
