import {defineMessages, type IntlShape} from 'react-intl';

import type {AggregateEntityMetadata, ImageMetadata, ImageProp, MediaMetadata} from '~/lib/api';
import {formatBytes} from '~/lib/utils';
import {
  makeUsesReplayStorageField,
  type MetadataFieldType,
} from '~/pages/viewer_page/components/info_utils';

import {ReplayError, ReplayErrorCategory, reportException} from './error_reporting';

/**
 * These Image EXIF metadata values are copied over from server/typescript/component_libraries/files_components/src/blades/info_blade/content_metadata/image_metadata.ts
 * and should correspond to the metadata fields in: server/configs/proto/dropbox/proto/riviera/exif.proto
 * Some fields were deleted since we are not showing them on our file info modal.
 */

/**
 * Metering mode setting values for a given image under the EXIF tags specification
 * Values should correspond to ones specified on: server/configs/proto/dropbox/proto/riviera/exif.proto
 */
const enum MeteringModeIdentifier {
  Unknown = 0,
  Average = 1,
  CenterWeightedAverage = 2,
  Spot = 3,
  MultiSpot = 4,
  MultiSegment = 5,
  Partial = 6,
  Other = 7,
}

/**
 * Flash setting values for a given image under the EXIF tags specification
 * Values should correspond to ones specified on: server/configs/proto/dropbox/proto/riviera/exif.proto
 */
const enum FlashSettingIdentifier {
  Unknown = 0,
  NoFlash = 1,
  Fired = 2,
  FiredReturnNotDetected = 3,
  FiredReturnDetected = 4,
  OnDidNotFire = 5,
  OnFired = 6,
  OnReturnNotDetected = 7,
  OnReturnDetected = 8,
  OffDidNotFire = 9,
  OffDidNotFireReturnNotDetected = 10,
  AutoDidNotFire = 11,
  AutoFired = 12,
  AutoFiredReturnNotDetected = 13,
  AutoFiredReturnDetected = 14,
  NoFlashFunction = 15,
  OffNoFlashFunction = 16,
  FiredRedEyeReduction = 17,
  FiredRedEyeReductionReturnNotDetected = 18,
  FiredRedEyeReductionReturnDetected = 19,
  OnRedEyeReduction = 20,
  OnRedEyeReductionReturnNotDetected = 21,
  OnRedEyeReductionReturnDetected = 22,
  OffRedEyeReduction = 23,
  AutoDidNotFireRedEyeReduction = 24,
  AutoFiredRedEyeReduction = 25,
  AutoFiredRedEyeReductionReturnNotDetected = 26,
  AutoFiredRedEyeReductionReturnDetected = 27,
  Other = 28,
}

/**
 * White balance setting values for a given image under the EXIF tags specification
 * Values should correspond to ones specified on: server/configs/proto/dropbox/proto/riviera/exif.proto
 */
export const enum WhiteBalanceIdentifier {
  Unknown = 0,
  Auto = 1,
  Manual = 2,
  Other = 3,
}

/**
 * Color space setting values for a given image under the EXIF tags specification
 * Values should correspond to ones specified on: server/configs/proto/dropbox/proto/riviera/exif.proto
 */
export const enum ColorSpaceIdentifier {
  Unknown = 0,
  SRgb = 1,
  AdobeRgb = 2,
  WideGamutRgb = 3,
  IccProfile = 4,
  Uncalibrated = 5,
  Other = 6,
}

/**
 * Orientation values for a given image under the EXIF tags specification
 * Values should correspond to one specified on: server/configs/proto/dropbox/proto/riviera/exif.proto
 */
export const enum OrientationIdentifier {
  UnknownOrientation = 0,
  Horizontal = 1,
  MirrorHorizontal = 2,
  Rotate180 = 3,
  MirrorVertical = 4,
  MirrorHorizontalRotate270Cw = 5,
  Rotate90Cw = 6,
  MirrorHorizontalRotate90Cw = 7,
  Rotate270Cw = 8,
}

/**
 * Resolution unit values for a given image under the EXIF tags specification
 * Values should correspond to one specified on: server/configs/proto/dropbox/proto/riviera/exif.proto
 */
export const enum ResolutionUnit {
  UnknownResolutionUnit = 0,
  Inch = 1,
  CM = 2,
}

export const WhiteBalanceSetting = (
  intl: IntlShape,
): {
  [key: number]: string;
} => ({
  [WhiteBalanceIdentifier.Auto]: intl.formatMessage({
    defaultMessage: 'Auto',
    id: 'r9XpQP',
    description:
      'Field value displayed under a field label titled "White Balance". It represents a white balance setting tag value for a given image under the EXIF tags specification.',
  }),
  [WhiteBalanceIdentifier.Manual]: intl.formatMessage({
    defaultMessage: 'Manual',
    id: 'ahpeOj',
    description:
      'Field value displayed under a field label titled "White Balance". It represents a white balance setting tag value for a given image under the EXIF tags specification.',
  }),
  [WhiteBalanceIdentifier.Other]: intl.formatMessage({
    defaultMessage: 'Other',
    id: 'K0+xZL',
    description:
      'Field value displayed under a field label titled "White Balance". It represents a white balance setting tag value for a given image under the EXIF tags specification.',
  }),
});

export const ColorSpaceSetting = (
  intl: IntlShape,
): {
  [key: number]: string;
} => ({
  [ColorSpaceIdentifier.SRgb]: intl.formatMessage({
    defaultMessage: 'sRGB',
    id: 'louR6V',
    description:
      'Field value displayed under a field label titled "Color profile". It represents a color space setting tag value for a given image under the EXIF tags specification.',
  }),
  [ColorSpaceIdentifier.AdobeRgb]: intl.formatMessage({
    defaultMessage: 'Adobe RGB',
    id: 'e9OSO8',
    description:
      'Field value displayed under a field label titled "Color profile". It represents a color space setting tag value for a given image under the EXIF tags specification.',
  }),
  [ColorSpaceIdentifier.WideGamutRgb]: intl.formatMessage({
    defaultMessage: 'Wide Gamut RGB',
    id: 'w8DRiX',
    description:
      'Field value displayed under a field label titled "Color profile". It represents a color space setting tag value for a given image under the EXIF tags specification.',
  }),
  [ColorSpaceIdentifier.IccProfile]: intl.formatMessage({
    defaultMessage: 'ICC Profile',
    id: 'AZ/H9Y',
    description:
      'Field value displayed under a field label titled "Color profile". It represents a color space setting tag value for a given image under the EXIF tags specification.',
  }),
  [ColorSpaceIdentifier.Uncalibrated]: intl.formatMessage({
    defaultMessage: 'Uncalibrated',
    id: 'N3M19J',
    description:
      'Field value displayed under a field label titled "Color profile". It represents a color space setting tag value for a given image under the EXIF tags specification.',
  }),
  [ColorSpaceIdentifier.Other]: intl.formatMessage({
    defaultMessage: 'Other',
    id: 'IFMlHE',
    description:
      'Field value displayed under a field label titled "Color profile". It represents a color space setting tag value for a given image under the EXIF tags specification.',
  }),
});

export const FlashSetting = (
  intl: IntlShape,
): {
  [key: number]: string;
} => ({
  [FlashSettingIdentifier.NoFlash]: intl.formatMessage({
    defaultMessage: 'No flash',
    id: 'u0852h',
    description:
      'Field value displayed under a field label titled "Flash". It represents a flash setting tag value for a given image under the EXIF tags specification.',
  }),
  [FlashSettingIdentifier.Fired]: intl.formatMessage({
    defaultMessage: 'Fired',
    id: 'DTs3Ux',
    description:
      'Field value displayed under a field label titled "Flash". It represents a flash setting tag value for a given image under the EXIF tags specification.',
  }),
  [FlashSettingIdentifier.FiredReturnNotDetected]: intl.formatMessage({
    defaultMessage: 'Fired, return not detected',
    id: 'SlmIsZ',
    description:
      'Field value displayed under a field label titled "Flash". It represents a flash setting tag value for a given image under the EXIF tags specification.',
  }),
  [FlashSettingIdentifier.FiredReturnDetected]: intl.formatMessage({
    defaultMessage: 'Fired, return detected',
    id: '/G0xde',
    description:
      'Field value displayed under a field label titled "Flash". It represents a flash setting tag value for a given image under the EXIF tags specification.',
  }),
  [FlashSettingIdentifier.OnDidNotFire]: intl.formatMessage({
    defaultMessage: 'On, did not fire',
    id: 'VzXPKy',
    description:
      'Field value displayed under a field label titled "Flash". It represents a flash setting tag value for a given image under the EXIF tags specification.',
  }),
  [FlashSettingIdentifier.OnFired]: intl.formatMessage({
    defaultMessage: 'On, fired',
    id: 'FxaRKM',
    description:
      'Field value displayed under a field label titled "Flash". It represents a flash setting tag value for a given image under the EXIF tags specification.',
  }),
  [FlashSettingIdentifier.OnReturnNotDetected]: intl.formatMessage({
    defaultMessage: 'On, return not detected',
    id: 'LV+DQB',
    description:
      'Field value displayed under a field label titled "Flash". It represents a flash setting tag value for a given image under the EXIF tags specification.',
  }),
  [FlashSettingIdentifier.OnReturnDetected]: intl.formatMessage({
    defaultMessage: 'On, return detected',
    id: 'nIzkgn',
    description:
      'Field value displayed under a field label titled "Flash". It represents a flash setting tag value for a given image under the EXIF tags specification.',
  }),
  [FlashSettingIdentifier.OffDidNotFire]: intl.formatMessage({
    defaultMessage: 'Off, did not fire',
    id: 'xD66pH',
    description:
      'Field value displayed under a field label titled "Flash". It represents a flash setting tag value for a given image under the EXIF tags specification.',
  }),
  [FlashSettingIdentifier.OffDidNotFireReturnNotDetected]: intl.formatMessage({
    defaultMessage: 'Off, did not fire, return not detected',
    id: 'lNalMC',
    description:
      'Field value displayed under a field label titled "Flash". It represents a flash setting tag value for a given image under the EXIF tags specification.',
  }),
  [FlashSettingIdentifier.AutoDidNotFire]: intl.formatMessage({
    defaultMessage: 'Auto, did not fire',
    id: '8hWFgJ',
    description:
      'Field value displayed under a field label titled "Flash". It represents a flash setting tag value for a given image under the EXIF tags specification.',
  }),
  [FlashSettingIdentifier.AutoFired]: intl.formatMessage({
    defaultMessage: 'Auto, fired',
    id: '6rZYaX',
    description:
      'Field value displayed under a field label titled "Flash". It represents a flash setting tag value for a given image under the EXIF tags specification.',
  }),
  [FlashSettingIdentifier.AutoFiredReturnNotDetected]: intl.formatMessage({
    defaultMessage: 'Auto, fired, return not detected',
    id: 'UCLyml',
    description:
      'Field value displayed under a field label titled "Flash". It represents a flash setting tag value for a given image under the EXIF tags specification.',
  }),
  [FlashSettingIdentifier.AutoFiredReturnDetected]: intl.formatMessage({
    defaultMessage: 'Auto, fired, return detected',
    id: 'tvVfZQ',
    description:
      'Field value displayed under a field label titled "Flash". It represents a flash setting tag value for a given image under the EXIF tags specification.',
  }),
  [FlashSettingIdentifier.NoFlashFunction]: intl.formatMessage({
    defaultMessage: 'No flash function',
    id: 'hMC/nu',
    description:
      'Field value displayed under a field label titled "Flash". It represents a flash setting tag value for a given image under the EXIF tags specification.',
  }),
  [FlashSettingIdentifier.OffNoFlashFunction]: intl.formatMessage({
    defaultMessage: 'Off, no flash function',
    id: 'NtBm8R',
    description:
      'Field value displayed under a field label titled "Flash". It represents a flash setting tag value for a given image under the EXIF tags specification.',
  }),
  [FlashSettingIdentifier.FiredRedEyeReduction]: intl.formatMessage({
    defaultMessage: 'Fired, red-eye reduction',
    id: 'ksXmhS',
    description:
      'Field value displayed under a field label titled "Flash". It represents a flash setting tag value for a given image under the EXIF tags specification.',
  }),
  [FlashSettingIdentifier.FiredRedEyeReductionReturnNotDetected]: intl.formatMessage({
    defaultMessage: 'Fired, red-eye reduction, return not detected',
    id: 'Wx5tDn',
    description:
      'Field value displayed under a field label titled "Flash". It represents a flash setting tag value for a given image under the EXIF tags specification.',
  }),
  [FlashSettingIdentifier.FiredRedEyeReductionReturnDetected]: intl.formatMessage({
    defaultMessage: 'Fired, red-eye reduction, return detected',
    id: '3UnRXt',
    description:
      'Field value displayed under a field label titled "Flash". It represents a flash setting tag value for a given image under the EXIF tags specification.',
  }),
  [FlashSettingIdentifier.OnRedEyeReduction]: intl.formatMessage({
    defaultMessage: 'On, red-eye reduction',
    id: 'Gx6wmM',
    description:
      'Field value displayed under a field label titled "Flash". It represents a flash setting tag value for a given image under the EXIF tags specification.',
  }),
  [FlashSettingIdentifier.OnRedEyeReductionReturnNotDetected]: intl.formatMessage({
    defaultMessage: 'On, red-eye reduction, return not detected',
    id: 'bGkfjq',
    description:
      'Field value displayed under a field label titled "Flash". It represents a flash setting tag value for a given image under the EXIF tags specification.',
  }),
  [FlashSettingIdentifier.OnRedEyeReductionReturnDetected]: intl.formatMessage({
    defaultMessage: 'On, red-eye reduction, return detected',
    id: 'iPbL+4',
    description:
      'Field value displayed under a field label titled "Flash". It represents a flash setting tag value for a given image under the EXIF tags specification.',
  }),
  [FlashSettingIdentifier.OffRedEyeReduction]: intl.formatMessage({
    defaultMessage: 'Off, red-eye reduction',
    id: '+M2t2J',
    description:
      'Field value displayed under a field label titled "Flash". It represents a flash setting tag value for a given image under the EXIF tags specification.',
  }),
  [FlashSettingIdentifier.AutoDidNotFireRedEyeReduction]: intl.formatMessage({
    defaultMessage: 'Auto, did not fire, red-eye reduction',
    id: 'XsBCBR',
    description:
      'Field value displayed under a field label titled "Flash". It represents a flash setting tag value for a given image under the EXIF tags specification.',
  }),
  [FlashSettingIdentifier.AutoFiredRedEyeReduction]: intl.formatMessage({
    defaultMessage: 'Auto, fired, red-eye reduction',
    id: 'AyZKAK',
    description:
      'Field value displayed under a field label titled "Flash". It represents a flash setting tag value for a given image under the EXIF tags specification.',
  }),
  [FlashSettingIdentifier.AutoFiredRedEyeReductionReturnNotDetected]: intl.formatMessage({
    defaultMessage: 'Auto, fired, red-eye reduction, return not detected',
    id: 'svdrpK',
    description:
      'Field value displayed under a field label titled "Flash". It represents a flash setting tag value for a given image under the EXIF tags specification.',
  }),
  [FlashSettingIdentifier.AutoFiredRedEyeReductionReturnDetected]: intl.formatMessage({
    defaultMessage: 'Auto, fired, red-eye reduction, return detected',
    id: 'S3Ermp',
    description:
      'Field value displayed under a field label titled "Flash". It represents a flash setting tag value for a given image under the EXIF tags specification.',
  }),
  [FlashSettingIdentifier.Other]: intl.formatMessage({
    defaultMessage: 'Other',
    id: 'OJ76oB',
    description:
      'Field value displayed under a field label titled "Flash". It represents a flash setting tag value for a given image under the EXIF tags specification.',
  }),
});

export const MeteringModeSetting = (
  intl: IntlShape,
): {
  [key: number]: string;
} => ({
  [MeteringModeIdentifier.Average]: intl.formatMessage({
    defaultMessage: 'Average',
    id: 'FD1dYR',
    description:
      'Field value displayed under a field label titled "Metering". It represents a metering mode setting tag value for a given image under the EXIF tags specification.',
  }),
  [MeteringModeIdentifier.CenterWeightedAverage]: intl.formatMessage({
    defaultMessage: 'Center-weighted average',
    id: 'g+mXN1',
    description:
      'Field value displayed under a field label titled "Metering". It represents a metering mode setting tag value for a given image under the EXIF tags specification.',
  }),
  [MeteringModeIdentifier.Spot]: intl.formatMessage({
    defaultMessage: 'Spot',
    id: 'kgE1WV',
    description:
      'Field value displayed under a field label titled "Metering". It represents a metering mode setting tag value for a given image under the EXIF tags specification.',
  }),
  [MeteringModeIdentifier.MultiSpot]: intl.formatMessage({
    defaultMessage: 'Multi-spot',
    id: 'H4m6if',
    description:
      'Field value displayed under a field label titled "Metering". It represents a metering mode setting tag value for a given image under the EXIF tags specification.',
  }),
  [MeteringModeIdentifier.MultiSegment]: intl.formatMessage({
    defaultMessage: 'Multi-segment',
    id: 'RK6ks7',
    description:
      'Field value displayed under a field label titled "Metering". It represents a metering mode setting tag value for a given image under the EXIF tags specification.',
  }),
  [MeteringModeIdentifier.Partial]: intl.formatMessage({
    defaultMessage: 'Partial',
    id: 'Gfn9a+',
    description:
      'Field value displayed under a field label titled "Metering". It represents a metering mode setting tag value for a given image under the EXIF tags specification.',
  }),
  [MeteringModeIdentifier.Other]: intl.formatMessage({
    defaultMessage: 'Other',
    id: 'NjD9Ca',
    description:
      'Field value displayed under a field label titled "Metering". It represents a metering mode setting tag value for a given image under the EXIF tags specification.',
  }),
});

export const OrientationCopy = (
  intl: IntlShape,
): {
  [key: number]: string;
} => ({
  [OrientationIdentifier.Horizontal]: intl.formatMessage({
    defaultMessage: 'Horizontal (normal)',
    id: 'XKq24w',
    description: 'orientation - Horizontal (normal)',
  }),
  [OrientationIdentifier.MirrorHorizontal]: intl.formatMessage({
    defaultMessage: 'Mirror horizontal',
    id: '9RAY+b',
    description: 'orientation - Mirror horizontal',
  }),
  [OrientationIdentifier.Rotate180]: intl.formatMessage({
    defaultMessage: 'Rotate 180',
    id: 'QpUK9b',
    description: 'orientation - Rotate 180',
  }),
  [OrientationIdentifier.MirrorVertical]: intl.formatMessage({
    defaultMessage: 'Mirror vertical',
    id: '34/vSV',
    description: 'orientation - Mirror vertical',
  }),
  [OrientationIdentifier.MirrorHorizontalRotate270Cw]: intl.formatMessage({
    defaultMessage: 'Mirror horizontal and rotate 270 CW',
    id: 'LUaa7r',
    description: 'orientation - Mirror horizontal and rotate 270 CW',
  }),
  [OrientationIdentifier.Rotate90Cw]: intl.formatMessage({
    defaultMessage: 'Rotate 90 CW',
    id: 'ZkzUzm',
    description: 'orientation - Rotate 90 CW',
  }),
  [OrientationIdentifier.MirrorHorizontalRotate90Cw]: intl.formatMessage({
    defaultMessage: 'Mirror horizontal and rotate 90 CW',
    id: 'iGfVOy',
    description: 'orientation - Mirror horizontal and rotate 90 CW',
  }),
  [OrientationIdentifier.Rotate270Cw]: intl.formatMessage({
    defaultMessage: 'Rotate 270 CW',
    id: 'tPOt5R',
    description: 'orientation - Rotate 270 CW',
  }),
});

export const nameFields = defineMessages({
  Name: {
    defaultMessage: 'Name',
    id: 'wffT8T',
    description: 'Field label that shows the name of the original file',
  },
  Size: {
    defaultMessage: 'Size',
    id: 'zh2mZX',
    description: 'Field label that shows the file size of the current file',
  },
  totalVersions: {
    defaultMessage: 'Total versions',
    id: 'ZxYycX',
    description: 'Field label that shows how many versions for this project there are',
  },
  sizeThisVersion: {
    defaultMessage: 'Size (this version)',
    id: 'mKKa4d',
    description: 'Field label that shows the file size of the latest version',
  },
  sizeAllVersions: {
    defaultMessage: 'Size (all versions)',
    id: 'uCeO1G',
    description: 'Field label that shows the file size of all versions',
  },
  captureDate: {
    defaultMessage: 'Date Taken',
    id: 'UTS8rq',
    description:
      'Field label shown on top of the date/time when the original image was taken under EXIF tags specification. E.g. "Date taken" and on the next line "<some date string>"',
  },
  createDate: {
    defaultMessage: 'Date Created',
    id: 'DkaArg',
    description:
      'Field label shown on top of the date/time when the image created EXIF tags specification. E.g. "Date created" and on the next line "<some date string>"',
  },
  credits: {
    defaultMessage: 'Credits',
    id: 'm/Oy6A',
    description:
      'Field label shown on top of the name of credits extracted from the EXIF/IPTC of the image. E.g. "Credits" and on the next line "<some string>"',
  },
  dimensions: {
    defaultMessage: 'Dimensions',
    id: 'fqLfvn',
    description:
      'Field label shown on top of the dimensions of the image, following the pattern: "Pixels in width x Pixels in height". E.g. "Dimensions" and on the next line "4032 x 3400"',
  },
  resolution: {
    defaultMessage: 'Dots Per Inch',
    id: '6/AwqI',
    description:
      'Field label shown on top of the resolution of the image, following the pattern: "Pixels per inch in width x Pixels per inch in height". E.g. "Dots per inch" and on the next line "72 x 72"',
  },
  resolution_in_cm: {
    defaultMessage: 'Dots Per CM',
    id: 'Toq2yc',
    description:
      'Field label shown on top of the resolution in cm of the image, following the pattern: "Pixels per inch in width x Pixels per inch in height". E.g. "Dots per CM" and on the next line "72 x 72"',
  },
  colorSpace: {
    defaultMessage: 'Color Profile',
    id: 'FmfWBl',
    description:
      'Field label shown on top of the color profile of the image. E.g. "Color profile" and on the next line "sRGB"',
  },
  deviceMake: {
    defaultMessage: 'Camera Brand',
    id: 'KRB6p6',
    description:
      'Field label shown on top of the name of the brand of the device used to capture the image. E.g. "Camera brand" and on the next line "Apple"',
  },
  deviceModel: {
    defaultMessage: 'Camera Model',
    id: 'hVqtvW',
    description:
      'Field label shown on top of the name of the model of the device used to capture the image. E.g. "Camera model" and on the next line "Canon PowerShot S330"',
  },
  lensModel: {
    defaultMessage: 'Lens',
    id: '/+CZc6',
    description:
      'Field label shown on top of the name of the model of the lens used to capture the image. E.g. "Lens" and on the next line "iPad Pro (10.5-inch) back camera 3.99mm f/1.8"',
  },
  apertureValue: {
    defaultMessage: 'Aperture',
    id: 'QThAL7',
    description:
      'Field label shown on top of the aperture value setting (logarithmic number expressed as an F-stop number) used to capture the image. E.g. "Aperture" and on the next line "f/2.8"',
  },
  exposureTime: {
    defaultMessage: 'Shutter Speed',
    id: 'kRcuNx',
    description:
      'Field label shown on top of the shutter speed setting used to capture the image, as a fraction of a second. E.g. "Shutter speed" and on the next line "1/10"',
  },
  focalLength: {
    defaultMessage: 'Focal Length',
    id: '8eADpl',
    description:
      'Field label shown on top of the focal length setting used to capture the image, in millimiters. E.g. "Focal length" and on the next line "16.2 mm"',
  },
  isoSpeed: {
    defaultMessage: 'ISO',
    id: 'pw1MHw',
    description:
      'Field label shown on top of the ISO speed setting (measure of film/sensor sensitivity) used to capture the image. E.g. "ISO" and on the next line "200"',
  },
  flash: {
    defaultMessage: 'Flash',
    id: 'DpHBiR',
    description:
      'Field label shown on top of the flash setting used to capture the image. E.g. "Flash" and on the next line "Off, Did not fire"',
  },
  meteringMode: {
    defaultMessage: 'Metering',
    id: '2qKXPz',
    description:
      'Field label shown on top of the metering mode used to capture the image. E.g. "Metering" and on the next line, one of "Multi-segment", "Multi-spot", "Partial", etc.',
  },
  whiteBalance: {
    defaultMessage: 'White Balance',
    id: 'B16VM3',
    description:
      'Field label shown on top of the white balance setting used to capture the image. E.g. "White balance" and on the next line "Auto" or "Manual"',
  },
  copyright: {
    defaultMessage: 'Copyright',
    id: 'CofWap',
    description:
      'Field label shown on top of the name of the entity that holds a copyright on the image. E.g. "Copyright" and on the next line "<some string>"',
  },
  keywords: {
    defaultMessage: 'Keywords',
    id: 'TxxUbA',
    description:
      'Field label shown on top of the name of the entity that holds a keywords on the image. E.g. "Keywords" and on the next line "<some string>"',
  },
  title: {
    defaultMessage: 'Title',
    id: 'vZhzoQ',
    description:
      'Field label shown on top of the name of the entity that holds a title on the image. E.g. "Title" and on the next line "<some string>"',
  },
  caption: {
    defaultMessage: 'Caption',
    id: '47HhBr',
    description:
      'Field label shown on top of the name of the entity that holds a caption on the image. E.g. "Caption" and on the next line "<some string>"',
  },
  creator: {
    defaultMessage: 'Creator',
    id: 'iCdBaQ',
    description:
      'Field label shown on top of the name of the entity that holds a creator on the image. E.g. "Creator" and on the next line "<some string>"',
  },
  artist: {
    defaultMessage: 'Artist',
    id: 'zGrbn3',
    description:
      'Field label shown on top of the name of the entity that holds a artist on the image. E.g. "Artist" and on the next line "<some string>"',
  },
  description: {
    defaultMessage: 'Description',
    id: '3oM2bD',
    description:
      'Field label shown on top of the name of the entity that holds a description on the image. E.g. "Description" and on the next line "<some string>"',
  },
  headline: {
    defaultMessage: 'Headline',
    id: '22OZNz',
    description:
      'Field label shown on top of the name of the entity that holds a headline on the image. E.g. "Headline" and on the next line "<some string>"',
  },
  licensorUrl: {
    defaultMessage: 'Licensor URL',
    id: 'bs3cLH',
    description:
      'Field label shown on top of the name of the entity that holds a LicensorURL on the image. E.g. "LicensorURL" and on the next line "<some string>"',
  },
  orientation: {
    defaultMessage: 'Orientation',
    id: 'cW8sNo',
    description:
      'Field label shown on top of the name of the entity that holds a orientation on the image. E.g. "Orientation" and on the next line "<some string>"',
  },
  usageRights: {
    defaultMessage: 'Usage Rights',
    id: 'jH/EFy',
    description:
      'Field label shown on top of the name of the entity that holds a usage rights on the image. E.g. "Usage Rights" and on the next line "<some string>"',
  },
  category: {
    defaultMessage: 'PS Category',
    id: 'cJfTEP',
    description:
      'Field label shown on top of the name of the entity that holds a ps category on the image. E.g. "PS category" and on the next line "<some string>"',
  },
  userComments: {
    defaultMessage: 'User Comments',
    id: '39u/b1',
    description:
      'Field label shown on top of the name of the entity that holds a user comments on the image. E.g. "User comments" and on the next line "<some string>"',
  },
});

/**
 * For given width and height image dimensions in pixels, combine them into dimensions format
 * with the characters " x " in between, e.g., "1080 x 1200"
 */
export const formatImageDimensions = (
  width: number | undefined,
  height: number | undefined,
  intl: IntlShape,
): string | undefined => {
  if (width === undefined || height === undefined) {
    return undefined;
  }

  return intl.formatMessage(
    {
      defaultMessage: '{width}x{height}',
      id: 'MoSQVz',
      description:
        'For given width and height image dimensions expressed in pixels, display in dimensions format with the characters " x " in between, e.g., "1080 x 1200"',
    },
    {width: width, height: height},
  );
};

/**
 * For given width and height image resolutions in pixels per inch, combine them into dimensions format
 * with the characters " x " in between, e.g., "72 x 72"
 */
export const formatImageResolutions = (
  width: number | undefined,
  height: number | undefined,
  intl: IntlShape,
): string | undefined => {
  if (width === undefined || height === undefined) {
    return undefined;
  }

  return intl.formatMessage(
    {
      defaultMessage: '{width}x{height}',
      id: 'NeeRmy',
      description:
        'For given width and height image resolutions expressed in pixels per inch, display in dimensions format with the characters " x " in between, e.g., "72 x 72"',
    },
    {width: width, height: height},
  );
};

/**
 * For a given exposure time/shutter speed value expressed as a fraction of a second (e.g. "1/100") or float string ("1.0"), append the unit of
 * seconds ("s") to the value, with an space in between, e.g., "1/100 s" or "1.0 s"
 */
export const formatExposureTime = (
  exposureTime: string | undefined,
  intl: IntlShape,
): string | undefined => {
  if (!exposureTime) {
    return;
  }

  let exposureTimeFormatted = exposureTime;

  // Checks if value string is expressed as fraction of a second, e.g., "1/100"
  if (exposureTime.indexOf('/') === -1) {
    // if value string is expressed as a float number, parse it into a string with i18n format
    const exposureTimeValue = parseFloat(exposureTime);
    if (isNaN(exposureTimeValue)) {
      return;
    }

    exposureTimeFormatted =
      exposureTimeValue < 1
        ? intl.formatNumber(exposureTimeValue, {minimumFractionDigits: 2, maximumFractionDigits: 2})
        : intl.formatNumber(exposureTimeValue, {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
          });
  }

  return intl.formatMessage(
    {
      defaultMessage: '{exposure_time} s',
      id: '6aZPxD',
      description:
        'For a given exposure time/shutter speed value of an image expressed as fraction of seconds (e.g., "1/100") or float (e.g. "1.0"), append the unit of seconds ("s") to the value, e.g., "1/100 s" or "1.0 s"',
    },
    {exposure_time: exposureTimeFormatted},
  );
};

/**
 * For a given aperture value expressed as an f-number (e.g. 2.2), prepend the f-number label "f/" to the value
 * e.g., "f/2.2"
 */
export const formatAperture = (
  aperture: number | undefined,
  intl: IntlShape,
): string | undefined => {
  if (aperture) {
    const apertureFormatted = intl.formatNumber(aperture, {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    });

    return intl.formatMessage(
      {
        defaultMessage: 'f/{aperture}',
        id: 'Wylsry',
        description:
          'For a given aperture value of an image expresed as an f-number (e.g., 2.2), prepend the f-number label "f/" to the value, e.g., "f/2.2"',
      },
      {aperture: apertureFormatted},
    );
  }
  return;
};

/**
 * For a given focal length value expressed as a string appended with millimiter units (e.g. "16.2 mm"),
 * return the i18n format version of the string
 */
export const formatFocalLength = (
  focalLength: string | undefined,
  intl: IntlShape,
): string | undefined => {
  if (!focalLength) {
    return;
  }

  const focalLengthValue = parseFloat(focalLength);
  if (isNaN(focalLengthValue)) {
    return;
  }
  const focalLengthFormatted = intl.formatNumber(focalLengthValue, {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  });

  return intl.formatMessage(
    {
      defaultMessage: '{focal_length} mm',
      id: '7TcubY',
      description:
        'For a given focal length of an image expresed as a number in millimiters (e.g., 16.2), append the millimiters units "mm" to the value, e.g., "16.2 mm"',
    },
    {focal_length: focalLengthFormatted},
  );
};

/**
 * Transforms the original date string value received in UTC format, but representing the local time when the image was captured in an undefined timezone,
 * Ex: "2019-02-20T22:24:47Z", into a date time string that follows compact comma-separated format, Ex: "Feb 20, 2019, 10:24 pm"
 */
export const formatOriginalDate = (
  originalDate: string | undefined,
  intl: IntlShape,
): string | undefined => {
  if (!originalDate) {
    return undefined;
  }

  // timestamp of event representing date in local undefined timezone, but in UTC string format
  const timestamp = new Date(originalDate);

  try {
    // i believe we could still get a date, but it might not be valid - check that here
    // and if it's not then re return undefined
    if (isNaN(timestamp.getTime())) {
      throw new Error(`timestamp ${timestamp} getTime isNan!`);
    }

    return intl.formatDate(timestamp, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      // Since exif timestamps itself don't have time zone info, the timestamp we get from backend is acutally the local time when the image was taken (though as UTC string)
      // timezone in exif was recently allowed as a separate field, but lots of bugs we have for photos that don't include this fields
      // This behavior what native OS does, i.e display the original time without doing any time conversions
      // So we display the time as the time it was originally captured Ex; 2019-02-20T22:24:47Z shows as 2/20/2019, 10:24 pm
      timeZone: 'UTC',
      // use 12 hour format consistent with other timestamp
      hourCycle: 'h12',
    });
  } catch (error) {
    reportException(
      new ReplayError({
        severity: 'operational',
        category: ReplayErrorCategory.BadDataError,
        error: error,
        message: `invalid timestamp. originalDate str: ${originalDate}`,
        tags: ['intl_formatdate_failed'],
      }),
    );
    return undefined;
  }

  return;
};

export function getCreativeDocSpecificDataFields(
  metadata: ImageProp,
  intl: IntlShape,
): MetadataFieldType[] {
  return [
    {name: nameFields.Name, val: metadata.fileName},
    makeUsesReplayStorageField(metadata.countsAgainstQuota),
    {name: nameFields.Size, val: formatBytes(metadata.fileSizeBytes, intl)},
  ];
}

function generateCommonImageDataFields(
  imageMetadata: ImageMetadata,
  intl: IntlShape,
): MetadataFieldType[] {
  return [
    {
      name: nameFields.resolution,
      val:
        imageMetadata.resolutionUnit && imageMetadata.resolutionUnit !== ResolutionUnit.CM
          ? formatImageResolutions(imageMetadata.xResolution, imageMetadata.yResolution, intl)
          : undefined,
    },
    {
      name: nameFields.resolution_in_cm,
      val:
        imageMetadata.resolutionUnit && imageMetadata.resolutionUnit === ResolutionUnit.CM
          ? formatImageResolutions(imageMetadata.xResolution, imageMetadata.yResolution, intl)
          : undefined,
    },
    {
      name: nameFields.colorSpace,
      val:
        imageMetadata.colorSpace !== undefined
          ? ColorSpaceSetting(intl)[parseInt(imageMetadata.colorSpace)]
          : undefined,
    },
    {
      name: nameFields.orientation,
      val:
        imageMetadata.orientation !== undefined
          ? OrientationCopy(intl)[imageMetadata.orientation]
          : undefined,
    },
    {
      name: nameFields.deviceMake,
      val: imageMetadata.cameraMake,
    },
    {
      name: nameFields.deviceModel,
      val: imageMetadata.cameraModel,
    },
    {
      name: nameFields.lensModel,
      val: imageMetadata.lensModel,
    },
    {
      name: nameFields.focalLength,
      val: formatFocalLength(imageMetadata.focalLength, intl),
    },
    {
      name: nameFields.exposureTime,
      val: formatExposureTime(imageMetadata.exposureTime, intl),
    },
    {
      name: nameFields.apertureValue,
      val: formatAperture(imageMetadata.apertureValue, intl),
    },
    {
      name: nameFields.isoSpeed,
      val:
        imageMetadata.isoSpeed !== undefined && imageMetadata.isoSpeed !== 0
          ? String(imageMetadata.isoSpeed)
          : undefined,
    },
    {
      name: nameFields.meteringMode,
      val:
        imageMetadata.meteringMode !== undefined
          ? MeteringModeSetting(intl)[imageMetadata.meteringMode]
          : undefined,
    },
    {
      name: nameFields.flash,
      val: imageMetadata.flash !== undefined ? FlashSetting(intl)[imageMetadata.flash] : undefined,
    },
    {
      name: nameFields.whiteBalance,
      val:
        imageMetadata.whiteBalance !== undefined
          ? WhiteBalanceSetting(intl)[imageMetadata.whiteBalance]
          : undefined,
    },
  ];
}

export function getImageSpecificDataFields(
  metadata: ImageProp,
  intl: IntlShape,
): MetadataFieldType[] {
  const imageMetadata = metadata.imageMetadata;

  return [
    {name: nameFields.Name, val: metadata.fileName},
    makeUsesReplayStorageField(metadata.countsAgainstQuota),
    {name: nameFields.Size, val: formatBytes(metadata.fileSizeBytes, intl)},
    {
      name: nameFields.captureDate,
      val:
        imageMetadata.imageDateTimeOriginal !== undefined &&
        imageMetadata.imageDateTimeOriginal !== 'None'
          ? formatOriginalDate(imageMetadata.imageDateTimeOriginal, intl)
          : undefined,
    },
    {
      name: nameFields.dimensions,
      val: formatImageDimensions(
        metadata.mediaMetadata.resolutionWidth,
        metadata.mediaMetadata.resolutionHeight,
        intl,
      ),
    },
    ...generateCommonImageDataFields(imageMetadata, intl),
  ];
}

/**
 * This method makes the ImageMetadata directly from the values
 * instead of using a ImageProp like `getImageSpecificDataFields`.
 * This is mainly used for when we are getting the Project FileInfo
 * and the last version is an image.
 *
 * @param name - the name of the image
 * @param fileSizeBytes - the size of the file in bytes
 * @param mediaMetadata - the corresponding base media metadata
 * @param imageMetadata - the specific image metadata
 * @param intl - the IntlShape used for translations as well
 * @param aggregateInfo - aggregateInfo (if available) for the sum of the project
 * @returns
 */
export function getImageMetadataDirect(
  name: string,
  fileSizeBytes: number,
  mediaMetadata: MediaMetadata,
  imageMetadata: ImageMetadata,
  intl: IntlShape,
  aggregateInfo?: AggregateEntityMetadata,
): MetadataFieldType[] {
  let additionalFields = [];
  if (aggregateInfo) {
    additionalFields = [
      {name: nameFields.totalVersions, val: aggregateInfo.totalVersions},
      {name: nameFields.sizeThisVersion, val: formatBytes(fileSizeBytes, intl)},
      {name: nameFields.sizeAllVersions, val: formatBytes(aggregateInfo.totalSize, intl)},
    ];
  } else {
    additionalFields = [{name: nameFields.Size, val: formatBytes(fileSizeBytes, intl)}];
  }

  return [
    {name: nameFields.Name, val: name},
    ...additionalFields,
    {
      name: nameFields.captureDate,
      val:
        imageMetadata.imageDateTimeOriginal !== undefined &&
        imageMetadata.imageDateTimeOriginal !== 'None'
          ? formatOriginalDate(imageMetadata.imageDateTimeOriginal, intl)
          : undefined,
    },
    {
      name: nameFields.dimensions,
      val: formatImageDimensions(
        mediaMetadata.resolutionWidth,
        mediaMetadata.resolutionHeight,
        intl,
      ),
    },
    {
      name: nameFields.resolution,
      val:
        imageMetadata.resolutionUnit && imageMetadata.resolutionUnit !== ResolutionUnit.CM
          ? formatImageResolutions(imageMetadata.xResolution, imageMetadata.yResolution, intl)
          : undefined,
    },
    ...generateCommonImageDataFields(imageMetadata, intl),
  ];
}
