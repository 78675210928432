import {useCallback} from 'react';

import {atom, useAtomValue} from 'jotai';
import {useAtomCallback} from 'jotai/utils';
import type {PascalCase} from 'type-fest';

import {snakeToPascal} from './casing';
import {getDefaultUserClient} from './client';

export const stormcrowFeatures = [
  'reel_feature_flag',
  'file_imports_entry_points_sfolders',
  'file_imports_holdback',
  'file_imports_entry_points',
  'replay_fss_group_support',
  'flows_archive_from_replay',
  'reel_enable_provisioning',
  'replay_ga_time',
  'replay_beta_grace_period_end_time',
  'replay_disable_live_collab',
  'replay_fss_quota_counting',
  'replay_predict_transcriptions',
  'replay_fullstory',
  'replay_browse_performance',
  'partial_addon_management_self_serve',
  'replay_addon_member_request',
  'replay_search',
  'replay_creator_tool_callout',
  'replay_in_app_purchase',

  // REEL-4297
  'replay_pdf_drawing_enabled',

  // REEL-4342
  'replay_team_only_commenting',

  // REEL-4296
  'replay_pinpoint_comments_enabled',

  'replay_standalone_team_creation',
] as const;

export type StormcrowFeature = (typeof stormcrowFeatures)[number];

// Features listed here will log an exposure event
export const stormcrowExposures: StormcrowFeature[] = [
  'replay_creator_tool_callout',
  'replay_search',
  'replay_in_app_purchase',
  'replay_addon_member_request',
  'replay_team_only_commenting',
] as const;

export type StormcrowAssignments = Partial<Record<StormcrowFeature, string>>;
export const stormcrows = atom<StormcrowAssignments>({});

export function useOverrideStormcrow() {
  return useAtomCallback(
    useCallback((get, set, feature: StormcrowFeature, variant: string) => {
      const currentStormcrows = {...get(stormcrows)};
      currentStormcrows[feature] = variant;
      set(stormcrows, currentStormcrows);
    }, []),
  );
}

export async function fetchStormcrows(): Promise<StormcrowAssignments> {
  const response = await getDefaultUserClient().stormcrowServicerGetAssignments({
    features: stormcrowFeatures.map((feature) => ({feature})),
  });

  const assignments = (response.result.assignments ?? []).flatMap((x) =>
    x.feature !== undefined && x.variant !== undefined ? [[x.feature, x.variant]] : [],
  );

  if (import.meta.env.DEV) {
    const devAssignments = await stormcrowOverrides();
    assignments.push(...Object.entries(devAssignments));
  }

  return Object.fromEntries(assignments);
}

async function stormcrowOverrides() {
  // @ts-ignore - no need to for typescript to error here if
  //              stormcrow-overrides does not exist.
  const res = await import(/* webpackChunkName: 'strm-ovr' */ '../../stormcrow-overrides');
  return res as unknown as Record<string, string>;
}

export const useStormcrows = () => useAtomValue(stormcrows);

// Need to be separate functions due to a bug in swc / jotai:
// https://github.com/pmndrs/swc-jotai/issues/26
function genStormcrowFeatures() {
  return stormcrowFeatures.map((x) => [x, atom((get) => get(stormcrows)[x])] as const);
}

const stormcrowAtoms = genStormcrowFeatures();

function genEnabledAtoms() {
  return stormcrowAtoms.map(([x, atm]) => [x, atom((get) => get(atm) === 'ON')] as const);
}

const enabledAtoms = genEnabledAtoms();

export type StormcrowPascal = PascalCase<StormcrowFeature>;

export const StormcrowValue = Object.fromEntries(
  stormcrowAtoms.map(([x, atm]) => [`use${snakeToPascal(x)}`, () => useAtomValue(atm)]),
) as Record<`use${StormcrowPascal}`, () => string | undefined>;

export const StormcrowIsOn = Object.fromEntries(
  enabledAtoms.map(([x, atm]) => [`use${snakeToPascal(x)}`, () => useAtomValue(atm)]),
) as Record<`use${StormcrowPascal}`, () => boolean>;
