import * as Sentry from '@sentry/react';
import amplitude from 'amplitude-js';
import once from 'lodash/once';

import type {
  EventAttributes,
  EventAttributesMap,
  PAPUserEventAttributes,
  UserEventAttributes,
} from '~/lib/logging/logger_types';

import {globalLoggingSwitch} from './global_logging_switch';
import {getCurrentUser} from '../client';

// This should only happen one time on page load, no matter how many
// ProxiedPapLogger clients we instantiate.
const initializeFastTrackAmplitudeOnce = once(() => {
  const amplitudeKey =
    // Check the hostname in addition to the NODE_ENV or we will end up
    // logging staging events to the production project
    import.meta.env.PROD && window.location.hostname === 'replay.dropbox.com'
      ? // 'Replay - new new tech stack (prod)' Amplitude project
        '29b82eded5767d31e1045138f993bc14'
      : // 'Replay - new new tech stack (dev)' Amplitude project
        '3ebb901138db228cae3ee4c7b4575af8';

  amplitude.getInstance('pap').init(amplitudeKey, '', {
    apiEndpoint:
      import.meta.env.VITE_PAP_ENDPOINT ?? 'www.dropbox.com/amplitude_proxy/ingest_lenient',
    // Disable cookies & localStorage so that Amplitude SDK only stores data in memory
    // Amplitude string gets cleared on logout
    storage: globalLoggingSwitch.canUseStorage() ? 'localStorage' : 'none',
    saveEvents: globalLoggingSwitch.canUseStorage() ? true : false,
    trackingOptions: {
      ip_address: false,
    },
  });

  amplitude.getInstance('pap').setOptOut(false);

  // If we don't have a Sentry User ID, use the Amplitude Device ID
  const existingSentryId = Sentry.getIsolationScope().getUser()?.id;
  if (!existingSentryId) {
    Sentry.setUser({id: amplitude.getInstance('pap').getDeviceId()});
  }

  globalLoggingSwitch.addEventListener('storageEnabled', () => {
    amplitude.getInstance('pap').options.saveEvents = true;
    amplitude.getInstance('pap').options.storage = 'localStorage';
  });
  globalLoggingSwitch.addEventListener('storageDisabled', () => {
    amplitude.getInstance('pap').options.saveEvents = false;
    amplitude.getInstance('pap').options.storage = 'none';
  });
});

/**
 * This logger logs to PAP using `ingest_lenient` while we
 * migrate to a first-class PAP client.
 *
 * @deprecated use PAP logger instead {@link ../logging/logger#LoggingClient}
 */
export class ProxiedPapLogger {
  constructor(activeUserAttributes: UserEventAttributes) {
    initializeFastTrackAmplitudeOnce();
  }

  logEvent<E extends keyof typeof EventAttributesMap>(
    eventAttributes: EventAttributes,
    eventType: E,
    attributes:
      | undefined
      | {
          [key: string]: string | number | undefined;
        },
  ) {
    const currentUser = getCurrentUser();
    const userId = currentUser !== undefined ? `${currentUser.id}` : null;

    // TODO: Get Team ID in here too
    const user_properties: PAPUserEventAttributes = {
      user_id: userId || undefined,
      is_test_user: eventAttributes.userAttributes.isTestUser,
      is_team_admin: eventAttributes.userAttributes.isTeamAdmin,
      user_agent: eventAttributes.userAttributes.userAgent,
      active_team_size: `${eventAttributes.userAttributes.activeTeamSize}`,
      sku_name: eventAttributes.userAttributes.skuName,
      locale: eventAttributes.userAttributes.locale,
      storage_enabled: eventAttributes.userAttributes.storageEnabled,
      provision_tier: eventAttributes.userAttributes.provision_tier || 'null',
      provision_user_variant: eventAttributes.userAttributes.provision_user_variant || 'null',
    };

    const eventProperties = {
      user_id: userId,
      device_id: amplitude.getInstance('pap').getDeviceId(),
      session_id: amplitude.getInstance('pap').getSessionId(),
      video_id: eventAttributes.videoId,
      project_id: eventAttributes.projectId,
      user_properties: user_properties,
      action_surface: eventAttributes.surface,
      reel_type: eventAttributes.reelType,
      is_live_review: eventAttributes.isLiveReview,
      browse_location: eventAttributes.browseLocation,
      runtime_bundle_version: eventAttributes.runtimeBundleVersion,
      main_bundle_version: eventAttributes.mainBundleVersion,
      stormcrows: eventAttributes.userAttributes.stormcrows,
      ...attributes,
    };
    amplitude.getInstance('pap').logEvent(eventType, eventProperties);
  }
}
