import type {IntlConfig} from 'react-intl';
import {createIntl, ReactIntlErrorCode} from 'react-intl';

import {ReplayError, ReplayErrorCategory, reportException} from './error_reporting';

export enum Locale {
  Danish = 'da-DK',
  German = 'de',
  AmericanEnglish = 'en-US',
  BritishEnglish = 'en-GB',
  EuropeanSpanish = 'es-ES',
  Spanish = 'es',
  French = 'fr',
  CanadianFrench = 'fr-CA',
  Indonesian = 'id',
  Italian = 'it',
  Japanese = 'ja',
  Korean = 'ko',
  Malay = 'ms',
  Norwegian = 'nb-NO',
  Dutch = 'nl-NL',
  Polish = 'pl',
  BrazilianPortuguese = 'pt-BR',
  Russian = 'ru',
  Swedish = 'sv-SE',
  Thai = 'th-TH',
  Ukrainian = 'uk-UA',
  ChineseSimplified = 'zh-CN',
  ChineseTraditional = 'zh-TW',
  LongString = 'xx-LS',
}

async function importLocale(locale: Locale) {
  if (locale != Locale.AmericanEnglish) {
    return await import(
      /* webpackChunkName: 'i18n-[request]' */ `../../lang-compiled/${locale}.json`
    );
  }
  // Use default strings if locale is 'en-US'
  return Promise.resolve({default: {}});
}

/**
 * Function that normalizes some differently formatted locale strings that we might receive
 * zh-Hans -> zh-CN
 * en_US -> en-US
 * Taken from Paper's i18n implementation
 */
export const getNormalizedLocale = function normalizeLocale(locale: string) {
  let normalized = locale.replace('_', '-');

  // Make sure the case is correct, it should be lower-UPPER
  if (normalized.indexOf('-') > 0) {
    const split = normalized.split('-');
    normalized = `${split[0].toLowerCase()}-${split[1].toUpperCase()}`;
  }

  // Chinese special case - iOS sends these strings for simplified/traditional Chinese
  // zh-Hans vs. zh-Hant is technically a language difference, while zh-CN vs zh-TW is a locale difference
  // But this is the best guess we can make (and the only one that produces the right languages).
  if (normalized.toLowerCase().indexOf('zh-hans') != -1) {
    normalized = 'zh-CN';
  } else if (normalized.toLowerCase().indexOf('zh-hant') != -1) {
    normalized = 'zh-TW';
  }

  return normalized;
};

export const getValidatedLocale = (locale: string) => {
  locale = getNormalizedLocale(locale);

  const [localeLanguage] = locale.split('-');

  const locales: Record<string, Locale> = {
    da: Locale.Danish,
    de: Locale.German,
    en: Locale.AmericanEnglish,
    'en-GB': Locale.BritishEnglish,
    es: Locale.Spanish,
    'es-ES': Locale.EuropeanSpanish,
    fr: Locale.French,
    'fr-CA': Locale.CanadianFrench,
    id: Locale.Indonesian,
    it: Locale.Italian,
    ja: Locale.Japanese,
    ko: Locale.Korean,
    ms: Locale.Malay,
    nb: Locale.Norwegian,
    nl: Locale.Dutch,
    pl: Locale.Polish,
    pt: Locale.BrazilianPortuguese,
    ru: Locale.Russian,
    sv: Locale.Swedish,
    th: Locale.Thai,
    uk: Locale.Ukrainian,
    zh: Locale.ChineseSimplified,
    'zh-TW': Locale.ChineseTraditional,
    'xx-LS': Locale.LongString,
  };

  return locales[locale] ?? locales[localeLanguage] ?? Locale.AmericanEnglish;
};

// this is actually the wrong type - we should fix it to be Record<string, object[]>
type UiStringsFile = {default: Record<string, string>};

const loadUiStrings = async (locale: Locale) => {
  try {
    const {default: uiStrings}: UiStringsFile = await importLocale(locale);
    return uiStrings;
  } catch (e) {
    const msg = `Failed to load translations for locale ${locale}`;
    console.error(msg, e);
    reportException(
      new ReplayError({
        severity: 'non-critical',
        category: ReplayErrorCategory.NetworkRequestFailure,
        message: msg,
        error: e,
      }),
    );
    return {};
  }
};

export const getI18nLocale = (locale?: string) => {
  if (!locale) {
    return Locale.AmericanEnglish;
  }
  const validatedLocale = getValidatedLocale(locale);
  return validatedLocale === 'en-US' ? Locale.AmericanEnglish : validatedLocale;
};

export const loadLocaleData = async (locale: Locale, defaultLocaleData: Record<string, string>) => {
  if (locale === 'en-US') {
    return defaultLocaleData;
  }

  return await loadUiStrings(locale);
};

export const onIntlError: IntlConfig['onError'] = (err) => {
  if (err.code === ReactIntlErrorCode.MISSING_TRANSLATION) {
    // These warnings are to be expected until the full translation pipeline is
    // complete and we are ingesting translations into the app
    return;
  }

  console.error(err);

  reportException(
    new ReplayError({
      error: err,
      severity: err.code === ReactIntlErrorCode.FORMAT_ERROR ? 'critical' : 'non-critical',
      tags: ['i18n'],
      category: ReplayErrorCategory.TranslationError,
    }),
  );
};

export const createDefaultIntl = () =>
  createIntl({
    locale: 'en',
    messages: {},
    defaultLocale: 'en-US',
    onError: onIntlError,
  });
