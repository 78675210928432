import React from 'react';

import styled from 'styled-components';

import type {TextInputRefObject} from '@dropbox/dig-components/text_fields';
import {Text} from '@dropbox/dig-components/typography';
import {UIIcon} from '@dropbox/dig-icons';
import {HideLine, ShowLine, WarningLine} from '@dropbox/dig-icons/assets';

import {IconButton} from '~/components/button';

import {ReplayTextInput} from './text_input';

type PasswordInputProps = {
  disabled: boolean;
  error?: string;
  placeholder: string;
  onChange: (value: string) => void;
  onEnter?: () => void;
};

const InputGroup = styled.div`
  position: relative;
`;

const InputHelperText = styled.p`
  align-items: top;
  color: var(--dig-color__alert__base);
  display: flex;
  justify-content: start;
  gap: 3px;
  margin: 0;
  white-space: nowrap;
  margin-top: 20px;
  max-width: 100%;

  & svg {
    position: relative;
    top: 2px;
    min-width: 16px;
  }
`;

export const PasswordInput = (props: PasswordInputProps) => {
  const {disabled, error, onChange, onEnter, placeholder} = props;
  const [isTextVisible, setIsTextVisible] = React.useState<boolean>(false);
  const passwordRef = React.useRef<TextInputRefObject | null>(null);
  const toggleVisibility = React.useCallback(() => {
    setIsTextVisible((previous) => !previous);
    setTimeout(() => passwordRef?.current?.setSelectionRange(1000, 1000), 0); // Timeout is required to work in Chrome
    passwordRef?.current?.focus();
  }, []);
  const inputType = isTextVisible ? 'text' : 'password';

  return (
    <InputGroup>
      <ReplayTextInput
        disabled={disabled}
        onChange={(e) => onChange(e.target.value)}
        onEnter={onEnter}
        placeholder={placeholder}
        ref={passwordRef}
        type={inputType}
        withRightAccessory={
          <IconButton onClick={toggleVisibility} size="small" variant="transparent">
            <UIIcon src={isTextVisible ? ShowLine : HideLine} />{' '}
          </IconButton>
        }
      />
      {error && (
        <InputHelperText>
          <UIIcon size="small" src={WarningLine} />
          <Text color="error" size="small">
            {error}
          </Text>
        </InputHelperText>
      )}
    </InputGroup>
  );
};
