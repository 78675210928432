import './lib/logging/sentry'; // <--- first import (Sentry initialization)
// import './wdyr'; // uncomment to use <--- second import (Why Did You Render initialization)

import {createRoot} from 'react-dom/client';
import Modal from 'react-modal';

// eslint-disable-next-line import/order
import App from './App';

import '@dropbox/dig-fonts/dist/index.min.css';
import '@dropbox/dig-fonts/dist/sharp_grotesk_23.min.css';
import '@dropbox/dig-foundations/dist/tokens.css';
// eslint-disable-next-line no-restricted-imports
import '@dropbox/dig-components/dist/index.web.css';
import '@dropbox/dig-illustrations/dist/index.web.css';
import '@dropbox/dig-foundations/dist/index.css';
import '@dropbox/dpc-ia/index.css';
import './index.css';

import {dropboxSdk} from './lib/client';
import {initializePrivacyModule} from './lib/initialize_privacy_module';
import {replayStorage} from './lib/storage';

dropboxSdk.initialize();
Modal.setAppElement('#root');

const container = document.getElementById('root')!;
const root = createRoot(container);
root.render(<App />);
initializePrivacyModule(replayStorage);
