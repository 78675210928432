import type {LocaleData} from 'javascript-time-ago';
import TimeAgo from 'javascript-time-ago';
import type {IntlShape} from 'react-intl';

type DateTimeFormatOptions = Intl.DateTimeFormatOptions;

// Languages based on the Dropbox Supported languages https://app.dropboxer.net/binder/ybdbwD0RHk5Sx1OQ0LG60/dropbox_languages
// Some languages do not map directly to a locale in TimeAgo (e.g. TimeAgo has different zh options than zh_CN and zh_TW), so the general option is used in those cases.

export const localeToTimeAgoMapping: {[key: string]: string} = {
  'da-DK': 'da',
  de: 'de',
  en: 'en',
  'en-GB': 'en-GB',
  es: 'es',
  'es-ES': 'es',
  fr: 'fr',
  'fr-CA': 'fr',
  id: 'id',
  it: 'it',
  ja: 'ja',
  ko: 'ko',
  ms: 'ms',
  'nb-NO': 'no',
  'nl-NL': 'nl',
  pl: 'pl',
  'pt-BR': 'pt',
  ru: 'ru',
  'sv-SE': 'sv',
  'th-TH': 'th',
  'uk-UA': 'uk',
  'zh-CN': 'zh',
  'zh-TW': 'zh',
};

export type Int = number & {__int__: void};
export enum TimeDisplayFormat {
  // Video display formats
  STANDARD = 'standard',
  TIMECODE = 'timecode',
  FRAME = 'frame',

  //Audio display formats
  DECIMAL = 'decimal',
  COMPACT_DISC_75 = 'compact_disk_75',
  SMPTE_30 = 'smpte_30',
  SMPTE_2997 = 'smpte_2997',
  SMPTE_25 = 'smpte_25',
  SMPTE_24 = 'smpte_24',
}

export const audioFrameRates: Record<TimeDisplayFormat, number> = {
  decimal: 1000,
  compact_disk_75: 75,
  smpte_30: 30,
  smpte_2997: 29.97,
  smpte_25: 25,
  smpte_24: 24,
  standard: 0,
  timecode: 0,
  frame: 0,
};

const SECONDS_PER_MINUTE = 60;
const MINUTES_PER_HOUR = 60;
const SECONDS_PER_HOUR = SECONDS_PER_MINUTE * MINUTES_PER_HOUR;

const FRAME_TIME_EPSILON = 0.001;

export const SHOULD_DEBUG_TIMECODE = () => {
  // return true;
  if (typeof window === 'undefined') {
    return false;
  }
  // @ts-ignore
  return !!window.DEBUG_TIMECODE;
};

function padWithZero(n: number) {
  if (n < 10) {
    return `0${n}`;
  }
  return n.toString();
}

function padMillisecondsWithZero(n: number) {
  if (n === 0) {
    return '000';
  }
  if (n < 10) {
    return `00${n}`;
  }
  if (n < 100) {
    return `0${n}`;
  }
  return n.toString();
}

export const getTimeForDisplay = (seconds: number | undefined) => {
  if (seconds === undefined) {
    return '';
  }

  const secondsInt = Math.floor(seconds);
  const displaySeconds = secondsInt % 60;
  const totalMinutes = Math.floor(secondsInt / 60);
  const displayMinutes = totalMinutes % 60;
  const displayHours = Math.floor(totalMinutes / 60);
  const digits = [
    displayHours > 0 ? padWithZero(displayMinutes) : displayMinutes.toString(),
    padWithZero(displaySeconds),
  ];
  if (displayHours > 0) {
    digits.unshift(displayHours.toString());
  }
  const displayMilliseconds = Math.round((seconds - secondsInt) * 1000); // 3 decimal places only
  const displayMillisecondsString = padMillisecondsWithZero(displayMilliseconds);

  return `${digits.join(':')}.${displayMillisecondsString}`;
};

export const getFrameForDisplay = (seconds: number, frameRate: number) => {
  return getFrameFromTime(seconds, frameRate).toString();
};

export const getUploadedTimeForDisplay = (
  date: Date,
  intl: IntlShape,
  timeAgoLocale: LocaleData,
  now = new Date(),
): string => {
  return getTimeSinceForDisplay(date, intl, timeAgoLocale, false, now, true);
};

const isSameDay = (pastTime: Date, now: Date) => {
  return (
    pastTime.getFullYear() === now.getFullYear() &&
    pastTime.getMonth() === now.getMonth() &&
    pastTime.getDate() === now.getDate()
  );
};

const isWithinOneDay = (pastTime: Date, now: Date) => {
  const oneDayMs = 1 * 24 * 60 * 60 * 1000;
  const oneDayBefore = new Date(now.getTime() - oneDayMs);
  const startOfOneDayBefore = new Date(
    oneDayBefore.getFullYear(),
    oneDayBefore.getMonth(),
    oneDayBefore.getDate(),
  );
  return pastTime.getTime() >= startOfOneDayBefore.getTime();
};

const isWithinOneWeek = (pastTime: Date, now: Date) => {
  const sixDaysMs = 6 * 24 * 60 * 60 * 1000;
  const sixDaysBefore = new Date(now.getTime() - sixDaysMs);
  const startOfSixDaysBefore = new Date(
    sixDaysBefore.getFullYear(),
    sixDaysBefore.getMonth(),
    sixDaysBefore.getDate(),
  );
  return pastTime.getTime() >= startOfSixDaysBefore.getTime();
};

const isWithinOneYear = (pastTime: Date, now: Date) => {
  const oneYearMs = 365 * 24 * 60 * 60 * 1000; // Assuming a year has 365 days
  const oneYearBefore = new Date(now.getTime() - oneYearMs);
  const startOfOneYearBefore = new Date(
    oneYearBefore.getFullYear(),
    oneYearBefore.getMonth(),
    oneYearBefore.getDate(),
  );
  return pastTime.getTime() >= startOfOneYearBefore.getTime();
};

export const getTimeSinceForDisplay = (
  date: Date,
  intl: IntlShape,
  timeAgoLocaleData: LocaleData,
  skipRelativeFormat: boolean = false,
  now: Date = new Date(),
  isUpdatedAtText: boolean = false, // The time will be used in the format "Updated at [time]". This is technically not the right way to localize, but leaving it for now as it is not that simple to format properly.
): string => {
  const options: DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
  };
  const locale = intl.locale;
  const time = date.toLocaleTimeString(locale, options);
  const justNowText = intl.formatMessage({
    defaultMessage: 'Just now',
    id: 'Dykp1d',
    description: 'Text describing something that happened recently within the last minute.',
  });
  const yesterdayText = intl.formatMessage(
    {
      defaultMessage: 'Yesterday, {time}',
      id: 'Kha0bM',
      description: 'Text describing something that happened yesterday at a specific time.',
    },
    {time: time},
  );

  if (!skipRelativeFormat) {
    if (isSameDay(date, now)) {
      const timeAgo = new TimeAgo(timeAgoLocaleData.locale);
      const result = timeAgo.format(date, 'round-minute', {now: now.getTime()});
      // Formatting the "just now" from timeAgo is a little complicated now that it is localized
      // In English only, we use uppercase "Just now", and lowercase when it's in the form "Updated just now"
      if (!isUpdatedAtText && (result === 'just now' || result === 'now')) {
        return justNowText;
      }
      return result;
    }

    if (isWithinOneDay(date, now)) {
      return yesterdayText;
    }

    if (isWithinOneWeek(date, now)) {
      const dayName = date.toLocaleDateString(locale, {weekday: 'long'});
      return `${dayName}, ${time}`;
    }
  }

  const dateString = intl.formatDate(date, {month: 'short', day: 'numeric', year: 'numeric'});
  return `${dateString}, ${time}`;
};

export const getTimeSinceForCommentDisplay = (
  date: Date,
  intl: IntlShape,
  timeAgoLocaleData: LocaleData,
  skipRelativeFormat: boolean = false,
  now: Date = new Date(),
  isUpdatedAtText: boolean = false, // The time will be used in the format "Updated at [time]". This is technically not the right way to localize, but leaving it for now as it is not that simple to format properly.
): string => {
  const justNowText = intl.formatMessage({
    defaultMessage: 'Just now',
    id: 'Dykp1d',
    description: 'Text describing something that happened recently within the last minute.',
  });
  const yesterdayText = intl.formatMessage({
    defaultMessage: 'Yesterday',
    id: 'bvMuvK',
    description: 'Text describing something that happened yesterday at a specific time.',
  });

  const getHowLongSinceText = (formattedTime: string) =>
    intl.formatMessage(
      {
        defaultMessage: '{formatted_time} ago',
        id: 'piNU1g',
        description: 'End text describing that something happened earlier than the present time.',
      },
      {
        formatted_time: formattedTime,
      },
    );

  // To work around missing 'mini' formatting in en-GB, use en instead.
  const locale = timeAgoLocaleData.locale === 'en-GB' ? 'en' : timeAgoLocaleData.locale;

  if (!skipRelativeFormat) {
    if (isSameDay(date, now)) {
      const timeAgo = new TimeAgo(locale);
      const result = timeAgo.format(date, 'mini-minute', {now: now.getTime()});

      if (!isUpdatedAtText && result === '0m') {
        return justNowText;
      }
      return getHowLongSinceText(result);
    }

    if (isWithinOneDay(date, now)) {
      return yesterdayText;
    }

    if (isWithinOneYear(date, now)) {
      const timeAgo = new TimeAgo(locale);
      const result = timeAgo.format(date, 'mini-minute', {now: now.getTime()});
      return getHowLongSinceText(result);
    }
  }

  return intl.formatDate(date, {month: 'short', day: 'numeric', year: 'numeric'});
};

export const getFrameFromTime = (timeSecs = 0, frameRate: number): Int => {
  const frame = Math.floor(timeSecs * frameRate + FRAME_TIME_EPSILON) as Int;
  if (SHOULD_DEBUG_TIMECODE()) {
    console.debug(`timeSecs ${timeSecs} * frameRate ${frameRate} = ${frame}`);
  }
  return frame;
};

export const getTimeFromFrame = (frame: Int, frameRate: number, duration?: number): number => {
  if (isNaN(frame) || isNaN(frameRate)) {
    return 0;
  }
  const frameDuration = 1.0 / frameRate;
  // When we navigate to a time for a frame, we need to make sure we are at a time > than the time
  // for that frame (frame # * time duration), -- we could add half a frame duration, however we want
  // to be extra safe from any decoder that might decide to round up or merge the next frame so we add
  // 20% of a frame duration.
  const frameTime = (frame as number) * frameDuration + 0.2 * frameDuration;
  if (!duration || isNaN(duration)) {
    return Math.max(0, frameTime);
  }
  // Make sure the time is within the duration
  return Math.max(0, Math.min(frameTime, duration));
};

export const getCanonicalFrameTimeForTime = (
  timeSecs: number,
  frameRate: number,
  duration = 0,
): number => {
  const frame = getFrameFromTime(timeSecs, frameRate);
  return getTimeFromFrame(frame, frameRate, duration);
};

function formatTimeCode(
  hours: number,
  minutes: number,
  seconds: number,
  frames?: number,
  dropFrame: boolean = false,
) {
  let result: string;
  if (hours > 0) {
    result = `${padWithZero(hours)}:${padWithZero(minutes)}:${padWithZero(seconds)}`;
  } else {
    result = `${padWithZero(minutes)}:${padWithZero(seconds)}`;
  }
  if (frames !== undefined) {
    result = result + (dropFrame ? ';' : ':') + `${padWithZero(frames)}`;
  }
  return result;
}

function getDropFrameTimeCode(timeInSeconds: number, frameRate: number, debug: boolean = false) {
  /*
  Drop frame skips the first two frame counts in the timecode on the first second in each minute,
  but not if the minute count is divisible by ten

  Calculations are from here: http://andrewduncan.net/timecodes/
  */

  // const adjustedTimeInSeconds = (timeInSeconds * Math.round(frameRate)) / frameRate;

  const frameNumber = getFrameFromTime(timeInSeconds, frameRate);
  const roundedFrameRate = Math.round(frameRate);
  let dropFrames = 0;
  const framesPer10Mins = Math.trunc(frameRate * 60 * 10);
  const framesPerMinute = Math.trunc(framesPer10Mins / 10);

  // Only 30fps and 60fps have a DF mode (24 DF does not exist)
  if (roundedFrameRate === 30) {
    dropFrames = 2;
  } else if (roundedFrameRate === 60) {
    dropFrames = 4;
  }

  const D = Math.trunc(frameNumber / framesPer10Mins);
  const M = frameNumber % framesPer10Mins;

  const droppedFrameNumber =
    frameNumber + 9 * dropFrames * D + dropFrames * Math.trunc((M - dropFrames) / framesPerMinute);

  const ff = droppedFrameNumber % roundedFrameRate;
  const ss = Math.trunc(droppedFrameNumber / roundedFrameRate) % 60;
  const mm = Math.trunc(droppedFrameNumber / (roundedFrameRate * 60)) % 60;
  const hh = Math.trunc(droppedFrameNumber / (roundedFrameRate * 3600)) % 24;

  const formatted = formatTimeCode(hh, mm, ss, ff, true);

  if (debug) {
    console.debug(
      `Converting timeSec ${timeInSeconds} to DF Timecode ${frameRate}fps
    frameNumber ${frameNumber}
    dropFrames ${dropFrames} D ${D} M ${M} droppedFrameNumber ${droppedFrameNumber}
    DFTimecode ${formatted}
    `,
    );
  }
  return formatted;
}

export function getSecondsFromTimecode(timecodeString: string, frameRate: number) {
  var timecodeComponents = timecodeString.match('^(\\d\\d):(\\d\\d):(\\d\\d)(:|;|\\.)(\\d\\d)$');

  if (!timecodeComponents) {
    return 0;
  }

  const hours = parseInt(timecodeComponents[1]);
  const minutes = parseInt(timecodeComponents[2]);
  const seconds = parseInt(timecodeComponents[3]);
  const frameIndicator = timecodeComponents[4];
  const frames = parseInt(timecodeComponents[5]);

  let frameCount = 0;
  if (frameIndicator === ';') {
    const timeBase = Math.round(frameRate);
    const hourFrames = timeBase * SECONDS_PER_HOUR;
    const minuteFrames = timeBase * SECONDS_PER_MINUTE;

    const dropFrames = timeBase === 30 ? 2 : 4;
    const totalMinutes = MINUTES_PER_HOUR * hours + minutes;
    frameCount =
      hourFrames * hours +
      minuteFrames * minutes +
      timeBase * seconds +
      frames -
      dropFrames * (totalMinutes - Math.trunc(totalMinutes / 10));
  } else {
    const timeBase = frameRate;
    const hourFrames = timeBase * SECONDS_PER_HOUR;
    const minuteFrames = timeBase * SECONDS_PER_MINUTE;

    frameCount = hourFrames * hours + minuteFrames * minutes + timeBase * seconds + frames;
  }

  return frameCount / frameRate;
}

export const isFractionalFrameRate = (frameRate: number) => {
  return Math.round(frameRate * 1001) % 1000 === 0;
};

export function getTimecodeForDisplay(
  reportedTimeInSeconds: number,
  frameRate: number,
  dropFrames: boolean = false,
  debug: boolean = false,
  timecodeSecondsOffset: number = 0,
) {
  const timeInSeconds = reportedTimeInSeconds + timecodeSecondsOffset;

  if (isNaN(timeInSeconds) || isNaN(frameRate)) {
    return '';
  }
  const roundedFrameRate = Math.round(frameRate);
  if (
    isFractionalFrameRate(frameRate) &&
    (roundedFrameRate === 30 || roundedFrameRate === 60) &&
    dropFrames
  ) {
    // Drop frame (only applies to 30 and 60 fps)
    return getDropFrameTimeCode(timeInSeconds, frameRate, debug);
  } else {
    // Otherwise, compute normally
    const hours = Math.floor(timeInSeconds / SECONDS_PER_HOUR);
    const minutes = Math.floor(timeInSeconds / SECONDS_PER_MINUTE) % MINUTES_PER_HOUR;
    const seconds = Math.floor(timeInSeconds) % SECONDS_PER_MINUTE;
    const subseconds = timeInSeconds - Math.floor(timeInSeconds);
    const frames = getFrameFromTime(subseconds, frameRate);

    return formatTimeCode(hours, minutes, seconds, frames, false);
  }
}

export const convertToTimeFormat = (
  timeInSeconds: number = 0,
  frameRate: number,
  displayFormat: TimeDisplayFormat,
  timecodeSecondsOffset: number = 0,
  debug: boolean = false,
) => {
  if (isNaN(timeInSeconds) || isNaN(frameRate)) {
    return '-';
  }

  switch (displayFormat) {
    case TimeDisplayFormat.TIMECODE:
      // TODO(alan): Let the user switch between DF/NDF Timecode.
      return getTimecodeForDisplay(timeInSeconds, frameRate, true, debug, timecodeSecondsOffset);
    case TimeDisplayFormat.FRAME:
      return getFrameForDisplay(timeInSeconds, frameRate);
    case TimeDisplayFormat.STANDARD:
      return getTimeForDisplay(timeInSeconds);
    case TimeDisplayFormat.DECIMAL:
    case TimeDisplayFormat.COMPACT_DISC_75:
    case TimeDisplayFormat.SMPTE_30:
    case TimeDisplayFormat.SMPTE_2997:
    case TimeDisplayFormat.SMPTE_25:
    case TimeDisplayFormat.SMPTE_24:
    default:
      return getTimecodeForDisplay(timeInSeconds, frameRate, false, debug, timecodeSecondsOffset);
  }
};

export const convertToProToolsTimeFormat = (
  timeInSeconds: number = 0,
  frameRate: number,
  displayFormat: TimeDisplayFormat,
  timecodeSecondsOffset: number = 0,
) => {
  if (displayFormat === TimeDisplayFormat.TIMECODE) {
    return getTimeCodeForProTools(timeInSeconds, frameRate, true, false, timecodeSecondsOffset);
  }
  return getTimeForDisplay(timeInSeconds);
};

const getTimeCodeForProTools = (
  reportedTimeInSeconds: number,
  frameRate: number,
  dropFrames: boolean = false,
  debug: boolean = false,
  timecodeSecondsOffset: number = 0,
) => {
  const defaultTimeCode = getTimecodeForDisplay(
    reportedTimeInSeconds,
    frameRate,
    dropFrames,
    debug,
    timecodeSecondsOffset,
  );
  // Pro Tools doesn't support the ; drop frame indicator
  const withoutDropFrameIndicator = defaultTimeCode.replace(/;/g, ':');
  // Pro Tools expects padded hours in timecode string
  const isPaddedWithHours = (withoutDropFrameIndicator.match(/:/g) || []).length === 3;
  const proToolsTimeCode = isPaddedWithHours
    ? withoutDropFrameIndicator
    : `${padWithZero(0)}:${withoutDropFrameIndicator}`;
  return proToolsTimeCode;
};

export const timeSecToFrameEquality = (time1 = 0, time2: number, frameRate: number) => {
  // TODO(alan): This can probably do frame # comparison, but I don't want to change the calc right before beta.
  return getTimecodeForDisplay(time1, frameRate) === getTimecodeForDisplay(time2, frameRate);
};

export const convertToSrtTimecode = (timeInSeconds: number): string => {
  return convertToFileTimecode(timeInSeconds, ',');
};

export const convertToVttTimecode = (timeInSeconds: number): string => {
  return convertToFileTimecode(timeInSeconds, '.');
};

const convertToFileTimecode = (timeInSeconds: number, delimiter: string): string => {
  if (isNaN(timeInSeconds)) {
    return '';
  }

  const hours = Math.floor(timeInSeconds / SECONDS_PER_HOUR);
  const minutes = Math.floor(timeInSeconds / SECONDS_PER_MINUTE) % MINUTES_PER_HOUR;
  const seconds = Math.floor(timeInSeconds) % SECONDS_PER_MINUTE;
  const milliseconds = Math.floor((timeInSeconds - Math.floor(timeInSeconds)) * 1000);

  return `${padWithZero(hours)}:${padWithZero(minutes)}:${padWithZero(
    seconds,
  )}${delimiter}${padMillisecondsWithZero(milliseconds)}`;
};

export function getDateTimeForDisplay(date: Date | string | undefined, locale: string) {
  if (!date) {
    return '';
  }

  if (typeof date === 'string') {
    date = new Date(date);
  }

  return date.toLocaleString(locale, {
    weekday: 'short',
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZoneName: 'short',
  });
}
