import {useMemo} from 'react';

import {defineMessages, FormattedMessage, useIntl} from 'react-intl';
import styled, {css} from 'styled-components';

import {IconButtonGroup} from '@dropbox/dig-components/combinations';
import {Link, Text} from '@dropbox/dig-components/typography';
import type {BoxProps} from '@dropbox/dig-foundations';
import {Box, Split, ThemeContainer, ThemeProvider} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {ChevronUpLine, CloseLine} from '@dropbox/dig-icons/assets';

import {IconButton} from '~/components/button';
import {color, spacing} from '~/components/styled';
import {StatusIcon} from '~/components/upload_drawer/status_icon';
import type {ProvisionCtaType} from '~/lib/provisions';
import {UploadDrawerStatus} from '~/lib/uploads/types';
import type {CalculatedUploadValues} from '~/lib/uploads/types';

const StatusBox = styled(Box)`
  z-index: 14;
`;

const DrawerHandleBox = styled(Box)`
  right: ${spacing('16')};

  svg {
    color: ${color('Primary On Base')};
  }
`;

type ContentBoxProps = BoxProps<any> & {
  $statusColor: string;
  $statusProgress: number;
};
const ContentBox = styled(Box)<ContentBoxProps>`
  position: relative;
  transition: all 0.5s ease-in-out;

  & > * {
    position: relative;
  }

  ${({$statusColor, $statusProgress}) => css`
    &:before {
      background-color: ${$statusColor};
      content: '';
      height: 100%;
      transition: all 0.5s ease-in-out;
      position: absolute;
      top: 0;
      left: 0;
      width: ${$statusProgress}%;
    }
  `}
`;

export type ManageAddonsLink = ProvisionCtaType & {
  onClickLink: () => void;
};

type StatusBarProps = {
  calculatedUploadValues: CalculatedUploadValues;
  drawerUploadStatus: UploadDrawerStatus;
  isDrawerOpen: boolean;
  manageAddonsLink?: ManageAddonsLink;
  handleOnClose?: () => void;
  handleOnCollapse: () => void;
};

const StatusBar = ({
  calculatedUploadValues,
  drawerUploadStatus,
  isDrawerOpen,
  manageAddonsLink,
  handleOnClose,
  handleOnCollapse,
}: StatusBarProps) => {
  const intl = useIntl();
  const {
    backgroundColor,
    statusTitle,
    statusSubtitle,
    statusSubtitleLinkHref,
    statusSubtitleLinkCopy,
    statusSubtitleLinkOnClick,
  } = useMemo(() => {
    const {numErrors, numTotalUploads, numUploading, numSuccessful} = calculatedUploadValues;
    const defaultValues = {
      backgroundColor: color('Primary Base'),
      statusTitle: intlMessages.uploadingFallbackTitle,
      statusSubtitle: undefined,
      statusSubtitleLinkHref: undefined,
      statusSubtitleLinkCopy: undefined,
      statusSubtitleLinkOnClick: undefined,
    };

    switch (drawerUploadStatus) {
      case UploadDrawerStatus.ALL_FAILED:
        return {
          ...defaultValues,
          backgroundColor: color('Alert Base'),
          statusTitle: intlMessages.failedTitle,
          statusSubtitle: intlMessages.failedSubtitle,
        };
      case UploadDrawerStatus.SOME_FAILED:
        return {
          ...defaultValues,
          backgroundColor: color('Alert Base'),
          statusTitle: intlMessages.someFailedTitle,
          statusSubtitle: {
            ...intlMessages.someFailedSubtitle,
            values: {
              numFailed: numErrors,
              numTotal: numTotalUploads,
            },
          },
        };
      case UploadDrawerStatus.FILE_QUOTA_EXCEEDED:
        return {
          ...defaultValues,
          backgroundColor: color('Alert Base'),
          statusTitle: intlMessages.failedUploadLimitTitle,
          statusSubtitle: intlMessages.failedUploadLimitSubtitle,
          statusSubtitleLinkHref: manageAddonsLink?.ctaLink,
          statusSubtitleLinkCopy: manageAddonsLink?.ctaLabel,
          statusSubtitleLinkOnClick: manageAddonsLink?.onClickLink,
        };
      case UploadDrawerStatus.SUCCESS:
        return {
          ...defaultValues,
          backgroundColor: color('Success Base'),
          statusTitle: intlMessages.successTitle,
          statusSubtitle: intlMessages.successSubtitle,
        };
      case UploadDrawerStatus.UPLOADING:
        return {
          ...defaultValues,
          backgroundColor: color('Primary Base'),
          statusTitle: {
            ...intlMessages.uploadingTitle,
            values: {
              numUploading: numUploading + numSuccessful + numErrors,
              numTotalUploads,
            },
          },
        };
      case UploadDrawerStatus.CANCELED:
        return {
          ...defaultValues,
          backgroundColor: color('Warning Base'),
          statusTitle: intlMessages.canceledTitle,
          statusSubtitle: intlMessages.canceledSubtitle,
        };
      default:
        return defaultValues;
    }
  }, [drawerUploadStatus, calculatedUploadValues, manageAddonsLink]);

  const statusProgress = useMemo(() => {
    const {totalPercentage, numTotalUploads} = calculatedUploadValues;
    if (drawerUploadStatus === UploadDrawerStatus.UPLOADING) {
      return totalPercentage / numTotalUploads;
    }
    return 100;
  }, [calculatedUploadValues, drawerUploadStatus]);

  return (
    <StatusBox
      alignItems="center"
      backgroundColor="Background Base"
      borderBottom="Solid"
      borderLeft="Solid"
      borderRight="Solid"
      borderTop={!isDrawerOpen && 'Solid'}
      color="Primary On Base"
      display="flex"
      justifyContent="space-between"
      paddingX="8"
      paddingY="8"
      position="absolute"
      width="100%"
    >
      <ThemeProvider mode="bright" theme="vis2020">
        <ThemeContainer>
          <Box display="flex" justifyContent="center" width="100%">
            <div>
              <StatusIcon status={drawerUploadStatus} />
            </div>

            <ContentBox
              $statusColor={backgroundColor}
              $statusProgress={statusProgress}
              color="Primary On Base"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              paddingX="12"
              paddingY="8"
              width="100%"
            >
              <Text color="inherit" isBold size="medium">
                <FormattedMessage {...statusTitle} />
              </Text>

              {statusSubtitle && (
                <Split gap={spacing('4')}>
                  <Split.Item>
                    <Text color="inherit" size="small">
                      <FormattedMessage {...statusSubtitle} />
                    </Text>
                  </Split.Item>

                  <Split.Item>
                    {statusSubtitleLinkCopy && statusSubtitleLinkHref && (
                      <Text color="inherit" size="small">
                        <Link
                          href={statusSubtitleLinkHref}
                          onClick={statusSubtitleLinkOnClick}
                          target="_blank"
                          variant="monochromatic"
                        >
                          {statusSubtitleLinkCopy}
                        </Link>
                      </Text>
                    )}
                  </Split.Item>
                </Split>
              )}
            </ContentBox>
          </Box>

          {!isDrawerOpen && (
            <DrawerHandleBox position="absolute">
              <IconButtonGroup>
                {({getButtonProps}) => (
                  <>
                    <IconButton
                      aria-label={intl.formatMessage(intlMessages.expandDrawerLabel)}
                      variant="borderless"
                      {...getButtonProps()}
                      onClick={handleOnCollapse}
                    >
                      <UIIcon src={ChevronUpLine} />
                    </IconButton>

                    {!isDrawerOpen && handleOnClose && (
                      <IconButton
                        aria-label={intl.formatMessage(intlMessages.closeDrawerLabel)}
                        data-testid="close-drawer-button"
                        variant="borderless"
                        {...getButtonProps()}
                        onClick={handleOnClose}
                      >
                        <UIIcon src={CloseLine} />
                      </IconButton>
                    )}
                  </>
                )}
              </IconButtonGroup>
            </DrawerHandleBox>
          )}
        </ThemeContainer>
      </ThemeProvider>
    </StatusBox>
  );
};

export {StatusBar};

export const intlMessages = defineMessages({
  uploadingTitle: {
    defaultMessage: 'Uploading {numUploading} of {numTotalUploads} items',
    id: 'a1WDrZ',
    description: 'Description saying how many items are uploading',
  },
  successTitle: {
    defaultMessage: 'Upload successful!',
    id: 'FQ60no',
    description: 'Description that all uploads have been successful',
  },
  successSubtitle: {
    defaultMessage: 'All items successfully uploaded.',
    id: 'z5+kLl',
    description: 'Subtitle that all uploads have been uploaded',
  },
  someFailedTitle: {
    defaultMessage: 'Some uploads failed',
    id: '9aIJ6s',
    description: 'Title description saying some items failed',
  },
  someFailedSubtitle: {
    defaultMessage: '{numFailed} of {numTotal} items failed to upload',
    id: 'qyXUHN',
    description: 'Subtitle description saying how many items failed',
  },
  failedTitle: {
    defaultMessage: 'Upload failed',
    id: '99gIwS',
    description: 'Description that all attempted uploads failed',
  },
  failedSubtitle: {
    defaultMessage: 'Darn it! An error has occurred',
    id: 'xnHi8a',
    description: 'Subtitle description saying uploads have failed',
  },
  failedUploadLimitTitle: {
    defaultMessage: 'Out of uploads',
    id: 'RDcCzK',
    description: 'Description that user does not have quota count to upload files',
  },
  failedUploadLimitSubtitle: {
    defaultMessage: "You've reached your upload limit.",
    id: '6850FN',
    description: 'Description that user does not have quota count to upload files',
  },
  uploadingFallbackTitle: {
    defaultMessage: 'Uploading',
    id: 'YkeJ1X',
    description: 'Generic description on uploading',
  },
  canceledTitle: {
    defaultMessage: 'Uploads canceled',
    id: '9GWsLS',
    description: 'Title description that user has canceled all files',
  },
  canceledSubtitle: {
    defaultMessage: 'All uploads were canceled',
    id: 'aT0TTs',
    description: 'Subtitle description that user has canceled all files',
  },
  collapseDrawerLabel: {
    defaultMessage: 'Collapse the upload drawer',
    id: 'FghfPJ',
    description: 'label for button that collapses the upload drawer',
  },
  expandDrawerLabel: {
    defaultMessage: 'Expand the upload drawer',
    id: 'sPnGC2',
    description: 'label for button that expands the upload drawer',
  },
  closeDrawerLabel: {
    defaultMessage: 'Close',
    id: 'wuicYV',
    description: 'label for button that closes the upload drawer',
  },
});
