export type BrandingLogoVariant = 'none' | 'director' | 'reel' | 'slate' | 'camera';
export type BrandingThemeVariant = 'none' | 'lime' | 'tangerine' | 'rust' | 'zen';
export type BrandingThemeColorVariant = Exclude<BrandingThemeVariant, 'none'>;

export interface CustomLogo {
  type: 'custom';
  url?: string;
  file_id: string;
}

export interface DefaultLogo {
  type: 'default';
  variant: BrandingLogoVariant;
}

interface CustomTheme {
  type: 'custom';
  primary_color: string;
}

// All custom colors from outside DIG on purpose. Design was given free rein to invent!
export const themeVariantMap: Record<BrandingThemeVariant, string> = {
  lime: 'linear-gradient(rgba(0, 0, 0, 0.7) 0 0), linear-gradient(180deg, #FFEA2B 0%, #2D6519 100%)',
  tangerine:
    'linear-gradient(rgba(0, 0, 0, 0.7) 0 0), linear-gradient(180deg, #FFD02B 0%, #FF6B00 100%)',
  rust: 'linear-gradient(rgba(0, 0, 0, 0.7) 0 0), linear-gradient(180deg, #FFC371 0%, #FF5F6D 100%)',
  zen: 'linear-gradient(180deg, rgba(2, 170, 189, 0.30) 0%, rgba(0, 205, 172, 0.30) 100%)',
  none: '',
};

export const backgroundColorFromTheme = (theme?: BrandingTheme): string | undefined => {
  let backgroundColor: string | undefined;
  if (theme?.type === 'default') {
    backgroundColor = themeVariantMap[theme.variant];
  } else if (theme?.type === 'custom') {
    backgroundColor = theme.primary_color;
  }

  return backgroundColor;
};

interface DefaultTheme {
  type: 'default';
  variant: BrandingThemeVariant;
}

export type BrandingTheme = CustomTheme | DefaultTheme;
export type BrandingLogo = CustomLogo | DefaultLogo;

export interface Branding {
  theme: BrandingTheme;
  logo: BrandingLogo;
}
