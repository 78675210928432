import React from 'react';

import type {ClickSource} from 'pap-events/enums/click_source';
import {PAP_Select_ModalAction} from 'pap-events/replay/select_modal_action';
import {PAP_Shown_Modal} from 'pap-events/replay/shown_modal';
import {defineMessages, FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';

import {Modal} from '@dropbox/dig-components/modal';
import {Text, Title} from '@dropbox/dig-components/typography';
import {UIIcon} from '@dropbox/dig-icons';
import {
  AudioLine,
  ClockLine,
  DropboxLine,
  LinkLine,
  LockLine,
  PasswordsLine,
  StampLine,
  TranscriptLine,
  UploadLine,
  VersioningLine,
  WatermarkLine,
} from '@dropbox/dig-icons/assets';

import {CircleAnimation} from '~/components/circle_animation';
import {useReelAppGlobalState} from '~/context';
import type {BillingCycle} from '~/lib/api';
import {useGetBillingCycleQuery, useGetReplayEligibilityQuery} from '~/lib/api_queries';
import type {LoggingClient} from '~/lib/logging/logger';
import type {ProvisionUserVariant} from '~/lib/provisions';
import {SINGLE_PURCHASE_URL, useGetProvisionCtav2, useGetUserVariant} from '~/lib/provisions';
import {usePricingStrings} from '~/lib/subscriptions';
import {useLoggingClient} from '~/lib/use_logging_client';
import type {PremiumFeatureType} from '~/pages/browse_page/types';

import {Button} from './button';
import {type Color, radius} from './styled';
import {color, spacing} from './styled';

const ModalContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${spacing('Micro Large')} 0;
`;

const LineItem = styled(Text)`
  display: flex;
  gap: ${spacing('Micro Small')};
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const IllustrationContainer = styled.div<{$backgroundColor: Color}>`
  background-color: ${(props) => color(props.$backgroundColor)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: ${spacing('100')} ${spacing('Micro Large')};
  border-top-right-radius: ${radius('Large')};
`;

const IllustrationImg = styled.img`
  width: 100%;
`;

export type FeatureItemType =
  | 'due_dates'
  | 'unlimited_file_uploads'
  | 'transcript_and_captions'
  | 'password_protected_links'
  | 'digital_watermarking'
  | 'version_comparison'
  | 'archive_files'
  | 'shared_link_management'
  | 'project_customization'
  | 'lossless_audio'
  | 'team_only_commenting';

const featureSrcIconMap: Record<
  FeatureItemType,
  React.ComponentType<React.SVGAttributes<SVGElement>>
> = {
  due_dates: ClockLine,
  unlimited_file_uploads: UploadLine,
  transcript_and_captions: TranscriptLine,
  password_protected_links: PasswordsLine,
  digital_watermarking: WatermarkLine,
  version_comparison: VersioningLine,
  archive_files: DropboxLine,
  shared_link_management: LinkLine,
  project_customization: StampLine,
  lossless_audio: AudioLine,
  team_only_commenting: LockLine,
};

type ModalLineItemProps = {
  feature: FeatureItemType;
};

const ModalLineItem = ({feature}: ModalLineItemProps) => {
  return (
    <LineItem tagName="p" variant="paragraph">
      <UIIcon src={featureSrcIconMap[feature]} />
      <FormattedMessage {...featureStrings[feature]} />
    </LineItem>
  );
};

const featureListMap: Record<PremiumFeatureType, FeatureItemType[]> = {
  deadlines_and_tasks: [
    'due_dates',
    'unlimited_file_uploads',
    'transcript_and_captions',
    'password_protected_links',
    'digital_watermarking',
  ],
  password_protected_links: [
    'password_protected_links',
    'digital_watermarking',
    'unlimited_file_uploads',
    'transcript_and_captions',
    'version_comparison',
  ],
  archive_to_dbx: [
    'archive_files',
    'unlimited_file_uploads',
    'transcript_and_captions',
    'password_protected_links',
    'digital_watermarking',
  ],
  links_hub: [
    'shared_link_management',
    'password_protected_links',
    'unlimited_file_uploads',
    'transcript_and_captions',
    'digital_watermarking',
  ],
  version_comparison: [
    'version_comparison',
    'unlimited_file_uploads',
    'transcript_and_captions',
    'password_protected_links',
    'digital_watermarking',
  ],
  watermark: [
    'digital_watermarking',
    'password_protected_links',
    'unlimited_file_uploads',
    'transcript_and_captions',
    'version_comparison',
  ],
  custom_branding: [
    'project_customization',
    'digital_watermarking',
    'unlimited_file_uploads',
    'transcript_and_captions',
    'password_protected_links',
  ],
  lossless_audio: [
    'lossless_audio',
    'unlimited_file_uploads',
    'transcript_and_captions',
    'password_protected_links',
    'digital_watermarking',
  ],
  unlimited_file_uploads: [
    'unlimited_file_uploads',
    'transcript_and_captions',
    'digital_watermarking',
    'password_protected_links',
  ],
  team_only_commenting: [
    'team_only_commenting',
    'digital_watermarking',
    'unlimited_file_uploads',
    'transcript_and_captions',
    'password_protected_links',
  ],
};

const backgroundColorMap: Record<PremiumFeatureType, Color> = {
  archive_to_dbx: 'Identity Purple',
  password_protected_links: 'Identity Green',
  deadlines_and_tasks: 'Identity Yellow',
  links_hub: 'Identity Cyan',
  version_comparison: 'Identity Orange',
  watermark: 'Identity Blue',
  custom_branding: 'Identity Cyan',
  lossless_audio: 'Identity Purple',
  unlimited_file_uploads: 'Identity Yellow',
  team_only_commenting: 'Identity Orange',
};

const featureImages: Record<PremiumFeatureType, string> = {
  archive_to_dbx: '/images/archiving.png',
  password_protected_links: '/images/password_protect.png',
  deadlines_and_tasks: '/images/due_dates.png',
  links_hub: '/images/your_links.png',
  version_comparison: '/images/provisions_version_comparison.png',
  watermark: '/images/watermark.png',
  custom_branding: '/images/custom_branding.png',
  lossless_audio: '/images/lossless_audio.png',
  unlimited_file_uploads: '',
  team_only_commenting: '/images/team_only_commenting.png',
};

export type BasicAddOnUpsellModalUIProps = BasicAddOnUpsellModalProps & {
  billingCycle: BillingCycle;
  billingPrice: string;
  userVariant: ProvisionUserVariant;
  isEligible: boolean;
  clickSource?: ClickSource;
  loggingClient: LoggingClient;
};

export const BasicAddOnUpsellModalUI = ({
  variant,
  open,
  onClose,
  billingCycle,
  billingPrice,
  userVariant,
  isEligible,
  clickSource,
  loggingClient,
}: BasicAddOnUpsellModalUIProps) => {
  const intl = useIntl();
  const {individualMonthly, individualYearly, teamMonthly, teamYearly} =
    usePricingStrings(billingPrice);
  const provisionCTA = useGetProvisionCtav2(userVariant, isEligible);
  const ctaLink = `${SINGLE_PURCHASE_URL}?replay_purchase_origin=${variant}`;
  const isUnlimitedFileUploadsVariant = variant === 'unlimited_file_uploads';
  const isBillingUnknown = billingCycle === 'unknown' || billingPrice === '';

  const billingText =
    billingCycle === 'yearly'
      ? userVariant === 'self_serve_team_admin'
        ? teamYearly
        : individualYearly
      : userVariant === 'self_serve_team_admin'
      ? teamMonthly
      : individualMonthly;

  React.useEffect(() => {
    if (open) {
      loggingClient.logPap(
        PAP_Shown_Modal({
          modal: 'feature_upsell',
          replayFeature: variant,
          clickSource: clickSource,
          replayCtaType: provisionCTA.ctaType === 'buy_replay' ? 'buy_replay' : 'learn_more',
          userVariant: userVariant,
        }),
      );
    }
  }, [clickSource, loggingClient, open, provisionCTA.ctaType, userVariant, variant]);

  const handleNotNowClick = React.useCallback(() => {
    onClose();
    loggingClient.logPap(
      PAP_Select_ModalAction({
        modal: 'feature_upsell',
        modalAction: 'cancel',
        replayFeature: variant,
        clickSource: clickSource,
        userVariant: userVariant,
      }),
    );
  }, [clickSource, loggingClient, onClose, userVariant, variant]);

  return (
    <Modal isCentered open={open} shouldCloseOnOverlayClick={false} width={800}>
      <ModalContainer>
        <div>
          <Modal.Header hasBottomSpacing="title-small">
            <Title size="medium">
              <FormattedMessage {...titleStrings[variant]} />
            </Title>
            {isUnlimitedFileUploadsVariant && (
              <Text color="subtle" tagName="p" variant="paragraph">
                {intl.formatMessage(bodyStrings.unlimited_file_uploads)}
              </Text>
            )}
            {!isBillingUnknown && (
              <Text color="subtle" tagName="p" variant="paragraph">
                {billingText + (isUnlimitedFileUploadsVariant ? '.' : '')}
              </Text>
            )}
          </Modal.Header>
          <Modal.Body>
            <BodyContainer>
              {featureListMap[variant].map((feature) => {
                return <ModalLineItem feature={feature} key={feature} />;
              })}
            </BodyContainer>
          </Modal.Body>
        </div>
        <IllustrationContainer $backgroundColor={backgroundColorMap[variant]}>
          {isUnlimitedFileUploadsVariant ? (
            <CircleAnimation
              ariaLabel={intl.formatMessage({
                defaultMessage: 'Unlimited file uploads',
                id: 't9tzCo',
                description: 'progress bar label indicating a user can have unlimited file uploads',
              })}
              fontType={Title}
              size={200}
              strokeWidth={18}
            />
          ) : (
            <IllustrationImg alt="" src={featureImages[variant]} />
          )}
        </IllustrationContainer>
      </ModalContainer>
      <Modal.Footer hasTopBorder={true}>
        <FooterContainer>
          <Button onClick={handleNotNowClick} variant="transparent">
            {intl.formatMessage(modalStrings.notNow)}
          </Button>
          <Button
            href={ctaLink}
            papOnClick={PAP_Select_ModalAction({
              modal: 'feature_upsell',
              modalAction: provisionCTA.ctaType === 'buy_replay' ? 'buy_replay' : 'learn_more',
              replayFeature: variant,
              clickSource: clickSource,
              userVariant: userVariant,
            })}
            size="large"
            target="_blank"
            variant="primary"
          >
            {provisionCTA.ctaLabel}
          </Button>
        </FooterContainer>
      </Modal.Footer>
    </Modal>
  );
};

export type BasicAddOnUpsellModalProps = {
  variant: PremiumFeatureType;
  open: boolean;
  onClose: () => void;
  clickSource?: ClickSource;
};

export const BasicAddOnUpsellModal = ({
  variant,
  open,
  onClose,
  clickSource,
}: BasicAddOnUpsellModalProps) => {
  const userVariant = useGetUserVariant();
  const sessionContext = useReelAppGlobalState();
  const loggingClient = useLoggingClient();
  const isLoggedIn = sessionContext.status === 'logged in';
  const {data: isEligibleData, isLoading: isLoadingReplayEligible} =
    useGetReplayEligibilityQuery(isLoggedIn);
  const isEligible = isEligibleData ?? true;
  const {data: billingInfo, isLoading: isLoadingBillingInfo} = useGetBillingCycleQuery(isLoggedIn);
  const billingCycle = billingInfo?.billingCycle ?? 'unknown';
  const billingPrice = billingInfo?.billingPrice ?? '';

  return !isLoadingReplayEligible && !isLoadingBillingInfo ? (
    <BasicAddOnUpsellModalUI
      billingCycle={billingCycle}
      billingPrice={billingPrice}
      clickSource={clickSource}
      isEligible={isEligible}
      loggingClient={loggingClient}
      onClose={onClose}
      open={open}
      userVariant={userVariant}
      variant={variant}
    />
  ) : (
    <></>
  );
};

export const featureStrings = defineMessages<FeatureItemType>({
  unlimited_file_uploads: {
    defaultMessage: 'As many uploads as your storage allows',
    description: 'Premium feature of unlimited file uploads',
    id: 'rM5spj',
  },
  transcript_and_captions: {
    defaultMessage: 'Transcripts and captions',
    description: 'Premium feature of file transcripts and captions',
    id: 'w7plHM',
  },
  password_protected_links: {
    defaultMessage: 'Password-protected links',
    description: 'Premium feature of password protected links',
    id: 'KyVzWi',
  },
  digital_watermarking: {
    defaultMessage: 'Digital watermarking',
    description: 'Premium feature of watermarking',
    id: '8eHfDi',
  },
  due_dates: {
    defaultMessage: 'Due dates',
    description: 'Premium feature of adding due dates',
    id: 'D10eCz',
  },
  version_comparison: {
    defaultMessage: 'Version comparison',
    description: 'Premium feature of version comparison',
    id: 'AE57u2',
  },
  archive_files: {
    defaultMessage: 'Archive files to Dropbox',
    description: 'Premium feature of archiving files to Dropbox',
    id: 'qRsSKn',
  },
  shared_link_management: {
    defaultMessage: 'Shared link management',
    description: 'Premium feature of managing shared links',
    id: '57RiPl',
  },
  project_customization: {
    defaultMessage: 'Project customization',
    description: 'Premium feature of customizing project',
    id: 'rYrHN2',
  },
  lossless_audio: {
    defaultMessage: 'Lossless audio',
    description: 'Premium feature of lossless audio',
    id: 'AdPuCn',
  },
  team_only_commenting: {
    defaultMessage: 'Private editor-only commenting',
    id: 'dkejD5',
    description: 'Premium feature of team-only commenting',
  },
});

export const modalStrings = defineMessages({
  notNow: {
    defaultMessage: 'Not now',
    description: 'Dismissing the customization upsell modal',
    id: 'x92Jc+',
  },
});

const titleStrings = defineMessages({
  deadlines_and_tasks: {
    defaultMessage: 'Keep on schedule with Replay',
    id: 'Vm972a',
    description: 'Title for modal upselling Replay premium when coming from add due date feature',
  },
  password_protected_links: {
    defaultMessage: 'Protect your work with Replay',
    id: 'qEjyOT',
    description:
      'Title for modal upselling Replay premium when coming from password-protected links feature',
  },
  archive_to_dbx: {
    defaultMessage: 'Archive your work with Replay',
    id: 'wMx54n',
    description:
      'Title for modal upselling Replay premium when coming from archive to Dropbox feature',
  },
  links_hub: {
    defaultMessage: 'Manage link access with Replay',
    id: '16UDeE',
    description: 'Title for modal upselling Replay premium when coming from links hub feature',
  },
  version_comparison: {
    defaultMessage: 'Compare versions with Replay',
    id: '6q5k74',
    description:
      'Title for modal upselling Replay premium when coming from version comparison feature',
  },
  watermark: {
    defaultMessage: 'Watermark your work with Replay',
    id: 'afhgzw',
    description: 'Title for modal upselling Replay premium when coming from watermarking feature',
  },
  custom_branding: {
    defaultMessage: 'Add custom branding with Replay',
    id: '39wzDi',
    description:
      'Title for modal upselling Replay premium when coming from custom branding feature',
  },
  lossless_audio: {
    defaultMessage: 'Get lossless audio with Replay',
    id: 'cXR5se',
    description: 'Title for modal upselling Replay premium when coming from lossless audio feature',
  },
  unlimited_file_uploads: {
    defaultMessage: 'You’ve used all your uploads',
    id: 'e+65N7',
    description:
      'Title for modal upselling Replay premium when reached the file limit and come back to Replay again',
  },
  team_only_commenting: {
    defaultMessage: 'Send private comments with Replay',
    id: 'lG7xPY',
    description:
      'Title for modal upselling Replay add-on when coming from team-only commenting feature',
  },
});

const bodyStrings = defineMessages({
  unlimited_file_uploads: {
    defaultMessage: 'Get unlimited uploads with Replay.',
    id: 'LcX4Vi',
    description:
      'Body for modal upselling Replay add-on when reached the file limit and come back to Replay again',
  },
});
