import React from 'react';

import {useAtomValue} from 'jotai';
import {useIntl} from 'react-intl';
import styled from 'styled-components';

import {Checkbox} from '@dropbox/dig-components/controls';

import {BulkActionStatus, bulkActionStatusAtom} from '~/state/bulk_actions';

const BlockCheckbox = styled(Checkbox)`
  display: block;
`;

type SelectCheckboxProps = {
  id?: string;
  isChecked: boolean;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
};

export const SelectCheckbox = ({id, isChecked, onClick, onChange}: SelectCheckboxProps) => {
  const intl = useIntl();
  const bulkActionStatus = useAtomValue(bulkActionStatusAtom);

  // Disable multi-select checkboxes when requests are active
  const disabled = bulkActionStatus === BulkActionStatus.REQUESTING;

  const checkboxLabel = intl.formatMessage({
    defaultMessage: 'Select media',
    id: 'aUgY4W',
    description: 'Label text for checkbox that selects a specific file for bulk actions',
  });

  return (
    <BlockCheckbox
      aria-label={checkboxLabel}
      checked={isChecked}
      disabled={disabled}
      id={id}
      onChange={onChange}
      onClick={onClick}
    />
  );
};
