import amplitude from 'amplitude-js';
import once from 'lodash/once';

import type {
  EventAttributes,
  EventAttributesMap,
  UserEventAttributes,
} from '~/lib/logging/logger_types';

import {globalLoggingSwitch} from './global_logging_switch';

// This should only happen one time on page load, no matter how many
// AmplitudeLoggingClients we instantiate.
const initializeAmplitudeOnce = once(() => {
  const amplitudeKey =
    // Check the hostname in addition to the NODE_ENV or we will end up
    // logging staging events to the production project
    import.meta.env.PROD && window.location.hostname === 'replay.dropbox.com'
      ? // 'Replay - new new tech stack (prod)' Amplitude project
        '29b82eded5767d31e1045138f993bc14'
      : // 'Replay - new new tech stack (dev)' Amplitude project
        '3ebb901138db228cae3ee4c7b4575af8';

  amplitude.getInstance().init(amplitudeKey, '', {
    // Disable cookies & localStorage so that Amplitude only stores data in memory
    // Amplitude string gets cleared on logout
    storage: globalLoggingSwitch.canUseStorage() ? 'localStorage' : 'none',
    saveEvents: globalLoggingSwitch.canUseStorage() ? true : false,
    trackingOptions: {
      ip_address: false,
    },
  });

  globalLoggingSwitch.addEventListener('amplitudeEnabled', () => {
    amplitude.getInstance().setOptOut(false);
    amplitude.getInstance().enableTracking();
  });

  globalLoggingSwitch.addEventListener('amplitudeDisabled', () => {
    amplitude.getInstance().setOptOut(true);
  });

  globalLoggingSwitch.addEventListener('storageEnabled', () => {
    amplitude.getInstance().options.saveEvents = true;
    amplitude.getInstance().options.storage = 'localStorage';
  });
  globalLoggingSwitch.addEventListener('storageDisabled', () => {
    amplitude.getInstance().options.saveEvents = false;
    amplitude.getInstance().options.storage = 'none';
  });
});

/**
 * This logger logs to Amplitude using the "Paper Amplitude Project", it pre-dates PAP and
 * is therefore more lenient in terms of what we can log.
 *
 * @deprecated use PAP logger instead {@link ../logging/logger#LoggingClient}
 */
export class PaperAmplitudeLoggingClient {
  constructor(activeUserAttributes: UserEventAttributes) {
    initializeAmplitudeOnce();
    amplitude.getInstance().setUserId(activeUserAttributes.userId);
    amplitude.getInstance().setUserProperties({
      is_dropboxer: activeUserAttributes.isDropboxer,
      is_test_user: activeUserAttributes.isTestUser,
      is_team_admin: activeUserAttributes.isTeamAdmin,
      team_id: activeUserAttributes.teamId,
      user_agent: activeUserAttributes.userAgent,
      active_team_size: activeUserAttributes.activeTeamSize,
      sku_name: activeUserAttributes.skuName,
      stormcrows: activeUserAttributes.stormcrows,
      storage_enabled: activeUserAttributes.storageEnabled,
    });
  }

  logEvent<E extends keyof typeof EventAttributesMap>(
    eventAttributes: EventAttributes,
    eventType: E,
    attributes:
      | undefined
      | {
          [key: string]: string | number | undefined;
        },
  ) {
    const eventProperties = {
      video_id: eventAttributes.videoId,
      project_id: eventAttributes.projectId,
      userAttributes: eventAttributes.userAttributes,
      action_surface: eventAttributes.surface,
      reel_type: eventAttributes.reelType,
      is_live_review: eventAttributes.isLiveReview,
      browse_location: eventAttributes.browseLocation,
      runtime_bundle_version: eventAttributes.runtimeBundleVersion,
      main_bundle_version: eventAttributes.mainBundleVersion,
      ...attributes,
    };
    amplitude.getInstance().logEvent(eventType, eventProperties);
  }
}
