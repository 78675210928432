import React, {createContext, useContext, useEffect} from 'react';

import {useQueryClient} from '@tanstack/react-query';
import {useAtom, useSetAtom} from 'jotai';
import {matchPath, useLocation, useNavigate} from 'react-router';

import type {reel} from '@dropbox/api-v2-client';

import {useViewport} from '~/components/viewport_context';
import type {SessionContextValue} from '~/context';
import {setUserOnboardingActions} from '~/lib/api';
import {useOnboardingQuery} from '~/lib/api_queries';
import {reportBadContextUseError} from '~/lib/error_reporting';
import {ONBOARDING_STEPS_BY_TYPE} from '~/lib/onboarding_v2/use_onboarding_checklist';
import {hasCompletedAnyPreviousOnboardingActions} from '~/lib/onboarding_v2/utils';
import {replayApi} from '~/lib/query_client';
import {BROWSE_ROUTE_MATCH, OAUTH_ROUTE_MATCH} from '~/route_path_matches';
import {
  hasCheckedForOnboardingRedirectAtom,
  hasCompletedAnyPreviousOnboardingActionsAtom,
  hasCompletedOnboardingAtom,
  ONBOARDING_TYPE,
  setOnboardingStepsAtom,
  setOnboardingTypeAndStepsAtom,
  STEPS,
} from '~/state/onboarding_v2';

import {useQuery} from '../../lib/url_utils';
import {useE2eFeatureIsOn} from '../../state/e2e_features';

type OnboardingContextValue = {
  onboardingActions: reel.OnboardingActions | undefined;
  updateOnboardingActions: (actions: reel.OnboardingActions) => Promise<void>;
};
export const OnboardingContext = createContext<OnboardingContextValue>({
  onboardingActions: undefined,
  updateOnboardingActions: async () => {},
});

type Props = {
  children: React.ReactNode;
  sessionState: SessionContextValue;
};

export const useShouldBypassOnboarding = () => {
  const isOnboardingEnabled = useE2eFeatureIsOn('onboarding');
  const params = useQuery();
  return isOnboardingEnabled && params.get('bypass_onboarding') === 'true';
};

export const OnboardingProvider = ({children, sessionState}: Props) => {
  const setHasCompletedOnboarding = useSetAtom(hasCompletedOnboardingAtom);
  const setOnboardingSteps = useSetAtom(setOnboardingStepsAtom);
  const setOnboardingTypeAndSteps = useSetAtom(setOnboardingTypeAndStepsAtom);
  const setHasCompletedAnyPreviousOnboardingActions = useSetAtom(
    hasCompletedAnyPreviousOnboardingActionsAtom,
  );
  const [hasCheckedForOnboardingRedirect, setHasCheckedForOnboardingRedirect] = useAtom(
    hasCheckedForOnboardingRedirectAtom,
  );
  // eslint-disable-next-line deprecation/deprecation
  const {isSmallScreenSize, isMobileDevice} = useViewport();

  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();

  // this includes all of the old onboarding state too
  const {data: onboardingActions} = useOnboardingQuery(sessionState.status === 'logged in');

  const shouldBypassOnboarding = useShouldBypassOnboarding();

  const updateOnboardingActions = React.useCallback(
    async (actions: reel.OnboardingActions) => {
      await setUserOnboardingActions(actions);
      queryClient.invalidateQueries(replayApi.onboarding());
    },
    [queryClient],
  );

  useEffect(
    () => {
      const isOauthPageRoute = !!matchPath(
        {
          path: OAUTH_ROUTE_MATCH,
          end: true,
        },
        window.location.pathname,
      );

      if (
        sessionState.status === 'logged in' &&
        !isOauthPageRoute &&
        !hasCheckedForOnboardingRedirect
      ) {
        const isBrowsePageRoute = !!matchPath(
          {
            path: BROWSE_ROUTE_MATCH,
            end: true,
          },
          window.location.pathname,
        );

        const newHasCheckedForOnboardingRedirect = !isBrowsePageRoute;

        setHasCheckedForOnboardingRedirect(newHasCheckedForOnboardingRedirect);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- PR 894
    [
      sessionState,
      sessionState.status,
      setHasCheckedForOnboardingRedirect,
      window.location.pathname,
    ],
  );

  useEffect(() => {
    if (!onboardingActions) {
      return;
    }

    const {
      onboarding_v2_type,
      has_completed_view_file_step,
      has_completed_draw_on_file_step,
      has_completed_comment_on_file_step,
      has_completed_upload_file_step,
      has_completed_share_file_step,
      has_completed_onboarding_v2,
      onboarding_completed,
      onboarding_dismissed,
    } = onboardingActions;

    /**
     * TODO(sheniff): Temporary extra checks while we completely clean up the onboarding V1.
     * This ensures that users that already completed/dismissed the onboarding V1 will not see the onboarding V2.
     * Eventually, we'll want this check to be updated in the BE, so that has_completed_onboarding_v2 is set to true
     * based as well on those 2 parameters that belonged to v1 (onboarding_completed, onboarding_dismissed).
     */
    setHasCompletedOnboarding(
      !!has_completed_onboarding_v2 || !!onboarding_completed || !!onboarding_dismissed,
    );

    setOnboardingSteps({
      [STEPS.VIEW_FILE]: !!has_completed_view_file_step,
      [STEPS.DRAW_ON_FILE]: !!has_completed_draw_on_file_step,
      [STEPS.COMMENT_ON_FILE]: !!has_completed_comment_on_file_step,
      [STEPS.UPLOAD_FILE]: !!has_completed_upload_file_step,
      [STEPS.SHARE_FILE]: !!has_completed_share_file_step,
    });

    let savedOnboardingType = null;
    if (onboarding_v2_type) {
      if (onboarding_v2_type['.tag'] === 'audio_with_file') {
        savedOnboardingType = ONBOARDING_TYPE.AUDIO_WITH_FILE;
      } else if (onboarding_v2_type['.tag'] === 'image_with_file') {
        savedOnboardingType = ONBOARDING_TYPE.IMAGE_WITH_FILE;
      } else if (onboarding_v2_type['.tag'] === 'video_with_file') {
        savedOnboardingType = ONBOARDING_TYPE.VIDEO_WITH_FILE;
      } else if (onboarding_v2_type['.tag'] === 'video_without_file') {
        savedOnboardingType = ONBOARDING_TYPE.VIDEO_WITHOUT_FILE;
      }
    }

    const completedPreviousOnboardingActions =
      hasCompletedAnyPreviousOnboardingActions(onboardingActions);
    setHasCompletedAnyPreviousOnboardingActions(completedPreviousOnboardingActions);

    // setting an oboarding type means they've started onboarding already
    if (savedOnboardingType) {
      setOnboardingTypeAndSteps({
        type: savedOnboardingType,
        stepsToDo: ONBOARDING_STEPS_BY_TYPE[savedOnboardingType],
      });
      setHasCheckedForOnboardingRedirect(true);
    } else if (
      completedPreviousOnboardingActions ||
      has_completed_onboarding_v2 ||
      isMobileDevice ||
      isSmallScreenSize
    ) {
      setHasCheckedForOnboardingRedirect(true);
    } else if (!hasCheckedForOnboardingRedirect) {
      if (!shouldBypassOnboarding) {
        navigate('/project/onboarding');
      }
      setHasCheckedForOnboardingRedirect(true);
    }
  }, [
    hasCheckedForOnboardingRedirect,
    navigate,
    isMobileDevice,
    isSmallScreenSize,
    location,
    setHasCheckedForOnboardingRedirect,
    setHasCompletedAnyPreviousOnboardingActions,
    setHasCompletedOnboarding,
    setOnboardingSteps,
    setOnboardingTypeAndSteps,
    shouldBypassOnboarding,
    onboardingActions,
  ]);

  // context value should only update when onboardingActions changes. To avoid unnecessary re-renders.
  const value = React.useMemo(() => {
    return {
      onboardingActions: {
        ...onboardingActions,
        has_completed_onboarding_v2:
          !!onboardingActions?.has_completed_onboarding_v2 ||
          /**
           * TODO(sheniff): Temporary extra checks while we completely clean up the onboarding V1.
           * This ensures that users that already completed/dismissed the onboarding V1 will not see the onboarding V2.
           * Eventually, we'll want this check to be updated in the BE, so that has_completed_onboarding_v2 is set to true
           * based as well on those 2 parameters that belonged to v1 (onboarding_completed, onboarding_dismissed).
           * TODO2(sheniff): I made this change to keep coordination between this context provider and the `hasCompletedOnboardingAtom` atom.
           * However, we should eventually remove one of these 2 ways of accessing data, as it can lead to inconsistencies.
           */
          !!onboardingActions?.onboarding_completed ||
          !!onboardingActions?.onboarding_dismissed,
      },
      updateOnboardingActions,
    };
  }, [onboardingActions, updateOnboardingActions]);

  return <OnboardingContext.Provider value={value}>{children}</OnboardingContext.Provider>;
};

export const useOnboardingContext = (): OnboardingContextValue => {
  const onboardingContext = useContext(OnboardingContext);
  if (!onboardingContext) {
    const error = new Error('useOnboardingContext must be used within an OnboardingProvider');
    reportBadContextUseError(error);
    throw error;
  }
  return onboardingContext;
};
