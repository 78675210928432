/* eslint-disable deprecation/deprecation */
import React, {useEffect} from 'react';

import {useAtom, useAtomValue} from 'jotai';
import {PAP_Shown_Modal} from 'pap-events/replay/shown_modal';
import styled from 'styled-components';

import {Modal} from '@dropbox/dig-components/modal';

import {AddDueDateModalContents} from '~/components/due_date/due_date_modal';
import {ShareModalHome} from '~/components/share_modal/share_modal_home';
import {useShareModalStrings} from '~/components/share_modal/share_modal_text';
import {
  currentWatermarkLinkAtom,
  getShareItemAtom,
  getShareModalLoggingInfoAtom,
  shareModalContentsAtom,
} from '~/components/share_modal/share_state';
import type {WatermarkSettingsTab} from '~/components/share_modal/watermark_settings';
import {WatermarkSettings} from '~/components/share_modal/watermark_settings';
import {SMALL_SCREEN_WIDTH_BREAKPOINT} from '~/components/viewport_context';
import type {TeamContactInfo} from '~/lib/api';
import {useLoggingClient} from '~/lib/use_logging_client';

import {ShareModalSettings} from './share_modal_settings';

const ShareModalContainer = styled(Modal)`
  min-width: 600px;
  @media screen and (max-width: ${SMALL_SCREEN_WIDTH_BREAKPOINT}px) {
    min-width: 0;
  }
`;

type ShareModalProps = {
  open: boolean;
  canCreateLinks: boolean;
  onRequestClose: () => void;
};

export const ShareModal = (props: ShareModalProps) => {
  const loggingClient = useLoggingClient();
  const [contents, setContents] = useAtom(shareModalContentsAtom);
  const {open, canCreateLinks, onRequestClose} = props;
  const [shareRecipients, setShareRecipients] = React.useState<TeamContactInfo[]>([]);
  const shareItem = useAtomValue(getShareItemAtom);
  const loggingInfo = useAtomValue(getShareModalLoggingInfoAtom);
  const [currentWatermarkLink, setCurrentWatermarkLink] = useAtom(currentWatermarkLinkAtom);

  const text = useShareModalStrings(shareItem?.name ?? '');

  const [watermarkSettingsTab, setWatermarkSettingsTab] =
    React.useState<WatermarkSettingsTab>('customize-watermark');
  const handleCloseWatermarkSettings = () => {
    setContents({type: 'share-home', tab: 'watermark'});
    setWatermarkSettingsTab('customize-watermark');
    setCurrentWatermarkLink(undefined);
  };

  useEffect(() => {
    if (open && shareItem?.type) {
      loggingClient.logPap(
        PAP_Shown_Modal({
          modal: 'share',
          linkItemType: shareItem.type,
        }),
      );
    }
  }, [loggingClient, open, shareItem?.type]);

  if (shareItem === null) {
    return null;
  }

  const handleCloseModal = () => {
    onRequestClose();
    setShareRecipients([]);
  };

  return (
    <ShareModalContainer
      isCentered={false}
      onRequestClose={handleCloseModal}
      open={open}
      withCloseButton={text.closeShareModalButtonLabel}
    >
      {contents?.type === 'basic-link-settings' && (
        <ShareModalSettings
          canCreateLinks={canCreateLinks}
          showShareHome={() =>
            setContents({
              type: 'share-home',
              tab: 'original',
              selectedPermission: contents.selectedPermission,
              selectedVersion: contents.selectedVersion,
            })
          }
        />
      )}
      {contents?.type === 'watermark-link-settings' && (
        <WatermarkSettings
          canCreateLinks={canCreateLinks}
          currentLinkInfo={currentWatermarkLink}
          onRequestClose={handleCloseWatermarkSettings}
          selectedTab={watermarkSettingsTab}
          setSelectedTab={setWatermarkSettingsTab}
        />
      )}
      {contents?.type === 'due-date-settings' && (
        <AddDueDateModalContents
          handleCloseModal={handleCloseModal}
          logEvent={loggingInfo.logEvent}
          videoId={shareItem.id}
        />
      )}
      {contents?.type === 'share-home' && (
        <ShareModalHome
          canCreateLinks={canCreateLinks}
          onRequestClose={handleCloseModal}
          setShareRecipients={setShareRecipients}
          shareRecipients={shareRecipients}
        />
      )}
    </ShareModalContainer>
  );
};
