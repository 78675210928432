import React from 'react';

/**
 * A simple hook that provides a convenient mounted boolean to reduce state and code duplication in components
 */
const useMounted = () => {
  const mounted = React.useRef(false);
  const callback = React.useCallback(() => mounted.current, [mounted]);

  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  return callback;
};

type ReactSetStateType<T> = React.Dispatch<React.SetStateAction<T>>;

export function useStateIfMounted<T>(defaultValue: T): [T, ReactSetStateType<T>] {
  const isMounted = useMounted();

  const [state, setState] = React.useState<T>(defaultValue);

  const setStateIfMounted: ReactSetStateType<T> = React.useCallback(
    (...args) => {
      if (isMounted()) {
        setState(...args);
      }
    },
    [isMounted],
  );

  return [state, setStateIfMounted];
}

export default useMounted;
