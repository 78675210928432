import React, {useCallback} from 'react';

import {useQuery} from '@tanstack/react-query';
import debounce from 'lodash/debounce';
import type {PAPEventState} from 'pap-events/base/event';
import type {FileType} from 'pap-events/enums/file_type';
import {PAP_Select_WorkspaceAction} from 'pap-events/replay/select_workspace_action';
import {PAP_Shown_SearchBox} from 'pap-events/replay/shown_search_box';
import {PAP_Search_SearchQuery} from 'pap-events/search/search_search_query';
import {PAP_Select_SearchBox} from 'pap-events/search/select_search_box';
import {FormattedMessage, useIntl} from 'react-intl';
import {useNavigate} from 'react-router';
import styled, {css} from 'styled-components';

import type {reel} from '@dropbox/api-v2-client';
import type {WrapperContentProps} from '@dropbox/dig-components/menu';
import {TextInput} from '@dropbox/dig-components/text_fields';
import {Typeahead, type WrapperRef} from '@dropbox/dig-components/typeahead';
import {Text} from '@dropbox/dig-components/typography';
import {UIIcon} from '@dropbox/dig-icons';
import {CloseLine, FolderLine, SearchLine} from '@dropbox/dig-icons/assets';
import {LookMagnifyingGlassSpot} from '@dropbox/dig-illustrations/spot/look-magnifying-glass';

import {IconButton} from '~/components/button';
import {PAGE_HEADER_HEIGHT} from '~/components/common';
import {ProjectLogo} from '~/components/project_logo';
import {SkeletonRectangle} from '~/components/skeleton';
import {breakpointSmall, spacing} from '~/components/styled';
import {searchQuery as apiSearchQuery} from '~/lib/api';
import {useDisplayInfoQuery} from '~/lib/api_queries';
import type {Branding} from '~/lib/branding';
import {MIN_SEARCH_QUERY_LENGTH} from '~/lib/constants';
import {queryClient, replayApi} from '~/lib/query_client';
import {StormcrowIsOn} from '~/lib/stormcrow';
import {useLoggingClient} from '~/lib/use_logging_client';
import {useOnce} from '~/lib/use_once';
import {ThumbnailSlot} from '~/pages/browse_page/components/project_list_view';

import {uuidV4} from './uuidv4';

const SearchBar = styled(Typeahead.Wrapper)`
  min-width: 150px;
  width: 100%;
  margin-top: ${spacing('Micro Small')};

  ${breakpointSmall(css`
    max-width: 300px;
    flex: 1 1 300px;
    margin-top: 0;
  `)}

  // Dig styles below are used to move the padding from the container to the search input.
  // This gets the search input to 24px height for WCAG2.2aa. The rendered output should
  // be identical when removed
  .dig-TextInputContainer {
    padding: 0 ${spacing('Micro Medium')} 0 ${spacing('Micro Medium')};
    display: inline-flex;
  }

  input.dig-TextInput-input[type='text'] {
    padding: ${spacing('Micro XSmall')} 0 ${spacing('Micro XSmall')} 0;
  }

  .dig-TextInputAccessory {
    align-self: center;
  }

  .dig-ClickOutside {
    display: flex;
  }
`;

const NEW_QUERY_WAIT_MS = 5000; // 5 seconds

export const Search = () => {
  const isSearchOn = StormcrowIsOn.useReplaySearch();
  const [query, setQuery] = React.useState<string>('');
  const [searchInput, setSearchInput] = React.useState<string>('');
  // Search Session ID identifies a unique search session for the user.
  // This will remain the same while the user doesn't navigate somewhere else by selecting a search result.
  const [searchSessionId, setSearchSessionId] = React.useState<string>(uuidV4());
  // Search Session **Query** ID changes for every query the user searches for, within a search session.
  const [searchSessionQueryId, setSearchSessionQueryId] = React.useState<string>(uuidV4());
  // Last time the query was cleared
  const [lastQueryCleared, setLastQueryCleared] = React.useState<number>(0);

  const loggingClient = useLoggingClient();

  useOnce(() => {
    loggingClient.logPap(
      PAP_Shown_SearchBox({
        deviceId: loggingClient.deviceId,
      }),
    );
  });

  const navigate = useNavigate();
  const intl = useIntl();
  const searchText = intl.formatMessage({
    defaultMessage: 'Search',
    id: '2s8jk9',
    description: 'Placeholder text for search input field as well as aria-label.',
  });
  const clearText = intl.formatMessage({
    defaultMessage: 'Clear',
    id: 'eieEtF',
    description:
      'aria-label message to inform user this is a button to clear current search query.',
  });

  // SSQ: Search Session Query
  const refreshSSQuery = React.useCallback(() => {
    const newSessionQueryId = uuidV4();
    setSearchSessionQueryId(newSessionQueryId);
    return newSessionQueryId;
  }, []);

  const refreshSearchSession = React.useCallback(() => {
    const newSessionId = uuidV4();
    setSearchSessionId(newSessionId);
    return {
      searchSessionId: newSessionId,
      searchSessionQueryId: refreshSSQuery(),
    };
  }, [refreshSSQuery]);

  const refreshSSQIfNeeded = React.useCallback(
    (newQuery: string) => {
      // If we just cleared the query, we'll update the lastQueryCleared timestamp
      if (!!query && !newQuery) {
        setLastQueryCleared(Date.now());
      } else if (!query && !!newQuery && Date.now() - lastQueryCleared > NEW_QUERY_WAIT_MS) {
        // If we're starting a new query after a given grace period,
        // we'll refresh the Search Session Query ID
        refreshSSQuery();
      }
    },
    [lastQueryCleared, query, refreshSSQuery],
  );

  const wrapperRef = React.useRef<WrapperRef>();
  React.useEffect(() => {
    const handleSearch = (event: KeyboardEvent) => {
      if (event.key === '/' && event.ctrlKey) {
        event.preventDefault();
        wrapperRef.current?.focusTrigger();
      }
    };

    document.addEventListener('keyup', handleSearch);
    return () => {
      document.removeEventListener('keyup', handleSearch);
    };
  }, []);

  const debouncedOnInputChange = React.useMemo(
    () =>
      debounce(async (input: string) => {
        const newInput = input.trim().length >= MIN_SEARCH_QUERY_LENGTH ? input : '';
        refreshSSQIfNeeded(newInput);
        setQuery(newInput);
      }, 300),
    [refreshSSQIfNeeded],
  );

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value);
    queryClient.cancelQueries({
      queryKey: replayApi.allSearchQuery(),
      fetchStatus: 'fetching',
    });
    debouncedOnInputChange(event.target.value);
  };

  const onInputFocus = React.useCallback(() => {
    loggingClient.logPap(
      PAP_Select_SearchBox({
        actionElement: 'header_action_bar',
        searchSessionId,
        searchSessionQueryId,
      }),
    );
  }, [loggingClient, searchSessionId, searchSessionQueryId]);

  const clearSearch = React.useCallback(() => {
    setSearchInput('');
    setQuery('');
  }, []);

  const handleSelection = React.useCallback(
    (data: reel.SearchResultEntry) => {
      let type = '';
      if (!!data.type) {
        type = data.type['.tag'].replace('search_result_', '');
      }

      loggingClient.logPap(
        PAP_Select_WorkspaceAction({
          workspaceAction: 'select_search_result',
          contentType: (type === 'team_project' ? 'project' : type) as
            | 'folder'
            | 'project'
            | 'asset',
          videoId: data.asset_id,
          videoVersionId: data.id,
          fileType: data.file_type as FileType,
          projectId: data.id,
          searchSessionId,
          searchSessionQueryId,
        }),
      );

      // remove the domain from the url, as we want a soft navigation
      if (data.url) {
        try {
          const url = new URL(data.url);
          navigate(url.href.replace(url.origin, ''));
        } catch (e) {
          // Try to recover with a hard navigation
          window.location.href = data.url;
        }
      }

      // refresh the search session, as we're done with the current one
      refreshSearchSession();
      clearSearch();
    },
    [
      loggingClient,
      searchSessionId,
      searchSessionQueryId,
      refreshSearchSession,
      clearSearch,
      navigate,
    ],
  );

  if (!isSearchOn) {
    return null;
  }

  return (
    <SearchBar onSelection={handleSelection} shouldHighlightFirstRow wrapperRef={wrapperRef}>
      {({getTriggerProps, getContentProps}) => (
        <>
          <TextInput
            {...getTriggerProps({
              onChange: onInputChange,
              onFocus: onInputFocus,
            })}
            aria-label={searchText}
            placeholder={searchText}
            value={searchInput}
            withLeftAccessory={
              <UIIcon onClick={() => wrapperRef.current?.focusTrigger()} src={SearchLine} />
            }
            withRightAccessory={
              query && (
                <IconButton onClick={clearSearch} size="small" variant="transparent">
                  <UIIcon aria-label={clearText} src={CloseLine} />
                </IconButton>
              )
            }
          />
          <SearchResultContainer
            containerProps={getContentProps()}
            query={query}
            searchSessionId={searchSessionId}
            searchSessionQueryId={searchSessionQueryId}
          />
        </>
      )}
    </SearchBar>
  );
};

const AccessoryWrapper = styled.div`
  width: calc(${spacing('Macro Medium')} + ${spacing('Micro XSmall')} * 2);
  height: ${spacing('Macro Medium')};
  margin: 0 calc(${spacing('Micro XSmall')} * -1);
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface SearchResultContainerProps {
  query: string;
  containerProps: WrapperContentProps;
  skipLoading?: boolean;
  searchSessionId: string;
  searchSessionQueryId: string;
}

export const SearchResultContainer = ({
  query,
  containerProps,
  skipLoading,
  searchSessionId,
  searchSessionQueryId,
}: SearchResultContainerProps) => {
  const renderTypeaheadRow = useCallback((result: reel.SearchResultEntry, prefix?: string) => {
    let subtitleText: string | undefined = undefined;

    if (result.type?.['.tag'] === 'search_result_asset') {
      subtitleText = result.file_type;
    }

    const lastUpdated = result.last_updated ? new Date(result.last_updated) : undefined;

    return (
      <Typeahead.Row
        key={`${prefix}${result.url}`}
        value={result}
        withLeftAccessory={
          <AccessoryWrapper>
            <Thumbnail result={result} />
          </AccessoryWrapper>
        }
        withSubtitle={<RowSubtitle lastModifiedAt={lastUpdated} type={subtitleText} />}
        withTitle={result.name}
      />
    );
  }, []);

  const loggingClient = useLoggingClient();
  const logSearch = useCallback(
    (eventState: PAPEventState, args?: Partial<{resultCount: number; latency: number}>) => {
      loggingClient.logPap(
        PAP_Search_SearchQuery({
          eventState,
          searchSessionId,
          searchSessionQueryId,
          ...(args || {}),
        }),
      );
    },
    [loggingClient, searchSessionId, searchSessionQueryId],
  );

  const searchQuery = useQuery({
    queryKey: replayApi.searchQuery(query),
    queryFn: ({signal}) => {
      logSearch('start');

      const startMs = Date.now();
      return apiSearchQuery(query, signal).then(
        (data) => {
          const resultCount = (data.ranked_results || []).length;
          const latency = Date.now() - startMs;
          logSearch('success', {resultCount, latency});
          return data;
        },
        (error) => {
          logSearch('failed', {latency: Date.now() - startMs});
          throw error;
        },
      );
    },
    enabled: Boolean(query),
    placeholderData: {assets: [], folders: [], team_projects: []},
    staleTime: 1000, // 1 second. As search results can change frequently.
  });

  const {exactResults, relatedResults} = computeResults(query, searchQuery.data);
  const totalResults = exactResults.length + relatedResults.length;

  return (
    <Typeahead.Container
      {...containerProps}
      emptyPrompt={<NoResultsPrompt />}
      isEmptyQuery={query.length >= MIN_SEARCH_QUERY_LENGTH && totalResults === 0}
      loading={!skipLoading && searchQuery.isFetching}
      style={{
        maxHeight: `calc(90vh - ${PAGE_HEADER_HEIGHT}px)`,
        display: query.length < MIN_SEARCH_QUERY_LENGTH ? 'none' : undefined,
      }}
    >
      <Typeahead.Results
        initialResults={20}
        renderRow={renderTypeaheadRow}
        results={exactResults}
      />
      <Typeahead.Results
        initialResults={20}
        renderRow={renderTypeaheadRow}
        results={relatedResults}
      />
    </Typeahead.Container>
  );
};

const Thumbnail = ({result}: {result: reel.SearchResultEntry}) => {
  if (result.type?.['.tag'] === 'search_result_folder') {
    return <UIIcon src={FolderLine} />;
  }

  return result.id ? <AssetOrTeamProjectThumbnail assetId={result.id} /> : null;
};

interface RowSubtitleProps {
  type?: string;
  lastModifiedAt?: Date;
}

const RowSubtitle = ({type, lastModifiedAt}: RowSubtitleProps) => {
  const intl = useIntl();
  return (
    <Text size="xsmall">
      {type && <Text isBold>{type}</Text>}
      {lastModifiedAt && type && <span> · </span>}
      <span>
        <FormattedMessage
          defaultMessage="Last modified: {date}"
          description="Label for the last modified date of a file"
          id="X5Rz/C"
          values={{
            date: intl.formatDate(lastModifiedAt, {
              month: 'short',
              day: 'numeric',
              year: 'numeric',
            }),
          }}
        />
      </span>
    </Text>
  );
};

const Illustration = styled.div`
  // Not great, but we can't extend it like styled(LookMagnifyingGlassSpot)
  & > svg {
    height: 150px;
    min-height: 150px;
    width: 150px;
  }
`;

const NoResultsPrompt = () => {
  const intl = useIntl();
  return (
    <Typeahead.Prompt>
      <Illustration>
        <LookMagnifyingGlassSpot />
      </Illustration>
      <Text size="small">
        {intl.formatMessage({
          defaultMessage: 'No results found',
          id: 'oa8QGD',
          description: 'There are no matches for a given search query',
        })}
      </Text>
    </Typeahead.Prompt>
  );
};

const ProjectThumbnailWrapper = styled.div`
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  // resetting styles from ThumbnailSlot
  & > div,
  & > img {
    width: 100%;
    height: 100%;
    margin: 0;
    border-radius: 0;
    max-height: 100%;
    max-width: 100%;
  }

  & > div {
    justify-content: center;
  }
`;

interface ThumbnailProps {
  assetId: string;
}

const AssetOrTeamProjectThumbnail = ({assetId}: ThumbnailProps) => {
  const info = useDisplayInfoQuery(assetId, true);

  if (info.isLoading) {
    return <SkeletonRectangle $heightValue="30px" $widthValue="48px" />;
  }

  if (info.isError) {
    return null;
  }

  if (!info.data) {
    return null;
  }

  if (info.data['.tag'] === 'folder_display_info') {
    return <ProjectLogo branding={info.data.branding as Branding} />;
  }

  return (
    <ProjectThumbnailWrapper>
      <ThumbnailSlot
        hasPassword={info.data.requires_password}
        thumbnailSrc={info.data.thumbnail_url}
        waveformUrl={info.data.waveform_url}
      />
    </ProjectThumbnailWrapper>
  );
};

export function computeResults(query: string, data?: reel.SearchResult) {
  const exactResults: reel.SearchResultEntry[] = [];
  const relatedResults: reel.SearchResultEntry[] = [];

  if (!data) {
    return {exactResults, relatedResults};
  }

  const splitQuery = query
    .trim()
    .split(' ')
    .filter((q) => q.length > 0);
  const exactResultRx = new RegExp(`${splitQuery.join('|')}`, 'i');

  const organizeResults = (results: reel.SearchResultEntry[]) => {
    for (const result of results) {
      if (result.name && exactResultRx.test(result.name)) {
        exactResults.push(result);
      } else {
        relatedResults.push(result);
      }
    }
  };

  organizeResults(data.ranked_results ?? []);

  return {exactResults, relatedResults};
}
