import * as Sentry from '@sentry/react';
import {MutationCache, QueryCache, QueryClient} from '@tanstack/react-query';

import {type EntityMetadataType, type VersionSummariesIdArg} from './api';

export enum BrowseTab {
  HOME,
  SHARED,
}

const _replayApi = {
  all: ['replay'] as const,
  entityMetadata: (entityId: string, entityType: EntityMetadataType) =>
    [...replayApi.all, 'entity-metadata', entityType, entityId] as const,
  getProvisions: () => [...replayApi.all, 'provisions'] as const,
  getInitializationData: () => [...replayApi.all, 'initialization-data'] as const,
  getUserMetadata: () => [...replayApi.all, 'user-metadata'] as const,
  allMediaProjects: () => [...replayApi.all, 'media-projects'] as const,
  mediaProject: (mediaId: string) => [...replayApi.allMediaProjects(), mediaId] as const,
  getViewerCount: (mediaId: string) => [...replayApi.mediaProject(mediaId), 'view-count'] as const,
  getVideo: (videoId: string, versionId?: string) => [
    ...replayApi.mediaProject(videoId),
    versionId,
  ],
  getVideoFromShortLink: (
    videoId: string = '',
    versionId: string = '',
    sharedToken: string = '',
    grantbook?: string,
  ) => [...replayApi.getVideo(videoId, versionId), {sharedToken, grantbook}],
  getVersionSummaries: (args: VersionSummariesIdArg, grantBook?: string) => {
    const prefix = [
      ...replayApi.mediaProject(args.type === 'video_id' ? args.videoId : args.videoToken),
      'version-summaries',
    ];

    if (args.type === 'video_id') {
      const {videoId, type, ...rest} = args;
      return [...prefix, videoId, {...rest, grantBook}];
    } else {
      const {videoToken, type, ...rest} = args;
      return [...prefix, videoToken, {...rest, grantBook}];
    }
  },
  allMediaProjectVersions: () => [...replayApi.all, 'media-project-versions'] as const,
  mediaProjectVersion: (mediaVersionId: string) =>
    [...replayApi.allMediaProjectVersions(), mediaVersionId] as const,
  getOriginalTranscription: (
    mediaVersionId: string,
    options: {grantBook?: string; shareToken?: string; audioLanguage?: string},
  ) => [...replayApi.mediaProjectVersion(mediaVersionId), 'transcript', options] as const,
  getEditedTranscription: (
    mediaVersionId: string,
    options: {grantBook?: string; shareToken?: string},
  ) => [...replayApi.mediaProjectVersion(mediaVersionId), 'edit-transcript', options] as const,
  getProxyUrls: (mediaVersionId: string, options: {grantBook?: string; shareToken?: string}) =>
    [...replayApi.mediaProjectVersion(mediaVersionId), 'proxy-urls', options] as const,
  getOnedriveToken: () => [...replayApi.all, 'onedrive-token'] as const,
  getReplayEligibility: () => [...replayApi.all, 'replay-eligibility'] as const,
  getBillingCycle: () => [...replayApi.all, 'billing-cycle'] as const,
  listFolderBasicInfo: (folderIdOrTab?: string | BrowseTab) =>
    [...replayApi.all, folderIdOrTab, 'list-folder-basic-info'] as const,
  allFolderContentsDisplayInfo: () => [...replayApi.all, 'folder-content-display-info'] as const,
  folderContentsDisplayInfo: (projectOrFolderPublicId: string) =>
    [...replayApi.allFolderContentsDisplayInfo(), projectOrFolderPublicId] as const,
  folderNsId: (folderId: string) => [...replayApi.all, folderId, 'nsId'],
  getFolderWithShareLink: (
    folderId: string | undefined,
    options: {grantBook?: string; shareToken?: string},
  ) => [...replayApi.all, folderId ?? '', 'share-link', options],
  userInfo: (accountId: string) =>
    [...replayApi.all, 'account', accountId, 'account-info'] as const,
  allSearchQuery: () => [...replayApi.all, 'search'] as const,
  searchQuery: (query: string) => [...replayApi.allSearchQuery(), query] as const,
  availableAddons: () => [...replayApi.all, 'available-addons'] as const,
  addonPreview: (offeringId: string) => [...replayApi.all, 'addon-preview', offeringId] as const,
  getManagePeopleInfoForAssetVersion: (assetVersionId: string) =>
    [...replayApi.all, 'manage-people-info', assetVersionId] as const,
  getPendingReplayAddOnRequestsForTeam: () => [...replayApi.all, 'addon-request'] as const,

  invites: {
    all: () => [...replayApi.all, 'invites'] as const,
    isEligibleToInviteMembers: () => [...replayApi.invites.all(), 'can-invite'] as const,
    validateEmail: (email: string) => [...replayApi.invites.all(), 'invitability', email] as const,
  },
  team: {
    all: () => [...replayApi.all, 'team'] as const,
    info: () => [...replayApi.team.all(), 'info'] as const,
    members: () => [...replayApi.team.all(), 'members'] as const,
    userAssets: (accountId?: string) =>
      [...replayApi.team.all(), 'user-assets', accountId] as const,
    folders: () => [...replayApi.team.all(), 'folders'] as const,
  },
  onboarding: () => [...replayApi.all, 'onboarding'] as const,
  searchContacts: (query: string) => [...replayApi.all, 'search-contacts', query] as const,
} as const;

// Annotates our replay query keys with an additional "replayQueryKey" property
// so that we can store what function created a given query key.
function dotNotation(obj: Record<string, unknown>, prefix: string = '') {
  const result: Record<string, unknown> = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const newKey = prefix ? `${prefix}.${key}` : key;
      const val = obj[key];
      if (typeof val === 'object' && !Array.isArray(val)) {
        Object.assign(result, {[key]: dotNotation(val as Record<string, unknown>, newKey)});
      } else if (typeof val === 'function') {
        result[key] = (...args: any[]) => {
          const result = val(...args);
          Object.defineProperty(result, 'replayQueryKey', {value: newKey, writable: false});
          return result;
        };
      } else {
        result[key] = val;
      }
    }
  }

  return result;
}

export const replayApi = dotNotation(_replayApi) as typeof _replayApi;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // If we've fetched something in the last 30 seconds,
      // don't fetch it again unless specifically invalidated.
      staleTime: 1000 * 30,
    },
  },
  queryCache: new QueryCache({
    onError(error, query) {
      if (error instanceof Error && 'handled' in error && error.handled) {
        return;
      }

      Sentry.withScope((scope) => {
        if (
          'replayQueryKey' in query.queryKey &&
          typeof query.queryKey.replayQueryKey === 'string'
        ) {
          scope.setContext('query', {
            queryKey: query.queryKey.replayQueryKey,
          });
        }

        scope.captureException(error);
      });
    },
  }),
  mutationCache: new MutationCache({
    onError(error) {
      Sentry.captureException(error);
    },
  }),
});
