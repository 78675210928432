import React, {useState} from 'react';

import type {Dropbox} from '@dropbox/api-v2-client';
import type {WaffleGroup, WaffleItem} from '@dropbox/dig-components/global_header';

import {IS_DBX_DEVBOX} from '~/context_utils';

import {reportFailedDataFetch} from './error_reporting';

export const useWaffleItems = ({
  dropboxClient,
}: {
  dropboxClient: Dropbox;
}): {waffleItems: WaffleItem[]; waffleGroups: WaffleGroup[]} => {
  const [waffleItems, setWaffleItems] = useState<WaffleItem[]>([]);
  const [waffleGroups, setWaffleGroups] = useState<WaffleGroup[]>([]);

  React.useEffect(() => {
    (async () => {
      try {
        const waffleResponse = await dropboxClient.waffleGetWaffle({has_groups: true});

        setWaffleItems(
          // @ts-ignore
          waffleResponse.result.items.map((item) => {
            return {
              id: item.id || '',
              title: item.title || '',
              subtitle: item.subtitle || '',
              href: item.href || '',
              iconTag: item.icon_tag,
              badge: item.badge,
              ...(item.icon ? {icon: item.icon} : {iconTag: item.icon_tag || ''}),
            };
          }),
        );
        setWaffleGroups(
          // @ts-ignore
          waffleResponse.result.groups
            .filter((group) => group.items && group.items.length > 0)
            .map((group) => {
              return {
                title: group.title,
                items: group.items?.map((item) => ({
                  ...item,
                  ...(item.icon ? {icon: item.icon} : {iconTag: item.icon_tag || ''}),
                })),
              };
            }),
        );
      } catch (e) {
        // We do not run the waffle target by default in devbox
        if (IS_DBX_DEVBOX) {
          return;
        }
        reportFailedDataFetch(e, 'GetWaffle', 'non-critical');
      }
    })();
  }, [dropboxClient]);

  return {waffleItems, waffleGroups};
};
