import {useQuery} from '@tanstack/react-query';

import {getDefaultUserClient} from '~/lib/client';
import {replayApi} from '~/lib/query_client';

export const useListAllFoldersForTeamQuery = () =>
  useQuery({
    queryKey: replayApi.team.folders(),
    queryFn: async () => {
      const response = await getDefaultUserClient().reelListAllFoldersForTeam({});
      return response.result;
    },
  });

export const useListUserRootAssets = (accountId?: string) =>
  useQuery({
    enabled: Boolean(accountId),
    queryKey: replayApi.team.userAssets(accountId),
    queryFn: async () => {
      const response = await getDefaultUserClient().reelListUserRootMediaProjects({
        target_account_id: accountId,
      });
      return response.result.projects_with_videos;
    },
  });

export type UseTeamInfoArgs = {
  enabled?: boolean;
};

export const useTeamInfo = ({enabled}: UseTeamInfoArgs = {}) => {
  return useQuery({
    enabled,
    queryKey: replayApi.team.info(),
    queryFn: async () => {
      // We are using the "mobile" endpoint as it allows us to query with a user token
      // the main api uses a team token which we don't have
      const response = await getDefaultUserClient().teamMobileGetInfo();
      return response.result;
    },
  });
};
