import React from 'react';

import styled from 'styled-components';

import {Text, Title} from '@dropbox/dig-components/typography';

type ErrorPageProps = {
  titleText: string;
  summaryText: React.ReactNode;
  actionButton: React.ReactNode;
  secondaryActionButton?: React.ReactNode;
  // Should be a DIG spot illustration
  illustration?: React.ReactNode;
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 2;
`;

const VideoWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  opacity: 12%;

  background-image: linear-gradient(
    90deg,
    rgba(216, 216, 202, 255) 0 14.3%,
    rgba(235, 233, 40, 255) 14.3% 28.6%,
    rgba(6, 238, 219, 255) 28.6% 42.9%,
    rgba(7, 238, 56, 255) 42.9% 57.2%,
    rgba(235, 6, 210, 255) 57.2% 71.5%,
    rgba(234, 4, 50, 255) 71.5% 85.8%,
    rgba(3, 4, 218, 255) 85.8% 100%
  );
`;

const StyledVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: fill;
`;

const SummaryWrapper = styled.div`
  text-align: center;
  z-index: 3;
`;

const ButtonWrapper = styled.div`
  margin-right: var(--dig-spacing__micro__large);
  z-index: 3;
`;

const ButtonsDiv = styled.div`
  margin-top: var(--dig-spacing__macro__medium);
  display: flex;
`;

export const ErrorPageLayout = (props: ErrorPageProps) => {
  const {actionButton, secondaryActionButton, summaryText, titleText} = props;

  return (
    <Wrapper>
      <VideoWrapper>
        <StyledVideo autoPlay loop muted>
          <source
            src="https://replay-web-static.s3.amazonaws.com/images/error-video.mp4"
            type="video/mp4"
          />
        </StyledVideo>
      </VideoWrapper>

      <SummaryWrapper>
        <Title size="medium">{titleText}</Title>
        <Text size="medium">{summaryText}</Text>
      </SummaryWrapper>

      <ButtonsDiv>
        <ButtonWrapper>{actionButton}</ButtonWrapper>
        {secondaryActionButton ? <ButtonWrapper>{secondaryActionButton}</ButtonWrapper> : null}
      </ButtonsDiv>
    </Wrapper>
  );
};
