import type {flows} from '@dropbox/api-v2-client';

import {getDefaultUserClient} from '~/lib/client';

export const archiveToDropbox = async (path: string, videoVersionIds: string[]) => {
  const template: flows.WorkflowTemplate = {
    sequence: [{workflow_action: {'.tag': 'archive_from_replay', dest_fq_path: path}}],
  };
  const args: flows.SubmitReplayVideoWorkflowRequestArg = {
    template: template,
    video_version_xids: videoVersionIds,
    notifications: {enabled_notifiers: {'.tag': 'bolt_only'}},
  };
  await getDefaultUserClient().flowsSubmitReplayVideoWorkflow(args);
};

export const getFlowsBoltData = async () => {
  const res = await getDefaultUserClient().flowsGetBoltData();
  return res.result;
};
