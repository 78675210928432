import React, {useEffect} from 'react';

import {PAP_Select_ModalAction} from 'pap-events/replay/select_modal_action';
import {PAP_Shown_Modal} from 'pap-events/replay/shown_modal';
import {FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';

import {Menu, type WrapperOnToggle} from '@dropbox/dig-components/menu';
import {Modal} from '@dropbox/dig-components/modal';
import {Snackbar} from '@dropbox/dig-components/snackbar';
import {TextArea} from '@dropbox/dig-components/text_fields';
import {Text, Title} from '@dropbox/dig-components/typography';

import {Button} from '~/components/button';
import {ReelSnackbar} from '~/components/snackbar';
import {shareProject} from '~/lib/api';
import type {GroupContactInfo, ShareFolderAccessType, TeamContactInfo} from '~/lib/api';
import type {ProjectAccessType} from '~/lib/logging/logger_types';
import {useLoggingClient} from '~/lib/use_logging_client';

import {DropboxAccountTypeahead} from './dropbox_account_typeahead';
import {getRestrictedContentErrorTextFromType} from './restricted_content_messages';
import {useAreFSSGroupsSupported} from '../lib/utils';
import type {FolderToAccounts} from '../pages/browse_page/use_share_recipients';

type AddTeamModalProps = {
  open: boolean;
  requestClose: () => void;
  shareRecipients?: FolderToAccounts;
  sharedFolderId: string;
  sharedFolderName: string;
  successCallback: (
    numPeopleAdded: number,
    accessType: ProjectAccessType,
    numPeopleTotal: number,
  ) => void;
  successGroupShareLoggingCallback?: (
    numPeopleAdded: number,
    accessType: ProjectAccessType,
    numPeopleTotal: number,
  ) => void;
};

const ModalTitle = styled.div`
  padding-top: 10px;
`;

const ModalBody = styled(Modal.Body)`
  padding-bottom: 24px;
`;

const ModalBodyTitle = styled.p`
  margin: 0 0 21px 0;
`;

const ModalButtons = styled.div`
  display: flex;
`;

const CreateButton = styled.div`
  margin-left: auto;
  padding-left: 8px;
`;

const TypeaheadWrapper = styled.div`
  padding-bottom: 5px;
`;

const ModalErrorTextWrapper = styled(Text)`
  color: var(--dig-color__alert__on-surface);
  font-size: 12px;
`;

const TextAreaWrapper = styled(TextArea)`
  margin-top: 14px;
`;

// Fixes margin-bottom issues with DIG's TextArea
const TextAreaContainer = styled.div`
  margin-bottom: 24px;
`;

const SelectRoleWrapper = styled.div`
  margin-left: 10px;
`;

// Reference https://sourcegraph.pp.dropbox.com/server/-/blob/metaserver/static/js/modules/clean/teams/invite/contacts/invite_contacts_tokenizer.tsx#L229

export const AddTeamModal = ({
  open,
  requestClose,
  shareRecipients,
  sharedFolderId,
  sharedFolderName,
  successCallback,
  successGroupShareLoggingCallback,
}: AddTeamModalProps) => {
  const intl = useIntl();

  const [contactInfos, setContactInfos] = React.useState<TeamContactInfo[]>([]);
  const [snackbarText, setSnackbarText] = React.useState<string>('');
  const [accessLevel, setAccessLevel] = React.useState<ShareFolderAccessType>('admin');
  const [message, setMessage] = React.useState<string>('');
  const [errorText, setErrorText] = React.useState<string>('');
  const [errorCount, setErrorCount] = React.useState<number>(0);
  const areFssGroupsSupported = useAreFSSGroupsSupported();
  const loggingClient = useLoggingClient();

  useEffect(() => {
    if (open) {
      loggingClient.logPap(
        PAP_Shown_Modal({
          modal: 'add_people',
        }),
      );
    }
  }, [loggingClient, open]);

  // When contact info changes, check to see if the invalid contacts were removed
  React.useEffect(() => {
    const numErrors = contactInfos.filter((contactInfo) => !contactInfo.valid).length;
    setErrorCount(numErrors);
  }, [contactInfos]);

  const setContacts = (updatedContacts: TeamContactInfo[]) => {
    setContactInfos(updatedContacts);
  };

  const handleAddTeamMembers = async () => {
    loggingClient.logPap(
      PAP_Select_ModalAction({
        modal: 'add_people',
        modalAction: 'save',
      }),
    );

    try {
      // We should show `People added.` if a group was shared
      const anyGroups = contactInfos.some((c) => c.type === 'group');
      const addCount = contactInfos.length;

      let shareMessage = message;
      if (message === '') {
        shareMessage =
          accessLevel === 'admin' ? textAreaEditPlaceholderText : textAreaViewPlaceholderText;
      }

      await shareProject(sharedFolderId, contactInfos, accessLevel, shareMessage);
      let addedText;
      if (anyGroups || addCount > 1) {
        addedText = intl.formatMessage({
          defaultMessage: 'People added.',
          id: 'inzbRm',
          description: 'Snackbar text that appears when people have been added to a project.',
        });
      } else {
        addedText = intl.formatMessage({
          defaultMessage: 'Person added.',
          id: '3bx0O6',
          description: 'Snackbar text that appears when people have been added to a project.',
        });
      }

      setSnackbarText(addedText);
      const old_recipients = shareRecipients?.[sharedFolderId];

      if (anyGroups && successGroupShareLoggingCallback) {
        const totalGroupUsers = contactInfos
          .filter((c): c is GroupContactInfo => c.type === 'group') // Type guard here
          .reduce((total, group) => {
            return total + (group.groupSize || 0); // 'group' is now known to be GroupContactInfo
          }, 0);
        successGroupShareLoggingCallback(
          totalGroupUsers,
          accessLevel === 'admin' ? 'admin' : 'reviewer',
          old_recipients ? Object.keys(old_recipients).length + addCount : 0,
        );
      }

      successCallback(
        addCount,
        accessLevel === 'admin' ? 'admin' : 'reviewer',
        old_recipients ? Object.keys(old_recipients).length + addCount : 0,
      );
      closeModal();
    } catch (e) {
      const tag = e.error && e.error.error && e.error.error['.tag'];
      if (tag && tag === 'emails_without_dropbox_account') {
        const invalidEmails = e.error.error.email_addresses;
        setErrorCount(invalidEmails.length);
        setContactInfos((prevState) => {
          return prevState.map((c) => {
            if (c.type === 'user' && invalidEmails.includes(c.email)) {
              c.valid = false;
            }
            return c;
          });
        });
        const formattedErrorText = intl.formatMessage({
          defaultMessage: '⚠ You can only invite users with a Dropbox account at this time.',
          id: 'qvfo8Y',
          description:
            'An error that is displayed when a user attempts to add another user to project that does not have a Dropbox account.',
        });
        setErrorText(formattedErrorText);
      } else if (
        tag === 'sharing_is_restricted' ||
        tag === 'rate_limit_reached' ||
        tag === 'dmca_requester' ||
        tag === 'email_not_verified' ||
        tag === 'users_that_conflict_with_team_policies'
      ) {
        const requester = e.error.error['dmca_requester'];
        const formattedSnackbarText = getRestrictedContentErrorTextFromType(intl, tag, requester);
        setSnackbarText(formattedSnackbarText);
      } else {
        const formattedSnackbarText = intl.formatMessage({
          defaultMessage: "Couldn't add people. Please try again.",
          id: 'W5khk1',
          description:
            'A message that is displayed in the snackbar when a user attempts to add another user to project and there is a failure in the request.',
        });
        setSnackbarText(formattedSnackbarText);
      }
    }
  };

  const closeModal = () => {
    setAccessLevel('admin');
    setMessage('');
    setContactInfos([]);
    loggingClient.logPap(
      PAP_Select_ModalAction({
        modal: 'add_people',
        modalAction: 'cancel',
      }),
    );

    requestClose();
  };

  const closeSnackbar = () => {
    setSnackbarText('');
  };

  React.useEffect(() => {
    if (errorCount === 0) {
      setErrorText('');
    }
  }, [errorCount]);

  const setAccessType = (accessType: ShareFolderAccessType) => {
    setAccessLevel(accessType);
  };

  const withCloseButtonText = intl.formatMessage({
    defaultMessage: 'Close create folder modal',
    id: 't4w0L7',
    description:
      'A message shown as label for modal that will close the modal for creating folders',
  });
  const inputPlaceholderText = intl.formatMessage({
    defaultMessage: 'Email or name',
    id: 'wkXzFM',
    description:
      'A placeholder text that requests an email or name in order to add people to a project.',
  });
  const inputPlaceholderTextWithGroup = intl.formatMessage({
    defaultMessage: 'Email, name or group',
    id: 'zkaSyY',
    description:
      'A placeholder text that requests an email, name, or group in order to add entity to project.',
  });
  const inputLabelText = intl.formatMessage({
    defaultMessage: 'Person to add',
    id: 'N40iFH',
    description:
      'A label for the input that requests an email or name in order to add people to a project.',
  });
  const inputLabelWithGroupText = intl.formatMessage({
    defaultMessage: 'Person or group to add',
    id: 'AAsi6p',
    description:
      'A label for the input that requests an email, name, or group in order to add entity to a project.',
  });
  const textAreaLabelText = intl.formatMessage({
    defaultMessage: 'Invite Message',
    id: '02GXgt',
    description:
      'A label for the text area for a message that is sent along with an invite to add people to a project.',
  });
  const textAreaEditPlaceholderText = intl.formatMessage({
    defaultMessage:
      'As a project member, you can manage project files and give frame-by-frame feedback.',
    id: 'JxQMZ4',
    description:
      'Default text that is sent in an email along with an invite to add people to a project with edit permissions. Having edit access gives users access to files and folders inside the project, ability to leave feedback and the ability to manage files and folders.',
  });
  // @ts-ignore - This is a new message that is not yet translated
  const textAreaEditPlaceholderTextV2 = intl.formatMessage({
    defaultMessage:
      'You can manage files, give frame-by-frame feedback, and post editor-only comments.',
    id: 'qQrPpN',
    description:
      'Default text that is sent in an email along with an invite to add people to a project with edit permissions. Having edit access gives users access to files and folders inside the project, ability to leave feedback and the ability to manage files and folders.',
  });
  const textAreaViewPlaceholderText = intl.formatMessage({
    defaultMessage: 'Now you can view project files and give frame-by-frame feedback.',
    id: '1jJKMr',
    description:
      'Default text that is sent in an email along with an invite to add people to a project with view permissions. Having view-only access gives users access to files and folders inside the project and the ability to leave feedback.',
  });
  // @ts-ignore - This is a new message that is not yet translated
  const textAreaViewPlaceholderTextV2 = intl.formatMessage({
    defaultMessage: 'You can view files in this project and leave frame-by-frame feedback.',
    id: 'WRiRyc',
    description:
      'Default text that is sent in an email along with an invite to add people to a project with view permissions. Having view-only access gives users access to files and folders inside the project and the ability to leave feedback.',
  });

  return (
    <>
      <Modal
        isCentered
        onRequestClose={closeModal}
        open={open}
        width="small"
        withCloseButton={withCloseButtonText}
      >
        <Modal.Header hasBottomSpacing="title-small">
          <ModalTitle>
            <Title isBold size="small">
              <FormattedMessage
                defaultMessage="Add people to {sharedFolderName}"
                description="Text at the top of the add people modal that describes the action of the form and what folder the people will be added to."
                id="i57t7q"
                values={{sharedFolderName}}
              />
            </Title>
          </ModalTitle>
        </Modal.Header>
        <ModalBody>
          <ModalBodyTitle>
            <TypeaheadWrapper>
              <DropboxAccountTypeahead
                existingSharedFolderRecipients={shareRecipients}
                inputLabelText={areFssGroupsSupported ? inputLabelWithGroupText : inputLabelText}
                inputPlaceholderText={
                  areFssGroupsSupported ? inputPlaceholderTextWithGroup : inputPlaceholderText
                }
                setContacts={setContacts}
                sharedFolderId={sharedFolderId}
                type="team"
              />
            </TypeaheadWrapper>
            <ModalErrorTextWrapper>{errorText}</ModalErrorTextWrapper>
            <TextAreaContainer>
              <TextAreaWrapper
                aria-label={textAreaLabelText}
                onChange={(e) => setMessage(e.currentTarget.value)}
                placeholder={
                  accessLevel === 'admin'
                    ? textAreaEditPlaceholderText
                    : textAreaViewPlaceholderText
                }
                resizable="auto"
                value={message}
              />
            </TextAreaContainer>
            <RoleSettings setAccessType={setAccessType} />
          </ModalBodyTitle>
          <ModalButtons>
            <CreateButton>
              <Button
                disabled={errorCount > 0 || contactInfos.length === 0}
                onClick={handleAddTeamMembers}
                size="standard"
                type="submit"
                variant="primary"
              >
                <FormattedMessage
                  defaultMessage="Add"
                  description="Text on button that confirms and sends invites to users to join a project."
                  id="wHHiQ0"
                />
              </Button>
            </CreateButton>
          </ModalButtons>
        </ModalBody>
      </Modal>
      <AddPeopleSnackbar onClose={closeSnackbar} snackbarText={snackbarText} />
    </>
  );
};

type AddPeopleSnackbarProps = {
  snackbarText: string;
  onClose: () => void;
};

export const AddPeopleSnackbar = ({snackbarText, onClose}: AddPeopleSnackbarProps) => {
  return (
    <ReelSnackbar onRequestClose={onClose} open={Boolean(snackbarText)} timeout={4000}>
      <Snackbar.Message>{snackbarText}</Snackbar.Message>
      <Snackbar.Actions>
        <Button inverse onClick={onClose} variant="transparent">
          <FormattedMessage
            defaultMessage="OK"
            description="Text on add team member snackbar that dismisses the snackbar."
            id="DJf8+z"
          />
        </Button>
      </Snackbar.Actions>
    </ReelSnackbar>
  );
};

export type RoleSettingsProps = {
  setAccessType: (accessType: ShareFolderAccessType) => void;
};

const RoleSettings = (props: RoleSettingsProps) => {
  type AddTeamModalAccessTypes = 'admin' | 'reviewer';

  const intl = useIntl();
  const loggingClient = useLoggingClient();

  const [selectedRole, setSelectedRole] = React.useState<AddTeamModalAccessTypes>('admin');

  const handleSelection = (selection: AddTeamModalAccessTypes) => {
    setSelectedRole(selection);
    loggingClient.logPap(
      PAP_Select_ModalAction({
        modal: 'add_people',
        modalAction: selection === 'admin' ? 'can_edit' : 'can_comment',
      }),
    );
    props.setAccessType(selection);
  };

  const handleToggle: WrapperOnToggle = (isOpen) => {
    if (isOpen) {
      loggingClient.logPap(
        PAP_Select_ModalAction({
          modal: 'add_people',
          modalAction: 'permission_menu',
        }),
      );
    }
  };

  const adminRoleText = intl.formatMessage({
    defaultMessage: 'Can edit',
    id: 'WTrgE/',
    description:
      'Text on a menu option that describes an ability given to people added through add team member modal. This option allows the added members to edit the project.',
  });
  const reviewerRoleText = intl.formatMessage({
    defaultMessage: 'Can comment',
    id: 'MdXfmi',
    description:
      'Text on a menu option that describes an ability given to people added through add team member modal. This option allows the added members to view and comment on the project.',
  });

  const RoleToDescriptionMap: {[key in AddTeamModalAccessTypes]: string} = {
    admin: adminRoleText,
    reviewer: reviewerRoleText,
  };

  return (
    <>
      <Text>
        <FormattedMessage
          defaultMessage="Project access:"
          description="Text that precedes a menu to choose what abilities added people will have."
          id="kjM3rs"
        />
      </Text>
      <Menu.Wrapper onSelection={handleSelection} onToggle={handleToggle}>
        {({getContentProps, getTriggerProps}) => (
          <SelectRoleWrapper>
            <Button {...getTriggerProps()} variant="transparent" withDropdownIcon>
              <Text isBold={true}>{RoleToDescriptionMap[selectedRole]}</Text>
            </Button>
            <Menu.Content {...getContentProps()} placement="bottom-end">
              <Menu.Segment>
                <Menu.SelectItem key={0} selected={selectedRole === 'admin'} value="admin">
                  {adminRoleText}
                </Menu.SelectItem>
                <Menu.SelectItem key={1} selected={selectedRole === 'reviewer'} value="reviewer">
                  {reviewerRoleText}
                </Menu.SelectItem>
              </Menu.Segment>
            </Menu.Content>
          </SelectRoleWrapper>
        )}
      </Menu.Wrapper>
    </>
  );
};
