import {useCallback} from 'react';

import {matchPath, useLocation} from 'react-router';

import {SurfaceType} from '~/lib/logging/logger_types';
import {
  BROWSE_FOLDER_ROUTE_MATCH,
  BROWSE_ROUTE_MATCH,
  CREATE_ONBOARDING_V2_VIDEO_ROUTE_MATCH,
  CREATING_PROJECT_FROM_FILE_ROUTE_MATCH,
  VIDEO_ROUTE_MATCH,
} from '~/route_path_matches';

const useActionSurface = (): (() => SurfaceType) => {
  const {pathname} = useLocation();
  return useCallback<() => SurfaceType>(() => {
    const isVideoRoute = !!matchPath(
      {
        path: VIDEO_ROUTE_MATCH,
        end: true,
      },
      pathname,
    );

    const isBrowsePageRoute = !!matchPath(
      {
        path: BROWSE_ROUTE_MATCH,
        end: true,
      },
      pathname,
    );

    const isBrowseFolderPageRoute = !!matchPath(
      {
        path: BROWSE_FOLDER_ROUTE_MATCH,
        end: true,
      },
      pathname,
    );

    const isCreateOnboardingVideoRoute = !!matchPath(
      {
        path: CREATE_ONBOARDING_V2_VIDEO_ROUTE_MATCH,
        end: true,
      },
      pathname,
    );

    const isCreateProjectFromFileRoute = !!matchPath(
      {
        path: CREATING_PROJECT_FROM_FILE_ROUTE_MATCH,
        end: true,
      },
      pathname,
    );

    const isSettingsPageRoute = matchPath(
      {
        path: '/settings',
      },
      pathname,
    );

    if (isVideoRoute) {
      return SurfaceType.Video;
    } else if (isBrowsePageRoute || isBrowseFolderPageRoute) {
      return SurfaceType.Browse;
    } else if (isCreateOnboardingVideoRoute) {
      return SurfaceType.CreateOnboardingVideo;
    } else if (isCreateProjectFromFileRoute) {
      return SurfaceType.CreateVideo;
    } else if (isSettingsPageRoute) {
      return SurfaceType.Settings;
    } else {
      return SurfaceType.Unknown;
    }
  }, [pathname]);
};

export {useActionSurface};
