import React from 'react';

import styled from 'styled-components';

import type {LinkInfoResult} from '../../lib/api';

export const SNACKBAR_TIMEOUT = 8000;

export type ShareLinkPermission = 'review' | 'view-only';

export type LinkType = 'viewing' | 'commenting';

export type CopyRequest =
  | {
      type: 'none';
    }
  | {
      type: 'in_progress';
      linkType: LinkType;
    }
  | {
      type: 'success';
      linkType: LinkType;
      result: string;
    }
  | {
      type:
        | 'error'
        | 'irrecoverable_error'
        | 'sharing_is_restricted'
        | 'dmca_requester'
        | 'email_not_verified';
      error: Error;
      linkType: LinkType;
      dmca_requester: string | undefined;
    }
  | {
      type: 'copy_error';
      error: Error;
      result: string;
    };

export type CopyRequestState = {
  request: CopyRequest;
  requestId: number;
};

export type LinkCreationState =
  | {status: 'none'}
  | {status: 'being_modified'; linkPromise: Promise<string | undefined>}
  | {status: 'created'; linkPromise: Promise<string>};

export type ExistingLinkFetchState =
  | {status: 'none'}
  | {status: 'fetching'}
  | {status: 'available'; existingLinks: LinkInfoResult}
  | {status: 'error'};

type OnShareClickProps = {
  getExistingLinks: () => Promise<LinkInfoResult>;
  doNotAutoFetch?: boolean;
  mediaId: string;
};

export type ExistingLinksPromise = Promise<LinkInfoResult | null> | 'unfetched';

type OnShareClickHook = {
  existingLinksPromise: ExistingLinksPromise;
  refetchLinks: () => ExistingLinksPromise;
  lastFetchTime: number;
};

export const useOnShareClick = (props: OnShareClickProps): OnShareClickHook => {
  const updateExistingLinks = React.useCallback(async (): Promise<LinkInfoResult | null> => {
    try {
      const links = await props.getExistingLinks();
      return links;
    } catch (_) {
      return null;
    }
  }, [props]);

  const [lastFetchTime, setLastFetchTime] = React.useState<number>(0);
  const [existingLinksPromise, setExistingLinksPromise] =
    React.useState<ExistingLinksPromise>('unfetched');
  const [fetchedFor, setFetchedFor] = React.useState<string>('');
  const mediaId = React.useMemo<string>(() => props.mediaId, [props]);

  const refetchLinks = React.useCallback(() => {
    const newPromise = updateExistingLinks();
    setExistingLinksPromise(newPromise);
    setLastFetchTime(Date.now());
    return newPromise;
  }, [updateExistingLinks]);

  React.useEffect(() => {
    if (!props.doNotAutoFetch && mediaId && mediaId !== fetchedFor) {
      setFetchedFor(mediaId);
      refetchLinks();
    }
  }, [mediaId, fetchedFor, props.doNotAutoFetch, refetchLinks]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (existingLinksPromise !== 'unfetched') {
        refetchLinks();
      }
    }, 300000);
    return () => clearTimeout(interval);
  }, [existingLinksPromise, refetchLinks]);

  return {
    existingLinksPromise,
    refetchLinks,
    lastFetchTime,
  };
};

export const ShareModalTitle = styled.div`
  margin-bottom: -10px;
`;
