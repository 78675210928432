import React, {type ComponentType} from 'react';

import {setSentryEnabled} from '~/lib/logging/sentry';
import {replayStorage} from '~/lib/storage';

const refreshTimeout = 1000 * 60 * 5; // 5 minutes

const chunkLoadErrorPattern = /ChunkLoadError/;

let isNavigating = false;
window.addEventListener('beforeunload', () => {
  isNavigating = true;
});

export const lazy = <T extends ComponentType<any>>(page: () => Promise<{default: T}>) =>
  // eslint-disable-next-line no-restricted-syntax
  React.lazy(async () => {
    const lastRefreshTime = replayStorage.getLastPageRefreshTime();

    try {
      return await page();
    } catch (error) {
      const isChunkLoadError = chunkLoadErrorPattern.test(error.name);
      const now = Date.now();
      // If the page has not been reloaded in the last 5 minutes, refresh the page
      if (isChunkLoadError && !isNavigating && now > lastRefreshTime + refreshTimeout) {
        // Assume the user is using an outdated version of the app.
        replayStorage.setLastPageRefreshTime(now);

        // Disable sentry logs to not pick up this error or any caused by
        // the sudden reload
        setSentryEnabled(false);
        window.location.reload();
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error;
    }
  });
