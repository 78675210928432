/* eslint-disable deprecation/deprecation */
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import {useAtom, useAtomValue} from 'jotai';
import {PAP_Dismiss_UploadDrawer} from 'pap-events/replay/dismiss_upload_drawer';
import {PAP_Select_ReelMediaAction} from 'pap-events/replay/select_reel_media_action';
import {PAP_Select_UploadDrawer} from 'pap-events/replay/select_upload_drawer';
import {defineMessages, FormattedMessage, useIntl} from 'react-intl';
import {useNavigate} from 'react-router';
import styled from 'styled-components';

import {IconButtonGroup} from '@dropbox/dig-components/combinations';
import {Drawer} from '@dropbox/dig-components/drawer';
import {Text} from '@dropbox/dig-components/typography';
import {Box} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {ChevronDownLine, ChevronUpLine} from '@dropbox/dig-icons/assets';

import {IconButton} from '~/components/button';
import {color, motionDuration, spacing} from '~/components/styled';
import type {ManageAddonsLink} from '~/components/upload_drawer/status_bar';
import {
  StatusBar,
  intlMessages as statusBarIntlMessages,
} from '~/components/upload_drawer/status_bar';
import {UploadRow} from '~/components/upload_drawer/upload_row';
import {SMALL_SCREEN_WIDTH_BREAKPOINT} from '~/components/viewport_context';
import type {CtaType, ProvisionUserVariant} from '~/lib/provisions';
import {SINGLE_PURCHASE_URL, useGetProvisionCta, useGetUserVariant} from '~/lib/provisions';
import type {
  CalculatedUploadValues,
  MappedUploads,
  UploadInfo,
  UploadProgress,
  UploadsProgress,
  UploadsVersionSummaries,
} from '~/lib/uploads/types';
import {FileUploadState, UploadDrawerStatus, UploadErrorType} from '~/lib/uploads/types';
import {useActionSurface} from '~/lib/use_action_surface';
import {useLoggingClient} from '~/lib/use_logging_client';
import {openURLInExternalBrowser} from '~/lib/utils';
import {
  currentUploadBatchIdsAtom,
  isUploadDrawerOpenAtom,
  isUploadDrawerShownAtom,
  uploadsAtom,
  uploadsProgressAtom,
  versionSummariesAtom,
  viewNewUploadFunctionAtom,
} from '~/state/uploads';
import {isInWebView} from '~/use_extensions';

const UploadDrawerHeader = styled(Drawer.Header)`
  margin-bottom: ${spacing('16')};
  padding-bottom: 0;
  padding-top: ${spacing('24')};
`;

const UploadDrawerLayout = styled.div`
  height: 0%;
  width: 100%;
`;

type UploadDrawerContainerProps = {
  $isDrawerOpen: boolean;
};
const UploadDrawerContainer = styled.div<UploadDrawerContainerProps>`
  align-items: flex-end;
  bottom: 0;
  display: flex;
  height: ${(props) => (props.$isDrawerOpen ? 510 : 0)}px;
  position: absolute;
  right: ${spacing('32')};
  transition: all ${motionDuration('Medium')} ease-out;
  width: 440px;

  @media screen and (max-width: ${SMALL_SCREEN_WIDTH_BREAKPOINT}px) {
    right: 0;
    width: 100%;
  }
`;
const StyledOverlayDrawer = styled(Drawer.OverlayDrawer)`
  border: 1px solid ${color('Border Base')};
  height: 510px;
`;

const UploadDrawerBody = styled(Drawer.Body)`
  margin-bottom: calc(62px + var(--dig-spacing__micro__small));
`;

type UploadDrawerProps = {
  currentUploadBatchIds?: string[];
  isDrawerOpen?: boolean;
  manageAddonsLink?: ManageAddonsLink;
  uploads: MappedUploads;
  uploadsProgress: UploadsProgress;
  userVariant?: ProvisionUserVariant;
  versionSummaries: UploadsVersionSummaries;
  handleOnClose?: () => void;
  handleOnRetry?: () => void;
  handleOnUpdateDrawerStatus?: (status: UploadDrawerStatus) => void;
  handleOpenCloseDrawer?: () => void;
  handleViewNewUpload?: (uploadInfo: UploadInfo) => void;
};

const UploadDrawer = ({
  currentUploadBatchIds = [],
  isDrawerOpen = true,
  manageAddonsLink,
  uploads,
  uploadsProgress,
  handleOnClose,
  userVariant = 'none',
  versionSummaries,
  handleOnUpdateDrawerStatus = () => {},
  handleOpenCloseDrawer = () => {},
  handleViewNewUpload = () => {},
}: UploadDrawerProps) => {
  const intl = useIntl();
  const uploadDrawerRef = useRef<HTMLDivElement>(null);
  const [uploadDrawerWidth, setUploadDrawerWidth] = useState(0);

  useEffect(() => {
    if (!uploadDrawerRef.current) {
      return;
    }
    const resizeObserver = new ResizeObserver(() => {
      if (uploadDrawerRef.current && uploadDrawerRef.current?.offsetWidth !== uploadDrawerWidth) {
        setUploadDrawerWidth(uploadDrawerRef.current?.offsetWidth);
      }
    });
    resizeObserver.observe(uploadDrawerRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [uploadDrawerWidth, uploadDrawerRef]);

  const calculatedUploadValues = useMemo<CalculatedUploadValues>(() => {
    return Object.keys(uploadsProgress).reduce(
      (prevCalculatedValues: CalculatedUploadValues, uploadId: string) => {
        if (currentUploadBatchIds.length > 0 && !currentUploadBatchIds.includes(uploadId)) {
          return prevCalculatedValues;
        }

        const {status, percentage, error}: UploadProgress = uploadsProgress[uploadId];
        const newCalculatedValues = prevCalculatedValues;

        if (status === FileUploadState.Uploading) {
          newCalculatedValues.numUploading += 1;
          newCalculatedValues.totalPercentage += percentage || 0;
        } else if (status === FileUploadState.Ready) {
          newCalculatedValues.numReady += 1;
        } else if (status === FileUploadState.Complete) {
          newCalculatedValues.numSuccessful += 1;
          newCalculatedValues.totalPercentage += percentage || 0;
        } else if (status === FileUploadState.Error) {
          newCalculatedValues.numErrors += 1;
          newCalculatedValues.totalPercentage += 100;

          if (error && !newCalculatedValues.errors.includes(error)) {
            newCalculatedValues.errors.push(error);
          }
        } else if (status === FileUploadState.Cancelled) {
          newCalculatedValues.numCanceled += 1;
        }

        if (status !== FileUploadState.Cancelled) {
          newCalculatedValues.numTotalUploads += 1;
        }

        return newCalculatedValues;
      },
      {
        numCanceled: 0,
        numErrors: 0,
        numSuccessful: 0,
        numUploading: 0,
        numReady: 0,
        numTotalUploads: 0,
        totalPercentage: 0,
        errors: [],
      },
    );
  }, [uploadsProgress, currentUploadBatchIds]);

  const drawerUploadStatus = useMemo(() => {
    const {numUploading, numReady, numErrors, numTotalUploads, errors, numCanceled} =
      calculatedUploadValues;

    if (numUploading > 0 || numReady > 0) {
      return UploadDrawerStatus.UPLOADING;
    }
    if (numErrors > 0) {
      if (errors.includes(UploadErrorType.FILE_QUOTA_EXCEEDED_ERROR)) {
        return UploadDrawerStatus.FILE_QUOTA_EXCEEDED;
      } else if (numTotalUploads > 0 && numErrors === numTotalUploads) {
        return UploadDrawerStatus.ALL_FAILED;
      }
      return UploadDrawerStatus.SOME_FAILED;
    } else if (numCanceled > 0 && numTotalUploads === 0) {
      return UploadDrawerStatus.CANCELED;
    }
    return UploadDrawerStatus.SUCCESS;
  }, [calculatedUploadValues]);

  useEffect(() => {
    handleOnUpdateDrawerStatus(drawerUploadStatus);
  }, [drawerUploadStatus, handleOnUpdateDrawerStatus]);

  const {notFailed, failed} = useMemo(() => {
    return Object.entries(uploads)
      .filter(([_id, {hideDrawer}]) => !hideDrawer)
      .reduce(
        ({notFailed, failed}, [uploadId, upload]) => {
          const uploadProgress = uploadsProgress[uploadId];
          const uploadversionSummaries = versionSummaries[uploadId];

          if (upload && uploadProgress) {
            const newRow = (
              <UploadRow
                drawerUploadStatus={drawerUploadStatus}
                handleViewNewUpload={handleViewNewUpload}
                key={`${uploadId}--upload-drawer-row`}
                upload={upload}
                uploadDrawerWidth={uploadDrawerWidth}
                uploadProgress={uploadProgress}
                userVariant={userVariant}
                versionSummaries={uploadversionSummaries}
              />
            );

            if (uploadProgress.status === FileUploadState.Error) {
              return {
                notFailed,
                failed: [...failed, newRow],
              };
            }

            return {
              notFailed: [...notFailed, newRow],
              failed,
            };
          }

          return {
            notFailed,
            failed,
          };
        },
        {
          notFailed: [] as React.ReactElement[],
          failed: [] as React.ReactElement[],
        },
      );
  }, [
    uploads,
    uploadsProgress,
    versionSummaries,
    drawerUploadStatus,
    handleViewNewUpload,
    uploadDrawerWidth,
    userVariant,
  ]);

  return (
    <UploadDrawerLayout>
      <UploadDrawerContainer $isDrawerOpen={isDrawerOpen} ref={uploadDrawerRef}>
        <StatusBar
          calculatedUploadValues={calculatedUploadValues}
          drawerUploadStatus={drawerUploadStatus}
          handleOnClose={handleOnClose}
          handleOnCollapse={handleOpenCloseDrawer}
          isDrawerOpen={isDrawerOpen}
          manageAddonsLink={manageAddonsLink}
        />
        <StyledOverlayDrawer
          alignment="bottom"
          height="510"
          isOpen={isDrawerOpen}
          isPortaled={false}
        >
          <Drawer.Container>
            <UploadDrawerHeader isBorderHidden>
              <Drawer.Layout>
                <Drawer.LayoutItem width="fill">
                  <Text isBold variant="label">
                    <FormattedMessage {...intlMessages.title} />
                  </Text>
                </Drawer.LayoutItem>
                <Drawer.LayoutItem shift="right">
                  <IconButtonGroup>
                    {({getButtonProps}) => (
                      <>
                        <IconButton
                          aria-label={intl.formatMessage(
                            isDrawerOpen
                              ? statusBarIntlMessages.collapseDrawerLabel
                              : statusBarIntlMessages.expandDrawerLabel,
                          )}
                          variant="borderless"
                          {...getButtonProps()}
                          onClick={handleOpenCloseDrawer}
                        >
                          <UIIcon src={isDrawerOpen ? ChevronDownLine : ChevronUpLine} />
                        </IconButton>
                      </>
                    )}
                  </IconButtonGroup>
                </Drawer.LayoutItem>
              </Drawer.Layout>
            </UploadDrawerHeader>

            <UploadDrawerBody>
              {notFailed.map((row) => row)}
              {failed.length > 0 && (
                <>
                  <Box
                    borderColor="Alert Base"
                    borderTop="Solid"
                    marginTop="0"
                    paddingTop="8"
                    paddingX="16"
                  >
                    <Text color="error" isBold size="xsmall">
                      <FormattedMessage {...intlMessages.failed} />
                    </Text>
                  </Box>
                  {failed.map((row) => row)}
                </>
              )}
            </UploadDrawerBody>
          </Drawer.Container>
        </StyledOverlayDrawer>
      </UploadDrawerContainer>
    </UploadDrawerLayout>
  );
};

const ConnectedUploadDrawer = () => {
  const [drawerStatus, setDrawerStatus] = useState(UploadDrawerStatus.UPLOADING);
  const userVariant = useGetUserVariant();
  const provisionCTA = useGetProvisionCta(userVariant);
  const ctaLink = `${SINGLE_PURCHASE_URL}?replay_purchase_origin=upload_drawer`;
  const navigate = useNavigate();
  const loggingClient = useLoggingClient();
  const getActionSurface = useActionSurface();

  const uploads = useAtomValue(uploadsAtom);
  const uploadsProgress = useAtomValue(uploadsProgressAtom);
  const versionSummaries = useAtomValue(versionSummariesAtom);
  const currentUploadBatchIds = useAtomValue(currentUploadBatchIdsAtom);
  const viewNewUploadFunction = useAtomValue(viewNewUploadFunctionAtom);
  const [isUploadDrawerShown, setIsUploadDrawerShown] = useAtom(isUploadDrawerShownAtom);
  const [isUploadDrawerOpen, setIsUploadDrawerOpen] = useAtom(isUploadDrawerOpenAtom);

  const numFiles = useMemo(() => {
    return Object.keys(uploads).length;
  }, [uploads]);

  const handleOnCloseDrawer = () => {
    setIsUploadDrawerShown(false);
    loggingClient.logPap(
      PAP_Dismiss_UploadDrawer({
        actionSurface: getActionSurface(),
        numFiles,
        replayUploadState: drawerStatus,
      }),
    );
  };

  const handleOpenCloserDrawer = () => {
    const isExpanded = !isUploadDrawerOpen;
    setIsUploadDrawerOpen(isExpanded);
    loggingClient.logPap(
      PAP_Select_UploadDrawer({
        actionSurface: getActionSurface(),
        isExpanded,
        numFiles,
        replayUploadState: drawerStatus,
      }),
    );
  };

  const handleOpenViewLink = (upload: UploadInfo) => {
    loggingClient.logPap(
      PAP_Select_ReelMediaAction({actionSurface: getActionSurface(), actionType: 'view'}),
    );

    if (viewNewUploadFunction.fn) {
      viewNewUploadFunction.fn(upload);
    } else if (upload.link) {
      navigate(upload.link);
    }
  };

  const manageAddonsLink = useMemo(() => {
    return {
      ctaLink: userVariant !== 'basic_individual' && !isInWebView() ? ctaLink : '',
      ctaLabel: provisionCTA.ctaLabel,
      ctaType: 'manage_add_on' as CtaType,
      onClickLink: () => {
        if (isInWebView()) {
          openURLInExternalBrowser(ctaLink);
        }

        loggingClient.logEvent('select.cta_link', {
          link_type: 'purchase_addon',
          cta_location: 'upload_drawer',
        });
      },
    };
  }, [loggingClient, provisionCTA.ctaLabel, ctaLink, userVariant]);

  const handleOnUpdateDrawerStatus = useCallback(
    (newStatus: UploadDrawerStatus) => {
      setDrawerStatus(newStatus);
    },
    [setDrawerStatus],
  );

  return isUploadDrawerShown && Object.keys(uploads).length > 0 ? (
    <UploadDrawer
      currentUploadBatchIds={currentUploadBatchIds}
      handleOnClose={handleOnCloseDrawer}
      handleOnUpdateDrawerStatus={handleOnUpdateDrawerStatus}
      handleOpenCloseDrawer={handleOpenCloserDrawer}
      handleViewNewUpload={handleOpenViewLink}
      isDrawerOpen={isUploadDrawerOpen}
      manageAddonsLink={manageAddonsLink}
      uploads={uploads}
      uploadsProgress={uploadsProgress}
      userVariant={userVariant}
      versionSummaries={versionSummaries}
    />
  ) : null;
};

export {UploadDrawer, ConnectedUploadDrawer};

const intlMessages = defineMessages({
  title: {
    defaultMessage: 'Uploads',
    id: 'RcM3s5',
    description: 'Title of upload drawer',
  },
  failed: {
    defaultMessage: 'Failed',
    id: 'jYB7Zz',
    description: 'label for failed uploads section',
  },
});
