import React from 'react';

import styled, {css} from 'styled-components';

import {breakpointMedium, breakpointSmall, spacing} from '~/components/styled';
import type {ShareFolderAccessType} from '~/lib/api';
import type {AddMediaClickSourceType} from '~/lib/logging/logger_types';
import type {
  AllUploadsCompleteHandler,
  BasicFileInfo,
  ChooserFile,
  DirectUpload,
} from '~/lib/uploads/types';
import {useGetPapAccessType} from '~/lib/use_get_pap_access_type';
import {useBrowseLogEventMaker} from '~/pages/browse_page/browse_logging_context';
import {AddPeopleButton} from '~/pages/browse_page/components/add_people_button';
import type {ShareRecipient} from '~/pages/browse_page/components/common';
import {CreateFolderButton} from '~/pages/browse_page/components/create_folder_button';
import {ManagePeopleButton} from '~/pages/browse_page/components/manage_people_button';
import {useFoldersSharedState} from '~/pages/browse_page/folders_context';

import {DownloadFolderButtonAndModal} from './download/download_folder_button_and_modal';
import {MediaAdder} from './media_adder';
import {ShareFolderButton} from './share_folder_button';

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: ${spacing('Micro Small')};

  align-items: flex-start;

  margin-top: ${spacing('Micro Small')};
  ${breakpointSmall(
    css`
      margin-top: ${spacing('Micro Medium')};
      flex-direction: row;
    `,
  )}

  ${breakpointMedium(css`
    align-items: flex-end;
    margin-top: 0;
  `)};
`;

const RowContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  grid-gap: ${spacing('Micro Small')};
`;

type ActionButtonsProps = {
  accessLevel: ShareFolderAccessType;
  addVideoFromDropboxCallback: (
    files: ChooserFile[],
    click_source: AddMediaClickSourceType,
    level: number,
  ) => Promise<void>;
  currentFolderId: string;
  currentFolderName: string;
  folderLevel: number;
  onAddVideoErrorCallback: () => void;
  onAllUploadsCompleteCallback: AllUploadsCompleteHandler;
  onCreateFolderSuccessCallback: (folderToShow: string, level: number) => Promise<void>;
  onFilePickCallback: (files: BasicFileInfo[]) => void;
  onFileUploadCompleteCallback: (
    click_source: AddMediaClickSourceType,
    level: number,
    projectId: string,
    videoId: string,
    name: string,
  ) => void;
  openAddPeopleModal: (sharedFolderId: string, sharedFolderName: string) => void;
  settingsChangeCallback(folderId: string): void;
  shareRecipients?: ShareRecipient[] | 'loading';
  showAddPeopleButton: boolean;
  showAddVideoButton: boolean;
  showCreateFolderButton: boolean;
  showManagePeopleButton: boolean;
  showShareButton: 'team_project' | 'standard' | 'none';
  allowSuperAdminRights?: boolean;
  showDownloadFolderButton: boolean;
};

export const ActionButtons = ({
  accessLevel,
  addVideoFromDropboxCallback,
  currentFolderId,
  currentFolderName,
  folderLevel,
  onAddVideoErrorCallback,
  onAllUploadsCompleteCallback,
  onCreateFolderSuccessCallback,
  onFilePickCallback,
  onFileUploadCompleteCallback,
  openAddPeopleModal,
  settingsChangeCallback,
  shareRecipients,
  showAddPeopleButton,
  showAddVideoButton,
  showCreateFolderButton,
  showManagePeopleButton,
  showShareButton,
  showDownloadFolderButton,
}: ActionButtonsProps) => {
  const {
    currentFolderPermission,
    breadcrumbPath,
    currentProjectAmplitudeId,
    currentAllowSuperAdminRights,
  } = useFoldersSharedState();
  const makeLogEvent = useBrowseLogEventMaker();
  const logEvent = React.useMemo(() => makeLogEvent({reelObjectType: 'folder'}), [makeLogEvent]);
  const renderShowManagePeopleButton =
    showManagePeopleButton && shareRecipients && shareRecipients !== 'loading';
  const papAccessType = useGetPapAccessType({accessLevel: currentFolderPermission});

  const shareFolderButton = (
    <ShareFolderButton
      accessLevel={accessLevel}
      buttonVariant={showShareButton === 'team_project' ? 'opacity' : 'outline'}
      folderId={currentFolderId}
      folderName={currentFolderName}
      isTeamFolder={showShareButton === 'team_project'}
      isViewOnlyLinkAccess={false}
      logEvent={logEvent}
    />
  );

  return (
    <ColumnContainer>
      {(renderShowManagePeopleButton || showAddPeopleButton || showDownloadFolderButton) && (
        <RowContainer>
          {showDownloadFolderButton && (
            <DownloadFolderButtonAndModal
              analyticsData={{
                accessType: papAccessType,
                actionElement: 'toolbar',
                folderLevel: folderLevel,
              }}
              displayType="compact"
              downloadOptions={[
                {'.tag': 'latest_files'},
                {'.tag': 'approved_files'},
                {'.tag': 'all_files'},
              ]}
              folderId={currentFolderId}
            />
          )}
          {renderShowManagePeopleButton && (
            <ManagePeopleButton
              accessLevel={currentFolderPermission ?? 'other'}
              allowSuperAdminRights={currentAllowSuperAdminRights}
              onSettingsChange={settingsChangeCallback}
              shareRecipients={shareRecipients}
              teamFolderId={currentFolderId}
              teamFolderName={currentFolderName}
            />
          )}
          {showAddPeopleButton && (
            <AddPeopleButton
              folderId={currentFolderId}
              folderName={currentFolderName}
              openAddPeopleModal={openAddPeopleModal}
            />
          )}
        </RowContainer>
      )}
      <RowContainer>
        {showShareButton === 'team_project' && shareFolderButton}
        {showCreateFolderButton && folderLevel === 1 ? (
          <CreateFolderButton
            currentProjectAmplitudeId={currentProjectAmplitudeId}
            level={folderLevel}
            onSuccess={onCreateFolderSuccessCallback}
            parentFolderId={currentFolderId}
          />
        ) : null}
        {showAddVideoButton ? (
          <>
            <MediaAdder
              buttonSize="standard"
              buttonVariant="opacity"
              clickSource="folder_upload_browse_button"
              currentFolderDepth={breadcrumbPath?.length ?? 1}
              currentFolderId={currentFolderId}
              folderLevel={folderLevel}
              folderUpload={true}
              includeFocusStyles
              loadingState="disabled"
              onAddError={() => onAddVideoErrorCallback()}
              onAddFromDropbox={async (files) => {
                await addVideoFromDropboxCallback(
                  files,
                  'folder_upload_browse_button',
                  folderLevel,
                );
              }}
              onAllUploadsComplete={onAllUploadsCompleteCallback}
              onCreateFolderSuccessCallback={onCreateFolderSuccessCallback}
              onFilePick={onFilePickCallback}
              onFileUploadComplete={(
                {projectId, file: {name}, videoId}: DirectUpload,
                fileId: string,
              ) =>
                onFileUploadCompleteCallback(
                  'folder_upload_browse_button',
                  folderLevel,
                  projectId!,
                  videoId!,
                  name,
                )
              }
            />

            <MediaAdder
              buttonSize="standard"
              clickSource="browse_button"
              currentFolderDepth={breadcrumbPath?.length ?? 1}
              currentFolderId={currentFolderId}
              folderLevel={folderLevel}
              folderUpload={false}
              includeFocusStyles
              loadingState="disabled"
              onAddError={() => onAddVideoErrorCallback()}
              onAddFromDropbox={async (files) => {
                await addVideoFromDropboxCallback(files, 'browse_button', folderLevel);
              }}
              onAllUploadsComplete={onAllUploadsCompleteCallback}
              onFilePick={onFilePickCallback}
              onFileUploadComplete={(
                {projectId, file: {name}, videoId}: DirectUpload,
                fileId: string,
              ) =>
                onFileUploadCompleteCallback(
                  'browse_button',
                  folderLevel,
                  projectId!,
                  videoId!,
                  name,
                )
              }
            />
          </>
        ) : null}
        {showShareButton === 'standard' && shareFolderButton}
      </RowContainer>
    </ColumnContainer>
  );
};
