import React from 'react';

import {RELOADED_POST_PURCHASE, replayStorage} from '~/lib/storage';
import {SessionStorage} from '~/lib/storage_utils';
import {useHasFullAddonAccess} from '~/lib/utils';

export const PostPurchaseListener = () => {
  const hasFullAddonAccess = useHasFullAddonAccess();

  React.useEffect(() => {
    const handleFocus = () => {
      if (SessionStorage.get(RELOADED_POST_PURCHASE)) {
        return;
      }

      if (replayStorage.getPurchasedInReplay() && !hasFullAddonAccess) {
        SessionStorage.set(RELOADED_POST_PURCHASE, true);
        window.location.reload();
      }
    };

    window.addEventListener('focus', handleFocus);

    return () => {
      window.removeEventListener('focus', handleFocus);
    };
  }, [hasFullAddonAccess]);

  return null;
};
