import React from 'react';

import styled from 'styled-components';

import {static_url} from '~/lib/static_urls';

export type IconProps = {
  size?: number;
};

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
`;

export const PremiereProIcon = ({size}: IconProps) => {
  const src = `/static/images/reel/Premiere@1x.png`;
  return (
    <IconContainer>
      <img alt="" src={static_url(src)} style={{maxWidth: size, maxHeight: size}} />
    </IconContainer>
  );
};
export const FinalCutProIcon = ({size}: IconProps) => {
  const src = `/static/images/reel/finalcutpro@1x.png`;
  return (
    <IconContainer>
      <img alt="" src={static_url(src)} style={{maxWidth: size, maxHeight: size}} />
    </IconContainer>
  );
};

export const DaVinciResolveIcon = ({size}: IconProps) => {
  const src = `/static/images/reel/DaVinciResolve@1x.png`;
  return (
    <IconContainer>
      <img alt="" src={static_url(src)} style={{maxWidth: size, maxHeight: size}} />
    </IconContainer>
  );
};

export const LumaFusionIcon = ({size}: IconProps) => {
  const src = `/static/images/reel/LumaFusion@1x.png`;
  return (
    <IconContainer>
      <img alt="" src={static_url(src)} style={{maxWidth: size, maxHeight: size}} />
    </IconContainer>
  );
};

export const WeVideoIcon = ({size}: IconProps) => {
  const src = `/static/images/reel/WeVideo@1x.png`;
  return (
    <IconContainer>
      <img alt="" src={static_url(src)} style={{maxWidth: size, maxHeight: size}} />
    </IconContainer>
  );
};

export const ProToolsIcon = ({size}: IconProps) => {
  const src = `/static/images/reel/ProTools@1x.png`;
  return (
    <IconContainer>
      <img alt="" src={static_url(src)} style={{maxWidth: size, maxHeight: size}} />
    </IconContainer>
  );
};

/**
 * The google drive svg is not available through DIG. We don’t manage certain
 * 3rd-party logos because they have specific guidelines.
 */
export const GoogleDriveIcon = ({width}: {width?: string | number}): React.ReactElement => {
  if (!width) {
    width = 20;
  }
  return (
    <img
      alt=""
      aria-hidden="true"
      src={static_url('/static/images/reel/google_drive_icon.svg')}
      width={width}
    />
  );
};

/**
 * The microsoft onedrive icon is also unavailable through DIG.
 */
export const OneDriveIcon = ({
  width,
  height,
}: {
  width?: number;
  height?: number;
}): React.ReactElement => {
  if (!width) {
    width = 20;
  }
  if (!height) {
    // viewBox on our oneDrive svg is '0 5.5 32 20.5'
    // so width:height = 32:20.5
    height = (width * 20.5) / 32;
  }
  return (
    <img
      alt=""
      height={height}
      src={static_url('/static/images/reel/microsoft_onedrive_icon.svg')}
      width={width}
    />
  );
};
