import React, {useCallback, useEffect, useState} from 'react';

import {useAtomValue} from 'jotai';
import {useAtomCallback} from 'jotai/utils';
import {PAP_View_UploadDrawer} from 'pap-events/replay/view_upload_drawer';

import {DropboxChooserProvider} from '~/components/dropbox_chooser_context';
import {GDriveContextProvider} from '~/components/gdrive_context';
import {OneDriveContextProvider} from '~/components/onedrive_context';
import {UploadProvider} from '~/components/upload_context';
import {useLoggingClient} from '~/lib/use_logging_client';
import {isUploadDrawerShownAtom, uploadsAtom} from '~/state/uploads';

type Props = {
  children: React.ReactNode;
};

const UploadWrapper = ({children}: Props) => {
  const [previousIsUploadDrawerShown, setIsPreviousUploadDrawerShown] = useState(false);
  const loggingClient = useLoggingClient();
  const isUploadDrawerShown = useAtomValue(isUploadDrawerShownAtom);
  const getUploads = useAtomCallback(useCallback((get) => get(uploadsAtom), []));

  useEffect(() => {
    if (!previousIsUploadDrawerShown && isUploadDrawerShown) {
      loggingClient.logPap(
        PAP_View_UploadDrawer({
          numFiles: Object.keys(getUploads()).length,
        }),
      );
    }
    if (isUploadDrawerShown !== previousIsUploadDrawerShown) {
      setIsPreviousUploadDrawerShown(isUploadDrawerShown);
    }
  }, [isUploadDrawerShown, getUploads, previousIsUploadDrawerShown, loggingClient]);

  return (
    <DropboxChooserProvider>
      <UploadProvider>
        <GDriveContextProvider>
          <OneDriveContextProvider>{children}</OneDriveContextProvider>
        </GDriveContextProvider>
      </UploadProvider>
    </DropboxChooserProvider>
  );
};

export {UploadWrapper};
