import React from 'react';

import {useAtomValue, useSetAtom} from 'jotai';
import {defineMessages, FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';

import {Accordion} from '@dropbox/dig-components/accordion';
import {Avatar, avatarColorPalette} from '@dropbox/dig-components/avatar';
import {Badge} from '@dropbox/dig-components/badge';
import {Banner} from '@dropbox/dig-components/banner';
import {Modal} from '@dropbox/dig-components/modal';
import {Tooltip} from '@dropbox/dig-components/tooltips';
import {Text} from '@dropbox/dig-components/typography';
import {UIIcon} from '@dropbox/dig-icons';
import {
  AddLine,
  FailLine,
  GlobeLine,
  InfoLine,
  LinkLine,
  SettingsLine,
} from '@dropbox/dig-icons/assets';

import {Button, IconButton} from '~/components/button';
import {useCopyBasicLinkToClipboard} from '~/components/share_modal/copy_link_utils';
import {useShareModalStrings} from '~/components/share_modal/share_modal_text';
import {
  basicShareLinksForItemAtom,
  currentWatermarkLinkAtom,
  getNewlyCreatedShareTokensAtom,
  getShareItemAtom,
  getShareModalLoggingInfoAtom,
  shareModalContentsAtom,
  watermarkLinksForItemAtom,
} from '~/components/share_modal/share_state';
import type {WatermarkLinkInfo} from '~/lib/api';
import {useLinkActions} from '~/lib/use_link_actions';
import {useHasFullAddonAccess} from '~/lib/utils';

export const watermarkText = defineMessages({
  newLinkButton: {
    defaultMessage: 'New link',
    id: 'aBily+',
    description: 'Text for a button that opens a flow to create a new watermark link.',
  },
  helpText: {
    defaultMessage: 'Create a standard link, or track views with individual links.',
    id: '5iaUsH',
    description: 'Help text for the watermark links modal.',
  },
  standardLinkSubtext: {
    defaultMessage: 'Standard link (untracked)',
    id: 'YC0c3i',
    description: 'Text describing a standard watermark link.',
  },
  individualLinkSubtext: {
    defaultMessage: 'Individual link',
    id: 'ylyMzy',
    description: 'Text describing an individual watermark link for a specific email address.',
  },
  noLinks: {
    defaultMessage: 'No links created yet',
    id: 'GFUOuA',
    description: 'Text shown when no watermark links have been created yet.',
  },
  unsupportedFileTypes: {
    defaultMessage: 'Your watermark will only appear on video files.',
    id: 'Rw78xM',
    description:
      'Text shown when a project with watermark links has non-video files uploaded to it.',
  },
  disabledTooltip: {
    defaultMessage: 'Available with the Replay Add-on',
    id: 'poHR/6',
    description:
      'Tooltip text shown for watermarking when the user has not purchased the Replay Add-on.',
  },
  copyLinkTooltip: {
    defaultMessage: 'Copy link',
    id: 'XhrKcB',
    description: 'Tooltip text shown for watermarking row for each user.',
  },
  disabledBanner: {
    defaultMessage: "Watermark links can't be edited.",
    id: 'Q+7FiY',
    description:
      'Banner text shown when the user does not have the Replay Add-on. They cannot edit existing watermark links.',
  },
  disableError: {
    defaultMessage: 'There was an error disabling the link.',
    id: 'o+d4Cj',
    description: 'Error message that appears when disabling a link fails.',
  },
  disableButton: {
    defaultMessage: 'Disable link',
    id: 'T8XLwh',
    description: 'Text for a tooltip for a button that disables a watermark link.',
  },
  settingsButton: {
    defaultMessage: 'Settings',
    id: '1xoyTl',
    description: 'Text for a tooltip for a button that opens the watermark settings.',
  },
  newLabel: {
    defaultMessage: 'New',
    id: '1+u6Hk',
    description: 'Text on a badge indicating a newly created link.',
  },
});

const StyledWatermarkLinks = styled.div`
  > :not(:last-child) {
    margin-bottom: var(--spacing__unit--1_5);
  }
`;

const LinkRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const LinkRowLeft = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

const LinkText = styled.div`
  display: flex;
  flex-direction: column;
`;

export const WatermarkHome = (props: {canCreateLinks: boolean; areAllVersionsVideos: boolean}) => {
  const {canCreateLinks, areAllVersionsVideos} = props;
  const setContents = useSetAtom(shareModalContentsAtom);
  const hasFullAddonAccess = useHasFullAddonAccess();
  const [showAddonTooltip, setShowAddonTooltip] = React.useState(false);
  const tooltipRef = React.useRef(null);

  return (
    <>
      <Modal.Body>
        {(!hasFullAddonAccess || !areAllVersionsVideos) && (
          <Banner
            style={{marginBottom: 'var(--spacing__unit--2)'}}
            type="neutral"
            withLeftIcon={<UIIcon src={InfoLine} />}
          >
            <Banner.Message>
              {!hasFullAddonAccess ? (
                <FormattedMessage {...watermarkText.disabledBanner} />
              ) : (
                <FormattedMessage {...watermarkText.unsupportedFileTypes} />
              )}
            </Banner.Message>
          </Banner>
        )}
        <Text tabIndex={0}>
          <FormattedMessage {...watermarkText.helpText} />
        </Text>
        <WatermarkAccordion />
      </Modal.Body>
      <Modal.Footer>
        <div
          onMouseEnter={() => setShowAddonTooltip(!hasFullAddonAccess)}
          onMouseLeave={() => setShowAddonTooltip(false)}
          ref={tooltipRef}
        >
          <Button
            disabled={!canCreateLinks || !hasFullAddonAccess}
            onClick={() => setContents({type: 'watermark-link-settings'})}
            type="submit"
            variant="primary"
            withIconStart={<UIIcon src={AddLine} />}
          >
            <FormattedMessage {...watermarkText.newLinkButton} />
          </Button>
        </div>
        <Tooltip.Control
          auto
          open={showAddonTooltip}
          placement="top"
          triggerRef={tooltipRef}
          variant="basic"
        >
          <FormattedMessage {...watermarkText.disabledTooltip} />
        </Tooltip.Control>
      </Modal.Footer>
    </>
  );
};

function getEmailLinkDisplayName(linkInfo: WatermarkLinkInfo) {
  return linkInfo.watermarkInfo.recipientUser.displayName || linkInfo.watermarkInfo.email;
}

function compareNames(a: string, b: string) {
  if (a > b) {
    return 1;
  } else if (a < b) {
    return -1;
  }
  return 0;
}

const WatermarkAccordion = () => {
  const watermarkLinks = useAtomValue(watermarkLinksForItemAtom);
  const hasFullAddonAccess = useHasFullAddonAccess();
  const hideDisabledLinks = !hasFullAddonAccess;
  const newShareTokens = useAtomValue(getNewlyCreatedShareTokensAtom);
  const filteredWatermarkLinks = watermarkLinks.filter((linkInfo) => {
    return hideDisabledLinks ? !linkInfo.shareInfo.expired : true;
  });

  function compareWatermarkLinks(linkA: WatermarkLinkInfo, linkB: WatermarkLinkInfo) {
    // New links first
    if (
      newShareTokens.includes(linkA.shareInfo.shareToken) &&
      !newShareTokens.includes(linkB.shareInfo.shareToken)
    ) {
      return -1;
    }
    // Generic links second
    if (!linkA.watermarkInfo.showEmail && linkB.watermarkInfo.showEmail) {
      return -1;
    }
    if (linkA.watermarkInfo.showEmail && !linkB.watermarkInfo.showEmail) {
      return 1;
    }

    // Email links third, alphabetically
    const nameA = getEmailLinkDisplayName(linkA);
    const nameB = getEmailLinkDisplayName(linkB);

    if (linkA.watermarkInfo.showEmail && linkB.watermarkInfo.showEmail) {
      return compareNames(nameA, nameB);
    }
    return 0;
  }

  const sortedWatermarkLinks = filteredWatermarkLinks.sort(compareWatermarkLinks);
  const numLinks = sortedWatermarkLinks.length;
  const [expandedPanels, setExpandedPanels] = React.useState<string[]>(['watermark-links']);
  const handleClick = (itemId: string) => {
    setExpandedPanels((prev) => {
      if (prev.includes(itemId)) {
        return prev.filter((id) => id !== itemId);
      } else {
        return prev.concat([itemId]);
      }
    });
  };

  return (
    <Accordion expandedPanels={expandedPanels} variant="standard">
      <Accordion.Item itemId="watermark-links">
        <Accordion.Header
          onClick={() => handleClick('watermark-links')}
          style={{borderTop: '0px', padding: 'var(--spacing__unit--1_5) 0'}}
        >
          <Accordion.HeaderContent
            withTitle={
              <FormattedMessage
                defaultMessage="Links ({numLinks})"
                description="Title for a modal section displaying the watermark share links for a file."
                id="RMvQps"
                values={{numLinks}}
              />
            }
          />
        </Accordion.Header>
        <Accordion.Panel style={{padding: '0', maxHeight: '250px', overflow: 'auto'}}>
          <StyledWatermarkLinks>
            {numLinks ? (
              sortedWatermarkLinks.map((link) => (
                <WatermarkLinkRow
                  isNew={newShareTokens.includes(link.shareInfo.shareToken)}
                  key={link.shareInfo.shareToken}
                  linkInfo={link}
                />
              ))
            ) : (
              <Text color="faint">
                <FormattedMessage {...watermarkText.noLinks} />
              </Text>
            )}
          </StyledWatermarkLinks>
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};

const WatermarkLinkRow = (props: {linkInfo: WatermarkLinkInfo; isNew: boolean}) => {
  const intl = useIntl();
  const shareModalText = useShareModalStrings('');
  const {copyLinkToClipboard} = useCopyBasicLinkToClipboard();
  const hasFullAddonAccess = useHasFullAddonAccess();
  const setCurrentWatermarkLink = useSetAtom(currentWatermarkLinkAtom);
  const setBasicLinkInfo = useSetAtom(basicShareLinksForItemAtom);
  const setWatermarkLinkInfo = useSetAtom(watermarkLinksForItemAtom);
  const setContents = useSetAtom(shareModalContentsAtom);
  const {linkInfo, isNew} = props;
  const {email, recipientUser} = linkInfo.watermarkInfo;
  const isEmailLink = !!email;
  const shareItem = useAtomValue(getShareItemAtom);
  const loggingInfo = useAtomValue(getShareModalLoggingInfoAtom);
  const name = shareItem?.name ?? '';
  const displayName = getEmailLinkDisplayName(linkInfo) || name || '';
  const copyTooltipRef = React.useRef(null);
  const [showCopyTooltip, setShowCopyTooltip] = React.useState<boolean>(false);
  const settingsTooltipRef = React.useRef(null);
  const [showSettingsTooltip, setShowSettingsTooltip] = React.useState<boolean>(false);
  const [showDisableError, setShowDisableError] = React.useState<boolean>(false);

  const {disableLink, getExistingLinks} = useLinkActions({
    logEvent: loggingInfo.logEvent,
    linkItemId: shareItem?.id ?? '',
    linkItemType: shareItem?.type ?? 'file',
    versionSummaries: shareItem?.type === 'file' ? shareItem?.versionSummaries : [],
    location: loggingInfo.location,
  });

  const handleSettingsClick = () => {
    setCurrentWatermarkLink(linkInfo);
    setContents({type: 'watermark-link-settings'});
  };

  const handleDisableClick = async () => {
    const result = await disableLink(linkInfo.shareInfo.shareToken, false, false, true);
    if (!result) {
      setShowDisableError(true);
    } else {
      const refreshedLinks = await getExistingLinks();
      setBasicLinkInfo(refreshedLinks.basicLinks);
      setWatermarkLinkInfo(refreshedLinks.watermarkLinks);
    }
  };

  const emailInitials = email.length ? email.toUpperCase()[0] : '';

  return (
    <LinkRow>
      <LinkRowLeft>
        <ContactAvatar
          {...recipientUser}
          displayName={displayName}
          initials={recipientUser.initials.length ? recipientUser.initials : emailInitials}
        />
        <LinkText>
          <div style={{display: 'flex'}}>
            <Text>{displayName}</Text>
            {isNew && (
              <Badge
                style={{marginLeft: 'var(--spacing__unit--1)', alignSelf: 'center'}}
                variant="attention"
              >
                <FormattedMessage {...watermarkText.newLabel} />
              </Badge>
            )}
          </div>
          <Text color="faint">
            {isEmailLink ? (
              <FormattedMessage {...watermarkText.individualLinkSubtext} />
            ) : (
              <FormattedMessage {...watermarkText.standardLinkSubtext} />
            )}
          </Text>
        </LinkText>
      </LinkRowLeft>

      <div>
        <div style={{display: 'flex'}}>
          <Tooltip.Control
            open={showCopyTooltip}
            placement="top"
            triggerRef={copyTooltipRef}
            variant="basic"
          >
            {linkInfo.shareInfo.expired
              ? shareModalText.linkDisabledTooltipText
              : intl.formatMessage(watermarkText.copyLinkTooltip)}
          </Tooltip.Control>
          <div
            onMouseEnter={() => setShowCopyTooltip(true)}
            onMouseLeave={() => setShowCopyTooltip(false)}
            style={{display: 'inline', marginLeft: 'auto'}}
          >
            <IconButton
              aria-label={intl.formatMessage(watermarkText.copyLinkTooltip)}
              disabled={linkInfo.shareInfo.expired}
              onClick={() => copyLinkToClipboard(linkInfo.shareInfo.link)}
              ref={copyTooltipRef}
              variant="borderless"
            >
              <UIIcon src={LinkLine} />
            </IconButton>
          </div>
          <Tooltip.Control
            open={showSettingsTooltip}
            placement="top"
            triggerRef={settingsTooltipRef}
            variant="basic"
          >
            {hasFullAddonAccess
              ? intl.formatMessage(watermarkText.settingsButton)
              : intl.formatMessage(watermarkText.disableButton)}
          </Tooltip.Control>
          <div
            onMouseEnter={() => setShowSettingsTooltip(true)}
            onMouseLeave={() => setShowSettingsTooltip(false)}
          >
            <IconButton
              aria-label={
                hasFullAddonAccess
                  ? intl.formatMessage(watermarkText.settingsButton)
                  : intl.formatMessage(watermarkText.disableButton)
              }
              onClick={
                hasFullAddonAccess ? () => handleSettingsClick() : () => handleDisableClick()
              }
              ref={settingsTooltipRef}
              variant="borderless"
            >
              {hasFullAddonAccess ? <UIIcon src={SettingsLine} /> : <UIIcon src={FailLine} />}
            </IconButton>
          </div>
        </div>
        {showDisableError && (
          <Text color="error">{intl.formatMessage(watermarkText.disableError)}</Text>
        )}
      </div>
    </LinkRow>
  );
};

const ContactAvatar = (props: {displayName: string; accountPhotoUrl: string; initials: string}) => {
  return (
    <Avatar
      alt=""
      colorPalette={avatarColorPalette(props.displayName)}
      hasNoOutline
      src={props.accountPhotoUrl}
      style={{marginRight: 'var(--spacing__unit--2)', alignSelf: 'center'}}
    >
      {props.initials.length ? props.initials : <UIIcon src={GlobeLine} />}
    </Avatar>
  );
};
