import {useEffect} from 'react';

import {PAP_Select_ReplayCta} from 'pap-events/replay/select_replay_cta';
import {PAP_Shown_ReplayCta} from 'pap-events/replay/shown_replay_cta';
import {defineMessages, useIntl} from 'react-intl';

import {Button} from '~/components/button';
import {useReelAppGlobalState} from '~/context';
import {DROPBOX_DECIDE_URL} from '~/lib/provisions';
import {useLoggingClient} from '~/lib/use_logging_client';
import {useAreSuperAdminFeaturesEnabled} from '~/lib/utils';

import {Banner} from './banner';

const addDays = (date: Date, days: number) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

const LOCKED_STATE_HELP_URL = 'https://help.dropbox.com/account-access/team-locked-state';

type LockedBannerProps = {
  fullWidth?: boolean;
  // Below properties are only used for testing purposes
  testSuspendedDate?: Date;
};

export const LockedBanner = ({fullWidth, testSuspendedDate}: LockedBannerProps) => {
  const intl = useIntl();
  const sessionContext = useReelAppGlobalState();
  const isAdmin = useAreSuperAdminFeaturesEnabled();
  const loggingClient = useLoggingClient();
  const teamLastSuspended =
    testSuspendedDate ??
    (sessionContext.status === 'logged in' && sessionContext.currentAccount.team_last_suspended);
  const isTeamNearDelete = teamLastSuspended && new Date() > addDays(teamLastSuspended, 60);
  const replayCtaType = isTeamNearDelete ? 'locked_state_delete_warning' : 'locked_state';

  useEffect(() => {
    if (teamLastSuspended) {
      loggingClient.logPap(
        PAP_Shown_ReplayCta({
          ctaLocation: 'banner',
          replayCtaType,
        }),
      );
    }
  }, [loggingClient, teamLastSuspended, replayCtaType]);

  // Invariant, but just in case
  if (!teamLastSuspended) return;

  const teamDeleteDate = addDays(teamLastSuspended, 90);

  return (
    <Banner $fullWidth={fullWidth} type="alert" withLeftIcon={true}>
      <Banner.Message>
        {isTeamNearDelete
          ? intl.formatMessage(isAdmin ? strings.adminNearDelete : strings.userNearDelete, {
              date: intl.formatDate(teamDeleteDate, {
                month: 'long',
                day: 'numeric',
                year: 'numeric',
              }),
            })
          : intl.formatMessage(isAdmin ? strings.adminWarning : strings.userWarning)}
      </Banner.Message>
      <Banner.Actions>
        <Button
          href={isAdmin ? DROPBOX_DECIDE_URL : LOCKED_STATE_HELP_URL}
          papOnClick={PAP_Select_ReplayCta({
            ctaLocation: 'banner',
            replayCtaType,
          })}
          variant="transparent"
        >
          {intl.formatMessage(isAdmin ? strings.adminCtaLink : strings.userCtaLink)}
        </Button>
      </Banner.Actions>
    </Banner>
  );
};

const strings = defineMessages({
  adminWarning: {
    defaultMessage:
      'Your team’s plan wasn’t renewed, so you can only view and download files. Enter new billing info to pick up where you left off.',
    description: "Message in banner informing user their team's plan wasn't renewed",
    id: 'XJ1Jtt',
  },
  adminNearDelete: {
    defaultMessage:
      'Your team is in locked state. If still in locked state after {date}, it’ll be deleted permanently.',
    description: "Message in banner informing user their team's plan is near deletion",
    id: 'sEHoJK',
  },
  adminCtaLink: {
    defaultMessage: 'Review options',
    description: 'Text for button prompting user to review their team plan options',
    id: '1ED9DB',
  },
  userWarning: {
    defaultMessage:
      'Your team’s plan wasn’t renewed, so you can only view and download files. Team admins can renew your team’s plan so you can keep Replay.',
    description: "Message in banner informing user their team's plan wasn't renewed",
    id: 'hm446o',
  },
  userNearDelete: {
    defaultMessage:
      'Your team is in locked state. If still in locked state after {date}, it’ll be deleted permanently. Download any files you don’t want to lose.',
    description: "Message in banner informing user their team's plan is near deletion",
    id: 'LgG500',
  },
  userCtaLink: {
    defaultMessage: 'Learn more',
    description: 'Text for button prompting user to learn more about their team plan',
    id: 'J2MMLj',
  },
});
