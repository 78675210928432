import {useIntl} from 'react-intl';

export const usePricingStrings = (price: string) => {
  const intl = useIntl();
  return {
    individualMonthly: intl.formatMessage(
      {
        defaultMessage: '{price}/month',
        id: 'Q/c6x3',
        description: 'Price per month for individual SKUs on a monthly billing cycle.',
      },
      {price: price},
    ),
    individualYearly: intl.formatMessage(
      {
        defaultMessage: '{price}/year',
        id: 'eAaYOt',
        description: 'Price per year for individual SKUs on a yearly billing cycle.',
      },
      {price: price},
    ),
    teamMonthly: intl.formatMessage(
      {
        defaultMessage: '{price}/month/member',
        id: '8a6oYj',
        description: 'Price per month for team SKUs on a monthly billing cycle.',
      },
      {price: price},
    ),
    teamYearly: intl.formatMessage(
      {
        defaultMessage: '{price}/year/member',
        id: 'hmdGId',
        description: 'Price per year for team SKUs on a yearly billing cycle.',
      },
      {price: price},
    ),
  };
};
