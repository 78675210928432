import type {ComponentPropsWithoutRef} from 'react';
import React from 'react';

import {PAP_Select_ReplayCta} from 'pap-events/replay/select_replay_cta';
import {PAP_Select_TopNavAction} from 'pap-events/replay/select_top_nav_action';
import {PAP_Shown_ReplayCta} from 'pap-events/replay/shown_replay_cta';
import {defineMessages, FormattedMessage, useIntl} from 'react-intl';
import {useHref} from 'react-router';
import {type Link, useLinkClickHandler} from 'react-router-dom';
import styled, {css} from 'styled-components';

import {useBreakpoint} from '@dropbox/dig-components/hooks';
import type {WrapperOnToggle} from '@dropbox/dig-components/menu';
import {Text} from '@dropbox/dig-components/typography';
import {UIIcon} from '@dropbox/dig-icons';
import {ListViewLine, LockLine} from '@dropbox/dig-icons/assets';
import {GlyphLogo, ReplayGlyph, ReplayWordmark} from '@dropbox/dig-logos';
import {GlobalHeader} from '@dropbox/dpc-ia/global_header';

import {HeaderAccountMenu} from '~/components/account_menu/account_menu';
import {BasicAddOnUpsellModal} from '~/components/add_on_upsell_modal';
import {appStrings} from '~/components/app_strings';
import {Button, IconButton} from '~/components/button';
import {useExtensionContext} from '~/components/extension_context';
import {OnboardingChecklist} from '~/components/onboarding_v2/onboarding_checklist';
import {breakpointSmall, color, spacing} from '~/components/styled';
import {useViewport} from '~/components/viewport_context';
import {useReelAppGlobalState} from '~/context';
import {REPLAY_BASE_URL} from '~/context_utils';
import {useGetReplayEligibilityQuery} from '~/lib/api_queries';
import {getCurrentUser, getDefaultUserClient} from '~/lib/client';
import {SurfaceType} from '~/lib/logging/logger_types';
import {DROPBOX_ADMIN_CONSOLE_MEMBERS_PAGE, SINGLE_PURCHASE_URL} from '~/lib/provisions';
import {useGetUserVariant} from '~/lib/provisions';
import {clearPurchaseStorageItems} from '~/lib/storage_utils';
import {StormcrowIsOn} from '~/lib/stormcrow';
import {useLoggingClient} from '~/lib/use_logging_client';
import {useWaffleItems} from '~/lib/use_waffle_items';
import {
  useAreSuperAdminFeaturesEnabled,
  useHasFullAddonAccess,
  useReelProvisioningEnabled,
} from '~/lib/utils';
import {SkipToContent} from '~/pages/layout/skip_to_content';
import {useTeamInfo} from '~/state/team';
import {isInWebKit, isInWebView, SendMessageType} from '~/use_extensions';

import {AdminHasMovedTooltip} from './admin_has_moved_tooltip';
import {ChangelogButton} from './changelog_button';
import {HelpMenu} from './help_menu';
import {PremiumTooltip} from './premium_tooltip';
import {Search} from './search';
import {SettingsHasMovedTooltip} from './settings_has_moved_tooltip';
import {useBrowseLogEventMaker} from '../../browse_page/browse_logging_context';
import {useFoldersSharedState} from '../../browse_page/folders_context';

type GlobalNavProps = {
  currentView: string;
};

const GlobalHeaderLocalNavLink = ({
  onClick,
  replace = false,
  state,
  target,
  to,
  ...rest
}: ComponentPropsWithoutRef<typeof Link>) => {
  const href = useHref(to);
  const handleClick = useLinkClickHandler(to, {
    replace,
    state,
    target,
  });

  return (
    <GlobalHeader.LocalNavLink
      {...rest}
      hasNoUnderline={false}
      href={href}
      isBold={true}
      onClick={(event) => {
        onClick?.(event);
        if (!event.defaultPrevented) {
          handleClick(event);
        }
      }}
      target={target}
    />
  );
};

const HeaderNavLink = styled.div<{$hasUnderline: boolean}>`
  display: inline-block;
  ${(props) =>
    props.$hasUnderline && 'border-bottom: 1px solid var(--dig-color__secondary__on-base);'}
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderContainer = styled.div<{$includeSearchBar: boolean}>`
  position: sticky;
  z-index: 15;
  top: 0;
  width: 100%;
  & a:focus {
    box-shadow: none;
  }
  .dpc-GlobalHeader {
    background: ${color('Background Raised')};
    ${({$includeSearchBar}) =>
      $includeSearchBar &&
      css`
        flex-wrap: wrap;
      `}
  }
  div.dpc-GlobalHeader-Local-Nav__hamburger {
    display: none;
  }
  div.dpc-GlobalHeader-Local-Nav__children {
    display: flex;
  }
  .dpc-GlobalHeader-Local-Nav__children .dpc-GlobalHeader-Local-Nav__link {
    margin: 0 0 0 ${spacing('Micro Large')};
  }
  .dpc-GlobalHeader-UtilityNav {
    margin-left: auto;

    ${breakpointSmall(css`
      display: flex;
      justify-content: flex-end;
      width: 100%;
      margin-left: ${spacing('Micro XSmall')};
    `)}
  }
`;

const ReplayLogomark = styled.div`
  width: 32px;
  height: 32px;
  display: none;
  @media (max-width: 800px) {
    display: flex;
  }
`;

const BuyReplayButton = styled(Button)`
  background: ${color('Success Base')};
  color: ${color('Success On Base')};
`;

export const BrowsePageHeader = (props: GlobalNavProps) => {
  const sessionContext = useReelAppGlobalState();
  const {setIsMobileNavBarOpen} = useFoldersSharedState();
  const user = getCurrentUser();
  const makeLogEvent = useBrowseLogEventMaker();
  const {isMobileDevice} = useViewport();
  const {medium, small} = useBreakpoint();
  const showUpsellModal = !isMobileDevice;
  const logEvent = React.useMemo(() => makeLogEvent(null), [makeLogEvent]);
  const intl = useIntl();
  const isProvisioningEnabled = useReelProvisioningEnabled();
  const areSuperAdminFeaturesEnabled = useAreSuperAdminFeaturesEnabled();

  const userVariant = useGetUserVariant();
  const isLoggedIn = sessionContext.status === 'logged in';
  const isLinksHubProvisioned =
    !isProvisioningEnabled || (isLoggedIn && sessionContext.provisions.links_hub);
  const triggerRef = React.useRef(null);
  const settingsRef = React.useRef(null);
  const [showLinksHubTooltip, setShowLinksHubTooltip] = React.useState<boolean>(false);
  const [upsellModalIsOpen, setUpsellModalIsOpen] = React.useState<boolean>(false);
  const [tooltipTimeout, setTooltipTimeout] = React.useState<NodeJS.Timeout>();
  const {redCarpet} = useExtensionContext();
  const {currentView} = props;
  const {data: isReplayPurchaseEligibleData} = useGetReplayEligibilityQuery(isLoggedIn);
  const teamInfo = useTeamInfo({
    enabled: user?.is_standalone_user && areSuperAdminFeaturesEnabled,
  });
  const isReplayPurchaseEligible = isReplayPurchaseEligibleData ?? false;
  let numAvailableLicenses = 0;

  if (teamInfo.data) {
    const {num_licensed_users, num_used_licenses = num_licensed_users} = teamInfo.data;
    numAvailableLicenses = num_licensed_users - num_used_licenses;
  }

  const hasFullAddonAccess = useHasFullAddonAccess();
  const loggingClient = useLoggingClient();
  const actionSurface = React.useMemo(() => {
    switch (currentView) {
      case 'share-folder':
      case 'browse-home':
        return 'browse';
      case 'browse-shared':
        return 'shared';
      case 'browse-links':
        return 'links';
      case 'settings-page':
        return 'settings';
      default:
        return '';
    }
  }, [currentView]);
  const isInappPurchasePage = currentView === 'in_app_purchase';

  const isEligibleToBuyAddon =
    (userVariant === 'self_serve_team_admin' || userVariant === 'paying_individual') &&
    isReplayPurchaseEligible;
  const isEligibleToBuyStandalone =
    userVariant === 'basic_individual' || userVariant === 'standalone_free_admin';
  const showBuyReplayButton =
    !isInappPurchasePage &&
    !isMobileDevice &&
    !hasFullAddonAccess &&
    (isEligibleToBuyAddon || isEligibleToBuyStandalone);

  React.useEffect(() => {
    if (showBuyReplayButton) {
      loggingClient.logPap(
        PAP_Shown_ReplayCta({
          replayCtaType: 'buy_replay',
          ctaLocation: 'browse_page_header',
          userVariant: userVariant,
        }),
      );
    }
  }, [logEvent, loggingClient, showBuyReplayButton, userVariant]);

  const handleLogoutClick = React.useCallback(async () => {
    // eslint-disable-next-line deprecation/deprecation
    logEvent('select_sign_out');
    if (isLoggedIn) {
      clearPurchaseStorageItems();

      // TODO(alan): Make this work for Adobe extensions too.
      if (isInWebKit()) {
        // First remove API client auth
        await getDefaultUserClient()
          .authTokenRevoke()
          .catch((err) => {
            console.debug('token already revoked');
          })
          .then(() => {
            if (redCarpet?.sendMessage) {
              return redCarpet.sendMessage(SendMessageType.UserLogout);
            } else {
              return Promise.resolve();
            }
          })
          .catch((err) => {
            console.debug('unknown error doing native logout');
          })
          .then(() => {
            window.location.href = `${REPLAY_BASE_URL}?login=true&redirectauth=false`;
          });
      } else {
        await sessionContext.logout();
      }
    }
  }, [isLoggedIn, logEvent, redCarpet, sessionContext]);

  const handleSwitchRoleClick = React.useMemo(() => {
    if (isLoggedIn && sessionContext.currentRole !== 'unpaired') {
      return async () => {
        const {currentRole} = sessionContext;
        const newRole = currentRole === 'personal' ? 'work' : 'personal';
        await sessionContext.switchRole(newRole);
      };
    }
    return undefined;
  }, [isLoggedIn, sessionContext]);

  const handleSettingsClick = () => {
    // eslint-disable-next-line deprecation/deprecation
    logEvent('select_settings');
  };

  const handleStorageClick = () => {
    // eslint-disable-next-line deprecation/deprecation
    logEvent('select_storage_setting');
  };

  const handleWaffleToggle: WrapperOnToggle = ({isOpen}: {isOpen: boolean}) => {
    if (isOpen) {
      // eslint-disable-next-line deprecation/deprecation
      logEvent('select_waffle');
      loggingClient.logPap(
        PAP_Select_TopNavAction({
          deviceId: loggingClient.deviceId,
          actionElement: 'top_nav',
          actionSurface: actionSurface,
          topNavAction: 'dropdown_menu',
          actionDetail: 'waffle_menu',
        }),
      );
    }
  };

  const handleBuyReplayClick = () => {
    loggingClient.logPap(
      PAP_Select_ReplayCta({
        replayCtaType: 'buy_replay',
        ctaLocation: 'browse_page_header',
        userVariant: userVariant,
      }),
    );
  };

  const {waffleItems, waffleGroups} = useWaffleItems({dropboxClient: sessionContext.dropboxClient});
  const isSearchBarOn = StormcrowIsOn.useReplaySearch();
  const showMobileSearchBar = !small || isMobileDevice;
  const isSharedView = currentView === 'browse-shared';

  return (
    <>
      <HeaderContainer $includeSearchBar={isSearchBarOn && showMobileSearchBar}>
        <SkipToContent />
        <GlobalHeader.Wrapper>
          {!isInWebView() && !isMobileDevice && (
            <GlobalHeader.Waffle
              groups={waffleGroups}
              items={waffleItems}
              menuTriggerAriaLabel={intl.formatMessage(strings.appMenu)}
              onSelection={({href}) => {
                // eslint-disable-next-line deprecation/deprecation
                logEvent('select_waffle_item');
                window.open(href, '_blank');
              }}
              onToggle={handleWaffleToggle}
            />
          )}
          {!medium && (
            <IconButton
              aria-label={intl.formatMessage(strings.openNavMenu)}
              onClick={() => {
                setIsMobileNavBarOpen(true);
              }}
              size="standard"
              variant="transparent"
            >
              <UIIcon size="standard" src={ListViewLine} />
            </IconButton>
          )}
          <LogoWrapper>
            <GlobalHeader.Logo
              aria-label={intl.formatMessage(strings.logoLabel)}
              href="/"
              onClick={() => {
                // eslint-disable-next-line deprecation/deprecation
                logEvent('select_replay_home');
                loggingClient.logPap(
                  PAP_Select_TopNavAction({
                    deviceId: loggingClient.deviceId,
                    actionElement: 'top_nav',
                    actionSurface: actionSurface,
                    topNavAction: 'navigate_surface',
                    actionDetail: 'replay_icon',
                  }),
                );
              }}
              src={ReplayWordmark}
            />
            <ReplayLogomark>
              <GlyphLogo size={32} src={ReplayGlyph} />
            </ReplayLogomark>
          </LogoWrapper>
          {medium && !isInappPurchasePage && (
            <GlobalHeader.LocalNavigation>
              <GlobalHeaderLocalNavLink to="/">
                <HeaderNavLink
                  $hasUnderline={currentView === 'browse-home'}
                  onClick={() => {
                    // eslint-disable-next-line deprecation/deprecation
                    logEvent('select_navigation_item', {item: 'your_work'});
                    loggingClient.logPap(
                      PAP_Select_TopNavAction({
                        deviceId: loggingClient.deviceId,
                        actionElement: 'top_nav',
                        actionSurface: actionSurface,
                        topNavAction: 'navigate_surface',
                        actionDetail: 'home',
                      }),
                    );
                  }}
                >
                  <FormattedMessage {...appStrings.homeTitle} />
                </HeaderNavLink>
              </GlobalHeaderLocalNavLink>
              {!isInWebKit() &&
                (isLinksHubProvisioned ? (
                  <GlobalHeaderLocalNavLink to="/links">
                    <HeaderNavLink
                      $hasUnderline={currentView === 'browse-links'}
                      onClick={() => {
                        // eslint-disable-next-line deprecation/deprecation
                        logEvent('select_navigation_item', {item: 'your_links'});
                        loggingClient.logPap(
                          PAP_Select_TopNavAction({
                            deviceId: loggingClient.deviceId,
                            actionElement: 'top_nav',
                            actionSurface: actionSurface,
                            topNavAction: 'navigate_surface',
                            actionDetail: 'links',
                          }),
                        );
                      }}
                    >
                      <FormattedMessage {...appStrings.linksTitle} />
                    </HeaderNavLink>
                  </GlobalHeaderLocalNavLink>
                ) : showUpsellModal ? (
                  <div
                    onClick={() => setUpsellModalIsOpen(true)}
                    style={{display: 'flex', marginLeft: '16px', color: 'white', cursor: 'pointer'}}
                  >
                    <Text color="inherit" isBold variant="paragraph">
                      <FormattedMessage {...appStrings.linksTitle} />
                    </Text>
                  </div>
                ) : (
                  <div
                    onMouseEnter={() => {
                      const timeout = setTimeout(() => setShowLinksHubTooltip(true), 200);
                      setTooltipTimeout(timeout);
                    }}
                    onMouseLeave={() => clearTimeout(tooltipTimeout)}
                    ref={triggerRef}
                    style={{display: 'flex', marginLeft: '16px', color: 'white', cursor: 'default'}}
                  >
                    <Text color="faint" isBold variant="paragraph">
                      <FormattedMessage {...appStrings.linksTitle} />
                    </Text>
                    <LockLine width={20} />
                  </div>
                ))}

              <GlobalHeaderLocalNavLink to="/shared">
                <HeaderNavLink
                  $hasUnderline={isSharedView}
                  onClick={() => {
                    // eslint-disable-next-line deprecation/deprecation
                    logEvent('select_navigation_item', {item: 'shared_with_you'});
                    loggingClient.logPap(
                      PAP_Select_TopNavAction({
                        deviceId: loggingClient.deviceId,
                        actionElement: 'top_nav',
                        actionSurface: actionSurface,
                        topNavAction: 'navigate_surface',
                        actionDetail: 'shared',
                      }),
                    );
                  }}
                >
                  <FormattedMessage {...appStrings.sharedTitle} />
                </HeaderNavLink>
              </GlobalHeaderLocalNavLink>
            </GlobalHeader.LocalNavigation>
          )}
          {medium && !isInappPurchasePage && (
            <>
              <GlobalHeader.LocalNavigation>
                <GlobalHeaderLocalNavLink to="/settings">
                  <HeaderNavLink
                    $hasUnderline={currentView === 'settings-page'}
                    onClick={() => {
                      loggingClient.logPap(
                        PAP_Select_TopNavAction({
                          deviceId: loggingClient.deviceId,
                          actionElement: 'top_nav',
                          actionSurface: actionSurface,
                          topNavAction: 'navigate_surface',
                          actionDetail: 'settings',
                        }),
                      );
                    }}
                    ref={settingsRef}
                  >
                    <FormattedMessage {...appStrings.settingsTitle} />
                  </HeaderNavLink>
                </GlobalHeaderLocalNavLink>
              </GlobalHeader.LocalNavigation>
              {areSuperAdminFeaturesEnabled ? (
                <AdminHasMovedTooltip
                  actionSurface={actionSurface}
                  placement="bottom"
                  triggerRef={settingsRef}
                />
              ) : (
                <SettingsHasMovedTooltip
                  actionSurface={actionSurface}
                  placement="bottom"
                  triggerRef={settingsRef}
                />
              )}
            </>
          )}
          <GlobalHeader.UtilityNav>
            {!isInappPurchasePage && !showMobileSearchBar && !isSharedView && <Search />}
            {!isInappPurchasePage && <OnboardingChecklist />}
            {!isInappPurchasePage && (
              <ChangelogButton actionSurface={actionSurface} logEvent={logEvent} />
            )}
            {!isInappPurchasePage && (
              <HelpMenu actionSurface={actionSurface} surfaceType={SurfaceType.Browse} />
            )}
            {user !== undefined ? (
              <HeaderAccountMenu
                actionSurface={actionSurface}
                onLogoutClick={handleLogoutClick}
                onSettingsClick={handleSettingsClick}
                onStorageClick={handleStorageClick}
                onSwitchRoleClick={handleSwitchRoleClick}
                user={user}
              />
            ) : null}
            <div>
              {showBuyReplayButton ? (
                <BuyReplayButton
                  href={`${SINGLE_PURCHASE_URL}?replay_purchase_origin=browse_page_header`}
                  onClick={handleBuyReplayClick}
                  target="_blank"
                  variant="primary"
                >
                  <FormattedMessage {...strings.buyReplay} />
                </BuyReplayButton>
              ) : user?.is_standalone_user && numAvailableLicenses > 0 ? (
                <BuyReplayButton
                  href={DROPBOX_ADMIN_CONSOLE_MEMBERS_PAGE}
                  target="_blank"
                  variant="primary"
                >
                  <FormattedMessage
                    {...strings.licensesAvailable}
                    values={{licensesAvailable: numAvailableLicenses}}
                  />
                </BuyReplayButton>
              ) : null}
            </div>
          </GlobalHeader.UtilityNav>
          {showMobileSearchBar && !isInappPurchasePage && <Search />}
        </GlobalHeader.Wrapper>
      </HeaderContainer>
      {showUpsellModal ? (
        <BasicAddOnUpsellModal
          clickSource="navigation_header"
          onClose={() => setUpsellModalIsOpen(false)}
          open={upsellModalIsOpen}
          variant="links_hub"
        />
      ) : (
        <PremiumTooltip
          onClose={() => setShowLinksHubTooltip(false)}
          open={showLinksHubTooltip}
          placement="bottom"
          triggerRef={triggerRef}
        />
      )}
    </>
  );
};

const strings = defineMessages({
  appMenu: {
    defaultMessage: 'Apps menu',
    id: 'eYphti',
    description: 'Aria label for button that opens a menu linking to all applications.',
  },
  openNavMenu: {
    defaultMessage: 'Open navigation menu',
    id: 'Mwjk1R',
    description: 'Aria label for button that opens a menu containing Replay links',
  },
  logoLabel: {
    defaultMessage: 'Replay Logo - Go to homepage',
    id: 'WOTlQg',
    description:
      'Aria label for the product logo in the page header. When clicked, it takes you back to the main homepage.',
  },
  buyReplay: {
    defaultMessage: 'Buy Replay',
    description: 'Button label to purchase the Replay add-on',
    id: 'fsMbvw',
  },
  licensesAvailable: {
    defaultMessage:
      'Assign {licensesAvailable} {licensesAvailable, plural, one{license} other{licenses}}',
    description: 'Button label to assign replay licenses',
    id: '+3yc48',
  },
});
