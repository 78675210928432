import {
  defineMessages,
  FormattedMessage,
  type IntlShape,
  type MessageDescriptor,
  useIntl,
} from 'react-intl';
import styled from 'styled-components';

import {Toggletip} from '@dropbox/dig-components/tooltips';
import {Text} from '@dropbox/dig-components/typography';
import {HelpLine} from '@dropbox/dig-icons/assets';

import {color, spacing} from '~/components/styled';
import type {
  AudioStreamMetadata,
  DocumentProp,
  MediaMetadata,
  MediaProp,
  VideoStreamMetadata,
} from '~/lib/api';
import {formatImageResolutions} from '~/lib/image_metadata';
import {getDateTimeForDisplay, getTimeForDisplay} from '~/lib/time';
import {formatBytes} from '~/lib/utils';

const FieldName = styled(Text)`
  color: ${color('Text Subtle')};

  .dig-IconButton {
    color: currentColor;
  }

  width: calc(${spacing('100')} * 2);
`;

const Field = styled.div`
  display: flex;
  min-width: 280px;
`;

export interface MetadataFieldType {
  name: MessageDescriptor;
  tooltip?: MessageDescriptor;
  val: string | number | undefined | MessageDescriptor;
}

const fieldStrings = defineMessages({
  aspectRatio: {
    defaultMessage: 'Aspect Ratio',
    id: 'SkG1Dm',
    description: 'Metadata field indicating aspect ratio of video stream in original file',
  },
  bitrate: {
    defaultMessage: 'Bitrate',
    id: 'H81IXp',
    description: 'Metadata field indicating bit rate of current stream in original file',
  },
  captureDate: {
    defaultMessage: 'Capture Date',
    id: 'XaqCCU',
    description: 'Metadata field showing when the stream was captured',
  },
  channels: {
    defaultMessage: 'Channels',
    id: 'P6PkVa',
    description:
      'Metadata field indicating how many audio channels are in the current audio stream',
  },
  channelLayout: {
    defaultMessage: 'Channel Layout',
    id: '57X/Xr',
    description: 'Metadata field indicating how the layout of channels in an audio stream',
  },
  codecLongName: {
    defaultMessage: 'Codec Long Name',
    id: 'maIfmC',
    description: 'Metadata field showing the long version of the codec used for the current stream',
  },
  codecName: {
    defaultMessage: 'Codec Name',
    id: 'S0nVXc',
    description: 'Metadata field showing the codec used for the current stream',
  },
  codecTagString: {
    defaultMessage: 'Codec Tag String',
    id: 'hAzPWD',
    description: 'Metadata field showing the codec tag used for the current stream',
  },
  colorSpace: {
    defaultMessage: 'Color Space',
    id: 'pPVAL3',
    description: 'Metadata field showing the color space used for the current file',
  },
  creationTime: {
    defaultMessage: 'Creation Time',
    id: 'KQk5CV',
    description: 'Metadata field indicating when the file was originally created',
  },
  device: {
    defaultMessage: 'Device',
    id: '3JOof9',
    description: 'Metadata field showing the device the file was captured on',
  },
  displayAspectRatio: {
    defaultMessage: 'Display Aspect Ratio',
    id: 'y7j8is',
    description: 'Metadata field indicating the aspect ratio of the current video stream',
  },
  duration: {
    defaultMessage: 'Duration',
    id: 'uvpx5C',
    description: 'Metadata field indicating duration of the current stream or file',
  },
  fps: {
    defaultMessage: 'Frames Per Second',
    id: 's5E6wL',
    description:
      'Metadata field, indicating the frames per second of the original file or video stream',
  },
  height: {
    defaultMessage: 'Height',
    id: 'nlmfQ0',
    description: 'Metadata field that shows the height of the document',
  },
  isDefault: {
    defaultMessage: 'Is Default',
    id: 'ee+uAR',
    description: 'Metadata field indicating if the current stream is the default for this file',
  },
  name: {
    defaultMessage: 'Name',
    id: 'y1I3O7',
    description: 'Metadata field that shows the name of the original file',
  },
  pages: {
    defaultMessage: 'Pages',
    id: '6GMZCJ',
    description: 'Metadata field that shows the number of pages in the document',
  },
  numberOfFiles: {
    defaultMessage: 'Number of Files',
    id: 'wta4z4',
    description: 'Metadata field that shows the number of files per this folder/project',
  },
  resolution: {
    defaultMessage: 'Resolution',
    id: 'FlbUPo',
    description: 'Metadata field that shows the resolution of the current stream or file',
  },
  rotation: {
    defaultMessage: 'Rotation',
    id: 'fAnh/r',
    description: 'Metadata field indicating if current stream was rotated or not',
  },
  sampleRate: {
    defaultMessage: 'Sample Rate',
    id: 'IC5NAO',
    description: 'Metadata field indicating sample rate of the current audio stream',
  },
  size: {
    defaultMessage: 'Size',
    id: 'ozcr2V',
    description: 'Metadata field showing the file size of the current file',
  },
  streamIndex: {
    defaultMessage: 'Stream Index',
    id: 'e3wt44',
    description: 'Metadata field indicating the index of the current stream',
  },
  width: {
    defaultMessage: 'Width',
    id: 'Y9C5hf',
    description: 'Metadata field that shows the width of the document',
  },
  totalVersions: {
    defaultMessage: 'Total versions',
    id: 'LzKi2e',
    description: 'Metadata field for the project showing how many versions',
  },
  usesReplayStorage: {
    defaultMessage: 'Uses Replay storage',
    id: 'gEpJ2M',
    description: 'Metadata field showing if the file uses replay storage',
  },
  usesReplayStorageTooltip: {
    defaultMessage: 'Files added from Dropbox to Replay don’t take additional storage.',
    id: '1b6LND',
    description: 'Tooltip explaining replay storage',
  },
  //Values
  yes: {
    defaultMessage: 'Yes',
    id: 'JtSvur',
    description: "Text indicating that the File's audio stream is the default.",
  },
  no: {
    defaultMessage: 'No',
    id: 'yKbVvi',
    description: "Text indicating that the File's audio stream is not the default.",
  },
  stereo: {
    defaultMessage: 'Stereo',
    id: 'S/BApx',
    description: "Text indicating the File's audio stream channel layout is Stereo.",
  },
  mono: {
    defaultMessage: 'Mono',
    id: 'Ap/Gsq',
    description: "Text indicating the File's audio stream channel layout is Mono.",
  },
});

const booleanValue = (value?: boolean): MessageDescriptor =>
  value ? fieldStrings.yes : fieldStrings.no;

export const makeUsesReplayStorageField = (usesReplayStorage: boolean): MetadataFieldType => ({
  name: fieldStrings.usesReplayStorage,
  tooltip: fieldStrings.usesReplayStorageTooltip,
  val: booleanValue(usesReplayStorage),
});

export const makeFolderInfoFields = (
  name: string,
  numberOfFiles: number,
  size: number,
  intl: IntlShape,
): MetadataFieldType[] => {
  return [
    {name: fieldStrings.name, val: name},
    {name: fieldStrings.numberOfFiles, val: numberOfFiles},
    {name: fieldStrings.size, val: formatBytes(size, intl)},
  ];
};

const makeFileInfoMetadataFields = (metadata: MediaMetadata, intl: IntlShape) => {
  return [
    {name: fieldStrings.captureDate, val: getDateTimeForDisplay(metadata.captureDate, intl.locale)},
    {
      name: fieldStrings.resolution,
      // Using formatImageResolutions because it is already translated, and formatting should be the same for other file type resolutions
      val: formatImageResolutions(metadata.resolutionWidth, metadata.resolutionHeight, intl) ?? '',
    },
    {name: fieldStrings.aspectRatio, val: metadata.aspectRatio},
    {name: fieldStrings.duration, val: getTimeForDisplay(metadata.durationPrecise)},

    {name: fieldStrings.device, val: metadata.device},
  ];
};

export const makeFileInfoFields = (
  mediaMetadata: MediaProp,
  intl: IntlShape,
): MetadataFieldType[] => {
  const metadata = mediaMetadata.mediaMetadata;
  return [
    {name: fieldStrings.name, val: mediaMetadata.fileName},
    makeUsesReplayStorageField(mediaMetadata.countsAgainstQuota),
    {name: fieldStrings.size, val: formatBytes(mediaMetadata.fileSizeBytes, intl)},
    ...makeFileInfoMetadataFields(metadata, intl),
    {
      name: fieldStrings.fps,
      val: mediaMetadata.mediaType == 'video' ? intl.formatNumber(metadata.frameRate) : '',
    },
  ];
};

export const makeVideoStreamFields = (
  videoStream: VideoStreamMetadata,
  intl: IntlShape,
): MetadataFieldType[] => {
  return [
    {name: fieldStrings.streamIndex, val: videoStream.streamIndex},
    {
      name: fieldStrings.isDefault,
      val: booleanValue(videoStream.isDefault),
    },
    {
      name: fieldStrings.bitrate,
      val: videoStream.bitrateBps
        ? intl.formatMessage(
            {
              defaultMessage: '{bit_rate_converted} Mbps',
              id: '6xCKl7',
              description: "Text indicating the File's bitRate in Mbps",
            },
            {
              bit_rate_converted: intl.formatNumber(videoStream.bitrateBps / 1000 / 1000, {
                minimumFractionDigits: 3,
                maximumFractionDigits: 3,
              }),
            },
          )
        : '',
    },
    {
      name: fieldStrings.codecLongName,
      val: videoStream.codecLongName,
    },
    {name: fieldStrings.codecName, val: videoStream.codecName},
    {name: fieldStrings.codecTagString, val: videoStream.codecTagString},
    {
      name: fieldStrings.colorSpace,
      val: videoStream.colorSpace,
    },
    {
      name: fieldStrings.creationTime,
      val: getDateTimeForDisplay(videoStream.creationTime, intl.locale),
    },
    {name: fieldStrings.device, val: videoStream.device},
    {name: fieldStrings.displayAspectRatio, val: videoStream.displayAspectRatio},
    {name: fieldStrings.duration, val: getTimeForDisplay(videoStream.duration)},
    {name: fieldStrings.fps, val: videoStream.framesPerSecond},
    {name: fieldStrings.resolution, val: `${videoStream.width}x${videoStream.height}`},
    {
      name: fieldStrings.rotation,
      val: videoStream.rotation != 0 ? videoStream.rotation : '',
    },
  ];
};

export const makeAudioStreamFields = (
  audioStream: AudioStreamMetadata,
  intl: IntlShape,
): MetadataFieldType[] => {
  return [
    {name: fieldStrings.streamIndex, val: audioStream.streamIndex},
    {
      name: fieldStrings.isDefault,
      val: booleanValue(audioStream.isDefault),
    },
    {
      name: fieldStrings.bitrate,
      val: audioStream.bitrateBps
        ? intl.formatMessage(
            {
              defaultMessage: '{bit_rate_converted} Kbps',
              id: 'XaO8Ph',
              description: "Text indicating the File's bitRate in Kbps",
            },
            {
              bit_rate_converted: intl.formatNumber(audioStream.bitrateBps / 1000, {
                minimumFractionDigits: 3,
                maximumFractionDigits: 3,
              }),
            },
          )
        : '',
    },
    {
      name: fieldStrings.codecLongName,
      val: audioStream.codecLongName,
    },
    {name: fieldStrings.codecName, val: audioStream.codecName},
    {name: fieldStrings.codecTagString, val: audioStream.codecTagString},
    {
      name: fieldStrings.channels,
      val: audioStream.channels,
    },
    {
      name: fieldStrings.creationTime,
      val: getDateTimeForDisplay(audioStream.creationTime, intl.locale),
    },
    {
      name: fieldStrings.channelLayout,
      val:
        audioStream.channelLayout === 'stereo'
          ? intl.formatMessage(fieldStrings.stereo)
          : audioStream.channelLayout === 'mono'
          ? intl.formatMessage(fieldStrings.mono)
          : audioStream.channelLayout,
    },
    {name: fieldStrings.duration, val: getTimeForDisplay(audioStream.duration)},
    {
      name: fieldStrings.sampleRate,
      val: audioStream.sampleRate
        ? intl.formatMessage(
            {
              defaultMessage: '{sample_rate_converted} kHz',
              id: 'iPRnMb',
              description: "Text indicating the file's sample rate in kHz",
            },
            {
              sample_rate_converted: intl.formatNumber(audioStream.sampleRate / 1000, {
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
              }),
            },
          )
        : '',
    },
  ];
};

export const getDocumentSpecificDataFields = (
  mediaMetadata: DocumentProp,
  intl: IntlShape,
): MetadataFieldType[] => {
  return [
    {name: fieldStrings.name, val: mediaMetadata.fileName},
    makeUsesReplayStorageField(mediaMetadata.countsAgainstQuota),
    {name: fieldStrings.size, val: formatBytes(mediaMetadata.fileSizeBytes, intl)},
    {name: fieldStrings.pages, val: mediaMetadata.documentMetadata.pages},
    {name: fieldStrings.height, val: mediaMetadata.documentMetadata.height},
    {name: fieldStrings.width, val: mediaMetadata.documentMetadata.width},
  ];
};

export const MetadataFields = ({fields}: {fields: MetadataFieldType[]}) => {
  const intl = useIntl();

  return (
    <>
      {Object.entries(fields).map(([name, field]) => {
        const value = typeof field.val === 'object' ? intl.formatMessage(field.val) : field.val;

        return field && field.val ? (
          <Field key={name}>
            <FieldName>
              <FormattedMessage {...field.name} />
              {field.tooltip && (
                <Toggletip
                  aria-label={intl.formatMessage(field.tooltip)}
                  iconButtonSize="small"
                  src={HelpLine}
                  title={intl.formatMessage(field.tooltip)}
                />
              )}
            </FieldName>

            <Text>{`${value}`}</Text>
          </Field>
        ) : null;
      })}
    </>
  );
};
