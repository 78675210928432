import React, {Suspense, useEffect, useState} from 'react';

import isChromatic from 'chromatic/isChromatic';

import {lazy} from '~/lib/lazy';

const Lottie = lazy(() => import(/* webpackChunkName: 'lottie-react' */ 'lottie-react'));

export enum LottieAnimationType {
  // onboarding animations
  COMMENT_ON_FILE = 'comment_on_file',
  DRAW_ON_FILE = 'draw_on_file',
  SHARE_FILE = 'share_file',
  UPLOAD_FILE = 'upload_file',
  VIEW_FILE = 'view_file',
  ONBOARDING_COMPLETED = 'onboarding_completed',

  // upload drawer animations
  UPLOAD_ARROW = 'upload_arrow',
  UPLOAD_COMPLETE = 'upload_complete',

  // status icon animations
  STATUS_NEEDS_REVIEW = 'status_needs_review',
  STATUS_IN_PROGRESS = 'status_in_progress',
  STATUS_APPROVED = 'status_approved',
  STATUS_EDITS_REQUESTED = 'status_edits_requested',

  // post-purchase modal
  TRANSCRIPTS_CAPTIONS_FEATURE = 'transcripts_captions_feature',
  PASSWORD_PROTECTED_LINKS_FEATURE = 'password_protected_links_feature',
  DIGITAL_WATERMARKING_FEATURE = 'digital_watermarking_feature',
  VERSION_COMPARISON_FEATURE = 'version_comparison_feature',
}

type LottieAnimationProps = {
  animationType: LottieAnimationType;
  autoplay?: boolean;
  initialSegment?: [number, number];
  loop?: number | boolean;
  lottieRef?: React.Ref<any>;
  style?: React.CSSProperties;
  fallback?: React.ReactNode;
  onComplete?: () => void;
};

const LottieAnimation = ({
  animationType,
  style,
  initialSegment,
  loop = true,
  autoplay,
  fallback = null,
  onComplete,
}: LottieAnimationProps) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [animationData, setAnimationData] = useState({});

  useEffect(() => {
    let mounted = true;

    (async () => {
      let animationData;

      /**
       * We can't pass in a variable to the URL otherwise an error is through about critical dependencies on the import
       * So this is the best way I could tell to dynamically import.
       * Pretty unhappy with this, but for now it works.
       */
      switch (animationType) {
        case LottieAnimationType.COMMENT_ON_FILE:
          animationData = await import(
            /* webpackChunkName: 'comment-on-file' */
            '~/components/onboarding_v2/lottie-assets/comment-on-file-lottie.json'
          );
          break;
        case LottieAnimationType.DRAW_ON_FILE:
          animationData = await import(
            /* webpackChunkName: 'draw-on-file-lottie' */
            '~/components/onboarding_v2/lottie-assets/draw-on-file-lottie.json'
          );
          break;
        case LottieAnimationType.SHARE_FILE:
          animationData = await import(
            /* webpackChunkName: 'share-file-lottie' */
            '~/components/onboarding_v2/lottie-assets/share-file-lottie.json'
          );
          break;
        case LottieAnimationType.VIEW_FILE:
          animationData = await import(
            /* webpackChunkName: 'view-file-lottie' */
            '~/components/onboarding_v2/lottie-assets/view-file-lottie.json'
          );
          break;
        case LottieAnimationType.ONBOARDING_COMPLETED:
          animationData = await import(
            /* webpackChunkName: 'onboarding-complete-lottie' */
            '~/components/onboarding_v2/lottie-assets/onboarding-complete-lottie.json'
          );
          break;
        case LottieAnimationType.UPLOAD_FILE:
          animationData = await import(
            /* webpackChunkName: 'upload-file-lottie' */
            '~/components/onboarding_v2/lottie-assets/upload-file-lottie.json'
          );
          break;
        case LottieAnimationType.UPLOAD_ARROW:
          animationData = await import(
            /* webpackChunkName: 'upload-arrow-lottie' */
            '~/components/upload_drawer/lottie-assets/upload-arrow-lottie.json'
          );
          break;
        case LottieAnimationType.UPLOAD_COMPLETE:
          animationData = await import(
            /* webpackChunkName: 'upload-complete-lottie' */
            '~/components/upload_drawer/lottie-assets/upload-complete-lottie.json'
          );
          break;
        case LottieAnimationType.STATUS_NEEDS_REVIEW:
          animationData = await import(
            /* webpackChunkName: 'status-animations-lottie' */
            '~/components/status/status_icons/lottie-assets/status-needs-review-lottie.json'
          );
          break;
        case LottieAnimationType.STATUS_IN_PROGRESS:
          animationData = await import(
            /* webpackChunkName: 'status-animations-lottie' */
            '~/components/status/status_icons/lottie-assets/status-in-progress-lottie.json'
          );
          break;
        case LottieAnimationType.STATUS_APPROVED:
          animationData = await import(
            /* webpackChunkName: 'status-animations-lottie' */
            '~/components/status/status_icons/lottie-assets/status-approved-lottie.json'
          );
          break;
        case LottieAnimationType.STATUS_EDITS_REQUESTED:
          animationData = await import(
            /* webpackChunkName: 'status-animations-lottie' */
            '~/components/status/status_icons/lottie-assets/status-edits-requested-lottie.json'
          );
          break;
        case LottieAnimationType.TRANSCRIPTS_CAPTIONS_FEATURE:
          animationData = await import(
            /* webpackChunkName: 'transcripts-captions-feature-lottie' */
            '~/components/lottie-assets/transcripts-captions-feature-lottie.json'
          );
          break;
        case LottieAnimationType.PASSWORD_PROTECTED_LINKS_FEATURE:
          animationData = await import(
            /* webpackChunkName: 'password-protected-links-feature-lottie' */
            '~/components/lottie-assets/password-protected-links-feature-lottie.json'
          );
          break;
        case LottieAnimationType.DIGITAL_WATERMARKING_FEATURE:
          animationData = await import(
            /* webpackChunkName: 'digital-watermarking-feature-lottie' */
            '~/components/lottie-assets/digital-watermarking-feature-lottie.json'
          );
          break;
        case LottieAnimationType.VERSION_COMPARISON_FEATURE:
          animationData = await import(
            /* webpackChunkName: 'version-comparison-feature-lottie' */
            '~/components/lottie-assets/version-comparison-feature-lottie.json'
          );
          break;
        default:
          break;
      }

      if (animationData && mounted) {
        // Make copy of animation data as lottie mutates the object
        // and the json import could be read-only.
        setAnimationData({...animationData});
        setIsLoaded(true);
      }
    })();

    return () => {
      mounted = false;
    };
  }, [animationType]);

  if (!isLoaded) {
    return fallback;
  }

  return (
    <Suspense fallback={fallback}>
      <Lottie
        animationData={animationData}
        autoplay={isChromatic() ? false : autoplay}
        initialSegment={initialSegment}
        loop={loop}
        onComplete={onComplete}
        style={style}
      />
    </Suspense>
  );
};

export {LottieAnimation};
