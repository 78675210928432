import styled, {css} from 'styled-components';

import type {BannerProps as DigBannerProps} from '@dropbox/dig-components/banner';
import {Banner as DigBanner} from '@dropbox/dig-components/banner';

import {ifProp} from '~/components/styled';

export type BannerProps = DigBannerProps & {
  $fullWidth?: boolean;
};

export const Banner = styled(DigBanner)<BannerProps>`
  ${ifProp(
    '$fullWidth',
    css`
      border-radius: 0;
    `,
  )}
`;
