import React from 'react';

import {PAP_Select_ModalAction} from 'pap-events/replay/select_modal_action';
import {PAP_Shown_Modal} from 'pap-events/replay/shown_modal';
import {defineMessages, FormattedMessage, type MessageDescriptor, useIntl} from 'react-intl';
import styled from 'styled-components';

import {Modal} from '@dropbox/dig-components/modal';
import {TextArea} from '@dropbox/dig-components/text_fields';
import {Text, Title} from '@dropbox/dig-components/typography';
import {Box} from '@dropbox/dig-foundations';

import {Button} from '~/components/button';
import {color, spacing} from '~/components/styled';
import {useViewport} from '~/components/viewport_context';
import {createFolder} from '~/lib/api';
import {MAX_PROJECT_LENGTH} from '~/lib/constants';
import {useLoggingClient} from '~/lib/use_logging_client';

import {ReplayTextInput} from './text_input';

type CreateFolderModalProps = {
  isProject?: boolean;
  level: number;
  onFolderCreateSuccess: (folderId: string, level: number) => void;
  open: boolean;
  parentFolderId: string;
  requestClose: () => void;
};

const ModalHeader = styled(Modal.Header)`
  padding-bottom: 0;
`;

const ModalErrorText = styled.p`
  color: ${color('Warning On Surface')};
  margin-bottom: ${spacing('20')};
`;

export const CreateFolderModal = (props: CreateFolderModalProps) => {
  const {parentFolderId, open, onFolderCreateSuccess, requestClose, level, isProject} = props;
  const [folderName, setFolderName] = React.useState<string>('');
  const [folderDescription, setFolderDescription] = React.useState<string>('');
  const [errorText, setErrorText] = React.useState<MessageDescriptor | null>(
    intlMessages.duplicateProjectNameText,
  );
  const loggingClient = useLoggingClient();
  const [waitingOnRequest, setWaitingOnRequest] = React.useState<boolean>(false);
  // eslint-disable-next-line deprecation/deprecation
  const {isSmallScreenSize} = useViewport();

  const intl = useIntl();

  React.useEffect(() => {
    setFolderName('');
    setFolderDescription('');
    setErrorText(null);

    if (open) {
      loggingClient.logPap(
        PAP_Shown_Modal({
          modal: 'create_project',
          linkItemType: isProject ? 'project' : 'folder',
        }),
      );
    }
  }, [isProject, loggingClient, open]);

  const handleClose = () => {
    loggingClient.logPap(
      PAP_Select_ModalAction({
        modal: 'create_project',
        modalAction: 'cancel',
      }),
    );

    requestClose();
  };

  const handleFolderCreate = async () => {
    setWaitingOnRequest(true);
    const name = folderName.trim();

    loggingClient.logPap(
      PAP_Select_ModalAction({
        modal: 'create_project',
        modalAction: 'create',
      }),
    );

    try {
      await createFolder(name, parentFolderId, folderDescription);
      onFolderCreateSuccess(parentFolderId, level);
      setFolderName('');
      setFolderDescription('');
      setErrorText(null);
      requestClose();
      setWaitingOnRequest(false);
    } catch (e) {
      // If the request fails we want to keep the modal
      // open for now. Eventually we should move to having
      // some better notification for users
      const tag = e.error && e.error.error && e.error.error['.tag'];
      if (tag && tag === 'duplicate_folder_name') {
        isProject
          ? setErrorText(intlMessages.duplicateProjectNameText)
          : setErrorText(intlMessages.duplicateFolderNameText);
      } else if (tag && tag === 'max_depth_exceeded') {
        setErrorText(intlMessages.maxDepthExceededFolderText);
      }
      setWaitingOnRequest(false);
      return;
    }
  };

  return (
    <Modal
      fullScreen={false}
      isCentered={isSmallScreenSize ? false : true}
      onRequestClose={handleClose}
      open={open}
      withCloseButton={intl.formatMessage(
        isProject ? intlMessages.closeCreateProjectText : intlMessages.closeCreateFolderText,
      )}
    >
      <ModalHeader>
        <Title>
          <FormattedMessage
            {...(isProject ? intlMessages.createProjectModalHeader : intlMessages.createFolderText)}
          />
        </Title>
      </ModalHeader>
      <Modal.Body>
        <Box marginBottom="4">
          <Text isBold>
            <FormattedMessage {...intlMessages.nameLabel} />
          </Text>
        </Box>
        <ReplayTextInput
          aria-label={intl.formatMessage(
            isProject
              ? intlMessages.projectNameEntryAriaText
              : intlMessages.folderNameEntryAriaText,
          )}
          maxLength={MAX_PROJECT_LENGTH}
          onChange={(e) => setFolderName(e.currentTarget.value)}
          onEnter={handleFolderCreate}
          placeholder={intl.formatMessage(
            isProject
              ? intlMessages.createProjectNamePlaceholder
              : intlMessages.folderNameDefaultText,
          )}
          value={folderName}
        />

        {errorText && (
          <ModalErrorText>
            <FormattedMessage {...errorText} />
          </ModalErrorText>
        )}

        {isProject && (
          <>
            <Box marginBottom="4" marginTop="20">
              <Text isBold>
                <FormattedMessage {...intlMessages.createProjectDescriptionLabel} />
              </Text>
            </Box>
            <TextArea
              onChange={(e) => setFolderDescription(e.currentTarget.value)}
              placeholder={intl.formatMessage(intlMessages.descriptionPlaceholder)}
              value={folderDescription}
            />
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={handleClose} size="standard" variant="opacity">
          <FormattedMessage {...intlMessages.cancelButtonText} />
        </Button>
        <Button
          disabled={!folderName.trim()}
          isLoading={waitingOnRequest}
          onClick={handleFolderCreate}
          size="standard"
          type="submit"
          variant="primary"
        >
          <FormattedMessage {...intlMessages.createButtonText} />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const intlMessages = defineMessages({
  createProjectModalHeader: {
    defaultMessage: 'Create a project',
    description: 'Modal title for creating a new project',
    id: 'TUCFX0',
  },
  nameLabel: {
    defaultMessage: 'Name',
    description: 'Label for input for project name',
    id: 'tw8/oy',
  },
  createProjectNamePlaceholder: {
    defaultMessage: 'Add a project name',
    description: 'Placeholder text denoting the input to enter a project name',
    id: 's2lLWA',
  },
  createProjectDescriptionLabel: {
    defaultMessage: 'Description (optional)',
    description: 'Label for input for project description',
    id: 'AzGDZZ',
  },
  descriptionPlaceholder: {
    defaultMessage: 'Add a few details',
    description: 'Placeholder text denoting the input to enter a project description',
    id: 'DLi+l9',
  },

  duplicateProjectNameText: {
    defaultMessage: 'Psst—you already have a project with the name, please choose another.',
    id: 'ZjN30X',
    description:
      'Message when a user tries to create a project but there is a project with the same name that already exists. To fix this a user should create the project with a different name.',
  },
  duplicateFolderNameText: {
    defaultMessage: 'Psst—you already have a folder with the name, please choose another.',
    id: 'nBNoFj',
    description:
      'Message when a user tries to create a folder but there is a folder with the same name that already exists. To fix this a user should create the project with a different name.',
  },
  maxDepthExceededFolderText: {
    defaultMessage:
      'Oops! A new folder could not be created because Replay only supports twelve levels of nested folders.',
    id: 'MIsMBd',
    description:
      'Message when a user tries to create a folder that would result in the folder structure being more than 12 levels deep. In Replay a user is not allowed to create a 13th level so we error and let the user know.',
  },
  closeCreateFolderText: {
    defaultMessage: 'Close create folder modal',
    id: 'LvRYr5',
    description:
      'Aria label for the close button in a create folder modal. Clicking this button will close the modal',
  },
  closeCreateProjectText: {
    defaultMessage: 'Close create project modal',
    id: '7gUxFh',
    description:
      'Aria label for the close button in a create project modal. Clicking this button will close the modal',
  },
  projectNameDefaultText: {
    defaultMessage: 'Project name',
    id: 'jDUxmZ',
    description:
      'Default text in the text entry area of a create project modal. Signifies the user is supposed to enter the project name in that area',
  },
  folderNameDefaultText: {
    defaultMessage: 'Folder name',
    id: 'xemKFr',
    description:
      'Default text in the text entry area of a create folder modal. The user is meant to enter the folder name in that area',
  },
  folderNameEntryAriaText: {
    defaultMessage: 'Folder name',
    id: 'RJSMde',
    description:
      'Aria label for the text entry area in a create folder modal. The user is meant to enter the folder name in that area',
  },
  projectNameEntryAriaText: {
    defaultMessage: 'Project name',
    id: 'dp8uWd',
    description:
      'Aria label for the text entry area in a create project modal. The user is meant to enter the project name in that area',
  },
  createFolderText: {
    defaultMessage: 'Create folder',
    id: 'B3LDB6',
    description: 'Heading text in modal where users are able to create a new folder',
  },
  createButtonText: {
    defaultMessage: 'Create',
    id: 'S+Qn54',
    description: 'Label on button in create modal which when clicked will create a new object',
  },
  cancelButtonText: {
    defaultMessage: 'Cancel',
    description: 'Button copy for cancel button for link modal',
    id: '8HDx9g',
  },
});
