import React from 'react';

import {useAtomValue} from 'jotai';
import {FormattedMessage, useIntl} from 'react-intl';
import styled, {css} from 'styled-components';

import type {reel} from '@dropbox/api-v2-client';
import {Badge} from '@dropbox/dig-components/badge';
import {Text} from '@dropbox/dig-components/typography';
import {UIIcon} from '@dropbox/dig-icons';
import {AddLine} from '@dropbox/dig-icons/assets';

import {Button} from '~/components/button';
import {TileDropArea, useMoveItemDnd} from '~/components/drop_area';
import {ProjectLogo} from '~/components/project_logo';
import {SkeletonRectangle} from '~/components/skeleton';
import {breakpointSmall, spacing} from '~/components/styled';
import type {BrowseUploadedFolderProps} from '~/pages/browse_page/components/common';
import {TeamFacepile} from '~/pages/browse_page/components/teams_facepile';
import {
  Tile,
  TileFooter,
  TileHeader,
  TileLink,
  TileTitle,
} from '~/pages/browse_page/components/tiles/tile';
import {useFoldersSharedState} from '~/pages/browse_page/folders_context';
import {folderSelectionStateAtom} from '~/state/browse';

import {FolderTileContextMenu} from './tile_context_menu';
import {useBrowseLogEventMaker} from '../../browse_logging_context';

const FolderInfo = styled.div`
  display: flex;
  gap: ${spacing('Micro Small')};
  align-items: center;
  padding-top: ${spacing('Micro Small')};
  padding-bottom: ${spacing('Micro Small')};
`;

const FacepileWrapper = styled.div`
  display: none;

  ${breakpointSmall(css`
    display: block;
  `)}
`;

const Footer = styled(TileFooter)`
  // Shrinking padding to make room for the facepile
  padding-top: ${spacing('Micro XSmall')};
  padding-bottom: ${spacing('Micro XSmall')};
`;

const TileLogo = styled(ProjectLogo)`
  flex-basis: fit-content;
  display: none;

  ${breakpointSmall(css`
    display: block;
  `)}
`;

export const TeamProjectTile = (props: BrowseUploadedFolderProps) => {
  const {
    reelLink,
    name,
    branding,
    folderId,
    numProjects,
    shareRecipients,
    settingsChangeCallback,
    accessLevel,
    openAddPeopleModal,
    allowSuperAdminRights,
    isSharedVideoView,
  } = props;

  const {folderTree, setChildTree, currentProjectAmplitudeId} = useFoldersSharedState();

  const makeLogEvent = useBrowseLogEventMaker();
  const logEvent = React.useMemo(
    () =>
      makeLogEvent({
        projectId: currentProjectAmplitudeId,
        reelObjectType: 'project',
      }),
    [makeLogEvent, currentProjectAmplitudeId],
  );
  const {selected, selectable} = useAtomValue(folderSelectionStateAtom)(folderId);
  const [{canDrop, onHover}, drop] = useMoveItemDnd(props);

  React.useEffect(() => {
    const getFolderChildren = (tree: reel.FolderTreeNode[], lookupId: string): void => {
      tree.forEach((node) => {
        if (node.folder && node.folder.id === lookupId) {
          node.children ? setChildTree(node.children) : setChildTree(null);
          return;
        }
        if (node.children && node.children.length > 0) {
          return getFolderChildren(node.children, lookupId);
        }
      });
    };
    if (folderTree) {
      getFolderChildren(folderTree, folderId);
    }
  }, [folderId, folderTree, setChildTree]);

  const intl = useIntl();

  const addButtonString = intl.formatMessage({
    defaultMessage: 'Add people',
    id: 'X985ld',
    description:
      'This text is placed on a button that allows users to be added to a project when clicked',
  });
  const adminLabel = intl.formatMessage({
    defaultMessage: 'Admin',
    id: 'O2taDY',
    description:
      'This text is rendered on a project folder tile and indicates that the user is an admin for the project',
  });
  const viewLabel = intl.formatMessage({
    defaultMessage: 'View only',
    id: 'HD1teZ',
    description:
      'This text is rendered on a project folder tile and indicates that the user may only view the project',
  });
  const dndHoverText = intl.formatMessage({
    defaultMessage: 'Move to project',
    id: 'mW30y8',
    description:
      'Text that informs user that the hovered file or folder can be moved to a project.',
  });

  const accessLevelTag =
    accessLevel === 'admin' ? adminLabel : accessLevel === 'reviewer' ? viewLabel : null;

  const handleOpenAddPeopleModal = (event: React.MouseEvent) => {
    event.preventDefault();

    openAddPeopleModal?.(folderId, name);
  };

  return (
    <Tile
      aria-busy={shareRecipients === 'loading'}
      data-test-id="team-project-tile"
      dndHoverText={dndHoverText}
      isDndDroppable={canDrop}
      isDndHovered={onHover}
      isSelected={selected}
      ref={drop}
      role="gridcell"
    >
      <TileDropArea accessLevel={accessLevel} folderId={folderId} isEnabled={!isSharedVideoView}>
        <TileLink
          draggable={false}
          onClick={() =>
            // eslint-disable-next-line deprecation/deprecation
            logEvent('select_reel_object')
          }
          to={reelLink}
        >
          <FolderTileContextMenu
            {...props}
            isSelectable={selectable}
            isSelected={selected}
            isTeamProject={true}
          />
          <TileHeader theme={branding?.theme}>
            <TileLogo branding={branding} nullOnEmpty={true} size="large" />
            <TileTitle>{name}</TileTitle>
          </TileHeader>
          <Footer>
            <FolderInfo>
              {accessLevelTag ? (
                <Badge size="xsmall" variant="standard">
                  {accessLevelTag}
                </Badge>
              ) : null}
              {numProjects !== undefined ? (
                <Text size="small" tagName="span">
                  <FormattedMessage
                    defaultMessage="{numProjects} {numProjects, plural, one {file} other {files}}"
                    description="Displays how many files are contained within a folder or project"
                    id="Y9Oos1"
                    values={{numProjects}}
                  />
                </Text>
              ) : (
                <SkeletonRectangle $heightValue="20px" $widthValue="70px" />
              )}
            </FolderInfo>
            {shareRecipients && shareRecipients.length > 0 ? (
              <FacepileWrapper
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                {shareRecipients === 'loading' ? (
                  <SkeletonRectangle $heightValue="20px" $widthValue="100px" />
                ) : shareRecipients.length > 1 ? (
                  <TeamFacepile
                    accessLevel={accessLevel}
                    actionElement="content-tile"
                    allowSuperAdminRights={allowSuperAdminRights}
                    maxAvatars={2}
                    openAddPeopleModal={openAddPeopleModal}
                    settingsChangeCallback={settingsChangeCallback}
                    shareRecipients={shareRecipients}
                    teamFolderId={folderId}
                    teamFolderName={name}
                  />
                ) : (
                  <Button
                    onClick={handleOpenAddPeopleModal}
                    size="medium"
                    variant="transparent"
                    withIconStart={<UIIcon height={16} role="presentation" src={AddLine} />}
                  >
                    {addButtonString}
                  </Button>
                )}
              </FacepileWrapper>
            ) : (
              <></>
            )}
          </Footer>
        </TileLink>
      </TileDropArea>
    </Tile>
  );
};
