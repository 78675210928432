import React from 'react';

import {PAP_Select_ReplayCta} from 'pap-events/replay/select_replay_cta';
import {PAP_Shown_ReplayUpsellBanner} from 'pap-events/replay/shown_replay_upsell_banner';
import {useIntl} from 'react-intl';

import {Text, Title} from '@dropbox/dig-components/typography';
import {Box} from '@dropbox/dig-foundations';

import {Button} from '~/components/button';
import {CircleAnimation} from '~/components/circle_animation';
import {useViewport} from '~/components/viewport_context';
import {useReelAppGlobalState} from '~/context';
import {useGetBillingCycleQuery, useGetReplayEligibilityQuery} from '~/lib/api_queries';
import {growthbook} from '~/lib/growthbook';
import {
  SINGLE_PURCHASE_URL,
  useExceededFileQuota,
  useGetProvisionCta,
  useGetRemainingFileQuota,
  useGetUserVariant,
} from '~/lib/provisions';
import {usePricingStrings} from '~/lib/subscriptions';
import {useLoggingClient} from '~/lib/use_logging_client';
import {useIsPartialProvisioningSelfServeEnabled} from '~/lib/utils';
import {useBrowseLogEventMaker} from '~/pages/browse_page/browse_logging_context';

import {Banner, type BannerProps} from './banner';

interface QuotaBannerProps {
  onRequestClose: () => void;
  fullWidth?: boolean;
}

export const FileQuotaBanner = ({onRequestClose, fullWidth}: QuotaBannerProps) => {
  const intl = useIntl();
  const sessionContext = useReelAppGlobalState();
  const userVariant = useGetUserVariant();
  const provisionCta = useGetProvisionCta(userVariant);
  const ctaLink = `${SINGLE_PURCHASE_URL}?replay_purchase_origin=file_quota_banner`;
  const remainingFileQuota = useGetRemainingFileQuota();
  const exceededFileQuota = useExceededFileQuota();
  const makeLogEvent = useBrowseLogEventMaker();
  const logEvent = React.useMemo(() => makeLogEvent({reelObjectType: 'folder'}), [makeLogEvent]);
  const partialProvisioningSelfServeEnabled = useIsPartialProvisioningSelfServeEnabled();
  const isLoggedIn = sessionContext.status === 'logged in';
  const {data: isEligibleData, isLoading: isLoadingReplayEligible} =
    useGetReplayEligibilityQuery(isLoggedIn);
  const isEligible = isEligibleData ?? true;
  // If true, this user is specifically an admin who must purchase the Replay
  // add-on to buy Replay, but who is excluded from making this purchase for
  // some reason
  const isAdminExcludedFromBuyingAddOn =
    (userVariant === 'self_serve_team_admin' || userVariant !== 'paying_individual') && !isEligible;
  // We show some form of CTA for all other users
  const showBuyReplayCta = !isAdminExcludedFromBuyingAddOn;
  const canUserBuyReplay =
    userVariant === 'basic_individual' ||
    userVariant === 'self_serve_team_admin' ||
    userVariant === 'paying_individual' ||
    userVariant === 'standalone_free_admin';
  const {data: billingInfo, isLoading: isLoadingBillingInfo} = useGetBillingCycleQuery(isLoggedIn);
  const billingCycle = billingInfo?.billingCycle ?? 'unknown';
  const billingPrice = billingInfo?.billingPrice ?? '';
  const isBillingUnknown = billingCycle === 'unknown' || billingPrice === '';
  const {individualMonthly, individualYearly, teamMonthly, teamYearly} =
    usePricingStrings(billingPrice);
  const {isMobileDevice} = useViewport();
  const loggingClient = useLoggingClient();
  const showNewUpsellBannerStyle = canUserBuyReplay && showBuyReplayCta && exceededFileQuota;

  const {value: mpeReplayCheckoutValue} = growthbook.evalFeature(
    'mp_enablement_2024_05_21_replay_checkout_v1',
  );
  const mpeReplayCheckoutIsEnabled =
    mpeReplayCheckoutValue === 'ON' || mpeReplayCheckoutValue === 'TRUE';

  React.useEffect(() => {
    if (!isLoadingReplayEligible && !isLoadingBillingInfo) {
      if (showNewUpsellBannerStyle) {
        // eslint-disable-next-line deprecation/deprecation
        logEvent('shown.alert_banner', {
          message_type: 'over_file_limit',
          cta_location: 'file_quota_experiment_banner',
        });

        loggingClient.logPap(
          PAP_Shown_ReplayUpsellBanner({
            messageType: 'over_file_limit',
            ctaLocation: 'file_quota_experiment_banner',
            replayCtaType: 'buy_replay',
            userVariant: userVariant,
          }),
        );
      } else {
        // eslint-disable-next-line deprecation/deprecation
        logEvent('shown.alert_banner', {
          message_type: exceededFileQuota ? 'over_file_limit' : 'file_limit_info',
          cta_location: 'file_quota_banner',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingBillingInfo, isLoadingReplayEligible, showNewUpsellBannerStyle]);

  const fileQuotaBannerText = React.useMemo(() => {
    if (sessionContext.status === 'logged in') {
      const fileCountLimit = sessionContext.provisions.file_count_limit;

      if (!exceededFileQuota) {
        switch (userVariant) {
          case 'basic_individual':
          case 'paying_individual':
            if (mpeReplayCheckoutIsEnabled) {
              return intl.formatMessage(
                {
                  defaultMessage:
                    'Your upload limit is {availableQuota} files. To upload more files and access premium features, buy Dropbox Replay.',
                  id: 'Jcl7Pe',
                  description: 'Message in banner informing users about their file quota limit',
                },
                {availableQuota: fileCountLimit.available},
              );
            }
            return userVariant === 'paying_individual'
              ? intl.formatMessage(
                  {
                    defaultMessage:
                      'Your upload limit is {availableQuota} files. To upload more files and access premium features, buy Dropbox Replay.',
                    id: 'Jcl7Pe',
                    description: 'Message in banner informing users about their file quota limit',
                  },
                  {availableQuota: fileCountLimit.available},
                )
              : intl.formatMessage(
                  {
                    defaultMessage:
                      'Your upload limit is {availableQuota} files. To upload more files and access premium features, upgrade to Dropbox Plus and purchase the Replay Add-On.',
                    id: '+He94h',
                    description: 'Message in banner informing users about their file quota limit',
                  },
                  {availableQuota: fileCountLimit.available},
                );
          case 'family_user':
            if (intl.locale.startsWith('en-'))
              return intl.formatMessage(
                {
                  defaultMessage:
                    'To access premium features, use a different Dropbox account to buy Dropbox Replay. Replay isnʼt available with a family plan.',
                  id: 'ezMFaw',
                  description: 'Message in banner informing users about their file quota limit',
                },
                {availableQuota: fileCountLimit.available},
              );
            return intl.formatMessage(
              {
                defaultMessage:
                  'Your upload limit is {availableQuota} files. To upload more files and access premium features, purchase the Replay Add-On.',
                id: 'uyQbwX',
                description: 'Message in banner informing users about their file quota limit',
              },
              {availableQuota: fileCountLimit.available},
            );
          case 'managed_team_member':
          case 'self_serve_team_member':
          case 'managed_team_admin':
          case 'standalone_free_member':
          case 'standalone_paid_member':
            if (intl.locale.startsWith('en-'))
              return intl.formatMessage(
                {
                  defaultMessage:
                    'Your upload limit is {availableQuota} files. To upload more files and access premium features, request Dropbox Replay from your admin.',
                  id: 'FeZUYw',
                  description: 'Message in banner informing users about their file quota limit',
                },
                {availableQuota: fileCountLimit.available},
              );
            return intl.formatMessage(
              {
                defaultMessage:
                  'Your upload limit is {availableQuota} files. To upload more files and access premium features, contact your account manager to get the Dropbox Replay Add-On.',
                id: 'rf9Dx5',
                description: 'Message in banner informing users about their file quota limit',
              },
              {availableQuota: fileCountLimit.available},
            );
          case 'standalone_free_admin':
            return intl.formatMessage(
              {
                defaultMessage:
                  'Your team members have a {availableQuota}-file upload limit. To upload more files and access premium features, buy Dropbox Replay.',
                id: 'UyEVsQ',
                description:
                  'Message in banner informing free team admin about the file upload limit of their users, paired with CTA to upgrade their team',
              },
              {availableQuota: fileCountLimit.available},
            );
        }
      } else if (remainingFileQuota === 0) {
        switch (userVariant) {
          case 'basic_individual':
          case 'paying_individual':
            if (mpeReplayCheckoutIsEnabled) {
              return intl.formatMessage({
                defaultMessage:
                  'You’ve reached your file upload limit. To upload more files, buy Dropbox Replay.',
                id: 'Z++7ue',
                description:
                  'Message in banner informing users they have exceeded their file quota limit',
              });
            }
            return userVariant === 'basic_individual'
              ? intl.formatMessage({
                  defaultMessage:
                    'You’ve reached your file upload limit. To upload more files, upgrade to Dropbox Plus and purchase the Replay Add-On.',
                  id: 'fxxHgG',
                  description:
                    'Message in banner informing users they have exceeded their file quota limit',
                })
              : intl.formatMessage({
                  defaultMessage:
                    'You’ve reached your file upload limit. To upload more files, purchase the Replay Add-On.',
                  id: '8trKW7',
                  description:
                    'Message in banner informing users they have exceeded their file quota limit',
                });
          case 'family_user':
            if (intl.locale.startsWith('en-'))
              return intl.formatMessage({
                defaultMessage:
                  'To increase your file upload limit, use a different Dropbox account to buy Dropbox Replay. Replay isnʼt available with a family plan.',
                id: 'OBBk0/',
                description:
                  'Message in banner informing users they have exceeded their file quota limit',
              });
            return intl.formatMessage({
              defaultMessage:
                'You’ve reached your file upload limit. To upload more files, purchase the Replay Add-On.',
              id: '8trKW7',
              description:
                'Message in banner informing users they have exceeded their file quota limit',
            });
          case 'managed_team_member':
          case 'self_serve_team_member':
          case 'managed_team_admin':
          case 'standalone_free_member':
          case 'standalone_paid_member':
            if (intl.locale.startsWith('en-'))
              return intl.formatMessage({
                defaultMessage:
                  'You’ve reached your file upload limit. To upload more files, request Dropbox Replay from your admin.',
                id: 'CAttWd',
                description:
                  'Message in banner informing users they have exceeded their file quota limit',
              });
            return intl.formatMessage({
              defaultMessage:
                'You’ve reached your file upload limit. To upload more files, contact your account manager to get the Dropbox Replay Add-On',
              id: 'GWGceG',
              description:
                'Message in banner informing users they have exceeded their file quota limit',
            });
          case 'standalone_free_admin':
            return intl.formatMessage({
              defaultMessage:
                'Some team members reached their upload limit. To upload more files, buy Dropbox Replay.',
              id: 'pq7V5z',
              description:
                'Message in banner informing free team admin about the file upload limit of their users, paired with CTA to upgrade their team',
            });
        }
      } else {
        switch (userVariant) {
          case 'basic_individual':
          case 'paying_individual':
            if (mpeReplayCheckoutIsEnabled) {
              return intl.formatMessage(
                {
                  defaultMessage:
                    'You’re {count, plural, one {# file} other {# files}} over your upload limit. To upload more files, buy Dropbox Replay.',
                  id: 'tgHlyy',
                  description:
                    'Message in banner informing users how much they have exceeded their file quota limit by.',
                },
                {count: fileCountLimit.used - fileCountLimit.available},
              );
            }
            return userVariant === 'basic_individual'
              ? intl.formatMessage(
                  {
                    defaultMessage:
                      'You’re {count, plural, one {# file} other {# files}} over your upload limit. To upload more files, upgrade to Dropbox Plus and purchase the Replay Add-On.',
                    id: 'mqD6pv',
                    description:
                      'Message in banner informing users how much they have exceeded their file quota limit by.',
                  },
                  {count: fileCountLimit.used - fileCountLimit.available},
                )
              : intl.formatMessage(
                  {
                    defaultMessage:
                      'You’re {count, plural, one {# file} other {# files}} over your upload limit. To upload more files, purchase the Replay Add-On.',
                    id: 'Da/AQw',
                    description:
                      'Message in banner informing users on paid plans how much they have exceeded their file quota limit by.',
                  },
                  {count: fileCountLimit.used - fileCountLimit.available},
                );
          case 'family_user':
            if (intl.locale.startsWith('en-'))
              return intl.formatMessage(
                {
                  defaultMessage:
                    'You’re {count, plural, one {# file} other {# files}} over your upload limit. To increase your file upload limit, use a different Dropbox account to buy Dropbox Replay. Replay isnʼt available with a family plan.',
                  id: 'bW4t3G',
                  description:
                    'Message in banner informing users how much they have exceeded their file quota limit by for family plans.',
                },
                {count: fileCountLimit.used - fileCountLimit.available},
              );
            return intl.formatMessage(
              {
                defaultMessage:
                  'You’re {count, plural, one {# file} other {# files}} over your upload limit. To upload more files, purchase the Replay Add-On.',
                id: 'wM/KU/',
                description:
                  'Message in banner informing users how much they have exceeded their file quota limit by for family plans.',
              },
              {count: fileCountLimit.used - fileCountLimit.available},
            );
          case 'managed_team_member':
          case 'self_serve_team_member':
          case 'managed_team_admin':
          case 'standalone_free_member':
          case 'standalone_paid_member':
            if (intl.locale.startsWith('en-'))
              return intl.formatMessage(
                {
                  defaultMessage:
                    'You’re {count, plural, one {# file} other {# files}} over your upload limit. To upload more files, request Dropbox Replay from your admin.',
                  id: 'q/MVSj',
                  description:
                    'Message in banner informing users on plans with an account manager how much they have exceeded their file quota limit by.',
                },
                {count: fileCountLimit.used - fileCountLimit.available},
              );
            return intl.formatMessage(
              {
                defaultMessage:
                  'You’re {count, plural, one {# file} other {# files}} over your upload limit. To upload more files, contact your account manager to get the Dropbox Replay Add-On.',
                id: 'rbD34B',
                description:
                  'Message in banner informing users on plans with an account manager how much they have exceeded their file quota limit by.',
              },
              {count: fileCountLimit.used - fileCountLimit.available},
            );
        }
      }
    }
    return intl.formatMessage({
      defaultMessage: 'To upload more files and access premium features, purchase Replay.',
      id: 'bDwVW4',
      description: 'Message in banner informing users about their file quota limit',
    });
  }, [
    exceededFileQuota,
    intl,
    mpeReplayCheckoutIsEnabled,
    remainingFileQuota,
    sessionContext,
    userVariant,
  ]);

  // START: experiment upsell banner new style
  const newBannerStyleExperimentUpsellTitle = React.useMemo(() => {
    if (userVariant === 'self_serve_team_admin' && partialProvisioningSelfServeEnabled) {
      return intl.formatMessage({
        defaultMessage: 'Some team members reached their upload limit',
        id: '6Mi56N',
        description:
          'Message title in banner informing admins on self-serve teams that some team members have exceeded their file quota limit.',
      });
    } else {
      return intl.formatMessage({
        defaultMessage: 'You’ve reached your file upload limit',
        id: 'sCdEzV',
        description: 'Banner title to inform users that they have exceeded the file quota limit.',
      });
    }
  }, [intl, userVariant, partialProvisioningSelfServeEnabled]);

  const newBannerStyleExperimentUpsellBody = React.useMemo(() => {
    if (intl.locale.startsWith('en-'))
      return intl.formatMessage({
        defaultMessage: 'Get more uploads with Dropbox Replay.',
        id: 'BwuAj3',
        description:
          'Banner body to inform users about the benefit of purchasing the Replay add-on.',
      });
    return intl.formatMessage({
      defaultMessage: 'Get more uploads with the Replay add-on.',
      id: 'E1GpXm',
      description: 'Banner body to inform users about the benefit of purchasing the Replay add-on.',
    });
  }, [intl]);

  const newBannerStyleExperimentUpsellCta = React.useMemo(() => {
    return intl.formatMessage({
      defaultMessage: 'Buy Replay',
      id: 'fsMbvw',
      description: 'Button label to purchase the Replay add-on',
    });
  }, [intl]);

  const billingText = React.useMemo(() => {
    return billingCycle === 'yearly'
      ? userVariant === 'self_serve_team_admin'
        ? teamYearly
        : individualYearly
      : userVariant === 'self_serve_team_admin'
      ? teamMonthly
      : individualMonthly;
  }, [billingCycle, userVariant, individualMonthly, individualYearly, teamMonthly, teamYearly]);
  // END: experiment upsell banner new style

  const bannerProps: BannerProps = {
    type: 'warning',
    onRequestClose,
    withLeftIcon: true,
    $fullWidth: fullWidth,
  };

  if (!exceededFileQuota) {
    bannerProps.type = 'attention';
    bannerProps.withCloseButton = intl.formatMessage({
      defaultMessage: 'Close.',
      id: 'KxWLYF',
      description: 'Banner Close button',
    });
  }

  return !isLoadingReplayEligible && !isLoadingBillingInfo ? (
    <>
      {showNewUpsellBannerStyle ? (
        <Box
          backgroundColor="Warning Surface"
          data-safe-to-unmask-name="file-quota-banner"
          display="flex"
          padding="Macro XSmall"
        >
          {!isMobileDevice && (
            <Box marginRight="Macro XSmall">
              <CircleAnimation
                ariaLabel={intl.formatMessage({
                  defaultMessage: 'File quota used',
                  id: 'gBFUl+',
                  description: 'progress bar label indicating how much file quota has been used',
                })}
                fontType={Text}
                size={100}
                strokeWidth={9}
              />
            </Box>
          )}
          <Box>
            <Box marginBottom="Macro XSmall">
              <Title size="small" style={{margin: 0}} weightVariant="emphasized">
                {newBannerStyleExperimentUpsellTitle}
              </Title>
              <Text variant="label">
                {newBannerStyleExperimentUpsellBody +
                  (!isBillingUnknown ? ' ' + billingText + '.' : '')}
              </Text>
            </Box>
            <Button
              href={`${SINGLE_PURCHASE_URL}?replay_purchase_origin=file_quota_banner`}
              onClick={() => {
                // eslint-disable-next-line deprecation/deprecation
                logEvent('select.cta_link', {
                  link_type: 'buy_replay',
                  cta_location: 'file_quota_experiment_banner',
                });
                loggingClient.logPap(
                  PAP_Select_ReplayCta({
                    replayCtaType: 'buy_replay',
                    ctaLocation: 'file_quota_experiment_banner',
                    userVariant: userVariant,
                  }),
                );
              }}
              target="_blank"
              variant="primary"
            >
              {newBannerStyleExperimentUpsellCta}
            </Button>
          </Box>
        </Box>
      ) : (
        <Banner {...bannerProps}>
          <Banner.Message data-safe-to-unmask-name="file-quota-banner">
            {fileQuotaBannerText}
          </Banner.Message>
          <Banner.Actions>
            {showBuyReplayCta && (
              <Button
                data-safe-to-unmask-name="file-quota-banner-action-button"
                href={ctaLink}
                onClick={() => {
                  // eslint-disable-next-line deprecation/deprecation
                  logEvent('select.cta_link', {
                    link_type: 'purchase_addon',
                    cta_location: 'file_quota_banner',
                  });
                }}
                target="_blank"
                variant="transparent"
              >
                {provisionCta.ctaLabel}
              </Button>
            )}
          </Banner.Actions>
        </Banner>
      )}
    </>
  ) : (
    <></>
  );
};
