import {useCallback} from 'react';

import {QueryObserver, useQueryClient} from '@tanstack/react-query';

import {getLinkedOneDriveToken} from '~/lib/api';
import {replayApi} from '~/lib/query_client';

const queryOptions = {
  queryKey: replayApi.getOnedriveToken(),
  queryFn: () => getLinkedOneDriveToken(),
  cacheTime: 1000 * 60 * 15, // 15 minutes
  staleTime: 1000 * 60 * 15, // 15 minutes
};

/** Fetches onedrive token or returns false on error */
export function useOnedriveTokenFetch() {
  const queryClient = useQueryClient();
  return useCallback(
    () => queryClient.fetchQuery(queryOptions).catch(() => false as const),
    [queryClient],
  );
}

/** Semi-passively listen for the onedrive token without polling */
export function useOnedriveTokenSubscribe() {
  const queryClient = useQueryClient();
  return useCallback(async () => {
    const observer = new QueryObserver(queryClient, queryOptions);
    const result = new Promise<string>((res) =>
      observer.subscribe((x) => {
        if (x.data) {
          res(x.data);
        }
      }),
    );
    return await result;
  }, [queryClient]);
}

/** Polls until a onedrive token is returned or until the caller signals. */
export function useOnedriveTokenPoll() {
  const fetchToken = useOnedriveTokenFetch();
  return useCallback(
    async (options?: {refetch?: () => boolean}) => {
      while (true) {
        const token = await fetchToken();
        if (token) {
          return token;
        } else if (options?.refetch?.() === false) {
          return '';
        }
        await new Promise((res) => setTimeout(res, 3000));
      }
    },
    [fetchToken],
  );
}
