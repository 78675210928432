import React from 'react';

import {useAtom, useAtomValue, useSetAtom} from 'jotai';
import {useDrag, useDrop} from 'react-dnd';
import {FormattedMessage, useIntl} from 'react-intl';
import {useNavigate} from 'react-router';
import styled from 'styled-components';

import {Spinner} from '@dropbox/dig-components/progress_indicators';
import {Text} from '@dropbox/dig-components/typography';
import {UIIcon} from '@dropbox/dig-icons';
import {CommentLine, FolderLine, PlayCircleLine, ShowLine} from '@dropbox/dig-icons/assets';

import {AudioWaveformThumbnail} from '~/components/audio_waveform_thumbnail';
import {FolderContextMenu, VideoContextMenu} from '~/components/context_menu';
import {useTileDropArea} from '~/components/drop_area';
import {
  CommentsInfoCol,
  CreatedTimeCol,
  OverflowMenuCol,
  PrimaryCol,
  StatusCol,
  UpdatedTimeCol,
} from '~/components/list-table';
import {SkeletonRectangle} from '~/components/skeleton';
import {getStatusFromId} from '~/components/status/status';
import {StatusDropdown} from '~/components/status/status_dropdown';
import {spacing} from '~/components/styled';
import {useViewport} from '~/components/viewport_context';
import {useReelAppGlobalState} from '~/context';
import type {MediaType} from '~/lib/api';
import type {ShareFolderAccessType} from '~/lib/api';
import {getCurrentUser} from '~/lib/client';
import {isAVType} from '~/lib/helpers';
import {mapMediaTypeToLoggingType} from '~/lib/logging/logger_types';
import {mergeRefs} from '~/lib/merge_refs';
import {getTimeSinceForDisplay} from '~/lib/time';
import {useCanUserCreateLinks} from '~/lib/use_folder_share_onclick';
import type {
  BrowseFolderProps,
  BrowseItemsProps,
  BrowseProjectProps,
  BrowseUploadedFolderProps,
  BrowseUploadedProjectProps,
  BrowseUploadingFolderProps,
  BrowseUploadingProjectProps,
  LoadingValue,
  ShareRecipient,
} from '~/pages/browse_page/components/common';
import {ListItemsRenderer, StatusLabel, VersionLabel} from '~/pages/browse_page/components/common';
import {FileLimitBadge} from '~/pages/browse_page/components/file_limit_badge';
import {SelectCheckbox} from '~/pages/browse_page/components/select_checkbox';
import {
  folderShouldRenderBasedOnFilters,
  projectShouldRenderBasedOnFilters,
} from '~/pages/browse_page/filter_utils';
import {
  browseFiltersAtom,
  folderSelectionStateAtom,
  noItemsSelectedAtom,
  projectSelectionStateAtom,
  selectedFolderIdsAtom,
  selectedProjectIdsAtom,
  selectRangeAtom,
} from '~/state/browse';
import {useKeyboard} from '~/state/keyboard';
import {isInAdobeExtension} from '~/use_extensions';

import {CopyLinkFileButton} from './copy_link_file_button';
import {CopyLinkFolderButton} from './copy_link_folder_button';
import {moveItem, previewOptions} from './drag_and_drop_utils';
import {TeamFacepile} from './teams_facepile';
import {useResizeObserver} from './utils';
import {useBrowseItemsViewContext} from '../browse_items_view_context';
import {useBrowseLogEventMaker} from '../browse_logging_context';
import type {ProjectOrFolderItems} from '../types';
import {DndItemTypes} from '../types';

const RowContainer = styled.div<{$opacity?: number}>`
  ${({$opacity}) => ($opacity ? `opacity: ${$opacity}%;` : '')}
  display: flex;
  align-items: center;
  box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.12);
  flex: 1;
  height: 64px;
  &[aria-disabled='true'] {
    cursor: not-allowed;
    pointer-events: none;
  }
`;

const CheckboxCol = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex: 35 0 auto;
  width: 35px;
  max-width: 35px;
  height: 64px;
  white-space: nowrap;
`;

const CheckboxWrapperLabel = styled.label`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  padding-right: 12px;
  width: 100%;
`;

const IconThumbnailSlot = styled.div`
  align-items: center;
  color: var(--dig-color__text__base);
  display: flex;
  margin-left: 5px;
  width: 30px;
  height: 24px;
  max-height: 24px;
  max-width: 30px;
`;

const NameAndThumbnailWrapper = styled.div`
  align-items: center;
  display: flex;
`;

const ThumbnailImg = styled.img`
  border-radius: var(--dig-radius__medium, 0);
  width: 30px;
  height: 24px;
  margin-left: 5px;
  margin-right: 14px;
  max-height: 24px;
  max-width: 30px;
`;

const AudioWaveform = styled.div`
  width: 30px;
  height: 24px;
  margin-left: 5px;
  margin-right: 14px;
  max-height: 24px;
  max-width: 30px;
`;

const ErrorThumbnail = styled.div`
  margin-left: 5px;
  margin-right: 14px;
  width: 30px;
  height: 24px;
`;

const ThumbnailSpinner = styled.div<{$background?: string}>`
  background: ${(props) => props.$background || 'none'};
  box-sizing: border-box;
  margin-left: 5px;
  margin-right: 14px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
`;

export const TextStyled = styled(Text)`
  &&& {
    margin: 0;
  }
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const FolderInfoWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-left: 5px;
`;

export const IconAndTextWrapper = styled.div`
  min-width: 0px;
  display: flex;
  align-items: flex-start;
`;

export const IconWrapper = styled.div`
  margin-right: 6px;
  color: var(--color__white);
`;

export const TextsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
`;

const NameAndDurationWrapper = styled.div`
  margin-left: 5px;
  min-width: 0;
`;

const FileMetadataWrapper = styled.div`
  display: flex;
`;

const TextSpacer = styled(Text)`
  margin-left: var(--spacing__unit--0_5);
  margin-right: var(--spacing__unit--0_5);
`;

const PasswordRequiredText = styled(Text)`
  background: var(--dig-color__background__base);
  padding: 1px 3px;
  margin-right: 4px;
`;

const HasPasswordText = styled.div`
  display: flex;
  align-items: center;
`;

const FacepileItems = styled.div`
  margin-left: calc(var(--spacing__base_unit) * 2);
  margin-bottom: var(--spacing__base_unit);
  margin-top: auto;
`;

export const ThumbnailSlot = (props: {
  isCreatingVersion?: boolean;
  thumbnailSrc?: string;
  waveformUrl?: string;
  hasPassword?: boolean;
}) => {
  const {hasPassword, isCreatingVersion, thumbnailSrc, waveformUrl} = props;

  const [error, setError] = React.useState<boolean>(false);

  if (hasPassword) {
    return (
      <IconThumbnailSlot>
        <UIIcon src={PlayCircleLine} />
      </IconThumbnailSlot>
    );
  }

  if (isCreatingVersion) {
    return (
      <ThumbnailSpinner>
        <Spinner monochromatic size="xsmall" />
      </ThumbnailSpinner>
    );
  }

  if (error) {
    return <ErrorThumbnail />;
  }

  if (thumbnailSrc === 'loading' && waveformUrl === 'loading') {
    // Use the audio waveform div for positioning
    return (
      <AudioWaveform>
        <SkeletonRectangle $heightValue="24px" $widthValue="30px" />
      </AudioWaveform>
    );
  }

  if (waveformUrl) {
    return (
      <AudioWaveform>
        <AudioWaveformThumbnail waveformUrl={waveformUrl} />
      </AudioWaveform>
    );
  }

  if (thumbnailSrc) {
    return <ThumbnailImg alt="" onError={() => setError(true)} src={thumbnailSrc} />;
  }

  return <ErrorThumbnail />;
};

const NameAndThumbnailContent = (props: {
  name: string;
  durationDisplayString?: string;
  isCreatingVersion?: boolean;
  thumbnailSrc?: string;
  versionNumber?: number;
  isUploading?: boolean;
  waveformUrl?: string;
  hasPassword?: boolean;
  mediaType: MediaType | LoadingValue;
  showFileLimitBadge: boolean;
  fileExtension?: string;
}) => {
  const {
    durationDisplayString,
    hasPassword,
    isCreatingVersion,
    name,
    thumbnailSrc,
    versionNumber,
    waveformUrl,
    mediaType,
    showFileLimitBadge,
    fileExtension,
  } = props;

  const intl = useIntl();

  const loadingMsg = intl.formatMessage({
    defaultMessage: 'Updating...',
    id: '0GWRbP',
    description:
      'Text that displays in the project preview when a user has uploaded a new version of a file and the new version is still loading',
  });

  const versionMsg = intl.formatMessage(
    {
      defaultMessage: 'V{version_number}',
      id: 'iSN1ba',
      description:
        'Text that displays in the project preview denoting the latest version, e.g., for a project with three versions, V3',
    },
    {
      version_number: versionNumber,
    },
  );

  const formattedFileExt = fileExtension?.substring(1).toUpperCase();

  return (
    <NameAndThumbnailWrapper>
      <ThumbnailSlot
        hasPassword={hasPassword}
        isCreatingVersion={isCreatingVersion}
        thumbnailSrc={thumbnailSrc}
        waveformUrl={waveformUrl}
      />
      <NameAndDurationWrapper>
        {hasPassword ? (
          <HasPasswordText>
            <PasswordRequiredText size="small" variant="label">
              <FormattedMessage
                defaultMessage="Password required"
                description="Video description that a user sees on the browse page for a password-protected video. These videos do not display any other information."
                id="LfmrjV"
              />
            </PasswordRequiredText>
            <Text>{name}</Text>
          </HasPasswordText>
        ) : (
          <>
            <TextStyled isBold tagName="p">
              {name}
            </TextStyled>
            <FileMetadataWrapper>
              {versionNumber && (
                <VersionLabel>{isCreatingVersion ? loadingMsg : versionMsg}</VersionLabel>
              )}
              {fileExtension && (
                <>
                  <TextSpacer size="small">·</TextSpacer>
                  <TextStyled color="faint" size="small" tagName="span">
                    {formattedFileExt}
                  </TextStyled>
                </>
              )}
              {mediaType !== 'loading' && isAVType(mediaType) ? (
                <>
                  <TextSpacer size="small">·</TextSpacer>
                  <TextStyled color="faint" size="small" tagName="span">
                    {durationDisplayString === 'loading' ? '' : durationDisplayString}
                  </TextStyled>
                </>
              ) : null}
              {showFileLimitBadge && <FileLimitBadge view="list" />}
            </FileMetadataWrapper>
          </>
        )}
      </NameAndDurationWrapper>
    </NameAndThumbnailWrapper>
  );
};

const CommentViewInfo = styled.p`
  margin: 0;
  margin-right: 4px;
  margin-left: 4px;
  margin-bottom: 2px;
`;

export const LoadingProjectListView = () => {
  return (
    <>
      <LoadingProjectListRow key="project-loading-skeleton-1" />
      <LoadingProjectListRow key="project-loading-skeleton-2" opacity={0.8} />
      <LoadingProjectListRow key="project-loading-skeleton-3" opacity={0.4} />
    </>
  );
};

type LoadingFolderListRowProps = {
  name: string;
  isRootLevelView: boolean;
  isUploading: boolean;
};

const LoadingFolderListRow = ({name, isRootLevelView, isUploading}: LoadingFolderListRowProps) => {
  const intl = useIntl();
  const uploadingText = intl.formatMessage({
    defaultMessage: 'Uploading...',
    id: 'syb7nr',
    description:
      'Text used to describe a file that is uploading. The text appears alongside a placeholder thumbnail while the file is being uploaded.',
  });

  return (
    <div role="row">
      <RowContainer>
        <PrimaryCol>
          <FolderInfoWrapper>
            <IconAndTextWrapper>
              <IconWrapper>
                <UIIcon size="standard" src={FolderLine} />
              </IconWrapper>
              <TextsWrapper>
                <TextStyled isBold tagName="p">
                  {name}
                </TextStyled>
                <TextStyled color="faint" size="small" tagName="span">
                  {isUploading ? uploadingText : <SkeletonRectangle style={{width: 158}} />}{' '}
                </TextStyled>
              </TextsWrapper>
            </IconAndTextWrapper>
          </FolderInfoWrapper>
        </PrimaryCol>
        <StatusCol />
        <UpdatedTimeCol />
        <CreatedTimeCol />
        <CommentsInfoCol />
        <OverflowMenuCol />
      </RowContainer>
    </div>
  );
};

const UploadingFolderListRow = ({
  name,
  isRootLevelView,
  isUploading,
}: BrowseUploadingFolderProps) => {
  return (
    <LoadingFolderListRow isRootLevelView={isRootLevelView} isUploading={isUploading} name={name} />
  );
};

const FileCountSkeleton = styled(SkeletonRectangle)`
  // Introduce a gap between the folder name and the file count to reduce the
  // layout shift when the folder info comes in. Alternatively, make the
  // skeleton height 20px, but that is contextually too loud.
  margin-top: ${spacing('8')};
`;

const LoadedFolderInfoWrapper = ({
  fileCount,
  shareRecipients,
  accessLevel,
  allowSuperAdminRights,
  openAddPeopleModal,
  settingsChangeCallback,
  folderId,
  name,
}: {
  fileCount?: number;
  shareRecipients?: ShareRecipient[] | LoadingValue;
  accessLevel: ShareFolderAccessType;
  allowSuperAdminRights?: boolean;
  openAddPeopleModal: (sharedFolderId: string, sharedFolderName: string) => void;
  settingsChangeCallback?(folderId: string): void;
  folderId: string;
  name: string;
}) => {
  // Observe the width of the first column to determine if there is enough space to show the facepile
  const [ref, dimensions] = useResizeObserver();
  const threshold = 300;
  const shouldShowFacepile = dimensions && dimensions.width > threshold;

  return (
    <FolderInfoWrapper ref={ref}>
      <IconAndTextWrapper>
        <IconWrapper>
          <UIIcon size="standard" src={FolderLine} />
        </IconWrapper>
        <TextsWrapper>
          <TextStyled isBold tagName="p">
            {name}
          </TextStyled>
          {fileCount !== undefined ? (
            <TextStyled color="faint" size="small" tagName="span">
              <FormattedMessage
                defaultMessage="{count, plural, one{# file} other{# files}}"
                description="Text that describes the number of files in a folder."
                id="JK5sgN"
                values={{count: fileCount}}
              />
            </TextStyled>
          ) : (
            <FileCountSkeleton $widthValue="70px" />
          )}
        </TextsWrapper>
      </IconAndTextWrapper>
      {shareRecipients === 'loading' ? (
        <FacepileItems>
          <SkeletonRectangle $heightValue="24px" $widthValue="75px" />
        </FacepileItems>
      ) : shareRecipients && shouldShowFacepile ? (
        <FacepileItems>
          {shareRecipients && shareRecipients.length > 1 ? (
            <TeamFacepile
              accessLevel={accessLevel}
              actionElement="content-tile"
              allowSuperAdminRights={allowSuperAdminRights}
              openAddPeopleModal={openAddPeopleModal}
              settingsChangeCallback={settingsChangeCallback}
              shareRecipients={shareRecipients}
              teamFolderId={folderId}
              teamFolderName={name}
            />
          ) : null}
        </FacepileItems>
      ) : null}
    </FolderInfoWrapper>
  );
};

const FolderListRow = (props: BrowseUploadedFolderProps) => {
  const {
    createdAtDate,
    reelLink,
    name,
    updatedAtDate,
    numProjects,
    onFoldersDelete,
    onFolderRename,
    updateModifyItemSnackbar,
    folderId,
    parentFolderId,
    parentFolderPermission,
    onBulkMove,
    onProjectMove,
    onFolderMove,
    openAddPeopleModal,
    openManagePeopleModal,
    accessLevel,
    isSharedVideoView,
    isRootLevelView,
    updateMoveInProgressSnackbar,
    shareRecipients,
    settingsChangeCallback,
    allowSuperAdminRights,
  } = props;
  const [isHovered, setIsHovered] = React.useState(false);
  const {timeAgoLocale} = useReelAppGlobalState();

  const noItemsSelected = useAtomValue(noItemsSelectedAtom);
  const selectedProjectIds = useAtomValue(selectedProjectIdsAtom);
  const selectedFoldersIds = useAtomValue(selectedFolderIdsAtom);

  const selectRange = useSetAtom(selectRangeAtom);
  const [getFolderSelectionState, setFolderSelectionState] = useAtom(folderSelectionStateAtom);
  const {selected, selectable} = getFolderSelectionState(folderId);
  const filters = useAtomValue(browseFiltersAtom);

  const {isHoverSupported} = useViewport();
  const keyboard = useKeyboard();
  const navigate = useNavigate();

  const {currentFolderPermission, editActionsProps} = useBrowseItemsViewContext();

  const visible = folderShouldRenderBasedOnFilters(filters);

  const canCreateShareLinks =
    useCanUserCreateLinks({accessLevel}) && currentFolderPermission !== 'reviewer';

  const makeLogEvent = useBrowseLogEventMaker();
  const logEvent = React.useMemo(
    () => makeLogEvent({reelObjectType: isRootLevelView ? 'project' : 'folder'}),
    [makeLogEvent, isRootLevelView],
  );

  const intl = useIntl();
  const listView = true;

  const [{onHover}, drop] = useDrop(
    () => ({
      accept: [DndItemTypes.ProjectTile, DndItemTypes.FolderTile],
      drop: (item: ProjectOrFolderItems, monitor) => {
        if (!editActionsProps.editActionsEnabled) {
          return;
        }
        let itemToMove;
        if (noItemsSelected) {
          itemToMove = item;
        } else {
          const folderIdsToMove = selectedFoldersIds;
          const projectIdsToMove = selectedProjectIds;

          if (
            item.itemType === DndItemTypes.FolderTile &&
            !folderIdsToMove.includes(item.folderId)
          ) {
            folderIdsToMove.push(item.folderId);
          } else if (
            item.itemType === DndItemTypes.ProjectTile &&
            !projectIdsToMove.includes(item.projectId)
          ) {
            projectIdsToMove.push(item.projectId);
          }

          itemToMove = {
            itemType: DndItemTypes.BulkTiles,
            folderIds: selectedFoldersIds,
            projectIds: selectedProjectIds,
          };
        }
        return moveItem(
          itemToMove,
          parentFolderId!,
          folderId,
          name,
          onBulkMove,
          onProjectMove,
          onFolderMove,
          'grid_or_list',
          editActionsProps.folderTree,
          updateMoveInProgressSnackbar,
          editActionsProps.reelRootFolderId,
        );
      },
      collect: (monitor) => ({
        onHover: monitor.isOver(),
      }),
    }),
    [folderId, name, onProjectMove, onFolderMove],
  );

  const item: ProjectOrFolderItems = {
    itemType: DndItemTypes.FolderTile,
    folderId,
    folderName: name,
  };

  const [{isDragging}, dragRef, dragPreview] = useDrag(
    () => ({
      type: DndItemTypes.FolderTile,
      item,
      previewOptions: previewOptions,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [folderId, name],
  );

  const dragPreviewCallbackRef = React.useCallback(
    (node: HTMLDivElement | null) => {
      if (
        editActionsProps.editActionsEnabled === false ||
        editActionsProps.leftRailStatus.status !== 'closed'
      ) {
        return;
      }
      if (
        isRootLevelView ||
        (isSharedVideoView && parentFolderPermission === 'reviewer') ||
        parentFolderPermission === 'other'
      ) {
        return;
      }
      dragRef(node);
      dragPreview(node, previewOptions);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- PR 894
    [
      dragPreview,
      dragRef,
      editActionsProps,
      isRootLevelView,
      isSharedVideoView,
      noItemsSelected,
      parentFolderPermission,
    ],
  );

  // Prevent click from navigating to media
  const handleCheckboxClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const handleSelectFolder = (isSelected: boolean) => {
    if (keyboard.Shift) {
      selectRange(folderId);
    } else {
      setFolderSelectionState({folderId, selected: isSelected});
    }
  };

  const [tileRef, tileContents] = useTileDropArea({
    accessLevel,
    folderId,
    isEnabled: !isSharedVideoView,
    listView,
  });

  const showCheckbox =
    selectable && (!noItemsSelected || isHovered || selected || !isHoverSupported);

  return (
    <RowContainer
      onClick={() => {
        // eslint-disable-next-line deprecation/deprecation
        logEvent('select_reel_object');
        navigate(reelLink);
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      ref={mergeRefs([dragPreviewCallbackRef, drop, tileRef])}
      role="row"
      style={{
        display: visible ? 'flex' : 'none',
        position: 'relative',
        cursor: 'pointer',
        background: onHover ? '#242121' : 'transparent',
      }}
      tabIndex={0}
    >
      {tileContents}
      {editActionsProps.editActionsEnabled && (
        <CheckboxCol role="cell">
          {showCheckbox && (
            <CheckboxWrapperLabel
              htmlFor={`select-folder-${folderId}`}
              onClick={handleCheckboxClick}
            >
              <SelectCheckbox
                id={`select-folder-${folderId}`}
                isChecked={selected}
                onChange={({target: {checked}}) => handleSelectFolder(checked)}
              />
            </CheckboxWrapperLabel>
          )}
        </CheckboxCol>
      )}
      <PrimaryCol
        role="cell"
        style={{
          opacity: isDragging ? 0.5 : 1,
        }}
      >
        <LoadedFolderInfoWrapper
          accessLevel={accessLevel}
          allowSuperAdminRights={allowSuperAdminRights}
          fileCount={numProjects}
          folderId={folderId}
          name={name}
          openAddPeopleModal={openAddPeopleModal}
          settingsChangeCallback={settingsChangeCallback}
          shareRecipients={shareRecipients}
        />
      </PrimaryCol>
      <StatusCol role="cell" />
      <UpdatedTimeCol role="cell">
        <Text>{getTimeSinceForDisplay(updatedAtDate, intl, timeAgoLocale)}</Text>
      </UpdatedTimeCol>
      <CreatedTimeCol role="cell">
        <Text>{getTimeSinceForDisplay(createdAtDate, intl, timeAgoLocale)}</Text>
      </CreatedTimeCol>
      <CommentsInfoCol role="cell">
        {!isSharedVideoView && isHovered && isHoverSupported && canCreateShareLinks && (
          <CopyLinkFolderButton
            accessLevel={accessLevel}
            folderId={folderId}
            folderName={name}
            isTeamFolder={isRootLevelView}
            isViewOnlyLinkAccess={false}
            logEvent={logEvent}
          />
        )}
      </CommentsInfoCol>
      <OverflowMenuCol role="cell">
        {currentFolderPermission !== 'reviewer' && editActionsProps.editActionsEnabled ? (
          <FolderContextMenu
            accessLevel={accessLevel ? accessLevel : 'none'}
            allowSuperAdminRights={
              allowSuperAdminRights &&
              shareRecipients &&
              shareRecipients !== 'loading' &&
              shareRecipients.length > 1
            }
            folderId={folderId}
            isTeamProject={isRootLevelView}
            name={name}
            onDelete={onFoldersDelete}
            onRename={onFolderRename}
            openAddPeopleModal={openAddPeopleModal}
            openManagePeopleModal={openManagePeopleModal}
            updateModifyItemSnackbar={updateModifyItemSnackbar}
          />
        ) : null}
      </OverflowMenuCol>
    </RowContainer>
  );
};

type LoadingProjectListRowProps = {
  opacity?: number;
  name?: string;
  isUploading?: boolean;
};

const LoadingProjectListRow = ({opacity, name, isUploading}: LoadingProjectListRowProps) => {
  const intl = useIntl();
  const uploadingText = intl.formatMessage({
    defaultMessage: 'Uploading...',
    id: 'syb7nr',
    description:
      'Text used to describe a file that is uploading. The text appears alongside a placeholder thumbnail while the file is being uploaded.',
  });
  return (
    <RowContainer $opacity={opacity} role="row">
      <CheckboxCol role="cell" />
      <PrimaryCol role="cell">
        <NameAndThumbnailWrapper>
          {isUploading ? (
            <ThumbnailSpinner $background="var(--dig-color__opacity__surface--state-2)">
              <Spinner monochromatic size="xsmall" />
            </ThumbnailSpinner>
          ) : (
            <SkeletonRectangle style={{height: 32, width: 32, marginRight: '12px'}} />
          )}
          <NameAndDurationWrapper>
            <TextStyled color="disabled" isBold tagName="p">
              {name || <SkeletonRectangle style={{width: 258, marginBottom: '8px'}} />}
            </TextStyled>
            <FileMetadataWrapper>
              <TextStyled color="disabled" size="small" tagName="span">
                {isUploading ? uploadingText : <SkeletonRectangle style={{width: 158}} />}{' '}
              </TextStyled>
            </FileMetadataWrapper>
          </NameAndDurationWrapper>
        </NameAndThumbnailWrapper>
      </PrimaryCol>
      <StatusCol role="cell"></StatusCol>
      <UpdatedTimeCol role="cell">
        <SkeletonRectangle style={{width: 80}} />
      </UpdatedTimeCol>
      <CreatedTimeCol role="cell">
        <SkeletonRectangle style={{width: 122}} />
      </CreatedTimeCol>
      <CommentsInfoCol role="cell">--</CommentsInfoCol>
      <OverflowMenuCol role="cell"></OverflowMenuCol>
    </RowContainer>
  );
};

const UploadingProjectListRow = ({name, isUploading}: BrowseUploadingProjectProps) => {
  return <LoadingProjectListRow isUploading={isUploading} name={name} />;
};

const ProjectListRow = (props: BrowseUploadedProjectProps) => {
  const {
    createdAtDate,
    durationDisplayString,
    hasPassword,
    mediaType,
    isCreatingVersion,
    isUpdatingStatus,
    isDemo,
    isRootLevelView,
    isSharedVideoView,
    lastModifiedDate,
    name,
    nsId,
    numComments,
    onProjectsDelete,
    onProjectRename,
    onProjectTransfer,
    onVersionSelected,
    onVersionStatusSelected,
    ownerUid,
    parentFolderPermission,
    projectId,
    reelLink,
    status,
    thumbnailSrc,
    updateModifyItemSnackbar,
    versionNumber,
    versionSummaries,
    videoId,
    videoIdForAmplitude,
    waveformUrl,
    viewerCount,
    showFileLimitBadge,
    ownerAddonEnabled,
    fileExtension,
  } = props;

  const navigate = useNavigate();
  const {editActionsProps} = useBrowseItemsViewContext();
  const {hasGuestUserInfo, isAnonymousUser, timeAgoLocale} = useReelAppGlobalState();
  const intl = useIntl();
  const keyboard = useKeyboard();
  const isGuestUser = hasGuestUserInfo() || isAnonymousUser;

  const noItemsSelected = useAtomValue(noItemsSelectedAtom);
  const selectRange = useSetAtom(selectRangeAtom);
  const [getProjectSelectionState, setProjectSelectionState] = useAtom(projectSelectionStateAtom);
  const {selected, selectable} = getProjectSelectionState(projectId);
  const filters = useAtomValue(browseFiltersAtom);

  const [isHovered, setIsHovered] = React.useState(false);
  const {isHoverSupported} = useViewport();
  const currentUserIsOwner = ownerUid === String(getCurrentUser()?.id);

  const visible =
    mediaType === 'loading'
      ? true
      : projectShouldRenderBasedOnFilters(getStatusFromId(status ?? 0), filters, mediaType);

  const canCreateShareLinks =
    currentUserIsOwner ||
    parentFolderPermission == 'admin' ||
    parentFolderPermission == 'owner' ||
    parentFolderPermission === 'super_admin';

  const makeLogEvent = useBrowseLogEventMaker();
  const logEvent = React.useMemo(
    () =>
      makeLogEvent({
        videoId: videoIdForAmplitude,
        reelObjectType: mapMediaTypeToLoggingType(mediaType),
      }),
    [makeLogEvent, videoIdForAmplitude, mediaType],
  );

  const item: ProjectOrFolderItems = {
    itemType: DndItemTypes.ProjectTile,
    projectId,
    projectName: name,
  };

  // Prevent click from navigating to media
  const handleCheckboxClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const handleSelectProject = (isSelected: boolean) => {
    if (keyboard.Shift) {
      selectRange(projectId);
    } else {
      setProjectSelectionState({projectId, selected: isSelected});
    }
  };

  const [{isDragging}, dragRef, dragPreview] = useDrag(
    () => ({
      type: DndItemTypes.ProjectTile,
      item,
      previewOptions,
      collect: (monitor: any) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [projectId, name],
  );

  const dragPreviewCallbackRef = React.useCallback(
    (node: HTMLDivElement | null) => {
      if (
        editActionsProps.editActionsEnabled === false ||
        editActionsProps.leftRailStatus.status !== 'closed'
      ) {
        return;
      }
      if (
        isSharedVideoView &&
        (isRootLevelView ||
          parentFolderPermission === 'reviewer' ||
          parentFolderPermission === 'other')
      ) {
        return;
      }
      dragRef(node);
      dragPreview(node, previewOptions);
    },
    [
      editActionsProps,
      isSharedVideoView,
      isRootLevelView,
      parentFolderPermission,
      dragRef,
      dragPreview,
    ],
  );

  const [tileRef, tileContents] = useTileDropArea({
    isEnabled: !isSharedVideoView,
    listView: true,
    name,
    onVersionSelected,
    projectId,
    versionNumber,
    videoId,
    videoNsId: nsId,
  });

  const showCheckbox =
    selectable && (!noItemsSelected || isHovered || selected || !isHoverSupported);

  const disable = isInAdobeExtension() && mediaType === 'document';

  return (
    <RowContainer
      $opacity={disable ? 30 : 100}
      aria-disabled={disable}
      onClick={() => {
        // eslint-disable-next-line deprecation/deprecation
        logEvent('select_reel_object');
        navigate(reelLink);
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      ref={mergeRefs([dragPreviewCallbackRef, tileRef])}
      role="row"
      style={{
        display: visible ? 'flex' : 'none',
        position: 'relative',
      }}
      tabIndex={0}
    >
      {tileContents}
      {editActionsProps.editActionsEnabled && (
        <CheckboxCol role="cell">
          {showCheckbox && (
            <CheckboxWrapperLabel
              htmlFor={`select-project-${projectId}`}
              onClick={handleCheckboxClick}
            >
              <SelectCheckbox
                id={`select-project-${projectId}`}
                isChecked={selected}
                onChange={({target: {checked}}) => handleSelectProject(checked)}
              />
            </CheckboxWrapperLabel>
          )}
        </CheckboxCol>
      )}
      <PrimaryCol role="cell" style={{opacity: isDragging ? 0.5 : 1}}>
        <NameAndThumbnailContent
          durationDisplayString={durationDisplayString}
          fileExtension={fileExtension}
          hasPassword={hasPassword}
          isCreatingVersion={isCreatingVersion}
          mediaType={mediaType}
          name={name}
          showFileLimitBadge={showFileLimitBadge}
          thumbnailSrc={thumbnailSrc}
          versionNumber={versionNumber}
          waveformUrl={waveformUrl}
        />
      </PrimaryCol>
      <StatusCol role="cell">
        {isGuestUser ? (
          // If status is 0 (NO_STATUS) or missing, leave column but show nothing
          status ? (
            <StatusLabel dotSize={12} dotTextSpacing={8} status={status} textSize="medium" />
          ) : null
        ) : (
          <div
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <StatusDropdown
              buttonSize="standard"
              buttonType="outline"
              disabled={isUpdatingStatus}
              onChange={onVersionStatusSelected}
              versionStatus={getStatusFromId(status)}
            />
          </div>
        )}
      </StatusCol>
      <UpdatedTimeCol role="cell">
        {lastModifiedDate ? (
          <Text>{getTimeSinceForDisplay(lastModifiedDate, intl, timeAgoLocale)}</Text>
        ) : (
          '-'
        )}
      </UpdatedTimeCol>
      <CreatedTimeCol role="cell">
        <Text>{getTimeSinceForDisplay(createdAtDate, intl, timeAgoLocale)}</Text>
      </CreatedTimeCol>
      <CommentsInfoCol role="cell">
        {isHovered && isHoverSupported && canCreateShareLinks ? (
          <div
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <CopyLinkFileButton logEvent={logEvent} {...props} />
          </div>
        ) : numComments === 'loading' ? (
          <SkeletonRectangle />
        ) : (
          <>
            {numComments === 0 && (viewerCount === undefined || viewerCount === 0) && '--'}
            <Counter count={numComments} counterType="comment" />
            <Counter count={viewerCount ?? 0} counterType="viewer" />
          </>
        )}
      </CommentsInfoCol>
      <OverflowMenuCol role="cell">
        {mediaType !== 'loading' &&
        parentFolderPermission != 'reviewer' &&
        editActionsProps.editActionsEnabled ? (
          <VideoContextMenu
            currentVersionNumber={versionNumber ? versionNumber : 1}
            isDemo={isDemo}
            mediaType={mediaType}
            name={name}
            onDelete={onProjectsDelete}
            onRename={onProjectRename}
            onTransfer={onProjectTransfer}
            onVersionSelected={onVersionSelected}
            ownerAddonEnabled={ownerAddonEnabled}
            ownerUid={ownerUid}
            parentFolderPermission={parentFolderPermission}
            projectId={projectId}
            thumbnailUrl={thumbnailSrc}
            updateModifyItemSnackbar={updateModifyItemSnackbar}
            versionSummaries={versionSummaries}
            videoId={videoId}
            videoIdForAmplitude={videoIdForAmplitude}
            videoNsId={nsId}
          />
        ) : null}
      </OverflowMenuCol>
    </RowContainer>
  );
};

type CounterProps = {
  count: number;
  counterType: 'comment' | 'viewer';
};

const Counter = ({count, counterType}: CounterProps) => {
  if (count > 0) {
    return (
      <>
        <UIIcon size="small" src={counterType === 'comment' ? CommentLine : ShowLine} />
        <CommentViewInfo>
          <Text isBold size="small" variant="label">
            {count}
          </Text>
        </CommentViewInfo>
      </>
    );
  }
  return null;
};

export const ProjectListView = (props: BrowseItemsProps) => {
  return (
    <div role="table">
      <ListItemsRenderer {...props} renderFolder={ListFolderRow} renderProject={ListProjectRow} />
    </div>
  );
};

const ListFolderRow = (props: BrowseFolderProps) => {
  if (props.isUploading) {
    return <UploadingFolderListRow key={`uploading-${props.name}`} {...props} />;
  }
  return <FolderListRow key={props.folderId} {...props} />;
};

const ListProjectRow = (props: BrowseProjectProps) => {
  if (props.isUploading) {
    if (props.folderUpload) {
      return null;
    }
    return <UploadingProjectListRow key={`uploading-${props.name}`} {...props} />;
  }
  return <ProjectListRow key={props.videoId} {...props} />;
};
