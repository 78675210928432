import React from 'react';

import {FormattedMessage} from 'react-intl';

import {UIIcon} from '@dropbox/dig-icons';
import {LinkLine} from '@dropbox/dig-icons/assets';

import {Button} from '~/components/button';
import {useCopyBasicLinkToClipboard} from '~/components/share_modal/copy_link_utils';
import {useViewport} from '~/components/viewport_context';
import type {LoggingClient} from '~/lib/logging/logger';
import {type FolderShareProps, useCanUserCreateLinks} from '~/lib/use_folder_share_onclick';
import {useLinkActions} from '~/lib/use_link_actions';

export const CopyLinkFolderButton = (
  props: FolderShareProps & {logEvent: LoggingClient['logEvent']},
) => {
  const {folderId, logEvent, accessLevel} = props;
  const [loading, setLoading] = React.useState(false);
  const {createShareLink} = useLinkActions({
    logEvent,
    linkItemId: folderId,
    linkItemType: 'folder',
    location: 'browse_list_view',
  });
  // eslint-disable-next-line deprecation/deprecation
  const {isSmallScreenSize} = useViewport();
  const {copyLinkToClipboard} = useCopyBasicLinkToClipboard();
  const canCreateLinks = useCanUserCreateLinks({accessLevel});

  const onShareClick = async (e: React.MouseEvent<HTMLElement>) => {
    if (!canCreateLinks) {
      return;
    }

    setLoading(true);
    e.preventDefault();
    e.stopPropagation();

    // eslint-disable-next-line deprecation/deprecation
    logEvent('copy_reel_share_link', {
      access_type: 'review',
      link_item_type: 'folder',
      is_onboarding_share_step: 'false',
      replay_media_type: 'none',
      experiment_variant: 'none',
    });

    // The generated link is set as basic as possible, given we have no way to configure in this one-click action
    const shareLink = await createShareLink({
      isFolder: false,
      viewOnly: false,
      downloadsDisabled: false,
      passwordEnabled: false,
      password: '',
      loggedOutAccessDisabled: false,
      viewPreviousCommentsDisabled: false,
    });
    await copyLinkToClipboard(shareLink);
    setLoading(false);
  };

  if (!canCreateLinks) {
    return null;
  }

  return (
    <Button
      isLoading={loading}
      onClick={onShareClick}
      size={isSmallScreenSize ? 'small' : 'standard'}
      variant="outline"
      withIconStart={<UIIcon src={LinkLine} />}
    >
      <FormattedMessage
        defaultMessage="Copy link"
        description="Text for a menu item that lets you share the folder with someone else by directly copying the share link."
        id="mdEUU1"
      />
    </Button>
  );
};
