import React from 'react';

import {useIntl} from 'react-intl';
import {useLocation} from 'react-router';
import styled, {css} from 'styled-components';

import type {reel} from '@dropbox/api-v2-client';
import {Banner} from '@dropbox/dig-components/banner';
import {Breadcrumb} from '@dropbox/dig-components/breadcrumb';
import {Truncate} from '@dropbox/dig-components/truncate';
import {Text, Title} from '@dropbox/dig-components/typography';
import {Box} from '@dropbox/dig-foundations';

import {appStrings} from '~/components/app_strings';
import {Button} from '~/components/button';
import {FolderHeader} from '~/components/folder_header/folder_header';
import {ReelLink} from '~/components/link';
import {useGrantBook} from '~/components/password_cache_provider';
import {RestrictedBanner} from '~/components/restricted_banner/restricted_banner';
import {breakpointMedium, breakpointSmall, color, spacing} from '~/components/styled';
import type {ShareFolderAccessType} from '~/lib/api';
import {createFolderLink, deleteFolderLink, emptyBranding} from '~/lib/api';
import {useUpdateProjectMutation} from '~/lib/api_queries';
import type {Branding, BrandingLogo, BrandingTheme} from '~/lib/branding';
import type {AddMediaClickSourceType} from '~/lib/logging/logger_types';
import type {AllUploadsCompleteHandler, BasicFileInfo, ChooserFile} from '~/lib/uploads/types';
import {useGetPapAccessType} from '~/lib/use_get_pap_access_type';
import {useIsBetaGracePeriodOver, useIsUserGrandfatheredFromBeta} from '~/lib/utils';
import {useBrowseLogEventMaker} from '~/pages/browse_page/browse_logging_context';
import {ActionButtons} from '~/pages/browse_page/components/action_buttons';
import type {BrandingThemeModalProps} from '~/pages/browse_page/components/branding/branding_theme_modal';
import {EditableDescription} from '~/pages/browse_page/components/header/editable_description';
import {EditableTitle} from '~/pages/browse_page/components/header/editable_title';
import {HeaderLogo} from '~/pages/browse_page/components/header/header_logo';
import {useFoldersSharedState} from '~/pages/browse_page/folders_context';
import {type FolderLink, type ProjectFolderInfo} from '~/pages/browse_page/types';

import type {FolderToAccounts} from '../../use_share_recipients';
import type {BrowseItemsProps, NavigationRouteTypes} from '../common';
import {DownloadFolderButtonAndModal} from '../download/download_folder_button_and_modal';
import {FolderLinkButton} from '../folder_links/folder_link_button';
import {ShareFolderButton} from '../share_folder_button';

export const YourRootText = 'Your videos';

const BreadcrumbWrapper = styled(Breadcrumb)`
  width: 100%;
  flex: 1;
`;

const BreadcrumbRow = styled.div`
  align-items: center;
  display: flex;
`;

const BreadcrumbLink = styled(Breadcrumb.Link)`
  & .dig-Breadcrumb-link-text {
    color: ${color('Text Subtle')};
  }
`;

const TitleRow = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const PageTitle = styled(Title)`
  display: inline-block;
  margin: 0 ${spacing('Micro Medium')} 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* Override size to match the designs */
  ${(props) => (props.size === 'medium' ? 'font-size: 24px;' : '')}
`;

export const ProjectInfo = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing('Micro Small')};

  ${breakpointSmall(
    css`
      margin-top: ${spacing('Micro Small')};
    `,
  )}
`;

const ProjectDetails = styled.div`
  width: 100%;
  overflow: hidden;
`;

const BreadcrumbReelLink = styled(ReelLink)`
  &:hover {
    text-decoration: underline;
  }
`;

type BreadcrumbPathLinkProps = {
  folderId: string;
  breadcrumbName: string;
  breadcrumbTo: string;
  onClick: () => void;
};

const breadcrumbPathLink = ({
  folderId,
  breadcrumbName,
  breadcrumbTo,
  onClick,
}: BreadcrumbPathLinkProps) => {
  return (
    // set isCurrentPath to true to use `span` and prevent a-in-a
    <BreadcrumbLink isCurrentPath={true} key={folderId}>
      <BreadcrumbReelLink onClick={onClick} to={breadcrumbTo}>
        <Truncate>{breadcrumbName}</Truncate>
      </BreadcrumbReelLink>
    </BreadcrumbLink>
  );
};

export enum ViewType {
  BROWSE = 0,
  SHARED = 1,
  ADMIN = 2,
}

export type BareBreadcrumbFolderInfo = {
  id: string;
  name: string | undefined;
};

type BreadcrumbsProps = {
  breadcrumbPath: BareBreadcrumbFolderInfo[];
  viewType: ViewType;
  currentProjectAmplitudeId?: string;
  makeFolderLink: BrowseItemsProps['makeFolderLink'];
};

const BreadcrumbsNonMemo = ({
  breadcrumbPath,
  currentProjectAmplitudeId,
  viewType,
  makeFolderLink,
}: BreadcrumbsProps) => {
  const makeLogEvent = useBrowseLogEventMaker();
  const intl = useIntl();

  const logEvent = React.useMemo(
    () => makeLogEvent({projectId: currentProjectAmplitudeId}),
    [makeLogEvent, currentProjectAmplitudeId],
  );

  const handleBreadcrumbClick = () =>
    // eslint-disable-next-line deprecation/deprecation
    logEvent('select_breadcrumb');

  const isSharedFolder = viewType === ViewType.SHARED;
  let rootFolderUrl: string;
  if (viewType === ViewType.BROWSE) {
    rootFolderUrl = '/';
  } else if (viewType === ViewType.SHARED) {
    rootFolderUrl = '/shared';
  } else if (viewType === ViewType.ADMIN) {
    rootFolderUrl = '/admin';
  }

  return (
    <BreadcrumbRow>
      <BreadcrumbWrapper size="small">
        {breadcrumbPath.map((folder) => {
          const inRootFolder = folder.name === YourRootText;
          const folderUrl = makeFolderLink({folderId: folder.id});
          const breadcrumbTo = inRootFolder ? rootFolderUrl : folderUrl;
          const inRootSharedFolder = inRootFolder && isSharedFolder;
          const breadcrumbName = inRootSharedFolder
            ? intl.formatMessage(appStrings.sharedTitle)
            : folder.name === YourRootText
            ? intl.formatMessage(appStrings.homeTitle)
            : folder.name || '';

          return breadcrumbPathLink({
            folderId: folder.id,
            breadcrumbName: breadcrumbName,
            breadcrumbTo: breadcrumbTo,
            onClick: handleBreadcrumbClick,
          });
        })}
      </BreadcrumbWrapper>
    </BreadcrumbRow>
  );
};

export const Breadcrumbs = React.memo(BreadcrumbsNonMemo);

interface CommonFolderHeaderLayoutProps {
  theme?: BrandingTheme;
  breadcrumbs: React.ReactNode;
  inFolder: boolean;
  isProject?: boolean;
  loading: boolean;
  children?: React.ReactNode;
  rightSection?: React.ReactNode;
  onThemeChange?: BrandingThemeModalProps['onThemeSave'];
}

const CommonFolderHeaderLayout = ({
  theme,
  breadcrumbs,
  children,
  loading,
  inFolder,
  isProject,
  rightSection,
  onThemeChange,
}: CommonFolderHeaderLayoutProps) => {
  const intl = useIntl();

  const isBetaPeriodOver = useIsBetaGracePeriodOver();
  const showBetaBanner = useIsUserGrandfatheredFromBeta() && !isBetaPeriodOver;
  const makeLogEvent = useBrowseLogEventMaker();
  const logEvent = React.useMemo(() => makeLogEvent({reelObjectType: 'folder'}), [makeLogEvent]);

  const betaBannerText = intl.formatMessage({
    defaultMessage:
      'As a valued Beta user,  you already have access to the Dropbox Replay Add-On. No need to do anything else until June 25, 2023.',
    id: 'xwAOKb',
    description:
      'Banner text for users who participated in the beta and are getting two months of Replay access as a thank-you',
  });

  const learnMoreText = intl.formatMessage({
    defaultMessage: 'Learn more',
    id: 'ofmhrA',
    description: 'Text for button that sends user to a page to learn more about the Replay add-on.',
  });

  const folderHeaderSection = (
    <FolderHeader
      breadcrumbs={breadcrumbs}
      inFolder={inFolder}
      isProject={isProject}
      loading={loading}
      onThemeChange={onThemeChange}
      rightSection={rightSection}
      theme={theme}
    >
      {children}
    </FolderHeader>
  );

  if (loading) {
    return folderHeaderSection;
  }

  return (
    <>
      <RestrictedBanner inFolder={inFolder} />
      {showBetaBanner && (
        <Banner
          style={{
            marginTop: '8px',
            minHeight: 'auto',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
          type="attention"
          withLeftIcon={true}
        >
          <Banner.Message>{betaBannerText}</Banner.Message>
          <Banner.Actions>
            <Button
              href="https://help.dropbox.com/installs/dropbox-replay-add-on"
              onClick={() => {
                // eslint-disable-next-line deprecation/deprecation
                logEvent('select_beta_banner_learn_more');
              }}
              target="_blank"
              variant="transparent"
            >
              {learnMoreText}
            </Button>
          </Banner.Actions>
        </Banner>
      )}
      {folderHeaderSection}
    </>
  );
};

export interface BrowsePageFolderHeaderProps {
  routeName: NavigationRouteTypes;
  hasShareButton: boolean;
  viewType: ViewType;
  makeFolderLink: BrowseItemsProps['makeFolderLink'];
  onRefreshFolders: (folderId?: string) => void;
  onCreateFolderSuccess: (folderToShow: string, level: number) => Promise<void>;
  onAddVideoFromDropbox: (
    files: ChooserFile[],
    clickSource: AddMediaClickSourceType,
    level: number,
  ) => Promise<void>;
  onFileUploadComplete: (
    click_source: AddMediaClickSourceType,
    level: number,
    projectId: string,
    videoId: string,
    name: string,
  ) => void;
  onAllUploadsComplete: AllUploadsCompleteHandler;
  onAddVideoError: () => void;
  onFilePick: (files: BasicFileInfo[]) => void;
  onOpenAddPeopleModal: (sharedFolderId: string, sharedFolderName: string) => void;
  shareRecipients?: FolderToAccounts;
  allowSuperAdminRights?: boolean;
}

export const BrowsePageFolderHeader = ({
  hasShareButton,
  viewType,
  makeFolderLink,
  onRefreshFolders,
  onCreateFolderSuccess,
  onAddVideoFromDropbox,
  onFileUploadComplete,
  onAllUploadsComplete,
  onAddVideoError,
  onFilePick,
  onOpenAddPeopleModal,
  routeName,
  shareRecipients,
  allowSuperAdminRights,
}: BrowsePageFolderHeaderProps) => {
  const {
    breadcrumbPath,
    currentFolderId,
    currentFolderName: currentFolderNameProp,
    currentFolderPermission,
    currentProjectAmplitudeId,
    projectFolderInfo: projectFolderInfoProp,
    setCurrentProjectId,
    setProjectFolderInfo,
  } = useFoldersSharedState();
  const {pathname} = useLocation();
  const [isProjectLevel, setIsProjectLevel] = React.useState<boolean>(false);
  const updateProject = useUpdateProjectMutation();

  const intl = useIntl();

  const currentFolderName = React.useMemo(() => {
    if (breadcrumbPath && breadcrumbPath.length > 1 && currentFolderNameProp) {
      return currentFolderNameProp;
    } else {
      if (routeName === 'browse-shared') {
        return intl.formatMessage(appStrings.sharedTitle);
      } else if (routeName === 'admin-page') {
        return intl.formatMessage(appStrings.adminTitle);
      } else {
        return intl.formatMessage(appStrings.homeTitle);
      }
    }
  }, [breadcrumbPath, currentFolderNameProp, intl, routeName]);

  const inFolder = pathname.includes('/folder/');
  const inSharedWithYou = pathname.startsWith('/shared');
  const level = breadcrumbPath ? breadcrumbPath.length : 1;
  const projectFolderInfo: ProjectFolderInfo = {
    name: projectFolderInfoProp?.name ?? '',
    description: projectFolderInfoProp?.description ?? '',
    projectFolderId: projectFolderInfoProp?.projectFolderId ?? '',
    branding: projectFolderInfoProp?.branding ?? emptyBranding,
    folderLinks: projectFolderInfoProp?.folderLinks ?? [],
  };

  const isLoading = inFolder && (!Boolean(breadcrumbPath) || !projectFolderInfo.projectFolderId);

  React.useEffect(() => {
    const isCurrentlyProjectLevel = Boolean(breadcrumbPath && breadcrumbPath.length === 2);
    if (isCurrentlyProjectLevel) {
      setCurrentProjectId(currentFolderId);
    }
    // This solution serves as a temporary fix, and we should revisit the core issue—our breadcrumb logic is incorrect in this specific scenario—at a later time.
    // However, it's important to implement this change now to resolve the current user issue. With this update,
    // super admins will be able to view membership controls even in subfolders of a team project.
    if (!isCurrentlyProjectLevel && currentFolderPermission === 'super_admin') {
      setIsProjectLevel(!isCurrentlyProjectLevel);
    } else {
      setIsProjectLevel(isCurrentlyProjectLevel);
    }
  }, [inFolder, breadcrumbPath, currentFolderId, setCurrentProjectId, currentFolderPermission]);

  const showAddVideoButton =
    (viewType === ViewType.BROWSE || inFolder) && !(viewType === ViewType.ADMIN);
  const permission = isProjectLevel ? currentFolderPermission : undefined;
  const hasEditPermission = inFolder && !inSharedWithYou;

  const handleFolderRename = (newFolderName: string) => {
    setProjectFolderInfo({name: newFolderName});
  };

  const handleFolderDescriptionRename = (newFolderDescription: string) => {
    setProjectFolderInfo({...projectFolderInfo, description: newFolderDescription});
  };

  const updateProjectBranding = async (branding: Branding) => {
    const update = await updateProject.mutateAsync({
      folderId: projectFolderInfo.projectFolderId,
      branding,
    });
    setProjectFolderInfo({
      description: update?.description ?? projectFolderInfo.description,
      name: update?.name ?? projectFolderInfo.name,
      projectFolderId: update?.id ?? projectFolderInfo.projectFolderId,
      branding: (update?.branding as Branding) ?? projectFolderInfo.branding,
    });
  };

  const handleLogoChange = async (logo: BrandingLogo) => {
    const theme = projectFolderInfo.branding?.theme.type
      ? projectFolderInfo.branding.theme
      : emptyBranding.theme;
    await updateProjectBranding({theme, logo});
  };

  const handleThemeChange = async (theme: BrandingTheme) => {
    const logo = projectFolderInfo.branding?.logo.type
      ? projectFolderInfo.branding.logo
      : emptyBranding.logo;
    await updateProjectBranding({theme, logo});
  };

  const addFolderLink = async (folderLink: FolderLink) => {
    // Right now the folderLinks api is built to return an array of FolderLink objects,
    // but we only want to create one link at a time right now. We will limit
    // the number of links a user can create to 1 for now.
    if (projectFolderInfo.folderLinks.length >= 1) {
      return;
    }
    const url = folderLink.url;
    const linkName = folderLink.name;
    if (projectFolderInfo.folderLinks.length === 0) {
      const newLink = await createFolderLink({
        folderId: projectFolderInfo.projectFolderId,
        url,
        linkName,
      });
      if (newLink.folder_links) {
        setProjectFolderInfo({
          ...projectFolderInfo,
          folderLinks: newLink.folder_links,
        });
      }
    }
  };

  const deleteLink = async () => {
    // Right now the folderLinks api is built to return an array of FolderLink objects,
    // but we only want to delete one link at a time right now. We will limit
    // the number of links a user can add to 1 for now.
    if (projectFolderInfo.folderLinks.length === 1) {
      const linkId = projectFolderInfo.folderLinks[0].folder_link_id;
      if (linkId) {
        const remainingLinks = await deleteFolderLink({
          folderId: projectFolderInfo.projectFolderId,
          linkId,
        });
        setProjectFolderInfo({
          ...projectFolderInfo,
          folderLinks: remainingLinks.folder_links,
        });
      }
    }
  };

  const shouldShowShareButton = inFolder && hasShareButton;
  const shouldShowAddPeopleButton = currentFolderPermission !== 'reviewer' && isProjectLevel;

  const isViewOnly = currentFolderPermission === 'reviewer';
  const actionButtons = (
    <ActionButtons
      accessLevel={currentFolderPermission ?? 'other'}
      addVideoFromDropboxCallback={onAddVideoFromDropbox}
      allowSuperAdminRights={allowSuperAdminRights}
      currentFolderId={currentFolderId}
      currentFolderName={currentFolderName || 'Unknown'}
      folderLevel={level}
      onAddVideoErrorCallback={onAddVideoError}
      onAllUploadsCompleteCallback={onAllUploadsComplete}
      onCreateFolderSuccessCallback={onCreateFolderSuccess}
      onFilePickCallback={onFilePick}
      onFileUploadCompleteCallback={onFileUploadComplete}
      openAddPeopleModal={onOpenAddPeopleModal}
      settingsChangeCallback={onRefreshFolders}
      shareRecipients={
        shareRecipients && shareRecipients[currentFolderId]
          ? [
              ...Object.values(shareRecipients[currentFolderId].userAccounts),
              ...Object.values(shareRecipients[currentFolderId].groupAccounts),
            ]
          : 'loading'
      }
      showAddPeopleButton={shouldShowAddPeopleButton}
      showAddVideoButton={
        ['admin', 'owner', 'super_admin', 'none'].includes(currentFolderPermission ?? 'other') &&
        showAddVideoButton
      }
      showCreateFolderButton={
        currentFolderPermission !== 'reviewer' && (!inSharedWithYou || inFolder)
      }
      showDownloadFolderButton={isProjectLevel}
      showManagePeopleButton={currentFolderPermission !== 'none' && isProjectLevel}
      showShareButton={
        shouldShowShareButton && isProjectLevel
          ? 'team_project'
          : shouldShowShareButton
          ? 'standard'
          : 'none'
      }
    />
  );

  return (
    <CommonFolderHeaderLayout
      breadcrumbs={
        breadcrumbPath ? (
          <Breadcrumbs
            breadcrumbPath={breadcrumbPath.map((x) => ({id: x.id, name: x.name}))}
            currentProjectAmplitudeId={currentProjectAmplitudeId}
            makeFolderLink={makeFolderLink}
            viewType={viewType}
          />
        ) : null
      }
      inFolder={inFolder}
      isProject={inFolder && level === 2}
      loading={isLoading}
      onThemeChange={handleThemeChange}
      rightSection={actionButtons}
      theme={projectFolderInfo.branding.theme}
    >
      {inFolder ? (
        <ProjectInfo>
          <HeaderLogo
            branding={projectFolderInfo.branding}
            folderId={currentFolderId}
            isViewOnly={!hasEditPermission}
            onLogoChange={handleLogoChange}
          />
          <ProjectDetails>
            <EditableTitle
              currentFolderPermission={permission ?? 'other'}
              disabled={!hasEditPermission}
              folderId={projectFolderInfo.projectFolderId}
              folderName={projectFolderInfo.name}
              handleFolderRename={handleFolderRename}
            >
              {level > 1 ? projectFolderInfo.name : currentFolderName}
            </EditableTitle>
            <EditableDescription
              disabled={!hasEditPermission}
              folderDescription={projectFolderInfo.description}
              folderId={projectFolderInfo.projectFolderId}
              handleFolderDescriptionRename={handleFolderDescriptionRename}
            >
              {projectFolderInfo.description}
            </EditableDescription>
            <FolderLinkButton
              addFolderLink={addFolderLink}
              deleteLink={deleteLink}
              isViewOnly={isViewOnly}
              projectFolderInfo={projectFolderInfo}
            />
          </ProjectDetails>
        </ProjectInfo>
      ) : (
        <PageTitle>{currentFolderName}</PageTitle>
      )}
    </CommonFolderHeaderLayout>
  );
};

/**
 * ShareFolderHeader
 */

const ProjectTitle = styled(Title)`
  margin: 0;
`;

const ProjectDescription = styled(Box)`
  margin-top: ${spacing('4')};
  min-height: ${spacing('32')};
  width: 100%;
  display: flex;
  align-items: center;

  ${breakpointSmall(css`
    margin-bottom: 0;
  `)};
`;

const ShareFolderHeaderActionButtons = styled.div<{$isSubfolder: boolean}>`
  margin-top: ${spacing('Micro Medium')};
  display: flex;
  grid-gap: ${spacing('Micro Small')};

  ${({$isSubfolder}) =>
    $isSubfolder &&
    breakpointMedium(
      css`
        margin-top: 0;
      `,
    )}
`;

const ProjectDescriptionText = styled(Text)``;

export type LoadedShareFolderHeaderProps = {
  accessLevel: ShareFolderAccessType;
  isViewOnlyLinkAccess: boolean;
  breadcrumbPath: reel.Folder[];
  makeFolderLink: BrowseItemsProps['makeFolderLink'];
  // currently visited folder data
  folderId: string;
  folderName: string;
  // whether the current folder is within another folder
  isSubfolder?: boolean;
  // wrapping project info, if any
  isProject?: boolean;
  projectName?: string;
  projectDescription?: string;
  branding?: Branding;
  downloadsEnabled: boolean;
  shareToken?: string;
};

const LoadedShareFolderHeader = (props: LoadedShareFolderHeaderProps) => {
  const {
    accessLevel,
    folderId,
    folderName,
    isViewOnlyLinkAccess,
    breadcrumbPath,
    makeFolderLink,
    projectName,
    projectDescription,
    branding,
    isSubfolder,
    isProject,
    downloadsEnabled,
    shareToken,
  } = props;

  const makeLogEvent = useBrowseLogEventMaker();
  const grantBook = useGrantBook(shareToken);

  const logEvent = React.useMemo(() => makeLogEvent(), [makeLogEvent]);
  const papAccessType = useGetPapAccessType({accessLevel: accessLevel});

  return (
    <FolderHeader
      breadcrumbs={
        breadcrumbPath ? (
          <Breadcrumbs
            breadcrumbPath={breadcrumbPath.map((x) => ({id: x.id, name: x.name}))}
            makeFolderLink={makeFolderLink}
            viewType={ViewType.SHARED}
          />
        ) : null
      }
      inFolder={!!isSubfolder}
      isProject={!!isProject}
      isSharedView
      loading={false}
      rightSection={
        <ShareFolderHeaderActionButtons $isSubfolder={!!isSubfolder}>
          {downloadsEnabled && (
            <DownloadFolderButtonAndModal
              analyticsData={{
                actionElement: 'toolbar',
                folderLevel: breadcrumbPath.length,
                accessType: papAccessType,
              }}
              buttonVariant="opacity"
              displayType="expanded"
              downloadOptions={[{'.tag': 'latest_files'}, {'.tag': 'approved_files'}]}
              folderId={folderId}
              grantBook={grantBook}
              shareToken={shareToken}
            />
          )}
          <ShareFolderButton
            accessLevel={accessLevel}
            buttonVariant="opacity"
            folderId={folderId}
            folderName={folderName}
            isTeamFolder={false}
            isViewOnlyLinkAccess={isViewOnlyLinkAccess}
            logEvent={logEvent}
          />
        </ShareFolderHeaderActionButtons>
      }
      theme={branding?.theme}
    >
      <ProjectInfo>
        <HeaderLogo branding={props.branding} folderId={folderId} isViewOnly />
        <Box width="100%">
          <ProjectTitle size="medium">{isProject ? projectName : folderName}</ProjectTitle>
          {isProject && projectDescription ? (
            <ProjectDescription>
              <ProjectDescriptionText>{projectDescription}</ProjectDescriptionText>
            </ProjectDescription>
          ) : null}
        </Box>
      </ProjectInfo>
    </FolderHeader>
  );
};

type ShareFolderHeaderProps = {loading: true} | ({loading: false} & LoadedShareFolderHeaderProps);

export const ShareFolderHeader = (props: ShareFolderHeaderProps) => {
  if (props.loading) {
    return (
      <CommonFolderHeaderLayout breadcrumbs={null} inFolder={true} loading={true}>
        <TitleRow>
          <PageTitle></PageTitle>
        </TitleRow>
      </CommonFolderHeaderLayout>
    );
  }

  return <LoadedShareFolderHeader {...props} />;
};

type AdminPageFolderHeaderProps = {
  title: string;
  loading?: boolean;
};

export const AdminPageFolderHeader = ({loading = false, title}: AdminPageFolderHeaderProps) => {
  return (
    <CommonFolderHeaderLayout breadcrumbs={null} inFolder={false} loading={loading}>
      <TitleRow>
        <PageTitle>{title}</PageTitle>
      </TitleRow>
    </CommonFolderHeaderLayout>
  );
};
