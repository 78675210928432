import {atom} from 'jotai';
import {atomWithRefresh, loadable} from 'jotai/utils';

import {getUserSpaceUsage} from '~/lib/api';
import {hydratable} from '~/state/jotai_utils';

// atomWithRefresh provides a setter function that, when called with no parameters will refetch the atom's value.
// Used when a file is uploaded or deleted to update the user's space usage all over the app.
// See upload_context.tsx for an example.
export const refetchableUserSpaceAtom = atomWithRefresh(async () => {
  const {status, result} = await getUserSpaceUsage();

  if (status === 200) {
    return result;
  }

  throw new Error('Failed to get user space usage');
});

export const userSpaceAtom = hydratable(refetchableUserSpaceAtom);

export interface UserQuota {
  used: number;
  hasLargeStorage: boolean;
  allocated?: number;
  percentUsed?: number;
  isNearQuota: boolean;
  isAtQuota: boolean;
}

const ONE_GB = 1024 * 1024 * 1024;
const ONE_TB = 1024 * ONE_GB;

const BIG_STORAGE_REMAINING_WARNING = 300 * ONE_GB; // 300 GB
const BIG_STORAGE_QUOTA = 15 * ONE_TB; // 15TB

const userQuotaAtom = atom<Promise<UserQuota>>(async (get) => {
  const userSpace = await get(userSpaceAtom);

  const allocation = userSpace?.allocation;
  let used = userSpace?.used ?? 0;
  let allocated;

  if (allocation && allocation['.tag'] !== 'other') {
    allocated = allocation.allocated;
  }

  if (allocation && allocation['.tag'] === 'team') {
    used = allocation.used;

    // If user is MSL-team member (member space limit), the currently used space
    // comes from the external `used` field (representing user usage, not team's).
    // See users_info.py#async_handle_get_space_usage, in `user_uses_msl_quota` section.
    // https://dropbox.sourcegraphcloud.com/github.com/dropbox-internal/server@ea34fe79b28ab7afeedf346759a1c35f6aa5cbd9/-/blob/metaserver/controllers/api_v2/users_info.py?L1114
    const userAllocation = allocation.user_within_team_space_allocated;
    if (userAllocation > 0) {
      allocated = userAllocation;
      used = userSpace.used;
    }
  }

  const percentUsed = allocated ? (used / allocated) * 100 : undefined;
  const remaining = allocated ? allocated - used : undefined;
  const hasLargeStorage = Boolean(allocated && allocated >= BIG_STORAGE_QUOTA);

  return {
    used,
    allocated,
    percentUsed,
    hasLargeStorage,
    isNearQuota:
      hasLargeStorage && remaining
        ? remaining <= BIG_STORAGE_REMAINING_WARNING
        : Boolean(percentUsed && percentUsed >= 90),
    isAtQuota: Boolean(percentUsed && percentUsed >= 100),
  };
});

export const loadableUserQuotaAtom = loadable(userQuotaAtom);

// To determine if a user has seen the file limit upsell modal
export const SEEN_FILE_LIMIT_UPSELL_MODAL = 'seen_file_limit_upsell_modal';

export const isPostPurchaseModalOpenAtom = atom<boolean>(false);
