/** Initiate a download of a URL or an in-memory blob to a given filename */
export function download(opts: {data: Blob; filename: string} | {url: string; filename?: string}) {
  const anchor = document.createElement('a');
  if ('url' in opts) {
    anchor.href = opts.url;
    anchor.download = opts.filename ?? '';
    anchor.click();
  } else {
    const url = URL.createObjectURL(opts.data);
    anchor.href = url;
    anchor.download = opts.filename;
    anchor.click();
    URL.revokeObjectURL(url);
  }
}
