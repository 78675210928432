import React from 'react';

import {FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';

import type {reel} from '@dropbox/api-v2-client';
import {Modal} from '@dropbox/dig-components/modal';
import {Tooltip} from '@dropbox/dig-components/tooltips';
import {Truncate} from '@dropbox/dig-components/truncate';
import {Text, Title} from '@dropbox/dig-components/typography';

import {Button} from '~/components/button';
import type {ItemType} from '~/components/common';
import {changeOwnership} from '~/lib/api';
import type {TeamContactInfo} from '~/lib/api';

import {DropboxAccountTypeahead} from './dropbox_account_typeahead';
import {getCurrentUserAccountId, useReelAppGlobalState} from '../context';
import {ReplayError, ReplayErrorCategory, reportException} from '../lib/error_reporting';
import type {LoggingClient} from '../lib/logging/logger';

export type TransferOwnershipModalProps = {
  open: boolean;
  projectName: string;
  projectId: string;
  isHide: boolean;
  requestClose: () => void;
  onTransfer: (accountId: string) => void;
  logEvent: LoggingClient['logEvent'];
  itemType: ItemType;
  isOwner: boolean;
  currentOwnerAccountId: string;
  versionNumber?: number;
};

const TransferOwnershipTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

const ModalErrorTextContainer = styled.div`
  margin-top: 8px;
`;

const ModalButtons = styled.div`
  display: flex;
`;

const ModalErrorText = styled(Text)`
  margin-top: 12px;
  color: var(--dig-color__alert__on-surface);
  font-size: 12px;
`;

export const TransferOwnershipModal = (props: TransferOwnershipModalProps) => {
  const {open, projectName, projectId, onTransfer, requestClose, logEvent, currentOwnerAccountId} =
    props;

  const intl = useIntl();
  const sessionContext = useReelAppGlobalState();
  const currentUserAccountId = getCurrentUserAccountId(sessionContext);

  const [contactInfos, setContactInfos] = React.useState<TeamContactInfo[]>([]);
  const [errorText, setErrorText] = React.useState('');
  const [isSaving, setIsSaving] = React.useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = React.useState(false);
  const [isTooltipVisible, setIsTooltipVisible] = React.useState(false);
  const [showEmailDisabledTooltip, setShowEmailDisabledTooltip] = React.useState<boolean>(false);
  const emailEntryRef = React.useRef(null);
  const saveButtonRef = React.useRef(null);

  React.useEffect(() => {
    const numErrors = contactInfos.filter((contactInfo) => !contactInfo.valid).length;
    if (numErrors === 0) {
      setErrorText('');
    }

    // When we're at one contact to share, disable the button with the message
    // that you can only transfer ownership to one person.
    setIsSaveDisabled(contactInfos.length != 1);
  }, [contactInfos]);

  const setContacts = (updatedContacts: TeamContactInfo[]) => {
    setContactInfos(updatedContacts);
  };

  const closeModal = () => {
    setContactInfos([]);
    requestClose();
    setErrorText('');
  };

  const withCloseButtonText = intl.formatMessage({
    defaultMessage: 'Close transfer ownership modal',
    id: 'lMPkVr',
    description: 'A message shown as a label for modal that will close the modal.',
  });

  const transferOwnershipModalTitle = intl.formatMessage(
    {
      defaultMessage: 'Transfer ownership for "{name}"',
      id: 'XOZob0',
      description: 'Title for modal for transfering ownership of media project',
    },
    {name: projectName},
  );

  const transferEmailPlaceholderText = intl.formatMessage({
    defaultMessage: 'Transfer ownership to email address',
    id: 'raVvjC',
    description:
      'Placeholder text for the field used to enter email addresses in the transfer ownership modal.',
  });

  const transferEmailAriaLabelText = intl.formatMessage({
    defaultMessage: 'Add email address',
    id: 'aVWvo8',
    description:
      'Aria label text for a field for entering email addresses in the transfer ownership modal.',
  });

  const transferOwnershipDisabled = intl.formatMessage({
    defaultMessage: "You're not able to transfer ownership for this project.",
    id: 'FDLe4l',
    description:
      'Tooltip text indicating to a user that they cannot transfer ownership of the current media project.',
  });

  // Disabled tool tips
  const transferOwnershipDisabledNoTarget = intl.formatMessage({
    defaultMessage: 'Select a recipient to receive ownership of the project.',
    id: 'ygGtdo',
    description: 'Tooltip text indicating a single recipient is required for ownership transfer.',
  });

  const transferOwnershipDisabledOnlyOne = intl.formatMessage({
    defaultMessage: "You're only able to transfer ownership to a single user.",
    id: 'smkyyH',
    description:
      'Tooltip text that indicates there must only be one recipient to transfer the ownership to.',
  });

  const transferOwnershipDisabledTargetIsOwner = intl.formatMessage({
    defaultMessage:
      'The target contact is the current owner of the project. Please select another.',
    id: 'BC3lbo',
    description:
      'Tooltip text that indicates the selected target is already the owner of the project.',
  });

  const transferOwnershipDisabledGeneric = intl.formatMessage({
    defaultMessage: "You're only able to transfer ownership for this project.",
    id: 'qx1/Cq',
    description:
      'Tooltip text that indicates there is some reason that we cannot transfer ownership.',
  });

  const transferOwnershipMissingAccount = intl.formatMessage({
    defaultMessage: 'There was a problem finding account details for that email.',
    id: 'UOZ5JJ',
    description: 'Error text for when the share link settings fail to save.',
  });

  const transferOwnershipError = intl.formatMessage({
    defaultMessage: 'There was a problem transferring the ownership.',
    id: 'TJNjIO',
    description: 'Error text for when the share link settings fail to save.',
  });

  const onSave = async () => {
    const targetContact = contactInfos[0];
    if (targetContact.type === 'group' || !targetContact.accountId) {
      setErrorText(transferOwnershipMissingAccount);
      setIsSaving(false);
      return;
    }

    // eslint-disable-next-line deprecation/deprecation
    logEvent('save_reel_admin_transfer_ownership');
    setIsSaving(true);

    try {
      const entityType: reel.ChangeOwnershipRequestEntityType = {
        '.tag': 'media',
      };
      const shouldTakeOwnership = currentUserAccountId === targetContact.accountId;
      await changeOwnership(projectId, entityType, targetContact.accountId, shouldTakeOwnership);
    } catch (e) {
      reportException(
        new ReplayError({
          severity: 'non-critical',
          error: e,
          message: 'Failed to transfer media project ownership',
          // Label this as uncaught since the error may have been from the API
          // or from preparing the request
          category: ReplayErrorCategory.UncaughtException,
        }),
      );
      setErrorText(transferOwnershipError);
    }
    setIsSaving(false);
    onTransfer(currentUserAccountId);
    requestClose();
  };

  return (
    <>
      <Modal
        isCentered
        onRequestClose={closeModal}
        open={open}
        width="standard"
        withCloseButton={withCloseButtonText}
      >
        <Modal.Header hasBottomSpacing="title-small">
          <TransferOwnershipTitle>
            <Title size="small">
              <Truncate location={0.5}>{transferOwnershipModalTitle}</Truncate>
            </Title>
          </TransferOwnershipTitle>
        </Modal.Header>
        <Modal.Body>
          <Tooltip.Control
            auto
            maxWidth={275}
            open={showEmailDisabledTooltip}
            placement="top"
            triggerRef={emailEntryRef}
            variant="basic"
          >
            {transferOwnershipDisabled}
          </Tooltip.Control>
          <div
            onMouseEnter={() => setShowEmailDisabledTooltip(false)}
            onMouseLeave={() => setShowEmailDisabledTooltip(false)}
          >
            <div ref={emailEntryRef}>
              <DropboxAccountTypeahead
                autofocus={false}
                initialContacts={undefined}
                inputLabelText={transferEmailAriaLabelText}
                inputPlaceholderText={transferEmailPlaceholderText}
                setContacts={setContacts}
                type="team"
              />
            </div>
          </div>
          <ModalErrorTextContainer>
            {errorText && <ModalErrorText>{errorText}</ModalErrorText>}
          </ModalErrorTextContainer>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              // eslint-disable-next-line deprecation/deprecation
              logEvent('cancel_reel_admin_transfer_ownership');
              closeModal();
            }}
            size="standard"
            type="button"
            variant="opacity"
          >
            <FormattedMessage
              defaultMessage="Cancel"
              description="Cancel button for the Share Link Settings modal."
              id="PFzhWE"
            />
          </Button>
          <Tooltip.Control
            auto
            open={isTooltipVisible && isSaveDisabled && saveButtonRef.current !== null}
            placement="top"
            triggerRef={saveButtonRef}
            variant="basic"
          >
            {contactInfos.length === 0
              ? transferOwnershipDisabledNoTarget
              : contactInfos.length !== 1
              ? transferOwnershipDisabledOnlyOne
              : contactInfos.length === 1 &&
                contactInfos[0].type === 'user' &&
                contactInfos[0].accountId === currentOwnerAccountId
              ? transferOwnershipDisabledTargetIsOwner
              : transferOwnershipDisabledGeneric}
          </Tooltip.Control>
          <ModalButtons
            onMouseEnter={() => setIsTooltipVisible(true)}
            onMouseLeave={() => setIsTooltipVisible(false)}
          >
            <Button
              disabled={isSaveDisabled}
              onClick={onSave}
              ref={saveButtonRef}
              type="submit"
              variant="primary"
            >
              {isSaving ? (
                <FormattedMessage
                  defaultMessage="Saving..."
                  description="Save button text while saving the settings is in progress."
                  id="HnpJR/"
                />
              ) : (
                <FormattedMessage
                  defaultMessage="Save"
                  description="Save button for the Share Link Settings modal."
                  id="b36ncv"
                />
              )}
            </Button>
          </ModalButtons>
        </Modal.Footer>
      </Modal>
    </>
  );
};
