import type {reel} from '@dropbox/api-v2-client';

import {STEPS} from '~/state/onboarding_v2';

const ATTRS_TO_OMIT = [
  `has_completed_${STEPS.COMMENT_ON_FILE}_step`,
  `has_completed_${STEPS.DRAW_ON_FILE}_step`,
  `has_completed_${STEPS.SHARE_FILE}_step`,
  `has_completed_${STEPS.UPLOAD_FILE}_step`,
  `has_completed_${STEPS.VIEW_FILE}_step`,
  'has_completed_onboarding_v2',
  'onboarding_v2_type',
  'first_video_added',
  'dismissed_welcome_modal',
  'integration_premierepro',
  'integration_aftereffects',
  'integration_davinci',
  'integration_protools',
];

export const hasCompletedAnyPreviousOnboardingActions = (
  onboardingActions: reel.OnboardingActions,
): boolean => {
  for (const [attr, value] of Object.entries(onboardingActions)) {
    if (!ATTRS_TO_OMIT.includes(attr) && !!value) {
      return true;
    }
  }

  return false;
};
