import {atom} from 'jotai';
import {focusAtom} from 'jotai-optics';

export enum STEPS {
  VIEW_FILE = 'view_file',
  DRAW_ON_FILE = 'draw_on_file',
  COMMENT_ON_FILE = 'comment_on_file',
  UPLOAD_FILE = 'upload_file',
  SHARE_FILE = 'share_file',
}

export enum ONBOARDING_TYPE {
  AUDIO_WITH_FILE = 'audio_with_file',
  IMAGE_WITH_FILE = 'image_with_file',
  VIDEO_WITH_FILE = 'video_with_file',
  VIDEO_WITHOUT_FILE = 'video_without_file',
}

export enum ONBOARDING_OPEN_SOURCE {
  PERCENT_COMPLETE_BUTTON = 'percent_complete_button',
  STEP_COMPLETION = 'step_completion',
  WELCOME_MODAL = 'welcome_modal',
}

export type OnboardingSteps = {
  [key in STEPS]: boolean;
};

export type OnboardingState = {
  hasCompletedOnboarding: boolean;
  onboardingType: ONBOARDING_TYPE | null;
  onboardingSteps: OnboardingSteps;
};

// Things we save to BE
export const defaultOnboardingState: OnboardingState = {
  hasCompletedOnboarding: false,
  onboardingType: null,
  onboardingSteps: {
    [STEPS.VIEW_FILE]: false,
    [STEPS.DRAW_ON_FILE]: false,
    [STEPS.COMMENT_ON_FILE]: false,
    [STEPS.UPLOAD_FILE]: false,
    [STEPS.SHARE_FILE]: false,
  },
};

export const onboardingStateAtom = atom<OnboardingState>(defaultOnboardingState);
export const onboardingStepsAtom = focusAtom(onboardingStateAtom, (optic) =>
  optic.prop('onboardingSteps'),
);
export const onboardingTypeAtom = focusAtom(onboardingStateAtom, (optic) =>
  optic.prop('onboardingType'),
);
export const hasCompletedOnboardingAtom = focusAtom(onboardingStateAtom, (optic) =>
  optic.prop('hasCompletedOnboarding'),
);

export const completedStepsAtom = atom<STEPS[]>([]);
export const currOpenStepAtom = atom<STEPS | null>(null);
export const stepsToDoAtom = atom<STEPS[]>([]);
export const isChecklistOpenAtom = atom<boolean>(false);
export const isWelcomeModalOpenAtom = atom<boolean>(false);
export const hasCheckedForOnboardingRedirectAtom = atom<boolean>(false);
export const lastCompletedStepAtom = atom<STEPS | null>(null);
export const shouldFillInOnboardingCommentAtom = atom<boolean>(false);
export const hasCompletedAnyPreviousOnboardingActionsAtom = atom<boolean>(false);

// Atom called to update the state of onboarding steps. Primarily whenever
// someone completes a step
export const setOnboardingStepsAtom = atom(null, (get, set, onboardingSteps: OnboardingSteps) => {
  set(onboardingStepsAtom, onboardingSteps);

  const completed: STEPS[] = [];
  for (const step in onboardingSteps) {
    if (onboardingSteps[step as STEPS]) {
      completed.push(step as STEPS);
    }
  }
  set(completedStepsAtom, completed);

  const hasCompletedOnboarding = get(hasCompletedOnboardingAtom);
  const onboardingType = get(onboardingTypeAtom);
  const stepsToDo = get(stepsToDoAtom);

  if (!hasCompletedOnboarding && onboardingType) {
    const nextStepToComplete = stepsToDo.filter((step) => !completed.includes(step))[0];
    set(currOpenStepAtom, nextStepToComplete);
    return nextStepToComplete;
  }
  return null;
});

// Specific Atom that will set the steps when we get a type. 2 cases:
// 1. When we load a previous onboarding state
// 2. Someone goes through "with file" onboarding flow
export const setOnboardingTypeAndStepsAtom = atom(
  null,
  (get, set, {type, stepsToDo}: {type: ONBOARDING_TYPE; stepsToDo: STEPS[]}) => {
    const hasCompletedOnboarding = get(hasCompletedOnboardingAtom);
    const onboardingType = get(onboardingTypeAtom);

    if (!hasCompletedOnboarding && !onboardingType && type) {
      set(onboardingTypeAtom, type);
      set(stepsToDoAtom, stepsToDo);

      const completed = get(completedStepsAtom);
      const nextStepToComplete = stepsToDo.filter((step) => !completed.includes(step))[0];
      set(currOpenStepAtom, nextStepToComplete);
    }
  },
);
