import {atom} from 'jotai';
import filter from 'lodash/filter';
import isEqual from 'lodash/isEqual';

import type {
  MappedUploads,
  UploadInfo,
  UploadLoggingData,
  UploadLoggingDataMap,
  UploadProgress,
  UploadsProgress,
  UploadsVersionSummaries,
} from '~/lib/uploads/types';

export const uploadsloggingDataAtom = atom<UploadLoggingDataMap>({});
export const uploadsAtom = atom<MappedUploads>({});
export const uploadsProgressAtom = atom<UploadsProgress>({});
export const versionSummariesAtom = atom<UploadsVersionSummaries>({});
export const isUploadDrawerShownAtom = atom<boolean>(false);
export const isUploadDrawerOpenAtom = atom<boolean>(false);
export const currentUploadBatchIdsAtom = atom<string[]>([]);
export const viewNewUploadFunctionAtom = atom<{fn: ((upload: UploadInfo) => void) | null}>({
  fn: null,
});

export const handleUpdateUploadDrawerUploadsAtom = atom(
  null,
  (get, set, newUploads: MappedUploads) => {
    const currentUploads = get(uploadsAtom);
    set(uploadsAtom, {...currentUploads, ...newUploads});

    const currentUploadBatchIds = get(currentUploadBatchIdsAtom);
    const newUploadBatchIds = Object.keys(newUploads);
    set(currentUploadBatchIdsAtom, newUploadBatchIds);

    const shownUploads = filter(newUploads, (upload, _id) => !upload.hideDrawer);
    const shouldOpenDrawer = shownUploads.length > 0;

    // once we add or update files in the upload drawer, maker sure we show it
    const currentIsUploadDrawerShown = get(isUploadDrawerShownAtom);
    if (shouldOpenDrawer && !currentIsUploadDrawerShown) {
      set(isUploadDrawerShownAtom, true);
    }

    // reopen the upload drawer if there are new uploads that come in
    if (shouldOpenDrawer && !isEqual(currentUploadBatchIds, shownUploads)) {
      set(isUploadDrawerOpenAtom, true);
    }
  },
);

export const handleUpdateUploadDrawerUploadByIdAtom = atom(
  null,
  (get, set, {uploadId, updatedUpload}: {uploadId: string; updatedUpload: Partial<UploadInfo>}) => {
    const currentUploads = get(uploadsAtom);
    set(uploadsAtom, {
      ...currentUploads,
      [uploadId]: {
        ...currentUploads[uploadId],
        ...updatedUpload,
      } as UploadInfo,
    });
  },
);

export const handleUpdateUploadDrawerProgressAtom = atom(
  null,
  (get, set, newUploadsProgress: UploadsProgress) => {
    const currentUploadsProgress = get(uploadsProgressAtom);
    set(uploadsProgressAtom, {...currentUploadsProgress, ...newUploadsProgress});
  },
);

export const handleUpdateUploadDrawerProgressByIdAtom = atom(
  null,
  (
    get,
    set,
    {
      uploadId,
      updatedUploadProgress,
    }: {uploadId: string; updatedUploadProgress: Partial<UploadProgress>},
  ) => {
    const currentUploadsProgress = get(uploadsProgressAtom);
    set(uploadsProgressAtom, {
      ...currentUploadsProgress,
      [uploadId]: {
        ...currentUploadsProgress[uploadId],
        ...updatedUploadProgress,
      } as UploadProgress,
    });
  },
);

export const handleInflightLoggingDataAtom = atom(
  null,
  (
    get,
    set,
    {uploadId, loggingData}: {uploadId: string; loggingData: Partial<UploadLoggingData>},
  ) => {
    const currentInflightLoggingData = get(uploadsloggingDataAtom);
    set(uploadsloggingDataAtom, {
      ...currentInflightLoggingData,
      [uploadId]: {
        ...currentInflightLoggingData[uploadId],
        ...loggingData,
      } as UploadLoggingDataMap,
    });
  },
);

export const handleUpdateVersionSummariesAtom = atom(
  null,
  (get, set, newVersionSummaries: UploadsVersionSummaries) => {
    const currentVersionSummaries = get(versionSummariesAtom);
    set(versionSummariesAtom, {...currentVersionSummaries, ...newVersionSummaries});
  },
);
