export const STATUSES = [
  'NO_STATUS',
  'NEEDS_REVIEW',
  'IN_PROGRESS',
  'APPROVED',
  'EDITS_REQUESTED',
] as const;
export type VersionStatus = (typeof STATUSES)[number];

export type VersionStatusDetails = {
  key: VersionStatus;
  id: number;
  label: string;
  color: string;
  unmaskName?: string;
};

/*
 * Ideally we would retrieve the color token values using getComputedStyle, but
 * hardcoding them is more expedient because when this module code runs, the
 * values are not available. To fix, generate these properties in react and pass
 * them down via props or context.
 */
export const VERSION_STATUS_PROPERTIES: Record<VersionStatus, VersionStatusDetails> = {
  // --color__inverse__opacity--3
  NO_STATUS: {
    key: 'NO_STATUS',
    id: 0,
    label: 'No status',
    color: 'rgba(142, 139, 135, 0.32)',
    unmaskName: 'no-status-status-button',
  },
  // --color__accent__sunset
  NEEDS_REVIEW: {
    key: 'NEEDS_REVIEW',
    id: 1,
    label: 'Needs review',
    color: '#FA551E',
    unmaskName: 'needs-review-status-button',
  },
  // --color__accent__zen
  IN_PROGRESS: {
    key: 'IN_PROGRESS',
    id: 2,
    label: 'In progress',
    color: '#14C8EB',
    unmaskName: 'in-progress-status-button',
  },
  // --color__accent__lime
  APPROVED: {
    key: 'APPROVED',
    id: 3,
    label: 'Approved',
    color: '#B4DC19',
    unmaskName: 'approved-status-button',
  },
  // --color__accent__tangerine
  EDITS_REQUESTED: {
    key: 'EDITS_REQUESTED',
    id: 4,
    label: 'Edits requested',
    color: '#FF8C19',
    unmaskName: 'edits-requested-status-button',
  },
};

export const STATUS_UI_ORDER = [
  'NO_STATUS',
  'NEEDS_REVIEW',
  'IN_PROGRESS',
  'EDITS_REQUESTED',
  'APPROVED',
] as const;

export const getStatusFromId = (id?: number): VersionStatus => {
  if (typeof id === 'number' && STATUSES[id]) {
    return STATUSES[id];
  }
  return 'NO_STATUS';
};

export const getIdFromStatus = (status?: VersionStatus): number => {
  return status ? VERSION_STATUS_PROPERTIES[status].id : 0;
};
