import {FormattedMessage, useIntl} from 'react-intl';

import {Modal} from '@dropbox/dig-components/modal';
import {Text, Title} from '@dropbox/dig-components/typography';

import {Button} from '~/components/button';

type MaxDepthExceededProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

export const MaxDepthExceededModal = (props: MaxDepthExceededProps) => {
  const {open, setOpen} = props;
  const intl = useIntl();

  const errorMessage = intl.formatMessage({
    defaultMessage:
      'Replay supports a maximum of 12 levels of nested folders. Try reducing the number of levels, and uploading again.',
    id: 'E9QCF2',
    description:
      'Text shown in an error modal when uploading a folder would create too many levels of nested subfolders.',
  });

  const errorModalCloseText = intl.formatMessage({
    defaultMessage: 'OK',
    id: 'CYl0vC',
    description: 'Close button text for folder upload error modal.',
  });

  return (
    <Modal
      isCentered
      onRequestClose={() => setOpen(false)}
      open={open}
      withCloseButton={errorModalCloseText}
    >
      <Modal.Header hasBottomSpacing="title-standard">
        <Title>
          <FormattedMessage
            defaultMessage="Folder contains too many nested levels"
            description="Title for error modal that appears when the max folder depth is exceeded during an upload."
            id="pyDsH6"
          />
        </Title>
      </Modal.Header>
      <Modal.Body>
        <Text>{errorMessage}</Text>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setOpen(false)} variant="primary">
          {errorModalCloseText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
