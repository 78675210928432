import type {comments2_common, reel} from '@dropbox/api-v2-client';
import type {comments2, reactions} from '@dropbox/api-v2-client';

import {getVersionSummariesQuery} from '~/lib/api_queries';
import type {BoltInfo} from '~/lib/bolt_client';
import type {ReelVideoIdIdentifier} from '~/lib/comments/data/api';
import {Comments2Api, Comments2LoggedOutApi, ReactionsApi} from '~/lib/comments/data/api';
import type {
  ReplayComment,
  ReplayMention,
  ReplayRegion,
  ReplayThread,
  ReplayUser,
  TimeRange,
} from '~/pages/viewer_page/comments_view_types.d';
import {ReplayThreadType} from '~/pages/viewer_page/comments_view_types.d';
import type {ReplayDrawing} from '~/pages/viewer_page/components/annotations/types';

import {replayStorage} from '../../lib/storage';
import type {Int} from '../../lib/time';

// We've gone with the approach to convert the API data into local UI (aka view state)
// that is easily consumable by components. This helps reduce the complexity of components.
// We can continue to expand on what's included in the view state as necessary.
// An alternative that would achieve the same results, was to build set of selectors that
// components could use to reduce complexity. We decided on implementing the former.
const createUserMap = (users: Array<comments2.User>): Map<string, comments2.User> => {
  const userMap = new Map();
  users.forEach((user) => userMap.set(user.id, user));
  return userMap;
};

const convertToReplayUser = (apiUser?: comments2.User, comment?: comments2.Comment): ReplayUser => {
  const replayUser = {
    id: apiUser?.id || '',
    displayName: apiUser?.display_name || '',
    initials: apiUser?.initials || '',
    photoURL: apiUser?.photo_url || null,
    isCurrentUser: apiUser?.is_current_user || false,
  };

  if (!replayUser.isCurrentUser && comment?.metadata.reel?.guest_session_id) {
    // For not-logged-in replay users, check if the user is actually the *current* guest user
    const jwt = replayStorage.getGuestSessionJWT();
    if (jwt) {
      // In case you wonder: JWT is meant to SIGN a given payload, so that the server can verify the payload hasn't been tampered with.
      // A JWT is composed of 3 parts: header, payload, signature -- each part separate by a period(.).
      // The payload is just base64 encoded, so we can extract it from the string with a `split` and
      // decode it to access the payload that we need to compare against the comment's guest_session_id.
      // Ref: https://jwt.io/
      const decodedJwt = JSON.parse(atob(jwt.split('.')[1]));
      const currentGuestSessionId = decodedJwt['guest_session_id'];
      replayUser.isCurrentUser = comment?.metadata.reel?.guest_session_id === currentGuestSessionId;
    }
  }

  return replayUser;
};

const convertProtoToReplayUser = (
  apiUser?: comments2_common.User,
  comment?: comments2_common.Comment,
): ReplayUser => {
  const replayUser = {
    id: apiUser?.id || '',
    displayName: apiUser?.display_name || '',
    initials: apiUser?.initials || '',
    photoURL: apiUser?.photo_url || null,
    isCurrentUser: apiUser?.is_current_user || false,
  };

  if (!replayUser.isCurrentUser && comment?.metadata?.reel?.guest_session_id) {
    // For not-logged-in replay users, check if the user is actually the *current* guest user
    const jwt = replayStorage.getGuestSessionJWT();
    if (jwt) {
      // In case you wonder: JWT is meant to SIGN a given payload, so that the server can verify the payload hasn't been tampered with.
      // A JWT is composed of 3 parts: header, payload, signature -- each part separate by a period(.).
      // The payload is just base64 encoded, so we can extract it from the string with a `split` and
      // decode it to access the payload that we need to compare against the comment's guest_session_id.
      // Ref: https://jwt.io/
      const decodedJwt = JSON.parse(atob(jwt.split('.')[1]));
      const currentGuestSessionId = decodedJwt['guest_session_id'];
      replayUser.isCurrentUser = comment?.metadata.reel?.guest_session_id === currentGuestSessionId;
    }
  }

  return replayUser;
};

const getDrawingFromProtoThread = (
  apiComment: comments2_common.Comment,
): ReplayDrawing | undefined => {
  if (!apiComment || apiComment.deleted) {
    return undefined;
  }
  const jsonString = apiComment.metadata?.reel?.annotation_data;
  if (!jsonString) {
    return undefined;
  }
  const annotationData = JSON.parse(jsonString);
  if (!Array.isArray(annotationData.objects)) {
    return undefined;
  }
  return annotationData;
};

const getDrawingFromThread = (apiComment: comments2.Comment): ReplayDrawing | undefined => {
  if (!apiComment || apiComment.deleted) {
    return undefined;
  }
  const jsonString = apiComment.metadata.reel?.annotation_data;
  if (!jsonString) {
    return undefined;
  }
  const annotationData = JSON.parse(jsonString);
  if (!Array.isArray(annotationData.objects)) {
    return undefined;
  }
  return annotationData;
};

const convertProtoCommentsToReplayComments = (
  apiThread: comments2_common.Thread,
  apiUsers: Array<comments2_common.User>,
): Array<ReplayComment> => {
  const videoAnnotation = apiThread?.annotation_data
    ? (apiThread?.annotation_data.annotation_type as comments2_common.VideoAnnotation)
    : undefined;
  const inTimeSec = videoAnnotation?.time_sec;
  const outTimeSec = videoAnnotation?.out_time_sec;

  let timeRange: TimeRange | undefined;
  if (videoAnnotation) {
    if (inTimeSec! < outTimeSec!) {
      timeRange = {in: inTimeSec!, out: outTimeSec!};
    } else {
      timeRange = {in: inTimeSec!};
    }
  }

  const apiUserMap: Map<string, comments2_common.User> = new Map();
  apiUsers.forEach((user) => {
    if (user.id) {
      apiUserMap.set(user.id, user);
    }
  });

  const replayComments: Array<ReplayComment> = apiThread.comments
    ? apiThread.comments.map((apiComment: comments2_common.Comment) => {
        const apiUser = apiComment.author_id ? apiUserMap.get(apiComment.author_id) : undefined;
        const replayComment: ReplayComment = {
          id: apiComment.id!,
          author: convertProtoToReplayUser(apiUser, apiComment),
          timestamp: apiComment.timestamp ? new Date(apiComment.timestamp) : new Date(),
          content: apiComment.content!,
          isDeleted: apiComment.deleted!,
          drawingJSON: getDrawingFromProtoThread(apiComment),
          timeRange,
          threadID: apiThread.id!,
          isThreadResolved: apiThread.resolved!,
          mentions: apiComment.metadata?.mentions
            ? apiComment.metadata?.mentions?.map((mention) => {
                const replayMention = {
                  user_id: mention.user?.identifier!,
                  location: {
                    start: mention.location?.start!,
                    end: mention.location?.end!,
                  },
                };
                return replayMention;
              })
            : [],
          pageNumber: apiComment.metadata?.reel?.page_number,
          pinPoint: apiComment.metadata?.reel?.pin_point as ReplayRegion | undefined,
          resolvedAtVersion: apiComment.metadata?.reel?.resolved_at_version,
        };
        return replayComment;
      })
    : [];
  return replayComments;
};

const convertToReplayComments = (
  apiThread: comments2.Thread,
  apiUsers: Array<comments2.User>,
): Array<ReplayComment> => {
  const videoAnnotation = apiThread?.annotation_data as comments2.VideoAnnotationReference;
  const inTimeSec = videoAnnotation?.time_sec;
  const outTimeSec = videoAnnotation?.out_time_sec;

  const timeRange = inTimeSec === undefined ? undefined : {in: inTimeSec, out: outTimeSec};

  const apiUserMap = createUserMap(apiUsers);

  const replayComments: Array<ReplayComment> = apiThread.comments.map(
    (apiComment: comments2.Comment) => {
      const apiUser = apiUserMap.get(apiComment.author_id);
      const replayComment: ReplayComment = {
        id: apiComment.id,
        author: convertToReplayUser(apiUser, apiComment),
        timestamp: new Date(apiComment.timestamp),
        content: apiComment.content,
        isDeleted: apiComment.deleted,
        drawingJSON: getDrawingFromThread(apiComment),
        timeRange,
        threadID: apiThread.id,
        isThreadResolved: apiThread.resolved,
        mentions: apiComment.metadata.mentions.map((mention) => {
          const replayMention = {
            user_id: mention.user.identifier,
            location: mention.location,
          };
          return replayMention;
        }),
        pageNumber: apiComment.metadata.reel?.page_number,
        pinPoint: apiComment.metadata.reel?.pin_point,
        resolvedAtVersion: apiComment.metadata.reel?.resolved_at_version,
      };
      return replayComment;
    },
  );
  return replayComments;
};

const convertProtoCommentsThreadToReplayThread = (
  apiThread: comments2_common.Thread,
  apiUsers: Array<comments2_common.User>,
  versionNum?: number,
  versionId?: string,
): ReplayThread => {
  const videoAnnotation = apiThread.annotation_data
    ? (apiThread.annotation_data.annotation_type as comments2_common.VideoAnnotation)
    : undefined;
  const isFrameLevel = videoAnnotation?.time_sec !== undefined;
  const inTimeSec = videoAnnotation?.time_sec;
  const outTimeSec = videoAnnotation?.out_time_sec;

  let timeRange = undefined;
  if (videoAnnotation) {
    if (inTimeSec! < outTimeSec!) {
      timeRange = {in: inTimeSec!, out: outTimeSec!};
    } else {
      timeRange = {in: inTimeSec!};
    }
  }

  const apiUserMap: Map<string, comments2_common.User> = new Map();
  apiUsers.forEach((user) => {
    if (user.id) {
      apiUserMap.set(user.id, user);
    }
  });

  const apiComment = apiThread.comments![0];
  const apiUser = apiComment.author_id ? apiUserMap.get(apiComment.author_id) : undefined;

  const pageNumber = apiComment.metadata?.reel?.page_number;

  const threadType = pageNumber
    ? ReplayThreadType.PAGE_LEVEL
    : isFrameLevel
    ? ReplayThreadType.FRAME_LEVEL
    : ReplayThreadType.MEDIA_LEVEL;

  const thread = {
    type: threadType,
    pageNumber: pageNumber ? (pageNumber as Int) : undefined,
    id: apiThread.id!,
    author: convertProtoToReplayUser(apiUser, apiComment),
    isRead: apiThread.read!,
    isReadOnly: apiThread.readonly!,
    timeRange: timeRange,
    comments: convertProtoCommentsToReplayComments(apiThread, apiUsers),
    drawingJSON: getDrawingFromProtoThread(apiComment) || undefined,
    isNew: false,
    isQuickReaction: !!apiComment.metadata?.reel?.is_quick_reaction,
    isPinned: !!apiComment.metadata?.reel?.is_pinned_comment,
    pinnedLabel: apiComment.metadata?.reel?.pinned_label,
    pinPoint: apiComment.metadata?.reel?.pin_point as ReplayRegion | undefined,
    versionNum: versionNum,
    versionId: versionId,
    resolvedAtVersion: apiComment.metadata?.reel?.resolved_at_version,
  };

  if (apiThread.resolved) {
    const resolvedAtVersion = thread.resolvedAtVersion ?? thread.versionNum;
    const resolverInfo = apiThread.resolved_info?.resolved_info as comments2_common.ResolvedDetails;
    const apiResolverId =
      apiThread.resolved_info?.resolved_info?.['.tag'] === 'resolved_with_details'
        ? apiThread.resolved_info?.resolved_info.resolver_id
        : undefined;
    const apiResolver =
      apiThread.resolved && apiResolverId ? apiUserMap.get(apiResolverId) : undefined;
    const {id, photoURL, displayName} = convertProtoToReplayUser(apiResolver);
    return {
      ...thread,
      isResolved: true,
      isPrivate: apiThread.is_private || false,
      resolution: {
        time: resolverInfo.resolved_timestamp!,
        resolverDisplayName: displayName,
        resolverId: id,
        resolverPhotoUrl: photoURL || undefined,
        resolvedAtVersion,
      },
    };
  } else {
    return {
      ...thread,
      isResolved: false,
      isPrivate: apiThread.is_private || false,
    };
  }
};

// For now this assumes all threads annotation data in Replay is video-specific.
// In the future, we could filter out other types of media if this becomes a problem.
const convertToReplayThread = (
  apiThread: comments2.Thread,
  apiUsers: Array<comments2.User>,
  versionNum?: number,
  versionId?: string,
): ReplayThread => {
  const videoAnnotation = apiThread?.annotation_data as comments2.VideoAnnotationReference;
  const isFrameLevel = videoAnnotation?.time_sec !== undefined;
  const inTimeSec = videoAnnotation?.time_sec;
  const outTimeSec = videoAnnotation?.out_time_sec;

  const timeRange = inTimeSec !== undefined ? {in: inTimeSec, out: outTimeSec} : undefined;
  const apiUserMap = createUserMap(apiUsers);
  const apiComment = apiThread.comments[0];
  const apiUser = apiUserMap.get(apiComment.author_id);

  const pageNumber = apiComment.metadata.reel?.page_number;

  const threadType = pageNumber
    ? ReplayThreadType.PAGE_LEVEL
    : isFrameLevel
    ? ReplayThreadType.FRAME_LEVEL
    : ReplayThreadType.MEDIA_LEVEL;

  const thread = {
    id: apiThread.id,
    type: threadType,
    pageNumber: pageNumber ? (pageNumber as Int) : undefined,
    author: convertToReplayUser(apiUser, apiComment),
    isRead: apiThread.read,
    isReadOnly: apiThread.readonly,
    timeRange: timeRange,
    comments: convertToReplayComments(apiThread, apiUsers),
    drawingJSON: getDrawingFromThread(apiComment) || undefined,
    isNew: false,
    isQuickReaction: !!apiComment.metadata.reel?.is_quick_reaction,
    isPinned: !!apiComment.metadata.reel?.is_pinned_comment,
    pinnedLabel: apiComment.metadata.reel?.pinned_label,
    versionNum: versionNum,
    versionId: versionId,
    pinPoint: apiComment.metadata.reel?.pin_point,
    resolvedAtVersion: apiComment.metadata.reel?.resolved_at_version,
  };

  if (apiThread.resolved) {
    const resolvedAtVersion = thread.resolvedAtVersion ?? thread.versionNum;
    const resolverInfo = apiThread.resolved_info as comments2.ResolvedInfoResolvedWithDetails;
    const apiResolver = apiThread.resolved ? apiUserMap.get(resolverInfo.resolver_id) : undefined;
    const {id, photoURL, displayName} = convertToReplayUser(apiResolver);
    return {
      ...thread,
      isResolved: true,
      isPrivate: false, // TODO(amaratunga): support this when listing comments
      resolution: {
        time: resolverInfo.resolved_timestamp,
        resolverDisplayName: displayName,
        resolverId: id,
        resolverPhotoUrl: photoURL || undefined,
        resolvedAtVersion,
      },
    };
  } else {
    return {
      ...thread,
      isResolved: false,
      isPrivate: false, // TODO(amaratunga): support this when listing comments
    };
  }
};

export const processAPIResponse = (
  response: comments2.ListThreadsResult,
  versionNum?: number,
  versionId?: string,
): Array<ReplayThread> => {
  // We hold on to deleted comments if they are the first comment in a thread with active replies.
  // We'll display a "This comment was deleted" in it's place.
  const filterDeleted = (thread: comments2.Thread) =>
    !(thread.comments[0]?.deleted && thread.comments.length === 1);
  const filteredThreads = response.threads.filter(filterDeleted);
  const replayThreads: Array<ReplayThread> = filteredThreads.map((thread) =>
    convertToReplayThread(thread, response.users, versionNum, versionId),
  );
  return replayThreads;
};

export const processInternalAPIResponse = (
  response: reel.ListThreadsInternalResult,
  versionNum?: number,
  versionId?: string,
): Array<ReplayThread> => {
  // Proto3 doesn't allow undefined primitives, so we can safely assume that these fields are defined. Unsure why the type defintions allow undefined.

  // We hold on to deleted comments if they are the first comment in a thread with active replies.
  // We'll display a "This comment was deleted" in it's place.
  const filterDeleted = (thread: comments2_common.Thread) =>
    thread.comments && !(thread.comments[0]?.deleted && thread.comments.length === 1);
  const filteredThreads = response.threads!.filter(filterDeleted);
  const replayThreads: Array<ReplayThread> = filteredThreads.map((thread) =>
    convertProtoCommentsThreadToReplayThread(thread, response.users!, versionNum, versionId),
  );
  return replayThreads;
};

const convertToAPICommentRequest = (
  replayComment: ReplayComment,
  hasAnnotations: boolean,
  guestInfo: GuestInfo | null,
  isQuickReaction: boolean,
  isPinned: boolean,
  label: string | undefined,
  pageNumber: number | undefined,
  pinPoint: ReplayRegion | undefined,
): comments2.UserSubmittedComment => {
  const {drawingJSON} = replayComment;
  const reelMetadata: comments2.ReelMetadata = {
    annotation_data: drawingJSON ? JSON.stringify(drawingJSON) : '',
    is_quick_reaction: isQuickReaction,
    is_pinned_comment: isPinned,
    pinned_label: label,
    page_number: pageNumber,
    pin_point: pinPoint,
  };
  const mentionMetadata: Array<comments2.MentionMetadata> = replayComment.mentions.map(
    (mention) => {
      {
        const comments2Mention: comments2.MentionMetadata = {
          user: {
            type: {'.tag': 'id'},
            identifier: mention.user_id,
          },
          location: mention.location,
        };
        return comments2Mention;
      }
    },
  );
  const apiComment: comments2.UserSubmittedComment = {
    content: replayComment.content,
    metadata: {
      formatting: [],
      mentions: mentionMetadata,
      reel:
        drawingJSON || isQuickReaction || isPinned || pageNumber || pinPoint
          ? reelMetadata
          : undefined,
    },
  };
  if (guestInfo && apiComment.metadata) {
    apiComment.metadata.guest = {
      first_name: guestInfo.firstName,
      last_name: guestInfo.lastName,
    };
  }
  if (hasAnnotations) {
    apiComment.annotation_data = {
      '.tag': 'video',
      time_sec: replayComment.timeRange?.in,
      out_time_sec: replayComment.timeRange?.out,
    };
  }
  if (replayComment.drawingJSON && apiComment.metadata) {
    apiComment.metadata.reel = {
      annotation_data: JSON.stringify(replayComment.drawingJSON),
      is_pinned_comment: isPinned,
      pinned_label: label,
      page_number: pageNumber,
    };
  }
  return apiComment;
};

const convertToReelAPIUserSubmittedComment = (
  apiComment: comments2.UserSubmittedComment,
  isPrivate: boolean | undefined,
): reel.UserSubmittedComment => {
  const commentScope: reel.CommentScope = isPrivate
    ? {'.tag': 'comment_scope_project_editors_only'}
    : {
        '.tag': 'comment_scope_anyone',
      };

  return {...apiComment, scope: commentScope};
};

export const createStream = (
  videoVersionId: string,
  replaySessionID: string | null,
  shareToken?: string,
  grantBook?: string,
): comments2.Stream => {
  const objectIdentifier: ReelVideoIdIdentifier = {
    grant_book: grantBook,
    share_token: shareToken,
    video_version_id: videoVersionId,
    replay_session_id: replaySessionID,
  };
  return {
    identifier: {
      '.tag': 'reel_video_id',
      reel_video_id: JSON.stringify(objectIdentifier),
    },
    type: {
      '.tag': 'reel_video',
    },
  };
};

export type FetchCommentsResponse = {
  boltInfo: BoltInfo;
  threads: Array<ReplayThread>;
};

export function isListThread(
  listThread:
    | comments2.ListThreadsResultOrErrorResult
    | comments2.ListThreadsResultOrErrorErr
    | comments2.ListThreadsResultOrErrorOther,
): listThread is comments2.ListThreadsResultOrErrorResult {
  return (listThread as comments2.ListThreadsResult).threads !== undefined;
}

// Helper to parse a ReelStream object from a comments2.Stream object.
// This is used to extract the video version id from the stream identifier.
const parseStreamIdentifierForVersionId = (stream?: comments2.Stream): string | undefined => {
  if (!stream) {
    return undefined;
  }
  const identifier = stream.identifier as comments2.StreamIdentifierReelVideoId;

  try {
    const streamIdentifierObj = JSON.parse(identifier.reel_video_id);
    return streamIdentifierObj.video_version_id;
  } catch (e) {
    return undefined;
  }
};

const parseReelStreamIdentifierForVersionId = (stream?: reel.Stream): string | undefined => {
  if (!stream) {
    return undefined;
  }
  const identifier = stream.identifier?.identifier as reel.identifier_unionVideoVersionId;

  try {
    const streamIdentifierObj = JSON.parse(identifier.video_version_id);
    return streamIdentifierObj.video_version_id;
  } catch (e) {
    return undefined;
  }
};

interface FetchReactionsResponse {
  reactions: {[commentId: string]: Array<reactions.Reaction>};
  boltInfo: {[commentId: string]: BoltInfo};
  reactionsUsers: {[userPid: string]: string};
}

type SessionStatus = 'logged out' | 'logged in';

interface LoggedOutClient {
  sessionStatus: 'logged out';
  api: Comments2LoggedOutApi;
}

interface LoggedInClient {
  sessionStatus: 'logged in';
  api: Comments2Api;
}

type CommentClientType = LoggedOutClient | LoggedInClient;

interface GuestInfo {
  firstName: string;
  lastName: string;
}

export interface CommentsMiddleWareOptions {
  sessionStatus: SessionStatus;
  videoVersionId: string;
  guestInfo?: GuestInfo;
  shareToken?: string;
  grantBook?: string;
  videoId?: string;
  versionNum?: number;
}

export class CommentsMiddleware {
  private client: CommentClientType;
  private guestInfo: GuestInfo | null;
  private reactionsApiClient;
  private videoVersionId: string;
  private shareToken: string | undefined;
  private grantBook: string | undefined;
  private replaySessionId: string | null;
  private videoId: string | undefined;
  private versionNum: number | undefined;

  constructor(opts: CommentsMiddleWareOptions) {
    const {sessionStatus, videoVersionId, guestInfo, shareToken, grantBook, videoId, versionNum} =
      opts;
    const stream = createStream(
      videoVersionId,
      replayStorage.getReplaySessionID(),
      shareToken,
      grantBook,
    );
    this.videoVersionId = videoVersionId;
    this.shareToken = shareToken;
    this.grantBook = grantBook;
    this.replaySessionId = replayStorage.getReplaySessionID();
    this.videoId = videoId;
    this.versionNum = versionNum;

    if (sessionStatus === 'logged in') {
      this.client = {
        sessionStatus,
        api: new Comments2Api(stream),
      };
      this.guestInfo = null;
    } else {
      this.client = {
        sessionStatus,
        api: new Comments2LoggedOutApi(stream),
      };
      this.guestInfo = guestInfo || null;
    }
    this.reactionsApiClient = new ReactionsApi();
  }

  async fetchCommentsRequest(privateCommentsEnabled?: boolean): Promise<FetchCommentsResponse> {
    if (privateCommentsEnabled && this.client.api instanceof Comments2Api) {
      let response: reel.ListThreadsInternalResult;
      try {
        response = await this.client.api.listThreadsInternal();

        if (
          response.bolt_info === undefined ||
          response.bolt_info.app_id === undefined ||
          response.bolt_info.unique_id === undefined ||
          response.bolt_info.revision === undefined ||
          response.bolt_info.token === undefined
        ) {
          throw new Error(
            'Bolt info from ListThreadsInternal is undefined, but it is always expected.',
          );
        }

        if (response.threads === undefined) {
          throw new Error(
            'Threads from ListThreadsInternal are undefined, but it is always expected.',
          );
        }

        if (response.users === undefined) {
          throw new Error(
            'Users from ListThreadsInternal are undefined, but it is always expected.',
          );
        }
      } catch (error) {
        console.error(`List threads internal request failed with error: ${error}`);
        throw error;
      }

      const boltInfo: BoltInfo = {
        appId: response.bolt_info.app_id,
        uniqueId: response.bolt_info.unique_id,
        revision: response.bolt_info.revision,
        token: response.bolt_info.token,
      };
      const threads: ReplayThread[] = processInternalAPIResponse(response);
      return {boltInfo, threads};
    } else {
      let response: comments2.ListThreadsResult;
      try {
        response = await this.client.api.listComments();
      } catch (error) {
        console.error(`List threads request failed with error: ${error}`);
        throw error;
      }

      const boltInfo: BoltInfo = {
        appId: response.bolt_info.app_id,
        uniqueId: response.bolt_info.unique_id,
        revision: response.bolt_info.revision,
        token: response.bolt_info.token,
      };
      const threads: ReplayThread[] = processAPIResponse(response);
      return {boltInfo, threads};
    }
  }

  async fetchPastCommentsRequest(
    privateCommentsEnabled: boolean,
  ): Promise<FetchCommentsResponse[]> {
    if (this.videoId === undefined) {
      return [];
    }

    let listThreadsResults: comments2.BatchListThreadsResult;
    let listThreadsInternalResults: reel.ListThreadsInternalBatchResult;

    const videoIdentifier = {
      type: 'video_id' as const,
      shareToken: this.shareToken,
      videoId: this.videoId,
    };

    const versionSummaries = await getVersionSummariesQuery(videoIdentifier, this.grantBook);

    // Get all the past versions and exclude the current version
    const streams = versionSummaries
      .filter(
        (versionSummary) =>
          versionSummary.video_version_id !== this.videoVersionId &&
          this.versionNum &&
          versionSummary.version_num < this.versionNum,
      )
      .map((version) => {
        return createStream(
          version.video_version_id,
          this.replaySessionId,
          this.shareToken,
          this.grantBook,
        );
      });

    if (privateCommentsEnabled && this.client.api instanceof Comments2Api) {
      try {
        listThreadsInternalResults = await this.client.api.listThreadsInternalBatch(streams);
      } catch (error) {
        console.error(`List threads internal batch request failed with error: ${error}`);
        throw error;
      }

      const listThreads = listThreadsInternalResults.results;

      if (listThreads?.length != streams.length) {
        throw new Error(
          `List threads internal batch didn't return the same number of results as the number of streams passed in`,
        );
      }

      const threadAndBoltInfo = listThreads.map((listThread) => {
        const versionId = parseReelStreamIdentifierForVersionId(listThread.stream);
        const versionInfo = versionSummaries.find(
          (versionSummary) => versionSummary.video_version_id === versionId,
        );

        if (listThread.bolt_info === undefined) {
          throw new Error(
            'Bolt info from ListThreadsInternal is undefined, but it is always expected.',
          );
        }

        return {
          boltInfo: {
            appId: listThread.bolt_info.app_id!,
            uniqueId: listThread.bolt_info.unique_id!,
            revision: listThread.bolt_info.revision!,
            token: listThread.bolt_info.token!,
          },
          threads: processInternalAPIResponse(listThread, versionInfo?.version_num || 1, versionId),
        };
      });

      return threadAndBoltInfo;
    } else {
      try {
        listThreadsResults = await this.client.api.listCommentsBatch(streams);
      } catch (error) {
        console.error(`List threads request failed with error: ${error}`);
        throw error;
      }

      // Filter out the results that are not list threads
      const listThreads = listThreadsResults.results.filter(isListThread);

      // Our focus is solely on threads with comments,
      // thus filtering out those without any.
      // Each version includes relevant bolt information,
      // ensuring our past versions' threads remain updated.
      const threadAndBoltInfo = listThreads.map((listThread) => {
        //Streams are unique to each version, so we can use the stream identifier to get the version id.
        const stream = listThread.stream;
        const versionId = parseStreamIdentifierForVersionId(stream);

        const versionInfo = versionSummaries.find(
          (versionSummary) =>
            listThread.threads.length > 0 && versionSummary.video_version_id === versionId,
        );
        return {
          boltInfo: {
            appId: listThread.bolt_info.app_id,
            uniqueId: listThread.bolt_info.unique_id,
            revision: listThread.bolt_info.revision,
            token: listThread.bolt_info.token,
          },
          threads: processAPIResponse(listThread, versionInfo?.version_num || 1, versionId),
        };
      });
      return threadAndBoltInfo;
    }
  }

  async fetchReactionsRequest(commentIds: Array<string>): Promise<FetchReactionsResponse> {
    const targets = commentIds.map((commentId) => ({
      '.tag': 'reel_video_comment' as const,
      reel_video_version_id: this.videoVersionId,
      comment_id: commentId,
      share_token: this.shareToken ?? '',
      grant_book: this.grantBook ?? '',
      replay_session_id: this.replaySessionId ?? '',
    }));
    let response: reactions.ListReactionsBatchResult;
    try {
      response = await this.reactionsApiClient.listReactionsBatch(targets);
    } catch (error) {
      console.error(`List reactions batch request failed with error: ${error.message}`);
      throw error;
    }
    const reactionInfo = response.target_reactions ?? {};
    const reactions: {[commentId: string]: Array<reactions.Reaction>} = {};
    const reactionsBoltInfo: {[commentId: string]: BoltInfo} = {};
    for (const [commentPid, reactionsPerComment] of Object.entries(reactionInfo)) {
      reactions[commentPid] = reactionsPerComment.reactions ?? [];

      const responseBoltInfo = reactionsPerComment.bolt_info;
      if (responseBoltInfo) {
        const boltInfo: BoltInfo = {
          appId: responseBoltInfo.app_id,
          uniqueId: responseBoltInfo.unique_id,
          revision: responseBoltInfo.revision,
          token: responseBoltInfo.token,
        };
        reactionsBoltInfo[commentPid] = boltInfo;
      }
    }
    return {
      reactions: reactions,
      boltInfo: reactionsBoltInfo,
      reactionsUsers: response.users ?? {},
    };
  }

  async createThreadRequest(
    replayThread: ReplayThread,
    privateCommentsEnabled?: boolean,
  ): Promise<string> {
    const replayComment = replayThread.comments[0];
    const threadType = replayThread.type;

    const apiComment: comments2.UserSubmittedComment = convertToAPICommentRequest(
      replayComment,
      threadType === ReplayThreadType.FRAME_LEVEL,
      this.guestInfo,
      replayThread.isQuickReaction,
      replayThread.isPinned,
      undefined,
      replayThread.pageNumber,
      replayThread.pinPoint,
    );

    const reelAPIComment: reel.UserSubmittedComment = convertToReelAPIUserSubmittedComment(
      apiComment,
      replayThread.isPrivate,
    );

    const hasAnnotations = threadType === ReplayThreadType.FRAME_LEVEL;
    const videoTimestamp = hasAnnotations ? replayComment.timeRange?.in : undefined;
    const videoTimestampOut = hasAnnotations ? replayComment.timeRange?.out : undefined;
    const annotationDrawingData = replayComment.drawingJSON
      ? JSON.stringify(replayComment.drawingJSON)
      : '';
    const mentionMetadata: Array<comments2.MentionMetadata> = replayComment.mentions.map(
      (mention) => {
        {
          const comments2Mention: comments2.MentionMetadata = {
            user: {
              type: {'.tag': 'id'},
              identifier: mention.user_id,
            },
            location: mention.location,
          };
          return comments2Mention;
        }
      },
    );

    let resp_comment_id: string | undefined;
    try {
      // Only logged in users can create private comments
      if (privateCommentsEnabled && this.client.api instanceof Comments2Api) {
        const createCommentInternalResponse = await this.client.api.createCommentInternal(
          reelAPIComment,
          videoTimestamp,
          videoTimestampOut,
          replayThread.pageNumber,
          annotationDrawingData,
          mentionMetadata,
          replayThread.isQuickReaction,
          replayThread.isPinned,
          replayThread.pinnedLabel,
          replayThread.pinPoint,
        );
        resp_comment_id = createCommentInternalResponse.comment_id;
        if (resp_comment_id === undefined) {
          throw new Error(
            'Create comment internal request failed with error: comment_id is undefined',
          );
        }
      } else {
        // If user is not logged in, we call the reelGuestAddComment API
        // Also calling this while the team_only_comments feature flag is disabled
        const createThreadResponse = await this.client.api.createThread(apiComment);
        resp_comment_id = createThreadResponse.id;
      }
    } catch (error) {
      console.error(`Create thread request failed with error: ${error}`);
      throw error;
    }
    return resp_comment_id;
  }

  async replyToThreadRequest(
    replayThreadID: string,
    replayComment: ReplayComment,
    isPrivateReply: boolean,
    privateCommentsEnabled: boolean,
    versionId?: string,
  ): Promise<string> {
    const pastStream = versionId
      ? createStream(versionId, replayStorage.getReplaySessionID(), this.shareToken, this.grantBook)
      : undefined;
    const apiComment: comments2.UserSubmittedComment = convertToAPICommentRequest(
      replayComment,
      false,
      this.guestInfo,
      false,
      false,
      undefined,
      replayComment.pageNumber,
      replayComment.pinPoint,
    );

    const reelAPIComment: reel.UserSubmittedComment = convertToReelAPIUserSubmittedComment(
      apiComment,
      isPrivateReply,
    );

    const annotationDrawingData = replayComment.drawingJSON
      ? JSON.stringify(replayComment.drawingJSON)
      : '';
    const mentionMetadata: Array<comments2.MentionMetadata> = replayComment.mentions.map(
      (mention) => {
        {
          const comments2Mention: comments2.MentionMetadata = {
            user: {
              type: {'.tag': 'id'},
              identifier: mention.user_id,
            },
            location: mention.location,
          };
          return comments2Mention;
        }
      },
    );

    let resp_comment_id: string | undefined;
    try {
      // Only logged in users can create private comments
      if (privateCommentsEnabled && this.client.api instanceof Comments2Api) {
        const response = await this.client.api.replyToThreadInternal(
          reelAPIComment,
          replayThreadID,
          undefined,
          undefined,
          replayComment.pageNumber,
          annotationDrawingData,
          mentionMetadata,
          false,
          false,
          undefined,
          pastStream,
        );
        resp_comment_id = response.comment_id;
        if (resp_comment_id === undefined) {
          throw new Error('Reply to thread request failed with error: comment_id is undefined');
        }
      } else {
        const response = await this.client.api.replyToThread(
          apiComment,
          replayThreadID,
          pastStream,
        );
        resp_comment_id = response.id;
      }
    } catch (error) {
      console.error(`Reply to thread request failed with error: ${error}`);
      throw error;
    }
    return resp_comment_id;
  }

  async editCommentRequest(
    comment: ReplayComment,
    newContent: string,
    mentions: ReplayMention[],
    versionId?: string,
  ): Promise<string> {
    const pastStream = versionId
      ? createStream(versionId, replayStorage.getReplaySessionID(), this.shareToken, this.grantBook)
      : undefined;
    const newComment = {...comment, content: newContent, mentions};
    const apiComment: comments2.UserSubmittedComment = convertToAPICommentRequest(
      newComment,
      false,
      this.guestInfo,
      false,
      false,
      undefined,
      undefined,
      undefined,
    );
    let response: comments2.AcknowledgementResult;
    try {
      response = await this.client.api.editComment(comment.id, apiComment, pastStream);
    } catch (error) {
      console.error(`Edit comment request failed with error: ${error}`);
      throw error;
    }
    return response.id;
  }

  async deleteCommentRequest(replayComment: ReplayComment, versionId?: string): Promise<string> {
    let response: comments2.AcknowledgementResult;
    const pastStream = versionId
      ? createStream(versionId, replayStorage.getReplaySessionID(), this.shareToken, this.grantBook)
      : undefined;

    try {
      response = await this.client.api.deleteComment(replayComment.id, pastStream);
    } catch (error) {
      console.error(`Delete comment request failed with error: ${error}`);
      throw error;
    }
    return response.id;
  }

  async resolveThreadRequestInternal(
    replayThreadID: string,
    versionId?: string,
    versionNum?: number,
  ): Promise<string> {
    if (this.client.sessionStatus === 'logged out') {
      throw new Error('Resolve operation disabled for logged out sessions');
    }
    const pastStream = versionId
      ? createStream(versionId, replayStorage.getReplaySessionID(), this.shareToken, this.grantBook)
      : undefined;
    let response: reel.ResolveThreadInternalResponse;
    try {
      response = await this.client.api.markThreadResolvedInternal(
        replayThreadID,
        pastStream,
        versionNum,
      );
      if (response.comment_id === undefined) {
        throw new Error('Resolve comment request failed with error: comment_id is undefined');
      }
    } catch (error) {
      console.error(`Resolve comment request failed with error: ${error}`);
      throw error;
    }
    return response.comment_id;
  }

  async unresolveThreadRequestInternal(
    replayThreadID: string,
    versionId?: string,
  ): Promise<string> {
    if (this.client.sessionStatus === 'logged out') {
      throw new Error('Unresolve operation disabled for logged out sessions');
    }
    let response: reel.UnresolveThreadInternalResponse;
    const pastStream = versionId
      ? createStream(versionId, replayStorage.getReplaySessionID(), this.shareToken, this.grantBook)
      : undefined;
    try {
      response = await this.client.api.markThreadUnresolvedInternal(replayThreadID, pastStream);

      if (response.comment_id === undefined) {
        throw new Error('Unresolve comment request failed with error: comment_id is undefined');
      }
    } catch (error) {
      console.error(`Unresolve comment request failed with error: ${error}`);
      throw error;
    }
    return response.comment_id;
  }

  async pinThreadRequest(comment: ReplayComment): Promise<string> {
    if (this.client.sessionStatus === 'logged out') {
      throw new Error('Pinning threads only available to logged in users.');
    }
    let response: comments2.AcknowledgementResult;
    const pastStream = comment.versionId
      ? createStream(
          comment.versionId,
          replayStorage.getReplaySessionID(),
          this.shareToken,
          this.grantBook,
        )
      : undefined;
    try {
      const apiComment: comments2.UserSubmittedComment = convertToAPICommentRequest(
        comment,
        false,
        this.guestInfo,
        false,
        true,
        undefined,
        undefined,
        undefined,
      );

      response = await this.client.api.editComment(comment.id, apiComment, pastStream);
    } catch (error) {
      console.error(`Resolve comment request failed with error: ${error}`);
      throw error;
    }
    return response.id;
  }

  async updateLabel(comment: ReplayComment, label: string, versionId?: string): Promise<string> {
    if (this.client.sessionStatus === 'logged out') {
      throw new Error('Pinning threads only available to logged in users.');
    }
    let response: comments2.AcknowledgementResult;
    const pastStream = versionId
      ? createStream(versionId, replayStorage.getReplaySessionID(), this.shareToken, this.grantBook)
      : undefined;
    try {
      const apiComment: comments2.UserSubmittedComment = convertToAPICommentRequest(
        comment,
        false,
        this.guestInfo,
        false,
        true,
        label,
        undefined,
        undefined,
      );

      response = await this.client.api.editComment(comment.id, apiComment, pastStream);
    } catch (error) {
      console.error(`Resolve comment request failed with error: ${error}`);
      throw error;
    }
    return response.id;
  }

  async unpinThreadRequest(comment: ReplayComment): Promise<string | null> {
    if (this.client.sessionStatus === 'logged out') {
      throw new Error('Pinning threads only available to logged in users.');
    }
    let response: comments2.AcknowledgementResult;
    const pastStream = comment.versionId
      ? createStream(
          comment.versionId,
          replayStorage.getReplaySessionID(),
          this.shareToken,
          this.grantBook,
        )
      : undefined;
    try {
      const apiComment: comments2.UserSubmittedComment = convertToAPICommentRequest(
        comment,
        false,
        this.guestInfo,
        false,
        true,
        undefined,
        undefined,
        undefined,
      );
      if (apiComment.metadata?.reel) {
        apiComment.metadata.reel = {
          ...apiComment?.metadata?.reel,
          is_pinned_comment: false,
          pinned_label: undefined,
        };
        response = await this.client.api.editComment(comment.id, apiComment, pastStream);
        return response.id;
      }
    } catch (error) {
      console.error(`Unpin thread request failed with error: ${error}`);
      throw error;
    }
    return null;
  }

  async markThreadAsReadRequest(replayThreadID: string): Promise<string> {
    if (this.client.sessionStatus === 'logged out') {
      throw new Error('Mark thread read operation disabled for logged out sessions');
    }
    let response: comments2.AcknowledgementResult;
    try {
      response = await this.client.api.markThreadRead(replayThreadID);
    } catch (error) {
      console.error(`Mark thread read request failed with error: ${error}`);
      throw error;
    }
    return response.id;
  }
}
