import type {files} from '@dropbox/api-v2-client';

import {dropboxSdk} from '~/lib/client';
import {getExtension} from '~/lib/helpers';
import type {ChooserFile} from '~/lib/uploads/types';

const makePathRelative = (filePath: string, parentPath: string) => {
  if (filePath.startsWith(parentPath)) {
    return filePath.substring(parentPath.length);
  }
  return filePath;
};

const listDropboxFolder = async (folderId: string, validExtensionsList: string[]) => {
  const basicClient = dropboxSdk.client;
  const accountInfo = await basicClient.usersGetCurrentAccount();
  const rootNamespaceId = parseInt(accountInfo.result.root_info.root_namespace_id);
  const clientWithRootNamespace = dropboxSdk.withPathRoot(rootNamespaceId);
  const client = clientWithRootNamespace ?? basicClient;

  const files: ChooserFile[] = [];
  let cursor = '';

  try {
    const folderInfo = await client.filesGetMetadata({path: folderId});
    const folderPath = folderInfo.result.path_display;
    const folderName = folderInfo.result.name;
    const dropboxParentFolderPath = folderPath?.substring(0, folderPath.length - folderName.length);

    do {
      const listFolderResponse =
        cursor === ''
          ? await client.filesListFolder({path: folderId, recursive: true})
          : await client.filesListFolderContinue({cursor: cursor});
      cursor = listFolderResponse.result.has_more ? listFolderResponse.result.cursor : '';
      const folderContents = listFolderResponse.result.entries;
      const filesMetadata = folderContents.filter(
        (item): item is files.FileMetadataReference => item['.tag'] === 'file',
      );
      filesMetadata.forEach((fileMetadata) => {
        const relativePath =
          fileMetadata.path_display && dropboxParentFolderPath
            ? makePathRelative(fileMetadata.path_display, dropboxParentFolderPath)
            : fileMetadata.path_display;
        if (relativePath) {
          files.push({
            id: fileMetadata.id,
            name: fileMetadata.name,
            relativePath: relativePath,
            parentDirectory: folderName,
            fileSize: fileMetadata.size,
          });
        }
      });
    } while (cursor);
  } catch (err) {
    throw Error('Fetching folder contents failed.');
  }

  const supportedFiles = files.filter((file) =>
    validExtensionsList.includes(getExtension(file.name)),
  );
  const unsupportedFiles = files.filter(
    (file) => !validExtensionsList.includes(getExtension(file.name)),
  );

  const uniqueUnsupportedExtensions = [
    ...new Set(unsupportedFiles.map((file) => getExtension(file.name))),
  ];

  return {
    supportedFiles: supportedFiles,
    unsupportedFiles: unsupportedFiles,
    unsupportedExtensions: uniqueUnsupportedExtensions,
  };
};

export default listDropboxFolder;
