import {useCallback, useEffect, useRef} from 'react';

import {useAtomValue} from 'jotai';
import {defineMessages, FormattedMessage} from 'react-intl';
import {useLocation} from 'react-router';
import styled, {css, keyframes} from 'styled-components';

import {Button, StylelessButton} from '~/components/button';
import {LottieAnimation, LottieAnimationType} from '~/components/lottie_animation';
import {Checklist} from '~/components/onboarding_v2/checklist';
import {useViewport} from '~/components/viewport_context';
import {useOnboardingChecklist} from '~/lib/onboarding_v2/use_onboarding_checklist';
import {useLoggingClient} from '~/lib/use_logging_client';
import {
  hasCompletedOnboardingAtom,
  isChecklistOpenAtom,
  ONBOARDING_OPEN_SOURCE,
  onboardingTypeAtom,
  stepsToDoAtom,
} from '~/state/onboarding_v2';

import {useInterpolatedNumber} from '../animated_number';

const Container = styled.div`
  align-items: center;
  display: flex;
  position: relative;
`;

// Create the keyframes
const progressFill = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
`;
type PercentCompleteButtonProps = {
  $isLoadingPercent: boolean;
};

const PercentCompleteButton = styled(Button)<PercentCompleteButtonProps>`
  && {
    min-width: 135px;
    position: relative;
  }

  // override to keep the blue
  &.percent-complete-button {
    background-color: ${(props) =>
      props.$isLoadingPercent ? 'rgb(153,192,255)' : 'rgb(48,128,255)'};
    color: var(--dig-color__text__base);
  }

  &:hover.percent-complete-button {
    color: var(--dig-color__primary-cta__on-base);
  }

  &::before {
    content: '';
    position: absolute;
    background-color: rgb(48, 128, 255);
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transform: translateX(-100%);

    ${(props) =>
      props.$isLoadingPercent &&
      css`
        animation: ${progressFill} 0.5s;
        animation-fill-mode: forwards;
      `}
  }
`;

const intlMessages = defineMessages({
  getStarted: {
    defaultMessage: 'Get started',
    id: 'pExSYg',
    description: 'Title for checklist',
  },
  percentComplete: {
    defaultMessage: '{percentComplete}% complete',
    id: 'HoVyqN',
    description: 'Button to open checklist that says what % complete',
  },
});

type Props = {
  resizeForSmallScreenSize?: boolean;
};

const OnboardingChecklist = ({resizeForSmallScreenSize = true}: Props) => {
  const triggerRef = useRef(null);
  // eslint-disable-next-line deprecation/deprecation
  const {isSmallScreenSize} = useViewport();
  const loggingClient = useLoggingClient();
  const {handleCloseChecklist, handleToggleChecklist, percentComplete} = useOnboardingChecklist({
    // eslint-disable-next-line deprecation/deprecation
    logEvent: loggingClient.logEvent.bind(loggingClient),
  });
  const hasCompletedOnboarding = useAtomValue(hasCompletedOnboardingAtom);
  const isChecklistOpen = useAtomValue(isChecklistOpenAtom);
  const onboardingType = useAtomValue(onboardingTypeAtom);
  const stepsToDo = useAtomValue(stepsToDoAtom);
  const location = useLocation();

  const handleOnClickPercentButton = useCallback(() => {
    handleToggleChecklist(ONBOARDING_OPEN_SOURCE.PERCENT_COMPLETE_BUTTON);
  }, [handleToggleChecklist]);

  const {interpolatedValue, isRunning} = useInterpolatedNumber({
    value: percentComplete,
  });

  // when page route changes, close the checklist
  useEffect(() => {
    handleCloseChecklist();
  }, [handleCloseChecklist, location.pathname]);

  if (
    isSmallScreenSize ||
    !onboardingType ||
    (hasCompletedOnboarding && !isChecklistOpen) ||
    stepsToDo.length === 0
  ) {
    return null;
  }

  return (
    <Container>
      {percentComplete === 100 ? (
        <StylelessButton onClick={handleOnClickPercentButton} ref={triggerRef}>
          <LottieAnimation
            animationType={LottieAnimationType.ONBOARDING_COMPLETED}
            loop={false}
            style={{
              height: '32px',
              marginRight: 'var(--spacing__unit--1)',
              width: '137px',
            }}
          />
        </StylelessButton>
      ) : (
        <PercentCompleteButton
          $isLoadingPercent={isRunning}
          className="percent-complete-button"
          onClick={handleOnClickPercentButton}
          ref={triggerRef}
          size={resizeForSmallScreenSize && isSmallScreenSize ? 'small' : 'standard'}
          variant="primary"
        >
          <FormattedMessage
            {...intlMessages.percentComplete}
            values={{
              percentComplete: interpolatedValue,
            }}
          />
        </PercentCompleteButton>
      )}

      <Checklist triggerRef={triggerRef} />
    </Container>
  );
};

export {OnboardingChecklist};
