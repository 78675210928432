import type {Upload_File} from 'pap-events/manual_upload/upload_file';

import type {reel} from '@dropbox/api-v2-client';

import type {
  AddMediaClickSourceType,
  MediaSourceType,
  UploadSourceType,
} from '~/lib/logging/logger_types';

/** State of the upload process */
export enum UploadState {
  /** Upload has not been started */
  Init = 'init',
  /** Upload process has begun but is not ready to start uploading data */
  Starting = 'starting',
  /** Uploading of files is ready to begin */
  Ready = 'ready',
  /** Files are actively uploading */
  Uploading = 'uploading',
  /** Uploading process is complete, all files have successfully uploaded */
  Complete = 'complete',
  /** Uploading process is complete, at least one file has failed to upload */
  Error = 'error',
  /** Uploading process failed because the file quote was exceeded */
  QuotaExceeded = 'quota_exceeded',
}

export const UPLOAD_IN_PROGRESS_STATES = [
  UploadState.Starting,
  UploadState.Ready,
  UploadState.Uploading,
];
export const UPLOAD_FINAL_STATES = [
  UploadState.Complete,
  UploadState.Error,
  UploadState.QuotaExceeded,
];

/** State of a single file during an upload */
export enum FileUploadState {
  /** Uploading of file is ready to begin */
  Ready = 'ready',
  /** Files is actively uploading */
  Uploading = 'uploading',
  /** Upload process is complete for file */
  Complete = 'complete',
  /** Upload process has failed for file */
  Error = 'error',
  /** Upload process has been cancelled for file */
  Cancelled = 'cancelled',
}

export const UPLOAD_IN_PROGRESS_FILE_STATES = [FileUploadState.Ready, FileUploadState.Uploading];
export const UPLOAD_FINAL_FILE_STATES = [
  FileUploadState.Complete,
  FileUploadState.Error,
  FileUploadState.Cancelled,
];

export type BasicFileInfo = {
  name: string;
  relativePath: string;
  parentDirectory?: string;
  folderUpload?: boolean;
  fileSize: number;
};

export type ChooserFile = BasicFileInfo & {
  id: string;
  error?: UploadErrorType;
};

export type GDriveFile = {
  gdriveId: string;
  name: string;
};

export type OneDriveFile = {
  oneDriveId: string;
  name: string;
};

export enum UploadTypes {
  PROJECT = 'project',
  VERSION = 'version',
  CAPTIONS = 'captions',
  LOGO = 'logo',
}

type Upload = {
  /* If true, signals to upload process not to continue working with this entry */
  canceled: boolean;
  error?: UploadErrorType;
  hideDrawer?: boolean;
  link?: string;
  mediaSourceType: MediaSourceType;
  nsId?: number;
  parentFolderId?: string;
  projectId?: string;
  type: UploadTypes;
  uploadId: string;
  versionId?: string;
  videoId?: string;
  onCancel?: (upload: UploadInfo) => void;
  onRetry?: (upload: UploadInfo) => void;
};

export type UploadLoggingData = Upload_File['properties'];

export type FileExt = (File | StreamableFile) & {
  relativePath?: string;
  parentDirectory?: string;
  folderUpload?: boolean;
};

export type ChooserUpload = Upload & {
  file: ChooserFile;
};

export type DirectUpload = Upload & {
  file: FileExt;
};

export type GDriveUpload = Upload & {
  file: GDriveFile;
};

export type OneDriveUpload = Upload & {
  file: OneDriveFile;
};

export type MappedChooserUploads = Record<string, ChooserUpload>;
export type MappedDirectUploads = Record<string, DirectUpload>;
export type MappedGDriveUploads = Record<string, GDriveUpload>;
export type MappedOneDriveUploads = Record<string, OneDriveUpload>;
export type MappedUploadTypes =
  | MappedChooserUploads
  | MappedDirectUploads
  | MappedGDriveUploads
  | MappedOneDriveUploads;

export type UploadInfo = ChooserUpload | DirectUpload | GDriveUpload | OneDriveUpload;
export type MappedUploads = Record<string, UploadInfo>;
export enum UploadDrawerStatus {
  ALL_FAILED = 'all_failed',
  CANCELED = 'canceled',
  FILE_QUOTA_EXCEEDED = 'file_quota_exceeded',
  SOME_FAILED = 'some_failed',
  SUCCESS = 'success',
  UPLOADING = 'uploading',
  USAGE_QUOTA_EXCEEDED = 'usage_quota_exceeded',
}

export enum UploadErrorType {
  InvalidType,
  UploadFailed,
  FileEmpty,
  ADD_TO_ROOT_FOLDER_ERROR = 'add_to_root_folder',
  FILE_QUOTA_EXCEEDED_ERROR = 'file_quota_exceeded',
  USAGE_QUOTA_EXCEEDED_ERROR = 'usage_quota_exceeded',
  UNKNOWN_ERROR = 'unknown_error',
}

export type UploadProgress = {
  status?: FileUploadState;
  percentage?: number;
  totalSize?: number;
  uploadedSize?: number;
  error?: UploadErrorType;
};

export type UploadsProgress = Record<string, UploadProgress>;

export type UploadsVersionSummaries = Record<string, reel.VersionSummary[]>;

export type StreamableFile = {
  name: string;
  size: number;
  newStream: () => Promise<ReadableStream>;

  webkitRelativePath: string;
};

export type FileUpload = {
  uploadId: string;
  nsId: number;
  file: File | StreamableFile;
};

export type CalculatedUploadValues = {
  numCanceled: number;
  numErrors: number;
  numSuccessful: number;
  numUploading: number;
  numReady: number;
  numTotalUploads: number;
  totalPercentage: number;
  errors: UploadErrorType[];
};

export type UploadFilePickHandler = (
  filesList: FileList | File[] | StreamableFile[],
  options?: {
    onFileUploadCompleteCallback?: (
      upload: DirectUpload,
      fileId: string,
      videoVersionId: string,
    ) => void;
    onAllUploadsCompleteCallback?: () => void;
  },
) => Promise<void>;

export type AllUploadsCompleteHandler = (
  files: (File | StreamableFile)[] | ChooserFile[] | GDriveFile[] | OneDriveFile[],
  folderId: string,
  clickSource: AddMediaClickSourceType,
  mediaSource: MediaSourceType,
  uploadSource: UploadSourceType,
) => void;

export type UploadLoggingDataMap = Record<string, UploadLoggingData>;

// Constants taken from https://sourcegraph.pp.dropbox.com/server/-/blob/metaserver/static/js/modules/clean/react/upload_kit/scheduler/parallel.ts
export const BLOCK_SIZE = 4 * 1024 * 1024;
export const MAX_PARALLEL_BLOCK_UPLOAD_REQUESTS = 6;
export const MAX_PARALLEL_DIRECT_UPLOADS = 4;
export const MAX_PARALLEL_DROPBOX_UPLOADS = 10;
export const MAX_CREATE_PROJECT_BATCH = 20;
export const MAX_CREATE_EMPTY_PROJECT_BATCH = 40;
