import React from 'react';

import {useAtomValue} from 'jotai';
import {useIntl} from 'react-intl';

import {UIIcon} from '@dropbox/dig-icons';
import {DropboxLine, LockLine} from '@dropbox/dig-icons/assets';

import {BasicAddOnUpsellModal} from '~/components/add_on_upsell_modal';
import {Button, IconButton} from '~/components/button';
import {useViewport} from '~/components/viewport_context';
import type {LoggingClient} from '~/lib/logging/logger';
import {useReelProvisioningEnabled} from '~/lib/utils';
import {BulkActionStatus, bulkActionStatusAtom} from '~/state/bulk_actions';

import {useReelAppGlobalState} from '../../../context';
import {PremiumTooltip} from '../../layout/components/premium_tooltip';

type BulkArchiveToDropboxButtonProps = {
  onClick: () => void;
  logEvent: LoggingClient['logEvent'];
};

export const BulkArchiveToDropboxButton = ({
  onClick,
  logEvent,
}: BulkArchiveToDropboxButtonProps) => {
  // eslint-disable-next-line deprecation/deprecation
  const {isSmallScreenSize, isMobileDevice} = useViewport();
  const showUpsellModal = !isMobileDevice;
  const intl = useIntl();
  const sessionContext = useReelAppGlobalState();
  const isProvisioningEnabled = useReelProvisioningEnabled();
  const isArchivingProvisioned =
    !isProvisioningEnabled ||
    (sessionContext.status === 'logged in' && sessionContext.provisions.archive_to_dbx);
  const triggerRef = React.useRef(null);
  const [showArchivePremiumTooltip, setShowArchivePremiumTooltip] = React.useState<boolean>(false);
  const [upsellModalIsOpen, setUpsellModalIsOpen] = React.useState<boolean>(false);
  const [tooltipTimeout, setTooltipTimeout] = React.useState<NodeJS.Timeout>();

  const bulkActionStatus = useAtomValue(bulkActionStatusAtom);

  const disabled =
    bulkActionStatus === BulkActionStatus.REQUESTING ||
    (!showUpsellModal && !isArchivingProvisioned);

  const archiveButton = intl.formatMessage({
    defaultMessage: 'Archive to Dropbox',
    description: 'Button that allows a user to archive one or more files to Dropbox',
    id: 'Lx9tQu',
  });

  return isSmallScreenSize ? (
    <IconButton
      aria-label={archiveButton}
      disabled={disabled}
      onClick={onClick}
      size="small"
      variant="opacity"
    >
      <UIIcon src={DropboxLine} />
    </IconButton>
  ) : (
    <>
      <div
        onMouseEnter={() => {
          if (!isArchivingProvisioned) {
            const timeout = setTimeout(() => setShowArchivePremiumTooltip(true), 200);

            setTooltipTimeout(timeout);
          }
        }}
        onMouseLeave={() => clearTimeout(tooltipTimeout)}
        ref={triggerRef}
      >
        <Button
          disabled={disabled}
          onClick={!isArchivingProvisioned ? () => setUpsellModalIsOpen(true) : onClick}
          variant="opacity"
          withIconLeft={<UIIcon src={DropboxLine} />}
        >
          {archiveButton}
          {!showUpsellModal && !isArchivingProvisioned && <LockLine color="white" width={20} />}
        </Button>
      </div>
      {showUpsellModal ? (
        <BasicAddOnUpsellModal
          clickSource="browse_action_bar"
          onClose={() => setUpsellModalIsOpen(false)}
          open={upsellModalIsOpen}
          variant="archive_to_dbx"
        />
      ) : (
        <PremiumTooltip
          onClose={() => {
            setShowArchivePremiumTooltip(false);
          }}
          open={showArchivePremiumTooltip}
          placement="bottom"
          triggerRef={triggerRef}
        />
      )}
    </>
  );
};
