import type {reel} from '@dropbox/api-v2-client';

import {useUserInfoQueries} from '~/lib/api_queries';

import type {GroupShareRecipient, UserShareRecipient} from './components/common';
import {useDisplayInfoQueriesForCurrentFolder} from './use_display_info';
import type {FolderProp} from '../../lib/api';
import {isFolderDisplayInfo} from '../../lib/api';

export interface FolderToAccounts {
  [folderId: string]: {
    userAccounts: {[accountId: string]: UserShareRecipient};
    groupAccounts: {[groupId: string]: GroupShareRecipient};
  };
}

export const useShareRecipients = (
  currentFolder: FolderProp | null,
): FolderToAccounts | undefined => {
  const {folderDisplayInfoQueries} = useDisplayInfoQueriesForCurrentFolder();

  const folderMembersById: Record<string, reel.ShareRecipientInfo[]> = {};

  if (currentFolder) {
    folderMembersById[currentFolder.folderId] = currentFolder.folderMembers;
  }

  if (currentFolder && currentFolder.isOnlyBasicContent === false && currentFolder.folders) {
    currentFolder.folders.forEach((folder) => {
      if (folder.is_only_basic_content === false && folder.folder_members) {
        folderMembersById[folder.id] = folder.folder_members;
      }
    });
  } else {
    folderDisplayInfoQueries.forEach((query) => {
      if (isFolderDisplayInfo(query.data) && query.data?.folder_members) {
        folderMembersById[query.data.id] = query.data.folder_members;
      }
    });
  }

  const userFolderMap: {
    [folderId: string]: {[accountId: string]: reel.SharedFolderAccessLevel};
  } = {};
  const userRecipients = new Set<string>();
  const groupRecipients = new Set<string>();
  const shareRecipientLookup: FolderToAccounts = {};

  Object.entries(folderMembersById).forEach(([folderId, folderMembers]) => {
    userFolderMap[folderId] = {};
    shareRecipientLookup[folderId] = {
      userAccounts: {},
      groupAccounts: {},
    };
    folderMembers.forEach(function (folderMember) {
      if (folderMember.share_recipient?.recipient_id?.['.tag'] === 'account_id') {
        const accountId = folderMember.share_recipient.recipient_id.account_id;
        userRecipients.add(accountId);
        userFolderMap[folderId][accountId] = folderMember.access_level!;
      } else if (folderMember.share_recipient?.recipient_id?.['.tag'] === 'group_id') {
        const groupId = folderMember.share_recipient.recipient_id.group_id;
        const groupInfo = folderMember.share_recipient.group_info!;
        groupRecipients.add(groupId);
        shareRecipientLookup[folderId].groupAccounts[groupId] = {
          groupId: groupInfo.group_id ?? '',
          displayName: groupInfo.group_name!,
          memberCount: groupInfo.group_size?.toString() ?? '',
          accessLevel: folderMember.access_level!['.tag'],
          amplitudeSafeTeamId: groupInfo.team_id_for_amplitude?.toString() ?? '',
        };
      }
    });
  });

  const userInfoQueries = useUserInfoQueries(Array.from(userRecipients));

  const userInfoMap = userInfoQueries.data;

  for (const [folderId, accessDict] of Object.entries(userFolderMap)) {
    // Ensure the folderId entry exists in shareRecipientLookup and has the required structure
    if (!shareRecipientLookup[folderId]) {
      shareRecipientLookup[folderId] = {
        userAccounts: {},
        groupAccounts: {}, // Preserves the structure even if not used in this loop
      };
    } else if (!shareRecipientLookup[folderId].userAccounts) {
      // Initialize userAccounts if it doesn't exist, without overwriting groupAccounts
      shareRecipientLookup[folderId].userAccounts = {};
    }
    for (const [accountId, accessLevel] of Object.entries(accessDict)) {
      const userInfo = userInfoMap[accountId];
      if (userInfo) {
        shareRecipientLookup[folderId].userAccounts[accountId] = {
          accountId: userInfo.account_id,
          profilePhotoUrl: userInfo.profile_photo_url,
          abbreviatedName: userInfo.name.abbreviated_name,
          displayName: userInfo.name.display_name,
          emailAddress: userInfo.email,
          accessLevel: accessLevel['.tag'],
        };
      }
    }
  }

  return shareRecipientLookup;
};
