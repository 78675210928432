import React from 'react';

import {PAP_Select_TopNavAction} from 'pap-events/replay/select_top_nav_action';
import {FormattedMessage, useIntl} from 'react-intl';

import {Menu} from '@dropbox/dig-components/menu';
import {GlobalHeader} from '@dropbox/dpc-ia/global_header';

import {useReelAppGlobalState} from '~/context';
import {checkPithosPrivacyConsentFlag} from '~/lib/environment';
import {activateCookiesModule} from '~/lib/initialize_privacy_module';
import type {SelectHelpMenuItemOptionsType} from '~/lib/logging/logger_types';
import {SurfaceType} from '~/lib/logging/logger_types';
import {useLoggingClient} from '~/lib/use_logging_client';

import {CANNY_URL} from '../../browse_page/components/canny_button';

type HelpMenuProps = {
  surfaceType?: SurfaceType;
  resetOnboardingMenuItem?: React.ReactNode;
  isEditorPage?: boolean;
  actionSurface?: string;
};

export const HelpMenu = ({
  surfaceType,
  resetOnboardingMenuItem,
  isEditorPage,
  actionSurface,
}: HelpMenuProps) => {
  const intl = useIntl();
  const sessionContext = useReelAppGlobalState();
  const loggingClient = useLoggingClient({
    surface: surfaceType ?? SurfaceType.Unknown,
  });

  const onHelpMenuToggle = ({isOpen}: {isOpen: boolean}) => {
    if (isOpen) {
      // eslint-disable-next-line deprecation/deprecation
      loggingClient.logEvent('select_help_menu');
      loggingClient.logPap(
        PAP_Select_TopNavAction({
          deviceId: loggingClient.deviceId,
          actionElement: 'top_nav',
          actionSurface: actionSurface,
          topNavAction: 'dropdown_menu',
          actionDetail: 'help_menu',
        }),
      );
    }
  };

  const logItemClick = React.useCallback(
    (key: SelectHelpMenuItemOptionsType) => {
      // eslint-disable-next-line deprecation/deprecation
      loggingClient.logEvent('select_help_menu_item', {item: key});
    },
    [loggingClient],
  );

  const onManageCookiesClick = React.useCallback(() => {
    logItemClick('manage_cookies');
    checkPithosPrivacyConsentFlag()
      ? activateCookiesModule()
      : sessionContext.setIsCookieSnackbarOpen(true);
  }, [sessionContext, logItemClick]);

  const FORUM_URL_WITH_UTM_TOKEN =
    'https://www.dropboxforum.com/t5/Dropbox-Replay/gh-p/1009?utm_source=Replay&utm_medium=Web&utm_campaign=Replay-Web';

  return (
    <GlobalHeader.Help
      triggerProps={{
        'aria-label': intl.formatMessage({
          defaultMessage: 'Help menu',
          id: 'cxOzQ9',
          description: 'Aria label for the help menu button.',
        }),
      }}
      wrapperActionProps={{onToggle: onHelpMenuToggle}}
    >
      {!isEditorPage && (
        <Menu.Segment>
          <Menu.LinkItem
            href="https://replay.dropbox.com/share-folder/mYaR4zupy2OTzszd"
            key="tutorials"
            onClick={() => logItemClick('tutorials')}
            target="_blank"
          >
            <FormattedMessage
              defaultMessage="Tutorials"
              description="Link to the Tutorials videos page for Dropbox Replay."
              id="3ZOYz8"
            />
          </Menu.LinkItem>
          <Menu.LinkItem
            href={FORUM_URL_WITH_UTM_TOKEN}
            onClick={() => logItemClick('discuss_replay')}
            target="_blank"
          >
            <FormattedMessage
              defaultMessage="Discuss Replay"
              description="Link to the Dropbox Replay forum"
              id="rclYlb"
            />
          </Menu.LinkItem>
          <Menu.LinkItem
            href="https://help.dropbox.com/installs-integrations/photos/dropbox-replay"
            key="help_and_faqs"
            onClick={() => logItemClick('help_and_faqs')}
            target="_blank"
          >
            <FormattedMessage
              defaultMessage="Help and FAQs"
              description="Link to the help and FAQ page for Dropbox Replay."
              id="v/pMjY"
            />
          </Menu.LinkItem>
          <Menu.LinkItem
            href={CANNY_URL}
            key="feedback"
            onClick={() => logItemClick('feedback')}
            target="_blank"
          >
            <FormattedMessage
              defaultMessage="Make a suggestion"
              description="Link to send feedback about Dropbox Replay."
              id="w0smAp"
            />
          </Menu.LinkItem>
          <Menu.LinkItem
            href="mailto:replay-feedback@dropbox.com"
            key="contact_support"
            onClick={() => logItemClick('contact_support')}
          >
            <FormattedMessage
              defaultMessage="Contact support"
              description="Link to email customer support."
              id="Qlo5Qt"
            />
          </Menu.LinkItem>
        </Menu.Segment>
      )}
      <Menu.Segment>
        <Menu.LinkItem
          href="https://www.dropbox.com/privacy"
          key="privacy_policy"
          onClick={() => logItemClick('privacy_policy')}
          target="_blank"
        >
          <FormattedMessage
            defaultMessage="Privacy policy"
            description="Link to the Dropbox privacy policy."
            id="4xnqs7"
          />
        </Menu.LinkItem>
        <Menu.LinkItem
          href="https://help.dropbox.com/accounts-billing/security/cookies"
          key="cookie_policy"
          onClick={() => logItemClick('cookie_policy')}
          target="_blank"
        >
          <FormattedMessage
            defaultMessage="Cookie policy"
            description="Link to the Dropbox cookie policy."
            id="UH21vE"
          />
        </Menu.LinkItem>
        <Menu.ActionItem key="manage_cookies" onClick={() => onManageCookiesClick()}>
          <FormattedMessage
            defaultMessage="Manage cookies"
            description="Link that opens a prompt asking the user to decline or accept cookies."
            id="gtY/p6"
          />
        </Menu.ActionItem>
      </Menu.Segment>
      {!isEditorPage && resetOnboardingMenuItem}
    </GlobalHeader.Help>
  );
};
