import {atom} from 'jotai';

export enum ProToolsConnectionState {
  Disconnected = 1,
  Connecting,
  Connected,
  Errored,
}

export const proToolsConnectionStateAtom = atom<ProToolsConnectionState>(
  ProToolsConnectionState.Disconnected,
);

export const proToolsAppDetectedAtom = atom<boolean>(false);

export const showProToolsConnectionModalAtom = atom<boolean>(false);
export const showProToolsConnectionSnackbarAtom = atom<boolean>(false);
export const showProToolsConnectionUIAtom = atom<boolean>(
  (get) => get(showProToolsConnectionModalAtom) || get(showProToolsConnectionSnackbarAtom),
);

export enum ProToolsExportCommentsState {
  Idle = 1,
  Exporting,
  Exported,
}

export const proToolsExportCommentsStateAtom = atom<ProToolsExportCommentsState>(
  ProToolsExportCommentsState.Idle,
);
export const showProToolsCommentsSnackbarAtom = atom<boolean>(false);

export const proToolsTooltipWasDismissedAtom = atom<boolean>(false);

export const canShowProToolsTooltipAtom = atom((get) => {
  const isConnected = get(proToolsConnectionStateAtom) === ProToolsConnectionState.Connected;
  const wasNotPreviouslyDismissed = get(proToolsTooltipWasDismissedAtom) === false;
  return isConnected && wasNotPreviouslyDismissed;
});
