import {useAtomValue, useSetAtom} from 'jotai';

import {Menu} from '@dropbox/dig-components/menu';
import {Text} from '@dropbox/dig-components/typography';

import {Button} from '~/components/button';
import {useShareModalStrings} from '~/components/share_modal/share_modal_text';
import {
  getSelectedPermission,
  shareModalContentsAtom,
  updateShareModalPermissionAtom,
} from '~/components/share_modal/share_state';

export type ShareLinkPermissionProps = {
  canCreateLinks: boolean;
  ariaLabelId: string;
  disabled: boolean;
};

export const ShareLinkPermissionSelector = (props: ShareLinkPermissionProps) => {
  const {ariaLabelId, canCreateLinks, disabled} = props;
  const shareModalContents = useAtomValue(shareModalContentsAtom);
  const selectedPermission = getSelectedPermission(shareModalContents);
  const setSelectedPermission = useSetAtom(updateShareModalPermissionAtom);
  const text = useShareModalStrings('');

  if (canCreateLinks && !disabled) {
    return (
      <Menu.Wrapper onSelection={setSelectedPermission}>
        {({getContentProps, getTriggerProps}) => (
          <>
            <Button
              {...getTriggerProps()}
              aria-labelledby={ariaLabelId}
              title={selectedPermission === 'review' ? text.reviewLinkText : text.viewOnlyLinkText} // Included so screenreader reads both button title and aria-labelledby
              variant="transparent"
              withDropdownIcon
            >
              <Text isBold={true}>
                {selectedPermission === 'review' ? text.reviewLinkText : text.viewOnlyLinkText}
              </Text>
            </Button>
            <Menu.Content {...getContentProps()} placement="bottom-start">
              <Menu.Segment>
                <Menu.SelectItem key={0} selected={selectedPermission === 'review'} value="review">
                  {text.reviewLinkText}
                </Menu.SelectItem>
                <Menu.SelectItem
                  key={1}
                  selected={selectedPermission === 'view-only'}
                  value="view-only"
                >
                  {text.viewOnlyLinkText}
                </Menu.SelectItem>
              </Menu.Segment>
            </Menu.Content>
          </>
        )}
      </Menu.Wrapper>
    );
  } else {
    return (
      <Text style={{marginLeft: '8px'}}>
        {selectedPermission === 'view-only' ? text.viewOnlyLinkText : text.reviewLinkText}
      </Text>
    );
  }
};
