import React from 'react';

import {yupResolver} from '@hookform/resolvers/yup';
import {PAP_Select_ModalAction} from 'pap-events/replay/select_modal_action';
import {PAP_Shown_Modal} from 'pap-events/replay/shown_modal';
import {useForm} from 'react-hook-form';
import {FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';
import type {Asserts} from 'yup';
import {object, string} from 'yup';

import {FormHelperText, FormLabel, FormRow} from '@dropbox/dig-components/form_row';
import {Modal} from '@dropbox/dig-components/modal';
import {TextInput} from '@dropbox/dig-components/text_fields';
import {Text} from '@dropbox/dig-components/typography';
import {UIIcon} from '@dropbox/dig-icons';
import {FailLine} from '@dropbox/dig-icons/assets';

import {Button} from '~/components/button';
import {useErrorSnackbar} from '~/components/snackbar/error_snackbar';
import {ReplayError, ReplayErrorCategory} from '~/lib/error_reporting';
import {useLoggingClient} from '~/lib/use_logging_client';

import type {FolderLink} from '../../types';

type AddFolderLinkProps = {
  open: boolean;
  requestClose: () => void;
  folderId: string;
  folderName: string;
  addFolderLink: (folderLink: FolderLink, folderId: string) => Promise<void>;
};

const schema = object({
  text: string().trim().required(),
  url: string().trim().url().required(),
}).required();

interface FormValues extends Asserts<typeof schema> {}

const CancelButton = styled(Button)`
  background-color: var(--dig-color__background__raised);
`;

export const AddFolderLinkModal = ({
  open,
  requestClose,
  folderId,
  addFolderLink,
}: AddFolderLinkProps) => {
  const intl = useIntl();
  const {reset, register, handleSubmit, formState} = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues: {
      url: '',
      text: '',
    },
  });
  const {errors, isValid, isSubmitting} = formState;

  const loggingClient = useLoggingClient();
  const {openErrorSnackbar} = useErrorSnackbar();

  React.useEffect(() => {
    if (open) {
      loggingClient.logPap(
        PAP_Shown_Modal({
          modal: 'add_link',
        }),
      );
    }
  }, [loggingClient, open]);

  const createLink = async ({text, url}: FormValues) => {
    loggingClient.logPap(
      PAP_Select_ModalAction({
        modal: 'add_link',
        modalAction: 'create',
      }),
    );

    try {
      await addFolderLink({url, name: text, folder_link_id: ''}, folderId);
      closeModal();
    } catch (e) {
      reportError(
        new ReplayError({
          error: e,
          severity: 'non-critical',
          tags: ['add_folder_link_failed'],
          category: ReplayErrorCategory.UncaughtException,
        }),
      );
      const formattedSnackbarText = intl.formatMessage({
        defaultMessage: 'Unable to update the url.',
        id: '6ZaJba',
        description: 'Snackbar notification informing url could not be created.',
      });
      openErrorSnackbar(formattedSnackbarText);
    }
  };

  const closeModal = () => {
    loggingClient.logPap(
      PAP_Select_ModalAction({
        modal: 'add_link',
        modalAction: 'cancel',
      }),
    );

    requestClose();
  };

  const handleAfterClose = () => {
    reset();
  };

  const withCloseButtonText = intl.formatMessage({
    defaultMessage: 'Close create folder link modal',
    id: 'D5U3LY',
    description: 'aria for the Modal prompting closure for creating folder links',
  });
  const linkPlaceholderText = intl.formatMessage({
    defaultMessage: 'https://',
    id: 'XIFIoe',
    description: 'A placeholder text that requests a link for the given folder.',
  });
  const namePlaceholderText = intl.formatMessage({
    defaultMessage: 'Text to display',
    id: '9/h+Mt',
    description: 'A placeholder text that requests the project reqirment as the link name.',
  });

  return (
    <Modal
      isCentered
      onAfterClose={handleAfterClose}
      onRequestClose={closeModal}
      open={open}
      width="standard"
      withCloseButton={withCloseButtonText}
    >
      <form onSubmit={handleSubmit(createLink)}>
        <Modal.Header hasBottomSpacing="title-small">
          <Modal.Title>
            <FormattedMessage
              defaultMessage="Add a webpage"
              description="Text at the top of the add link modal that informs users that they can add a link."
              id="k/5IT+"
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormRow>
            <FormLabel>
              <Text isBold>
                <FormattedMessage
                  defaultMessage="Url"
                  description="Text over the link text area informing user where links should be added."
                  id="/cAFc5"
                />
              </Text>
            </FormLabel>
            <TextInput {...register('url')} placeholder={linkPlaceholderText} size="large" />
            {errors.url && (
              <FormHelperText variant="alert">
                <UIIcon size="small" src={FailLine} />
                <FormattedMessage
                  defaultMessage="Invalid URL. Please enter a valid URL."
                  description="Error message when URL is invalid."
                  id="MhBGRx"
                />
              </FormHelperText>
            )}
          </FormRow>
          <FormRow>
            <FormLabel>
              <Text isBold>
                <FormattedMessage
                  defaultMessage="Text"
                  description="Text over the url name text area informing user where to name the url."
                  id="84fDlv"
                />
              </Text>
            </FormLabel>
            <TextInput {...register('text')} placeholder={namePlaceholderText} size="large" />
            {errors.text && (
              <FormHelperText variant="alert">
                <UIIcon size="small" src={FailLine} />
                <FormattedMessage
                  defaultMessage="Name is required. Please enter a name for the url."
                  description="Error message when name is not entered."
                  id="rVsc1g"
                />
              </FormHelperText>
            )}
          </FormRow>
        </Modal.Body>
        <Modal.Footer>
          <CancelButton onClick={closeModal} size="large" variant="filled">
            <FormattedMessage
              defaultMessage="Cancel"
              description="Text on button that cancels adding folder link."
              id="oEpj56"
            />
          </CancelButton>
          <Button
            disabled={!isValid}
            isLoading={isSubmitting}
            size="large"
            type="submit"
            variant="primary"
          >
            <FormattedMessage
              defaultMessage="Save"
              description="Text on button that confirms and creates the folder link"
              id="iAtMla"
            />
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
