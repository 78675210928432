import React, {type ReactNode} from 'react';

export const Page = (props: {children: ReactNode; title?: string}) => {
  React.useEffect(() => {
    let title = '';

    if (props.title) {
      title = `${props.title} - Replay`;
    }

    document.title = title;
  }, [props.title]);
  return props.children;
};
