import React, {type ReactElement, type ReactNode} from 'react';

import {useAtomValue} from 'jotai';
import {FormattedMessage, useIntl} from 'react-intl';
import styled, {css} from 'styled-components';
import type {Opaque} from 'type-fest';

import type {reel} from '@dropbox/api-v2-client';
import {Text} from '@dropbox/dig-components/typography';
import {UIIcon} from '@dropbox/dig-icons';
import {
  AudioLine,
  CommentLine,
  FolderLine,
  GifLine,
  ImageLine,
  PdfLine,
  PersonCircleLine,
  ShowLine,
  VideoLine,
} from '@dropbox/dig-icons/assets';

import {Loader} from '~/components/loading';
import {SkeletonRectangle} from '~/components/skeleton';
import type {VersionStatus} from '~/components/status/status';
import {
  getIdFromStatus,
  getStatusFromId,
  STATUSES,
  VERSION_STATUS_PROPERTIES,
} from '~/components/status/status';
import {color} from '~/components/styled';
import {
  convertReelMediaTypeToMediaType,
  createVersion,
  isFolderDisplayInfo,
  isMediaProjectDisplayInfo,
  updateVersionStatus,
} from '~/lib/api';
import type {
  Folder,
  InitialFolder,
  InitialProjectWithVideos,
  InitialVideo,
  MediaType,
  ShareFolderAccessType,
} from '~/lib/api';
import {useDisplayInfoQuery, useInvalidateVideoQueries} from '~/lib/api_queries';
import type {Branding} from '~/lib/branding';
import {getCurrentUser} from '~/lib/client';
import {ReplayError, ReplayErrorCategory, reportException} from '~/lib/error_reporting';
import type {
  MediaSourceType,
  MoveSourceType,
  NewVersionStatusType,
} from '~/lib/logging/logger_types';
import {mapMediaTypeToLoggingType} from '~/lib/logging/logger_types';
import {getReplayGATime} from '~/lib/provisions';
import {useStormcrows} from '~/lib/stormcrow';
import {getTimeForDisplay} from '~/lib/time';
import {
  generateRandomId,
  useAreSuperAdminFeaturesEnabled,
  useHasFullAddonAccess,
  useIsUserGrandfatheredFromBeta,
} from '~/lib/utils';
import type {ModifyItemSnackbarInfo} from '~/pages/browse_page/browse_page';
import type {ProjectMoveConfirmationProps} from '~/pages/browse_page/components/browse_page_snackbar';
import {CreateProjectTile} from '~/pages/browse_page/components/tiles/create_project_tile';
import type {onCreateFolderSuccessFn} from '~/pages/browse_page/use_create_project';
import {useIsCurrentPageSharePage} from '~/route_path_matches';
import {sortedFoldersAtom, sortedProjectsAtom, sortedTeamInfoItemsAtom} from '~/state/browse';

import {PartialLoadingFailure} from './partial_loading_failure';
import {ListTeamInfoRenderer} from '../../admin_console_page/admin_team_folder_row';
import {useBrowseItemsViewContext} from '../browse_items_view_context';
import {useBrowseLogEventMaker} from '../browse_logging_context';
import type {FolderToAccounts} from '../use_share_recipients';

const noop = () => {};

export type CreatedVersionInfo = {projectId: string; videoId: string; videoVersionId?: string};

type RenderProjectCallback = (props: BrowseProjectProps) => ReactElement | null;
type RenderFolderCallback = (props: BrowseFolderProps) => ReactElement | null;

export type NavigationRouteTypes =
  | 'share-folder'
  | 'browse-home'
  | 'browse-shared'
  | 'browse-links'
  | 'admin-page'
  | 'settings-page';
export type NavigationRouteProps = {
  routeName: NavigationRouteTypes;
};

export type ItemLoadingStates = {
  [projectId: string]: {isCreatingVersion: boolean; isUpdatingStatus: boolean};
};

type Project = {isUploading?: false} & reel.ProjectWithVideos;
export const isUploadedProject = (project: BrowseProject): project is Project =>
  !project.isUploading;

export type UploadingProjectId = Opaque<string, 'UploadingProjectId'>;

export type UploadingProject = {
  isUploading: true;
  uploadId: UploadingProjectId;
  project: {name: string};
  folderUpload: boolean;
};

export const isUploadedFolder = (folder: BrowseFolder): folder is Folder =>
  !folder.isUploading && folder.hasOwnProperty('folder_members');

export const isUploadedInitialFolder = (folder: BrowseFolder): folder is InitialFolder =>
  !folder.isUploading;

export type UploadingFolder = {
  isUploading: boolean;
  name: string;
  folderUpload: boolean;
};

export type BrowseProject =
  | ({isUploading?: false} & InitialProjectWithVideos)
  | Project
  | UploadingProject;

export type BrowseFolder = InitialFolder | Folder | UploadingFolder;

export type BrowseItemsProps = {
  hasMorePages: boolean;
  hasFetchError: boolean;
  itemLoadingStates: ItemLoadingStates;
  setItemLoadingStates: (setter: (states: ItemLoadingStates) => ItemLoadingStates) => void;
  parentFolderId?: string;
  currentProjectAmplitudeId?: string;
  updateModifyItemSnackbar: (modifyItemSnackbarInfo: ModifyItemSnackbarInfo) => void;
  isSharedVideoView: boolean;
  isAdminPageView: boolean;
  isRootLevelView: boolean;
  shareRecipients: FolderToAccounts | undefined;
  parentFolderPermission: ShareFolderAccessType;
  settingsChangeCallback?(folderId: string): void;
  updateMoveInProgressSnackbar: (
    moveProps: ProjectMoveConfirmationProps,
    isError: boolean,
    copyUnsupported?: boolean,
  ) => void;
  makeFolderLink: (linkArgs: {folderId: string}) => string;
  makeFileLink: (linkArgs: {shareToken?: string; videoId: string; projectId?: string}) => string;
  shareToken?: string;
  grantBook?: string;
};

export type UserShareRecipient = {
  accountId: string;
  profilePhotoUrl?: string;
  displayName?: string;
  abbreviatedName?: string;
  accessLevel: ShareFolderAccessType;
  emailAddress?: string;
  teamId?: string;
};

export type GroupShareRecipient = {
  groupId: string;
  displayName: string;
  memberCount: string;
  accessLevel: ShareFolderAccessType;
  amplitudeSafeTeamId: string;
};

export type ShareRecipient = UserShareRecipient | GroupShareRecipient;

export function isUserShareRecipient(recipient: ShareRecipient): recipient is UserShareRecipient {
  return (recipient as UserShareRecipient).accountId !== undefined;
}

export function hasEditAccess(recipient: ShareRecipient): boolean {
  return (
    recipient.accessLevel === 'owner' ||
    recipient.accessLevel === 'admin' ||
    recipient.accessLevel === 'super_admin'
  );
}

export function isGroupShareRecipient(recipient: ShareRecipient): recipient is GroupShareRecipient {
  return (recipient as GroupShareRecipient).groupId !== undefined;
}

export type BrowseUploadedFolderProps = {
  isUploading: false;
  folderId: string;
  createdAtDate: Date;
  lastModifiedDate: Date | undefined;
  reelLink: string;
  name: string;
  branding?: Branding;
  parentFolderId?: string;
  currentProjectAmplitudeId?: string;
  isRootLevelView: boolean;
  parentFolderPermission: ShareFolderAccessType;
  accessLevel: ShareFolderAccessType;
  updatedAtDate: Date;
  numProjects?: number;
  onFoldersDelete: () => void;
  onFolderRename: (newProjectName: string) => void;
  updateModifyItemSnackbar: (modifyItemSnackbarInfo: ModifyItemSnackbarInfo) => void;
  onBulkMove: (
    folderIds: string[],
    projectIds: string[],
    destinationFolderId: string,
    destinationFolderName: string,
    moveSource: MoveSourceType,
    showErrorModal: boolean,
    isCopy: boolean,
  ) => void;
  onProjectMove: (
    projectId: string,
    projectName: string,
    destinationFolderId: string,
    destinationFolderName: string,
    moveSource: MoveSourceType,
    isCopy: boolean,
  ) => void;
  onFolderMove: (
    folderId: string,
    folderName: string,
    destinationFolderId: string,
    destinationFolderName: string,
    moveSource: MoveSourceType,
    showErrorModal: boolean,
    isCopy: boolean,
  ) => void;
  openAddPeopleModal: (sharedFolderId: string, sharedFolderName: string) => void;
  openManagePeopleModal: (sharedFolderId: string, sharedFolderName: string) => void;
  shareRecipients?: ShareRecipient[] | LoadingValue;
  isSharedVideoView: boolean;
  settingsChangeCallback?(folderId: string): void;
  updateMoveInProgressSnackbar: (
    moveProps: ProjectMoveConfirmationProps,
    isError: boolean,
    copyUnsupported?: boolean,
  ) => void;
  allowSuperAdminRights?: boolean;
};

export type BrowseUploadingFolderProps = {
  name: string;
  folderId: string;
  isRootLevelView: boolean;
  isUploading: true;
};

const LOADING = 'loading' as const;

export type LoadingValue = typeof LOADING;

export type BrowseUploadedProjectProps = {
  createdAtDate: Date;
  currentProjectAmplitudeId?: string;
  durationDisplayString: string;
  grantBook?: string;
  hasPassword?: boolean;
  isCreatingVersion: boolean;
  isDemo: boolean;
  isRootLevelView: boolean;
  isSharedVideoView: boolean;
  isUpdatingStatus: boolean;
  isUploading: false;
  lastModifiedDate?: Date;
  mediaType: MediaType | LoadingValue;
  name: string;
  nsId?: number;
  numComments: number | LoadingValue;
  onProjectRename: (newProjectName: string) => void;
  onProjectsDelete: () => void;
  onProjectTransfer: (accountId: string) => void;
  onVersionSelected: (
    videoVersionId: string,
    fileIdType: 'file_id' | 'file_path',
    fileSource: MediaSourceType,
    needsCopy: boolean,
    uploadId?: string,
  ) => Promise<{projectId?: string; videoId?: string; videoVersionId?: string}>;
  onVersionStatusSelected: (newStatus: VersionStatus) => void;
  ownerUid?: string;
  parentFolderId?: string;
  parentFolderName?: string;
  parentFolderPermission: ShareFolderAccessType;
  projectId: string;
  reelLink: string;
  shareToken?: string;
  shouldShowNewBadge?: boolean;
  showFileLimitBadge: boolean;
  status: number;
  thumbnailSrc?: string;
  updateModifyItemSnackbar: (modifyItemSnackbarInfo: ModifyItemSnackbarInfo) => void;
  versionNumber?: number;
  versionSummaries: Array<reel.VersionSummary>;
  videoId: string;
  videoIdForAmplitude?: string;
  videoToken?: string;
  videoVersionId: string;
  viewerCount?: number;
  waveformUrl?: string;
  ownerAddonEnabled: boolean;
  fileExtension?: string;
};

export type BrowseUploadingProjectProps = {
  isUploading: true;
  name: string;
  projectId: string;
  parentFolderId?: string;
  folderUpload?: boolean;
};

export type BrowseProjectProps = BrowseUploadedProjectProps | BrowseUploadingProjectProps;
export type BrowseFolderProps = BrowseUploadedFolderProps | BrowseUploadingFolderProps;

export const isUploadedFolderProps = (
  folderProps: BrowseFolderProps,
): folderProps is BrowseUploadedFolderProps => !folderProps.isUploading;

export const isFullVideo = (video: InitialVideo | reel.Video): video is reel.Video => {
  return !video.is_only_basic_content;
};

export function findFolderCreationTime(folder: BrowseFolder) {
  if (!isUploadedFolder(folder)) {
    return new Date().toUTCString();
  }
  if (folder.creation_timestamp) {
    return new Date(folder.creation_timestamp);
  }
  return new Date().toUTCString();
}

export function findFolderLastModifiedTime(folder: BrowseFolder) {
  if (!isUploadedFolder(folder)) {
    return new Date();
  }
  if (folder.last_modified_time) {
    return new Date(folder.last_modified_time);
  }
  if (folder.creation_timestamp) {
    return new Date(folder.creation_timestamp);
  }
  return new Date();
}

export function findMediaProjectLatestUpdateTime(
  projectWithVideo: BrowseProject,
): Date | undefined {
  if (projectWithVideo.isUploading || !projectWithVideo || !projectWithVideo.project) {
    return new Date();
  }

  if (projectWithVideo.project.last_modified_time) {
    return new Date(projectWithVideo.project.last_modified_time);
  }

  if (!projectWithVideo.project.creation_timestamp) {
    return new Date();
  }

  let latestTimestamp = new Date(projectWithVideo.project.creation_timestamp);
  const projectVideos = projectWithVideo.videos;
  if (projectVideos) {
    const video = projectVideos[0];
    if (isFullVideo(video) && video.comments_info && video.comments_info.latest_timestamp) {
      const commentTimestamp = new Date(video.comments_info.latest_timestamp);
      latestTimestamp = commentTimestamp > latestTimestamp ? commentTimestamp : latestTimestamp;
    }
    if (video.version_summaries) {
      video.version_summaries.forEach(function (versionSummary: reel.VersionSummary) {
        if (versionSummary.upload_timestamp) {
          const versionUploadTimestamp = new Date(versionSummary.upload_timestamp);
          latestTimestamp =
            versionUploadTimestamp > latestTimestamp ? versionUploadTimestamp : latestTimestamp;
        }
        return latestTimestamp;
      });
    }
  }
  return latestTimestamp;
}

const ListProjectRenderer = (
  props: Omit<
    BrowseUploadedProjectProps,
    | 'onProjectsDelete'
    | 'onProjectRename'
    | 'onProjectTransfer'
    | 'onVersionSelected'
    | 'onVersionStatusSelected'
    | 'isCreatingVersion'
    | 'isUpdatingStatus'
  > & {
    renderProject: RenderProjectCallback;
    setIsCreatingVersion: (value: boolean) => void;
    isCreatingVersion: boolean;
    setIsUpdatingStatus: (value: boolean) => void;
    isUpdatingStatus: boolean;
  },
) => {
  const {
    renderProject,
    setIsCreatingVersion,
    isCreatingVersion,
    setIsUpdatingStatus,
    isUpdatingStatus,
    shareToken,
    grantBook,
    ...itemProps
  } = props;

  const {projectId, videoId, videoVersionId, videoIdForAmplitude, mediaType, status} = itemProps;

  const invalidateVideoQueries = useInvalidateVideoQueries();
  const {editActionsProps, onProjectsDelete} = useBrowseItemsViewContext();
  const makeLogEvent = useBrowseLogEventMaker();
  const logEvent = React.useMemo(
    () =>
      makeLogEvent({
        videoId: videoIdForAmplitude,
        reelObjectType: mapMediaTypeToLoggingType(mediaType),
      }),
    [makeLogEvent, videoIdForAmplitude, mediaType],
  );
  const onVersionSelected = React.useCallback(
    async (
      fileId: string,
      fileIdType: 'file_id' | 'file_path',
      mediaSource: MediaSourceType,
      needsCopy: boolean,
      uploadId?: string,
    ) => {
      try {
        if (!editActionsProps.editActionsEnabled) {
          // Should be impossible
          return {};
        }
        setIsCreatingVersion(true);
        const {video: videoVersion, videoVersionId} = await createVersion({
          videoId,
          newVersionFileId: fileId,
          namespaceCopy: needsCopy,
          fileSourceType: fileIdType,
        });
        await invalidateVideoQueries(videoId);

        if (!videoVersion || !videoVersion.version_num) {
          // Something has gone seriously wrong
          throw new Error("Create version endpoint didn't return a valid version");
        }

        // eslint-disable-next-line deprecation/deprecation
        logEvent('create_new_version', {
          click_source: 'browse_expanded_menu',
          media_source: mediaSource,
          media_type: mapMediaTypeToLoggingType(mediaType),
        });

        editActionsProps.onVersionUpload(
          projectId,
          videoVersion,
          {
            videoVersionNumber: videoVersion.version_num,
            projectName: itemProps.name,
            projectLink: itemProps.reelLink,
          },
          needsCopy,
          uploadId,
        );
        setIsCreatingVersion(false);

        return {
          projectId: videoVersion.project_id || projectId,
          videoId: videoVersion.video_id,
          videoVersionId,
          fileSize: videoVersion.file_size_bytes,
          videoIdForAmplitude: videoVersion.video_id_for_amplitude,
          versionIdForAmplitude: videoVersion.version_id_for_amplitude,
        };
      } catch (err) {
        reportError(err);
        setIsCreatingVersion(false);
        throw err;
      }
    },
    [
      editActionsProps,
      invalidateVideoQueries,
      itemProps.name,
      itemProps.reelLink,
      logEvent,
      mediaType,
      projectId,
      setIsCreatingVersion,
      videoId,
    ],
  );

  const onVersionStatusSelected = React.useCallback(
    async (newStatus: VersionStatus) => {
      // Note: We intentionally do not check if edit actions are enabled because any logged in user can change the status

      if (newStatus === getStatusFromId(status)) {
        return;
      }

      setIsUpdatingStatus(true);
      try {
        editActionsProps.onVersionStatusChange(
          projectId,
          videoVersionId,
          getIdFromStatus(newStatus),
        );
        await updateVersionStatus({
          grantBook,
          newStatus,
          projectId,
          shareToken,
          videoVersionId,
        });
      } catch (e) {
        editActionsProps.onVersionStatusChange(projectId, videoVersionId, status);
        reportException(
          new ReplayError({
            error: e,
            message: `Failed to update version status. Reverting to previous status. Original error: ${e.message}`,
            severity: 'non-critical',
            // Prevents logging name/message until we verify that e.message is safe (no L0 data)
            category: ReplayErrorCategory.UncaughtException,
          }),
        );
      }
      setIsUpdatingStatus(false);

      // eslint-disable-next-line deprecation/deprecation
      logEvent('select_version_status', {
        new_version_status: newStatus.toLowerCase() as NewVersionStatusType,
      });
    },
    [
      editActionsProps,
      grantBook,
      logEvent,
      projectId,
      setIsUpdatingStatus,
      shareToken,
      status,
      videoVersionId,
    ],
  );

  const rendered = renderProject({
    ...itemProps,
    isCreatingVersion,
    isUpdatingStatus,
    onProjectsDelete() {
      onProjectsDelete([projectId]);
    },
    onProjectRename(projectName: string) {
      if (editActionsProps.editActionsEnabled) {
        editActionsProps.onProjectRename(projectId, projectName);
      }
    },
    onProjectTransfer(accountId: string) {
      if (editActionsProps.editActionsEnabled) {
        editActionsProps.onProjectTransfer(accountId);
      }
    },
    onVersionSelected,
    onVersionStatusSelected,
  });

  // make types happy
  return rendered ?? null;
};

interface ListUploadingProjectRendererProps extends BrowseUploadingProjectProps {
  renderProject: RenderProjectCallback;
}

const ListUploadingProjectRenderer = (props: ListUploadingProjectRendererProps) => {
  const {renderProject, ...itemProps} = props;

  const rendered = renderProject(itemProps);

  // make types happy
  return rendered ?? null;
};

type FolderFetcherProps = Pick<
  ListItemsRendererProps,
  | 'currentProjectAmplitudeId'
  | 'isRootLevelView'
  | 'isSharedVideoView'
  | 'parentFolderId'
  | 'parentFolderPermission'
  | 'settingsChangeCallback'
  | 'shareRecipients'
  | 'updateModifyItemSnackbar'
  | 'updateMoveInProgressSnackbar'
  | 'renderFolder'
  | 'makeFolderLink'
>;

const ListFolderFetcher = (
  props: FolderFetcherProps & {folder: InitialFolder | Folder; numProjects: number | undefined},
) => {
  const {
    makeFolderLink,
    renderFolder,
    folder,
    currentProjectAmplitudeId,
    parentFolderId,
    parentFolderPermission,
    isSharedVideoView,
    isRootLevelView,
    settingsChangeCallback,
    updateModifyItemSnackbar,
    updateMoveInProgressSnackbar,
    shareRecipients,
  } = props;

  const folderPublicId = folder.id;

  const isSharePage = useIsCurrentPageSharePage();
  const fullDisplayInfoQuery = useDisplayInfoQuery(folderPublicId, !isSharePage);

  let folderShareRecipients: ShareRecipient[] | LoadingValue = [];

  if (shareRecipients) {
    const localShareRecipients = shareRecipients[folder.id];
    if (localShareRecipients) {
      const folderMembers =
        !folder.is_only_basic_content && folder.folder_members
          ? folder.folder_members
          : isFolderDisplayInfo(fullDisplayInfoQuery.data) &&
            fullDisplayInfoQuery.data.folder_members
          ? fullDisplayInfoQuery.data.folder_members
          : [];

      folderMembers.forEach((members) => {
        const recipient = members.share_recipient?.recipient_id;

        if (
          // This is redundant but satisfies TS
          Array.isArray(folderShareRecipients) &&
          shareRecipients &&
          recipient &&
          recipient?.['.tag'] === 'account_id'
        ) {
          const newUserRecipient = localShareRecipients.userAccounts[recipient.account_id];
          if (newUserRecipient) folderShareRecipients.push(newUserRecipient);
        }
        if (
          Array.isArray(folderShareRecipients) &&
          shareRecipients &&
          recipient &&
          recipient?.['.tag'] === 'group_id'
        ) {
          const newGroupRecipient = localShareRecipients.groupAccounts[recipient.group_id];
          if (newGroupRecipient) folderShareRecipients.push(newGroupRecipient);
        }
      });
    }
  } else {
    folderShareRecipients = 'loading';
  }

  const {editActionsProps} = useBrowseItemsViewContext();

  let numProjects = folder.is_only_basic_content ? undefined : props.numProjects;
  let branding = folder.branding as Branding | undefined;
  if (fullDisplayInfoQuery.isSuccess && isFolderDisplayInfo(fullDisplayInfoQuery.data)) {
    numProjects = fullDisplayInfoQuery.data.num_projects ?? props.numProjects;
    branding = fullDisplayInfoQuery.data.branding as Branding | undefined;
  }

  if (
    fullDisplayInfoQuery.isSuccess &&
    isFolderDisplayInfo(fullDisplayInfoQuery.data) &&
    fullDisplayInfoQuery.data.num_projects
  ) {
    numProjects = fullDisplayInfoQuery.data.num_projects;
  }

  const intl = useIntl();
  const untitledString = intl.formatMessage({
    defaultMessage: 'Untitled',
    id: 'L5/Kpd',
    description: 'Placeholder name for projects or videos that do not have names',
  });

  const reelLink = makeFolderLink({folderId: folder.id});

  return (
    <ListFolderRenderer
      accessLevel={folder.access_level ? folder.access_level!['.tag'] : 'none'}
      allowSuperAdminRights={folder.are_admin_rights_available}
      branding={branding}
      createdAtDate={folder.creation_timestamp ? new Date(folder.creation_timestamp) : new Date()}
      currentProjectAmplitudeId={currentProjectAmplitudeId}
      folderId={folder.id}
      isRootLevelView={isRootLevelView}
      isSharedVideoView={isSharedVideoView}
      isUploading={false}
      key={folder.id}
      lastModifiedDate={findFolderLastModifiedTime(folder)}
      name={folder.name ? folder.name : untitledString}
      numProjects={numProjects}
      openAddPeopleModal={
        editActionsProps.editActionsEnabled ? editActionsProps.openAddPeopleModal : noop
      }
      openManagePeopleModal={
        editActionsProps.editActionsEnabled && folder.are_admin_rights_available
          ? editActionsProps.openManagePeopleModal
          : noop
      }
      parentFolderId={parentFolderId}
      parentFolderPermission={parentFolderPermission}
      reelLink={reelLink}
      renderFolder={renderFolder}
      settingsChangeCallback={settingsChangeCallback}
      shareRecipients={folderShareRecipients}
      updateModifyItemSnackbar={updateModifyItemSnackbar}
      updateMoveInProgressSnackbar={updateMoveInProgressSnackbar}
      updatedAtDate={findFolderLastModifiedTime(folder)}
    />
  );
};

interface ListFolderRendererProps
  extends Omit<
    BrowseUploadedFolderProps,
    'onFoldersDelete' | 'onFolderRename' | 'onBulkMove' | 'onProjectMove' | 'onFolderMove'
  > {
  renderFolder: RenderFolderCallback;
}

const ListFolderRenderer = (props: ListFolderRendererProps) => {
  const {renderFolder, ...itemProps} = props;
  const {folderId} = itemProps;

  const {editActionsProps, onFoldersDelete} = useBrowseItemsViewContext();

  const rendered = renderFolder({
    ...itemProps,
    onFoldersDelete() {
      onFoldersDelete([folderId]);
    },
    onFolderRename(folderName: string) {
      if (editActionsProps.editActionsEnabled) {
        editActionsProps.onFolderRename(folderId, folderName);
      }
    },
    onProjectMove(
      projectId: string,
      projectName: string,
      destinationFolderId: string,
      destinationFolderName: string,
      moveSource: MoveSourceType,
      isCopy: boolean,
    ) {
      if (editActionsProps.editActionsEnabled) {
        editActionsProps.onProjectMove(
          projectId,
          projectName,
          destinationFolderId,
          destinationFolderName,
          moveSource,
          isCopy,
        );
      }
    },
    onBulkMove(
      folderIds: string[],
      projectIds: string[],
      destinationFolderId: string,
      destinationFolderName: string,
      moveSource: MoveSourceType,
      showErrorModal: boolean,
      isCopy: boolean,
    ) {
      if (editActionsProps.editActionsEnabled) {
        editActionsProps.onBulkMove(
          folderIds,
          projectIds,
          destinationFolderId,
          destinationFolderName,
          moveSource,
          showErrorModal,
          isCopy,
        );
      }
    },
    onFolderMove(
      folderId: string,
      folderName: string,
      destinationFolderId: string,
      destinationFolderName: string,
      moveSource: MoveSourceType,
      showErrorModal: boolean,
      isCopy: boolean,
    ) {
      if (editActionsProps.editActionsEnabled) {
        editActionsProps.onFolderMove(
          folderId,
          folderName,
          destinationFolderId,
          destinationFolderName,
          moveSource,
          showErrorModal,
          isCopy,
        );
      }
    },
  });
  return rendered ?? null;
};

interface ListUploadingFolderRendererProps extends BrowseUploadingFolderProps {
  renderFolder: RenderFolderCallback;
}

const ListUploadingFolderRenderer = ({
  renderFolder,
  ...itemProps
}: ListUploadingFolderRendererProps) => renderFolder(itemProps);

interface ListItemsRendererProps extends BrowseItemsProps {
  renderProject: RenderProjectCallback;
  renderFolder: RenderFolderCallback;
  wrapper?: React.JSXElementConstructor<{children: ReactNode}>;
  showCreateProjectPrompt?: boolean;
  onCreateFolderSuccessFn?: onCreateFolderSuccessFn;
}

type ProjectFetcherProps = Pick<
  ListItemsRendererProps,
  | 'currentProjectAmplitudeId'
  | 'grantBook'
  | 'isRootLevelView'
  | 'isSharedVideoView'
  | 'itemLoadingStates'
  | 'makeFileLink'
  | 'parentFolderPermission'
  | 'renderProject'
  | 'setItemLoadingStates'
  | 'shareToken'
  | 'updateModifyItemSnackbar'
>;

const ListProjectFetcher = (
  props: ProjectFetcherProps & {
    project: ({isUploading?: false} & InitialProjectWithVideos) | Project;
  },
) => {
  const {
    currentProjectAmplitudeId,
    grantBook,
    isRootLevelView,
    isSharedVideoView,
    itemLoadingStates,
    makeFileLink,
    parentFolderPermission,
    project,
    renderProject,
    setItemLoadingStates,
    shareToken,
    updateModifyItemSnackbar,
  } = props;

  const stormcrows = useStormcrows();
  const currentUserId = String(getCurrentUser()?.id);
  const replayGATime = getReplayGATime(stormcrows);
  const isBetaUser = useIsUserGrandfatheredFromBeta();
  const hasFullAddonAccess = useHasFullAddonAccess();
  const isSharePage = useIsCurrentPageSharePage();

  if (!project.project || !project.videos) {
    throw new Error('Project and video data missing from API response');
  }

  const mediaProjectPublicId = project.project.id;
  const fullDisplayInfoQuery = useDisplayInfoQuery(mediaProjectPublicId, !isSharePage);

  const {creation_timestamp: creationTimestamp, name, id: projectId} = project.project;

  if (!name || !creationTimestamp) {
    throw new Error('Project data missing');
  }

  const createdAtDate = new Date(creationTimestamp);

  // There should really only ever be one of these
  const [video] = project.videos.sort((v1, v2) =>
    v1 && v2 && v1.version_num && v2.version_num ? v2.version_num - v1.version_num : 0,
  );

  const getListItemDataFromVideo = (
    video: InitialVideo | reel.Video,
  ): {
    // Optional attributes are derived from InitialVideo but are not
    // available in reel.Video
    durationSeconds: number | LoadingValue;
    isDemo: boolean;
    mediaType: MediaType | LoadingValue;
    nsId?: number;
    numComments: number | LoadingValue;
    ownerUid?: string;
    requiresPassword?: boolean;
    shouldDisplayNewBadge?: boolean;
    status?: number;
    thumbnailSrc?: string | LoadingValue;
    versionNumber?: number;
    versionSummaries?: reel.VersionSummary[];
    videoId: string;
    videoIdForAmplitude?: string;
    videoToken?: string;
    viewerCount?: number;
    waveformUrl?: string | LoadingValue;
    ownerAddonEnabled: boolean;
    fileExtension?: string;
  } => {
    const {
      is_demo: isDemo,
      owner_uid: ownerUid,
      status: status,
      version_num: versionNumber,
      version_summaries: versionSummaries,
      video_id: videoId,
      viewer_count: viewerCount,
      owner_addon_enabled: ownerAddonEnabled,
    } = video;

    if (videoId == null || isDemo === undefined) {
      throw new Error('InitialVideo data missing');
    }

    const shared = {
      durationSeconds: 'loading' as const,
      isDemo,
      mediaType: 'loading' as const,
      numComments: 'loading' as const,
      ownerUid,
      status,
      thumbnailSrc: 'loading' as const,
      versionNumber,
      versionSummaries,
      videoId,
      viewerCount,
      waveformUrl: 'loading' as const,
      ownerAddonEnabled: !!ownerAddonEnabled,
    };

    let commentsInfo,
      durationSeconds,
      fileExtension,
      nsId,
      reelMediaType,
      requiresPassword,
      shouldDisplayNewBadge,
      fullStatus,
      thumbnailSrc,
      fullVersionNumber,
      fullVersionSummaries,
      fullViewerCount,
      videoIdForAmplitude,
      videoToken,
      waveformUrl;

    if (
      fullDisplayInfoQuery.isSuccess &&
      fullDisplayInfoQuery.data &&
      isMediaProjectDisplayInfo(fullDisplayInfoQuery.data)
    ) {
      ({
        comments_info: commentsInfo,
        media_type: reelMediaType,
        ns_id: nsId,
        requires_password: requiresPassword,
        should_display_new_badge: shouldDisplayNewBadge,
        status: fullStatus,
        thumbnail_url: thumbnailSrc,
        version_num: fullVersionNumber,
        version_summaries: fullVersionSummaries,
        video_id_for_amplitude: videoIdForAmplitude,
        video_token: videoToken,
        viewer_count: fullViewerCount,
        waveform_url: waveformUrl,
        file_extension: fileExtension,
      } = fullDisplayInfoQuery.data);
      durationSeconds = fullDisplayInfoQuery.data.video_metadata?.duration ?? 0;
    } else if (fullDisplayInfoQuery.isError) {
      waveformUrl = '';
      thumbnailSrc = '';
      reelMediaType = {'.tag': 'other' as const};
      commentsInfo = {num_comments: 0};
      durationSeconds = 0;
    } else if (isFullVideo(video)) {
      ({
        comments_info: commentsInfo,
        media_type: reelMediaType,
        ns_id: nsId,
        requires_password: requiresPassword,
        should_display_new_badge: shouldDisplayNewBadge,
        status: fullStatus,
        thumbnail_url: thumbnailSrc,
        version_num: fullVersionNumber,
        version_summaries: fullVersionSummaries,
        video_id_for_amplitude: videoIdForAmplitude,
        video_token: videoToken,
        viewer_count: fullViewerCount,
        waveform_url: waveformUrl,
        file_extension: fileExtension,
      } = video);
      durationSeconds = video.video_metadata?.duration ?? 0;
    } else {
      return shared;
    }

    if (
      // commentsInfo will be missing if... there are no comments?
      !commentsInfo ||
      commentsInfo.num_comments === undefined ||
      !reelMediaType
    ) {
      throw new Error('reel.Video data missing');
    }

    const mediaType = convertReelMediaTypeToMediaType(reelMediaType);

    return {
      ...shared,
      durationSeconds: durationSeconds ?? 'loading',
      mediaType,
      numComments: commentsInfo?.num_comments ?? 0,
      nsId,
      requiresPassword,
      shouldDisplayNewBadge,
      status: fullStatus,
      thumbnailSrc,
      versionNumber: fullVersionNumber,
      versionSummaries: fullVersionSummaries,
      videoIdForAmplitude,
      videoToken,
      viewerCount: fullViewerCount,
      waveformUrl,
      fileExtension,
    };
  };

  const {
    durationSeconds,
    isDemo,
    nsId,
    mediaType,
    numComments,
    ownerUid,
    requiresPassword,
    shouldDisplayNewBadge,
    status,
    thumbnailSrc,
    versionNumber,
    versionSummaries,
    videoId,
    videoIdForAmplitude,
    videoToken,
    viewerCount,
    waveformUrl,
    ownerAddonEnabled,
    fileExtension,
  } = getListItemDataFromVideo(video);

  const reelLink = makeFileLink({
    shareToken: videoToken,
    projectId,
    videoId,
  });

  const currentUserIsOwner = ownerUid === currentUserId;
  const fileCreatedAfterGA = createdAtDate > replayGATime;

  // Beta users can have files created before GA that do not count against the file limit.
  // We show a file limit badge so they can determine which of their files count towards the limit.
  const showFileLimitBadge =
    isBetaUser &&
    !hasFullAddonAccess &&
    currentUserIsOwner &&
    fileCreatedAfterGA &&
    !isSharePage &&
    !isDemo;

  return (
    <ListProjectRenderer
      createdAtDate={createdAtDate}
      currentProjectAmplitudeId={currentProjectAmplitudeId}
      durationDisplayString={
        durationSeconds !== 'loading' ? getTimeForDisplay(durationSeconds) : 'loading'
      }
      fileExtension={fileExtension}
      grantBook={grantBook}
      hasPassword={requiresPassword}
      isCreatingVersion={itemLoadingStates[projectId]?.isCreatingVersion ?? false}
      isDemo={isDemo}
      isRootLevelView={isRootLevelView}
      isSharedVideoView={isSharedVideoView}
      isUpdatingStatus={itemLoadingStates[projectId]?.isUpdatingStatus ?? false}
      isUploading={false}
      key={videoId}
      lastModifiedDate={findMediaProjectLatestUpdateTime(project)}
      mediaType={mediaType}
      name={name}
      nsId={nsId}
      numComments={numComments}
      ownerAddonEnabled={ownerAddonEnabled}
      ownerUid={ownerUid}
      parentFolderPermission={parentFolderPermission}
      projectId={projectId}
      reelLink={reelLink}
      renderProject={renderProject}
      setIsCreatingVersion={(value: boolean) => {
        setItemLoadingStates((states: ItemLoadingStates) => ({
          ...states,
          [projectId]: {...states[projectId], isCreatingVersion: value},
        }));
      }}
      setIsUpdatingStatus={(value: boolean) => {
        setItemLoadingStates((states: ItemLoadingStates) => ({
          ...states,
          [projectId]: {...states[projectId], isUpdatingStatus: value},
        }));
      }}
      shareToken={shareToken}
      shouldShowNewBadge={shouldDisplayNewBadge}
      showFileLimitBadge={showFileLimitBadge}
      status={status ? status : 0}
      thumbnailSrc={thumbnailSrc}
      updateModifyItemSnackbar={updateModifyItemSnackbar}
      versionNumber={versionNumber}
      versionSummaries={versionSummaries ? versionSummaries : []}
      videoId={videoId}
      videoIdForAmplitude={videoIdForAmplitude}
      videoToken={videoToken}
      videoVersionId={video.id}
      viewerCount={viewerCount}
      waveformUrl={waveformUrl}
    />
  );
};

export const ListItemsRenderer = (props: ListItemsRendererProps) => {
  const {
    currentProjectAmplitudeId,
    grantBook,
    hasMorePages,
    hasFetchError,
    isRootLevelView,
    isSharedVideoView,
    isAdminPageView,
    itemLoadingStates,
    makeFileLink,
    makeFolderLink,
    parentFolderId,
    parentFolderPermission,
    renderFolder,
    renderProject,
    setItemLoadingStates,
    settingsChangeCallback,
    shareToken,
    shareRecipients,
    updateModifyItemSnackbar,
    updateMoveInProgressSnackbar,
    wrapper: Wrapper = React.Fragment,
  } = props;

  const folders = useAtomValue(sortedFoldersAtom);
  const projects = useAtomValue(sortedProjectsAtom);
  const teamInfoItems = useAtomValue(sortedTeamInfoItemsAtom);

  const areSuperAdminFeaturesEnabled = useAreSuperAdminFeaturesEnabled();

  if (!projects && !teamInfoItems) {
    return null;
  }

  const shouldShowFoldersRow = !!folders && (folders.length > 0 || !!props.showCreateProjectPrompt);

  return (
    <>
      {shouldShowFoldersRow && (
        <Wrapper>
          {folders.length === 0 && props.showCreateProjectPrompt && (
            <CreateProjectTile onCreateFolderSuccess={props.onCreateFolderSuccessFn} />
          )}
          {folders.map((folder) => {
            if (!folder) {
              return null;
            }

            if (!isUploadedInitialFolder(folder) && !isUploadedFolder(folder)) {
              const name = folder.name;
              // Creates a temporary folder ID for uniqueness in key
              const folderId = `uploading-${generateRandomId()}`;

              return (
                <ListUploadingFolderRenderer
                  folderId={folderId}
                  isRootLevelView={isRootLevelView}
                  isUploading
                  key={folderId}
                  name={name}
                  renderFolder={renderFolder}
                />
              );
            }

            return (
              <ListFolderFetcher
                currentProjectAmplitudeId={currentProjectAmplitudeId}
                folder={folder}
                isRootLevelView={isRootLevelView}
                isSharedVideoView={isSharedVideoView}
                key={folder.id}
                makeFolderLink={makeFolderLink}
                numProjects={folder.num_projects}
                parentFolderId={parentFolderId}
                parentFolderPermission={parentFolderPermission}
                renderFolder={renderFolder}
                settingsChangeCallback={settingsChangeCallback}
                shareRecipients={shareRecipients}
                updateModifyItemSnackbar={updateModifyItemSnackbar}
                updateMoveInProgressSnackbar={updateMoveInProgressSnackbar}
              />
            );
          })}
        </Wrapper>
      )}
      {projects && (
        <Wrapper>
          {projects?.map((project) => {
            if (project.isUploading) {
              const name = project.project.name;

              return (
                <ListUploadingProjectRenderer
                  folderUpload={project.folderUpload}
                  isUploading
                  key={project.uploadId}
                  name={name}
                  projectId={project.uploadId}
                  renderProject={renderProject}
                />
              );
            }
            return (
              <ListProjectFetcher
                currentProjectAmplitudeId={currentProjectAmplitudeId}
                grantBook={grantBook}
                isRootLevelView={isRootLevelView}
                isSharedVideoView={isSharedVideoView}
                itemLoadingStates={itemLoadingStates}
                key={project.project?.id}
                makeFileLink={makeFileLink}
                parentFolderPermission={parentFolderPermission}
                project={project}
                renderProject={renderProject}
                setItemLoadingStates={setItemLoadingStates}
                shareToken={shareToken}
                updateModifyItemSnackbar={updateModifyItemSnackbar}
              />
            );
          })}
        </Wrapper>
      )}
      {isAdminPageView && areSuperAdminFeaturesEnabled && teamInfoItems && (
        <Wrapper>
          {teamInfoItems.map((teamInfoItem) => {
            return (
              <ListTeamInfoRenderer key={teamInfoItem.folderName} teamInfoItem={teamInfoItem} />
            );
          })}
        </Wrapper>
      )}
      {hasMorePages && <CenteredLoadingSpinner />}
      {hasFetchError && <PartialLoadingFailure />}
    </>
  );
};

const CenteredRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
`;

const CenteredLoadingSpinner = () => (
  <CenteredRow>
    <Loader />
  </CenteredRow>
);

type StatusDotProps = {
  $dotSize: number;
  $dotTextSpacing: number;
  $status: number;
};

export const StatusDot = styled.div<StatusDotProps>`
  border-radius: 50%;
  display: inline-flex;
  height: ${(props) => props.$dotSize}px;
  margin-right: ${(props) => props.$dotTextSpacing}px;
  width: ${(props) => props.$dotSize}px;

  ${({$status}) =>
    css`
      background: ${STATUSES[$status] ? VERSION_STATUS_PROPERTIES[STATUSES[$status]].color : ''};
    `}
`;

export const ItemTypeIcon = styled.div`
  color: var(--color__white);
  padding-right: 12px;
  padding-top: 3px;
`;

export const ItemTypeText = styled.p`
  display: inline-flex;
  margin: 0;
`;

export const StatusTextWrapper = styled.p`
  display: inline-flex;
  margin: 0;
`;

const FlexLabel = styled.div`
  display: flex;
  align-items: center;
`;

type StatusLabelProps = {
  status: number;
  textColor?: 'standard' | 'faint' | 'disabled' | 'error';
  textInverse?: boolean;
  textSize: 'small' | 'medium';
  dotSize: 8 | 12;
  dotTextSpacing: 4 | 6 | 8;
};

export const StatusLabel = ({
  status,
  textColor,
  textInverse,
  textSize,
  dotSize,
  dotTextSpacing,
}: StatusLabelProps) => {
  const intl = useIntl();
  const noStatus = intl.formatMessage({
    defaultMessage: 'No status',
    id: 'XMrjDZ',
    description: 'Status entry for when a file has no status (default)',
  });
  const needsReview = intl.formatMessage({
    defaultMessage: 'Needs review',
    id: 'P6fQoV',
    description: 'Status entry for when a file needs review',
  });
  const inProgress = intl.formatMessage({
    defaultMessage: 'In progress',
    id: 'eQLBsI',
    description: 'Status entry for when a file is in review',
  });
  const editsRequested = intl.formatMessage({
    defaultMessage: 'Edits requested',
    id: 'LwrUOj',
    description: 'Status entry for when someone has requested edits to a file',
  });
  const approved = intl.formatMessage({
    defaultMessage: 'Approved',
    id: 'Mc4EuK',
    description: 'Status entry for when a file has been approved',
  });

  const statii = [noStatus, needsReview, inProgress, approved, editsRequested];
  const statusLabel = statii[status] ? statii[status] : statii[0];

  return (
    <FlexLabel>
      <StatusDot $dotSize={dotSize} $dotTextSpacing={dotTextSpacing} $status={status} />
      <StatusTextWrapper>
        <Text color={textColor} inverse={textInverse} size={textSize} variant="label">
          {statusLabel}
        </Text>
      </StatusTextWrapper>
    </FlexLabel>
  );
};

// The names of the types of items that are listed on the browse view
const itemNames = [
  'video',
  'audio',
  'image',
  'document',
  'creative',
  'project',
  'folder',
  'teamProject',
  'teamMember',
] as const;
export type ItemTypes = (typeof itemNames)[number];

export const ItemTypeLabel = (props: {itemType: ItemTypes}) => {
  const intl = useIntl();
  const videoTypeLabel = intl.formatMessage({
    defaultMessage: 'Video',
    id: 'qEJq31',
    description: 'Label to identify the item in the row as a video file',
  });
  const audioTypeLabel = intl.formatMessage({
    defaultMessage: 'Audio',
    id: 'qMRuxH',
    description: 'Label to identify the item in the row as an audio file',
  });
  const imageTypeLabel = intl.formatMessage({
    defaultMessage: 'Image',
    id: 'D/LO9r',
    description: 'Label to identify the item in the row as an image file',
  });
  const documentTypeLabel = intl.formatMessage({
    defaultMessage: 'Document',
    id: '+Jcje9',
    description: 'Label to identify the item in the row as a pdf file',
  });
  const projectTypeLabel = intl.formatMessage({
    defaultMessage: 'Project',
    id: 'IMZmy0',
    description: 'Label to identify the item in the row as a team project',
  });
  const folderTypeLabel = intl.formatMessage({
    defaultMessage: 'Folder',
    id: 'vkRIya',
    description: 'Label to identify the item in the row as a folder',
  });
  // Admin specific
  const teamProjectTypeLabel = intl.formatMessage({
    defaultMessage: 'Team',
    id: 'ycdz+6',
    description: 'Label to identify the item in the row as a team project',
  });
  const teamMemberTypeLabel = intl.formatMessage({
    defaultMessage: 'Member',
    id: 'vO0X/d',
    description: 'Label to identify the item in the row as a team Member',
  });

  const itemMatch = {
    video: {icon: VideoLine, label: videoTypeLabel},
    audio: {icon: AudioLine, label: audioTypeLabel},
    image: {icon: ImageLine, label: imageTypeLabel},
    creative: {icon: ImageLine, label: imageTypeLabel},
    document: {icon: PdfLine, label: documentTypeLabel},
    project: {icon: FolderLine, label: projectTypeLabel},
    folder: {icon: FolderLine, label: folderTypeLabel},
    teamProject: {icon: FolderLine, label: teamProjectTypeLabel},
    teamMember: {icon: PersonCircleLine, label: teamMemberTypeLabel},
  };

  return (
    <FlexLabel>
      <ItemTypeIcon>
        <UIIcon src={itemMatch[props.itemType].icon} />
      </ItemTypeIcon>
      <ItemTypeText>
        <Text variant="label">{itemMatch[props.itemType].label}</Text>
      </ItemTypeText>
    </FlexLabel>
  );
};

export const MaybeLoadingItemTypeLabel = (props: {itemType: ItemTypes | 'loading'}) => {
  if (props.itemType === 'loading') {
    return (
      <FlexLabel>
        <SkeletonRectangle $widthValue="75px" />
      </FlexLabel>
    );
  }

  return <ItemTypeLabel itemType={props.itemType} />;
};

const TileLabelWrapper = styled.div`
  color: var(--color__white);
  display: flex;
  align-items: center;
`;

export const TextWrapper = styled.p`
  display: inline-flex;
  margin: 0;
`;

const UIIconDisabledStyled = styled(UIIcon)`
  && {
    color: ${color('Text Subtle')};
  }
`;

export const VersionLabel = (props: React.PropsWithChildren<{}>) => (
  <TileLabelWrapper>
    <UIIcon size="small" src={GifLine} />
    <TextWrapper>
      <Text size="small">{props.children}</Text>
    </TextWrapper>
  </TileLabelWrapper>
);

export const UploadingVersionLabel = () => (
  <TileLabelWrapper>
    <UIIconDisabledStyled size="small" src={GifLine} />
    <TextWrapper>
      <Text color="subtle" size="small">
        <FormattedMessage
          defaultMessage="V1"
          description="A label signifying that the video is (or will be) version 1"
          id="noI606"
        />
      </Text>
    </TextWrapper>
  </TileLabelWrapper>
);

export const CommentsLabel = (props: React.PropsWithChildren<{}>) => (
  <TileLabelWrapper>
    <UIIcon size="small" src={CommentLine} />
    <TextWrapper>
      <Text size="small">{props.children}</Text>
    </TextWrapper>
  </TileLabelWrapper>
);

export const ViewerCountLabel = (props: React.PropsWithChildren<{}>) => (
  <TileLabelWrapper>
    <UIIcon size="small" src={ShowLine} style={{marginLeft: '4px'}} />
    <TextWrapper>
      <Text size="small">{props.children}</Text>
    </TextWrapper>
  </TileLabelWrapper>
);
