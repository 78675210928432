import {useMemo} from 'react';

import {useDisplayInfoQueries} from '~/lib/api_queries';

import {useFoldersSharedState} from './folders_context';

export const useDisplayInfoQueriesForCurrentFolder = () => {
  const {currentFolder} = useFoldersSharedState();

  const subfolders = currentFolder === null ? [] : currentFolder.folders;
  const folderDisplayInfoQueries = useDisplayInfoQueries(
    subfolders.map((subfolder) => subfolder.id),
  );

  const projects = currentFolder === null ? [] : currentFolder.projectsWithVideos;
  const projectDisplayInfoQueries = useDisplayInfoQueries(
    projects.map((project) => project.project?.id),
  );

  return useMemo(
    () => ({
      folderDisplayInfoQueries,
      projectDisplayInfoQueries,
    }),
    [folderDisplayInfoQueries, projectDisplayInfoQueries],
  );
};
