// adapted from https://sourcegraph.pp.dropbox.com/server/-/blob/typescript/component_libraries/files_components/src/persistent-upload/upload_status_bar_icon.tsx
import React, {useEffect, useMemo, useRef, useState} from 'react';

import type {LottieRefCurrentProps} from 'lottie-react';
import styled, {css} from 'styled-components';

import {Box} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {CheckmarkLine, ClockLine, FailLine, UploadLine} from '@dropbox/dig-icons/assets';

import {LottieAnimation, LottieAnimationType} from '~/components/lottie_animation';
import {type Color, color, spacing} from '~/components/styled';
import {UploadDrawerStatus} from '~/lib/uploads/types';

const UploadStatusIcon = styled(Box)`
  height: 100%;
  transition: all 0.5s ease-in-out;
  width: 56px;

  /* display icon over status-progress */
  z-index: 3;

  svg {
    color: ${color('Primary On Base')};
    fill: ${color('Primary On Base')};
    height: ${spacing('Macro XLarge')};
    width: ${spacing('Macro XLarge')};
  }
`;

type AnimationWrapperProps = {
  $phase: AnimationPhase;
};
const AnimationWrapper = styled.div<AnimationWrapperProps>`
  height: 100%;

  & > div {
    height: 100%;
  }

  ${(props) =>
    props.$phase === 'transition' &&
    css`
      transform: translateY(-56px);
      transition: transform var(--duration__micro) ease-in;
    `}
`;

const UPLOAD_STATUS_TO_ICON: Record<
  UploadDrawerStatus,
  (props: React.SVGProps<SVGSVGElement>) => React.ReactElement
> = {
  [UploadDrawerStatus.ALL_FAILED]: FailLine,
  [UploadDrawerStatus.CANCELED]: FailLine,
  [UploadDrawerStatus.FILE_QUOTA_EXCEEDED]: ClockLine,
  [UploadDrawerStatus.SOME_FAILED]: FailLine,
  [UploadDrawerStatus.SUCCESS]: CheckmarkLine,
  [UploadDrawerStatus.UPLOADING]: UploadLine,
  [UploadDrawerStatus.USAGE_QUOTA_EXCEEDED]: ClockLine,
};

type AnimationPhase = 'uploading' | 'transition' | 'complete';
const TRANSITION_DURATION_MS = 400; // --duration__micro plus a little extra

type StatusIconProps = {
  status: UploadDrawerStatus;
  iconOverride?: (props: React.SVGProps<SVGSVGElement>) => React.ReactElement;
};
const StatusIcon = ({status, iconOverride}: StatusIconProps) => {
  const lottieRef = useRef<LottieRefCurrentProps>(null);
  const transitionTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [phase, setPhase] = useState<AnimationPhase>('uploading');

  const shouldUseLottie =
    !iconOverride &&
    (status === UploadDrawerStatus.UPLOADING || status === UploadDrawerStatus.SUCCESS);

  const clearTransitionTimeout = () => {
    if (transitionTimeoutRef.current) {
      clearTimeout(transitionTimeoutRef.current);
      transitionTimeoutRef.current = null;
    }
  };

  useEffect(() => {
    if (!shouldUseLottie) {
      return;
    }

    if (status === UploadDrawerStatus.UPLOADING) {
      clearTransitionTimeout(); // if we start uploading again during the transition, cancel it
      setPhase('uploading');
    } else if (status === UploadDrawerStatus.SUCCESS) {
      setPhase('transition');
      transitionTimeoutRef.current = setTimeout(() => {
        setPhase('complete');
      }, TRANSITION_DURATION_MS);
    }
    return clearTransitionTimeout;
  }, [status, shouldUseLottie]);

  const backgroundColor: Color = useMemo(() => {
    switch (status) {
      case UploadDrawerStatus.ALL_FAILED:
      case UploadDrawerStatus.FILE_QUOTA_EXCEEDED:
      case UploadDrawerStatus.USAGE_QUOTA_EXCEEDED:
      case UploadDrawerStatus.SOME_FAILED:
        return 'Alert Base - State 1';
      case UploadDrawerStatus.SUCCESS:
        return 'Success Base - State 1';
      case UploadDrawerStatus.UPLOADING:
        return 'Primary Base - State 1';
      case UploadDrawerStatus.CANCELED:
        return 'Warning Base - State 1';
      default:
        return 'Primary Base - State 1';
    }
  }, [status]);

  return (
    <UploadStatusIcon
      alignItems="center"
      backgroundColor={backgroundColor}
      display="flex"
      justifyContent="center"
      overflow="hidden"
    >
      {shouldUseLottie ? (
        <AnimationWrapper $phase={phase}>
          <LottieAnimation
            animationType={
              phase === 'complete'
                ? LottieAnimationType.UPLOAD_COMPLETE
                : LottieAnimationType.UPLOAD_ARROW
            }
            autoplay
            loop={phase !== 'complete'}
            lottieRef={lottieRef}
          />
        </AnimationWrapper>
      ) : (
        <UIIcon role="status" src={iconOverride ?? UPLOAD_STATUS_TO_ICON[status]} />
      )}
    </UploadStatusIcon>
  );
};

export {StatusIcon};
