import {Link} from 'react-router-dom';
import styled from 'styled-components';

export const ReelLink = styled(Link)<{disabled?: boolean; $truncate?: boolean}>`
  text-decoration: none;
  height: 100%;
  color: ${({disabled}) => (disabled ? 'var(--dig-color__text__subtle)' : 'inherit')};
  pointer-events: ${({disabled}) => (disabled ? 'none' : 'inherit')};
  overflow: ${({$truncate}) => ($truncate ? 'hidden' : 'inherit')};
`;
