import React from 'react';

import {FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';

import {Tooltip} from '@dropbox/dig-components/tooltips';
import {Text, Title} from '@dropbox/dig-components/typography';
import {Box} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {AddLine, CloseLine} from '@dropbox/dig-icons/assets';

import {IconButton} from '~/components/button';
import {spacing} from '~/components/styled';
import {PREFERENCE_SETTING_METADATA_FIELD} from '~/lib/provisions';
import {SuggestedTile, TileContent, TilePoster} from '~/pages/browse_page/components/tiles/tile';

import type {BrowseProject} from './common';
import {TileBottomSection, TileTopSection} from './tiles/project_tile';
import {TileGrid} from './tiles/tile_grid';
import type {SuggestedFilesResponse} from '../../../lib/api';
import {LOADING, MediaType, NONE} from '../../../lib/api';
import {getBasename} from '../../../lib/helpers';
import type {LoggingClient} from '../../../lib/logging/logger';
import {getTimeForDisplay} from '../../../lib/time';
import {useMetadataSetting} from '../../settings_page/useMetadataSetting';

type SuggestedItem = {
  durationSecs?: number;
  filename: string;
  fileId: string;
  lastModifiedTs?: string;
  mediaType: MediaType;
  thumbnailUrl?: string;
};

const ExtraActionsRow = styled(Text)`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  color: currentColor;
  padding-top: ${spacing('Micro Medium')};
`;

const SuggestedItemTile = (props: {
  index: number;
  suggestedItem: SuggestedItem;
  onSuggestedItemClick: SuggestedItemClickHandler;
}) => {
  const {
    index,
    onSuggestedItemClick,
    suggestedItem: {durationSecs, fileId, filename, lastModifiedTs, mediaType, thumbnailUrl},
  } = props;

  return (
    <SuggestedTile
      onClick={() => {
        onSuggestedItemClick(fileId, filename, {
          positionInList: index,
          mediaType,
        });
      }}
    >
      <TilePoster>
        <TileTopSection
          contextMenu={null}
          durationDisplayString={getTimeForDisplay(durationSecs)}
          isTutorial={false}
          mediaType={mediaType}
          src={thumbnailUrl}
        />
      </TilePoster>
      <TileContent backgroundColor="Transparent">
        <TileBottomSection
          isTutorial={false}
          lastModifiedDate={lastModifiedTs ? new Date(lastModifiedTs) : undefined}
          name={filename}
        >
          <ExtraActionsRow size="small">
            <UIIcon size="small" src={AddLine} />{' '}
            <FormattedMessage
              defaultMessage="Click to add"
              description="Text inside tile representing a suggested file to import into Dropbox; clicking this tile begins a Replay import"
              id="dyHsie"
            />
          </ExtraActionsRow>
        </TileBottomSection>
      </TileContent>
    </SuggestedTile>
  );
};

const SuggestedItemsList = (props: {
  onSuggestedItemClick: SuggestedItemClickHandler;
  suggestedItems: SuggestedItem[];
}) => {
  const {onSuggestedItemClick, suggestedItems} = props;

  return (
    <TileGrid $isSingleLine={true}>
      {suggestedItems.map((s, idx) => (
        <SuggestedItemTile
          index={idx}
          key={s.fileId}
          onSuggestedItemClick={onSuggestedItemClick}
          suggestedItem={s}
        />
      ))}
    </TileGrid>
  );
};

const MAX_NUM_SUGGESTED_ITEMS = 7;
const MIN_NUM_SUGGESTED_ITEMS = 2;

const SuggestedItemsTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SuggestedItemsTitle = styled(Title)`
  margin: 0;
`;

const SuggestedItemsCloseButton = styled.div`
  margin-left: ${spacing('Micro XSmall')};
  margin-right: ${spacing('Micro XSmall')};
`;

const SuggestedItemsSubtitle = styled(Text)`
  margin: 0;
  padding-bottom: ${spacing('Micro Small')};
`;

export type SuggestedItemClickHandler = (
  fileId: string,
  name: string,
  loggingAttrs: {positionInList: number; mediaType: MediaType},
) => void;

export const SuggestedItems = (props: {
  logEvent: LoggingClient['logEvent'];
  existingProjects: BrowseProject[];
  onSuggestedItemClick: SuggestedItemClickHandler;
  suggestedItemsResponse: SuggestedFilesResponse;
}) => {
  const intl = useIntl();
  const {existingProjects, logEvent, onSuggestedItemClick, suggestedItemsResponse} = props;
  const toggleSuggestedFiles = useMetadataSetting(
    PREFERENCE_SETTING_METADATA_FIELD.REPLAY_SETTINGS_SHOW_SUGGESTED_FILES,
    (...args) => {
      // eslint-disable-next-line deprecation/deprecation
      logEvent(...args);
    },
    'show_suggested_files',
  );

  const existingProjectNames = React.useMemo(() => {
    return new Set(
      existingProjects.reduce((acc, proj) => {
        if (proj.project?.name) {
          acc.push(proj.project.name);
        }
        return acc;
      }, [] as string[]),
    );
  }, [existingProjects]);

  const dismissSuggestedItems = React.useCallback(() => {
    // We can simply use the toggleSuggestedFiles because we already know we won't be able to see this section
    // if the toggleSuggestedFiles is already off.
    toggleSuggestedFiles.onToggle();
    // eslint-disable-next-line deprecation/deprecation
    logEvent('hide_suggested_items');
  }, [logEvent, toggleSuggestedFiles]);

  const suggestedItems = React.useMemo(() => {
    if (suggestedItemsResponse === NONE || suggestedItemsResponse === LOADING) {
      return [];
    }

    const result: SuggestedItem[] = [];
    for (let i = 0; i < suggestedItemsResponse.length; i += 1) {
      if (result.length > MAX_NUM_SUGGESTED_ITEMS - 1) {
        break;
      }
      const current = suggestedItemsResponse[i];
      if (!current.filename || existingProjectNames.has(getBasename(current.filename))) {
        continue;
      }
      if (current.file_id && current.filename && current.media_type) {
        result.push({
          durationSecs: current.duration_s,
          filename: current.filename,
          fileId: current.file_id,
          lastModifiedTs: current.last_modified_timestamp,
          mediaType:
            current.media_type['.tag'] === 'audio'
              ? MediaType.Audio
              : current.media_type['.tag'] === 'image'
              ? MediaType.Image
              : MediaType.Video,
          thumbnailUrl: current.thumbnail_url,
        });
      }
    }
    return result;
  }, [existingProjectNames, suggestedItemsResponse]);

  if (suggestedItems.length < MIN_NUM_SUGGESTED_ITEMS) {
    return null;
  }
  const dismissLabel = intl.formatMessage({
    defaultMessage: 'Dismiss',
    id: '8bmrJK',
    description: 'Click to dismiss the suggested files and update user setting.',
  });

  return (
    <Box paddingTop="Macro Medium">
      <SuggestedItemsTitleContainer>
        <SuggestedItemsTitle size="small" tagName="h2">
          <FormattedMessage
            defaultMessage="Suggested files"
            description="Header for section that contains files suggested from Dropbox to import into Replay"
            id="avLomg"
          />
        </SuggestedItemsTitle>
        <SuggestedItemsCloseButton>
          <Tooltip placement="right" title={dismissLabel}>
            <IconButton
              aria-label={dismissLabel}
              onClick={dismissSuggestedItems}
              size="small"
              variant="borderless"
            >
              <UIIcon src={CloseLine} />
            </IconButton>
          </Tooltip>
        </SuggestedItemsCloseButton>
      </SuggestedItemsTitleContainer>
      <SuggestedItemsSubtitle color="faint" size="small" tagName="p">
        <FormattedMessage
          defaultMessage="From your Dropbox"
          description="Subtitle to title 'Suggested files' for section that contains files suggested from Dropbox to import into Replay"
          id="bFCb3t"
        />
      </SuggestedItemsSubtitle>
      <SuggestedItemsList
        onSuggestedItemClick={onSuggestedItemClick}
        suggestedItems={suggestedItems}
      />
    </Box>
  );
};
