import defer from 'lodash/defer';

const lodash = {defer};

import {
  BoltClient as BoltClientNoDeps,
  ChannelId,
  ChannelPayloads,
  Payload,
  SignedChannelState,
  ThunderClient as ThunderClientNoDeps,
} from '~/lib/bolt_lib';

export interface BoltInfo {
  appId: string;
  uniqueId: string;
  revision: string;
  token: string;
}

declare const process: NodeJS.Process;

const BOLT_HOSTNAME = 'bolt.dropbox.com';
const THUNDER_HOSTNAME = 'thunder.dropbox.com';

class BoltClient extends BoltClientNoDeps {
  constructor(signed_channel_states: any, update_callback: any, refresh_callback: any) {
    super(signed_channel_states, update_callback, refresh_callback, BOLT_HOSTNAME, lodash);
  }
}

class ThunderClient extends ThunderClientNoDeps {
  constructor(
    signed_channel_states: SignedChannelState[],
    update_callback: (states: SignedChannelState[]) => void,
    refresh_callback: (states: SignedChannelState[]) => void,
  ) {
    super(signed_channel_states, update_callback, refresh_callback, THUNDER_HOSTNAME, lodash);
  }
}

export {BoltClient, ThunderClient, ChannelId, SignedChannelState, Payload, ChannelPayloads};
