import {useAtomValue} from 'jotai';
import {FormattedMessage, useIntl} from 'react-intl';
import {useNavigate} from 'react-router-dom';

import {Spinner} from '@dropbox/dig-components/progress_indicators';
import {Snackbar} from '@dropbox/dig-components/snackbar';
import {Text} from '@dropbox/dig-components/typography';

import {Button} from '~/components/button';
import {ReelSnackbar} from '~/components/snackbar';
import {useFoldersSharedState} from '~/pages/browse_page/folders_context';
import {browseSnackbarAtom} from '~/state/browse';

export type VersionUploadConfirmationProps = {
  projectName: string;
  projectLink: string;
  videoVersionNumber: number;
};

type ArchiveStatus =
  | 'start'
  | 'submit_failed'
  | 'in_progress'
  | 'success'
  | 'fail'
  | 'partial_success';

export type ArchiveSnackbarProps = {
  archiveStatus: ArchiveStatus;
  numInProgress?: number;
  numSucceeded?: number;
  numFailed?: number;
  overQuota?: boolean;
};

type BaseProjectMoveConfirmationProps = {
  destinationFolderId: string;
  destinationFolderName: string;
  isCopy: boolean;
};

type BulkProjectMoveConfirmationProps = BaseProjectMoveConfirmationProps & {
  itemType: 'bulk';
  folderIds: string[];
  projectIds: string[];
};
type SingleProjectMoveConfirmationProps = BaseProjectMoveConfirmationProps & {
  itemType: 'project' | 'folder';
  projectOrFolderName: string;
};
export type ProjectMoveConfirmationProps =
  | SingleProjectMoveConfirmationProps
  | BulkProjectMoveConfirmationProps;

export enum BrowseSnackbarStatus {
  HIDDEN = 'hidden',
  VERSION_UPLOAD_CONFIRMATION = 'version upload confirmation',
  MOVE_CONFIRMATION = 'move confirmation',
  MOVE_IN_PROGRESS = 'move in progress',
  MOVE_ERROR = 'move error',
  ARCHIVE = 'archive',
}

export type BrowsePageSnackbarProps =
  | {
      status: BrowseSnackbarStatus.HIDDEN;
    }
  | ({
      status: BrowseSnackbarStatus.VERSION_UPLOAD_CONFIRMATION;
      onRequestClose: () => void;
    } & VersionUploadConfirmationProps)
  | ({
      status: BrowseSnackbarStatus.MOVE_CONFIRMATION;
      onRequestClose: () => void;
    } & ProjectMoveConfirmationProps)
  | ({
      status: BrowseSnackbarStatus.MOVE_IN_PROGRESS;
      onRequestClose: () => void;
    } & ProjectMoveConfirmationProps)
  | ({
      status: BrowseSnackbarStatus.MOVE_ERROR;
      onRequestClose: () => void;
    } & ProjectMoveConfirmationProps)
  | ({
      status: BrowseSnackbarStatus.ARCHIVE;
      onRequestClose: () => void;
    } & ArchiveSnackbarProps);

export const BrowsePageSnackbar = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const props = useAtomValue(browseSnackbarAtom);
  const isError = props.status === BrowseSnackbarStatus.MOVE_ERROR;

  const {isFolderOrProject} = useFoldersSharedState();

  let itemName = <Text />;
  let destinationFolder = <Text />;

  if (
    props.status === BrowseSnackbarStatus.MOVE_IN_PROGRESS ||
    props.status === BrowseSnackbarStatus.MOVE_CONFIRMATION ||
    props.status === BrowseSnackbarStatus.MOVE_ERROR
  ) {
    const itemCount =
      props.itemType === 'bulk' ? props.folderIds.length + props.projectIds.length : 1;
    const genericItemsName = intl.formatMessage(
      {
        defaultMessage: '{itemCount} {itemCount, plural, one{item} other{items}}',
        id: 'pKQbjQ',
        description:
          'A generic term to describe folders and/or projects that will be moved or copied',
      },
      {itemCount},
    );

    itemName = (
      <Text color={isError ? 'error' : 'standard'} inverse isBold={!isError}>
        {props.itemType === 'bulk' ? genericItemsName : props.projectOrFolderName}
      </Text>
    );

    destinationFolder = (
      <Text color={isError ? 'error' : 'standard'} inverse isBold={!isError}>
        {props.destinationFolderName}
      </Text>
    );
  }

  const snackbarChildren =
    props.status === BrowseSnackbarStatus.HIDDEN ? null : props.status ===
      BrowseSnackbarStatus.VERSION_UPLOAD_CONFIRMATION ? (
      <>
        <Snackbar.Message>
          <Text inverse isBold>
            {props.projectName}
          </Text>{' '}
          <Text inverse>
            <FormattedMessage
              defaultMessage="V{version_number} added"
              description="Snackbar message telling user that a new version has been added"
              id="cGCCN7"
              values={{version_number: props.videoVersionNumber}}
            />
          </Text>
        </Snackbar.Message>
        <Snackbar.Actions>
          <Button
            inverse
            onClick={() => {
              navigate(props.projectLink);
            }}
            variant="transparent"
          >
            <FormattedMessage
              defaultMessage="View"
              description="Text for button that sends users to the new version they just uploaded"
              id="8Me8Bq"
            />
          </Button>
        </Snackbar.Actions>
      </>
    ) : props.status === BrowseSnackbarStatus.MOVE_IN_PROGRESS ||
      props.status === BrowseSnackbarStatus.MOVE_CONFIRMATION ? (
      <>
        {' '}
        {props.status === BrowseSnackbarStatus.MOVE_IN_PROGRESS ? (
          <Spinner aria-valuetext="Loading" size="small" />
        ) : null}
        <Snackbar.Message>
          {props.isCopy ? (
            props.status === BrowseSnackbarStatus.MOVE_IN_PROGRESS ? (
              <FormattedMessage
                defaultMessage="Copying {itemName} to {destinationFolder}"
                description="Notification text indicating that the item is in the process of being copied to the destination folder"
                id="qLXdV5"
                values={{itemName: itemName, destinationFolder: destinationFolder}}
              />
            ) : (
              <FormattedMessage
                defaultMessage="Copied {itemName} to {destinationFolder}"
                description="Notification text indicating that the item has successfully been copied to the destination folder"
                id="NIzMDQ"
                values={{itemName: itemName, destinationFolder: destinationFolder}}
              />
            )
          ) : props.status === BrowseSnackbarStatus.MOVE_IN_PROGRESS ? (
            <FormattedMessage
              defaultMessage="Moving {itemName} to {destinationFolder}"
              description="Notification text indicating that the item is in the process of being moved to the destination folder"
              id="3O3Xs3"
              values={{itemName: itemName, destinationFolder: destinationFolder}}
            />
          ) : (
            <FormattedMessage
              defaultMessage="Moved {itemName} to {destinationFolder}"
              description="Notification text indicating that the item has successfully been moved to the destination folder"
              id="uDdFVw"
              values={{itemName: itemName, destinationFolder: destinationFolder}}
            />
          )}
        </Snackbar.Message>
        {props.status === BrowseSnackbarStatus.MOVE_CONFIRMATION ? (
          <Snackbar.Actions>
            <Button
              inverse
              onClick={() => {
                navigate('/folder/' + props.destinationFolderId);
              }}
              variant="transparent"
            >
              <FormattedMessage
                defaultMessage="Open {destinationFolderType}"
                description="Text for button that sends user to the folder they just successfully moved"
                id="fjqO0W"
                values={{destinationFolderType: isFolderOrProject(props.destinationFolderId)}}
              />
            </Button>
          </Snackbar.Actions>
        ) : null}
      </>
    ) : props.status === BrowseSnackbarStatus.MOVE_ERROR ? (
      <Snackbar.Message>
        <Text color="error" inverse>
          {props.isCopy ? (
            <FormattedMessage
              defaultMessage="Failed to copy {itemName} to {destinationFolder}"
              description="Notification text indicating that there was an error in copying the item to the destination folder and it failed"
              id="IkwDkI"
              values={{itemName, destinationFolder}}
            />
          ) : (
            <FormattedMessage
              defaultMessage="Failed to move {itemName} to {destinationFolder}"
              description="Notification text indicating that there was an error in moving the item to the destination folder and it failed"
              id="qao/1w"
              values={{itemName, destinationFolder}}
            />
          )}
        </Text>
      </Snackbar.Message>
    ) : props.status === BrowseSnackbarStatus.ARCHIVE ? (
      getArchiveSnackbarContent(props)
    ) : null;

  return (
    <ReelSnackbar
      onRequestClose={
        props.status !== BrowseSnackbarStatus.HIDDEN ? props.onRequestClose : undefined
      }
      open={props.status !== BrowseSnackbarStatus.HIDDEN}
      timeout={
        props.status === BrowseSnackbarStatus.MOVE_IN_PROGRESS ||
        (props.status === BrowseSnackbarStatus.ARCHIVE &&
          ['start', 'in_progress'].includes(props.archiveStatus))
          ? 0
          : 5000
      }
    >
      {snackbarChildren}
    </ReelSnackbar>
  );
};

const getArchiveSnackbarContent = (props: BrowsePageSnackbarProps) => {
  if (!(props.status === BrowseSnackbarStatus.ARCHIVE)) {
    return null;
  }

  const {archiveStatus, numInProgress, numSucceeded, numFailed, onRequestClose} = props;

  const successMsg = (
    <FormattedMessage
      defaultMessage="{itemCount} {itemCount, plural, one {project file} other {project files}} successfully archived to Dropbox."
      description="Notification text indicating that files have successfully been archived to Dropbox"
      id="A73fdh"
      values={{itemCount: numSucceeded}}
    />
  );

  const failMsg = props.overQuota ? (
    <Text color="error" inverse>
      <FormattedMessage
        defaultMessage="Archive to Dropbox could not be completed due to insufficient storage."
        description="Notification text indicating files have failed to be archived to Dropbox"
        id="juss57"
        values={{itemCount: numFailed}}
      />
    </Text>
  ) : (
    <FormattedMessage
      defaultMessage="{itemCount} {itemCount, plural, one {project file} other {project files}} failed to be archived to Dropbox."
      description="Notification text indicating files have failed to be archived to Dropbox"
      id="Hxfe5j"
      values={{itemCount: numFailed}}
    />
  );

  const dismissButton = (
    <Snackbar.Actions>
      <Button inverse onClick={onRequestClose} variant="transparent">
        <FormattedMessage
          defaultMessage="Dismiss"
          description="Dismiss button for archive to Dropbox snackbar"
          id="HUcMDh"
        />
      </Button>
    </Snackbar.Actions>
  );

  switch (archiveStatus) {
    case 'start':
      return (
        <>
          {' '}
          <Spinner aria-valuetext="Loading" size="small" />
          <Snackbar.Message>
            <FormattedMessage
              defaultMessage="Archiving project files to Dropbox..."
              description="Notification text indicating that files have started being archived to Dropbox"
              id="1hAyPe"
            />
          </Snackbar.Message>
          {dismissButton}
        </>
      );
    case 'in_progress':
      return (
        <>
          {' '}
          <Spinner aria-valuetext="Loading" size="small" />
          <Snackbar.Message>
            <FormattedMessage
              defaultMessage="{itemCount} {itemCount, plural, one {project file is} other {project files are}} being archived to Dropbox..."
              description="Notification text indicating that archiving files to Dropbox are in progress"
              id="IYVnQ0"
              values={{itemCount: numInProgress}}
            />
          </Snackbar.Message>
          {dismissButton}
        </>
      );
    case 'success':
      return (
        <>
          <Snackbar.Message>{successMsg}</Snackbar.Message>
          {dismissButton}
        </>
      );
    case 'fail':
      return (
        <>
          <Snackbar.Message>{failMsg}</Snackbar.Message>
          {dismissButton}
        </>
      );
    case 'submit_failed':
      return (
        <>
          <Snackbar.Message>
            <Text color="error" inverse>
              <FormattedMessage
                defaultMessage="Archive to Dropbox failed"
                description="Notification text indicating that archiving selected files to Dropbox failed to even start"
                id="X003Jo"
              />
            </Text>
          </Snackbar.Message>
          {dismissButton}
        </>
      );
    case 'partial_success':
      return (
        <>
          <Snackbar.Message>
            {successMsg} {failMsg}
          </Snackbar.Message>
          {dismissButton}
        </>
      );
    default:
      return null;
  }
};
