import type {QueryClient} from '@tanstack/react-query';

import {
  AccountInfoBatcher,
  getInitializationData,
  getProvisionedFeatures,
  getUserMetadata,
} from '~/lib/api';
import {replayApi} from '~/lib/query_client';

const initStaleTime = 10 * 60 * 1000; // 10 minutes

export const queryProvisionsData = (queryClient: QueryClient) => {
  return queryClient.fetchQuery({
    queryKey: replayApi.getProvisions(),
    queryFn: getProvisionedFeatures,
  });
};

export const queryInitializationData = (queryClient: QueryClient) => {
  return queryClient.fetchQuery({
    queryKey: replayApi.getInitializationData(),
    queryFn: getInitializationData,
    staleTime: initStaleTime,
  });
};

export const refreshProvisionsData = (queryClient: QueryClient) => {
  queryClient.removeQueries({
    queryKey: replayApi.getProvisions(),
    exact: true,
  });
  return queryProvisionsData(queryClient);
};

export const queryUserAccount = (queryClient: QueryClient, accountId: string) => {
  return queryClient.fetchQuery({
    queryKey: replayApi.userInfo(accountId),
    queryFn: () => AccountInfoBatcher.fetch(accountId),
    staleTime: initStaleTime,
  });
};

export const queryUserMetadata = (queryClient: QueryClient) => {
  return queryClient.fetchQuery({
    queryKey: replayApi.getUserMetadata(),
    queryFn: getUserMetadata,
    staleTime: initStaleTime,
  });
};
