import type {reel} from '@dropbox/api-v2-client';

import {VERSION_COMPARE_ROUTE_MATCH, VERSION_COMPARE_ROUTE_SHARE_MATCH} from '~/route_path_matches';

export type DefaultLatestVersions = {
  latestVersion: reel.VersionSummary;
  nextLatestVersion: reel.VersionSummary;
};

export const getLatestTwoVersions = (
  currentVersionSummaries: reel.VersionSummary[],
): DefaultLatestVersions => {
  const sortedItems = currentVersionSummaries.sort(
    (summary1, summary2) => summary2.version_num - summary1.version_num,
  );
  return {
    latestVersion: sortedItems[0],
    nextLatestVersion: sortedItems[1],
  };
};

export const generateVersionComparePageUrl = ({
  projectId,
  videoId,
  nextLatestVideoVersionId,
  latestVideoVersionId,
  shareToken,
}: {
  projectId: string;
  videoId: string;
  nextLatestVideoVersionId: string;
  latestVideoVersionId: string;
  shareToken?: string;
}) => {
  const urlParams = new URLSearchParams();
  urlParams.append('l', nextLatestVideoVersionId);
  urlParams.append('r', latestVideoVersionId);
  let compareUrl: string;

  if (shareToken) {
    urlParams.append('shareToken', shareToken);
    compareUrl = VERSION_COMPARE_ROUTE_SHARE_MATCH.replace(':projectId', projectId)
      .replace(':videoId', videoId)
      .replace(':shareToken', shareToken)
      .concat(`?${urlParams.toString()}`);
  } else {
    compareUrl = VERSION_COMPARE_ROUTE_MATCH.replace(':projectId', projectId)
      .replace(':videoId', videoId)
      .concat(`?${urlParams.toString()}`);
  }

  return compareUrl;
};

export const areLatestTwoVersionsSameMediaType = ({
  latestVersions,
}: {
  latestVersions: DefaultLatestVersions;
}) => {
  return (
    latestVersions.latestVersion.media_type &&
    latestVersions.nextLatestVersion.media_type &&
    latestVersions.latestVersion.media_type['.tag'] ===
      latestVersions.nextLatestVersion.media_type['.tag']
  );
};

export const getLatestVersionDate = (version: reel.VersionSummary): Date | null => {
  const dates = [];

  if (version.comments_info?.latest_timestamp) {
    const ts = Number(new Date(version.comments_info?.latest_timestamp));
    if (!isNaN(ts)) {
      dates.push(ts);
    }
  }

  if (version.upload_timestamp) {
    const ts = Number(new Date(version.upload_timestamp));
    if (!isNaN(ts)) {
      dates.push(ts);
    }
  }

  if (dates.length === 0) {
    return null;
  }

  return new Date(Math.max(...dates));
};
