import React, {type ComponentProps} from 'react';

import {Chooser} from 'dropins-components';
import type {NavigationFeature, OnChooserEvent} from 'dropins-components';
import {FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';

import {LabelGroup} from '@dropbox/dig-components/combinations';
import {Checkbox} from '@dropbox/dig-components/controls';
import {FormRow} from '@dropbox/dig-components/form_row';
import {Modal} from '@dropbox/dig-components/modal';
import {Title} from '@dropbox/dig-components/typography';

import {useDropboxChooser} from '~/components/dropbox_chooser_context';
import type {UploadType} from '~/components/dropbox_chooser_context';
import {useReelAppGlobalState} from '~/context';
import {replayStorage} from '~/lib/storage';
import type {ChooserFile} from '~/lib/uploads/types';
import {useArchiveToDropboxEnabled} from '~/lib/utils';

import 'dropins-components/dist/css/style.min.css';

const StyledModal = styled(Modal)`
  background-color: var(--dig-color__background__base);
`;

const Header = styled.div`
  align-items: center;
  background-color: var(--dig-color__background__base);
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 8px 20px;
`;

// Adds a full-width bottom-border to the tabs
const ActionsMenuWrapper = styled.div`
  & .dropins-chooser-actions-menu:first-child {
    position: relative;

    & .dropins-chooser-actions-menu__item {
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    &::after {
      background: rgba(142, 139, 135, 0.5);
      bottom: 0;
      content: '';
      display: block;
      height: 1px;
      left: 0;
      position: absolute;
      right: 0;
      width: 100%;
    }
  }

  & .dropins-chooser-breadcrumbs-with-checkbox {
    width: 100%;

    & .dropins-chooser-breadcrumbs {
      width: 100%;
    }
  }
`;

const FileViewWrapper = styled.div`
  & .dropins-chooser-file-view {
    height: 350px;
  }
`;

const SearchWrapper = styled.div`
  width: 50%;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing__unit--2);
  background-color: var(--dig-color__background__base);
`;

type ArchiveCheckboxProps = {
  archiveLatestOnly: boolean;
  setArchiveLatestOnly: (val: boolean) => void;
};

const ArchiveCheckbox = ({archiveLatestOnly, setArchiveLatestOnly}: ArchiveCheckboxProps) => {
  return (
    <FormRow variant="control">
      <label htmlFor="archive-checkbox">
        <LabelGroup
          align="center"
          withLeftAccessory={
            <Checkbox
              checked={archiveLatestOnly}
              id="archive-checkbox"
              onChange={() => setArchiveLatestOnly(!archiveLatestOnly)}
            />
          }
          withText={
            <FormattedMessage
              defaultMessage="Only archive the latest version of Replay file"
              description="Helper text on checkbox inside the Dropbox chooser that lets users archive Replay projects"
              id="iqxOAv"
            />
          }
        />
      </label>
    </FormRow>
  );
};

type ChooserContainerProps = ComponentProps<typeof Chooser.Container>;

type ChooserModalProps = {
  onSubmit?: (
    files: ChooserFile[],
    skipFolderExtensions?: boolean,
    latestVersionsOnly?: boolean,
  ) => Promise<void> | void;
  multiSelectEnabled: boolean;
  selectionType: 'file' | 'folder';
  uploadType: UploadType | null;
  extensions?: string[];
  showCreateFolder?: boolean;
};

export const ChooserModal = ({
  onSubmit = () => {},
  multiSelectEnabled,
  selectionType,
  uploadType,
  extensions,
  showCreateFolder,
}: ChooserModalProps) => {
  const {chooserOpen, setChooserOpen, archiveLatestOnly, setArchiveLatestOnly} =
    useDropboxChooser();
  const {dropboxAuth} = useReelAppGlobalState();
  const intl = useIntl();

  const handleSubmit: ChooserContainerProps['onSubmit'] = React.useCallback(
    (files) => {
      setChooserOpen(false);
      const chooserFiles = files.map((x) => ({...x, fileSize: x.bytes, relativePath: ''}));
      onSubmit(chooserFiles, undefined, archiveLatestOnly);
    },
    [onSubmit, setChooserOpen, archiveLatestOnly],
  );

  const getTitle = () => {
    if (uploadType === 'archive') {
      return (
        <FormattedMessage
          defaultMessage="Choose archive folder"
          description="Title on modal that allows users to choose archive folder."
          id="LB+Bys"
        />
      );
    }

    return selectionType === 'file' ? (
      <FormattedMessage
        defaultMessage="Add files from Dropbox"
        description="Title on modal that allows users to upload files from Dropbox."
        id="xq7pwd"
      />
    ) : (
      <FormattedMessage
        defaultMessage="Add folder from Dropbox"
        description="Title on modal that allows users to upload a folder from Dropbox."
        id="IlOZM2"
      />
    );
  };

  const handleEvent = React.useCallback((event: OnChooserEvent) => {
    if (event.type === 'navigate' && event.route.feature === 'browse') {
      replayStorage.setChooserLastPath(event.route.path);
    }
  }, []);

  const submitText =
    uploadType === 'archive'
      ? intl.formatMessage({
          defaultMessage: 'Choose',
          id: 'd/637H',
          description: 'Text on button for choosing a folder to archive to from Dropbox chooser',
        })
      : intl.formatMessage({
          defaultMessage: 'Add',
          id: '9DeCP+',
          description:
            'Text on button to submit adding files from Dropbox chooser into a Replay project',
        });
  const cancelText = intl.formatMessage({
    defaultMessage: 'Cancel',
    id: 'ATL827',
    description: 'Text on button to cancel adding files from Dropbox chooser into a Replay project',
  });

  return (
    <StyledModal onRequestClose={() => setChooserOpen(false)} open={chooserOpen} width="large">
      <Chooser.Container
        auth={dropboxAuth}
        extensions={extensions}
        fileSelect={selectionType === 'file'}
        folderSelect={selectionType === 'folder'}
        hideUnselectableFiles={false}
        initialNavigationPath={replayStorage.getChooserLastPath()}
        initialNavigationTab={'browse' as NavigationFeature}
        multiSelect={multiSelectEnabled}
        navigationTabs={['recents', 'browse'] as NavigationFeature[]}
        onCancel={() => setChooserOpen(false)}
        onEvent={handleEvent}
        onSubmit={handleSubmit}
      >
        <Header>
          <Title>{getTitle()}</Title>
          <SearchWrapper>
            <Chooser.Search />
          </SearchWrapper>
        </Header>
        <ActionsMenuWrapper>
          <Chooser.ActionsMenu showTabs />
        </ActionsMenuWrapper>
        <FileViewWrapper>
          <Chooser.FileView />
        </FileViewWrapper>
        {useArchiveToDropboxEnabled() && uploadType === 'archive' && (
          <CheckboxWrapper>
            <ArchiveCheckbox
              archiveLatestOnly={archiveLatestOnly}
              setArchiveLatestOnly={setArchiveLatestOnly}
            />
          </CheckboxWrapper>
        )}
        <Chooser.Footer
          cancelText={cancelText}
          showCreateFolder={showCreateFolder}
          submitText={submitText}
        />
      </Chooser.Container>
    </StyledModal>
  );
};
