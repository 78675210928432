import React from 'react';

import {FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';

import {Link, Text} from '@dropbox/dig-components/typography';

import {PasswordInput} from './password_input';
import type {LinkInfoResult} from '../lib/api';

const ShareModalLink = styled(Link)<{disabled: boolean}>`
  cursor: pointer;
  ${(props) => props.disabled && 'color: var(--dig-color__text__subtle);'}
  &&&:focus {
    padding: 0;
  }
`;

const EditingRow = styled.div`
  display: flex;
  gap: 24px;
  margin-top: 16px;
`;

export type LinkInfo = null | {
  hasPassword: boolean;
};

export type ExistingLinkRequest =
  | {
      status: 'none';
    }
  | {
      status: 'pending';
    }
  | {
      status: 'complete';
      result: LinkInfoResult;
    };

export const PasswordControlsV2 = (props: {
  hasExistingPassword: boolean;
  disabled: boolean;
  userCanSetPassword: boolean;
  onEdit: () => void;
  updatePassword: (updatedPassword: string) => void;
}) => {
  const {hasExistingPassword, disabled, userCanSetPassword, onEdit, updatePassword} = props;

  if (!userCanSetPassword && hasExistingPassword) {
    // If the user cannot set the password, we don't want to show anything here.
    // They should only be able to uncheck the box to remove the password.
    return null;
  }

  return hasExistingPassword ? (
    <ExistingPasswordControlsV2
      disabled={disabled}
      onEdit={onEdit}
      updatePassword={updatePassword}
    />
  ) : (
    <PasswordEditingControlsV2 disabled={disabled} updatePassword={updatePassword} />
  );
};

const ExistingPasswordControlsV2 = (props: {
  disabled: boolean;
  onEdit: () => void;
  updatePassword: (updatedPassword: string) => void;
}) => {
  const {disabled, onEdit, updatePassword} = props;
  const [showEditor, setShowEditor] = React.useState(false);
  const onClick = () => {
    onEdit();
    setShowEditor(true);
  };

  return (
    <>
      {!showEditor ? (
        <Text size="small" style={{whiteSpace: 'nowrap'}}>
          <ShareModalLink disabled={disabled} href="#" isBold onClick={onClick}>
            <FormattedMessage
              defaultMessage="Edit password"
              description="Text for a link that triggers a password input to edit an existing password to a shared link"
              id="ShJj1K"
            />
          </ShareModalLink>
        </Text>
      ) : (
        <PasswordEditingControlsV2 disabled={disabled} updatePassword={updatePassword} />
      )}
    </>
  );
};

const PasswordEditingControlsV2 = (props: {
  disabled: boolean;
  updatePassword: (updatedPassword: string) => void;
}) => {
  const {disabled, updatePassword} = props;
  const intl = useIntl();

  return (
    <EditingRow>
      <PasswordInput
        disabled={disabled}
        onChange={updatePassword}
        placeholder={intl.formatMessage({
          defaultMessage: 'Set password',
          id: 'pVjqZb',
          description:
            'Placeholder text for password input when user is editing or adding password to a share link',
        })}
      />
    </EditingRow>
  );
};
