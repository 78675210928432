import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import {useSetAtom} from 'jotai';
import {PAP_Shown_ReplayProtoolsConnectionModal} from 'pap-events/replay/shown_replay_protools_connection_modal';
import {useNavigate} from 'react-router';

import {HAS_DISMISSED_PROTOOLS_CONNECTION_MODAL, replayStorage} from '~/lib/storage';
import {
  proToolsAppDetectedAtom,
  ProToolsConnectionState,
  proToolsConnectionStateAtom,
  ProToolsExportCommentsState,
  proToolsExportCommentsStateAtom,
  showProToolsConnectionModalAtom,
} from '~/state/pro_tools';

import {useReelAppGlobalState} from './context';
import {getVideo} from './lib/api';
import {useStormcrows} from './lib/stormcrow';
import {VERSION_ID_QUERY_STRING_KEY} from './lib/url_utils';
import {useLoggingClient} from './lib/use_logging_client';

/** Response from Host app (Adobe: main.js RedCarpetMessageType, FCP/Mac: RedCarpetXViewController) to Replay RedCarpet */

export enum ResponseMessageType {
  UploadNewVersion = 'uploadNewVersion',
  ActiveSequenceData = 'activeSequenceData',
  ExtensionLoaded = 'extensionLoaded',
  UploadComposition = 'uploadComposition',
  HasQueuedCompositions = 'hasQueuedCompositions',
  UploadQueuedCompositions = 'uploadQueuedCompositions',
  UserLogout = 'userLogout',
  // These two are only sent by Adobe extension versionsolder than 1.3.0
  OpenExtension = 'openExtension',
  ExtensionVersion = 'extensionVersion',
  // Avid Pro Tools
  ProToolsAppDetected = 'proToolsAppDetected',
  ProToolsRegisterConnectionRequest = 'proToolsRegisterConnectionRequest',
  ProToolsRegisterConnectionSuccess = 'proToolsRegisterConnectionSuccess',
  ProToolsRegisterConnectionError = 'proToolsRegisterConnectionError',
  ProToolsDisplayConnectionModal = 'proToolsDisplayConnectionModal',
  ProToolsExportCommentsSuccess = 'proToolsExportCommentsSuccess',
  ProToolsExportCommentsError = 'proToolsExportCommentsError',
}

/** Message sent from Replay RedCarpet to Host App (Adobe main.js or FCP/Mac RedCarpetXViewController) */
export enum SendMessageType {
  ExtensionLoaded = 'extensionLoaded',
  SetPlayerPosition = 'setPlayerPosition',
  GetActiveSequenceData = 'getActiveSequenceData',
  RenderActiveSequence = 'renderActiveSequence',
  LinkVideoToActiveSequence = 'linkVideoToActiveSequence',
  LinkVideoToComposition = 'linkVideoToComposition',
  ImportComments = 'importComments',
  DragImportFCPXML = 'dragImportFCPXML',
  ResetActiveSequenceData = 'resetActiveSequenceData',
  RenderActiveComposition = 'renderActiveComposition',
  HasQueuedCompositions = 'hasQueuedCompositions',
  UploadQueuedCompositions = 'uploadQueuedCompositions',
  UserLogout = 'userLogout',
  // Needed for older versions of the Adobe Plugin
  GetExtensionVersion = 'getExtensionVersion',
  // For Avid Pro Tools
  ProToolsRegisterConnectionRequest = 'proToolsRegisterConnectionRequest',
  ProToolsExportCommentsRequest = 'proToolsExportCommentsRequest',
}

export enum RenderStatus {
  INIT = 'INIT',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETE',
  ERROR = 'ERROR',
  CANCELED = 'CANCELED',
}
// map feature with require versions of the extension
// this allow us only show features when the user is running
// at least the required version
const requiredVersionForFeature = {
  uploadActiveSequence: '1.1.0',
  linkVideoToComposition: '1.4.0',
};

export type ActiveSequenceData = {
  projectId?: string;
  videoId?: string;
  videoVersionId?: string;
  autoSyncComments?: boolean;
  rangeIn?: number;
  rangeOut?: number;
};

export type RedCarpetFeatures = {
  importComments: boolean;
  syncComments: boolean;
  linkPlayhead: boolean;
  linkVideoToComposition: boolean;
  uploadActiveSequence: boolean;
  uploadActiveComposition: boolean;
  uploadQueuedCompositions: boolean;
};

export enum RedCarpetMode {
  NONE = 'NONE',
  PPRO = 'PPRO',
  AEFT = 'AEFT',
  MAC = 'MAC',
  FCP = 'FCP',
}

export type RedCarpet = {
  extensionVersion: string;
  features: RedCarpetFeatures;
  mode: RedCarpetMode;
  sendMessage: null | (<T = any>(messageName: SendMessageType, params?: any) => Promise<T>);
};

export const isInAdobeExtension = () =>
  navigator.userAgent.includes('com.dropbox.replay.redcarpet'); // TRUE if the app is running inside the Adobe CEP extensions (AEFT, PPRO)
export const isFinalCutPro = () => navigator.userAgent.includes('com.dropbox.replay.fcp');
export const isMacApp = () => navigator.userAgent.includes('com.dropbox.replay.mac');
export const isInWebKit = () => isFinalCutPro() || isMacApp(); // TRUE if the app is running inside a WebKit based wrapper (FCP or MAC)
export const isInWebView = () => isInAdobeExtension() || isInWebKit(); // TRUE if the app is running inside any web wrapper (PPRO, AEFT, FCP or the MAC wrapper)
export const isInExtension = () => isInAdobeExtension() || isFinalCutPro(); // TRUE if the app is running in PPRO, AEFT, or FCP

export const useExtensions = () => {
  const sessionContext = useReelAppGlobalState();
  const stormcrows = useStormcrows();
  const loggingClient = useLoggingClient();
  const navigate = useNavigate();
  const [hasLoadedExtension, setHasLoadedExtension] = useState(false);
  const [activeSequenceData, setActiveSequenceData] = useState<ActiveSequenceData>({});
  const promisesRef = useRef<any>({}); // We use a ref here such that we don't re-register a new event handler everytime promises changes
  const [redCarpet, setRedCarpet] = useState<RedCarpet>({
    extensionVersion: '',
    sendMessage: null,
    mode: RedCarpetMode.NONE,
    features: {
      importComments: false,
      syncComments: false,
      linkPlayhead: false,
      linkVideoToComposition: false,
      uploadActiveSequence: false,
      uploadActiveComposition: false,
      uploadQueuedCompositions: false,
    },
  });

  /**
   * This method will send a postMessage to the parent extension
   * with an id so it can track down the response message, it also creates a promise that will
   * resolve when the success response is received or rejected if there is any errors.
   *
   * returns Promise
   */
  const sendMessage = useCallback(function <T = any>(
    messageName: SendMessageType,
    params?: any,
  ): Promise<T> {
    const id = Date.now();
    const promise = new Promise<T>((resolve, reject) => {
      promisesRef.current[id] = {reject, resolve};
    });
    const message = JSON.stringify({
      message: messageName,
      id,
      ...params,
    });

    // @ts-ignore
    const redcarpetX = window.webkit?.messageHandlers?.redcarpet;

    if (redcarpetX) {
      redcarpetX.postMessage(message);
    } else {
      window.parent.postMessage(message, '*');
    }
    return promise;
  },
  []);

  const loadActiveSequenceData = useCallback(
    async (redirect?: boolean) => {
      if (redCarpet.features.uploadActiveSequence || redCarpet.features.uploadActiveComposition) {
        const response = await sendMessage<{data: string}>(SendMessageType.GetActiveSequenceData);
        const data = JSON.parse(response.data || '{}');
        if (data) {
          setActiveSequenceData({
            ...data,
            // PPRO returns autoSyncComments as 'True' or 'False' so we check to make sure
            // we are setting the correct value and not a truthy value
            autoSyncComments:
              typeof data.autoSyncComments === 'string'
                ? data.autoSyncComments === 'True'
                : data.autoSyncComments,
            rangeIn: typeof data.rangeIn === 'string' ? parseFloat(data.rangeIn) : data.rangeIn,
            rangeOut: typeof data.rangeOut === 'string' ? parseFloat(data.rangeOut) : data.rangeOut,
          });

          if (redirect && data.videoId) {
            try {
              const result = await getVideo(data.videoId, data.videoVersionId);
              if (result) {
                const params = new URLSearchParams(window.location.search);
                if (data.videoVersionId) {
                  params.set(VERSION_ID_QUERY_STRING_KEY, data.videoVersionId);
                }
                navigate(
                  `/project/${result.projectId}/video/${result.videoId}?${params.toString()}`,
                );
              } else {
                throw new Error('No video returned from api');
              }
            } catch (e) {
              if (redCarpet.mode === RedCarpetMode.PPRO) {
                sendMessage(SendMessageType.ResetActiveSequenceData);
              }
            }
          }
        }
      }
    },
    [redCarpet, sendMessage, navigate],
  );

  const setProToolsAppDetected = useSetAtom(proToolsAppDetectedAtom);
  const setProToolsConnectionState = useSetAtom(proToolsConnectionStateAtom);
  const setProToolsExportCommentsState = useSetAtom(proToolsExportCommentsStateAtom);
  const setShowProToolsConnectionModal = useSetAtom(showProToolsConnectionModalAtom);

  const handleMessages = useCallback(
    (event: MessageEvent) => {
      if (event.data) {
        let data = {} as any;
        try {
          data = typeof event.data === 'object' ? event.data : JSON.parse(event.data || {});
        } catch {
          // event.data was not json, happens outside extensions in dev
        }
        switch (data.message) {
          case ResponseMessageType.ExtensionLoaded:
          case ResponseMessageType.UserLogout:
          case ResponseMessageType.ActiveSequenceData:
          case ResponseMessageType.UploadNewVersion:
          case ResponseMessageType.UploadComposition:
          case ResponseMessageType.HasQueuedCompositions:
          case ResponseMessageType.UploadQueuedCompositions:
          case ResponseMessageType.ProToolsRegisterConnectionRequest: {
            const request = promisesRef.current[data.id];
            if (request) {
              if (data.status === RenderStatus.ERROR || data.status === RenderStatus.CANCELED) {
                request.reject(data);
              } else {
                request.resolve(data);
              }
              delete promisesRef.current[data.id];
            }
            break;
          }
          case ResponseMessageType.ProToolsAppDetected: {
            setProToolsAppDetected(true);
            break;
          }
          case ResponseMessageType.ProToolsRegisterConnectionSuccess: {
            setProToolsConnectionState(ProToolsConnectionState.Connected);
            break;
          }
          case ResponseMessageType.ProToolsRegisterConnectionError: {
            setProToolsConnectionState(ProToolsConnectionState.Errored);
            loggingClient.logPap(
              PAP_Shown_ReplayProtoolsConnectionModal({
                replayProtoolsConnectionModalReason: 'error',
              }),
            );
            // eslint-disable-next-line deprecation/deprecation
            loggingClient.logEvent('shown_protools_connection_modal', {
              connection_reason: 'error',
            });
            break;
          }
          case ResponseMessageType.ProToolsDisplayConnectionModal: {
            replayStorage.set(HAS_DISMISSED_PROTOOLS_CONNECTION_MODAL, false);
            setShowProToolsConnectionModal(true);
            loggingClient.logPap(
              PAP_Shown_ReplayProtoolsConnectionModal({
                replayProtoolsConnectionModalReason: 'manual',
              }),
            );
            // eslint-disable-next-line deprecation/deprecation
            loggingClient.logEvent('shown_protools_connection_modal', {
              connection_reason: 'manual',
            });
            break;
          }
          case ResponseMessageType.ProToolsExportCommentsSuccess: {
            setProToolsExportCommentsState(ProToolsExportCommentsState.Exported);
            break;
          }
          case ResponseMessageType.ProToolsExportCommentsError: {
            // We'll display a ProToolsRegisterConnectionError so hide the ProToolsCommentsSnackbar
            setProToolsExportCommentsState(ProToolsExportCommentsState.Idle);
            break;
          }
          case ResponseMessageType.OpenExtension: {
            // Only the Adobe extension version 1.2.0 and earlier sends this message
            sendMessage(SendMessageType.GetExtensionVersion);
            break;
          }
          case ResponseMessageType.ExtensionVersion: {
            if (data.version) {
              // Only the PremierePro extension version 1.2.0 and earlier sends this message
              const uploadActiveSequenceEnabled = isVersionSupported(
                requiredVersionForFeature['uploadActiveSequence'],
                data.version,
              );
              setRedCarpet((prev) => ({
                ...prev,
                sendMessage,
                mode: RedCarpetMode.PPRO,
                extensionVersion: data.version || '',
                features: {
                  ...prev.features,
                  importComments: true,
                  linkPlayhead: true,
                  syncComments: uploadActiveSequenceEnabled,
                  uploadActiveSequence: uploadActiveSequenceEnabled,
                },
              }));
            }
            break;
          }
        }
      }
    },
    [
      setProToolsAppDetected,
      setProToolsConnectionState,
      loggingClient,
      setShowProToolsConnectionModal,
      setProToolsExportCommentsState,
      sendMessage,
    ],
  );

  // Listen to premiere pro or after effects messages
  useEffect(() => {
    if (isInWebView()) {
      window.addEventListener('message', handleMessages);
      return () => {
        window.removeEventListener('message', handleMessages);
      };
    }
    return () => {};
  }, [handleMessages]);

  useEffect(() => {
    if (hasLoadedExtension) {
      // If the app is AEFT, load the active sequence data and redirect to the video in Replay if the active composition is linked
      if (
        redCarpet.mode === RedCarpetMode.AEFT ||
        (redCarpet.mode === RedCarpetMode.PPRO && redCarpet.features.uploadActiveSequence)
      ) {
        loadActiveSequenceData(true);
      }
    }
  }, [
    hasLoadedExtension,
    loadActiveSequenceData,
    redCarpet.features.uploadActiveSequence,
    redCarpet.mode,
  ]);

  // On extension load, perform logging, feature flag checks, and set the app constants
  useEffect(() => {
    if (hasLoadedExtension) {
      return;
    }

    if (isInWebKit()) {
      // Set the extension version, features and app constants for Mac and FCP
      setRedCarpet((prev) => ({
        ...prev,
        sendMessage,
        mode: isFinalCutPro() ? RedCarpetMode.FCP : RedCarpetMode.MAC,
        features: {
          ...prev.features,
          linkPlayhead: isFinalCutPro(),
        },
      }));
    }

    setHasLoadedExtension(true);

    if (!isInAdobeExtension()) {
      if (isMacApp()) {
        // We just need to let the mac app know that we've loaded and can accept messages now.
        sendMessage(SendMessageType.ExtensionLoaded);
      }

      return;
    }

    sendMessage(SendMessageType.ExtensionLoaded).then((response) => {
      const data = response?.data ?? {};
      const isPremierePro = data.appName === 'PPRO';
      const isAfterEffects = data.appName === 'AEFT';
      let uploadActiveSequenceEnabled = false;
      let linkVideoToCompositionEnabled = false;

      // Log the extension open event
      // If the app is PPRO, log the attempt_premiere_sign_in event if the user is logged out
      if (isPremierePro) {
        // eslint-disable-next-line deprecation/deprecation
        loggingClient.logEvent('open_replay_extension', {redCarpetMode: data.appName});
        if (sessionContext.status === 'logged out') {
          // eslint-disable-next-line deprecation/deprecation
          loggingClient.logEvent('attempt_premiere_device_flow_sign_in');
        }
        // If the app is AEFT, log the open extension event
      } else if (isAfterEffects) {
        // eslint-disable-next-line deprecation/deprecation
        loggingClient.logEvent('open_after_effects_extension');
      }

      if (isPremierePro) {
        uploadActiveSequenceEnabled = isVersionSupported(
          requiredVersionForFeature.uploadActiveSequence,
          data.version,
        );
      }

      if (isAfterEffects) {
        linkVideoToCompositionEnabled = isVersionSupported(
          requiredVersionForFeature.linkVideoToComposition,
          data.version,
        );
      }

      // Set the extension version, features and app constants
      setRedCarpet((prev) => ({
        ...prev,
        sendMessage,
        extensionVersion: data.version || '',
        mode: isPremierePro
          ? RedCarpetMode.PPRO
          : isAfterEffects
          ? RedCarpetMode.AEFT
          : RedCarpetMode.NONE,
        features: {
          ...prev.features,
          importComments: isInAdobeExtension(),
          syncComments: isInAdobeExtension() || (isPremierePro && uploadActiveSequenceEnabled),
          linkPlayhead: isInExtension() && !isAfterEffects,
          linkVideoToComposition: linkVideoToCompositionEnabled,
          uploadActiveSequence: uploadActiveSequenceEnabled,
          uploadActiveComposition: isAfterEffects,
          uploadQueuedCompositions: isAfterEffects,
        },
      }));
    });
  }, [
    hasLoadedExtension,
    loadActiveSequenceData,
    loggingClient,
    sendMessage,
    sessionContext.status,
    stormcrows,
  ]);

  return useMemo(() => {
    return {
      loggingClient,
      redCarpet,
      sendMessage,
      activeSequenceData,
      loadActiveSequenceData,
    };
  }, [loggingClient, redCarpet, sendMessage, activeSequenceData, loadActiveSequenceData]);
};

// At least current version needs to be greater than or equal to required version to be supported
const isVersionSupported = (requiredVersion?: string, currentVersion?: string) => {
  if (!requiredVersion || !currentVersion) {
    // No version present, we don't need to log it
    return false;
  }
  const currentParts = currentVersion.split('.');
  const requiredParts = requiredVersion.split('.');

  for (let i = 0; i < requiredParts.length; i++) {
    const currentPart = parseInt(currentParts[i], 10) || 0;
    const requiredPart = parseInt(requiredParts[i], 10) || 0;

    if (currentPart < requiredPart) {
      return false;
    } else if (currentPart > requiredPart) {
      return true;
    }
  }

  return true;
};
