import {useCallback, useState} from 'react';

import {PAP_Select_WorkspaceAction} from 'pap-events/replay/select_workspace_action';

import {CreateFolderModal} from '~/components/create_folder_modal';
import type {PapActionElement} from '~/lib/logging/logger_types';
import {useLoggingClient} from '~/lib/use_logging_client';
import {useFoldersSharedState} from '~/pages/browse_page/folders_context';

export type onCreateFolderSuccessFn = (folderToShow: string, level: number) => void;

type useCreateProjectProps = {
  onCreateFolderSuccess?: onCreateFolderSuccessFn;
  actionElement: PapActionElement;
};
const useCreateProject = ({onCreateFolderSuccess, actionElement}: useCreateProjectProps) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const loggingClient = useLoggingClient();
  const {currentFolderId, breadcrumbPath} = useFoldersSharedState();

  const folderLevel = breadcrumbPath ? breadcrumbPath.length : 1;

  const handleOpenCreateFolderModal = useCallback(() => {
    loggingClient.logPap(
      PAP_Select_WorkspaceAction({
        workspaceAction: 'create_project',
        actionElement: actionElement,
      }),
    );
    setModalOpen(true);
  }, [actionElement, loggingClient]);

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  const folderCreateSuccess = useCallback(
    (folderToShow: string, level: number) => {
      setModalOpen(false);

      if (onCreateFolderSuccess) {
        onCreateFolderSuccess(folderToShow, level);
      }
    },
    [onCreateFolderSuccess],
  );

  const createFolderModal = (
    <CreateFolderModal
      isProject
      level={folderLevel}
      onFolderCreateSuccess={folderCreateSuccess}
      open={modalOpen}
      parentFolderId={currentFolderId}
      requestClose={closeModal}
    />
  );

  return {
    createFolderModal,
    handleOpenCreateFolderModal,
  };
};

export {useCreateProject};
