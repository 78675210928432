import {FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';

import {Title} from '@dropbox/dig-components/typography';
import {UIIcon} from '@dropbox/dig-icons';
import {CloseLine} from '@dropbox/dig-icons/assets';
import {ReplayWordmark, WordmarkLogo} from '@dropbox/dig-logos';

import {appStrings} from '~/components/app_strings';
import {IconButton} from '~/components/button';
import {ReelLink} from '~/components/link';

import type {NavigationRouteProps} from './common';
import {useFoldersSharedState} from '../folders_context';

const LeftRailWrapper = styled.div<{$showLeftRail: boolean}>`
  display: block;
  box-sizing: border-box;
  height: 100%;
  max-width: 235px;
  width: ${(props) => (props.$showLeftRail ? 235 : 0)}px;
  position: absolute;
  z-index: 16;
  left: 0;
  background-color: #242121;
  color: white;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 60px;
  padding-bottom: 16px;
  transition: width 0.25s;
`;

const CloseButtonStyled = styled(IconButton)`
  position: relative;
  margin-left: auto;
  margin-top: 2px;
  margin-right: 4px;
`;

const LinkText = styled(Title)<{$selected: boolean}>`
  padding-left: 16px;
  &&& {
    margin-top: 0px;
  }
  color: var(--dig-color__text__subtle);
  ${(props) => (props.$selected ? 'color: white' : '')};
`;

const LeftRailContentWrapper = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
`;

export const NavigationLeftRail = (props: NavigationRouteProps) => {
  const {isMobileNavBarOpen, setIsMobileNavBarOpen} = useFoldersSharedState();
  const intl = useIntl();

  const handleClose = () => {
    setIsMobileNavBarOpen(false);
  };

  return (
    <LeftRailWrapper
      $showLeftRail={isMobileNavBarOpen}
      aria-label={intl.formatMessage({
        defaultMessage: 'Replay navigation menu',
        id: 'JNJDUK',
        description: 'Menu containing Replay top-level links',
      })}
      role="dialog"
    >
      <div style={{display: 'flex'}}>
        <WordmarkLogo size={32} src={ReplayWordmark} />
        <CloseButtonStyled
          aria-label={intl.formatMessage({
            defaultMessage: 'Close',
            id: '1s1Y9U',
            description: 'Close replay navigation menu',
          })}
          onClick={handleClose}
          variant="transparent"
        >
          <UIIcon src={CloseLine} />
        </CloseButtonStyled>
      </div>
      <LeftRailContentWrapper>
        <ReelLink to="/">
          <LinkText $selected={props.routeName === 'browse-home'} size="small" tagName="h3">
            <FormattedMessage {...appStrings.homeTitle} />
          </LinkText>
        </ReelLink>
        <ReelLink to="/shared">
          <LinkText $selected={props.routeName === 'browse-shared'} size="small" tagName="h3">
            <FormattedMessage {...appStrings.sharedTitle} />
          </LinkText>
        </ReelLink>
        <ReelLink to="/settings">
          <LinkText $selected={props.routeName === 'settings-page'} size="small" tagName="h3">
            <FormattedMessage {...appStrings.settingsTitle} />
          </LinkText>
        </ReelLink>
      </LeftRailContentWrapper>
    </LeftRailWrapper>
  );
};
