import {useMatch, useNavigate} from 'react-router';

import {useReelAppGlobalState} from '~/context';
import {TUTORIALS_ROUTE_MATCH} from '~/route_path_matches';

const useMatchesTutorialsRoute = () => {
  const navigate = useNavigate();
  const matchesTutorials = useMatch({path: TUTORIALS_ROUTE_MATCH});
  const {status} = useReelAppGlobalState();

  const handleClickBackToTutorials = () => {
    navigate(TUTORIALS_ROUTE_MATCH);
  };

  return {
    matchesTutorials,
    handleClickBackToTutorials,
    hasLoggedOutError: matchesTutorials && status === 'logged out',
  };
};

export {useMatchesTutorialsRoute};
