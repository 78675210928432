import React from 'react';

import debounce from 'lodash/debounce';

import {isHoveringSupported, isTouchSupported} from '~/lib/mobile-utils';

import {reportBadContextUseError} from '../lib/error_reporting';

/**
 * @deprecated
 * If you need a JS breakpoint, use `useBreakpoint` from `@dropbox/dig-components/hooks`.
 *
 * If you need a CSS breakpoint, use `breakpoint<Small, Medium, Large, XLarge>` from `~/components/styled`.
 *
 * If you just need the breakpoint value, use `bpSizeMap` from `@dropbox/dig-components/hooks`.
 */
export const VERY_SMALL_SCREEN_WIDTH_BREAKPOINT = 350;
/**
 * @deprecated
 * If you need a JS breakpoint, use `useBreakpoint` from `@dropbox/dig-components/hooks`.
 *
 * If you need a CSS breakpoint, use `breakpoint<Small, Medium, Large, XLarge>` from `~/components/styled`.
 *
 * If you just need the breakpoint value, use `bpSizeMap` from `@dropbox/dig-components/hooks`.
 */
export const EXTRA_SMALL_SCREEN_WIDTH_BREAKPOINT = 475;
/**
 * @deprecated
 * If you need a JS breakpoint, use `useBreakpoint` from `@dropbox/dig-components/hooks`.
 *
 * If you need a CSS breakpoint, use `breakpoint<Small, Medium, Large, XLarge>` from `~/components/styled`.
 *
 * If you just need the breakpoint value, use `bpSizeMap` from `@dropbox/dig-components/hooks`.
 */
export const SMALL_SCREEN_WIDTH_BREAKPOINT = 800;
/**
 * @deprecated
 * If you need a JS breakpoint, use `useBreakpoint` from `@dropbox/dig-components/hooks`.
 *
 * If you need a CSS breakpoint, use `breakpoint<Small, Medium, Large, XLarge>` from `~/components/styled`.
 *
 * If you just need the breakpoint value, use `bpSizeMap` from `@dropbox/dig-components/hooks`.
 */
export const MEDIUM_SCREEN_WIDTH_BREAKPOINT = 1040;

export const SMALL_SCREEN_HEIGHT_BREAKPOINT = 560;
// If the _visual viewport_ is shorter than this value, we infer that the
// user has scrolled well past the bottom of the page on iOS (!), which is
// only possible with the keyboard open.
export const SCROLLED_SHORTENED_SCREEN_HEIGHT_BREAKPOINT = 350;

export const TRANSITION_DURATION_MS = 300;

type ViewportValue = {
  height: number;
  width: number;
  /**
   * @deprecated Use `useBreakpoint` from `@dropbox/dig-components/hooks` instead.
   */
  isSmallScreenSize: boolean;
  /**
   * @deprecated Use `useBreakpoint` from `@dropbox/dig-components/hooks` instead.
   */
  isMediumDownScreenSize: boolean;
  isMobileDevice: boolean;
  isHoverSupported: boolean;
};
const ViewportContext = React.createContext<ViewportValue | null>(null);

export const ViewportProvider = ({children}: React.PropsWithChildren<{}>) => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);

  React.useEffect(() => {
    const debouncedHandler = debounce(() => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }, 100);

    window.addEventListener('resize', debouncedHandler);

    return () => {
      window.removeEventListener('resize', debouncedHandler);
    };
  }, []);

  // eslint-disable-next-line deprecation/deprecation
  const isSmallScreenSize = width <= SMALL_SCREEN_WIDTH_BREAKPOINT;
  // eslint-disable-next-line deprecation/deprecation
  const isMediumDownScreenSize = width <= MEDIUM_SCREEN_WIDTH_BREAKPOINT;
  const isMobileDevice = isSmallScreenSize && isTouchSupported();
  const isHoverSupported = isHoveringSupported();

  const providerValue: ViewportValue = React.useMemo(
    () => ({
      height,
      width,
      isSmallScreenSize,
      isMediumDownScreenSize,
      isMobileDevice,
      isHoverSupported,
    }),
    [height, width, isSmallScreenSize, isMediumDownScreenSize, isMobileDevice, isHoverSupported],
  );

  return <ViewportContext.Provider value={providerValue}>{children}</ViewportContext.Provider>;
};

export const useViewport = () => {
  const makeViewport = React.useContext(ViewportContext);

  if (makeViewport === null) {
    const error = new Error('useViewport must be used within a ViewportProvider');
    reportBadContextUseError(error);
    throw error;
  }
  return makeViewport;
};
