import React from 'react';

import {useAtomValue} from 'jotai';
import type {IntlShape} from 'react-intl';
import {FormattedMessage, useIntl} from 'react-intl';
import styled, {css} from 'styled-components';

import type {WrapperRefObject} from '@dropbox/dig-components/menu';
import {Menu} from '@dropbox/dig-components/menu';
import type {OverlayPlacement} from '@dropbox/dig-components/overlay';
import {Link, Text} from '@dropbox/dig-components/typography';
import {UIIcon} from '@dropbox/dig-icons';
import {
  AddFolderLine,
  AddLine,
  AddMediaLine,
  DropboxLine,
  UploadLine,
} from '@dropbox/dig-icons/assets';

import {Button} from '~/components/button';
import type {ButtonSize, ButtonVariant} from '~/components/button';
import {EXCLUDED_FILES, MAX_DOCUMENT_UPLOAD_SIZE, MAX_FOLDER_DEPTH} from '~/components/common';
import {CreateFolderModal} from '~/components/create_folder_modal';
import {useDropboxChooser} from '~/components/dropbox_chooser_context';
import {useExtensionContext} from '~/components/extension_context';
import {useGDriveContext} from '~/components/gdrive_context';
import {InvalidExtensionModal} from '~/components/invalid_extensions_modal';
import {MaxDepthExceededModal} from '~/components/max_depth_exceeded_modal';
import {useOneDriveContext} from '~/components/onedrive_context';
import {PaywallModal} from '~/components/paywall_modal';
import {ProToolsTooltip} from '~/components/pro_tools_tooltip';
import {useRenderContext} from '~/components/render_context';
import {useErrorSnackbar} from '~/components/snackbar/error_snackbar';
import {GoogleDriveIcon, OneDriveIcon} from '~/components/third_party_icons';
import {useUpload} from '~/components/upload_context';
import {useViewport} from '~/components/viewport_context';
import {getExtension, isPdfExtension, useValidExtensions} from '~/lib/helpers';
import type {AddMediaClickSourceType} from '~/lib/logging/logger_types';
import {useFocusRing} from '~/lib/onboarding_v2/use_focus_ring';
import type {FocusStyles} from '~/lib/onboarding_v2/use_focus_ring';
import {
  SINGLE_PURCHASE_URL,
  useExceededFileQuota,
  useGetFileQuotaText,
  useGetProvisionCta,
  useGetUserVariant,
  useShowFileQuotaInfo,
} from '~/lib/provisions';
import {UPLOAD_IN_PROGRESS_STATES} from '~/lib/uploads/types';
import type {
  AllUploadsCompleteHandler,
  BasicFileInfo,
  ChooserFile,
  DirectUpload,
  StreamableFile,
  UploadFilePickHandler,
} from '~/lib/uploads/types';
import {useGetDocumentUploadSizeExceededErrorText} from '~/lib/uploads/uploads';
import {
  getFolderDepthFromPaths,
  openURLInExternalBrowser,
  useIsTeamLocked,
  useReelProvisioningEnabled,
} from '~/lib/utils';
import {useBrowseLogEventMaker} from '~/pages/browse_page/browse_logging_context';
import {STEPS} from '~/state/onboarding_v2';
import {canShowProToolsTooltipAtom} from '~/state/pro_tools';
import {isInWebView} from '~/use_extensions';

type AddMediaButtonProps = {
  clickSource: AddMediaClickSourceType;
  currentFolderId: string;
  currentFolderDepth: number;
  onAddFromDropbox: (files: ChooserFile[]) => void;
  onFileUploadComplete?: (upload: DirectUpload, videoId: string) => void;
  onAllUploadsComplete: AllUploadsCompleteHandler;
  onAddError: () => void;
  onFilePick: (files: BasicFileInfo[], folderUpload?: boolean) => void;
  buttonVariant?: ButtonVariant;
  buttonSize?: ButtonSize;
  buttonText?: string;
  collapseOnMobile?: boolean;
  dropdownPlacement?: OverlayPlacement;
  loadingState?: 'spinner' | 'disabled';
  showMenuContents?: boolean;
  withDropdownIcon?: boolean;
  withIconLeft?: boolean;
  folderUpload?: boolean;
  onCreateFolderSuccessCallback?: (folderToShow: string, level: number) => Promise<void>;
  folderLevel: number;
  includeFocusStyles?: boolean;
};

type MainButtonProps = {
  $focusStyles: FocusStyles;
};
const MainButton = styled(Button)<MainButtonProps>`
  ${(props) => props.$focusStyles};
`;

export const DropboxUploadText = () => (
  <FormattedMessage
    defaultMessage="Dropbox"
    description="Menu item text that opens a Dropbox file picker. Creates a new project with the chosen file."
    id="Aiw/Mo"
  />
);

export const DirectUploadText = () => (
  <FormattedMessage
    defaultMessage="Local drive"
    description="Menu item text that opens a local file picker. Creates a new project with the file or folder chosen from the user's computer."
    id="aZ7nn6"
  />
);

// Adobe Integrations (PPRO/AEFT)

export const UploadActiveSequenceText = () => (
  <FormattedMessage
    defaultMessage="Upload Active Sequence"
    description="Menu item text for PPRO that uploads the active sequence and creates a new version"
    id="4pV/52"
  />
);

export const UploadActiveCompositionText = () => (
  <FormattedMessage
    defaultMessage="Upload Active Composition"
    description="Menu item text for AEFT that uploads the active sequence and creates a new version"
    id="X0cuMA"
  />
);

export const UploadQueuedCompositionsText = () => (
  <FormattedMessage
    defaultMessage="Upload Queued Compositions"
    description="Menu item text for AEFT that uploads the render queue compositions and creates a new version"
    id="L0aoOo"
  />
);

const getButtonText = (
  intl: IntlShape,
  {folderUpload, isSmallScreenSize}: {folderUpload: boolean; isSmallScreenSize: boolean},
) => {
  if (folderUpload && !isSmallScreenSize) {
    return intl.formatMessage({
      defaultMessage: 'Add folder',
      id: 'jVlOQ9',
      description:
        'Button text that opens a menu giving the user different ways to create a new project by choosing files.',
    });
  }
  if (folderUpload && isSmallScreenSize) {
    return intl.formatMessage({
      defaultMessage: 'Folder',
      id: 'tijo5m',
      description:
        'Button text that opens a menu giving the user different ways to create a new project by choosing files.',
    });
  }
  if (!folderUpload && !isSmallScreenSize) {
    return intl.formatMessage({
      defaultMessage: 'Add files',
      id: 'k8mP8C',
      description:
        'Button text that opens a menu giving the user different ways to create a new project by choosing files.',
    });
  }
  return intl.formatMessage({
    defaultMessage: 'File',
    id: '+Shx01',
    description:
      'Button text that opens a menu giving the user different ways to create a new project by choosing files.',
  });
};

export const MediaAdder = ({
  clickSource,
  currentFolderId,
  currentFolderDepth,
  onAddFromDropbox,
  onFileUploadComplete,
  onAllUploadsComplete,
  onAddError,
  onFilePick,
  buttonVariant = 'primary',
  buttonSize,
  buttonText,
  dropdownPlacement = 'bottom-start',
  loadingState = 'disabled',
  showMenuContents,
  withDropdownIcon = true,
  withIconLeft = true,
  folderUpload = false,
  onCreateFolderSuccessCallback,
  folderLevel,
  includeFocusStyles = false,
}: AddMediaButtonProps) => {
  const isProvisioningEnabled = useReelProvisioningEnabled();
  const makeLogEvent = useBrowseLogEventMaker();
  const {chooseFiles, uploadProject, uploadState: directUploadState} = useUpload();
  const {redCarpet} = useExtensionContext();
  const {onUploadActiveSequence, onUploadQueuedCompositions} = useRenderContext();
  const {chooseProjectUploads, uploadState: chooserUploadState} = useDropboxChooser();
  const {startGDriveProjectImport} = useGDriveContext();
  const {startOneDriveProjectImport} = useOneDriveContext();
  // eslint-disable-next-line deprecation/deprecation
  const {isSmallScreenSize} = useViewport();
  const [showCreateFolderModal, setShowCreateFolderModal] = React.useState(false);
  const [extensionModalOpen, setExtensionModalOpen] = React.useState(false);
  const [pendingFiles, setPendingFiles] = React.useState<(File | StreamableFile)[]>([]);
  const [invalidExtensions, setInvalidExtensions] = React.useState<string[]>([]);
  const [maxDepthErrorModalOpen, setMaxDepthErrorModalOpen] = React.useState(false);
  const [paywallModalOpen, setPaywallModalOpen] = React.useState(false);
  const logEvent = React.useMemo(() => makeLogEvent(null), [makeLogEvent]);
  const intl = useIntl();
  const uploadFileFocusStyles = useFocusRing([STEPS.UPLOAD_FILE]);
  const validExtensionsList = useValidExtensions();

  const onModalExtensionClose = React.useCallback(
    (cancelled: boolean) => {
      if (!cancelled) {
        const parsedFiles = pendingFiles.map((file) => {
          return {
            name: file.name,
            parentDirectory: file.webkitRelativePath.split('/')[0],
            relativePath: file.webkitRelativePath,
            fileSize: file.size,
          };
        });

        onFilePick(parsedFiles, folderUpload);
        uploadProject({
          files: pendingFiles,
          currentFolderId,
          onFileUploadComplete,
          onFileError: () => {
            onAddError();
          },
          onAllUploadsComplete: (files, folderId: string) => {
            onAllUploadsComplete(
              files,
              folderId,
              clickSource,
              'direct_upload',
              folderUpload ? 'folder' : 'file',
            );
          },
          clickSource,
          folderUpload,
        });
      }
      setPendingFiles([]);
      setExtensionModalOpen(false);
    },
    [
      clickSource,
      currentFolderId,
      folderUpload,
      onAddError,
      onAllUploadsComplete,
      onFilePick,
      onFileUploadComplete,
      pendingFiles,
      uploadProject,
    ],
  );

  const {openErrorSnackbar} = useErrorSnackbar();
  const documentSizeExceededError = useGetDocumentUploadSizeExceededErrorText();

  const onUploadFilePick: UploadFilePickHandler = React.useCallback(
    async (filesList, options) => {
      if (filesList && filesList.length > 0) {
        const allValidFiles = [...filesList].filter((file) => !EXCLUDED_FILES.includes(file.name));

        const files = allValidFiles.filter(
          (file) => !isPdfExtension(file.name) || file.size < MAX_DOCUMENT_UPLOAD_SIZE,
        );

        if (files.length < allValidFiles.length) {
          // For multiple file uploads still show the snackbar for invalid PDF upload but proceed to upload
          // as normal for remaining valid file uploads
          // eslint-disable-next-line deprecation/deprecation
          logEvent('failed_pdf_upload');
          openErrorSnackbar(documentSizeExceededError);
        }

        const parsedFiles = files.map((file) => {
          return {
            name: file.name,
            parentDirectory: file.webkitRelativePath.split('/')[0],
            relativePath: file.webkitRelativePath,
            fileSize: file.size,
          };
        });
        const folderDepth = getFolderDepthFromPaths(parsedFiles.map((file) => file.relativePath));

        if (folderDepth + currentFolderDepth > MAX_FOLDER_DEPTH) {
          // eslint-disable-next-line deprecation/deprecation
          logEvent('shown_max_depth_modal', {
            click_source: clickSource,
            media_source: 'direct_upload',
            upload_source: folderUpload ? 'folder' : 'file',
          });
          setMaxDepthErrorModalOpen(true);
          return;
        }

        const allInvalidExtensions = files
          .filter((file) => !validExtensionsList.includes(getExtension(file?.name)))
          .map((file) => getExtension(file?.name));

        if (allInvalidExtensions.length) {
          setPendingFiles(files);
          setExtensionModalOpen(true);
          setInvalidExtensions([...new Set(allInvalidExtensions)]);
          return;
        }

        onFilePick(parsedFiles, folderUpload);

        uploadProject({
          files,
          currentFolderId,
          onFileUploadComplete: (upload: DirectUpload, fileId: string, videoVersionId: string) => {
            onFileUploadComplete?.(upload, fileId);
            options?.onFileUploadCompleteCallback?.(upload, fileId, videoVersionId);
          },
          onFileError: () => {
            onAddError();
          },
          onAllUploadsComplete: (files, folderId: string) => {
            onAllUploadsComplete(
              files,
              folderId,
              clickSource,
              'direct_upload',
              folderUpload ? 'folder' : 'file',
            );
            options?.onAllUploadsCompleteCallback?.();
          },
          clickSource,
          folderUpload,
        });
      }
    },
    [
      currentFolderDepth,
      onFilePick,
      folderUpload,
      uploadProject,
      currentFolderId,
      clickSource,
      openErrorSnackbar,
      documentSizeExceededError,
      logEvent,
      validExtensionsList,
      onFileUploadComplete,
      onAddError,
      onAllUploadsComplete,
    ],
  );

  const size = React.useMemo(() => {
    if (buttonSize) {
      return buttonSize;
    }
    if (isSmallScreenSize) {
      return 'small';
    }
    return 'standard';
  }, [buttonSize, isSmallScreenSize]);

  const onUploadStart = React.useCallback(
    async (files: ChooserFile[]) => {
      onFilePick(files, folderUpload);
    },
    [folderUpload, onFilePick],
  );

  const onClickDropbox = React.useCallback(() => {
    (async () => {
      // eslint-disable-next-line deprecation/deprecation
      logEvent('select_add_reel_media', {
        click_source: clickSource,
        media_source: 'dropbox',
        upload_source: folderUpload ? 'folder' : 'file',
      });
      chooseProjectUploads({
        currentFolderId,
        currentFolderDepth,
        onUploadStart,
        onAllUploadsComplete: (files) => {
          onAddFromDropbox(files);
          onAllUploadsComplete(
            files,
            currentFolderId,
            clickSource,
            'dropbox',
            folderUpload ? 'folder' : 'file',
          );
        },
        selectionType: folderUpload ? 'folder' : 'file',
        clickSource,
      });
    })();
  }, [
    logEvent,
    clickSource,
    chooseProjectUploads,
    currentFolderId,
    onUploadStart,
    folderUpload,
    onAddFromDropbox,
    onAllUploadsComplete,
    currentFolderDepth,
  ]);

  const onClickCreateFolder = React.useCallback(() => {
    setShowCreateFolderModal(true);
  }, [setShowCreateFolderModal]);

  const onClickUpload = React.useCallback(() => {
    // eslint-disable-next-line deprecation/deprecation
    logEvent('select_add_reel_media', {
      click_source: clickSource,
      media_source: 'direct_upload',
      upload_source: folderUpload ? 'folder' : 'file',
    });
    chooseFiles({
      uploadType: 'project',
      onFilesPick: onUploadFilePick,
      multiple: true,
      isFolder: folderUpload,
    });
  }, [chooseFiles, clickSource, logEvent, onUploadFilePick, folderUpload]);

  const onUploadActiveSequenceClick = React.useCallback(() => {
    onUploadActiveSequence({onUploadFilePick, loggingClickSource: 'add_files_menu'});
  }, [onUploadActiveSequence, onUploadFilePick]);

  const onUploadQueuedCompositionsClick = React.useCallback(() => {
    onUploadQueuedCompositions({onUploadFilePick, loggingClickSource: 'add_files_menu'});
  }, [onUploadQueuedCompositions, onUploadFilePick]);

  const onClickGDrive = React.useCallback(() => {
    // eslint-disable-next-line deprecation/deprecation
    logEvent('select_add_reel_media', {
      click_source: clickSource,
      media_source: 'gdrive',
      upload_source: 'file',
    });
    startGDriveProjectImport({
      currentFolderId,
      clickSource,
      onFilePick,
      onAllUploadsComplete,
      logEvent,
    });
  }, [
    logEvent,
    currentFolderId,
    startGDriveProjectImport,
    onFilePick,
    clickSource,
    onAllUploadsComplete,
  ]);

  const onClickOneDrive = React.useCallback(() => {
    // eslint-disable-next-line deprecation/deprecation
    logEvent('select_add_reel_media', {
      click_source: clickSource,
      media_source: 'onedrive',
      upload_source: 'file',
    });
    startOneDriveProjectImport({
      currentFolderId,
      clickSource,
      onFilePick,
      onAllUploadsComplete,
      logEvent,
    });
  }, [
    logEvent,
    currentFolderId,
    startOneDriveProjectImport,
    onFilePick,
    clickSource,
    onAllUploadsComplete,
  ]);

  const showFileQuotaInfo = useShowFileQuotaInfo();
  const exceededFileQuota = useExceededFileQuota();
  const getFileQuotaText = useGetFileQuotaText();
  const userVariant = useGetUserVariant();
  const provisionCta = useGetProvisionCta(userVariant);
  const {isMobileDevice} = useViewport();
  const isTeamLocked = useIsTeamLocked();
  const showUpsellModal = !isMobileDevice;

  const isDirectUploading = UPLOAD_IN_PROGRESS_STATES.includes(directUploadState);
  const isChooserUploading = UPLOAD_IN_PROGRESS_STATES.includes(chooserUploadState);
  const isUploading = isDirectUploading || isChooserUploading;

  const menuRef = React.useRef<WrapperRefObject>(null);
  const proToolsTooltipTriggerRef = React.useRef(null);

  const canShowProToolsTooltip = useAtomValue(canShowProToolsTooltipAtom);
  const ctaLink = `${SINGLE_PURCHASE_URL}?replay_purchase_origin=add_files_menu`;

  const menu = React.useMemo(() => {
    // Clicking anywhere on the upload project tile opens the upload menu, so we need to manually set the open status for that case
    const menuContentProps =
      clickSource === 'upload_project_tile'
        ? {placement: dropdownPlacement, open: showMenuContents}
        : {placement: dropdownPlacement};

    const onFolderCreateSucess = (folderToShow: string, level: number) => {
      setShowCreateFolderModal(false);
      if (onCreateFolderSuccessCallback) {
        onCreateFolderSuccessCallback(folderToShow, level);
      }
    };

    const inBrowsePage = clickSource === 'browse_button';
    const expandFileMenuForProTools = inBrowsePage && canShowProToolsTooltip;

    return (
      <>
        <Menu.Wrapper ref={menuRef}>
          {({getContentProps, getTriggerProps, toggleMenu}) => (
            <>
              {
                <>
                  <MainButton
                    {...getTriggerProps()}
                    $focusStyles={includeFocusStyles ? uploadFileFocusStyles : css``}
                    data-safe-to-unmask-name="media-adder-button"
                    disabled={isTeamLocked || (isUploading && loadingState === 'disabled')}
                    isLoading={isUploading && loadingState === 'spinner'}
                    onClick={(e: any) => {
                      if (
                        showUpsellModal &&
                        exceededFileQuota &&
                        (!folderUpload || (folderLevel && folderLevel === 1))
                      ) {
                        setPaywallModalOpen(true);
                      } else {
                        toggleMenu(e);
                      }
                    }}
                    size={size}
                    variant={buttonVariant}
                    withDropdownIcon={withDropdownIcon}
                    withIconStart={
                      withIconLeft ? (
                        <UIIcon
                          src={
                            isSmallScreenSize
                              ? UploadLine
                              : folderUpload
                              ? AddFolderLine
                              : AddMediaLine
                          }
                        />
                      ) : (
                        ''
                      )
                    }
                  >
                    {buttonText ?? getButtonText(intl, {isSmallScreenSize, folderUpload})}
                  </MainButton>
                </>
              }
              <Menu.Content
                {...getContentProps()}
                {...menuContentProps}
                open={getContentProps().open || expandFileMenuForProTools}
              >
                {onCreateFolderSuccessCallback &&
                folderLevel &&
                folderLevel !== 1 &&
                folderLevel < 12 ? (
                  <Menu.Segment>
                    <Menu.ActionItem
                      data-safe-to-unmask-name="media-adder-create-folder-button"
                      onClick={onClickCreateFolder}
                      withLeftAccessory={<UIIcon src={AddLine} />}
                    >
                      <FormattedMessage
                        defaultMessage="Create folder"
                        description="Button text for the create folder button."
                        id="9d/JE0"
                      />
                    </Menu.ActionItem>
                  </Menu.Segment>
                ) : null}
                {isProvisioningEnabled && showFileQuotaInfo && (
                  <Menu.Segment>
                    <Text size="small" style={{margin: '0 18px'}} variant="label">
                      {getFileQuotaText}
                      <Link
                        href={!isInWebView() ? ctaLink : '#'}
                        inverse
                        isBold
                        onClick={() => {
                          // eslint-disable-next-line deprecation/deprecation
                          logEvent('select.cta_link', {
                            link_type: 'purchase_addon',
                            cta_location: 'add_files_menu',
                          });
                          if (isInWebView()) {
                            openURLInExternalBrowser(ctaLink);
                          }
                        }}
                        style={{marginLeft: '8px'}}
                        target="_blank"
                      >
                        {provisionCta.ctaLabel}
                      </Link>
                    </Text>
                  </Menu.Segment>
                )}
                <Menu.Segment>
                  <Menu.ActionItem
                    data-safe-to-unmask-name="media-adder-dropbox-upload-button"
                    disabled={isProvisioningEnabled && exceededFileQuota}
                    onClick={onClickDropbox}
                    withLeftAccessory={<UIIcon src={DropboxLine} />}
                  >
                    <DropboxUploadText />
                  </Menu.ActionItem>
                  <Menu.ActionItem
                    data-safe-to-unmask-name="media-adder-local-drive-upload-button"
                    disabled={isProvisioningEnabled && exceededFileQuota}
                    onClick={onClickUpload}
                    ref={proToolsTooltipTriggerRef}
                    withLeftAccessory={<UIIcon src={UploadLine} />}
                  >
                    <DirectUploadText />
                  </Menu.ActionItem>
                  <ProToolsTooltip
                    showTooltip={expandFileMenuForProTools}
                    triggerRef={proToolsTooltipTriggerRef}
                  />
                  {redCarpet.features.uploadActiveSequence && (
                    <Menu.ActionItem
                      disabled={isProvisioningEnabled && exceededFileQuota}
                      onClick={onUploadActiveSequenceClick}
                      withLeftAccessory={<UIIcon src={UploadLine} />}
                    >
                      <UploadActiveSequenceText />
                    </Menu.ActionItem>
                  )}
                  {redCarpet.features.uploadActiveComposition && (
                    <Menu.ActionItem
                      disabled={isProvisioningEnabled && exceededFileQuota}
                      onClick={onUploadActiveSequenceClick}
                      withLeftAccessory={<UIIcon src={UploadLine} />}
                    >
                      <UploadActiveCompositionText />
                    </Menu.ActionItem>
                  )}
                  {redCarpet.features.uploadQueuedCompositions && (
                    <Menu.ActionItem
                      disabled={isProvisioningEnabled && exceededFileQuota}
                      onClick={onUploadQueuedCompositionsClick}
                      withLeftAccessory={<UIIcon src={UploadLine} />}
                    >
                      <UploadQueuedCompositionsText />
                    </Menu.ActionItem>
                  )}
                  {!folderUpload && (
                    <Menu.ActionItem
                      data-safe-to-unmask-name="media-adder-gdrive-upload-button"
                      disabled={isProvisioningEnabled && exceededFileQuota}
                      onClick={onClickGDrive}
                      withLeftAccessory={<GoogleDriveIcon />}
                    >
                      <FormattedMessage
                        defaultMessage="Google Drive"
                        description="Text on a menu item that opens a Google Drive file picker"
                        id="5ixv4r"
                      />
                    </Menu.ActionItem>
                  )}
                  {!folderUpload && (
                    <Menu.ActionItem
                      data-safe-to-unmask-name="media-adder-onedrive-upload-button"
                      disabled={isProvisioningEnabled && exceededFileQuota}
                      onClick={onClickOneDrive}
                      withLeftAccessory={<OneDriveIcon />}
                    >
                      <FormattedMessage
                        defaultMessage="Microsoft OneDrive"
                        description="Text on a menu item that opens a Microsoft OneDrive file picker"
                        id="pNq+ZY"
                      />
                    </Menu.ActionItem>
                  )}
                </Menu.Segment>
              </Menu.Content>
            </>
          )}
        </Menu.Wrapper>
        <PaywallModal
          clickSource="add_files_button"
          onClose={() => {
            setPaywallModalOpen(false);
          }}
          open={paywallModalOpen}
        ></PaywallModal>
        <CreateFolderModal
          isProject={folderLevel === 1}
          level={folderLevel}
          onFolderCreateSuccess={onFolderCreateSucess}
          open={showCreateFolderModal}
          parentFolderId={currentFolderId}
          requestClose={() => setShowCreateFolderModal(false)}
        />
        <MaxDepthExceededModal open={maxDepthErrorModalOpen} setOpen={setMaxDepthErrorModalOpen} />
        <InvalidExtensionModal
          invalidExtensions={invalidExtensions}
          open={extensionModalOpen}
          requestClose={onModalExtensionClose}
        ></InvalidExtensionModal>
      </>
    );
  }, [
    clickSource,
    dropdownPlacement,
    showMenuContents,
    canShowProToolsTooltip,
    paywallModalOpen,
    folderLevel,
    showCreateFolderModal,
    currentFolderId,
    maxDepthErrorModalOpen,
    invalidExtensions,
    extensionModalOpen,
    onModalExtensionClose,
    onCreateFolderSuccessCallback,
    includeFocusStyles,
    uploadFileFocusStyles,
    isTeamLocked,
    isUploading,
    loadingState,
    size,
    buttonVariant,
    withDropdownIcon,
    withIconLeft,
    isSmallScreenSize,
    folderUpload,
    buttonText,
    intl,
    onClickCreateFolder,
    isProvisioningEnabled,
    showFileQuotaInfo,
    getFileQuotaText,
    ctaLink,
    provisionCta.ctaLabel,
    exceededFileQuota,
    onClickDropbox,
    onClickUpload,
    redCarpet.features.uploadActiveSequence,
    redCarpet.features.uploadActiveComposition,
    redCarpet.features.uploadQueuedCompositions,
    onUploadActiveSequenceClick,
    onUploadQueuedCompositionsClick,
    onClickGDrive,
    onClickOneDrive,
    showUpsellModal,
    logEvent,
  ]);

  React.useEffect(() => {
    if (menuRef && menuRef.current) menuRef.current.updateContent();
  }, [showMenuContents]);

  return menu;
};
