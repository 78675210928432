import React from 'react';

import {atom, useAtom, useSetAtom} from 'jotai';
import {useIntl} from 'react-intl';
import styled from 'styled-components';

import type {DatePickerSelection} from '@dropbox/dig-components/date_picker';
import {DatePickerInput} from '@dropbox/dig-components/date_picker';
import {Modal} from '@dropbox/dig-components/modal';
import {Select} from '@dropbox/dig-components/text_fields';
import {Text, Title} from '@dropbox/dig-components/typography';
import {UIIcon} from '@dropbox/dig-icons';
import {ClockLine, DeleteLine} from '@dropbox/dig-icons/assets';

import {Button} from '~/components/button';
import {ShareModalTitle} from '~/components/share_modal/share_modal_common';
import {createTask, deleteTask, updateTask} from '~/lib/api';
import type {LoggingClient} from '~/lib/logging/logger';
import {clearTaskStateAtom, currentTaskStateAtom} from '~/state/task';

type DueDateModalContentsProps = {
  videoId: string;
  handleCloseModal: () => void;
  handleShowDueDateSnackbar?: () => void;
  logEvent: LoggingClient['logEvent'];
};

type DueDateModalProps = DueDateModalContentsProps & {
  open: boolean;
};

const ModalErrorText = styled.div`
  color: 'var(--dig-color__warning__on-surface)';
`;

const DatePickerRow = styled.div`
  margin-top: 24px;
  margin-bottom: 16px;
  display: flex;

  & > div:first-child {
    margin-right: 10px;
  }

  & > * {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
`;

const LeftFooterButtonWrapper = styled.div`
  margin-right: auto;

  & .dig-Button {
    margin-left: 0px;
  }
`;

const ClockIcon = styled(UIIcon)`
  color: var(--dig-color__text__subtle);
`;

export const AddDueDateModal = (props: DueDateModalProps) => {
  const {open, handleCloseModal, handleShowDueDateSnackbar, videoId, logEvent} = props;
  return (
    <Modal isCentered onRequestClose={handleCloseModal} open={open}>
      <AddDueDateModalContents
        handleCloseModal={handleCloseModal}
        handleShowDueDateSnackbar={handleShowDueDateSnackbar}
        logEvent={logEvent}
        videoId={videoId}
      />
    </Modal>
  );
};

export const AddDueDateModalContents = (props: DueDateModalContentsProps) => {
  const {handleCloseModal, handleShowDueDateSnackbar, videoId, logEvent} = props;

  const intl = useIntl();

  const modalTitleAdd = intl.formatMessage({
    defaultMessage: 'Add due date',
    id: 'BdpI3m',
    description: 'Title for modal to add a due date.',
  });

  const modalTitleEdit = intl.formatMessage({
    defaultMessage: 'Edit due date',
    id: 'uTjf6F',
    description: 'Title for modal to edit a due date.',
  });

  const dueDateDescriptionAdd = intl.formatMessage({
    defaultMessage:
      "When a due date is set, you'll get one email reminder from us 24 hours before the due date, so you can remind your reviewers. This due date will also be visible to reviewers when they are in Replay.",
    id: 'FYoQRR',
    description: 'Information describing what happens when you add a due date to a file.',
  });

  const dueDateDescriptionEdit = intl.formatMessage({
    defaultMessage:
      'Editing a due date will change when you get your one email reminder from us. The due date will also change for reviewers that view this file on Replay.',
    id: 'bxKgZX',
    description: 'Information describing what happens when you edit a due date on a file.',
  });

  const saveText = intl.formatMessage({
    defaultMessage: 'Save',
    id: 'zvhZDN',
    description: 'Button text to save the due date.',
  });

  const closeButtonText = intl.formatMessage({
    defaultMessage: 'Cancel',
    id: 'lbiH62',
    description: 'Button text to close the Add Due Date modal.',
  });

  const deleteButtonText = intl.formatMessage({
    defaultMessage: 'Delete due date',
    id: 'xzhMBI',
    description: 'Button text to remove a due date from a file.',
  });

  const datePickerAriaLabel = intl.formatMessage({
    defaultMessage: 'Choose a due date',
    id: 'FG+8je',
    description: 'Aria-label describing the date picker used to select the due date.',
  });

  const invalidDueDateErrorText = intl.formatMessage({
    defaultMessage: 'The due date selected must be in the future.',
    id: 'TtH7j2',
    description: 'Error message shown when an invalid due date is selected.',
  });

  const genericDueDateErrorText = intl.formatMessage({
    defaultMessage:
      'There was an issue setting the due date. Please refresh the page, and try again.',
    id: 'OCwnWW',
    description: 'Error message shown when setting the due date did not work.',
  });

  const deleteErrorText = intl.formatMessage({
    defaultMessage:
      'There was an issue removing the due date. Please refresh the page, and try again.',
    id: 'YIqrsb',
    description: 'Error message shown when deleting a task fails.',
  });

  const duePreviousText = intl.formatMessage({
    defaultMessage: 'Previous',
    id: 'GJzjsq',
    description: 'Datepicker previous text',
  });

  const dueNextText = intl.formatMessage({
    defaultMessage: 'Next',
    id: 'HI8s/z',
    description: 'Datepicker next text',
  });

  const dayLabelFormat = (date: Date) =>
    new Intl.DateTimeFormat(intl.locale, {day: '2-digit'}).format(date);

  const monthLabelFormat = (date: Date) =>
    new Intl.DateTimeFormat(intl.locale, {month: 'long'}).format(date);

  const weekdayLabelFormat = (date: Date) =>
    new Intl.DateTimeFormat(intl.locale, {weekday: 'narrow'}).format(date);

  const [taskState, setTaskState] = useAtom(currentTaskStateAtom);
  const clearTask = useSetAtom(clearTaskStateAtom);
  const isAddModal = !taskState.isLoading && !taskState.task;
  const [waitingOnSaveRequest, setWaitingOnSaveRequest] = React.useState(false);
  const [waitingOnDeleteRequest, setWaitingOnDeleteRequest] = React.useState(false);
  const defaultDate = new Date();
  // Default date if none is set is in 2 days at 5pm
  defaultDate.setDate(defaultDate.getDate() + 2);
  defaultDate.setHours(17, 0, 0, 0);

  const [dueDate, setDueDate] = React.useState<Date>(
    !taskState.isLoading && taskState.task ? taskState.task.dueDate : defaultDate,
  );
  const [dueDateHours, setDueDateHours] = React.useState(dueDate.getHours());
  const [errorText, setErrorText] = React.useState('');

  const handleSaveDueDate = async () => {
    setWaitingOnSaveRequest(true);
    setErrorText('');
    try {
      if (dueDate) {
        const newTask = await createTask(dueDate, videoId);
        setTaskState({isLoading: false, videoId: videoId, task: newTask});
        // eslint-disable-next-line deprecation/deprecation
        logEvent('add_due_date');
        handleShowDueDateSnackbar && handleShowDueDateSnackbar();
        handleCloseModal();
      } else {
        throw new Error('Due date cannot be null or undefined.');
      }
    } catch (e) {
      const tag = e.error && e.error.error && e.error.error['.tag'];
      if (tag === 'invalid_due_date') {
        setErrorText(invalidDueDateErrorText);
      } else {
        setErrorText(genericDueDateErrorText);
      }
    } finally {
      setWaitingOnSaveRequest(false);
    }
  };

  const handleDeleteDueDate = async () => {
    setWaitingOnDeleteRequest(true);
    setErrorText('');
    try {
      if (!taskState.isLoading && taskState.task?.taskId) {
        await deleteTask(taskState.task.taskId);
        clearTask(videoId);
        // eslint-disable-next-line deprecation/deprecation
        logEvent('remove_due_date');
        handleShowDueDateSnackbar && handleShowDueDateSnackbar();
        handleCloseModal();
      } else {
        throw new Error('Task ID is missing.');
      }
    } catch (e) {
      setErrorText(deleteErrorText);
    } finally {
      setWaitingOnDeleteRequest(false);
    }
  };

  const handleEditDueDate = async () => {
    if (!taskState.isLoading && taskState.task?.taskId) {
      setWaitingOnSaveRequest(true);
      setErrorText('');
      try {
        if (dueDate) {
          const newTask = await updateTask(dueDate, taskState.task.taskId, videoId);
          setTaskState({isLoading: false, videoId: videoId, task: newTask});
          // eslint-disable-next-line deprecation/deprecation
          logEvent('edit_due_date');
          handleShowDueDateSnackbar && handleShowDueDateSnackbar();
          handleCloseModal();
        } else {
          throw new Error('Due date cannot be null or undefined.');
        }
      } catch (e) {
        const tag = e.error && e.error.error && e.error.error['.tag'];
        if (tag === 'invalid_due_date') {
          setErrorText(invalidDueDateErrorText);
        } else {
          setErrorText(genericDueDateErrorText);
        }
      } finally {
        setWaitingOnSaveRequest(false);
      }
    }
  };

  const handleDateSelection = ({selectedDate}: {selectedDate: DatePickerSelection}) => {
    if (selectedDate) {
      selectedDate.setHours(dueDateHours);
      setDueDate(selectedDate);
    }
  };
  const handleDateChange = (date: DatePickerSelection) => {
    if (date) {
      date.setHours(dueDateHours);
      setDueDate(date);
    }
  };
  const handleTimeSelection = (hour: number) => {
    dueDate?.setHours(hour, 0, 0, 0);
    setDueDateHours(hour);
  };
  const isDateBlocked = (date: Date) => {
    const currentDay = new Date();
    currentDay.setHours(0, 0, 0, 0);
    return date < currentDay;
  };

  return (
    <>
      <Modal.Header hasBottomSpacing="title-standard">
        <ShareModalTitle>
          <Title size="medium">{isAddModal ? modalTitleAdd : modalTitleEdit}</Title>
        </ShareModalTitle>
      </Modal.Header>
      <Modal.Body>
        <Text>{isAddModal ? dueDateDescriptionAdd : dueDateDescriptionEdit}</Text>
        <DatePickerRow>
          <DatePickerInput
            aria-label={datePickerAriaLabel}
            dayLabelFormat={dayLabelFormat}
            format={{
              dateToString: (_) => Intl.DateTimeFormat(intl.locale, {}).format(dueDate),
              stringToDate: (dateString) => new Date(dateString),
            }}
            isDateBlocked={isDateBlocked}
            monthLabelFormat={monthLabelFormat}
            onChange={handleDateChange}
            onSelection={handleDateSelection}
            value={dueDate}
            weekdayLabelFormat={weekdayLabelFormat}
            withNextLabel={dueNextText}
            withPreviousLabel={duePreviousText}
          />
          <TimeSelector handleSelection={handleTimeSelection} initialTime={dueDate?.getHours()} />
        </DatePickerRow>
        {errorText && <ModalErrorText>{errorText}</ModalErrorText>}
      </Modal.Body>
      <Modal.Footer>
        {!isAddModal && (
          <LeftFooterButtonWrapper>
            <Button
              disabled={waitingOnSaveRequest}
              isLoading={waitingOnDeleteRequest}
              onClick={handleDeleteDueDate}
              variant="transparent"
              withIconLeft={<UIIcon role="presentation" src={DeleteLine} />}
            >
              {deleteButtonText}
            </Button>
          </LeftFooterButtonWrapper>
        )}
        <Button onClick={handleCloseModal} type="" variant="opacity">
          {closeButtonText}
        </Button>
        <Button
          disabled={!dueDate || waitingOnDeleteRequest}
          isLoading={waitingOnSaveRequest}
          onClick={isAddModal ? handleSaveDueDate : handleEditDueDate}
          type="submit"
          variant="primary"
        >
          {saveText}
        </Button>
      </Modal.Footer>
    </>
  );
};

type TimeSelectorProps = {
  handleSelection: (hour: number) => void;
  initialTime: Number;
};
const TimeSelector = (props: TimeSelectorProps) => {
  const {handleSelection, initialTime} = props;
  const date = new Date();
  const timeOptions = [...Array(24).keys()];
  const localizedTimeOptions = timeOptions.map((hour) => {
    date.setHours(hour, 0, 0, 0);
    return date.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'});
  });

  const intl = useIntl();
  const timePickerAriaLabel = intl.formatMessage({
    defaultMessage: 'Choose a time for the due date',
    id: '1OkCfC',
    description: 'Aria label describing the time selector used to pick the time for the due date.',
  });

  return (
    <Select
      aria-label={timePickerAriaLabel}
      id="time-selector"
      onChange={(value) => handleSelection(Number(value))}
      shouldShowOptionAccessory={false}
      value={initialTime.toString()}
      withLeftAccessory={<ClockIcon role="presentation" src={ClockLine} />}
    >
      {localizedTimeOptions.map((timeText, index) => (
        <Select.Option key={index} value={index.toString()}>
          {timeText}
        </Select.Option>
      ))}
    </Select>
  );
};

type DueDateModalCurrentVersionInfo = {
  videoId: string;
  projectName: string;
  projectId?: string;
  versionNumber?: number;
};
export const dueDateModalCurrentVersionInfoAtom = atom<DueDateModalCurrentVersionInfo>({
  videoId: '',
  projectName: '',
  projectId: '',
  versionNumber: undefined,
});

type removeDueDateState = {
  showDueDateVersionModalErrorText: boolean;
  dueDateDeleteRequestInProgress: boolean;
};

export const removeDueDateStateAtom = atom<removeDueDateState>({
  showDueDateVersionModalErrorText: false,
  dueDateDeleteRequestInProgress: false,
});

export const dueDateVersionModalAtom = atom(false);

type DueDateVersionModalProps = {
  isOpen: boolean;
  projectName: string;
  currentVersion: number;
  showErrorText: boolean;
  requestInProgress: boolean;
  handleCloseModal: () => void;
  handleRemoveDueDate: () => void;
};

export const DueDateVersionModal = (props: DueDateVersionModalProps) => {
  const {
    isOpen,
    projectName,
    currentVersion,
    requestInProgress,
    showErrorText,
    handleCloseModal,
    handleRemoveDueDate,
  } = props;
  const intl = useIntl();

  const titleText = intl.formatMessage({
    defaultMessage: 'Use existing deadline for new version?',
    id: 'IzJS4q',
    description: 'Title for modal requesting user to keep or remove existing due date.',
  });

  const bodyText = intl.formatMessage(
    {
      defaultMessage:
        'You had an active deadline for feedback on <b>V{previousVersion}</b> of <b>{projectName}</b>. Do you want to keep this existing deadline for your new version (V{currentVersion})?',
      id: 'sBB/jb',
      description: 'Body text for modal requesting user to keep or remove existing due date.',
    },
    {
      previousVersion: currentVersion - 1,
      currentVersion: currentVersion,
      projectName: projectName,
      b: (chunks: React.ReactNode) => <b>{chunks}</b>,
    },
  );

  const errorText = intl.formatMessage({
    defaultMessage:
      'There was an issue removing the due date. Please refresh the page, and try again.',
    id: 'GDpUib',
    description: 'Error messaage shown when deleting a task fails.',
  });

  const primaryButtonText = intl.formatMessage({
    defaultMessage: 'Yes, use existing',
    id: 'XpDhpZ',
    description: 'Text for primary action keeping the due date.',
  });

  const secondaryButtonText = intl.formatMessage({
    defaultMessage: "No, don't use",
    id: 'fu+HEb',
    description: 'Text for secondary (destructive) action removing the due date.',
  });

  return (
    <Modal
      aria-labelledby="due-date-version-modal-title"
      isCentered
      onRequestClose={handleCloseModal}
      open={isOpen}
      width="small"
      withCloseButton="Close"
    >
      <Modal.Header hasBottomSpacing="title-standard">
        <Modal.Title id="due-date-version-modal-title">{titleText}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {bodyText}
        {showErrorText && <ModalErrorText>{errorText}</ModalErrorText>}
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={requestInProgress}
          isLoading={requestInProgress}
          onClick={handleRemoveDueDate}
          variant="opacity"
        >
          {secondaryButtonText}
        </Button>
        <Button onClick={handleCloseModal} variant="primary">
          {primaryButtonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
