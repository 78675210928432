import React from 'react';

import {FormattedMessage} from 'react-intl';

import {UIIcon} from '@dropbox/dig-icons';
import {LinkLine} from '@dropbox/dig-icons/assets';

import {Button} from '~/components/button';
import {useCopyBasicLinkToClipboard} from '~/components/share_modal/copy_link_utils';
import {useViewport} from '~/components/viewport_context';
import type {LoggingClient} from '~/lib/logging/logger';
import {toBooleanAttr} from '~/lib/logging/logger_types';
import {useOnboardingChecklist} from '~/lib/onboarding_v2/use_onboarding_checklist';
import {useLinkActions} from '~/lib/use_link_actions';
import {getCurrentUserPermissions} from '~/lib/utils';
import {STEPS} from '~/state/onboarding_v2';

import type {BrowseUploadedProjectProps} from './common';

export const CopyLinkFileButton = (
  props: BrowseUploadedProjectProps & {logEvent: LoggingClient['logEvent']},
) => {
  const {ownerUid, parentFolderPermission, versionSummaries, videoId, logEvent} = props;
  const [loading, setLoading] = React.useState(false);
  const {createShareLink} = useLinkActions({
    logEvent,
    versionSummaries,
    linkItemId: videoId,
    linkItemType: 'file',
    location: 'browse_list_view',
  });
  // eslint-disable-next-line deprecation/deprecation
  const {isSmallScreenSize} = useViewport();
  const {canCreateShareLinks} = getCurrentUserPermissions(parentFolderPermission, ownerUid);

  const {copyLinkToClipboard} = useCopyBasicLinkToClipboard();
  const {handleCheckIsCurrentStepOpen, handleCompleteStep} = useOnboardingChecklist({logEvent});

  const onShareClick = async (e: React.MouseEvent<HTMLElement>) => {
    setLoading(true);
    e.preventDefault();
    e.stopPropagation();

    handleCompleteStep(STEPS.SHARE_FILE);

    // eslint-disable-next-line deprecation/deprecation
    logEvent('copy_reel_share_link', {
      access_type: 'review',
      link_item_type: 'file',
      is_onboarding_share_step: toBooleanAttr(handleCheckIsCurrentStepOpen(STEPS.SHARE_FILE)),
      replay_media_type: 'none',
      experiment_variant: 'none',
    });

    // The generated link is set as basic as possible, given we have no way to configure in this one-click action
    const shareLink = await createShareLink({
      isFolder: false,
      viewOnly: false,
      downloadsDisabled: false,
      passwordEnabled: false,
      password: '',
      loggedOutAccessDisabled: false,
      viewPreviousCommentsDisabled: false,
    });
    await copyLinkToClipboard(shareLink);
    setLoading(false);
  };

  if (!canCreateShareLinks) {
    return null;
  }

  return (
    <Button
      isLoading={loading}
      onClick={onShareClick}
      size={isSmallScreenSize ? 'small' : 'standard'}
      variant="outline"
      withIconStart={<UIIcon src={LinkLine} />}
    >
      <FormattedMessage
        defaultMessage="Copy link"
        description="Text for a menu item that lets you share the file with someone else by directly copying the share link."
        id="WEJ8UJ"
      />
    </Button>
  );
};
