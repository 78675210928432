import {defineMessages} from 'react-intl';

export const appStrings = defineMessages({
  homeTitle: {
    defaultMessage: 'Home',
    id: 'BOWDTz',
    description: 'Top-level title for page displaying files that are not shared.',
  },
  sharedTitle: {
    defaultMessage: 'Shared',
    id: 'n3Cax7',
    description: 'Top-level title for page displaying files that are "Shared".',
  },
  browseTitle: {
    defaultMessage: 'Browse',
    id: 'AW10E5',
    description: 'Title of the browse page of Replay',
  },
  adminTitle: {
    defaultMessage: 'Admin',
    id: 'm+NsN8',
    description: 'Top-level title for page displaying admin related files and info',
  },
  linksTitle: {
    defaultMessage: 'Links',
    id: 'oOiPmH',
    description: "Header label for the user's links hub",
  },
  settingsTitle: {
    defaultMessage: 'Settings',
    id: 'thuo5Y',
    description: 'Top-level title for page displaying user and team settings',
  },
  segmentationQuizTitle: {
    defaultMessage: 'Replay',
    id: 'l2ZJid',
    description: 'Top-level title for onboarding segmentation quiz page',
  },

  openInNewWindow: {
    defaultMessage: 'Open in new window',
    id: 'HtvPtD',
    description: 'label for icon indicating that the link will open in a new window',
  },
});
