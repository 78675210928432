import type {ReplayDrawing} from '~/pages/viewer_page/components/annotations/types';

import type {Int} from '../../lib/time';

export interface ReplayUser {
  id: string;
  displayName: string;
  initials: string;
  photoURL: string | null;
  isCurrentUser: boolean;
}

// Whether the thread is specific to a frame, a page or the entire content.
export enum ReplayThreadType {
  FRAME_LEVEL = 'FRAME_LEVEL',
  MEDIA_LEVEL = 'MEDIA_LEVEL',
  PAGE_LEVEL = 'PAGE_LEVEL',
}

export interface ReplayMention {
  user_id: string;
  location: {
    start: number;
    end: number;
  };
}

export type TimeRange = {
  in: number;
  out?: number;
};

export type ReplayRegion = {
  x: number;
  y: number;
};

export interface ReplayComment {
  id: string;
  author: ReplayUser;
  timestamp: Date;
  content: string;
  isDeleted: boolean;
  drawingJSON?: ReplayDrawing;
  timeRange?: TimeRange;
  threadID: string;
  isThreadResolved: boolean;
  mentions: Array<ReplayMention>;
  pageNumber?: number;
  pinPoint?: ReplayRegion;
  versionId?: string;
  resolvedAtVersion?: number;
}

export type ReplayThread = {
  id: string;
  type: ReplayThreadType;
  author: ReplayUser;
  comments: Array<ReplayComment>;
  isRead: boolean;
  isReadOnly: boolean;
  drawingJSON?: ReplayDrawing;
  timeRange?: TimeRange;
  isNew: boolean;
  isQuickReaction: boolean;
  isPinned: boolean;
  pinnedLabel?: string;
  isResolved: boolean;
  resolution?: {
    time: string;
    resolverDisplayName: string;
    resolverPhotoUrl?: string;
    resolverId: string;
    resolvedAtVersion?: number;
  };
  // Only used for UI. Actual permissions are checked server-side.
  isPrivate: boolean;

  pageNumber?: Int; // Only present on page-level threads

  versionNum?: number;
  versionId?: string;
  pinPoint?: ReplayRegion;
};

export enum ReplayThreadErrorType {
  CREATE_THREAD = 'CREATE_THREAD',
  RESOLVE_THREAD = 'RESOLVE_THREAD',
  UNRESOLVE_THREAD = 'UNRESOLVE_THREAD',
  MARK_THREAD_READ = 'MARK_THREAD_READ',
  PIN_THREAD = 'PIN_THREAD',
  UNPIN_THREAD = 'UNPIN_THREAD',
}

export enum ReplayCommentErrorType {
  CREATE_COMMENT = 'CREATE_COMMENT',
  DELETE_COMMENT = 'DELETE_COMMENT',
  EDIT_COMMENT = 'EDIT_COMMENT',
}

export interface ReplayThreadError {
  thread: ReplayThread | null;
  errorType: ReplayThreadErrorType;
  errorReason?: string;
}

export interface ReplayCommentError {
  comment: ReplayComment | null;
  errorType: ReplayCommentErrorType;
  isPrivateComment: boolean;
}

export type ReplayError = ReplayThreadError | ReplayCommentError;

export enum CommentsFilter {
  PINNED = 'pinned',
  UNRESOLVED = 'unresolved',
  RESOLVED = 'resolved',
  ALL = 'all',
}
