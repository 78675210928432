import type {DependencyList} from 'react';
import {useMemo} from 'react';

export const debounceAnimationFrame = <F extends (...args: any[]) => any>(fn: F) => {
  let timeout: number;
  const debouncedFn = (...args: Parameters<F>) => {
    cancelAnimationFrame(timeout);
    return new Promise<ReturnType<F>>((resolve) => {
      timeout = requestAnimationFrame(() => {
        const result: ReturnType<F> = fn(...args);
        resolve(result);
      });
    });
  };
  return debouncedFn;
};

export const useDebounceAnimationFrame = <F extends (...args: any[]) => any>(
  fn: F,
  deps: DependencyList,
) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => debounceAnimationFrame(fn), deps);
};
