import React from 'react';

import {useAtomValue} from 'jotai';
import {useDrag} from 'react-dnd';
import {FormattedMessage, useIntl} from 'react-intl';

import type {reel} from '@dropbox/api-v2-client';
import {Spinner} from '@dropbox/dig-components/progress_indicators';
import {Text} from '@dropbox/dig-components/typography';
import {Box, Split} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {FolderLine} from '@dropbox/dig-icons/assets';

import {TileDropArea, useMoveItemDnd} from '~/components/drop_area';
import {SkeletonRectangle} from '~/components/skeleton';
import {type Color} from '~/components/styled';
import type {BrowseUploadedFolderProps} from '~/pages/browse_page/components/common';
import {previewOptions} from '~/pages/browse_page/components/drag_and_drop_utils';
import type {ProjectOrFolderItems} from '~/pages/browse_page/types';
import {DndItemTypes} from '~/pages/browse_page/types';
import {folderSelectionStateAtom} from '~/state/browse';

import {Tile, TileFooter, TileHeader, TileLink, TileTitle} from './tile';
import {FolderTileContextMenu} from './tile_context_menu';
import {useBrowseItemsViewContext} from '../../browse_items_view_context';
import {useBrowseLogEventMaker} from '../../browse_logging_context';

interface FolderTitleProps {
  title: string;
  color?: Color;
}

const FolderTitle = ({title, color}: FolderTitleProps) => {
  return (
    <Split>
      <Box height="0" paddingRight="Micro XSmall">
        <UIIcon size="medium" src={FolderLine} />
      </Box>
      <TileTitle color={color}>{title}</TileTitle>
    </Split>
  );
};

export const FolderTile = (props: BrowseUploadedFolderProps) => {
  const {
    currentProjectAmplitudeId,
    folderId,
    isRootLevelView,
    isSharedVideoView,
    name,
    numProjects,
    parentFolderPermission,
    reelLink,
  } = props;

  const intl = useIntl();

  const makeLogEvent = useBrowseLogEventMaker();
  const logEvent = React.useMemo(
    () => makeLogEvent({projectId: currentProjectAmplitudeId, reelObjectType: 'folder'}),
    [makeLogEvent, currentProjectAmplitudeId],
  );

  const {editActionsProps} = useBrowseItemsViewContext();
  const {selected, selectable} = useAtomValue(folderSelectionStateAtom)(folderId);
  const [{canDrop, onHover}, drop] = useMoveItemDnd(props);

  const item: ProjectOrFolderItems = {folderId, folderName: name, itemType: 'FolderTile'};

  const [{isDragging}, dragRef, dragPreview] = useDrag(
    () => ({
      type: DndItemTypes.FolderTile,
      item,
      previewOptions: previewOptions,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [folderId, name],
  );

  const dragPreviewCallbackRef = React.useCallback(
    (node: HTMLDivElement | null) => {
      if (
        editActionsProps.editActionsEnabled === false ||
        editActionsProps.leftRailStatus.status !== 'closed'
      ) {
        return;
      }
      if (
        isRootLevelView ||
        (isSharedVideoView &&
          (parentFolderPermission === 'reviewer' || parentFolderPermission === 'other'))
      ) {
        return;
      }
      drop(node);
      dragRef(node);
      dragPreview(node, previewOptions);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- PR 894
    [
      dragPreview,
      dragRef,
      editActionsProps,
      isRootLevelView,
      isSharedVideoView,
      parentFolderPermission,
    ],
  );

  React.useEffect(() => {
    if (!editActionsProps.editActionsEnabled) {
      return;
    }

    const getFolderChildren = (tree: reel.FolderTreeNode[], lookupId: string): void => {
      tree.forEach((node) => {
        if (node.folder && node.folder.id === lookupId) {
          node.children
            ? editActionsProps.setChildTree(node.children)
            : editActionsProps.setChildTree(null);
          return;
        }
        if (node.children && node.children.length > 0) {
          return getFolderChildren(node.children, lookupId);
        }
      });
    };
    if (editActionsProps.folderTree) {
      getFolderChildren(editActionsProps.folderTree, folderId);
    }
  }, [isDragging, folderId, editActionsProps]);

  const dndHoverText = intl.formatMessage({
    defaultMessage: 'Move to folder',
    id: '9dcSi5',
    description: 'Text that informs user that the hovered file or folder can be moved to a folder.',
  });

  return (
    <Tile
      data-test-id="folder-tile"
      dndHoverText={dndHoverText}
      isDndDroppable={canDrop}
      isDndHovered={onHover}
      isDragging={isDragging}
      isLink
      isSelected={selected}
      ref={dragPreviewCallbackRef}
      role="gridcell"
    >
      <TileDropArea folderId={folderId} isEnabled={!isSharedVideoView}>
        <TileLink
          draggable={false}
          onClick={() =>
            // eslint-disable-next-line deprecation/deprecation
            logEvent('select_reel_object')
          }
          to={reelLink}
        >
          <TileHeader>
            {editActionsProps.editActionsEnabled && (
              <FolderTileContextMenu
                {...props}
                isSelectable={selectable}
                isSelected={selected}
                isTeamProject={false}
              />
            )}

            <FolderTitle title={name} />
          </TileHeader>

          <TileFooter>
            {numProjects !== undefined ? (
              <Text size="small" variant="label">
                <FormattedMessage
                  defaultMessage="{numProjects} {numProjects, plural, one {file} other {files}}"
                  description="Displays how many files are contained within a folder or project"
                  id="Y9Oos1"
                  values={{numProjects}}
                />
              </Text>
            ) : (
              <SkeletonRectangle $heightValue="20px" $widthValue="70px" />
            )}
          </TileFooter>
        </TileLink>
      </TileDropArea>
    </Tile>
  );
};

export const UploadingFolderTile = () => {
  const intl = useIntl();
  const label = intl.formatMessage({
    defaultMessage: 'Uploading folder...',
    description:
      'Text that displays on the browse page over a file that is still uploading as is not ready yet',
    id: '8Kuziw',
  });

  return (
    <Tile role="gridcell">
      <TileHeader $centerContents={true}>
        <Spinner aria-label={label} />
      </TileHeader>
      <TileFooter>
        <Text color="subtle" size="small" tagName="div" variant="label">
          {label}
        </Text>
      </TileFooter>
    </Tile>
  );
};
