import {useIntl} from 'react-intl';

import {Tooltip} from '@dropbox/dig-components/tooltips';
import {UIIcon} from '@dropbox/dig-icons';
import {ShareLine} from '@dropbox/dig-icons/assets';

import {IconButton} from '~/components/button';

type AddPeopleButtonProps = {
  folderId: string;
  folderName: string;
  openAddPeopleModal: (sharedFolderId: string, sharedFolderName: string) => void;
};

export const AddPeopleButton = ({
  openAddPeopleModal,
  folderId,
  folderName,
}: AddPeopleButtonProps) => {
  const intl = useIntl();
  const label = intl.formatMessage({
    defaultMessage: 'Add people',
    description: 'Button that allows a project owner or collaborator to add others to the project',
    id: 'uWMswF',
  });

  return (
    <Tooltip title={label}>
      <IconButton
        aria-label={label}
        onClick={() => openAddPeopleModal(folderId, folderName)}
        variant="outline"
      >
        <UIIcon src={ShareLine} />
      </IconButton>
    </Tooltip>
  );
};
