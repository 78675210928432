import React, {type MouseEventHandler, useEffect, useRef, useState} from 'react';

import {PAP_Select_ModalAction} from 'pap-events/replay/select_modal_action';
import {defineMessages, FormattedMessage, useIntl} from 'react-intl';
import styled, {css} from 'styled-components';

import type {ModalProps} from '@dropbox/dig-components/modal';
import {Text} from '@dropbox/dig-components/typography';
import {UIIcon} from '@dropbox/dig-icons';
import {NoFillLine, ReplaceImageLine} from '@dropbox/dig-icons/assets';

import {useErrorSnackbar} from '~/components/snackbar/error_snackbar';
import {ifProp, spacing} from '~/components/styled';
import {
  type BrandingTheme,
  type BrandingThemeColorVariant,
  type BrandingThemeVariant,
  themeVariantMap,
} from '~/lib/branding';
import {useLoggingClient} from '~/lib/use_logging_client';
import {HexColorPicker} from '~/pages/browse_page/components/color_picker/hex_color_picker';

import {customButtonStyles, OptionButton, OptionModal} from './option_modal';

type ThemeValue = BrandingThemeVariant | 'custom';

interface ThemeOptionButtonProps {
  $variant: BrandingThemeColorVariant;
}

const ThemeGrid = styled.div`
  display: grid;
  gap: ${spacing('Macro Small')};
  grid-template-columns: repeat(2, 1fr);
`;

const sizeStyles = css`
  height: ${spacing('116')};
  width: 100%;
`;

const ThemeOptionButton = styled(OptionButton)<ThemeOptionButtonProps>`
  background: ${({$variant}) => themeVariantMap[$variant]};
  ${sizeStyles};
`;

interface CustomColorButtonProps {
  $color?: string;
}

const CustomColorButton = styled(OptionButton)<CustomColorButtonProps>`
  ${sizeStyles}

  ${ifProp(
    '$color',
    () =>
      css`
        ${customButtonStyles}

        &:hover {
          background-image: linear-gradient(rgba(0, 0, 0, 0.6) 0 0);
        }
      `,
    css`
      background-image: 
        // Overlay to darken the gradient, check contrast before adjusting alpha
        linear-gradient(to top, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
        // Bottom white selector
        linear-gradient(
            to top,
            rgb(255, 255, 255),
            rgba(255, 255, 255, 0.1),
            rgba(255, 255, 255, 0)
          ),
        linear-gradient(
          // RGB Color gradient
          to right,
          rgb(255, 0, 0),
          rgb(255, 255, 0),
          rgb(0, 255, 0),
          rgb(0, 255, 255),
          rgb(0, 0, 255),
          rgb(255, 0, 255),
          rgb(255, 0, 0)
        );
    `,
  )}
`;

export interface BrandingThemeModalProps {
  open: boolean;
  theme: BrandingTheme;
  onThemeSave: (logo: BrandingTheme) => Promise<void>;
  onClose: () => void;
  onAfterClose?: ModalProps['onAfterClose'];
}

export const BrandingThemeModal = ({
  open,
  theme,
  onThemeSave,
  onClose,
}: BrandingThemeModalProps) => {
  const intl = useIntl();
  const loggingClient = useLoggingClient();
  const {openErrorSnackbar} = useErrorSnackbar();
  const colorPickerButtonRef = useRef(null);
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [selected, setSelected] = React.useState<ThemeValue>(
    theme.type === 'custom' ? 'custom' : theme.variant,
  );
  const defaultColor = 'primary_color' in theme ? theme.primary_color : undefined;
  const [primaryColor, setPrimaryColor] = React.useState<string | undefined>(defaultColor);

  useEffect(() => {
    setPrimaryColor('primary_color' in theme ? theme.primary_color : undefined);
    setSelected(theme.type === 'custom' ? 'custom' : theme.variant);
  }, [theme]);

  const handleClose = () => {
    loggingClient.logPap(PAP_Select_ModalAction({modal: 'theme', modalAction: 'cancel'}));
    setIsColorPickerOpen(false);
    onClose();
  };

  const handleSave = async () => {
    loggingClient.logPap(PAP_Select_ModalAction({modal: 'theme', modalAction: 'save'}));
    setIsColorPickerOpen(false);
    setIsSubmitting(true);
    let update: BrandingTheme = {
      ...theme,
      type: 'default',
      variant: selected as BrandingThemeVariant,
    };

    if (selected === 'custom' && primaryColor) {
      update = {
        ...theme,
        type: 'custom',
        primary_color: primaryColor,
      };
    }

    try {
      await onThemeSave(update);
    } catch (error) {
      console.error(error);
      openErrorSnackbar(
        intl.formatMessage({
          defaultMessage: 'Failed to update your background.',
          id: 'TtBNQ5',
          description: 'Error message when failing to update a project background',
        }),
      );
    }
    setIsSubmitting(false);
  };

  const handleOptionSelected =
    (value: ThemeValue): MouseEventHandler<HTMLButtonElement> =>
    () => {
      setIsColorPickerOpen(false);
      setSelected(value);
    };

  const handleColorPickerClick = () => {
    setSelected('custom');
    setIsColorPickerOpen(!isColorPickerOpen);
  };

  const handleColorPickerClose = () => {
    setIsColorPickerOpen(false);
  };

  const handleColorPickerChange = (color: string) => {
    setPrimaryColor(color);
  };

  const buttonProps = (value: ThemeValue) => ({
    role: 'option',
    disabled: isSubmitting,
    'aria-selected': selected === value,
    onClick: handleOptionSelected(value),
  });

  return (
    <OptionModal
      id="branding-logo-modal-title"
      isSubmitting={isSubmitting}
      onRequestClose={handleClose}
      onSave={handleSave}
      open={open}
      title={intl.formatMessage(modalStrings.modalHeader)}
    >
      <ThemeGrid>
        <ThemeOptionButton
          $variant="lime"
          aria-label={intl.formatMessage(modalStrings.limeButtonLabel)}
          {...buttonProps('lime')}
        />
        <ThemeOptionButton
          $variant="tangerine"
          aria-label={intl.formatMessage(modalStrings.tangerineButtonLabel)}
          {...buttonProps('tangerine')}
        />
        <ThemeOptionButton
          $variant="rust"
          aria-label={intl.formatMessage(modalStrings.rustButtonLabel)}
          {...buttonProps('rust')}
        />
        <ThemeOptionButton
          $variant="zen"
          aria-label={intl.formatMessage(modalStrings.zenButtonLabel)}
          {...buttonProps('zen')}
        />
        <OptionButton aria-labelledby="upload-modal-none-title" {...buttonProps('none')}>
          <UIIcon size="standard" src={NoFillLine} />
          <Text id="upload-modal-none-title">
            <FormattedMessage {...modalStrings.noBackgroundItem} />
          </Text>
        </OptionButton>

        <CustomColorButton
          {...buttonProps('custom')}
          $color={primaryColor}
          aria-labelledby="upload-modal-custom-title"
          onClick={handleColorPickerClick}
          ref={colorPickerButtonRef}
          style={{backgroundColor: primaryColor}}
        >
          <UIIcon size="standard" src={ReplaceImageLine} />
          <Text id="upload-modal-custom-title">
            <FormattedMessage {...modalStrings.changeColorItem} />
          </Text>
        </CustomColorButton>
        <HexColorPicker
          anchorRef={colorPickerButtonRef}
          color={defaultColor}
          isPortaled={true}
          onChangeColor={handleColorPickerChange}
          onClose={handleColorPickerClose}
          onSave={handleColorPickerClose}
          open={isColorPickerOpen}
        />
      </ThemeGrid>
    </OptionModal>
  );
};

const modalStrings = defineMessages({
  modalHeader: {
    defaultMessage: 'Choose a banner for this project',
    description: 'Copy for modal header to change banner ',
    id: 'Slzvul',
  },
  noBackgroundItem: {
    defaultMessage: 'None',
    description: 'Button copy for option to have no project banner background',
    id: 'DZ+ncd',
  },
  changeColorItem: {
    defaultMessage: 'New color',
    description: 'Button copy for option to change the banner color',
    id: '6mfSu0',
  },
  limeButtonLabel: {
    defaultMessage: 'Lime theme',
    description: 'Button label for selecting a theme named lime',
    id: 'tslcGx',
  },
  tangerineButtonLabel: {
    defaultMessage: 'Tangerine theme',
    description: 'Button label for selecting a theme named tangerine',
    id: 'q7wIvz',
  },
  rustButtonLabel: {
    defaultMessage: 'Rust theme',
    description: 'Button label for selecting a theme named rust',
    id: 'Rp28tZ',
  },
  zenButtonLabel: {
    defaultMessage: 'Zen theme',
    description: 'Button label for selecting a theme named zen',
    id: 'Lw2oQ3',
  },
});
