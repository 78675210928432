import type {ReplayMediaType as PAPMediaType} from 'pap-events/enums/replay_media_type';

import {ExperimentVariantType, ReplayMediaType} from './logger_types';

export const stringToPAPReplayMediaType = (str: string | null): PAPMediaType | undefined => {
  switch (str) {
    case 'video':
    case 'audio':
    case 'image':
    case 'pdf':
      return str;
    default:
      return undefined;
  }
};

export function narrowToReplayMediaType(str: string | null): (typeof ReplayMediaType)[number] {
  return ReplayMediaType.find((x) => str === x) ?? 'none';
}

export function narrowToExperimentVariantType(
  str: string | null,
): (typeof ExperimentVariantType)[number] {
  return ExperimentVariantType.find((x) => str === x) ?? 'none';
}
