import clone from 'lodash/clone';

// Wrapper function that provides simple retry functionality.
// Note that this expects any failures to throw in order to retry.
type AnyFunction = (...args: any[]) => any;
type Parameters<AnyFunction> = AnyFunction extends (...args: infer AnyFunction) => any
  ? AnyFunction
  : never;

const delay = (ms: number) => new Promise((r) => setTimeout(r, ms));

export function withRetries<T extends AnyFunction>(
  fn: T,
  maxRetries: number,
  // As a workaround for invoking functions that modify their arguments
  cloneArgsBeforeCalling = false,
) {
  return async (...args: Parameters<T>): Promise<ReturnType<T>> => {
    let toThrow = new Error();

    for (let currentTry = 0; currentTry < maxRetries; currentTry += 1) {
      if (currentTry > 1) {
        await delay(100 * 2 ** (currentTry - 2));
      }
      const applyArgs = cloneArgsBeforeCalling ? args.map(clone) : args;
      try {
        const result = await fn.apply(null, applyArgs);
        return result;
      } catch (thrown) {
        toThrow = thrown;
      }
    }

    console.error('Exceeded maximum retries');
    throw toThrow;
  };
}

export default withRetries;
