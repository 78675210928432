import {useCallback, useMemo, useState} from 'react';

import {useOnboardingContext} from '~/components/onboarding_v2/onboarding_provider';
import {useReelAppGlobalState} from '~/context';
import {getExtension, getMediaType} from '~/lib/helpers';
import {
  type AddMediaClickSourceType,
  mapMediaTypeToLoggingType,
  type MediaSourceType,
  toBooleanAttr,
  type UploadSourceType,
} from '~/lib/logging/logger_types';
import {useOnboardingChecklist} from '~/lib/onboarding_v2/use_onboarding_checklist';
import type {
  BasicFileInfo,
  ChooserFile,
  GDriveFile,
  OneDriveFile,
  StreamableFile,
} from '~/lib/uploads/types';
import {UserSurvey} from '~/lib/user_survey';
import {generateRandomId} from '~/lib/utils';
import {useBrowseLogEventMaker} from '~/pages/browse_page/browse_logging_context';
import type {
  UploadingFolder,
  UploadingProject,
  UploadingProjectId,
} from '~/pages/browse_page/components/common';
import {useFoldersSharedState} from '~/pages/browse_page/folders_context';
import {STEPS} from '~/state/onboarding_v2';

type EditActionsArgs = {
  folderLevel: number;
  refreshFolders: (folderId?: string) => void;
};

export const useEditActions = ({refreshFolders, folderLevel}: EditActionsArgs) => {
  const makeLogEvent = useBrowseLogEventMaker();
  const sessionContext = useReelAppGlobalState();
  const {
    currentFolderId,
    leftRailStatus,
    folderTree,
    setFolderTree,
    reelRootFolderId,
    setChildTree,
    setLeftRailStatus,
    updateFolderTree,
    updateSuggestedItems,
    suggestedItems,
    currentProjectId,
  } = useFoldersSharedState();

  const logEvent = useMemo(
    () => makeLogEvent({projectId: currentProjectId}),
    [currentProjectId, makeLogEvent],
  );

  const {onboardingActions, updateOnboardingActions} = useOnboardingContext();
  const {handleCompleteStep, handleCheckIsCurrentStepOpen} = useOnboardingChecklist({
    logEvent,
  });

  const handleAddFileFromDropbox = useCallback(
    async (files: ChooserFile[], clickSource: AddMediaClickSourceType, level: number) => {
      files.forEach(({name}) => {
        // eslint-disable-next-line deprecation/deprecation
        logEvent('create_reel_media', {
          click_source: clickSource,
          folder_level: level,
          media_source: 'dropbox',
          media_type: mapMediaTypeToLoggingType(getMediaType(getExtension(name))),
          is_multiple: files.length > 1 ? 'true' : 'false',
          is_onboarding_upload_step: toBooleanAttr(handleCheckIsCurrentStepOpen(STEPS.UPLOAD_FILE)),
        });
        UserSurvey.trackEvent('create_replay_video', 'replay');
      });

      handleCompleteStep(STEPS.UPLOAD_FILE);
      await refreshFolders(currentFolderId);
    },
    [logEvent, refreshFolders, currentFolderId, handleCheckIsCurrentStepOpen, handleCompleteStep],
  );

  const handleAddFileFromUpload = useCallback(
    async (
      clickSource: AddMediaClickSourceType,
      level: number,
      projectId: string,
      videoId: string,
      name: string,
    ) => {
      // eslint-disable-next-line deprecation/deprecation
      logEvent('create_reel_media', {
        click_source: clickSource,
        folder_level: level,
        media_source: 'direct_upload',
        media_type: mapMediaTypeToLoggingType(getMediaType(getExtension(name))),
        is_multiple: 'false',
        is_onboarding_upload_step: toBooleanAttr(handleCheckIsCurrentStepOpen(STEPS.UPLOAD_FILE)),
      });
      UserSurvey.trackEvent('create_replay_video', 'replay');
      handleCompleteStep(STEPS.UPLOAD_FILE);
    },
    [logEvent, handleCheckIsCurrentStepOpen, handleCompleteStep],
  );

  const onAddVideoError = useCallback(() => {
    refreshFolders(currentFolderId);
  }, [refreshFolders, currentFolderId]);

  const [uploadingProjects, setUploadingProjects] = useState<Map<string, UploadingProject[]>>(
    new Map(),
  );

  const [uploadingFolders, setUploadingFolders] = useState<Map<string, UploadingFolder[]>>(
    new Map(),
  );

  const onFilePick = useCallback(
    (files: BasicFileInfo[], folderUpload = false) => {
      const fileNames = files.map((file) => {
        return {
          inFolder: !!file.folderUpload,
          name: file.name.substring(0, file.name.lastIndexOf('.')) || file.name,
        };
      });
      const folderSet: Set<string> = new Set();
      for (const file of files) {
        if (!file.parentDirectory || file.parentDirectory === file.name) continue;
        folderSet.add(file.parentDirectory);
      }
      const uploadingProjects = fileNames.map((file) => ({
        isUploading: true as true,
        uploadId: generateRandomId() as UploadingProjectId,
        project: {name: file.name},
        folderUpload: folderUpload || file.inFolder,
      }));
      const uploadingFolders = [...folderSet.values()].map((folderName) => ({
        isUploading: true as true,
        name: folderName,
        folderUpload,
      }));
      setUploadingProjects((previous) => {
        const next = new Map(previous);
        next.set(currentFolderId, uploadingProjects);
        return next;
      });
      if (folderSet.size) {
        setUploadingFolders((previous) => {
          const next = new Map(previous);
          next.set(currentFolderId, uploadingFolders);
          return next;
        });
      }
    },
    [currentFolderId],
  );

  /** Called when all uploads are complete from either Dropbox or direct uploader */
  const handleAllUploadsComplete = useCallback(
    async (
      files: (File | StreamableFile)[] | ChooserFile[] | GDriveFile[] | OneDriveFile[],
      folderId: string,
      clickSource: AddMediaClickSourceType,
      mediaSource: MediaSourceType,
      uploadSource: UploadSourceType,
    ) => {
      const mediaCount = files.length;

      if (mediaCount > 1) {
        // eslint-disable-next-line deprecation/deprecation
        logEvent('create_multiple_reel_media', {
          click_source: clickSource,
          media_source: mediaSource,
          folder_level: folderLevel,
          media_count: mediaCount,
          upload_source: uploadSource,
        });
      }

      // Await here so that the pending uploading tile doesn't disappear
      // before the response comes back
      await refreshFolders(currentFolderId);
      setUploadingProjects((previous) => {
        const next = new Map(previous);
        next.set(folderId, []);
        return next;
      });
      setUploadingFolders((previous) => {
        const next = new Map(previous);
        next.set(folderId, []);
        return next;
      });
      refreshFolders(currentFolderId);
      sessionContext.status === 'logged in' && sessionContext.refreshProvisions();

      if (onboardingActions && !onboardingActions?.first_video_added) {
        updateOnboardingActions({first_video_added: true});
      }
    },
    [
      refreshFolders,
      currentFolderId,
      sessionContext,
      onboardingActions,
      logEvent,
      folderLevel,
      updateOnboardingActions,
    ],
  );

  const [addPeopleModalIsOpen, setAddPeopleModalIsOpen] = useState<boolean>(false);
  const [selectedFolderId, setSelectedFolderId] = useState<string>('');
  const [selectedFolderName, setSelectedFolderName] = useState<string>('');
  const openAddPeopleModal = useCallback((folderId: string, folderName: string) => {
    setAddPeopleModalIsOpen(true);
    setModifyPeopleModalIsOpen(false);
    setSelectedFolderId(folderId);
    setSelectedFolderName(folderName);
  }, []);

  const [modifyPeopleModalIsOpen, setModifyPeopleModalIsOpen] = useState<boolean>(false);
  const openManagePeopleModal = useCallback((folderId: string, folderName: string) => {
    setSelectedFolderId(folderId);
    setSelectedFolderName(folderName);
    setModifyPeopleModalIsOpen(true);
    setAddPeopleModalIsOpen(false);
  }, []);

  const closeModifyPeopleModal = useCallback(() => {
    setModifyPeopleModalIsOpen(false);
  }, []);

  const closeAddPeopleModal = useCallback(() => {
    setAddPeopleModalIsOpen(false);
  }, []);

  return useMemo(
    () => ({
      editActionsEnabled: true,
      folderTree,
      handleAddFileFromDropbox,
      handleAddFileFromUpload,
      handleAllUploadsComplete,
      leftRailStatus,
      onAddVideoError,
      onFilePick,
      reelRootFolderId,
      setChildTree,
      setFolderTree,
      setLeftRailStatus,
      suggestedItems,
      updateFolderTree,
      updateSuggestedItems,
      uploadingProjects,
      uploadingFolders,
      selectedFolderId,
      selectedFolderName,
      modifyPeopleModalIsOpen,
      closeModifyPeopleModal,
      closeAddPeopleModal,
      openAddPeopleModal,
      addPeopleModalIsOpen,
      openManagePeopleModal,
    }),
    [
      folderTree,
      handleAddFileFromDropbox,
      handleAddFileFromUpload,
      handleAllUploadsComplete,
      leftRailStatus,
      onAddVideoError,
      onFilePick,
      openAddPeopleModal,
      openManagePeopleModal,
      reelRootFolderId,
      setChildTree,
      setFolderTree,
      setLeftRailStatus,
      suggestedItems,
      updateFolderTree,
      updateSuggestedItems,
      uploadingProjects,
      uploadingFolders,
      addPeopleModalIsOpen,
      selectedFolderId,
      selectedFolderName,
      modifyPeopleModalIsOpen,
      closeModifyPeopleModal,
      closeAddPeopleModal,
    ],
  );
};
