import {useAtomValue} from 'jotai';

import {Modal} from '@dropbox/dig-components/modal';
import {Truncate} from '@dropbox/dig-components/truncate';
import {Title} from '@dropbox/dig-components/typography';

import {ShareModalTitle} from '~/components/share_modal/share_modal_common';
import {ShareModalHomeTabs} from '~/components/share_modal/share_modal_home_tabs';
import {useShareModalStrings} from '~/components/share_modal/share_modal_text';
import {getShareItemAtom, watermarkLinksForItemAtom} from '~/components/share_modal/share_state';
import type {TeamContactInfo} from '~/lib/api';
import {convertReelMediaTypeToMediaType, MediaType} from '~/lib/api';

export const ShareModalHome = (props: {
  canCreateLinks: boolean;
  onRequestClose: () => void;
  shareRecipients: TeamContactInfo[];
  setShareRecipients: (recipients: TeamContactInfo[]) => void;
}) => {
  const {canCreateLinks, onRequestClose, shareRecipients, setShareRecipients} = props;
  const watermarkLinks = useAtomValue(watermarkLinksForItemAtom);

  const shareItem = useAtomValue(getShareItemAtom);
  const text = useShareModalStrings(shareItem?.name ?? '');

  const isAnyVersionAVideo =
    shareItem?.type === 'file' &&
    shareItem.versionSummaries.some(
      (version) =>
        version.media_type &&
        convertReelMediaTypeToMediaType(version.media_type) === MediaType.Video,
    );
  const areAllVersionsVideos =
    shareItem?.type === 'file' &&
    shareItem.versionSummaries.every(
      (version) =>
        version.media_type &&
        convertReelMediaTypeToMediaType(version.media_type) === MediaType.Video,
    );
  const maxVersion =
    shareItem?.type === 'file' &&
    !!shareItem.versionSummaries.length &&
    shareItem.versionSummaries.reduce((a, b) => (a.version_num > b.version_num ? a : b));
  const isLatestVersionVideo =
    !!maxVersion &&
    !!maxVersion.media_type &&
    convertReelMediaTypeToMediaType(maxVersion.media_type) === MediaType.Video;

  const showWatermarkTab = isLatestVersionVideo || (isAnyVersionAVideo && !!watermarkLinks.length);

  return (
    <>
      <Modal.Header hasBottomSpacing="title-standard">
        <ShareModalTitle>
          <Title size="medium">
            <Truncate location={0.5}>{text.shareModalTitle}</Truncate>
          </Title>
        </ShareModalTitle>
      </Modal.Header>
      <ShareModalHomeTabs
        areAllVersionsVideos={areAllVersionsVideos}
        canCreateLinks={canCreateLinks}
        onRequestClose={onRequestClose}
        setShareRecipients={setShareRecipients}
        shareRecipients={shareRecipients}
        showWatermarkTab={showWatermarkTab}
      />
    </>
  );
};
