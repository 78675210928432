import React from 'react';

import {useLocation} from 'react-router';

import {APP_HAS_INITIALIZED_MEASURE, LOCATION_CHANGE_MARK} from '../lib/performance_measures';

export const MetricsListener = () => {
  const location = useLocation();
  const isFirstRender = React.useRef(true);

  React.useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      performance.measure(APP_HAS_INITIALIZED_MEASURE);
    }

    performance.mark(LOCATION_CHANGE_MARK);
  }, [location]);

  return null;
};
