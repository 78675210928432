import React from 'react';

import type {LocaleData} from 'javascript-time-ago';

import type {Dropbox, DropboxAuth, users} from '@dropbox/api-v2-client';

import type {Locale} from '~/lib/i18n';

import {getDrawingColorForUser} from './lib/colors';
import {reportBadContextUseError} from './lib/error_reporting';
import type {
  FullProvisionsType,
  PREFERENCE_SETTING_METADATA_FIELD,
  PreferenceSettingMetadataType,
  USER_METADATA_FIELD,
  UserMetadataType,
} from './lib/provisions';

export type GuestUserInfo = {
  firstName: string | null;
  lastName: string | null;
  email: string | null;
};

export const hasGuestUserInfo = (info: GuestUserInfo | null): boolean => {
  return Boolean(info && (info.firstName || info.lastName));
};

export const getGuestUserName = (info: GuestUserInfo | null): string => {
  if (!info) {
    return '';
  }
  return `${info.firstName ?? ''} ${info.lastName ?? ''}`.trim();
};

export const getUserDisplayName = (sessionContext: SessionContextValue): string | null => {
  if (sessionContext.status === 'logged in') {
    return sessionContext.currentAccount.name.display_name;
  } else if (sessionContext.hasGuestUserInfo()) {
    return getGuestUserName(sessionContext.guestUserInfo);
  }
  return null;
};

export const getUserAmplitudeSafeTeamId = (sessionContext: SessionContextValue): string => {
  if (sessionContext.status === 'logged in') {
    return sessionContext.currentAccount.amplitude_safe_team_id;
  }
  return '';
};

export const getCurrentUserDrawingColor = (sessionContext: SessionContextValue): string => {
  let name: string;
  if (sessionContext.status === 'logged in') {
    name = sessionContext.currentAccount.name.display_name;
  } else {
    name = getGuestUserName(sessionContext.guestUserInfo);
  }

  return getDrawingColorForUser(name);
};

export const getCurrentUserAccountId = (sessionContext: SessionContextValue): string => {
  let currentUserAccountId: string;
  if (sessionContext.status === 'logged in') {
    currentUserAccountId = sessionContext.currentAccount.account_id;
  } else {
    currentUserAccountId = 'unknown';
  }
  return currentUserAccountId;
};

export const getCurrentUserIsDropboxer = (sessionContext: SessionContextValue): boolean => {
  return sessionContext.status === 'logged in' && sessionContext.currentAccount.is_dropboxer;
};

// Thanks for nothing, API client...
export type CurrentAccount = users.BasicAccount & {
  id: number;
  photo_url?: string;
  is_dropboxer: boolean;
  amplitude_safe_team_id: string;
  amplitude_safe_user_id: string;
  is_csm_team: boolean;
  active_team_size?: number;
  sku_name?: string;
  locale?: string;
  user_pid?: string;
  user_pid_eci?: string;
  is_team_member?: boolean;
  is_team_admin?: boolean;
  has_onboarding_v2?: boolean;
  sku_family?: string;
  is_self_serve_user?: boolean;
  is_managed_user?: boolean;
  is_standalone_user?: boolean;
  is_team_locked: boolean;
  team_last_suspended?: Date;
};

export type LoggedOutContext = {
  dropboxAuth: DropboxAuth;
  dropboxClient: Dropbox;
  guestUserInfo: GuestUserInfo | null;
  hasGuestUserInfo: () => boolean;
  isAnonymousUser: boolean;
  isCookieSnackbarOpen: boolean;
  locale: Locale;
  onLoginComplete: (uid: number, accountId: string, refreshToken: string) => Promise<void>;
  setIsCookieSnackbarOpen: (v: boolean) => void;
  setGuestUserInfo: (a: any) => void;
  status: 'logged out';
  timeAgoLocale: LocaleData;
  uiStrings: Record<string, string>;
};

export type LoggedInContext = {
  currentAccount: CurrentAccount;
  currentRole: 'unpaired' | 'work' | 'personal';
  switchRole: (newRole: 'work' | 'personal') => Promise<void>;
  dropboxAuth: DropboxAuth;
  dropboxClient: Dropbox;
  hasGuestUserInfo: () => boolean;
  isAnonymousUser: boolean;
  isCookieSnackbarOpen: boolean;
  locale: Locale;
  logout: () => Promise<void>;
  setIsCookieSnackbarOpen: (v: boolean) => void;
  status: 'logged in';
  uiStrings: Record<string, string>;
  provisions: FullProvisionsType;
  metadata: UserMetadataType;
  preferenceSettingMetadata: PreferenceSettingMetadataType;
  setMetadata: (key: USER_METADATA_FIELD, value: boolean | number | string) => void;
  setPreferenceSettingMetadata: (
    key: PREFERENCE_SETTING_METADATA_FIELD,
    value: string | boolean,
  ) => void;
  timeAgoLocale: LocaleData;
  refreshProvisions: () => void;
};

export type SessionContextValue = LoggedOutContext | LoggedInContext;

export type InitialSessionState = LoggedOutContext | LoggedInContext;

export type InitializationState =
  | InitialSessionState
  | {status: 'loading'}
  | {status: 'error'; error: Error};

export const SessionContext = React.createContext<SessionContextValue | null>(null);

export const useReelAppGlobalState = (): SessionContextValue => {
  const sessionContext = React.useContext(SessionContext);

  if (sessionContext === null) {
    const error = new Error('useReelAppGlobalState must be used inside a SessionContext provider');
    reportBadContextUseError(error);
    throw error;
  }
  return sessionContext;
};
