import React, {type ReactNode} from 'react';

import {useAtomValue, useSetAtom} from 'jotai';
import {useDrag} from 'react-dnd';
import {FormattedMessage, useIntl} from 'react-intl';
import styled, {css} from 'styled-components';

import {Spinner} from '@dropbox/dig-components/progress_indicators';
import {Tooltip} from '@dropbox/dig-components/tooltips';
import {Text} from '@dropbox/dig-components/typography';
import {Box, Split} from '@dropbox/dig-foundations';
import {PictogramIcon} from '@dropbox/dig-icons';
import {PlayCircleLine} from '@dropbox/dig-icons/assets';

import {NEW_BADGE_DISMISSAL_DELAY} from '~/components/common';
import type {ViewOnlyContextMenuProps} from '~/components/context_menu';
import {VideoContextMenu, ViewOnlyContextMenu} from '~/components/context_menu';
import {TileDropArea} from '~/components/drop_area';
import {SkeletonRectangle} from '~/components/skeleton';
import {getStatusFromId} from '~/components/status/status';
import {StatusDropdown} from '~/components/status/status_dropdown';
import {breakpointSmall, color, ifProp, radius, spacing} from '~/components/styled';
import {useReelAppGlobalState} from '~/context';
import {logHoverForVideo, MediaType} from '~/lib/api';
import {isAVType} from '~/lib/helpers';
import type {LoggingClient} from '~/lib/logging/logger';
import type {MediaSourceType} from '~/lib/logging/logger_types';
import {mapMediaTypeToLoggingType} from '~/lib/logging/logger_types';
import {useMatchesTutorialsRoute} from '~/lib/onboarding_v2/use_matches_tutorials_route';
import {getTimeSinceForDisplay} from '~/lib/time';
import {
  browseFiltersAtom,
  noItemsSelectedAtom,
  projectSelectionStateAtom,
  selectRangeAtom,
} from '~/state/browse';
import {BulkActionStatus, bulkActionStatusAtom} from '~/state/bulk_actions';
import {useKeyboard} from '~/state/keyboard';
import {isInAdobeExtension} from '~/use_extensions';

import {NewBadge} from './new_badge';
import {MediaPoster} from './poster';
import {Tile, TileContent, TileContentTitle, TileLink, TilePoster, TileRoot} from './tile';
import {ContextMenuWrapper} from './tile_context_menu';
import {useBrowseItemsViewContext} from '../../browse_items_view_context';
import {useBrowseLogEventMaker} from '../../browse_logging_context';
import {projectShouldRenderBasedOnFilters} from '../../filter_utils';
import {DndItemTypes} from '../../types';
import type {ProjectOrFolderItems} from '../../types';
import {CommentsLabel, UploadingVersionLabel, VersionLabel, ViewerCountLabel} from '../common';
import type {BrowseUploadedProjectProps, BrowseUploadingProjectProps} from '../common';
import {FileLimitBadge} from '../file_limit_badge';
import {SelectCheckbox} from '../select_checkbox';

const LabelBadge = styled.div`
  z-index: 1;
  display: flex;
  align-items: center;
  position: absolute;
  padding: ${spacing('Micro XSmall')};

  background: ${color('Background Base')};
  color: ${color('Text Base')};
  border-radius: ${radius('Small')};
`;

const StatusWrapper = styled(LabelBadge)`
  z-index: 2;
  bottom: ${spacing('Micro Medium')};
  left: ${spacing('Micro Medium')};
  padding: 0;
  display: none;

  ${breakpointSmall(css`
    display: flex;
  `)}
`;

interface ContentLabelProps {
  children: ReactNode;
  $isCheckboxEnabled?: boolean;
}

const ContentLabelRoot = styled(LabelBadge)<ContentLabelProps>`
  top: ${spacing('Micro Medium')};
  left: ${spacing('Micro Medium')};

  display: flex;

  ${ifProp(
    '$isCheckboxEnabled',
    css`
      ${TileRoot}[aria-selected='true'] &,
      ${TileRoot}:hover & {
        display: none;
      }

      @media (hover: none) {
        display: none;
      }
    `,
  )}
`;

const ContentLabel = ({children, $isCheckboxEnabled}: ContentLabelProps) => {
  return (
    <ContentLabelRoot $isCheckboxEnabled={$isCheckboxEnabled}>
      <Text size="small" variant="label">
        {children}
      </Text>
    </ContentLabelRoot>
  );
};

const TopLeftCheckboxWrapper = styled(ContentLabelRoot)`
  background: ${color('Utility Overlay')};
  border-radius: ${radius('Medium')};
  padding: 0;

  display: none;

  @media (hover: none) {
    display: block;
  }

  ${ifProp(
    '$isCheckboxEnabled',
    css`
      ${TileRoot}[aria-selected='true'] &,
        ${TileRoot}:hover & {
        display: block;
      }
    `,
  )}
`;

const ExtraActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${spacing('Micro Medium')};
`;

const Centered = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;
`;

interface TileTopSectionProps {
  mediaType: MediaType;
  isSelected?: boolean;
  isTutorial: boolean;
  status?: BrowseUploadedProjectProps['status'];
  onVersionStatusSelected?: BrowseUploadedProjectProps['onVersionStatusSelected'];
  isUpdatingStatus?: BrowseUploadedProjectProps['isUpdatingStatus'];
  isCreatingVersion?: BrowseUploadedProjectProps['isCreatingVersion'];
  projectId?: string;
  isCheckboxEnabled?: boolean;
  src?: string;
  durationDisplayString: string;
  contextMenu: ReactNode;
}
export const TileTopSection = ({
  mediaType,
  isSelected = false,
  onVersionStatusSelected,
  isUpdatingStatus = false,
  isCreatingVersion,
  isCheckboxEnabled = false,
  status,
  projectId,
  src,
  durationDisplayString,
  contextMenu,
  isTutorial,
}: TileTopSectionProps) => {
  const {hasGuestUserInfo, isAnonymousUser} = useReelAppGlobalState();
  const isGuestUser = hasGuestUserInfo() || isAnonymousUser;
  const setProjectSelectionState = useSetAtom(projectSelectionStateAtom);
  const selectRange = useSetAtom(selectRangeAtom);
  const keyboard = useKeyboard();

  // Prevent click from navigating to media
  const handleCheckboxClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const handleSelectProject = (isSelected: boolean) => {
    if (!projectId) return;

    if (keyboard.Shift) {
      selectRange(projectId);
    } else {
      setProjectSelectionState({projectId, selected: isSelected});
    }
  };

  return (
    <div>
      <TopLeftCheckboxWrapper $isCheckboxEnabled={isCheckboxEnabled}>
        <SelectCheckbox
          isChecked={isSelected}
          onChange={({target: {checked}}) => handleSelectProject(checked)}
          onClick={handleCheckboxClick}
        />
      </TopLeftCheckboxWrapper>
      {isAVType(mediaType) && durationDisplayString && (
        <ContentLabel $isCheckboxEnabled={isCheckboxEnabled}>{durationDisplayString}</ContentLabel>
      )}
      {contextMenu}
      {status !== undefined &&
      onVersionStatusSelected &&
      !isTutorial &&
      !isCreatingVersion &&
      !isGuestUser ? (
        <StatusWrapper onClick={(e) => e.preventDefault()}>
          <StatusDropdown
            buttonSize="small"
            buttonType="outline"
            disabled={isUpdatingStatus}
            onChange={onVersionStatusSelected}
            versionStatus={getStatusFromId(status)}
          />
        </StatusWrapper>
      ) : null}
      <MediaPoster mediaType={mediaType} src={src} />
    </div>
  );
};

interface TileTopSectionWithMenuProps extends BrowseUploadedProjectProps {
  isCreatingVersion: boolean;
  isSelectable: boolean;
  isSelected: boolean;
  isTutorial: boolean;
  onVersionSelected: (
    fileId: string,
    fileIdType: 'file_id' | 'file_path',
    fileSource: MediaSourceType,
    needsCopy: boolean,
  ) => Promise<{projectId?: string; videoId?: string; videoVersionId?: string}>;
}

const TileTopSectionWithMenu = ({
  durationDisplayString,
  isCreatingVersion,
  isSelectable,
  isSelected,
  isTutorial,
  name,
  nsId,
  onProjectsDelete,
  onProjectRename,
  onProjectTransfer,
  onVersionStatusSelected,
  isUpdatingStatus,
  updateModifyItemSnackbar,
  onVersionSelected,
  ownerUid,
  projectId,
  thumbnailSrc,
  videoId,
  videoIdForAmplitude,
  versionNumber,

  versionSummaries,
  parentFolderPermission,
  isDemo,
  waveformUrl,
  mediaType,
  ownerAddonEnabled,
  status,
}: TileTopSectionWithMenuProps) => {
  const intl = useIntl();
  const {editActionsProps} = useBrowseItemsViewContext();
  const noItemsSelected = useAtomValue(noItemsSelectedAtom);
  const bulkActionStatus = useAtomValue(bulkActionStatusAtom);

  if (isCreatingVersion) {
    return (
      <Centered>
        <Spinner
          aria-label={intl.formatMessage({
            defaultMessage: 'Creating new version',
            id: 'uB1cSD',
            description: 'label for spinner when a new version is being added to the project',
          })}
        />
      </Centered>
    );
  }

  if (
    !mediaType ||
    mediaType === 'loading' ||
    thumbnailSrc === 'loading' ||
    waveformUrl === 'loading'
  ) {
    // Return nothing distracting while loading the image
    return null;
  }

  const notRequesting = bulkActionStatus !== BulkActionStatus.REQUESTING;
  const checkboxEnabled = editActionsProps.editActionsEnabled && isSelectable && notRequesting;
  const src = mediaType === MediaType.Audio ? waveformUrl : thumbnailSrc;
  const contextMenuEnabled =
    editActionsProps.editActionsEnabled && noItemsSelected && parentFolderPermission !== 'reviewer';

  return (
    <TileTopSection
      contextMenu={
        contextMenuEnabled ? (
          <ContextMenuWrapper>
            <VideoContextMenu
              currentVersionNumber={versionNumber ? versionNumber : undefined}
              isDemo={isDemo}
              mediaType={mediaType}
              name={name}
              onDelete={onProjectsDelete}
              onRename={onProjectRename}
              onTransfer={onProjectTransfer}
              onVersionSelected={onVersionSelected}
              ownerAddonEnabled={ownerAddonEnabled}
              ownerUid={ownerUid}
              parentFolderPermission={parentFolderPermission}
              projectId={projectId}
              thumbnailUrl={thumbnailSrc}
              updateModifyItemSnackbar={updateModifyItemSnackbar}
              versionSummaries={versionSummaries}
              videoId={videoId}
              videoIdForAmplitude={videoIdForAmplitude}
              videoNsId={nsId}
            />
          </ContextMenuWrapper>
        ) : null
      }
      durationDisplayString={durationDisplayString}
      isCheckboxEnabled={checkboxEnabled}
      isCreatingVersion={isCreatingVersion}
      isSelected={isSelected}
      isTutorial={isTutorial}
      isUpdatingStatus={isUpdatingStatus}
      mediaType={mediaType}
      onVersionStatusSelected={onVersionStatusSelected}
      projectId={projectId}
      src={src}
      status={status}
    />
  );
};

interface TileBottomSectionProps {
  name: string;
  lastModifiedDate?: Date;
  children: ReactNode;
  isTutorial: boolean;
}

const LastModifiedTime = styled(Text)`
  display: none;
  ${breakpointSmall(css`
    display: block;
  `)}
`;

export const TileBottomSection = ({
  name,
  lastModifiedDate,
  isTutorial,
  children,
}: TileBottomSectionProps) => {
  const intl = useIntl();
  const {timeAgoLocale} = useReelAppGlobalState();

  return (
    <>
      <TileContentTitle>{name}</TileContentTitle>
      {lastModifiedDate && !isTutorial && (
        <LastModifiedTime color="faint" size="small" variant="label">
          {getTimeSinceForDisplay(lastModifiedDate, intl, timeAgoLocale)}
        </LastModifiedTime>
      )}
      {!isTutorial && children}
    </>
  );
};

interface InnerProjectTileProps extends BrowseUploadedProjectProps {
  isSelectable: boolean;
  isSelected: boolean;
  tileIsHovered: boolean;
  logEvent: LoggingClient['logEvent'];
  showNewBadge: boolean;
  showFileLimitBadge: boolean;
  isTutorial: boolean;
}

const InnerProjectTile = (props: InnerProjectTileProps) => {
  const {
    isCreatingVersion,
    isSelectable,
    isSelected,
    lastModifiedDate,
    name,
    numComments,
    onVersionSelected,
    tileIsHovered,
    versionNumber,
    viewerCount,
    showNewBadge,
    showFileLimitBadge,
    isTutorial,
  } = props;

  return (
    <>
      <TilePoster>
        {showNewBadge && !tileIsHovered && <NewBadge />}
        {showFileLimitBadge && <FileLimitBadge tileIsHovered={tileIsHovered} view="tile" />}

        <TileTopSectionWithMenu
          {...props}
          isCreatingVersion={isCreatingVersion}
          isSelectable={isSelectable}
          isSelected={isSelected}
          onVersionSelected={onVersionSelected}
        />
      </TilePoster>
      <TileContent>
        <TileBottomSection isTutorial={isTutorial} lastModifiedDate={lastModifiedDate} name={name}>
          <ExtraActionsWrapper>
            <Split>
              {numComments === 'loading' ? (
                <SkeletonRectangle $heightValue="20px" $widthValue="70px" />
              ) : !isCreatingVersion && numComments ? (
                <CommentsLabel>{numComments}</CommentsLabel>
              ) : (
                <></>
              )}
              {!isCreatingVersion && viewerCount && !isTutorial ? (
                <ViewerCountLabel>{viewerCount}</ViewerCountLabel>
              ) : (
                <></>
              )}
            </Split>
            {Boolean(versionNumber) && (
              <Split>
                <VersionLabel>
                  {isCreatingVersion ? (
                    <FormattedMessage
                      defaultMessage="Updating..."
                      description="Text to display while a new version is being created."
                      id="iOjunz"
                    />
                  ) : (
                    <FormattedMessage
                      defaultMessage="V{versionNumber}"
                      description="Text describing the version number for media project tiles."
                      id="LJWrWn"
                      values={{versionNumber: versionNumber}}
                    />
                  )}
                </VersionLabel>
              </Split>
            )}
          </ExtraActionsWrapper>
        </TileBottomSection>
      </TileContent>
    </>
  );
};

// Spacing to align with other project tiles
const PasswordlessTileContent = styled(TileContent)`
  min-height: ${spacing('76')};

  ${breakpointSmall(css`
    min-height: calc(${spacing('Macro XLarge')} * 2);
  `)};
`;

const PasswordProtectedProjectTile = (
  props: ViewOnlyContextMenuProps & {
    tileIsHovered: boolean;
    showNewBadge: boolean;
  },
) => {
  const {tileIsHovered, showNewBadge, ...rest} = props;
  const noItemsSelected = useAtomValue(noItemsSelectedAtom);

  return (
    <>
      <TilePoster>
        {showNewBadge && <NewBadge />}
        <ContentLabel>
          <FormattedMessage
            defaultMessage="Password required"
            description="Video description that a user sees on the browse page for a password-protected video. These videos do not display any other information."
            id="LfmrjV"
          />
        </ContentLabel>
        {noItemsSelected && (
          <ContextMenuWrapper>
            <ViewOnlyContextMenu {...rest} />
          </ContextMenuWrapper>
        )}
        <Centered>
          <PictogramIcon src={PlayCircleLine} />
        </Centered>
      </TilePoster>
      <PasswordlessTileContent>
        <TileContentTitle>{props.name}</TileContentTitle>
      </PasswordlessTileContent>
    </>
  );
};

const previewOptions = {
  offsetX: 2,
  offsetY: 2,
};

interface ProjectTileProps extends BrowseUploadedProjectProps {
  isTutorialOverride?: boolean; // For testing
  isDisabledOverride?: boolean; // For testing
}

export const ProjectTile = (props: ProjectTileProps) => {
  const {
    currentProjectAmplitudeId,
    hasPassword,
    isRootLevelView,
    isSharedVideoView,
    isTutorialOverride,
    isDisabledOverride,
    mediaType,
    name,
    parentFolderPermission,
    projectId,
    reelLink,
    status,
    shouldShowNewBadge,
    videoId,
    showFileLimitBadge,
    ownerUid,
  } = props;
  const intl = useIntl();
  const {matchesTutorials} = useMatchesTutorialsRoute();
  const isTutorial = isTutorialOverride || !!matchesTutorials;
  const {editActionsProps} = useBrowseItemsViewContext();
  const {selected, selectable} = useAtomValue(projectSelectionStateAtom)(projectId);
  const filters = useAtomValue(browseFiltersAtom);

  const visible =
    mediaType === 'loading'
      ? true
      : projectShouldRenderBasedOnFilters(getStatusFromId(status ?? 0), filters, mediaType);

  // While in an adobe extension, disable `documents` which can't render there
  const isDisabled =
    isDisabledOverride ||
    (isInAdobeExtension() && (mediaType === 'loading' || mediaType === 'document'));

  const [hoveredOnce, setHoveredOnce] = React.useState(false);

  const makeLogEvent = useBrowseLogEventMaker();
  const logEvent = React.useMemo(
    () =>
      makeLogEvent({
        projectId: currentProjectAmplitudeId,
        reelObjectType: mapMediaTypeToLoggingType(mediaType),
      }),
    [makeLogEvent, currentProjectAmplitudeId, mediaType],
  );
  const [tileIsHovered, setTileIsHovered] = React.useState<boolean>(false);
  const [newBadgeDismissTimeout, setNewBadgeDismissTimeout] = React.useState<NodeJS.Timeout>();

  const item: ProjectOrFolderItems = {projectId, projectName: name, itemType: 'ProjectTile'};

  const [{isDragging}, dragRef, dragPreview] = useDrag(
    () => ({
      type: DndItemTypes.ProjectTile,
      item,
      previewOptions,
      collect: (monitor: any) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [projectId, name],
  );

  const dragPreviewCallbackRef = React.useCallback(
    (node: HTMLDivElement | null) => {
      if (
        editActionsProps.editActionsEnabled === false ||
        editActionsProps.leftRailStatus.status !== 'closed'
      ) {
        return;
      }
      if (
        isSharedVideoView &&
        (isRootLevelView ||
          parentFolderPermission === 'reviewer' ||
          parentFolderPermission === 'other')
      ) {
        return;
      }
      dragRef(node);
      dragPreview(node, previewOptions);
    },
    [
      dragPreview,
      dragRef,
      editActionsProps,
      isRootLevelView,
      isSharedVideoView,
      parentFolderPermission,
    ],
  );

  const projectTileContent = (
    <Tile
      aria-busy={mediaType === 'loading'}
      data-testid="project-tile"
      isDisabled={isDisabled}
      isDragging={isDragging}
      isSelected={selected}
      isVisible={visible}
      onMouseEnter={() => {
        if (!hoveredOnce) {
          const timeout = setTimeout(() => {
            setHoveredOnce(true);
            if (!!videoId && shouldShowNewBadge) {
              logHoverForVideo(videoId);
            }
          }, NEW_BADGE_DISMISSAL_DELAY);

          setNewBadgeDismissTimeout(timeout);
        }
      }}
      onMouseLeave={() => {
        setTileIsHovered(false);
        clearTimeout(newBadgeDismissTimeout);
      }}
      onMouseOver={() => setTileIsHovered(true)}
      ref={dragPreviewCallbackRef}
      role="gridcell"
    >
      <TileDropArea
        isEnabled={!isSharedVideoView}
        name={name}
        onVersionSelected={props.onVersionSelected}
        ownerUid={ownerUid}
        projectId={projectId}
        versionNumber={props.versionNumber}
        videoId={videoId}
        videoNsId={props.nsId}
      >
        <TileLink
          disabled={isDisabled}
          draggable={false}
          onClick={() =>
            // eslint-disable-next-line deprecation/deprecation
            logEvent('select_reel_object')
          }
          to={reelLink}
        >
          {hasPassword ? (
            <PasswordProtectedProjectTile
              mediaType={mediaType as MediaType}
              name={name}
              onDelete={props.onProjectsDelete}
              projectId={projectId}
              showNewBadge={!!shouldShowNewBadge && !hoveredOnce}
              tileIsHovered={tileIsHovered}
              // We can narrow the type here because password protected links will
              // only show up in fully loaded folders. Plus, the media type does
              // not actually matter for rendering these links.
              updateModifyItemSnackbar={props.updateModifyItemSnackbar}
              videoIdForAmplitude={props.videoIdForAmplitude}
            />
          ) : (
            <InnerProjectTile
              {...props}
              isSelectable={selectable}
              isSelected={selected}
              isTutorial={isTutorial}
              logEvent={logEvent}
              showFileLimitBadge={!isTutorial && showFileLimitBadge}
              showNewBadge={!showFileLimitBadge && !!shouldShowNewBadge && !hoveredOnce}
              tileIsHovered={tileIsHovered}
              videoId={videoId}
            />
          )}
        </TileLink>
      </TileDropArea>
    </Tile>
  );

  return isDisabled ? (
    <Tooltip
      maxWidth={300}
      title={intl.formatMessage({
        defaultMessage: 'PDFs can only be opened on replay.dropbox.com',
        id: 'Qh1EOO',
        description: 'Tooltip text explaining why PDFs cannot be opened in extension mode.',
      })}
    >
      <Box as="span" display="inline-block" style={{cursor: 'not-allowed'}}>
        {projectTileContent}
      </Box>
    </Tooltip>
  ) : (
    projectTileContent
  );
};

interface LoadingProjectTileProps {
  opacity?: number;
  name?: string;
  isUploading?: boolean;
  role?: string;
}

export const LoadingProjectTile = ({name, isUploading, role}: LoadingProjectTileProps) => {
  const intl = useIntl();
  return (
    <Tile role={role}>
      <TilePoster>
        {isUploading ? (
          <Centered>
            <Spinner
              aria-label={intl.formatMessage({
                defaultMessage: 'Uploading',
                id: 'xPCCyK',
                description: 'Progress bar label for when an asset is being uploaded',
              })}
            />
          </Centered>
        ) : null}
      </TilePoster>
      <TileContent>
        {isUploading ? (
          <TileContentTitle color={isUploading ? 'subtle' : 'standard'}>{name}</TileContentTitle>
        ) : (
          <>
            <SkeletonRectangle style={{width: '85%', marginBottom: '8px'}} />
            <SkeletonRectangle style={{width: '60%'}} />
          </>
        )}
        {isUploading && (
          <LastModifiedTime color="subtle" size="small" variant="label">
            <FormattedMessage
              defaultMessage="Uploading..."
              description="Text that displays on the browse page over a file that is still uploading as is not ready yet"
              id="hsAW5z"
            />
          </LastModifiedTime>
        )}
        <ExtraActionsWrapper>
          {/* Extra Div to force the version to the right */}
          <div />
          <UploadingVersionLabel />
        </ExtraActionsWrapper>
      </TileContent>
    </Tile>
  );
};

export const UploadingProjectTile = ({name, isUploading}: BrowseUploadingProjectProps) => {
  return <LoadingProjectTile isUploading={isUploading} name={name} role="gridcell" />;
};
