import React from 'react';

import {useFormContext, useWatch} from 'react-hook-form';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';

import type {WatermarkPositionsType} from '~/components/share_modal/position_selector';
import type {WatermarkVideoFields} from '~/components/share_modal/watermark_types';
import useDimensions from '~/lib/use-dimensions';

export const WATERMARK_LIGHT_THEME = '#ffffff';
export const WATERMARK_DARK_THEME = '#1a1918';

type WatermarkPreviewProps = {
  videoWidth: number;
  thumbnailUrl: string;
};

const TextWrapper = styled.div`
  line-height: normal;
  width: 100%;
  text-wrap: nowrap;
  white-space: nowrap;
`;

const TextPositionWrapper = styled.div<{$top: number; $left: number; $fontSize: number}>`
  position: absolute;
  top: ${(props) => props.$top}px;
  left: ${(props) => props.$left}px;
  font-size: ${(props) => props.$fontSize}pt;
`;

// The scaling of text size on the frontend is not matching the backend,
// so this adjustment factor was chosen through experimentation.
const ADJUSTMENT_FACTOR = 0.77;

export const WatermarkPreview = ({videoWidth, thumbnailUrl}: WatermarkPreviewProps) => {
  const {control} = useFormContext<WatermarkVideoFields>();
  const [
    opacity,
    theme,
    size,
    position,
    showEmail,
    showIP,
    showViewDate,
    showViewTime,
    customText,
  ] = useWatch({
    control,
    name: [
      'opacity',
      'theme',
      'size',
      'position',
      'showEmail',
      'showIP',
      'showViewDate',
      'showViewTime',
      'customText',
    ],
  });

  const textRef = React.useRef<HTMLDivElement>(null);
  const previewRef = React.useRef<HTMLImageElement>(null);
  const textDimensions = useDimensions(textRef);
  const previewDimensions = useDimensions(previewRef);
  const dateAndTimeString = getTimeAndDateText(showViewDate, showViewTime);

  const {top, left} = getOffset(
    position,
    textDimensions.width,
    textDimensions.height,
    previewDimensions.width,
    previewDimensions.height,
  );

  return (
    <div style={{display: 'flex', justifyContent: 'center'}}>
      <div style={{position: 'relative', overflow: 'hidden'}}>
        <TextPositionWrapper $fontSize={size} $left={left} $top={top}>
          <TextWrapper
            ref={textRef}
            style={{
              fontSize: `${
                (videoWidth ? (previewDimensions.width / videoWidth) * ADJUSTMENT_FACTOR : 0) * 100
              }%`,
              color: theme === 'dark' ? WATERMARK_DARK_THEME : WATERMARK_LIGHT_THEME,
              opacity: opacity / 100,
              textAlign: 'left',
            }}
          >
            {showEmail && (
              <FormattedMessage
                defaultMessage="email@email.com"
                description="Default text for the email shown in a watermark preview."
                id="dGmO0M"
              />
            )}
            {showEmail && showIP && <br />}
            {showIP && '0.0.0.0'}
            {(showEmail || showIP) && dateAndTimeString && <br />}
            {dateAndTimeString}
            {(showEmail || showIP || dateAndTimeString) && customText && <br />}
            {customText}
          </TextWrapper>
        </TextPositionWrapper>
        <img
          alt=""
          ref={previewRef}
          src={thumbnailUrl}
          style={{width: '-webkit-fill-available', maxHeight: '300px', justifySelf: 'center'}}
        />
      </div>
    </div>
  );
};

function getTimeAndDateText(showDate: boolean, showTime: boolean) {
  // Do not localize the date, because the backend will display the UTC time in the watermark
  const date = new Date();
  let dateAndTimeString = '';
  if (showDate) {
    dateAndTimeString = dateAndTimeString.concat(date.toISOString().split('T')[0] + ' ');
  }
  if (showTime) {
    dateAndTimeString = dateAndTimeString.concat(date.toISOString().split('T')[1].slice(0, 5));
  }

  return dateAndTimeString.length ? dateAndTimeString + ' UTC' : null;
}

const BORDER_PERCENTAGE = 0.05;

function getOffset(
  position: WatermarkPositionsType,
  textWidth: number,
  textHeight: number,
  previewWidth: number,
  previewHeight: number,
) {
  switch (position) {
    case 'top-left':
      return {
        top: previewHeight * BORDER_PERCENTAGE,
        left: previewWidth * BORDER_PERCENTAGE,
      };
    case 'top':
      return {
        top: (previewHeight - textHeight) * BORDER_PERCENTAGE,
        left: (previewWidth - textWidth) / 2,
      };
    case 'top-right':
      return {
        top: previewHeight * BORDER_PERCENTAGE,
        left: (previewWidth - textWidth) * (1 - BORDER_PERCENTAGE),
      };
    case 'center':
      return {
        top: (previewHeight - textHeight) / 2,
        left: (previewWidth - textWidth) / 2,
      };
    case 'bottom-left':
      return {
        top: (previewHeight - textHeight) * (1 - BORDER_PERCENTAGE),
        left: previewWidth * BORDER_PERCENTAGE,
      };
    case 'bottom':
      return {
        top: (previewHeight - textHeight) * (1 - BORDER_PERCENTAGE),
        left: (previewWidth - textWidth) / 2,
      };
    case 'bottom-right':
      return {
        top: (previewHeight - textHeight) * (1 - BORDER_PERCENTAGE),
        left: (previewWidth - textWidth) * (1 - BORDER_PERCENTAGE),
      };
    default:
      return {
        top: (previewHeight - textHeight) / 2,
        left: (previewWidth - textWidth) / 2,
      };
  }
}
