import styled from 'styled-components';

/** Skeleton component for loading rectangular sections of a page */
export const SkeletonRectangle = styled.div<{$heightValue?: string; $widthValue?: string}>`
  height: ${(props) => props.$heightValue ?? '12px'};
  width: ${(props) => props.$widthValue ?? '100px'};
  background: var(--dig-color__border__subtle);
  animation: 1.5s ease-in-out 0.5s infinite normal none running animation-skeleton;
`;

export const StaticSkeletonRectangle = styled.div`
  height: 24px;
  width: 24px;
  background: var(--dig-color__text__base);
`;
