import {useState} from 'react';

import {useIntl} from 'react-intl';
import styled from 'styled-components';

import {Tooltip} from '@dropbox/dig-components/tooltips';
import {UIIcon} from '@dropbox/dig-icons';
import {AddLine} from '@dropbox/dig-icons/assets';

import {Button} from '~/components/button';
import {spacing} from '~/components/styled';
import {
  DeleteFolderLinksButton,
  type DeleteFolderLinksButtonProps,
} from '~/pages/browse_page/components/folder_links/delete_folder_link_button';

import {AddFolderLinkModal} from './add_folder_link_modal';
import type {FolderLink, ProjectFolderInfo} from '../../types';

type AddFolderLinksButtonProps = {
  onClick: () => void;
};

const Root = styled.div`
  padding-top: ${spacing('Micro XSmall')};
  line-height: 0;
`;

export const AddFolderLinksButton = ({onClick}: AddFolderLinksButtonProps) => {
  const intl = useIntl();

  const addLinkText = intl.formatMessage({
    defaultMessage: 'Add link',
    id: 'L/8YEg',
    description: 'Button that allows a folder owner or collaborator to add links to the folder',
  });

  return (
    <Tooltip title={addLinkText}>
      <Button onClick={onClick} variant="transparent">
        <UIIcon src={AddLine} />
        {addLinkText}
      </Button>
    </Tooltip>
  );
};
interface FolderLinkButtonProps {
  addFolderLink: (folderLink: FolderLink, folderId: string) => Promise<void>;
  isViewOnly?: boolean;
  projectFolderInfo: ProjectFolderInfo;
  deleteLink: DeleteFolderLinksButtonProps['deleteLink'];
}

export const FolderLinkButton = ({
  addFolderLink,
  projectFolderInfo,
  deleteLink,
  isViewOnly = false,
}: FolderLinkButtonProps) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleAddClick = () => {
    setModalOpen(true);
  };

  return (
    <Root>
      {projectFolderInfo.folderLinks.length > 0 ? (
        <DeleteFolderLinksButton
          deleteLink={deleteLink}
          isViewOnly={isViewOnly}
          name={projectFolderInfo.folderLinks[0]?.name ?? ''}
          url={projectFolderInfo.folderLinks[0]?.url ?? ''}
        />
      ) : !isViewOnly ? (
        <AddFolderLinksButton onClick={handleAddClick} />
      ) : null}
      <AddFolderLinkModal
        addFolderLink={addFolderLink}
        folderId={projectFolderInfo.projectFolderId}
        folderName={projectFolderInfo.name}
        open={modalOpen}
        requestClose={() => setModalOpen(false)}
      />
    </Root>
  );
};
