import {useAtomValue} from 'jotai';
import {useIntl} from 'react-intl';

import {UIIcon} from '@dropbox/dig-icons';
import {DeleteLine} from '@dropbox/dig-icons/assets';

import {Button, IconButton} from '~/components/button';
import {useViewport} from '~/components/viewport_context';
import {BulkActionStatus, bulkActionStatusAtom} from '~/state/bulk_actions';

type BulkDeleteButtonProps = {
  onClick: () => void;
};

export const BulkDeleteButton = ({onClick}: BulkDeleteButtonProps) => {
  // eslint-disable-next-line deprecation/deprecation
  const {isSmallScreenSize} = useViewport();
  const intl = useIntl();

  const bulkActionStatus = useAtomValue(bulkActionStatusAtom);

  const disabled = bulkActionStatus === BulkActionStatus.REQUESTING;

  const deleteButton = intl.formatMessage({
    defaultMessage: 'Delete',
    description: 'Button that allows a user to delete one or more files',
    id: 'xUOhit',
  });

  return isSmallScreenSize ? (
    <IconButton
      aria-label={deleteButton}
      disabled={disabled}
      onClick={onClick}
      size="small"
      variant="opacity"
    >
      <UIIcon src={DeleteLine} />
    </IconButton>
  ) : (
    <Button
      disabled={disabled}
      onClick={onClick}
      variant="opacity"
      withIconLeft={<UIIcon src={DeleteLine} />}
    >
      {deleteButton}
    </Button>
  );
};
