import styled from 'styled-components';

import {Spinner} from '@dropbox/dig-components/progress_indicators';
import {Text} from '@dropbox/dig-components/typography';

const Container = styled.div<{$height?: string}>`
  display: flex;
  height: ${({$height}) => $height || '100%'};
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledText = styled(Text)`
  margin-bottom: var(--spacing__unit--4);
`;

interface LoaderProps {
  height?: string;
  text?: string;
}

export const Loader = (props: LoaderProps) => {
  const {height, text} = props;

  return (
    <Container $height={height}>
      <Spinner aria-valuetext={text} />
      <StyledText size="medium">{text}</StyledText>
    </Container>
  );
};
