import React from 'react';

import {useInfiniteQuery, useQueryClient} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';
import {useIntl} from 'react-intl';

import type {reel} from '@dropbox/api-v2-client';

import {appStrings} from '~/components/app_strings';
import {useReelAppGlobalState} from '~/context';
import type {FolderProp, ShareFolderAccessType, SuggestedFilesResponse} from '~/lib/api';
import {
  FOLDER_PAGE_LIMIT,
  folderResponseToFolderProp,
  getFolderTree,
  getSuggestedItems,
  initialListFolder,
  listFolderBasicInfoContinue,
  listFolderBasicInfoV2,
  LOADING,
  NONE,
  uiSortToApiSort,
} from '~/lib/api';
import type {Branding} from '~/lib/branding';
import {reportBadContextUseError} from '~/lib/error_reporting';
import {BrowseTab, replayApi} from '~/lib/query_client';
import {useStateIfMounted} from '~/lib/use-mounted';
import {usePaginatedFolderResults} from '~/lib/utils';
import {browseSortAtom} from '~/state/browse';

import type {LeftRailStatus, ProjectFolderInfo} from './types';

const useCurrentFolder = ({
  setCurrentFolder,
  setCurrentFolderError,
}: {
  setCurrentFolder: React.Dispatch<React.SetStateAction<FoldersContextValue['currentFolder']>>;
  setCurrentFolderError: (e: Error) => void;
}) => {
  const [folderOrTabToFetch, setFolderOrTabToFetch] = React.useState<
    string | BrowseTab | undefined
  >();
  const isSharedView = folderOrTabToFetch === BrowseTab.SHARED;

  const shouldUsePaginatedFolderResults = usePaginatedFolderResults();

  const sort = useAtomValue(browseSortAtom);

  const query = useInfiniteQuery<
    reel.ListFolderBasicInfoResult | reel.ListFolderBasicInfoContinueResult | void
  >({
    // This eslint disable is technically redundant because the query will be
    // disabled by the dependencies changing (see the `enabled` attr)
    queryKey: replayApi.listFolderBasicInfo(folderOrTabToFetch), // eslint-disable-line @tanstack/query/exhaustive-deps
    queryFn: ({pageParam = null}) => {
      if (!shouldUsePaginatedFolderResults || folderOrTabToFetch === undefined) {
        return Promise.reject(new Error('Query should be disabled but queryFn has been invoked'));
      }
      if (pageParam === null) {
        const isRootFolder =
          folderOrTabToFetch === BrowseTab.HOME || folderOrTabToFetch === BrowseTab.SHARED;
        const folderId = isRootFolder ? '' : folderOrTabToFetch;
        // This is ugly, but when we fetch results for the root folder, there
        // is a bug where the backend counts results from both tabs towards the
        // page limit, even though it filters out the results from the hidden
        // tab. Make a guess at the best way to offset this by requesting more
        // results when we are requesting the root folder.
        const limit = isRootFolder ? Math.round(FOLDER_PAGE_LIMIT * 1.5) : FOLDER_PAGE_LIMIT;
        return listFolderBasicInfoV2({
          folderId,
          limit: false ? limit : 5,
          sortAttr: uiSortToApiSort(sort.attribute),
          sortDesc: sort.direction === 'descending',
          isSharedView,
        });
      }
      return listFolderBasicInfoContinue(pageParam);
    },
    enabled: shouldUsePaginatedFolderResults && folderOrTabToFetch !== undefined,
    getNextPageParam: (lastPage) => {
      if (lastPage?.cursor?.has_next) {
        return lastPage.cursor;
      }
      return undefined;
    },
  });

  const queryClient = useQueryClient();

  const navigateToFolderId = React.useCallback(
    (folderId: string | BrowseTab, forceRefresh: boolean) => {
      if (forceRefresh) {
        queryClient.resetQueries({queryKey: replayApi.listFolderBasicInfo(folderId)});
      }
      setFolderOrTabToFetch(folderId);
    },
    [queryClient],
  );

  const {data, error, isError, isFetching, fetchNextPage, hasNextPage} = query;

  React.useEffect(() => {
    if (!isFetching && hasNextPage) {
      fetchNextPage();
    }
  }, [isFetching, hasNextPage, fetchNextPage]);

  React.useEffect(() => {
    if (!data && isError) {
      // If the first page fetch failed, just set the error for now
      setCurrentFolderError(
        error instanceof Error ? error : new Error('Error calling ListFolderBasicInfo'),
      );
      return;
    }

    setCurrentFolder((previous) => {
      // If we've already loaded some files but the next page fetch fails, set
      // the cursor to an error
      if (previous && isError) {
        return {
          ...previous,
          cursor: error instanceof Error ? error : new Error('Error retrieving pages'),
        };
      }

      if (!data) {
        return null;
      }

      if (data.pages.length < 1) {
        return null;
      }

      const firstPage = data.pages[0];

      if (!firstPage) {
        return null;
      }

      const videos = data.pages.flatMap((x) => x?.projects_with_videos ?? []);
      const folders = data.pages.flatMap((x) => x?.folders ?? []);
      const reducedPages = {...firstPage, folders, projects_with_videos: videos};

      const lastPage = data.pages[data.pages.length - 1];
      const cursor = lastPage?.cursor?.has_next ? lastPage.cursor : null;

      return folderResponseToFolderProp({
        listFolderResult: reducedPages,
        isSharedView,
        cursor,
      });
    });
  }, [data, error, isError, isSharedView, setCurrentFolder, setCurrentFolderError]);

  return {
    navigateToFolderId,
  };
};

export interface FoldersContextValue {
  breadcrumbPath: reel.Folder[] | undefined;
  childTree: reel.FolderTreeNode[] | null;
  clearCurrentFolder: () => void;
  currentAllowSuperAdminRights: boolean;
  currentFolder: FolderProp | null;
  currentFolderError: Error | null;
  currentFolderId: string;
  currentFolderName: string | null;
  currentFolderPermission: ShareFolderAccessType | null;
  currentParentFolderId: string | undefined;
  currentProjectAmplitudeId?: string;
  currentProjectId: string;
  folderTree: reel.FolderTreeNode[] | null;
  isFolderOrProject: (folderId: string) => 'folder' | 'project';
  isMobileNavBarOpen: boolean;
  leftRailStatus: LeftRailStatus;
  projectFolderInfo: ProjectFolderInfo | null;
  reelRootFolderId: string;
  setChildTree: (tree: reel.FolderTreeNode[] | null) => void;
  setCurrentFolderError: (e: Error) => void;
  setCurrentParentFolderId: (id: string) => void;
  setCurrentProjectId: (currentProjectId: string) => void;
  setFolderTree: (tree: reel.FolderTreeNode[] | null) => void;
  setIsMobileNavBarOpen: (value: boolean) => void;
  setLeftRailStatus: (value: LeftRailStatus) => void;
  setProjectFolderInfo: (folderInfo: Partial<ProjectFolderInfo>) => void;
  setReelRootFolderId: (reelRootFolderId: string) => void;
  suggestedItems: SuggestedFilesResponse;
  updateFolder: (folderId: string, isSharedView: boolean, forceRefresh?: boolean) => Promise<void>;
  updateFolderList: (newFolders: reel.Folder[]) => void;
  updateFolderTree: () => void;
  updateProjectList: (newProjects: reel.ProjectWithVideos[]) => void;
  updateSuggestedItems: () => Promise<void>;
}

export const FoldersContext = React.createContext<FoldersContextValue | null>(null);

const getCurrentFolderInChain = (folder: FolderProp | null) => {
  if (folder === null) {
    return null;
  }
  if (!folder.folderNameIdChainFromRoot || !folder.folderNameIdChainFromRoot.length) {
    return undefined;
  }
  return folder.folderNameIdChainFromRoot[folder.folderNameIdChainFromRoot.length - 1];
};

const getProjectFolderInChain = (folder: FolderProp | null) => {
  if (folder === null) {
    return null;
  }
  if (
    folder.folderNameIdChainFromRoot.length === 1 &&
    folder.accessLevel['.tag'] === 'super_admin'
  ) {
    return folder.folderNameIdChainFromRoot[0];
  }
  if (!folder.folderNameIdChainFromRoot || folder.folderNameIdChainFromRoot.length < 2) {
    return undefined;
  }
  return folder.folderNameIdChainFromRoot[1];
};

export const FoldersProvider = ({children}: React.PropsWithChildren<{}>) => {
  const intl = useIntl();
  const sessionContext = useReelAppGlobalState();

  const [currentFolder, setCurrentFolder] =
    useStateIfMounted<FoldersContextValue['currentFolder']>(null);
  const [currentFolderError, setCurrentFolderError] = useStateIfMounted<Error | null>(null);
  // Start this off as undefined so we can tell the first load of the root
  // folder apart from a refresh of the root folder (the folder id will change
  // to an empty string). This is for recording the "TTVC" of the browse page.
  const [currentFolderId, setCurrentFolderId] = React.useState<
    FoldersContextValue['currentFolderId'] | undefined
  >(undefined);
  const [currentParentFolderId, setCurrentParentFolderId] =
    React.useState<FoldersContextValue['currentParentFolderId']>();
  const [folderTree, setFolderTree] = React.useState<FoldersContextValue['folderTree']>(null);
  const [leftRailStatus, setLeftRailStatus] = React.useState<FoldersContextValue['leftRailStatus']>(
    {status: 'closed'},
  );
  const [childTree, setChildTree] = React.useState<FoldersContextValue['childTree']>(null);
  const [currentProjectId, setCurrentProjectId] =
    React.useState<FoldersContextValue['currentProjectId']>('');
  const [isMobileNavBarOpen, setIsMobileNavBarOpen] =
    React.useState<FoldersContextValue['isMobileNavBarOpen']>(false);
  const [reelRootFolderId, setReelRootFolderId] =
    React.useState<FoldersContextValue['reelRootFolderId']>('');
  const [suggestedItems, setSuggestedItems] = React.useState<SuggestedFilesResponse>(NONE);

  const updateSuggestedItems = React.useCallback(async () => {
    setSuggestedItems(LOADING);
    try {
      setSuggestedItems(await getSuggestedItems());
    } catch (e) {
      // Don't report these errors since they are non-critical and they fire
      // regularly.
      setSuggestedItems(NONE);
    }
  }, []);

  const currentProjectAmplitudeId = currentFolder
    ? currentFolder.currentProjectIdForAmplitude
    : undefined;

  const {navigateToFolderId} = useCurrentFolder({
    setCurrentFolder,
    setCurrentFolderError,
  });

  const shouldUsePaginatedFolderResults = usePaginatedFolderResults();

  const updateFolder = React.useCallback(
    async (folderId: string, isSharedView: boolean, forceRefresh = true) => {
      if (shouldUsePaginatedFolderResults) {
        const folderIdOrTabName =
          folderId === '' ? (isSharedView ? BrowseTab.SHARED : BrowseTab.HOME) : folderId;
        navigateToFolderId(folderIdOrTabName, forceRefresh);
        return;
      }

      try {
        const initialFolder = await initialListFolder(folderId, isSharedView, !isSharedView);

        setCurrentFolder(
          folderResponseToFolderProp({listFolderResult: initialFolder, isSharedView, cursor: null}),
        );
      } catch (e) {
        setCurrentFolderError(e);
      }
    },
    [navigateToFolderId, setCurrentFolder, setCurrentFolderError, shouldUsePaginatedFolderResults],
  );

  const clearCurrentFolder = React.useCallback(() => setCurrentFolder(null), [setCurrentFolder]);

  const isFolderOrProject = React.useCallback(
    (folderId: string) => {
      // Only loop through top of folder tree (project nodes) and search for folder ID
      return folderTree?.some((folderTreeNode) => folderTreeNode.folder?.id === folderId)
        ? 'project'
        : 'folder';
    },
    [folderTree],
  );

  const updateFolderList = React.useCallback(
    (newFolders: reel.Folder[]) => {
      setCurrentFolder((f) => (f ? {...f, folders: newFolders} : null));
    },
    [setCurrentFolder],
  );

  const updateProjectList = React.useCallback(
    (newProjects: reel.ProjectWithVideos[]) => {
      setCurrentFolder((f) => (f ? {...f, projectsWithVideos: newProjects} : null));
    },
    [setCurrentFolder],
  );

  const updateFolderTree = React.useCallback(async () => {
    const result = await getFolderTree();
    const tree = result.root_folder_node?.children || null;

    // We want to have the root folder (aka "Home") be on top of the folder
    // tree for display purposes in the client on the left rail. Technically it's the
    // parent folder of everything underneath it but this way looks a little neater in the UI.
    if (tree) {
      tree.unshift({children: [], folder: result.root_folder_node?.folder});

      // The root folder is still called "Your videos" in the ES entities so this
      // is a workaround for now
      const homeFolderName = intl.formatMessage(appStrings.homeTitle);

      tree[0].folder!.name = homeFolderName;
      setReelRootFolderId(tree[0].folder!.id);
    }

    setFolderTree(tree);
  }, [intl]);

  React.useEffect(() => {
    if (sessionContext.status === 'logged in') {
      updateFolderTree();
    }
  }, [updateFolderTree, sessionContext.status]);

  const breadcrumbPath = currentFolder ? currentFolder.folderNameIdChainFromRoot : undefined;

  const currentFolderPermission = currentFolder ? currentFolder.accessLevel['.tag'] : null;

  const currentAllowSuperAdminRights = Boolean(
    getCurrentFolderInChain(currentFolder)?.are_admin_rights_available,
  );

  const currentFolderName = getCurrentFolderInChain(currentFolder)?.name ?? null;

  const projectFolderInfo = React.useMemo(() => {
    const projectFolderInChain = getProjectFolderInChain(currentFolder);
    if (!projectFolderInChain) return null;
    return {
      description: projectFolderInChain.description ?? '',
      name: projectFolderInChain.name ?? '',
      projectFolderId: projectFolderInChain.id,
      branding: projectFolderInChain.branding as Branding,
      folderLinks: projectFolderInChain.folder_links as reel.FolderLink[],
    };
  }, [currentFolder]);

  const setProjectFolderInfo = React.useCallback(
    (projectFolderInfo: {name?: string; description?: string; folderLinks?: reel.FolderLink[]}) => {
      setCurrentFolder((previous) => {
        if (previous === null) return previous;
        if (previous.folderNameIdChainFromRoot.length < 2) return previous;
        const projectFolderInChain = getProjectFolderInChain(previous);
        if (projectFolderInChain === null || projectFolderInChain === undefined) {
          return previous;
        }
        const replaceWith = {
          ...projectFolderInChain,
          ...projectFolderInfo,
          folder_links: projectFolderInfo.folderLinks ?? projectFolderInChain.folder_links,
        };
        const nextBreadcrumbPath = [...previous.folderNameIdChainFromRoot];
        nextBreadcrumbPath[1] = replaceWith;

        return {
          ...previous,
          folderNameIdChainFromRoot: nextBreadcrumbPath,
        };
      });
    },
    [setCurrentFolder],
  );

  React.useEffect(() => {
    if (currentFolder) setCurrentFolderId(currentFolder.folderId);
  }, [currentFolder]);

  const providerValue = React.useMemo(
    () => ({
      breadcrumbPath,
      childTree,
      clearCurrentFolder,
      currentAllowSuperAdminRights,
      currentFolder,
      currentFolderError,
      currentFolderId: currentFolderId || '',
      currentFolderName,
      currentFolderPermission,
      currentParentFolderId,
      currentProjectAmplitudeId,
      currentProjectId,
      folderTree,
      isFolderOrProject,
      isMobileNavBarOpen,
      leftRailStatus,
      projectFolderInfo,
      reelRootFolderId,
      setChildTree,
      setCurrentFolderError,
      setCurrentParentFolderId,
      setCurrentProjectId,
      setFolderTree,
      setIsMobileNavBarOpen,
      setLeftRailStatus,
      setProjectFolderInfo,
      setReelRootFolderId,
      suggestedItems,
      updateFolder,
      updateFolderList,
      updateFolderTree,
      updateProjectList,
      updateSuggestedItems,
    }),
    [
      breadcrumbPath,
      childTree,
      clearCurrentFolder,
      currentAllowSuperAdminRights,
      currentFolder,
      currentFolderError,
      currentFolderId,
      currentFolderName,
      currentFolderPermission,
      currentParentFolderId,
      currentProjectAmplitudeId,
      currentProjectId,
      folderTree,
      isFolderOrProject,
      isMobileNavBarOpen,
      leftRailStatus,
      projectFolderInfo,
      reelRootFolderId,
      setCurrentFolderError,
      setProjectFolderInfo,
      suggestedItems,
      updateFolder,
      updateFolderList,
      updateFolderTree,
      updateProjectList,
      updateSuggestedItems,
    ],
  );

  return <FoldersContext.Provider value={providerValue}>{children}</FoldersContext.Provider>;
};

export const useFoldersSharedState = () => {
  const foldersContext = React.useContext<FoldersContextValue | null>(FoldersContext);
  if (foldersContext === null) {
    const error = new Error('useFoldersSharedState must be used within a FoldersContext provider');
    reportBadContextUseError(error);
    throw error;
  }
  return foldersContext;
};
