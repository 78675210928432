/**
 * Copied from: metaserver/static/js/modules/core/user_i18n.ts
 * Splice a string into its correct UTF 16 Characters. It handles correctly UTF 16 chars in the
 * Unicode supplementary planes (having code points higher than 0xFFFF), like Emojis, that
 * consists of 2 UTF 16 words, called surrogate chars. It also supports correctly chars
 * in the regular Unicode Basic Multilingual Plane (BMP), consist of a single UTF 16 word.
 * https://mathiasbynens.be/notes/javascript-unicode
 */
const spliceToCodePointChars = (str: string): string[] => {
  const codePointCharsArray: string[] = [];

  const strLength = str.length;
  let currentUtf16WordIndex = 0;

  // Scan the UTF 16 words of the input string.
  while (currentUtf16WordIndex < strLength) {
    // Get the current UTF 16 word of the input string.
    const currentUtf16Word = str[currentUtf16WordIndex];

    // Check the type of the current UTF 16 word.
    if (currentUtf16Word >= '\uD800' && currentUtf16Word <= '\uDB7F') {
      // The current UTF 16 word is a high surrogate char in a surrogate pair.
      // The actual UTF 16 char consists of 2 UTF 16 words.
      if (currentUtf16WordIndex < strLength - 1) {
        // This is a safety check. If the current word is a high surrogate char, there must be a
        // second one, and it should be the low surrogate char.
        const nextUtf16Word = str[currentUtf16WordIndex + 1];
        const currentUtf16CodePointChar = currentUtf16Word + nextUtf16Word;
        codePointCharsArray.push(currentUtf16CodePointChar);
        currentUtf16WordIndex += 2;
      } else {
        // We should never get here for a well formatted strings.
        break;
      }
    } else {
      // The current UTF 16 word is a regular UTF 16 char in the Basic Multilingual Plane.
      codePointCharsArray.push(currentUtf16Word);
      currentUtf16WordIndex += 1;
    }
  }

  return codePointCharsArray;
};

export const getUserInitials = (nameString: string, locale: string) => {
  let parts = nameString.toUpperCase().trim().split(' ').filter(Boolean);

  if (parts.length === 0) {
    return '';
  }

  const isCjkLocale = ['zh', 'ja', 'ko'].some((language) => locale.startsWith(language));

  if (isCjkLocale) {
    parts = parts.reverse();
  }

  const [firstChar, secondChar] = spliceToCodePointChars(parts[0]);

  if (parts.length === 1) {
    const isAscii = /^[\x00-\x7F]*$/.test(nameString);

    return isCjkLocale && !isAscii && secondChar ? `${firstChar}${secondChar}` : firstChar;
  }

  const [lastChar] = spliceToCodePointChars(parts[parts.length - 1]);

  return `${firstChar}${lastChar}`;
};
