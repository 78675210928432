import React from 'react';

import {useIntl} from 'react-intl';

import {Tooltip} from '@dropbox/dig-components/tooltips';
import {UIIcon} from '@dropbox/dig-icons';
import {PersonMultipleLine} from '@dropbox/dig-icons/assets';

import {IconButton} from '~/components/button';
import {ManageAccessModal, type ManageAccessModalProps} from '~/components/manage_access_modal';
import type {ShareFolderAccessType} from '~/lib/api';
import type {ShareRecipient} from '~/pages/browse_page/components/common';

import {useBrowseLogEventMaker} from '../browse_logging_context';
import {useFoldersSharedState} from '../folders_context';

interface ManagePeopleButtonProps {
  accessLevel: ShareFolderAccessType;
  allowSuperAdminRights?: boolean;
  onSettingsChange?: ManageAccessModalProps['settingsChangeCallback'];
  shareRecipients: ShareRecipient[];
  teamFolderId: string;
  teamFolderName: string;
}

export const ManagePeopleButton = ({
  accessLevel,
  allowSuperAdminRights,
  onSettingsChange,
  shareRecipients,
  teamFolderName,
  teamFolderId,
}: ManagePeopleButtonProps) => {
  const intl = useIntl();
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const {currentProjectId} = useFoldersSharedState();

  const makeLogEvent = useBrowseLogEventMaker();
  const logEvent = React.useMemo(
    () => makeLogEvent({projectId: currentProjectId}),
    [currentProjectId, makeLogEvent],
  );

  const handleButtonClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  if (!onSettingsChange) {
    return null;
  }

  const buttonLabel = intl.formatMessage({
    defaultMessage: 'Manage people',
    description:
      'Button that allows a project owner or collaborator to manage others in the project',
    id: 'Cf5uyw',
  });

  return (
    <>
      <Tooltip placement="bottom" title={buttonLabel}>
        <IconButton aria-label={buttonLabel} onClick={handleButtonClick} variant="outline">
          <UIIcon src={PersonMultipleLine} />
        </IconButton>
      </Tooltip>
      <ManageAccessModal
        accessLevel={accessLevel}
        allowSuperAdminRights={allowSuperAdminRights}
        logEvent={logEvent}
        modalType="manage_access_only"
        open={modalOpen}
        requestClose={handleCloseModal}
        settingsChangeCallback={onSettingsChange}
        shareRecipients={shareRecipients}
        teamFolderId={teamFolderId}
        teamFolderName={teamFolderName}
      />
    </>
  );
};
