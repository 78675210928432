import type {
  BrowseFolderProps,
  BrowseItemsProps,
  BrowseProjectProps,
} from '~/pages/browse_page/components/common';
import {isUploadedFolderProps, ListItemsRenderer} from '~/pages/browse_page/components/common';
import type {onCreateFolderSuccessFn} from '~/pages/browse_page/use_create_project';

import {FolderTile, UploadingFolderTile} from './tiles/folder_tile';
import {ProjectTile, UploadingProjectTile} from './tiles/project_tile';
import {TeamProjectTile} from './tiles/team_project_tile';
import {TileGrid, type TileGridProps} from './tiles/tile_grid';
import {UploadTile} from './tiles/upload_tile';
import type {EditActionsDeps} from '../browse_items_view_context';

type ProjectGridViewProps = BrowseItemsProps & {
  showUploadPrompts: boolean;
  showCreateProjectPrompt?: boolean;
  currentFolderId: string;
  folderLevel: number;
  editActionsDeps: EditActionsDeps;
  onCreateFolderSuccessFn?: onCreateFolderSuccessFn;
};

const GridWrapper = (props: TileGridProps) => {
  return <TileGrid {...props} role="row" />;
};

export const ProjectGridView = (props: ProjectGridViewProps) => {
  const showUploadTile = props.showUploadPrompts && !props.showCreateProjectPrompt;

  return (
    <>
      {showUploadTile && props.editActionsDeps.editActionsEnabled && (
        <UploadTile
          currentFolderId={props.currentFolderId}
          level={props.folderLevel}
          onAddVideoError={props.editActionsDeps.onAddVideoError}
          onAddVideoFromDropbox={props.editActionsDeps.handleAddFileFromDropbox}
          onAllUploadsComplete={props.editActionsDeps.handleAllUploadsComplete}
          onFilePick={props.editActionsDeps.onFilePick}
          onFileUploadComplete={props.editActionsDeps.handleAddFileFromUpload}
        />
      )}

      <div aria-multiselectable={true} role="grid">
        <ListItemsRenderer
          {...props}
          renderFolder={GridFolderTile}
          renderProject={GridProjectTile}
          wrapper={GridWrapper}
        />
      </div>
    </>
  );
};

const GridFolderTile = (props: BrowseFolderProps) => {
  if (!isUploadedFolderProps(props)) {
    return <UploadingFolderTile key={props.folderId} />;
  }

  // In a root level view (either 'Shared' or 'Home'), all folders are team projects
  if (props.isRootLevelView) {
    return <TeamProjectTile key={props.folderId} {...props} />;
  }

  return <FolderTile key={props.folderId} {...props} />;
};

const GridProjectTile = (props: BrowseProjectProps) => {
  if (props.isUploading) {
    return props.folderUpload ? null : (
      <UploadingProjectTile
        key={props.projectId}
        parentFolderId={props.parentFolderId}
        {...props}
      />
    );
  }

  return <ProjectTile key={props.videoId} {...props} />;
};
