import {hostname} from './globals';

export enum CurrentEnvironment {
  Development,
  Amplify,
  Production,
}

export const PROD_HOSTNAME = 'replay.dropbox.com';

const AMPLIFY_MAIN_HOSTNAME = /main\.(\w)+\.amplifyapp\.com/;
export const AMPLIFY_HOSTNAME = 'amplifyapp.com';

export const isMainAmplifyBranch = () => {
  return Boolean(window.location.hostname.match(AMPLIFY_MAIN_HOSTNAME));
};

export const getCurrentEnvironment: () => CurrentEnvironment = () => {
  if (hostname.includes(PROD_HOSTNAME)) {
    return CurrentEnvironment.Production;
  }
  if (hostname.endsWith(AMPLIFY_HOSTNAME)) {
    return CurrentEnvironment.Amplify;
  }
  return CurrentEnvironment.Development;
};

export const checkPithosPrivacyConsentFlag = () => {
  return Boolean(import.meta.env.VITE_USE_PITHOS_PRIVACY_CONSENT);
};
