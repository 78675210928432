import React, {useCallback, useEffect} from 'react';

import {useAtomValue} from 'jotai/index';
import styled from 'styled-components';

import {Box} from '@dropbox/dig-foundations';

import {LockedBanner} from '~/components/restricted_banner/locked_banner';
import {spacing} from '~/components/styled';
import {useReelAppGlobalState} from '~/context';
import {USER_METADATA_FIELD, useShowFileQuotaInfo} from '~/lib/provisions';
import {useIsTeamLocked, useReelProvisioningEnabled} from '~/lib/utils';
import {loadableUserQuotaAtom} from '~/state/user';

import {FileQuotaBanner} from './file_quota_banner';
import {StorageQuotaBanner} from './storage_quota_banner';

const ONE_DAY_IN_MILLISECONDS = 86400000;

const Root = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${spacing('Micro Medium')};
`;
interface QuotaBannerProps {
  inFolder?: boolean;
}

export const RestrictedBanner = ({inFolder = false}: QuotaBannerProps) => {
  const sessionContext = useReelAppGlobalState();
  const isProvisioningEnabled = useReelProvisioningEnabled();
  const loadableUserQuota = useAtomValue(loadableUserQuotaAtom);
  const showLockedBanner = useIsTeamLocked();
  const showStorageQuotaBanner =
    loadableUserQuota.state === 'hasData' &&
    (loadableUserQuota.data.isAtQuota || loadableUserQuota.data.isNearQuota);

  const LAST_DISMISSED_BANNER_DATE =
    sessionContext.status === 'logged in'
      ? sessionContext.metadata.REPLAY_DISMISSED_QUOTA_BANNER
      : 0;
  const wasQuotaBannerDismissedRecently =
    LAST_DISMISSED_BANNER_DATE > Date.now() - ONE_DAY_IN_MILLISECONDS;
  const showFileQuotaInfo = useShowFileQuotaInfo();

  const showFileQuotaBanner =
    isProvisioningEnabled && !wasQuotaBannerDismissedRecently && showFileQuotaInfo;
  const [showBanner, setShowBanner] = React.useState<boolean>(false);

  useEffect(() => {
    setShowBanner(showLockedBanner || showFileQuotaBanner || showStorageQuotaBanner);
  }, [showLockedBanner, showFileQuotaBanner, showStorageQuotaBanner]);

  const onRequestClose = useCallback(() => {
    if (sessionContext.status === 'logged in') {
      sessionContext.setMetadata(USER_METADATA_FIELD.REPLAY_DISMISSED_QUOTA_BANNER, Date.now());
    }
    setShowBanner(false);
  }, [sessionContext]);

  if (!showBanner || loadableUserQuota.state === 'loading') return null;

  return (
    <Root padding={!inFolder ? 'Micro Large' : '0'} paddingBottom="0">
      {showLockedBanner ? (
        <LockedBanner fullWidth={inFolder} />
      ) : showStorageQuotaBanner ? (
        <StorageQuotaBanner fullWidth={inFolder} userQuota={loadableUserQuota.data} />
      ) : showFileQuotaBanner ? (
        <FileQuotaBanner fullWidth={inFolder} onRequestClose={onRequestClose} />
      ) : null}
    </Root>
  );
};
