import {forwardRef, type HTMLAttributes, type ReactNode} from 'react';

import styled, {css} from 'styled-components';

import {Card} from '@dropbox/dig-components/card';
import type {CardContentProps, CardMediaProps} from '@dropbox/dig-components/card';
import {Title} from '@dropbox/dig-components/typography';
import {Box, type BoxProps, ThemeContainer, ThemeProvider} from '@dropbox/dig-foundations';

import {TileDropOverlay} from '~/components/drop_area';
import {ReelLink} from '~/components/link';
import {
  breakpointSmall,
  type Color,
  fontSize,
  fontWeight,
  ifNotProp,
  ifProp,
  lineHeight,
} from '~/components/styled';
import {color, colorTransition, radius, spacing} from '~/components/styled';
import {backgroundColorFromTheme, type BrandingTheme} from '~/lib/branding';
import {getContrastThemeMode} from '~/lib/colors';

const tileRadius = radius('Medium');

export const TILE_FOOTER_MIN_HEIGHT = spacing('44');

const bottomStyles = css`
  border-bottom-left-radius: ${tileRadius};
  border-bottom-right-radius: ${tileRadius};
`;

const topStyles = css`
  border-top-left-radius: ${tileRadius};
  border-top-right-radius: ${tileRadius};
`;

export const TileLink = styled(ReelLink)`
  &:focus {
    outline: ${color('Utility Focus Ring')} auto 1px;
  }
`;

interface RootProps {
  $isDragging?: boolean;
  $isVisible: boolean;
  $isDisabled: boolean;
}

export const TileRoot = styled.div<RootProps>`
  position: relative;
  transition: ${colorTransition('opacity', 'border')};
  border-radius: ${tileRadius};
  border-style: solid;
  border-width: 1px;
  border-color: transparent;

  ${({$isDisabled}) =>
    $isDisabled &&
    css`
      pointer-events: none;
      opacity: 0.7;
    `}

  ${({$isDragging}) =>
    $isDragging &&
    css`
      opacity: 0.6;
    `}

  ${({$isVisible = true}) =>
    !$isVisible &&
    css`
      display: none;
    `}

  &[aria-selected='true'] {
    border-color: ${color('Primary Base')};
  }

  .dig-Card {
    // Custom color for shadow from design
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    border-radius: ${tileRadius};
  }

  .dig-Card-Media {
    background-color: ${color('Opacity Surface')};

    img {
      ${topStyles}
    }
  }

  .dig-Card-Media,
  .dig-Card__section:first-child {
    position: relative;
    transition: ${colorTransition('background-color')};

    ${({$isDisabled}) =>
      $isDisabled &&
      css`
        pointer-events: none;
        opacity: 0.4;
      `}

    ${topStyles};
  }
`;

const SuggestedRoot = styled.div`
  border: 1px dashed ${color('Border Subtle')};
  cursor: pointer;

  .dig-Card {
    padding: ${spacing('Micro Medium')};
    padding-bottom: 0;
  }

  .dig-Card-Media {
    position: relative;
  }

  .dig-Card-Container {
    color: ${color('Text Subtle')};
  }

  &:hover {
    border-color: ${color('Border Base')};

    .dig-Card-Container {
      color: ${color('Text Base')};
    }
  }
`;

export interface TileProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  isLink?: boolean;
  isDragging?: boolean;
  isSelected?: boolean;
  isDndHovered?: boolean;
  isDndDroppable?: boolean;
  isVisible?: boolean;
  isDisabled?: boolean;
  dndHoverText?: string;
}

export const Tile = forwardRef<HTMLDivElement, TileProps>(
  (
    {
      isSelected = false,
      isVisible = true,
      isDragging,
      isDndHovered,
      isDndDroppable,
      isDisabled = false,
      isLink,
      children,
      dndHoverText,
      role,
      ...props
    },
    ref,
  ) => {
    return (
      <TileRoot
        $isDisabled={isDisabled}
        $isDragging={isDragging}
        $isVisible={isVisible}
        aria-selected={role == 'gridcell' ? isSelected : undefined}
        ref={ref}
        role={role}
        {...props}
      >
        {dndHoverText && isDndDroppable && isDndHovered && (
          <div>
            <TileDropOverlay isFolder={true}>{dndHoverText}</TileDropOverlay>
          </div>
        )}
        <Card hasBorders={false} isLink={isLink}>
          {children}
        </Card>
      </TileRoot>
    );
  },
);

export interface SuggestedTileProps extends HTMLAttributes<HTMLDivElement> {}

export const SuggestedTile = ({children, ...props}: SuggestedTileProps) => {
  return (
    <SuggestedRoot {...props}>
      <Card hasBorders={false}>{children}</Card>
    </SuggestedRoot>
  );
};

export interface TilePosterProps extends BoxProps<any> {
  ratio?: CardMediaProps['ratio'];
  children: ReactNode;
}

export const TilePoster = ({children, ...boxProps}: TilePosterProps) => (
  <Card.Media allowOverflow={true} hasBottomBorder={false} ratio={16 / 9}>
    <Box {...boxProps}>{children}</Box>
  </Card.Media>
);

export interface TileContentProps {
  children: ReactNode;
  backgroundColor?: CardContentProps['backgroundColor'];
  className?: string;
}
export const TileContentBase = ({children, className, backgroundColor}: TileContentProps) => (
  <Card.Content backgroundColor={backgroundColor} className={className} spacing="small">
    {children}
  </Card.Content>
);

export interface TileHeaderRootProps extends TileContentProps {
  $centerContents?: boolean;
  $background?: string;
}

const TileHeaderRoot = styled(TileContentBase)<TileHeaderRootProps>`
  height: calc(${spacing('Macro Large')} * 2);

  @media (hover: none) {
    // Dodge the checkbox and context menu
    padding-top: ${spacing('Macro Large')};
  }

  ${breakpointSmall(css`
    height: calc(${spacing('Macro XLarge')} * 2);
  `)}

  ${TileRoot}:hover & {
    opacity: 0.5;
  }

  ${ifProp(
    '$background',
    ({$background}) =>
      css`
        background: ${$background};
      `,
    css`
      background-color: ${color('Background Raised')};
    `,
  )}

  ${({$centerContents}) =>
    $centerContents
      ? css`
          display: flex;
          justify-content: center;
          align-items: center;
        `
      : css`
          display: flex;
          align-items: center;
          gap: ${spacing('Micro Small')};
        `}

  ${breakpointSmall(
    ifNotProp(
      '$background',
      css`
        background-color: ${color('Opacity Surface')};
      `,
    ),
  )}
`;

export interface TileHeaderProps extends Omit<TileHeaderRootProps, '$background'> {
  theme?: BrandingTheme;
  children: ReactNode;
}

export const TileHeader = ({theme, children, ...props}: TileHeaderProps) => {
  const backgroundColor = backgroundColorFromTheme(theme);
  const mode = getContrastThemeMode(backgroundColor);

  return (
    <ThemeProvider mode={mode} overrides="reset" theme="vis2023">
      <ThemeContainer>
        <TileHeaderRoot $background={backgroundColor} {...props}>
          {children}
        </TileHeaderRoot>
      </ThemeContainer>
    </ThemeProvider>
  );
};

export const TileContent = styled(TileContentBase)`
  ${bottomStyles}
`;

export const TileFooter = styled(TileContentBase)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: ${TILE_FOOTER_MIN_HEIGHT};
  ${bottomStyles}
`;

export const TileContentTitle = styled(Title)`
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;

  font-weight: ${fontWeight('Strong')};

  font-size: ${fontSize('Text Small')};
  line-height: ${lineHeight('Text Small')};

  ${breakpointSmall(css`
    font-size: ${fontSize('Title Small')};
    line-height: ${lineHeight('Text Large')};
  `)}
`;

interface ClampTextProps {
  $color?: Color;
}

// https://css-tricks.com/line-clampin/#the-standardized-way
const ClampTitle = styled(Title)<ClampTextProps>`
  ${({$color}) => ($color ? `color: ${color($color)}` : '')};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: anywhere;
  margin: 0;

  line-height: ${lineHeight('Text Small')};
  font-weight: ${fontWeight('Strong')};

  ${breakpointSmall(css`
    line-height: ${lineHeight('Text Medium')};
    font-weight: ${fontWeight('Base')};
  `)}
`;

interface TileTitleProps {
  children: string;
  color?: Color;
}
export const TileTitle = ({color, children}: TileTitleProps) => (
  <ClampTitle $color={color} size="small">
    {children}
  </ClampTitle>
);
