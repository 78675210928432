export enum ItemType {
  VERSION,
  FOLDER,
  PROJECT,
  TEAM_PROJECT,
  BULK,
  VIDEO,
}

export const MAX_FOLDER_DEPTH = 12;
export const COMMENT_CONTAINER_OFFSET_PX = 128;
export const COMMENT_CONTAINER_OFFSET_WITH_QUICK_REACTIONS_PX = 180;
export const COMMENT_FILTER_HEIGHT = 40;
export const COMMENT_TAB_FILTER_HEIGHT = 42;
export const COMMENT_PROMPT_BANNER_HEIGHT = 40;
export const COMMENT_HEADER_HEIGHT_COLLAPSED = 56;
export const COMMENT_HEADER_HEIGHT = COMMENT_HEADER_HEIGHT_COLLAPSED + COMMENT_TAB_FILTER_HEIGHT;
export const TRANSCRIPT_HEADER_HEIGHT = 64;
export const TRANSCRIPT_SEARCHBAR_HEIGHT = 38;
export const PAGE_HEADER_HEIGHT = 68;
export const OFFSET_TOP_TO_SEARCH_RESULTS =
  TRANSCRIPT_HEADER_HEIGHT + TRANSCRIPT_SEARCHBAR_HEIGHT + PAGE_HEADER_HEIGHT;
export const IMAGE_VIEWER_TOP_CONTAINER_HEIGHT = 32;
export const SMALLSCREEN_COMMENT_CONTAINER = 94;
export const EXCLUDED_FILES = ['.DS_Store'];
export const IMAGE_VIEW_DUEDATE_BANNER_HEIGHT_DUEDATE_BANNER_HEIGHT = 59;
export const MAX_DOCUMENT_UPLOAD_SIZE = 41943040; // 40 MB

export const NEW_BADGE_DISMISSAL_DELAY = 500;

export const displayNameForParticipant = (participantName: string) => {
  const nameParts = participantName.trim().split(' ');
  const firstName = nameParts[0];
  const secondNameInitial = nameParts.length > 1 ? nameParts[1][0].toUpperCase() : null;
  return secondNameInitial ? `${firstName} ${secondNameInitial}` : firstName;
};
