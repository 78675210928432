import {Controller, useFormContext} from 'react-hook-form';
import type {ChangeHandler} from 'react-hook-form';

// eslint-disable-next-line no-restricted-imports
import type {SelectProps as DigSelectProps} from '@dropbox/dig-components/dist/text_fields/select/types';
import {Select as DigSelect} from '@dropbox/dig-components/text_fields';

export * from '@dropbox/dig-components/text_fields';

interface SelectProps extends Omit<DigSelectProps, 'onChange'> {
  name: string;
  onChange?: ChangeHandler;
}

export const Select = ({name, ...props}: SelectProps) => {
  const {control} = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={({field: {ref, ...field}}) => {
        // Select doesn't support forwarding refs
        return <DigSelect ref={ref} {...props} {...field} />;
      }}
    />
  );
};

Select.Option = DigSelect.Option;
