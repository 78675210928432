import React from 'react';

import {FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';

import {Modal} from '@dropbox/dig-components/modal';
import {Text, Title} from '@dropbox/dig-components/typography';

import {Button} from '~/components/button';
import {MAX_FOLDER_DEPTH} from '~/components/common';

type MoveFailModalProps = {
  open: boolean;
  destinationName: string;
  requestClose: () => void;
};

const ModalBody = styled(Modal.Body)`
  padding-bottom: 24px;
`;

const ModalBodyTitle = styled.p`
  margin: 0 0 21px 0;
`;

const ModalButtons = styled.div`
  display: flex;
`;

const ExitButton = styled.div`
  margin-left: auto;
`;

export const MoveFailModal = ({destinationName, open, requestClose}: MoveFailModalProps) => {
  const intl = useIntl();

  const messages = {
    close: intl.formatMessage({
      defaultMessage: 'Close modal',
      id: 'i6kBaf',
      description:
        'Text that indicates what the button that closes the project move failure modal does.',
    }),
    warning: intl.formatMessage(
      {
        defaultMessage:
          'Moving this folder into <text>{destinationName}</text> would create nested folders more than {maxFolderDepth} {maxFolderDepth, plural, one{level} other{levels}} deep, which isn’t supported.',
        id: 'PeRzBX',
        description:
          'Text that describes to user the reason why the project move failed, namely that it was nested too deeply.',
      },
      {
        text: (txt: React.ReactNode) => <Text isBold>{txt}</Text>,
        destinationName,
        maxFolderDepth: MAX_FOLDER_DEPTH,
      },
    ),
  };

  return (
    <Modal isCentered onRequestClose={requestClose} open={open} withCloseButton={messages.close}>
      <Modal.Header hasBottomSpacing="title-small">
        <Title isBold size="small">
          <FormattedMessage
            defaultMessage="Oops! This folder cannot be moved"
            description="Text on the project move failure modal that tells the user that process of moving the folder failed."
            id="C3Lhfl"
          />
        </Title>
      </Modal.Header>
      <ModalBody>
        <ModalBodyTitle>
          <Text>
            <Text>{messages.warning}</Text>
          </Text>
        </ModalBodyTitle>
        <ModalButtons>
          <ExitButton>
            <Button onClick={requestClose} size="standard" variant="primary">
              <FormattedMessage
                defaultMessage="Got it"
                description="Text on a button that when clicked accepts the failure of moving a project and closes modal."
                id="F0PJ3Z"
              />
            </Button>
          </ExitButton>
        </ModalButtons>
      </ModalBody>
    </Modal>
  );
};
