import {Suspense} from 'react';

import {lazy} from '~/lib/lazy';

import type {SessionContextValue} from './context';

const AsyncPostPurchaseModal = lazy(() =>
  import(/* webpackChunkName: 'post_purchase_modal' */ './components/post_purchase_modal').then(
    (exps) => ({default: exps.PostPurchaseModal}),
  ),
);

type PurchaseFlowModalsProps = {
  sessionState: SessionContextValue;
};

export const PurchaseFlowModals = (props: PurchaseFlowModalsProps) => {
  const {sessionState} = props;
  const isLoggedOut = sessionState.status === 'logged out';

  if (isLoggedOut) {
    return null;
  }

  return (
    <Suspense fallback={null}>
      <AsyncPostPurchaseModal {...sessionState}></AsyncPostPurchaseModal>
    </Suspense>
  );
};
