/* eslint-disable deprecation/deprecation */
import React from 'react';

import {useAtomValue} from 'jotai';
import {FormattedMessage} from 'react-intl';
import {CSSTransition} from 'react-transition-group';
import styled from 'styled-components';

import {Text} from '@dropbox/dig-components/typography';

import type {VersionStatus} from '~/components/status/status';
import type {LoggingClient} from '~/lib/logging/logger';
import {useArchiveToDropboxEnabled, useHasFullAddonAccess} from '~/lib/utils';
import {BulkArchiveToDropboxButton} from '~/pages/browse_page/components/bulk_archive_to_dropbox_button';
import {BulkCopyToProjectButton} from '~/pages/browse_page/components/bulk_copy_to_project_button';
import {BulkDeleteButton} from '~/pages/browse_page/components/bulk_delete_button';
import {BulkHideButton} from '~/pages/browse_page/components/bulk_hide_button';
import {BulkLeaveTeamProjectButton} from '~/pages/browse_page/components/bulk_leave_team_project_button';
import {BulkMoveToFolderButton} from '~/pages/browse_page/components/bulk_move_to_folder_button';
import {BulkMoveToProjectButton} from '~/pages/browse_page/components/bulk_move_to_project_button';
import {BulkUpdateStatusButton} from '~/pages/browse_page/components/bulk_update_status_button';
import {CompareVersionsButton} from '~/pages/browse_page/components/compare_versions_button';
import {BulkDownloadFilesButtonAndModal} from '~/pages/browse_page/components/download/bulk_download_files_button_and_modal';
import {BulkDownloadFolderButtonAndModal} from '~/pages/browse_page/components/download/bulk_download_folder_button_and_modal';
import {itemsSelectedCountAtom, noItemsSelectedAtom} from '~/state/browse';
import {availableBulkActionsAtom, BulkAction} from '~/state/bulk_actions';

import {
  SMALL_SCREEN_WIDTH_BREAKPOINT,
  TRANSITION_DURATION_MS,
  useViewport,
} from '../../../components/viewport_context';

type BulkActionsRowProps = {
  onUpdateStatusButtonClick: (newStatus: VersionStatus) => void;
  onCompareVersionsButtonClick: () => void;
  onCopyToProjectButtonClick: () => void;
  onDeleteButtonClick: () => void;
  onHideButtonClick: () => void;
  onLeaveTeamProjectButtonClick: () => void;
  onMoveToFolderButtonClick: () => void;
  onMoveToProjectButtonClick: () => void;
  onArchiveToDropboxButtonClick: () => void;
  isSharedView: boolean;
  shouldShowVersionComparison: boolean;
  logEvent: LoggingClient['logEvent'];
};

const BulkActionsRowWrapper = styled.div`
  align-content: center;
  align-items: flex-start;
  display: flex;
  height: 0px;
  opacity: 0;

  &.enter {
    height: 0px;
    opacity: 0;
  }
  &.enter-active {
    height: 48px;
    @media (max-width: ${SMALL_SCREEN_WIDTH_BREAKPOINT}px) {
      height: 40px;
    }
    opacity: 1;
    transition-duration: ${TRANSITION_DURATION_MS}ms;
  }
  &.enter-done {
    height: 48px;
    @media (max-width: ${SMALL_SCREEN_WIDTH_BREAKPOINT}px) {
      height: 40px;
    }
    opacity: 1;
  }
  &.exit {
    height: 48px;
    @media (max-width: ${SMALL_SCREEN_WIDTH_BREAKPOINT}px) {
      height: 40px;
    }
    opacity: 1;
  }
  &.exit-active {
    height: 0px;
    opacity: 0;
    transition-duration: ${TRANSITION_DURATION_MS}ms;
  }
  &.exit-done {
    height: 0px;
    opacity: 0;
  }
`;

const BulkActionsCol = styled.div`
  display: flex;
  column-gap: 8px;
  box-sizing: border-box;
  flex-grow: 1;
  min-width: 0;
  padding-right: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const MobileActionsBorder = styled.div`
  height: 15px;
  width: 100%;
  border-top: 1px solid var(--dig-color__border__base);
`;

export const BulkActionsRow = ({
  onCompareVersionsButtonClick,
  onCopyToProjectButtonClick,
  onDeleteButtonClick,
  onHideButtonClick,
  onLeaveTeamProjectButtonClick,
  onMoveToFolderButtonClick,
  onMoveToProjectButtonClick,
  onUpdateStatusButtonClick,
  onArchiveToDropboxButtonClick,
  shouldShowVersionComparison,
  logEvent,
}: BulkActionsRowProps) => {
  const noItemsSelected = useAtomValue(noItemsSelectedAtom);
  const availableBulkActions = useAtomValue(availableBulkActionsAtom);
  const isVersionComparisonProvisioned = useHasFullAddonAccess();

  const {isSmallScreenSize, isMobileDevice} = useViewport();
  const numItemsSelected = useAtomValue(itemsSelectedCountAtom);

  const showDownloadFolderButton = React.useMemo(() => {
    return availableBulkActions.includes(BulkAction.FOLDER_DOWNLOAD) && numItemsSelected === 1; // We aren't supporting multi-folder downloads at this time
  }, [availableBulkActions, numItemsSelected]);
  const showDownloadAssetsButton = React.useMemo(() => {
    return availableBulkActions.includes(BulkAction.ASSET_DOWNLOAD);
  }, [availableBulkActions]);

  const shouldShowVersionComparisonButton =
    isVersionComparisonProvisioned && !isMobileDevice && shouldShowVersionComparison;

  return (
    <>
      {isSmallScreenSize && (
        <CSSTransition in={!noItemsSelected} timeout={TRANSITION_DURATION_MS} unmountOnExit>
          <MobileActionsBorder />
        </CSSTransition>
      )}
      <CSSTransition in={!noItemsSelected} timeout={TRANSITION_DURATION_MS} unmountOnExit>
        <BulkActionsRowWrapper data-testid="bulk-actions-row">
          <BulkActionsCol>
            {showDownloadFolderButton && <BulkDownloadFolderButtonAndModal />}
            {showDownloadAssetsButton && <BulkDownloadFilesButtonAndModal />}
            {availableBulkActions.includes(BulkAction.LEAVE_TEAM_PROJECT) && (
              <BulkLeaveTeamProjectButton onClick={onLeaveTeamProjectButtonClick} />
            )}
            {availableBulkActions.includes(BulkAction.MOVE_TO_FOLDER) && (
              <BulkMoveToFolderButton onClick={onMoveToFolderButtonClick} />
            )}
            {availableBulkActions.includes(BulkAction.MOVE_TO_PROJECT) && (
              <BulkMoveToProjectButton onClick={onMoveToProjectButtonClick} />
            )}
            {availableBulkActions.includes(BulkAction.COPY_TO_PROJECT) && (
              <BulkCopyToProjectButton onClick={onCopyToProjectButtonClick} />
            )}
            {shouldShowVersionComparisonButton &&
              availableBulkActions.includes(BulkAction.COMPARE_VERSIONS) && (
                <CompareVersionsButton onClick={onCompareVersionsButtonClick} />
              )}
            {availableBulkActions.includes(BulkAction.UPDATE_VERSION_STATUS) && (
              <BulkUpdateStatusButton onClick={onUpdateStatusButtonClick} />
            )}
            {availableBulkActions.includes(BulkAction.DELETE) && (
              <BulkDeleteButton onClick={onDeleteButtonClick} />
            )}
            {availableBulkActions.includes(BulkAction.HIDE) && (
              <BulkHideButton onClick={onHideButtonClick} />
            )}
            {useArchiveToDropboxEnabled() &&
              availableBulkActions.includes(BulkAction.ARCHIVE_TO_DROPBOX) && (
                <BulkArchiveToDropboxButton
                  logEvent={logEvent}
                  onClick={onArchiveToDropboxButtonClick}
                />
              )}
            {!noItemsSelected && availableBulkActions.length === 0 && (
              <Text>
                <FormattedMessage
                  defaultMessage="No actions available for selected items"
                  description="Text that is shown in bulk actions row to alert user that the selected items on the browse page have no matching actions."
                  id="xPfaGN"
                />
              </Text>
            )}
          </BulkActionsCol>
        </BulkActionsRowWrapper>
      </CSSTransition>
    </>
  );
};
