import React from 'react';

import type {AccessType} from 'pap-events/enums/access_type';

import type {ShareFolderAccessType} from '~/lib/api';
import {useIsCurrentPageShareFolderPage} from '~/route_path_matches';

export const useGetPapAccessType = (props: {
  accessLevel: ShareFolderAccessType | null;
}): AccessType => {
  const {accessLevel} = props;
  const isCurrentPageShareFolderPage = useIsCurrentPageShareFolderPage();
  return React.useMemo(() => {
    // user is viewing a link to a shared folder
    if (accessLevel === 'none' && isCurrentPageShareFolderPage) {
      return 'other';
    }
    // user is viewing their own folder, or its children, but it is not a shared folder
    if (accessLevel === 'none') {
      return 'owner';
    }
    // user is viewing a shared folder, either their own or someone else's
    // only now can we trust the accessLevel
    if (
      accessLevel === 'admin' ||
      accessLevel === 'reviewer' ||
      accessLevel === 'owner' ||
      accessLevel === 'other'
    ) {
      return accessLevel;
    }
    // default to 'other' as shared folder access types aren't
    // a 1:1 match to PAP Access Types
    return 'other';
  }, [accessLevel, isCurrentPageShareFolderPage]);
};
