import type {reel, users} from '@dropbox/api-v2-client';

import type {Folder} from '../../lib/api';
import {getDefaultUserClient} from '../../lib/client';
import type {FolderToAccounts} from '../browse_page/use_share_recipients';

export enum BrowseTeamInfoType {
  User = 'user',
  TeamProject = 'teamproject',
}

export type UserItem = {
  type: BrowseTeamInfoType.User;
  userId?: string;
  userAccountId?: string;
  userPublicId?: string;
  folderName: string;
  folderCount: number;
  updatedTime?: Date;
  createdTime?: Date;
  entityUrl: string;
  fetchingThumbnail: boolean;
  // Added post these new designs:
  // https://www.figma.com/file/6BbaH68gsyh8WHsJE2Y9eQ/Counting-files-against-FSS-storage?type=design&node-id=2067-36790&mode=design&t=53RM3pKYEX2tQJoO-0
  teamQuotaUsage?: number;
};

export type FolderItem = {
  type: BrowseTeamInfoType.TeamProject;
  folderName: string;
  folderCount: number;
  updatedTime?: Date;
  createdTime?: Date;
  entityUrl: string;
  fetchingThumbnail: boolean;
  rawFolder: Folder;
  // Added post these new designs:
  // https://www.figma.com/file/6BbaH68gsyh8WHsJE2Y9eQ/Counting-files-against-FSS-storage?type=design&node-id=2067-36790&mode=design&t=53RM3pKYEX2tQJoO-0
  teamQuotaUsage?: number;
  folderOwner?: string;
};

export type BrowseTeamInfoItem = UserItem | FolderItem;

export const mapListAllFoldersResponse = (
  currentUserAccountId: string,
  data: reel.ListAllFoldersForTeamResult,
) => {
  const mappedTeamFolderData: BrowseTeamInfoItem[] = [];
  if (data.team_members) {
    for (const member of data.team_members) {
      if (member.user_account_id === currentUserAccountId) {
        continue;
      }
      const userItem: UserItem = {
        type: BrowseTeamInfoType.User,
        userId: member.user_id,
        userAccountId: member.user_account_id,
        userPublicId: member.user_public_id,
        folderName: member.user_name ?? '',
        folderCount: member.num_projects ?? 0,
        updatedTime: member.latest_update_timestamp
          ? new Date(member.latest_update_timestamp)
          : undefined,
        createdTime: member.creation_timestamp ? new Date(member.creation_timestamp) : undefined,
        entityUrl: '',
        fetchingThumbnail: false,
        teamQuotaUsage: member.user_replay_quota_usage,
      };
      mappedTeamFolderData.push(userItem);
    }
  }

  if (data.team_projects) {
    for (const project of data.team_projects) {
      const folderItem: FolderItem = {
        type: BrowseTeamInfoType.TeamProject,
        folderName: project.name ?? '',
        folderCount: project.num_projects ?? 0,
        updatedTime: project.latest_update_timestamp
          ? new Date(project.latest_update_timestamp)
          : undefined,
        createdTime: project.creation_timestamp ? new Date(project.creation_timestamp) : undefined,
        entityUrl: '',
        fetchingThumbnail: false,
        rawFolder: project,
        // TODO(@jlarkin_dbx): this will fail until we get the api-v2-client updated
        folderOwner: project.folder_owner,
        teamQuotaUsage: project.folder_quota_usage,
      };
      mappedTeamFolderData.push(folderItem);
    }
  }

  return mappedTeamFolderData;
};

export const getAdminShareRecipients = async (
  data: reel.ListAllFoldersForTeamResult,
): Promise<FolderToAccounts> => {
  const adminShareRecipients: FolderToAccounts = {};
  const userFolderMap: {
    [folderId: string]: {[accountId: string]: reel.SharedFolderAccessLevel};
  } = {};
  const groupFolderMap: {
    [folderId: string]: {[groupId: string]: reel.SharedFolderAccessLevel};
  } = {};
  const userRecipients = new Set<string>();
  const groupRecipients = new Set<string>();

  const folderMembersById: Record<string, reel.ShareRecipientInfo[]> =
    data.team_projects?.reduce<Record<string, reel.ShareRecipientInfo[]>>((acc, folder) => {
      acc[folder.id] = acc[folder.id] || [];
      if (folder.folder_members) {
        acc[folder.id] = [...acc[folder.id], ...folder.folder_members];
      }
      return acc;
    }, {}) || {};

  const processFolderMembers = async (folder: reel.Folder) => {
    if (!folder.folder_members) return;

    Object.entries(folderMembersById).forEach(([folderId, folderMembers]) => {
      userFolderMap[folderId] = {};
      adminShareRecipients[folderId] = {
        userAccounts: {},
        groupAccounts: {},
      };
      folderMembers.forEach(function (folderMembers) {
        if (folderMembers.share_recipient?.recipient_id?.['.tag'] === 'account_id') {
          const accountId = folderMembers.share_recipient.recipient_id.account_id;
          userRecipients.add(accountId);
          userFolderMap[folderId][accountId] = folderMembers.access_level!;
        } else if (folderMembers.share_recipient?.recipient_id?.['.tag'] === 'group_id') {
          const groupId = folderMembers.share_recipient.recipient_id.group_id;
          const groupInfo = folderMembers.share_recipient.group_info!;
          groupRecipients.add(groupId);
          groupFolderMap[folderId][groupId] = folderMembers.access_level!;
          adminShareRecipients[folderId].groupAccounts[groupId] = {
            groupId: groupInfo.group_id ?? '',
            displayName: groupInfo.group_name ?? 'unknown',
            memberCount: groupInfo.group_size?.toString() ?? '',
            accessLevel: folderMembers.access_level!['.tag'],
            amplitudeSafeTeamId: groupInfo.team_id_for_amplitude?.toString() ?? '',
          };
        }
      });
    });
  };

  if (data.team_projects) {
    for (const project of data.team_projects) {
      processFolderMembers(project);
    }
  }

  if (userRecipients.size) {
    await getDefaultUserClient()
      .usersGetAccountBatch({
        account_ids: Array.from(userRecipients.values()),
      })
      .then((userInfos) => {
        const userInfoMap: Record<string, users.BasicAccount> = Object.assign(
          {},
          ...userInfos.result.map((s) => ({[s.account_id]: s})),
        );

        for (const [folderId, accessDict] of Object.entries(userFolderMap)) {
          adminShareRecipients[folderId].userAccounts = {};
          for (const [accountId, accessLevel] of Object.entries(accessDict)) {
            const userInfo = userInfoMap[accountId];
            adminShareRecipients[folderId].userAccounts[accountId] = {
              accountId: userInfo.account_id,
              profilePhotoUrl: userInfo.profile_photo_url,
              abbreviatedName: userInfo.name.abbreviated_name,
              displayName: userInfo.name.display_name,
              emailAddress: userInfo.email,
              accessLevel: accessLevel['.tag'],
            };
          }
        }
      });
  }

  return adminShareRecipients;
};
