import React from 'react';

import type {BrowseLocationType, ReelObjectType} from '~/lib/logging/logger_types';
import {SurfaceType} from '~/lib/logging/logger_types';

import {reportBadContextUseError} from '../../lib/error_reporting';
import type {LoggingClient} from '../../lib/logging/logger';
import {useLoggingClient} from '../../lib/use_logging_client';

interface LogEventProps {
  videoId?: string;
  projectId?: string;
  reelObjectType?: ReelObjectType;
}

type LogEventMaker = (props?: LogEventProps | null) => LoggingClient['logEvent'];

const BrowseLoggingContext = React.createContext<LogEventMaker | null>(null);

export const BrowseLoggingProvider = (
  props: React.PropsWithChildren<{location: BrowseLocationType}>,
) => {
  const loggingClient = useLoggingClient({
    surface: SurfaceType.Browse,
    location: props.location,
  });

  const makeLogEvent: LogEventMaker = React.useCallback(
    (makeLogEventProps) => {
      return (...args) => {
        loggingClient.setActiveVideoId(makeLogEventProps ? makeLogEventProps.videoId ?? '' : '');
        loggingClient.setActiveProjectId(
          makeLogEventProps ? makeLogEventProps.projectId ?? '' : '',
        );
        loggingClient.setReelObjectType(makeLogEventProps?.reelObjectType ?? null);
        // eslint-disable-next-line deprecation/deprecation
        loggingClient.logEvent(...args);
      };
    },
    [loggingClient],
  );

  return (
    <BrowseLoggingContext.Provider value={makeLogEvent}>
      {props.children}
    </BrowseLoggingContext.Provider>
  );
};

export const useBrowseLogEventMaker = () => {
  const makeLogEvent = React.useContext(BrowseLoggingContext);
  if (makeLogEvent === null) {
    const error = new Error('useBrowseLogEventMaker must be used within a BrowseLoggingProvider');
    reportBadContextUseError(error);
    throw error;
  }
  return makeLogEvent;
};
