import Color from 'color';

import type {ThemeProviderProps} from '@dropbox/dig-foundations';

export const ANNOTATION_COLORS: {[key: string]: string} = {
  Black: '#000000',
  White: '#FFFFFF',
  Sunset: '#FA551E',
  Tangerine: '#FF8C19',
  Lime: '#B4DC19',
  Zen: '#14C8EB',
  Cloud: '#B4C8E1',
  Orchid: '#C8AFF0',
  Pink: '#FFAFA5',
  Banana: '#FAD24B',
  Crimson: '#9B0032',
  Rust: '#BE4B0A',
  Canopy: '#0F503C',
  Ocean: '#007891',
  Navy: '#283750',
  Plum: '#78286E',
  Azalea: '#CD2F7B',
  Gold: '#9B6400',
};

export type ANNOTATION_COLOR = (typeof ANNOTATION_COLORS)[keyof typeof ANNOTATION_COLORS];

export const AVATAR_COLORS: {[key: string]: string} = {
  Lime: '#B4DC19',
  Zen: '#14C8EB',
  Cloud: '#B4C8E1',
  Orchid: '#C8AFF0',
  Pink: '#FFAFA5',
};

const accessibleColorPalette = Object.values(AVATAR_COLORS);

export const hexToNameMap: {[key: string]: string} = {
  '#000000': 'Black',
  '#FFFFFF': 'White',
  '#fa551e': 'Sunset',
  '#FF8C19': 'Tangerine',
  '#B4DC19': 'Lime',
  '#14C8EB': 'Zen',
  '#B4C8E1': 'Cloud',
  '#C8AFF0': 'Orchid',
  '#FFAFA5': 'Pink',
  '#FAD24B': 'Banana',
  '#9B0032': 'Crimson',
  '#BE4B0A': 'Rust',
  '#0F503C': 'Canopy',
  '#007891': 'Ocean',
  '#283750': 'Navy',
  '#78286E': 'Plum',
  '#CD2F7B': 'Azalea',
  '#9B6400': 'Gold',
};

/*
  Accent color pairings and light text assignment are based on the 'Colors > Accent' panel at:
  https://dig.pp.dropbox.com/library/tokens/colors/design
  Tokens for these accent colors aren't available yet but will be created in DSYS-1727.
*/

// Background colors that require light font color
export const lightTextColors = [
  '#007891',
  '#9B0032',
  '#BE4B0A',
  '#0F503C',
  '#283750',
  '#78286E',
  '#CD2F7B',
  '#9B6400',
];

export const inverseColorMap: {[key: string]: string} = {
  Zen: 'Ocean',
  Ocean: 'Zen',
  Sunset: 'Crimson',
  Crimson: 'Sunset',
  Tangerine: 'Rust',
  Rust: 'Tangerine',
  Lime: 'Canopy',
  Canopy: 'Lime',
  Cloud: 'Navy',
  Navy: 'Cloud',
  Orchid: 'Plum',
  Plum: 'Orchid',
  Pink: 'Azalea',
  Azalea: 'Pink',
  Banana: 'Gold',
  Gold: 'Banana',
};

export function getInverseColor(originalColorHex: string) {
  const originalColorName = hexToNameMap[originalColorHex];
  const inverseColorName = inverseColorMap[originalColorName];
  return ANNOTATION_COLORS[inverseColorName];
}

/*
  Given a display name or member id, this function generates a color for the avatar.
  This is used as the avatar color in the facepile for users without a profile picture.
*/
export function getDrawingColorForUser(inputString: string) {
  // We think 5381 comes from the djb2 hash function (see http://www.cse.yorku.ca/~oz/hash.html).
  // This code originated with allen@dropbox.com.
  const HASH_MULTIPLIER = 5381;
  let hash = HASH_MULTIPLIER * inputString.length;
  for (let i = 0; i < inputString.length; i++) {
    hash = inputString.charCodeAt(i) + ((hash << 6) + (hash << 16) - hash);
  }
  hash = Math.abs(hash);
  return accessibleColorPalette[hash % accessibleColorPalette.length];
}

const brightTextSubtle = Color('#736c64');
const darkTextSubtle = Color('#bbb5ae');

export const getContrastThemeMode = (color?: string) => {
  let mode: ThemeProviderProps['mode'] = 'dark';
  if (!color) return mode;

  try {
    const background = Color(color);

    // We're comparing against text-subtle as that is the most likely color element
    // to have contrast issues.
    if (background.contrast(brightTextSubtle) > background.contrast(darkTextSubtle)) {
      mode = 'bright';
    }
  } catch {
    /* failed to parse color, default to dark */
  }
  return mode;
};
