import styled from 'styled-components';

export const PrimaryCol = styled.div`
  box-sizing: border-box;
  flex: 1 0 250px;
  width: 250px;
  min-width: 0;
  padding-right: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StatusCol = styled.div`
  flex: 0 1 230px;
  min-width: 180px;
  width: 180px;
  /* The last updated column disappears last */
  @media (max-width: 540px) {
    display: none;
  }
`;

export const UsageCol = styled.div`
  flex: 0 1 230px;
  min-width: 180px;
  width: 180px;
  /* The last updated column disappears last */
  @media (max-width: 540px) {
    display: none;
  }
`;

export const UpdatedTimeCol = styled.div`
  flex: 0 1 210px;
  min-width: 150px;
  width: 150px;
  /* The last updated column disappears third */
  @media (max-width: 876px) {
    display: none;
  }
`;

export const CreatedTimeCol = styled.div`
  flex: 0 1 210px;
  min-width: 150px;
  width: 150px;
  /* The uploaded column disappears first */
  /* Add up all the column widths and the padding and you get 898 */
  @media (max-width: 1176px) {
    display: none;
  }
`;

export const CommentsInfoCol = styled.div`
  flex: 0 1 210px;
  align-items: center;
  color: var(--dig-color__text__base);
  display: flex;
  width: 140px;
  min-width: 140px;
  /* The comments info column disappears second */
  @media (max-width: 996px) {
    display: none;
  }
`;

export const OverflowMenuCol = styled.div`
  display: flex;
  flex: 1 0 66px;
  width: 66px;
  min-width: 0;
  max-width: 90px;
  justify-content: flex-end;
  position: relative;
`;
