import React from 'react';

import {reportBadContextUseError} from '../lib/error_reporting';
import type {LoggingClient} from '../lib/logging/logger';
import type {SendMessageType} from '../use_extensions';
import {type ActiveSequenceData, type RedCarpet, useExtensions} from '../use_extensions';

export type ExtensionContext = {
  loggingClient: LoggingClient;
  redCarpet: RedCarpet;
  sendMessage: null | (<T = any>(messageName: SendMessageType, params?: any) => Promise<T>);
  activeSequenceData: ActiveSequenceData;
  loadActiveSequenceData: (redirect?: boolean) => void;
};

const ExtensionContext = React.createContext<ExtensionContext | null>(null);

export const ExtensionProvider = (props: React.PropsWithChildren<{}>) => {
  const {redCarpet, sendMessage, loggingClient, activeSequenceData, loadActiveSequenceData} =
    useExtensions();

  const value = React.useMemo<ExtensionContext>(
    () => ({
      redCarpet,
      loggingClient,
      activeSequenceData,
      loadActiveSequenceData,
      sendMessage,
    }),
    [activeSequenceData, loadActiveSequenceData, loggingClient, redCarpet, sendMessage],
  );

  return <ExtensionContext.Provider value={value}>{props.children}</ExtensionContext.Provider>;
};

export const useExtensionContext = () => {
  const extensionContext = React.useContext(ExtensionContext);

  if (extensionContext === null) {
    const error = new Error('useExtensionContext must be used within an ExtensionProvider');
    reportBadContextUseError(error);
    throw error;
  }

  return extensionContext;
};
