import React, {createContext, useCallback, useContext, useMemo, useRef, useState} from 'react';

import {useQueryClient} from '@tanstack/react-query';
import {PAP_Select_OnedriveImport} from 'pap-events/replay/select_onedrive_import';
import {FormattedMessage} from 'react-intl';

import {Snackbar} from '@dropbox/dig-components/snackbar';
import {Text} from '@dropbox/dig-components/typography';

import {Button} from '~/components/button';
import {useGetDocumentUploadSizeExceededErrorText} from '~/lib/uploads/uploads';

import {MAX_DOCUMENT_UPLOAD_SIZE} from './common';
import {OneDriveTokenModal} from './onedrive_token_modal';
import {OneDriveUnlinkModal} from './onedrive_unlink_modal';
import {ReelSnackbar, SnackbarCloseMessage} from './snackbar';
import {useErrorSnackbar} from './snackbar/error_snackbar';
import {useUploadDrawerUploadsAndProgress} from './upload_drawer/use_upload_drawer_uploads_and_progress';
import type {EmptyProjectsResponse} from '../lib/api';
import {
  addToProject,
  createEmptyProjects,
  EmptyProjectCreationStatus,
  getOneDriveAccountId,
  OneDriveImportStatus,
  queryOneDriveImportStatus,
  startOneDriveFileImport,
} from '../lib/api';
import {reportBadContextUseError, reportOneDriveError} from '../lib/error_reporting';
import {useValidFileExtensionsList, VALID_CAPTIONS_FILE_EXTENSIONS} from '../lib/helpers';
import type {LoggingClient} from '../lib/logging/logger';
import type {AddMediaClickSourceType} from '../lib/logging/logger_types';
import {legacyPickFile} from '../lib/onedrive/legacy_file_picker';
import {replayApi} from '../lib/query_client';
import type {
  AllUploadsCompleteHandler,
  BasicFileInfo,
  MappedOneDriveUploads,
  OneDriveUpload,
  UploadsProgress,
} from '../lib/uploads/types';
import {FileUploadState, UploadTypes} from '../lib/uploads/types';
import {useLoggingClient} from '../lib/use_logging_client';
import {useOnedriveTokenFetch, useOnedriveTokenSubscribe} from '../state/onedrive_token';

type CreateVersionHandler = (
  fileId: string,
) => Promise<{projectId?: string; videoId?: string; videoVersionId?: string}>;
type FileErrorHandler = (upload?: OneDriveUpload) => void;
type FileCompleteHandler = (upload: OneDriveUpload, videoId: string) => void;

const StatusMap = {
  [OneDriveImportStatus.Completed]: FileUploadState.Uploading,
  [OneDriveImportStatus.Failed]: FileUploadState.Error,
  [OneDriveImportStatus.Running]: FileUploadState.Uploading,
};

export type OneDriveUploadProjectProps = {
  currentFolderId: string;
  clickSource: AddMediaClickSourceType;
  onFilePick: (files: BasicFileInfo[]) => void;
  onAllUploadsComplete: AllUploadsCompleteHandler;
  logEvent: LoggingClient['logEvent'];
};

export type OneDriveUploadVersionProps = {
  nsId: number;
  onCreateVersion: CreateVersionHandler;
  onUploadComplete?: FileCompleteHandler;
  onError?: FileErrorHandler;
  logEvent: LoggingClient['logEvent'];
};

export type OneDriveUploadCaptionProps = {
  nsId: number;
  onCaptionsUploaded: (fileId: string) => void;
  onError: () => void;
  logEvent: LoggingClient['logEvent'];
};

export type OneDriveContextValue = {
  startOneDriveProjectImport: (props: OneDriveUploadProjectProps) => void;
  startOneDriveVersionImport: (props: OneDriveUploadVersionProps) => void;
  startOneDriveCaptionImport: (props: OneDriveUploadCaptionProps) => void;
};

const OneDriveContext = createContext<OneDriveContextValue | null>(null);

type SnackbarProps = {
  open: boolean;
  onClose: () => void;
};

type UnlinkSnackbarProps = SnackbarProps & {isError: boolean};
export type UnlinkStatus = 'succeeded' | 'failed' | 'cancelled';

const TooManyfilesSnackbar = ({open, onClose}: SnackbarProps) => {
  return (
    <ReelSnackbar onRequestClose={onClose} open={open} timeout={5000}>
      <Snackbar.Message>
        <FormattedMessage
          defaultMessage="Please select only one file"
          description="Error message instructing the user to only choose one file for a version or caption upload"
          id="G4TrLk"
        />
      </Snackbar.Message>
      <Snackbar.Actions>
        <Button inverse onClick={onClose} variant="transparent">
          <SnackbarCloseMessage />
        </Button>
      </Snackbar.Actions>
    </ReelSnackbar>
  );
};

const UnlinkSnackbar = ({open, onClose, isError}: UnlinkSnackbarProps) => {
  return (
    <ReelSnackbar onRequestClose={onClose} open={open} timeout={5000}>
      <Snackbar.Message>
        <Text>
          {isError ? (
            <FormattedMessage
              defaultMessage="There was an issue unlinking your account. Please go to your Dropbox Account settings, and try relinking your OneDrive account there."
              description="Error message telling the user unlinking their OneDrive account failed."
              id="ctvYgE"
            />
          ) : (
            <FormattedMessage
              defaultMessage="Unlink complete. Try uploading again to relink your account."
              description="Message telling the user how to relink their OneDrive account."
              id="cMjwtR"
            />
          )}
        </Text>
      </Snackbar.Message>
      <Snackbar.Actions>
        <Button inverse onClick={onClose} variant="transparent">
          <SnackbarCloseMessage />
        </Button>
      </Snackbar.Actions>
    </ReelSnackbar>
  );
};

const buildViewLink = ({
  projectId,
  videoId,
  videoVersionId,
}: {
  projectId: string;
  videoId: string;
  videoVersionId?: string;
}) => {
  let link = `/project/${projectId}/video/${videoId}`;
  if (videoVersionId) {
    link += `?video_version_id=${videoVersionId}`;
  }
  return link;
};

export const OneDriveContextProvider = (props: React.PropsWithChildren<{}>) => {
  const [unlinkModalOpen, setUnlinkModalOpen] = useState<boolean>(false);
  const [unlinkFailed, setUnlinkFailed] = useState<boolean>(false);
  const [tokenModalOpen, setTokenModalOpen] = useState<boolean>(false);
  const [unlinkSnackbarOpen, setUnlinkSnackbarOpen] = useState<boolean>(false);
  const loggingClient = useLoggingClient();
  const [importIdMapping, setImportIdMapping] = useState<Record<string, string>>({});
  const [tooManyfilesSnakbarOpen, setTooManyFilesSnackbarOpen] = useState<boolean>(false);

  const validFileExtensions = useValidFileExtensionsList();
  const {openErrorSnackbar} = useErrorSnackbar();
  const documentSizeExceededError = useGetDocumentUploadSizeExceededErrorText();

  const queryClient = useQueryClient();
  const onedriveFetch = useOnedriveTokenFetch();
  const onedriveSubscribe = useOnedriveTokenSubscribe();

  const waitForToken = useCallback(async () => {
    let token = await onedriveFetch();
    if (token) {
      return token;
    }

    setTokenModalOpen(true);

    token = await onedriveSubscribe();
    setUnlinkModalOpen(false);
    setTokenModalOpen(false);
    return token;
  }, [onedriveFetch, onedriveSubscribe]);

  const {
    cancelUpload,
    getUploads,
    getUploadsProgress,
    handleUpdateProgress,
    handleUpdateUploads,
    updateMappedUploadAsError,
    updateUpload,
    updateUploadProgress,
  } = useUploadDrawerUploadsAndProgress<MappedOneDriveUploads>();

  const onCloseFilesSnackbar = () => setTooManyFilesSnackbarOpen(false);

  // When the callbacks refer to each other they do not get refreshed and therefor refer to
  // stale state values. By using a ref callbacks will always have the current state value
  const importIdMappingRef = useRef<Record<string, string>>();
  importIdMappingRef.current = importIdMapping;

  const onUnlinkModalClose = (unlinkStatus: UnlinkStatus) => {
    if (unlinkStatus === 'succeeded') {
      // After unlinking the OneDrive Account, we should try refetching the token
      queryClient.invalidateQueries(replayApi.getOnedriveToken());
      setUnlinkFailed(false);
      setUnlinkModalOpen(false);
      setUnlinkSnackbarOpen(true);
    } else if (unlinkStatus === 'failed') {
      setUnlinkFailed(true);
      setUnlinkModalOpen(false);
      setUnlinkSnackbarOpen(true);
    } else {
      setUnlinkModalOpen(false);
    }
  };

  const pollTransferProgress = useCallback(
    async (
      onAllFinished: () => void,
      logEvent: LoggingClient['logEvent'],
      finalizeTransfer: (
        transfer: OneDriveUpload,
        fileId: string,
        uploadId: string,
      ) => Promise<void>,
      onFileError?: FileErrorHandler,
    ) => {
      // this is impossible but here for typechecker
      const uploadsProgress = getUploadsProgress();
      const uploads = getUploads();
      if (!uploadsProgress || !uploads) {
        throw new Error('Invalid ref state');
      }

      let finished = true;

      // make a copy so we can update state
      const progress = {...uploadsProgress};
      const updatePromises = Object.entries(progress).map(
        async ([fileId, transfer]) =>
          new Promise<void>(async (resolve) => {
            // this is impossible but here for typechecker
            if (!uploads || !importIdMappingRef.current) {
              throw new Error('Invalid ref state');
            }

            const markError = () => {
              if (onFileError && uploads) {
                onFileError(uploads[fileId]);
              }
              progress[fileId] = {
                ...transfer,
                status: FileUploadState.Error,
              };
              finished = true;
              resolve();
            };

            if (
              transfer.status &&
              (transfer.status === FileUploadState.Uploading ||
                transfer.status === FileUploadState.Ready)
            ) {
              const importId = importIdMappingRef.current[fileId];
              const newStatus = await queryOneDriveImportStatus(importId);
              if (newStatus === OneDriveImportStatus.Running) {
                const oldPercent = transfer.percentage ? transfer.percentage : 1;
                progress[fileId] = {
                  ...transfer,
                  percentage: Math.min(oldPercent + 3, 99), // lol. Works out to ~30 seconds
                  status: StatusMap[newStatus],
                };
                finished = false;
              } else if (newStatus === OneDriveImportStatus.Completed) {
                const t = uploads[fileId];

                if (!t.nsId) {
                  markError();
                  return;
                }
                try {
                  await finalizeTransfer(t, `ns:${t.nsId}/${fileId}/${t.file.name}`, fileId);
                  progress[fileId] = {
                    ...transfer,
                    percentage: 100,
                    status: FileUploadState.Complete,
                  };
                  loggingClient.logPap(PAP_Select_OnedriveImport({eventState: 'success'}));
                  // eslint-disable-next-line deprecation/deprecation
                  loggingClient.logEvent('select_onedrive_import', {
                    click_source: 'null',
                    event_state: 'success',
                    is_logged_in: 'null',
                  });
                } catch (err) {
                  reportOneDriveError(err);
                  loggingClient.logPap(PAP_Select_OnedriveImport({eventState: 'failed'}));
                  // eslint-disable-next-line deprecation/deprecation
                  loggingClient.logEvent('select_onedrive_import', {
                    click_source: 'null',
                    event_state: 'failed',
                    is_logged_in: 'null',
                  });
                  markError();
                }
              } else {
                loggingClient.logPap(PAP_Select_OnedriveImport({eventState: 'failed'}));
                // eslint-disable-next-line deprecation/deprecation
                loggingClient.logEvent('select_onedrive_import', {
                  click_source: 'null',
                  event_state: 'failed',
                  is_logged_in: 'null',
                });
                markError();
              }
            }

            resolve();
          }),
      );
      await Promise.all(updatePromises);

      handleUpdateProgress(progress);

      if (!finished) {
        setTimeout(
          () => pollTransferProgress(onAllFinished, logEvent, finalizeTransfer, onFileError),
          1000,
        );
      } else {
        onAllFinished();
      }
    },
    [getUploadsProgress, getUploads, handleUpdateProgress, loggingClient],
  );

  const startFileImportToProjects = useCallback(
    async (
      projects: EmptyProjectsResponse,
      onAllFinished: () => void,
      logEvent: LoggingClient['logEvent'],
    ) => {
      const onImportStart = (fileId: string, importId: string | null) => {
        const uploadsProgress = getUploadsProgress();
        const uploads = getUploads();

        // this is impossible but here for typechecker
        if (!uploadsProgress || !uploads) {
          throw new Error('Invalid ref state');
        }

        if (!importId) {
          updateUploadProgress(fileId, {status: FileUploadState.Error});
        } else {
          updateUploadProgress(fileId, {
            status: FileUploadState.Uploading,
            percentage: 1, // we don't get progress from the backend unfortunately
          });
          setImportIdMapping({
            ...importIdMapping,
            [fileId]: importId,
          });
        }
      };

      // start imports
      const progress = {...getUploadsProgress()};
      const transfersCopy: MappedOneDriveUploads = {};
      const startRequests: Promise<void>[] = Object.entries(projects).map(
        ([fileId, createResult]) => {
          if (createResult.status !== EmptyProjectCreationStatus.Complete || !createResult.nsId) {
            progress[fileId].status = FileUploadState.Error;
            return new Promise<void>((resolve) => resolve());
          }
          transfersCopy[fileId] = {
            ...getUploads()[fileId],
            projectId: createResult.projectId,
            nsId: createResult.nsId,
            onCancel: (upload) => {
              cancelUpload(upload.uploadId);
              loggingClient.logPap(PAP_Select_OnedriveImport({eventState: 'canceled'}));
              // eslint-disable-next-line deprecation/deprecation
              loggingClient.logEvent('select_onedrive_import', {
                click_source: 'null',
                event_state: 'canceled',
                is_logged_in: 'null',
              });
            },
          };

          return new Promise<void>(async (resolve, reject) => {
            if (createResult.nsId) {
              const accountId = await getOneDriveAccountId();
              if (accountId === null) {
                reject(new Error('No linked onedrive account found'));
              }
              const importId = await startOneDriveFileImport(
                fileId,
                createResult.nsId,
                accountId ?? '',
              );
              onImportStart(fileId, importId);
            }
            resolve();
          });
        },
      );
      handleUpdateUploads(transfersCopy);
      await Promise.all(startRequests);

      // start polling
      const finalizeTransfer = async (
        transfer: OneDriveUpload,
        fileId: string,
        uploadId: string,
      ): Promise<void> => {
        if (!transfer.projectId) {
          throw new Error('Project id is missing');
        }

        const uploadStatus = getUploadsProgress()[uploadId].status;
        if (uploadStatus === FileUploadState.Cancelled) {
          return;
        }

        const {videoId, videoVersionId} = await addToProject(transfer.projectId, fileId, false);

        let link = `/project/${transfer.projectId}/video/${videoId}`;
        if (videoVersionId) {
          link += `?video_version_id=${videoVersionId}`;
        }

        updateUpload(uploadId, {
          link,
          projectId: transfer.projectId,
          versionId: transfer.versionId || videoVersionId,
        });
      };
      pollTransferProgress(onAllFinished, logEvent, finalizeTransfer);
    },
    [
      getUploadsProgress,
      handleUpdateUploads,
      pollTransferProgress,
      getUploads,
      updateUploadProgress,
      importIdMapping,
      cancelUpload,
      loggingClient,
      updateUpload,
    ],
  );

  const startOneDriveProjectImport = useCallback(
    async (props: OneDriveUploadProjectProps) => {
      const {currentFolderId, clickSource, onFilePick, onAllUploadsComplete, logEvent} = props;

      loggingClient.logPap(
        PAP_Select_OnedriveImport({
          clickSource: 'project_import',
          eventState: 'start',
        }),
      );
      // eslint-disable-next-line deprecation/deprecation
      loggingClient.logEvent('select_onedrive_import', {
        click_source: 'project_import',
        event_state: 'start',
        is_logged_in: 'null',
      });

      await waitForToken();

      const pickerResult = await legacyPickFile(validFileExtensions, loggingClient);
      if (!pickerResult) return;

      const fileId = pickerResult.fileId;
      const name = pickerResult.fileName;
      const fileSize = pickerResult.fileSize;

      if (fileSize >= MAX_DOCUMENT_UPLOAD_SIZE) {
        // eslint-disable-next-line deprecation/deprecation
        loggingClient.logEvent('failed_pdf_upload');
        openErrorSnackbar(documentSizeExceededError);
        return;
      }

      onFilePick([{relativePath: '', name: name, fileSize: fileSize}]);

      // Get the snackbar and modal data ready so we have something to show
      const newTransfers: MappedOneDriveUploads = {};
      newTransfers[fileId] = {
        file: {
          oneDriveId: fileId,
          name: name,
        },
        mediaSourceType: 'onedrive',
        canceled: false,
        uploadId: fileId,
        type: UploadTypes.PROJECT,
      };

      const progress: UploadsProgress = {};
      progress[fileId] = {
        percentage: 0,
        status: FileUploadState.Ready,
      };

      handleUpdateUploads(newTransfers);
      handleUpdateProgress(progress);

      const filenameMap = Object.entries(newTransfers).reduce((map, [fileId, file]) => {
        return {
          ...map,
          [fileId]: file.file.name,
        };
      }, {} as Record<string, string>);

      try {
        const createResult = await createEmptyProjects(filenameMap, {}, currentFolderId);
        startFileImportToProjects(
          createResult,
          () =>
            onAllUploadsComplete(
              Object.values(newTransfers).map((transfer) => transfer.file),
              currentFolderId,
              clickSource,
              'onedrive',
              'file',
            ),
          logEvent,
        );
      } catch (err) {
        reportOneDriveError(err);
        updateMappedUploadAsError(fileId, {}, err);
        onAllUploadsComplete(
          Object.values(newTransfers).map((transfer) => transfer.file),
          currentFolderId,
          clickSource,
          'onedrive',
          'file',
        );
      }
    },
    [
      loggingClient,
      waitForToken,
      validFileExtensions,
      handleUpdateUploads,
      handleUpdateProgress,
      openErrorSnackbar,
      documentSizeExceededError,
      startFileImportToProjects,
      updateMappedUploadAsError,
    ],
  );

  const startOneDriveVersionImport = useCallback(
    async (props: OneDriveUploadVersionProps) => {
      const {logEvent, nsId, onCreateVersion, onError} = props;

      loggingClient.logPap(
        PAP_Select_OnedriveImport({
          clickSource: 'version_import',
          eventState: 'start',
        }),
      );
      // eslint-disable-next-line deprecation/deprecation
      loggingClient.logEvent('select_onedrive_import', {
        click_source: 'version_import',
        event_state: 'start',
        is_logged_in: 'null',
      });

      await waitForToken();

      const pickerResult = await legacyPickFile(validFileExtensions, loggingClient);
      if (!pickerResult) return;

      const fileId = pickerResult.fileId;
      const name = pickerResult.fileName;
      const fileSize = pickerResult.fileSize;

      if (fileSize >= MAX_DOCUMENT_UPLOAD_SIZE) {
        // eslint-disable-next-line deprecation/deprecation
        loggingClient.logEvent('failed_pdf_upload');
        openErrorSnackbar(documentSizeExceededError);
        return;
      }

      const mappedUploads: MappedOneDriveUploads = {
        [fileId]: {
          canceled: false,
          file: {
            oneDriveId: fileId,
            name: name,
          },
          mediaSourceType: 'onedrive',
          nsId: nsId,
          type: UploadTypes.VERSION,
          uploadId: fileId,
          onCancel: (upload) => cancelUpload(upload.uploadId),
        },
      };
      const progress: UploadsProgress = {
        [fileId]: {
          percentage: 0,
          status: FileUploadState.Ready,
        },
      };

      handleUpdateUploads(mappedUploads);
      handleUpdateProgress(progress);

      try {
        const accountId = await getOneDriveAccountId();
        if (accountId === null) {
          throw new Error('No linked onedrive account found');
        }

        const importId = await startOneDriveFileImport(fileId, nsId, accountId);
        if (!importId) {
          throw new Error('Failed to start import');
        }
        setImportIdMapping({[fileId]: importId});
        // start polling
        const finalizeTransfer = async (
          transfer: OneDriveUpload,
          fileId: string,
          uploadId: string,
        ): Promise<void> => {
          const uploadStatus = getUploadsProgress()[uploadId].status;
          if (uploadStatus === FileUploadState.Cancelled) {
            return;
          }

          const {projectId, videoId, videoVersionId} = await onCreateVersion(fileId);
          if (!projectId) {
            throw new Error('Project id is missing');
          }
          if (!videoId) {
            throw new Error('Video id is missing');
          }
          const link = buildViewLink({
            projectId,
            videoId,
            videoVersionId,
          });
          updateUpload(uploadId, {
            link: link,
            projectId: projectId,
            versionId: videoVersionId,
            videoId: videoId,
          });
        };
        pollTransferProgress(() => {}, logEvent, finalizeTransfer, onError);
      } catch (err) {
        reportOneDriveError(err);
        updateMappedUploadAsError(fileId, {}, err);
        onError?.();
      }
    },
    [
      loggingClient,
      waitForToken,
      validFileExtensions,
      handleUpdateUploads,
      handleUpdateProgress,
      openErrorSnackbar,
      documentSizeExceededError,
      cancelUpload,
      pollTransferProgress,
      getUploadsProgress,
      updateUpload,
      updateMappedUploadAsError,
    ],
  );

  const startOneDriveCaptionImport = useCallback(
    async (props: OneDriveUploadCaptionProps) => {
      const {nsId, onCaptionsUploaded, onError, logEvent} = props;

      loggingClient.logPap(
        PAP_Select_OnedriveImport({
          clickSource: 'caption_import',
          eventState: 'start',
        }),
      );
      // eslint-disable-next-line deprecation/deprecation
      loggingClient.logEvent('select_onedrive_import', {
        click_source: 'caption_import',
        event_state: 'start',
        is_logged_in: 'null',
      });

      await waitForToken();

      const pickerResult = await legacyPickFile(VALID_CAPTIONS_FILE_EXTENSIONS, loggingClient);
      if (!pickerResult) return;
      const fileId = pickerResult.fileId;
      const name = pickerResult.fileName;

      const mappedUploads: MappedOneDriveUploads = {
        [fileId]: {
          canceled: false,
          file: {
            oneDriveId: fileId,
            name: name,
          },
          mediaSourceType: 'onedrive',
          nsId: nsId,
          type: UploadTypes.CAPTIONS,
          uploadId: fileId,
          onCancel: (upload) => cancelUpload(upload.uploadId),
        },
      };
      const progress: UploadsProgress = {
        [fileId]: {
          percentage: 0,
          status: FileUploadState.Ready,
        },
      };

      handleUpdateUploads(mappedUploads);
      handleUpdateProgress(progress);

      try {
        const accountId = await getOneDriveAccountId();
        if (accountId === null) {
          throw new Error('No linked onedrive account found');
        }
        const importId = await startOneDriveFileImport(fileId, nsId, accountId);
        if (!importId) {
          throw new Error('Failed to start import');
        }
        setImportIdMapping({
          [fileId]: importId,
        });
        // start polling
        const finalizeTransfer = async (
          transfer: OneDriveUpload,
          fileId: string,
          uploadId: string,
        ): Promise<void> => {
          const uploadStatus = getUploadsProgress()[uploadId].status;
          if (uploadStatus === FileUploadState.Cancelled) {
            return;
          }
          await onCaptionsUploaded(fileId);
        };
        pollTransferProgress(() => {}, logEvent, finalizeTransfer, onError);
      } catch (err) {
        reportOneDriveError(err);
        updateMappedUploadAsError(fileId, {}, err);
        onError();
      }
    },
    [
      waitForToken,
      loggingClient,
      handleUpdateUploads,
      handleUpdateProgress,
      cancelUpload,
      pollTransferProgress,
      getUploadsProgress,
      updateMappedUploadAsError,
    ],
  );

  const value: OneDriveContextValue = useMemo(
    () => ({startOneDriveProjectImport, startOneDriveVersionImport, startOneDriveCaptionImport}),
    [startOneDriveCaptionImport, startOneDriveProjectImport, startOneDriveVersionImport],
  );

  return (
    <OneDriveContext.Provider value={value}>
      <OneDriveUnlinkModal onClose={onUnlinkModalClose} open={unlinkModalOpen} />
      <OneDriveTokenModal
        loggingClient={loggingClient}
        onClose={() => setTokenModalOpen(false)}
        open={tokenModalOpen}
      />
      <UnlinkSnackbar
        isError={unlinkFailed}
        onClose={() => setUnlinkSnackbarOpen(false)}
        open={unlinkSnackbarOpen}
      />
      <TooManyfilesSnackbar onClose={onCloseFilesSnackbar} open={tooManyfilesSnakbarOpen} />

      {props.children}
    </OneDriveContext.Provider>
  );
};

export const useOneDriveContext = () => {
  const context = useContext(OneDriveContext);

  if (context === null) {
    const error = new Error('useOneDriveContext must be used within a OneDriveContextProvider');
    reportBadContextUseError(error);
    throw error;
  }

  return context;
};
