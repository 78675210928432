// Mostly a copy of metaserver/static/js/flows/utils/notifications.tsx with simplified logic for Replay

import {useCallback, useEffect, useState} from 'react';

import type {FlowsBoltPayload} from '~/lib/flows/bolt';
import type {BrowsePageSnackbarProps} from '~/pages/browse_page/components/browse_page_snackbar';
import {BrowseSnackbarStatus} from '~/pages/browse_page/components/browse_page_snackbar';

export function useFlowsNotifications(
  setBrowseSnackbar: (props: BrowsePageSnackbarProps) => void,
  hideSnackbar: () => void,
) {
  const [payloads, setPayloads] = useState<FlowsBoltPayload[]>([]);

  const handlePayload = useCallback((payload: FlowsBoltPayload) => {
    setPayloads((payloads) => [...payloads, payload]);
  }, []);

  const clearPayloads = useCallback(() => setPayloads([]), []);

  const handleNotification = useCallback(
    (activePayloads: FlowsBoltPayload[]) => {
      const inProgress = activePayloads.filter((p) => p.status === 'start');
      const succeeded = activePayloads.filter((p) => p.status === 'success');
      const failed = activePayloads.filter((p) => p.status === 'fail');

      const hasOverquota = failed.some((p) => p.exit_reason === 'overquota');

      const onRequestClose = () => {
        clearPayloads();
        hideSnackbar();
      };

      if (inProgress.length) {
        setBrowseSnackbar({
          status: BrowseSnackbarStatus.ARCHIVE,
          archiveStatus: 'in_progress',
          onRequestClose,
          numInProgress: inProgress.length,
        });
      } else if (succeeded.length === activePayloads.length) {
        setBrowseSnackbar({
          status: BrowseSnackbarStatus.ARCHIVE,
          archiveStatus: 'success',
          onRequestClose,
          numSucceeded: succeeded.length,
        });
      } else if (failed.length === activePayloads.length || hasOverquota) {
        setBrowseSnackbar({
          status: BrowseSnackbarStatus.ARCHIVE,
          archiveStatus: 'fail',
          onRequestClose,
          numFailed: failed.length,
          overQuota: hasOverquota,
        });
      } else {
        // partial success
        setBrowseSnackbar({
          status: BrowseSnackbarStatus.ARCHIVE,
          archiveStatus: 'partial_success',
          onRequestClose,
          numSucceeded: succeeded.length,
          numFailed: failed.length,
          overQuota: failed.some((p) => p.exit_reason === 'overquota'),
        });
      }
    },
    [clearPayloads, hideSnackbar, setBrowseSnackbar],
  );

  useEffect(() => {
    if (!payloads.length) {
      return;
    }

    const activePayloads = Object.values(
      payloads
        .filter((p) => p.action_type === 'archive_from_replay')
        .reduce((acc: {[key: string]: FlowsBoltPayload}, cur) => ({...acc, [cur.revkey]: cur}), {}),
    );

    handleNotification(activePayloads);
  }, [payloads, handleNotification]);

  return {
    handlePayload,
    clearPayloads,
  };
}
