import React from 'react';

import {useSetAtom} from 'jotai';

import type {ButtonVariant} from '~/components/button';
import {
  initializeShareModalAtom,
  isShareModalOpenAtom,
  shareSnackbarStateAtom,
} from '~/components/share_modal/share_state';

import type {LinkInfoResult, ShareFolderAccessType} from './api';
import type {LoggingClient} from './logging/logger';
import {useLinkActions} from './use_link_actions';
import type {ShareLinkPermission} from '../components/share_modal/share_modal_common';
import {useIsCurrentPageShareFolderPage} from '../route_path_matches';

export type FolderShareProps = {
  buttonVariant?: ButtonVariant;
  accessLevel: ShareFolderAccessType;
  folderId: string;
  folderName: string;
  logEvent: LoggingClient['logEvent'];
  isTeamFolder: boolean;
  isViewOnlyLinkAccess: boolean;
};

export type FolderShareHandlers = {
  getExistingLinks: () => Promise<LinkInfoResult>;
  onShareClick: (existingLinks: LinkInfoResult | null, perm: ShareLinkPermission) => void;
};

export const useCanUserCreateLinks = (props: {accessLevel: ShareFolderAccessType}) => {
  const {accessLevel} = props;

  const isCurrentPageShareFolderPage = useIsCurrentPageShareFolderPage();

  return React.useMemo(() => {
    // We have to check the current url because an access level of NONE is
    // ambiguous; it can mean either that the user is the file owner but has
    // not shared the folder with anyone or that the user is viewing a folder
    // via a shared folder link.
    if (accessLevel === 'none' && isCurrentPageShareFolderPage) {
      // The user is viewing a link to a shared folder; they cannot create new
      // links no matter who they are
      return false;
    }
    if (accessLevel === 'none') {
      // The user is viewing a project (or one of its children) that they own
      // but have not yet shared
      return true;
    }

    // The user is viewing a project (or one of its children); fall back to
    // their project permissions to determine if they can create links
    // Note: we're also going to give super_admins the ability to create those links
    return accessLevel === 'owner' || accessLevel === 'admin' || accessLevel === 'super_admin';
  }, [accessLevel, isCurrentPageShareFolderPage]);
};

export const useFolderShareOnClick = (
  props: Omit<FolderShareProps, 'isViewOnlyLinkAccess'>,
): FolderShareHandlers => {
  const {logEvent, folderId, folderName, isTeamFolder} = props;

  const linkActions = useLinkActions({
    logEvent,
    linkItemId: folderId,
    linkItemType: 'folder',
    liveReview: false,
    location: 'folder',
  });

  const initializeShareModal = useSetAtom(initializeShareModalAtom);
  const setIsShareModalOpen = useSetAtom(isShareModalOpenAtom);
  const setShareErrorSnackbar = useSetAtom(shareSnackbarStateAtom);

  const onShareClick = React.useCallback(
    (existingLinks: LinkInfoResult | null) => {
      // eslint-disable-next-line deprecation/deprecation
      logEvent('select_reel_share', {
        link_item_type: isTeamFolder ? 'team_project' : 'folder',
        location: 'folder',
        replay_media_type: 'none',
        experiment_variant: 'none',
      });

      if (existingLinks != null) {
        initializeShareModal({
          id: folderId,
          type: 'folder',
          name: folderName,
          logEvent: logEvent,
          location: 'folder',
          isDemo: false,
          isLiveReview: false,
          linkItemType: isTeamFolder ? 'team_project' : 'folder',
          basicLinks: existingLinks.basicLinks,
          watermarkLinks: existingLinks.watermarkLinks,
          sharingIsRestrictedByTeamPolicy: existingLinks.sharingIsRestrictedByTeamPolicy,
        });
        setIsShareModalOpen(true);
      } else {
        setShareErrorSnackbar({type: 'link-fetch-failed'});
      }
    },
    [
      logEvent,
      isTeamFolder,
      initializeShareModal,
      folderId,
      folderName,
      setIsShareModalOpen,
      setShareErrorSnackbar,
    ],
  );

  return {
    getExistingLinks: linkActions.getExistingLinks,
    onShareClick,
  };
};
