import {type ReactNode} from 'react';

import {defineMessages, FormattedMessage} from 'react-intl';
import styled, {css} from 'styled-components';

import type {reel} from '@dropbox/api-v2-client/types/dropbox_types';
import {Modal, type ModalProps} from '@dropbox/dig-components/modal';
import {ThemeContainer, ThemeProvider} from '@dropbox/dig-foundations';

import {Button, StylelessButton, type StylelessButtonProps} from '~/components/button';
import {color, colorTransition, radius, spacing} from '~/components/styled';

interface OptionButtonProps extends StylelessButtonProps {
  'aria-selected': boolean;
  role: string;
}

export const OptionButton = styled(StylelessButton)<OptionButtonProps>`
  position: relative;
  border-radius: ${radius('Medium')};
  transition: ${colorTransition('box-shadow', 'opacity', 'color', 'background-image')};

  background-color: ${color('Opacity Surface')};
  cursor: pointer;

  &:focus {
    box-shadow: none;
  }

  &:hover {
    opacity: 0.5;
  }

  &&&[aria-selected='true'] {
    box-shadow: 0 0 0 2px ${color('Text Base')};
  }

  &:focus {
    box-shadow: 0 0 0 3px ${color('Text Base')};
  }

  &:disabled {
    opacity: 0.5;
    color: ${color('Disabled Base')};
  }

  .dig-StylelessButton-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const customButtonStyles = css`
  & .dig-StylelessButton-content {
    display: none;
  }

  &:hover {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.5);
    background-blend-mode: darken;

    .dig-StylelessButton-content {
      display: flex;
    }
  }
`;

const ModalBody = styled(Modal.Body)`
  padding-top: ${spacing('Macro XSmall')};
`;

interface OptionModalProps extends ModalProps {
  children: ReactNode;
  isSubmitting?: boolean;
  isSaveDisabled?: boolean;
  id: string;
  onSave: (logo: reel.BrandingLogo) => Promise<void>;
  open: boolean;
  title: string;
}

export const OptionModal = ({
  children,
  isSubmitting = false,
  isSaveDisabled = false,
  id,
  title,
  onSave,
  onRequestClose,
  ...modalProps
}: OptionModalProps) => {
  return (
    <ThemeProvider mode="dark" overrides="reset" theme="vis2023">
      <ThemeContainer>
        <Modal
          aria-labelledby={id}
          isCentered
          onRequestClose={onRequestClose}
          width="standard"
          withCloseButton="Close"
          {...modalProps}
        >
          <Modal.Header hasBottomSpacing="title-standard">
            <Modal.Title id={id}>{title}</Modal.Title>
          </Modal.Header>
          <ModalBody>{children}</ModalBody>
          <Modal.Footer>
            <Button disabled={isSubmitting} onClick={onRequestClose} variant="opacity">
              <FormattedMessage {...modalStrings.cancelButton} />
            </Button>
            <Button
              disabled={isSaveDisabled}
              isLoading={isSubmitting}
              onClick={onSave}
              variant="primary"
            >
              <FormattedMessage {...modalStrings.saveButton} />
            </Button>
          </Modal.Footer>
        </Modal>
      </ThemeContainer>
    </ThemeProvider>
  );
};

const modalStrings = defineMessages({
  cancelButton: {
    defaultMessage: 'Cancel',
    description: 'Button copy for cancel button for change logo modal',
    id: 'hdK95Q',
  },
  saveButton: {
    defaultMessage: 'Save',
    description: 'Button copy for save button for change logo modal',
    id: 'DGZVe6',
  },
});
