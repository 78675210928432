import {useAtomValue} from 'jotai';
import {FormattedMessage} from 'react-intl';

import {UIIcon} from '@dropbox/dig-icons';
import {MoveLine} from '@dropbox/dig-icons/assets';

import {Button, IconButton} from '~/components/button';
import {useViewport} from '~/components/viewport_context';
import {BulkActionStatus, bulkActionStatusAtom} from '~/state/bulk_actions';

type BulkMoveToFolderButtonProps = {
  onClick: () => void;
};

export const BulkMoveToFolderButton = ({onClick}: BulkMoveToFolderButtonProps) => {
  // eslint-disable-next-line deprecation/deprecation
  const {isSmallScreenSize} = useViewport();

  const bulkActionStatus = useAtomValue(bulkActionStatusAtom);

  const disabled = bulkActionStatus === BulkActionStatus.REQUESTING;

  return isSmallScreenSize ? (
    <IconButton disabled={disabled} onClick={onClick} size="small" variant="opacity">
      <UIIcon src={MoveLine} />
    </IconButton>
  ) : (
    <Button
      disabled={disabled}
      onClick={onClick}
      variant="opacity"
      withIconLeft={<UIIcon src={MoveLine} />}
    >
      <FormattedMessage
        defaultMessage="Move to folder"
        description="Button that allows a user to move one or more files to a folder"
        id="iCNaG3"
      />
    </Button>
  );
};
