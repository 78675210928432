import {useIntl} from 'react-intl';
import styled from 'styled-components';

import {Badge} from '@dropbox/dig-components/badge';

import {spacing} from '~/components/styled';

const BadgeWrapper = styled.div`
  position: absolute;
  bottom: ${spacing('Micro Medium')};
  right: ${spacing('Micro Medium')};
  z-index: 1;
`;

export const NewBadge = () => {
  const intl = useIntl();
  const newBadgeLabel = intl.formatMessage({
    defaultMessage: 'New',
    id: 'T6UbJy',
    description: 'Text on a badge that indicates that this item is new to the user.',
  });

  return (
    <BadgeWrapper>
      <Badge variant="attention">{newBadgeLabel}</Badge>
    </BadgeWrapper>
  );
};
