import {Suspense} from 'react';

import {lazy} from '~/lib/lazy';

import {Page} from './components/page';

const AsyncPageMissingPage = lazy(() =>
  import(/* webpackChunkName: 'four_oh_four' */ './page_missing_page').then((exps) => ({
    default: exps.PageMissingPage,
  })),
);

export const PageMissingPageLazy = () => {
  return (
    <Page title="404">
      <Suspense fallback={null}>
        <AsyncPageMissingPage />
      </Suspense>
    </Page>
  );
};
