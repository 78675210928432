/* Originally copied from metaserver:
 * metaserver/static/js/modules/core/persistence/storage.ts */

import {RELOADED_POST_PURCHASE, replayStorage} from '~/lib/storage';

import {DOMException, localStorage, sessionStorage} from './globals';

// An in-memory implementation of storage api as a fallback
export class MemoryStorage {
  public storage: {[key: string]: any};

  constructor() {
    this.storage = {};
  }

  clear() {
    this.storage = {};
  }

  getItem(key: string): any {
    return this.storage[key];
  }

  key(index: number) {
    return Object.keys(this.storage)[index];
  }

  removeItem(key: string) {
    delete this.storage[key];
  }

  setItem(key: string, data: string) {
    this.storage[key] = data;
  }
}

type StorageInitFunction = () => Storage | undefined;

export class BrowserStorage {
  // Do not use this private field directly, use the `.storage` getter field.
  private _storage?: Storage | MemoryStorage;
  initFn?: StorageInitFunction;

  constructor(initFn: StorageInitFunction) {
    this.initFn = initFn;
  }

  // The first time this is called, it should init the storage, and then use that going forward.
  // This allows the class to only init the storage when it is needed.
  get storage(): Storage | MemoryStorage {
    if (this.initFn) {
      this._storage = initStorage(this.initFn);
      delete this.initFn;
    }
    return this._storage as Storage | MemoryStorage;
  }

  get(key: string) {
    if (this.storage) {
      const item = this.storage.getItem(key);
      if (item) {
        // If a user accidentally called *Storage.set passing undefined then
        // the string 'undefined' will have been stored in local/session storage.
        // Then when calling get, we would retrieve the string 'undefined' which
        // is truthy and thus we would normally try to JSON.parse it which would
        // result in an "Uncaught SyntaxError: Unexpected token u in JSON at
        // position 0" error.
        return item === 'undefined' ? undefined : JSON.parse(item);
      } else {
        return item;
      }
    }
  }

  set(key: string, value: any) {
    if (!this.storage) {
      return;
    }

    // In safari private browsing, the storage quota is set to 0
    // Calling setItem will throw a QuotaExceededError (DOMException 22)
    try {
      if (value === null) {
        return this.delete(key);
      } else {
        return this.storage.setItem(key, JSON.stringify(value));
      }
    } catch (e) {
      if (isOutOfQuotaException(e)) {
        return;
      } else {
        throw e;
      }
    }
  }

  delete(key: string) {
    return this.storage ? this.storage.removeItem(key) : undefined;
  }

  reset() {
    return this.storage ? this.storage.clear() : undefined;
  }
}

class UserStorage {
  private browserStorage: BrowserStorage;
  private idPrefix: string = 'userId:';

  constructor(browserStorage: BrowserStorage) {
    this.browserStorage = browserStorage;
  }

  get(userId: number, key: string) {
    return this.browserStorage.get(this.formatKey(userId, key));
  }

  set(userId: number, key: string, value: any) {
    return this.browserStorage.set(this.formatKey(userId, key), value);
  }

  // Only keep the local storage data for given array of userIds
  clearOtherUsers(userIds: number[]) {
    Object.keys(this.browserStorage.storage).map((key) => {
      if (key.startsWith(this.idPrefix)) {
        const extractedId = key.substring(this.idPrefix.length, key.indexOf('.'));
        if (!userIds.includes(Number(extractedId))) {
          this.browserStorage.delete(key);
        }
      }
    });
  }

  delete(userId: number, key: string) {
    return this.browserStorage.delete(this.formatKey(userId, key));
  }

  // Local storage key will be userId:XXXXXX.storageKey
  private formatKey(userId: number, key: string): string {
    return `${this.idPrefix}${userId}.${key}`;
  }
}

function initStorage(initFn: () => Storage | undefined): Storage | MemoryStorage {
  // initFn whould be a function that returns window.localStorage or other
  // browser storage object
  let storage: Storage | MemoryStorage;
  try {
    // we call initFn in a try-catch because IE10/11 sometimes throw "Access is Denied"
    // when window.localStorage is touched.
    // https://stackoverflow.com/questions/20212627/access-denied-for-localstorage-in-ie11-but-only-in-desktop-mode-not-in-metro-mo
    storage = initFn() || new MemoryStorage();
  } catch (e) {
    storage = new MemoryStorage();
  }

  try {
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);

    return storage;
  } catch (e) {
    if (storage && isOutOfQuotaException(e)) {
      return storage;
    } else {
      return new MemoryStorage();
    }
  }
}

function isOutOfQuotaException(e: Error): boolean {
  /*
   * The error when accessing local storage can indicate that either:
   *  - The local storage is not available
   *  - The local storage is full
   * This code does the best effort of detecting whether it is actually full, in which case
   * we'd still want to initialize an api to this storage since we might need to read old data.
   */
  return (
    e instanceof DOMException &&
    // everything except Firefox
    // eslint-disable-next-line deprecation/deprecation
    (e.code === 22 ||
      // Firefox
      // eslint-disable-next-line deprecation/deprecation
      e.code === 1014 ||
      // test name field too, because code might not be present
      // everything except Firefox
      e.name === 'QuotaExceededError' ||
      // Firefox
      e.name === 'NS_ERROR_DOM_QUOTA_REACHED')
  );
}

export function clearPurchaseStorageItems(): void {
  if (replayStorage.getPurchasedInReplay()) {
    replayStorage.deletePurchasedInReplay();
  }
  if (SessionStorage.get(RELOADED_POST_PURCHASE)) {
    SessionStorage.delete(RELOADED_POST_PURCHASE);
  }
}

export const SessionStorage = new BrowserStorage(() => sessionStorage);
export const LocalStorage = new BrowserStorage(() => localStorage);
export const UserLocalStorage = new UserStorage(LocalStorage);
