import React from 'react';

import {PAP_Select_ModalAction} from 'pap-events/replay/select_modal_action';
import {defineMessages, FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';

import type {reel} from '@dropbox/api-v2-client';
import {Avatar, avatarColorForUserIdentifier} from '@dropbox/dig-components/avatar';
import {Menu} from '@dropbox/dig-components/menu';
import {Modal} from '@dropbox/dig-components/modal';
import {Snackbar} from '@dropbox/dig-components/snackbar';
import {Link, Text, Title} from '@dropbox/dig-components/typography';

import {Button} from '~/components/button';
import {ReelSnackbar} from '~/components/snackbar';
import type {AccessActionType, ShareFolderAccessType, TeamContactInfo} from '~/lib/api';
import {changeOwnership, shareProject} from '~/lib/api';
import type {LoggingClient} from '~/lib/logging/logger';
import {useGetUserVariant} from '~/lib/provisions';
import {useActionSurface} from '~/lib/use_action_surface';
import {useLoggingClient} from '~/lib/use_logging_client';
import type {
  GroupShareRecipient,
  ShareRecipient,
  UserShareRecipient,
} from '~/pages/browse_page/components/common';
import {isGroupShareRecipient, isUserShareRecipient} from '~/pages/browse_page/components/common';
import type {FolderToAccounts} from '~/pages/browse_page/use_share_recipients';

import {AddPeopleSnackbar} from './add_team_modal';
import {DropboxAccountTypeahead} from './dropbox_account_typeahead';
import {getRestrictedContentErrorTextFromType} from './restricted_content_messages';
import {lineHeight, spacing} from './styled';
import {
  getCurrentUserAccountId,
  getUserAmplitudeSafeTeamId,
  useReelAppGlobalState,
} from '../context';
import {DROPBOX_WEBSERVER_URL} from '../lib/third_party_auth';
import {useAreSuperAdminFeaturesEnabled} from '../lib/utils';

const createGroupAvatarName = (groupName: string): string => {
  const words = groupName.trim().split(/\s+/);
  if (words.length === 1) {
    return words[0][0].toUpperCase(); // if only one word, return first letter
  }
  // Otherwise, return the first letters of first and last word
  return (words[0][0] + words[words.length - 1][0]).toUpperCase();
};

const ModalHeader = styled(Modal.Header)`
  padding-top: ${spacing('32')};
  padding-bottom: ${spacing('16')};
`;

const ModalTitle = styled.div`
  padding-top: 10px;
`;

const ModalBody = styled(Modal.Body)`
  padding-bottom: 24px;
`;

const UserSettings = styled.div`
  display: flex;
  padding-bottom: ${spacing('16')};
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 12px;
`;

const UserInfoText = styled(Text)`
  line-height: ${lineHeight('Text XSmall')};
`;

const MembersListWrapper = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 184px;
`;

const ShareOption = styled.div`
  display: flex;
  margin-left: auto;
`;

const MenuItemWrapper = styled.div`
  max-width: 250px;
`;

const TypeaheadWrapper = styled.div`
  flex: 1;
  margin-right: ${spacing('8')};
`;

const AddPeopleWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: ${spacing('12')} 0 ${spacing('24')} 0;
`;

const shareOptionMap: {[key in ShareFolderAccessType]: string} = {
  super_admin: 'Admin',
  admin: 'Can edit',
  owner: 'Owner',
  reviewer: 'Can comment',
  other: 'Unknown',
  none: 'Unknown',
  remove: 'Remove',
};

export type AddMembersAccessType = 'admin' | 'reviewer';

export type ManageAccessModalProps = {
  modalType: 'manage_access_only' | 'manage_access_combo';
  shareRecipients: ShareRecipient[];
  teamFolderId: string;
  teamFolderName: string;
  open: boolean;
  requestClose: () => void;
  settingsChangeCallback(folderId: string): void;
  accessLevel: ShareFolderAccessType;
  logEvent: LoggingClient['logEvent'];
  allowSuperAdminRights?: boolean;
};

export type ManageAccessSnackbarProps = {
  isOpen: boolean;
  userName: string;
  newAccessLevel: string;
  succeeded: boolean;
};

type UserOrGroupRowProps = {
  avatarUrl?: string;
  abbreviatedName?: string;
  displayName?: string;
  emailAddressOrMemberCnt?: string;
  accessLevel: ShareFolderAccessType;
  accountId: string;
  amplitudeSafeTeamId?: string;
  updateShareSettingsCallback(
    accountIdOrGroupId: string,
    displayName: string,
    accessLevel: ShareFolderAccessType,
    targetType: 'user' | 'group',
  ): Promise<boolean>;
  disableAccessEdit: boolean;
  isCallerSuperAdminWithRights: boolean;
  takeOwnershipCallback(
    accountId: string,
    displayName: string,
    shouldTakeOwnership: boolean,
    shouldTakeOwnershipDirectly: boolean,
  ): Promise<boolean>;
  isCurrentUserRow: boolean;
  type: 'user' | 'group';
};

const UserOrGroupRow = (props: UserOrGroupRowProps) => {
  const {
    avatarUrl,
    displayName,
    emailAddressOrMemberCnt,
    accountId,
    amplitudeSafeTeamId,
    abbreviatedName,
    accessLevel,
    updateShareSettingsCallback,
    disableAccessEdit,
    isCallerSuperAdminWithRights,
    takeOwnershipCallback,
    isCurrentUserRow,
    type,
  } = props;
  const sessionContext = useReelAppGlobalState();
  const [selected, setSelected] = React.useState<ShareFolderAccessType>(accessLevel!);
  const currentTeamId = getUserAmplitudeSafeTeamId(sessionContext);
  // Only want to show superAdmin options if the cur accessLevel is not already a owner
  const shouldShowSuperAdminOptions =
    isCallerSuperAdminWithRights && isCurrentUserRow && selected !== 'owner';
  const shouldShowTransferSuperAdminOptions =
    isCallerSuperAdminWithRights && !isCurrentUserRow && selected !== 'owner';

  const targetGroupUrl = isCallerSuperAdminWithRights
    ? `${DROPBOX_WEBSERVER_URL}/team/admin/groups?role=work#/${accountId}&return_to=replay`
    : `${DROPBOX_WEBSERVER_URL}/team#groups/${accountId}`;

  React.useEffect(() => {
    setSelected(accessLevel);
  }, [accessLevel]);

  const normalOwnerButton = (
    <Button disabled={true} variant="transparent" withDropdownIcon>
      <FormattedMessage
        defaultMessage="Owner"
        description="Text on the dropdown menu in the access modal. The menu lists access roles for team members in the project. The Owner access role is assigned by default to the owner of the file"
        id="4l4tGQ"
      />
    </Button>
  );

  const superAdminTakeOwnership = (
    <Menu.SelectItem disabled={type === 'group'} key={accountId} value="take_ownership">
      <Text style={{color: 'var(--dig-color__alert__on-surface)'}}>
        <FormattedMessage
          defaultMessage="Take ownership"
          description="Text for the take ownership option as admin."
          id="k4oyTV"
        />
      </Text>
      <br></br>
      <Text color="faint" size="small">
        <FormattedMessage
          defaultMessage="Owners have full project permissions. You will replace the current owner."
          description="Description for the take ownership option"
          id="tHseF1"
        />
      </Text>
    </Menu.SelectItem>
  );

  const superAdminTransferOwnership = (
    <Menu.SelectItem disabled={type === 'group'} key={accountId} value="transfer_ownership">
      <Text style={{color: 'var(--dig-color__alert__on-surface)'}}>
        <FormattedMessage
          defaultMessage="Transfer ownership"
          description="Text for the transfer ownership option as admin."
          id="eTUnad"
        />
      </Text>
      <br></br>
      <Text color="faint" size="small">
        <FormattedMessage
          defaultMessage="Transfer ownership to this user. The current owner will be replaced."
          description="Description for the transfer ownership option"
          id="YUd3iS"
        />
      </Text>
    </Menu.SelectItem>
  );

  const superAdminMenuForTeamOwner = (takeOwnershipDirectly: boolean) => (
    <Menu.Wrapper
      onSelection={async (selection: 'transfer_ownership' | 'take_ownership') => {
        if (selection == 'take_ownership') {
          const shouldTakeOwnership = true;
          await takeOwnershipCallback(
            accountId,
            displayName || 'User',
            shouldTakeOwnership,
            takeOwnershipDirectly,
          );
        }
      }}
    >
      {({getContentProps, getTriggerProps}) => (
        <>
          <Button {...getTriggerProps()} variant="transparent" withDropdownIcon>
            <FormattedMessage
              defaultMessage="Owner"
              description="Text on the dropdown menu in the access modal. The owner can reassign ownership."
              id="j5Gwm2"
            />
          </Button>
          <Menu.Content {...getContentProps()} placement="bottom-end">
            <Menu.Segment>
              <MenuItemWrapper>{superAdminTakeOwnership}</MenuItemWrapper>
            </Menu.Segment>
          </Menu.Content>
        </>
      )}
    </Menu.Wrapper>
  );

  return (
    <UserSettings>
      <Avatar
        alt=""
        /* eslint-disable-next-line deprecation/deprecation */
        backgroundColor={avatarColorForUserIdentifier(abbreviatedName || '')}
        src={avatarUrl}
      >
        {type === 'user' ? abbreviatedName : createGroupAvatarName(displayName ?? '')}
      </Avatar>
      <UserInfo>
        <UserInfoText isBold>{displayName}</UserInfoText>
        {type === 'user' ? (
          <UserInfoText color="faint" size="small">
            {emailAddressOrMemberCnt}
          </UserInfoText>
        ) : currentTeamId === amplitudeSafeTeamId ? (
          <Link hasNoUnderline={true} href={targetGroupUrl} target="_blank" variant="monochromatic">
            <Text color="faint" size="small">
              <FormattedMessage
                defaultMessage="{size} {size, plural, one {member} other {members}}"
                description="Group count information on the manage access modal"
                id="xF9YYa"
                values={{size: emailAddressOrMemberCnt}}
              />
            </Text>
          </Link>
        ) : (
          <UserInfoText color="faint" size="small">
            <FormattedMessage
              defaultMessage="{size} {size, plural, one {member} other {members}}"
              description="Group count information on the manage access modal"
              id="xF9YYa"
              values={{size: emailAddressOrMemberCnt}}
            />
          </UserInfoText>
        )}
      </UserInfo>
      <ShareOption>
        {accessLevel === 'owner' ? (
          isCallerSuperAdminWithRights && !isCurrentUserRow ? (
            superAdminMenuForTeamOwner(true)
          ) : (
            normalOwnerButton
          )
        ) : (
          <Menu.Wrapper
            onSelection={async (selection: AccessActionType) => {
              if (selection == 'take_ownership') {
                const shouldTakeOwnership = true;
                await takeOwnershipCallback(
                  accountId,
                  displayName || 'User',
                  shouldTakeOwnership,
                  false,
                );
              } else if (selection === 'transfer_ownership') {
                // We're transfering not taking :/
                const shouldTakeOwnership = false;
                await takeOwnershipCallback(
                  accountId,
                  displayName || 'User',
                  shouldTakeOwnership,
                  false,
                );
              } else {
                const isSuccess = await updateShareSettingsCallback(
                  accountId,
                  displayName || 'User',
                  selection,
                  type,
                );
                if (isSuccess && selection !== 'remove') {
                  setSelected(selection);
                }
              }
            }}
          >
            {({getContentProps, getTriggerProps}) => (
              <>
                <Button
                  {...getTriggerProps()}
                  disabled={disableAccessEdit}
                  variant="transparent"
                  withDropdownIcon
                >
                  {shouldShowSuperAdminOptions ? (
                    <FormattedMessage
                      defaultMessage="Admin"
                      description="The text to show if the current user is a super admin"
                      id="Az4yzR"
                    />
                  ) : (
                    shareOptionMap[selected]
                  )}
                </Button>
                <Menu.Content {...getContentProps()} placement="bottom-end">
                  <Menu.Segment>
                    <MenuItemWrapper>
                      {shouldShowSuperAdminOptions && superAdminTakeOwnership}
                    </MenuItemWrapper>
                    <MenuItemWrapper>
                      {shouldShowTransferSuperAdminOptions && superAdminTransferOwnership}
                    </MenuItemWrapper>
                    <MenuItemWrapper>
                      <Menu.SelectItem
                        key={accountId}
                        selected={selected === 'admin'}
                        value="admin"
                      >
                        <Text>
                          <FormattedMessage
                            defaultMessage="Can edit"
                            description="Text for an option on the dropdown menu in the access modal. Selecting this option assigns the project team member Admin permissions, meaning that the user can edit the project."
                            id="c6p+nf"
                          />
                        </Text>
                        <br />
                        <Text color="faint" size="small">
                          <FormattedMessage {...managePeopleModalStrings.canEditDescription} />
                        </Text>
                      </Menu.SelectItem>
                    </MenuItemWrapper>
                    <MenuItemWrapper>
                      <Menu.SelectItem
                        key={accountId}
                        selected={selected === 'reviewer'}
                        value="reviewer"
                      >
                        <Text>
                          <FormattedMessage
                            defaultMessage="Can comment"
                            description="Text for an option on the dropdown menu in the access modal. Selecting this option assigns the project team member Reviewer permissions, meaning that the user can view and comment on assets in the project."
                            id="g1oJxk"
                          />
                        </Text>
                        <br />
                        <Text color="faint" size="small">
                          <FormattedMessage {...managePeopleModalStrings.canCommentDescription} />
                        </Text>
                      </Menu.SelectItem>
                    </MenuItemWrapper>
                    <MenuItemWrapper>
                      <Menu.SelectItem
                        key={accountId}
                        selected={selected === 'remove'}
                        value="remove"
                      >
                        <Text style={{color: 'var(--dig-color__alert__on-surface)'}}>
                          <FormattedMessage
                            defaultMessage="Remove"
                            description="Text for the option on the access modal's dropdown menu. Selecting this option removes the person from the team project"
                            id="ycFE+1"
                          />
                        </Text>
                      </Menu.SelectItem>
                    </MenuItemWrapper>
                  </Menu.Segment>
                </Menu.Content>
              </>
            )}
          </Menu.Wrapper>
        )}
      </ShareOption>
    </UserSettings>
  );
};

export const ManageAccessModal = (props: ManageAccessModalProps) => {
  const {
    open,
    requestClose,
    teamFolderName,
    teamFolderId,
    shareRecipients,
    settingsChangeCallback,
    accessLevel,
    allowSuperAdminRights,
    logEvent,
    modalType,
  } = props;
  const intl = useIntl();
  const sessionContext = useReelAppGlobalState();
  const [isSnackbarOpen, setIsSnackbarOpen] = React.useState<boolean>(false);
  const [displayName, setDisplayName] = React.useState<string>();
  const [newAccessLevel, setNewAccessLevel] = React.useState<string>();
  const [succeeded, setSucceeded] = React.useState<boolean>(false);
  const [settingsChanged, setSettingsChanged] = React.useState<boolean>(false);
  const [recipients, setRecipients] = React.useState<ShareRecipient[]>(shareRecipients);
  const areSuperAdminFeaturesEnabled = useAreSuperAdminFeaturesEnabled();
  const currentUserAccountId = getCurrentUserAccountId(sessionContext);
  const [contacts, setContacts] = React.useState<TeamContactInfo[]>([]);
  const [selectedAccessLevel, setSelectedAccessLevel] =
    React.useState<AddMembersAccessType>('reviewer');
  const [addPeopleLoading, setAddPeopleLoading] = React.useState<boolean>(false);
  const [addPeopleSnackbarText, setAddPeopleSnackbarText] = React.useState<string>('');
  const loggingClient = useLoggingClient();
  const userVariant = useGetUserVariant();
  const getActionSurface = useActionSurface();
  const existingSharedFolderRecipients: FolderToAccounts | undefined = React.useMemo(() => {
    if (modalType === 'manage_access_only') {
      return undefined;
    }
    return {
      [teamFolderId]: {
        userAccounts: shareRecipients.reduce<{[accountId: string]: UserShareRecipient}>(
          (acc, recipient) => {
            if (isUserShareRecipient(recipient)) {
              acc[recipient.accountId] = recipient;
            }
            return acc;
          },
          {},
        ),
        groupAccounts: shareRecipients.reduce<{
          [accountId: string]: GroupShareRecipient;
        }>((acc, recipient) => {
          if (isGroupShareRecipient(recipient)) {
            acc[recipient.groupId] = recipient;
          }
          return acc;
        }, {}),
      },
    };
  }, [modalType, teamFolderId, shareRecipients]);

  // After adding a user, we want to clear the contents of the typeahead box
  const clearTypeaheadRef = React.useRef<() => void>(() => {});
  const logPapModalAction = React.useCallback(
    (
      modalAction: 'add' | 'remove' | 'can_edit' | 'can_comment' | undefined,
      existingUser?: 'new_user' | 'existing_user',
    ) => {
      loggingClient.logPap(
        PAP_Select_ModalAction({
          actionSurface: getActionSurface(),
          projectId: teamFolderId,
          modal: 'manage_people',
          modalAction: modalAction,
          actionDetail: existingUser,
          userVariant: userVariant,
        }),
      );
    },
    [getActionSurface, loggingClient, teamFolderId, userVariant],
  );

  React.useEffect(() => {
    if (open && areSuperAdminFeaturesEnabled && !!allowSuperAdminRights) {
      // eslint-disable-next-line deprecation/deprecation
      logEvent('super_admin_manage_access_modal_opened');
    }
  }, [open, allowSuperAdminRights, areSuperAdminFeaturesEnabled, logEvent]);

  const updateShareSettingsCallback = async (
    accountIdOrGroupId: string,
    displayName: string,
    accessLevel: ShareFolderAccessType,
    targetType: 'user' | 'group',
  ): Promise<boolean> => {
    let returnSuccess = false;
    setDisplayName(displayName);
    setNewAccessLevel(accessLevel);

    try {
      logPapModalAction(
        accessLevel === 'admin'
          ? 'can_edit'
          : accessLevel === 'reviewer'
          ? 'can_comment'
          : accessLevel === 'remove'
          ? 'remove'
          : undefined,
        'existing_user',
      );
      let newContactInfo: TeamContactInfo[];
      if (targetType === 'user') {
        newContactInfo = [
          {
            type: 'user',
            email: '',
            accountId: accountIdOrGroupId,
          },
        ];
      } else {
        newContactInfo = [
          {
            type: 'group',
            groupId: accountIdOrGroupId,
          },
        ];
      }

      await shareProject(teamFolderId, newContactInfo, accessLevel);
      returnSuccess = true;
      setSettingsChanged(true);
    } catch (e) {
      returnSuccess = false;
    }

    setIsSnackbarOpen(true);
    setSucceeded(returnSuccess);

    if (targetType === 'user' && accessLevel !== 'remove' && returnSuccess) {
      // eslint-disable-next-line deprecation/deprecation
      logEvent('fss_group_permission_updated');
    }

    if (accessLevel === 'remove' && returnSuccess) {
      if (targetType === 'user') {
        // eslint-disable-next-line deprecation/deprecation
        logEvent('succeed_remove_members_team_project', {
          num_members_removed: 1,
          num_members_total: recipients.length - 1,
        });
      } else {
        // eslint-disable-next-line deprecation/deprecation
        logEvent('fss_group_permission_removed');
      }

      setRecipients(
        recipients.filter(
          (s: ShareRecipient) =>
            (isUserShareRecipient(s) && s.accountId !== accountIdOrGroupId) ||
            (isGroupShareRecipient(s) && s.groupId !== accountIdOrGroupId),
        ),
      );
      settingsChangeCallback(teamFolderId);
    }

    return returnSuccess;
  };

  const takeOwnershipCallback = async (
    accountId: string,
    displayName: string,
    shouldTakeOwnership: boolean,
    shouldTakeOwnershipDirectly: boolean,
  ): Promise<boolean> => {
    setDisplayName(displayName);
    let returnSuccess = false;
    let oldOwner: UserShareRecipient | undefined = undefined;
    let targetOwner: UserShareRecipient | undefined = undefined;
    try {
      // If they're hitting this option, it means the SuperAdmin
      // is taking ownership of the folder themselves. We therefore
      // do not want to pass in the newAccountId.
      let targetAccountId: string | undefined = undefined;
      if (!shouldTakeOwnership) {
        // If we should not take ownership, we want to assign the perms
        // to the new person we're taking access from
        targetAccountId = accountId;
      }
      const entityType: reel.ChangeOwnershipRequestEntityType = {
        '.tag': 'folder',
      };
      await changeOwnership(teamFolderId, entityType, targetAccountId, shouldTakeOwnership);
      returnSuccess = true;
      setSettingsChanged(true);

      // Update our shareRecipients state - get the old and new owner
      oldOwner = recipients.filter(
        (s): s is UserShareRecipient => isUserShareRecipient(s) && s.accessLevel === 'owner',
      )[0];

      if (!shouldTakeOwnershipDirectly && targetAccountId) {
        // This means the SuperAdmin has hit `take ownership directly` instead of `remove and take ownership`
        setNewAccessLevel('admin');
        targetOwner = recipients.filter(
          (s): s is UserShareRecipient =>
            isUserShareRecipient(s) && s.accountId === targetAccountId,
        )[0];
      } else {
        setNewAccessLevel('owner');
        targetOwner = recipients.filter(
          (s): s is UserShareRecipient =>
            isUserShareRecipient(s) && s.accountId === currentUserAccountId,
        )[0];
      }

      setRecipients(
        recipients.map((recipient) => {
          if (recipient === targetOwner) {
            return {...recipient, accessLevel: 'owner'};
          }
          if (recipient === oldOwner) {
            return {...oldOwner, accessLevel: 'admin'};
          }
          return recipient;
        }),
      );
    } catch (e) {
      returnSuccess = false;
    }

    if (returnSuccess) {
      if (shouldTakeOwnership) {
        // eslint-disable-next-line deprecation/deprecation
        logEvent('super_admin_take_ownership_team_project');
      } else {
        // eslint-disable-next-line deprecation/deprecation
        logEvent('super_admin_reassign_ownership_team_project');
      }
    }

    setIsSnackbarOpen(true);
    setSucceeded(returnSuccess);
    return returnSuccess;
  };

  const closeSnackbar = React.useCallback(() => {
    setAddPeopleSnackbarText('');
  }, []);

  const addMembersCallback = React.useCallback(
    async (members: TeamContactInfo[], accessLevel: AddMembersAccessType) => {
      if (modalType === 'manage_access_only') {
        // We shouldn't be here
        return;
      }
      setAddPeopleLoading(true);
      try {
        logPapModalAction('add', 'new_user');
        const shareMessage = intl.formatMessage(
          accessLevel === 'admin'
            ? managePeopleModalStrings.canEditEmailMessage
            : managePeopleModalStrings.canViewEmailMessage,
        );
        await shareProject(teamFolderId, members, accessLevel, shareMessage);
        const snackbarMessage = intl.formatMessage(managePeopleModalStrings.membersAdded, {
          count: members.length,
        });
        setAddPeopleSnackbarText(snackbarMessage);
        clearTypeaheadRef.current();
      } catch (e) {
        const tag = e.error && e.error.error && e.error.error['.tag'];
        let errorSnackbarMessage: string;
        if (tag === 'emails_without_dropbox_account') {
          errorSnackbarMessage = intl.formatMessage(
            managePeopleModalStrings.failedToAddNonDropboxMembers,
          );
        } else if (
          [
            'sharing_is_restricted',
            'rate_limit_reached',
            'dmca_requester',
            'email_not_verified',
            'users_that_conflict_with_team_policies',
          ].includes(tag)
        ) {
          const requester = e.error.error['dmca_requester'];
          errorSnackbarMessage = getRestrictedContentErrorTextFromType(intl, tag, requester);
        } else {
          errorSnackbarMessage = intl.formatMessage(managePeopleModalStrings.failedToAddMembers);
        }
        setAddPeopleSnackbarText(errorSnackbarMessage);
      } finally {
        setAddPeopleLoading(false);
        setContacts([]);
        settingsChangeCallback(teamFolderId);
      }
    },
    [modalType, logPapModalAction, intl, teamFolderId, settingsChangeCallback],
  );

  const closeCallback = () => {
    if (settingsChanged) {
      settingsChangeCallback(teamFolderId);
      setSettingsChanged(false);
    }
  };

  React.useEffect(() => {
    setRecipients(shareRecipients);
  }, [shareRecipients]);

  const withCloseButtonText = intl.formatMessage({
    defaultMessage: 'Close manage access modal modal',
    id: 'fzNtFr',
    description: 'Aria label for the close button on the team project manage access modal',
  });

  const manageAccessModalTitle = intl.formatMessage(
    {
      defaultMessage: 'People added to {teamFolderName}',
      id: 'mK0jCP',
      description: 'Title on the manage access modal for a team project',
    },
    {
      teamFolderName: teamFolderName,
    },
  );

  return (
    <>
      <Modal
        data-testid="manage-access-modal"
        isCentered
        onAfterClose={closeCallback}
        onRequestClose={requestClose}
        open={open}
        withCloseButton={withCloseButtonText}
      >
        <ModalHeader hasBottomSpacing="title-small">
          {modalType === 'manage_access_combo' ? (
            <Modal.Title size="small" weightVariant="emphasized">
              <FormattedMessage
                {...managePeopleModalStrings.modalTitle}
                values={{folderName: teamFolderName}}
              />
            </Modal.Title>
          ) : (
            <ModalTitle>
              <Title size="small">{manageAccessModalTitle}</Title>
            </ModalTitle>
          )}
        </ModalHeader>
        <ModalBody>
          {modalType === 'manage_access_combo' && (
            <>
              <Text>
                <FormattedMessage {...managePeopleModalStrings.modalSubtitle} />
              </Text>
              <AddPeopleWrapper>
                <TypeaheadWrapper>
                  <DropboxAccountTypeahead
                    clearTypeaheadRef={clearTypeaheadRef}
                    existingContacts={contacts}
                    existingSharedFolderRecipients={existingSharedFolderRecipients}
                    inlineButton={
                      contacts.length > 0 ? (
                        <AccessLevelButton
                          selectedAccessLevel={selectedAccessLevel}
                          setSelectedAccessLevel={(accessLevel) => {
                            logPapModalAction(
                              accessLevel === 'admin' ? 'can_edit' : 'can_comment',
                              'new_user',
                            );
                            setSelectedAccessLevel(accessLevel);
                          }}
                        />
                      ) : undefined
                    }
                    inputLabelText={intl.formatMessage(managePeopleModalStrings.typeaheadAriaLabel)}
                    inputPlaceholderText={intl.formatMessage(
                      managePeopleModalStrings.typeaheadPlaceholderText,
                    )}
                    setContacts={setContacts}
                    sharedFolderId={teamFolderId}
                    type="team"
                  />
                </TypeaheadWrapper>
                <Button
                  disabled={contacts.length === 0}
                  isLoading={addPeopleLoading}
                  onClick={() => addMembersCallback(contacts, selectedAccessLevel)}
                  variant="primary"
                >
                  <FormattedMessage {...managePeopleModalStrings.addButton} />
                </Button>
              </AddPeopleWrapper>
            </>
          )}
          <MembersListWrapper>
            {recipients
              .sort((a, b) => (a.accessLevel === 'owner' ? -1 : b.accessLevel === 'owner' ? 1 : 0))
              .map((recipient) => {
                if (isUserShareRecipient(recipient)) {
                  const isCurrentUserRow = recipient.accountId === currentUserAccountId;

                  return (
                    recipient && (
                      <UserOrGroupRow
                        abbreviatedName={recipient.abbreviatedName}
                        accessLevel={recipient.accessLevel}
                        accountId={recipient.accountId}
                        amplitudeSafeTeamId={recipient.teamId}
                        avatarUrl={recipient.profilePhotoUrl}
                        disableAccessEdit={accessLevel === 'reviewer'}
                        displayName={recipient.displayName}
                        emailAddressOrMemberCnt={recipient.emailAddress}
                        isCallerSuperAdminWithRights={
                          areSuperAdminFeaturesEnabled && !!allowSuperAdminRights
                        }
                        isCurrentUserRow={isCurrentUserRow}
                        key={`recipient-${recipient.accountId}`}
                        takeOwnershipCallback={takeOwnershipCallback}
                        type="user"
                        updateShareSettingsCallback={updateShareSettingsCallback}
                      />
                    )
                  );
                } else {
                  return (
                    recipient && (
                      <UserOrGroupRow
                        abbreviatedName={recipient.displayName}
                        accessLevel={recipient.accessLevel}
                        accountId={recipient.groupId}
                        amplitudeSafeTeamId={recipient.amplitudeSafeTeamId}
                        avatarUrl={undefined}
                        disableAccessEdit={accessLevel === 'reviewer'}
                        displayName={recipient.displayName}
                        emailAddressOrMemberCnt={recipient.memberCount}
                        isCallerSuperAdminWithRights={
                          areSuperAdminFeaturesEnabled && !!allowSuperAdminRights
                        }
                        isCurrentUserRow={false}
                        key={`recipient-${recipient.groupId}`}
                        takeOwnershipCallback={takeOwnershipCallback}
                        type="group"
                        updateShareSettingsCallback={updateShareSettingsCallback}
                      />
                    )
                  );
                }
              })}
          </MembersListWrapper>
        </ModalBody>
      </Modal>
      <ReelSnackbar
        onRequestClose={() => setIsSnackbarOpen(false)}
        open={isSnackbarOpen}
        timeout={3000}
      >
        <Snackbar.Message>
          {succeeded
            ? newAccessLevel === 'remove'
              ? `Removed ${displayName} from ${teamFolderName}`
              : `Updated access permissions for ${displayName} to ${newAccessLevel}`
            : `Failed to update access permissions for ${displayName}`}
        </Snackbar.Message>
      </ReelSnackbar>
      <AddPeopleSnackbar onClose={closeSnackbar} snackbarText={addPeopleSnackbarText} />
    </>
  );
};

type AccessLevelButtonProps = {
  selectedAccessLevel: AddMembersAccessType;
  setSelectedAccessLevel: (accessLevel: AddMembersAccessType) => void;
};
const AccessLevelButton: React.FC<AccessLevelButtonProps> = ({
  selectedAccessLevel,
  setSelectedAccessLevel,
}) => {
  const handleAccessLevelMenuToggle = React.useCallback(() => {
    //TODO: add logging with REEL-4430
  }, []);

  return (
    <Menu.Wrapper onSelection={setSelectedAccessLevel} onToggle={handleAccessLevelMenuToggle}>
      {({getContentProps, getTriggerProps}) => (
        <>
          <Button {...getTriggerProps()} variant="transparent" withDropdownIcon>
            <Text isBold={true} size="small">
              {selectedAccessLevel === 'admin' ? (
                <FormattedMessage {...managePeopleModalStrings.canEdit} />
              ) : (
                <FormattedMessage {...managePeopleModalStrings.canComment} />
              )}
            </Text>
          </Button>
          <Menu.Content {...getContentProps()} placement="bottom-end">
            <Menu.Segment>
              <MenuItemWrapper>
                <Menu.SelectItem key={0} selected={selectedAccessLevel === 'admin'} value="admin">
                  <Text>
                    <FormattedMessage {...managePeopleModalStrings.canEdit} />
                  </Text>
                  <br />
                  <Text color="faint" size="small">
                    <FormattedMessage {...managePeopleModalStrings.canEditDescription} />
                  </Text>
                </Menu.SelectItem>
              </MenuItemWrapper>
              <MenuItemWrapper>
                <Menu.SelectItem
                  key={1}
                  selected={selectedAccessLevel === 'reviewer'}
                  value="reviewer"
                >
                  <Text>
                    <FormattedMessage {...managePeopleModalStrings.canComment} />
                  </Text>
                  <br />
                  <Text color="faint" size="small">
                    <FormattedMessage {...managePeopleModalStrings.canCommentDescription} />
                  </Text>
                </Menu.SelectItem>
              </MenuItemWrapper>
            </Menu.Segment>
          </Menu.Content>
        </>
      )}
    </Menu.Wrapper>
  );
};

export const managePeopleModalStrings = defineMessages({
  closeButton: {
    defaultMessage: 'Close manage access modal modal',
    id: 'fzNtFr',
    description: 'Aria label for the close button on the team project manage access modal',
  },
  modalTitle: {
    defaultMessage: 'Add people to {folderName}',
    id: 'R1S9uS',
    description: 'Title of the Add people modal',
  },
  modalSubtitle: {
    defaultMessage: 'Add people to this project so you can work together.',
    id: 'ebPIzY',
    description: 'Subtitle directing the user to add people to the project.',
  },
  typeaheadAriaLabel: {
    defaultMessage: 'Person or group to add',
    id: 'xSZJ23',
    description: 'Aria label for the typeahead in the manage people modal',
  },
  typeaheadPlaceholderText: {
    defaultMessage: 'Email, name, or group',
    id: 'maMaPh',
    description: 'Placeholder text for the typeahead in the manage people modal',
  },
  addButton: {
    defaultMessage: 'Add',
    id: 'MN85VJ',
    description: 'Text for the add button in the manage people modal',
  },
  canEdit: {
    defaultMessage: 'Can edit',
    id: '0MzFLr',
    description: 'Access level for a user who can edit a project',
  },
  canEditDescription: {
    defaultMessage: 'People can manage project files and give frame-by-frame feedback.',
    id: 'uLbe14',
    description:
      "Description for the 'Can edit' option in the access modal's dropdown menu. This text explains the permissions granted to a person with the Admin role.",
  },
  canEditDescriptionV2: {
    defaultMessage:
      'People can manage project files, give frame-by-frame feedback, and post editor-only comments.',
    id: 'VgLrVW',
    description:
      "Description for the 'Can edit' option in the access modal's dropdown menu. This text explains the permissions granted to a person with the Admin role.",
  },
  canComment: {
    defaultMessage: 'Can comment',
    id: 'gNfdNM',
    description: 'Access level for a user who can comment on a project',
  },
  canCommentDescription: {
    defaultMessage: 'People can view project files and give frame-by-frame feedback.',
    id: 'iy0bbJ',
    description:
      "Description for the 'Can comment' option in the access modal's dropdown menu. This text explains the permissions granted to a person with the Reviewer role.",
  },
  canEditEmailMessage: {
    defaultMessage:
      'As a project member, you can manage project files and give frame-by-frame feedback.',
    id: 'JxQMZ4',
    description:
      'Default text that is sent in an email along with an invite to add people to a project with edit permissions. Having edit access gives users access to files and folders inside the project, ability to leave feedback and the ability to manage files and folders.',
  },
  canEditEmailMessageV2: {
    defaultMessage:
      'You can manage files, give frame-by-frame feedback, and post editor-only comments.',
    id: 'PU7uud',
    description:
      'Default text that is sent in an email along with an invite to add people to a project with edit permissions. Having edit access gives users access to files and folders inside the project, ability to leave feedback, ability to leave private comments, and the ability to manage files and folders.',
  },
  canViewEmailMessage: {
    defaultMessage: 'Now you can view project files and give frame-by-frame feedback.',
    id: '1jJKMr',
    description:
      'Default text that is sent in an email along with an invite to add people to a project with view permissions. Having view-only access gives users access to files and folders inside the project and the ability to leave feedback.',
  },
  canViewEmailMessageV2: {
    defaultMessage: 'You can view files in this project and leave frame-by-frame feedback.',
    id: 'WRiRyc',
    description:
      'Default text that is sent in an email along with an invite to add people to a project with view permissions. Having view-only access gives users access to files and folders inside the project and the ability to leave feedback.',
  },
  membersAdded: {
    defaultMessage: '{count, plural, one {Person} other {People}} added',
    id: '733aAl',
    description: 'Message displayed when members are added to a project',
  },
  failedToAddMembers: {
    defaultMessage: "Couldn't add people. Please try again.",
    id: 'W5khk1',
    description:
      'A message that is displayed in the snackbar when a user attempts to add another user to project and there is a failure in the request.',
  },
  failedToAddNonDropboxMembers: {
    defaultMessage: '⚠ You can only invite users with a Dropbox account at this time.',
    id: 'qvfo8Y',
    description:
      'An error that is displayed when a user attempts to add another user to project that does not have a Dropbox account.',
  },
  canEditSelectionDescription: {
    defaultMessage:
      'People can manage files, give frame-by-frame feedback, and leave editor-only comments.',
    id: '5hljNy',
    description:
      'Description for the "Can edit" option in the access modal. This text explains the permissions granted to a person with the Edit role.',
  },
  canViewSelectionDescription: {
    defaultMessage: 'People can view files in this project and leave frame-by-frame feedback.',
    id: 'b26ntF',
    description:
      'Description for the "Can comment" option in the access modal. This text explains the permissions granted to a person with the View role.',
  },
});
