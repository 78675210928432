import React, {useCallback, useEffect, useMemo, useRef} from 'react';

import {useAtom, useAtomValue, useSetAtom} from 'jotai';
import type {MessageDescriptor} from 'react-intl';
import {defineMessages} from 'react-intl';

import {LottieAnimation, LottieAnimationType} from '~/components/lottie_animation';
import {useOnboardingContext} from '~/components/onboarding_v2/onboarding_provider';
import {MediaType} from '~/lib/api';
import type {LoggingClient} from '~/lib/logging/logger';
import {
  completedStepsAtom,
  currOpenStepAtom,
  hasCompletedOnboardingAtom,
  isChecklistOpenAtom,
  isWelcomeModalOpenAtom,
  lastCompletedStepAtom,
  ONBOARDING_OPEN_SOURCE,
  ONBOARDING_TYPE,
  onboardingStepsAtom,
  onboardingTypeAtom,
  setOnboardingStepsAtom,
  setOnboardingTypeAndStepsAtom,
  STEPS,
  stepsToDoAtom,
} from '~/state/onboarding_v2';
import type {OnboardingState} from '~/state/onboarding_v2';

import {isPostPurchaseModalOpenAtom} from '../../state/user';

const stepsMessages = defineMessages({
  viewFileTitle: {
    defaultMessage: 'View your file',
    id: 'dd1VxI',
    description: 'Step encouraging user to view their file',
  },
  drawOnFileTitle: {
    defaultMessage: 'Draw on your file',
    id: 'WHP/Xy',
    description: 'Step title encouraging user to draw on their file',
  },
  drawOnFileDescription: {
    defaultMessage: 'Hover over the file or select the pencil tool to draw.',
    id: 'P9vRV7',
    description: 'Instructions for user to draw on their file',
  },
  leaveACommentTitle: {
    defaultMessage: 'Leave a comment',
    id: 'LZ0bJn',
    description: 'Step title encouraging user to leave a comment',
  },
  leaveACommentDescription: {
    defaultMessage: 'Click “post” to comment.',
    id: 'tGT/6o',
    description: 'Instructions for user to leave a comment',
  },
  uploadFileTitle: {
    defaultMessage: 'Upload a file',
    id: 'Vi6Gtd',
    description: 'Step title encouraging users to upload a file',
  },
  uploadFileDescription: {
    defaultMessage: 'Experiment with your own file.',
    id: 'Qj9NxS',
    description: 'Step description encouraging users to upload a file',
  },
  shareFileTitle: {
    defaultMessage: 'Share your file',
    id: '96SOP/',
    description: 'Step title encouraging users to share their file',
  },
  shareFileDescription: {
    defaultMessage: 'Try sharing the file with yourself.',
    id: 'N9+aT7',
    description: 'Step description encouraging users to share their file',
  },
});

type StepInfo = {
  title: MessageDescriptor;
  description?: MessageDescriptor;
  actionButton?: React.ReactElement;
  aboveContent?: React.ReactElement;
  dataSafeToUnmaskName?: string;
};
const AVAILABLE_STEPS: {[key: string]: StepInfo} = {
  [STEPS.VIEW_FILE]: {
    aboveContent: <LottieAnimation animationType={LottieAnimationType.VIEW_FILE} />,
    title: stepsMessages.viewFileTitle,
    dataSafeToUnmaskName: 'onboarding-view-your-file',
  },
  [STEPS.DRAW_ON_FILE]: {
    aboveContent: <LottieAnimation animationType={LottieAnimationType.DRAW_ON_FILE} />,
    description: stepsMessages.drawOnFileDescription,
    title: stepsMessages.drawOnFileTitle,
    dataSafeToUnmaskName: 'onboarding-draw-on-your-file',
  },
  [STEPS.COMMENT_ON_FILE]: {
    aboveContent: <LottieAnimation animationType={LottieAnimationType.COMMENT_ON_FILE} />,
    description: stepsMessages.leaveACommentDescription,
    title: stepsMessages.leaveACommentTitle,
    dataSafeToUnmaskName: 'onboarding-leave-comment',
  },
  [STEPS.UPLOAD_FILE]: {
    aboveContent: <LottieAnimation animationType={LottieAnimationType.UPLOAD_FILE} />,
    description: stepsMessages.uploadFileDescription,
    title: stepsMessages.uploadFileTitle,
    dataSafeToUnmaskName: 'onboarding-upload-file',
  },
  [STEPS.SHARE_FILE]: {
    aboveContent: <LottieAnimation animationType={LottieAnimationType.SHARE_FILE} />,
    description: stepsMessages.shareFileDescription,
    title: stepsMessages.shareFileTitle,
    dataSafeToUnmaskName: 'onboarding-share-file',
  },
};

const ONBOARDING_STEPS_BY_TYPE: {[key: string]: STEPS[]} = {
  [ONBOARDING_TYPE.AUDIO_WITH_FILE]: [STEPS.VIEW_FILE, STEPS.COMMENT_ON_FILE, STEPS.SHARE_FILE],
  [ONBOARDING_TYPE.IMAGE_WITH_FILE]: [
    STEPS.VIEW_FILE,
    STEPS.DRAW_ON_FILE,
    STEPS.COMMENT_ON_FILE,
    STEPS.SHARE_FILE,
  ],
  [ONBOARDING_TYPE.VIDEO_WITH_FILE]: [
    STEPS.VIEW_FILE,
    STEPS.DRAW_ON_FILE,
    STEPS.COMMENT_ON_FILE,
    STEPS.SHARE_FILE,
  ],
  [ONBOARDING_TYPE.VIDEO_WITHOUT_FILE]: [
    STEPS.VIEW_FILE,
    STEPS.DRAW_ON_FILE,
    STEPS.COMMENT_ON_FILE,
    STEPS.UPLOAD_FILE,
    STEPS.SHARE_FILE,
  ],
};

const getPercent = (completedSteps: STEPS[], stepsToDo: STEPS[]) => {
  const matchingCompletedSteps = completedSteps.filter((completedStep) =>
    stepsToDo.includes(completedStep),
  );
  return Math.floor((matchingCompletedSteps.length / stepsToDo.length) * 100) || 0;
};

type Props = {
  logEvent?: LoggingClient['logEvent'];
};
const useOnboardingChecklist = ({logEvent: rawLogEvent}: Props) => {
  const {updateOnboardingActions} = useOnboardingContext();

  const [isChecklistOpen, setIsChecklistOpen] = useAtom(isChecklistOpenAtom);
  const [hasCompletedOnboarding, setHasCompletedOnboarding] = useAtom(hasCompletedOnboardingAtom);

  const onboardingSteps = useAtomValue(onboardingStepsAtom);
  const completedSteps = useAtomValue(completedStepsAtom);
  const stepsToDo = useAtomValue(stepsToDoAtom);
  const onboardingType = useAtomValue(onboardingTypeAtom);
  const currOpenStep = useAtomValue(currOpenStepAtom);
  const isPostPurchaseModalOpen = useAtomValue(isPostPurchaseModalOpenAtom);

  const setOnboardingSteps = useSetAtom(setOnboardingStepsAtom);
  const setOnboardingTypeAndSteps = useSetAtom(setOnboardingTypeAndStepsAtom);
  const setIsWelcomeModalOpen = useSetAtom(isWelcomeModalOpenAtom);
  const setLastCompletedStep = useSetAtom(lastCompletedStepAtom);

  const logEventRef = useRef(rawLogEvent);
  useEffect(() => {
    logEventRef.current = rawLogEvent;
  }, [rawLogEvent]);

  const percentComplete = useMemo(
    () => getPercent(completedSteps, stepsToDo),
    [completedSteps, stepsToDo],
  );

  const handleLogEvent: LoggingClient['logEvent'] = useCallback((eventType, ...attributes) => {
    // eslint-disable-next-line deprecation/deprecation
    logEventRef.current?.(eventType, ...attributes);
  }, []);

  const logOpenChecklistEvent = useCallback(
    (source: ONBOARDING_OPEN_SOURCE) => {
      // eslint-disable-next-line deprecation/deprecation
      handleLogEvent('shown_onboarding_checklist', {
        current_step: currOpenStep || 'NONE',
        num_steps_completed: completedSteps.length,
        onboarding_type: onboardingType as ONBOARDING_TYPE,
        percent_completed: percentComplete,
        source,
      });
    },
    [completedSteps, currOpenStep, handleLogEvent, onboardingType, percentComplete],
  );

  const handleCloseChecklist = React.useCallback(() => {
    setIsChecklistOpen(false);
  }, [setIsChecklistOpen]);

  const handleOpenChecklist = React.useCallback(
    (source?: ONBOARDING_OPEN_SOURCE) => {
      if (source) {
        logOpenChecklistEvent(source);
      }
      setIsChecklistOpen(true);
    },
    [logOpenChecklistEvent, setIsChecklistOpen],
  );

  const handleToggleChecklist = useCallback(
    (source?: ONBOARDING_OPEN_SOURCE) => {
      if (isChecklistOpen) {
        handleCloseChecklist();
      } else {
        handleOpenChecklist(source);
      }
    },
    [handleCloseChecklist, handleOpenChecklist, isChecklistOpen],
  );

  const handleUpdateOnboardingState = useCallback(
    (updatedState: Partial<OnboardingState>) => {
      (async () => {
        await updateOnboardingActions({
          onboarding_v2_type: updatedState.onboardingType
            ? {'.tag': updatedState.onboardingType}
            : undefined,
          has_completed_onboarding_v2: updatedState.hasCompletedOnboarding || undefined,
          has_completed_view_file_step: updatedState.onboardingSteps?.view_file || undefined,
          has_completed_draw_on_file_step: updatedState.onboardingSteps?.draw_on_file || undefined,
          has_completed_comment_on_file_step:
            updatedState.onboardingSteps?.comment_on_file || undefined,
          has_completed_upload_file_step: updatedState.onboardingSteps?.upload_file || undefined,
          has_completed_share_file_step: updatedState.onboardingSteps?.share_file || undefined,
        });
      })();
    },
    [updateOnboardingActions],
  );

  // Called upon step completion
  const handleCompleteStep = useCallback(
    (step: STEPS, autoShowChecklist: boolean = true) => {
      if (onboardingType && !hasCompletedOnboarding && stepsToDo.includes(step)) {
        let updatedSteps = onboardingSteps;
        let updatedCompletedSteps: STEPS[] = completedSteps;
        let updatedPercentComplete = percentComplete;
        let nextStepToComplete = currOpenStep;
        const hasCompletedStep = completedSteps.includes(step);
        setLastCompletedStep(step);

        if (!hasCompletedStep) {
          updatedSteps = {...onboardingSteps, [step]: true};
          nextStepToComplete = setOnboardingSteps(updatedSteps);
          updatedCompletedSteps = [...completedSteps, step];
          updatedPercentComplete = getPercent(updatedCompletedSteps, stepsToDo);

          // eslint-disable-next-line deprecation/deprecation
          handleLogEvent('complete_onboarding_step', {
            num_steps_completed: updatedCompletedSteps.length,
            onboarding_type: onboardingType,
            percent_completed: updatedPercentComplete,
            step_completed: step,
          });
        }

        const updatedHasCompletedOnboarding = stepsToDo.every((step) =>
          updatedCompletedSteps.includes(step),
        );

        if (updatedHasCompletedOnboarding) {
          setHasCompletedOnboarding(true);

          // eslint-disable-next-line deprecation/deprecation
          handleLogEvent('complete_onboarding', {
            onboarding_type: onboardingType,
            step_completed: step,
          });
        }

        handleUpdateOnboardingState({
          hasCompletedOnboarding: updatedHasCompletedOnboarding,
          onboardingSteps: updatedSteps,
        });

        if (!isChecklistOpen && autoShowChecklist && !hasCompletedStep) {
          handleToggleChecklist();

          // eslint-disable-next-line deprecation/deprecation
          handleLogEvent('shown_onboarding_checklist', {
            current_step: nextStepToComplete || 'NONE',
            num_steps_completed: updatedCompletedSteps.length,
            onboarding_type: onboardingType as ONBOARDING_TYPE,
            percent_completed: updatedPercentComplete,
            source: ONBOARDING_OPEN_SOURCE.STEP_COMPLETION,
          });
        }
      }
    },
    [
      completedSteps,
      currOpenStep,
      handleLogEvent,
      handleToggleChecklist,
      handleUpdateOnboardingState,
      hasCompletedOnboarding,
      isChecklistOpen,
      onboardingSteps,
      onboardingType,
      percentComplete,
      setHasCompletedOnboarding,
      setLastCompletedStep,
      setOnboardingSteps,
      stepsToDo,
    ],
  );

  // set onboarding steps and type based on media type passed through
  // primary usage is starting onboarding from the viewer page
  const handleSetOnboardingTypeByMediaType = useCallback(
    (mediaType?: MediaType, manualOnboardingType?: ONBOARDING_TYPE) => {
      if (!hasCompletedOnboarding && !onboardingType) {
        let type = manualOnboardingType;

        if (!type) {
          if (mediaType === MediaType.Image) {
            type = ONBOARDING_TYPE.IMAGE_WITH_FILE;
          } else if (mediaType === MediaType.Audio) {
            type = ONBOARDING_TYPE.AUDIO_WITH_FILE;
          } else {
            type = ONBOARDING_TYPE.VIDEO_WITH_FILE;
          }
        }

        setOnboardingTypeAndSteps({type, stepsToDo: ONBOARDING_STEPS_BY_TYPE[type]});
        handleUpdateOnboardingState({
          onboardingType: type,
        });

        if (!isPostPurchaseModalOpen) {
          setIsWelcomeModalOpen(true);
          // eslint-disable-next-line deprecation/deprecation
          handleLogEvent('shown_onboarding_welcome_modal', {
            onboarding_type: type,
          });
        }
      }
    },
    [
      handleLogEvent,
      handleUpdateOnboardingState,
      hasCompletedOnboarding,
      isPostPurchaseModalOpen,
      onboardingType,
      setIsWelcomeModalOpen,
      setOnboardingTypeAndSteps,
    ],
  );

  const handleCheckIsCurrentStepOpen = (stepToCheck: STEPS) => {
    return isChecklistOpen && currOpenStep === stepToCheck;
  };

  return {
    handleCheckIsCurrentStepOpen,
    handleCompleteStep,
    handleSetOnboardingTypeByMediaType,
    handleToggleChecklist,
    handleCloseChecklist,
    handleOpenChecklist,
    percentComplete,
  };
};

export {useOnboardingChecklist, AVAILABLE_STEPS, ONBOARDING_STEPS_BY_TYPE};
