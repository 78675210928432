import React from 'react';

import {useIntl} from 'react-intl';
import styled from 'styled-components';

import type {reel} from '@dropbox/api-v2-client';
import {LabelGroup} from '@dropbox/dig-components/combinations';
import {RadioButton} from '@dropbox/dig-components/controls';
import {FormControlGroup} from '@dropbox/dig-components/form_row';
import {Modal} from '@dropbox/dig-components/modal';
import {Title} from '@dropbox/dig-components/typography';
import {ThemeContainer, ThemeProvider} from '@dropbox/dig-foundations';

import {Button} from '~/components/button';

import {spacing} from '../styled';

export type DownloadOptionsModalProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: (option: reel.DownloadOption) => void;
  downloadOptions: reel.DownloadOption[];
  downloadType: 'folder' | 'files';
};

const ModalHeader = styled(Modal.Header)`
  padding: ${spacing('Macro Medium')} ${spacing('Macro Small')} ${spacing('Macro XSmall')}
    ${spacing('Macro Small')};
`;

const ModalTitle = styled(Title)`
  margin: ${spacing('0')};
`;

const OptionItem = styled.div`
  margin-bottom: ${spacing('Micro Small')};
`;

const RadioItem = styled(RadioButton)`
  margin: ${spacing('0')};
`;

const ItemGroup = styled(FormControlGroup)`
  margin: ${spacing('0')};
`;

export const DownloadOptionsModal: React.FC<DownloadOptionsModalProps> = ({
  open,
  onClose,
  onSubmit,
  downloadOptions,
  downloadType,
}: DownloadOptionsModalProps) => {
  const intl = useIntl();
  const [selectedOption, setSelectedOption] = React.useState<reel.DownloadOption>();

  React.useEffect(() => {
    if (!open) {
      setSelectedOption(undefined);
    }
  }, [open]);

  const folderDownloadStrings: Record<reel.DownloadOption['.tag'], string> = React.useMemo(
    () => ({
      all_files: intl.formatMessage({
        defaultMessage: 'All files and all versions in this project',
        id: 'uhNKs2',
        description: 'Text for the all files download option in the download options modal',
      }),
      approved_files: intl.formatMessage({
        defaultMessage: 'Only files with "Approved" status',
        id: 'TxEAim',
        description: 'Text for the approved files download option in the download options modal',
      }),
      latest_files: intl.formatMessage({
        defaultMessage: 'Only the latest version of files',
        id: 'ksYWHM',
        description: 'Text for the latest version download option in the download options modal',
      }),
      other: '',
    }),
    [intl],
  );

  const fileDownloadStrings: Record<reel.DownloadOption['.tag'], string> = React.useMemo(
    () => ({
      all_files: intl.formatMessage({
        defaultMessage: 'All files, including all versions',
        id: 'azOQur',
        description: 'Text for the all files download option in the download options modal',
      }),
      latest_files: intl.formatMessage({
        defaultMessage: 'Only the latest version',
        id: 'ygURGd',
        description: 'Text for the latest version download option in the download options modal',
      }),
      approved_files: '',
      other: '',
    }),
    [intl],
  );

  const downloadOptionString = React.useCallback(
    (option: reel.DownloadOption['.tag']) => {
      return downloadType === 'folder'
        ? folderDownloadStrings[option]
        : fileDownloadStrings[option];
    },
    [downloadType, fileDownloadStrings, folderDownloadStrings],
  );

  const withCloseButtonText = intl.formatMessage({
    defaultMessage: 'Close download options modal',
    id: 'aAKVQk',
    description:
      'A message shown as label for modal that will close the modal for selecting download options',
  });
  const downloadButtonText = intl.formatMessage({
    defaultMessage: 'Download',
    id: 'BVi+L0',
    description: 'Text for the Download button in the download options modal',
  });
  const cancelButtonText = intl.formatMessage({
    defaultMessage: 'Cancel',
    id: '73+srg',
    description: 'Text for the cancel button in the download options modal',
  });
  const modalTitle = intl.formatMessage({
    defaultMessage: 'Choose which files to download',
    id: '+8nkJG',
    description:
      'Text at the top of the download options modal that instructs the user to choose which files to download',
  });

  return (
    <ThemeProvider mode="dark" overrides="reset" theme="vis2023">
      <ThemeContainer>
        <Modal
          data-testid="download-options-modal"
          isCentered
          onRequestClose={onClose}
          open={open}
          withCloseButton={withCloseButtonText}
        >
          <ModalHeader hasBottomSpacing="title-small">
            <ModalTitle size="medium">{modalTitle}</ModalTitle>
          </ModalHeader>
          <Modal.Body
            onClick={(e) => {
              // stopPropagation is required here in case
              // this modal is rendered inside something that
              // is stopping default events *cough* context menu *cough*
              e.stopPropagation();
            }}
          >
            <ItemGroup>
              {downloadOptions.map((item, index) => {
                const message = downloadOptionString(item['.tag']);
                if (!message) {
                  return null;
                }
                return (
                  <OptionItem key={index}>
                    <LabelGroup
                      key={index}
                      tagName="label"
                      withLeftAccessory={
                        <RadioItem
                          checked={selectedOption === item}
                          data-testid={`download-option-${index}`}
                          name="download option"
                          onChange={() => setSelectedOption(item)}
                        />
                      }
                      withText={message}
                    />
                  </OptionItem>
                );
              })}
            </ItemGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onClose} variant="opacity">
              {cancelButtonText}
            </Button>
            <Button
              data-testid="download-options-submit"
              disabled={!selectedOption}
              onClick={
                selectedOption
                  ? () => {
                      onSubmit(selectedOption);
                    }
                  : undefined
              }
              variant="primary"
            >
              {downloadButtonText}
            </Button>
          </Modal.Footer>
        </Modal>
      </ThemeContainer>
    </ThemeProvider>
  );
};
