import {useCallback} from 'react';

import type {QueryClient} from '@tanstack/react-query';
import {useMutation, useQueries, useQuery, useQueryClient} from '@tanstack/react-query';
import memoize from 'lodash/memoize';

import type {Dropbox, users} from '@dropbox/api-v2-client';

import {patchContentsDisplayInfo} from '~/lib/query_updates';

import {
  type EntityMetadataType,
  folderNsId,
  getEntityMetadata,
  getFolderWithShareLink,
  getManagePeopleInfoForAssetVersion,
  getPendingReplayAddOnRequestsForTeam,
  getVideo,
  getVideoFromShortLink,
  listOnboardingActions,
  type MediaProp,
  searchContacts,
  ShareLinkDisabledError,
  ShareLinkLoggedOutAccessDisabledError,
  ShareLinkNotAuthedError,
  updateProject,
  type UpdateProjectArgs,
  type VersionSummariesIdArg,
} from './api';
import {AccountInfoBatcher} from './api';
import {
  DisplayInfoBatcher,
  getBillingCycle,
  getReplayEligibility,
  getVersionSummaries,
} from './api';
import {getDefaultUserClient} from './client';
import {queryClient, replayApi} from './query_client';

// Fetch the display info less aggressively so we don't overwhelm the endpoint
// with parallel batch requests
const DISPLAY_INFO_STALE_TIME = 1000 * 60 * 3; // (3 minutes)

// Do you want to memoize an object without dealing with useMemo dependencies?
const memoValue = memoize(
  (x) => x,
  (x) => JSON.stringify(x),
) as <T>(x: T) => T;

export const useDisplayInfoQueries = (
  publicIds: (string | undefined)[],
  enabled: boolean = true,
) => {
  return useQueries({
    // For unknown reasons, passing the enabled key in useQueries does not seem
    // to work; pass in an empty array if the query is disabled
    queries: enabled
      ? publicIds.map((publicId) => {
          // Assert that publicId is truthy since the query will be disabled if it
          // is not
          return {
            queryKey: replayApi.folderContentsDisplayInfo(publicId!),
            queryFn: () => DisplayInfoBatcher.fetch(publicId!),
            enabled: Boolean(publicId) && enabled,
            staleTime: DISPLAY_INFO_STALE_TIME,
          };
        })
      : [],
  });
};

export const useDisplayInfoQuery = (publicId: string, enabled: boolean) => {
  return useQuery({
    queryKey: replayApi.folderContentsDisplayInfo(publicId),
    queryFn: () => DisplayInfoBatcher.fetch(publicId),
    enabled: Boolean(publicId) && enabled,
    staleTime: DISPLAY_INFO_STALE_TIME,
  });
};

export const useGetReplayEligibilityQuery = (enabled: boolean = true) => {
  return useQuery({
    queryKey: replayApi.getReplayEligibility(),
    queryFn: getReplayEligibility,
    enabled: enabled,
  });
};

export const useGetManagePeopleInfoForAssetVersionQuery = (mediaMetadata: MediaProp) => {
  const assetInTeamProject = mediaMetadata.folderId !== undefined;
  const userHasEditAccessToParentTeamProject =
    mediaMetadata.accessLevel &&
    (mediaMetadata.accessLevel === 'admin' ||
      mediaMetadata.accessLevel === 'owner' ||
      mediaMetadata.accessLevel === 'super_admin');

  return useQuery({
    queryKey: replayApi.getManagePeopleInfoForAssetVersion(mediaMetadata.id),
    queryFn: () => {
      return getManagePeopleInfoForAssetVersion(mediaMetadata.id);
    },
    staleTime: 3 * 60 * 1000, // 3 minutes
    cacheTime: 3 * 60 * 1000, // 3 minutes
    enabled: assetInTeamProject && userHasEditAccessToParentTeamProject,
  });
};

export const useGetEntityMetadataQuery = (
  entityId: string,
  entityType: EntityMetadataType,
  enabled = true,
) => {
  return useQuery({
    queryKey: replayApi.entityMetadata(entityId, entityType),
    queryFn: () => getEntityMetadata(entityId, entityType),
    enabled,
  });
};

export const useGetBillingCycleQuery = (enabled: boolean = true) => {
  return useQuery({
    queryKey: replayApi.getBillingCycle(),
    queryFn: getBillingCycle,
    enabled: enabled,
  });
};

/**
 * This function is a stopgap while we're converting to using react-query.
 * This can be removed once all video apis use mutations
 */
export const useInvalidateVideoQueries = () => {
  const queryClient = useQueryClient();

  return useCallback(
    (videoId: string) => queryClient.invalidateQueries(replayApi.mediaProject(videoId)),
    [queryClient],
  );
};

export const useGetVideoQuery = (videoId: string, versionId?: string, enabled = true) => {
  return useQuery({
    queryKey: replayApi.getVideo(videoId, versionId),
    queryFn: () => getVideo(videoId, versionId),
    enabled,
  });
};

export const getVersionSummariesQuery = (params: VersionSummariesIdArg, grantBook?: string) => {
  return queryClient.fetchQuery({
    queryKey: replayApi.getVersionSummaries(params, grantBook),
    queryFn: () => getVersionSummaries(params, grantBook),
    staleTime: DISPLAY_INFO_STALE_TIME,
  });
};

export const useGetVersionSummariesQuery = ({
  enabled = true,
  params,
  grantBook,
}: {
  enabled?: boolean;
  params: VersionSummariesIdArg;
  grantBook?: string;
}) => {
  return useQuery({
    queryKey: replayApi.getVersionSummaries(params, grantBook),
    queryFn: () => getVersionSummaries(params, grantBook),
    enabled,
    staleTime: DISPLAY_INFO_STALE_TIME,
  });
};

export const useUserInfoQueries = (accountIds: string[]) => {
  const queries = useQueries({
    queries: accountIds.map((accountId) => ({
      queryKey: replayApi.userInfo(accountId),
      queryFn: () => AccountInfoBatcher.fetch(accountId),
    })),
  });
  return memoValue({
    isLoading: queries.some((query) => query.isLoading),
    isError: queries.some((query) => query.isError),
    isSuccess: queries.every((query) => query.isSuccess),
    data: queries.reduce<{[account_id: string]: users.BasicAccount}>((acc, userInfo) => {
      if (userInfo.data) {
        acc[userInfo.data.account_id] = userInfo.data;
      }
      return acc;
    }, {}),
  });
};

export const useFolderNsIdQuery = (folderId: string) => {
  return useQuery({
    queryKey: replayApi.folderNsId(folderId),
    queryFn: () => folderNsId(folderId),
    enabled: Boolean(folderId),
  });
};

export const useUpdateProjectMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (update: UpdateProjectArgs) => updateProject(update),
    onSuccess: (folder, update) => {
      if (folder) {
        patchContentsDisplayInfo(queryClient, update.folderId, {
          name: folder.name,
          branding: folder.branding,
        });
      }
    },
  });
};

export const useAvailableAddonsQuery = () => {
  return useQuery({
    queryKey: replayApi.availableAddons(),
    queryFn: () => getDefaultUserClient().growthPlansGetAvailableAddonsInfo({}),
  });
};

export const useAddonPreviewQuery = (offeringId: string | undefined) => {
  const offerId = offeringId ?? '';
  return useQuery({
    queryKey: replayApi.addonPreview(offerId),
    queryFn: () =>
      getDefaultUserClient().growthPlansGetAddonTransitionPreview({
        is_addition: true,
        offering_id: offerId,
        no_decimals_if_whole_number: false,
      }),
    enabled: !!offerId,
  });
};

export const useGrowthPlansPerformAddonTransitionMutation = () =>
  useMutation({
    mutationFn: (...props: Parameters<Dropbox['growthPlansPerformAddonTransition']>) =>
      getDefaultUserClient().growthPlansPerformAddonTransition(...props),
  });

export const usePendingAddonRequestQuery = ({enabled}: {enabled: boolean}) =>
  useQuery({
    queryKey: replayApi.getPendingReplayAddOnRequestsForTeam(),
    queryFn: getPendingReplayAddOnRequestsForTeam,
    enabled,
  });

export const useOnboardingQuery = (enabled = false) => {
  return useQuery({
    queryKey: replayApi.onboarding(),
    queryFn: listOnboardingActions,
    staleTime: 1000 * 60 * 60, // 1 hour (onboarding steps don't change often)
    enabled,
  });
};

export const useSearchContactsQuery = ({
  searchTerm,
  enabled = true,
}: {
  searchTerm: string;
  enabled: boolean;
}) => {
  return useQuery({
    queryKey: replayApi.searchContacts(searchTerm),
    queryFn: ({signal}) => searchContacts(searchTerm, 12, signal),
    enabled: enabled && Boolean(searchTerm),
  });
};

type GetVideoFromShortLinkArgs = {
  grantBook?: string;
  shareToken: string;
  videoId?: string;
  videoVersionId?: string;
};

export const getVideoFromShortLinkQueryConfig = ({
  grantBook,
  shareToken,
  videoId,
  videoVersionId,
}: GetVideoFromShortLinkArgs) => {
  return {
    queryKey: replayApi.getVideoFromShortLink(videoId, videoVersionId, shareToken, grantBook),
    queryFn: () =>
      getVideoFromShortLink({
        grantBook,
        shareToken,
        videoId,
        videoVersionId,
      }),
    retry: (failureCount: number, error: unknown) => {
      if (
        error instanceof ShareLinkNotAuthedError ||
        error instanceof ShareLinkLoggedOutAccessDisabledError ||
        error instanceof ShareLinkDisabledError
      ) {
        Object.defineProperty(error, 'handled', {value: true});
        return false;
      }
      return failureCount < 3;
    },
  };
};

export const useGetVideoFromShortLinkQuery = ({
  grantBook,
  shareToken,
  videoId,
  videoVersionId,
  enabled = true,
}: GetVideoFromShortLinkArgs & {
  enabled?: boolean;
}) => {
  return useQuery({
    ...getVideoFromShortLinkQueryConfig({
      grantBook,
      shareToken,
      videoId,
      videoVersionId,
    }),
    enabled,
  });
};

export const queryGetVideoFromShortLinkData = (
  queryClient: QueryClient,
  props: GetVideoFromShortLinkArgs,
) => {
  return queryClient.fetchQuery(getVideoFromShortLinkQueryConfig(props));
};

type FolderShareLinkQueryArgs = Parameters<typeof getFolderWithShareLink>[0];
const getFolderWithShareLinkConfig = ({folderId, ...rest}: FolderShareLinkQueryArgs) => ({
  queryKey: replayApi.getFolderWithShareLink(folderId, rest),
  queryFn: () => getFolderWithShareLink({folderId, ...rest}),
  retry: (failureCount: number, error: unknown) => {
    if (error instanceof ShareLinkNotAuthedError) {
      Object.defineProperty(error, 'handled', {value: true});
      return false;
    }
    return failureCount < 3;
  },
});

export const queryGetFolderWithShareLink = (
  queryClient: QueryClient,
  props: FolderShareLinkQueryArgs,
) => queryClient.fetchQuery(getFolderWithShareLinkConfig(props));
export const useGetFolderWithShareLinkQuery = ({
  enabled = true,
  ...rest
}: FolderShareLinkQueryArgs & {
  enabled?: boolean;
}) => {
  return useQuery({
    ...getFolderWithShareLinkConfig(rest),
    enabled,
  });
};
