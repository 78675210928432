import React from 'react';

// Hook that calls a function one time, when the component first renders.
export const useOnce = (f: () => void) => {
  const hasRun = React.useRef(false);
  const functionRef = React.useRef(f);
  React.useEffect(() => {
    if (!hasRun.current) {
      functionRef.current();

      // Keep track of runs to avoid react strict mode shenanigans.
      hasRun.current = true;
    }
  }, []);
};
