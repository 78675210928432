import React, {useSyncExternalStore} from 'react';

import {type GrantBook, grantBooks, type ShareToken} from '~/state/grantbooks';

import {reportBadContextUseError} from '../lib/error_reporting';

type PasswordCacheValue = Pick<typeof grantBooks, 'get' | 'set'>;

const PasswordCacheContext = React.createContext<PasswordCacheValue | null>(null);

export const PasswordCacheProvider = (props: {children: React.ReactNode}) => {
  return (
    <PasswordCacheContext.Provider value={grantBooks}>
      {props.children}
    </PasswordCacheContext.Provider>
  );
};

export const usePasswordCacheContext = (): PasswordCacheValue => {
  const value = React.useContext(PasswordCacheContext);
  if (!value) {
    const error = new Error('usePasswordCacheContext must be used within a PasswordCacheProvider');
    reportBadContextUseError(error);
    throw error;
  }
  return value;
};

export const useGrantBook = (shareToken?: string) => {
  const {get} = usePasswordCacheContext();

  const subscribe = (callback: () => void) => {
    const listener = (key: ShareToken, value: GrantBook) => {
      if (shareToken === key) {
        callback();
      }
    };

    return grantBooks.subscribe(listener);
  };

  const snapshot = () => get(shareToken);

  return useSyncExternalStore(subscribe, snapshot);
};
