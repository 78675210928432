import {useCallback, useEffect, useState} from 'react';

import type {Select_SettingAction} from 'pap-events/replay/select_setting_action';

import {useReelAppGlobalState} from '~/context';
import {setUserPreferenceSettingMetadata as setUserPreferenceMetadataAPI} from '~/lib/api';
import type {LoggingClient} from '~/lib/logging/logger';
import type {ToggleSettingsType} from '~/lib/logging/logger_types';
import type {PREFERENCE_SETTING_METADATA_FIELD} from '~/lib/provisions';
import {
  PreferenceSettingMetadataOFFValuesMap,
  PreferenceSettingMetadataONValuesMap,
} from '~/lib/provisions';

export interface SettingConfig {
  onToggle: () => void;
  isToggled: boolean;
  isSetSettingLoading: boolean;
}

export const useMetadataSetting = (
  metadataEnumName: PREFERENCE_SETTING_METADATA_FIELD,
  logEvent: LoggingClient['logEvent'],
  loggingType: ToggleSettingsType,
  papLogger?: LoggingClient['logPap'],
  papEvent?: Select_SettingAction,
): SettingConfig => {
  const sessionContext = useReelAppGlobalState();
  const preferenceSettingMetadata =
    sessionContext.status === 'logged in' ? sessionContext.preferenceSettingMetadata : undefined;

  const [toggleIsOn, setToggleIsOn] = useState(false);
  const [isSetMetadataLoading, setIsSetMetadataLoading] = useState(false);

  const OFF_VALUE = PreferenceSettingMetadataOFFValuesMap[metadataEnumName];
  const ON_VALUE = PreferenceSettingMetadataONValuesMap[metadataEnumName];

  useEffect(() => {
    if (preferenceSettingMetadata) {
      setToggleIsOn(preferenceSettingMetadata[metadataEnumName] === ON_VALUE);
    }
    // By leaving preferenceSettingMetadata from the useEffect dependency array, we ensure that the email notifications toggle is not reset with every toggle.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metadataEnumName, ON_VALUE]);

  const metadataToggleHandler = useCallback(async () => {
    if (isSetMetadataLoading) {
      return;
    }
    const newSubscribedValue = !toggleIsOn;
    // eslint-disable-next-line deprecation/deprecation
    logEvent('toggle_setting', {
      setting_name: loggingType,
      new_value: newSubscribedValue ? 'on' : 'off',
    });
    if (papLogger && papEvent) {
      papEvent.properties = {...papEvent.properties, isToggled: newSubscribedValue};
      papLogger(papEvent);
    }

    // Toggle the setting optimistically and revert if the API call fails
    setToggleIsOn(newSubscribedValue);
    setIsSetMetadataLoading(true);
    await setUserPreferenceMetadataAPI({
      [metadataEnumName]: newSubscribedValue ? ON_VALUE : OFF_VALUE,
    })
      .catch(() => {
        setToggleIsOn(!newSubscribedValue);
      })
      .finally(() => {
        setIsSetMetadataLoading(false);
        if (sessionContext.status === 'logged in') {
          sessionContext.setPreferenceSettingMetadata(metadataEnumName, newSubscribedValue);
        }
      });
  }, [
    isSetMetadataLoading,
    toggleIsOn,
    logEvent,
    loggingType,
    metadataEnumName,
    ON_VALUE,
    OFF_VALUE,
    sessionContext,
    papLogger,
    papEvent,
  ]);

  return {
    onToggle: metadataToggleHandler,
    isToggled: toggleIsOn,
    isSetSettingLoading: isSetMetadataLoading,
  };
};
