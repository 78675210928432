import {atom} from 'jotai';
import {atomWithReducer} from 'jotai/utils';

import type {reel} from '@dropbox/api-v2-client';

import type {
  CopyRequestState,
  ShareLinkPermission,
} from '~/components/share_modal/share_modal_common';
import type {LinkInfo, LinkInfoResult, WatermarkLinkInfo} from '~/lib/api';
import type {LoggingClient} from '~/lib/logging/logger';
import type {LinkItemTypes, ShareButtonLocationType} from '~/lib/logging/logger_types';
import {getVideoVersionId} from '~/lib/utils';

type ShareInfo = ShareItem & ShareModalLoggingInfo & LinkInfoResult;

type ShareItem = ShareFile | ShareFolder;

type ShareFile = {
  type: 'file';
  id: string; // video ID
  name: string;
  defaultVersion: number; // The version currently being viewed
  versionSummaries: Array<reel.VersionSummary>;
  thumbnailUrl?: string;
};

type ShareFolder = {
  type: 'folder';
  id: string; // folder id
  name: string;
};

type ShareModalLoggingInfo = {
  logEvent: LoggingClient['logEvent'];
  isDemo: boolean;
  isLiveReview: boolean;
  location: ShareButtonLocationType;
  linkItemType: LinkItemTypes;
};

type ShareModalContents = ShareHome | DueDateSettings | BasicLinkSettings | WatermarkLinkSettings;

type ShareHome = BasicHome | WatermarkHome;

type BasicHome = {
  type: 'share-home';
  tab: 'original';
  selectedPermission: ShareLinkPermission;
  selectedVersion: number;
};

type WatermarkHome = {
  type: 'share-home';
  tab: 'watermark';
};

type BasicLinkSettings = {
  type: 'basic-link-settings';
  selectedPermission: ShareLinkPermission;
  selectedVersion: number;
};

type WatermarkLinkSettings = {
  type: 'watermark-link-settings';
};

type DueDateSettings = {
  type: 'due-date-settings';
};

function isBasicLink(
  shareModalState: ShareModalContents | null,
): shareModalState is BasicHome | BasicLinkSettings {
  return (
    (shareModalState?.type === 'share-home' && shareModalState.tab === 'original') ||
    shareModalState?.type === 'basic-link-settings'
  );
}

export function isViewOnlyLink(
  shareModalState: ShareModalContents | null,
): shareModalState is BasicHome | BasicLinkSettings {
  return isBasicLink(shareModalState) && shareModalState.selectedPermission === 'view-only';
}

export function getSelectedPermission(shareModalState: ShareModalContents | null) {
  return isBasicLink(shareModalState) ? shareModalState.selectedPermission : undefined;
}

export function getSelectedVersionNum(shareModalState: ShareModalContents | null) {
  return isViewOnlyLink(shareModalState) ? shareModalState.selectedVersion : undefined;
}

// ----------------- Share Modal State  -----------------
// Local because they are only set through the initializeShareModalAtom
const shareItemAtom = atom<ShareItem | null>(null);
const shareModalLoggingInfoAtom = atom(<ShareModalLoggingInfo>{});

// Local because it is only accessed through getters and setters
const newlyCreatedShareTokensAtom = atom<Array<string>>([]);

// Exported because they can also be set after the modal is initialized
// e.g. refreshing links, closing the modal, or updating which view is shown in the modal
export const basicShareLinksForItemAtom = atom<Array<LinkInfo>>([]);
export const watermarkLinksForItemAtom = atom<Array<WatermarkLinkInfo>>([]);
export const isShareModalOpenAtom = atom(false);
export const shareModalContentsAtom = atom<ShareModalContents | null>(null);
export const currentWatermarkLinkAtom = atom<WatermarkLinkInfo | undefined>(undefined);
export const sharingIsRestrictedByTeamPolicyAtom = atom(false);
export const viewPreviousCommentsDisabledAtom = atom(false);

// Getters
export const getShareItemAtom = atom((get) => get(shareItemAtom));
export const getShareModalLoggingInfoAtom = atom((get) => get(shareModalLoggingInfoAtom));
export const getNewlyCreatedShareTokensAtom = atom((get) => get(newlyCreatedShareTokensAtom));

export const currentBasicLinkAtom = atom((get) => {
  // Gets the view-only or review link based on the modal settings
  const basicLinks = get(basicShareLinksForItemAtom);
  const shareModalState = get(shareModalContentsAtom);
  const shareItem = get(shareItemAtom);

  if (!shareItem || !shareModalState) {
    return undefined;
  }

  switch (shareModalState.type) {
    case 'share-home':
      if (shareModalState.tab === 'original') {
        const isViewOnly = shareModalState.selectedPermission === 'view-only';
        return getBasicCurrentLink(
          shareItem,
          basicLinks,
          isViewOnly,
          shareModalState.selectedVersion,
        );
      }
      if (shareModalState.tab === 'watermark') {
        // There can be unlimited number of watermark links, so there is no one current link
        return undefined;
      }
    case 'basic-link-settings':
      return getBasicCurrentLink(
        shareItem,
        basicLinks,
        shareModalState.selectedPermission === 'view-only',
        shareModalState.selectedVersion,
      );
    case 'watermark-link-settings':
    case 'due-date-settings': // Due dates apply to the whole video, so there is no current link
    default:
      return undefined;
  }
});

const getBasicCurrentLink = (
  shareItem: ShareItem,
  basicLinks: LinkInfo[],
  isViewOnly: boolean,
  selectedVersion: number,
) => {
  let linkBeingViewed;
  if (shareItem.type === 'folder' || !isViewOnly) {
    linkBeingViewed = basicLinks.find((linkInfo) =>
      isViewOnly ? linkInfo.viewOnly : !linkInfo.viewOnly,
    );
  } else {
    // For view-only file links, there can be one link for each version
    // Currently the version number isn't included in the link info, so we need to search for the right version
    const videoVersionId = getVideoVersionId(shareItem.versionSummaries, selectedVersion);

    linkBeingViewed = videoVersionId
      ? basicLinks.find((linkInfo) => {
          const url = new URL(linkInfo.link);
          const params = decodeURIComponent(url.search);
          return linkInfo.viewOnly && params.includes(videoVersionId);
        })
      : undefined;
  }
  return linkBeingViewed;
};

// Setters
export const initializeShareModalAtom = atom(null, (get, set, shareInfo: ShareInfo) => {
  if (shareInfo.type === 'file') {
    set(shareItemAtom, {
      type: shareInfo.type,
      id: shareInfo.id,
      name: shareInfo.name,
      defaultVersion: shareInfo.defaultVersion,
      versionSummaries: shareInfo.versionSummaries,
      thumbnailUrl: shareInfo.thumbnailUrl,
    });
    set(shareModalContentsAtom, {
      type: 'share-home',
      tab: 'original',
      selectedPermission: 'review',
      selectedVersion: shareInfo.defaultVersion,
    });
  } else {
    set(shareItemAtom, {
      type: shareInfo.type,
      id: shareInfo.id,
      name: shareInfo.name,
    });
    set(shareModalContentsAtom, {
      type: 'share-home',
      tab: 'original',
      selectedPermission: 'review',
      selectedVersion: 1,
    });
  }

  set(shareModalLoggingInfoAtom, {
    logEvent: shareInfo.logEvent,
    location: shareInfo.location,
    isDemo: shareInfo.isDemo,
    isLiveReview: shareInfo.isLiveReview,
    linkItemType: shareInfo.linkItemType,
  });

  set(basicShareLinksForItemAtom, shareInfo.basicLinks);
  set(watermarkLinksForItemAtom, shareInfo.watermarkLinks);
  set(newlyCreatedShareTokensAtom, []);
  set(currentWatermarkLinkAtom, undefined);
  set(sharingIsRestrictedByTeamPolicyAtom, shareInfo.sharingIsRestrictedByTeamPolicy ?? false);
});

export const updateShareModalPermissionAtom = atom(
  null,
  (get, set, newPermission: ShareLinkPermission) => {
    const shareModalState = get(shareModalContentsAtom);
    if (!shareModalState || !isBasicLink(shareModalState)) {
      return;
    }

    set(shareModalContentsAtom, {...shareModalState, selectedPermission: newPermission});
  },
);

export const updateShareModalVersionAtom = atom(null, (get, set, newVersion: number) => {
  const shareModalState = get(shareModalContentsAtom);
  if (
    !shareModalState ||
    shareModalState.type !== 'share-home' ||
    (shareModalState.type === 'share-home' && shareModalState.tab !== 'original')
  ) {
    return;
  }

  set(shareModalContentsAtom, {...shareModalState, selectedVersion: newVersion});
});

export const updateNewlyCreatedShareTokensAtom = atom(null, (get, set, newToken: string[]) => {
  const currentTokens = get(newlyCreatedShareTokensAtom);
  set(newlyCreatedShareTokensAtom, [...currentTokens, ...newToken]);
});

// ----------------- Copy Link Request State  -----------------

const copyRequestReducer = (prev: CopyRequestState, update: CopyRequestState) => {
  if (prev.requestId > update.requestId) {
    return prev;
  }
  return update;
};

export const copyRequestAtom = atomWithReducer(
  {
    requestId: 0,
    request: {type: 'none'},
  },
  copyRequestReducer,
);

export const clearCopyRequestAtom = atom(null, (get, set) => {
  const currentRequest = get(copyRequestAtom);
  set(copyRequestAtom, {requestId: currentRequest.requestId, request: {type: 'none'}});
});

//  ----------------- Error Snackbar State  -----------------

type ShareSnackbarState =
  | {
      type: 'none';
    }
  | {
      type: 'link-fetch-failed';
    }
  | {
      type: 'link-disabled-failed';
    }
  | {
      type: 'link-enabled-failed';
    }
  | SendEmailSnackbarState;

export type SendEmailSnackbarState = {
  type: 'send-email-succeeded';
  numEmails: number;
  shareItem: ShareItem;
  shareModalState: ShareModalContents;
};

export const shareSnackbarStateAtom = atom<ShareSnackbarState>({type: 'none'});
