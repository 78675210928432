import React from 'react';

import type {PAPEvent} from 'pap-events/base/event';
import {useNavigate} from 'react-router';
import type {NavigateOptions} from 'react-router/dist/lib/context';

// eslint-disable-next-line no-restricted-imports
import {
  Button as DigButton,
  type ButtonProps as DigButtonProps,
} from '@dropbox/dig-components/buttons';

import {useLoggingClient} from '~/lib/use_logging_client';
import {openURLInExternalBrowser} from '~/lib/utils';

// Re-export all DIG buttons as our own so we can lint against all DIG button
// imports
// eslint-disable-next-line no-restricted-imports
export * from '@dropbox/dig-components/buttons';

export type ButtonProps = DigButtonProps & {
  papOnClick?: PAPEvent;
  navigate?: NavigateOptions;
};
/**
 * Attention: This Button component uses React Router to navigate to a new page.
 * This means it will blow up if this button is attempted to be used outside of a
 * React Router context, which might happen in cases like portalized modals.
 *
 * Adobe's CEP environment can't handle _blank links and requires usage of a
 * special function to open a page in a new window. This button will
 * automatically call this function when a `_blank` button link is detected
 */
export const Button = React.forwardRef<HTMLElement, ButtonProps>(
  ({papOnClick, onClick, navigate: navigateOptions, ...props}, ref) => {
    const navigate = useNavigate();

    const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      onClick?.(e);

      if (props.href) {
        e.preventDefault();
        if (props.target === '_blank') {
          openURLInExternalBrowser(props.href);
        } else if (isValidUrl(props.href)) {
          window.location.href = props.href;
        } else {
          navigate(props.href, navigateOptions);
        }
      }
    };

    if (papOnClick) {
      return <PapButton {...props} onClick={handleClick} papOnClick={papOnClick} ref={ref} />;
    }

    return <DigButton {...props} onClick={handleClick} ref={ref} />;
  },
);

const isValidUrl = (href: string) => {
  try {
    new URL(href);
    return true;
  } catch {
    return false;
  }
};

type PapButtonProps = DigButtonProps & {
  papOnClick: PAPEvent;
};

/**
 * Creating a second "button" for papOnClick props as `loggingClient` can only be used on
 * app initialization. Forking the render in `Button` allows us to keep the downstream api simple
 * but still allow for useful hooks to be used.
 */
const PapButton = React.forwardRef<HTMLElement, PapButtonProps>(
  ({papOnClick, onClick, ...props}, ref) => {
    const loggingClient = useLoggingClient();

    const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (papOnClick) loggingClient.logPap(papOnClick);
      onClick?.(e);
    };

    return <DigButton {...props} onClick={handleClick} ref={ref} />;
  },
);

// Re-exporting the dig button for cases where we can't use
// the regular button (eg, videojs components).
// Because the Button component uses React Router to navigate to a new page.
// We can use this RawDigButton to avoid the React Router dependency issues with modals and such.
export const RawDigButton = DigButton;
