import ReactDOM from 'react-dom';
import {FormattedMessage} from 'react-intl';
import styled, {css} from 'styled-components';

import {Snackbar} from '@dropbox/dig-components/snackbar';
import type {SnackbarProps} from '@dropbox/dig-components/snackbar';

import {breakpointSmall} from '../styled';

export const ReelSnackbarContainer = styled.div`
  position: fixed;
  /* if the cookie snackbar is open, push any other snackbar above it by the
   * height of the cookie snackbar plus a margin
   */
  bottom: calc(var(--privacy-consent-banner-height, 0px) + 40px);
  left: 0;
  right: 0;
  z-index: 1000000; // Higher than everything else.
  ${breakpointSmall(css`
    display: flex;
  `)}
`;

export const ReelSnackbarContent = styled(Snackbar)`
  margin: auto;
  max-width: 700px;
  ${breakpointSmall(css`
    min-width: 350px;
  `)}
`;

export const ReelSnackbar = (props: SnackbarProps) => {
  return ReactDOM.createPortal(
    <ReelSnackbarContainer>
      <ReelSnackbarContent {...props} />
    </ReelSnackbarContainer>,
    document.body,
  );
};

export const SnackbarCloseMessage = () => (
  <FormattedMessage
    defaultMessage="Close"
    description="Text for button on a snackbar that, when clicked, closes the snackbar."
    id="JF6eTC"
  />
);
