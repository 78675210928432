import type React from 'react';

// For the times when you need multiple references of a component
// Vendored react-merge-refs: v2.0.1 (MIT)
// https://github.com/gregberge/react-merge-refs/blob/96ca3bf1feea0e9f0a4c6d5ca247882be3cba585/src/index.tsx
export function mergeRefs<T = any>(
  refs: Array<React.MutableRefObject<T> | React.LegacyRef<T> | undefined | null>,
): React.RefCallback<T> {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref != null) {
        (ref as React.MutableRefObject<T | null>).current = value;
      }
    });
  };
}
