import {checkPithosPrivacyConsentFlag} from './environment';
import type {_ReplayStorage} from './storage';

export class _PrivacyConsentModule extends EventTarget {
  private analyticsConsent = false;
  private functionalityConsent = false;
  private hasMadeConsentChoice = false;
  private hasChoicesPromise: Promise<void>;
  private isInitialized = false;
  // Sorry Typescript, you're going to have to trust me on this one
  // @ts-ignore
  private finishChoices: () => void;

  constructor() {
    super();
    this.hasChoicesPromise = new Promise((resolve) => {
      this.finishChoices = resolve;
    });
  }

  updateConsentChoices(
    choices: {analytics?: boolean; functionality?: boolean},
    userInteracted: boolean,
  ) {
    const previousAnalytics = this.analyticsConsent;
    const previousFunctionality = this.functionalityConsent;
    if (choices.analytics !== undefined) {
      this.analyticsConsent = choices.analytics;
    }
    if (choices.functionality !== undefined) {
      this.functionalityConsent = choices.functionality;
    }

    this.hasMadeConsentChoice = userInteracted;

    if (!this.isInitialized) {
      this.isInitialized = true;
      this.finishChoices();
      this.dispatchEvent(new Event('consent_change'));
    } else if (
      previousAnalytics !== this.analyticsConsent ||
      previousFunctionality !== this.functionalityConsent
    ) {
      this.dispatchEvent(new Event('consent_change'));
    }
  }

  onConsentChange(f: (e: Event) => void) {
    this.addEventListener('consent_change', f);
  }

  removeConsentChangeListener(f: (e: Event) => void) {
    this.removeEventListener('consent_change', f);
  }

  getConsentChoices() {
    return {
      analytics: this.analyticsConsent,
      functionality: this.functionalityConsent,
    };
  }

  getHasMadeConsentChoice() {
    return this.hasMadeConsentChoice;
  }

  waitForChoices(): Promise<void> {
    return this.hasChoicesPromise;
  }
}

export const privacyConsentModule = new _PrivacyConsentModule();

export const tryPrivacyConsentInit = (emailProofToken?: string) => {
  if (typeof window !== 'undefined' && window.dropbox) {
    window.dropbox.privacyConsent.init({
      injectToFooter: true,
      injectFloatingButton: false,
      disableBanner: false,

      thirdPartyConfig: {
        cookieDomain: window.location.hostname,
        emailProofToken,
      },

      initCallback: (
        categories: DropboxPrivacyConsent.CookieCategories,
        userInteracted: boolean,
      ) => {
        privacyConsentModule.updateConsentChoices(
          {
            analytics: categories.analytics,
            functionality: categories['performance and functionality'],
          },
          userInteracted,
        );
      },

      consentChangedCallback: (categories: DropboxPrivacyConsent.CookieCategories) => {
        privacyConsentModule.updateConsentChoices(
          {
            analytics: categories.analytics,
            functionality: categories['performance and functionality'],
          },
          true,
        );
      },

      priorConsentCallback: (
        categories: DropboxPrivacyConsent.CookieCategories,
        preferencesChanged: boolean,
        userInteracted: boolean,
      ) => {
        privacyConsentModule.updateConsentChoices(
          {
            analytics: categories.analytics,
            functionality: categories['performance and functionality'],
          },
          userInteracted,
        );
      },

      onBannerVisible: (height: number) => {
        document.documentElement.style.setProperty(
          '--privacy-consent-banner-height',
          `${height + 20}px`,
        );
      },
      onBannerNotVisible: () => {
        const consentIframe = document.getElementById('consent-iframe');
        const consentIframeHeight = consentIframe ? consentIframe.offsetHeight + 20 : 0;

        document.documentElement.style.setProperty(
          '--privacy-consent-banner-height',
          `${consentIframeHeight}px`,
        );
      },
    });
  } else {
    setTimeout(() => {
      tryPrivacyConsentInit(emailProofToken);
    }, 50);
  }
};

let invisibleManageCookiesLink: HTMLAnchorElement;

export const activateCookiesModule = () => {
  if (checkPithosPrivacyConsentFlag()) {
    invisibleManageCookiesLink?.click();
  }
};

export const initializePrivacyModule = (replayStorage: _ReplayStorage) => {
  const script = document.createElement('script');
  script.src = 'https://www.dropbox.com/pithos/privacy_consent';
  script.defer = true;
  script.async = true;
  document.head.appendChild(script);

  invisibleManageCookiesLink = document.createElement('a');
  invisibleManageCookiesLink.id = 'invisible-manage-cookies-link';
  invisibleManageCookiesLink.style.display = 'none';
  invisibleManageCookiesLink.href = '#manage-cookies';
  document.body.append(invisibleManageCookiesLink);

  privacyConsentModule.onConsentChange(() => {
    replayStorage.updateConsent(privacyConsentModule.getConsentChoices());
  });

  // If the pithos flag is off, use the old cookie setting (hasCookieSetting
  // will always return false if the flag is on)
  if (replayStorage.hasCookieSetting()) {
    const hasAccepted = Boolean(replayStorage.getCookieSetting()); // convert to boolean to make ts happy
    privacyConsentModule.updateConsentChoices(
      {analytics: hasAccepted, functionality: hasAccepted},
      hasAccepted,
    );
  }
};
