import React, {type ErrorInfo} from 'react';

import {ReplayError, ReplayErrorCategory, reportException} from '../lib/error_reporting';

type ErrorBoundaryProps = React.PropsWithChildren<{
  ErrorHandler: React.ComponentType;
}>;

type ErrorBoundaryState = {
  hasError: boolean;
};

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError() {
    return {hasError: true};
  }

  componentDidCatch(e: Error, errorInfo: ErrorInfo) {
    reportException(
      new ReplayError({
        severity: 'critical',
        tags: ['page_crashed'],
        category: ReplayErrorCategory.UncaughtException,
        error: e,
        stack: errorInfo.componentStack ?? undefined,
      }),
    );
  }

  render() {
    if (this.state.hasError) {
      return <this.props.ErrorHandler />;
    }

    return this.props.children;
  }
}
