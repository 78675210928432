import {atom} from 'jotai';
import {focusAtom} from 'jotai-optics';
import intersection from 'lodash/intersection';

import {folderItemMapAtom, projectItemMapAtom} from '~/state/browse';

export enum BulkAction {
  COMPARE_VERSIONS,
  COPY_TO_PROJECT,
  DELETE,
  HIDE,
  LEAVE_TEAM_PROJECT,
  MOVE_TO_FOLDER,
  MOVE_TO_PROJECT,
  UPDATE_VERSION_STATUS,
  ARCHIVE_TO_DROPBOX,
  FOLDER_DOWNLOAD,
  ASSET_DOWNLOAD,
}

export enum BulkActionStatus {
  INIT = 'init',
  REQUESTING = 'requesting',
  COMPLETE = 'complete',
}

type BulkActionsState =
  | {
      action: BulkAction;
      status: BulkActionStatus;
    }
  | {
      action: null;
      status: null;
    };

/** Atom that holds current bulk action state */
export const bulkActionStateAtom = atom<BulkActionsState>({action: null, status: null});

export const bulkActionAtom = focusAtom(bulkActionStateAtom, (optic) => optic.prop('action'));
export const bulkActionStatusAtom = focusAtom(bulkActionStateAtom, (optic) => optic.prop('status'));

/** Write-only atom that clears the bulk actions atom state */
export const clearBulkActionAtom = atom(null, (get, set) => {
  set(bulkActionStateAtom, {action: null, status: null});
});

/** Write-only atom that updates the provided bulk action to init */
export const initBulkActionAtom = atom(null, (get, set, bulkAction: BulkAction) => {
  set(bulkActionStateAtom, {action: bulkAction, status: BulkActionStatus.INIT});
});

/** Write-only atom that updates the provided bulk action to requesting */
export const requestBulkActionAtom = atom(null, (get, set, bulkAction: BulkAction) => {
  set(bulkActionStateAtom, {action: bulkAction, status: BulkActionStatus.REQUESTING});
});

/** Write-only atom that updates the provided bulk action to complete */
export const completeBulkActionAtom = atom(null, (get, set, bulkAction: BulkAction) => {
  set(bulkActionStateAtom, {action: bulkAction, status: BulkActionStatus.COMPLETE});
});

/** Read-only atom that holds the list of possible bulk actions */
export const availableBulkActionsAtom = atom((get) => {
  const folderItemMap = get(folderItemMapAtom);
  const projectItemMap = get(projectItemMapAtom);

  const selectedFolderActions = [...folderItemMap]
    .filter(([, {selectable, selected}]) => selectable && selected)
    .map(([, {actions}]) => actions);

  const selectedProjectActions = [...projectItemMap]
    .filter(([, {selectable, selected}]) => selectable && selected)
    .map(([, {actions}]) => actions);

  // The only available actions are those that are usable on ALL items
  return intersection(...selectedFolderActions, ...selectedProjectActions);
});
