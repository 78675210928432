import React from 'react';

import {useIntl} from 'react-intl';
import styled from 'styled-components';

import type {OverlayPlacement} from '@dropbox/dig-components/overlay';
import {Tooltip} from '@dropbox/dig-components/tooltips';
import {Text, Title} from '@dropbox/dig-components/typography';
import {Box} from '@dropbox/dig-foundations';

import {Button} from '~/components/button';

const StyledTitle = styled(Title)`
  margin: 0 0 8px 0;
`;

type SettingsHasMovedTooltipComponentProps = {
  triggerRef: React.RefObject<HTMLDivElement>;
  open: boolean;
  placement: OverlayPlacement;
  onClose: () => void;
  onTooltipDisplayed: () => void;
  admin: boolean;
};

export const SettingsHasMovedTooltipComponent: React.FC<SettingsHasMovedTooltipComponentProps> = ({
  triggerRef,
  open,
  placement,
  onClose,
  onTooltipDisplayed,
  admin,
}) => {
  const intl = useIntl();

  const titleMessage = intl.formatMessage({
    defaultMessage: 'A new home for settings',
    description: 'Title for the settings has moved tooltip',
    id: '9KhHYY',
  });
  const bodyMessage = intl.formatMessage({
    defaultMessage: 'Personalize your Replay experience here.',
    description: 'Body for the settings has moved tooltip',
    id: '6lNKPD',
  });
  const adminTitleMessage = intl.formatMessage({
    defaultMessage: 'Access admin settings',
    description: 'Title for the settings has moved tooltip for admins',
    id: 'b0/Awz',
  });
  const adminBodyMessage = intl.formatMessage({
    defaultMessage: 'Manage your team members, projects, sharing settings and more.',
    description: 'Body for the settings has moved tooltip for admins',
    id: 'yJhodV',
  });
  const ctaMessage = intl.formatMessage({
    defaultMessage: 'Got it',
    description: 'Button text for the settings has moved tooltip',
    id: 'C/2QNZ',
  });

  return (
    <Tooltip.Control
      auto
      data-testid="settings-has-moved-tooltip"
      maxWidth={230}
      onDisplayed={onTooltipDisplayed}
      open={open}
      placement={placement}
      triggerRef={triggerRef}
      variant="rich"
    >
      <StyledTitle data-testid="settings-has-moved-tooltip-title">
        {admin ? adminTitleMessage : titleMessage}
      </StyledTitle>
      <Text data-testid="settings-has-moved-tooltip-body">
        {admin ? adminBodyMessage : bodyMessage}
      </Text>
      <Box display="flex" justifyContent="flex-end" marginTop="Micro Large" width="100%">
        <Button data-testid="settings-has-moved-tooltip-gotit" onClick={onClose} variant="outline">
          {ctaMessage}
        </Button>
      </Box>
    </Tooltip.Control>
  );
};
