import React from 'react';

import * as Sentry from '@sentry/react';

import {Dropbox, DropboxAuth} from '@dropbox/api-v2-client';

import {addMetric, isLoadingStrategy} from './api';
import type {LoadingStrategy} from './api';
import {CurrentEnvironment, getCurrentEnvironment} from './environment';
import {ReplayError, ReplayErrorCategory, reportException} from './error_reporting';
import {DBX_CLIENT_DOMAIN, DBX_CLIENT_ID, DBX_CLIENT_SECRET} from '../context_utils';

export const APP_HAS_INITIALIZED_MEASURE = 'app-has-initialized';

const OPEN_TRANSCRIPTION_SIDEBAR_PANEL_MARK = 'open-transcription-sidebar-panel';

export const markOpenTranscriptionSidebarPanel = () => {
  performance.mark(OPEN_TRANSCRIPTION_SIDEBAR_PANEL_MARK);
};

const COMPLETE_TRANSCRIPTION_VISIBLE_MARK = 'complete-transcription-visible-mark';

export const markViewCompleteTranscription = () => {
  performance.mark(COMPLETE_TRANSCRIPTION_VISIBLE_MARK);
};

export const VIEW_COMPLETE_TRANSCRIPTION_MEASURE = 'view-complete-transcription';

export const measureViewCompleteTranscription = () => {
  performance.measure(
    VIEW_COMPLETE_TRANSCRIPTION_MEASURE,
    OPEN_TRANSCRIPTION_SIDEBAR_PANEL_MARK,
    COMPLETE_TRANSCRIPTION_VISIBLE_MARK,
  );
};

export const LOCATION_CHANGE_MARK = 'location-change';

export const UNCAUGHT_ERROR_VIEW_MARK = 'uncaught-error-view';

export const BROWSE_LOAD_MEASURE = 'browse-load';

export const measureBrowseLoad = ({loadingStrategy}: {loadingStrategy: LoadingStrategy}) => {
  performance.measure(BROWSE_LOAD_MEASURE, {
    start: LOCATION_CHANGE_MARK,
    detail: {loading_strategy: loadingStrategy},
  });
};

export const VIDEO_LOAD_MEASURE = 'video-load';

export const measureVideoLoad = () => {
  performance.measure(VIDEO_LOAD_MEASURE, LOCATION_CHANGE_MARK);
};

const MAX_TTVC_MS = 120_000;
export const useMetrics = () => {
  // We set up a new client for metrics since some of these API calls happen
  // between the logged out setup and the logged in setup when a user first
  // logs in. It's fine to use only app authentication for these since the
  // current user doesn't matter.
  const metricsClient = React.useMemo(() => {
    const dropboxAuth = new DropboxAuth({
      domain: DBX_CLIENT_DOMAIN,
      clientId: DBX_CLIENT_ID,
      clientSecret: DBX_CLIENT_SECRET,
    });
    return new Dropbox({
      auth: dropboxAuth,
      domain: DBX_CLIENT_DOMAIN,
    });
  }, []);

  React.useEffect(() => {
    const isProd = getCurrentEnvironment() === CurrentEnvironment.Production;

    const measureObserver = new PerformanceObserver(function (list, observer) {
      list.getEntries().forEach(async (entry) => {
        if (entry.entryType !== 'measure') {
          return;
        }

        Sentry.setMeasurement(entry.name, entry.duration, 'millisecond');

        switch (entry.name) {
          case BROWSE_LOAD_MEASURE: {
            if (entry.duration > MAX_TTVC_MS) {
              reportException(
                new ReplayError({
                  category: ReplayErrorCategory.BadMetricsMeasurement,
                  severity: 'non-critical',
                  error: new Error('Browse TTVC measurement exceeded allowable range'),
                }),
              );
              break;
            }

            const loadingStrategyDetail = (entry as PerformanceMeasure).detail?.loading_strategy;
            const loadingStrategy = isLoadingStrategy(loadingStrategyDetail)
              ? loadingStrategyDetail
              : '';

            if (!isProd) {
              console.debug(
                `[metrics debug] Would have recorded ttvc_browse with duration ${entry.duration} and loading strategy ${loadingStrategy}`,
              );
              break;
            }
            try {
              await addMetric(
                {
                  metric_type: {'.tag': 'ttvc_browse'},
                  value: entry.duration,
                  loading_strategy: loadingStrategy,
                },
                metricsClient,
              );
            } catch (e) {
              // This is pretty noisy and unactionable. So not logging to Sentry until we have a better idea of what we want to do this with this data.
              // reportFailedDataFetch(e, 'AddMetric', 'non-critical');
            }
            break;
          }
          case VIDEO_LOAD_MEASURE: {
            if (entry.duration > MAX_TTVC_MS) {
              reportException(
                new ReplayError({
                  category: ReplayErrorCategory.BadMetricsMeasurement,
                  severity: 'non-critical',
                  error: new Error('Video TTVC measurement exceeded allowable range'),
                }),
              );
              break;
            }
            if (!isProd) {
              console.debug(
                `[metrics debug] Would have recorded ttvc_video with duration ${entry.duration}`,
              );
              break;
            }
            try {
              await addMetric(
                {metric_type: {'.tag': 'ttvc_video'}, value: entry.duration},
                metricsClient,
              );
            } catch (e) {
              // This is pretty noisy and unactionable. So not logging to Sentry until we have a better idea of what we want to do this with this data.
              // reportFailedDataFetch(e, 'AddMetric', 'non-critical');
            }
            break;
          }
          case VIEW_COMPLETE_TRANSCRIPTION_MEASURE: {
            if (!isProd) {
              console.debug(
                `[metrics debug] Would have recorded transcription_display_wait_time with duration ${entry.duration}`,
              );
              break;
            }
            try {
              await addMetric(
                {
                  metric_type: {'.tag': 'transcription_display_wait_time'},
                  value: entry.duration,
                },
                metricsClient,
              );
            } catch (e) {
              // This is pretty noisy and unactionable. So not logging to Sentry until we have a better idea of what we want to do this with this data.
              // reportFailedDataFetch(e, 'AddMetric', 'non-critical');
            }
            break;
          }
          case APP_HAS_INITIALIZED_MEASURE: {
            if (!isProd) {
              console.debug(
                `[metrics debug] Would have recorded time_to_first_route with duration ${entry.duration}`,
              );
              break;
            }
            try {
              await addMetric(
                {
                  metric_type: {'.tag': 'time_to_first_route'},
                  value: entry.duration,
                },
                metricsClient,
              );
            } catch (e) {
              // This is pretty noisy and unactionable. So not logging to Sentry until we have a better idea of what we want to do this with this data.
              // reportFailedDataFetch(e, 'AddMetric', 'non-critical');
            }
            break;
          }
          default: {
            // do nothing
          }
        }
      });
    });
    measureObserver.observe({buffered: true, type: 'measure'});

    const markObserver = new PerformanceObserver(function (list, observer) {
      list.getEntries().forEach(async (entry) => {
        if (entry.entryType !== 'mark') {
          return;
        }
        switch (entry.name) {
          case LOCATION_CHANGE_MARK: {
            if (!isProd) {
              console.debug('[metrics debug] Would have recorded route_view');
              break;
            }
            try {
              await addMetric({metric_type: {'.tag': 'route_view'}}, metricsClient);
            } catch (e) {
              // This is pretty noisy and unactionable. So not logging to Sentry until we have a better idea of what we want to do this with this data.
              // reportFailedDataFetch(e, 'AddMetric', 'non-critical');
            }
            break;
          }
          case UNCAUGHT_ERROR_VIEW_MARK: {
            if (!isProd) {
              console.debug('[metrics debug] Would have recorded uncaught_error_view');
              break;
            }
            try {
              await addMetric({metric_type: {'.tag': 'uncaught_error_view'}}, metricsClient);
            } catch (e) {
              // This is pretty noisy and unactionable. So not logging to Sentry until we have a better idea of what we want to do this with this data.
              // reportFailedDataFetch(e, 'AddMetric', 'non-critical');
            }
            break;
          }
          default: {
            // do nothing
          }
        }
      });
    });
    markObserver.observe({buffered: true, type: 'mark'});
  }, [metricsClient]);
};
