import React from 'react';

import {useIntl} from 'react-intl';
import {useNavigate} from 'react-router';

import {Button} from '~/components/button';

type ExploreDemoButtonProps = {
  projectId?: string;
  videoId?: string;
  markModalDimissed: (logged: boolean, demo?: boolean) => void;
  pageSource: 'video' | 'browse';
};

export const ExploreDemoButton = (props: ExploreDemoButtonProps) => {
  const {projectId, videoId, markModalDimissed} = props;
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [demoLink, setDemoLink] = React.useState<string>('');
  const navigate = useNavigate();
  const intl = useIntl();

  React.useEffect(() => {
    if (projectId && videoId) {
      setIsLoading(false);
      setDemoLink(`/project/${projectId}/video/${videoId}`);
    }
  }, [projectId, videoId]);

  return (
    <Button
      isLoading={isLoading}
      onClick={async () => {
        await markModalDimissed(true);
        if (props.pageSource === 'browse') {
          navigate(demoLink);
        } else {
          window.open(demoLink, '_blank');
        }
      }}
      variant="opacity"
    >
      {intl.formatMessage({
        defaultMessage: 'Explore demo project',
        id: 'sRF4rL',
        description: 'Button to navigate to Replay demo project',
      })}
    </Button>
  );
};
