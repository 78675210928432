import {ViewId} from './google_picker_types';
import type {FilePickerResult, MessageToParent} from './iframe_types';
import {FilePickerAction, MessageFromParentType, MessageToParentType} from './iframe_types';
import {DBX_CLIENT_DOMAIN} from '../../context_utils';
import {ALL_MIMES_STRING, enforceExhaustive} from '../../lib/helpers';

export type FilePickerCallback = (result: FilePickerResult) => void;

let filePickerIframe: HTMLIFrameElement | null = null;
let filePickerCallback: FilePickerCallback = (): void => void 0;
let filePickerAccessToken = '';
let filePickerMimeString: string | undefined = ALL_MIMES_STRING;

const fileImportsSubdomain =
  DBX_CLIENT_DOMAIN && DBX_CLIENT_DOMAIN.includes('dev.corp')
    ? `https://file-imports-${DBX_CLIENT_DOMAIN}`
    : 'https://file-imports.dropbox.com';

const cleanupIframe = (result: FilePickerResult): void => {
  if (filePickerIframe) {
    filePickerCallback(result);

    filePickerIframe.parentNode?.removeChild(filePickerIframe);
    window.removeEventListener('message', handlePostMessage);
    filePickerIframe = null;
    filePickerCallback = (): void => void 0;

    // Don't clean up filePickerAccessToken in case we want to reopen the
    // file picker later.
  }
};

const cleanupWithError = async (): Promise<void> => {
  const sleep = async (milliseconds: number): Promise<void> => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  // Important: Allow unhandled rejections to show up first. Otherwise the
  // errors will be silently hidden.
  await sleep(500);

  cleanupIframe({action: FilePickerAction.CANCELED});

  // Let the background color restore first before proceeding.
  await sleep(50);
};

const handlePostMessage = async (message: MessageEvent): Promise<void> => {
  if (
    !filePickerIframe ||
    message.origin !== fileImportsSubdomain ||
    message.source !== filePickerIframe.contentWindow ||
    !message.data?.hasOwnProperty('eventType')
  ) {
    return;
  }

  const data: MessageToParent = message.data;
  switch (data.eventType) {
    case MessageToParentType.IframeLoaded:
      if (filePickerIframe?.contentWindow) {
        filePickerIframe.contentWindow.postMessage(
          {
            eventType: MessageFromParentType.OpenGdriveFilePicker,
            accessToken: filePickerAccessToken,
            origin: window.origin,
            views: [
              {
                viewId: ViewId.DOCS as string,
                mimeTypes: filePickerMimeString,
                includeFolders: true,
                allowSelectFolders: false,
              },
            ],
            hideSwitchAccountButton: true,
            multiFileImportEnabled: false,
          },
          fileImportsSubdomain,
        );
      }
      break;
    case MessageToParentType.FilePickerResult:
      cleanupIframe(data.result);
      break;
    case MessageToParentType.SignInWithDifferentAccount:
      cleanupIframe({action: FilePickerAction.CANCELED});
      // TODO - trigger sign in to google account
      break;
    case MessageToParentType.UnhandledError:
      await cleanupWithError();
      break;
    case MessageToParentType.UnhandledRejection:
      // Don't cleanup the iframe due to unhandled rejections as we don't know
      // whether the error is a blocker or just frivolous.
      break;
    default:
      enforceExhaustive(data);
  }
};

export const openGdriveFilePicker = (
  accessToken: string,
  showAllFiles?: boolean,
  validMimeTypes?: string,
): Promise<FilePickerResult> => {
  return new Promise<FilePickerResult>((resolve) => {
    if (filePickerIframe) {
      throw new Error('File picker already open');
    }

    filePickerAccessToken = accessToken;
    filePickerMimeString = showAllFiles ? undefined : validMimeTypes;
    filePickerCallback = resolve;

    filePickerIframe = document.createElement('iframe');
    filePickerIframe.setAttribute('allowTransparency', 'true');
    filePickerIframe.setAttribute(
      'sandbox',
      'allow-scripts allow-same-origin allow-modals allow-popups',
    );
    filePickerIframe.setAttribute('src', fileImportsSubdomain);
    filePickerIframe.setAttribute('frameBorder', '0');

    filePickerIframe.style.position = 'fixed';
    filePickerIframe.style.width = '100%';
    filePickerIframe.style.height = '100%';
    filePickerIframe.style.top = '0';
    filePickerIframe.style.left = '0';
    filePickerIframe.style.backgroundColor = 'gray';
    filePickerIframe.style.transitionDelay = '0';
    filePickerIframe.style.transitionDuration = '0.333';
    filePickerIframe.style.transitionProperty = 'opacity';
    filePickerIframe.style.transitionTimingFunction = 'cubic-bezier(0.66, 0, 0, 1)';
    filePickerIframe.style.zIndex = '1001';

    document.body.appendChild(filePickerIframe);
    window.addEventListener('message', handlePostMessage);

    if (filePickerIframe?.contentWindow) {
      filePickerIframe.contentWindow.postMessage(
        {
          eventType: MessageFromParentType.OpenGdriveFilePicker,
          accessToken: filePickerAccessToken,
        },
        fileImportsSubdomain,
      );
    }
  });
};
