import React, {type FocusEventHandler, forwardRef} from 'react';

import type {ChangeHandler} from 'react-hook-form';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';

import type {sharing} from '@dropbox/api-v2-client';
import {Avatar} from '@dropbox/dig-components/avatar';
import {Chip} from '@dropbox/dig-components/chip';
import {TextInput, type TextInputInputRefObject} from '@dropbox/dig-components/text_fields';
import {Typeahead} from '@dropbox/dig-components/typeahead';

import type {ButtonProps} from '~/components/button';
import {useReelAppGlobalState} from '~/context';
import type {GroupContactInfo, TeamContactInfo, UserContactInfo} from '~/lib/api';
import {useSearchContactsQuery} from '~/lib/api_queries';
import {getDrawingColorForUser} from '~/lib/colors';
import {getUserInitials} from '~/lib/facepile_utils';
import {isValidEmail, useAreFSSGroupsSupported} from '~/lib/utils';
import type {FolderToAccounts} from '~/pages/browse_page/use_share_recipients';

import {spacing} from './styled';

interface AddChipsListProps {
  contactInfos: TeamContactInfo[];
  onDeleteChip: (contact: TeamContactInfo) => void;
  existingContacts?: TeamContactInfo[]; // Use when it's necessary to check if a contact has previously been added, e.g. when a watermark share link already exists for the email
  showDuplicateWarning?: boolean;
}

const AddChipsList = ({
  contactInfos,
  onDeleteChip,
  existingContacts,
  showDuplicateWarning = false,
}: AddChipsListProps) => {
  const existingUserContacts = existingContacts?.filter(
    (c) => c.type === 'user',
  ) as UserContactInfo[];
  const existingGroupContacts = existingContacts?.filter(
    (c) => c.type === 'group',
  ) as TeamContactInfo[];

  const renderChip = (contact: UserContactInfo | TeamContactInfo, index: number) => {
    const isUser = contact.type === 'user';
    const chipKey = (isUser ? contact.email : contact.groupId) + index;
    const chipContent = isUser ? contact.displayName || contact.email : contact.groupName;

    let isDuplicate = false;
    if (showDuplicateWarning) {
      const isExistingContactDuplicate =
        contact.type === 'user'
          ? existingUserContacts?.some((c) => c.type == 'user' && c.email === contact.email)
          : existingGroupContacts?.some((c) => c.type == 'group' && c.groupId === contact.groupId);

      const isAlreadySelectedChipDuplicate =
        contact.type === 'user'
          ? contactInfos.filter((c) => c.type === 'user' && c.email === contact.email).length > 1
          : contactInfos.filter((c) => c.type === 'group' && c.groupId === contact.groupId).length >
            1;

      isDuplicate = isExistingContactDuplicate || isAlreadySelectedChipDuplicate;
    }

    return (
      <Chip
        key={chipKey}
        onDelete={() => onDeleteChip(contact)}
        size="small"
        variant={!contact.valid ? 'alert' : isDuplicate ? 'warning' : 'standard'}
      >
        <Chip.IconAccessory>
          {isUser && contact.displayName && (
            <ContactAvatar
              isSmall={true}
              name={contact.displayName}
              photoUrl={contact.avatarPhotoUrl}
            />
          )}
          {!isUser && contact.groupName && (
            <ContactAvatar isSmall={true} name={contact.groupName} />
          )}
        </Chip.IconAccessory>
        <Chip.Content>{chipContent}</Chip.Content>
      </Chip>
    );
  };

  return <>{contactInfos.map((contact, index) => renderChip(contact, index))}</>;
};

const AddChipsListMemo = React.memo(AddChipsList);

const ContactAvatar = (props: {name: string; photoUrl?: string; isSmall: boolean}) => {
  const {locale} = useReelAppGlobalState();
  const contactInitials = getUserInitials(props.name, locale);

  return (
    <Avatar
      alt=""
      backgroundColor={getDrawingColorForUser(props.name)}
      hasNoOutline
      size={props.isSmall ? 'small' : 'standard'}
      src={props.photoUrl || undefined}
    >
      {contactInitials}
    </Avatar>
  );
};

type TeamTypeaheadProps = {
  type: 'team';
  setContacts: (contactInfos: TeamContactInfo[]) => void;
  initialContacts?: TeamContactInfo[];
  existingContacts?: TeamContactInfo[];
} & CommonTypeaheadProps;

type UserAccountTypeaheadProps = {
  type: 'user';
  setContacts: (contactInfos: UserContactInfo[]) => void;
  initialContacts?: UserContactInfo[];
  existingContacts?: UserContactInfo[];
} & CommonTypeaheadProps;

type CommonTypeaheadProps = {
  inlineButton?: React.ReactElement<ButtonProps>; // Optionally include a button in the typeahead text box
  inputPlaceholderText: string;
  inputLabelText: string;
  existingSharedFolderRecipients?: FolderToAccounts;
  sharedFolderId?: string;
  showDuplicateWarning?: boolean;
  setHasDuplicates?: (hasDuplicates: boolean) => void;
  disableTypeahead?: boolean;
  clearTypeaheadRef?: React.MutableRefObject<() => void>;

  // Input props
  name?: string;
  onBlur?: ChangeHandler;
  autofocus?: boolean;
  disabled?: boolean;
  isInvalid?: boolean;
};

export type DropboxAccountTypeaheadProps = UserAccountTypeaheadProps | TeamTypeaheadProps;
type SharingTargetRefs =
  | sharing.UserTargetReference
  | sharing.GroupTargetReference
  | sharing.TargetReference;

const InlineButtonWrapper = styled.div`
  margin: ${spacing('0')};
`;

const TextInputContainer = styled(TextInput.Container)`
  align-items: flex-start;
`;

export const DropboxAccountTypeahead = forwardRef<
  TextInputInputRefObject,
  DropboxAccountTypeaheadProps
>(
  (
    {
      type,
      disableTypeahead: isTypeaheadDisabled = false,
      setContacts,

      // Contacts that are already in the typeahead
      initialContacts = [],
      clearTypeaheadRef,

      inlineButton,
      inputPlaceholderText,
      inputLabelText,
      existingSharedFolderRecipients,
      sharedFolderId = '',

      // Contacts that have been added in a previous session, but are not currently in the typeahead
      existingContacts = [],

      showDuplicateWarning = false,
      setHasDuplicates = () => {},

      // Input props
      name,
      isInvalid,
      onBlur,
      autofocus = true,
      disabled = false,
    },
    ref,
  ) => {
    const inputContainerRef = React.useRef<HTMLElement | null>(null);
    const [contactInfos, setContactInfos] = React.useState<TeamContactInfo[]>(initialContacts);
    const [userInputValue, setUserInputValue] = React.useState<string>('');
    const areFssGroupsSupported = useAreFSSGroupsSupported() && type === 'team';
    if (clearTypeaheadRef) {
      clearTypeaheadRef.current = () => {
        setContactInfos([]);
      };
    }

    const emitContacts = React.useCallback(
      (contactInfos: UserContactInfo[] | TeamContactInfo[]) => {
        if (type === 'user') {
          setContacts(contactInfos.filter((c) => c.type === 'user') as UserContactInfo[]);
        } else {
          setContacts(contactInfos);
        }
      },
      [setContacts, type],
    );

    const emitHasDuplicates = React.useCallback(
      (contactInfos: UserContactInfo[] | TeamContactInfo[]) => {
        const hasExistingContactDuplicate = contactInfos.some((contact) => {
          return (
            contact.type === 'user' &&
            existingContacts.some((c) => c.type === 'user' && c.email === contact.email)
          );
        });
        const uniqueSelectedChips = new Set(
          contactInfos.map((c) => (c.type === 'user' ? c.email : c.groupId)),
        );
        const hasSelectedChipDuplicate = uniqueSelectedChips.size !== contactInfos.length;
        setHasDuplicates(hasExistingContactDuplicate || hasSelectedChipDuplicate);
      },
      [existingContacts, setHasDuplicates],
    );

    const handleContactInfoChange = React.useCallback(
      (contactInfos: UserContactInfo[] | TeamContactInfo[]) => {
        setContactInfos(contactInfos);
        emitContacts(contactInfos);
        emitHasDuplicates(contactInfos);
      },
      [emitContacts, emitHasDuplicates],
    );

    const filterToValidUserOrGroups = React.useCallback(
      (entry: SharingTargetRefs) => {
        if (entry['.tag'] === 'user' && 'dbx_account_id' in entry) {
          // Valid UserTargetReference
          if (
            !(
              contactInfos.some((c) => c.type === 'user' && c.accountId === entry.dbx_account_id) ||
              existingSharedFolderRecipients?.[sharedFolderId].userAccounts[entry.dbx_account_id]
            )
          ) {
            // We haven't already seen this user
            return true;
          }
        }

        if (areFssGroupsSupported) {
          if (entry['.tag'] === 'group' && 'group_id' in entry) {
            // Valid GroupTargetReference
            if (!existingSharedFolderRecipients?.[sharedFolderId].groupAccounts[entry.group_id]) {
              // We haven't already seen this user
              return true;
            }
          }
        }
        return false;
      },
      [areFssGroupsSupported, contactInfos, existingSharedFolderRecipients, sharedFolderId],
    );

    const contactHintsQuery = useSearchContactsQuery({
      searchTerm: userInputValue,
      enabled: !isTypeaheadDisabled,
    });
    const contactHints =
      (contactHintsQuery.data?.entries.filter(filterToValidUserOrGroups) as (
        | sharing.UserTargetReference
        | sharing.GroupTargetReference
      )[]) || [];

    const addUserInputToChips = (newChip: string) => {
      const newContactInfo: UserContactInfo = {
        type: 'user',
        email: newChip.toLowerCase(),
        valid: true,
      };
      handleContactInfoChange([...contactInfos, newContactInfo]);
      setUserInputValue('');
    };

    const onInputChange = (e: React.KeyboardEvent<HTMLInputElement>) => {
      setUserInputValue(e.currentTarget.value);
    };

    const handleKeyDown: React.KeyboardEventHandler = (e) => {
      const trimmedInputValue = userInputValue.trim();
      if (e.key === 'Backspace' && trimmedInputValue.length === 0) {
        setContactInfos((prevState) =>
          prevState.filter((c) => c !== prevState[prevState.length - 1]),
        );
        // Pressing enter, comma, or space converts a valid text input into a chip
      } else if (trimmedInputValue !== '' && isValidEmail(trimmedInputValue)) {
        if (e.key === 'Enter') {
          addUserInputToChips(trimmedInputValue);
        } else if (e.key === ',' || e.key === ' ') {
          addUserInputToChips(trimmedInputValue);
          e.preventDefault();
        }
      }
    };

    const handleOnBlur: FocusEventHandler<HTMLInputElement> = (e) => {
      onBlur?.(e);
      const trimmedInputValue = userInputValue.trim();
      if (trimmedInputValue !== '' && isValidEmail(trimmedInputValue)) {
        addUserInputToChips(trimmedInputValue);
      }
    };

    const renderTypeaheadRow = (
      result: sharing.UserTargetReference | sharing.GroupTargetReference,
    ) => {
      if (result['.tag'] === 'user') {
        return (
          <Typeahead.Row
            key={result.email}
            value={result}
            withLeftAccessory={
              <ContactAvatar isSmall={false} name={result.name} photoUrl={result.photo_url} />
            }
            withSubtitle={result.email}
            withTitle={result.name ?? result.email}
          />
        );
      }

      return (
        <Typeahead.Row
          key={result.name}
          value={result}
          withLeftAccessory={<ContactAvatar isSmall={false} name={result.name} />}
          withSubtitle={
            <FormattedMessage
              defaultMessage="{size} {size, plural, one {member} other {members}}"
              description="Title on the bulk hide modal"
              id="6Ca+wc"
              values={{size: result.group_size}}
            />
          }
          withTitle={result.name}
        />
      );
    };

    const deleteChip = React.useCallback(
      (contact: TeamContactInfo) => {
        const userContacts = contactInfos.filter((c) => c.type === 'user') as UserContactInfo[];
        const groupContacts = contactInfos.filter((c) => c.type === 'group') as GroupContactInfo[];
        let firstDuplicateContact: number | undefined;
        if (contact.type === 'user') {
          firstDuplicateContact = userContacts.findIndex((c) => c.email === contact.email);
        } else {
          firstDuplicateContact = groupContacts.findIndex((c) => c.groupId === contact.groupId);
        }
        const newContactInfo = contactInfos.filter((c, index) => index !== firstDuplicateContact);

        handleContactInfoChange(newContactInfo);
      },
      [contactInfos, handleContactInfoChange],
    );

    const addHintToChips = React.useCallback(
      (hint: sharing.UserTargetReference | sharing.GroupTargetReference) => {
        setUserInputValue('');
        let newContactInfo: TeamContactInfo;
        if (hint['.tag'] === 'user') {
          newContactInfo = {
            type: 'user',
            email: hint.email,
            accountId: hint.dbx_account_id,
            displayName: hint.name,
            avatarPhotoUrl: hint.photo_url,
            valid: true,
          };
        } else {
          newContactInfo = {
            type: 'group',
            groupId: hint.group_id,
            groupName: hint.name,
            groupSize: hint.group_size,
            valid: true,
          };
        }

        handleContactInfoChange([...contactInfos, newContactInfo]);
      },
      [contactInfos, handleContactInfoChange],
    );

    return (
      <Typeahead.Wrapper hasMaxHeight={275} onSelection={addHintToChips} openMenuOnFocus={false}>
        {({getTriggerProps, getContentProps}) => (
          <>
            <TextInputContainer isInvalid={isInvalid} onBlur={handleOnBlur} ref={inputContainerRef}>
              <TextInput.ChipsContainer>
                {!!contactInfos.length && (
                  <AddChipsListMemo
                    contactInfos={contactInfos}
                    existingContacts={existingContacts}
                    onDeleteChip={deleteChip}
                    showDuplicateWarning={showDuplicateWarning}
                  />
                )}
                <TextInput.Input
                  aria-label={inputLabelText}
                  autoFocus={autofocus}
                  disabled={disabled}
                  name={name}
                  onBlur={handleOnBlur}
                  placeholder={contactInfos.length === 0 ? inputPlaceholderText : ''}
                  ref={ref}
                  value={userInputValue}
                  {...getTriggerProps({onChange: onInputChange, onKeyDown: handleKeyDown})}
                />
              </TextInput.ChipsContainer>
              {inlineButton && <InlineButtonWrapper>{inlineButton}</InlineButtonWrapper>}
            </TextInputContainer>
            <Typeahead.Container {...getContentProps()} triggerRef={inputContainerRef}>
              <Typeahead.Results
                renderRow={renderTypeaheadRow}
                results={contactHints}
                size="standard"
              />
            </Typeahead.Container>
          </>
        )}
      </Typeahead.Wrapper>
    );
  },
);
