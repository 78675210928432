import {useAtomValue, useSetAtom} from 'jotai';
import {FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';

import {Modal} from '@dropbox/dig-components/modal';
import {Text, Title} from '@dropbox/dig-components/typography';

import {Button} from '~/components/button';
import {ItemType} from '~/components/common';
import {useReelAppGlobalState} from '~/context';
import {deleteItems, GenericStatus, leaveProjects} from '~/lib/api';
import {ReplayError, ReplayErrorCategory, reportException} from '~/lib/error_reporting';
import {deselectAllItemsAtom, selectedFolderIdsAtom, selectedProjectIdsAtom} from '~/state/browse';
import {
  BulkAction,
  bulkActionAtom,
  BulkActionStatus,
  bulkActionStatusAtom,
  clearBulkActionAtom,
  requestBulkActionAtom,
} from '~/state/bulk_actions';
import {refetchableUserSpaceAtom} from '~/state/user';

import type {ModifyItemSnackbarInfo} from '../pages/browse_page/browse_page';

type BulkDeleteModalProps = {
  onBulkDelete: (deletedFolderIds: string[], deletedProjectIds: string[]) => void;
  requestClose: () => void;
  updateModifyItemSnackbar: (modifyItemSnackbarInfo: ModifyItemSnackbarInfo) => void;
};

const DeleteModalBody = styled(Modal.Body)`
  padding-bottom: 24px;
`;

const DeleteModalBodyTitle = styled.p`
  margin: 0 0 21px 0;
`;

const ModalButtons = styled.div`
  display: flex;
`;

const CancelButton = styled.div`
  margin-left: auto;
`;

const DeleteButtonContainer = styled.div`
  padding-left: 8px;
`;

const DeleteButton = styled.div`
  background-color: #f98a6b;
  border-radius: var(--dig-radius__medium, 0);
`;

const RemoveModalTitle = ({bulkAction, itemCount}: {bulkAction: BulkAction; itemCount: number}) => {
  return bulkAction === BulkAction.HIDE ? (
    <FormattedMessage
      defaultMessage="Hide {itemCount, plural, one {Item} other {Items}}"
      description="Title on the bulk hide modal"
      id="0CLwOz"
      values={{itemCount}}
    />
  ) : bulkAction === BulkAction.DELETE ? (
    <FormattedMessage
      defaultMessage="Permanently delete {itemCount, plural, one {file} other {files}}"
      description="Title on the bulk delete modal"
      id="LMpCw5"
      values={{itemCount}}
    />
  ) : (
    <FormattedMessage
      defaultMessage="Leave {itemCount, plural, one {Project} other {Projects}}"
      description="Title on the bulk delete modal"
      id="PIQaXf"
      values={{itemCount}}
    />
  );
};

const RemoveText = ({bulkAction, itemCount}: {bulkAction: BulkAction; itemCount: number}) => {
  return bulkAction === BulkAction.HIDE ? (
    <FormattedMessage
      defaultMessage="Are you sure you want to hide {itemCount} {itemCount, plural, one {item from Replay? The item} other {items from Replay? The items}} will be hidden in Replay and will reappear if you open the file's shared link."
      description="Message on the bulk deletion modal that describes what happens when files are hidden and asks the user to confirm if they should be hidden"
      id="oTDFBa"
      values={{itemCount}}
    />
  ) : bulkAction === BulkAction.DELETE ? (
    <FormattedMessage
      defaultMessage="{itemCount, plural, one {This file as well as it's} other {These files as well as their}} share links and comments, will be permanently deleted and you won't be able to recover them."
      description="Message on the bulk deletion modal that asks the user to confirm if the items should be deleted from Replay"
      id="KLg3iQ"
      values={{itemCount}}
    />
  ) : (
    <FormattedMessage
      defaultMessage="Are you sure you want to leave {itemCount} {itemCount, plural, one {project} other {projects}}?"
      description="Title on the bulk deletion modal that asks the user to confirm if they want to leave the projects"
      id="9ItYto"
      values={{itemCount}}
    />
  );
};

const RemoveButtonText = ({bulkAction}: {bulkAction: BulkAction}) => {
  return bulkAction === BulkAction.HIDE ? (
    <FormattedMessage
      defaultMessage="Hide"
      description="Text on the hide button on the bulk delete modal to hide items"
      id="0pxxA5"
    />
  ) : bulkAction === BulkAction.DELETE ? (
    <FormattedMessage
      defaultMessage="Permanently delete"
      description="Text on the delete button on the bulk delete modal to delete items"
      id="SUfp5V"
    />
  ) : (
    <FormattedMessage
      defaultMessage="Leave"
      description="Text on the leave button on the bulk delete modal to leave projects"
      id="mjbaLL"
    />
  );
};

export const BulkDeleteModal = ({
  onBulkDelete,
  requestClose,
  updateModifyItemSnackbar,
}: BulkDeleteModalProps) => {
  const intl = useIntl();

  const folderIds = useAtomValue(selectedFolderIdsAtom);
  const projectIds = useAtomValue(selectedProjectIdsAtom);
  const bulkAction = useAtomValue(bulkActionAtom);
  const bulkActionStatus = useAtomValue(bulkActionStatusAtom);
  const deselectAllItems = useSetAtom(deselectAllItemsAtom);
  const clearBulkAction = useSetAtom(clearBulkActionAtom);
  const requestBulkAction = useSetAtom(requestBulkActionAtom);
  const refreshUserSpaceAction = useSetAtom(refetchableUserSpaceAtom);
  const sessionContext = useReelAppGlobalState();
  const itemCount = folderIds.length + projectIds.length;

  // We transformed the button's appearance from blue to red by switching its variant from 'opacity' to 'primary.'
  // This change allowed us to reveal the red button's background CSS
  const deleteButtonVariant = bulkAction === BulkAction.DELETE ? 'opacity' : 'primary';

  const handleDelete = async () => {
    if (!bulkAction) return;

    requestBulkAction(bulkAction);

    try {
      if (bulkAction === BulkAction.LEAVE_TEAM_PROJECT) {
        await leaveProjects(folderIds);

        onBulkDelete(folderIds, []);
      } else {
        const result = await deleteItems(folderIds, projectIds, bulkAction === BulkAction.HIDE);

        const deletedFolderIds = Object.entries(result.folderIdToStatus)
          .filter(([, status]) => status === GenericStatus.SUCCESS)
          .map(([folderId]) => folderId);

        const deletedProjectIds = Object.entries(result.projectIdToStatus)
          .filter(([, status]) => status === GenericStatus.SUCCESS)
          .map(([projectId]) => projectId);

        sessionContext.status === 'logged in' && sessionContext.refreshProvisions();
        onBulkDelete(deletedFolderIds, deletedProjectIds);
      }

      updateModifyItemSnackbar({
        type: 'remove-bulk',
        status: 'success',
        itemType: ItemType.BULK,
        itemCount,
      });
      deselectAllItems();
      requestClose();
      refreshUserSpaceAction();
    } catch (e) {
      reportException(
        new ReplayError({
          error: e,
          severity: 'non-critical',
          tags: ['delete_items_failed'],
          category: ReplayErrorCategory.UncaughtException,
        }),
      );
      updateModifyItemSnackbar({
        type: 'remove-bulk',
        status: 'error',
        itemType: ItemType.BULK,
        itemCount,
      });
      requestClose();
      return;
    } finally {
      clearBulkAction();
    }
  };

  const closeDeleteModalText = intl.formatMessage({
    defaultMessage: 'Close remove modal',
    id: 'dJM2f+',
    description: 'Label for the button that closes the remove modal',
  });

  if (!bulkAction) {
    return null;
  }

  return (
    <Modal
      isCentered
      onRequestClose={requestClose}
      open={[BulkAction.DELETE, BulkAction.HIDE, BulkAction.LEAVE_TEAM_PROJECT].includes(
        bulkAction,
      )}
      withCloseButton={closeDeleteModalText}
    >
      <Modal.Header hasBottomSpacing="title-small">
        <Title isBold size="small">
          <RemoveModalTitle bulkAction={bulkAction} itemCount={itemCount} />
        </Title>
      </Modal.Header>
      <DeleteModalBody>
        <DeleteModalBodyTitle>
          <Text>
            <RemoveText bulkAction={bulkAction} itemCount={itemCount} />
          </Text>
        </DeleteModalBodyTitle>
        <ModalButtons>
          <Button
            href="https://help.dropbox.com/delete-restore/dropbox-replay-archive"
            target="_blank"
            variant="transparent"
          >
            <FormattedMessage
              defaultMessage="Learn about archiving"
              description="Text on the remove modal for link to archiving infomation"
              id="s340Rl"
            />
          </Button>
          <CancelButton>
            <Button onClick={requestClose} size="standard" variant="opacity">
              <FormattedMessage
                defaultMessage="Cancel"
                description="Text on the button on the bulk item deletion modal to cancel deleting the items from Replay"
                id="wFfnmb"
              />
            </Button>
          </CancelButton>
          <DeleteButtonContainer>
            <DeleteButton>
              <Button
                inverse={deleteButtonVariant === 'opacity'}
                isLoading={bulkActionStatus === BulkActionStatus.REQUESTING}
                onClick={handleDelete}
                size="standard"
                variant={deleteButtonVariant}
              >
                <RemoveButtonText bulkAction={bulkAction} />
              </Button>
            </DeleteButton>
          </DeleteButtonContainer>
        </ModalButtons>
      </DeleteModalBody>
    </Modal>
  );
};
