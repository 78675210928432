import type {ReadStream} from 'fs';

import {BLOCK_SIZE} from './uploads/types';

/**
 * Returns an async iterator where each element contains a buffer that is
 * guaranteed, except for the last iteration, to have exactly 4MB of data.
 *
 * Elements contain an offset of where this buffer starts in the file.
 *
 * WARNING: before invoking the next iteration, the buffer must be copied
 * elsewhere if two iterations can exist at any one time.
 */
export async function* chunks(reader: ReadableStreamBYOBReader, {signal}: {signal: AbortSignal}) {
  let buffer = new ArrayBuffer(BLOCK_SIZE);
  let offset = 0;
  let totalOffset = 0;
  while (true) {
    if (signal.aborted) {
      throw signal.reason;
    }

    const {done, value} = await reader.read(
      new Uint8Array(buffer, offset, buffer.byteLength - offset),
    );

    if (signal.aborted) {
      throw signal.reason;
    }

    if (done) {
      if (value) {
        yield {buffer: value.buffer.slice(0, offset), offset: totalOffset};
      }
      return;
    }

    buffer = value.buffer;
    offset += value.byteLength;
    if (offset == BLOCK_SIZE) {
      offset = 0;
      yield {buffer, offset: totalOffset};
      totalOffset += BLOCK_SIZE;
    }
  }
}

/**
 * Convert node.js read stream to an async generator:
 * https://exploringjs.com/nodejs-shell-scripting/ch_web-streams.html#ReadableStream-async-iteration
 */
export async function* nodeStreamToIterator(stream: ReadStream) {
  for await (const chunk of stream) {
    yield chunk;
  }
}

/**
 * Create a readable byte stream from an async iterator that yields buffers.
 */
export function createReadableByteStream(iterator: AsyncIterableIterator<{buffer: ArrayBuffer}>) {
  return new ReadableStream({
    type: 'bytes',
    async pull(controller) {
      const {value, done} = await iterator.next();
      if (done || value.buffer.byteLength === 0) {
        controller.close();
        controller.byobRequest?.respond(0);
      } else if (controller.byobRequest?.view) {
        const v = controller.byobRequest.view;
        const send = new Uint8Array(value.buffer);
        new Uint8Array(v.buffer).set(send, v.byteOffset);
        controller.byobRequest.respond(send.byteLength);
      } else {
        controller.enqueue(new Uint8Array(value.buffer));
      }
    },
  });
}
