import {Suspense} from 'react';

import {getCurrentUserIsDropboxer, useReelAppGlobalState} from '~/context';
import {lazy} from '~/lib/lazy';

const AsyncDebugPanel = lazy(() =>
  import(/* webpackChunkName: 'debug_panel' */ './debug_panel').then((exps) => ({
    default: exps.DebugPanel,
  })),
);

export const LazyDebugPanel = () => {
  const sessionContext = useReelAppGlobalState();
  const isDropboxer = getCurrentUserIsDropboxer(sessionContext);

  if (!isDropboxer) return null;

  return (
    <Suspense fallback={null}>
      <AsyncDebugPanel />
    </Suspense>
  );
};
