import {useCallback, useMemo} from 'react';

import {useAtomValue, useSetAtom} from 'jotai';
import {useAtomCallback} from 'jotai/utils';
import {PAP_Upload_File} from 'pap-events/manual_upload/upload_file';

import {getVersionSummaries} from '~/lib/api';
import type {UploadLoggingData, UploadLoggingDataMap, UploadsProgress} from '~/lib/uploads/types';
import {
  FileUploadState,
  type MappedUploadTypes,
  UploadErrorType,
  type UploadInfo,
  type UploadProgress,
} from '~/lib/uploads/types';
import {useActionSurface} from '~/lib/use_action_surface';
import {useLoggingClient} from '~/lib/use_logging_client';
import {
  currentUploadBatchIdsAtom,
  handleInflightLoggingDataAtom,
  handleUpdateUploadDrawerProgressAtom,
  handleUpdateUploadDrawerProgressByIdAtom,
  handleUpdateUploadDrawerUploadByIdAtom,
  handleUpdateUploadDrawerUploadsAtom,
  handleUpdateVersionSummariesAtom,
  uploadsAtom,
  uploadsloggingDataAtom,
  uploadsProgressAtom,
} from '~/state/uploads';

const useUploadDrawerUploadsAndProgress = <T extends MappedUploadTypes>() => {
  const getActionSurface = useActionSurface();

  const uploads = useAtomValue(uploadsAtom) as T;
  const uploadsProgress = useAtomValue(uploadsProgressAtom);
  const currentUploadBatchIds = useAtomValue(currentUploadBatchIdsAtom);
  const loggingClient = useLoggingClient({surface: getActionSurface()});

  const handleUpdateUploadById = useSetAtom(handleUpdateUploadDrawerUploadByIdAtom);
  const handleUpdateUploadProgressById = useSetAtom(handleUpdateUploadDrawerProgressByIdAtom);
  const handleUpdateUploads = useSetAtom(handleUpdateUploadDrawerUploadsAtom);
  const handleUpdateProgress = useSetAtom(handleUpdateUploadDrawerProgressAtom);
  const handleUpdateCurrentUploadBatchIds = useSetAtom(currentUploadBatchIdsAtom);
  const setInFlightLoggingData = useSetAtom(handleInflightLoggingDataAtom);
  const handleUpdateVersionSummaries = useSetAtom(handleUpdateVersionSummariesAtom);

  // use these 2 callback functions when we need the most-up-to-date at a given point
  // primarily used in callback statements for async tasks, like cancel, error, retry
  const getUploads = useAtomCallback<T, []>(useCallback((get) => get(uploadsAtom) as T, []));
  const getUploadsProgress = useAtomCallback(useCallback((get) => get(uploadsProgressAtom), []));

  const getLoggingData = useAtomCallback<UploadLoggingDataMap, []>(
    useCallback((get) => get(uploadsloggingDataAtom), []),
  );

  const updateUploadProgress = useCallback(
    (uploadId: string, progress: Partial<UploadProgress>) => {
      const currentProgress = getUploadsProgress()[uploadId] || {};
      handleUpdateUploadProgressById({
        uploadId,
        updatedUploadProgress: {
          status: progress.status ?? currentProgress.status,
          percentage: progress.percentage ?? currentProgress.percentage,
          uploadedSize: progress.uploadedSize ?? currentProgress.uploadedSize,
          error: progress.error ?? currentProgress.error,
        },
      });
    },
    [getUploadsProgress, handleUpdateUploadProgressById],
  );

  const updateUpload = useCallback(
    async (uploadId: string, upload: Partial<UploadInfo>) => {
      if (upload.videoId) {
        const summaries = await getVersionSummaries({
          type: 'video_id',
          videoId: upload.videoId,
        });

        handleUpdateVersionSummaries({[uploadId]: summaries});
      }

      handleUpdateUploadById({
        uploadId,
        updatedUpload: {
          ...upload,
        } as UploadInfo,
      });
    },
    [handleUpdateUploadById, handleUpdateVersionSummaries],
  );

  const updateMappedUploadAsError = useCallback(
    (uploadId: string, upload: Partial<UploadInfo>, error?: any) => {
      let errorType = UploadErrorType.UNKNOWN_ERROR;
      switch (error) {
        case UploadErrorType.ADD_TO_ROOT_FOLDER_ERROR:
          errorType = UploadErrorType.ADD_TO_ROOT_FOLDER_ERROR;
          break;
        case UploadErrorType.FILE_QUOTA_EXCEEDED_ERROR:
          errorType = UploadErrorType.FILE_QUOTA_EXCEEDED_ERROR;
          break;
        case UploadErrorType.USAGE_QUOTA_EXCEEDED_ERROR:
          errorType = UploadErrorType.USAGE_QUOTA_EXCEEDED_ERROR;
          break;
        default:
      }

      updateUploadProgress(uploadId, {
        status: FileUploadState.Error,
        percentage: 100,
        error: errorType,
      });

      return {
        ...upload,
        canceled: true,
        error: UploadErrorType.UploadFailed,
      };
    },
    [updateUploadProgress],
  );

  const cancelUpload = useCallback(
    (uploadId: string, loggingUploadId?: string) => {
      updateUploadProgress(uploadId, {
        status: FileUploadState.Cancelled,
        percentage: 100,
      });
      const currentDate = new Date();
      loggingClient.logPap(
        PAP_Upload_File({
          ...getLoggingData()[loggingUploadId || uploadId],
          cancelTime: currentDate.getTime(),
          eventState: 'canceled',
          fileCategory: 'other',
        }),
      );
    },
    [getLoggingData, loggingClient, updateUploadProgress],
  );

  // temporarily need this for the old version of the uploader where we show the modal and
  // snackbar with only the currently uploading files
  type CurrentUploadsAndProgress = {
    currentUploads: T;
    currentUploadsProgress: UploadsProgress;
  };
  const {currentUploads, currentUploadsProgress} = useMemo<CurrentUploadsAndProgress>(() => {
    const currentUploads = {} as T;
    const currentUploadsProgress = {} as UploadsProgress;

    for (const uploadId of currentUploadBatchIds) {
      const newUpload = uploads[uploadId];
      const newProgress = uploadsProgress[uploadId];

      if (newUpload && newProgress) {
        currentUploads[uploadId] = newUpload;
        currentUploadsProgress[uploadId] = newProgress;
      }
    }

    return {
      currentUploads,
      currentUploadsProgress,
    };
  }, [currentUploadBatchIds, uploads, uploadsProgress]);

  const updateLoggingData = useCallback(
    (uploadId: string, loggingData: Partial<UploadLoggingData>) => {
      const baseLoggingData = {
        videoId: '',
        videoVersionId: '',
        actionSource: '',
        uploadMethod: '',
        contentType: 'file',
        failureType: '',
        fileSize: 0,
        startType: 'initial',
        batchIdString: '',
        uploadId: '',
        eventState: 'start',
        fileCategory: undefined,
        fileExtension: '',
      } as UploadLoggingDataMap;

      setInFlightLoggingData({
        uploadId,
        loggingData: {
          ...baseLoggingData,
          ...(getLoggingData()[uploadId] || {}),
          ...loggingData,
        } as UploadLoggingData,
      });
    },
    [getLoggingData, setInFlightLoggingData],
  );

  const isUploadCanceled = useCallback(
    (uploadId: string) => {
      return getUploadsProgress()[uploadId].status === FileUploadState.Cancelled;
    },
    [getUploadsProgress],
  );

  return useMemo(
    () => ({
      cancelUpload,
      currentUploads,
      currentUploadsProgress,
      getActionSurface,
      getLoggingData,
      getUploads,
      getUploadsProgress,
      handleUpdateCurrentUploadBatchIds,
      handleUpdateProgress,
      handleUpdateUploadById,
      handleUpdateUploads,
      isUploadCanceled,
      updateLoggingData,
      updateMappedUploadAsError,
      updateUpload,
      updateUploadProgress,
      uploads,
      uploadsProgress,
    }),
    [
      cancelUpload,
      currentUploads,
      currentUploadsProgress,
      getActionSurface,
      getLoggingData,
      getUploads,
      getUploadsProgress,
      handleUpdateCurrentUploadBatchIds,
      handleUpdateProgress,
      handleUpdateUploadById,
      handleUpdateUploads,
      isUploadCanceled,
      updateLoggingData,
      updateMappedUploadAsError,
      updateUpload,
      updateUploadProgress,
      uploads,
      uploadsProgress,
    ],
  );
};

export {useUploadDrawerUploadsAndProgress};
