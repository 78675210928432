/**
 * https://developers.google.com/picker/docs/reference#view-id
 */
export const enum ViewId {
  DOCS = 'all',
  DOCS_IMAGES = 'docs-images',
  DOCS_IMAGES_AND_VIDEOS = 'docs-images-and-videos',
  DOCS_VIDEOS = 'docs-videos',
  DOCUMENTS = 'documents',
  DRAWINGS = 'drawings',
  FOLDERS = 'folders',
  FORMS = 'forms',
}
