// https://stackoverflow.com/a/4819886
export const isTouchSupported = () => {
  return (
    'ontouchstart' in window ||
    navigator.maxTouchPoints > 0 ||
    (navigator as any).msMaxTouchPoints > 0
  );
};

export const isHoveringSupported = () => {
  return !matchMedia('(hover: none)').matches;
};
