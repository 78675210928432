export type ShareToken = string;
export type GrantBook = string;

const grantbookCache: Map<ShareToken, GrantBook> = new Map();

type GrantbookListener = (key: ShareToken, value: GrantBook) => void;
const listeners: Set<GrantbookListener> = new Set();

export const grantBooks = {
  get: (shareToken: ShareToken | undefined) =>
    shareToken ? grantbookCache.get(shareToken) : undefined,

  set: (key: ShareToken, value: GrantBook) => {
    grantbookCache.set(key, value);
    listeners.forEach((listener) => listener(key, value));
  },

  subscribe(listener: GrantbookListener): () => void {
    listeners.add(listener);
    return () => listeners.delete(listener);
  },
};
