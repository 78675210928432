import {useMemo} from 'react';

import {useAtom} from 'jotai';
import {atomWithStorage, createJSONStorage} from 'jotai/utils';
import {useLocation} from 'react-router';

/**
 * This is a list of e2e features that can be toggled on/off.
 *
 * These features are specifically useful for e2e tests so that we can toggle specific pieces
 * and simulate different workflows even though we're sharing the same accounts.
 *
 * They differ from stormcrows in that they're toggleable with a query param
 * that starts with `e2e_`. To add a new default feature, add it in `e2e/replay_test.ts`
 */
const e2eFeatures = ['onboarding', 'show_devtools'];

type E2eFeature = (typeof e2eFeatures)[number];
type E2eFeatures = Record<E2eFeature, boolean>;

const defaults = e2eFeatures.reduce(
  (acc, feature) => ({
    ...acc,
    [feature]: true,
  }),
  {} as E2eFeatures,
);

const storage = createJSONStorage<E2eFeatures>(() => localStorage);

const e2eFeatureRawAtom = atomWithStorage('e2e-features', defaults, storage, {getOnInit: true});

/**
 * Check if a specific e2e feature is on (enabled, just like stormcrow).
 *
 * These features are useful for simulating account state in our e2e environment.
 */
export const useE2eFeatureIsOn = (feature: E2eFeature) => {
  const [e2eFeatures, setE2eFeatures] = useAtom(e2eFeatureRawAtom);
  const locationParams = useLocation();

  return useMemo(() => {
    const params = new URLSearchParams(locationParams.search);

    let featureValue = e2eFeatures[feature];
    const searchValue = params.get(`e2e_${feature}`);
    if (searchValue) {
      try {
        featureValue = Boolean(JSON.parse(searchValue));
      } catch (e) {
        // Nothing to do
      }
    }

    if (e2eFeatures[feature] !== featureValue) {
      setE2eFeatures((prev) => ({
        ...prev,
        [feature]: featureValue,
      }));
    }

    return featureValue;
  }, [e2eFeatures, feature, locationParams.search, setE2eFeatures]);
};
