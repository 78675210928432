import React from 'react';

import {useAtom, useAtomValue, useSetAtom} from 'jotai';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {Navigate, useLocation, useNavigate} from 'react-router';
import styled from 'styled-components';

import {useBreakpoint} from '@dropbox/dig-components/hooks';
import {Spinner} from '@dropbox/dig-components/progress_indicators';
import {Box} from '@dropbox/dig-foundations';

import {BasicAddOnUpsellModal} from '~/components/add_on_upsell_modal';
import {AddTeamModal} from '~/components/add_team_modal';
import type {ItemType} from '~/components/common';
import {DropArea} from '~/components/drop_area';
import {useExtensionContext} from '~/components/extension_context';
import {ManageAccessModal} from '~/components/manage_access_modal';
import type {SnackbarStatus} from '~/components/modify_item_snackbar';
import {
  useOnboardingContext,
  useShouldBypassOnboarding,
} from '~/components/onboarding_v2/onboarding_provider';
import {CopyLinkSnackbar} from '~/components/share_modal/copy_link_snackbar';
import {ShareModal} from '~/components/share_modal/share_modal';
import {ShareSnackbar} from '~/components/share_modal/share_snackbar';
import {isShareModalOpenAtom} from '~/components/share_modal/share_state';
import type {VersionStatus} from '~/components/status/status';
import {useViewport} from '~/components/viewport_context';
import {useReelAppGlobalState} from '~/context';
import {IS_DBX_DEVBOX} from '~/context_utils';
import type {DemoButtonProp} from '~/lib/api';
import {createDemoProject, logAggregatePageVisit} from '~/lib/api';
import type {ProjectAccessType} from '~/lib/logging/logger_types';
import {useOnboardingChecklist} from '~/lib/onboarding_v2/use_onboarding_checklist';
import {SEGMENTATION_QUIZ_PENDING_PURCHASE_STATE, useExceededFileQuota} from '~/lib/provisions';
import {BrowseTab} from '~/lib/query_client';
import {replayStorage} from '~/lib/storage';
import {SessionStorage} from '~/lib/storage_utils';
import type {DirectUpload} from '~/lib/uploads/types';
import {useCanUserCreateLinks} from '~/lib/use_folder_share_onclick';
import {useOnce} from '~/lib/use_once';
import {UserSurvey} from '~/lib/user_survey';
import {getPageContext, getReferrer, useAreSuperAdminFeaturesEnabled} from '~/lib/utils';
import {BrowsePageHeader} from '~/pages/layout/components/browse_page_header';
import {
  browseAtom,
  browseLoadingState,
  browseSortAtom,
  browseViewTypeAtom,
  deselectAllItemsAtom,
} from '~/state/browse';
import {hasCheckedForOnboardingRedirectAtom, STEPS} from '~/state/onboarding_v2';
import {SEEN_FILE_LIMIT_UPSELL_MODAL} from '~/state/user';
import {isInExtension} from '~/use_extensions';

import {BrowsePageItemsView} from './browse_items_view';
import {BrowseLoggingProvider, useBrowseLogEventMaker} from './browse_logging_context';
import {BrowseSnackbarStatus} from './components/browse_page_snackbar';
import {CannyButton, CannyButtonBuffer} from './components/canny_button';
import type {BrowseItemsProps, NavigationRouteProps, ShareRecipient} from './components/common';
import {BrowsePageFolderHeader, ViewType} from './components/header/browse_page_folder_header';
import {NavigationLeftRail} from './components/navigation_left_rail';
import {useSettingsRow} from './components/settings_row';
import {WelcomeModal} from './components/welcome_modal';
import {FoldersProvider, useFoldersSharedState} from './folders_context';
import {useEditActions} from './use_edit_actions';
import {useShareRecipients} from './use_share_recipients';
import {Page} from '../components/page';
import {ErrorPage} from '../error_page';

const PageContentContainer = styled.div`
  flex: 1 1 0;
  background: var(--dig-color__background__base);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: var(--spacing__unit--2);
  padding-top: 0;
  width: 100%;
  overflow-x: hidden;
`;

const BrowsePageContentWrapper = styled.div<{$isLeftRailOpen: boolean}>`
  display: flex;
  flex-direction: column;
  margin-left: ${(props) => (props.$isLeftRailOpen ? 235 : 0)}px;
  transition: margin-left 0.5s;
`;

const LoadingWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const MainContent = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

export type ModifyItemSnackbarInfo =
  | {
      type: 'rename';
      status: SnackbarStatus;
      itemType: ItemType;
      originalName: string;
      newName: string;
    }
  | {
      type: 'remove';
      status: SnackbarStatus;
      itemType: ItemType;
      name: string;
      versionNumber?: number;
    }
  | {
      type: 'remove-bulk';
      status: SnackbarStatus;
      itemType: ItemType.BULK;
      itemCount: number;
    }
  | {
      type: 'update-version-status-bulk';
      status: SnackbarStatus;
      itemType: ItemType.BULK;
      itemCount: number;
      itemStatus: VersionStatus;
    };

const BrowsePageContent = ({routeName}: NavigationRouteProps) => {
  // eslint-disable-next-line deprecation/deprecation
  const {isSmallScreenSize, isMobileDevice} = useViewport();
  const {medium} = useBreakpoint();
  const showUpsellModal = !isMobileDevice;
  const sessionContext = useReelAppGlobalState();
  const {redCarpet} = useExtensionContext();
  const areSuperAdminFeaturesEnabled = useAreSuperAdminFeaturesEnabled();

  const [showWelcomeModal, setShowWelcomeModal] = React.useState<boolean>(false);
  const [demoInfo, setDemoInfo] = React.useState<DemoButtonProp>();

  const [isShareModalOpen, setIsShareModalOpen] = useAtom(isShareModalOpenAtom);

  const hasCheckedForOnboardingRedirect = useAtomValue(hasCheckedForOnboardingRedirectAtom);
  const {onboardingActions, updateOnboardingActions} = useOnboardingContext();

  const deselectAllItems = useSetAtom(deselectAllItemsAtom);
  const setBrowse = useSetAtom(browseAtom);
  const setBrowseSort = useSetAtom(browseSortAtom);
  const setBrowseViewType = useSetAtom(browseViewTypeAtom);

  const [pageTitle, setPageTitle] = React.useState<string | undefined>();

  const shouldBypassOnboarding = useShouldBypassOnboarding();
  const location = useLocation();
  const pathname = location.pathname;
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  // Redirect parameters for users coming from preview prompt
  const redirectProjectId = params.get('redirect_project_id') ?? undefined;
  const redirectVideoId = params.get('redirect_video_id') ?? undefined;
  const isSharedView = routeName === 'browse-shared';
  const isFolderOnSharedView = isSharedView && pathname.startsWith('/shared/folder');

  const {
    breadcrumbPath,
    clearCurrentFolder,
    currentAllowSuperAdminRights,
    currentFolder,
    currentFolderError,
    currentFolderId,
    currentFolderPermission,
    currentProjectAmplitudeId,
    leftRailStatus,
    updateFolder,
    updateFolderList,
    updateFolderTree,
    updateProjectList,
  } = useFoldersSharedState();

  const refreshFolders = React.useCallback(
    (folderId?: string) => {
      updateFolder(folderId || currentFolderId, routeName === 'browse-shared', true);
    },
    [currentFolderId, routeName, updateFolder],
  );

  const editActionsDeps = useEditActions({
    refreshFolders,
    folderLevel: breadcrumbPath ? breadcrumbPath.length : 1,
  });

  const {
    handleAddFileFromUpload,
    handleAllUploadsComplete,
    onAddVideoError,
    handleAddFileFromDropbox,
    onFilePick,
    uploadingProjects,
    uploadingFolders,
    openAddPeopleModal,
    addPeopleModalIsOpen,
    modifyPeopleModalIsOpen,
    selectedFolderId,
    selectedFolderName,
    closeModifyPeopleModal,
    closeAddPeopleModal,
  } = editActionsDeps;

  // Used to track the health of the auth flow from our marketing campaigns
  useOnce(() => {
    const campaignContext = getPageContext();
    logAggregatePageVisit('home_page', campaignContext);
  });

  const makeLogEvent = useBrowseLogEventMaker();
  const logEvent = React.useMemo(
    () => makeLogEvent({projectId: currentProjectAmplitudeId}),
    [makeLogEvent, currentProjectAmplitudeId],
  );

  const {handleCompleteStep} = useOnboardingChecklist({
    logEvent,
  });

  React.useEffect(() => {
    if (leftRailStatus.status !== 'closed') {
      // eslint-disable-next-line deprecation/deprecation
      logEvent('succeed_open_move_rail');
    }
  }, [leftRailStatus, logEvent]);

  React.useEffect(() => {
    if (currentFolder) {
      UserSurvey.addAttribute('numberOfReelProjects', currentFolder.projectsWithVideos.length);
      UserSurvey.addAttribute('hasCreatedReelProject', 'true');
    }
  }, [currentFolder]);

  const shareRecipients = useShareRecipients(currentFolder);

  const onDropUploadFileComplete = React.useCallback(
    ({projectId, file: {name}, videoId}: DirectUpload, fileId: string) => {
      handleAddFileFromUpload(
        'drag_and_drop',
        breadcrumbPath ? breadcrumbPath.length : 1,
        projectId!,
        videoId!,
        name,
      );

      handleCompleteStep(STEPS.UPLOAD_FILE);
    },
    [handleAddFileFromUpload, breadcrumbPath, handleCompleteStep],
  );

  React.useEffect(() => {
    if (!breadcrumbPath) {
      return;
    }
    if (breadcrumbPath.length === 1) {
      return;
    }
    const currentFolder = breadcrumbPath[breadcrumbPath.length - 1];
    if (!currentFolder) {
      return;
    }
    const {name} = currentFolder;
    if (name) {
      setPageTitle(name);
    }
  }, [breadcrumbPath]);

  const onCreateFolderSuccess = React.useCallback(
    async (folderToShow: string, level: number) => {
      await Promise.all([refreshFolders(folderToShow), updateFolderTree()]);
      if (!breadcrumbPath || (breadcrumbPath && breadcrumbPath.length === 1)) {
        // eslint-disable-next-line deprecation/deprecation
        logEvent('create_team_project');
      } else {
        // eslint-disable-next-line deprecation/deprecation
        logEvent('create_reel_folder', {folder_level: level});
      }
    },
    [breadcrumbPath, logEvent, refreshFolders, updateFolderTree],
  );

  const addDemoProject = React.useCallback(async () => {
    try {
      const result = await createDemoProject();
      setDemoInfo(result);
    } catch (error) {
      // On first creation in devbox, this can throw a 409 even though it was successful
      if (!(error.status === 409 && IS_DBX_DEVBOX)) {
        throw error;
      }
    }

    await refreshFolders();
  }, [refreshFolders]);

  /**
   * The hooks below perform browse-page specific logic that should run exactly
   * once after the initial fetch of each folder is available.
   */

  const splitPath = pathname.split('/folder/');
  const folderIdFromPath = decodeURIComponent(splitPath.length === 2 ? splitPath[1] : '');
  const folderIdOrTabName =
    folderIdFromPath === ''
      ? routeName === 'browse-shared'
        ? BrowseTab.SHARED
        : BrowseTab.HOME
      : folderIdFromPath;
  // Tuple representing the folder the user has navigated to and whether it has fully loaded
  const folderIdLoadingTuple = React.useRef<[string | BrowseTab, boolean]>([
    folderIdOrTabName,
    false,
  ]);

  React.useEffect(() => {
    // Whenever the user navigates to a new folder, we reset the tuple
    folderIdLoadingTuple.current = [folderIdOrTabName, false];
  }, [folderIdOrTabName]);

  React.useEffect(() => {
    // If we have data for the current folder, make sure we haven't already run
    // this effect based on it
    const [loadingFolderIdOrTabName, hasLoaded] = folderIdLoadingTuple.current;
    if (currentFolder === null || hasLoaded) {
      return;
    }

    // This is ugly but because the shared and the home tabs render under
    // an `exact` route path match, there can never be a race to load the
    // folder data on these pages - because they will always mount the page
    // fresh. So it is safe to continue if the folder ids match _or_ if the
    // folder that we loaded was the root folder in some guise.
    if (
      currentFolder.folderId !== loadingFolderIdOrTabName &&
      loadingFolderIdOrTabName !== BrowseTab.SHARED &&
      loadingFolderIdOrTabName !== BrowseTab.HOME
    ) {
      return;
    }

    // OK, we have data for the current folder
    folderIdLoadingTuple.current = [folderIdOrTabName, true];

    /**
     * Redirect to the correct route based on permission level: /shared for
     * review-level access and just / for owner or admin-level access. This
     * covers for cases where the user lands directly on the wrong url, like
     * for example from the back button on videos in review-level team
     * projects.
     */
    const currentFolderPermission = currentFolder.accessLevel['.tag'];

    let maybeRedirectUrl = null;
    if (pathname.startsWith('/folder') && currentFolderPermission === 'reviewer') {
      maybeRedirectUrl = `/shared/folder/${folderIdFromPath}`;
    } else if (
      isFolderOnSharedView &&
      (currentFolderPermission === 'none' ||
        currentFolderPermission === 'owner' ||
        currentFolderPermission === 'admin' ||
        currentFolderPermission === 'super_admin')
    ) {
      maybeRedirectUrl = `/folder/${folderIdFromPath}`;
    }

    if (maybeRedirectUrl && maybeRedirectUrl !== pathname) {
      navigate(maybeRedirectUrl);
    }

    /**
     * Log the page view
     */
    let logAccessType: ProjectAccessType = 'other';
    if (
      currentFolderPermission === 'none' ||
      currentFolderPermission === 'owner' ||
      currentFolderPermission === 'super_admin'
    ) {
      logAccessType = 'owner';
    } else if (currentFolderPermission === 'remove' || currentFolderPermission === 'other') {
      logAccessType = 'other';
    } else {
      logAccessType = currentFolderPermission;
    }

    // eslint-disable-next-line deprecation/deprecation
    logEvent('shown_reel_page', {
      reel_page_source: 'home_page',
      access_type: logAccessType,
      email_campaign_id: 0,
      referrer: getReferrer() || '',
      red_carpet_mode: redCarpet.mode,
    });
    // We only want to run this effect based on changes to the currentFolder
    // state because the effect should complete once per successful page load -
    // ie, when the currentFolder state becomes populated and no longer `null`
  }, [currentFolder]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    setBrowse(browseLoadingState);
    clearCurrentFolder(); // reset folder during loading
    updateFolder(folderIdFromPath, routeName === 'browse-shared');
    updateFolderTree();
  }, [folderIdFromPath, routeName]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (!onboardingActions) {
      return;
    }
    const hasDemoVideo = !shouldBypassOnboarding && !isMobileDevice && !isSmallScreenSize;

    if (onboardingActions.dismissed_welcome_modal === false) {
      // Pre-GA, we used dismissed_welcome_modal to track if demo_project was created.
      // Post GA, we no longer have the welcome modal, so are manually setting the flag to true to keep consistency.
      updateOnboardingActions({dismissed_welcome_modal: true});

      if (hasDemoVideo) {
        addDemoProject();
      }
    }
  }, [
    addDemoProject,
    isMobileDevice,
    isSmallScreenSize,
    onboardingActions,
    shouldBypassOnboarding,
    updateOnboardingActions,
  ]);

  const makeFolderLink: BrowseItemsProps['makeFolderLink'] = React.useCallback(
    (linkArgs) => {
      if (isSharedView) {
        return `/shared/folder/${linkArgs.folderId}`;
      } else {
        return `/folder/${linkArgs.folderId}`;
      }
    },
    [isSharedView],
  );

  const expectShareToken = !isFolderOnSharedView && isSharedView;
  const makeFileLink: BrowseItemsProps['makeFileLink'] = React.useCallback(
    (linkArgs) => {
      if (expectShareToken) {
        // Share token comes back in the full listFolder request,
        // don't link until we can calculate it
        return linkArgs.shareToken ? `/share/${linkArgs.shareToken}` : '#';
      }

      return `/project/${linkArgs.projectId}/video/${linkArgs.videoId}`;
    },
    [expectShareToken],
  );

  const accessLevel = currentFolder?.accessLevel['.tag'] ?? 'other';
  const canCreateLinks = useCanUserCreateLinks({accessLevel});

  // Hide share button from root level and from users without sharing permissions
  const hasShareButton = (breadcrumbPath?.length ?? 0) > 1 && canCreateLinks;

  const exceededFileQuota = useExceededFileQuota();
  const [showFileLimitUpsellModal, setShowFileLimitUpsellModal] = React.useState(false);

  React.useEffect(() => {
    if (
      sessionContext.status === 'logged in' &&
      exceededFileQuota &&
      !SessionStorage.get(SEEN_FILE_LIMIT_UPSELL_MODAL)
    ) {
      setShowFileLimitUpsellModal(true);
    }
  }, [exceededFileQuota, sessionContext.status]);

  const allProjects = React.useMemo(() => {
    if (currentFolder === null) {
      // The initial fetch is loading
      return null;
    }
    const uploadingProjectsForFolder = uploadingProjects.get(currentFolderId) ?? [];
    return [...currentFolder.projectsWithVideos, ...uploadingProjectsForFolder];
  }, [uploadingProjects, currentFolder, currentFolderId]);

  const allFolders = React.useMemo(() => {
    if (currentFolder === null) {
      // The initial fetch is loading
      return null;
    }
    const uploadFoldersForFolder = uploadingFolders.get(currentFolderId) ?? [];
    return [...uploadFoldersForFolder, ...(currentFolder.folders ?? null)];
  }, [currentFolder, uploadingFolders, currentFolderId]);

  const isRoot = breadcrumbPath?.length === 1;

  const isProjectEmpty = React.useMemo(() => {
    return !(allProjects?.length || allFolders?.length);
  }, [allFolders, allProjects]);

  React.useEffect(() => {
    if (!allProjects || !allFolders) {
      setBrowse(browseLoadingState);
    } else {
      const hasFetchError = currentFolder !== null && currentFolder.cursor instanceof Error;
      const hasMorePages =
        currentFolder !== null &&
        currentFolder.cursor !== null &&
        !(currentFolder.cursor instanceof Error);

      setBrowse({
        accessLevel,
        isRoot,
        projects: allProjects,
        folders: allFolders,
        teamInfoItems: [],
        snackbar: {status: BrowseSnackbarStatus.HIDDEN},
        hasMorePages,
        hasFetchError,
      });
    }
    deselectAllItems();
  }, [accessLevel, allFolders, allProjects, currentFolder, deselectAllItems, isRoot, setBrowse]);

  React.useEffect(() => {
    setBrowseSort(replayStorage.getBrowseSort());
    setBrowseViewType(replayStorage.getBrowseViewType());
  }, [setBrowseSort, setBrowseViewType]);

  const isAdminPageView = routeName === 'admin-page';
  const [mobileAndFilter, tableHeader] = useSettingsRow({
    hasSelectAllCheckbox: editActionsDeps.editActionsEnabled,
    hasTopBorder: editActionsDeps.editActionsEnabled && !pathname.startsWith('/folder'),
    isAdminPageView,
    logEvent,
  });

  if (currentFolderError) {
    return <ErrorPage errorToReport={currentFolderError} />;
  }

  if (!hasCheckedForOnboardingRedirect) {
    return (
      <Page title={pageTitle}>
        <MainContent>
          <LoadingWrapper>
            <Spinner />
            <p />
          </LoadingWrapper>
        </MainContent>
      </Page>
    );
  }

  return (
    <>
      <Page title={pageTitle}>
        <DndProvider backend={HTML5Backend}>
          <BrowsePageHeader currentView={routeName} />
          <BrowsePageFolderHeader
            allowSuperAdminRights={currentAllowSuperAdminRights}
            hasShareButton={hasShareButton}
            makeFolderLink={makeFolderLink}
            onAddVideoError={onAddVideoError}
            onAddVideoFromDropbox={handleAddFileFromDropbox}
            onAllUploadsComplete={handleAllUploadsComplete}
            onCreateFolderSuccess={onCreateFolderSuccess}
            onFilePick={onFilePick}
            onFileUploadComplete={handleAddFileFromUpload}
            onOpenAddPeopleModal={openAddPeopleModal}
            onRefreshFolders={refreshFolders}
            routeName={routeName}
            shareRecipients={shareRecipients}
            viewType={
              routeName === 'browse-shared'
                ? ViewType.SHARED
                : routeName === 'admin-page'
                ? ViewType.ADMIN
                : ViewType.BROWSE
            }
          />

          <Box paddingLeft="16" paddingRight="16">
            {mobileAndFilter}
          </Box>
          <div role="table">
            <Box paddingLeft="16" paddingRight="16" role="rowgroup">
              {tableHeader}
            </Box>
          </div>

          {!medium && <NavigationLeftRail routeName={routeName} />}
          <PageContentContainer id="main">
            <DropArea
              isEnabled={!isSharedView}
              isProjectEmpty={isProjectEmpty}
              onAllUploadsComplete={(files, folderId, isFolder = false) =>
                handleAllUploadsComplete(
                  files,
                  folderId,
                  'drag_and_drop',
                  'direct_upload',
                  isFolder ? 'folder' : 'file',
                )
              }
              onDrop={onFilePick}
              onFileUploadComplete={onDropUploadFileComplete}
            >
              <BrowsePageContentWrapper $isLeftRailOpen={leftRailStatus.status !== 'closed'}>
                <BrowsePageItemsView
                  breadcrumbPath={breadcrumbPath ?? null}
                  currentFolderId={currentFolderId}
                  currentFolderPermission={currentFolderPermission ?? 'other'}
                  currentProjectAmplitudeId={currentProjectAmplitudeId}
                  editActionsDeps={editActionsDeps}
                  isRootLevelView={breadcrumbPath?.length === 1}
                  logEvent={logEvent}
                  makeFileLink={makeFileLink}
                  makeFolderLink={makeFolderLink}
                  onSyncFolderListState={updateFolderList}
                  onSyncProjectListState={updateProjectList}
                  refreshFolders={refreshFolders}
                  routeName={routeName}
                  shareRecipients={shareRecipients}
                />
                {showUpsellModal && (
                  <BasicAddOnUpsellModal
                    clickSource="browse_page"
                    onClose={() => {
                      SessionStorage.set(SEEN_FILE_LIMIT_UPSELL_MODAL, true);
                      setShowFileLimitUpsellModal(false);
                    }}
                    open={showFileLimitUpsellModal}
                    variant="unlimited_file_uploads"
                  />
                )}
                {showWelcomeModal && !isSmallScreenSize ? (
                  <WelcomeModal
                    currentFolderId={currentFolderId}
                    demoProjectId={demoInfo?.projectId}
                    demoVideoId={demoInfo?.videoId}
                    level={breadcrumbPath ? breadcrumbPath.length : 1}
                    logEvent={logEvent}
                    onAddVideoError={onAddVideoError}
                    onAddVideoFromDropbox={handleAddFileFromDropbox}
                    onAllUploadsComplete={handleAllUploadsComplete}
                    onFilePick={onFilePick}
                    onFileUploadComplete={handleAddFileFromUpload}
                    onRequestClose={(demo?: boolean) => {
                      setShowWelcomeModal(false);
                      if (demo) {
                        // eslint-disable-next-line deprecation/deprecation
                        logEvent('select_welcome_modal_open_demo');
                      }
                      updateOnboardingActions({dismissed_welcome_modal: true});
                    }}
                    pageSource="browse"
                    redirectProjectId={redirectProjectId}
                    redirectVideoId={redirectVideoId}
                  />
                ) : null}
                <AddTeamModal
                  open={addPeopleModalIsOpen}
                  requestClose={closeAddPeopleModal}
                  shareRecipients={shareRecipients}
                  sharedFolderId={selectedFolderId}
                  sharedFolderName={selectedFolderName}
                  successCallback={(
                    numPeopleAdded: number,
                    accessType: ProjectAccessType,
                    numPeopleTotal: number,
                  ) => {
                    // eslint-disable-next-line deprecation/deprecation
                    logEvent('succeed_add_members_team_project', {
                      access_type: accessType,
                      num_members_added: numPeopleAdded,
                      num_members_total: numPeopleTotal,
                    });
                    refreshFolders(currentFolderId);
                  }}
                  successGroupShareLoggingCallback={(
                    numPeopleAdded: number,
                    accessType: ProjectAccessType,
                    numPeopleTotal: number,
                  ) => {
                    // eslint-disable-next-line deprecation/deprecation
                    logEvent('fss_group_permission_added', {
                      access_type: accessType,
                      num_members_added: numPeopleAdded,
                      num_members_total: numPeopleTotal,
                    });
                  }}
                />
                {areSuperAdminFeaturesEnabled &&
                  shareRecipients &&
                  selectedFolderId in shareRecipients &&
                  Object.values(shareRecipients[selectedFolderId]).length > 1 && (
                    <ManageAccessModal
                      accessLevel={accessLevel}
                      allowSuperAdminRights={currentAllowSuperAdminRights}
                      logEvent={logEvent}
                      modalType="manage_access_only"
                      open={modifyPeopleModalIsOpen}
                      requestClose={closeModifyPeopleModal}
                      settingsChangeCallback={refreshFolders}
                      shareRecipients={
                        [
                          ...Object.values(shareRecipients[selectedFolderId].userAccounts),
                          ...Object.values(shareRecipients[selectedFolderId].groupAccounts),
                        ] as ShareRecipient[]
                      }
                      teamFolderId={selectedFolderId}
                      teamFolderName={selectedFolderName}
                    />
                  )}
                <ShareModal
                  canCreateLinks={canCreateLinks}
                  onRequestClose={() => setIsShareModalOpen(false)}
                  open={isShareModalOpen}
                />
                <CopyLinkSnackbar />
                <ShareSnackbar />
                {!isMobileDevice && !isInExtension() && <CannyButtonBuffer />}
                {!isMobileDevice && !isInExtension() && <CannyButton />}
              </BrowsePageContentWrapper>
            </DropArea>
          </PageContentContainer>
        </DndProvider>
      </Page>
    </>
  );
};

export const BrowsePage = ({routeName}: NavigationRouteProps) => {
  const sessionContext = useReelAppGlobalState();

  if (
    sessionContext.status === 'logged in' &&
    sessionContext.metadata.REPLAY_SEGMENTATION_QUIZ_VIEW_STATE ===
      SEGMENTATION_QUIZ_PENDING_PURCHASE_STATE
  ) {
    return <Navigate replace to="/onboarding/use-case" />;
  }

  return (
    <BrowseLoggingProvider
      location={routeName === 'browse-shared' ? 'shared_with_you' : 'your_work'}
    >
      <FoldersProvider>
        <BrowsePageContent routeName={routeName} />
      </FoldersProvider>
    </BrowseLoggingProvider>
  );
};
