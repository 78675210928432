import React from 'react';

import {useQueryClient} from '@tanstack/react-query';
import {useAtom, useAtomValue, useSetAtom} from 'jotai';
import {useNavigate} from 'react-router';
import styled from 'styled-components';

import type {reel} from '@dropbox/api-v2-client';
import {Box} from '@dropbox/dig-foundations';

import {BulkDeleteModal} from '~/components/bulk_delete_modal';
import {ItemType} from '~/components/common';
import type {VersionStatus} from '~/components/status/status';
import {VERSION_STATUS_PROPERTIES} from '~/components/status/status';
import type {LoggingClient} from '~/lib/logging/logger';
import type {MoveSourceType, NewVersionStatusType} from '~/lib/logging/logger_types';
import {mapMediaTypeToLoggingType} from '~/lib/logging/logger_types';
import {
  deleteFolderFromListFolderBasicInfo,
  updateFolderDisplayInfoName,
  updateFolderDisplayInfoNumProjects,
  updateMediaProjectDisplayInfoCurrentVersion,
  updateMediaProjectDisplayInfoName,
  updateMediaProjectDisplayInfoVersionStatus,
} from '~/lib/query_updates';
import {FileUploadState, type UploadsProgress} from '~/lib/uploads/types';
import type {
  ProjectMoveConfirmationProps,
  VersionUploadConfirmationProps,
} from '~/pages/browse_page/components/browse_page_snackbar';
import {
  BrowsePageSnackbar,
  BrowseSnackbarStatus,
} from '~/pages/browse_page/components/browse_page_snackbar';
import {BulkActionsRow} from '~/pages/browse_page/components/BulkActionsRow';
import type {
  BrowseFolder,
  BrowseItemsProps,
  BrowseProject,
  ItemLoadingStates,
  NavigationRouteTypes,
} from '~/pages/browse_page/components/common';
import {isUploadedFolder, isUploadedProject} from '~/pages/browse_page/components/common';
import {LoadingGridPlaceholder} from '~/pages/browse_page/components/tiles/tile_grid';
import {useFlowsNotifications} from '~/pages/browse_page/use_flows_notifications';
import {
  useIsCurrentPagePartOfAdminConsole,
  useIsCurrentPageSharedBrowsePage,
} from '~/route_path_matches';
import {
  browseSnackbarAtom,
  browseViewTypeAtom,
  deselectAllItemsAtom,
  foldersAtom,
  hasFetchErrorAtom,
  hasMorePagesAtom,
  hideBrowseSnackbarAtom,
  modifyItemsSnackbarAtom,
  projectsAtom,
  selectedAllVideoVersionsIdsAtom,
  selectedFolderIdsAtom,
  selectedProjectIdsAtom,
  selectedVersionIdsAtom,
  selectedVideoIdAtom,
} from '~/state/browse';
import {
  BulkAction,
  clearBulkActionAtom,
  initBulkActionAtom,
  requestBulkActionAtom,
} from '~/state/bulk_actions';
import {uploadsProgressAtom} from '~/state/uploads';

import type {BrowseItemsViewContextValue, EditActionsDeps} from './browse_items_view_context';
import {
  BrowseItemsViewContext,
  innerFolderRename,
  innerProjectRename,
} from './browse_items_view_context';
import {EmptyProjectList} from './components/empty_project_list/empty_project_list';
import {FoldersLeftRail} from './components/folders_left_rail';
import {ProjectGridView} from './components/project_grid_view';
import {LoadingProjectListView, ProjectListView} from './components/project_list_view';
import type {SuggestedItemClickHandler} from './components/suggested_items';
import {SuggestedItems} from './components/suggested_items';
import type {FolderToAccounts} from './use_share_recipients';
import {useDropboxChooser} from '../../components/dropbox_chooser_context';
import {
  dueDateModalCurrentVersionInfoAtom,
  DueDateVersionModal,
  dueDateVersionModalAtom,
  removeDueDateStateAtom,
} from '../../components/due_date/due_date_modal';
import {ModifyItemSnackbar} from '../../components/modify_item_snackbar';
import {MoveFailModal} from '../../components/move_fail_modal';
import {useViewport} from '../../components/viewport_context';
import {useReelAppGlobalState} from '../../context';
import type {ShareFolderAccessType} from '../../lib/api';
import {
  deleteTask,
  GenericStatus,
  getTasksForItem,
  listUserRootMediaProjects,
  updateVersionStatuses,
} from '../../lib/api';
import {FlowsBoltClient, isCompleteFlowsBoltPayload} from '../../lib/flows/bolt';
import {HAS_DISMISSED_SUGGESTED_ITEMS, replayStorage} from '../../lib/storage';
import {useStormcrows} from '../../lib/stormcrow';
import {useGatedDependencies} from '../../lib/use_gated_dependencies';
import {clearTaskStateAtom} from '../../state/task';
import {generateVersionComparePageUrl} from '../viewer_page/components/versions/version_utils';

const useBrowseDispatchers = (dispatchDeps: {
  currentFolderDepth: number;
  currentFolderId: string;
  logEvent: LoggingClient['logEvent'];
  editActionsDeps: EditActionsDeps;
  refreshFolders: (folderId?: string) => void;
  setFolderDestinationName: (name: string) => void;
  setShowErrorModal: (show: boolean) => void;
  syncFolderListState: (folders: BrowseFolder[]) => void;
  syncProjectListState: (projects: BrowseProject[]) => void;
}) => {
  const {
    currentFolderDepth,
    currentFolderId,
    logEvent,
    editActionsDeps,
    refreshFolders,
    setFolderDestinationName,
    setShowErrorModal,
    syncFolderListState,
    syncProjectListState,
  } = dispatchDeps;

  const [projects, setProjects] = useAtom(projectsAtom);
  const [folders, setFolders] = useAtom(foldersAtom);
  const clearBulkAction = useSetAtom(clearBulkActionAtom);

  const hideBrowseSnackbar = useSetAtom(hideBrowseSnackbarAtom);
  const setBrowseSnackbar = useSetAtom(browseSnackbarAtom);

  const uploadDrawerProgress = useAtomValue(uploadsProgressAtom);
  const uploadsProgressRef = React.useRef<UploadsProgress>(uploadDrawerProgress);
  uploadsProgressRef.current = uploadDrawerProgress;

  /* ---- Project actions ---- */
  const onProjectsDelete = React.useCallback(
    async (projectIds: string[]) => {
      if (projects) {
        const newProjects = projects.filter(
          (project) => !isUploadedProject(project) || !projectIds.includes(project.project!.id),
        );

        setProjects(newProjects);
        syncProjectListState(newProjects);
        // eslint-disable-next-line deprecation/deprecation
        logEvent('delete_reel_media', {media_count: projectIds.length});
      }
    },
    [logEvent, projects, setProjects, syncProjectListState],
  );

  const queryClient = useQueryClient();

  const onProjectRename = React.useCallback(
    (projectId: string, projectName: string) => {
      if (!projects) {
        return;
      }

      const newProjects = innerProjectRename(projects, projectId, projectName);
      setProjects(newProjects);
      syncProjectListState(newProjects);
      updateMediaProjectDisplayInfoName(queryClient, projectId, projectName);
    },
    [projects, queryClient, setProjects, syncProjectListState],
  );

  const onProjectMove = React.useCallback(
    async (
      projectId: string,
      projectName: string,
      destinationFolderId: string,
      destinationFolderName: string,
      moveSource: MoveSourceType,
      isCopy: boolean,
    ) => {
      updateFolderDisplayInfoNumProjects(queryClient, destinationFolderId, 1);

      const newFolders = folders!.map((folder) => {
        if (!isUploadedFolder(folder)) {
          return folder;
        }

        return folder.id === destinationFolderId
          ? {
              ...folder,
              num_projects: folder.num_projects! + 1,
            }
          : folder.id === currentFolderId
          ? {
              ...folder,
              num_projects: folder.num_projects! - 1,
            }
          : folder;
      });

      const newProjects =
        destinationFolderId == currentFolderId || isCopy
          ? projects
          : projects!.filter(
              (project) => !project.isUploading && project.project!.id !== projectId,
            );

      setFolders(newFolders);
      setProjects(newProjects);
      setBrowseSnackbar(
        destinationFolderId == currentFolderId
          ? {status: BrowseSnackbarStatus.HIDDEN}
          : {
              status: BrowseSnackbarStatus.MOVE_CONFIRMATION,
              onRequestClose: hideBrowseSnackbar,
              projectOrFolderName: projectName,
              destinationFolderId,
              destinationFolderName,
              itemType: 'project',
              isCopy,
            },
      );

      if (newFolders) {
        syncFolderListState(newFolders);
      }

      if (newProjects) {
        syncProjectListState(newProjects);
      }

      // eslint-disable-next-line deprecation/deprecation
      logEvent(isCopy ? 'succeed_project_copy' : 'succeed_project_move', {
        click_source: moveSource,
        media_count: 1,
      });
    },
    [
      currentFolderId,
      folders,
      hideBrowseSnackbar,
      logEvent,
      projects,
      setBrowseSnackbar,
      setFolders,
      setProjects,
      syncFolderListState,
      syncProjectListState,
      queryClient,
    ],
  );

  const onVersionStatusChange = React.useCallback(
    (projectId: string, videoVersionId: string, newStatus: number) => {
      if (!videoVersionId) {
        return;
      }

      setProjects((previous) => {
        if (!previous) {
          return previous;
        }
        return previous.map((project) => {
          if (project.isUploading) {
            return project;
          } else {
            const videos = project.videos
              ? project.videos.map((video) =>
                  video.id === videoVersionId ? {...video, status: newStatus} : video,
                )
              : project.videos;
            return {...project, videos};
          }
        });
      });

      updateMediaProjectDisplayInfoVersionStatus(queryClient, projectId, newStatus);
    },
    [setProjects, queryClient],
  );

  const onVersionUpload = React.useCallback(
    (
      projectId: string,
      videoVersion: reel.Video,
      snackbarProps: VersionUploadConfirmationProps,
      _: boolean,
      uploadId?: string,
    ) => {
      if (!videoVersion) {
        return;
      }

      // if cancelled, don't do the rest of the stuff
      if (uploadId) {
        const isCancelled =
          uploadsProgressRef.current[uploadId].status === FileUploadState.Cancelled;
        if (isCancelled) {
          return;
        }
      }

      const newProjects = projects
        ? projects.map((project) => {
            if (project.isUploading) {
              return project;
            } else {
              const videos =
                project.videos && project.project?.id === projectId
                  ? Array.from(project.videos).concat({...videoVersion})
                  : project.videos;
              return {...project, videos};
            }
          })
        : projects;

      setProjects(newProjects);
      updateMediaProjectDisplayInfoCurrentVersion(queryClient, projectId, videoVersion);
      setBrowseSnackbar({
        ...snackbarProps,
        status: BrowseSnackbarStatus.VERSION_UPLOAD_CONFIRMATION,
        onRequestClose: hideBrowseSnackbar,
      });
    },
    [hideBrowseSnackbar, projects, setBrowseSnackbar, setProjects, queryClient],
  );

  /* ---- Folder actions ---- */
  const onFoldersDelete = React.useCallback(
    async (folderIds: string[]) => {
      const newFolders = folders!.filter(
        (folder) => !isUploadedFolder(folder) || !folderIds.includes(folder.id),
      );
      setFolders(newFolders);
      syncFolderListState(newFolders);
      deleteFolderFromListFolderBasicInfo(queryClient, currentFolderId, folderIds);

      if (editActionsDeps.editActionsEnabled) {
        await editActionsDeps.updateFolderTree();
      }
      // eslint-disable-next-line deprecation/deprecation
      logEvent('delete_reel_folder', {folder_count: folderIds.length});
    },
    [
      currentFolderId,
      editActionsDeps,
      folders,
      logEvent,
      setFolders,
      syncFolderListState,
      queryClient,
    ],
  );

  const onFolderRename = React.useCallback(
    (folderId: string, newName: string) => {
      if (!folders) {
        return;
      }

      const newFolders = innerFolderRename(folders, folderId, newName);

      setFolders(newFolders);
      syncFolderListState(newFolders);
      updateFolderDisplayInfoName(queryClient, folderId, newName);

      if (editActionsDeps.editActionsEnabled) {
        const {folderTree, setFolderTree} = editActionsDeps;

        const newTree = folderTree ? [...folderTree] : [];
        const renameFolderInTree = (
          tree: reel.FolderTreeNode[],
          folderId: string,
          newFolderName: string,
        ): void => {
          tree.forEach((node) => {
            if (node.folder && node.folder.id === folderId) {
              node.folder.name = newFolderName;
              return;
            }
            if (node.children && node.children.length > 0) {
              renameFolderInTree(node.children, folderId, newFolderName);
            }
          });
        };
        renameFolderInTree(newTree, folderId, newName);
        setFolderTree(newTree);
      }
      // eslint-disable-next-line deprecation/deprecation
      logEvent('rename_reel_folder');
    },
    [editActionsDeps, folders, logEvent, setFolders, syncFolderListState, queryClient],
  );

  const onFolderMove = React.useCallback(
    async (
      folderId: string,
      folderName: string,
      destinationFolderId: string,
      destinationFolderName: string,
      moveSource: MoveSourceType,
      showErrorModal: boolean,
      isCopy: boolean,
    ) => {
      if (!showErrorModal) {
        await refreshFolders();

        setBrowseSnackbar({
          status: BrowseSnackbarStatus.MOVE_CONFIRMATION,
          onRequestClose: hideBrowseSnackbar,
          projectOrFolderName: folderName,
          destinationFolderId,
          destinationFolderName,
          itemType: 'folder',
          isCopy,
        });

        if (editActionsDeps.editActionsEnabled) {
          await editActionsDeps.updateFolderTree();
        }
        // eslint-disable-next-line deprecation/deprecation
        logEvent(isCopy ? 'succeed_folder_copy' : 'succeed_folder_move', {
          click_source: moveSource,
          folder_count: 1,
        });
      } else {
        setShowErrorModal(true);
        setFolderDestinationName(destinationFolderName);
      }
    },
    [
      editActionsDeps,
      hideBrowseSnackbar,
      logEvent,
      refreshFolders,
      setBrowseSnackbar,
      setFolderDestinationName,
      setShowErrorModal,
    ],
  );

  const onBulkMove = React.useCallback(
    async (
      folderIds: string[],
      projectIds: string[],
      destinationFolderId: string,
      destinationFolderName: string,
      moveSource: MoveSourceType,
      showErrorModal: boolean,
      isCopy: boolean,
    ) => {
      if (!showErrorModal) {
        updateFolderDisplayInfoNumProjects(queryClient, destinationFolderId, projectIds.length);
        await refreshFolders();

        setBrowseSnackbar({
          status: BrowseSnackbarStatus.MOVE_CONFIRMATION,
          onRequestClose: hideBrowseSnackbar,
          folderIds: folderIds,
          projectIds: projectIds,
          destinationFolderId,
          destinationFolderName,
          itemType: 'bulk',
          isCopy,
        });

        if (editActionsDeps.editActionsEnabled) {
          await editActionsDeps.updateFolderTree();
        }
        if (folderIds.length > 0) {
          // eslint-disable-next-line deprecation/deprecation
          logEvent(isCopy ? 'succeed_folder_copy' : 'succeed_folder_move', {
            click_source: moveSource,
            folder_count: folderIds.length,
          });
        }
        if (projectIds.length > 0) {
          // eslint-disable-next-line deprecation/deprecation
          logEvent(isCopy ? 'succeed_project_copy' : 'succeed_project_move', {
            click_source: moveSource,
            media_count: projectIds.length,
          });
        }
      } else {
        setShowErrorModal(true);
        setFolderDestinationName(destinationFolderName);
      }

      clearBulkAction();
    },
    [
      clearBulkAction,
      editActionsDeps,
      hideBrowseSnackbar,
      logEvent,
      refreshFolders,
      setBrowseSnackbar,
      setFolderDestinationName,
      setShowErrorModal,
      queryClient,
    ],
  );

  const dropboxChooserContext = useDropboxChooser();

  const onSuggestedItemClick: SuggestedItemClickHandler = React.useCallback(
    async (fileId, name, loggingAttrs) => {
      if (!editActionsDeps.editActionsEnabled) {
        return;
      }

      const fileInfo = {id: fileId, name, relativePath: '', fileSize: 0};

      editActionsDeps.onFilePick([fileInfo]);

      const {mediaType, positionInList} = loggingAttrs;
      const snakeCaseAttrs = {
        media_type: mapMediaTypeToLoggingType(mediaType),
        position_in_suggestions: positionInList,
      };

      // eslint-disable-next-line deprecation/deprecation
      logEvent('select_import_suggested_item', snakeCaseAttrs);

      await dropboxChooserContext.createProjectsFromDropbox(
        {
          clickSource: 'browse_suggested_item_tile',
          currentFolderDepth,
          currentFolderId,
          onUploadsFailure() {
            // eslint-disable-next-line deprecation/deprecation
            logEvent('fail_import_suggested_item', snakeCaseAttrs);
          },
          onUploadsSuccess() {
            // eslint-disable-next-line deprecation/deprecation
            logEvent('succeed_import_suggested_item', snakeCaseAttrs);
          },
        },
        [fileInfo],
        'file',
      );

      // All arguments except the second one are only used if we're choosing
      // multiple files at once
      editActionsDeps.handleAllUploadsComplete(
        [],
        currentFolderId,
        'browse_suggested_item_tile',
        'dropbox',
        'file',
      );
    },
    [currentFolderId, currentFolderDepth, dropboxChooserContext, editActionsDeps, logEvent],
  );

  const dispatchers = React.useMemo(
    () => ({
      onBulkMove,
      onFolderMove,
      onFolderRename,
      onFoldersDelete,
      onProjectMove,
      onProjectRename,
      onProjectsDelete,
      onSuggestedItemClick,
      onVersionStatusChange,
      onVersionUpload,
    }),
    [
      onBulkMove,
      onFolderMove,
      onFolderRename,
      onFoldersDelete,
      onProjectMove,
      onProjectRename,
      onProjectsDelete,
      onSuggestedItemClick,
      onVersionStatusChange,
      onVersionUpload,
    ],
  );

  return dispatchers;
};

type BrowsePageItemsViewProps = {
  breadcrumbPath: reel.Folder[] | null;
  currentFolderId: string;
  currentFolderPermission: ShareFolderAccessType;
  currentProjectAmplitudeId?: string;
  isRootLevelView: boolean;
  logEvent: LoggingClient['logEvent'];
  makeFolderLink: BrowseItemsProps['makeFolderLink'];
  makeFileLink: BrowseItemsProps['makeFileLink'];
  onSyncFolderListState?: (folders: reel.Folder[]) => void;
  onSyncProjectListState?: (projectVideos: reel.ProjectWithVideos[]) => void;
  editActionsDeps: EditActionsDeps;
  refreshFolders: (folderId?: string) => void;
  routeName: NavigationRouteTypes;
  shareRecipients?: FolderToAccounts;
  shareToken?: string;
  grantBook?: string;
  onboardingActions?: reel.OnboardingActions;
  updateOnboardingActions?: (actions: reel.OnboardingActions) => Promise<void>;
};

const StickyDiv = styled.div`
  position: sticky;
  top: 0;
  z-index: 3;
`;

export const BrowsePageItemsView = (props: BrowsePageItemsViewProps) => {
  const {
    breadcrumbPath,
    currentFolderId,
    currentFolderPermission,
    currentProjectAmplitudeId,
    isRootLevelView,
    logEvent,
    makeFileLink,
    makeFolderLink,
    onSyncFolderListState,
    onSyncProjectListState,
    editActionsDeps,
    refreshFolders,
    routeName,
    shareRecipients,
    shareToken,
    grantBook,
  } = props;

  const sessionContext = useReelAppGlobalState();
  const stormcrows = useStormcrows();
  const {isMobileDevice} = useViewport();
  const navigate = useNavigate();
  const {chooseArchiveFolder} = useDropboxChooser();
  const isCurrentPageSharedBrowsePage = useIsCurrentPageSharedBrowsePage();
  const isCurrentPageAdminPage = useIsCurrentPagePartOfAdminConsole();

  const [projects, setProjects] = useAtom(projectsAtom);
  const hasMorePages = useAtomValue(hasMorePagesAtom);
  const hasFetchError = useAtomValue(hasFetchErrorAtom);
  const folders = useAtomValue(foldersAtom);
  const deselectAllItems = useSetAtom(deselectAllItemsAtom);
  const selectedFolderIds = useAtomValue(selectedFolderIdsAtom);
  const selectedProjectIds = useAtomValue(selectedProjectIdsAtom);
  const versionIds = useAtomValue(selectedVersionIdsAtom);
  const videoId = useAtomValue(selectedVideoIdAtom);
  const allVersionIds = useAtomValue(selectedAllVideoVersionsIdsAtom);
  const viewType = useAtomValue(browseViewTypeAtom);

  const clearBulkAction = useSetAtom(clearBulkActionAtom);
  const initBulkAction = useSetAtom(initBulkActionAtom);
  const requestBulkAction = useSetAtom(requestBulkActionAtom);
  const syncFolderListState = (browseFolders: BrowseFolder[]) => {
    onSyncFolderListState?.(browseFolders.filter(isUploadedFolder));
  };

  const syncProjectListState = (browseProjects: BrowseProject[]) => {
    onSyncProjectListState?.(browseProjects.filter(isUploadedProject));
  };

  // Keep track of whether each item in the view, representing a project, has
  // trigged the creation of a new version and therefore should show a loading
  // state. The children cannot manage this state themselves because if the
  // user switches the view type (e.g. from list to grid), each item is
  // rendered with a different component.
  const [itemLoadingStates, setItemLoadingStates] = React.useState<ItemLoadingStates>({});
  const [showErrorModal, setShowErrorModal] = React.useState<boolean>(false);
  const [folderDestinationName, setFolderDestinationName] = React.useState<string>('');
  const setModifyItemsSnackbar = useSetAtom(modifyItemsSnackbarAtom);
  const setBrowseSnackbar = useSetAtom(browseSnackbarAtom);
  const hideBrowseSnackbar = useSetAtom(hideBrowseSnackbarAtom);

  const folderLevel = breadcrumbPath ? breadcrumbPath.length : 1;

  const shouldShowVersionComparison = allVersionIds.length > 1;

  const clearTask = useSetAtom(clearTaskStateAtom);
  const currentVersionInfo = useAtomValue(dueDateModalCurrentVersionInfoAtom);
  const [showDueDateVersionModal, setShowDueDateVersionModal] = useAtom(dueDateVersionModalAtom);
  const [
    {showDueDateVersionModalErrorText, dueDateDeleteRequestInProgress},
    setRemoveDueDateState,
  ] = useAtom(removeDueDateStateAtom);

  const refreshCurrentFolder = React.useCallback(() => {
    refreshFolders(currentFolderId);
  }, [currentFolderId, refreshFolders]);

  const onProjectTransfer = React.useCallback(
    async (userAccountId: string) => {
      // If we're part of the admin page, we know we're transfering from only
      // the user root folder, so we can refresh that data
      if (isCurrentPageAdminPage) {
        const response = await listUserRootMediaProjects(userAccountId);
        const newProjects = response.projects_with_videos;
        if (newProjects) {
          setProjects(newProjects);
        }
      } else {
        // otherwise, we're transfering from a browse view so we should refresh our current
        // folders
        await refreshFolders();
      }
    },
    [refreshFolders, isCurrentPageAdminPage, setProjects],
  );

  const handleBulkArchive = React.useCallback(async () => {
    const snackbarSuccessHandler = () => {
      setBrowseSnackbar({
        status: BrowseSnackbarStatus.ARCHIVE,
        archiveStatus: 'start',
        onRequestClose: hideBrowseSnackbar,
      });
    };

    const snackbarFailureHandler = () => {
      setBrowseSnackbar({
        status: BrowseSnackbarStatus.ARCHIVE,
        archiveStatus: 'submit_failed',
        onRequestClose: hideBrowseSnackbar,
      });
    };
    chooseArchiveFolder({
      latestVideoVersionIds: versionIds,
      allVideoVersionIds: allVersionIds,
      snackbarSuccessHandler,
      snackbarFailureHandler,
    });
  }, [chooseArchiveFolder, allVersionIds, versionIds, setBrowseSnackbar, hideBrowseSnackbar]);

  const {handlePayload} = useFlowsNotifications(setBrowseSnackbar, hideBrowseSnackbar);

  const updateMoveInProgressSnackbar = React.useCallback(
    (moveProps: ProjectMoveConfirmationProps, isError: boolean) => {
      const browseSnackbarProps = {
        destinationFolderId: moveProps.destinationFolderId,
        destinationFolderName: moveProps.destinationFolderName,
        isCopy: moveProps.isCopy,
        onRequestClose: hideBrowseSnackbar,
        status: isError
          ? (BrowseSnackbarStatus.MOVE_ERROR as const)
          : (BrowseSnackbarStatus.MOVE_IN_PROGRESS as const),
      };

      if (moveProps.itemType === 'bulk') {
        setBrowseSnackbar({
          ...browseSnackbarProps,
          itemType: 'bulk',
          folderIds: moveProps.folderIds,
          projectIds: moveProps.projectIds,
        });
      } else {
        setBrowseSnackbar({
          ...browseSnackbarProps,
          itemType: moveProps.itemType,
          projectOrFolderName: moveProps.projectOrFolderName,
        });
      }
    },
    [hideBrowseSnackbar, setBrowseSnackbar],
  );

  const dispatchers = useBrowseDispatchers({
    currentFolderDepth: folderLevel,
    currentFolderId,
    editActionsDeps,
    logEvent,
    refreshFolders,
    setFolderDestinationName,
    setShowErrorModal,
    syncFolderListState,
    syncProjectListState,
  });

  const {onFoldersDelete, onProjectsDelete, onVersionStatusChange, ...editActionsDispatchers} =
    dispatchers;

  const editActionsContextProps = editActionsDeps.editActionsEnabled
    ? {
        ...editActionsDeps,
        ...editActionsDispatchers,
        onVersionStatusChange,
        onProjectTransfer,
        editActionsEnabled: true as const,
      }
    : {
        editActionsEnabled: false as const,
        onVersionStatusChange,
      };

  const itemsViewContext: BrowseItemsViewContextValue = {
    breadcrumbPath,
    currentFolderId,
    currentFolderPermission,
    editActionsProps: editActionsContextProps,
    onFoldersDelete,
    onProjectsDelete,
  };

  const {editActionsProps} = itemsViewContext;

  const isSharedView = routeName === 'browse-shared';
  const isAdminPageView = routeName === 'admin-page';

  const folderAndMediaCounts = {
    folder_count: selectedFolderIds.length,
    media_count: selectedProjectIds.length,
  };

  const handleBulkUpdateStatusButtonClick = async (newStatus: VersionStatus) => {
    if (!projects) {
      return;
    }

    requestBulkAction(BulkAction.UPDATE_VERSION_STATUS);

    // eslint-disable-next-line deprecation/deprecation
    logEvent('select_bulk_version_status', {
      new_version_status: newStatus.toLowerCase() as NewVersionStatusType,
      media_count: versionIds.length,
    });

    try {
      const {versionIdToStatus} = await updateVersionStatuses({
        versionIds,
        newStatus,
        grantBook,
        shareToken,
      });
      const newStatusId = VERSION_STATUS_PROPERTIES[newStatus].id;
      const newProjects = projects.map((project) => {
        if (project.isUploading) {
          return project;
        }

        const videos = project.videos
          ? project.videos.map((video) => {
              const versionId = video.id;
              const statusUpdated = versionIdToStatus[versionId] === GenericStatus.SUCCESS;

              return statusUpdated ? {...video, status: newStatusId} : video;
            })
          : project.videos;

        return {...project, videos};
      });

      setProjects(newProjects);
      setModifyItemsSnackbar({
        type: 'update-version-status-bulk',
        status: 'success',
        itemType: ItemType.BULK,
        itemCount: versionIds.length,
        itemStatus: newStatus,
      });
    } catch {
      setModifyItemsSnackbar({
        type: 'update-version-status-bulk',
        status: 'error',
        itemType: ItemType.BULK,
        itemCount: versionIds.length,
        itemStatus: newStatus,
      });
    }
    deselectAllItems();
    clearBulkAction();
  };

  const handleBulkLeaveTeamProjectButtonClick = () => {
    initBulkAction(BulkAction.LEAVE_TEAM_PROJECT);
    // eslint-disable-next-line deprecation/deprecation
    logEvent('select_bulk_leave', {
      folder_count: selectedFolderIds.length,
    });
  };

  const handleBulkDeleteButtonClick = () => {
    initBulkAction(BulkAction.DELETE);
    // eslint-disable-next-line deprecation/deprecation
    logEvent('select_bulk_delete', folderAndMediaCounts);
  };

  const handleBulkHideButtonClick = () => {
    initBulkAction(BulkAction.HIDE);
    // eslint-disable-next-line deprecation/deprecation
    logEvent('select_bulk_hide', folderAndMediaCounts);
  };

  const handleBulkDelete = (deletedFolderIds: string[], deletedProjectIds: string[]) => {
    onFoldersDelete(deletedFolderIds);
    onProjectsDelete(deletedProjectIds);
  };

  const handleBulkMoveOrCopyButtonClick = (updateType: 'move' | 'copy') => {
    if (!editActionsProps.editActionsEnabled) {
      return;
    }

    // Setting the action to REQUESTING disables all the checkboxes so they are not adjusted while left rail is open
    requestBulkAction(
      updateType === 'move' ? BulkAction.MOVE_TO_FOLDER : BulkAction.COPY_TO_PROJECT,
    );
    // eslint-disable-next-line deprecation/deprecation
    logEvent(updateType === 'move' ? 'select_bulk_move' : 'select_bulk_copy', folderAndMediaCounts);
    editActionsProps.setLeftRailStatus({
      status: updateType,
      items: {
        itemType: 'BulkTiles',
        folderIds: selectedFolderIds,
        projectIds: selectedProjectIds,
      },
    });
  };

  const handleCompareVersionsButtonClick = () => {
    // Can only select one project to show versions for,
    // so we know that this is only going to have one item.
    if (videoId) {
      const compareLink = generateVersionComparePageUrl({
        projectId: selectedProjectIds[0],
        videoId,
        nextLatestVideoVersionId: allVersionIds[1],
        latestVideoVersionId: allVersionIds[0],
      });
      navigate(compareLink);
    }
  };

  const showBaseUploadPrompts =
    !isCurrentPageSharedBrowsePage &&
    projects !== null &&
    folders !== null &&
    folderLevel === 1 &&
    editActionsDeps.editActionsEnabled &&
    !isMobileDevice &&
    !isAdminPageView;

  // get empty dropzone to show
  const showUploadPrompts = showBaseUploadPrompts && projects.length === 0 && folders.length === 0;

  // show create project tile
  const showCreateProjectPrompt =
    showBaseUploadPrompts && folders.length === 0 && viewType === 'tile';

  const shouldShowSuggestedItems =
    sessionContext.status === 'logged out' ||
    sessionContext.preferenceSettingMetadata.REPLAY_SETTINGS_SHOW_SUGGESTED_FILES !== 'OFF';

  const fetchItemsDependencies = useGatedDependencies(
    {
      editActionsDeps,
      showUploadPrompts,
      stormcrows,
    },
    'showUploadPrompts',
  );

  const goToNewVersion = React.useCallback(() => {
    const newUrl = `/project/${currentVersionInfo.projectId}/video/${currentVersionInfo.videoId}`;
    navigate(newUrl);
  }, [currentVersionInfo.projectId, currentVersionInfo.videoId, navigate]);

  const handleRemoveDueDate = async () => {
    setRemoveDueDateState((prevState) => ({
      ...prevState,
      showDueDateVersionModalErrorText: false,
      dueDateDeleteRequestInProgress: true,
    }));

    const updated_task = await getTasksForItem(currentVersionInfo.videoId);
    const async_task_state = {
      isLoading: false,
      videoId: videoId,
      task: updated_task,
    };
    try {
      if (!async_task_state.isLoading && async_task_state.task?.taskId) {
        await deleteTask(async_task_state.task.taskId);
        clearTask(currentVersionInfo.videoId);
        setShowDueDateVersionModal(false);
      } else {
        throw new Error('Task ID is missing.');
      }
    } catch (e) {
      setRemoveDueDateState((prevState) => ({
        ...prevState,
        showDueDateVersionModalErrorText: true,
      }));
    }

    setRemoveDueDateState((prevState) => ({
      ...prevState,
      dueDateDeleteRequestInProgresst: false,
    }));
  };

  const handleCloseDueDateVersionModal = () => {
    setShowDueDateVersionModal(false);
    goToNewVersion();
  };

  React.useEffect(() => {
    const {editActionsDeps, showUploadPrompts} = fetchItemsDependencies;

    if (
      editActionsDeps.editActionsEnabled &&
      replayStorage.get(HAS_DISMISSED_SUGGESTED_ITEMS) !== true &&
      showUploadPrompts
    ) {
      editActionsDeps.updateSuggestedItems();
    }
  }, [fetchItemsDependencies]);

  React.useEffect(() => {
    if (sessionContext.status === 'logged in') {
      FlowsBoltClient.subscribe((updates) => {
        updates.forEach((update) => {
          if (update.action_type === 'archive_from_replay' && isCompleteFlowsBoltPayload(update)) {
            handlePayload(update);
          }
        });
      });
    }
  }, [handlePayload, sessionContext.status]);

  return (
    <BrowseItemsViewContext.Provider value={itemsViewContext}>
      <StickyDiv>
        <Box backgroundColor="Background Base">
          {editActionsDeps.editActionsEnabled && !isAdminPageView && (
            <BulkActionsRow
              isSharedView={isSharedView}
              logEvent={logEvent}
              onArchiveToDropboxButtonClick={handleBulkArchive}
              onCompareVersionsButtonClick={handleCompareVersionsButtonClick}
              onCopyToProjectButtonClick={() => handleBulkMoveOrCopyButtonClick('copy')}
              onDeleteButtonClick={handleBulkDeleteButtonClick}
              onHideButtonClick={handleBulkHideButtonClick}
              onLeaveTeamProjectButtonClick={handleBulkLeaveTeamProjectButtonClick}
              onMoveToFolderButtonClick={() => handleBulkMoveOrCopyButtonClick('move')}
              onMoveToProjectButtonClick={() => handleBulkMoveOrCopyButtonClick('move')}
              onUpdateStatusButtonClick={handleBulkUpdateStatusButtonClick}
              shouldShowVersionComparison={shouldShowVersionComparison}
            />
          )}
        </Box>
      </StickyDiv>

      {/* for admin page, let's always render a list*/}
      {isAdminPageView ? (
        <ProjectListView
          currentProjectAmplitudeId={currentProjectAmplitudeId}
          grantBook={grantBook}
          hasFetchError={false}
          hasMorePages={false}
          isAdminPageView={isAdminPageView}
          isRootLevelView={isRootLevelView}
          isSharedVideoView={isSharedView}
          itemLoadingStates={itemLoadingStates}
          makeFileLink={makeFileLink}
          makeFolderLink={makeFolderLink}
          parentFolderId={currentFolderId}
          parentFolderPermission={currentFolderPermission}
          setItemLoadingStates={setItemLoadingStates}
          shareRecipients={shareRecipients}
          shareToken={shareToken}
          updateModifyItemSnackbar={setModifyItemsSnackbar}
          updateMoveInProgressSnackbar={updateMoveInProgressSnackbar}
        />
      ) : projects === null ? (
        viewType === 'tile' ? (
          <LoadingGridPlaceholder />
        ) : (
          <LoadingProjectListView />
        )
      ) : (
        <>
          {(projects?.length || folders?.length) && viewType === 'tile' ? (
            <ProjectGridView
              currentFolderId={currentFolderId}
              currentProjectAmplitudeId={currentProjectAmplitudeId}
              editActionsDeps={editActionsDeps}
              folderLevel={folderLevel}
              grantBook={grantBook}
              hasFetchError={Boolean(hasFetchError)}
              hasMorePages={Boolean(hasMorePages)}
              isAdminPageView={isAdminPageView}
              isRootLevelView={isRootLevelView}
              isSharedVideoView={isSharedView}
              itemLoadingStates={itemLoadingStates}
              makeFileLink={makeFileLink}
              makeFolderLink={makeFolderLink}
              onCreateFolderSuccessFn={() => refreshCurrentFolder()}
              parentFolderId={currentFolderId}
              parentFolderPermission={currentFolderPermission}
              setItemLoadingStates={setItemLoadingStates}
              settingsChangeCallback={refreshCurrentFolder}
              shareRecipients={shareRecipients}
              shareToken={shareToken}
              showCreateProjectPrompt={showCreateProjectPrompt}
              showUploadPrompts={showUploadPrompts}
              updateModifyItemSnackbar={setModifyItemsSnackbar}
              updateMoveInProgressSnackbar={updateMoveInProgressSnackbar}
            />
          ) : (projects?.length || folders?.length) && viewType === 'list' ? (
            <ProjectListView
              currentProjectAmplitudeId={currentProjectAmplitudeId}
              grantBook={grantBook}
              hasFetchError={Boolean(hasFetchError)}
              hasMorePages={Boolean(hasMorePages)}
              isAdminPageView={isAdminPageView}
              isRootLevelView={isRootLevelView}
              isSharedVideoView={isSharedView}
              itemLoadingStates={itemLoadingStates}
              makeFileLink={makeFileLink}
              makeFolderLink={makeFolderLink}
              parentFolderId={currentFolderId}
              parentFolderPermission={currentFolderPermission}
              setItemLoadingStates={setItemLoadingStates}
              shareRecipients={shareRecipients}
              shareToken={shareToken}
              updateModifyItemSnackbar={setModifyItemsSnackbar}
              updateMoveInProgressSnackbar={updateMoveInProgressSnackbar}
            />
          ) : (
            <EmptyProjectList
              currentFolderId={currentFolderId}
              currentPermission={currentFolderPermission}
              editActionsDeps={editActionsDeps}
              level={folderLevel}
              routeName={routeName}
            />
          )}
          <DueDateVersionModal
            currentVersion={
              currentVersionInfo.versionNumber ? currentVersionInfo.versionNumber + 1 : 1
            }
            handleCloseModal={handleCloseDueDateVersionModal}
            handleRemoveDueDate={handleRemoveDueDate}
            isOpen={showDueDateVersionModal}
            projectName={currentVersionInfo.projectName}
            requestInProgress={dueDateDeleteRequestInProgress}
            showErrorText={showDueDateVersionModalErrorText}
          />
        </>
      )}

      {showUploadPrompts && shouldShowSuggestedItems && (
        <SuggestedItems
          existingProjects={projects}
          logEvent={logEvent}
          onSuggestedItemClick={dispatchers.onSuggestedItemClick}
          suggestedItemsResponse={editActionsDeps.suggestedItems}
        />
      )}

      {breadcrumbPath &&
        breadcrumbPath.length > 0 &&
        editActionsProps.editActionsEnabled &&
        editActionsProps.leftRailStatus.status !== 'closed' &&
        !isAdminPageView && (
          <FoldersLeftRail
            onBulkMove={editActionsProps.onBulkMove}
            onFolderMove={editActionsProps.onFolderMove}
            onProjectMove={editActionsProps.onProjectMove}
            rootFolder={breadcrumbPath[0]}
            updateMoveInProgressSnackbar={updateMoveInProgressSnackbar}
          />
        )}
      <ModifyItemSnackbar />
      <MoveFailModal
        destinationName={folderDestinationName}
        open={showErrorModal}
        requestClose={() => {
          setShowErrorModal(false);
          setFolderDestinationName('');
        }}
      />
      <BulkDeleteModal
        onBulkDelete={handleBulkDelete}
        requestClose={clearBulkAction}
        updateModifyItemSnackbar={setModifyItemsSnackbar}
      />
      <BrowsePageSnackbar />
    </BrowseItemsViewContext.Provider>
  );
};
