import React from 'react';

import {PAP_Select_WorkspaceAction} from 'pap-events/replay/select_workspace_action';
import {FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';

import {Snackbar} from '@dropbox/dig-components/snackbar';
import {Tooltip} from '@dropbox/dig-components/tooltips';
import {UIIcon} from '@dropbox/dig-icons';
import {CloseLine, LinkLine} from '@dropbox/dig-icons/assets';

import {
  Button,
  type ButtonProps,
  SplitButton,
  type SplitButtonMenuRenderFnOptions,
} from '~/components/button';
import {ReelSnackbar} from '~/components/snackbar';
import {useErrorSnackbar} from '~/components/snackbar/error_snackbar';
import {color, fontSize, radius, spacing} from '~/components/styled';
import {useLoggingClient} from '~/lib/use_logging_client';

const RoundedButton = styled(Button)`
  font-size: ${fontSize('Text Small')};
  border-radius: ${radius('Circular')};
`;

const RoundedSplitButton = styled(SplitButton)`
  & .dig-SplitButton-button {
    font-size: ${fontSize('Text Small')};
    border-radius: ${radius('Circular')} 0 0 ${radius('Circular')};

    .dig-Button-content {
      padding: ${spacing('Micro Small')};
    }
  }

  & .dig-SplitButton-menu-button {
    border-radius: 0 ${radius('Circular')} ${radius('Circular')} 0;

    .dig-Button-content {
      color: ${color('Text Subtle')};
      padding: 0 ${spacing('Micro XSmall')} 0 0;
    }
  }
`;

export interface DeleteFolderLinksButtonProps {
  deleteLink: () => Promise<void>;
  name: string;
  url: string;
  isViewOnly: boolean;
}

export const DeleteFolderLinksButton = ({
  deleteLink,
  isViewOnly,
  name,
  url,
}: DeleteFolderLinksButtonProps) => {
  const [snackbarText, setSnackbarText] = React.useState<string>('');
  const intl = useIntl();
  const {openErrorSnackbar} = useErrorSnackbar();
  const loggingClient = useLoggingClient();

  const deleteLinkSnackbarText = intl.formatMessage({
    defaultMessage: 'Link deleted',
    description: 'Snackbar text when a link is successfully deleted',
    id: '2PwkHb',
  });

  const onClose = () => {
    setSnackbarText('');
  };

  React.useEffect(() => {
    if (snackbarText) {
      openErrorSnackbar(snackbarText);
    }
  }, [openErrorSnackbar, snackbarText]);

  const handleDeleteLink = async () => {
    loggingClient.logPap(
      PAP_Select_WorkspaceAction({
        workspaceAction: 'remove_link',
        actionElement: 'remove_link_header_button',
      }),
    );
    try {
      await deleteLink();
      setSnackbarText(deleteLinkSnackbarText);
    } catch (error) {
      const deleteLinkErrorText = intl.formatMessage({
        defaultMessage: 'Unable to delete link',
        id: 'fBE+3m',
        description: 'Snackbar text that appears for errors arising from link deletions.',
      });

      setSnackbarText(deleteLinkErrorText);
    }
  };

  const renderDeleteButton = ({buttonProps}: SplitButtonMenuRenderFnOptions) => {
    const deleteLinkLabel = intl.formatMessage({
      defaultMessage: 'Delete Link',
      id: 'r1g9KV',
      description: 'tooltip for button that deletes a project link',
    });

    return (
      <Tooltip title={deleteLinkLabel}>
        <Button
          {...buttonProps}
          aria-label={deleteLinkLabel}
          onClick={handleDeleteLink}
          variant="opacity"
          withDropdownIcon={undefined}
        >
          <UIIcon src={CloseLine} />
        </Button>
      </Tooltip>
    );
  };

  const buttonProps: ButtonProps = {
    href: url,
    rel: 'nofollow',
    size: 'small',
    target: '_blank',
    role: 'link',
    variant: 'opacity',
  };

  return isViewOnly ? (
    <RoundedButton {...buttonProps} variant="opacity">
      <UIIcon size="small" src={LinkLine} />
      {name}
    </RoundedButton>
  ) : (
    <>
      <RoundedSplitButton {...buttonProps} renderMenu={renderDeleteButton} variant="opacity">
        <UIIcon size="small" src={LinkLine} />
        {name}
      </RoundedSplitButton>
      <DeleteLinkSnackbar onClose={onClose} snackbarText={snackbarText} />
    </>
  );
};

type DeleteLinkSnackbarProps = {
  snackbarText: string;
  onClose: () => void;
};

export const DeleteLinkSnackbar = ({snackbarText, onClose}: DeleteLinkSnackbarProps) => {
  return (
    <ReelSnackbar onRequestClose={onClose} open={Boolean(snackbarText)} timeout={4000}>
      <Snackbar.Message>{snackbarText}</Snackbar.Message>
      <Snackbar.Actions>
        <Button onClick={onClose} variant="transparent">
          <FormattedMessage
            defaultMessage="OK"
            description="Text on delete folder link snackbar that dismisses the snackbar."
            id="nHEndA"
          />
        </Button>
      </Snackbar.Actions>
    </ReelSnackbar>
  );
};
