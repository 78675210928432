import React from 'react';

import {PAP_Shown_Modal} from 'pap-events/replay/shown_modal';
import {useIntl} from 'react-intl';
import styled, {css} from 'styled-components';

import {Text} from '@dropbox/dig-components/typography';
import {UIIcon} from '@dropbox/dig-icons';
import {AddLine, CustomizeLine} from '@dropbox/dig-icons/assets';

import {BasicAddOnUpsellModal} from '~/components/add_on_upsell_modal';
import {StylelessButton} from '~/components/button';
import {
  breakpointSmall,
  color,
  colorTransition,
  ifNotProp,
  ifProp,
  radius,
  spacing,
} from '~/components/styled';
import type {Branding, BrandingLogo} from '~/lib/branding';
import {useLoggingClient} from '~/lib/use_logging_client';
import {useHasFullAddonAccess} from '~/lib/utils';
import {BrandingLogoIcon} from '~/pages/browse_page/components/branding/branding_logo_icon';
import {
  BrandingLogoModal,
  type BrandingLogoModalProps,
} from '~/pages/browse_page/components/branding/branding_logo_modal';

interface ChangeLogoButtonProps {
  $backgroundUrl?: string;
  $isEmpty: boolean;
  $isViewOnly?: boolean;
  $showInSmallScreen?: boolean;
}

const ChangeLogoButton = styled(StylelessButton)<ChangeLogoButtonProps>`
  position: relative;
  border-radius: ${radius('Medium')};
  background-color: ${color('Opacity Surface')};

  height: ${spacing('100')};
  width: ${spacing('100')};

  ${ifNotProp(
    '$isViewOnly',
    css`
      cursor: pointer;
    `,
  )}
  ${ifProp(
    '$backgroundUrl',
    ({$backgroundUrl}) => css`
      background: url(${$backgroundUrl}) ${color('Opacity Surface')} center / cover no-repeat;
    `,
  )}

  ${ifProp(
    '$isEmpty',
    css`
      background-color: transparent;
      border: dashed 1px ${color('Text Base')};
    `,
  )}

  ${({$showInSmallScreen}) =>
    !$showInSmallScreen &&
    css`
      display: none;
    `}

  ${breakpointSmall(css`
    display: block;
  `)}

  .dig-StylelessButton-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    aspect-ratio: 1;
  }

  .branding-icon {
    border-radius: ${radius('Medium')};
    height: 100%;
    width: 100%;
  }
`;

interface ChangeLogoOverlayProps {
  $showText: boolean;
}

const ChangeLogoOverlay = styled.div<ChangeLogoOverlayProps>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: ${radius('Medium')};
  color: ${color('Text Base')};

  transition: ${colorTransition('opacity')};

  ${ifProp(
    '$showText',
    css`
      ${ChangeLogoButton}:hover & {
        background-color: rgba(0, 0, 0, 0.5);
      }
    `,
    css`
      opacity: 0;
      background-color: rgba(0, 0, 0, 0.5);

      ${ChangeLogoButton}:hover & {
        opacity: 1;
      }
    `,
  )}
`;

interface HeaderLogoProps {
  branding?: Branding;
  folderId: string;
  isViewOnly?: boolean;
  showInSmallScreen?: boolean;
  onLogoChange?: BrandingLogoModalProps['onLogoSave'];
}

export const HeaderLogo = ({
  branding,
  folderId,
  isViewOnly,
  showInSmallScreen,
  onLogoChange,
}: HeaderLogoProps) => {
  const intl = useIntl();
  const [isChangeLogoModalOpen, setIsChangeLogoModalOpen] = React.useState<boolean>(false);
  const hasFullAddonAccess = useHasFullAddonAccess();
  const loggingClient = useLoggingClient();

  const isLoading = !branding;
  const logoType = branding?.logo?.type;
  const logoVariant = (logoType == 'default' && branding?.logo?.variant) || 'none';
  const isEmpty = !isLoading && logoType === 'default' && logoVariant === 'none';
  // If no `onLogoChange` callback is provided, fallback to view-only
  isViewOnly = isViewOnly || !onLogoChange;

  const handleChangeLogoClick = () => {
    loggingClient.logPap(PAP_Shown_Modal({modal: 'logo'}));
    setIsChangeLogoModalOpen(true);
  };

  const handleLogoModalClose = () => {
    setIsChangeLogoModalOpen(false);
  };

  const handleLogoModalSave = async (logo: BrandingLogo) => {
    await onLogoChange?.(logo);
    setIsChangeLogoModalOpen(false);
  };

  if (isLoading || (isViewOnly && isEmpty)) {
    return null;
  }

  const buttonLabel = isEmpty
    ? intl.formatMessage({
        defaultMessage: 'Add logo',
        description: 'Text for button to add a logo',
        id: 'htUmnR',
      })
    : intl.formatMessage({
        defaultMessage: 'Change',
        description: 'Copy for option to change the theme',
        id: 'blEX2m',
      });

  return (
    <>
      <ChangeLogoButton
        $backgroundUrl={logoType === 'custom' ? branding?.logo?.url : ''}
        $isEmpty={isEmpty}
        $isViewOnly={isViewOnly}
        $showInSmallScreen={!!showInSmallScreen}
        aria-label={buttonLabel}
        data-testid="header-logo"
        onClick={isViewOnly ? undefined : handleChangeLogoClick}
      >
        {logoType === 'default' && !isEmpty && <BrandingLogoIcon variant={logoVariant} />}

        {!isViewOnly && !isLoading && (
          <ChangeLogoOverlay $showText={isEmpty}>
            <UIIcon src={isEmpty ? AddLine : CustomizeLine} />
            <Text isBold={!isEmpty} size="small">
              {buttonLabel}
            </Text>
          </ChangeLogoOverlay>
        )}
      </ChangeLogoButton>

      {branding && hasFullAddonAccess ? (
        <BrandingLogoModal
          folderId={folderId}
          logo={branding.logo}
          onClose={handleLogoModalClose}
          onLogoSave={handleLogoModalSave}
          open={isChangeLogoModalOpen}
        />
      ) : (
        <BasicAddOnUpsellModal
          onClose={handleLogoModalClose}
          open={isChangeLogoModalOpen}
          variant="custom_branding"
        />
      )}
    </>
  );
};
