import React, {useId} from 'react';

import {useAtomValue} from 'jotai/index';
import {PAP_Select_TopNavAction} from 'pap-events/replay/select_top_nav_action';
import {FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';

import {Avatar} from '@dropbox/dig-components/avatar';
import {Menu} from '@dropbox/dig-components/menu';
import {ProgressBar, type ProgressBarProps} from '@dropbox/dig-components/progress_indicators';
import {Tooltip} from '@dropbox/dig-components/tooltips';
import {Text} from '@dropbox/dig-components/typography';
import {Box, Split, Stack} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {InfoLine, OpenLine} from '@dropbox/dig-icons/assets';
import {GlobalHeader} from '@dropbox/dpc-ia/global_header';

import {type CurrentAccount, useReelAppGlobalState} from '~/context';
import {getUserInitials} from '~/lib/facepile_utils';
import {
  DROPBOX_MANAGE_ACCOUNT_PAGE,
  DROPBOX_MANAGE_PLANS_PAGE,
  DROPBOX_TRANSFER_MANAGE_PAGE,
} from '~/lib/provisions';
import {useLoggingClient} from '~/lib/use_logging_client';
import {formatBytes} from '~/lib/utils';
import {useBrowseLogEventMaker} from '~/pages/browse_page/browse_logging_context';
import {loadableUserQuotaAtom} from '~/state/user';

import type {Color} from '../styled';
import {color} from '../styled';

type StorageBarProps = ProgressBarProps & {
  $trackColor?: Color;
};

const StorageBar = styled(ProgressBar)<StorageBarProps>`
  .dig-ProgressBar-track {
    background: ${({$trackColor = 'Primary Base'}) => color($trackColor)};
  }
`;

export interface AccountMenuProps {
  user: CurrentAccount;
  onLogoutClick: () => void;
  onSwitchRoleClick?: () => void;
  onSettingsClick: () => void;
  onStorageClick: () => void;
}

export const AccountMenuItems = ({
  user,
  onSettingsClick,
  onLogoutClick,
  onSwitchRoleClick,
  onStorageClick,
}: AccountMenuProps) => {
  const intl = useIntl();
  const storageUsedId = useId();
  const loadableUserQuota = useAtomValue(loadableUserQuotaAtom);
  const userQuota = loadableUserQuota.state === 'hasData' ? loadableUserQuota.data : undefined;
  const {used, allocated, isNearQuota, percentUsed, isAtQuota} = userQuota || {};
  const totalQuotaString = allocated ? formatBytes(allocated, intl) : undefined;
  const currentUsedString = formatBytes(used ?? 0, intl);
  let trackColor: Color = 'Primary Base';

  const storageCopyTooltipRef = React.useRef(null);
  const [showStorageCopyTooltip, setShowStorageCopyTooltip] = React.useState<boolean>(false);

  const storageTooltip = intl.formatMessage({
    defaultMessage: "Files added to Replay from Dropbox don't use storage",
    description: 'Helpful tooltip to indicate that imported Dropbox files are not storage counting',
    id: 'jT9SZq',
  });
  if (isNearQuota) {
    trackColor = 'Warning Base';
  }

  if (isAtQuota) {
    trackColor = 'Alert Base';
  }

  return (
    <>
      <Menu.Segment>
        <Menu.Row
          withLeftAccessory={<Avatar alt="" src={user.profile_photo_url} />}
          withSubtitle={user.email}
        >
          {user.name.display_name}
        </Menu.Row>
      </Menu.Segment>
      <Menu.Segment>
        {userQuota && (
          <Menu.LinkItem href={DROPBOX_MANAGE_PLANS_PAGE} key="storage" onClick={onStorageClick}>
            <Stack>
              <Split alignY="center" gap="Micro XSmall">
                <Split.Item>
                  <FormattedMessage
                    defaultMessage="Dropbox storage"
                    description="Menu link that redirects to their storage page"
                    id="EAcQXL"
                  />
                </Split.Item>
                <Split.Item>
                  <Tooltip.Control
                    open={showStorageCopyTooltip}
                    placement="top"
                    triggerRef={storageCopyTooltipRef}
                    variant="basic"
                  >
                    {storageTooltip}
                  </Tooltip.Control>
                  <Box display="flex" ref={storageCopyTooltipRef}>
                    <UIIcon
                      aria-label={storageTooltip}
                      onMouseEnter={() => setShowStorageCopyTooltip(true)}
                      onMouseLeave={() => setShowStorageCopyTooltip(false)}
                      src={InfoLine}
                    />
                  </Box>
                </Split.Item>
              </Split>
              {allocated ? (
                <>
                  <Text color="faint" id={storageUsedId} tagName="p">
                    <FormattedMessage
                      defaultMessage="{currentUsed} of {totalQuota} used"
                      description="Menu link that redirects to their storage page"
                      id="L7nxvu"
                      values={{
                        currentUsed: (
                          <Text color="faint" isBold>
                            {currentUsedString}
                          </Text>
                        ),
                        totalQuota: (
                          <Text color="faint" isBold>
                            {totalQuotaString}
                          </Text>
                        ),
                      }}
                    />
                  </Text>
                  <StorageBar
                    $trackColor={trackColor}
                    aria-labelledby={storageUsedId}
                    isStatic
                    value={percentUsed}
                  />
                </>
              ) : (
                <Text color="faint" isBold tagName="div">
                  {currentUsedString}
                </Text>
              )}
            </Stack>
          </Menu.LinkItem>
        )}
      </Menu.Segment>
      <Menu.Segment>
        <Menu.LinkItem
          href={DROPBOX_MANAGE_ACCOUNT_PAGE}
          key="account"
          target="_blank"
          withRightAccessory={<UIIcon src={OpenLine} />}
        >
          <FormattedMessage
            defaultMessage="Manage account"
            description="Menu link that redirects to the dropbox account page"
            id="E7CC52"
          />
        </Menu.LinkItem>
        <>
          <Menu.LinkItem
            href={DROPBOX_TRANSFER_MANAGE_PAGE}
            key="account"
            target="_blank"
            withRightAccessory={<UIIcon src={OpenLine} />}
          >
            <FormattedMessage
              defaultMessage="Dropbox Transfer"
              description="Menu link that redirects to the dropbox transfer landing page"
              id="SsSaaR"
            />
          </Menu.LinkItem>
        </>
      </Menu.Segment>
      <Menu.Segment>
        {onSwitchRoleClick && (
          <Menu.ActionItem key="switchrole" onClick={onSwitchRoleClick} value="switchrole">
            <FormattedMessage
              defaultMessage="Switch accounts"
              description="Menu link that allows a user to sign in using their other paired Dropbox account"
              id="tgMv0Q"
            />
          </Menu.ActionItem>
        )}
        <Menu.ActionItem key="signout" onClick={onLogoutClick} value="signout">
          <FormattedMessage
            defaultMessage="Sign out"
            description="Menu link that signs a user out ending their current session"
            id="0r2DM0"
          />
        </Menu.ActionItem>
      </Menu.Segment>
    </>
  );
};

interface HeaderAccountMenuProps extends Omit<AccountMenuProps, 'userSpace'> {
  actionSurface: string;
}

export const HeaderAccountMenu = ({
  user,
  onLogoutClick,
  onSettingsClick,
  onStorageClick,
  actionSurface,
  onSwitchRoleClick,
}: HeaderAccountMenuProps) => {
  const {locale} = useReelAppGlobalState();
  const loggingClient = useLoggingClient();
  const makeLogEvent = useBrowseLogEventMaker();
  const logEvent = React.useMemo(() => makeLogEvent(null), [makeLogEvent]);
  const intl = useIntl();

  const accountMenuLabel = intl.formatMessage({
    defaultMessage: 'Account menu',
    id: 'ilzMNJ',
    description: 'Aria label for button that opens the account menu.',
  });

  return (
    <GlobalHeader.AccountMenu
      alt="" // aria-label handles this
      initials={getUserInitials(user.name.display_name, locale)}
      onClick={() => {
        // eslint-disable-next-line deprecation/deprecation
        logEvent('select_account_menu');
        loggingClient.logPap(
          PAP_Select_TopNavAction({
            deviceId: loggingClient.deviceId,
            actionElement: 'top_nav',
            actionSurface: actionSurface,
            topNavAction: 'dropdown_menu',
            actionDetail: 'account_menu',
          }),
        );
      }}
      src={user.profile_photo_url}
      triggerProps={{
        'aria-label': accountMenuLabel,
      }}
    >
      <AccountMenuItems
        onLogoutClick={onLogoutClick}
        onSettingsClick={onSettingsClick}
        onStorageClick={onStorageClick}
        onSwitchRoleClick={onSwitchRoleClick}
        user={user}
      />
    </GlobalHeader.AccountMenu>
  );
};
