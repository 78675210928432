import {FullStory, init, isInitialized} from '@fullstory/browser';

export const VIDEO_ORG_ID = 'o-1DBYWZ-na1';

const DEVBOX_HOSTNAME_REGEX = /^replay-local\.dropbox\.com$/;

export async function hashId(string: string): Promise<string> {
  const hash = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(string));
  return btoa(String.fromCharCode(...new Uint8Array(hash)));
}

export const initializeFullStory = async (orgId: string, userAccountId?: string) => {
  if (isInitialized()) {
    // If FullStory was previously initialized before being disabled for any reason, it must be restarted rather than
    // re-initialized
    FullStory('restart');
    return;
  }

  // Use the debug script when running in a non-production environment to avoid polluting sessions with dev data
  const hostname = window.location.hostname;

  const isDev = DEVBOX_HOSTNAME_REGEX.test(hostname) || hostname === 'localhost';

  // For whatever reason, fullstory adds an additional 'https://', so I'm just leaving it off our links
  const FULLSTORY_DEBUG_SCRIPT = (hostname + '/fs-debug.js').replace('https://', '');
  const FULLSTORY_SCRIPT = (hostname + '/fs.js').replace('https://', '');

  init({
    orgId: orgId,
    script: isDev ? FULLSTORY_DEBUG_SCRIPT : FULLSTORY_SCRIPT,
  });
  if (userAccountId) {
    // Legal has agreed to semi-anonymous tracking of users across sessions only by hashing the user's account_id
    const identifier = await hashId(userAccountId);

    FullStory('setIdentity', {uid: identifier});
  }
};

export const disableFullStory = () => {
  if (isInitialized()) {
    FullStory('shutdown');
  }

  return;
};
