import React from 'react';

import {useIntl} from 'react-intl';

import {Tooltip} from '@dropbox/dig-components/tooltips';
import {UIIcon} from '@dropbox/dig-icons';
import {DownloadLine} from '@dropbox/dig-icons/assets';

import type {IconButtonVariant} from '~/components/button';
import {Button, IconButton} from '~/components/button';
import {useViewport} from '~/components/viewport_context';

export type DownloadButtonProps = {
  displayType: 'compact' | 'expanded';
  buttonVariant?: IconButtonVariant;
  onClick: () => void;
};

export const DownloadButton: React.FC<DownloadButtonProps> = ({
  displayType,
  buttonVariant = 'outline',
  onClick,
}: DownloadButtonProps) => {
  const intl = useIntl();
  // 10/4/24: Introducing new usage of deprecated hook for consistency with other components pending a broader refactor
  // eslint-disable-next-line deprecation/deprecation
  const {isSmallScreenSize} = useViewport();

  const downloadMessage = intl.formatMessage({
    defaultMessage: 'Download',
    id: 'qFeyfb',
    description: 'Download button text',
  });

  return displayType === 'compact' || isSmallScreenSize ? (
    <Tooltip placement="bottom" title={downloadMessage}>
      <IconButton
        aria-label={downloadMessage}
        data-testid="download-button"
        onClick={onClick}
        size={displayType === 'compact' ? 'standard' : 'small'}
        variant={buttonVariant}
      >
        <UIIcon src={DownloadLine} />
      </IconButton>
    </Tooltip>
  ) : (
    <Button
      data-testid="download-button"
      onClick={onClick}
      variant={buttonVariant}
      withIconStart={<UIIcon src={DownloadLine} />}
    >
      {downloadMessage}
    </Button>
  );
};
