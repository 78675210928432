import React from 'react';

import type {reel} from '@dropbox/api-v2-client';

import type {WatermarkPositionsType} from '~/components/share_modal/position_selector';
import type {WatermarkThemeType} from '~/components/share_modal/theme_selector';
import {useReelAppGlobalState} from '~/context';

import type {LinkItemTypes} from './api';
import {
  createShareLink as apiCreateShareLink,
  createWatermarkLinks as apiCreateWatermarkLinks,
  getExistingLinks as apiGetExistingLinks,
  updateFileLinkSettings as apiUpdateFileLinkSettings,
  updateFolderLinkExpiry as apiUpdateFolderLinkExpiry,
  updateFolderLinkSettings as apiUpdateFolderLinkSettings,
  updateShareLinkExpiry as apiUpdateShareLinkExpiry,
  getReelLink,
  updateFolderLinkExpiry,
} from './api';
import {getShareTokenFromLink} from './helpers';
import type {LoggingClient} from './logging/logger';
import type {ShareButtonLocationType} from './logging/logger_types';
import {getWatermarkPositionLoggingType, toBooleanAttr} from './logging/logger_types';

interface ShareLinkOptions {
  isFolder: boolean;
  viewOnly: boolean;
  downloadsDisabled: boolean;
  passwordEnabled: boolean;
  password: string;
  loggedOutAccessDisabled: boolean;
  viewPreviousCommentsDisabled: boolean;
  versionNumber?: number;
  disableLink?: boolean;
}

export const useLinkActions = ({
  logEvent: rawLogEvent,
  versionSummaries,
  linkItemId,
  linkItemType,
  liveReview,
  location,
}: {
  logEvent: LoggingClient['logEvent'];
  versionSummaries?: Array<reel.VersionSummary>;
  linkItemId: string;
  linkItemType: LinkItemTypes;
  liveReview?: boolean;
  location: ShareButtonLocationType;
}) => {
  const sessionContext = useReelAppGlobalState();
  const logEventRef = React.useRef(rawLogEvent);
  React.useEffect(() => {
    logEventRef.current = rawLogEvent;
  }, [rawLogEvent]);

  const getItemId = React.useCallback(
    (versionNumber?: number) => {
      if (linkItemType == 'file') {
        const vid = versionNumber
          ? versionSummaries?.find((summary) => summary.version_num === versionNumber)
              ?.video_version_id
          : linkItemId;

        if (!vid) {
          throw new Error('Could not determine video id or video version id');
        }

        return vid;
      } else {
        return linkItemId;
      }
    },
    [versionSummaries, linkItemId, linkItemType],
  );

  const fetchLink = React.useCallback(
    async (viewOnly: boolean, versionNumber?: number) => {
      // eslint-disable-next-line deprecation/deprecation
      logEventRef.current('create_reel_link', {
        access_type: viewOnly ? 'view_only' : 'review',
        link_item_type: linkItemType,
        location: location,
      });
      const link = await getReelLink(getItemId(versionNumber), viewOnly, liveReview, linkItemType);
      return link;
    },
    [getItemId, liveReview, linkItemType, location],
  );

  const createShareLink = React.useCallback(
    async ({
      isFolder,
      viewOnly,
      downloadsDisabled,
      passwordEnabled,
      password,
      loggedOutAccessDisabled,
      viewPreviousCommentsDisabled,
      versionNumber,
      disableLink,
    }: ShareLinkOptions) => {
      // eslint-disable-next-line deprecation/deprecation
      logEventRef.current('create_reel_link', {
        access_type: viewOnly ? 'view_only' : 'review',
        link_item_type: linkItemType,
        location: location,
      });
      const link = await apiCreateShareLink({
        linkItemId: getItemId(versionNumber),
        linkItemType,
        viewOnly,
        password,
        passwordEnabled,
        downloadsDisabled,
        loggedOutAccessDisabled,
        viewPreviousCommentsDisabled,
      });
      if (disableLink !== undefined) {
        const result = isFolder
          ? await apiUpdateFolderLinkExpiry(
              getShareTokenFromLink(link),
              disableLink ? new Date() : undefined,
            )
          : await apiUpdateShareLinkExpiry(
              getShareTokenFromLink(link),
              disableLink ? new Date() : undefined,
            );
        if (!result) {
          throw new Error('Failed to set link enable/disable setting');
        }
      }

      return link;
    },
    [getItemId, linkItemType, location],
  );

  const updateBasicLink = React.useCallback(
    async (
      shareToken: string,
      isFolder: boolean,
      viewOnly: boolean,
      downloadsDisabled: boolean,
      passwordEnabled: boolean,
      password: string,
      loggedOutAccessDisabled: boolean,
      viewPreviousCommentsDisabled: boolean,
      setDisableLink: boolean,
      disableLink: boolean,
    ) => {
      // eslint-disable-next-line deprecation/deprecation
      logEventRef.current('update_share_link_settings', {
        access_type: viewOnly ? 'view_only' : 'review',
        password_setting: passwordEnabled ? 'enabled' : 'disabled',
        downloads_permission: downloadsDisabled ? 'disabled' : 'enabled',
        logged_out_access: loggedOutAccessDisabled ? 'disabled' : 'enabled',
        link_item_type: linkItemType,
      });

      let link;
      if (isFolder) {
        link = await apiUpdateFolderLinkSettings({
          shareToken,
          downloadsDisabled,
          password,
          passwordEnabled,
          loggedOutAccessDisabled,
        });
        if (setDisableLink) {
          if (
            !(await apiUpdateFolderLinkExpiry(
              getShareTokenFromLink(link),
              disableLink ? new Date() : undefined,
            ))
          ) {
            throw new Error('Failed to set link enable/disable setting');
          }
          if (disableLink) {
            // eslint-disable-next-line deprecation/deprecation
            logEventRef.current('disable_share_link', {
              access_type: viewOnly ? 'view_only' : 'review',
              link_item_type: linkItemType,
              is_watermarked: toBooleanAttr(false),
            });
          }
        }
      } else {
        link = await apiUpdateFileLinkSettings({
          shareToken,
          basicSettings: {
            password,
            passwordEnabled,
            downloadsDisabled,
            loggedOutAccessDisabled,
            viewPreviousCommentsDisabled,
            setDisableLink,
            disableLink,
          },
        });
        if (setDisableLink && disableLink) {
          // eslint-disable-next-line deprecation/deprecation
          logEventRef.current('disable_share_link', {
            access_type: viewOnly ? 'view_only' : 'review',
            link_item_type: linkItemType,
            is_watermarked: toBooleanAttr(false),
          });
        }
      }

      return link;
    },
    [linkItemType],
  );

  const getExistingLinks = React.useCallback(() => {
    if (sessionContext.status === 'logged out') {
      return Promise.resolve({basicLinks: [], watermarkLinks: []});
    }
    return apiGetExistingLinks(linkItemId, linkItemType);
  }, [linkItemId, linkItemType, sessionContext.status]);

  const createWatermarkShareLinks = React.useCallback(
    async (
      password: string,
      loggedOutAccessDisabled: boolean,
      viewPreviousCommentsDisabled: boolean,
      disableLink: boolean,
      recipientEmails: string[],
      showIP: boolean,
      showViewDate: boolean,
      showViewTime: boolean,
      customText: string,
      position: WatermarkPositionsType,
      theme: WatermarkThemeType,
      textOpacity: number,
      textSize: number,
    ) => {
      // eslint-disable-next-line deprecation/deprecation
      logEventRef.current('create_watermark_share_links', {
        num_links_created: recipientEmails.length ? recipientEmails.length : 1,
        watermark_link_type: recipientEmails.length ? 'individual' : 'generic',
        password_setting: password ? 'enabled' : 'disabled',
        logged_out_access: loggedOutAccessDisabled ? 'disabled' : 'enabled',
        replay_link_access: disableLink ? 'disabled' : 'enabled',
        view_previous_versions_disabled: viewPreviousCommentsDisabled ? 'disabled' : 'enabled',
        show_ip: showIP ? 'enabled' : 'disabled',
        show_view_date: showViewDate ? 'enabled' : 'disabled',
        show_view_time: showViewTime ? 'enabled' : 'disabled',
        has_custom_text: toBooleanAttr(!!customText),
        watermark_theme: theme,
        opacity: textOpacity,
        font_size: textSize,
        watermark_position: getWatermarkPositionLoggingType(position),
      });

      const maxVersionNumber =
        versionSummaries?.reduce((a, b) => Math.max(a, b.version_num), 1) ?? 1;

      const links = await apiCreateWatermarkLinks(
        getItemId(maxVersionNumber),
        loggedOutAccessDisabled,
        viewPreviousCommentsDisabled,
        password,
        disableLink ? new Date() : undefined,
        recipientEmails,
        showIP,
        showViewDate,
        showViewTime,
        customText,
        position,
        theme,
        textOpacity,
        textSize,
      );

      return links;
    },
    [getItemId, versionSummaries],
  );

  const updateWatermarkLink = React.useCallback(
    async (
      shareToken: string,
      password: string,
      passwordEnabled: boolean,
      loggedOutAccessDisabled: boolean,
      viewPreviousCommentsDisabled: boolean,
      setDisableLink: boolean,
      disableLink: boolean,
      showIP: boolean,
      showViewDate: boolean,
      showViewTime: boolean,
      customText: string,
      position: WatermarkPositionsType,
      theme: WatermarkPositionsType,
      textOpacity: number,
      textSize: number,
      isEmailLink: boolean,
    ) => {
      // eslint-disable-next-line deprecation/deprecation
      logEventRef.current('update_watermark_link_settings', {
        watermark_link_type: isEmailLink ? 'individual' : 'generic',
        password_setting: passwordEnabled ? 'enabled' : 'disabled',
        logged_out_access: loggedOutAccessDisabled ? 'disabled' : 'enabled',
        view_previous_versions_disabled: viewPreviousCommentsDisabled ? 'disabled' : 'enabled',
        replay_link_access: disableLink ? 'disabled' : 'enabled',
        show_ip: showIP ? 'enabled' : 'disabled',
        show_view_date: showViewDate ? 'enabled' : 'disabled',
        show_view_time: showViewTime ? 'enabled' : 'disabled',
        has_custom_text: toBooleanAttr(!!customText),
        watermark_theme: theme,
        opacity: textOpacity,
        font_size: textSize,
        watermark_position: getWatermarkPositionLoggingType(position),
      });
      if (setDisableLink && disableLink) {
        // eslint-disable-next-line deprecation/deprecation
        logEventRef.current('disable_share_link', {
          access_type: 'review',
          link_item_type: linkItemType,
          is_watermarked: toBooleanAttr(true),
        });
      }

      const link = await apiUpdateFileLinkSettings({
        shareToken,
        basicSettings: {
          password,
          passwordEnabled,
          loggedOutAccessDisabled,
          viewPreviousCommentsDisabled,
          setDisableLink,
          disableLink,
        },
        watermarkSettings: {
          showIP: showIP,
          showViewDate: showViewDate,
          showViewTime: showViewTime,
          customText: customText,
          position: position,
          theme: theme,
          textOpacity: textOpacity,
          textSize: textSize,
        },
      });
      return link;
    },
    [linkItemType],
  );

  const disableLink = React.useCallback(
    async (shareToken: string, isFolder: boolean, isViewOnly: boolean, isWatermarked: boolean) => {
      // eslint-disable-next-line deprecation/deprecation
      logEventRef.current('disable_share_link', {
        access_type: isViewOnly ? 'view_only' : 'review',
        link_item_type: linkItemType,
        is_watermarked: toBooleanAttr(isWatermarked),
      });
      const result = isFolder
        ? await updateFolderLinkExpiry(shareToken, new Date())
        : await apiUpdateShareLinkExpiry(shareToken, new Date());
      return result;
    },
    [linkItemType],
  );

  return {
    fetchLink,
    getExistingLinks,
    updateBasicLink,
    updateWatermarkLink,
    createShareLink,
    createWatermarkShareLinks,
    disableLink,
  };
};
