import type {FileCategory} from 'pap-events/enums/file_category';

import {MediaType} from './api';

export const maybePluralize = (count: number, noun: string, suffix = 's') =>
  `${count} ${noun}${count !== 1 ? suffix : ''}`;

// This list matches the list on the backend (IMAGE_EXIF_EXTS) of supported image types
export const IMAGE_EXTENSIONS = [
  '.3fr',
  '.arw',
  '.bmp',
  '.cr2',
  '.crw',
  '.dcr',
  //'.dcs', Very uncommon file that is hard to test
  '.dng',
  '.erf',
  '.gif',
  //'.heic', Thumbnailing fails
  '.jpeg',
  '.jpg',
  '.kdc',
  '.mef',
  '.mos',
  '.mrw',
  '.nef',
  '.nrw',
  '.orf',
  '.pef',
  '.png',
  '.ppm',
  //'.r3d', These can also be video files and this can lead to some issues
  '.raf',
  '.rw2',
  '.rwl',
  '.sr2',
  '.tif',
  '.tiff',
  '.webp',
  '.x3f',
];

export const AUDIO_EXTENSIONS = [
  '.aac',
  '.aif',
  '.aiff',
  '.m4a',
  '.m4r',
  '.mp3',
  '.oga',
  '.wav',
  '.wma',
  '.ogg',
  '.flac',
];

export const VIDEO_EXTENSIONS = [
  '.3gp',
  '.3gpp',
  '.3gpp2',
  '.asf',
  '.avi',
  '.dv',
  '.flv',
  '.m2t',
  '.m2ts',
  '.m4v',
  '.mkv',
  '.mov',
  '.mp4',
  '.mpeg',
  '.mpg',
  '.mts',
  '.mxf',
  '.oggtheora',
  '.ogv',
  '.rm',
  '.ts',
  '.vob',
  '.webm',
  '.wmv',
];

export const DOCUMENT_EXTENSIONS = ['.pdf'];

export const CREATIVE_DOC_EXTENSIONS = ['.psd'];

export const useValidExtensions = () => {
  return [
    ...AUDIO_EXTENSIONS,
    ...VIDEO_EXTENSIONS,
    ...IMAGE_EXTENSIONS,
    ...DOCUMENT_EXTENSIONS,
    ...CREATIVE_DOC_EXTENSIONS,
  ];
};

export const VALID_CAPTIONS_FILE_EXTENSIONS = ['.srt'];

// Mimes taken from https://www.iana.org/assignments/media-types/media-types.xhtml

export const VIDEO_MIME_TYPES = [
  'video/vc2',
  'video/vc1',
  'video/ulpfec',
  'video/scip',
  'video/rtx',
  'video/raw',
  'video/quicktime',
  'video/pointer',
  'video/parityfec',
  'video/ogg',
  'video/nv',
  'video/MPV',
  'video/mpeg4-generic',
  'video/MP4V-ES',
  'video/mp4',
  'video/MP2T',
  'video/MP2P',
  'video/MP1S',
  'video/mj2',
  'video/jxsv',
  'video/jpeg2000',
  'video/JPEG',
  'video/iso.segment',
  'video/H265',
  'video/H264-SVC',
  'video/H264-RCDO',
  'video/H264',
  'video/H263-2000',
  'video/H263-1998',
  'video/H263',
  'video/H261',
  'video/flexfec',
  'video/FFV1',
  'video/BMPEG',
  'video/AV1',
  'video/3gpp2',
  'video/3gpp-tt',
  'video/3gpp',
  'video/m4v',
  '',
];

export const AUDIO_MIME_TYPES = [
  'audio/aac',
  'audio/x-aif',
  'audio/x-aiff',
  'audio/MPA',
  'audio/mp4',
  'audio/mpeg',
  'audio/ogg',
  'audio/opus',
  'audio/vorbis',
  'audio/x-wav',
  'audio/x-flac',
  'audio/m4a',
  'audio/flac',
  'audio/x-m4r',
  'audio/m4r',
  'audio/x-ms-wma',
  'audio/x-wav',
  'audio/wav',
];

export const IMAGE_MIME_TYPES = [
  'image/bmp',
  'image/gif',
  'image/jpeg',
  'image/png',
  'image/tiff',
  'image/tiff-fx',
];

export const DOCUMENT_MIME_TYPES = ['application/pdf'];
export const CREATIVE_DOC_MIME_TYPES = ['image/vnd.adobe.photoshop'];

export const ALL_MIMES_STRING = [
  ...VIDEO_MIME_TYPES,
  ...AUDIO_MIME_TYPES,
  ...IMAGE_MIME_TYPES,
  ...DOCUMENT_MIME_TYPES,
  ...CREATIVE_DOC_MIME_TYPES,
].join(',');

export const useValidMimeTypesString = () => {
  return [
    ...VIDEO_MIME_TYPES,
    ...AUDIO_MIME_TYPES,
    ...IMAGE_MIME_TYPES,
    ...DOCUMENT_MIME_TYPES,
    ...CREATIVE_DOC_MIME_TYPES,
  ].join(',');
};

export const useValidFileExtensionsList = () => {
  return [
    ...VIDEO_EXTENSIONS,
    ...AUDIO_EXTENSIONS,
    ...IMAGE_EXTENSIONS,
    ...DOCUMENT_EXTENSIONS,
    ...CREATIVE_DOC_EXTENSIONS,
  ];
};

export const getExtension = (filename: string) => {
  return '.' + filename.split('.').pop()?.toLowerCase() || '';
};

export const getBasename = (filename: string) => {
  const parts = filename.split('.');
  return parts.slice(0, parts.length - 1).join('');
};

export const isImageLike = (mediaType?: MediaType) => {
  return mediaType === MediaType.Image || mediaType === MediaType.CreativeDoc;
};

export const isAVType = (mediaType?: MediaType) => {
  return mediaType === MediaType.Audio || mediaType === MediaType.Video;
};

export const getMediaType = (extension: string) => {
  return DOCUMENT_EXTENSIONS.includes(extension)
    ? MediaType.Document
    : AUDIO_EXTENSIONS.includes(extension)
    ? MediaType.Audio
    : IMAGE_EXTENSIONS.includes(extension)
    ? MediaType.Image
    : CREATIVE_DOC_EXTENSIONS.includes(extension)
    ? MediaType.CreativeDoc
    : VIDEO_EXTENSIONS.includes(extension)
    ? MediaType.Video
    : undefined;
};

export const isPdfExtension = (filename: string) => {
  return getMediaType(getExtension(filename)) === MediaType.Document;
};

export const convertMediaTypeToPapFileCategory = (mediaType?: MediaType): FileCategory => {
  switch (mediaType) {
    case MediaType.Video:
      return 'video';
    case MediaType.Audio:
      return 'audio';
    case MediaType.Image:
    case MediaType.CreativeDoc:
      return 'image';
    case MediaType.Document:
      return 'pdf';
    default:
      return 'other';
  }
};

// helper to ensure that the default switch case is never called (at type check)
export function enforceExhaustive(value: never): never {
  return value;
}

export const getShareTokenFromLink = (link: string): string => {
  try {
    const url = new URL(link);
    return getShareTokenFromPathname(url.pathname);
  } catch {
    return '';
  }
};

export const getShareTokenFromPathname = (pathname: string): string => {
  try {
    const path_parts = pathname.split('/');
    if (path_parts.length >= 3) {
      return path_parts[2];
    }
    return '';
  } catch {
    return '';
  }
};
