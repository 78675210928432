import React, {useEffect, useState} from 'react';

import {FormattedMessage, useIntl} from 'react-intl';

import {Snackbar} from '@dropbox/dig-components/snackbar';
import {Link} from '@dropbox/dig-components/typography';

import {Button} from '~/components/button';
import {ReelSnackbarContainer, ReelSnackbarContent} from '~/components/snackbar';
import {useReelAppGlobalState} from '~/context';

import {privacyConsentModule} from '../lib/initialize_privacy_module';

export const CookieSnackBar = () => {
  const {isCookieSnackbarOpen, setIsCookieSnackbarOpen, status} = useReelAppGlobalState();
  const [hasCookieSetting, setHasCookieSetting] = useState(false);

  const intl = useIntl();

  const productAndPrivacyPolicy = () => {
    return intl.formatMessage(
      {
        defaultMessage:
          'We use cookies to provide, improve, protect, and promote our services. Learn more in our <cclink>cookie policy</cclink> and <pplink>privacy policy</pplink>.',
        id: 'TN1d2y',
        description: 'Direct users to pages to learn more about cookie policy and privacy policy.',
      },
      {
        cclink: (txt: React.ReactNode) => (
          <Link
            href="https://help.dropbox.com/accounts-billing/security/cookies"
            target="_blank"
            variant="monochromatic"
          >
            {txt}
          </Link>
        ),
        pplink: (txt) => (
          <Link href="https://www.dropbox.com/privacy" target="_blank" variant="monochromatic">
            {txt}
          </Link>
        ),
      },
    );
  };

  const declineCookies = () => {
    privacyConsentModule.updateConsentChoices({analytics: false, functionality: false}, true);
    setIsCookieSnackbarOpen(false);
  };

  const acceptCookies = () => {
    privacyConsentModule.updateConsentChoices({analytics: true, functionality: true}, true);
    setIsCookieSnackbarOpen(false);
  };

  useEffect(() => {
    const checkConsentChoices = async () => {
      try {
        await privacyConsentModule.waitForChoices();
        setHasCookieSetting(true);
      } catch {
        setHasCookieSetting(false);
      }
    };
    checkConsentChoices();
  }, []);

  const willUserBeRedirected = React.useMemo(() => {
    if (status === 'logged in') {
      return false;
    }
    return window.location.pathname === '/';
  }, [status]);

  if (willUserBeRedirected) {
    return null;
  }

  return (
    <ReelSnackbarContainer>
      <ReelSnackbarContent open={!hasCookieSetting || isCookieSnackbarOpen}>
        <Snackbar.Message>{productAndPrivacyPolicy()}</Snackbar.Message>
        <Snackbar.Actions>
          <Button inverse onClick={declineCookies} variant="transparent">
            <FormattedMessage
              defaultMessage="Decline"
              description="Snackbar button that indicates a user does not wish to store cookie data"
              id="8DyxR2"
            />
          </Button>
          <Button inverse onClick={acceptCookies} variant="transparent">
            <FormattedMessage
              defaultMessage="Accept all"
              description="Snackbar button that indicates a user is willing to store cookie data"
              id="PcDyMG"
            />
          </Button>
        </Snackbar.Actions>
      </ReelSnackbarContent>
    </ReelSnackbarContainer>
  );
};
