import {useAtomValue} from 'jotai';

import type {VersionStatus} from '~/components/status/status';
import {StatusDropdown} from '~/components/status/status_dropdown';
import {BulkActionStatus, bulkActionStatusAtom} from '~/state/bulk_actions';

import {useViewport} from '../../../components/viewport_context';

type BulkUpdateStatusButtonProps = {
  onClick: (newStatus: VersionStatus) => void;
};

export const BulkUpdateStatusButton = ({onClick}: BulkUpdateStatusButtonProps) => {
  const bulkActionStatus = useAtomValue(bulkActionStatusAtom);
  // eslint-disable-next-line deprecation/deprecation
  const {isSmallScreenSize} = useViewport();

  const disabled = bulkActionStatus === BulkActionStatus.REQUESTING;

  return (
    <StatusDropdown
      buttonSize={isSmallScreenSize ? 'small' : 'standard'}
      buttonType="opacity"
      disabled={disabled}
      onChange={onClick}
    />
  );
};
