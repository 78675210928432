import {AnalyticsClient} from 'pap-client';
import type {PAPEvent} from 'pap-events/base/event';

import type {InitialSessionState} from '~/context';
import {CurrentEnvironment, getCurrentEnvironment} from '~/lib/environment';

const isTestEnv = process.env.NODE_ENV === 'test';
/**
 * This logger logs to PAP using the upstream pap-client. It is our
 * primary logger, and should be used for all new logging. However, it should
 * only be used through `logger.logEvent` and not instantiated directly.
 */
class PAPLogger {
  private client?: AnalyticsClient;
  private isInitialized = false;

  initialize(sessionState: InitialSessionState) {
    if (this.isInitialized) {
      return;
    }

    this.isInitialized = true;

    // Don't enable PAP client in development otherwise webpack overlay will
    // show an unhandled promise rejection with devbox
    if (getCurrentEnvironment() === CurrentEnvironment.Development) {
      return;
    }

    const client = new AnalyticsClient({
      transport: {
        type: 'sdk',
        sdk: sessionState.dropboxClient,
      },
    });

    const fields =
      sessionState.status === 'logged in'
        ? {
            identity: {
              foreignUserProvider: 'dbx_account_id',
              foreignUserId: sessionState.currentAccount.account_id,
            },
          }
        : {};

    client.setPersistentFields({
      ...fields,
      application: {
        productName: 'replay',
      },
    });

    this.client = client;
  }

  logEvent(event: PAPEvent) {
    if (isTestEnv) return;

    const isDevelopment = getCurrentEnvironment() === CurrentEnvironment.Development;

    if (!this.client) {
      if (isDevelopment) {
        console.debug(
          `[disabled] received PAP event to log: ${event.action}.${event.object}`,
          event,
        );
      }
      return;
    }
    if (isDevelopment) {
      console.debug(`logging PAP event: ${event.action}.${event.object}`, event);
    }
    this.client.logEvent(event);
  }
}

/**
 * This logger should only be used from ~/lib/logging/logger.ts or `useLoggingClient`
 * @deprecated use logger.logEvent instead (from ~/lib/logging/logger.ts)
 */
export const papLogger = new PAPLogger();

export const initializePapLogger = (sessionState: InitialSessionState) => {
  // Exposing non-deprecated wrapper to initialize the logger
  // eslint-disable-next-line deprecation/deprecation
  papLogger.initialize(sessionState);
};
