import React from 'react';

import {PAP_Upload_File} from 'pap-events/manual_upload/upload_file';
import {FormattedMessage} from 'react-intl';

import {Snackbar} from '@dropbox/dig-components/snackbar';
import {Text} from '@dropbox/dig-components/typography';

import {Button} from '~/components/button';
import {useUploadDrawerUploadsAndProgress} from '~/components/upload_drawer/use_upload_drawer_uploads_and_progress';
import {useReelAppGlobalState} from '~/context';
import {openGdriveFilePicker} from '~/lib/gdrive/file_picker';
import {FileUploadState, UploadTypes} from '~/lib/uploads/types';
import type {
  AllUploadsCompleteHandler,
  BasicFileInfo,
  GDriveUpload,
  MappedGDriveUploads,
  UploadsProgress,
} from '~/lib/uploads/types';
import {generateRandomId} from '~/lib/utils';

import {GDriveTokenModal} from './gdrive_token_modal';
import {GDriveUnlinkModal} from './gdrive_unlink_modal';
import {ReelSnackbar, SnackbarCloseMessage} from './snackbar';
import type {EmptyProjectsResponse, MediaType} from '../lib/api';
import {
  addToProject,
  createEmptyProjects,
  EmptyProjectCreationStatus,
  GDriveImportStatus,
  getLinkedGoogleToken,
  queryGDriveImportStatus,
  startGDriveFileImport,
} from '../lib/api';
import {reportBadContextUseError, reportException, reportGDriveError} from '../lib/error_reporting';
import {FilePickerAction} from '../lib/gdrive/iframe_types';
import {
  convertMediaTypeToPapFileCategory,
  enforceExhaustive,
  getExtension,
  getMediaType,
  useValidMimeTypesString,
} from '../lib/helpers';
import type {LoggingClient} from '../lib/logging/logger';
import type {
  AddMediaClickSourceType,
  AddNewVersionClickSourceType,
} from '../lib/logging/logger_types';
import {useLoggingClient} from '../lib/use_logging_client';

const delay = (ms: number) => new Promise((r) => setTimeout(r, ms));

type CreateVersionHandler = (fileId: string) => Promise<{
  projectId?: string;
  videoId?: string;
  videoVersionId?: string;
  videoIdForAmplitude?: string;
  versionIdForAmplitude?: string;
  fileSize?: number;
  mediaType?: MediaType;
}>;
type FileErrorHandler = (upload?: GDriveUpload) => void;
type FileCompleteHandler = (upload: GDriveUpload, videoId: string) => void;

const StatusMap = {
  [GDriveImportStatus.Completed]: FileUploadState.Uploading, // still need to add to project
  [GDriveImportStatus.Failed]: FileUploadState.Error,
  [GDriveImportStatus.Running]: FileUploadState.Uploading,
};

export type GDriveUploadProjectProps = {
  currentFolderId: string;
  clickSource: AddMediaClickSourceType;
  onFilePick: (files: BasicFileInfo[]) => void;
  onAllUploadsComplete: AllUploadsCompleteHandler;
  logEvent: LoggingClient['logEvent'];
};

export type GDriveUploadVersionProps = {
  nsId: number;
  onCreateVersion: CreateVersionHandler;
  onUploadComplete?: FileCompleteHandler;
  onError?: FileErrorHandler;
  clickSource: AddMediaClickSourceType | AddMediaClickSourceType | AddNewVersionClickSourceType;
};

export type GDriveUploadCaptionsProps = {
  nsId: number;
  onCaptionsUploaded: (fileId: string) => void;
  onError: () => void;
  logEvent: LoggingClient['logEvent'];
};

export type GDriveContextValue = {
  startGDriveProjectImport: (props: GDriveUploadProjectProps) => void;
  startGDriveVersionImport: (props: GDriveUploadVersionProps) => void;
  startGDriveCaptionImport: (props: GDriveUploadCaptionsProps) => void;
};

const GDriveContext = React.createContext<GDriveContextValue | null>(null);

type SnackbarProps = {
  open: boolean;
  onClose: () => void;
};

type UnlinkSnackbarProps = SnackbarProps & {isError: boolean};
export type UnlinkStatus = 'succeeded' | 'failed' | 'cancelled';

const TooManyfilesSnackbar = ({open, onClose}: SnackbarProps) => {
  return (
    <ReelSnackbar onRequestClose={onClose} open={open} timeout={5000}>
      <Snackbar.Message>
        <FormattedMessage
          defaultMessage="Please select only one file"
          description="Error message instructing the user to only choose one file for a version or caption upload"
          id="G4TrLk"
        />
      </Snackbar.Message>
      <Snackbar.Actions>
        <Button inverse onClick={onClose} variant="transparent">
          <SnackbarCloseMessage />
        </Button>
      </Snackbar.Actions>
    </ReelSnackbar>
  );
};

const UnlinkSnackbar = ({open, onClose, isError}: UnlinkSnackbarProps) => {
  return (
    <ReelSnackbar onRequestClose={onClose} open={open} timeout={5000}>
      <Snackbar.Message>
        <Text>
          {isError ? (
            <FormattedMessage
              defaultMessage="There was an issue unlinking your account. Please go to your Dropbox Account settings, and try relinking your Google account there."
              description="Error message telling the user unlinking their Google account failed."
              id="g1zmHI"
            />
          ) : (
            <FormattedMessage
              defaultMessage="Unlink complete. Try uploading again to relink your account."
              description="Message telling the user how to relink their Google account."
              id="Q5tZmY"
            />
          )}
        </Text>
      </Snackbar.Message>
      <Snackbar.Actions>
        <Button inverse onClick={onClose} variant="transparent">
          <SnackbarCloseMessage />
        </Button>
      </Snackbar.Actions>
    </ReelSnackbar>
  );
};

type GDrivePausedOperation =
  | {
      type: 'project';
      props: GDriveUploadProjectProps;
    }
  | {
      type: 'version';
      props: GDriveUploadVersionProps;
    }
  | {
      type: 'caption';
      props: GDriveUploadCaptionsProps;
    };
const usePausedGDriveOperation = function () {
  const opRef = React.useRef<GDrivePausedOperation | null>(null);
  const setPausedOp = React.useCallback((op: GDrivePausedOperation) => {
    opRef.current = op;
  }, []);

  const hasPausedOp = React.useCallback(() => opRef.current != null, []);

  const getPausedOp = React.useCallback(() => {
    const op = opRef.current;
    opRef.current = null;
    return op;
  }, []);

  return {setPausedOp, hasPausedOp, getPausedOp};
};

const buildViewLink = ({
  projectId,
  videoId,
  videoVersionId,
}: {
  projectId: string;
  videoId: string;
  videoVersionId?: string;
}) => {
  let link = `/project/${projectId}/video/${videoId}`;
  if (videoVersionId) {
    link += `?video_version_id=${videoVersionId}`;
  }
  return link;
};

export const GDriveContextProvider = (props: React.PropsWithChildren<{}>) => {
  const [havePerms, setHavePerms] = React.useState<boolean>(false);
  const [unlinkModalOpen, setUnlinkModalOpen] = React.useState<boolean>(false);
  const [unlinkFailed, setUnlinkFailed] = React.useState<boolean>(false);
  const [tokenModalOpen, setTokenModalOpen] = React.useState<boolean>(false);
  const [gdriveToken, setGdriveToken] = React.useState<string | null>(null);
  const [unlinkSnackbarOpen, setUnlinkSnackbarOpen] = React.useState<boolean>(false);
  const loggingClient = useLoggingClient();
  const [importIdMapping, setImportIdMapping] = React.useState<Record<string, string>>({});
  const [tooManyfilesSnakbarOpen, setTooManyFilesSnackbarOpen] = React.useState<boolean>(false);
  const {setPausedOp, hasPausedOp, getPausedOp} = usePausedGDriveOperation();
  const validMimeTypes = useValidMimeTypesString();
  const isLoggedIn = useReelAppGlobalState().status === 'logged in';

  const {
    cancelUpload,
    getLoggingData,
    getUploads,
    getUploadsProgress,
    handleUpdateProgress,
    handleUpdateUploads,
    isUploadCanceled,
    updateLoggingData,
    updateMappedUploadAsError,
    updateUpload,
    updateUploadProgress,
  } = useUploadDrawerUploadsAndProgress<MappedGDriveUploads>();

  const onCloseFilesSnackbar = () => setTooManyFilesSnackbarOpen(false);

  // When the callbacks refer to each other they do not get refreshed and therefor refer to
  // stale state values. By using a ref callbacks will always have the current state value
  const importIdMappingRef = React.useRef<Record<string, string>>();
  importIdMappingRef.current = importIdMapping;

  const fetchGDriveToken = async () => {
    await getLinkedGoogleToken().then((token) => {
      if (typeof token === 'string') {
        setGdriveToken(token);
        setHavePerms(true);
      } else if (token === null) {
        setHavePerms(true);
      }
    });
  };

  React.useEffect(() => {
    if (!isLoggedIn) {
      return;
    }
    // delay to avoid startup issues and to avoid sending a non-important request during startup
    delay(1000).then(() => {
      fetchGDriveToken();
    });
  }, [isLoggedIn]);

  const onUnlinkModalClose = (unlinkStatus: UnlinkStatus) => {
    if (unlinkStatus === 'succeeded') {
      // After unlinking the Google Account, we should try refetching the token
      fetchGDriveToken();
      setUnlinkFailed(false);
      setUnlinkModalOpen(false);
      setUnlinkSnackbarOpen(true);
    } else if (unlinkStatus === 'failed') {
      setUnlinkFailed(true);
      setUnlinkModalOpen(false);
      setUnlinkSnackbarOpen(true);
    } else {
      setUnlinkModalOpen(false);
    }
  };

  const ensureCanUploadToGDrive = React.useCallback((): boolean => {
    if (!havePerms) {
      setUnlinkFailed(false);
      setUnlinkModalOpen(true);
      // eslint-disable-next-line deprecation/deprecation
      loggingClient.logEvent('gdrive_login_modal_shown');
      return false;
    }

    if (gdriveToken === null) {
      setTokenModalOpen(true);
      // eslint-disable-next-line deprecation/deprecation
      loggingClient.logEvent('gdrive_account_link_modal_shwon');
      return false;
    }

    return true;
  }, [havePerms, gdriveToken, loggingClient]);

  const pollTransferProgress = React.useCallback(
    async (
      onAllFinished: () => void,
      finalizeTransfer: (transfer: GDriveUpload, fileId: string, uploadId: string) => Promise<void>,
      onFileError?: FileErrorHandler,
    ) => {
      // this is impossible but here for typechecker
      const uploadsProgress = getUploadsProgress();
      const uploads = getUploads();
      if (!uploadsProgress || !uploads) {
        throw new Error('Invalid ref state');
      }

      let finished = true;

      // make a copy so we can update state
      const progress = {...uploadsProgress};
      const updatePromises = Object.entries(progress).map(
        async ([uploadId, transfer]) =>
          new Promise<void>(async (resolve) => {
            // this is impossible but here for typechecker
            if (!uploads || !importIdMappingRef.current) {
              throw new Error('Invalid ref state');
            }

            const markError = () => {
              if (onFileError && uploads) {
                onFileError(uploads[uploadId]);
              }
              progress[uploadId] = {
                ...transfer,
                status: FileUploadState.Error,
              };
              finished = true;
              resolve();
            };

            if (
              transfer.status &&
              (transfer.status === FileUploadState.Uploading ||
                transfer.status === FileUploadState.Ready)
            ) {
              const importId = importIdMappingRef.current[uploadId];
              const newStatus = await queryGDriveImportStatus(importId);
              if (newStatus === GDriveImportStatus.Running) {
                const oldPercent = transfer.percentage ? transfer.percentage : 1;
                progress[uploadId] = {
                  ...transfer,
                  percentage: Math.min(oldPercent + 3, 99), // lol. Works out to ~30 seconds
                  status: StatusMap[newStatus],
                };
                finished = false;
              } else if (newStatus === GDriveImportStatus.Completed) {
                const t = uploads[uploadId];

                if (!t.nsId) {
                  markError();
                  return;
                }
                try {
                  await finalizeTransfer(
                    t,
                    `ns:${t.nsId}/${t.file.gdriveId}/${t.file.name}`,
                    uploadId,
                  );
                  progress[uploadId] = {
                    ...transfer,
                    percentage: 100,
                    status: FileUploadState.Complete,
                  };
                } catch (err) {
                  loggingClient.logPap(
                    PAP_Upload_File({
                      ...getLoggingData()[uploadId],
                      fileCategory: 'other',
                      eventState: 'failed',
                      failureType: `${err}`,
                    }),
                  );
                  reportGDriveError(err);
                  markError();
                }
              } else {
                markError();
              }
            }

            resolve();
          }),
      );
      await Promise.all(updatePromises);

      handleUpdateProgress(progress);

      if (!finished) {
        setTimeout(() => pollTransferProgress(onAllFinished, finalizeTransfer, onFileError), 1000);
      } else {
        onAllFinished();
        // eslint-disable-next-line deprecation/deprecation
        loggingClient.logEvent('gdrive_transfer_complete');
      }
    },
    [getLoggingData, getUploads, getUploadsProgress, handleUpdateProgress, loggingClient],
  );

  const startFileImportToProjects = React.useCallback(
    async (
      projects: EmptyProjectsResponse,
      onAllFinished: () => void,
      logEvent: LoggingClient['logEvent'],
      fileIdToUploadIdMapping: Record<string, string>,
    ) => {
      const onImportStart = (uploadId: string, importId: string | null) => {
        const uploadsProgress = getUploadsProgress();
        const uploads = getUploads();

        // this is impossible but here for typechecker
        if (!uploadsProgress || !uploads) {
          throw new Error('Invalid ref state');
        }

        if (!importId) {
          updateUploadProgress(uploadId, {
            status: FileUploadState.Error,
          });
        } else {
          if (!isUploadCanceled(uploadId)) {
            updateUploadProgress(uploadId, {
              status: FileUploadState.Uploading,
              percentage: 1, // we don't get progress from the backend unfortunately
            });
          }
          setImportIdMapping({
            ...importIdMapping,
            [uploadId]: importId,
          });
        }
      };

      // start imports
      const progress = {...getUploadsProgress()};
      const transfersCopy: MappedGDriveUploads = {};
      const startRequests: Promise<void>[] = Object.entries(projects).map(
        ([fileId, createResult]) => {
          const uploadId = fileIdToUploadIdMapping[fileId];
          if (createResult.status !== EmptyProjectCreationStatus.Complete || !createResult.nsId) {
            progress[uploadId].status = FileUploadState.Error;
            return new Promise<void>((resolve) => resolve());
          }
          transfersCopy[uploadId] = {
            ...getUploads()[uploadId],
            projectId: createResult.projectId,
            nsId: createResult.nsId,
            onCancel: (upload) => cancelUpload(upload.uploadId),
          };

          return new Promise<void>(async (resolve) => {
            if (createResult.nsId) {
              const importId = await startGDriveFileImport(fileId, createResult.nsId);
              onImportStart(uploadId, importId);
            }
            resolve();
          });
        },
      );
      handleUpdateUploads(transfersCopy);
      await Promise.all(startRequests);

      // start polling
      const finalizeTransfer = async (
        transfer: GDriveUpload,
        fileId: string,
        uploadId: string,
      ): Promise<void> => {
        if (!transfer.projectId) {
          throw new Error('Project id is missing');
        }

        if (isUploadCanceled(uploadId)) {
          return;
        }

        const {videoId, videoVersionId, versionIdForAmplitude, videoIdForAmplitude, fileSize} =
          await addToProject(transfer.projectId, fileId, false);

        loggingClient.logPap(
          PAP_Upload_File({
            ...getLoggingData()[uploadId],
            fileSize: fileSize,
            eventState: 'success',
            videoId: videoIdForAmplitude,
            videoVersionId: versionIdForAmplitude,
          }),
        );
        const link = buildViewLink({
          videoId,
          videoVersionId: transfer.versionId || videoVersionId,
          projectId: transfer.projectId,
        });

        updateUpload(uploadId, {
          link,
          projectId: transfer.projectId,
          versionId: transfer.versionId || videoVersionId,
        });
      };
      pollTransferProgress(onAllFinished, finalizeTransfer, () => {});
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- PR 894
    [
      cancelUpload,
      getLoggingData,
      getUploads,
      getUploadsProgress,
      handleUpdateUploads,
      importIdMapping,
      pollTransferProgress,
      updateUpload,
      updateUploadProgress,
    ],
  );

  const startGDriveProjectImport = React.useCallback(
    async (props: GDriveUploadProjectProps) => {
      const {currentFolderId, clickSource, onFilePick, onAllUploadsComplete, logEvent} = props;
      if (!ensureCanUploadToGDrive()) {
        setPausedOp({type: 'project', props});
        return;
      }
      if (!gdriveToken) return;
      // eslint-disable-next-line deprecation/deprecation
      loggingClient.logEvent('gdrive_picker_opened');

      const pickerResult = await openGdriveFilePicker(gdriveToken, false, validMimeTypes);
      if (pickerResult.action !== FilePickerAction.PICKED) return;

      /* TODO - for now we limit to only one file because the file-imports backend does not allow
          multiple concurrent jobs. The work to update the backend and here to use a single import job
          is part of the handover to Creative Workflows
      */
      if (pickerResult.fileIds.length !== 1) {
        setTooManyFilesSnackbarOpen(true);
        return;
      }

      const fileIds = pickerResult.fileIds;
      onFilePick(
        Object.values(pickerResult.filesInfo).map((info) => {
          // TODO(RPD-462): Return actual file size once we get this from pickerResult
          return {...info, relativePath: '', fileSize: 0};
        }),
      );

      // Get the snackbar and modal data ready so we have something to show
      const newTransfers: MappedGDriveUploads = {};
      const progress: UploadsProgress = {};
      const fileIdToUploadIdMapping: Record<string, string> = {};

      fileIds.forEach((fileId) => {
        const uploadId = generateRandomId();

        newTransfers[uploadId] = {
          canceled: false,
          file: {
            gdriveId: fileId,
            name: pickerResult.filesInfo[fileId].name,
          },
          mediaSourceType: 'gdrive',
          type: UploadTypes.PROJECT,
          uploadId,
        };

        progress[uploadId] = {
          percentage: 0,
          status: FileUploadState.Ready,
        };

        fileIdToUploadIdMapping[fileId] = uploadId;
      });

      handleUpdateUploads(newTransfers);
      handleUpdateProgress(progress);
      const batchId = generateRandomId();

      const filenameMap = Object.entries(newTransfers).reduce((map, [uploadId, file]) => {
        updateLoggingData(uploadId, {
          actionSource: clickSource,
          uploadMethod: 'gdrive',
          contentType: 'file',
          startType: 'initial',
          batchIdString: batchId,
          eventState: 'start',
          uploadId,
          fileCategory: getMediaType(getExtension(file.file.name)),
          fileExtension: getExtension(file.file.name),
        });

        loggingClient.logPap(PAP_Upload_File(getLoggingData()[uploadId]));
        return {
          ...map,
          [file.file.gdriveId]: file.file.name,
        };
      }, {} as Record<string, string>);

      try {
        const createResult = await createEmptyProjects(filenameMap, {}, currentFolderId);
        startFileImportToProjects(
          createResult,
          () =>
            onAllUploadsComplete(
              Object.values(newTransfers).map((transfer) => transfer.file),
              currentFolderId,
              clickSource,
              'gdrive',
              'file',
            ),
          logEvent,
          fileIdToUploadIdMapping,
        );
      } catch (err) {
        reportGDriveError(err);
        Object.values(newTransfers).forEach((transfer) => {
          updateMappedUploadAsError(transfer.uploadId, {}, err);
          loggingClient.logPap(
            PAP_Upload_File({
              ...getLoggingData()[transfer.uploadId],
              fileCategory: 'other',
              eventState: 'failed',
              failureType: `${err}`,
            }),
          );
        });
        onAllUploadsComplete(
          Object.values(newTransfers).map((transfer) => transfer.file),
          currentFolderId,
          clickSource,
          'gdrive',
          'file',
        );
      }
    },
    [
      ensureCanUploadToGDrive,
      gdriveToken,
      getLoggingData,
      handleUpdateProgress,
      handleUpdateUploads,
      loggingClient,
      setPausedOp,
      startFileImportToProjects,
      updateLoggingData,
      updateMappedUploadAsError,
      validMimeTypes,
    ],
  );

  const startGDriveVersionImport = React.useCallback(
    async (props: GDriveUploadVersionProps) => {
      const {nsId, onCreateVersion, onError, clickSource} = props;
      if (!ensureCanUploadToGDrive()) {
        setPausedOp({type: 'version', props});
        return;
      }
      if (!gdriveToken) return;
      // eslint-disable-next-line deprecation/deprecation
      loggingClient.logEvent('gdrive_picker_opened');

      const pickerResult = await openGdriveFilePicker(gdriveToken, false, validMimeTypes);
      if (pickerResult.action !== FilePickerAction.PICKED) return;
      const fileIds = pickerResult.fileIds;
      if (fileIds.length !== 1) {
        // it seems we cannot limit the picker to a single file
        setTooManyFilesSnackbarOpen(true);
        onError?.();
        return;
      }
      const fileId = fileIds[0];

      const batchId = generateRandomId();
      const uploadId = generateRandomId();
      updateLoggingData(uploadId, {
        actionSource: clickSource,
        uploadMethod: 'gdrive',
        contentType: 'file',
        startType: 'initial',
        batchIdString: batchId,
        eventState: 'start',
        uploadId,
        fileCategory: getMediaType(pickerResult.filesInfo[fileId].name),
        actionElement: 'version',
        fileExtension: getExtension(pickerResult.filesInfo[fileId].name),
      });

      loggingClient.logPap(PAP_Upload_File(getLoggingData()[uploadId]));

      const mappedUploads: MappedGDriveUploads = {
        [uploadId]: {
          canceled: false,
          file: {
            gdriveId: fileId,
            name: pickerResult.filesInfo[fileId].name,
          },
          mediaSourceType: 'gdrive',
          nsId: nsId,
          type: UploadTypes.VERSION,
          uploadId,
          onCancel: (upload) => cancelUpload(upload.uploadId),
        },
      };
      const progress: UploadsProgress = {
        [uploadId]: {
          percentage: 0,
          status: FileUploadState.Ready,
        },
      };

      handleUpdateUploads(mappedUploads);
      handleUpdateProgress(progress);

      try {
        const importId = await startGDriveFileImport(fileId, nsId);
        if (!importId) {
          throw new Error('Failed to start import');
        }
        setImportIdMapping({
          [uploadId]: importId,
        });
        // start polling
        const finalizeTransfer = async (
          transfer: GDriveUpload,
          fileId: string,
          uploadId: string,
        ): Promise<void> => {
          if (isUploadCanceled(uploadId)) {
            return;
          }

          const {
            projectId,
            videoId,
            videoVersionId,
            versionIdForAmplitude,
            videoIdForAmplitude,
            fileSize,
            mediaType,
          } = await onCreateVersion(fileId);
          if (!projectId) {
            throw new Error('Project id is missing');
          }
          if (!videoId) {
            throw new Error('Video id is missing');
          }
          loggingClient.logPap(
            PAP_Upload_File({
              ...getLoggingData()[uploadId],
              fileSize: fileSize,
              eventState: 'success',
              videoId: videoIdForAmplitude,
              videoVersionId: versionIdForAmplitude,
              fileCategory: convertMediaTypeToPapFileCategory(mediaType),
            }),
          );

          const link = buildViewLink({
            projectId,
            videoId,
            videoVersionId,
          });
          updateUpload(uploadId, {
            link,
            projectId,
            versionId: videoVersionId,
            videoId,
          });
        };
        pollTransferProgress(() => {}, finalizeTransfer, onError);
      } catch (err) {
        loggingClient.logPap(
          PAP_Upload_File({
            ...getLoggingData()[uploadId],
            fileCategory: 'other',
            eventState: 'failed',
            failureType: `${err}`,
          }),
        );
        reportGDriveError(err);
        updateMappedUploadAsError(uploadId, {}, err);
        onError?.();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- PR 894
    [
      ensureCanUploadToGDrive,
      gdriveToken,
      validMimeTypes,
      updateLoggingData,
      getLoggingData,
      handleUpdateUploads,
      handleUpdateProgress,
      setPausedOp,
      cancelUpload,
      pollTransferProgress,
      getUploadsProgress,
      updateUpload,
      updateMappedUploadAsError,
    ],
  );

  const startGDriveCaptionImport = React.useCallback(
    async (props: GDriveUploadCaptionsProps) => {
      const {nsId, onCaptionsUploaded, onError, logEvent} = props;
      if (!ensureCanUploadToGDrive()) {
        setPausedOp({type: 'caption', props});
        return;
      }
      if (!gdriveToken) return;
      // eslint-disable-next-line deprecation/deprecation
      logEvent('gdrive_picker_opened');

      const pickerResult = await openGdriveFilePicker(gdriveToken, true, validMimeTypes);
      if (pickerResult.action !== FilePickerAction.PICKED) return;
      const fileIds = pickerResult.fileIds;
      if (fileIds.length !== 1) {
        // it seems we cannot limit the picker to a single file
        setTooManyFilesSnackbarOpen(true);
        return;
      }
      const fileId = fileIds[0];
      const batchId = generateRandomId();
      const uploadId = generateRandomId();
      updateLoggingData(uploadId, {
        uploadMethod: 'gdrive',
        startType: 'initial',
        batchIdString: batchId,
        eventState: 'start',
        uploadId,
        fileCategory: getMediaType(pickerResult.filesInfo[fileId].name),
        actionElement: UploadTypes.CAPTIONS,
        fileExtension: getExtension(pickerResult.filesInfo[fileId].name),
      });

      loggingClient.logPap(PAP_Upload_File(getLoggingData()[uploadId]));

      const mappedUploads: MappedGDriveUploads = {
        [uploadId]: {
          canceled: false,
          file: {
            gdriveId: fileId,
            name: pickerResult.filesInfo[fileId].name,
          },
          mediaSourceType: 'gdrive',
          nsId: nsId,
          type: UploadTypes.CAPTIONS,
          uploadId,
          onCancel: (upload) => cancelUpload(upload.uploadId),
        },
      };
      const progress: UploadsProgress = {
        [uploadId]: {
          percentage: 0,
          status: FileUploadState.Ready,
        },
      };

      handleUpdateUploads(mappedUploads);
      handleUpdateProgress(progress);

      try {
        const importId = await startGDriveFileImport(fileId, nsId);
        if (!importId) {
          throw new Error('Failed to start import');
        }
        setImportIdMapping({
          [uploadId]: importId,
        });
        // start polling
        const finalizeTransfer = async (
          transfer: GDriveUpload,
          fileId: string,
          uploadId: string,
        ): Promise<void> => {
          if (isUploadCanceled(uploadId)) {
            return;
          }
          await onCaptionsUploaded(fileId);

          loggingClient.logPap(
            PAP_Upload_File({
              ...getLoggingData()[uploadId],
              eventState: 'success',
            }),
          );
        };
        pollTransferProgress(() => {}, finalizeTransfer, onError);
      } catch (err) {
        reportGDriveError(err);
        loggingClient.logPap(
          PAP_Upload_File({
            ...getLoggingData()[uploadId],
            fileCategory: 'other',
            eventState: 'failed',
            failureType: `${err}`,
          }),
        );
        updateMappedUploadAsError(uploadId, {}, err);
        onError();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- PR 894
    [
      cancelUpload,
      ensureCanUploadToGDrive,
      gdriveToken,
      getLoggingData,
      getUploadsProgress,
      handleUpdateProgress,
      handleUpdateUploads,
      pollTransferProgress,
      setPausedOp,
      updateLoggingData,
      updateMappedUploadAsError,
      validMimeTypes,
    ],
  );

  React.useEffect(() => {
    if (havePerms && gdriveToken && hasPausedOp()) {
      setUnlinkModalOpen(false);
      setTokenModalOpen(false);
      const op = getPausedOp();
      if (op == null) {
        // Shouldn't ever happen, but need it for type guard
        return;
      }

      switch (op.type) {
        case 'project':
          startGDriveProjectImport(op.props).catch(reportException);
          break;
        case 'version':
          startGDriveVersionImport(op.props).catch(reportException);
          break;
        case 'caption':
          startGDriveCaptionImport(op.props).catch(reportException);
          break;
        default:
          enforceExhaustive(op);
      }
    }
  }, [
    havePerms,
    gdriveToken,
    startGDriveProjectImport,
    startGDriveVersionImport,
    startGDriveCaptionImport,
    hasPausedOp,
    getPausedOp,
  ]);

  const value: GDriveContextValue = React.useMemo(
    () => ({
      startGDriveProjectImport,
      startGDriveVersionImport,
      startGDriveCaptionImport,
    }),
    [startGDriveProjectImport, startGDriveVersionImport, startGDriveCaptionImport],
  );

  return (
    <GDriveContext.Provider value={value}>
      <GDriveUnlinkModal onClose={onUnlinkModalClose} open={unlinkModalOpen} />
      <GDriveTokenModal
        loggingClient={loggingClient}
        onClose={() => setTokenModalOpen(false)}
        open={tokenModalOpen}
        setGdriveToken={setGdriveToken}
      />
      <UnlinkSnackbar
        isError={unlinkFailed}
        onClose={() => setUnlinkSnackbarOpen(false)}
        open={unlinkSnackbarOpen}
      />
      <TooManyfilesSnackbar onClose={onCloseFilesSnackbar} open={tooManyfilesSnakbarOpen} />

      {props.children}
    </GDriveContext.Provider>
  );
};

export const useGDriveContext = () => {
  const context = React.useContext(GDriveContext);

  if (context === null) {
    const error = new Error('useGDriveContext must be used within a GDriveContextProvider');
    reportBadContextUseError(error);
    throw error;
  }

  return context;
};
