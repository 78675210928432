import React from 'react';

import type {AccessType} from 'pap-events/enums/access_type';
import {PAP_Select_ModalAction} from 'pap-events/replay/select_modal_action';
import {PAP_Shown_Modal} from 'pap-events/replay/shown_modal';
import {useIntl} from 'react-intl';

import type {reel} from '@dropbox/api-v2-client';

import {DownloadOptionsModal} from '~/components/download/download_options_modal';
import {useErrorSnackbar} from '~/components/snackbar/error_snackbar';
import {
  getFolderDownloadLink,
  instanceOfFolderNotFound,
  instanceOfNoFilesFound,
  instanceOfTooManyFiles,
  instanceOfTooMuchData,
} from '~/lib/api';
import {download} from '~/lib/download';
import {useLoggingClient} from '~/lib/use_logging_client';

import {downloadErrorStrings} from './strings';

type AnalyticsData = {
  accessType?: AccessType;
  actionElement?: 'toolbar' | 'action_bar' | 'overflow_menu';
};

export type DownloadFolderModalProps = {
  onModalClose: () => void;
  folderId: string;
  shareToken?: string;
  downloadOptions: reel.DownloadOption[];
  modalOpen: boolean;
  analyticsData?: AnalyticsData;
  grantBook?: string;
};

export const DownloadFolderModal: React.FC<DownloadFolderModalProps> = ({
  onModalClose,
  folderId,
  shareToken,
  downloadOptions,
  modalOpen,
  analyticsData,
  grantBook,
}: DownloadFolderModalProps) => {
  const intl = useIntl();
  const logger = useLoggingClient();
  const {openErrorSnackbar} = useErrorSnackbar();

  // Log Modal Shown event
  React.useEffect(() => {
    if (modalOpen) {
      logger.logPap(
        PAP_Shown_Modal({
          accessType: analyticsData?.accessType,
          modal: 'download',
          actionElement: analyticsData?.actionElement,
        }),
      );
    }
  }, [analyticsData?.accessType, analyticsData?.actionElement, logger, modalOpen]);

  const onModalSubmit = React.useCallback(
    (option: reel.DownloadOption) => {
      onModalClose();

      logger.logPap(
        PAP_Select_ModalAction({
          deviceId: logger.deviceId,
          accessType: analyticsData?.accessType,
          modal: 'download',
          modalAction: 'download',
          actionDetail: option['.tag'] !== 'other' ? option['.tag'] : undefined,
        }),
      );
      getFolderDownloadLink({
        folderId: folderId,
        downloadOption: option,
        shareToken: shareToken,
        grantBook: grantBook,
        analyticsData: {
          papAccessType: analyticsData?.accessType,
          logger: logger,
        },
      })
        .then(({url}) => {
          download({url: url});
        })
        .catch((e) => {
          let message = intl.formatMessage(downloadErrorStrings.otherError);
          if (e?.error?.error && typeof e.error.error === 'object') {
            const error: Object = e.error.error;
            if (instanceOfNoFilesFound(error) || instanceOfFolderNotFound(error)) {
              message = intl.formatMessage(downloadErrorStrings.noFilesFoundError);
            } else if (instanceOfTooMuchData(error)) {
              message = intl.formatMessage(downloadErrorStrings.tooMuchDataError);
            } else if (instanceOfTooManyFiles(error)) {
              message = intl.formatMessage(downloadErrorStrings.tooManyFilesError);
            }
          }
          openErrorSnackbar(message);
        });
    },
    [
      analyticsData?.accessType,
      folderId,
      intl,
      logger,
      onModalClose,
      openErrorSnackbar,
      shareToken,
      grantBook,
    ],
  );

  const closeModal = React.useCallback(() => {
    logger.logPap(
      PAP_Select_ModalAction({
        deviceId: logger.deviceId,
        accessType: analyticsData?.accessType,
        modal: 'download',
        modalAction: 'cancel',
      }),
    );
    onModalClose();
  }, [analyticsData?.accessType, logger, onModalClose]);

  return (
    <DownloadOptionsModal
      downloadOptions={downloadOptions}
      downloadType="folder"
      onClose={closeModal}
      onSubmit={onModalSubmit}
      open={modalOpen}
    />
  );
};
