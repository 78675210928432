import type {reel} from '@dropbox/api-v2-client';

import type {InitialVideo, MediaType} from '~/lib/api';
import {convertReelMediaTypeToMediaType} from '~/lib/api';
import type {BrowseFolder, BrowseProject} from '~/pages/browse_page/components/common';
import {findFolderCreationTime} from '~/pages/browse_page/components/common';
import {
  findFolderLastModifiedTime,
  findMediaProjectLatestUpdateTime,
  isFullVideo,
} from '~/pages/browse_page/components/common';
import type {SortAttr, SortDirection} from '~/pages/browse_page/types';

import type {BrowseTeamInfoItem} from '../admin_console_page/common';

export const innerSortFolders = <T extends BrowseFolder>(
  folders: T[],
  newSortOrder: SortDirection,
  newSortAttr: SortAttr,
) =>
  Array.from(folders).sort((a, b) => {
    if (!a || !b) {
      return 0;
    }
    const firstName = a.name;
    const secondName = b.name;
    const firstCreationTimestamp = findFolderCreationTime(a);
    const secondCreationTimestamp = findFolderCreationTime(b);
    const firstLatestUpdateTime = findFolderLastModifiedTime(a);
    const secondLatestUpdateTime = findFolderLastModifiedTime(b);

    const multiplyAscendingResult = (num: number) =>
      newSortOrder === 'descending' ? num * -1 : num;

    switch (newSortAttr) {
      case 'name': {
        const result =
          compareStrings(firstName, secondName) ||
          compareDates(firstLatestUpdateTime, secondLatestUpdateTime);
        return multiplyAscendingResult(result);
      }
      case 'updated': {
        const result =
          compareDates(firstLatestUpdateTime, secondLatestUpdateTime) ||
          compareStrings(firstName, secondName);
        return multiplyAscendingResult(result);
      }
      case 'created': {
        const firstCreationTime = firstCreationTimestamp
          ? new Date(firstCreationTimestamp)
          : undefined;
        const secondCreationTime = secondCreationTimestamp
          ? new Date(secondCreationTimestamp)
          : undefined;
        const result =
          compareDates(firstCreationTime, secondCreationTime) ||
          compareStrings(firstName, secondName);
        return multiplyAscendingResult(result);
      }
      default: {
        return 0;
      }
    }
  });

const getMostRecentMediaProjectMediaType = (
  videos?: reel.Video[] | InitialVideo[],
): MediaType | undefined => {
  if (videos === undefined || videos.length === 0) {
    return undefined;
  }

  const finalMediaProject = videos[videos.length - 1];

  if (isFullVideo(finalMediaProject) && finalMediaProject.media_type) {
    return convertReelMediaTypeToMediaType(finalMediaProject.media_type);
  }

  return undefined;
};

const getMostRecentLastSeenByUserAt = (videos?: reel.Video[] | InitialVideo[]) => {
  if (videos === undefined || videos.length === 0) {
    return undefined;
  }

  const finalMediaProject = videos[videos.length - 1];

  if (isFullVideo(finalMediaProject)) {
    return finalMediaProject.last_seen_by_user_at;
  }

  return undefined;
};

const getMostRecentNumComments = (videos?: reel.Video[] | InitialVideo[]): number => {
  if (videos === undefined) {
    return 0;
  }

  const finalMediaProject = videos[videos.length - 1];

  if (isFullVideo(finalMediaProject)) {
    return finalMediaProject.comments_info?.num_comments || 0;
  }

  return 0;
};

const getSortProperties = (
  projectWithVideo: BrowseProject,
): {
  name?: string;
  mediaType?: MediaType;
  creationTimestamp?: string;
  numberOfComments: number;
  status?: number;
  viewed?: number | string | undefined;
} => {
  return {
    name: projectWithVideo.project?.name,
    mediaType: projectWithVideo.isUploading
      ? undefined
      : getMostRecentMediaProjectMediaType(projectWithVideo.videos),
    creationTimestamp: projectWithVideo.isUploading
      ? new Date().toUTCString()
      : projectWithVideo.project?.creation_timestamp,
    numberOfComments: projectWithVideo.isUploading
      ? 0
      : getMostRecentNumComments(projectWithVideo.videos),
    status: projectWithVideo.isUploading ? 0 : projectWithVideo.videos?.[0].status,
    viewed: projectWithVideo.isUploading
      ? 0
      : getMostRecentLastSeenByUserAt(projectWithVideo.videos),
  };
};

export const innerSortTeamInfoItems = (
  folders: BrowseTeamInfoItem[],
  newSortOrder: SortDirection,
  newSortAttr: SortAttr,
) =>
  Array.from(folders).sort((a, b) => {
    const firstName = a.folderName;
    const secondName = b.folderName;
    const firstCreationTimestamp = a.createdTime ?? new Date().toUTCString();
    const secondCreationTimestamp = b.createdTime ?? new Date().toUTCString();
    const multiplyAscendingResult = (num: number) =>
      newSortOrder === 'descending' ? num * -1 : num;

    switch (newSortAttr) {
      case 'name': {
        return multiplyAscendingResult(
          (firstName?.toLowerCase() ?? '') <= (secondName?.toLowerCase() ?? '') ? -1 : 1,
        );
      }
      // Todo: replace with a findLatestUpdateTime fn for folders
      case 'updated': {
        return multiplyAscendingResult(
          (firstCreationTimestamp ?? '') <= (secondCreationTimestamp ?? '') ? -1 : 1,
        );
      }
      case 'created': {
        return multiplyAscendingResult(
          (firstCreationTimestamp ?? '') <= (secondCreationTimestamp ?? '') ? -1 : 1,
        );
      }
      default: {
        return 0;
      }
    }
  });

const compareStrings = (
  first: string | null | undefined,
  second: string | null | undefined,
): number => {
  const firstName = first ?? '';
  const secondName = second ?? '';
  if (firstName < secondName) return -1;
  if (firstName > secondName) return 1;
  return 0;
};

const compareDates = (first: Date | undefined, second: Date | undefined): number => {
  return +(first ?? 0) - +(second ?? 0);
};

export const innerSortProjects = <T extends BrowseProject>(
  projects: T[],
  newSortOrder: SortDirection,
  newSortAttr: SortAttr,
) =>
  Array.from(projects).sort((a, b) => {
    const {name: firstName, creationTimestamp: firstCreationTimestamp} = getSortProperties(a);
    const {name: secondName, creationTimestamp: secondCreationTimestamp} = getSortProperties(b);
    const firstLatestUpdateTime = findMediaProjectLatestUpdateTime(a);
    const secondLatestUpdateTime = findMediaProjectLatestUpdateTime(b);

    const multiplyAscendingResult = (num: number) =>
      newSortOrder === 'descending' ? num * -1 : num;

    switch (newSortAttr) {
      case 'name': {
        const result =
          compareStrings(firstName, secondName) ||
          compareDates(firstLatestUpdateTime, secondLatestUpdateTime);
        return multiplyAscendingResult(result);
      }
      case 'updated': {
        const result =
          compareDates(firstLatestUpdateTime, secondLatestUpdateTime) ||
          compareStrings(firstName, secondName);
        return multiplyAscendingResult(result);
      }
      case 'created': {
        const firstCreationTime = firstCreationTimestamp
          ? new Date(firstCreationTimestamp)
          : undefined;
        const secondCreationTime = secondCreationTimestamp
          ? new Date(secondCreationTimestamp)
          : undefined;
        const result =
          compareDates(firstCreationTime, secondCreationTime) ||
          compareStrings(firstName, secondName);
        return multiplyAscendingResult(result);
      }
      default: {
        return 0;
      }
    }
  });
