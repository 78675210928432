import {useMemo} from 'react';

import {useReelAppGlobalState} from '~/context';
import {LoggingClient} from '~/lib/logging/logger';
import {getUserVariant} from '~/lib/provisions';
import {useActionSurface} from '~/lib/use_action_surface';

import {useStormcrows} from './stormcrow';

type LoggingOptions = {
  surface: Parameters<LoggingClient['setSurface']>[0];
  location: Parameters<LoggingClient['setLocation']>[0];
  activeVideoId: Parameters<LoggingClient['setActiveVideoId']>[0];
  isLiveReview: Parameters<LoggingClient['setIsLiveReview']>[0];
  reelObjectType: Parameters<LoggingClient['setReelObjectType']>[0];
};

export const useLoggingClient = (props?: Partial<LoggingOptions>) => {
  const sessionContext = useReelAppGlobalState();
  const getActionSurface = useActionSurface();
  const stormcrows = useStormcrows();
  const provisionTier =
    sessionContext.status === 'logged in' ? sessionContext.provisions.provision_tier : undefined;
  const provisionUserVariant =
    sessionContext.status === 'logged in' ? getUserVariant(sessionContext) : undefined;

  return useMemo(() => {
    const logger = new LoggingClient(stormcrows, provisionTier, provisionUserVariant);
    logger.setSurface(props?.surface ?? getActionSurface());

    if (props?.location) {
      logger.setLocation(props.location);
    }
    if (props?.activeVideoId) {
      logger.setActiveVideoId(props.activeVideoId);
    }
    if (props?.isLiveReview) {
      logger.setIsLiveReview(props.isLiveReview);
    }
    if (props?.reelObjectType) {
      logger.setReelObjectType(props.reelObjectType);
    }
    return logger;
  }, [
    stormcrows,
    provisionTier,
    provisionUserVariant,
    getActionSurface,
    props?.surface,
    props?.location,
    props?.activeVideoId,
    props?.isLiveReview,
    props?.reelObjectType,
  ]);
};
