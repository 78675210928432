import React from 'react';

import {UIIcon} from '@dropbox/dig-icons';
import {CircleSmallFilledFill} from '@dropbox/dig-icons/assets';

import {LottieAnimation, LottieAnimationType} from '~/components/lottie_animation';
import {color} from '~/components/styled';

import {VERSION_STATUS_PROPERTIES, type VersionStatus} from '../status';

export const StatusIcon = ({
  status,
  introEffect,
  effect = 'once',
  style,
}: {
  status?: VersionStatus;
  introEffect?: AnimationEffect;
  effect?: AnimationEffect;
  style?: React.CSSProperties;
}) => {
  const [initialized, setInitialized] = React.useState(false);

  const handleIntroEnd = React.useCallback(() => {
    setInitialized(true);
  }, []);

  const statusColor = !status
    ? color('Text Base')
    : status === 'NO_STATUS'
    ? color('Disabled Base')
    : VERSION_STATUS_PROPERTIES[status].color;

  const fallback = <UIIcon color={statusColor} size="standard" src={CircleSmallFilledFill} />;
  const props = {
    effect: initialized || !introEffect ? effect : introEffect,
    fallback,
    onComplete: handleIntroEnd,
    style,
  };

  switch (status) {
    case 'NEEDS_REVIEW':
      return <NeedsReviewStatusIcon {...props} />;
    case 'IN_PROGRESS':
      return <InProgressStatusIcon {...props} />;
    case 'APPROVED':
      return <ApprovedStatusIcon {...props} />;
    case 'EDITS_REQUESTED':
      return <EditsRequestedStatusIcon {...props} />;
    default:
      return fallback;
  }
};

type AnimationEffect = 'intro' | 'loop' | 'once' | 'stable';

interface BaseAnimatedStatusIconProps {
  effect: AnimationEffect;
  fallback?: React.ReactNode;
  onComplete?: () => void;
  style?: React.CSSProperties;
}

interface AnimatedStatusIconProps extends BaseAnimatedStatusIconProps {
  type: LottieAnimationType;
  effects: {
    [key in AnimationEffect]: {range: [start: number, end: number]; reps: number | boolean};
  };
}

const AnimatedStatusIcon = ({
  type,
  effect = 'stable',
  effects,
  fallback,
  onComplete,
  style,
}: AnimatedStatusIconProps) => {
  const {range, reps} = effects[effect];
  const iconStyle = {width: 24, height: 24, marginLeft: -4, marginRight: -4, ...style};
  return (
    <LottieAnimation
      animationType={type}
      autoplay
      fallback={fallback}
      initialSegment={range}
      loop={reps}
      onComplete={onComplete}
      style={iconStyle}
    />
  );
};

const NeedsReviewStatusIcon = (props: BaseAnimatedStatusIconProps) => {
  const effects = {
    intro: {range: [0, 15] as [number, number], reps: false},
    loop: {range: [10, 149] as [number, number], reps: true},
    once: {range: [10, 149] as [number, number], reps: false},
    stable: {range: [215, 349] as [number, number], reps: false},
  };
  return (
    <AnimatedStatusIcon
      effects={effects}
      type={LottieAnimationType.STATUS_NEEDS_REVIEW}
      {...props}
    />
  );
};

const InProgressStatusIcon = (props: BaseAnimatedStatusIconProps) => {
  const effects = {
    intro: {range: [0, 15] as [number, number], reps: false},
    loop: {range: [10, 264] as [number, number], reps: true},
    once: {range: [10, 264] as [number, number], reps: false},
    stable: {range: [330, 579] as [number, number], reps: false},
  };
  return (
    <AnimatedStatusIcon
      effects={effects}
      type={LottieAnimationType.STATUS_IN_PROGRESS}
      {...props}
    />
  );
};

const EditsRequestedStatusIcon = (props: BaseAnimatedStatusIconProps) => {
  const effects = {
    intro: {range: [0, 15] as [number, number], reps: false},
    loop: {range: [10, 254] as [number, number], reps: true},
    once: {range: [10, 254] as [number, number], reps: false},
    stable: {range: [320, 559] as [number, number], reps: false},
  };
  return (
    <AnimatedStatusIcon
      effects={effects}
      type={LottieAnimationType.STATUS_EDITS_REQUESTED}
      {...props}
    />
  );
};

const ApprovedStatusIcon = (props: BaseAnimatedStatusIconProps) => {
  const effects = {
    intro: {range: [0, 15] as [number, number], reps: false},
    loop: {range: [10, 134] as [number, number], reps: true},
    once: {range: [10, 134] as [number, number], reps: false},
    stable: {range: [200, 319] as [number, number], reps: false},
  };
  return (
    <AnimatedStatusIcon effects={effects} type={LottieAnimationType.STATUS_APPROVED} {...props} />
  );
};
