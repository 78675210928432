import * as Sentry from '@sentry/react';

import {DBX_BUILD_MODE} from '~/context_utils';

export type Severity = 'critical' | 'non-critical' | 'operational' | 'user-error' | 'uncaught';

export enum ReplayErrorCategory {
  UncaughtException = 'uncaught_exception',
  NetworkRequestFailure = 'request_failure',
  InBrowserFailure = 'inbrowser_failure',
  TranslationError = 'translation_error',
  BadUrlError = 'bad_url_error',
  BadContextUseError = 'bad_react_context_use_error',
  FailedDataFetch = 'failed_data_fetch',
  FailedAuth = 'failed_auth',
  GDriveImports = 'gdrive_import_error',
  OneDriveImports = 'onedrive_import_error',
  RedCarpetError = 'red_carpet_error',
  BadDataError = 'bad_data_error',
  BadMetricsMeasurement = 'bad_metrics_measurement',
  GrowthBookInitError = 'growthbook_init_error',
  Upload = 'upload_error',
}

export type ReplayErrorProps = {
  category: ReplayErrorCategory;
  severity: Severity;
  // WARNING: All Categories of Errors allow unfiltered message/name text to be sent to sentry.
  // Please make sure that no L0 data is included in your error messages. (URLs, IDs etc.)
  source?: string;
  message?: string;
  error?: Error;
  tags?: string[];
  stack?: string;
};

export class ReplayError {
  severity: Severity;
  category: ReplayErrorCategory;
  source: string;
  tags?: string[];
  message: string;
  stack?: string;

  constructor({severity, category, source, tags, error, message, stack}: ReplayErrorProps) {
    this.severity = severity;
    this.category = category;
    this.source = source ?? 'UnknownSource';
    this.tags = tags;
    this.message = message
      ? message
      : error
      ? `${error.name} - ${error.message}`
      : '<No message specified>';
    this.stack = stack ? stack : error ? error.stack : undefined;
  }

  toError(): Error {
    return {
      name: this.message,
      message: `[${this.source}]: ${this.category} - ${this.message}`,
      stack: this.stack,
    };
  }
}

const mapSeverity = (sev: Severity): 'info' | 'warning' | 'error' => {
  if (sev === 'operational' || sev === 'user-error') {
    return 'info';
  }
  if (sev === 'non-critical') {
    return 'warning';
  }
  return 'error';
};

const isTestEnv = import.meta.env.MODE === 'test';

export const reportException = (err: ReplayError) => {
  if (isTestEnv) return;

  err.tags = err.tags ? [err.category, ...err.tags] : [err.category];

  if (DBX_BUILD_MODE == 'local') {
    console.error(`Error would be submitted to sentry in prod/stage: ${JSON.stringify(err)}`);
  } else {
    Sentry.withScope(function (scope) {
      scope.setLevel(mapSeverity(err.severity));
      err.tags?.forEach((tag) => {
        scope.setTag(tag, 'true');
      });

      if (err.severity === 'critical') {
        scope.setTag('unhandled', 'true');
      }

      const error = new Error(`[${err.source}]: ${err.category} - ${err.message}`);
      error.name = err.message;
      error.stack = err.stack;
      Sentry.captureException(error);
    });
  }
};

export const reportBadContextUseError = (error: Error) => {
  reportException(
    new ReplayError({
      severity: 'critical',
      category: ReplayErrorCategory.BadContextUseError,
      error,
    }),
  );
};

export const redCarpetError = (error: Error, tags?: string[]) => {
  reportException(
    new ReplayError({
      severity: 'critical',
      category: ReplayErrorCategory.RedCarpetError,
      error,
      tags,
    }),
  );
};

type ApiErrorSource =
  | 'ListFolder'
  | 'GetReelLink'
  | 'GetWithSharedLink'
  | 'UpdateLinkPassword'
  | 'UpdateLinkDownloadSetting'
  | 'RemoveLinkPassword'
  | 'GenerateCCPAEmailToken'
  | 'GetAssistance'
  | 'ListSharedLinks'
  | 'ListSharedLinksThumbnails'
  | 'UpdateSharedLinks'
  | 'GetSuggestedItemsPreviewData'
  | 'GetTasksForItem'
  | 'CreateTask'
  | 'UpdateTask'
  | 'GetTranscription'
  | 'CreateFolderShareLink'
  | 'CreateFileShareLink'
  | 'CreateWatermarkLinksBatch'
  | 'GetWaffle'
  | 'AddMetric';

export const reportFailedDataFetch = (
  err: Error,
  source: ApiErrorSource,
  severity: Severity = 'critical',
) => {
  reportException(
    new ReplayError({
      severity,
      category: ReplayErrorCategory.FailedDataFetch,
      source: source,
      error: err,
    }),
  );
};

export const reportGDriveError = (err: Error) => {
  reportException(
    new ReplayError({
      severity: 'non-critical',
      category: ReplayErrorCategory.GDriveImports,
      error: err,
    }),
  );
};

export const reportOneDriveError = (err: Error) => {
  reportException(
    new ReplayError({
      severity: 'non-critical',
      category: ReplayErrorCategory.OneDriveImports,
      error: err,
    }),
  );
};
