// Copied from metaserver

import {DBX_BUILD_MODE} from '~/context_utils';

import {LOCALHOST_CORS_PROXY} from './utils';

//
// NOTE This file was converted from a Coffeescript file.
//

/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// NOTE: This library is used by Chime that cannot depend on anything in metaserver.
//       Make sure to keep the dependency list empty. Any dependencies must be
//       explicitly passed into the constructor of the client.

// We use an exponential backoff strategy. The bolt client will keep a time interval t,
// which starts at BACKOFF_BASE_MS and doubles on each successive error until it reaches
// BACKOFF_CAP_MS. The actual time spent sleeping is uniformly drawn from [0, t), meaning
// that the expected sleep is actually t/2.
const BACKOFF_BASE_MS = 1000;
const BACKOFF_CAP_MS = 300000;

const NOTIFICATION_SUBSCRIBE_ENDPOINT = '/2/notify/subscribe';
const PAYLOAD_SUBSCRIBE_ENDPOINT = '/2/payloads/subscribe';

// Set to false for Replay
const CORS_WITH_CREDENTIALS = false;

class ChannelId {
  app_id: any;
  unique_id: any;
  // A ChannelId identifies a particular channel, which comprises an app id and a unique id.
  // It is passed to the application code in some callbacks, but should not need to be generated
  constructor(app_id: any, unique_id: any) {
    this.app_id = app_id;
    this.unique_id = unique_id;
  }
}

export class SignedChannelState {
  app_id: string;
  unique_id: string;
  revision: string;
  token: string;
  // A SignedChannelState represents a particular revision on a particular channel. A channel
  // comprises an app id, and a unique id that represents a logical channel within the
  // application. The app id is used as a namespacing mechanism for Bolt. Examples of channels:
  //   - (app id: "file_activity", unique id: FileId of a file to watch updates for)
  //   - (app id: "sfj_ping", unique id: nsid to watch updates for)
  //   - (app id: "user_notification", unique id: user id to watch updates for)
  // The revision is a string encoding a monotonically increasing integer representing the
  // latest known state of the channel. When used to subscribe for notifications, acts as
  // a starting point for the channel so that only notifications with a revision greater than
  // the starting point are returned to the application.
  constructor(app_id: string, unique_id: string, revision: string, token: string) {
    this.app_id = app_id;
    this.unique_id = unique_id;
    this.revision = revision;
    this.token = token;
  }
}

class Payload {
  revision: any;
  payload: any;
  // A Payload represents specific payload received from application server.
  // `revision` is a string encoding a monotonically increasing integer representing the
  // revision of this payload.
  // `payload` is payload object.
  constructor(revision: any, payload: any) {
    this.revision = revision;
    this.payload = payload;
  }
}

class ChannelPayloads {
  channel_state: any;
  payloads: any;
  // ChannelPayloads represents single update response from Thunder.
  // `channel_state` is SignedChannelState, its revision is the same as revision of the latest
  // returned payload.
  // `payloads` is a list of Payload objects needed to update client's state to latest known
  // revision.
  constructor(channel_state: any, payloads: any) {
    this.channel_state = channel_state;
    this.payloads = payloads;
  }
}

interface EncodedSignedChannelState {
  channel_id: ChannelId;
  revision: string;
  token: string;
}

const stringifyChannelId = (channel_id: ChannelId): string => JSON.stringify(channel_id);

type Lodash = {
  defer(func: (...args: any[]) => any, ...args: any[]): number;
};

class BaseClient {
  _update_callback: (states: SignedChannelState[]) => void;
  _refresh_callback: (states: SignedChannelState[]) => void;
  _hostname: any;
  _lodash: Lodash;
  _exclog: any;
  _signed_channel_states: {
    [combined_id: string]: EncodedSignedChannelState;
  };
  _signed_channel_states_keys: string[];
  _started: boolean;
  _sequence_num: number;
  _backoff_window: any;
  _additional_headers: {[header: string]: string};
  _timeout_id: any;
  _long_poll_xhr: any;

  _encode_channel_state(signed_channel_state: SignedChannelState): EncodedSignedChannelState {
    // Encodes a SignedChannelState to a format that Bolt understands.
    return {
      channel_id: {
        app_id: signed_channel_state.app_id,
        unique_id: signed_channel_state.unique_id,
      },
      revision: signed_channel_state.revision,
      token: signed_channel_state.token,
    };
  }

  _decode_channel_state(signed_channel_state: any): SignedChannelState {
    // Decodes a Bolt-formatted channel state to a SignedChannelState.
    return new SignedChannelState(
      signed_channel_state.channel_id.app_id,
      signed_channel_state.channel_id.unique_id,
      signed_channel_state.revision,
      signed_channel_state.token,
    );
  }

  _decode_channel_id(channel_id: any): ChannelId {
    return new ChannelId(channel_id.app_id, channel_id.unique_id);
  }

  _compare_revisions(rev1: any, rev2: any) {
    // Compares two string-encoded revisions
    // Returns -1 if rev1 < rev2, 0 if rev1 = rev2, 1 if rev1 > rev2.
    const padded_length = Math.max(rev1.length, rev2.length);
    const padded_rev1 = Array(padded_length - rev1.length + 1).join('0') + rev1;
    const padded_rev2 = Array(padded_length - rev2.length + 1).join('0') + rev2;
    if (padded_rev1 < padded_rev2) {
      return -1;
    } else if (padded_rev1 > padded_rev2) {
      return 1;
    } else {
      return 0;
    }
  }

  _find_state(channel_id: ChannelId): EncodedSignedChannelState {
    // Returns the ChannelState with the given channel id in the current subscription.
    return this._signed_channel_states[stringifyChannelId(channel_id)];
  }

  // BaseClient is base class for BoltClient and ThunderClient which implements
  // long polling, state update and backoff on errors.
  //
  // See also BoltClient and ThunderClient.
  constructor(
    signed_channel_states: SignedChannelState[],
    _update_callback: (states: SignedChannelState[]) => void,
    _refresh_callback: (states: SignedChannelState[]) => void,
    _hostname: string,
    _lodash: Lodash,
    _exclog: any = null,
  ) {
    this._find_state = this._find_state.bind(this);
    this._long_poll = this._long_poll.bind(this);
    this._must_find_state = this._must_find_state.bind(this);
    this._handle_poll_success = this._handle_poll_success.bind(this);
    this._handle_poll_error = this._handle_poll_error.bind(this);
    this._update_callback = _update_callback;
    this._refresh_callback = _refresh_callback;
    this._hostname = _hostname;
    this._lodash = _lodash;
    this._exclog = _exclog;
    this._signed_channel_states = {};
    this._signed_channel_states_keys = [];
    this._started = false;
    this._sequence_num = 0;
    this._backoff_window = BACKOFF_BASE_MS;
    this._additional_headers = {};
    this._timeout_id = null;

    this.update_states(signed_channel_states);
  }

  // Update client's revision by information from another sources, i.e. from AppServer.
  update_states(signed_channel_states: SignedChannelState[]): void {
    // Update the subscription state by information from other sources.
    for (const newstate of signed_channel_states) {
      const encoded_newstate = this._encode_channel_state(newstate);
      // Look for the channel id in the current subscription.
      const matched_state = this._find_state(encoded_newstate.channel_id);

      // Sanity checks.
      if (matched_state == null) {
        // Add new state.
        const key = stringifyChannelId(encoded_newstate.channel_id);
        this._signed_channel_states[key] = encoded_newstate;
        this._signed_channel_states_keys.push(key);
        // We use >= 0 because we want to replace the token even if there isn't a new revision.
        // Otherwise it's impossible to refresh the token if the channel hasn't changed.
      } else if (this._compare_revisions(encoded_newstate.revision, matched_state.revision) >= 0) {
        // Bump the revision.
        matched_state.revision = encoded_newstate.revision;
        matched_state.token = encoded_newstate.token;
      }
    }
  }

  // Call this function on an instantiated client to start listening for updates.
  // If called more than once without a preceding unsubscribe(), does nothing.
  start(): void {
    if (this._started) {
      return;
    }
    this._started = true;
    this._long_poll();
  }

  unsubscribe(): void {
    this._started = false;
    if (this._long_poll_xhr != null) {
      this._long_poll_xhr.abort();
    }
    this._long_poll_xhr = null;
    window.clearTimeout(this._timeout_id);
    this._timeout_id = null;
  }

  _long_poll() {
    this._sequence_num++;
    const sequence_num = this._sequence_num;

    const request = new XMLHttpRequest();
    request.open('POST', this._subscribe_url(), true);
    request.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
    Object.keys(this._additional_headers).forEach((header) => {
      request.setRequestHeader(header, this._additional_headers[header]);
    });

    // Need a timeout in case the connection is closed while the machine is suspended.
    request.timeout = 120000;
    request.withCredentials = CORS_WITH_CREDENTIALS;
    request.responseType = 'json';

    const onSuccess = () => {
      const {status} = request;
      // Status check from dbx.link/12lk3jzxc
      if ((status >= 200 && status < 300) || status === 304) {
        this._handle_poll_success(sequence_num, request);
      } else {
        this._handle_poll_error(sequence_num);
      }
    };
    const onError = () => this._handle_poll_error(sequence_num);
    request.addEventListener('load', onSuccess);
    request.addEventListener('timeout', onError);
    request.addEventListener('error', onError);
    request.addEventListener('abort', onError);

    request.send(
      JSON.stringify({
        channel_states: this._signed_channel_states_keys.map((k) => this._signed_channel_states[k]),
      }),
    );
  }

  _must_find_state(channel_id: any) {
    // Find channel state and assert that it's not null.
    const matched_state = this._find_state(channel_id);

    // Sanity checks.
    if (matched_state == null) {
      const assert_msg = `Bolt returned unknown channel id ${channel_id}`;
      if (this._exclog != null) {
        this._exclog.reportStack(assert_msg);
      }
    }

    return matched_state;
  }

  /* tslint:disable-next-line:no-any */
  _handle_poll_data(data: any, request: XMLHttpRequest): any {
    const assert_msg = 'Method must be implemented.';
    return this._exclog != null ? this._exclog.reportStack(assert_msg) : undefined;
  }

  _subscribe_url() {
    const boltSubscribeURL = `https://${this._hostname}${this._subscribe_endpoint()}`;
    // When running from Webpack dev server use the Replay Fastify proxy.
    // We currently don't support subscriptions for Amplify PR previews, e.g. pr-123.d1c9l4azigno77.amplifyapp.com
    const subscribeURL =
      DBX_BUILD_MODE === 'local'
        ? LOCALHOST_CORS_PROXY + `/proxy/${btoa(boltSubscribeURL)}`
        : boltSubscribeURL;
    return subscribeURL;
  }

  _subscribe_endpoint() {
    const assert_msg = 'Method must be implemented.';
    return this._exclog != null ? this._exclog.reportStack(assert_msg) : undefined;
  }

  _handle_poll_success(sequence_num: number, request: XMLHttpRequest) {
    if (sequence_num !== this._sequence_num) {
      return;
    }
    this._long_poll_xhr = null;
    if (!this._started) {
      return;
    }

    const data = request.response;
    const updates = this._handle_poll_data(data, request);
    if (updates.length > 0) {
      this._lodash.defer(this._update_callback, updates);
    }

    if ((data.invalid_channels != null ? data.invalid_channels.length : undefined) > 0) {
      this._lodash.defer(
        this._refresh_callback,
        Array.from(data.invalid_channels).map((channel_id: any) =>
          this._decode_channel_id(channel_id),
        ),
      );
      // Don't continue with long poll; the application should create a new client once
      // it has refreshed its state.
      return;
    }

    this._backoff_window = BACKOFF_BASE_MS;
    this._timeout_id = window.setTimeout(this._long_poll, 0); // re-poll
  }

  _handle_poll_error(sequence_num: number) {
    if (sequence_num !== this._sequence_num) {
      return;
    }
    this._long_poll_xhr = null;
    if (!this._started) {
      return;
    }
    // Retry poll after a random duration in [0, @_backoff_window).
    const backoff_duration = Math.random() * this._backoff_window;
    this._backoff_window = Math.min(2 * this._backoff_window, BACKOFF_CAP_MS);
    this._timeout_id = window.setTimeout(this._long_poll, backoff_duration);
  }
}

class BoltClient extends BaseClient {
  // An instance of BoltClient can be used to subscribe for notifications to a set of channels.
  //
  // channel_states: An array of SignedChannelState used as the starting point for notifications.
  // update_callback: Function that is invoked when a new revision is available on one or more of
  //   the channels being watched. The function is passed an array of ChannelState representing
  //   the latest revisions on the updated channels.
  // refresh_callback: Function that is invoked to signal that that the client should refresh
  //   its state for the specified channels with the application server, then subscribe with the
  //   resulting data.
  //   hostname: Bolt host to connect to, e.g. "bolt.dropbox.com".
  //   lodash: Lodash module.
  //   exclog: Implements a `reportStack` method to report an exception.
  constructor(
    signed_channel_states: SignedChannelState[],
    update_callback: (states: SignedChannelState[]) => void,
    refresh_callback: (states: SignedChannelState[]) => void,
    hostname: string,
    lodash: any,
    exclog: any = null,
  ) {
    super(signed_channel_states, update_callback, refresh_callback, hostname, lodash, exclog);
    this._handle_poll_data = this._handle_poll_data.bind(this);
  }

  _subscribe_endpoint() {
    return NOTIFICATION_SUBSCRIBE_ENDPOINT;
  }

  /* tslint:disable-next-line:no-any */
  _handle_poll_data(data: any, request: XMLHttpRequest): SignedChannelState[] {
    const updates: SignedChannelState[] = [];

    // Update the subscription state for the next poll.
    if (data.channel_states != null) {
      for (const newstate of Array.from(data.channel_states as any[])) {
        const matched_state = this._must_find_state(newstate.channel_id);
        if (this._compare_revisions(newstate.revision, matched_state.revision) > 0) {
          // Bump the revision.
          matched_state.revision = newstate.revision;
          matched_state.token = newstate.token;
          updates.push(this._decode_channel_state(newstate));
        }
      }
    }

    return updates;
  }
}

const SESSION_HEADER = 'X-Bolt-Session';
class ThunderClient extends BaseClient {
  // An instance of ThunderClient can be used to subscribe for payloads to a set of channels.
  //
  // channel_states: An array of SignedChannelState used as the starting point for notifications.
  // update_callback: Function that is invoked when a new payloads are available on one or more of
  //   the channels being watched. The function is passed an array of ChannelPayloads representing
  //   the latest revisions on the updated channels with list of corresponding payloads.
  // refresh_callback: Function that is invoked to signal that that the client should refresh
  //   its state for the specified channels with the application server, then subscribe with the
  //   resulting data.
  //   hostname: Thunder host to connect to, e.g. "thunder.dropbox.com".
  //   lodash: Lodash module.
  //   exclog: Implements a `reportStack` method to report an exception.
  constructor(
    signed_channel_states: SignedChannelState[],
    update_callback: (states: SignedChannelState[]) => void,
    refresh_callback: (states: SignedChannelState[]) => void,
    hostname: any,
    lodash: any,
    exclog: any = null,
  ) {
    super(signed_channel_states, update_callback, refresh_callback, hostname, lodash, exclog);
    this._handle_poll_data = this._handle_poll_data.bind(this);
  }

  unsubscribe() {
    delete this._additional_headers[SESSION_HEADER];
    return super.unsubscribe();
  }

  _subscribe_endpoint(): string {
    return PAYLOAD_SUBSCRIBE_ENDPOINT;
  }

  /* tslint:disable-next-line:no-any */
  _handle_poll_data(data: any, request: XMLHttpRequest): ChannelPayloads[] {
    const updates: ChannelPayloads[] = [];
    this._additional_headers = {};

    // Always response by the same session header.
    const sessionHeader = request.getResponseHeader(SESSION_HEADER);
    if (sessionHeader) {
      this._additional_headers[SESSION_HEADER] = sessionHeader;
    }

    // Update the subscription state for the next poll and collect payloads.
    if (data.channel_payloads != null) {
      for (const channel_payloads of Array.from(data.channel_payloads as any[])) {
        const newstate = channel_payloads.channel_state;
        const matched_state = this._must_find_state(newstate.channel_id);

        const channel_state = this._decode_channel_state(channel_payloads.channel_state);
        const payloads: any[] = [];
        for (const payload of Array.from(channel_payloads.payloads as any[])) {
          // Skip payloads client is already aware of.
          if (this._compare_revisions(payload.revision, matched_state.revision) > 0) {
            payloads.push(new Payload(payload.revision, payload.payload));
          }
        }

        if (payloads.length > 0) {
          updates.push(new ChannelPayloads(channel_state, payloads));
        }

        if (this._compare_revisions(newstate.revision, matched_state.revision) > 0) {
          // Bump the revision.
          matched_state.revision = newstate.revision;
          matched_state.token = newstate.token;
        }
      }
    }

    return updates;
  }
}

export {BoltClient, ThunderClient, ChannelId, Payload, ChannelPayloads};
