import React from 'react';

import type {ClickSource} from 'pap-events/enums/click_source';
import {PAP_Select_ModalAction} from 'pap-events/replay/select_modal_action';
import {PAP_Shown_Modal} from 'pap-events/replay/shown_modal';
import {FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';

import {Modal} from '@dropbox/dig-components/modal';
import {Text, Title} from '@dropbox/dig-components/typography';
import {Box} from '@dropbox/dig-foundations';

import {featureStrings, modalStrings} from '~/components/add_on_upsell_modal';
import {Button} from '~/components/button';
import {useReelAppGlobalState} from '~/context';
import {useGetBillingCycleQuery, useGetReplayEligibilityQuery} from '~/lib/api_queries';
import {SINGLE_PURCHASE_URL, useGetProvisionCtav2, useGetUserVariant} from '~/lib/provisions';
import {usePricingStrings} from '~/lib/subscriptions';
import {useLoggingClient} from '~/lib/use_logging_client';

const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const ModalHeader = styled(Modal.Header)`
  border-bottom: 1px solid var(--dig-color__border__subtle);
  padding-bottom: var(--dig-spacing__macro__small);
`;

const ModalTitle = styled(Title)`
  margin: 0 0 var(--dig-spacing__micro__medium) 0;
`;

const FeatureColumn = styled(Box)`
  padding: var(--dig-spacing__macro__small) var(--dig-spacing__macro__small) 0;
  display: flex;
  align-items: center;
`;

const FreeColumn = styled(Box)`
  padding: var(--dig-spacing__macro__small) var(--dig-spacing__macro__small) 0;
  width: 162px;
  background-color: var(--Background-Subtle, #242321);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

const AddOnColumn = styled(Box)`
  padding: var(--dig-spacing__macro__small) var(--dig-spacing__macro__small) 0;
  width: 162px;
  background-color: var(--Highlight-Blue, #002969);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

const CheckIcon = styled.div`
  width: var(--dig-spacing__macro__medium);
  height: var(--dig-spacing__macro__medium);
  background-image: url('/images/check_icon.svg');
`;

const CrossIcon = styled.div`
  width: var(--dig-spacing__macro__medium);
  height: var(--dig-spacing__macro__medium);
  background-image: url('/images/cross_icon.svg');
`;

const DEFAULT_FILE_UPLOAD_LIMIT = 4;

export type PaywallModalProps = {
  open: boolean;
  onClose: () => void;
  clickSource: ClickSource;
};

export const PaywallModal = ({open, onClose, clickSource}: PaywallModalProps) => {
  const intl = useIntl();
  const userVariant = useGetUserVariant();
  const sessionContext = useReelAppGlobalState();
  const isLoggedIn = sessionContext.status === 'logged in';
  const {data: isEligibleData, isLoading: isLoadingReplayEligible} =
    useGetReplayEligibilityQuery(isLoggedIn);
  const isEligible = isEligibleData ?? true;
  const {data: billingInfo, isLoading: isLoadingBillingInfo} = useGetBillingCycleQuery(isLoggedIn);
  const billingCycle = billingInfo?.billingCycle ?? 'unknown';
  const billingPrice = billingInfo?.billingPrice ?? '';
  const isBillingUnknown = billingCycle === 'unknown' || billingPrice === '';
  const loggingClient = useLoggingClient();
  const {individualMonthly, individualYearly, teamMonthly, teamYearly} =
    usePricingStrings(billingPrice);
  const provisionCTA = useGetProvisionCtav2(userVariant, isEligible);
  const ctaLink = `${SINGLE_PURCHASE_URL}?replay_purchase_origin=paywall`;
  const planAllowedUpload = isLoggedIn
    ? sessionContext.provisions.file_count_limit.available
    : DEFAULT_FILE_UPLOAD_LIMIT;

  const billingText =
    billingCycle === 'yearly'
      ? userVariant === 'self_serve_team_admin'
        ? teamYearly
        : individualYearly
      : userVariant === 'self_serve_team_admin'
      ? teamMonthly
      : individualMonthly;

  const title = intl.formatMessage({
    defaultMessage: 'You’ve used all your free uploads',
    id: '5DNjvz',
    description: 'Title to inform users when reached the file limit and try to upload a new file',
  });

  // Once strings are translated, show for all languages
  const body = intl.locale.startsWith('en-')
    ? intl.formatMessage({
        defaultMessage: 'Get unlimited uploads when you upgrade Replay.',
        id: 'O1DD+R',
        description: 'Body to inform users about the benefit of purchasing the Replay add-on.',
      })
    : intl.formatMessage({
        defaultMessage: 'Get unlimited uploads with the Replay add-on.',
        id: '61YLeY',
        description: 'Body to inform users about the benefit of purchasing the Replay add-on.',
      });

  const features = intl.formatMessage({
    defaultMessage: 'Features',
    id: 'zyRhFt',
    description: 'Features column to display premium features.',
  });

  const free = intl.formatMessage({
    defaultMessage: 'Free',
    id: '0m1wdF',
    description: 'Free column to indicate what features you get as a free user.',
  });

  // Once strings are translated, show for all languages
  const addon = intl.locale.startsWith('en-')
    ? intl.formatMessage({
        defaultMessage: 'Upgrade',
        id: 'B/16Ug',
        description:
          'Header for column in upsell table that indicates which features you upgrade to a paid Replay plan',
      })
    : intl.formatMessage({
        defaultMessage: 'Add-on',
        id: 'yF1jKb',
        description: 'Add-on column to indicate what features you get as a add-on paying user.',
      });

  const fileUploads = intl.formatMessage({
    defaultMessage: 'File uploads',
    description: 'Feature name for file uploads',
    id: '5tk2ah',
  });

  React.useEffect(() => {
    if (open) {
      loggingClient.logPap(
        PAP_Shown_Modal({
          modal: 'paywall',
          clickSource: clickSource,
          replayCtaType: provisionCTA.ctaType === 'buy_replay' ? 'buy_replay' : 'learn_more',
          userVariant: userVariant,
        }),
      );
    }
  }, [clickSource, loggingClient, open, provisionCTA.ctaType, userVariant]);

  const handleNotNowClick = React.useCallback(() => {
    onClose();
    loggingClient.logPap(
      PAP_Select_ModalAction({
        modal: 'paywall',
        modalAction: 'cancel',
        clickSource: clickSource,
        userVariant: userVariant,
      }),
    );
  }, [clickSource, loggingClient, onClose, userVariant]);

  return !isLoadingReplayEligible && !isLoadingBillingInfo ? (
    <Modal isCentered open={open} shouldCloseOnOverlayClick={false} width={750}>
      <ModalHeader>
        <ModalTitle size="medium" weightVariant="emphasized">
          {title}
        </ModalTitle>
        <Text variant="label">{body + (!isBillingUnknown ? ' ' + billingText + '.' : '')}</Text>
      </ModalHeader>
      <Box width="100%">
        <Box display="flex">
          <FeatureColumn width="100%">
            <Text isBold={true} size="xlarge" variant="paragraph">
              {features}
            </Text>
          </FeatureColumn>
          <FreeColumn>
            <Text isBold={true} size="xlarge" variant="paragraph">
              {free}
            </Text>
          </FreeColumn>
          <AddOnColumn>
            <Text isBold={true} size="xlarge" variant="paragraph">
              {addon}
            </Text>
          </AddOnColumn>
        </Box>
        <Box display="flex">
          <FeatureColumn width="100%">
            <Text variant="paragraph">{fileUploads}</Text>
          </FeatureColumn>
          <FreeColumn>
            <Text variant="paragraph">{planAllowedUpload}</Text>
          </FreeColumn>
          <AddOnColumn>
            <Text size="small" variant="paragraph">
              <FormattedMessage {...featureStrings['unlimited_file_uploads']} />
            </Text>
          </AddOnColumn>
        </Box>
        <Box display="flex">
          <FeatureColumn width="100%">
            <Text variant="paragraph">
              <FormattedMessage {...featureStrings['transcript_and_captions']} />
            </Text>
          </FeatureColumn>
          <FreeColumn>
            <CrossIcon />
          </FreeColumn>
          <AddOnColumn>
            <CheckIcon />
          </AddOnColumn>
        </Box>
        <Box display="flex">
          <FeatureColumn width="100%">
            <Text variant="paragraph">
              <FormattedMessage {...featureStrings['version_comparison']} />
            </Text>
          </FeatureColumn>
          <FreeColumn>
            <CrossIcon />
          </FreeColumn>
          <AddOnColumn>
            <CheckIcon />
          </AddOnColumn>
        </Box>
        <Box display="flex">
          <FeatureColumn width="100%">
            <Text variant="paragraph">
              <FormattedMessage {...featureStrings['password_protected_links']} />
            </Text>
          </FeatureColumn>
          <FreeColumn>
            <CrossIcon />
          </FreeColumn>
          <AddOnColumn>
            <CheckIcon />
          </AddOnColumn>
        </Box>
        <Box display="flex">
          <FeatureColumn style={{paddingBottom: 'var(--dig-spacing__macro__small)'}} width="100%">
            <Text variant="paragraph">
              <FormattedMessage {...featureStrings['digital_watermarking']} />
            </Text>
          </FeatureColumn>
          <FreeColumn style={{paddingBottom: 'var(--dig-spacing__macro__small)'}}>
            <CrossIcon />
          </FreeColumn>
          <AddOnColumn style={{paddingBottom: 'var(--dig-spacing__macro__small)'}}>
            <CheckIcon />
          </AddOnColumn>
        </Box>
      </Box>
      <Modal.Footer hasTopBorder={true}>
        <FooterContainer>
          <Button onClick={handleNotNowClick} style={{marginLeft: 0}} variant="transparent">
            {intl.formatMessage(modalStrings.notNow)}
          </Button>
          <Button
            href={ctaLink}
            onClick={() => {
              onClose();
              loggingClient.logPap(
                PAP_Select_ModalAction({
                  modal: 'paywall',
                  modalAction: provisionCTA.ctaType === 'buy_replay' ? 'buy_replay' : 'learn_more',
                  clickSource: clickSource,
                  userVariant: userVariant,
                }),
              );
            }}
            size="large"
            target="_blank"
            variant="primary"
          >
            {provisionCTA.ctaLabel}
          </Button>
        </FooterContainer>
      </Modal.Footer>
    </Modal>
  ) : (
    <></>
  );
};
