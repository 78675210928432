import {useState} from 'react';

import {useSetAtom} from 'jotai';
import {FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';

import {Modal} from '@dropbox/dig-components/modal';
import {Link, Text, Title} from '@dropbox/dig-components/typography';

import {Button} from '~/components/button';
import {ItemType} from '~/components/common';
import {deleteFolder, deleteProject, shareProject} from '~/lib/api';
import {getCurrentUser} from '~/lib/client';
import {ReplayError, ReplayErrorCategory, reportException} from '~/lib/error_reporting';
import useMounted from '~/lib/use-mounted';
import {refetchableUserSpaceAtom} from '~/state/user';

import {useReelAppGlobalState} from '../context';
import {openURLInExternalBrowser} from '../lib/utils';
import type {ModifyItemSnackbarInfo} from '../pages/browse_page/browse_page';

type RemoveModalProps = {
  open: boolean;
  name: string;
  id: string;
  isHide: boolean;
  onDelete: (itemType: ItemType, itemIds: string[]) => void;
  requestClose: () => void;
  itemType: ItemType;
  isOwner: boolean;
  updateModifyItemSnackbar: (modifyItemSnackbarInfo: ModifyItemSnackbarInfo) => void;
  versionNumber?: number;
};

type RemoveTextProps = {
  isHide: boolean;
  name: string;
  itemType: ItemType;
  isOwner: boolean;
};

const RemoveModalBodyTitle = styled.p`
  margin: 0 0 21px 0;
`;

const ModalFooter = styled(Modal.Footer)`
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  padding-top: 0px;
  padding-bottom: 0px;
`;

const ModalButton = styled(Button)``;

const ModalButtons = styled.div`
  align-items: center;
  display: flex;
  margin: var(--spacing__unit--2) 0;

  ${ModalButton} {
    margin-left: 0;
  }
`;

const RemoveButtonContainer = styled.div`
  background-color: var(--dig-color__alert__base);
  border-radius: var(--dig-radius__medium, 0);
  margin-left: var(--spacing__unit--1);
`;

const RemoveText = ({isHide, name, itemType, isOwner}: RemoveTextProps) => {
  const boldItemName = <Text isBold>{name}</Text>;

  if (itemType == ItemType.FOLDER) {
    // Remove folder copy is a placeholder for now, might want to fill in more detail
    return (
      <FormattedMessage
        defaultMessage="These files as well as their share links and comments, will be permanently deleted and you won't be able to recover them."
        description="Message on the folder deletion modal that asks the user to confirm if the folder should be deleted from Replay"
        id="Gub4ZF"
      />
    );
  } else if (itemType == ItemType.VERSION) {
    return (
      <FormattedMessage
        defaultMessage="This version of this file as well as it's share links and comments, will be permanently deleted and you won't be able to recover them."
        description="Message on the version deletion modal explaining that the specified version and its share links and comments will be deleted"
        id="sy/MnN"
      />
    );
  } else if (itemType == ItemType.TEAM_PROJECT) {
    if (isOwner) {
      return (
        <FormattedMessage
          defaultMessage="All editors will lose access and deleting this project will permanently remove all files and related activity, as well as folders."
          description="Message on the team project deletion modal explaining that removing the project will delete all items in the project for all members of the project."
          id="AWTdLN"
        />
      );
    } else {
      return (
        <FormattedMessage
          defaultMessage="You will no longer be able to access or edit this project, and you will stop receiving notifications."
          description="Message on the leave project modal explaining that the user will no longer have any access to the contents of the team project if they leave the project"
          id="3fJaVz"
        />
      );
    }
  } else {
    if (isHide) {
      return (
        <FormattedMessage
          defaultMessage="Are you sure you want to hide {boldItemName} from Replay? The file will be
          hidden in Replay and will reappear if you open the file's shared link."
          description="Message on the file deletion modal that describes what happens when a file is hidden and asks the user to confirm if the file should be hidden"
          id="sh/l1g"
          values={{boldItemName: boldItemName}}
        />
      );
    }
    return (
      <FormattedMessage
        defaultMessage=" All versions of this file as well as their share links and comments, will be permanently deleted and you won't be able to recover them."
        description="Message on the file deletion modal explaining that comments and share links for every version of the file will all be deleted"
        id="qkgEeS"
      />
    );
  }
};

type RemoveModalTitleProps = {
  isHide: boolean;
  isOwner: boolean;
  versionNumber?: number;
  itemType: ItemType;
};

const RemoveModalTitle = ({isHide, isOwner, versionNumber, itemType}: RemoveModalTitleProps) => {
  if (itemType == ItemType.FOLDER) {
    return (
      <FormattedMessage
        defaultMessage="Remove folder"
        description="Title on the remove folder modal"
        id="oFnbZG"
      />
    );
  } else if (itemType == ItemType.TEAM_PROJECT) {
    if (isOwner) {
      return (
        <FormattedMessage
          defaultMessage="Permanently delete this project"
          description="Title on the bulk delete modal"
          id="z59GWM"
        />
      );
    } else {
      return (
        <FormattedMessage
          defaultMessage="Are you sure you want to leave this project?"
          description="Title on the remove project modal that asks the user to confirm if they want to leave the project"
          id="0aFJ0L"
        />
      );
    }
  } else if (itemType == ItemType.VERSION) {
    return (
      <FormattedMessage
        defaultMessage="Permanently delete v{versionNumber}"
        description="Title on the version deletion modal that asks the user to confirm if the specified version of the file should be deleted"
        id="NHq/WZ"
        values={{versionNumber: versionNumber}}
      />
    );
  } else {
    if (isHide) {
      return (
        <FormattedMessage
          defaultMessage="Hide file"
          description="Title on the hide file modal"
          id="mgtvfK"
        />
      );
    } else {
      return (
        <FormattedMessage
          defaultMessage="Permanently delete all versions"
          description="Title on the file deletion modal that asks the user to confirm if the file and all its versions should be deleted"
          id="wdJOir"
        />
      );
    }
  }
};

type RemoveButtonProps = {
  isHide: boolean;
  isOwner: boolean;
  versionNumber?: number;
  itemType: ItemType;
};

const RemoveButtonText = ({isHide, isOwner, versionNumber, itemType}: RemoveButtonProps) => {
  if (itemType == ItemType.TEAM_PROJECT) {
    if (isOwner) {
      return (
        <FormattedMessage
          defaultMessage="Permanently delete"
          description="Text on the delete button on the team project deletion modal"
          id="CF5lie"
        />
      );
    } else {
      return (
        <FormattedMessage
          defaultMessage="Leave"
          description="Text on the leave button on the leave project modal"
          id="60GN2G"
        />
      );
    }
  } else if (itemType == ItemType.VERSION) {
    return (
      <FormattedMessage
        defaultMessage="Permanently delete v{versionNumber}"
        description="Text on the delete version button on the version deletion modal"
        id="87YMxd"
        values={{versionNumber: versionNumber}}
      />
    );
  } else {
    if (isHide) {
      return (
        <FormattedMessage
          defaultMessage="Hide"
          description="Text on the hide button on the modal to hide a file or folder"
          id="7jKAA2"
        />
      );
    } else {
      return (
        <FormattedMessage
          defaultMessage="Permanently delete"
          description="Text on the delete button on the delete file or folder modal"
          id="Q0maav"
        />
      );
    }
  }
};

export const RemoveModal = (props: RemoveModalProps) => {
  const {
    id,
    isHide,
    name,
    onDelete,
    open,
    requestClose,
    itemType,
    isOwner,
    updateModifyItemSnackbar,
    versionNumber,
  } = props;
  const mounted = useMounted();
  const [waitingOnRequest, setWaitingOnRequest] = useState<boolean>(false);
  const intl = useIntl();
  const sessionContext = useReelAppGlobalState();
  const refreshUserSpaceAction = useSetAtom(refetchableUserSpaceAtom);

  // We transformed the button's appearance from blue to red by switching its variant from 'opacity' to 'primary.'
  // This change allowed us to reveal the red button's background CSS
  const removeButtonVariant = () => {
    if (itemType === ItemType.TEAM_PROJECT) {
      if (isOwner) {
        return 'opacity';
      } else {
        return 'primary';
      }
    } else if (itemType === ItemType.VERSION) {
      return 'opacity';
    } else {
      if (isHide) {
        return 'primary';
      } else {
        return 'opacity';
      }
    }
  };

  const removeButtonVariantValue = removeButtonVariant();

  const handleRemove = async () => {
    setWaitingOnRequest(true);

    const currentUser = getCurrentUser();

    if (currentUser === undefined) {
      return;
    }

    try {
      switch (itemType) {
        case ItemType.FOLDER:
          await deleteFolder(id);
          break;
        case ItemType.PROJECT:
          await deleteProject(id, isHide);
          break;
        case ItemType.TEAM_PROJECT:
          if (isOwner) {
            await deleteFolder(id);
          } else {
            await shareProject(
              id,
              [
                {
                  type: 'user',
                  email: '',
                  accountId: currentUser.account_id,
                },
              ],
              'remove',
            );
          }
          break;
      }
      onDelete(itemType, [id]);
      updateModifyItemSnackbar({
        type: 'remove',
        status: 'success',
        itemType: itemType,
        name: name,
        versionNumber: versionNumber,
      });
      sessionContext.status === 'logged in' && sessionContext.refreshProvisions();
      requestClose();
      refreshUserSpaceAction();
    } catch (e) {
      reportException(
        new ReplayError({
          error: e,
          severity: 'non-critical',
          tags: ['delete_project_failed'],
          category: ReplayErrorCategory.UncaughtException,
        }),
      );
      updateModifyItemSnackbar({
        type: 'remove',
        status: 'error',
        itemType: itemType,
        name: name,
        versionNumber: versionNumber,
      });
      requestClose();
      return;
    } finally {
      if (mounted()) {
        setWaitingOnRequest(false);
      }
    }
  };

  const closeRemoveModalText = intl.formatMessage({
    defaultMessage: 'Close remove modal',
    id: 'dJM2f+',
    description: 'Label for the button that closes the remove modal',
  });

  return (
    <Modal
      isCentered
      onRequestClose={requestClose}
      open={open}
      withCloseButton={closeRemoveModalText}
    >
      <Modal.Header hasBottomSpacing="title-small">
        <Title size="small" weightVariant="emphasized">
          <RemoveModalTitle
            isHide={isHide}
            isOwner={isOwner}
            itemType={itemType}
            versionNumber={versionNumber}
          />
        </Title>
      </Modal.Header>
      <Modal.Body>
        <RemoveModalBodyTitle>
          <Text>
            <RemoveText isHide={isHide} isOwner={isOwner} itemType={itemType} name={name} />
          </Text>
        </RemoveModalBodyTitle>
      </Modal.Body>
      <ModalFooter>
        <Link
          href="https://help.dropbox.com/delete-restore/dropbox-replay-archive"
          onClick={(e) => {
            // Prevent the default anchor link behavior
            // because we're about to call the method below
            // this is largely to be accessible
            e.preventDefault();
            openURLInExternalBrowser(
              'https://help.dropbox.com/delete-restore/dropbox-replay-archive',
            );
          }}
          style={{textDecoration: 'none'}}
        >
          <Button variant="transparent">
            <FormattedMessage
              defaultMessage="Learn about archiving"
              description="Text on the remove modal for link to archiving infomation"
              id="s340Rl"
            />
          </Button>
        </Link>
        <ModalButtons>
          <ModalButton onClick={requestClose} size="standard" variant="opacity">
            <FormattedMessage
              defaultMessage="Cancel"
              description="Text on the button on the file deletion modal to cancel deleting the file from Replay"
              id="5qb4vY"
            />
          </ModalButton>
          <RemoveButtonContainer>
            <ModalButton
              inverse={removeButtonVariantValue === 'opacity'}
              isLoading={waitingOnRequest}
              onClick={handleRemove}
              size="standard"
              variant={removeButtonVariantValue}
            >
              <RemoveButtonText
                isHide={isHide}
                isOwner={isOwner}
                itemType={itemType}
                versionNumber={versionNumber}
              />
            </ModalButton>
          </RemoveButtonContainer>
        </ModalButtons>
      </ModalFooter>
    </Modal>
  );
};
