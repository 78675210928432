import type {IntlShape} from 'react-intl';
import {useIntl} from 'react-intl';

import {UIIcon} from '@dropbox/dig-icons';
import {AddLine} from '@dropbox/dig-icons/assets';

import {Button} from '~/components/button';
import {MAX_FOLDER_DEPTH} from '~/components/common';
import {useViewport} from '~/components/viewport_context';
import {useIsTeamLocked} from '~/lib/utils';
import {useCreateProject} from '~/pages/browse_page/use_create_project';

import {useFoldersSharedState} from '../folders_context';

type CreateFolderButtonProps = {
  onSuccess: (folderId: string, level: number) => void;
  parentFolderId: string;
  level: number;
  currentProjectAmplitudeId?: string;
};

const getButtonText = (intl: IntlShape, {isSmallScreenSize}: {isSmallScreenSize: boolean}) => {
  if (!isSmallScreenSize) {
    return intl.formatMessage({
      defaultMessage: 'Create project',
      id: 'eBxXrW',
      description: 'Button that allows a user to create a new project in their root folder',
    });
  }
  return intl.formatMessage({
    defaultMessage: 'Project',
    id: 'HfeClQ',
    description:
      "Button that allows a user to create a new project in their root folder on mobile (prefixed with '+' icon)",
  });
};

export const CreateFolderButton = (props: CreateFolderButtonProps) => {
  const {onSuccess} = props;
  const {createFolderModal, handleOpenCreateFolderModal} = useCreateProject({
    onCreateFolderSuccess: onSuccess,
    actionElement: 'toolbar',
  });
  const {breadcrumbPath} = useFoldersSharedState();
  const intl = useIntl();
  // eslint-disable-next-line deprecation/deprecation
  const {isSmallScreenSize} = useViewport();
  const isTeamLocked = useIsTeamLocked();

  const buttonText = getButtonText(intl, {isSmallScreenSize});

  if (breadcrumbPath && breadcrumbPath.length >= MAX_FOLDER_DEPTH) return null;

  return (
    <>
      <Button
        disabled={isTeamLocked}
        onClick={handleOpenCreateFolderModal}
        variant="opacity"
        withIconStart={<UIIcon src={AddLine} />}
      >
        {buttonText}
      </Button>
      {createFolderModal}
    </>
  );
};
