import {useIntl} from 'react-intl';

import {Badge} from '@dropbox/dig-components/badge';

import type {ShareFolderAccessType} from '~/lib/api';

type PermissionTagProps = {currentFolderPermission: ShareFolderAccessType};

const PermissionTag = ({currentFolderPermission}: PermissionTagProps) => {
  const intl = useIntl();

  if (!currentFolderPermission) {
    return null;
  }

  let permissionName;

  switch (currentFolderPermission) {
    case 'reviewer':
      permissionName = intl.formatMessage({
        defaultMessage: 'View only',
        id: 'q1KlPg',
        description: 'Text that describes having view only permission to a folder.',
      });
      break;
    case 'admin':
      permissionName = intl.formatMessage({
        defaultMessage: 'Admin',
        id: 'SuH8d2',
        description: 'Text that describes having edit permissions to a folder.',
      });
      break;
    case 'owner':
    case 'none':
    case 'super_admin':
      permissionName = intl.formatMessage({
        defaultMessage: 'Owner',
        id: 'AE+NDw',
        description: 'Text that describes someone who is the owner of a folder.',
      });
      break;
    default:
      return null;
  }

  return <Badge>{permissionName}</Badge>;
};

export {PermissionTag};
