import React, {useState} from 'react';

import {useSetAtom} from 'jotai';
import {PAP_Select_WorkspaceAction} from 'pap-events/replay/select_workspace_action';
import {FormattedMessage, useIntl} from 'react-intl';
import {useNavigate} from 'react-router';
import styled from 'styled-components';

import type {reel} from '@dropbox/api-v2-client';
import type {UseMenuOnToggleFn} from '@dropbox/dig-components/hooks';
import type {WrapperOnToggle} from '@dropbox/dig-components/menu';
import {Menu} from '@dropbox/dig-components/menu';
import {Text} from '@dropbox/dig-components/typography';
import {UIIcon} from '@dropbox/dig-icons';
import {
  CopyLine,
  DeleteLine,
  DownloadLine,
  DropboxLine,
  EditLine,
  GifLine,
  HideLine,
  InfoLine,
  LinkLine,
  LockLine,
  MoreHorizontalLine,
  MoveLine,
  PersonLeaveLine,
  PersonMultipleLine,
  SendLine,
  ShareLine,
  UploadLine,
} from '@dropbox/dig-icons/assets';

import {IconButton} from '~/components/button';
import {ItemType} from '~/components/common';
import {useDropboxChooser} from '~/components/dropbox_chooser_context';
import {useGDriveContext} from '~/components/gdrive_context';
import {RemoveModal} from '~/components/remove_modal';
import {RenameModal} from '~/components/rename_modal';
import {useRenderContext} from '~/components/render_context';
import {useOnShareClick} from '~/components/share_modal/share_modal_common';
import type {ShareLinkPermission} from '~/components/share_modal/share_modal_common';
import {
  initializeShareModalAtom,
  isShareModalOpenAtom,
  shareSnackbarStateAtom,
} from '~/components/share_modal/share_state';
import {GoogleDriveIcon, OneDriveIcon} from '~/components/third_party_icons';
import {useViewport} from '~/components/viewport_context';
import type {ShareFolderAccessType} from '~/lib/api';
import {getVideoDownloadLink, MediaType} from '~/lib/api';
import {getTasksForItem} from '~/lib/api';
import {useGetVersionSummariesQuery} from '~/lib/api_queries';
import {getCurrentUser} from '~/lib/client';
import {download} from '~/lib/download';
import type {LoggingClient} from '~/lib/logging/logger';
import type {MediaSourceType} from '~/lib/logging/logger_types';
import {mapMediaTypeToLoggingType, toBooleanAttr} from '~/lib/logging/logger_types';
import {useOnboardingChecklist} from '~/lib/onboarding_v2/use_onboarding_checklist';
import type {DirectUpload, StreamableFile} from '~/lib/uploads/types';
import useMounted from '~/lib/use-mounted';
import {useCanUserCreateLinks, useFolderShareOnClick} from '~/lib/use_folder_share_onclick';
import {useGetPapAccessType} from '~/lib/use_get_pap_access_type';
import {useLinkActions} from '~/lib/use_link_actions';
import {useLoggingClient} from '~/lib/use_logging_client';
import {
  getCurrentUserPermissions,
  useArchiveToDropboxEnabled,
  useAreSuperAdminFeaturesEnabled,
  useHasFullAddonAccess,
  useIsTeamLocked,
} from '~/lib/utils';
import {useBrowseItemsViewContext} from '~/pages/browse_page/browse_items_view_context';
import {useBrowseLogEventMaker} from '~/pages/browse_page/browse_logging_context';
import type {ModifyItemSnackbarInfo} from '~/pages/browse_page/browse_page';
import {BrowseSnackbarStatus} from '~/pages/browse_page/components/browse_page_snackbar';
import {DownloadFolderModal} from '~/pages/browse_page/components/download/download_folder_modal';
import {DirectUploadText, DropboxUploadText} from '~/pages/browse_page/components/media_adder';
import {PremiumTooltip} from '~/pages/layout/components/premium_tooltip';
import {
  AssetInfoModal,
  FolderInfoModal,
} from '~/pages/viewer_page/components/info_modal/info_modal';
import {
  areLatestTwoVersionsSameMediaType,
  generateVersionComparePageUrl,
  getLatestTwoVersions,
} from '~/pages/viewer_page/components/versions/version_utils';
import {useIsCurrentPagePartOfAdminConsole} from '~/route_path_matches';
import {browseSnackbarAtom} from '~/state/browse';
import {STEPS} from '~/state/onboarding_v2';
import {RedCarpetMode} from '~/use_extensions';

import {BasicAddOnUpsellModal} from './add_on_upsell_modal';
import {
  dueDateModalCurrentVersionInfoAtom,
  dueDateVersionModalAtom,
} from './due_date/due_date_modal';
import {useExtensionContext} from './extension_context';
import {useOneDriveContext} from './onedrive_context';
import {useCopyBasicLinkToClipboard} from './share_modal/copy_link_utils';
import {useErrorSnackbar} from './snackbar/error_snackbar';
import {color} from './styled';
import {TransferOwnershipModal} from './transfer_ownership_modal';
import {useUpload} from './upload_context';

type ContextMenuProps = {
  name: string;
  onDelete: (itemType: ItemType, itemIds: string[]) => void;
  onRename: (newName: string) => void;
  updateModifyItemSnackbar: (modifyItemSnackbarInfo: ModifyItemSnackbarInfo) => void;
};

type FolderContextMenuProps = ContextMenuProps & {
  folderId: string;
  isTeamProject?: boolean;
  openAddPeopleModal: (sharedFolderId: string, sharedFolderName: string) => void;
  openManagePeopleModal: (sharedFolderId: string, sharedFolderName: string) => void;
  accessLevel: ShareFolderAccessType;
  allowSuperAdminRights?: boolean;
  hideShareOption?: boolean;
  hideLeaveProjectOption?: boolean;
};

type VideoContextMenuProps = ContextMenuProps & {
  projectId: string;
  ownerUid?: string;
  videoId: string;
  videoIdForAmplitude?: string;
  videoNsId?: number;
  onVersionSelected: (
    fileId: string,
    fileIdType: 'file_id' | 'file_path',
    fileSource: MediaSourceType,
    needsCopy: boolean,
    uploadId?: string,
  ) => Promise<{
    projectId?: string;
    videoId?: string;
    videoVersionId?: string;
    videoIdForAmplitude?: string;
    versionIdForAmplitude?: string;
    mediaType?: MediaType;
    fileSize?: number;
  }>;
  currentVersionNumber?: number;
  versionSummaries?: Array<reel.VersionSummary>;
  parentFolderPermission: ShareFolderAccessType;
  isDemo: boolean;
  mediaType: MediaType;
  ownerAddonEnabled: boolean;
  onTransfer: (accountId: string) => void;
  thumbnailUrl?: string;
};

type ModalState =
  | 'rename modal'
  | 'remove modal'
  | 'metadata modal'
  | 'share modal'
  | 'add people modal'
  | 'manage people modal'
  | 'transfer ownership modal'
  | 'download modal'
  | 'none';

const RemoveText = styled(Text)`
  color: ${color('Alert On Surface')};
`;

const RemoveIcon = styled(UIIcon)`
  color: ${color('Alert On Surface')};
`;

const ContextButton = styled(IconButton)`
  background: ${color('Utility Overlay')};
`;

export const FolderContextMenu = ({
  accessLevel,
  folderId,
  isTeamProject,
  name,
  onDelete,
  onRename,
  openAddPeopleModal,
  openManagePeopleModal,
  updateModifyItemSnackbar,
  allowSuperAdminRights,
  hideShareOption,
  hideLeaveProjectOption,
}: FolderContextMenuProps) => {
  const intl = useIntl();
  const mounted = useMounted();
  const loggingClient = useLoggingClient();
  const {editActionsProps, breadcrumbPath} = useBrowseItemsViewContext();
  const areSuperAdminFeaturesEnabled = useAreSuperAdminFeaturesEnabled();
  // eslint-disable-next-line deprecation/deprecation
  const {isSmallScreenSize} = useViewport();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [modalState, setModalState] = useState<ModalState>('none');

  const makeLogEvent = useBrowseLogEventMaker();
  const logEvent = React.useMemo(
    () =>
      makeLogEvent({
        projectId: isTeamProject ? folderId : undefined,
        reelObjectType: isTeamProject ? 'project' : 'folder',
      }),
    [makeLogEvent, isTeamProject, folderId],
  );

  const closeModal = React.useCallback(() => {
    if (mounted()) {
      setModalState('none');
    }
  }, [mounted]);

  let movable = false;
  if (editActionsProps.editActionsEnabled) {
    const findDepth = (children?: reel.FolderTreeNode[]): number => {
      if (!children) return 0;
      const depths = children.map((node) => findDepth(node.children) + 1);
      return depths.reduce((maxDepth: number, depth: number) => Math.max(maxDepth, depth), 0);
    };

    const getFolderChildDepth = (tree: reel.FolderTreeNode[], searchId: string): number | null => {
      if (!tree) return null;
      for (let i = 0; i < tree.length; i += 1) {
        if (tree[i].folder && tree[i].folder?.id === searchId) {
          return findDepth(tree[i].children);
        } else if (tree[i].children) {
          const d = getFolderChildDepth(tree[i].children!, searchId);
          if (d !== null) return d;
        }
      }
      return null;
    };

    movable = !isTeamProject;
  }

  const canCreateLinks = useCanUserCreateLinks({accessLevel});

  const {onShareClick: onFolderShareClick, getExistingLinks} = useFolderShareOnClick({
    accessLevel,
    folderId,
    folderName: name,
    isTeamFolder: Boolean(isTeamProject),
    logEvent,
  });

  const {existingLinksPromise, refetchLinks} = useOnShareClick({
    getExistingLinks,
    mediaId: folderId,
    doNotAutoFetch: true,
  });
  const {copyLinkToClipboard} = useCopyBasicLinkToClipboard();
  const {createShareLink} = useLinkActions({
    logEvent,
    linkItemId: folderId,
    linkItemType: 'folder',
    location: 'browse_context_menu',
  });

  const handleFolderShareClick = async (perm: ShareLinkPermission) => {
    if (existingLinksPromise !== 'unfetched') {
      const existingLinks = await existingLinksPromise;
      onFolderShareClick(existingLinks, perm);
    }
  };

  const handleToggle: WrapperOnToggle = ({isOpen}: {isOpen: boolean}) => {
    setIsMenuOpen(isOpen);
    if (isOpen) {
      // eslint-disable-next-line deprecation/deprecation
      logEvent('select_overflow_menu');
      if (existingLinksPromise === 'unfetched') {
        refetchLinks();
      }
    }
  };

  const isRootFolder = breadcrumbPath && breadcrumbPath.length == 1;
  const papAccessType = useGetPapAccessType({accessLevel: isTeamProject ? accessLevel : null});

  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Menu.Wrapper
        onSelection={async (value: string) => {
          switch (value) {
            case 'share':
            case 'share-review':
            case 'share-view-only':
              // eslint-disable-next-line deprecation/deprecation
              logEvent('select_overflow_menu_share');
              setModalState('share modal');
              handleFolderShareClick(value === 'share-view-only' ? 'view-only' : 'review');
              break;
            case 'copy-link':
              // eslint-disable-next-line deprecation/deprecation
              logEvent('copy_reel_share_link', {
                access_type: 'review',
                link_item_type: 'folder',
                is_onboarding_share_step: 'false',
                replay_media_type: 'none',
                experiment_variant: 'none',
              });
              const shareLink = await createShareLink({
                isFolder: true,
                viewOnly: false,
                downloadsDisabled: false,
                passwordEnabled: false,
                password: '',
                loggedOutAccessDisabled: false,
                viewPreviousCommentsDisabled: false,
              });
              await copyLinkToClipboard(shareLink);
              break;
            case 'remove':
              // eslint-disable-next-line deprecation/deprecation
              logEvent('select_overflow_menu_remove');
              setModalState('remove modal');
              break;
            case 'rename':
              // eslint-disable-next-line deprecation/deprecation
              logEvent('select_overflow_menu_rename');
              setModalState('rename modal');
              break;
            case 'get-metadata':
              // eslint-disable-next-line deprecation/deprecation
              logEvent('select_overflow_menu_get_metadata');
              setModalState('metadata modal');
              break;
            case 'move':
              if (!editActionsProps.editActionsEnabled) {
                break;
              }
              // eslint-disable-next-line deprecation/deprecation
              logEvent('select_overflow_menu_move');
              editActionsProps.setLeftRailStatus({
                status: 'move',
                items: {
                  folderId,
                  folderName: name,
                  itemType: 'FolderTile',
                },
              });
              break;
            case 'copy':
              if (!editActionsProps.editActionsEnabled) {
                break;
              }
              // eslint-disable-next-line deprecation/deprecation
              logEvent('select_overflow_menu_copy');
              editActionsProps.setLeftRailStatus({
                status: 'copy',
                items: {
                  folderId,
                  folderName: name,
                  itemType: 'FolderTile',
                },
              });
              break;
            case 'add-people':
              loggingClient.logPap(
                PAP_Select_WorkspaceAction({
                  workspaceAction: 'add_people',
                  actionElement: 'overflow_menu',
                }),
              );
              setModalState('add people modal');
              if (openAddPeopleModal) {
                openAddPeopleModal(folderId, name);
              }
              break;
            case 'manage-people':
              // eslint-disable-next-line deprecation/deprecation
              logEvent('select_overflow_menu_manage_people');
              setModalState('manage people modal');
              if (openManagePeopleModal) {
                openManagePeopleModal(folderId, name);
              }
              break;
            case 'download':
              loggingClient.logPap(
                PAP_Select_WorkspaceAction({
                  workspaceAction: 'download',
                  actionElement: 'overflow_menu',
                  projectId: folderId,
                  folderLevel: breadcrumbPath?.length,
                  contentType: 'folder',
                  deviceId: loggingClient.deviceId,
                  accessType: papAccessType,
                }),
              );
              setModalState('download modal');
              break;
          }
        }}
        onToggle={handleToggle}
      >
        {({getContentProps, getTriggerProps}) => (
          <>
            <ContextButton
              {...getTriggerProps()}
              aria-label={intl.formatMessage({
                defaultMessage: 'Actions menu',
                id: 'lv7DLL',
                description:
                  'Aria label for button that opens menu containing actions you can perform on a folder.',
              })}
              size="small"
              variant="borderless"
            >
              <UIIcon size="small" src={MoreHorizontalLine} />
            </ContextButton>
            <Menu.Content {...getContentProps()} open={isMenuOpen}>
              <Menu.Segment>
                {accessLevel !== 'reviewer' ? (
                  <>
                    {isTeamProject ? (
                      <Menu.ActionItem
                        data-safe-to-unmask-name="project-thumbnail-menu-add-people"
                        key="add-people"
                        value="add-people"
                        withLeftAccessory={<UIIcon src={ShareLine} />}
                      >
                        <FormattedMessage
                          defaultMessage="Add people"
                          description="Text for a menu item that lets you add people to a shared folder."
                          id="4YmpuY"
                        />
                      </Menu.ActionItem>
                    ) : null}
                    {isTeamProject && areSuperAdminFeaturesEnabled && !!allowSuperAdminRights && (
                      <Menu.ActionItem
                        data-safe-to-unmask-name="project-thumbnail-menu-manage-people"
                        key="manage-people"
                        value="manage-people"
                        withLeftAccessory={<UIIcon src={PersonMultipleLine} />}
                      >
                        <FormattedMessage
                          defaultMessage="Manage people"
                          description="Text for a menu item only available to the SuperAdmin that lets you adjust which people have access."
                          id="XXpF/P"
                        />
                      </Menu.ActionItem>
                    )}
                    {canCreateLinks && !hideShareOption && (
                      <>
                        <Menu.ActionItem
                          data-safe-to-unmask-name="project-thumbnail-menu-share"
                          key="share"
                          value="share"
                          withLeftAccessory={<UIIcon src={SendLine} />}
                        >
                          <FormattedMessage
                            defaultMessage="Share"
                            description="Text for a menu item that lets you share the file with someone else."
                            id="WBBNRV"
                          />
                        </Menu.ActionItem>
                        <Menu.ActionItem
                          data-safe-to-unmask-name="project-thumbnail-menu-copy-link"
                          key="copy-link"
                          value="copy-link"
                          withLeftAccessory={<UIIcon src={LinkLine} />}
                        >
                          <FormattedMessage
                            defaultMessage="Copy link"
                            description="Text for a menu item that lets you share the file with someone else by directly copying the share link."
                            id="WEJ8UJ"
                          />
                        </Menu.ActionItem>
                      </>
                    )}
                    <Menu.ActionItem
                      data-safe-to-unmask-name="project-thumbnail-menu-download"
                      key="download"
                      value="download"
                      withLeftAccessory={<UIIcon src={DownloadLine} />}
                    >
                      <FormattedMessage
                        defaultMessage="Download"
                        description="Text for a menu item that lets you download a folder."
                        id="VqXNDV"
                      />
                    </Menu.ActionItem>
                    <Menu.ActionItem
                      data-safe-to-unmask-name="project-thumbnail-menu-rename"
                      key="rename"
                      value="rename"
                      withLeftAccessory={<UIIcon src={EditLine} />}
                    >
                      <FormattedMessage
                        defaultMessage="Rename"
                        description="Text for a menu item that lets you rename a folder."
                        id="l0At/m"
                      />
                    </Menu.ActionItem>
                    {!movable || isSmallScreenSize ? null : (
                      <Menu.ActionItem
                        key="move"
                        value="move"
                        withLeftAccessory={<UIIcon src={MoveLine} />}
                      >
                        <FormattedMessage
                          data-safe-to-unmask-name="project-thumbnail-menu-move-to-folder"
                          defaultMessage="Move to folder"
                          description="Text for a menu item that lets you move a folder."
                          id="NpbS2Z"
                        />
                      </Menu.ActionItem>
                    )}
                    <Menu.ActionItem
                      key="get-metadata"
                      value="get-metadata"
                      withLeftAccessory={<UIIcon src={InfoLine} />}
                    >
                      <FormattedMessage
                        defaultMessage="Project Info"
                        description="Text for a team project to provide more metadata info to the user."
                        id="OX++51"
                      />
                    </Menu.ActionItem>
                    {!isRootFolder && movable && !isSmallScreenSize && (
                      <Menu.ActionItem
                        data-safe-to-unmask-name="project-thumbnail-menu-copy-to-folder"
                        key="copy"
                        value="copy"
                        withLeftAccessory={<UIIcon src={CopyLine} />}
                      >
                        <FormattedMessage
                          defaultMessage="Copy to project"
                          description="Text for a menu item that lets you copy a file to another project."
                          id="k41/nr"
                        />
                      </Menu.ActionItem>
                    )}
                  </>
                ) : null}
                {!hideLeaveProjectOption && isTeamProject ? (
                  accessLevel === 'owner' || accessLevel === 'none' ? (
                    <Menu.ActionItem
                      data-safe-to-unmask-name="project-thumbnail-menu-delete-shared-folder"
                      key="remove"
                      value="remove"
                      withLeftAccessory={<RemoveIcon src={DeleteLine} />}
                    >
                      <RemoveText>
                        <FormattedMessage
                          defaultMessage="Delete"
                          description="Text for a menu item that lets you delete a shared folder."
                          id="dFcLka"
                        />
                      </RemoveText>
                    </Menu.ActionItem>
                  ) : (
                    <Menu.ActionItem
                      data-safe-to-unmask-name="project-thumbnail-menu-leave-project"
                      key="remove"
                      value="remove"
                      withLeftAccessory={<RemoveIcon src={PersonLeaveLine} />}
                    >
                      <RemoveText>
                        <FormattedMessage
                          defaultMessage="Leave project"
                          description="Text for a menu item that lets you leave a shared project folder."
                          id="0IoSnF"
                        />
                      </RemoveText>
                    </Menu.ActionItem>
                  )
                ) : (
                  <Menu.ActionItem
                    data-safe-to-unmask-name="project-thumbnail-menu-delete-personal-folder"
                    key="remove"
                    value="remove"
                    withLeftAccessory={<RemoveIcon src={DeleteLine} />}
                  >
                    <RemoveText>
                      <FormattedMessage
                        defaultMessage="Delete"
                        description="Text for a menu item that lets you delete a personal folder."
                        id="ELaiDW"
                      />
                    </RemoveText>
                  </Menu.ActionItem>
                )}
              </Menu.Segment>
            </Menu.Content>
          </>
        )}
      </Menu.Wrapper>
      <RenameModal
        id={folderId}
        itemType={isTeamProject ? ItemType.TEAM_PROJECT : ItemType.FOLDER}
        logEvent={logEvent}
        onRename={onRename}
        open={modalState === 'rename modal'}
        originalName={name}
        requestClose={closeModal}
        updateModifyItemSnackbar={updateModifyItemSnackbar}
      />
      <RemoveModal
        id={folderId}
        isHide={false}
        isOwner={accessLevel === 'owner' || accessLevel === 'none'}
        itemType={isTeamProject ? ItemType.TEAM_PROJECT : ItemType.FOLDER}
        name={name}
        onDelete={onDelete}
        open={modalState === 'remove modal'}
        requestClose={closeModal}
        updateModifyItemSnackbar={updateModifyItemSnackbar}
      />
      <FolderInfoModal
        folderId={folderId}
        open={modalState === 'metadata modal'}
        requestClose={closeModal}
      />
      <DownloadFolderModal
        analyticsData={{
          accessType: papAccessType,
          actionElement: 'overflow_menu',
        }}
        downloadOptions={[
          {'.tag': 'latest_files'},
          {'.tag': 'approved_files'},
          {'.tag': 'all_files'},
        ]}
        folderId={folderId}
        modalOpen={modalState === 'download modal'}
        onModalClose={closeModal}
      />
    </div>
  );
};

type CommonContextMenuProps = {
  handleToggle: UseMenuOnToggleFn;
  logEvent: LoggingClient['logEvent'];
};

const useCommonContextMenuProps = ({
  mediaType,
  videoIdForAmplitude,
}: {
  mediaType: MediaType;
  videoIdForAmplitude?: string;
}): CommonContextMenuProps => {
  const makeLogEvent = useBrowseLogEventMaker();
  const logEvent = React.useMemo(
    () =>
      makeLogEvent({
        videoId: videoIdForAmplitude,
        reelObjectType: mapMediaTypeToLoggingType(mediaType),
      }),
    [makeLogEvent, videoIdForAmplitude, mediaType],
  );
  const handleToggle: WrapperOnToggle = ({isOpen}: {isOpen: boolean}) => {
    if (isOpen) {
      // eslint-disable-next-line deprecation/deprecation
      logEvent('select_overflow_menu');
    }
  };

  return {
    handleToggle,
    logEvent,
  };
};

export type ViewOnlyContextMenuProps = Pick<
  VideoContextMenuProps,
  | 'mediaType'
  | 'videoIdForAmplitude'
  | 'projectId'
  | 'onDelete'
  | 'name'
  | 'updateModifyItemSnackbar'
>;

export const ViewOnlyContextMenu = ({
  mediaType,
  name,
  onDelete,
  projectId,
  updateModifyItemSnackbar,
  videoIdForAmplitude,
}: ViewOnlyContextMenuProps) => {
  const {handleToggle, logEvent} = useCommonContextMenuProps({
    mediaType,
    videoIdForAmplitude,
  });
  const intl = useIntl();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

  const wrappedHandleToggle: WrapperOnToggle = (event) => {
    setIsMenuOpen(event.isOpen);
    handleToggle(event);
  };

  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Menu.Wrapper
        onSelection={async (value: string) => {
          switch (value) {
            case 'remove':
              // eslint-disable-next-line deprecation/deprecation
              logEvent('select_overflow_menu_remove');
              setIsModalOpen(true);
              break;
            default:
            // do nothing
          }
        }}
        onToggle={wrappedHandleToggle}
      >
        {({getContentProps, getTriggerProps}) => (
          <>
            <ContextButton
              {...getTriggerProps()}
              aria-label={intl.formatMessage({
                defaultMessage: 'Actions menu',
                id: 'MaPAN8',
                description:
                  'Aria label for button that opens a menu containing actions you can perform on a view only folder.',
              })}
              size="small"
              variant="borderless"
            >
              <UIIcon size="small" src={MoreHorizontalLine} />
            </ContextButton>
            <Menu.Content {...getContentProps()} open={isMenuOpen}>
              <Menu.Segment>
                <Menu.ActionItem
                  key="remove"
                  value="remove"
                  withLeftAccessory={<RemoveIcon src={HideLine} />}
                >
                  <RemoveText>
                    <FormattedMessage
                      defaultMessage="Hide"
                      description="Menu option to remove a shared video from the user's browse page"
                      id="+biYEr"
                    />
                  </RemoveText>
                </Menu.ActionItem>
              </Menu.Segment>
            </Menu.Content>
          </>
        )}
      </Menu.Wrapper>
      <RemoveModal
        id={projectId}
        isHide
        isOwner={false}
        itemType={ItemType.PROJECT}
        name={name}
        onDelete={onDelete}
        open={isModalOpen}
        requestClose={() => setIsModalOpen(false)}
        updateModifyItemSnackbar={updateModifyItemSnackbar}
      />
    </div>
  );
};

export const VideoContextMenu = ({
  projectId,
  videoId,
  videoIdForAmplitude,
  videoNsId,
  name,
  ownerUid,
  onDelete,
  onRename,
  onTransfer,
  updateModifyItemSnackbar,
  onVersionSelected,
  currentVersionNumber,
  parentFolderPermission,
  isDemo,
  mediaType,
  ownerAddonEnabled,
  thumbnailUrl,
}: VideoContextMenuProps) => {
  const isAdminPageRoute = useIsCurrentPagePartOfAdminConsole();
  const {editActionsProps, breadcrumbPath, currentFolderPermission} = useBrowseItemsViewContext();
  const {chooseVersionUpload, chooseArchiveFolder} = useDropboxChooser();
  const {chooseFiles, uploadVersion} = useUpload();
  // eslint-disable-next-line deprecation/deprecation
  const {isSmallScreenSize, isMobileDevice} = useViewport();
  const showUpsellModal = !isMobileDevice;
  const isTeamLocked = useIsTeamLocked();
  const isVersionComparisonProvisioned = useHasFullAddonAccess() || ownerAddonEnabled;
  const isArchivingEnabled = useArchiveToDropboxEnabled();
  const isArchivingProvisioned = useHasFullAddonAccess() && isArchivingEnabled;
  const mounted = useMounted();
  const intl = useIntl();
  const {startGDriveVersionImport} = useGDriveContext();
  const {startOneDriveVersionImport} = useOneDriveContext();
  const {redCarpet} = useExtensionContext();
  const {
    openModal: openRenderModal,
    linkVideoToAdobeVideo,
    importMarkersAsComments,
  } = useRenderContext();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const logger = useLoggingClient();

  // For performance reasons, the backend does not return the correct media type for each version in the ListFolder call
  // So when we open the context menu, we refetch the video versions to get the correct info
  const {data: versionSummaries = []} = useGetVersionSummariesQuery({
    enabled: isMenuOpen,
    params: {
      type: 'video_id',
      videoId: videoId,
    },
  });

  const [modalState, setModalState] = useState<ModalState>('none');
  const [showVersionComparisonPremiumTooltip, setShowVersionComparisonPremiumTooltip] =
    React.useState<boolean>(false);
  const versionComparisonTriggerRef = React.useRef(null);

  const [upsellModalIsOpen, setUpsellModalIsOpen] = React.useState<boolean>(false);

  const setBrowseSnackbar = useSetAtom(browseSnackbarAtom);

  const closeModal = React.useCallback(() => {
    if (mounted()) {
      setModalState('none');
    }
  }, [mounted]);

  const {handleToggle, logEvent} = useCommonContextMenuProps({
    mediaType,
    videoIdForAmplitude,
  });

  const {getExistingLinks, createShareLink} = useLinkActions({
    logEvent,
    versionSummaries,
    linkItemId: videoId,
    linkItemType: 'file',
    location: 'browse_context_menu',
  });

  const {existingLinksPromise, refetchLinks} = useOnShareClick({
    getExistingLinks,
    mediaId: videoId,
    doNotAutoFetch: true,
  });
  const {copyLinkToClipboard} = useCopyBasicLinkToClipboard();
  const {handleCheckIsCurrentStepOpen, handleCompleteStep} = useOnboardingChecklist({logEvent});

  const initializeShareModal = useSetAtom(initializeShareModalAtom);
  const setIsShareModalOpen = useSetAtom(isShareModalOpenAtom);
  const setShareErrorSnackbar = useSetAtom(shareSnackbarStateAtom);
  const {openErrorSnackbar} = useErrorSnackbar();

  const handleShareClick = React.useCallback(async () => {
    const existingLinks = await existingLinksPromise;
    const maxVersionNumber = versionSummaries?.reduce((a, b) => Math.max(a, b.version_num), 1) ?? 1;

    if (existingLinks != null && existingLinks !== 'unfetched') {
      initializeShareModal({
        id: videoId,
        type: 'file',
        name: name,
        thumbnailUrl: thumbnailUrl,
        versionSummaries: versionSummaries ?? [],
        defaultVersion: currentVersionNumber ?? maxVersionNumber,
        logEvent: logEvent,
        location: 'browse_context_menu',
        isDemo: isDemo,
        isLiveReview: false,
        linkItemType: 'file',
        basicLinks: existingLinks.basicLinks,
        watermarkLinks: existingLinks.watermarkLinks,
        sharingIsRestrictedByTeamPolicy: existingLinks.sharingIsRestrictedByTeamPolicy,
      });
      setIsShareModalOpen(true);
    } else {
      setShareErrorSnackbar({type: 'link-fetch-failed'});
    }
  }, [
    currentVersionNumber,
    existingLinksPromise,
    initializeShareModal,
    isDemo,
    logEvent,
    name,
    setIsShareModalOpen,
    setShareErrorSnackbar,
    thumbnailUrl,
    versionSummaries,
    videoId,
  ]);

  const wrappedHandleToggle: WrapperOnToggle = ({isOpen}: {isOpen: boolean}) => {
    setIsMenuOpen(isOpen);

    if (isOpen && existingLinksPromise === 'unfetched') {
      refetchLinks();
    }

    handleToggle({isOpen});
  };

  const {canEdit, isOwner} = getCurrentUserPermissions(parentFolderPermission, ownerUid);

  const currentUserHasSuperAdminPerms =
    useAreSuperAdminFeaturesEnabled() && parentFolderPermission === 'super_admin';

  // are there multiple versions?
  const shouldShowCompareVersionsButton =
    versionSummaries && versionSummaries.length > 1 && mediaType !== MediaType.Document;

  const setDateModalInfo = useSetAtom(dueDateModalCurrentVersionInfoAtom);
  const setShowDueDateVersionModal = useSetAtom(dueDateVersionModalAtom);

  const handleDueDateVersionModal = React.useCallback(async () => {
    const updated_task = await getTasksForItem(videoId);
    const async_task_state = {
      isLoading: false,
      videoId: videoId,
      task: updated_task,
    };

    if (!async_task_state.isLoading && async_task_state.task?.dueDate) {
      setDateModalInfo({
        videoId: videoId,
        projectName: name,
        versionNumber: currentVersionNumber,
        projectId: projectId,
      });
      setShowDueDateVersionModal(true);
    }
  }, [
    currentVersionNumber,
    name,
    projectId,
    setDateModalInfo,
    setShowDueDateVersionModal,
    videoId,
  ]);

  const onUploadFilePick = React.useCallback(
    async (
      files: FileList | (File | StreamableFile)[],
      onFileUploadComplete?: (upload: DirectUpload, fileId: string, videoVersionId: string) => void,
    ) => {
      if (videoNsId && files.length === 1) {
        const [file] = files;
        uploadVersion({
          onCreateVersion: (fileId: string) => {
            return onVersionSelected(fileId, 'file_id', 'direct_upload', false);
          },
          file,
          nsId: videoNsId,
          ownerUid,
          onFileUploadComplete: handleDueDateVersionModal,
          clickSource: 'browse_expanded_menu',
        });
      }
    },
    [handleDueDateVersionModal, onVersionSelected, uploadVersion, videoNsId, ownerUid],
  );

  const onUploadActiveSequenceClick = React.useCallback(() => {
    if (redCarpet.mode === RedCarpetMode.PPRO) {
      // eslint-disable-next-line deprecation/deprecation
      logEvent('select_upload_active_sequence', {click_source: 'context_menu'});
    } else if (redCarpet.mode === RedCarpetMode.AEFT) {
      // eslint-disable-next-line deprecation/deprecation
      logEvent('select_upload_active_composition', {click_source: 'context_menu'});
    }

    openRenderModal((file, autoSyncComments, markersData) => {
      if (file) {
        onUploadFilePick([file], (upload: DirectUpload, fileId: string, videoVersionId: string) => {
          if (projectId && upload.videoId && videoVersionId) {
            linkVideoToAdobeVideo({
              projectId,
              videoId: upload.videoId,
              videoVersionId,
              autoSyncComments,
              markersData,
            });
            if (markersData) {
              importMarkersAsComments(projectId, upload.videoId, videoVersionId, markersData);
            }

            if (redCarpet.mode === RedCarpetMode.PPRO) {
              // eslint-disable-next-line deprecation/deprecation
              logEvent('upload_active_sequence_complete');
            } else if (redCarpet.mode === RedCarpetMode.AEFT) {
              // eslint-disable-next-line deprecation/deprecation
              logEvent('upload_active_composition_complete');
            }
          }
        });
      }
    });
  }, [
    redCarpet.mode,
    openRenderModal,
    logEvent,
    onUploadFilePick,
    projectId,
    linkVideoToAdobeVideo,
    importMarkersAsComments,
  ]);

  const handleArchive = React.useCallback(async () => {
    const snackbarSuccessHandler = () => {
      setBrowseSnackbar({
        status: BrowseSnackbarStatus.ARCHIVE,
        archiveStatus: 'start',
        onRequestClose: () =>
          setBrowseSnackbar({
            status: BrowseSnackbarStatus.HIDDEN,
          }),
      });
    };

    const snackbarFailureHandler = () => {
      setBrowseSnackbar({
        status: BrowseSnackbarStatus.ARCHIVE,
        archiveStatus: 'submit_failed',
        onRequestClose: () =>
          setBrowseSnackbar({
            status: BrowseSnackbarStatus.HIDDEN,
          }),
      });
    };
    chooseArchiveFolder({
      latestVideoVersionIds: versionSummaries ? [versionSummaries[0].video_version_id] : [],
      allVideoVersionIds: versionSummaries?.map((video) => video.video_version_id) ?? [],
      snackbarSuccessHandler,
      snackbarFailureHandler,
    });
  }, [chooseArchiveFolder, setBrowseSnackbar, versionSummaries]);

  const isRootFolder = breadcrumbPath && breadcrumbPath.length == 1;
  const papAccessType = useGetPapAccessType({accessLevel: currentFolderPermission});

  return (
    <div>
      <div
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Menu.Wrapper
          onSelection={async (value: string) => {
            switch (value) {
              case 'dropbox-upload':
                // eslint-disable-next-line deprecation/deprecation
                logEvent('select_new_version', {
                  click_source: 'browse_expanded_menu',
                  media_source: 'dropbox',
                });
                if (onVersionSelected) {
                  chooseVersionUpload({
                    onCreateVersion: (fileId: string, uploadId?: string) => {
                      return onVersionSelected(fileId, 'file_id', 'dropbox', true, uploadId);
                    },
                    onAllUploadsComplete: handleDueDateVersionModal,
                    clickSource: 'browse_expanded_menu',
                  });
                }
                break;
              case 'direct-upload':
                // eslint-disable-next-line deprecation/deprecation
                logEvent('select_new_version', {
                  click_source: 'browse_expanded_menu',
                  media_source: 'direct_upload',
                });
                chooseFiles({
                  onFilesPick: onUploadFilePick,
                  multiple: false,
                  uploadType: 'version',
                });
                break;
              case 'gdrive-upload':
                // eslint-disable-next-line deprecation/deprecation
                logEvent('select_new_version', {
                  click_source: 'browse_expanded_menu',
                  media_source: 'gdrive',
                });
                if (videoNsId) {
                  startGDriveVersionImport({
                    nsId: videoNsId,
                    onCreateVersion: (fileId: string) => {
                      handleDueDateVersionModal();
                      return onVersionSelected(fileId, 'file_path', 'gdrive', false);
                    },
                    clickSource: 'browse_expanded_menu',
                  });
                }
                break;
              case 'onedrive-upload':
                // eslint-disable-next-line deprecation/deprecation
                logEvent('select_new_version', {
                  click_source: 'browse_expanded_menu',
                  media_source: 'onedrive',
                });
                if (videoNsId) {
                  startOneDriveVersionImport({
                    nsId: videoNsId,
                    onCreateVersion: (fileId: string) => {
                      handleDueDateVersionModal();
                      return onVersionSelected(fileId, 'file_path', 'onedrive', false);
                    },
                    logEvent: logEvent,
                  });
                }
                break;
              case 'compare-versions':
                if (showUpsellModal && !isVersionComparisonProvisioned) {
                  setUpsellModalIsOpen(true);
                }
                if (
                  versionSummaries &&
                  isVersionComparisonProvisioned &&
                  shouldShowCompareVersionsButton
                ) {
                  const latestVersions = getLatestTwoVersions(versionSummaries);
                  const compareUrl = generateVersionComparePageUrl({
                    projectId,
                    videoId,
                    nextLatestVideoVersionId: latestVersions.nextLatestVersion.video_version_id,
                    latestVideoVersionId: latestVersions.latestVersion.video_version_id,
                  });
                  navigate(compareUrl);
                }
                break;
              case 'share':
              case 'share-review':
              case 'share-view-only':
                // eslint-disable-next-line deprecation/deprecation
                logEvent('select_overflow_menu_share');
                // eslint-disable-next-line deprecation/deprecation
                logEvent('select_reel_share', {
                  link_item_type: 'file',
                  location: 'browse_context_menu',
                  replay_media_type: 'none',
                  experiment_variant: 'none',
                });
                setModalState('share modal');
                handleShareClick();
                break;
              case 'copy-link':
                handleCompleteStep(STEPS.SHARE_FILE);

                // eslint-disable-next-line deprecation/deprecation
                logEvent('copy_reel_share_link', {
                  access_type: 'review',
                  link_item_type: 'file',
                  is_onboarding_share_step: toBooleanAttr(
                    handleCheckIsCurrentStepOpen(STEPS.SHARE_FILE),
                  ),
                  replay_media_type: 'none',
                  experiment_variant: 'none',
                });
                const shareLink = await createShareLink({
                  isFolder: false,
                  viewOnly: false,
                  downloadsDisabled: false,
                  passwordEnabled: false,
                  password: '',
                  loggedOutAccessDisabled: false,
                  viewPreviousCommentsDisabled: false,
                });
                await copyLinkToClipboard(shareLink);
                break;
              case 'remove':
                // eslint-disable-next-line deprecation/deprecation
                logEvent('select_overflow_menu_remove');
                setModalState('remove modal');
                break;
              case 'rename':
                // eslint-disable-next-line deprecation/deprecation
                logEvent('select_overflow_menu_rename');
                setModalState('rename modal');
                break;
              case 'get-metadata':
                // eslint-disable-next-line deprecation/deprecation
                logEvent('select_overflow_menu_get_metadata');
                setModalState('metadata modal');
                break;
              case 'move':
                if (!editActionsProps.editActionsEnabled) {
                  break;
                }
                // eslint-disable-next-line deprecation/deprecation
                logEvent('select_overflow_menu_move');
                editActionsProps.setLeftRailStatus({
                  status: 'move',
                  items: {
                    itemType: 'ProjectTile',
                    projectId,
                    projectName: name,
                  },
                });
                break;
              case 'copy':
                if (!editActionsProps.editActionsEnabled) {
                  break;
                }
                // eslint-disable-next-line deprecation/deprecation
                logEvent('select_overflow_menu_copy');
                editActionsProps.setLeftRailStatus({
                  status: 'copy',
                  items: {
                    itemType: 'ProjectTile',
                    projectId,
                    projectName: name,
                  },
                });
                break;
              case 'transfer-ownership':
                // eslint-disable-next-line deprecation/deprecation
                logEvent('select_overflow_menu_transfer_ownership');
                setModalState('transfer ownership modal');
                break;
              case 'archive-dropbox':
                handleArchive();
                break;
              case 'manage-people':
                // eslint-disable-next-line deprecation/deprecation
                logEvent('select_overflow_menu_manage_people');
                setModalState('manage people modal');
                break;
              case 'download':
                logger.logPap(
                  PAP_Select_WorkspaceAction({
                    workspaceAction: 'download',
                    actionElement: 'overflow_menu',
                    videoId: videoId,
                    projectId: projectId,
                    folderLevel: breadcrumbPath?.length,
                    contentType: 'asset',
                    deviceId: logger.deviceId,
                    accessType: papAccessType,
                    creatorId: isOwner ? getCurrentUser()?.user_pid : undefined,
                  }),
                );
                try {
                  const res = await getVideoDownloadLink({
                    videoId: videoId,
                    videoVersionId: '',
                    analyticsData: {
                      logger: logger,
                      papAccessType: papAccessType,
                    },
                  });
                  const link = res.link;
                  download({url: link});
                } catch {
                  openErrorSnackbar(
                    intl.formatMessage({
                      defaultMessage: `Something went wrong. Try again later.`,
                      description: 'Error message when an unknown error occurs',
                      id: 'P33QYe',
                    }),
                  );
                }
                break;
            }
          }}
          onToggle={wrappedHandleToggle}
          slideSubmenu={isSmallScreenSize}
        >
          {({getContentProps, getTriggerProps}) => (
            <>
              <ContextButton
                {...getTriggerProps()}
                aria-label={intl.formatMessage({
                  defaultMessage: 'Actions menu',
                  id: 'M9rAn1',
                  description:
                    'Aria label for button that opens menu containing actions you can perform on a file.',
                })}
                size="small"
                variant="borderless"
              >
                <UIIcon size="small" src={MoreHorizontalLine} />
              </ContextButton>
              <Menu.Content {...getContentProps()} open={isMenuOpen}>
                <Menu.Segment>
                  {canEdit && !isAdminPageRoute && (
                    <>
                      <Menu.ActionItem
                        data-safe-to-unmask-name="file-thumbnail-menu-share"
                        key="share"
                        value="share"
                        withLeftAccessory={<UIIcon src={SendLine} />}
                      >
                        <FormattedMessage
                          defaultMessage="Share"
                          description="Text for a menu item that lets you share the file with someone else."
                          id="WBBNRV"
                        />
                      </Menu.ActionItem>
                      <Menu.ActionItem
                        data-safe-to-unmask-name="project-thumbnail-menu-copy-link"
                        key="copy-link"
                        value="copy-link"
                        withLeftAccessory={<UIIcon src={LinkLine} />}
                      >
                        <FormattedMessage
                          defaultMessage="Copy link"
                          description="Text for a menu item that lets you share the file with someone else by directly copying the share link."
                          id="WEJ8UJ"
                        />
                      </Menu.ActionItem>
                    </>
                  )}
                  {canEdit && (
                    <Menu.ActionItem
                      data-safe-to-unmask-name="file-thumbnail-menu-rename"
                      key="rename"
                      value="rename"
                      withLeftAccessory={<UIIcon src={EditLine} />}
                    >
                      <FormattedMessage
                        defaultMessage="Rename"
                        description="Text for a menu item that lets you rename a file."
                        id="vR/8w9"
                      />
                    </Menu.ActionItem>
                  )}
                  <Menu.ActionItem
                    key="get-metadata"
                    value="get-metadata"
                    withLeftAccessory={<UIIcon src={InfoLine} />}
                  >
                    <FormattedMessage
                      defaultMessage="File Info"
                      description="Text for a media project to provide more metadata info to the user."
                      id="B6qCXc"
                    />
                  </Menu.ActionItem>
                  {canEdit && !isSmallScreenSize && !isAdminPageRoute && (
                    <Menu.ActionItem
                      data-safe-to-unmask-name="file-thumbnail-menu-move"
                      key="move"
                      value="move"
                      withLeftAccessory={<UIIcon src={MoveLine} />}
                    >
                      {isRootFolder ? (
                        <FormattedMessage
                          defaultMessage="Move to project"
                          description="Text for a menu item that lets you move a file to another project."
                          id="2An5J2"
                        />
                      ) : (
                        <FormattedMessage
                          defaultMessage="Move to folder"
                          description="Text for a menu item that lets you move a file to another folder in the project."
                          id="PnNB7X"
                        />
                      )}
                    </Menu.ActionItem>
                  )}
                  {canEdit && (
                    <Menu.ActionItem
                      data-safe-to-unmask-name="file-thumbnail-menu-download"
                      key="download"
                      value="download"
                      withLeftAccessory={<UIIcon src={DownloadLine} />}
                    >
                      <FormattedMessage
                        defaultMessage="Download"
                        description="Text for a menu item that lets you download a file."
                        id="K6PJnI"
                      />
                    </Menu.ActionItem>
                  )}
                  {!isRootFolder && canEdit && !isSmallScreenSize && !isAdminPageRoute ? (
                    <Menu.ActionItem
                      data-safe-to-unmask-name="file-thumbnail-menu-copy"
                      key="copy"
                      value="copy"
                      withLeftAccessory={<UIIcon src={CopyLine} />}
                    >
                      <FormattedMessage
                        defaultMessage="Copy to project"
                        description="Text for a menu item that lets you copy a file to another project."
                        id="k41/nr"
                      />
                    </Menu.ActionItem>
                  ) : null}
                  {!isTeamLocked && canEdit && !isAdminPageRoute ? (
                    <Menu.Submenu
                      data-safe-to-unmask-name="file-thumbnail-menu-upload-new-version"
                      withLeftAccessory={<UIIcon role="presentation" src={UploadLine} />}
                      withTriggerContent={
                        <FormattedMessage
                          defaultMessage="New version"
                          description="Text for a menu item that lets you upload a new version."
                          id="TSBo4I"
                        />
                      }
                    >
                      <Menu.Segment>
                        <Menu.ActionItem
                          data-safe-to-unmask-name="file-thumbnail-upload-dropbox"
                          key="dropbox-upload"
                          value="dropbox-upload"
                          withLeftAccessory={<UIIcon src={DropboxLine} />}
                        >
                          <DropboxUploadText />
                        </Menu.ActionItem>
                        <Menu.ActionItem
                          data-safe-to-unmask-name="file-thumbnail-upload-local-drive"
                          key="direct-upload"
                          value="direct-upload"
                          withLeftAccessory={<UIIcon src={UploadLine} />}
                        >
                          <DirectUploadText />
                        </Menu.ActionItem>
                        {redCarpet.features.uploadActiveSequence && (
                          <Menu.ActionItem
                            onClick={onUploadActiveSequenceClick}
                            withLeftAccessory={<UIIcon src={UploadLine} />}
                          >
                            <FormattedMessage
                              defaultMessage="Upload Active Sequence"
                              description="Text for button that uploads the active sequence and creates a new version"
                              id="y11oYe"
                            />
                          </Menu.ActionItem>
                        )}
                        {redCarpet.features.uploadActiveComposition && (
                          <Menu.ActionItem
                            onClick={onUploadActiveSequenceClick}
                            withLeftAccessory={<UIIcon src={UploadLine} />}
                          >
                            <FormattedMessage
                              defaultMessage="Upload Active Composition"
                              description="Text for button that uploads the active composition and creates a new version"
                              id="A4w4lc"
                            />
                          </Menu.ActionItem>
                        )}
                        <Menu.ActionItem
                          data-safe-to-unmask-name="file-thumbnail-upload-gdrive"
                          key="gdrive-upload"
                          value="gdrive-upload"
                          withLeftAccessory={<GoogleDriveIcon />}
                        >
                          <FormattedMessage
                            defaultMessage="Google Drive"
                            description="Text on a menu item that opens a Google Drive file picker"
                            id="5ixv4r"
                          />
                        </Menu.ActionItem>
                        <Menu.ActionItem
                          data-safe-to-unmask-name="file-thumbnail-upload-onedrive"
                          key="onedrive-upload"
                          value="onedrive-upload"
                          withLeftAccessory={<OneDriveIcon />}
                        >
                          <FormattedMessage
                            defaultMessage="Microsoft OneDrive"
                            description="Text on a menu item that opens a OneDrive file picker"
                            id="SXxT+y"
                          />
                        </Menu.ActionItem>
                      </Menu.Segment>
                    </Menu.Submenu>
                  ) : null}
                  {currentUserHasSuperAdminPerms && (
                    <Menu.ActionItem
                      key="transfer-ownership"
                      value="transfer-ownership"
                      withLeftAccessory={<UIIcon src={PersonMultipleLine} />}
                    >
                      <FormattedMessage
                        defaultMessage="Transfer ownership"
                        description="Text that lets a super admin transfer ownership to another member on the team."
                        id="yoNiwO"
                      />
                    </Menu.ActionItem>
                  )}
                  {shouldShowCompareVersionsButton &&
                    versionSummaries &&
                    versionSummaries.length > 1 &&
                    areLatestTwoVersionsSameMediaType({
                      latestVersions: getLatestTwoVersions(versionSummaries),
                    }) && (
                      <Menu.ActionItem
                        data-safe-to-unmask-name="file-thumbnail-compare-versions"
                        interactive={true} // can't make this disabled because then we don't get div mouse actions
                        key="compare-versions"
                        value="compare-versions"
                        withLeftAccessory={<UIIcon src={GifLine} />}
                      >
                        <div
                          onMouseEnter={() => {
                            if (!isVersionComparisonProvisioned) {
                              setShowVersionComparisonPremiumTooltip(true);
                            }
                          }}
                          onMouseLeave={() => setShowVersionComparisonPremiumTooltip(false)}
                          ref={versionComparisonTriggerRef}
                        >
                          <Text
                            color={
                              showUpsellModal || isVersionComparisonProvisioned
                                ? 'standard'
                                : 'disabled'
                            }
                          >
                            <FormattedMessage
                              defaultMessage="Compare versions"
                              description="Text for menu item that opens the media version comparison page"
                              id="IMWMxm"
                            />
                          </Text>
                          {!showUpsellModal && !isVersionComparisonProvisioned && (
                            <LockLine color="white" width={20} />
                          )}
                          {!showUpsellModal && (
                            <PremiumTooltip
                              hideClickOutside={true}
                              onClose={() => {
                                setShowVersionComparisonPremiumTooltip(false);
                              }}
                              open={showVersionComparisonPremiumTooltip}
                              placement="bottom"
                              triggerRef={versionComparisonTriggerRef}
                            />
                          )}
                        </div>
                      </Menu.ActionItem>
                    )}
                  {isArchivingProvisioned && isOwner && versionSummaries?.length ? (
                    <Menu.ActionItem
                      key="archive-dropbox"
                      value="archive-dropbox"
                      withLeftAccessory={<UIIcon src={DropboxLine} />}
                    >
                      <FormattedMessage
                        defaultMessage="Archive to Dropbox"
                        description="Text for menu item that allows a user to archive the file to Dropbox"
                        id="cbeoJ1"
                      />
                    </Menu.ActionItem>
                  ) : null}
                  {parentFolderPermission !== 'reviewer' ? (
                    <Menu.ActionItem
                      data-safe-to-unmask-name="file-thumbnail-delete-or-hide"
                      key="remove"
                      value="remove"
                      withLeftAccessory={<RemoveIcon src={canEdit ? DeleteLine : HideLine} />}
                    >
                      <RemoveText>
                        {canEdit ? (
                          <FormattedMessage
                            defaultMessage="Delete"
                            description="Text for a menu item that lets you delete a file."
                            id="5USlAO"
                          />
                        ) : (
                          <FormattedMessage
                            defaultMessage="Hide"
                            description="Text for a menu item that lets you hide a file from view."
                            id="HwmBlF"
                          />
                        )}
                      </RemoveText>
                    </Menu.ActionItem>
                  ) : null}
                </Menu.Segment>
              </Menu.Content>
            </>
          )}
        </Menu.Wrapper>
        <RenameModal
          id={projectId}
          itemType={ItemType.PROJECT}
          logEvent={logEvent}
          onRename={onRename}
          open={modalState === 'rename modal'}
          originalName={name}
          requestClose={closeModal}
          updateModifyItemSnackbar={updateModifyItemSnackbar}
        />
        <RemoveModal
          id={projectId}
          isHide={!isOwner && parentFolderPermission == 'none'}
          isOwner={isOwner}
          itemType={ItemType.PROJECT}
          name={name}
          onDelete={onDelete}
          open={modalState === 'remove modal'}
          requestClose={closeModal}
          updateModifyItemSnackbar={updateModifyItemSnackbar}
        />
        <TransferOwnershipModal
          currentOwnerAccountId={ownerUid ?? ''}
          isHide={!currentUserHasSuperAdminPerms}
          isOwner={isOwner}
          itemType={ItemType.PROJECT}
          logEvent={logEvent}
          onTransfer={onTransfer}
          open={modalState === 'transfer ownership modal'}
          projectId={projectId}
          projectName={name}
          requestClose={closeModal}
        />
        <AssetInfoModal
          assetId={videoId}
          open={modalState === 'metadata modal'}
          requestClose={closeModal}
        />
        {showUpsellModal && (
          <BasicAddOnUpsellModal
            clickSource="context_menu"
            onClose={() => setUpsellModalIsOpen(false)}
            open={upsellModalIsOpen}
            variant="version_comparison"
          />
        )}
      </div>
    </div>
  );
};
