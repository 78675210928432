import type React from 'react';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import {useQueryClient} from '@tanstack/react-query';
import {PAP_Link_DbxOnedriveAccount} from 'pap-events/replay/link_dbx_onedrive_account';

import {AccountLinkState} from './link_flow_state';
import {useReelAppGlobalState} from '../../context';
import {DBX_CLIENT_DOMAIN} from '../../context_utils';
import {useOnedriveTokenPoll} from '../../state/onedrive_token';
import type {LoggingClient} from '../logging/logger';
import {replayApi} from '../query_client';

const METASERVER_ORIGIN =
  DBX_CLIENT_DOMAIN && DBX_CLIENT_DOMAIN.includes('dev.corp')
    ? `https://meta-${DBX_CLIENT_DOMAIN}`
    : `https://www.dropbox.com`;
const PROFILE_SERVICES_ROUTE = '/profile_services/start_auth_flow';

const getAuthFlowUrl = (userId: number) => {
  const params = new URLSearchParams({
    service: 'outlook',
    action: 'link_read_files',
    user_id: userId.toString(10),
    is_popup: 'true',
    show_splash: 'true',
  });
  const url = new URL(PROFILE_SERVICES_ROUTE, METASERVER_ORIGIN);
  url.search = params.toString();
  return url.toString();
};

const getAuthFlowFeatures = () => {
  return 'width=600,height=600';
};

type WindowRef = React.MutableRefObject<Window | null>;

const openOauthPopupWindow = (userId: number | undefined): Window | null => {
  if (userId) {
    const url = getAuthFlowUrl(userId);
    const features = getAuthFlowFeatures();
    return window.open(url, 'OAUTH_POPUP', features);
  }
  return null;
};

const usePopup = (
  openWindow: () => Window | null,
  onPopupManuallyClosed: () => void,
  refreshQuery: () => void,
) => {
  const popupWindow: WindowRef = useRef<Window | null>(null);

  const close = useCallback(() => {
    popupWindow.current?.close();
    popupWindow.current = null;
  }, []);

  const open = useCallback(() => {
    close();
    popupWindow.current = openWindow();
    refreshQuery();
    return popupWindow.current != null;
  }, [close, openWindow, refreshQuery]);

  const isOpen = useCallback(() => popupWindow.current != null, []);

  // Checks every second to see if the window has been closed.
  const checkPopupClosed = useCallback(() => {
    if (popupWindow.current?.closed) {
      close();
      onPopupManuallyClosed();
    }
  }, [close, onPopupManuallyClosed]);
  useEffect(() => {
    const interval = setInterval(checkPopupClosed, 1000);
    return () => clearInterval(interval);
  }, [checkPopupClosed]);

  return useMemo(() => ({open, close, isOpen}), [open, close, isOpen]);
};

export const useLinkFlow = ({
  isModalOpen,
  loggingClient,
}: {
  isModalOpen: boolean;
  loggingClient: LoggingClient;
}) => {
  const globalState = useReelAppGlobalState();
  const userId = globalState.status === 'logged in' ? globalState.currentAccount.id : undefined;

  const isModalOpenRef = useRef(isModalOpen);
  useEffect(() => {
    isModalOpenRef.current = isModalOpen;
  }, [isModalOpen]);

  const queryClient = useQueryClient();
  const onedrivePoll = useOnedriveTokenPoll();

  const [state, setState] = useState<AccountLinkState>(AccountLinkState.CLOSED);

  const openWindow = useCallback(() => openOauthPopupWindow(userId), [userId]);
  const popup = usePopup(
    openWindow,
    () => {
      setState(AccountLinkState.POPUP_INACTIVE);
    },
    async () => {
      queryClient.invalidateQueries(replayApi.getOnedriveToken());
      await onedrivePoll({refetch: () => isModalOpenRef.current && popup.isOpen()});
      setState(AccountLinkState.DONE);
      popup.close();
    },
  );

  const openPopup = useCallback(() => {
    const popupOpenSuccess = popup.open();
    setState(popupOpenSuccess ? AccountLinkState.POPUP_ACTIVE : AccountLinkState.POPUP_INACTIVE);
  }, [popup, setState]);

  // Effect for reconciling modal open state and popup open state
  useEffect(() => {
    if (isModalOpen && state === AccountLinkState.CLOSED) {
      // When modal opens / state is right, open the popup window
      openPopup();
    } else if (!isModalOpen && state !== AccountLinkState.CLOSED) {
      if (state === AccountLinkState.POPUP_ACTIVE) {
        loggingClient.logPap(PAP_Link_DbxOnedriveAccount({}));
        // eslint-disable-next-line deprecation/deprecation
        loggingClient.logEvent('link_dbx_onedrive_account');
      }
      // If modal is ever closed, close child window and update state
      popup.close();
      setState(AccountLinkState.CLOSED);
    } else if (popup.isOpen() && state !== AccountLinkState.POPUP_ACTIVE) {
      // If auth window is still open when we don't expect it to be, close it.
      popup.close();
    }
  }, [isModalOpen, popup, openPopup, state, setState, loggingClient]);

  return {state, openPopup};
};
