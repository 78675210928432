import React from 'react';

import {useIntl} from 'react-intl';

import {Button} from '~/components/button';
import {ReplayError, ReplayErrorCategory, reportException} from '~/lib/error_reporting';
import {useOnce} from '~/lib/use_once';
import {ErrorPageLayout} from '~/pages/components/error_page_layout';

import {Page} from './components/page';
import {UNCAUGHT_ERROR_VIEW_MARK} from '../lib/performance_measures';

type ErrorPageProps = {
  // This is an optional override to determine where the back button takes us
  customHomeRedirect?: () => void;
  // This is an optional formatted message that should be passed in
  summaryText?: string;
  errorToReport: 'OPT_OUT_OF_ERROR_REPORTING' | Error;
};

let isNavigating = false;
window.addEventListener('beforeunload', () => {
  isNavigating = true;
});

export const ErrorPage = (props: React.PropsWithChildren<ErrorPageProps>) => {
  const {customHomeRedirect, summaryText} = props;

  React.useEffect(() => {
    performance.mark(UNCAUGHT_ERROR_VIEW_MARK);
  }, []);

  useOnce(() => {
    if (props.errorToReport !== 'OPT_OUT_OF_ERROR_REPORTING') {
      reportException(
        new ReplayError({
          severity: 'critical',
          tags: ['page_crashed'],
          category: ReplayErrorCategory.UncaughtException,
          error: props.errorToReport,
        }),
      );
    }
  });

  const home = React.useCallback(() => {
    window.location.assign('/');
  }, []);

  const intl = useIntl();

  if (isNavigating) return null; // Prevent flash of error page during navigation

  const title = intl.formatMessage({
    defaultMessage: 'Unexpected error',
    id: '75nJnb',
    description:
      'Title that shows under the illustration on the error page when there has been a fatal error and the app has crashed. This text is also used in the title of the browser tab.',
  });

  const redirectReplayCommunityForum = () => {
    window.location.replace('https://www.dropboxforum.com/t5/Dropbox-Replay/bd-p/1009forum-board');
  };

  const redirectHome = customHomeRedirect || home;

  return (
    <Page title={title}>
      <ErrorPageLayout
        actionButton={
          <Button onClick={redirectReplayCommunityForum} variant="opacity">
            {intl.formatMessage({
              defaultMessage: 'Contact us',
              id: '6I7Fe+',
              description:
                'Text for button prompting user to view the Dropbox Replay community forum',
            })}
          </Button>
        }
        secondaryActionButton={
          <Button onClick={redirectHome} variant="primary">
            {intl.formatMessage({
              defaultMessage: 'Back to home',
              id: '02ZrAD',
              description:
                'Text for button prompting user to go back to the home page of Replay when they stumble on a bad link and they are seeing the 404 page',
            })}
          </Button>
        }
        summaryText={
          summaryText ??
          intl.formatMessage({
            defaultMessage: "Something's gone wrong - let's get back on track.",
            id: 'e2ul0D',
            description:
              'Subtitle for the illustration on the error page when there has been a fatal error and the app has crashed',
          })
        }
        titleText={title}
      />
    </Page>
  );
};
