// Dropbox API credentials - Devbox / Production
export const DBX_CLIENT_DOMAIN: string | undefined = import.meta.env.VITE_DBX_CLIENT_DOMAIN;
export const IS_DBX_DEVBOX = import.meta.env.VITE_DBX_BUILD_MODE === 'devbox';

export const DBX_WSS_ADDRESS: string =
  import.meta.env.VITE_DBX_WSS_ADDRESS ?? 'wss://replay-api-live.dropbox.com/ws';
export const DBX_COLLAB_SERVICE_AUTH_ADDRESS: string =
  import.meta.env.VITE_DBX_COLLAB_SERVICE_AUTH_ADDRESS ??
  'https://replay-api-live.dropbox.com/auth';
export const DBX_CLIENT_ID: string = import.meta.env.VITE_DBX_CLIENT_ID ?? 'xmh41stq9e08247';
export const DBX_CLIENT_SECRET: string =
  import.meta.env.VITE_DBX_CLIENT_SECRET ?? 'kzy400cxa82kjvr';

export const DBX_BUILD_MODE = import.meta.env.VITE_DBX_BUILD_MODE;

export const REPLAY_BASE_URL = window.location.origin;

export const APP_BUILD_VERSION: string | undefined = import.meta.env.APP_BUILD_VERSION;

export const TUTORIALS_ROUTE = import.meta.env.VITE_TUTORIALS_ROUTE ?? 'mYaR4zupy2OTzszd';
