import type {DropboxOptions} from '@dropbox/api-v2-client';
import {Dropbox, DropboxAuth} from '@dropbox/api-v2-client';

import type {CurrentAccount} from '~/context';
import {DBX_CLIENT_DOMAIN, DBX_CLIENT_ID, DBX_CLIENT_SECRET} from '~/context_utils';

import {createAsyncProxy} from './async_proxy';
import {check} from './checks';
import {replayStorage} from './storage';

type ClientState = {
  client: Dropbox;
  auth: DropboxAuth;
  options: DropboxOptions;
};

let clientState: ClientState | {[K in keyof ClientState]: undefined} = {
  client: undefined,
  auth: undefined,
  options: undefined,
};

let initPromise: Promise<Dropbox> | undefined;

/** Idempotent dropbox client creation */
function initialize() {
  if (initPromise) {
    return dropboxSdk;
  }

  const {options, auth, client} = createClientFromStorage();

  if (client instanceof Promise) {
    initPromise = client;
    clientState = {
      client: createAsyncProxy(client),
      auth,
      options,
    };
  } else {
    initPromise = Promise.resolve(client);
    clientState = {
      client,
      auth,
      options,
    };
  }

  return dropboxSdk;
}

function createClientFromStorage() {
  const {refreshToken} = replayStorage.getRefreshToken();

  if (refreshToken) {
    return createClient({refreshToken});
  } else {
    return createGuestClient();
  }
}

/** Create dropbox client suited for logged out experiences */
function createGuestClient() {
  const auth = new DropboxAuth({
    domain: DBX_CLIENT_DOMAIN,
    clientId: DBX_CLIENT_ID,
    clientSecret: DBX_CLIENT_SECRET,
  });

  const options = {
    auth,
    domain: DBX_CLIENT_DOMAIN,
  };
  const client = new Dropbox(options);
  return {client, auth, options};
}

function createClient({refreshToken}: {refreshToken: string}) {
  const auth = new DropboxAuth({
    domain: DBX_CLIENT_DOMAIN,
    clientId: DBX_CLIENT_ID,
    refreshToken,
  });

  const options = {
    auth,
    domain: DBX_CLIENT_DOMAIN,
  };

  // checkAndRefreshAccessToken is awaitable even though the type definition is
  // void
  // https://github.com/dropbox/dropbox-sdk-js/blob/6bfaf895b757d7bd1e65002847a7c801813cc210/src/auth.js#L340
  // https://dropbox.sourcegraphcloud.com/github.com/dropbox-internal/capture-desktop@e9d136afe7b1ca16cb23d553fea4dae855c6b341/-/blob/src/api/auth.ts?L242
  //
  // We technically don't need to predicate access to SDK functions on this
  // completing, as inspecting the dropbox client sdk shows that every RPC call
  // also invokes "checkAndRefreshAccessToken", but by proxying access we
  // achieve better performance and fewer API calls. Imagine this function is
  // called at time 0, and takes until time 100 to complete. If 5 other SDK
  // operations are requested at time 99, then each of them will be waterfall'ed
  // from their own request. So instead of the RPCs starting at time 100, they
  // will start at time 199.
  //
  // @ts-expect-error
  const prom: Promise<unknown> = auth.checkAndRefreshAccessToken();
  return {options, auth, client: prom.then(() => new Dropbox(options))};
}

/**
 * Returns a dropbox client that *appears* fully initialized (in reality it may
 * wait for the auth refresh request to finish before sending the request)
 */
export function getDefaultUserClient(): Dropbox {
  return dropboxSdk.client;
}

/** Set the default client for storybook */
export function __setDefaultUserClient(client: Dropbox) {
  (clientState as any).client = client;
}

export const dropboxSdk = {
  initialize,

  /** Return a new dropbox client scoped to a given root */
  withPathRoot: (nsid: number) =>
    new Dropbox({
      ...clientState.options,
      pathRoot: `{".tag": "namespace_id", "namespace_id": "${nsid}" }`,
    }),

  /** Return a new dropbox client that can be aborted */
  asAbortable: (signal: AbortSignal) =>
    new Dropbox({
      ...clientState.options,
      fetch: (url: string, options?: RequestInit) => {
        return fetch(url, {signal, ...(options ?? {})});
      },
    }),

  /** Mutably update the current client with a refresh token */
  update: (opts: {refreshToken: string}) => {
    const {options, auth, client} = createClient(opts);
    initPromise = client;
    clientState = {
      client: createAsyncProxy(client),
      auth,
      options,
    };
  },
  get auth() {
    return check(clientState.auth, 'dropbox auth does not exist');
  },

  get client() {
    return check(clientState.client, 'dropbox client does not exist');
  },
};

let user: CurrentAccount | undefined = undefined;

export function setCurrentUser(setThis: CurrentAccount) {
  user = setThis;
}
export function getCurrentUser(): CurrentAccount | undefined {
  return user;
}

declare global {
  interface Window {
    client?: Dropbox;
  }
}
