import {FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';

import {Modal} from '@dropbox/dig-components/modal';
import {Text, Title} from '@dropbox/dig-components/typography';

import {Button} from '~/components/button';

import {spacing} from './styled';
import type {LoggingClient} from '../lib/logging/logger';
import {useLinkFlow} from '../lib/onedrive/link_flow';
import {AccountLinkState} from '../lib/onedrive/link_flow_state';

const ModalBody = styled(Modal.Body)`
  padding-bottom: ${spacing('24')};
`;

const ModalButtons = styled.div`
  display: flex;
  margin-top: ${spacing('16')};
  justify-content: flex-end;
`;

const CloseButton = styled.div`
  margin-left: ${spacing('24')};
`;

type OneDriveTokenModalViewProps = {
  open: boolean;
  disableOpenButton: boolean;
  onClose: () => void;
  openPopup: () => void;
};

const OneDriveTokenModalView = ({
  open,
  disableOpenButton,
  onClose,
  openPopup,
}: OneDriveTokenModalViewProps) => {
  const intl = useIntl();
  const linkButtonText = disableOpenButton
    ? intl.formatMessage({
        defaultMessage: 'Linking...',
        id: 'TR5/lb',
        description:
          'Disabled state of button indicating that we are currently attempting to link their OneDrive account to Dropbox',
      })
    : intl.formatMessage({
        defaultMessage: 'Link OneDrive Account',
        id: 'KIoWn3',
        description:
          'Button that opens a modal allowing users to link their OneDrive account to Dropbox',
      });
  return (
    <Modal
      isCentered
      modalBaseIndex={100000}
      onRequestClose={onClose}
      open={open}
      withCloseButton={intl.formatMessage({
        defaultMessage: 'Close',
        id: 'avbRsR',
        description: 'Text on a button to close a modal',
      })}
    >
      <Modal.Header hasBottomSpacing="title-standard">
        <Title size="medium">
          <FormattedMessage
            defaultMessage="Account Link Required"
            description="Modal title describing that the user must link their 3rd party account to Dropbox to continue"
            id="0yCkWK"
          />
        </Title>
      </Modal.Header>
      <ModalBody>
        <Text variant="paragraph">
          <FormattedMessage
            defaultMessage="Before OneDrive can be used in Replay you must link your OneDrive account to Dropbox. You will be automatically prompted to link your account - if this does not happen, click “Link OneDrive Account” below."
            description="Message explaining that a user needs to link a 3rd party account to Dropbox, with instructions on how to do so from within this modal"
            id="zZ4K6D"
          />
        </Text>
        <ModalButtons>
          <Button
            disabled={disableOpenButton}
            onClick={openPopup}
            size="standard"
            variant="primary"
          >
            {linkButtonText}
          </Button>
          <CloseButton>
            <Button onClick={onClose} size="standard" variant="outline">
              <FormattedMessage
                defaultMessage="Cancel"
                description="Button that closes a modal"
                id="/WEl6m"
              />
            </Button>
          </CloseButton>
        </ModalButtons>
      </ModalBody>
    </Modal>
  );
};

type OneDriveTokenModalProps = {
  open: boolean;
  onClose: () => void;
  loggingClient: LoggingClient;
};

export const OneDriveTokenModal = ({open, onClose, loggingClient}: OneDriveTokenModalProps) => {
  const {state, openPopup} = useLinkFlow({isModalOpen: open, loggingClient});
  const disableOpenButton = state !== AccountLinkState.POPUP_INACTIVE;
  return (
    <OneDriveTokenModalView
      disableOpenButton={disableOpenButton}
      onClose={onClose}
      open={open}
      openPopup={openPopup}
    />
  );
};
