import React from 'react';

import {useAtomValue} from 'jotai';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';

import {Snackbar} from '@dropbox/dig-components/snackbar';
import {UIIcon} from '@dropbox/dig-icons';
import {CheckmarkCircleLine, FailLine} from '@dropbox/dig-icons/assets';

import {Button} from '~/components/button';
import type {ModifyItemSnackbarInfo} from '~/pages/browse_page/browse_page';
import {modifyItemsSnackbarAtom} from '~/state/browse';

import {ItemType} from './common';
import {ReelSnackbar} from './snackbar';

const SnackbarMessage = styled(Snackbar.Message)`
  word-break: break-word;
`;

export type SnackbarStatus = 'success' | 'error';
export type ModifyItemSnackbarProps =
  | {
      type: 'hidden';
      onRequestClose?: () => void;
    }
  | (ModifyItemSnackbarInfo & {
      onRequestClose: () => void;
    });

const ModifyItemSnackbarNonMemo = () => {
  const props = useAtomValue(modifyItemsSnackbarAtom);

  let message;
  switch (props.type) {
    case 'hidden':
      break;
    case 'rename':
      message =
        props.status === 'success' ? (
          props.itemType === ItemType.TEAM_PROJECT ? (
            <FormattedMessage
              defaultMessage="Renamed <b>{name}</b> project to <b>{newName}</b>."
              description="Message to display in snackbar when a project is successfully renamed."
              id="etgEEt"
              values={{
                name: props.originalName,
                newName: props.newName,
                b: (chunks: React.ReactNode) => <b>{chunks}</b>,
              }}
            />
          ) : props.itemType === ItemType.FOLDER ? (
            <FormattedMessage
              defaultMessage="Renamed <b>{name}</b> folder to <b>{newName}</b>."
              description="Message to display in snackbar when a folder is successfully renamed."
              id="76UhUv"
              values={{
                name: props.originalName,
                newName: props.newName,
                b: (chunks: React.ReactNode) => <b>{chunks}</b>,
              }}
            />
          ) : (
            <FormattedMessage
              defaultMessage="Renamed <b>{name}</b> file to <b>{newName}</b>."
              description="Message to display in snackbar when a file is successfully renamed."
              id="65oLbk"
              values={{
                name: props.originalName,
                newName: props.newName,
                b: (chunks: React.ReactNode) => <b>{chunks}</b>,
              }}
            />
          )
        ) : props.itemType === ItemType.TEAM_PROJECT ? (
          <FormattedMessage
            defaultMessage="Couldn't rename <b>{name}</b> project."
            description="Message to display in snackbar when a project failed to rename."
            id="p2P+kX"
            values={{
              name: props.originalName,
              b: (chunks: React.ReactNode) => <b>{chunks}</b>,
            }}
          />
        ) : props.itemType === ItemType.FOLDER ? (
          <FormattedMessage
            defaultMessage="Couldn't rename <b>{name}</b> folder."
            description="Message to display in snackbar when a folder failed to rename."
            id="mulbgv"
            values={{
              name: props.originalName,
              b: (chunks: React.ReactNode) => <b>{chunks}</b>,
            }}
          />
        ) : (
          <FormattedMessage
            defaultMessage="Couldn't rename <b>{name}</b> file."
            description="Message to display in snackbar when a file failed to rename."
            id="DPdOSr"
            values={{
              name: props.originalName,
              b: (chunks: React.ReactNode) => <b>{chunks}</b>,
            }}
          />
        );
      break;
    case 'remove':
      message =
        props.status === 'success' ? (
          props.itemType === ItemType.TEAM_PROJECT ? (
            <FormattedMessage
              defaultMessage="Deleted <b>{name}</b> project."
              description="Message to display in snackbar when a project is successfully removed."
              id="7+7BTg"
              values={{
                name: props.name,
                b: (chunks: React.ReactNode) => <b>{chunks}</b>,
              }}
            />
          ) : props.itemType === ItemType.FOLDER ? (
            <FormattedMessage
              defaultMessage="Deleted <b>{name}</b> folder."
              description="Message to display in snackbar when a folder is successfully removed."
              id="vC/O1I"
              values={{
                name: props.name,
                b: (chunks: React.ReactNode) => <b>{chunks}</b>,
              }}
            />
          ) : (
            <FormattedMessage
              defaultMessage="Deleted <b>{name}</b>{version} file."
              description="Message to display in snackbar when a file is successfully removed, additionally displaying version if it is available."
              id="dE3718"
              values={{
                name: props.name,
                version: props.itemType === ItemType.VERSION ? ` v${props.versionNumber}` : '',
                b: (chunks: React.ReactNode) => <b>{chunks}</b>,
              }}
            />
          )
        ) : props.itemType === ItemType.TEAM_PROJECT ? (
          <FormattedMessage
            defaultMessage="Couldn't remove <b>{name}</b> project."
            description="Message to display in snackbar when a project fails to remove."
            id="lzGcqx"
            values={{
              name: props.name,
              b: (chunks: React.ReactNode) => <b>{chunks}</b>,
            }}
          />
        ) : props.itemType === ItemType.FOLDER ? (
          <FormattedMessage
            defaultMessage="Couldn't remove <b>{name}</b> folder."
            description="Message to display in snackbar when a folder fails to remove."
            id="342wux"
            values={{
              name: props.name,
              b: (chunks: React.ReactNode) => <b>{chunks}</b>,
            }}
          />
        ) : (
          <FormattedMessage
            defaultMessage="Couldn't remove <b>{name}</b>{version} file."
            description="Message to display in snackbar when a file fails to remove, additionally displaying version if it is available."
            id="fYmpex"
            values={{
              name: props.name,
              version: props.itemType === ItemType.VERSION ? ` v${props.versionNumber}` : '',
              b: (chunks: React.ReactNode) => <b>{chunks}</b>,
            }}
          />
        );
      break;
    case 'remove-bulk':
      message =
        props.status === 'success' ? (
          <FormattedMessage
            defaultMessage="Deleted {itemCount} {itemCount, plural, one {item} other {items}}."
            description="Message on the bulk deletion snackbar that informs the user the deletion was a success"
            id="yF1Y/f"
            values={{itemCount: props.itemCount}}
          />
        ) : (
          <FormattedMessage
            defaultMessage="Couldn't delete {itemCount, plural, one {item} other {items}}."
            description="Message on the bulk deletion snackbar that informs the user the deletion failed"
            id="EEzl8L"
            values={{itemCount: props.itemCount}}
          />
        );
      break;
    case 'update-version-status-bulk':
      message =
        props.status === 'success' ? (
          <FormattedMessage
            defaultMessage="Updated {itemCount} {itemCount, plural, one {item's status} other {items' statuses}} to <b>{status, select, NO_STATUS {No status} NEEDS_REVIEW {Needs review} IN_PROGRESS {In progress} APPROVED {Approved} EDITS_REQUESTED {Edits requested} other {No status}}</b>."
            description="Message on the bulk version status update snackbar that informs the user the version status update was a success"
            id="079Lci"
            values={{
              b: (chunks: React.ReactNode) => <b>{chunks}</b>,
              itemCount: props.itemCount,
              status: props.itemStatus,
            }}
          />
        ) : (
          <FormattedMessage
            defaultMessage="Couldn't update {itemCount, plural, one {item} other {items}} to <b>{status, select, NO_STATUS {No status} NEEDS_REVIEW {Needs review} IN_PROGRESS {In progress} APPROVED {Approved} EDITS_REQUESTED {Edits requested} other {No status}}</b>."
            description="Message on the bulk version status update snackbar that informs the user the version status update failed"
            id="O9+Efk"
            values={{
              b: (chunks: React.ReactNode) => <b>{chunks}</b>,
              itemCount: props.itemCount,
              status: props.itemStatus,
            }}
          />
        );
      break;
  }
  return (
    <ReelSnackbar onRequestClose={props.onRequestClose} open={props.type !== 'hidden'}>
      {props.type !== 'hidden' && props.status === 'success' ? (
        <UIIcon src={CheckmarkCircleLine} />
      ) : (
        <UIIcon src={FailLine} />
      )}
      <SnackbarMessage>{message}</SnackbarMessage>
      <Snackbar.Actions>
        <Button inverse onClick={props.onRequestClose} variant="transparent">
          <FormattedMessage
            defaultMessage="Close"
            description="Text for button that closes the snackbar."
            id="ry545A"
          />
        </Button>
      </Snackbar.Actions>
    </ReelSnackbar>
  );
};

export const ModifyItemSnackbar = React.memo(ModifyItemSnackbarNonMemo);
