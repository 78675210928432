import React, {useState} from 'react';

import {useAtomValue, useSetAtom} from 'jotai';
import type {IntlShape} from 'react-intl';
import {useIntl} from 'react-intl';
import styled from 'styled-components';

import {Chip} from '@dropbox/dig-components/chip';
import {Menu} from '@dropbox/dig-components/menu';

import {Button} from '~/components/button';
import type {LoggingClient} from '~/lib/logging/logger';
import type {FilterOptionChoices} from '~/lib/logging/logger_types';
import {browseFiltersAtom, inRootAtom} from '~/state/browse';

import {StatusDot} from './common';
import {FilterMenuItem} from './filter_menu_item';
import type {FilterStates} from '../types';
import {
  FILTER_ALL_ADMIN_FOLDER_TYPE,
  FILTER_ALL_MEDIA_TYPE,
  FILTER_ALL_STATUS,
  FILTER_NONE_ADMIN_FOLDER_TYPE,
  FILTER_NONE_MEDIA_TYPE,
  FILTER_NONE_STATUS,
  FilterType,
} from '../types';

interface FilterDropdownProps {
  filter: FilterType;
  logEvent: LoggingClient['logEvent'];
}

const ClearOrSelectAllWrapper = styled.div`
  margin-left: 8px;
`;

export const FilterDropdown = ({filter, logEvent}: FilterDropdownProps) => {
  const intl = useIntl();
  const inRoot = Boolean(useAtomValue(inRootAtom));
  const setFilters = useSetAtom(browseFiltersAtom);

  const FILTER_LABELS = createInternationalizedLabels(intl);

  const [filterStatus, setFilterStatus] = useState<FilterStates>(
    getDefaultFilterStateBasedOnFilter(filter),
  );

  // On filter state change, call the setFilters function with the updated state so changes can be reflected.
  React.useEffect(() => {
    setFilters(filterStatus);
  }, [filterStatus, setFilters]);

  const updateFilter = (name: keyof typeof filterStatus) => {
    return () => {
      const was_enabled = !filterStatus[name];
      setFilterStatus({
        ...filterStatus,
        [name]: was_enabled,
      });
      const filterOption = getLoggingFilterOptionNameFromKey(name);
      // filterOption shouldn't be undefined, but just to be safe we're double checking.
      if (!!filterOption) {
        // eslint-disable-next-line deprecation/deprecation
        logEvent('filter_changed', {
          filter_type:
            filter === FilterType.STATUS
              ? 'status'
              : filter === FilterType.MEDIA_TYPE
              ? 'media_type'
              : 'admin_folder_type',
          filter_option: filterOption,
          // Can't use toString() here without an error
          was_enabled: was_enabled ? 'true' : 'false',
        });
      }
    };
  };

  const onClearOrSelectAllClicked = () => {
    if (getNumberOfFiltersSelected(filterStatus) > 0) {
      setFilterStatus(getDefaultFilterStateBasedOnFilter(filter));
      // eslint-disable-next-line deprecation/deprecation
      logEvent('filter_changed', {
        filter_type:
          filter === FilterType.STATUS
            ? 'status'
            : filter === FilterType.MEDIA_TYPE
            ? 'media_type'
            : 'admin_folder_type',
        filter_option: 'clear_all',
        was_enabled: 'true',
      });
    } else {
      setFilterStatus(getEnableAllFiltersStateBasedOnFilter(filter));
      // eslint-disable-next-line deprecation/deprecation
      logEvent('filter_changed', {
        filter_type:
          filter === FilterType.STATUS
            ? 'status'
            : filter === FilterType.MEDIA_TYPE
            ? 'media_type'
            : 'admin_folder_type',
        filter_option: 'select_all',
        was_enabled: 'true',
      });
    }
  };

  const numberFiltersSelected = getNumberOfFiltersSelected(filterStatus);
  const menuSegmentLabel =
    filter == FilterType.STATUS
      ? intl.formatMessage({
          defaultMessage: 'Status',
          id: 'DJzZz5',
          description:
            'Text on a dropdown menu that allows users to filter items based on the status.',
        })
      : filter == FilterType.ADMIN_FOLDER_TYPE
      ? intl.formatMessage({
          defaultMessage: 'Project type',
          id: 'yjfiKi',
          description:
            'Text on a dropdown menu that allows SuperAdmins to filter items based on the project type (e.g. "Member", "Team")',
        })
      : intl.formatMessage({
          defaultMessage: 'Media type',
          id: 't5vahC',
          description:
            'Text on a dropdown menu that allows users to filter items based on the media type (e.g. "Audio" or "Video").',
        });
  return (
    <>
      <Menu.Wrapper closeOnSelection={false}>
        {({getContentProps, getTriggerProps}) => (
          <>
            <Chip {...getTriggerProps()} size="small" withDropdownIcon>
              <Chip.Content>
                {numberFiltersSelected > 1 ? `${numberFiltersSelected} \u2022 ` : ''}
                {getLabelForDropdownMenu(intl, filter, inRoot, filterStatus, FILTER_LABELS)}
              </Chip.Content>
            </Chip>
            <Menu.Content {...getContentProps()}>
              <Menu.Segment
                withLabel={
                  <>
                    {menuSegmentLabel}
                    <ClearOrSelectAllWrapper>
                      <Button
                        aria-label={getClearOrSelectAllButtonLabel(filterStatus, intl)}
                        onClick={onClearOrSelectAllClicked}
                        size="small"
                        variant="transparent"
                      >
                        <span>{getClearOrSelectAllButtonLabel(filterStatus, intl)}</span>
                      </Button>
                    </ClearOrSelectAllWrapper>
                  </>
                }
              >
                {Object.keys(filterStatus).map((key) => (
                  <FilterMenuItem
                    callback={updateFilter(key as keyof typeof filterStatus)}
                    isChecked={filterStatus[key as keyof typeof filterStatus]}
                    key={key}
                    label={getLabelForMenuItem(key, inRoot, FILTER_LABELS)}
                    value={key}
                    withRightAccessory={getRightAccessoryFromKey(key)}
                  />
                ))}
              </Menu.Segment>
            </Menu.Content>
          </>
        )}
      </Menu.Wrapper>
    </>
  );
};

const createInternationalizedLabels = (intl: IntlShape): {[key: string]: string} => {
  const labels: {[key: string]: string} = {
    includeNoStatus: intl.formatMessage({
      defaultMessage: 'No status',
      id: 'XB/mSa',
      description:
        'Label for a checkbox that allows users to filter items with the status "No status"',
    }),
    includeNeedsReview: intl.formatMessage({
      defaultMessage: 'Needs review',
      id: '/J8LP1',
      description:
        'Label for a checkbox that allows users to filter items with the status "Needs review"',
    }),
    includeInProgress: intl.formatMessage({
      defaultMessage: 'In progress',
      id: 'qrOsXo',
      description:
        'Label for a checkbox that allows users to filter items with the status "In review"',
    }),
    includeEditsRequested: intl.formatMessage({
      defaultMessage: 'Edits requested',
      id: 'Hi1g3G',
      description:
        'Label for a checkbox that allows users to filter items with the status "Edits requested"',
    }),
    includeApproved: intl.formatMessage({
      defaultMessage: 'Approved',
      id: '5wWrVL',
      description:
        'Label for a checkbox that allows users to filter items with the status "Approved"',
    }),
    includeAudio: intl.formatMessage({
      defaultMessage: 'Audio',
      id: 'h+9dvM',
      description: 'Label for a checkbox that allows users to filter only Audio files',
    }),
    includeVideo: intl.formatMessage({
      defaultMessage: 'Video',
      id: 'szthjq',
      description: 'Label for a checkbox that allows users to filter only Video files',
    }),
    includeImages: intl.formatMessage({
      defaultMessage: 'Image',
      id: 'E5Aqh8',
      description: 'Label for a checkbox that allows users to filter only Image files',
    }),
    includeProjects: intl.formatMessage({
      defaultMessage: 'Project',
      id: 'bMjZbT',
      description:
        'Label for a checkbox that allows users to filter only Projects (essentially a folder hosting other files)',
    }),
    includeFolders: intl.formatMessage({
      defaultMessage: 'Folder',
      id: 'ZO7g6s',
      description: 'Label for a checkbox that allows users to filter only Folders',
    }),
    includeTeamFolders: intl.formatMessage({
      defaultMessage: 'Team',
      id: 'Sl8W0o',
      description: 'Label for a checkbox that allows a super admin to filter to only Team Projects',
    }),
    includeUserRootProjects: intl.formatMessage({
      defaultMessage: 'Member',
      id: 'nAOJao',
      description:
        'Label for a checkbox that allows a super admin to filter to only Member Projects',
    }),
    includePdf: intl.formatMessage({
      defaultMessage: 'PDF',
      id: 'Hbpet6',
      description: 'Label for a checkbox that allows users to filter only PDF files',
    }),
    includePsd: intl.formatMessage({
      defaultMessage: 'PSD',
      id: 'GWDp/c',
      description: 'Label for a checkbox that allows users to filter only PSD files',
    }),
  };

  return labels;
};

const getNumberOfFiltersSelected = (filterState: FilterStates): number => {
  return Object.values(filterState).filter((value) => !!value).length;
};

const getClearOrSelectAllButtonLabel = (filterState: FilterStates, intl: IntlShape): string => {
  if (Object.values(filterState).some((value) => value === true)) {
    return intl.formatMessage({
      defaultMessage: 'Clear',
      id: 'JEtxZI',
      description: 'Text that goes on a button to clear all selected filter options.',
    });
  }

  return intl.formatMessage({
    defaultMessage: 'Select all',
    id: 'eBuCm5',
    description: 'Text that goes on a button to select all possible filter options.',
  });
};

const getDefaultFilterStateBasedOnFilter = (filterType: FilterType): FilterStates => {
  switch (filterType) {
    case FilterType.STATUS:
      return FILTER_NONE_STATUS;
    case FilterType.MEDIA_TYPE:
      return {
        ...FILTER_NONE_MEDIA_TYPE,
        ...{includePdf: false},
        ...{includePsd: false},
      };
    case FilterType.ADMIN_FOLDER_TYPE:
      return FILTER_NONE_ADMIN_FOLDER_TYPE;
    default:
      return FILTER_NONE_STATUS;
  }
};

const getEnableAllFiltersStateBasedOnFilter = (filterType: FilterType): FilterStates => {
  switch (filterType) {
    case FilterType.STATUS:
      return FILTER_ALL_STATUS;
    case FilterType.MEDIA_TYPE:
      return {
        ...FILTER_ALL_MEDIA_TYPE,
        ...{includePdf: true},
        ...{includePsd: true},
      };
    case FilterType.ADMIN_FOLDER_TYPE:
      return FILTER_ALL_ADMIN_FOLDER_TYPE;
    default:
      return FILTER_ALL_STATUS;
  }
};

const getRightAccessoryFromKey = (key: string): React.ReactElement => {
  if (key in FILTER_NONE_STATUS) {
    return <StatusDot $dotSize={8} $dotTextSpacing={4} $status={getStatusFromKey(key)}></StatusDot>;
  }
  return <></>;
};

// TODO: can we move this to a util class (or status.ts)?
const getStatusFromKey = (key: string): number => {
  switch (key) {
    case 'includeNoStatus':
      return 0;
    case 'includeNeedsReview':
      return 1;
    case 'includeInProgress':
      return 2;
    case 'includeApproved':
      return 3;
    case 'includeEditsRequested':
      return 4;
    default:
      return 0;
  }
};

const getLabelForDropdownMenu = (
  intl: IntlShape,
  filter: FilterType,
  isRootLevelView: boolean,
  filterState: FilterStates,
  filterLabels: {[key: string]: string},
): string => {
  const numberSelected = getNumberOfFiltersSelected(filterState);

  // If there's only one filter selected, it will be formatted as "{filter label} only" regardless of the filter type
  if (numberSelected == 1) {
    const selectedFilterKey = Object.keys(filterState).filter(
      (key) => !!filterState[key as keyof FilterStates],
    );
    const selectedFilterLabel = getLabelForMenuItem(
      selectedFilterKey[0],
      isRootLevelView,
      filterLabels,
    );
    return intl.formatMessage(
      {
        defaultMessage: '{selectedFilterLabel} only',
        id: 'KTRC4H',
        description:
          'Text on a dropdown menu that allows users to filter items based on the status. This will be used to indicate that there are multiple statuses selected.',
      },
      {selectedFilterLabel: selectedFilterLabel},
    );
  }
  if (filter === FilterType.STATUS) {
    if (numberSelected > 1) {
      return intl.formatMessage({
        defaultMessage: 'Statuses',
        id: 'E4DrGt',
        description:
          'Text on a dropdown menu that allows users to filter items based on the status. This will be used to indicate that there are multiple statuses selected.',
      });
    }
    return intl.formatMessage({
      defaultMessage: 'Status',
      id: 'DJzZz5',
      description: 'Text on a dropdown menu that allows users to filter items based on the status.',
    });
  } else if (filter === FilterType.MEDIA_TYPE) {
    if (numberSelected > 1) {
      return intl.formatMessage({
        defaultMessage: 'Media types',
        id: '0OkjkP',
        description:
          'Text on a dropdown menu that allows users to filter items based on the media type (e.g. "Audio" or "Video"). This will be used to indicate that there are multiple media types selected.',
      });
    }
    return intl.formatMessage({
      defaultMessage: 'Media type',
      id: 't5vahC',
      description:
        'Text on a dropdown menu that allows users to filter items based on the media type (e.g. "Audio" or "Video").',
    });
  } else if (filter === FilterType.ADMIN_FOLDER_TYPE) {
    if (numberSelected > 1) {
      return intl.formatMessage({
        defaultMessage: 'Types',
        id: 'JH08ej',
        description:
          'Text on a dropdown menu that allows superAdmins to filter items based on the folder type (e.g. "Team Projects" or "User Roots").',
      });
    }
    return intl.formatMessage({
      defaultMessage: 'Type',
      id: 'pgu9pS',
      description:
        'Text on a dropdown menu that allows superAdmins to filter items based on the folder type (e.g. "Team Projects" or "User Roots").',
    });
  }
  return intl.formatMessage({
    defaultMessage: 'Default',
    id: '3mAj9N',
    description:
      'Text on a dropdown menu that allows users ti filter items based on media. This should not be returned.',
  });
};

const getLabelForMenuItem = (
  key: string,
  isRootLevelView: boolean,
  filterLabels: {[key: string]: string},
) => {
  // If outside the root view, we want to display "Folder" instead of "Project"
  if (key === 'includeProjects' && !isRootLevelView) {
    return filterLabels['includeFolders'];
  }
  return filterLabels[key as keyof FilterStates];
};

const getLoggingFilterOptionNameFromKey = (key: string): FilterOptionChoices | undefined => {
  // camelCase -> snake_case for event logging
  // eg includeNoStatus -> include_no_status
  switch (key) {
    case 'includeNoStatus':
      return 'include_no_status';
    case 'includeNeedsReview':
      return 'include_needs_review';
    case 'includeInProgress':
      return 'include_in_progress';
    case 'includeApproved':
      return 'include_approved';
    case 'includeEditsRequested':
      return 'include_edits_requested';
    case 'includeAudio':
      return 'include_audio';
    case 'includeImages':
      return 'include_images';
    case 'includePsd':
      return 'include_psd';
    case 'includePdf':
      return 'include_pdf';
    case 'includeProjects':
      return 'include_projects';
    case 'includeVideo':
      return 'include_video';
    case 'includeTeamProjects':
      return 'include_team_projects';
    case 'includeTeamUsers':
      return 'include_team_users';
    default:
      // We shouldn't hit this, but just in case...
      return undefined;
  }
};
