import React, {useState} from 'react';

import {useAtom, useAtomValue, useSetAtom} from 'jotai';
import {PAP_Copy_ReplayShareLink} from 'pap-events/share/copy_replay_share_link';
import styled from 'styled-components';

import {Banner} from '@dropbox/dig-components/banner';
import {Menu} from '@dropbox/dig-components/menu';
import {Modal} from '@dropbox/dig-components/modal';
import {Tooltip} from '@dropbox/dig-components/tooltips';
import {Text} from '@dropbox/dig-components/typography';
import {Box} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {GifLine, HelpLine, LinkLine} from '@dropbox/dig-icons/assets';

import {Button} from '~/components/button';
import {DueDate} from '~/components/due_date/due_date';
import {useCopyBasicLinkToClipboard} from '~/components/share_modal/copy_link_utils';
import {ShareLinkPermissionSelector} from '~/components/share_modal/permission_selector';
import {SendEmailTypeahead} from '~/components/share_modal/send_email_typeahead';
import {useShareModalStrings} from '~/components/share_modal/share_modal_text';
import {
  currentBasicLinkAtom,
  getShareItemAtom,
  getShareModalLoggingInfoAtom,
  shareModalContentsAtom,
  sharingIsRestrictedByTeamPolicyAtom,
  updateShareModalVersionAtom,
} from '~/components/share_modal/share_state';
import {useReelAppGlobalState} from '~/context';
import {getTasksForItem} from '~/lib/api';
import type {TeamContactInfo} from '~/lib/api';
import {
  narrowToExperimentVariantType,
  narrowToReplayMediaType,
  stringToPAPReplayMediaType,
} from '~/lib/logging/log_utils';
import {toBooleanAttr} from '~/lib/logging/logger_types';
import {useOnboardingChecklist} from '~/lib/onboarding_v2/use_onboarding_checklist';
import {useLoggingClient} from '~/lib/use_logging_client';
import {UserSurvey} from '~/lib/user_survey';
import {useIsReplayInGA, useReelProvisioningEnabled} from '~/lib/utils';
import {PremiumTooltip} from '~/pages/layout/components/premium_tooltip';
import {STEPS} from '~/state/onboarding_v2';
import {currentTaskStateAtom, loadingTaskStateAtom} from '~/state/task';

import {BasicAddOnUpsellModal} from '../add_on_upsell_modal';
import {spacing} from '../styled';
import {useViewport} from '../viewport_context';

const StyledUIIcon = styled(UIIcon)`
  margin-left: 10px;
`;

const SettingsRowWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px; // Negative margin is combined with positive margin on the children so the gap only shows between children on the same line
  margin-bottom: 10px;
`;

const DropdownMenuWrapper = styled.div`
  margin-left: 10px;
`;

const DropdownAndIconWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
`;

const ModalErrorText = styled.div`
  color: 'var(--dig-color__warning__on-surface)';
`;

const LeftFooterButtonWrapper = styled.div`
  margin-right: auto;
  & .dig-Button {
    margin-left: 0px;
  }
`;

const LeftMarginWrapper = styled.span`
  margin-left: ${spacing('10')};
`;

export const BasicHome = (props: {
  canCreateLinks: boolean;
  onRequestClose: () => void;
  shareRecipients: TeamContactInfo[];
  setShareRecipients: (recipients: TeamContactInfo[]) => void;
}) => {
  const {canCreateLinks, onRequestClose, shareRecipients, setShareRecipients} = props;

  const [upsellModalIsOpen, setUpsellModalIsOpen] = useState(false);
  const [shareModalState, setShareModalState] = useAtom(shareModalContentsAtom);
  const shareModalLoggingInfo = useAtomValue(getShareModalLoggingInfoAtom);
  const logEvent = shareModalLoggingInfo.logEvent;
  const shareItem = useAtomValue(getShareItemAtom);
  const setSelectedVersion = useSetAtom(updateShareModalVersionAtom);
  const currentLinkInfo = useAtomValue(currentBasicLinkAtom);
  const sharingIsRestrictedByTeamPolicy = useAtomValue(sharingIsRestrictedByTeamPolicyAtom);

  const isFolder = shareItem?.type === 'folder';
  const sharedEntityId = shareItem?.id ?? '';
  const text = useShareModalStrings(shareItem?.name ?? '');

  const isLoggedIn = useReelAppGlobalState().status === 'logged in';

  const showDueDate = useIsReplayInGA() && !isFolder;
  const [taskState, setTaskState] = useAtom(currentTaskStateAtom);
  const setLoadingTask = useSetAtom(loadingTaskStateAtom);

  React.useEffect(() => {
    (async () => {
      if (!isFolder) {
        const updated_task = await getTasksForItem(sharedEntityId);
        const async_task_state = {
          isLoading: false,
          videoId: sharedEntityId,
          task: updated_task,
        };
        setTaskState(async_task_state);
      }
    })();
  }, [isFolder, setTaskState, sharedEntityId]);

  React.useEffect(() => {
    if (!taskState.isLoading && sharedEntityId !== taskState.videoId) {
      setLoadingTask();
    }
  }, [taskState, sharedEntityId, setLoadingTask]);

  const [errorText, setErrorText] = React.useState('');

  const [showCopyDisabledTooltip, setShowCopyDisabledTooltip] = React.useState<boolean>(false);
  const copyButtonRef = React.useRef(null);

  const sessionContext = useReelAppGlobalState();
  const isProvisioningEnabled = useReelProvisioningEnabled();
  const {isMobileDevice} = useViewport();
  const showUpsellModal = !isMobileDevice;
  const isDueDateProvisioned =
    !isProvisioningEnabled ||
    (sessionContext.status === 'logged in' && sessionContext.provisions.deadlines_and_tasks);
  const dueDateTriggerRef = React.useRef(null);
  const [showDueDatePremiumTooltip, setShowDueDatePremiumTooltip] = React.useState<boolean>(false);
  const [tooltipTimeout, setTooltipTimeout] = React.useState<NodeJS.Timeout>();

  const {handleCompleteStep, handleCheckIsCurrentStepOpen} = useOnboardingChecklist({
    logEvent: logEvent,
  });
  const {copyLinkToClipboard} = useCopyBasicLinkToClipboard();

  const enableLinkAccess = currentLinkInfo ? !currentLinkInfo.expired : true;
  const requirePassword = currentLinkInfo ? currentLinkInfo.hasPassword : false;
  let requireDropboxLogin = currentLinkInfo ? currentLinkInfo.loggedOutAccessDisabled : false;
  // If sharing is restricted by team policy, override this setting
  requireDropboxLogin = sharingIsRestrictedByTeamPolicy ? true : requireDropboxLogin;
  const versionSummaries = shareItem?.type === 'file' ? shareItem?.versionSummaries : [];
  const downloadsDisabled = currentLinkInfo?.downloadsDisabled ?? true;

  const loggingClient = useLoggingClient();
  const params = new URLSearchParams(location.search);
  const variant = params.get('variant');
  const mediaType = params.get('media_type');

  if (
    shareModalState?.type !== 'share-home' ||
    (shareModalState.type === 'share-home' && shareModalState.tab !== 'original')
  ) {
    return null;
  }

  const handleShowSettings = () => {
    // eslint-disable-next-line deprecation/deprecation
    logEvent('select_reel_share_settings', {
      access_type: shareModalState?.selectedPermission === 'view-only' ? 'view_only' : 'review',
      link_item_type: shareModalLoggingInfo.linkItemType,
    });
    setShareModalState({
      type: 'basic-link-settings',
      selectedPermission: shareModalState?.selectedPermission,
      selectedVersion: shareModalState?.selectedVersion,
    });
  };

  const handleCopyLink = async () => {
    handleCompleteStep(STEPS.SHARE_FILE);
    // eslint-disable-next-line deprecation/deprecation
    logEvent('copy_reel_share_link', {
      access_type: shareModalState?.selectedPermission === 'view-only' ? 'view_only' : 'review',
      link_item_type: shareModalLoggingInfo.linkItemType,
      is_onboarding_share_step: toBooleanAttr(handleCheckIsCurrentStepOpen(STEPS.SHARE_FILE)),
      replay_media_type: narrowToReplayMediaType(mediaType),
      experiment_variant: narrowToExperimentVariantType(variant),
    });
    loggingClient.logPap(
      PAP_Copy_ReplayShareLink({
        accessType: 'reviewer',
        linkItemType: 'file',
        isOnboardingShareStep: handleCheckIsCurrentStepOpen(STEPS.SHARE_FILE),
        replayMediaType: stringToPAPReplayMediaType(mediaType),
        experimentVariant: variant || undefined,
      }),
    );
    await copyLinkToClipboard();
    if (!shareModalLoggingInfo.isDemo) {
      UserSurvey.trackEvent('create_replay_link', 'replay');
    }
    onRequestClose();
  };

  const versionDropdownMenu = (
    <DropdownAndIconWrapper marginTop="0">
      <DropdownMenuWrapper>
        <Menu.Wrapper onSelection={setSelectedVersion}>
          {({getContentProps, getTriggerProps}) => (
            <>
              <Button
                {...getTriggerProps()}
                aria-labelledby="version_permission_description"
                disabled={!canCreateLinks}
                title={'V' + shareModalState.selectedVersion} // Included so screenreader reads both button title and aria-labelledby
                variant="transparent"
                withDropdownIcon
              >
                {'V' + shareModalState.selectedVersion}
              </Button>
              <Menu.Content {...getContentProps()}>
                <Menu.Segment>
                  {versionSummaries?.map((version, index) => (
                    <Menu.ActionItem
                      key={version.video_version_id}
                      value={version.version_num}
                      withLeftAccessory={<UIIcon role="presentation" src={GifLine} />}
                    >
                      <Text isBold tagName="div">
                        {'V' + version.version_num}
                      </Text>
                    </Menu.ActionItem>
                  ))}
                </Menu.Segment>
              </Menu.Content>
            </>
          )}
        </Menu.Wrapper>
      </DropdownMenuWrapper>
      <Tooltip maxWidth={180} title={text.versionTooltipText}>
        <Text>
          <StyledUIIcon aria-label={text.versionTooltipText} src={HelpLine} />
        </Text>
      </Tooltip>
    </DropdownAndIconWrapper>
  );

  if (shareItem === null) {
    return null;
  }

  return (
    <>
      <Modal.Body>
        <SettingsRowWrapper data-safe-to-unmask-name="share-modal-view-comment-permissions-options">
          <Text
            id="share_permission_description"
            style={{maxWidth: 'fit-content', marginLeft: '10px', marginTop: '2px'}}
          >
            {requirePassword
              ? text.passwordRequiredPermissionDescription
              : text.sharePermissionDescription}
          </Text>
          <DropdownAndIconWrapper marginTop="2">
            <DropdownMenuWrapper>
              <ShareLinkPermissionSelector
                ariaLabelId="share_permission_description"
                canCreateLinks={canCreateLinks}
                disabled={false}
              />
            </DropdownMenuWrapper>
            {shareItem?.type === 'file' &&
              shareModalState?.selectedPermission === 'view-only' &&
              shareModalState?.selectedVersion &&
              versionSummaries &&
              versionDropdownMenu}
          </DropdownAndIconWrapper>
        </SettingsRowWrapper>

        <SettingsRowWrapper>
          <DropdownMenuWrapper>
            {downloadsDisabled ? text.downloadsDisabled : text.downloadsEnabled}
            <LeftMarginWrapper>
              {canCreateLinks && (
                <Button onClick={handleShowSettings} variant="transparent">
                  {text.manageSettings}
                </Button>
              )}
            </LeftMarginWrapper>
          </DropdownMenuWrapper>
        </SettingsRowWrapper>
        {sharingIsRestrictedByTeamPolicy && (
          <Banner type="warning">
            <Banner.Message>{text.sharingIsRestrictedWarning}</Banner.Message>
          </Banner>
        )}
        {isLoggedIn && (
          <SendEmailTypeahead
            isDropboxLoginRequired={requireDropboxLogin}
            isLinkDisabled={!enableLinkAccess}
            onRequestClose={onRequestClose}
            setErrorText={setErrorText}
            setShareRecipients={setShareRecipients}
            shareRecipients={shareRecipients}
          />
        )}
        {errorText && <ModalErrorText>{errorText}</ModalErrorText>}
      </Modal.Body>
      <Modal.Footer>
        {showDueDate && (
          <LeftFooterButtonWrapper
            onMouseEnter={() => {
              if (!isDueDateProvisioned) {
                const timeout = setTimeout(() => setShowDueDatePremiumTooltip(true), 200);

                setTooltipTimeout(timeout);
              }
            }}
            onMouseLeave={() => clearTimeout(tooltipTimeout)}
            ref={dueDateTriggerRef}
          >
            <DueDate
              dueDate={!taskState.isLoading ? taskState.task?.dueDate : undefined}
              editable={canCreateLinks}
              isLoading={taskState.isLoading}
              onClick={() => {
                if (!isDueDateProvisioned) {
                  setUpsellModalIsOpen(true);
                } else {
                  setShareModalState({type: 'due-date-settings'});
                  // eslint-disable-next-line deprecation/deprecation
                  logEvent('select_open_due_date', {source: 'share_modal'});
                }
              }}
            />
          </LeftFooterButtonWrapper>
        )}
        {showUpsellModal ? (
          <BasicAddOnUpsellModal
            clickSource="share_modal"
            onClose={() => setUpsellModalIsOpen(false)}
            open={upsellModalIsOpen}
            variant="deadlines_and_tasks"
          />
        ) : (
          <PremiumTooltip
            onClose={() => setShowDueDatePremiumTooltip(false)}
            open={showDueDatePremiumTooltip}
            placement="right"
            triggerRef={dueDateTriggerRef}
          />
        )}
        <Tooltip.Control
          auto
          maxWidth={275}
          open={showCopyDisabledTooltip}
          placement="top"
          triggerRef={copyButtonRef}
          variant="basic"
        >
          {text.linkDisabledTooltipText}
        </Tooltip.Control>
        <div
          onMouseEnter={() => setShowCopyDisabledTooltip(!enableLinkAccess)}
          onMouseLeave={() => setShowCopyDisabledTooltip(false)}
        >
          <Button
            data-safe-to-unmask-name="share-modal-copy-link-button"
            data-testid="copy-share-link-button"
            disabled={!enableLinkAccess}
            onClick={handleCopyLink}
            ref={copyButtonRef}
            type="submit"
            variant="primary"
            withIconStart={<UIIcon src={LinkLine} />}
          >
            {text.copyLinkText}
          </Button>
        </div>
      </Modal.Footer>
    </>
  );
};
