import React from 'react';

export function useInterpolatedNumber({value}: {value: number}) {
  const [interpolatedValue, setInterpolatedValue] = React.useState(value);
  const intervalRef = React.useRef<number | null>(null);
  const diff = Math.round(value - interpolatedValue);
  const reachedTerminus = diff === 0;
  const step = Math.sign(diff);

  // Start an interval that counts up (or down)
  React.useEffect(() => {
    const intervalId = (intervalRef.current = window.setInterval(() => {
      setInterpolatedValue((current) => current + step);
    }, 20));

    return () => {
      window.clearInterval(intervalId);
      intervalRef.current = null;
    };
  }, [step]);

  // If we reached our goal, stop our interval
  React.useEffect(() => {
    if (reachedTerminus && intervalRef.current) {
      window.clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  }, [reachedTerminus]);

  return {
    interpolatedValue: reachedTerminus ? value : interpolatedValue,
    isRunning: !reachedTerminus,
  };
}
