import React from 'react';

import {atom, useAtom} from 'jotai';

type Keypresses = Record<string, boolean>;

const keyboardAtom = atom<Keypresses>({});

export const useKeyboard = () => {
  const [keys, setKeys] = useAtom(keyboardAtom);

  React.useEffect(() => {
    // Clear keys on focus, fixes issues where focus is lost and keys get stuck
    const handleFocus = () => {
      setKeys({});
    };

    const handleKey = (event: KeyboardEvent) => {
      const key = event.key;
      const down = event.type === 'keydown';

      // If Meta or Control, clear previous keys.  This prevents bugs since some modifier keys will not return keyup on modified keys
      const newKeys = key === 'Meta' || key === 'Control' ? {} : {...keys};

      newKeys[key] = down;

      setKeys(newKeys);
    };

    window.addEventListener('focus', handleFocus);
    document.addEventListener('keydown', handleKey);
    document.addEventListener('keyup', handleKey);

    return () => {
      window.removeEventListener('focus', handleFocus);
      document.removeEventListener('keydown', handleKey);
      document.removeEventListener('keyup', handleKey);
    };
  }, [keys, setKeys]);

  return keys;
};
