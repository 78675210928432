import React, {useState} from 'react';

import {useIntl} from 'react-intl';
import styled from 'styled-components';

import {Modal} from '@dropbox/dig-components/modal';
import {Title} from '@dropbox/dig-components/typography';

import {Button} from '~/components/button';
import {ItemType} from '~/components/common';
import {renameFolder, renameProject} from '~/lib/api';
import type {LoggingClient} from '~/lib/logging/logger';

import {ReplayTextInput} from './text_input';
import {MAX_PROJECT_LENGTH} from '../lib/constants';
import type {ModifyItemSnackbarInfo} from '../pages/browse_page/browse_page';

type RenameModalProps = {
  logEvent: LoggingClient['logEvent'];
  open: boolean;
  originalName: string;
  id: string;
  onRename: (projectName: string) => void;
  requestClose: () => void;
  itemType: ItemType;
  updateModifyItemSnackbar: (modifyItemSnackbarInfo: ModifyItemSnackbarInfo) => void;
};

const RenameModalBody = styled(Modal.Body)`
  padding-bottom: 24px;
`;

const RenameModalBodyTitle = styled.p`
  margin: 0 0 21px 0;
`;

const ModalButtons = styled.div`
  display: flex;
`;

const CancelButton = styled.div`
  margin-left: auto;
`;

const RenameButton = styled.div`
  padding-left: 8px;
`;

const ModalBodyText = styled.p`
  margin: 0 0 21px 0;
  color: yellow;
`;

export const RenameModal = (props: RenameModalProps) => {
  const {
    logEvent,
    open,
    originalName,
    id,
    onRename,
    requestClose,
    itemType,
    updateModifyItemSnackbar,
  } = props;
  const [itemName, setItemName] = useState<string>(originalName);
  const [waitingOnRequest, setWaitingOnRequest] = useState<boolean>(false);
  const [errorText, setErrorText] = React.useState<string>('');

  const intl = useIntl();

  const duplicateProjectErrorText = intl.formatMessage({
    defaultMessage: 'Psst—you already have a project with the same name, please choose another.',
    id: 'nSbrAe',
    description:
      'Message that shows when a user tries to rename a project but there is already a project with the same name in the current folder. The user needs to choose another name to proceed',
  });

  const duplicateFolderErrorText = intl.formatMessage({
    defaultMessage: 'Psst—you already have a folder with the same name, please choose another.',
    id: 'TIbXPM',
    description:
      'Message that shows when a user tries to rename a folder but there is already a folder with the same name in the current folder. The user needs to choose another name to proceed',
  });

  const renameFileTitleText = intl.formatMessage({
    defaultMessage: 'Rename file',
    id: '4zOqII',
    description: 'Title text that shows up on modal indicating that this modal will rename a file',
  });

  const renameFolderTitleText = intl.formatMessage({
    defaultMessage: 'Rename folder',
    id: 'JJHL+y',
    description:
      'Title text that shows up on modal indicating that this modal will rename a folder',
  });

  const renameProjectTitleText = intl.formatMessage({
    defaultMessage: 'Rename project',
    id: '1qcQOO',
    description:
      'Title text that shows up on modal indicating that this modal will rename a project',
  });

  const ariaRenameTextInputText = intl.formatMessage({
    defaultMessage: 'New item name',
    id: 'ghn9Qd',
    description: 'Aria label indicating that the text in this textbox will be the new item name',
  });

  const closeButtonText = intl.formatMessage({
    defaultMessage: 'Close rename modal',
    id: 'EZ9yqj',
    description: 'Text indicating that clicking on this button will close the rename modal',
  });

  const cancelButtonText = intl.formatMessage({
    defaultMessage: 'Cancel',
    id: 'Ntsp6l',
    description: 'Text on cancel button for modal, clicking this button will close the modal',
  });

  const renameButtonText = intl.formatMessage({
    defaultMessage: 'Rename',
    id: 'lIgznC',
    description:
      'Text on rename button for modal, clicking this button will trigger a rename action with the text the user entered in the textbox',
  });

  React.useEffect(() => {
    setErrorText('');
  }, [open]);

  const handleRename = async () => {
    setWaitingOnRequest(true);
    const name = itemName.trim();
    try {
      switch (itemType) {
        case ItemType.FOLDER:
          await renameFolder(id, name);
          break;
        case ItemType.PROJECT:
          await renameProject(id, name);
          // eslint-disable-next-line deprecation/deprecation
          logEvent('rename_reel_video');
          break;
        case ItemType.TEAM_PROJECT:
          await renameFolder(id, name);
          break;
      }
      onRename(name);
      updateModifyItemSnackbar({
        type: 'rename',
        status: 'success',
        itemType,
        originalName,
        newName: name,
      });
      requestClose();
    } catch (e) {
      const tag = e.error && e.error.error && e.error.error['.tag'];
      if (tag && tag === 'duplicate_folder_name') {
        itemType === ItemType.TEAM_PROJECT
          ? setErrorText(duplicateProjectErrorText)
          : setErrorText(duplicateFolderErrorText);
      } else {
        updateModifyItemSnackbar({
          type: 'rename',
          status: 'error',
          itemType: itemType,
          originalName: originalName,
          newName: name,
        });
        requestClose();
      }
      return;
    } finally {
      setWaitingOnRequest(false);
    }
  };

  return (
    <Modal
      data-testid="rename-modal"
      isCentered
      onRequestClose={requestClose}
      open={open}
      width="small"
      withCloseButton={closeButtonText}
    >
      <Modal.Header hasBottomSpacing="title-small">
        <Title isBold size="small">
          {itemType == ItemType.PROJECT
            ? renameFileTitleText
            : itemType == ItemType.FOLDER
            ? renameFolderTitleText
            : itemType == ItemType.TEAM_PROJECT
            ? renameProjectTitleText
            : ''}
        </Title>
      </Modal.Header>
      <RenameModalBody>
        <RenameModalBodyTitle>
          <ReplayTextInput
            aria-label={ariaRenameTextInputText}
            maxLength={MAX_PROJECT_LENGTH}
            onChange={(e) => setItemName(e.currentTarget.value)}
            onEnter={handleRename}
            placeholder=""
            value={itemName}
          />
        </RenameModalBodyTitle>
        <ModalBodyText>{errorText}</ModalBodyText>
        <ModalButtons>
          <CancelButton>
            <Button onClick={requestClose} size="standard" type="button" variant="opacity">
              {cancelButtonText}
            </Button>
          </CancelButton>
          <RenameButton>
            <Button
              disabled={!itemName.trim()}
              isLoading={waitingOnRequest}
              onClick={handleRename}
              size="standard"
              type="submit"
              variant="primary"
            >
              {renameButtonText}
            </Button>
          </RenameButton>
        </ModalButtons>
      </RenameModalBody>
    </Modal>
  );
};
