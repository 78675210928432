import React from 'react';

import type {reel} from '@dropbox/api-v2-client';

import type {AddMediaClickSourceType, MoveSourceType} from '~/lib/logging/logger_types';
import type {VersionUploadConfirmationProps} from '~/pages/browse_page/components/browse_page_snackbar';
import type {BrowseFolder, BrowseProject} from '~/pages/browse_page/components/common';
import {isUploadedFolder} from '~/pages/browse_page/components/common';
import type {LeftRailStatus} from '~/pages/browse_page/types';

import type {ShareFolderAccessType, SuggestedFilesResponse} from '../../lib/api';
import {reportBadContextUseError} from '../../lib/error_reporting';
import type {AllUploadsCompleteHandler, BasicFileInfo, ChooserFile} from '../../lib/uploads/types';

export const innerFolderRename = (
  folders: BrowseFolder[],
  folderId: string,
  newFolderName: string,
) =>
  folders.map((folder) =>
    isUploadedFolder(folder) && folder.id === folderId
      ? {
          ...folder,
          name: newFolderName,
        }
      : folder,
  );

export const innerProjectRename = (
  projects: BrowseProject[],
  projectId: string,
  projectName: string,
) =>
  projects.map((project) =>
    !project.isUploading && project.project?.id === projectId
      ? {
          ...project,
          project: {
            ...project.project!,
            name: projectName,
          },
        }
      : project,
  );

type EnabledEditActionsDeps = {
  editActionsEnabled: true;
  folderTree: reel.FolderTreeNode[] | null;
  handleAddFileFromDropbox: (
    files: ChooserFile[],
    clickSource: AddMediaClickSourceType,
    level: number,
  ) => Promise<void>;
  handleAddFileFromUpload: (
    click_source: AddMediaClickSourceType,
    level: number,
    projectId: string,
    videoId: string,
    name: string,
  ) => void;
  handleAllUploadsComplete: AllUploadsCompleteHandler;
  leftRailStatus: LeftRailStatus;
  onAddVideoError: () => void;
  onFilePick: (files: BasicFileInfo[], folderUpload?: boolean) => void;
  openAddPeopleModal: (sharedFolderId: string, sharedFolderName: string) => void;
  openManagePeopleModal: (sharedFolderId: string, sharedFolderName: string) => void;
  reelRootFolderId: string;
  setChildTree: (tree: reel.FolderTreeNode[] | null) => void;
  setFolderTree: (tree: reel.FolderTreeNode[]) => void;
  setLeftRailStatus: (status: LeftRailStatus) => void;
  suggestedItems: SuggestedFilesResponse;
  updateFolderTree: () => void;
  updateSuggestedItems: () => Promise<void>;
};

export type EditActionsDeps = {editActionsEnabled: false} | EnabledEditActionsDeps;

/**
 * BrowseItemsViewContextValue types
 */
type VersionStatusChange = {
  onVersionStatusChange: (projectId: string, videoVersionId: string, newStatus: number) => void;
};

type MoveHandler = {
  onBulkMove: (
    folderIds: string[],
    projectIds: string[],
    destinationFolderId: string,
    destinationFolderName: string,
    moveSource: MoveSourceType,
    showErrorModal: boolean,
    isCopy: boolean,
  ) => void;
  onFolderMove: (
    folderId: string,
    folderName: string,
    destinationFolderId: string,
    destinationFolderName: string,
    moveSource: MoveSourceType,
    showErrorModal: boolean,
    isCopy: boolean,
  ) => void;
  onProjectMove: (
    projectId: string,
    projectName: string,
    destinationFolderId: string,
    destinationFolderName: string,
    moveSource: MoveSourceType,
    isCopy: boolean,
  ) => void;
};

type RenameHandler = {
  onFolderRename: (folderId: string, newName: string) => void;
  onProjectRename: (projectId: string, nextName: string) => void;
};

type EditActionsBase = {
  folderTree: reel.FolderTreeNode[] | null;
  leftRailStatus: LeftRailStatus;
  openAddPeopleModal: (sharedFolderId: string, sharedFolderName: string) => void;
  openManagePeopleModal: (sharedFolderId: string, sharedFolderName: string) => void;
  onVersionUpload: (
    projectId: string,
    video: reel.Video,
    snackbarProps: VersionUploadConfirmationProps,
    needsCopy: boolean,
    uploadId?: string,
  ) => void;
  reelRootFolderId: string;
  setChildTree: (tree: reel.FolderTreeNode[] | null) => void;
  setFolderTree: (tree: reel.FolderTreeNode[]) => void;
  setLeftRailStatus: (status: LeftRailStatus) => void;
  updateFolderTree: () => void;
};

type TransferHandler = {
  onProjectTransfer: (projectId: string) => void;
};

type BrowseItemsEditActionDeps = EditActionsBase &
  VersionStatusChange &
  MoveHandler &
  RenameHandler &
  TransferHandler;

export type BrowseItemsViewContextValue = {
  breadcrumbPath: reel.Folder[] | null;
  currentFolderId: string;
  currentFolderPermission: ShareFolderAccessType;
  editActionsProps:
    | ({editActionsEnabled: false} & VersionStatusChange)
    | ({editActionsEnabled: true} & BrowseItemsEditActionDeps);
  onFoldersDelete: (folderIds: string[]) => void;
  onProjectsDelete: (projectIds: string[]) => void;
};

export const BrowseItemsViewContext = React.createContext<BrowseItemsViewContextValue | null>(null);

export const useBrowseItemsViewContext = (): BrowseItemsViewContextValue => {
  const context = React.useContext(BrowseItemsViewContext);
  if (!context) {
    const error = new Error(
      'useBrowseItemsViewContext must be used within a BrowseItemsViewContext provider',
    );
    reportBadContextUseError(error);
    throw error;
  }
  return context;
};
