import React from 'react';

/**
 * Provides width and height that update on resize
 */
const useDimensions = (ref: React.MutableRefObject<HTMLElement | null>) => {
  const [dimensions, setDimensions] = React.useState({width: 0, height: 0});

  const resizeObserver = React.useMemo(
    () =>
      new ResizeObserver((entries: ResizeObserverEntry[]) => {
        if (entries.length > 0) {
          const element = entries[0].target;
          const {height, width} = element.getBoundingClientRect();
          setDimensions({height, width});
        }
      }),
    [],
  );

  React.useEffect(() => {
    let el: HTMLElement;
    if (ref.current) {
      el = ref.current;
      resizeObserver.observe(el);
    }

    return () => {
      if (el) {
        resizeObserver.unobserve(el);
      }
    };
  }, [ref, resizeObserver]);

  return dimensions;
};

export default useDimensions;
