import {useIntl} from 'react-intl';
import styled from 'styled-components';

import {Badge} from '@dropbox/dig-components/badge';
import {Tooltip} from '@dropbox/dig-components/tooltips';

import {spacing} from '~/components/styled';
import {useViewport} from '~/components/viewport_context';

const BadgeWrapper = styled.div`
  z-index: 1;
  position: absolute;
  right: ${spacing('Micro Medium')};
  bottom: ${spacing('Micro Medium')};
`;

type FileLimitBadgeProps =
  | {
      view: 'tile';
      tileIsHovered: boolean;
    }
  | {
      view: 'list';
    };

export const FileLimitBadge = (props: FileLimitBadgeProps) => {
  const intl = useIntl();
  // eslint-disable-next-line deprecation/deprecation
  const {isSmallScreenSize} = useViewport();
  const fileLimitLabel = intl.formatMessage({
    defaultMessage: 'File limit',
    id: 'dFrl9S',
    description:
      "Text on a badge that indicates that this item counts towards a user's file limit.",
  });

  const fileLimitTooltip = intl.formatMessage({
    defaultMessage: 'This file counts towards your upload limit. Delete to create space.',
    id: 'OO6oGQ',
    description: "Tooltip text that explains that this file counts towards a user's file limit.",
  });

  const {view} = props;

  if (view === 'tile') {
    return (
      <Tooltip openDelay={300} placement="top" title={fileLimitTooltip}>
        <BadgeWrapper>
          <Badge variant="warning">{fileLimitLabel}</Badge>
        </BadgeWrapper>
      </Tooltip>
    );
  }

  return !isSmallScreenSize ? (
    <Tooltip openDelay={300} placement="top" title={fileLimitTooltip}>
      <div style={{marginLeft: '10px', alignSelf: 'center', overflow: 'hidden'}}>
        <Badge variant="warning">{fileLimitLabel}</Badge>
      </div>
    </Tooltip>
  ) : null;
};
