import React from 'react';

import type {AccessType} from 'pap-events/enums/access_type';
import {PAP_Select_WorkspaceAction} from 'pap-events/replay/select_workspace_action';

import type {reel} from '@dropbox/api-v2-client';

import type {IconButtonVariant} from '~/components/button';
import {DownloadButton} from '~/components/download/download_button';
import type {ShareFolderAccessType} from '~/lib/api';
import {useLoggingClient} from '~/lib/use_logging_client';

import {DownloadFolderModal} from './download_folder_modal';

type AnalyticsData = {
  creatorId?: string;
  actionElement?: 'toolbar' | 'action_bar';
  folderLevel?: number;
  accessType?: AccessType;
};

export function getAccessType(accessLevel: ShareFolderAccessType): AccessType {
  return 'owner';
}

export type DownloadFolderProps = {
  displayType: 'compact' | 'expanded';
  buttonVariant?: IconButtonVariant;
  folderId: string;
  shareToken?: string;
  downloadOptions: reel.DownloadOption[];
  analyticsData?: AnalyticsData;
  grantBook?: string;
};

export const DownloadFolderButtonAndModal: React.FC<DownloadFolderProps> = ({
  displayType,
  buttonVariant = 'outline',
  folderId,
  shareToken,
  downloadOptions,
  analyticsData,
  grantBook,
}: DownloadFolderProps) => {
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const logger = useLoggingClient();

  const onDownloadButtonClick = React.useCallback(() => {
    logger.logPap(
      PAP_Select_WorkspaceAction({
        workspaceAction: 'download',
        deviceId: logger.deviceId,
        creatorId: analyticsData?.creatorId,
        actionElement: analyticsData?.actionElement,
        folderLevel: analyticsData?.folderLevel,
        projectId: folderId,
        accessType: analyticsData?.accessType,
        contentType: 'folder',
      }),
    );
    setModalOpen(true);
  }, [
    logger,
    analyticsData?.creatorId,
    analyticsData?.actionElement,
    analyticsData?.folderLevel,
    analyticsData?.accessType,
    folderId,
  ]);
  const onModalClose = React.useCallback(() => {
    setModalOpen(false);
  }, []);

  return (
    <>
      <DownloadButton
        buttonVariant={buttonVariant}
        displayType={displayType}
        onClick={onDownloadButtonClick}
      />
      <DownloadFolderModal
        analyticsData={
          analyticsData && {
            accessType: analyticsData?.accessType,
            actionElement: analyticsData?.actionElement,
          }
        }
        downloadOptions={downloadOptions}
        folderId={folderId}
        grantBook={grantBook}
        modalOpen={modalOpen}
        onModalClose={onModalClose}
        shareToken={shareToken}
      />
    </>
  );
};
