export type AsyncDelegate<T> = {
  [P in keyof T]: T[P] extends (...args: any[]) => any
    ? (...args: Parameters<T[P]>) => Promise<Awaited<ReturnType<T[P]>>>
    : () => Promise<T[P]>;
};

/**
 * Operate on a given object as if the promise was resolved.
 *
 * This is useful for objects that have a one-time initialization cost,
 * and these objects can be used as if they were already initialized.
 */
export function createAsyncProxy<T extends object>(promise: Promise<T>): AsyncDelegate<T> {
  const handler: ProxyHandler<{}> = {
    get(_target, prop) {
      return async (...args: any[]) => {
        const resolvedTarget = await promise;
        const value = resolvedTarget[prop as keyof T];
        if (typeof value === 'function') {
          return value.apply(resolvedTarget, args);
        }
        return value;
      };
    },
  };

  return new Proxy({}, handler) as AsyncDelegate<T>;
}
