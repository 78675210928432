import {useAtomValue} from 'jotai';
import {FormattedMessage} from 'react-intl';

import {UIIcon} from '@dropbox/dig-icons';
import {CopyLine} from '@dropbox/dig-icons/assets';

import {Button, IconButton} from '~/components/button';
import {useViewport} from '~/components/viewport_context';
import {BulkActionStatus, bulkActionStatusAtom} from '~/state/bulk_actions';

type BulkCopyToProjectButtonProps = {
  onClick: () => void;
};

export const BulkCopyToProjectButton = ({onClick}: BulkCopyToProjectButtonProps) => {
  // eslint-disable-next-line deprecation/deprecation
  const {isSmallScreenSize} = useViewport();

  const bulkActionStatus = useAtomValue(bulkActionStatusAtom);

  const disabled = bulkActionStatus === BulkActionStatus.REQUESTING;

  return isSmallScreenSize ? (
    <IconButton disabled={disabled} onClick={onClick} size="small" variant="opacity">
      <UIIcon src={CopyLine} />
    </IconButton>
  ) : (
    <Button
      disabled={disabled}
      onClick={onClick}
      variant="opacity"
      withIconLeft={<UIIcon src={CopyLine} />}
    >
      <FormattedMessage
        defaultMessage="Copy to project"
        description="Button that allows a user to copy one or more files to a project"
        id="HbLpAQ"
      />
    </Button>
  );
};
