import React from 'react';

import * as Sentry from '@sentry/react';
import {useQueryClient} from '@tanstack/react-query';
import type {LocaleData} from 'javascript-time-ago';
import TimeAgo from 'javascript-time-ago';
import TimeAgoEn from 'javascript-time-ago/locale/en.json';
import {useSetAtom} from 'jotai';
import {createIntl, createIntlCache} from 'react-intl';

import {DBX_CLIENT_DOMAIN, REPLAY_BASE_URL} from '~/context_utils';
import {dropboxSdk, getDefaultUserClient, setCurrentUser} from '~/lib/client';
import type {Locale} from '~/lib/i18n';
import {getI18nLocale, loadLocaleData, onIntlError} from '~/lib/i18n';
import {
  queryInitializationData,
  queryProvisionsData,
  queryUserAccount,
  queryUserMetadata,
  refreshProvisionsData,
} from '~/lib/init_queries';
import {initializePapLogger} from '~/lib/logging/pap_client_logger';
import {RELOADED_POST_PURCHASE, replayStorage} from '~/lib/storage';
import {clearPurchaseStorageItems, SessionStorage} from '~/lib/storage_utils';
import {localeToTimeAgoMapping} from '~/lib/time';
import {UserSurvey} from '~/lib/user_survey';

import type {GuestUserInfo, InitializationState, InitialSessionState} from './context';
import {hasGuestUserInfo} from './context';
import {checkPithosPrivacyConsentFlag} from './lib/environment';
import {reportFailedDataFetch} from './lib/error_reporting';
import {initGrowthbook} from './lib/growthbook';
import {tryPrivacyConsentInit} from './lib/initialize_privacy_module';
import {globalLoggingSwitch} from './lib/logging/global_logging_switch';
import {verifyMainBundle, verifyRuntimeBundle} from './lib/logging/logger';
import {setStormcrowTags} from './lib/logging/sentry';
import {PREFERENCE_SETTING_METADATA_FIELD, USER_METADATA_FIELD} from './lib/provisions';
import {fetchStormcrows, stormcrows} from './lib/stormcrow';
import {ROLE_QUERY_PARAM} from './lib/url_utils';
import {setMainBundleVersion, setRuntimeBundleVersion} from './lib/utils';
import {ErrorPage} from './pages/error_page';
import {intlShapeAtom} from './state/i18n';
import {isInWebView} from './use_extensions';

TimeAgo.addDefaultLocale(TimeAgoEn);

type AppInitializerProps = {
  render: (sessionState: InitialSessionState) => React.ReactElement | null;
};

export const AppInitializer = (props: AppInitializerProps) => {
  const {render} = props;

  const [state, setState] = React.useState<InitializationState>({
    status: 'loading',
  });
  const stormcrowWriter = useSetAtom(stormcrows);
  const intlWriter = useSetAtom(intlShapeAtom);
  const queryClient = useQueryClient();

  const setGuestUserInfo = (info: GuestUserInfo) => {
    replayStorage.setGuestUserInfo(info);
    setState((previous) => ({
      ...previous,
      guestUserInfo: info,
      hasGuestUserInfo() {
        return hasGuestUserInfo(info);
      },
      isAnonymousUser: previous.status === 'logged out' && !hasGuestUserInfo(info),
    }));
  };

  const logout = React.useCallback(async () => {
    await getDefaultUserClient()
      .authTokenRevoke()
      .finally(() => {
        const logoutURL = isInWebView()
          ? `${REPLAY_BASE_URL}?login=true&redirectauth=false`
          : DBX_CLIENT_DOMAIN
          ? `https://meta-${DBX_CLIENT_DOMAIN}/logout`
          : 'https://dropbox.com/logout';
        replayStorage.logUserOut(logoutURL);
      });
  }, []);

  const switchRole = React.useCallback(async (newRole: 'work' | 'personal') => {
    await getDefaultUserClient().authTokenRevoke();
    const logoutURL = `${REPLAY_BASE_URL}?login=true&redirectauth=false&${ROLE_QUERY_PARAM}=${newRole}`;
    replayStorage.logUserOut(logoutURL);
  }, []);

  const setIsCookieSnackbarOpen = (v: boolean) => {
    setState((previous) => ({
      ...previous,
      isCookieSnackbarOpen: v,
    }));
  };

  const getI18nLocaleData = async (locale: Locale) => {
    return await loadLocaleData(locale || 'en-US', {});
  };

  const getTimeAgoLocaleData = async (locale: string) => {
    const locale_name = localeToTimeAgoMapping[locale] ?? localeToTimeAgoMapping['en'];
    const timeAgoLocaleData = await import(
      /* webpackChunkName: 'i18n-time-ago-[request]' */
      /* webpackExclude: /package.json/ */
      `../node_modules/javascript-time-ago/locale/${locale_name}.json`
    );
    return timeAgoLocaleData as LocaleData;
  };

  const setupLoggedIn = React.useCallback(
    async (uid: number, accountId: string) => {
      if (!uid || !accountId) {
        await setupLoggedOut();
        return;
      }

      const reloadRequested = SessionStorage.get(RELOADED_POST_PURCHASE);
      const purchasedInApp = replayStorage.getPurchasedInReplay();
      if (purchasedInApp && !reloadRequested) {
        SessionStorage.set(RELOADED_POST_PURCHASE, true);
      }

      const dropboxAuth = dropboxSdk.auth;
      const dropboxClient = dropboxSdk.client;

      initGrowthbook().catch(() => {
        // Nothing needed, function handles error and no need to set an error page
      });

      Sentry.setUser({id: accountId});

      const basicAccountPromise = queryUserAccount(queryClient, accountId);
      const stormcrowPromise = fetchStormcrows();
      const initDataPromise = queryInitializationData(queryClient);
      const fetchUserMetadataPromise = queryUserMetadata(queryClient);
      const provisionsPromise = queryProvisionsData(queryClient);
      const localePromise = initDataPromise.then((x) => getI18nLocale(x.locale));
      const timeAgoLocalePromise = localePromise.then(getTimeAgoLocaleData);
      const uiStringsPromise = localePromise.then(getI18nLocaleData);

      // Is this swallowed
      try {
        const [
          basicUserAccount,
          stormcrows,
          initializationData,
          fetchUserMetadataResponse,
          provisions,
          locale,
          uiStrings,
          timeAgoLocale,
        ] = await Promise.all([
          basicAccountPromise,
          stormcrowPromise,
          initDataPromise,
          fetchUserMetadataPromise,
          provisionsPromise,
          localePromise,
          uiStringsPromise,
          timeAgoLocalePromise,
        ]);

        stormcrowWriter(stormcrows);
        setStormcrowTags(stormcrows);
        const {metadata, preferenceSettingMetadata} = fetchUserMetadataResponse;

        const initData = {...initializationData};

        TimeAgo.addLocale(timeAgoLocale);

        intlWriter(
          createIntl(
            {
              defaultLocale: 'en',
              locale,
              messages: uiStrings,
              onError: onIntlError,
            },
            createIntlCache(),
          ),
        );

        const validatedInitData = {
          usersurvey_id: initData.usersurvey_id ? initData.usersurvey_id : '',
          amplitude_safe_user_id: initData.user_id_for_amplitude ?? '',
          is_csm_team: initData.is_csm_team ? initData.is_csm_team : false,
          is_team_member: initData.is_on_team ? initData.is_on_team : false,
          is_team_admin: initData.is_team_admin ? initData.is_team_admin : false,
          amplitude_safe_team_id: initData.team_id_for_amplitude ?? '',
          is_dropboxer: initData.is_dropboxer ?? false,
          active_team_size: initData.active_team_size ?? 0,
          sku_name: initData.sku_name,
          locale,
          user_pid: initData.user_pid ?? '',
          user_pid_eci: initData.user_pid_eci ?? '',
          has_onboarding_v2: initData.has_onboarding_v2 ?? false,
          sku_family: initData.sku_family ?? '',
          is_self_serve_user: initData.is_self_serve_user ?? false,
          is_managed_user: initData.is_managed_user ?? false,
          is_standalone_user: initData.is_standalone_user ?? false,
          is_team_locked: initData.is_team_locked ?? false,
          team_last_suspended: new Date((initData.team_last_suspended_ts ?? 0) * 1000),
        };

        const currentAccount = {
          ...validatedInitData,
          ...basicUserAccount,
          id: uid,
          photo_url: basicUserAccount.profile_photo_url,
        };
        setCurrentUser(currentAccount);

        if (checkPithosPrivacyConsentFlag()) {
          dropboxClient
            .ccpaGenerateCcpaEmailToken({
              raw_email_address: currentAccount.email,
              is_account_verified: currentAccount.email_verified,
            })
            .then((response) => response.result.token)
            .catch((e) => {
              reportFailedDataFetch(e, 'GenerateCCPAEmailToken');
              return '';
            })
            .then((token) => {
              tryPrivacyConsentInit(token);
            });
        }

        const initializeUserleap = () => {
          UserSurvey.init(
            currentAccount.usersurvey_id,
            currentAccount.is_csm_team,
            Boolean(currentAccount.amplitude_safe_team_id),
            currentAccount.is_dropboxer,
          );
        };

        if (globalLoggingSwitch.canUseStorage()) {
          initializeUserleap();
        } else {
          globalLoggingSwitch.addEventListener('storageEnabled', initializeUserleap);
        }

        const setMetadata = (key: USER_METADATA_FIELD, value: number | boolean | string) => {
          const apiPromise = dropboxClient.userMetadataUserMetadataSet({
            metadata: {[USER_METADATA_FIELD[key]]: JSON.stringify(value)},
          });
          setState((previous) => {
            if (previous.status === 'logged in') {
              return {
                ...previous,
                metadata: {
                  ...previous.metadata,
                  [USER_METADATA_FIELD[key]]: value,
                },
              };
            } else {
              return previous;
            }
          });
          return apiPromise;
        };

        const setPreferenceSettingMetadata = (
          key: PREFERENCE_SETTING_METADATA_FIELD,
          value: string | boolean,
        ) => {
          const metadataValue = !value || value === 'OFF' ? 'OFF' : 'ON';
          setState((previous) => {
            if (previous.status === 'logged in') {
              return {
                ...previous,
                preferenceSettingMetadata: {
                  ...previous.preferenceSettingMetadata,
                  [PREFERENCE_SETTING_METADATA_FIELD[key]]: metadataValue,
                },
              };
            } else {
              return previous;
            }
          });
        };

        const refreshProvisions = () => {
          refreshProvisionsData(queryClient).then((provisions) => {
            setState((previous) => {
              if (previous.status === 'logged in') {
                return {
                  ...previous,
                  provisions,
                };
              } else {
                return previous;
              }
            });
          });
        };

        const roleTag = initData.role ? initData.role['.tag'] : 'other';
        const currentRole = roleTag === 'other' ? 'unpaired' : roleTag;

        setState((previous) => ({
          switchRole,
          currentAccount,
          dropboxAuth,
          dropboxClient,
          status: 'logged in',
          hasGuestUserInfo: () => false,
          isCookieSnackbarOpen: false,
          setIsCookieSnackbarOpen,
          logout,
          isAnonymousUser: false,
          uiStrings,
          locale,
          provisions,
          metadata,
          setMetadata,
          setPreferenceSettingMetadata,
          currentRole,
          preferenceSettingMetadata,
          timeAgoLocale,
          refreshProvisions,
        }));
      } catch (e) {
        if (e.status) {
          console.debug(
            'Failed to log in; server response ' + e.status + ': ' + JSON.stringify(e.error),
          );

          if (e.status === 400 && e.error.error === 'invalid_grant') {
            replayStorage.logUserOut();
            console.debug('Removed local credentials');
            setupLoggedOut();
            return;
          }
        }

        setState({status: 'error', error: e});
      }
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const onLoginComplete = React.useCallback(
    async (uid: number, accountId: string, refreshToken: string) => {
      clearPurchaseStorageItems();
      replayStorage.logUserIn(uid, accountId, refreshToken);
      dropboxSdk.update({refreshToken});
      await setupLoggedIn(uid, accountId);
    },
    [setupLoggedIn],
  );

  const initScriptNames = React.useCallback(async () => {
    const scripts = document.getElementsByTagName('script');

    for (const script of scripts) {
      const scriptName = script.src;
      if (verifyMainBundle(scriptName)) {
        setMainBundleVersion(scriptName);
      } else if (verifyRuntimeBundle(scriptName)) {
        setRuntimeBundleVersion(scriptName);
      }
    }
  }, []);

  const setupLoggedOut = React.useCallback(async () => {
    if (checkPithosPrivacyConsentFlag()) {
      tryPrivacyConsentInit();
    }

    try {
      const stormcrows = await fetchStormcrows();
      stormcrowWriter(stormcrows);
      setStormcrowTags(stormcrows);
      initGrowthbook();

      replayStorage.setupLoggedOut();

      const guestUserInfo = replayStorage.getGuestUserInfo();

      const locale = getI18nLocale(navigator.language);
      const [uiStrings, timeAgoLocale] = await Promise.all([
        getI18nLocaleData(locale),
        getTimeAgoLocaleData(locale),
      ]);

      TimeAgo.addLocale(timeAgoLocale);

      intlWriter(
        createIntl(
          {
            defaultLocale: 'en-US',
            locale,
            messages: uiStrings,
            onError: onIntlError,
          },
          createIntlCache(),
        ),
      );

      setState((previous) => ({
        dropboxAuth: dropboxSdk.auth,
        dropboxClient: dropboxSdk.client,
        onLoginComplete,
        status: 'logged out',
        guestUserInfo,
        setGuestUserInfo,
        hasGuestUserInfo: () => {
          return hasGuestUserInfo(guestUserInfo);
        },
        setIsCookieSnackbarOpen,
        isAnonymousUser: !hasGuestUserInfo(guestUserInfo),
        isCookieSnackbarOpen: false,
        uiStrings,
        locale,
        timeAgoLocale,
      }));
    } catch (err) {
      setState({status: 'error', error: err});
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    (async () => {
      const {uid, accountId} = replayStorage.getLoggedInUserInfo();
      initScriptNames();

      if (uid && accountId) {
        await setupLoggedIn(parseInt(uid, 10), accountId);
      } else {
        setupLoggedOut();
      }
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (state.status === 'loading') {
    return null;
  } else if (state.status === 'error') {
    return <ErrorPage errorToReport={state.error} />;
  }
  initializePapLogger(state);
  return render(state);
};
