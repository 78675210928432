import {useCallback, useMemo} from 'react';

import {useAtomValue} from 'jotai';
import {css, keyframes} from 'styled-components';

import {useViewport} from '~/components/viewport_context';
import type {STEPS} from '~/state/onboarding_v2';
import {currOpenStepAtom, isChecklistOpenAtom, onboardingTypeAtom} from '~/state/onboarding_v2';

export type FocusStyles = ReturnType<typeof css>;

// Create the keyframes
const pulse = keyframes`
  0% {
    box-shadow: 0 0 0 var(--dig-color__utility__focus-ring);
  }
  50% {
    box-shadow: 0 0 30px var(--dig-color__utility__focus-ring);
  }
  100% {
    box-shadow: 0 0 0 var(--dig-color__utility__focus-ring);
  }
`;

const useFocusRing = (stepsForFocus: STEPS[], override?: boolean) => {
  // eslint-disable-next-line deprecation/deprecation
  const {isSmallScreenSize} = useViewport();
  const currOpenStep = useAtomValue(currOpenStepAtom);
  const isChecklistOpen = useAtomValue(isChecklistOpenAtom);
  const onboardingType = useAtomValue(onboardingTypeAtom);

  const isFocused = useMemo(
    () =>
      override === true ||
      (!!onboardingType &&
        !!currOpenStep &&
        stepsForFocus.includes(currOpenStep) &&
        !isSmallScreenSize &&
        isChecklistOpen),
    [override, onboardingType, currOpenStep, stepsForFocus, isSmallScreenSize, isChecklistOpen],
  );

  const pulseAnimation = useCallback(
    () =>
      isFocused
        ? css`
            ${pulse} 1s infinite
          `
        : css``,
    [isFocused],
  );

  if (!isFocused) {
    return css``;
  }

  return css`
    && {
      animation: ${pulseAnimation};
      border-radius: 4px;
      box-shadow: 0 0 0 var(--dig-color__utility__focus-ring);
      box-sizing: border-box;
      clip-path: none;
      outline: 4px solid ${isFocused ? 'var(--dig-color__utility__focus-ring)' : 'transparent'};
    }
  `;
};

export {useFocusRing};
