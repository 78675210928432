import React from 'react';

import styled, {css} from 'styled-components';

import {breakpointSmall, spacing} from '~/components/styled';

import {LoadingProjectTile} from './project_tile';

export const TILE_COLUMN_GAP = 16; // Macro XSmall

export interface TileGridProps extends React.HTMLAttributes<HTMLDivElement> {
  $isSingleLine?: boolean;
}

const TileGridRoot = styled.div<TileGridProps>`
  display: grid;
  grid-auto-rows: 1fr;
  grid-column-gap: ${TILE_COLUMN_GAP}px;
  grid-row-gap: ${TILE_COLUMN_GAP}px;
  margin-bottom: ${TILE_COLUMN_GAP}px;
  grid-template-columns: repeat(auto-fill, minmax(calc(${spacing('84')} * 2), 1fr));

  ${breakpointSmall(css`
    grid-template-columns: repeat(auto-fill, minmax(246px, 1fr));
  `)}

  ${({$isSingleLine}) =>
    $isSingleLine &&
    css`
      grid-template-rows: 1fr;
      grid-auto-rows: 0;
      grid-row-gap: 0;
      overflow: hidden;
    `}
`;

export const TileGrid = (props: TileGridProps) => {
  return <TileGridRoot {...props} />;
};

export const LoadingGridPlaceholder = () => {
  return (
    <TileGrid>
      <LoadingProjectTile key="project-loading-skeleton-1" />
      <LoadingProjectTile key="project-loading-skeleton-2" opacity={0.8} />
      <LoadingProjectTile key="project-loading-skeleton-3" opacity={0.4} />
    </TileGrid>
  );
};
