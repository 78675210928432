/* eslint-disable deprecation/deprecation */
import React from 'react';

import {useAtom, useAtomValue, useSetAtom} from 'jotai';
import {useIntl} from 'react-intl';
import styled from 'styled-components';

import {Checkbox, SegmentedControl} from '@dropbox/dig-components/controls';
import {Text} from '@dropbox/dig-components/typography';
import {UIIcon} from '@dropbox/dig-icons';
import {GridViewLine, ListViewLine} from '@dropbox/dig-icons/assets';

import {
  CommentsInfoCol,
  CreatedTimeCol,
  OverflowMenuCol,
  PrimaryCol,
  StatusCol,
  UpdatedTimeCol,
  UsageCol,
} from '~/components/list-table';
import {spacing} from '~/components/styled';
import {SMALL_SCREEN_WIDTH_BREAKPOINT} from '~/components/viewport_context';
import {isDefined} from '~/lib/comments/components/types';
import type {LoggingClient} from '~/lib/logging/logger';
import {useMatchesTutorialsRoute} from '~/lib/onboarding_v2/use_matches_tutorials_route';
import {StormcrowIsOn} from '~/lib/stormcrow';
import {useBrowseLogEventMaker} from '~/pages/browse_page/browse_logging_context';
import {NonSortableHeader, SortingControl} from '~/pages/browse_page/components/sorting_control';
import {useFoldersSharedState} from '~/pages/browse_page/folders_context';
import type {ViewType} from '~/pages/browse_page/types';
import {FilterType} from '~/pages/browse_page/types';
import {
  allItemsSelectedAtom,
  browseViewTypeAtom,
  noItemsSelectedAtom,
  toggleSelectAllItemAtom,
} from '~/state/browse';
import {BulkActionStatus, bulkActionStatusAtom} from '~/state/bulk_actions';

import {FilterDropdown} from './filter_dropdown';
import {SortingDropdown} from './sorting_dropdown';

const Control = styled(SegmentedControl)`
  gap: ${spacing('Micro XSmall')};
`;

const ViewTypeControl = () => {
  const intl = useIntl();
  const {currentProjectAmplitudeId} = useFoldersSharedState();
  const makeLogEvent = useBrowseLogEventMaker();

  const logEvent = React.useMemo(
    () => makeLogEvent({projectId: currentProjectAmplitudeId}),
    [makeLogEvent, currentProjectAmplitudeId],
  );

  const [viewType, setViewType] = useAtom(browseViewTypeAtom);

  const handleSelection = (viewType: ViewType) => {
    logEvent('select_change_view', {view_type: viewType});
    setViewType(viewType);
  };

  const listViewAriaLabel = intl.formatMessage({
    defaultMessage: 'list view',
    id: 'uJXrsd',
    description:
      'Aria label describing a button that switches the files to be shown as a list instead of thumbnails.',
  });
  const gridViewAriaLabel = intl.formatMessage({
    defaultMessage: 'tile view',
    id: 'Joypvn',
    description:
      'Aria label describing a button that switches the files to be shown as thumbnail tiles instead of a list.',
  });

  return (
    <Control onSelection={(value) => handleSelection(value as ViewType)} selectedValue={viewType}>
      <SegmentedControl.Option aria-selected={viewType === 'list'} value="list">
        <UIIcon aria-label={listViewAriaLabel} src={ListViewLine} />
      </SegmentedControl.Option>
      <SegmentedControl.Option aria-selected={viewType === 'tile'} value="tile">
        <UIIcon aria-label={gridViewAriaLabel} src={GridViewLine} />
      </SegmentedControl.Option>
    </Control>
  );
};

const SettingsRowWrapper = styled.div<{$hasTopBorder: boolean; $viewType: ViewType}>`
  align-content: center;
  align-items: center;
  display: flex;
  padding: ${spacing('Micro Large')} 0;

  // Checkbox renders 1px too far and mis-aligns with the rest of the columns
  padding-left: 1px;

  ${({$hasTopBorder}) =>
    $hasTopBorder ? 'border-top: 1px solid var(--dig-color__border__base);' : ''}

  ${({$viewType}) =>
    $viewType === 'list' ? 'box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.12);' : ''}
`;

const CheckboxCol = styled(PrimaryCol)`
  flex: 0 0 35px;
  height: 20px;
`;

const SortAndFilterSectionWrapper = styled.div`
  align-items: center;
  display: flex;
`;

const MaybeBorderedFilterSectionWrapper = styled(SortAndFilterSectionWrapper)<{
  $hasTopBorder: boolean;
}>`
  ${({$hasTopBorder}) =>
    $hasTopBorder ? 'border-top: 1px solid var(--dig-color__border__base);' : ''}
  padding-left: 0;
  padding-top: 20px;
  margin-bottom: var(--spacing__unit--1);
`;

const LineDivider = styled.div`
  display: flex;
  margin-left: var(--spacing__unit--2);
  margin-right: var(--spacing__unit--1_5);
  height: 25px;
  width: 2px;
  background: var(--dig-color__border__base);
  @media (max-width: ${SMALL_SCREEN_WIDTH_BREAKPOINT}px) {
    display: none;
  }
`;

const SortAndFilterDiv = styled.div`
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;

  @media (max-width: ${SMALL_SCREEN_WIDTH_BREAKPOINT}px) {
    max-width: 100%;
    overflow-x: auto;
    padding: 6px 32px 6px 0;
  }
`;

const ListSortAndFilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

const ListFilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

const OptionLabel = styled.div`
  flex-shrink: 0;
`;

const FilterOrSearchDivItem = styled.div`
  margin-left: var(--spacing__unit--1);
`;

type SettingsRowProps = {
  logEvent: LoggingClient['logEvent'];
  hasTopBorder: boolean;
  hasSelectAllCheckbox: boolean;
  isAdminPageView?: boolean;
  isAdminUserPageView?: boolean;
};

export const useSettingsRow = (props: React.PropsWithChildren<SettingsRowProps>) => {
  const {isAdminPageView, isAdminUserPageView} = props;
  const intl = useIntl();
  const isReplayQuotaCountingEnabled = StormcrowIsOn.useReplayFssQuotaCounting();
  const {matchesTutorials} = useMatchesTutorialsRoute();

  const viewType = useAtomValue(browseViewTypeAtom);
  const {currentFolder} = useFoldersSharedState();

  const allItemsSelected = useAtomValue(allItemsSelectedAtom);
  const noItemsSelected = useAtomValue(noItemsSelectedAtom);
  const toggleSelectAllItems = useSetAtom(toggleSelectAllItemAtom);
  const bulkActionStatus = useAtomValue(bulkActionStatusAtom);

  const allProjectIds =
    currentFolder?.projectsWithVideos.map(({videos}) => videos?.[0].project_id).filter(isDefined) ||
    [];

  const hasProjects = allProjectIds.length !== 0;

  const sortByLabelText = intl.formatMessage({
    defaultMessage: 'Sort by',
    id: 'YF+VZw',
    description: 'Label for a dropdown menu that allows the user to sort on various properties.',
  });
  const filterByLabelText = intl.formatMessage({
    defaultMessage: 'Filter by',
    id: 'cvg/cS',
    description:
      'Label for a few dropdown menus that allows the user to filter their files based on media type or status.',
  });

  // Disable multi-select checkboxes when requests are active
  const checkboxDisabled = bulkActionStatus === BulkActionStatus.REQUESTING;

  const checkbox = (
    <Checkbox
      aria-label="Select media"
      checked={allItemsSelected}
      disabled={checkboxDisabled}
      isIndeterminate={!noItemsSelected && !allItemsSelected}
      onChange={toggleSelectAllItems}
    />
  );

  if (viewType === 'tile' && !isAdminPageView) {
    return [
      <SettingsRowWrapper $hasTopBorder={props.hasTopBorder} $viewType="tile" key="1">
        {props.hasSelectAllCheckbox && <CheckboxCol>{hasProjects && checkbox}</CheckboxCol>}
        <SortAndFilterDiv>
          <SortAndFilterSectionWrapper>
            <OptionLabel>
              <Text color="faint" variant="label">
                {sortByLabelText}
              </Text>
            </OptionLabel>
            <FilterOrSearchDivItem>
              <SortingDropdown />
            </FilterOrSearchDivItem>
          </SortAndFilterSectionWrapper>
          <SortAndFilterSectionWrapper>
            <LineDivider />
            <OptionLabel>
              <Text color="faint" variant="label">
                {filterByLabelText}
              </Text>
            </OptionLabel>
            <FilterOrSearchDivItem>
              <FilterDropdown filter={FilterType.STATUS} logEvent={props.logEvent} />
            </FilterOrSearchDivItem>
            <FilterOrSearchDivItem>
              <FilterDropdown filter={FilterType.MEDIA_TYPE} logEvent={props.logEvent} />
            </FilterOrSearchDivItem>
          </SortAndFilterSectionWrapper>
        </SortAndFilterDiv>
        <OverflowMenuCol>{!matchesTutorials && <ViewTypeControl />}</OverflowMenuCol>
      </SettingsRowWrapper>,
    ];
  }

  const nameLabelText = intl.formatMessage({
    defaultMessage: 'Name',
    id: 'ZlIRqk',
    description: 'Title describing a column of file names.',
  });
  const statusLabelText = intl.formatMessage({
    defaultMessage: 'Status',
    id: 'A6RJgM',
    description: 'Title describing a column of file approval statuses.',
  });
  // Only used for Admin Page - begin
  const typeLabelText = intl.formatMessage({
    defaultMessage: 'Type',
    id: 'lVYnKK',
    description: 'Title describing a column of the type of admin row (folder or user).',
  });
  const usageLabelText = intl.formatMessage({
    defaultMessage: 'Usage',
    id: 'NbzbKq',
    description: 'Title describing a column of the usage for the given folder or user.',
  });
  const ownerLabelText = intl.formatMessage({
    defaultMessage: 'Owner',
    id: '+zIt2b',
    description: 'Title describing a column of the owner for a given folder.',
  });
  // Only used for Admin Page - end

  const updatedLabelText = intl.formatMessage({
    defaultMessage: 'Updated',
    id: 'Iv2Ea6',
    description: 'Title describing a column of file last updated times.',
  });
  const createdLabelText = intl.formatMessage({
    defaultMessage: 'Created',
    id: 'mWjgxQ',
    description: 'Title describing a column of file creation times.',
  });
  const commentsLabelText = intl.formatMessage({
    defaultMessage: 'Comments/Views',
    id: 'oq0zRi',
    description:
      'Title describing a column that indicates the number of comments and views in each file.',
  });

  const showAdditionalSettings = !isAdminPageView || isAdminUserPageView;

  return [
    <MaybeBorderedFilterSectionWrapper $hasTopBorder={props.hasTopBorder} key="2">
      <ListSortAndFilterWrapper>
        <SortAndFilterDiv>
          <SortAndFilterSectionWrapper>
            <OptionLabel>
              <Text color="faint" variant="label">
                {sortByLabelText}
              </Text>
            </OptionLabel>
            <FilterOrSearchDivItem>
              <SortingDropdown />
            </FilterOrSearchDivItem>
          </SortAndFilterSectionWrapper>
        </SortAndFilterDiv>
        <LineDivider />
        <ListFilterWrapper>
          <Text color="faint" variant="label">
            {filterByLabelText}
          </Text>
          {isAdminPageView ? (
            <>
              <FilterOrSearchDivItem>
                <FilterDropdown filter={FilterType.ADMIN_FOLDER_TYPE} logEvent={props.logEvent} />
              </FilterOrSearchDivItem>
            </>
          ) : (
            <>
              <FilterOrSearchDivItem>
                <FilterDropdown filter={FilterType.STATUS} logEvent={props.logEvent} />
              </FilterOrSearchDivItem>
              <FilterOrSearchDivItem>
                <FilterDropdown filter={FilterType.MEDIA_TYPE} logEvent={props.logEvent} />
              </FilterOrSearchDivItem>
            </>
          )}
        </ListFilterWrapper>
      </ListSortAndFilterWrapper>
    </MaybeBorderedFilterSectionWrapper>,
    <SettingsRowWrapper $hasTopBorder={false} $viewType="list" key="3" role="row">
      {props.hasSelectAllCheckbox && (
        <CheckboxCol role="columnheader">{hasProjects && checkbox}</CheckboxCol>
      )}
      <SortingControl attribute="name" component={PrimaryCol} labelText={nameLabelText} />
      <StatusCol role="columnheader">
        {showAdditionalSettings ? (
          <NonSortableHeader labelText={statusLabelText} />
        ) : (
          isReplayQuotaCountingEnabled && <Text variant="label">{typeLabelText}</Text>
        )}
      </StatusCol>
      {isAdminPageView && isReplayQuotaCountingEnabled && !showAdditionalSettings && (
        <UsageCol>
          <Text variant="label">{usageLabelText}</Text>
        </UsageCol>
      )}
      <SortingControl attribute="updated" component={UpdatedTimeCol} labelText={updatedLabelText} />
      <SortingControl attribute="created" component={CreatedTimeCol} labelText={createdLabelText} />
      <CommentsInfoCol role="columnheader">
        {showAdditionalSettings ? (
          <NonSortableHeader labelText={commentsLabelText} />
        ) : (
          isReplayQuotaCountingEnabled && <Text variant="label">{ownerLabelText}</Text>
        )}
      </CommentsInfoCol>
      <OverflowMenuCol role="columnheader">
        {!isAdminPageView && <ViewTypeControl />}
      </OverflowMenuCol>
    </SettingsRowWrapper>,
  ];
};
