import {privacyConsentModule} from '../initialize_privacy_module';

class GlobalLoggingSwitch extends EventTarget {
  private hasUserAcceptedCookies = false;

  constructor() {
    super();
    const updateUserCookiesConsent = () => {
      const {analytics} = privacyConsentModule.getConsentChoices();
      this.setHasUserAcceptedCookies(analytics);
    };

    updateUserCookiesConsent();

    privacyConsentModule.onConsentChange(updateUserCookiesConsent);
  }

  public canUseStorage() {
    return this.hasUserAcceptedCookies;
  }

  private setHasUserAcceptedCookies(value: boolean) {
    if (value !== this.hasUserAcceptedCookies) {
      this.hasUserAcceptedCookies = value;
      this.dispatchEvent(
        this.hasUserAcceptedCookies ? new Event('storageEnabled') : new Event('storageDisabled'),
      );
    }
  }
}

export const globalLoggingSwitch = new GlobalLoggingSwitch();
