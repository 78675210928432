import React from 'react';

import {useAtomValue, useSetAtom} from 'jotai';
import {FormProvider, type SubmitHandler, useForm} from 'react-hook-form';
import {FormattedMessage} from 'react-intl';

import type {reel} from '@dropbox/api-v2-client';
import {Modal} from '@dropbox/dig-components/modal';
import {Tabs} from '@dropbox/dig-components/tabs';
import {Tooltip} from '@dropbox/dig-components/tooltips';

import {Button} from '~/components/button';
import {CustomizeWatermarkTab} from '~/components/share_modal/customize_watermark_tab';
import {
  LinkSettings,
  ModalErrorText,
  TabTitleContent,
} from '~/components/share_modal/share_modal_settings';
import {
  useShareSettingStrings,
  useWatermarkSettingsText,
} from '~/components/share_modal/share_modal_text';
import {
  basicShareLinksForItemAtom,
  getShareItemAtom,
  getShareModalLoggingInfoAtom,
  updateNewlyCreatedShareTokensAtom,
  watermarkLinksForItemAtom,
} from '~/components/share_modal/share_state';
import type {WatermarkFormValues} from '~/components/share_modal/watermark_types';
import type {WatermarkLinkInfo} from '~/lib/api';
import {commonText} from '~/lib/common_text';
import {ReplayError, ReplayErrorCategory, reportException} from '~/lib/error_reporting';
import {getShareTokenFromLink} from '~/lib/helpers';
import {useLinkActions} from '~/lib/use_link_actions';

type WatermarkSettingsProps = {
  selectedTab: WatermarkSettingsTab;
  setSelectedTab: (tab: WatermarkSettingsTab) => void;
  onRequestClose: () => void;
  canCreateLinks: boolean;
  currentLinkInfo?: WatermarkLinkInfo;
};

export type WatermarkSettingsTab = 'customize-watermark' | 'share-settings';

export const WatermarkSettings = (props: WatermarkSettingsProps) => {
  const {selectedTab, setSelectedTab, onRequestClose, canCreateLinks, currentLinkInfo} = props;
  const shareItem = useAtomValue(getShareItemAtom);
  const loggingInfo = useAtomValue(getShareModalLoggingInfoAtom);
  const setBasicLinkInfo = useSetAtom(basicShareLinksForItemAtom);
  const setWatermarkLinkInfo = useSetAtom(watermarkLinksForItemAtom);
  const addNewlyCreatedSharedTokens = useSetAtom(updateNewlyCreatedShareTokensAtom);

  const shareSettingsText = useShareSettingStrings(shareItem?.name ?? '');

  const [errorText, setErrorText] = React.useState('');
  const watermarkSettingsText = useWatermarkSettingsText();
  const initialWatermarkSettings = currentLinkInfo?.watermarkInfo;
  const initialShareSettings = currentLinkInfo?.shareInfo;

  const methods = useForm<WatermarkFormValues>({
    defaultValues: {
      showEmail: initialWatermarkSettings?.showEmail ?? false,
      recipients: [],
      showIP: initialWatermarkSettings?.showIp ?? false,
      showViewDate: initialWatermarkSettings?.showViewDate ?? false,
      showViewTime: initialWatermarkSettings?.showViewTime ?? false,
      position: initialWatermarkSettings?.position ?? 'center',
      theme: initialWatermarkSettings?.theme ?? 'dark',
      opacity: initialWatermarkSettings?.textOpacity ?? 80,
      size: initialWatermarkSettings?.textSize ?? 80,
      customText: initialWatermarkSettings?.customText ?? watermarkSettingsText.customTextDefault,
      passwordEnabled: initialShareSettings?.hasPassword ?? false,
      password: '',
      linkDisabled: initialShareSettings?.expired ?? false,
      loggedOutAccessDisabled: initialShareSettings?.loggedOutAccessDisabled ?? false,
      viewPreviousCommentsDisabled: initialShareSettings?.viewPreviousCommentsDisabled ?? false,
    },
  });
  const {
    handleSubmit,
    formState: {isSubmitting},
  } = methods;

  function useFormState<Key extends keyof WatermarkFormValues>(
    name: Key,
  ): [WatermarkFormValues[Key], (value: WatermarkFormValues[Key]) => void] {
    return [
      methods.watch(name),
      (value: WatermarkFormValues[Key]) => methods.setValue(name, value as unknown as any),
    ];
  }

  const [recipients, setRecipients] = useFormState('recipients');

  // General share link settings
  const [passwordEnabled, setPasswordEnabled] = useFormState('passwordEnabled');
  const setPassword = (password: string) => methods.setValue('password', password);
  const [linkDisabled, setLinkDisabled] = useFormState('linkDisabled');

  const [loggedOutAccessDisabled, setLoggedOutAccessDisabled] =
    useFormState('loggedOutAccessDisabled');

  const [viewPreviousCommentsDisabled, setViewPreviousCommentsDisabled] = useFormState(
    'viewPreviousCommentsDisabled',
  );

  const togglePassword = () => {
    setErrorText('');
    const newPasswordEnabled = !passwordEnabled;
    setPasswordEnabled(newPasswordEnabled);
    if (!newPasswordEnabled) {
      setPassword('');
    }
  };

  const toggleLinkDisabled = () => {
    setErrorText('');
    setLinkDisabled(!linkDisabled);
  };

  const toggleLoggedOutAccessDisabled = () => {
    setErrorText('');
    setLoggedOutAccessDisabled(!loggedOutAccessDisabled);
  };

  const updatePassword = (updatedPassword: string) => {
    setErrorText('');
    setPassword(updatedPassword);
  };

  const toggleViewPreviousCommentsDisabled = () => {
    setErrorText('');
    setViewPreviousCommentsDisabled(!viewPreviousCommentsDisabled);
  };

  // Only one watermark link can be created per email address, so we check for duplicates
  const [hasDuplicateEmail, setHasDuplicateEmail] = React.useState(false);
  const tooltipRef = React.useRef<HTMLDivElement>(null);
  const [showDisabledTooltip, setShowDisabledTooltip] = React.useState(false);
  const watermarkLinks = useAtomValue(watermarkLinksForItemAtom);
  const existingWatermarkUsers = watermarkLinks
    .filter((linkInfo) => linkInfo.watermarkInfo.email)
    .map((linkInfo) => {
      return {type: 'user' as const, email: linkInfo.watermarkInfo.email};
    });

  const {getExistingLinks, createWatermarkShareLinks, updateWatermarkLink} = useLinkActions({
    logEvent: loggingInfo.logEvent,
    linkItemId: shareItem?.id ?? '',
    linkItemType: shareItem?.type ?? 'file',
    versionSummaries: shareItem?.type === 'file' ? shareItem?.versionSummaries : [],
    location: loggingInfo.location,
  });

  const onSave: SubmitHandler<WatermarkFormValues> = async ({
    recipients,
    showIP,
    showViewDate,
    showViewTime,
    position,
    theme,
    opacity,
    size,
    customText,
    passwordEnabled,
    password,
    linkDisabled,
    loggedOutAccessDisabled,
    viewPreviousCommentsDisabled,
  }) => {
    if (passwordEnabled && !password.trim()) {
      setErrorText(shareSettingsText.emptyPasswordError);
      return;
    }

    let newLinks: reel.ShareLinkInfoV2[] = [];
    try {
      if (currentLinkInfo) {
        // Only update the expiry time if the setting has changed
        const setLinkDisabled = linkDisabled !== currentLinkInfo?.shareInfo.expired;
        await updateWatermarkLink(
          currentLinkInfo.shareInfo.shareToken,
          password,
          passwordEnabled,
          loggedOutAccessDisabled,
          viewPreviousCommentsDisabled,
          setLinkDisabled,
          linkDisabled,
          showIP,
          showViewDate,
          showViewTime,
          customText,
          position,
          theme,
          opacity,
          size,
          !!currentLinkInfo.watermarkInfo.email,
        );
      } else {
        newLinks = await createWatermarkShareLinks(
          password,
          loggedOutAccessDisabled,
          viewPreviousCommentsDisabled,
          linkDisabled,
          recipients.map((recipient) => recipient.email),
          showIP,
          showViewDate,
          showViewTime,
          customText,
          position,
          theme,
          opacity,
          size,
        );
      }
      if (newLinks.length) {
        const newShareTokens = newLinks.map((link) =>
          getShareTokenFromLink(link.share_link_info?.link ?? ''),
        );
        addNewlyCreatedSharedTokens(newShareTokens);
      }
      const refreshedLinks = await getExistingLinks();
      setBasicLinkInfo(refreshedLinks.basicLinks);
      setWatermarkLinkInfo(refreshedLinks.watermarkLinks);
      onRequestClose();
    } catch (e) {
      reportException(
        new ReplayError({
          severity: 'non-critical',
          error: e,
          message: 'Failed to update link settings',
          // Label this as uncaught since the error may have been from the API
          // or from preparing the request
          category: ReplayErrorCategory.UncaughtException,
        }),
      );
      setErrorText(shareSettingsText.shareLinkError);
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSave)}>
          <Modal.Header hasBottomSpacing="title-standard" />
          <Modal.Body>
            <Tabs
              onSelection={(value) => {
                if (value === 'customize-watermark' || value === 'share-settings') {
                  setSelectedTab(value);
                }
              }}
              selectedTab={selectedTab}
            >
              <Tabs.Group justified>
                <Tabs.Tab id="customize-watermark" style={{maxWidth: '50%'}}>
                  <TabTitleContent>{watermarkSettingsText.customizeTitle}</TabTitleContent>
                </Tabs.Tab>
                <Tabs.Tab id="share-settings">
                  <TabTitleContent>{watermarkSettingsText.settingsTitle}</TabTitleContent>
                </Tabs.Tab>
              </Tabs.Group>
              <Tabs.Panel
                style={{marginTop: 'var(--spacing__unit--2_5)'}}
                tabId="customize-watermark"
              >
                <CustomizeWatermarkTab
                  currentLinkInfo={currentLinkInfo}
                  existingWatermarkUsers={existingWatermarkUsers}
                  hasDuplicateEmail={hasDuplicateEmail}
                  recipients={recipients}
                  setHasDuplicateEmail={setHasDuplicateEmail}
                  setRecipients={setRecipients}
                />
              </Tabs.Panel>
              <Tabs.Panel style={{marginTop: 'var(--spacing__unit--2_5)'}} tabId="share-settings">
                <LinkSettings
                  canCreateLinks={canCreateLinks}
                  currentLinkInfo={currentLinkInfo?.shareInfo ?? null}
                  downloadSettingProps={false}
                  isSaving={isSubmitting}
                  linkDisabled={linkDisabled}
                  loggedOutAccessDisabled={loggedOutAccessDisabled}
                  passwordEnabled={passwordEnabled}
                  toggleLinkDisabled={toggleLinkDisabled}
                  toggleLoggedOutAccessDisabled={toggleLoggedOutAccessDisabled}
                  togglePassword={togglePassword}
                  toggleViewPreviousCommentsDisabled={toggleViewPreviousCommentsDisabled}
                  updatePassword={updatePassword}
                  viewOnly={false}
                  viewPreviousCommentsDisabled={viewPreviousCommentsDisabled}
                />
              </Tabs.Panel>
            </Tabs>
          </Modal.Body>
          {errorText && (
            <Modal.Body>
              <ModalErrorText>{errorText}</ModalErrorText>
            </Modal.Body>
          )}
          <Modal.Footer>
            <Button onClick={onRequestClose} variant="outline">
              <FormattedMessage {...commonText.cancelText} />
            </Button>
            <div
              onMouseEnter={() => setShowDisabledTooltip(hasDuplicateEmail)}
              onMouseLeave={() => setShowDisabledTooltip(false)}
            >
              <Button
                disabled={hasDuplicateEmail}
                isLoading={isSubmitting}
                ref={tooltipRef}
                type="submit"
                variant="primary"
              >
                <FormattedMessage {...commonText.saveText} />
              </Button>
            </div>
            <Tooltip.Control
              auto
              open={showDisabledTooltip}
              placement="top"
              triggerRef={tooltipRef}
              variant="basic"
            >
              {watermarkSettingsText.removeDuplicateEmailError}
            </Tooltip.Control>
          </Modal.Footer>
        </form>
      </FormProvider>
      )
    </>
  );
};
