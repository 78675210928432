import {PAP_Link_ReplayOnedriveAccount} from 'pap-events/replay/link_replay_onedrive_account';
import {PAP_Open_OnedriveFilePicker} from 'pap-events/replay/open_onedrive_file_picker';
import {PAP_Select_OnedriveFile} from 'pap-events/replay/select_onedrive_file';
import {PAP_Select_OnedriveImport} from 'pap-events/replay/select_onedrive_import';

import type {PickFileResult} from './file_picker_types';
import type {FilePickerResult, SignInResult} from './file_picker_types';
import type {LoggingClient} from '../logging/logger';

const clientId = '75beb847-b5fa-46b3-ad83-e36d2b703cdf';

// this file picker is legacy in that it's using the v7.2 File Picker
// https://learn.microsoft.com/en-us/onedrive/developer/controls/file-pickers/js-v72/
// This file picker is no longer being supported, but it does seem to work correctly.
// The modern file picker (v8 at time of writing), used in //src/lib/onedrive/file_picker.ts, is currently broken and so we're using this one
export async function legacyPickFile(
  supportedFileTypes: string[],
  loggingClient: LoggingClient,
): Promise<PickFileResult | null> {
  const replayWindow = window;

  const supportedFileTypesString = supportedFileTypes.join(',');

  const pickerToken = await auth(loggingClient);

  launchPicker(supportedFileTypesString, replayWindow, pickerToken.accessToken, loggingClient);

  const {fileId, fileName, fileSize} = await new Promise<FilePickerResult>(
    async (resolve, reject) => {
      window.addEventListener('message', (event: MessageEvent) => {
        messageHandler(event, resolve, reject, loggingClient);
      });
    },
  );

  if (fileId === '' || fileName === '' || fileSize === 0) return null;

  return {fileId: fileId, fileName: fileName, fileSize: fileSize};
}

function launchPicker(
  supportedFileTypes: string,
  replayWindow: Window,
  accessToken: string,
  loggingClient: LoggingClient,
): void {
  var pickerOptions = {
    clientId: clientId,
    action: 'query',
    multiSelect: false,
    advanced: {
      endpointHint: 'api.onedrive.com',
      queryParameters: 'select=id,name,size',
      filter: supportedFileTypes,
      accessToken: accessToken,
    },
    // @ts-ignore
    success: (files) => {
      sendMessage(files, replayWindow);
    },
    cancel: () => {
      sendCancel(replayWindow);
    },
    // @ts-ignore
    error: (error) => {
      sendErrorMessage(error, replayWindow);
    },
  };

  const script = document.createElement('script');
  script.src = 'https://js.live.net/v7.2/OneDrive.js';

  script.onload = () => {
    // @ts-ignore
    OneDrive.open(pickerOptions);
    loggingClient.logPap(PAP_Open_OnedriveFilePicker({}));
    // eslint-disable-next-line deprecation/deprecation
    loggingClient.logEvent('open_onedrive_file_picker');
  };

  document.body.appendChild(script);
}

// @ts-ignore
function sendMessage(files, replayWindow: Window): void {
  replayWindow.postMessage({type: 'success', files: files});
}

function sendCancel(replayWindow: Window): void {
  replayWindow.postMessage({type: 'cancel'});
}

function sendErrorMessage(error: Error, replayWindow: Window): void {
  replayWindow.postMessage({type: 'error', error: error});
}

function messageHandler(
  event: MessageEvent,
  resolve: (result: FilePickerResult) => void,
  reject: (message: string) => void,
  loggingClient: LoggingClient,
): void {
  switch (event.data.type) {
    case 'success':
      // structure is:
      // event.data.files.value[0]{id, name}
      const file = event.data.files.value[0];
      loggingClient.logPap(PAP_Select_OnedriveFile({}));
      // eslint-disable-next-line deprecation/deprecation
      loggingClient.logEvent('select_onedrive_file');
      resolve({fileId: file.id, fileName: file.name, fileSize: file.size});
      break;
    case 'error':
      loggingClient.logPap(PAP_Select_OnedriveImport({eventState: 'failed'}));
      // eslint-disable-next-line deprecation/deprecation
      loggingClient.logEvent('select_onedrive_import', {
        click_source: 'null',
        event_state: 'failed',
        is_logged_in: 'null',
      });
      resolve({fileId: '', fileName: '', fileSize: 0});
      break;
    case 'cancel':
      loggingClient.logPap(PAP_Select_OnedriveImport({eventState: 'canceled'}));
      // eslint-disable-next-line deprecation/deprecation
      loggingClient.logEvent('select_onedrive_import', {
        click_source: 'null',
        event_state: 'canceled',
        is_logged_in: 'null',
      });
      resolve({fileId: '', fileName: '', fileSize: 0});
    default:
      break;
  }
}

async function auth(loggingClient: LoggingClient): Promise<SignInResult> {
  const authConfig = {
    scopes: ['OneDrive.ReadWrite'],
  };

  const hostname = window.location.hostname;
  const isLocalhost = hostname === 'localhost';

  const msalConfig = {
    auth: {
      authority: 'https://login.microsoftonline.com/consumers',
      clientId: clientId,
      redirectUri: isLocalhost ? `http://localhost:5001` : `https://${hostname}`,
    },
  };

  const client = await import(/* webpackChunkName: 'onedrive' */ '@azure/msal-browser').then(
    (module) => module.PublicClientApplication.createPublicClientApplication(msalConfig),
  );

  const pickerToken = await client.acquireTokenSilent(authConfig).catch(async (error) => {
    if (error?.name === 'BrowserAuthError') {
      const loginPopupResult = await client.loginPopup(authConfig);
      client.setActiveAccount(loginPopupResult.account);
      loggingClient.logPap(PAP_Link_ReplayOnedriveAccount({}));
      // eslint-disable-next-line deprecation/deprecation
      loggingClient.logEvent('link_replay_onedrive_account');
      return client.acquireTokenSilent(authConfig);
    }
    throw new Error(error.message);
  });

  if (!pickerToken) {
    throw new Error('Failed to get picker token');
  }

  return {accessToken: pickerToken.accessToken};
}
