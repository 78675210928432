import {useAtomValue} from 'jotai';
import {useIntl} from 'react-intl';

import {UIIcon} from '@dropbox/dig-icons';
import {MoveLine} from '@dropbox/dig-icons/assets';

import {Button, IconButton} from '~/components/button';
import {useViewport} from '~/components/viewport_context';
import {BulkActionStatus, bulkActionStatusAtom} from '~/state/bulk_actions';

type BulkMoveToProjectButtonProps = {
  onClick: () => void;
};

export const BulkMoveToProjectButton = ({onClick}: BulkMoveToProjectButtonProps) => {
  // eslint-disable-next-line deprecation/deprecation
  const {isSmallScreenSize} = useViewport();
  const intl = useIntl();

  const bulkActionStatus = useAtomValue(bulkActionStatusAtom);

  const disabled = bulkActionStatus === BulkActionStatus.REQUESTING;

  const moveToProjectButton = intl.formatMessage({
    defaultMessage: 'Move to project',
    description: 'Button that allows a user to move one or more files to a project',
    id: 'BGZYsQ',
  });

  return isSmallScreenSize ? (
    <IconButton
      aria-label={moveToProjectButton}
      disabled={disabled}
      onClick={onClick}
      size="small"
      variant="opacity"
    >
      <UIIcon src={MoveLine} />
    </IconButton>
  ) : (
    <Button
      disabled={disabled}
      onClick={onClick}
      variant="opacity"
      withIconLeft={<UIIcon src={MoveLine} />}
    >
      {moveToProjectButton}
    </Button>
  );
};
