import React from 'react';

// Combine dependencies into an object that only updates when the primary
// dependency changes. Useful for writing useEffects that has multiple
// dependencies but should only run when a single dependency changes.
export function useGatedDependencies<T>(dependencies: T, primaryDependency: keyof T) {
  const [combinedDependencies, setCombinedDependencies] = React.useState<T>(dependencies);

  React.useEffect(() => {
    setCombinedDependencies((previous) => {
      if (dependencies[primaryDependency] !== previous[primaryDependency]) {
        return {
          ...dependencies,
        };
      }
      return previous;
    });
  }, [dependencies, primaryDependency]);

  return combinedDependencies;
}
