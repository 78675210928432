import {create, windowedFiniteBatchScheduler} from '@yornaath/batshit';
import type {AccessType} from 'pap-events/enums/access_type';
import type {FileType} from 'pap-events/enums/file_type';
import {type Download_File, PAP_Download_File} from 'pap-events/file_actions/download_file';

import type {team} from '@dropbox/api-v2-client';
import {type Dropbox, type DropboxResponse, type reel, type users} from '@dropbox/api-v2-client';
import {DropboxResponseError} from '@dropbox/api-v2-client';
import type {
  account,
  genie,
  media_edit,
  sharing,
  transcript_edit,
} from '@dropbox/api-v2-client/types/dropbox_types';

import type {WatermarkPositionsType} from '~/components/share_modal/position_selector';
import type {WatermarkThemeType} from '~/components/share_modal/theme_selector';
import {
  WATERMARK_DARK_THEME,
  WATERMARK_LIGHT_THEME,
} from '~/components/share_modal/watermark_preview';
import type {VersionStatus} from '~/components/status/status';
import {VERSION_STATUS_PROPERTIES} from '~/components/status/status';
import type {Branding} from '~/lib/branding';
import {dropboxSdk, getCurrentUser, getDefaultUserClient} from '~/lib/client';
import {getSecondsFromTimecode} from '~/lib/time';
import type {ChooserFile} from '~/lib/uploads/types';
import type {SortAttr} from '~/pages/browse_page/types';
import type {ReelTranscriptSegment} from '~/pages/viewer_page/components/transcript/edit_transcript';

import {unionAs} from './checks';
import {MIN_SEARCH_QUERY_LENGTH} from './constants';
import {reportFailedDataFetch, reportGDriveError, reportOneDriveError} from './error_reporting';
import {getExtension, getShareTokenFromLink, useValidExtensions} from './helpers';
import type {LoggingClient} from './logging/logger';
import {
  defaultProvisions,
  type FullProvisionsType,
  PREFERENCE_SETTING_METADATA_FIELD,
  type PreferenceSettingMetadataType,
  USER_METADATA_FIELD,
  type UserMetadataType,
} from './provisions';
import {queryClient, replayApi} from './query_client';
import {replayStorage} from './storage';
import type {PageContextType} from './utils';

type CreateVersionUiData = {
  videoVersionId: string;
  versionNum: number;
  video: reel.CreateNewVideoVersionResult['video'];
};

export enum MediaType {
  Video = 'video',
  Audio = 'audio',
  Image = 'image',
  Document = 'document',
  CreativeDoc = 'creative_doc',
}
export type MediaTypeValue = `${MediaType}`;

export const convertReelMediaTypeToMediaType = (reelMediaType: reel.ReelMediaType): MediaType => {
  let mediaType = MediaType.Video;
  if (reelMediaType['.tag'] === 'audio') {
    mediaType = MediaType.Audio;
  } else if (reelMediaType['.tag'] === 'image') {
    mediaType = MediaType.Image;
  } else if (reelMediaType['.tag'] === 'creative_doc') {
    mediaType = MediaType.CreativeDoc;
  } else if (reelMediaType['.tag'] === 'document') {
    mediaType = MediaType.Document;
  }
  return mediaType;
};
export enum GenericStatus {
  SUCCESS = 'success',
  FAILED = 'failed',
}

export type VideoStreamMetadata = {
  streamIndex?: number;
  codecType: string;
  bitrateBps?: number;
  creationTime?: string;
  codecLongName?: string;
  codecName?: string;
  codecTagString?: string;
  device?: string;
  duration?: number;
  isDefault?: boolean;
  colorSpace?: string;
  displayAspectRatio?: string;
  framesPerSecond?: number;
  height?: number;
  rotation?: number;
  width?: number;
};

export type AudioStreamMetadata = {
  streamIndex?: number;
  codecType: string;
  bitrateBps?: number;
  creationTime?: string;
  codecLongName?: string;
  codecName?: string;
  codecTagString?: string;
  device?: string;
  duration?: number;
  isDefault?: boolean;
  channelLayout?: string;
  channels?: number;
  sampleRate?: number;
};

export type ImageMetadata = {
  apertureValue?: number;
  cameraMake?: string;
  cameraModel?: string;
  caption?: string;
  category?: string;
  colorSpace?: string;
  copyright?: string;
  creator?: string;
  credit?: string;
  credits?: string;
  description?: string;
  exposureTime?: string;
  flash?: number;
  focalLength?: string;
  headline?: string;
  isoSpeed?: number;
  keywords?: string;
  lensModel?: string;
  licensorUrl?: string;
  meteringMode?: number;
  orientation?: number;
  resolutionUnit?: number;
  rightsUsageTerms?: string;
  title?: string;
  userComments?: string;
  whiteBalance?: number;
  xResolution?: number;
  yResolution?: number;
  imageDateTimeOriginal?: string;
  imageCreateDate?: string;
};

export type MediaMetadata = {
  aspectRatio?: string;
  audioChanLayout?: string;
  audioFreq?: number;
  bitRate?: number;
  captureDate?: Date;
  codec?: string;
  colorSpace?: string;
  device?: string;
  duration: number;
  durationPrecise: number;
  frameRate: number;
  resolutionHeight: number;
  resolutionWidth: number;
  timecodeSecondsOffset?: number;
  creatorTool?: string;
};

export type DocumentMetadata = {
  pages: number;
  height: number;
  width: number;
};

export type AVProp = {
  captionsTracks: reel.CaptionsTrack[];
  countsAgainstQuota: boolean;
  fileId: string;
  // This is actually the video version id
  id: string;
  isDemo: boolean;
  isWatermarked: boolean;
  name: string;
  nsId: number;
  ownerUid?: string;
  sharedLinkUrl?: string;
  sjId: number;
  posterUrl?: string;
  transcodeUrl: string;
  uploadTimestamp?: string;
  versionNum: number;
  versionStatus?: number;
  // This is the video entity id
  videoId: string;
  videoIdForAmplitude: string;
  projectName: string;
  // This is the video ProjectEntity (not team project) id
  projectId?: string;
  folderId?: string;
  folderName?: string;
  teamProjectName?: string;
  accessLevel?: ShareFolderAccessType;
  waveformUrl?: string;
  scrubberThumbnailsUrl?: string;
  containerFormat: string;
  mediaType: MediaType.Video | MediaType.Audio;
  fileName: string;
  fileExtension: string;
  fileSizeBytes: number;
  mediaMetadata: MediaMetadata;
  videoStreamMetadata?: VideoStreamMetadata[];
  audioStreamMetadata?: AudioStreamMetadata[];
  ownerName?: string;
  ownerAddonEnabled: boolean;
};

export type ImageProp = {
  countsAgainstQuota: boolean;
  fileId: string;
  id: string;
  isDemo: boolean;
  name: string;
  nsId: number;
  ownerUid?: string;
  sharedLinkUrl?: string;
  sjId: number;
  thumbnailUrl: string;
  fullSizeUrl: string;
  uploadTimestamp?: string;
  versionNum: number;
  versionStatus?: number;
  videoId: string;
  videoIdForAmplitude: string;
  projectName: string;
  // This is the video ProjectEntity (not team project) id
  projectId?: string;
  folderId?: string;
  folderName?: string;
  teamProjectName?: string;
  accessLevel?: ShareFolderAccessType;
  mediaType: MediaType.Image | MediaType.CreativeDoc;
  scrubberThumbnailsUrl?: string;
  containerFormat: string;
  fileName: string;
  fileExtension: string;
  fileSizeBytes: number;
  ownerName?: string;
  mediaMetadata: MediaMetadata;
  imageMetadata: ImageMetadata;
  ownerAddonEnabled: boolean;
};

export type DocumentProp = {
  countsAgainstQuota: boolean;
  fileId: string;
  id: string;
  isDemo: boolean;
  isWatermarked: boolean;
  name: string;
  nsId: number;
  ownerUid?: string;
  sharedLinkUrl?: string;
  sjId: number;
  thumbnailUrl: string;
  pdfPreviewUrl: string;
  uploadTimestamp?: string;
  versionNum: number;
  versionStatus?: number;
  videoId: string;
  videoIdForAmplitude: string;
  projectName: string;
  // This is the video ProjectEntity (not team project) id
  projectId?: string;
  folderId?: string;
  folderName?: string;
  teamProjectName?: string;
  accessLevel?: ShareFolderAccessType;
  mediaType: MediaType.Document;
  scrubberThumbnailsUrl?: string;
  containerFormat: string;
  fileName: string;
  fileExtension: string;
  fileSizeBytes: number;
  ownerName?: string;
  mediaMetadata: MediaMetadata;
  documentMetadata: DocumentMetadata;
  ownerAddonEnabled: boolean;
};

export type MediaProp =
  | ({type: 'av'} & AVProp)
  | ({type: 'image'} & ImageProp)
  | ({type: 'document'} & DocumentProp);

export const mediaAsAv = (x: MediaProp) => unionAs(x, 'av');
export const mediaAsImage = (x: MediaProp) => unionAs(x, 'image');
export const mediaAsDocument = (x: MediaProp) => unionAs(x, 'document');

export type EditorProjectProp = {
  editorProjectId: string;
  editorProjectIdForAmplitude: string;
  name?: string;
  lastModifiedTime?: Date;
  creatorId?: string;
  edl?: media_edit.EditDecisionListTree;
  assets?: Array<media_edit.MediaAsset>;
};

export type SharedVideoProp = {
  video: MediaProp;
  showComments: boolean;
  downloadsEnabled: boolean;
  viewPreviousCommentsDisabled: boolean;
};

export type SharedEditorProjectProp = {
  editorProject: EditorProjectProp;
  showComments: boolean;
  downloadsEnabled: boolean;
};

export type DemoButtonProp = {
  projectId?: string;
  videoId?: string;
};

export type VersionSummariesIdArg =
  | {type: 'video_id'; videoId: string; shareToken?: string}
  | {type: 'video_token'; videoToken: string};

export type DownloadParameters = {
  link: string;
  extension: string;
};

export type UserContactInfo = {
  type: 'user';
  email: string;
  accountId?: string;
  displayName?: string;
  avatarPhotoUrl?: string;
  valid?: boolean;
};

export type GroupContactInfo = {
  type: 'group';
  groupId: string;
  groupName?: string;
  groupSize?: number;
  valid?: boolean;
};

export type TeamContactInfo = UserContactInfo | GroupContactInfo;

export type ShareFolderAccessType = reel.SharedFolderAccessLevel['.tag'];

export type TakeOwnershipType = 'take_ownership' | 'transfer_ownership';

export type AccessActionType = ShareFolderAccessType | TakeOwnershipType;

export type Task = {
  taskId: string;
  dueDate: Date;
};

// -------------------- Errors --------------------
export class UserOverQuotaError extends Error {
  constructor() {
    super('User over quota');
  }
}

export class ShareLinkNotAuthedError extends Error {}
export class ShareLinkDisabledError extends Error {}
export class ShareLinkLoggedOutAccessDisabledError extends Error {}

export class OneDriveTokenNotFoundError extends Error {}

export class TranscriptionMaxSizeExceededError extends Error {}

const maybeThrowTaggedError = (err: any) => {
  if (err instanceof DropboxResponseError) {
    const tag = typeof err.error === 'object' ? err.error.error['.tag'] : undefined;
    if (tag === 'usage_quota_exceeded') {
      throw new UserOverQuotaError();
    }
  }
};

// -------------------- init --------------------

export const getProvisionedFeatures = async (): Promise<FullProvisionsType> => {
  const response = await getDefaultUserClient().reelGetProvisionedFeatures({});

  const provisions: FullProvisionsType = defaultProvisions;
  const result = response.result;
  if (result.provision_tier) {
    provisions.provision_tier = result.provision_tier['.tag'];
  }
  if (result.features) {
    for (const feature of result.features) {
      if (feature.feature_type) {
        if (feature.state?.['.tag'] === 'quota') {
          if (feature.feature_type['.tag'] === 'file_count_limit') {
            const limit = {
              available: feature.state.available === undefined ? 0 : feature.state.available,
              used: feature.state.used === undefined ? 0 : feature.state.used,
              unlimited: !!feature.state.unlimited,
            };
            provisions.file_count_limit = limit;
          }
        } else if (feature.state?.['.tag'] === 'flag') {
          switch (feature.feature_type['.tag']) {
            case 'pin_comments':
            case 'live_review':
            case 'archive_to_dbx':
            case 'disable_share_links':
            case 'password_protected_links':
            case 'deadlines_and_tasks':
            case 'links_hub':
            case 'transcriptions':
            case 'other':
              provisions[feature.feature_type['.tag']] = !!feature.state.enabled;
              break;
          }
        }
      }
    }
  }
  return provisions;
};

export const getInitializationData = async (): Promise<reel.GetInitializationDataResult> => {
  const response = await getDefaultUserClient().reelGetInitializationData({});
  return response.result;
};

export interface UserMetadata {
  metadata: UserMetadataType;
  preferenceSettingMetadata: PreferenceSettingMetadataType;
}

export const getUserMetadata = async (): Promise<UserMetadata> => {
  const metadataQuery = Object.values(USER_METADATA_FIELD).filter((i): i is string => {
    return typeof i === 'string';
  });
  metadataQuery.push(...Object.values(PREFERENCE_SETTING_METADATA_FIELD));
  const response = await getDefaultUserClient().userMetadataUserMetadataGet({
    metadata: metadataQuery,
  });

  const metadata: UserMetadataType = {
    REPLAY_DISMISSED_QUOTA_BANNER: Number(
      JSON.parse(response.result.metadata['REPLAY_DISMISSED_QUOTA_BANNER']),
    ),
    REPLAY_SEEN_ADDON_WELCOME_MODAL: Boolean(
      JSON.parse(response.result.metadata['REPLAY_SEEN_ADDON_WELCOME_MODAL']),
    ),
    REPLAY_SEEN_BETA_EXPIRED_MODAL: Boolean(
      JSON.parse(response.result.metadata['REPLAY_SEEN_BETA_EXPIRED_MODAL']),
    ),
    REPLAY_SEEN_BETA_REMINDER_MODAL: Boolean(
      JSON.parse(response.result.metadata['REPLAY_SEEN_BETA_REMINDER_MODAL']),
    ),
    REPLAY_SEEN_BETA_WELCOME_MODAL: Boolean(
      JSON.parse(response.result.metadata['REPLAY_SEEN_BETA_WELCOME_MODAL']),
    ),
    REPLAY_SEEN_FREE_WELCOME_MODAL: Boolean(
      JSON.parse(response.result.metadata['REPLAY_SEEN_FREE_WELCOME_MODAL']),
    ),
    REPLAY_FIRST_LIVE_REVIEW_HOSTED: Boolean(
      JSON.parse(response.result.metadata['REPLAY_FIRST_LIVE_REVIEW_HOSTED']),
    ),
    REPLAY_DISMISSED_NEW_DRAWING_MENU_TOOLTIP: Boolean(
      JSON.parse(response.result.metadata['REPLAY_DISMISSED_NEW_DRAWING_MENU_TOOLTIP']),
    ),
    REPLAY_DISMISSED_ONBOARDING_CHECKLIST_COUNT: Number(
      JSON.parse(response.result.metadata['REPLAY_DISMISSED_ONBOARDING_CHECKLIST_COUNT']),
    ),
    REPLAY_EDITOR_PAGE_VOLUME_LEVEL: Number(
      JSON.parse(response.result.metadata['REPLAY_EDITOR_PAGE_VOLUME_LEVEL']),
    ),
    REPLAY_HAS_DISMISSED_PINNED_COMMENT_TOOLTIP: Boolean(
      JSON.parse(response.result.metadata['REPLAY_HAS_DISMISSED_PINNED_COMMENT_TOOLTIP']),
    ),
    REPLAY_SEEN_SETTINGS_HAS_MOVED_TOOLTIP: Boolean(
      JSON.parse(response.result.metadata['REPLAY_SEEN_SETTINGS_HAS_MOVED_TOOLTIP']),
    ),
    REPLAY_DISMISSED_COMMENTS_V2_TOOLTIP: Boolean(
      JSON.parse(response.result.metadata['REPLAY_DISMISSED_COMMENTS_V2_TOOLTIP']),
    ),
    REPLAY_SEGMENTATION_QUIZ_VIEW_STATE:
      response.result.metadata['REPLAY_SEGMENTATION_QUIZ_VIEW_STATE'],
    REPLAY_SEEN_ADMIN_MOVED_TOOLTIP: Boolean(
      JSON.parse(response.result.metadata['REPLAY_SEEN_ADMIN_MOVED_TOOLTIP']),
    ),
    REPLAY_DISMISSED_PRIVATE_COMMENTS_TOOLTIP: Boolean(
      JSON.parse(response.result.metadata['REPLAY_DISMISSED_PRIVATE_COMMENTS_TOOLTIP']),
    ),
  };
  const preferenceSettingMetadata: PreferenceSettingMetadataType = {
    REPLAY_ENABLE_NOTIFICATIONS_PREFERENCE:
      JSON.parse(response.result.metadata['REPLAY_ENABLE_NOTIFICATIONS_PREFERENCE']) !== false,
    REPLAY_PROJECT_MEMBERSHIP_UPDATE_NOTIFICATION_PREFERENCE:
      JSON.parse(
        response.result.metadata['REPLAY_PROJECT_MEMBERSHIP_UPDATE_NOTIFICATION_PREFERENCE'],
      ) ?? 'ON',
    REPLAY_COMMENT_NOTIFICATION_PREFERENCE:
      JSON.parse(response.result.metadata['REPLAY_COMMENT_NOTIFICATION_PREFERENCE']) ?? 'ON',
    REPLAY_MENTION_AND_THREAD_COMMENT_NOTIFICATION_PREFERENCE:
      JSON.parse(
        response.result.metadata['REPLAY_MENTION_AND_THREAD_COMMENT_NOTIFICATION_PREFERENCE'],
      ) ?? 'ON',
    REPLAY_STATUS_UPDATE_NOTIFICATION_PREFERENCE:
      JSON.parse(response.result.metadata['REPLAY_STATUS_UPDATE_NOTIFICATION_PREFERENCE']) ?? 'ON',
    REPLAY_CONTENT_UPDATE_NOTIFICATION_PREFERENCE:
      JSON.parse(response.result.metadata['REPLAY_CONTENT_UPDATE_NOTIFICATION_PREFERENCE']) ?? 'ON',
    REPLAY_SHARE_LINK_VIEW_NOTIFICATION_PREFERENCE:
      JSON.parse(response.result.metadata['REPLAY_SHARE_LINK_VIEW_NOTIFICATION_PREFERENCE']) ??
      'ON',
    REPLAY_SETTINGS_COMMENTS_SHOW_ICON_ON_HOVER:
      JSON.parse(response.result.metadata['REPLAY_SETTINGS_COMMENTS_SHOW_ICON_ON_HOVER']) ?? 'ON',
    REPLAY_SETTINGS_COMMENTS_PAUSE_WHEN_TYPING:
      JSON.parse(response.result.metadata['REPLAY_SETTINGS_COMMENTS_PAUSE_WHEN_TYPING']) ?? 'ON',
    REPLAY_SETTINGS_COMMENTS_QUICK_REACTIONS:
      JSON.parse(response.result.metadata['REPLAY_SETTINGS_COMMENTS_QUICK_REACTIONS']) ?? 'ON',
    REPLAY_SETTINGS_SHOW_SUGGESTED_FILES:
      JSON.parse(response.result.metadata['REPLAY_SETTINGS_SHOW_SUGGESTED_FILES']) ?? 'ON',
    REPLAY_SETTINGS_COMMAND_ENTER_SUBMISSION_PREFERENCE:
      JSON.parse(response.result.metadata['REPLAY_SETTINGS_COMMAND_ENTER_SUBMISSION_PREFERENCE']) ??
      'ON',
  };
  return {metadata, preferenceSettingMetadata};
};

// -------------------- Projects --------------------

export const deleteProject = async (
  projectId: string,
  isHide?: boolean,
): Promise<reel.DeleteProjectResult> => {
  const response = await getDefaultUserClient().reelDeleteProject({
    project_id: projectId,
    is_hide: isHide,
  });
  return response.result;
};

export type DeleteItemsResult = {
  folderIdToStatus: Record<string, GenericStatus>;
  projectIdToStatus: Record<string, GenericStatus>;
};

const deleteStatusTagToGenericStatus = (deleteStatusTag: reel.DeleteStatus) =>
  deleteStatusTag['.tag'] === 'deletion_success' ? GenericStatus.SUCCESS : GenericStatus.FAILED;

export const deleteItems = async (
  folderIds: string[],
  projectIds: string[],
  isHide?: boolean,
): Promise<DeleteItemsResult> => {
  const response = await getDefaultUserClient().reelDeleteItems({
    folder_ids: folderIds,
    project_ids: projectIds,
    is_hide: isHide,
  });

  const folderIdToStatus: Record<string, GenericStatus> = {};
  const projectIdToStatus: Record<string, GenericStatus> = {};

  if (!response.result.folder_id_to_status || !response.result.project_id_to_status) {
    throw new Error('API Error deleting items');
  }

  Object.entries(response.result.folder_id_to_status).forEach(([folderId, status]) => {
    folderIdToStatus[folderId] = deleteStatusTagToGenericStatus(status);
  });
  Object.entries(response.result.project_id_to_status).forEach(([projectId, status]) => {
    projectIdToStatus[projectId] = deleteStatusTagToGenericStatus(status);
  });

  return {folderIdToStatus, projectIdToStatus};
};

export const renameProject = async (
  projectId: string,
  projectName: string,
): Promise<reel.RenameProjectResult> => {
  const response = await getDefaultUserClient().reelRenameProject({
    project_id: projectId,
    project_name: projectName,
  });
  return response.result;
};

export const createProject = async ({
  id,
  name,
  parentFolderId,
  creationSource,
  excludeXmp,
}: {
  id: string;
  name: string;
  parentFolderId?: string;
  creationSource?: string;
  excludeXmp: boolean;
}): Promise<reel.CreateProjectResult> => {
  let projectCreationSource: reel.ProjectCreationSource | undefined;
  if (creationSource === 'dropbox_web') {
    projectCreationSource = {'.tag': 'dropbox_web'};
  } else if (creationSource === 'dropbox_previews_prompt') {
    projectCreationSource = {'.tag': 'dropbox_previews_prompt'};
  } else if (creationSource === 'dropbox_desktop') {
    projectCreationSource = {'.tag': 'dropbox_desktop'};
  } else if (creationSource === 'dropbox_previews_action_bar') {
    projectCreationSource = {'.tag': 'dropbox_previews_action_bar'};
  } else if (creationSource === 'dropbox_share_action') {
    projectCreationSource = {'.tag': 'dropbox_share_action'};
  } else if (creationSource === 'dropbox_previews_open_in_menu') {
    projectCreationSource = {'.tag': 'dropbox_previews_open_in_menu'};
  }

  const response = await getDefaultUserClient().reelCreateProject({
    title: name,
    file_source: {'.tag': 'file_id', file_id: id},
    parent_folder_id: parentFolderId,
    creation_source: projectCreationSource,
    exclude_xmp: excludeXmp,
  });
  return response.result;
};

export enum ProjectCreationStatus {
  Complete = 'complete',
  Error = 'error',
  Other = 'other',
}
type ProjectCreationResponse = {
  title: string;
  projectId: string;
  videoId: string;
  videoVersionId: string;
  status: ProjectCreationStatus;
  videoIdForAmplitude: string;
  versionIdForAmplitude: string;
  fileSize: number;
};
export type CreateProjectsResponse = Record<string, ProjectCreationResponse>;
export const createProjects = async ({
  files,
  parentFolderId,
  creationSource,
  fileIdToParentFolderId,
  excludeXmp,
}: {
  files: ChooserFile[];
  parentFolderId?: string;
  creationSource?: string;
  fileIdToParentFolderId?: Record<string, string>;
  excludeXmp: boolean;
}): Promise<CreateProjectsResponse> => {
  const createProjectsError = new Error('API Error creating projects');
  const fileIdToTitle: Record<string, string> = {};
  const fileIdToPath: Record<string, string> = {};
  const createProjectsResponse: CreateProjectsResponse = {};
  let projectCreationSource: reel.ProjectCreationSource | undefined;

  if (creationSource === 'dropbox_web') {
    projectCreationSource = {'.tag': 'dropbox_web'};
  } else if (creationSource === 'dropbox_previews_prompt') {
    projectCreationSource = {'.tag': 'dropbox_previews_prompt'};
  } else if (creationSource === 'dropbox_desktop') {
    projectCreationSource = {'.tag': 'dropbox_desktop'};
  }

  files.forEach(({id, name, relativePath}) => {
    fileIdToTitle[id] = name;
    fileIdToPath[id] = relativePath;
  });
  try {
    const response = await getDefaultUserClient().reelCreateProjectBatch({
      file_id_to_title: fileIdToTitle,
      parent_folder_id: parentFolderId,
      creation_source: projectCreationSource,
      file_id_to_parent_folder_id: fileIdToParentFolderId,
      exclude_xmp: excludeXmp,
    });

    if (!response || !response.result || !response.result.file_id_to_project_info) {
      throw createProjectsError;
    }

    Object.entries(response.result.file_id_to_project_info).forEach(([fileId, result]) => {
      let missingProperties = false;

      if (result.title == null || result.project_id == null || result.video_id == null) {
        missingProperties = true;
      }

      const statusTag = result.creation_status?.['.tag'];
      const status =
        missingProperties || statusTag === 'upload_failed'
          ? ProjectCreationStatus.Error
          : statusTag === 'upload_success'
          ? ProjectCreationStatus.Complete
          : ProjectCreationStatus.Other;

      createProjectsResponse[fileId] = {
        title: result.title ?? '',
        projectId: result.project_id ?? '',
        videoId: result.video_id ?? '',
        videoVersionId: result.video_version_id ?? '',
        videoIdForAmplitude: result.video_id_for_amplitude ?? '',
        versionIdForAmplitude: result.version_id_for_amplitude ?? '',
        fileSize: result.file_size_bytes ?? 0,
        status,
      };
    });
  } catch (e) {
    throw e.error?.error['.tag'] || createProjectsError;
  }

  return createProjectsResponse;
};

export const sendNewFilesAddedNotifications = async (
  parentFolderId: string,
  fileCount: number,
  firstFileInfo: reel.NewFileNotificationInfo,
  secondFileInfo?: reel.NewFileNotificationInfo,
) => {
  await getDefaultUserClient().reelSendFilesAddedNotification({
    parent_folder_id: parentFolderId,
    file_count: fileCount,
    first_file_info: firstFileInfo,
    second_file_info: secondFileInfo,
  });
};

// -------------------- Versions --------------------

export const createVersion = async ({
  videoId,
  newVersionFileId,
  namespaceCopy,
  onlyMaxResolution,
  fileSourceType,
}: {
  videoId: string;
  newVersionFileId: string;
  namespaceCopy: boolean;
  onlyMaxResolution?: boolean;
  fileSourceType: 'file_id' | 'file_path';
}): Promise<CreateVersionUiData> => {
  const fileSource: reel.new_version_file_source_union =
    fileSourceType === 'file_id'
      ? {'.tag': 'file_id', file_id: newVersionFileId}
      : {'.tag': 'file_path', file_path: newVersionFileId};

  let response: DropboxResponse<reel.CreateNewVideoVersionResult>;
  try {
    response = await getDefaultUserClient().reelCreateNewVideoVersion({
      video_id: videoId,
      new_version_file_source: fileSource,
      skip_copy_to_namespace: !namespaceCopy,
      only_max_resolution: onlyMaxResolution,
    });
  } catch (err) {
    maybeThrowTaggedError(err);
    // Retry excluding xmp data
    response = await getDefaultUserClient().reelCreateNewVideoVersion({
      video_id: videoId,
      new_version_file_source: fileSource,
      skip_copy_to_namespace: !namespaceCopy,
      only_max_resolution: onlyMaxResolution,
      exclude_xmp: true,
    });
  }

  if (!response.result.video_version_id || !response.result.version_num) {
    throw new Error('Invalid video version response from api');
  }

  return {
    videoVersionId: response.result.video_version_id,
    versionNum: response.result.version_num,
    video: response.result.video,
  };
};

export const updateVersionStatus = async ({
  grantBook,
  newStatus,
  projectId,
  shareToken,
  videoVersionId,
}: {
  grantBook?: string;
  newStatus: VersionStatus;
  projectId: string;
  shareToken?: string;
  videoVersionId: string;
}) => {
  const newStatusId = VERSION_STATUS_PROPERTIES[newStatus].id;
  const arg: reel.UpdateVersionStatusArgs = {
    new_status: newStatusId,
    video_version_id: videoVersionId,
  };
  if (shareToken) {
    arg.common = {
      grant_book: grantBook,
      share_token: shareToken,
    };
  }
  await getDefaultUserClient().reelUpdateVersionStatus(arg);
  queryClient.invalidateQueries({queryKey: replayApi.folderContentsDisplayInfo(projectId)});
};

type UpdateVersionStatusesResult = {
  versionIdToStatus: Record<string, GenericStatus>;
};

const updateVersionStatusStatusTagToGenericStatus = (
  updateStatusStatusTag: reel.UpdateVersionStatusStatus,
) =>
  updateStatusStatusTag['.tag'] === 'update_version_status_success'
    ? GenericStatus.SUCCESS
    : GenericStatus.FAILED;

export const updateVersionStatuses = async ({
  versionIds,
  newStatus,
  grantBook,
  shareToken,
}: {
  versionIds: string[];
  newStatus: VersionStatus;
  grantBook?: string;
  shareToken?: string;
}): Promise<UpdateVersionStatusesResult> => {
  const arg: reel.UpdateVersionStatusesArgs = {
    new_status: VERSION_STATUS_PROPERTIES[newStatus].id,
    video_version_ids: versionIds,
  };

  if (shareToken) {
    arg.common = {
      grant_book: grantBook,
      share_token: shareToken,
    };
  }
  const response = await getDefaultUserClient().reelUpdateVersionStatuses(arg);

  const versionIdToStatus: Record<string, GenericStatus> = {};

  if (!response.result.version_id_to_status) {
    throw new Error('API Error updating statuses');
  }

  await queryClient.invalidateQueries({
    queryKey: replayApi.allFolderContentsDisplayInfo(),
  });

  Object.entries(response.result.version_id_to_status).forEach(([versionId, status]) => {
    versionIdToStatus[versionId] = updateVersionStatusStatusTagToGenericStatus(status);
  });

  return {versionIdToStatus};
};

export const getVersionSummaries = async (
  videoIdentifier: VersionSummariesIdArg,
  grantBook?: string,
): Promise<reel.VersionSummary[]> => {
  if (videoIdentifier.type === 'video_id' && !videoIdentifier.videoId) {
    throw new Error('videoId is empty in call to ListVersionSummaries');
  }

  const apiArg: reel.ListVersionSummariesForVideoArgs =
    videoIdentifier.type === 'video_token'
      ? {
          video_identifier: {
            '.tag': 'share_token' as const,
            share_token: videoIdentifier.videoToken,
          },
          common: {
            grant_book: grantBook,
          },
        }
      : {
          video_identifier: {
            '.tag': 'video_id' as const,
            video_id: videoIdentifier.videoId,
          },
          common: {
            grant_book: grantBook,
            share_token: videoIdentifier.shareToken,
          },
        };

  const response = await getDefaultUserClient().reelListVersionSummariesForVideo(apiArg);

  if (!response.result.version_summaries) {
    throw new Error('Bad ListVersionSummariesForVideoResult API response');
  }

  return response.result.version_summaries;
};

// -------------------- Videos --------------------
export const mediaTypeTagToMediaType = (mediaTypeTag: reel.ReelMediaType) => {
  const tag = mediaTypeTag['.tag'];

  switch (tag) {
    case 'image':
      return MediaType.Image;
    case 'creative_doc':
      return MediaType.CreativeDoc;
    case 'document':
      return MediaType.Document;
    case 'audio':
      return MediaType.Audio;
    default:
      return MediaType.Video;
  }
};

export function fileResponseToVideoUiProp(file?: reel.Video): MediaProp {
  if (!file) {
    throw new Error('Invalid file response from api: no file returned');
  }
  const mediaType = file.media_type?.['.tag'];

  switch (mediaType) {
    case 'audio':
    case 'video':
      return {type: 'av', ...avResponseToVideoUiProp(file)};
    case 'document':
      return {type: 'document', ...documentResponseToVideoUiProp(file)};
    default:
      return {type: 'image', ...imageResponseToVideoUiProp(file)};
  }
}

export function extractVideoMetadata(videoMetadata: reel.VideoMetadata): {
  mediaMetadata: MediaMetadata;
  videoStreamMetadata: VideoStreamMetadata[];
  audioStreamMetadata: AudioStreamMetadata[];
} {
  const mediaMetadata: MediaMetadata = {
    aspectRatio: videoMetadata.aspect_ratio,
    audioChanLayout: videoMetadata.audio_chan_layout,
    audioFreq: videoMetadata.audio_freq,
    bitRate: videoMetadata.bit_rate,
    captureDate: videoMetadata.capture_date ? new Date(videoMetadata.capture_date) : undefined,
    codec: videoMetadata.codec,
    colorSpace: videoMetadata.color_space,
    device: videoMetadata.device,
    duration: videoMetadata.duration ?? 0.0,
    durationPrecise: videoMetadata.duration_precise ?? 0.0,
    frameRate: videoMetadata.frame_rate_precise ?? 0.0,
    resolutionHeight: videoMetadata.resolution_height ?? 0,
    resolutionWidth: videoMetadata.resolution_width ?? 0,
    timecodeSecondsOffset: videoMetadata.timecode
      ? getSecondsFromTimecode(videoMetadata.timecode, videoMetadata.frame_rate_precise!)
      : 0,
    creatorTool: videoMetadata.creator_tool,
  };

  const videoStreamMetadata: VideoStreamMetadata[] = [];
  const audioStreamMetadata: AudioStreamMetadata[] = [];

  videoMetadata.stream_metadata?.forEach((metadata) => {
    if (metadata.codec_type == 'audio') {
      audioStreamMetadata.push({
        streamIndex: metadata.stream_index,
        codecType: metadata.codec_type,
        bitrateBps: metadata.bitrate_bps,
        codecLongName: metadata.codec_long_name,
        codecName: metadata.codec_name,
        codecTagString: metadata.codec_tag_string,
        creationTime: metadata.creation_time,
        device: metadata.device,
        duration: metadata.duration_s,
        isDefault: metadata.is_default,
        channelLayout: metadata.channel_layout,
        channels: metadata.channels,
        sampleRate: metadata.sample_rate_s,
      });
    } else if (metadata.codec_type == 'video') {
      videoStreamMetadata.push({
        streamIndex: metadata.stream_index,
        codecType: metadata.codec_type,
        bitrateBps: metadata.bitrate_bps,
        codecLongName: metadata.codec_long_name,
        codecName: metadata.codec_name,
        codecTagString: metadata.codec_tag_string,
        creationTime: metadata.creation_time,
        device: metadata.device,
        duration: metadata.duration_s,
        isDefault: metadata.is_default,
        colorSpace: metadata.color_space,
        displayAspectRatio: metadata.display_aspect_ratio,
        framesPerSecond: metadata.frames_per_second,
        height: metadata.height,
        rotation: metadata.rotation,
        width: metadata.width,
      });
    }
  });

  return {mediaMetadata, videoStreamMetadata, audioStreamMetadata};
}

export function avResponseToVideoUiProp(av: reel.Video): AVProp {
  if (!av.file_id) {
    throw new Error('Invalid video response from api: missing file_id');
  }
  if (!av.ns_id) {
    throw new Error('Invalid video response from api: missing ns_id');
  }
  if (!av.sj_id) {
    throw new Error('Invalid video response from api: missing sj_id');
  }
  if (!av.transcode_url) {
    throw new Error('Invalid video response from api: missing transcode_url');
  }
  if (!av.video_metadata) {
    throw new Error('Invalid video response from api: missing video_metadata');
  }
  if (!av.video_metadata.frame_rate_precise) {
    throw new Error('Invalid video response from api: missing frame_rate_precise');
  }
  if (av.video_metadata.duration == null) {
    throw new Error('Invalid video response from api: missing duration');
  }
  if (!av.version_num) {
    throw new Error('Invalid video response from api: missing version_num');
  }
  if (!av.video_id) {
    throw new Error('Invalid video response from api: missing video_id');
  }

  const {mediaMetadata, videoStreamMetadata, audioStreamMetadata} = extractVideoMetadata(
    av.video_metadata,
  );

  return {
    captionsTracks: av.captions_tracks ?? [],
    countsAgainstQuota: av.counts_against_quota ?? false,
    fileId: av.file_id,
    id: av.id,
    isDemo: av.is_demo || false,
    isWatermarked: av.is_watermarked ?? false,
    name: av.name || '',
    nsId: av.ns_id,
    ownerUid: av.owner_uid,
    sharedLinkUrl: av.shared_link_url,
    sjId: av.sj_id,
    posterUrl: av.poster_url,
    transcodeUrl: av.transcode_url,
    uploadTimestamp: av.upload_timestamp,
    versionNum: av.version_num,
    versionStatus: av.status,
    videoId: av.video_id,
    videoIdForAmplitude: av.video_id_for_amplitude ?? '',
    projectName: av.project_name || '',
    folderId: av.folder_id,
    folderName: av.folder_name,
    teamProjectName: av.top_level_project_name,
    accessLevel: av.access_level?.['.tag'],
    mediaType: av.media_type?.['.tag'] === 'audio' ? MediaType.Audio : MediaType.Video,
    waveformUrl: av.waveform_url,
    scrubberThumbnailsUrl: av.thumb_scrubber_vtt_url,
    containerFormat: av.video_metadata.container_format ?? '',
    mediaMetadata: mediaMetadata,
    fileName: av.file_name ?? '',
    fileExtension: av.file_extension ?? '',
    fileSizeBytes: av.file_size_bytes ?? 0,
    videoStreamMetadata: videoStreamMetadata,
    audioStreamMetadata: audioStreamMetadata,
    ownerName: av.owner_name,
    projectId: av.project_id,
    ownerAddonEnabled: !!av.owner_addon_enabled,
  };
}

export function extractImageMetadata(metadata?: reel.VideoMetadata): {
  mediaMetadata: MediaMetadata;
  imageMetadata: ImageMetadata;
} {
  const streamMetadata = metadata?.stream_metadata?.[0];

  const mediaMetadata: MediaMetadata = {
    frameRate: 0,
    duration: 0,
    durationPrecise: 0.0,
    resolutionHeight: streamMetadata?.height ?? 0,
    resolutionWidth: streamMetadata?.width ?? 0,
  };

  const imageMetadata: ImageMetadata = {
    apertureValue: metadata?.aperture_value,
    cameraMake: metadata?.camera_make,
    cameraModel: metadata?.camera_model,
    caption: metadata?.caption,
    category: metadata?.category,
    colorSpace: metadata?.color_space,
    copyright: metadata?.copyright,
    creator: metadata?.creator,
    credit: metadata?.credit,
    credits: metadata?.credits,
    description: metadata?.description,
    exposureTime: metadata?.exposure_time,
    flash: metadata?.flash,
    focalLength: metadata?.focal_length,
    headline: metadata?.headline,
    isoSpeed: metadata?.iso_speed,
    keywords: metadata?.keywords,
    lensModel: metadata?.lens_model,
    licensorUrl: metadata?.licensor_url,
    meteringMode: metadata?.metering_mode,
    orientation: metadata?.orientation,
    resolutionUnit: metadata?.resolution_unit,
    rightsUsageTerms: metadata?.rights_usage_terms,
    title: metadata?.title,
    userComments: metadata?.user_comments,
    whiteBalance: metadata?.white_balance,
    xResolution: metadata?.x_resolution,
    yResolution: metadata?.y_resolution,
    imageDateTimeOriginal: metadata?.image_date_time_original,
    imageCreateDate: metadata?.image_create_date,
  };

  return {mediaMetadata, imageMetadata};
}

export function imageResponseToVideoUiProp(image: reel.Video): ImageProp {
  if (!image.file_id) {
    throw new Error('Invalid image response from api: missing file_id');
  }
  if (!image.ns_id) {
    throw new Error('Invalid image response from api: missing ns_id');
  }
  if (!image.sj_id) {
    throw new Error('Invalid image response from api: missing sj_id');
  }
  if (!image.version_num) {
    throw new Error('Invalid image response from api: missing version_num');
  }
  if (!image.video_id) {
    throw new Error('Invalid image response from api: missing video_id');
  }
  if (!image.thumbnail_url) {
    throw new Error('Invalid image response from api: missing thumbnail_url');
  }
  if (!image.poster_url) {
    throw new Error('Invalid image response from api: missing poster_url');
  }

  const {mediaMetadata, imageMetadata} = extractImageMetadata(image.video_metadata);

  return {
    countsAgainstQuota: image.counts_against_quota ?? false,
    fileId: image.file_id,
    id: image.id,
    isDemo: image.is_demo || false,
    name: image.name || '',
    nsId: image.ns_id,
    ownerName: image.owner_name,
    ownerUid: image.owner_uid,
    sharedLinkUrl: image.shared_link_url,
    sjId: image.sj_id,
    thumbnailUrl: image.thumbnail_url,
    fullSizeUrl: image.poster_url,
    uploadTimestamp: image.upload_timestamp,
    versionNum: image.version_num,
    versionStatus: image.status,
    videoId: image.video_id,
    videoIdForAmplitude: image.video_id_for_amplitude ?? '',
    projectName: image.project_name || '',
    folderId: image.folder_id,
    folderName: image.folder_name,
    teamProjectName: image.top_level_project_name,
    accessLevel: image.access_level?.['.tag'],
    containerFormat: '',
    mediaType:
      image.media_type?.['.tag'] === 'creative_doc' ? MediaType.CreativeDoc : MediaType.Image,
    fileName: image.file_name ?? '',
    fileExtension: image.file_extension ?? '',
    fileSizeBytes: image.file_size_bytes ?? 0,
    scrubberThumbnailsUrl: image.thumb_scrubber_vtt_url,
    mediaMetadata: mediaMetadata,
    imageMetadata: imageMetadata,
    projectId: image.project_id,
    ownerAddonEnabled: !!image.owner_addon_enabled,
  };
}

export function documentResponseToVideoUiProp(document: reel.Video): DocumentProp {
  if (!document.file_id) {
    throw new Error('Invalid document response from api: missing file_id');
  }
  if (!document.ns_id) {
    throw new Error('Invalid document response from api: missing ns_id');
  }
  if (!document.sj_id) {
    throw new Error('Invalid document response from api: missing sj_id');
  }
  if (!document.version_num) {
    throw new Error('Invalid document response from api: missing version_num');
  }
  if (!document.video_id) {
    throw new Error('Invalid document response from api: missing video_id');
  }
  if (!document.thumbnail_url) {
    throw new Error('Invalid document response from api: missing thumbnail_url');
  }
  if (!document.pdf_preview_url) {
    throw new Error('Invalid document response from api: missing pdf_preview_url');
  }

  const metadata = document.video_metadata;
  const streamMetadata = metadata?.stream_metadata?.[0];

  const mediaMetadata: MediaMetadata = {
    frameRate: 0,
    duration: 0,
    durationPrecise: 0.0,
    resolutionHeight: streamMetadata?.height ?? 0,
    resolutionWidth: streamMetadata?.width ?? 0,
  };

  const documentMetadata: DocumentMetadata = {
    pages: document.video_metadata?.pages ?? 0,
    height: document.video_metadata?.height ?? 0,
    width: document.video_metadata?.width ?? 0,
  };

  return {
    countsAgainstQuota: document.counts_against_quota ?? false,
    fileId: document.file_id,
    id: document.id,
    isWatermarked: document.is_watermarked ?? false,
    isDemo: document.is_demo ?? false,
    name: document.name || '',
    nsId: document.ns_id,
    ownerName: document.owner_name,
    ownerUid: document.owner_uid,
    sharedLinkUrl: document.shared_link_url,
    sjId: document.sj_id,
    thumbnailUrl: document.thumbnail_url,
    pdfPreviewUrl: document.pdf_preview_url,
    uploadTimestamp: document.upload_timestamp,
    versionNum: document.version_num,
    versionStatus: document.status,
    videoId: document.video_id,
    videoIdForAmplitude: document.video_id_for_amplitude ?? '',
    projectName: document.project_name || '',
    folderId: document.folder_id,
    folderName: document.folder_name,
    teamProjectName: document.top_level_project_name,
    accessLevel: document.access_level?.['.tag'],
    containerFormat: '',
    mediaType: MediaType.Document,
    mediaMetadata: mediaMetadata,
    documentMetadata: documentMetadata,
    fileName: document.file_name ?? '',
    fileExtension: document.file_extension ?? '',
    fileSizeBytes: document.file_size_bytes ?? 0,
    scrubberThumbnailsUrl: document.thumb_scrubber_vtt_url,
    projectId: document.project_id,
    ownerAddonEnabled: !!document.owner_addon_enabled,
  };
}

export const getVideo = async (
  videoId: string,
  versionId?: string,
  onlyMaxResolution = false,
): Promise<MediaProp> => {
  const videoResponse = await getDefaultUserClient().reelGetVideo({
    video_id: videoId,
    video_version_id: versionId,
    only_max_resolution: onlyMaxResolution,
  });

  return fileResponseToVideoUiProp(videoResponse.result.video);
};

type LatestMediaMetadata = {
  name?: string;
  sizeBytes?: number;
  mediaType?: MediaType;
  mediaMetadata?: MediaMetadata;
  videoStreamMetadata?: VideoStreamMetadata[];
  audioStreamMetadata?: AudioStreamMetadata[];
  imageMetadata?: ImageMetadata;
};

export type FolderEntityMetadata = {
  type: 'folder';
  name: string;
  numFiles: number;
  size: number;
};

export type MediaEntityMetadata = {
  type: 'media';
  name: string;
  totalVersions: number;
  size: number;
  latestMetadata: LatestMediaMetadata;
};

export type AggregateEntityMetadata = {
  totalVersions: number;
  totalSize: number;
};

export type EntityMetadataResult = FolderEntityMetadata | MediaEntityMetadata;
export type EntityMetadataType = reel.GetEntityMetadataType['.tag'];

export const getEntityMetadata = async (
  teamProjectFolderIdOrMediaId: string,
  entityType: EntityMetadataType,
): Promise<EntityMetadataResult> => {
  const {result} = await getDefaultUserClient().reelGetEntityMetadata({
    folder_or_media_id: teamProjectFolderIdOrMediaId,
    entity_type: {'.tag': entityType},
  });

  const metadata = result.metadata;
  if (!metadata) throw new Error('Metadata is missing from response');

  switch (metadata['.tag']) {
    case 'team_project_metadata':
      return {
        type: 'folder',
        name: metadata.name ?? '',
        numFiles: metadata.number_of_files ?? 0,
        size: metadata.size ?? 0,
      };

    case 'media_project_metadata':
      if (!metadata.metadata) throw new Error('Media metadata is missing');

      const {media_type, core_metadata} = metadata.metadata;
      if (!media_type || !core_metadata) throw new Error('Required media metadata is missing');

      const mediaTypeTag = media_type?.['.tag'];
      const latestMetadata: LatestMediaMetadata = {
        name: metadata.metadata.name,
        sizeBytes: metadata.metadata.size_bytes,
        mediaType: mediaTypeTag ? convertReelMediaTypeToMediaType(media_type) : undefined,
      };

      if (mediaTypeTag === 'video') {
        Object.assign(latestMetadata, extractVideoMetadata(core_metadata));
      } else if (mediaTypeTag === 'image') {
        Object.assign(latestMetadata, extractImageMetadata(core_metadata));
      }

      return {
        type: 'media',
        name: metadata.name ?? '',
        totalVersions: metadata.total_versions ?? 0,
        size: metadata.size ?? 0,
        latestMetadata,
      };

    default:
      throw new Error('Invalid entityType tag passed into getEntityMetadata');
  }
};

export type ShareLinksThumbnailData = {
  video_version_id?: string;
  video_id?: string;
};

export const getListSharedLinksThumbnails = async (
  thumbnail_requests: ShareLinksThumbnailData[] = [],
) => {
  try {
    const response = await getDefaultUserClient().reelListSharedLinksThumbnails({
      thumbnail_requests,
    });
    return response;
  } catch (e) {
    reportFailedDataFetch(e, 'ListSharedLinksThumbnails');
    throw e;
  }
};

export const getListSharedLinks = async () => {
  try {
    const response = await getDefaultUserClient().reelListSharedLinks({});
    if (!response.result || !response.result.shared_links) {
      throw new Error('Bad ListSharedLinks API response: shared links missing');
    }
    return response;
  } catch (e) {
    reportFailedDataFetch(e, 'ListSharedLinks');
    throw e;
  }
};

export const getVideoFromShortLink = async ({
  grantBook,
  onlyMaxResolution = false,
  shareToken,
  videoId,
  videoVersionId,
}: {
  grantBook?: string;
  onlyMaxResolution?: boolean;
  shareToken: string;
  videoId?: string;
  videoVersionId?: string;
}): Promise<SharedVideoProp> => {
  try {
    const response = await getDefaultUserClient().reelGetWithSharedLink({
      entity_id: videoId,
      entity_type: {'.tag': 'shared_video'},
      share_token: shareToken,
      video_version_id: videoVersionId,
      only_max_resolution: onlyMaxResolution,
      common: {
        grant_book: grantBook,
        replay_session_id: replayStorage.getReplaySessionID() ?? undefined,
      },
    });

    const {
      show_comments: showComments,
      downloads_enabled: downloadsEnabled,
      view_previous_versions_disabled: viewPreviousCommentsDisabled,
      shared_entity,
    } = response.result;
    if (!shared_entity || shared_entity['.tag'] !== 'shared_video') {
      throw Error('Shared folders are not yet supported by this function');
    }
    const video = shared_entity as reel.Video;

    return {
      video: fileResponseToVideoUiProp(video),
      showComments: showComments ?? false,
      downloadsEnabled: !!downloadsEnabled,
      viewPreviousCommentsDisabled: !!viewPreviousCommentsDisabled,
    };
  } catch (e) {
    if (e instanceof DropboxResponseError && e.status === 403) {
      throw new ShareLinkNotAuthedError();
    } else if (e instanceof DropboxResponseError && e.status === 409) {
      if (e.error?.error['.tag'] === 'login_required')
        throw new ShareLinkLoggedOutAccessDisabledError();
      else throw new ShareLinkDisabledError();
    } else {
      throw e;
    }
  }
};

export const getViewerCount = async (videoId: string) => {
  const viewerCount = await getDefaultUserClient().reelGetViewCount({video_id: videoId});
  if (!viewerCount.result) {
    throw new Error('Bad GetViewCount API response: viewer counts missing');
  }
  return {
    teamMembers: viewerCount.result.team_members!,
    guests: viewerCount.result.guests!,
  };
};

export const logView = async (videoId: string) => {
  await getDefaultUserClient().reelLogAction({
    video_id: videoId,
    action: {'.tag': 'view'},
  });
};

export const logHoverForVideo = async (videoId: string) => {
  await getDefaultUserClient().reelLogAction({
    video_id: videoId,
    action: {'.tag': 'thumbnail_hover'},
  });
};

export const logHoverForFolder = async (folderId: string) => {
  await getDefaultUserClient().reelLogFolderAction({
    folder_id: folderId,
    action: {'.tag': 'thumbnail_hover'},
  });
};

export const addCaptionsTrack = async ({
  fileId,
  videoVersionId,
  skipCopyToNamespace,
}: {
  fileId: string;
  videoVersionId: string;
  skipCopyToNamespace?: boolean;
}): Promise<reel.CaptionsTrack> => {
  const result = await getDefaultUserClient().reelAddCaptionsTrack({
    file_description: {
      file_id: fileId,
      skip_copy_to_namespace: skipCopyToNamespace,
    },
    video_version_id: videoVersionId,
  });

  if (result.result.captions_track) {
    return result.result.captions_track;
  }

  throw new Error('Bad AddCaptionsTrack response: no captions track returned');
};

export const deleteCaptionsTrack = async ({
  captionsTrackId,
  videoVersionId,
}: {
  videoVersionId: string;
  captionsTrackId: string;
}) => {
  await getDefaultUserClient().reelDeleteCaptionsTrack({
    video_version_id: videoVersionId,
    captions_track_id: captionsTrackId,
  });
};

// -------------------- Links --------------------

export type LinkItemTypes = 'file' | 'folder';

export const addLiveReviewQueryParam = (url: URL): URL => {
  const search = new URLSearchParams(url.search);
  search.append('live', '1');
  url.search = search.toString();
  return url;
};

/**
 * Rewrite the URL so that it is scoped to the current window's origin, this
 * allows "replay.dropbox.com" links to be rewritten to "localhost" when
 * developing (and similarly with amplify previews).
 */
export const swapLinkOrigin = (url: URL) => {
  url.host = window.location.host;
  url.port = window.location.port;
  url.protocol = window.location.protocol;
};

export const getReelLink = async (
  linkItemId: string,
  viewOnly: boolean,
  live: boolean = false,
  linkItemType: LinkItemTypes,
) => {
  let result = '';

  if (linkItemType === 'file') {
    const response = await getDefaultUserClient().reelCreateReelVideoSharedLink({
      video_id: linkItemId,
      show_comments: !viewOnly,
      set_download_setting: false,
    });

    if (!response.result.reel_video_shared_link) {
      const e = new Error('Bad CreateReelVideoSharedLinkResult API response: link is missing');
      reportFailedDataFetch(e, 'GetReelLink');
      throw e;
    }

    result = response.result.reel_video_shared_link;
  } else {
    const response = await getDefaultUserClient().reelShareFolder({
      folder_id: linkItemId,
      is_review_link: !viewOnly,
      set_download_setting: false,
    });

    if (!response.result.share_link) {
      const e = new Error('Bad ShareFolderResult API response: link is missing');
      reportFailedDataFetch(e, 'GetReelLink');
      throw e;
    }

    result = response.result.share_link;
  }

  let url = new URL(result);

  // Create a live review shared link if requested.
  if (live && !viewOnly) {
    url = addLiveReviewQueryParam(url);
  }

  swapLinkOrigin(url);
  return url.toString();
};

export const sendShareLinkEmail = async (
  id: string,
  isFolder: boolean,
  isViewOnly: boolean,
  recipientEmails: string[],
) => {
  const entityType = isFolder
    ? ({'.tag': 'shared_folder'} as const)
    : ({'.tag': 'shared_video'} as const);

  // Because we save the link settings when they are changed in the Share Modal Settings,
  // we do not need to set the settings again here.
  const sharedLinkSettings = {
    show_comments: !isViewOnly,
    set_download_setting: false,
    set_password_setting: false,
  };

  await getDefaultUserClient().reelSendShareLinkEmail({
    video_version_id: '', // TODO: Deprecate this in the backend
    id: id,
    entity_type: entityType,
    recipient_emails: recipientEmails,
    share_link_settings: sharedLinkSettings,
  });
};

export const createShareLink = async ({
  linkItemId,
  linkItemType,
  viewOnly,
  downloadsDisabled,
  password,
  passwordEnabled,
  loggedOutAccessDisabled,
  viewPreviousCommentsDisabled,
}: {
  linkItemId: string;
  linkItemType: LinkItemTypes;
  viewOnly: boolean;
  downloadsDisabled: boolean;
  password: string;
  passwordEnabled: boolean;
  loggedOutAccessDisabled: boolean;
  viewPreviousCommentsDisabled: boolean;
}) => {
  let result = '';

  // Only change the password if there is a new value or if the password was disabled
  const setPassword = !passwordEnabled || (passwordEnabled && password.length > 0);

  if (linkItemType == 'file') {
    const response = await getDefaultUserClient().reelCreateReelVideoSharedLink({
      set_password: setPassword,
      password: password,
      show_comments: !viewOnly,
      video_id: linkItemId,
      disable_downloads: downloadsDisabled,
      set_download_setting: true,
      set_logged_out_access_setting: true,
      disable_logged_out_access: loggedOutAccessDisabled,
      view_previous_versions_disabled: viewPreviousCommentsDisabled,
      set_view_previous_versions_disabled: true,
    });

    if (!response.result.reel_video_shared_link) {
      const e = new Error('Bad CreateReelVideoSharedLinkResult API response: link is missing');
      reportFailedDataFetch(e, 'CreateFileShareLink');
      throw e;
    }

    result = response.result.reel_video_shared_link;
  } else {
    const response = await getDefaultUserClient().reelShareFolder({
      set_password: setPassword,
      password: password,
      is_review_link: !viewOnly,
      folder_id: linkItemId,
      disable_downloads: downloadsDisabled,
      set_download_setting: true,
    });

    if (!response.result.share_link) {
      const e = new Error('Bad ShareFolderResult API response: link is missing');
      reportFailedDataFetch(e, 'CreateFolderShareLink');
      throw e;
    }

    result = response.result.share_link;
  }

  const url = new URL(result);
  swapLinkOrigin(url);
  return url.toString();
};

type BasicLinkSettings = {
  downloadsDisabled?: boolean;
  password: string;
  passwordEnabled: boolean;
  loggedOutAccessDisabled: boolean;
  setDisableLink: boolean;
  disableLink: boolean;
  viewPreviousCommentsDisabled: boolean;
};

type WatermarkLinkSettings = {
  showIP: boolean;
  showViewDate: boolean;
  showViewTime: boolean;
  customText: string;
  position: WatermarkPositionsType;
  theme: WatermarkThemeType;
  textOpacity: number;
  textSize: number;
};

export const updateFileLinkSettings = async ({
  shareToken,
  basicSettings,
  watermarkSettings,
}: {
  shareToken: string;
  basicSettings: BasicLinkSettings;
  watermarkSettings?: WatermarkLinkSettings;
}) => {
  const basicUpdateMask = ['share_link_settings.logged_out_access_disabled'];
  const watermarkUpdateMask = [
    'watermark_settings.show_ip_address',
    'watermark_settings.show_view_date',
    'watermark_settings.show_view_time',
    'watermark_settings.text_opacity',
    'watermark_settings.font_color',
    'watermark_settings.font_size',
    'watermark_settings.position',
    'watermark_settings.custom_text',
  ];
  const updateMask = watermarkSettings
    ? basicUpdateMask.concat(watermarkUpdateMask)
    : basicUpdateMask;

  // Only change the password if there is a new value or if the password was disabled
  const setPassword =
    !basicSettings.passwordEnabled ||
    (basicSettings.passwordEnabled && basicSettings.password && basicSettings.password.length > 0);

  if (setPassword) {
    updateMask.push('share_link_settings.password');
  }

  // Only update the downloads setting if it's been provided (watermarked links
  // won't update this setting)
  if (basicSettings.downloadsDisabled !== undefined) {
    basicUpdateMask.push('share_link_settings.disable_downloads');
  }

  let expiryTime = undefined;
  if (basicSettings.setDisableLink) {
    expiryTime = basicSettings.disableLink ? new Date().toISOString() : undefined;
    updateMask.push('share_link_settings.expiry_timestamp');
  }

  if (basicSettings.viewPreviousCommentsDisabled !== undefined) {
    updateMask.push('share_link_settings.view_previous_versions_disabled');
  }

  const response = await getDefaultUserClient().reelUpdateSharedLinkV2({
    share_token: shareToken,
    update_mask: {
      paths: updateMask,
    },
    share_link_settings: {
      disable_downloads: basicSettings.downloadsDisabled,
      password: basicSettings.password,
      logged_out_access_disabled: basicSettings.loggedOutAccessDisabled,
      expiry_timestamp: expiryTime,
      view_previous_versions_disabled: basicSettings.viewPreviousCommentsDisabled,
    },
    watermark_settings: {
      show_ip_address: watermarkSettings?.showIP,
      show_view_date: watermarkSettings?.showViewDate,
      show_view_time: watermarkSettings?.showViewTime,
      custom_text: watermarkSettings?.customText,
      opacity: watermarkSettings?.textOpacity,
      font_color: convertWatermarkThemeToApi(watermarkSettings?.theme),
      font_size: watermarkSettings?.textSize,
      position: convertWatermarkPositionToApi(watermarkSettings?.position),
    },
  });

  const link_info = response.result.link_info?.length ? response.result.link_info[0] : undefined;

  if (!link_info || !link_info.share_link_info?.link) {
    const e = new Error('Bad UpdateSharedLinkV2 API response: link is missing');
    reportFailedDataFetch(e, 'UpdateSharedLinks');
    throw e;
  }

  const url = new URL(link_info.share_link_info.link);
  swapLinkOrigin(url);
  return url.toString();
};

export const updateFolderLinkSettings = async ({
  shareToken,
  downloadsDisabled,
  password,
  passwordEnabled,
  loggedOutAccessDisabled,
}: {
  shareToken: string;
  downloadsDisabled: boolean;
  password: string;
  passwordEnabled: boolean;
  loggedOutAccessDisabled: boolean;
}) => {
  // Only change the password if there is a new value or if the password was disabled
  const setPassword = !passwordEnabled || (passwordEnabled && password.length > 0);

  const response = await getDefaultUserClient().reelUpdateSharedLink({
    share_token: shareToken,
    set_password: setPassword,
    password: password,
    disable_downloads: downloadsDisabled,
    set_download_setting: true,
    set_logged_out_access_setting: true,
    disable_logged_out_access: loggedOutAccessDisabled,
  });

  if (!response.result.shared_link) {
    const e = new Error('Bad CreateReelVideoSharedLinkResult API response: link is missing');
    reportFailedDataFetch(e, 'UpdateSharedLinks');
    throw e;
  }

  if (!response.result.shared_link) {
    const e = new Error('Bad ShareFolderResult API response: link is missing');
    reportFailedDataFetch(e, 'UpdateSharedLinks');
    throw e;
  }

  const result = response.result.shared_link;

  const url = new URL(result);
  swapLinkOrigin(url);
  return url.toString();
};

export const updateShareLinkExpiry = async (
  shareToken: string,
  expiryTimestamp?: Date,
): Promise<boolean> => {
  try {
    const result = await getDefaultUserClient().reelUpdateSharedLinkV2({
      share_token: shareToken,
      update_mask: {paths: ['share_link_settings.expiry_timestamp']},
      share_link_settings: {
        expiry_timestamp: expiryTimestamp ? expiryTimestamp.toISOString() : undefined,
      },
    });
    const link_info = result.result.link_info?.length ? result.result.link_info[0] : undefined;
    if (!link_info || !link_info.share_link_info?.link) {
      throw new Error('Bad UpdateSharedLink API response: shared link is missing');
    }
    return !!link_info.share_link_info?.link;
  } catch (err) {
    reportFailedDataFetch(err, 'UpdateSharedLinks');
    return false;
  }
};

export const updateFolderLinkExpiry = async (
  shareToken: string,
  expiryTimestamp?: Date,
): Promise<boolean> => {
  try {
    const result = await getDefaultUserClient().reelUpdateSharedLink({
      share_token: shareToken,
      expiry_timestamp: expiryTimestamp ? expiryTimestamp.toISOString() : undefined,
      set_expiry: true,
    });

    if (!result.result.shared_link) {
      throw new Error('Bad UpdateSharedLink API response: shared link is missing');
    }
    return !!result.result.shared_link;
  } catch (err) {
    reportFailedDataFetch(err, 'UpdateSharedLinks');
    return false;
  }
};

export const getExistingLinks = async (
  videoId: string,
  linkItemType: LinkItemTypes,
): Promise<LinkInfoResult> => {
  let entityType: reel.SharedEntityType | undefined;
  if (linkItemType === 'file') {
    entityType = {'.tag': 'shared_video'};
  } else if (linkItemType === 'folder') {
    entityType = {'.tag': 'shared_folder'};
  }

  const response = await getDefaultUserClient().reelGetSharedLinks({
    video_id: videoId,
    entity_type: entityType,
  });
  const sharingIsRestrictedByTeamPolicy =
    response.result.sharing_is_restricted_by_team_policy ?? false;

  if (!response.result.links && !response.result.watermark_links) {
    return {
      basicLinks: [],
      watermarkLinks: [],
      sharingIsRestrictedByTeamPolicy: sharingIsRestrictedByTeamPolicy,
    };
  }

  const basicLinks = response.result.links
    ? response.result.links.map(
        ({
          view_only,
          has_password,
          link,
          downloads_disabled,
          expiry_timestamp,
          logged_out_access_disabled,
          view_previous_versions_disabled,
        }) => {
          if (
            has_password === undefined ||
            link === undefined ||
            view_only === undefined ||
            downloads_disabled === undefined
          ) {
            throw new Error('Server returned invalid shared links');
          }
          const parsed_expiry = expiry_timestamp ? new Date(expiry_timestamp) : undefined;
          return {
            hasPassword: has_password,
            link,
            shareToken: getShareTokenFromLink(link),
            viewOnly: view_only,
            downloadsDisabled: downloads_disabled,
            expiryTimestamp: parsed_expiry,
            expired: parsed_expiry ? new Date() >= parsed_expiry : false,
            loggedOutAccessDisabled: logged_out_access_disabled,
            viewPreviousCommentsDisabled: view_previous_versions_disabled,
          } as LinkInfo;
        },
      )
    : [];

  const watermarkLinks = response.result.watermark_links
    ? response.result.watermark_links.map(({share_link_info, video_watermark_info}) => {
        if (
          share_link_info === undefined ||
          video_watermark_info === undefined ||
          share_link_info.link === undefined
        ) {
          throw new Error('Server returned invalid shared links');
        }
        const parsed_expiry = share_link_info.expiry_timestamp
          ? new Date(share_link_info.expiry_timestamp)
          : undefined;
        const shareInfo = {
          hasPassword: share_link_info.has_password,
          link: share_link_info.link,
          shareToken: getShareTokenFromLink(share_link_info.link),
          viewOnly: share_link_info.view_only,
          downloadsDisabled: share_link_info.downloads_disabled,
          expiryTimestamp: parsed_expiry,
          expired: parsed_expiry ? new Date() >= parsed_expiry : false,
          loggedOutAccessDisabled: share_link_info.logged_out_access_disabled,
          viewPreviousCommentsDisabled: share_link_info.view_previous_versions_disabled,
        } as LinkInfo;
        const watermarkInfo = {
          email: video_watermark_info.email,
          showEmail: video_watermark_info.show_email,
          showIp: video_watermark_info.show_ip_address,
          showViewDate: video_watermark_info.show_view_date,
          showViewTime: video_watermark_info.show_view_time,
          recipientUser: {
            displayName: video_watermark_info.recipient_user?.display_name || '',
            accountPhotoUrl: video_watermark_info.recipient_user?.account_photo_url || '',
            initials: video_watermark_info.recipient_user?.initials || '',
          },
          customText: video_watermark_info.custom_text,
          position: convertApiWatermarkPosition(video_watermark_info.position),
          theme: convertApiWatermarkTheme(video_watermark_info.font_color),
          textOpacity: video_watermark_info.opacity,
          textSize: video_watermark_info.font_size,
        } as VideoWatermarkInfo;
        return {
          shareInfo: shareInfo,
          watermarkInfo: watermarkInfo,
        };
      })
    : [];

  return {
    basicLinks: basicLinks,
    watermarkLinks: watermarkLinks,
    sharingIsRestrictedByTeamPolicy: sharingIsRestrictedByTeamPolicy,
  };
};

const convertWatermarkThemeToApi = (theme?: WatermarkThemeType) => {
  // We use the dark and light theme background colors as the watermark color
  switch (theme) {
    case 'dark':
      return WATERMARK_DARK_THEME;
    case 'light':
      return WATERMARK_LIGHT_THEME;
    default:
      return WATERMARK_DARK_THEME;
  }
};

const convertApiWatermarkTheme = (theme?: string) => {
  // We use the dark and light theme background colors as the watermark color
  switch (theme) {
    case WATERMARK_DARK_THEME:
      return 'dark';
    case WATERMARK_LIGHT_THEME:
      return 'light';
    default:
      return 'dark';
  }
};

const convertWatermarkPositionToApi = (position?: WatermarkPositionsType) => {
  switch (position) {
    case 'center':
      return {'.tag': 'center'} as reel.WatermarkPositionCenter;
    case 'top':
      return {'.tag': 'top'} as reel.WatermarkPositionTop;
    case 'bottom':
      return {'.tag': 'bottom'} as reel.WatermarkPositionBottom;
    case 'top-left':
      return {'.tag': 'top_left'} as reel.WatermarkPositionTopLeft;
    case 'top-right':
      return {'.tag': 'top_right'} as reel.WatermarkPositionTopRight;
    case 'bottom-left':
      return {'.tag': 'bottom_left'} as reel.WatermarkPositionBottomLeft;
    case 'bottom-right':
      return {'.tag': 'bottom_right'} as reel.WatermarkPositionBottomRight;
    default:
      return {'.tag': 'center'} as reel.WatermarkPositionCenter;
  }
};

const convertApiWatermarkPosition = (position?: reel.WatermarkPosition) => {
  switch (position?.['.tag']) {
    case 'center':
      return 'center';
    case 'top':
      return 'top';
    case 'bottom':
      return 'bottom';
    case 'top_left':
      return 'top-left';
    case 'top_right':
      return 'top-right';
    case 'bottom_left':
      return 'bottom-left';
    case 'bottom_right':
      return 'bottom-right';
    default:
      return 'center';
  }
};

export const createWatermarkLinks = async (
  videoVersionId: string,
  loggedOutAccessDisabled: boolean,
  viewPreviousCommentsDisabled: boolean,
  password: string,
  expiryTimestamp: Date | undefined,
  recipientEmails: string[],
  showIP: boolean,
  showViewDate: boolean,
  showViewTime: boolean,
  customText: string,
  position: WatermarkPositionsType,
  theme: WatermarkThemeType,
  textOpacity: number,
  textSize: number,
) => {
  const response = await getDefaultUserClient().reelCreateWatermarkLinksBatch({
    video_version_id: videoVersionId,
    media_type: {'.tag': 'video'},
    share_link_settings: {
      show_comments: true,
      logged_out_access_disabled: loggedOutAccessDisabled,
      password: password,
      expiry_timestamp: expiryTimestamp ? expiryTimestamp.toISOString() : undefined,
      view_previous_versions_disabled: viewPreviousCommentsDisabled,
    },
    video_watermark: {
      show_email: !!recipientEmails.length,
      emails: recipientEmails,
      show_ip_address: showIP,
      show_view_date: showViewDate,
      show_view_time: showViewTime,
      opacity: textOpacity,
      position: convertWatermarkPositionToApi(position),
      font_size: textSize,
      font_color: convertWatermarkThemeToApi(theme),
      custom_text: customText,
    },
  });

  if (!response.result.links?.length) {
    const e = new Error('Bad CreateWatermarkLinksBatch API response: Links are missing');
    reportFailedDataFetch(e, 'CreateWatermarkLinksBatch');
    throw e;
  }

  const links = response.result.links;
  links.map((link) => {
    const url = new URL(link.share_link_info?.link ?? '');
    swapLinkOrigin(url);
    return {
      share_link_info: {...link.share_link_info, link: url.toString()},
      video_watermark_info: link.video_watermark_info,
    };
  });
  return links;
};

export const getGrantBookFromPassword = async (
  pw: string,
  shareToken: string,
): Promise<string | undefined> => {
  const response = await getDefaultUserClient().reelAuthWithPassword({
    password: pw,
    share_token: shareToken,
    common: {
      share_token: shareToken,
      replay_session_id: replayStorage.getReplaySessionID() ?? undefined,
    },
  });

  // If the auth attempt fails, the client should always throw, so we can
  // return string | undefined here
  return response.result.grant_book;
};

// ------------ User preference setting ---------------
export const setUserPreferenceSettingMetadata = async (
  metadataChanges: Partial<PreferenceSettingMetadataType>,
) => {
  const metadataArg: {[key: string]: string} = {};
  Object.keys(metadataChanges).forEach((key) => {
    metadataArg[key] = JSON.stringify(metadataChanges[key as keyof PreferenceSettingMetadataType]);
  });
  await getDefaultUserClient().userMetadataUserMetadataSet({
    metadata: metadataArg,
  });
};

export const getUserSpaceUsage = async () => {
  return await getDefaultUserClient().usersGetSpaceUsage();
};

// -------------------- Onboarding --------------------
export const listOnboardingActions = async () => {
  const onboardingActionsResponse = await getDefaultUserClient().reelListUserOnboardingActions({});
  if (!onboardingActionsResponse.result.actions) {
    throw new Error('Bad listOnboardingActions API response: onboarding actions are missing');
  }
  return onboardingActionsResponse.result.actions;
};

export const setUserOnboardingActions = async (onboardingActions: reel.OnboardingActions) => {
  await getDefaultUserClient().reelSetUserOnboardingActions({
    actions: onboardingActions,
  });
};

export const createDemoProject = async (): Promise<DemoButtonProp> => {
  const createDemoProjectResponse = await getDefaultUserClient().reelCreateDemoProject();

  const {project_id, video_id} = createDemoProjectResponse.result;
  return {
    projectId: project_id,
    videoId: video_id,
  };
};

export const resetOnboarding = async () => {
  await getDefaultUserClient().reelResetOnboarding({});
};

// ------------------- Publish -------------------
export const getUserPublishActions = async () => {
  const publishActionsResponse = await getDefaultUserClient().reelGetUserPublishActions({});
  if (!publishActionsResponse.result.publish_actions) {
    throw new Error('Bad getUserPublishActions API response: user Publish actions are missing');
  }
  return publishActionsResponse.result.publish_actions;
};

export const setUserPublishActions = async (publishActions: reel.PublishActions) => {
  await getDefaultUserClient().reelSetUserPublishActions({
    publish_actions: publishActions,
  });
};

// ------------------- Downloads -------------------

export const getVideoDownloadLink = async ({
  videoId,
  videoVersionId,
  shareToken,
  grantBook,
  analyticsData,
}: {
  videoId: string;
  videoVersionId: string;
  shareToken?: string;
  grantBook?: string;
  analyticsData: {
    logger: LoggingClient;
    papAccessType?: AccessType;
    projectId?: string;
    creatorId?: string;
  };
}): Promise<DownloadParameters> => {
  const papProps: Download_File['properties'] = {
    deviceId: analyticsData.logger.deviceId,
    accessType: analyticsData.papAccessType,
    wopiSessionId: analyticsData.logger.sessionId,
    videoId: videoId,
    videoVersionId: videoVersionId,
    creatorId: analyticsData.creatorId,
    projectId: analyticsData.projectId,
  };

  analyticsData.logger.logPap(
    PAP_Download_File({
      ...papProps,
      eventState: 'start',
    }),
  );
  try {
    const response = await getDefaultUserClient().reelGetTemporaryVideoDownloadLink({
      common: {
        grant_book: grantBook,
        replay_session_id: replayStorage.getReplaySessionID() ?? undefined,
      },
      share_token: shareToken,
      video_id: videoId,
      video_version_id: videoVersionId,
    });

    if (!response.result.link) {
      throw new Error('Failed to get video download link');
    }
    const filename = response.result.filename ? response.result.filename : '';
    const ext = filename.split('.').pop() as FileType | undefined;
    analyticsData.logger.logPap(
      PAP_Download_File({
        ...papProps,
        fileType: ext,
        eventState: 'success',
      }),
    );
    return {
      link: response.result.link,
      extension: ext ? ext : '',
    };
  } catch (e) {
    analyticsData.logger.logPap(
      PAP_Download_File({
        ...papProps,
        eventState: 'failed',
      }),
    );
    throw e;
  }
};

export const getZipVideoDownloadLink = async ({
  assetIds,
  downloadOption,
  analyticsData,
}: {
  assetIds: string[];
  downloadOption: reel.DownloadOption;
  analyticsData: {
    logger: LoggingClient;
    papAccessType?: AccessType;
  };
}) => {
  const papProps: Download_File['properties'] = {
    deviceId: analyticsData.logger.deviceId,
    accessType: analyticsData.papAccessType,
    wopiSessionId: analyticsData.logger.sessionId,
    fileType: 'zip',
  };

  analyticsData.logger.logPap(
    PAP_Download_File({
      ...papProps,
      eventState: 'start',
    }),
  );

  try {
    const response = await getDefaultUserClient().reelGetTemporaryVideoDownloadLink({
      multiple_video_download_args: {
        asset_ids: assetIds,
        download_option: downloadOption,
      },
    });
    if (!response.result.link) {
      throw new Error('Failed to get video download link');
    }
    analyticsData.logger.logPap(
      PAP_Download_File({
        ...papProps,
        eventState: 'success',
      }),
    );
    return {
      link: response.result.link,
    };
  } catch (e) {
    let failureType: 'size_limit' | 'file_limit' | 'no_files' | 'other' = 'other';
    if (instanceOfTooManyFiles(e)) {
      failureType = 'file_limit';
    } else if (instanceOfTooMuchData(e)) {
      failureType = 'size_limit';
    } else if (instanceOfNoFilesFound(e)) {
      failureType = 'no_files';
    }
    analyticsData.logger.logPap(
      PAP_Download_File({
        ...papProps,
        eventState: 'failed',
        failureType: failureType,
      }),
    );
    throw e;
  }
};

export const getFolderDownloadLink = async ({
  folderId,
  downloadOption,
  shareToken,
  grantBook,
  analyticsData,
}: {
  folderId: string;
  downloadOption: reel.DownloadOption;
  shareToken?: string;
  grantBook?: string;
  analyticsData: {
    logger: LoggingClient;
    papAccessType?: AccessType;
  };
}) => {
  analyticsData.logger.logPap(
    PAP_Download_File({
      deviceId: analyticsData.logger.deviceId,
      accessType: analyticsData.papAccessType,
      fileExtension: 'zip',
      eventState: 'start',
      wopiSessionId: analyticsData.logger.sessionId,
    }),
  );

  try {
    const response = await getDefaultUserClient().reelDownloadFolder({
      folder_ids: [folderId],
      download_option: downloadOption,
      common: {
        share_token: shareToken,
        grant_book: grantBook,
      },
    });
    if (!response.result.url) {
      throw new Error('Failed to get folder download link');
    }
    analyticsData.logger.logPap(
      PAP_Download_File({
        deviceId: analyticsData.logger.deviceId,
        accessType: analyticsData.papAccessType,
        fileExtension: 'zip',
        eventState: 'success',
        wopiSessionId: analyticsData.logger.sessionId,
      }),
    );
    return {
      url: response.result.url,
    };
  } catch (e) {
    let failureType: 'size_limit' | 'file_limit' | 'no_files' | 'other' = 'other';
    if (instanceOfTooManyFiles(e)) {
      failureType = 'file_limit';
    } else if (instanceOfTooMuchData(e)) {
      failureType = 'size_limit';
    } else if (instanceOfNoFilesFound(e)) {
      failureType = 'no_files';
    }
    analyticsData.logger.logPap(
      PAP_Download_File({
        deviceId: analyticsData.logger.deviceId,
        accessType: analyticsData.papAccessType,
        fileExtension: 'zip',
        eventState: 'failed',
        wopiSessionId: analyticsData.logger.sessionId,
        failureType: failureType,
      }),
    );
    throw e;
  }
};

export function instanceOfFolderNotFound(
  error: Object,
): error is reel.DownloadFolderErrorFolderNotFound {
  return Object.values(error).includes('folder_not_found');
}

export function instanceOfTooManyFiles(
  error: Object,
): error is reel.DownloadFolderErrorTooManyFiles &
  reel.GetTemporaryVideoDownloadLinkErrorTooManyFiles {
  return Object.values(error).includes('too_many_files');
}

export function instanceOfTooMuchData(
  error: Object,
): error is reel.DownloadFolderErrorTooMuchData &
  reel.GetTemporaryVideoDownloadLinkErrorTooMuchData {
  return Object.values(error).includes('too_much_data');
}

export function instanceOfNoFilesFound(
  error: Object,
): error is
  | reel.DownloadFolderErrorNoFilesFound
  | reel.GetTemporaryVideoDownloadLinkErrorFileNotFound {
  const values = Object.values(error);
  return values.includes('no_files_found') || values.includes('no_file_found');
}

// ------------------- Uploads -------------------

type EmptyProjectResponse = {
  nsId: number;
  projectId: string;
};
export const createEmptyProject = async (
  title: string,
  folderId: string,
): Promise<EmptyProjectResponse> => {
  const createEmptyProjectError = new Error('API Error creating empty project');
  let emptyProjectResponse: EmptyProjectResponse;

  try {
    const response = await getDefaultUserClient().reelCreateEmptyProject({
      title,
      parent_folder_id: folderId,
    });

    if (!response.result || !response.result.project_id || !response.result.ns_id) {
      throw createEmptyProjectError;
    }

    emptyProjectResponse = {
      nsId: response.result.ns_id,
      projectId: response.result.project_id,
    };
  } catch {
    throw createEmptyProjectError;
  }

  return emptyProjectResponse;
};

export type AddToProjectResponse = {
  videoId: string;
  videoVersionId: string;
  videoIdForAmplitude: string;
  versionIdForAmplitude: string;
  fileSize: number;
};
export const addToProject = async (
  projectId: string,
  fileId: string,
  excludeXmp: boolean,
): Promise<AddToProjectResponse> => {
  let addToProjectResponse;
  const addToProjectError = new Error('API Error creating empty project');

  try {
    const response = await getDefaultUserClient().reelAddToProject({
      project_id: projectId,
      file_id: fileId,
      exclude_xmp: excludeXmp,
    });

    if (!response.result) {
      throw addToProjectError;
    }

    addToProjectResponse = {
      videoId: response.result.video_id ?? '',
      videoVersionId: response.result.video_version_id ?? '',
      videoIdForAmplitude: response.result.video_id_for_amplitude ?? '',
      versionIdForAmplitude: response.result.version_id_for_amplitude ?? '',
      fileSize: response.result.file_size_bytes ?? 0,
    };
  } catch (err) {
    maybeThrowTaggedError(err);
    throw addToProjectError;
  }

  return addToProjectResponse;
};

export enum EmptyProjectCreationStatus {
  Complete = 'complete',
  Error = 'error',
  Other = 'other',
}
type EmptyProjectCreationResult = {
  title?: string;
  projectId?: string;
  nsId?: number;
  status: EmptyProjectCreationStatus;
};
export type EmptyProjectsResponse = Record<string, EmptyProjectCreationResult>;
export const createEmptyProjects = async (
  fileIdToTitle: Record<string, string>,
  fileIdToParentFolderId: Record<string, string> = {},
  folderId: string,
): Promise<EmptyProjectsResponse> => {
  const createEmptyProjectsError = new Error('API Error creating empty projects');
  const emptyProjectsResponse: EmptyProjectsResponse = {};
  try {
    const response = await getDefaultUserClient().reelCreateEmptyProjectBatch({
      file_id_to_title: fileIdToTitle,
      parent_folder_id: folderId,
      file_id_to_parent_folder_id: fileIdToParentFolderId,
    });

    if (!response.result || !response.result.file_id_to_empty_project_info) {
      throw createEmptyProjectsError;
    }

    Object.entries(response.result.file_id_to_empty_project_info).forEach(([uploadId, result]) => {
      let missingProperties = false;
      if (!result.title || !result.project_id || !result.ns_id) {
        missingProperties = true;
      }

      const statusTag = result.creation_status?.['.tag'];
      const status =
        missingProperties || statusTag === 'creation_failed'
          ? EmptyProjectCreationStatus.Error
          : statusTag === 'creation_success'
          ? EmptyProjectCreationStatus.Complete
          : EmptyProjectCreationStatus.Other;

      emptyProjectsResponse[uploadId] = {
        title: result.title,
        projectId: result.project_id,
        nsId: result.ns_id,
        status,
      };
    });
  } catch (e) {
    throw e.error?.error['.tag'] || createEmptyProjectsError;
  }

  return emptyProjectsResponse;
};

// ------------------- Folders -------------------

export const createFolder = async (
  folderName: string,
  parentFolderId: string,
  description?: string,
): Promise<reel.CreateFolderResult> => {
  const response = await getDefaultUserClient().reelCreateFolder({
    name: folderName,
    parent_folder_id: parentFolderId,
    description,
  });
  return response.result;
};

export const createFoldersFromPaths = async (
  parentFolderId: string,
  fileIdToPaths: Record<string, string>,
): Promise<reel.CreateFolderBatchFromPathsResult> => {
  const response = await getDefaultUserClient().reelCreateFolderBatchFromPaths({
    parent_folder_id: parentFolderId,
    file_id_to_path: fileIdToPaths,
  });
  return response.result;
};

export const deleteAccount = async (
  email: string,
  password: string,
): Promise<account.DeleteAccountResult> => {
  const response = await getDefaultUserClient().accountDeleteAccount({
    email,
    password,
    reason: {
      '.tag': 'other_reason',
    },
  });
  return response.result;
};

export type InitialVideo = Pick<
  reel.Video,
  | 'id'
  | 'is_demo'
  | 'name'
  | 'ns_id'
  | 'owner_uid'
  | 'project_id'
  | 'should_display_new_badge'
  | 'status'
  | 'upload_timestamp'
  | 'version_num'
  | 'version_summaries'
  | 'video_id'
  | 'viewer_count'
  | 'is_only_basic_content'
  | 'owner_addon_enabled'
>;

export type InitialProjectWithVideos = {
  project?: reel.Project;
  videos?: Array<InitialVideo>;
};

export type Folder = {isUploading?: false} & reel.Folder;

export type InitialFolder = Folder;

type InitialListFolderType = {
  projects_with_videos?: Array<InitialProjectWithVideos>;
  folders?: Array<InitialFolder>;
} & Omit<reel.ListFolderResult, 'projects_with_videos' | 'folders'>;

export const initialListFolder = async (
  folderId?: string,
  hideOwnedContent?: boolean,
  hideSharedContent?: boolean,
): Promise<InitialListFolderType> => {
  const response = await getDefaultUserClient().reelListFolderBasicInfo({
    folder_id: folderId,
    hide_owned_content: hideOwnedContent,
    hide_shared_content: hideSharedContent,
  });
  return response.result;
};

export const FOLDER_PAGE_LIMIT = 24;
const FOLDER_CONTENT_DISPLAY_INFO_BATCH_SIZE = 24;

export const uiSortToApiSort = (sortAttr: SortAttr): reel.SortOption => {
  switch (sortAttr) {
    case 'name': {
      return {'.tag': 'name'};
    }
    case 'updated': {
      return {'.tag': 'last_modified_time'};
    }
    case 'created': {
      return {'.tag': 'creation_time'};
    }
    default: {
      return {'.tag': 'name'};
    }
  }
};

export const listFolderBasicInfoContinue = async (cursor: reel.ListFolderCursorWrapper) => {
  const response = await getDefaultUserClient().reelListFolderBasicInfoContinue({
    cursor,
  });
  return response.result;
};

type InitialListFolderArg = {
  folderId?: string;
  limit?: number;
  sortAttr: reel.SortOption;
  sortDesc: boolean;
  isSharedView: boolean;
};

export const listFolderBasicInfoV2 = async (
  arg: InitialListFolderArg,
): Promise<reel.ListFolderBasicInfoResult> => {
  const {folderId, isSharedView, limit, sortAttr, sortDesc} = arg;
  const response = await getDefaultUserClient().reelListFolderBasicInfo({
    folder_id: folderId,
    limit: limit || FOLDER_PAGE_LIMIT,
    hide_owned_content: isSharedView,
    hide_shared_content: !isSharedView,
    sort_attr: sortAttr,
    sort_desc: sortDesc,
  });
  return response.result;
};

const getFolderIdentifier = (pid: string) => ({
  public_id: {
    '.tag': 'folder_id' as const,
    folder_id: pid,
  },
});

const getMediaProjectIdentifier = (pid: string) => ({
  public_id: {
    '.tag': 'media_project_id' as const,
    media_project_id: pid,
  },
});

export const folderNsId = async (folderId: string) => {
  const response = await getDefaultUserClient().reelFolderNamespace({folder_id: folderId});

  return response.result.ns_id;
};

export const isFolderDisplayInfo = (
  result?: reel.result_union,
): result is reel.result_unionFolderDisplayInfo => {
  return result?.['.tag'] === 'folder_display_info';
};

export const isMediaProjectDisplayInfo = (
  result?: reel.result_union,
): result is reel.result_unionMediaProjectDisplayInfo => {
  return result?.['.tag'] === 'media_project_display_info';
};

export type MediaProjectOrFolderDisplayInfoResult =
  | reel.result_unionFolderDisplayInfo
  | reel.result_unionMediaProjectDisplayInfo;

export const getDisplayInfoForFolderContents = async (
  public_ids: string[],
): Promise<reel.GetDisplayInfoForFolderContentsResult> => {
  const result = await getDefaultUserClient().reelGetDisplayInfoForFolderContents({
    folder_content_identifiers: public_ids.map((pid) =>
      pid.startsWith('pid_rf') ? getFolderIdentifier(pid) : getMediaProjectIdentifier(pid),
    ),
  });

  return result.result;
};

const ACCOUNT_BATCH_LIMIT = 20;

export const AccountInfoBatcher = create({
  fetcher: async (accountIds: string[]) => {
    const response = await getDefaultUserClient().usersGetAccountBatch({
      account_ids: accountIds,
    });
    return response.result;
  },
  resolver: (result, query) => {
    return result.find((accountInfo) => accountInfo.account_id === query) as users.BasicAccount;
  },
  scheduler: windowedFiniteBatchScheduler({
    windowMs: 10,
    maxBatchSize: ACCOUNT_BATCH_LIMIT,
  }),
});

export const DisplayInfoBatcher = create({
  fetcher: getDisplayInfoForFolderContents,
  resolver: (result, query) => {
    const listOfResults = result.folder_contents_display_info ?? [];
    const queryResult = listOfResults.find((fcdi) => {
      const publicId =
        fcdi.folder_content_identifier?.public_id?.['.tag'] === 'media_project_id'
          ? fcdi.folder_content_identifier.public_id.media_project_id
          : fcdi.folder_content_identifier?.public_id?.['.tag'] === 'folder_id'
          ? fcdi.folder_content_identifier.public_id.folder_id
          : null;

      return publicId === query;
    });

    if (!queryResult) {
      return undefined;
    }

    if (
      !queryResult.result ||
      queryResult.result['.tag'] === 'err' ||
      queryResult.result['.tag'] === 'other'
    ) {
      throw new Error('API returned bad folder content display info');
    }

    return queryResult.result;
  },
  scheduler: windowedFiniteBatchScheduler({
    windowMs: 10,
    maxBatchSize: FOLDER_CONTENT_DISPLAY_INFO_BATCH_SIZE,
  }),
});

// Defaults to root if no folderId is passed in
export const listFolder = async (
  folderId?: string,
  hideOwnedContent?: boolean,
  hideSharedContent?: boolean,
): Promise<reel.ListFolderResult> => {
  const response = await getDefaultUserClient().reelListFolder({
    folder_id: folderId,
    hide_owned_content: hideOwnedContent,
    hide_shared_content: hideSharedContent,
    only_basic_content: false,
  });
  return response.result;
};

export const listAllFoldersForTeam = async (): Promise<reel.ListAllFoldersForTeamResult> => {
  const response = await getDefaultUserClient().reelListAllFoldersForTeam({});
  return response.result;
};

export const usersGetAccountBatch = async (
  accountIds: string[],
): Promise<users.GetAccountBatchResult> => {
  const response = await getDefaultUserClient().usersGetAccountBatch({account_ids: accountIds});
  return response.result;
};

export const listUserRootMediaProjects = async (
  target_account_id: string,
): Promise<reel.ListUserRootMediaProjectsResult> => {
  const response = await getDefaultUserClient().reelListUserRootMediaProjects({
    target_account_id,
  });
  return response.result;
};

export type FolderProp = {
  accessLevel: reel.SharedFolderAccessLevel;
  currentProjectIdForAmplitude?: string;
  cursor: reel.ListFolderCursorWrapper | Error | null;
  folderId: string;
  folderIdForAmplitude?: string;
  folderMembers: Array<reel.ShareRecipientInfo>;
  folderNameIdChainFromRoot: Array<reel.Folder>;
  folders: Array<reel.Folder | InitialFolder>;
  isOnlyBasicContent: boolean;
  isSharedView: boolean;
  projectsWithVideos: Array<reel.ProjectWithVideos | InitialProjectWithVideos>;
};

export const folderResponseToFolderProp = ({
  listFolderResult,
  isSharedView,
  cursor,
}: {
  listFolderResult: reel.ListFolderResult;
  isSharedView: boolean;
  cursor: null | reel.ListFolderCursorWrapper;
}): FolderProp => {
  if (
    !listFolderResult.access_level ||
    !listFolderResult.folder_name_id_chain_from_root ||
    !listFolderResult.folder_name_id_chain_from_root.length ||
    !listFolderResult.folders ||
    !listFolderResult.projects_with_videos
  ) {
    const err = new Error('Bad ListFolder response: required properties missing');
    reportFailedDataFetch(err, 'ListFolder');
    throw err;
  }

  // If one folder or video includes only basic content, they all do
  const isOnlyBasicContent =
    listFolderResult.folders.some((folder) => folder.is_only_basic_content) ||
    listFolderResult.projects_with_videos.some(
      (project) => project.videos && project.videos.some((video) => video.is_only_basic_content),
    );

  const folderChain = listFolderResult.folder_name_id_chain_from_root;
  const currentFolder = folderChain[folderChain.length - 1];
  const currentFolderId = currentFolder.id;

  return {
    accessLevel: listFolderResult.access_level,
    currentProjectIdForAmplitude: listFolderResult.current_project_id_for_amplitude,
    cursor,
    folderId: currentFolderId,
    folderIdForAmplitude: listFolderResult.folder_id_for_amplitude,
    folderMembers: listFolderResult.folder_members ?? [],
    folderNameIdChainFromRoot: listFolderResult.folder_name_id_chain_from_root,
    folders: listFolderResult.folders,
    isOnlyBasicContent,
    isSharedView,
    projectsWithVideos: listFolderResult.projects_with_videos,
  };
};

export type ShareFolderProp = {
  showComments: boolean;
  downloadsEnabled: boolean;
  folder: FolderProp;
};

const isSharedFolderEntity = (
  entityType?: reel.SharedEntityType,
  entity?: any,
): entity is reel.SharedEntityTypeSharedFolder => {
  const entityTag = entityType && entityType['.tag'];
  return entityTag === 'shared_folder';
};

export const sharedFolderResponseToFolderProp = (
  result: reel.GetWithSharedLinkResult,
): ShareFolderProp => {
  if (
    !isSharedFolderEntity(result.entity_type, result.shared_entity) ||
    !result.shared_entity ||
    !result.shared_entity.access_level ||
    !result.shared_entity.folder_members ||
    !result.shared_entity.folder_name_id_chain_from_root ||
    !result.shared_entity.folders ||
    !result.shared_entity.projects_with_videos
  ) {
    const err = new Error('Bad GetWithShareLink response for folder: required properties missing');
    reportFailedDataFetch(err, 'GetWithSharedLink');
    throw err;
  }

  // If one folder or video includes only basic content, they all do
  const isOnlyBasicContent =
    result.shared_entity.folders.some((folder) => folder.is_only_basic_content) ||
    result.shared_entity.projects_with_videos.some(
      (project) => project.videos && project.videos.some((video) => video.is_only_basic_content),
    );

  const folderChain = result.shared_entity.folder_name_id_chain_from_root;
  const currentFolder = folderChain[folderChain.length - 1];
  const currentFolderId = currentFolder.id;

  return {
    showComments: Boolean(result.show_comments),
    downloadsEnabled: Boolean(result.downloads_enabled),
    folder: {
      accessLevel: result.shared_entity.access_level,
      currentProjectIdForAmplitude: result.shared_entity.current_project_id_for_amplitude,
      cursor: null,
      folderId: currentFolderId,
      folderIdForAmplitude: result.shared_entity.folder_id_for_amplitude,
      folderMembers: result.shared_entity.folder_members,
      folderNameIdChainFromRoot: result.shared_entity.folder_name_id_chain_from_root,
      folders: result.shared_entity.folders,
      isOnlyBasicContent,
      isSharedView: false,
      projectsWithVideos: result.shared_entity.projects_with_videos,
    },
  };
};

export const getFolderWithShareLink = async ({
  folderId,
  grantBook,
  shareToken,
}: {
  folderId?: string;
  grantBook?: string;
  shareToken: string;
}): Promise<ShareFolderProp> => {
  const sharedEntityType: reel.SharedEntityType = {
    '.tag': 'shared_folder',
  };

  try {
    const {result} = await getDefaultUserClient().reelGetWithSharedLink({
      common: {
        grant_book: grantBook,
        share_token: shareToken,
      },
      entity_id: folderId,
      entity_type: sharedEntityType,
      share_token: shareToken,
    });

    return sharedFolderResponseToFolderProp(result);
  } catch (e) {
    if (e instanceof DropboxResponseError && e.status === 403) {
      throw new ShareLinkNotAuthedError();
    } else {
      throw e;
    }
  }
};

export const getFolderTree = async (): Promise<reel.GetFolderTreeResult> => {
  const response = await getDefaultUserClient().reelGetFolderTree({});
  return response.result;
};

export const renameFolder = async (
  folderId: string,
  folderName: string,
): Promise<reel.RenameFolderResult> => {
  const response = await getDefaultUserClient().reelRenameFolder({
    folder_id: folderId,
    name: folderName,
  });
  queryClient.invalidateQueries({
    queryKey: replayApi.folderContentsDisplayInfo(folderId),
  });
  queryClient.invalidateQueries({
    queryKey: replayApi.team.folders(),
  });
  return response.result;
};

type CreateFolderLinkArgs = {
  folderId: string;
  url: string;
  linkName: string;
};

export const createFolderLink: ({
  folderId,
  url,
  linkName,
}: CreateFolderLinkArgs) => Promise<reel.CreateFolderLinkResult> = async ({
  folderId,
  url,
  linkName,
}) => {
  const response = await getDefaultUserClient().reelCreateFolderLink({
    folder_id: folderId,
    url: url,
    name: linkName,
  });

  return response.result;
};

type DeleteFolderLinkArgs = {
  folderId: string;
  linkId: string;
};

export const deleteFolderLink = async ({
  folderId,
  linkId,
}: DeleteFolderLinkArgs): Promise<reel.CreateFolderLinkResult> => {
  const response = await getDefaultUserClient().reelDeleteFolderLink({
    folder_id: folderId,
    folder_link_id: linkId,
  });

  return response.result;
};

export const emptyBranding: Branding = {
  theme: {
    type: 'default',
    variant: 'none',
  },
  logo: {
    type: 'default',
    variant: 'none',
  },
};

export interface UpdateProjectArgs extends Omit<reel.UpdateProjectRequestArgs, 'folder_id'> {
  folderId: string;
}

export const updateProject = async ({
  folderId,
  name,
  description,
  branding,
}: UpdateProjectArgs): Promise<reel.UpdateProjectResult['project']> => {
  const updateMask = [];

  if (name) updateMask.push('name');
  if (description !== undefined) updateMask.push('description');
  if (branding) updateMask.push('branding');

  const response = await getDefaultUserClient().reelUpdateProject({
    folder_id: folderId,
    name,
    description,
    branding,
    update_mask: {paths: updateMask},
  });
  return response.result.project;
};

export const deleteFolder = async (folderId: string): Promise<reel.DeleteFolderResult> => {
  const response = await getDefaultUserClient().reelDeleteFolder({
    folder_id: folderId,
  });
  return response.result;
};

export const moveProject = async (
  projectId: string,
  oldParentFolderId: string,
  newParentFolderId: string,
): Promise<reel.MoveProjectResult> => {
  const response = await getDefaultUserClient().reelMoveProject({
    project_id: projectId,
    old_parent_folder_id: oldParentFolderId,
    new_parent_folder_id: newParentFolderId,
  });
  queryClient.invalidateQueries({
    queryKey: replayApi.listFolderBasicInfo(oldParentFolderId),
  });
  queryClient.invalidateQueries({
    queryKey: replayApi.listFolderBasicInfo(newParentFolderId),
  });
  return response.result;
};

export const moveFolder = async (
  folderId: string,
  oldParentFolderId: string,
  newParentFolderId: string,
): Promise<reel.MoveFolderResult> => {
  const response = await getDefaultUserClient().reelMoveFolder({
    folder_id: folderId,
    old_parent_folder_id: oldParentFolderId,
    new_parent_folder_id: newParentFolderId,
  });
  return response.result;
};

type MoveItemsResult = {
  folderIdToStatus: Record<string, GenericStatus>;
  projectIdToStatus: Record<string, GenericStatus>;
};

const moveItemStatusTagToGenericStatus = (
  moveItemStatusTag: reel.MoveProjectStatus | reel.MoveFolderStatus,
) =>
  moveItemStatusTag['.tag'] === 'move_project_success' ||
  moveItemStatusTag['.tag'] === 'move_folder_success'
    ? GenericStatus.SUCCESS
    : GenericStatus.FAILED;

export const moveItems = async (
  folderIds: string[],
  projectIds: string[],
  oldParentFolderId: string,
  newParentFolderId: string,
): Promise<MoveItemsResult> => {
  const response = await getDefaultUserClient().reelMoveItems({
    folder_ids: folderIds,
    project_ids: projectIds,
    old_parent_folder_id: oldParentFolderId,
    new_parent_folder_id: newParentFolderId,
  });

  const folderIdToStatus: Record<string, GenericStatus> = {};
  const projectIdToStatus: Record<string, GenericStatus> = {};

  if (!response.result.folder_id_to_status || !response.result.project_id_to_status) {
    throw new Error('API Error moving items');
  }

  Object.entries(response.result.folder_id_to_status).forEach(([folderId, status]) => {
    folderIdToStatus[folderId] = moveItemStatusTagToGenericStatus(status);
  });
  Object.entries(response.result.project_id_to_status).forEach(([projectId, status]) => {
    projectIdToStatus[projectId] = moveItemStatusTagToGenericStatus(status);
  });

  return {folderIdToStatus, projectIdToStatus};
};

export const shareProject = async (
  folderId: string,
  contactInfos: TeamContactInfo[],
  accessLevel?: ShareFolderAccessType,
  customMessage?: string,
): Promise<reel.ShareFolderResult> => {
  const recipientInfos: reel.ShareRecipientInfo[] = contactInfos.map((contact) => {
    if (contact.type === 'user') {
      const id: reel.recipient_id_unionAccountId | reel.recipient_id_unionEmail = contact.accountId
        ? {
            '.tag': 'account_id',
            account_id: contact.accountId,
          }
        : {
            '.tag': 'email',
            email: contact.email,
          };
      const shareRecipient: reel.ShareRecipient = {
        recipient_id: id,
      };
      const info: reel.ShareRecipientInfo = {
        share_recipient: shareRecipient,
        access_level: {'.tag': accessLevel ? accessLevel : 'reviewer'},
      };
      return info;
    }
    const id: reel.recipient_id_unionGroupId = {
      '.tag': 'group_id',
      group_id: contact.groupId,
    };

    const shareRecipient: reel.ShareRecipient = {
      recipient_id: id,
    };
    const info: reel.ShareRecipientInfo = {
      share_recipient: shareRecipient,
      access_level: {'.tag': accessLevel ? accessLevel : 'reviewer'},
    };
    return info;
  });
  const response = await getDefaultUserClient().reelShareProject({
    folder_id: folderId,
    recipient_infos: recipientInfos,
    custom_message: customMessage,
  });
  queryClient.invalidateQueries({
    queryKey: replayApi.folderContentsDisplayInfo(folderId),
  });
  queryClient.invalidateQueries({
    queryKey: replayApi.team.folders(),
  });
  return response.result;
};

export const changeOwnership = async (
  folderOrMediaId: string,
  entityType: reel.ChangeOwnershipRequestEntityType,
  newAccountId?: string,
  shouldTakeOwnership?: boolean,
): Promise<reel.ChangeOwnershipResult> => {
  const args: reel.ChangeOwnershipArg = {
    folder_or_media_id: folderOrMediaId,
    entity_type: entityType,
    should_take_ownership: shouldTakeOwnership,
    new_account_id: newAccountId,
  };
  const response = await getDefaultUserClient().reelChangeOwnership(args);
  return response.result;
};

export const leaveProjects = async (folderIds: string[]): Promise<reel.ShareFolderResult> => {
  const response = await getDefaultUserClient().reelLeaveProjects({folder_ids: folderIds});
  return response.result;
};

export const searchContacts = async (
  query: string,
  limit: number = 12,
  signal?: AbortSignal,
): Promise<sharing.TargetsSearchResult> => {
  const response = await (signal
    ? dropboxSdk.asAbortable(signal)
    : getDefaultUserClient()
  ).sharingTargetsSearch({
    query: query,
    force_refresh: false,
    limit: limit,
  });
  return response.result;
};

export const getProxyUrls = async ({
  videoVersionId,
  resolutions,
  onlyRetrieveStatus,
  shareToken,
  grantBook,
  analyticsData,
}: {
  videoVersionId: string;
  resolutions?: Array<reel.ProxyResolution>;
  onlyRetrieveStatus?: boolean;
  shareToken?: string;
  grantBook?: string;
  analyticsData?: {
    logger: LoggingClient;
    papAccessType?: AccessType;
    projectId?: string;
    videoId?: string;
    creatorId?: string;
    fileType?: FileType;
  };
}): Promise<reel.GetProxyUrlsResult> => {
  const papProps: Download_File['properties'] = {
    deviceId: analyticsData?.logger.deviceId,
    accessType: analyticsData?.papAccessType,
    wopiSessionId: analyticsData?.logger.sessionId,
    videoVersionId: videoVersionId,
    projectId: analyticsData?.projectId,
    videoId: analyticsData?.videoId,
    creatorId: analyticsData?.creatorId,
    fileType: analyticsData?.fileType,
  };
  if (analyticsData && !onlyRetrieveStatus) {
    analyticsData.logger.logPap(
      PAP_Download_File({
        ...papProps,
        eventState: 'start',
      }),
    );
  }
  try {
    const response = await getDefaultUserClient().reelGetProxyUrls({
      video_version_id: videoVersionId,
      resolutions: resolutions,
      only_retrieve_status: onlyRetrieveStatus,
      share_token: shareToken,
      common: {
        grant_book: grantBook,
        replay_session_id: replayStorage.getReplaySessionID() ?? undefined,
      },
    });
    if (analyticsData && !onlyRetrieveStatus) {
      analyticsData.logger.logPap(
        PAP_Download_File({
          ...papProps,
          eventState: 'success',
        }),
      );
    }
    return response.result;
  } catch (e) {
    if (analyticsData && !onlyRetrieveStatus) {
      analyticsData.logger.logPap(
        PAP_Download_File({
          ...papProps,
          eventState: 'failed',
        }),
      );
    }
    throw e;
  }
};

type GetProxyZipUrlArgs = {
  videoVersionId: string;
  resolutions?: Array<reel.ProxyResolution>;
  common?: reel.GetProxyZipUrlArgs['common'];
  analyticsData?: {
    logger: LoggingClient;
    papAccessType?: AccessType;
    creatorId?: string;
    projectId?: string;
    videoId?: string;
  };
};

export const getProxyZipUrl = async ({
  videoVersionId,
  resolutions,
  common,
  analyticsData,
}: GetProxyZipUrlArgs): Promise<string> => {
  const papProps: Download_File['properties'] = {
    deviceId: analyticsData?.logger.deviceId,
    accessType: analyticsData?.papAccessType,
    wopiSessionId: analyticsData?.logger.sessionId,
    videoVersionId: videoVersionId,
    projectId: analyticsData?.projectId,
    videoId: analyticsData?.videoId,
    creatorId: analyticsData?.creatorId,
    fileType: 'zip',
  };
  if (analyticsData) {
    analyticsData.logger.logPap(
      PAP_Download_File({
        ...papProps,
        eventState: 'start',
      }),
    );
  }
  try {
    const response = await getDefaultUserClient().reelGetProxyZipUrl({
      video_version_id: videoVersionId,
      resolutions,
      common,
    });
    if (!response.result.url) {
      throw new Error('Unknown error fetching zip url');
    }
    if (analyticsData) {
      analyticsData.logger.logPap(
        PAP_Download_File({
          ...papProps,
          eventState: 'success',
        }),
      );
    }
    return response.result.url;
  } catch (e) {
    if (analyticsData) {
      analyticsData.logger.logPap(
        PAP_Download_File({
          ...papProps,
          eventState: 'failed',
        }),
      );
    }
    throw e;
  }
};

export const setThumbnail = async (
  videoVersionId: string,
  thumbnailTimestamp?: number,
): Promise<reel.SetThumbnailResult> => {
  const response = await getDefaultUserClient().reelSetThumbnail({
    video_version_id: videoVersionId,
    thumbnail_timestamp: thumbnailTimestamp,
  });
  return response.result;
};

export const deleteVersion = async (videoVersionId: string) => {
  await getDefaultUserClient().reelDeleteVersion({
    video_version_id: videoVersionId,
  });
};

export type LinkInfo = {
  viewOnly: boolean;
  link: string;
  shareToken: string;
  hasPassword: boolean;
  downloadsDisabled: boolean;
  expiryTimestamp?: Date;
  expired: boolean;
  loggedOutAccessDisabled: boolean;
  viewPreviousCommentsDisabled: boolean;
};

export type VideoWatermarkInfo = {
  email: string;
  showEmail: boolean;
  showIp: boolean;
  showViewDate: boolean;
  showViewTime: boolean;
  recipientUser: {
    displayName: string;
    accountPhotoUrl: string;
    initials: string;
  };
  customText: string;
  position: WatermarkPositionsType;
  theme: WatermarkThemeType;
  textOpacity: number;
  textSize: number;
};

export type WatermarkLinkInfo = {
  shareInfo: LinkInfo;
  watermarkInfo: VideoWatermarkInfo;
};

export type LinkInfoResult = {
  basicLinks: Array<LinkInfo>;
  watermarkLinks: Array<WatermarkLinkInfo>;
  sharingIsRestrictedByTeamPolicy?: boolean;
};

export type SuggestedFileId = string;

export const NONE = 'none' as const;

export const LOADING = 'loading' as const;

export type SuggestedFilesResponse = typeof NONE | typeof LOADING | reel.SuggestedItemPreviewData[];

export const getSuggestedItems = async (): Promise<reel.SuggestedItemPreviewData[]> => {
  let suggestedResults: genie.Result[] = [];

  try {
    const {result: assistantResult} = await getDefaultUserClient().genieGetAssistance({
      request_params: {
        client: {'.tag': 'genie_tray'},
        num_items: 50,
        metadata_configs: {
          include_file_metadata: true,
        },
        features: [{'.tag': 'suggest_content'}],
        feature_configs: [
          {
            configs: {
              '.tag': 'suggest_content_configs',
              num_items: 50,
              variant:
                'external_api_content_suggestions:combined_model_20211018_7d_web_v2_file_only',
            },
          },
        ],
      },
    });

    if (!assistantResult.results) {
      throw new Error('Bad GetAssistance response: no suggestions returned');
    }
    suggestedResults = assistantResult.results;
  } catch (e) {
    // Don't report these errors since they are non-critical and they fire regularly.
    return [];
  }

  const fileIds = suggestedResults.reduce<SuggestedFileId[]>((acc, result) => {
    if (
      !result.result ||
      result.result['.tag'] !== 'single_entry' ||
      !result.result.content ||
      !result.result.content.type ||
      !result.result.content.content ||
      result.result.content.content['.tag'] !== 'resource' ||
      !result.result.content.content.id ||
      !result.result.content.content.id.id ||
      result.result.content.content.id.id['.tag'] !== 'file_obj_id' ||
      !result.result.content.content.name
    ) {
      return acc;
    }
    // Phew
    const validExtensionsList = useValidExtensions();
    const extension = getExtension(result.result.content.content.name);
    const {file_obj_id: fileId} = result.result.content.content.id.id;

    if (validExtensionsList.includes(extension)) {
      acc.push(fileId);
    }

    return acc;
  }, []);

  try {
    const {result: suggestedItemsResult} =
      await getDefaultUserClient().reelGetSuggestedItemsPreviewData({
        suggested_file_ids: fileIds,
      });

    if (!suggestedItemsResult.suggested_items_preview_data) {
      throw new Error('Bad GetSuggestedItemsPreviewData response: no items returned');
    }

    return suggestedItemsResult.suggested_items_preview_data;
  } catch (e) {
    // Report these issues, but only report them as operational so they're not spamming
    // our ops channels given they are non-critical and fire regularly.
    reportFailedDataFetch(e, 'GetSuggestedItemsPreviewData', 'operational');
    return [];
  }
};

// ------------------- Logging -------------------

export type PageSourceType =
  | 'home_page'
  | 'landing_page_redirect_only'
  | 'landing_page_no_access'
  | 'landing_page'
  | 'third_party_auth_page';

export const logAggregatePageVisit = async (
  pageSource: PageSourceType,
  pageContext: PageContextType,
): Promise<reel.LogAggregateReplayPageVisitResult> => {
  const response = await getDefaultUserClient().reelLogAggregateReplayPageVisit({
    page_source: pageSource,
    page_context: pageContext,
  });
  return response.result;
};

const LoadingStrategies = [
  // Fetch all content in the folder at once
  'single_stage',
  // Aka lightning load - fetch only the basic info first, then the full info.
  // Both are single fetches.
  'two_stage',
  // Fetch only the basic info first in one request, then load the full data in
  // batches.
  'two_stage_batched',
  // Fetch the basic info in pages and then load the full data in batches.
  'fully_paginated',
] as const;

export type LoadingStrategy = (typeof LoadingStrategies)[number];

export const isLoadingStrategy = (a: any): a is LoadingStrategy => LoadingStrategies.includes(a);

type MetricArg =
  | {
      metric_type: reel.MetricTypeTtvcBrowse;
      value: number;
      loading_strategy: LoadingStrategy | '';
    }
  | {
      metric_type: reel.MetricTypeTtvcVideo;
      value: number;
    }
  | {
      metric_type: reel.MetricTypeTranscriptionDisplayWaitTime;
      value: number;
    }
  | {
      metric_type: reel.MetricTypeTimeToFirstRoute;
      value: number;
    }
  | {
      metric_type: reel.MetricTypeTimeForFirstSegmentDownload;
      value: number;
      playback_resolution: reel.PlaybackResolution;
    }
  | {
      metric_type: reel.MetricTypeRouteView;
    }
  | {
      metric_type: reel.MetricTypeUncaughtErrorView;
    };

export const addMetric = async (arg: MetricArg, client?: Dropbox): Promise<reel.MetricsResult> => {
  const response = await (client || getDefaultUserClient()).reelMetrics(arg);
  return response.result;
};

// ------------------- Google Drive --------------------

export const getLinkedGoogleToken = async (): Promise<string | null | Error> => {
  try {
    const res = await getDefaultUserClient().profileServicesGetAccessToken({
      service: {
        '.tag': 'google',
      },
      permissions: [
        {
          '.tag': 'documents',
        },
      ],
    });
    return res.result.access_token;
  } catch (err) {
    // :(
    const et =
      err.error &&
      err.error.error &&
      err.error.error.service_error &&
      err.error.error.service_error['.tag'];
    return et === 'missing_token' ? null : new Error('Missing oauth scope');
  }
};

export const startGDriveFileImport = async (
  fileId: string,
  nsid: number,
): Promise<string | null> => {
  try {
    const res = await getDefaultUserClient().fileImportsStartImport({
      import_type: {
        '.tag': 'gdrive',
      },
      src_files: [fileId],
      // this results in file path: nsid:/<gdrive-fileid>/<file-name.ext> and is unique
      dest_path: fileId,
      use_src_folder_structure: false,
      delete_imported_files: false,
      overwrite_files: false,
      dest_nsid: nsid,
      disable_notifications: true,
    });
    return res.result.job_id ? res.result.job_id : null;
  } catch (err) {
    reportGDriveError(err);
    return null;
  }
};

export enum GDriveImportStatus {
  Running,
  Failed,
  Completed,
}

export const queryGDriveImportStatus = async (importId: string): Promise<GDriveImportStatus> => {
  try {
    // TODO - maybe want to create a batch endpoint on the backend eventually
    const res = await getDefaultUserClient().fileImportsListImports({
      job_id: importId,
      count: 1,
    });

    if (!res.result.jobs || res.result.jobs.length !== 1) {
      reportGDriveError(
        new Error(
          `Got unexpected number of jobs (${res.result.jobs?.length}) for import ${importId}`,
        ),
      );
      return GDriveImportStatus.Failed;
    }

    const status = res.result.jobs[0].status;
    if (status) {
      if (status['.tag'] === 'running' || status['.tag'] === 'scheduled') {
        return GDriveImportStatus.Running;
      }
      if (status['.tag'] === 'succeeded') {
        return GDriveImportStatus.Completed;
      }
    }
    return GDriveImportStatus.Failed;
  } catch (err) {
    reportGDriveError(err);
    return GDriveImportStatus.Failed;
  }
};

// ------------------- OneDrive --------------------

export const getLinkedOneDriveToken = async (): Promise<string> => {
  try {
    const res = await getDefaultUserClient().profileServicesGetAccessToken({
      service: {
        '.tag': 'outlook',
      },
      permissions: [
        {
          '.tag': 'documents',
        },
      ],
    });

    return res.result.access_token;
  } catch (err) {
    if (err instanceof OneDriveTokenNotFoundError) throw err;
    // :(
    const et = err?.error?.error?.service_error['.tag'];

    throw et === 'missing_token'
      ? new OneDriveTokenNotFoundError('Missing OneDrive token')
      : new Error('Missing oauth scope');
  }
};

export const getOneDriveAccountId = async (): Promise<string | null> => {
  const res = await getDefaultUserClient().profileServicesListLinkedServices();
  const services = res.result.linked_services;
  const accountId = services.find((service) => service.service_type['.tag'] === 'outlook');
  return accountId?.external_service_id ?? null;
};

export const startOneDriveFileImport = async (
  fileId: string,
  nsid: number,
  accountId: string,
): Promise<string | null> => {
  try {
    const res = await getDefaultUserClient().fileImportsStartImport({
      import_type: {
        '.tag': 'onedrive',
      },
      src_files: [fileId],
      // this results in file path: nsid:/<onedrive-fileid>/<file-name.ext> and is unique
      dest_path: fileId,
      use_src_folder_structure: false,
      delete_imported_files: false,
      overwrite_files: false,
      dest_nsid: nsid,
      disable_notifications: true,
      account_identifier: accountId,
    });
    return res.result.job_id ? res.result.job_id : null;
  } catch (err) {
    reportOneDriveError(err);
    return null;
  }
};

export enum OneDriveImportStatus {
  Running,
  Failed,
  Completed,
}

export const queryOneDriveImportStatus = async (
  importId: string,
): Promise<OneDriveImportStatus> => {
  try {
    // TODO - maybe want to create a batch endpoint on the backend eventually
    const res = await getDefaultUserClient().fileImportsListImports({
      job_id: importId,
      count: 1,
    });

    if (!res.result.jobs || res.result.jobs.length !== 1) {
      reportOneDriveError(
        new Error(
          `Got unexpected number of jobs (${res.result.jobs?.length}) for import ${importId}`,
        ),
      );
      return OneDriveImportStatus.Failed;
    }

    const status = res.result.jobs[0].status;
    if (status) {
      if (status['.tag'] === 'running' || status['.tag'] === 'scheduled') {
        return OneDriveImportStatus.Running;
      }
      if (status['.tag'] === 'succeeded') {
        return OneDriveImportStatus.Completed;
      }
    }
    return OneDriveImportStatus.Failed;
  } catch (err) {
    reportOneDriveError(err);
    return OneDriveImportStatus.Failed;
  }
};

// -------------------- Tasks --------------------

export const getTasksForItem = async (
  videoId: string,
  shareToken?: string,
  grantBook?: string,
): Promise<Task | null> => {
  const res = await getDefaultUserClient().reelGetTasksForItem({
    item_id: videoId,
    common: {
      share_token: shareToken,
      grant_book: grantBook,
    },
  });

  if (!res.result.tasks) {
    const err = new Error('Bad GetTasksForItem API response: Tasks are undefined');
    reportFailedDataFetch(err, 'GetTasksForItem');
    throw err;
  }

  if (res.result.tasks.length === 0) {
    return null;
  } else {
    if (res.result.tasks.length > 1) {
      const err = new Error('Bad GetTasksForItem API response: Received more tasks than expected');
      reportFailedDataFetch(err, 'GetTasksForItem');
    }
    const task = res.result.tasks[0];
    if (!task.task_id || !task.due_date) {
      const err = new Error('Bad GetTasksForItem API response: Task is missing information');
      reportFailedDataFetch(err, 'GetTasksForItem');
      throw err;
    }
    return {taskId: task.task_id, dueDate: new Date(task.due_date)};
  }
};

export const createTask = async (dueDate: Date, videoId: string): Promise<Task> => {
  const res = await getDefaultUserClient().reelCreateTask({
    item_id: videoId,
    due_date: dueDate.toISOString(),
  });
  const task = res.result.task;

  if (!task || !task.task_id || !task.due_date) {
    const err = new Error('Bad CreateTask API response: There was an issue with the returned task');
    reportFailedDataFetch(err, 'CreateTask');
    throw err;
  }

  return {taskId: task.task_id, dueDate: new Date(task.due_date)};
};

export const updateTask = async (dueDate: Date, taskId: string, videoId: string): Promise<Task> => {
  const res = await getDefaultUserClient().reelUpdateTask({
    task_id: taskId,
    due_date: dueDate.toISOString(),
  });
  const task = res.result.task;
  if (!task || !task.task_id || !task.due_date) {
    const err = new Error('Bad UpdateTask API response: There was an issue with the returned task');
    reportFailedDataFetch(err, 'UpdateTask');
    throw err;
  }
  return {taskId: task.task_id, dueDate: new Date(task.due_date)};
};

export const deleteTask = async (taskId: string): Promise<reel.DeleteTaskResult> => {
  const res = await getDefaultUserClient().reelDeleteTask({
    task_id: taskId,
  });
  return res.result;
};

// ------------------- Transcriptions & Editing -------------------
export type TranscriptionSegment = {
  startTime: number;
  endTime: number;
  text: string;
};
export type Transcription = {
  mediaId?: string;
  segments: TranscriptionSegment[];
  isEditedTranscript?: boolean;
};

export type EditTranscriptSegment = {
  start_time: number;
  end_time: number;
  text?: string;
};

export type EditTranscription = {
  words?: EditTranscriptSegment[];
  captionTimestamps?: EditTranscriptSegment[];
  paragraphTimestamps?: EditTranscriptSegment[];
};

const convertToEditTranscriptSegment = (segment: TranscriptionSegment): EditTranscriptSegment => ({
  start_time: segment.startTime,
  end_time: segment.endTime,
  text: segment.text,
});

const convertToCaptionTimestamps = (segment: TranscriptionSegment): EditTranscriptSegment => ({
  start_time: segment.startTime,
  end_time: segment.endTime,
});

type TranscriptionInput = Transcription | TranscriptionSegment[];
export const convertToEditTranscription = (
  transcriptionData: TranscriptionInput,
): EditTranscription => {
  const segments = Array.isArray(transcriptionData)
    ? transcriptionData
    : transcriptionData.segments;
  return {
    words: segments.map(convertToEditTranscriptSegment),
    captionTimestamps: segments.map(convertToCaptionTimestamps),
  };
};

export const getOriginalTranscription = async (
  videoVersionId: string,
  shareToken?: string,
  grantBook?: string,
  audioLanguage?: string,
): Promise<{type: 'pending'} | ({type: 'completed'} & Transcription)> => {
  const arg: reel.GetTranscriptionsRequest = {
    video_version_id: videoVersionId,
  };
  if (audioLanguage) {
    arg.audio_language = audioLanguage;
  }
  if (shareToken) {
    arg.common = {
      grant_book: grantBook,
      share_token: shareToken,
    };
  }

  try {
    const {result} = await getDefaultUserClient().reelGetTranscription(arg);

    const status = result.transcription_state?.['.tag'] ?? 'other';

    if (status === 'transcribe_error' || status === 'other') {
      throw new Error('Unable to create transcript');
    }

    // If the response is pending, try again after a delay
    if (status === 'pending') {
      return {type: 'pending'};
    }

    return {
      type: 'completed',
      mediaId: videoVersionId,
      segments:
        result.segments?.map((segment) => ({
          startTime: segment.start_time ?? 0,
          endTime: segment.end_time ?? 0,
          text: segment.text?.replace(/ +(?= )/g, '').trim() ?? '',
        })) || [],
    };
  } catch (e) {
    if (e.error?.error['.tag'] === 'max_file_size_exceeded_error') {
      throw new TranscriptionMaxSizeExceededError();
    }
    throw e;
  }
};

export const createTranscript = async (
  asset_version_id: string,
  transcript: EditTranscription,
  editedSegments: ReelTranscriptSegment[],
): Promise<void> => {
  const currentUser = getCurrentUser();
  const edits: EditTranscriptSegment[] = editedSegments.map((segment) => ({
    start_time: segment.startTime,
    end_time: segment.endTime,
    text: segment.text,
  }));
  const arg: reel.CreateEditTranscriptionRequest = {
    asset_version_id: asset_version_id,
    transcript: {
      words: transcript.words,
      caption_timestamps: transcript.captionTimestamps,
      paragraph_timestamps: transcript.paragraphTimestamps,
    },
    edits: {edits},
    locale: currentUser?.locale ?? '',
  };

  try {
    await getDefaultUserClient().reelCreateEditTranscription(arg);
  } catch (e) {
    const errorTag = e.error?.['.tag'];
    if (errorTag === 'create_transcript_unknown_error' || errorTag === 'other') {
      throw new Error('Unable to create transcript');
    }
    throw e;
  }
};

export const getEditedTranscription = async (
  assetVersionId: string,
  shareToken?: string,
  grantBook?: string,
): Promise<{type: 'pending'} | ({type: 'completed'} & Transcription)> => {
  const arg: reel.GetEditTranscriptionRequest = {
    level: {'.tag': 'caption'},
    asset_version_id: assetVersionId,
  };
  if (shareToken) {
    arg.common = {
      grant_book: grantBook,
      share_token: shareToken,
    };
  }

  try {
    const {result} = await getDefaultUserClient().reelGetEditTranscription(arg);

    return {
      mediaId: assetVersionId,
      segments:
        result.segments?.map((segment) => ({
          startTime: segment.start_time ?? 0,
          endTime: segment.end_time ?? 0,
          text: segment.text?.replace(/ +(?= )/g, '').trim() ?? '',
        })) || [],
      isEditedTranscript: true,
      type: 'completed',
    };
  } catch (e) {
    const errorTag = e.error?.['.tag'];
    if (errorTag === 'get_transcript_unknown_error' || errorTag === 'other') {
      const e = new Error('Error when fetching from GetEditTranscripton.');
      throw e;
    }
    throw e;
  }
};

export const updateTranscript = async (
  fileId: string,
  editedSegments: ReelTranscriptSegment[],
): Promise<ReelTranscriptSegment[]> => {
  const edits: EditTranscriptSegment[] = editedSegments.map((segment) => ({
    start_time: segment.startTime,
    end_time: segment.endTime,
    text: segment.text,
  }));
  const arg: transcript_edit.UpdateTranscriptArg = {
    file_id: fileId,
    edits: {edits},
    feature: '/reel/client/view_transcription',
  };

  try {
    await getDefaultUserClient().transcriptEditUpdateTranscript(arg);
    return editedSegments;
  } catch (e) {
    const errorTag = e.error?.['.tag'];
    if (errorTag === 'update_transcript_unknown_error' || errorTag === 'other') {
      throw new Error('Unable to update transcript');
    }
    throw e;
  }
};

// ------------------- EditorProject -------------------
export const getEditorProjectFromShortLink = async ({
  grantBook,
  shareToken,
}: {
  grantBook?: string;
  shareToken: string;
}): Promise<SharedEditorProjectProp> => {
  try {
    const response = await getDefaultUserClient().reelGetWithSharedLink({
      entity_type: {'.tag': 'shared_editor_project'},
      share_token: shareToken,
      common: {
        grant_book: grantBook,
        replay_session_id: replayStorage.getReplaySessionID() ?? undefined,
      },
    });

    const {
      show_comments: showComments,
      downloads_enabled: downloadsEnabled,
      shared_entity,
    } = response.result;
    if (!shared_entity || shared_entity['.tag'] !== 'editor_project') {
      throw Error('This function only supports editor projects.');
    }

    if (!shared_entity.editor_project_id) {
      const e = new Error('Bad GetWithSharedLink API response: Missing editor project ID.');
      reportFailedDataFetch(e, 'GetWithSharedLink');
      throw e;
    }

    const editorProject = {
      editorProjectId: shared_entity.editor_project_id,
      creatorId: shared_entity.creator_id,
      editorProjectIdForAmplitude: 'TEST_123', // TODO: Return amplitude ID from the backend
      name: shared_entity.name,
      lastModifiedTime: shared_entity.last_modified_timestamp
        ? new Date(shared_entity.last_modified_timestamp)
        : undefined,
      edl: shared_entity.edl,
      assets: shared_entity.assets,
    };

    return {
      editorProject: editorProject,
      showComments: showComments ?? false,
      downloadsEnabled: !!downloadsEnabled,
    };
  } catch (e) {
    if (e instanceof DropboxResponseError && e.status === 403) {
      throw new ShareLinkNotAuthedError();
    } else if (e instanceof DropboxResponseError && e.status === 409) {
      if (e.error?.error['.tag'] === 'login_required')
        throw new ShareLinkLoggedOutAccessDisabledError();
      else throw new ShareLinkDisabledError();
    } else {
      throw e;
    }
  }
};

// -------------------- Upsell --------------------
export const getReplayEligibility = async (): Promise<boolean> => {
  try {
    const response = await getDefaultUserClient().reelGetReplayEligibility({});
    return !!response.result.eligible;
  } catch (e) {
    // default to true so it is closer to the current prod behavior of always showing the cta. In addition,
    // we don't want to lose the potential revenue opportunity for eligible admins to purchase the Replay.
    return true;
  }
};

export const requestReplayAddOn = async () => {
  await getDefaultUserClient().reelRequestReplayAddOn({});
};

export const getPendingReplayAddOnRequestsForTeam = async () => {
  return await getDefaultUserClient().reelGetPendingReplayAddOnRequestsForTeam({});
};

export type BillingCycle = 'monthly' | 'yearly' | 'unknown';
export type BillingInfo = {
  billingCycle: BillingCycle;
  billingPrice: string;
};

export const getBillingCycle = async (): Promise<BillingInfo> => {
  const res = await getDefaultUserClient().reelGetBillingCycle({});
  const billingPrice = res.result.price_with_currency ?? '';

  let billingCycle: BillingCycle;
  switch (res.result.billing_cycle?.['.tag']) {
    case 'monthly':
      billingCycle = 'monthly';
      break;
    case 'yearly':
      billingCycle = 'yearly';
      break;
    case 'other':
    case 'unknown_cycle':
      billingCycle = 'unknown';
      break;
    default:
      billingCycle = 'unknown';
      break;
  }

  return {billingCycle: billingCycle, billingPrice: billingPrice};
};

export const getManagePeopleInfoForAssetVersion = async (assetVersionId: string) => {
  const response = await getDefaultUserClient().reelGetManagePeopleInfoForAssetVersion({
    asset_version_id: assetVersionId,
  });

  if (!response.result) {
    throw new Error('Bad GetManagePeopleInfoForAssetVersion API response: no result');
  }

  return {
    team_project_members: response.result.team_project_members ?? [],
    allow_super_admin_rights: response.result.allow_super_admin_rights ?? false,
  };
};

// -------------------- Search --------------------

export const searchQuery = async (
  query: string,
  signal?: AbortSignal,
): Promise<reel.SearchResult> => {
  if (!query || query.length < MIN_SEARCH_QUERY_LENGTH) {
    return {
      team_projects: [],
      folders: [],
      assets: [],
    };
  }

  const response = await (signal
    ? dropboxSdk.asAbortable(signal)
    : getDefaultUserClient()
  ).reelSearch({
    query: query,
  });

  return response.result;
};

// -------------------- E2E --------------------

export const getAvailableTestUsers = async (): Promise<reel.E2eGetAvailableTestUsersResult> => {
  const response = await getDefaultUserClient().reelGetAvailableTestUsers({});
  return response.result;
};

export const lockTestUser = async (): Promise<reel.E2eLockTestUserResult> => {
  const response = await getDefaultUserClient().reelLockTestUser({});
  return response.result;
};

export const releaseTestUser = async (): Promise<reel.E2eReleaseTestUserResult> => {
  const response = await getDefaultUserClient().reelReleaseTestUser({});
  return response.result;
};

// -------------------- Invites --------------------

type InviteUsersArgs = {
  emails: string[];
  message: string;
};
export const inviteUsers = async ({
  emails,
  message,
}: InviteUsersArgs): Promise<team.InviteUsersResponse> => {
  const response = await getDefaultUserClient().teamMemberActionsInviteUsers({
    emails,
    custom_message: message,
    source_product: {'.tag': 'replay'},
  });
  return response.result;
};
