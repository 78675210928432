import React from 'react';

import {useAtom} from 'jotai';
import {useIntl} from 'react-intl';

import {Menu} from '@dropbox/dig-components/menu';
import {UIIcon} from '@dropbox/dig-icons';
import {CheckmarkLine} from '@dropbox/dig-icons/assets';

import {Button} from '~/components/button';
import {browseSortAtom} from '~/state/browse';

import {useBrowseLogEventMaker} from '../browse_logging_context';
import type {SortAttr, SortDirection} from '../types';

export const SortingDropdown = () => {
  const makeLogEvent = useBrowseLogEventMaker();
  const logEvent = React.useMemo(() => makeLogEvent(null), [makeLogEvent]);
  const intl = useIntl();
  const [{direction: currentSortDirection, attribute: currentSortAttribute}, setSort] =
    useAtom(browseSortAtom);

  const nameAToZLabel = intl.formatMessage({
    defaultMessage: 'Name (A to Z)',
    id: '0tbfDT',
    description: 'Label for a menu item a user can choose to sort files by name, alphabetically.',
  });

  const nameZToALabel = intl.formatMessage({
    defaultMessage: 'Name (Z to A)',
    id: 'vMA2iM',
    description:
      'Label for a menu item a user can choose to sort files by name, alphabetically descending.',
  });

  const newestFirstLabel = intl.formatMessage({
    defaultMessage: 'Newest first',
    id: 'olKtOM',
    description: 'Label for a menu item a user can choose to sort files by creation time.',
  });

  const recentlyUpdatedLabel = intl.formatMessage({
    defaultMessage: 'Recently updated',
    id: 'NpnvuQ',
    description:
      'Label for a menu item a user can choose to sort files by their most recent update time.',
  });

  const getLabelForSortDropdown = (
    sortingAttr: SortAttr,
    sortingDirection: SortDirection,
  ): string => {
    switch (sortingAttr) {
      case 'name':
        if (sortingDirection == 'descending') return nameZToALabel;
        return nameAToZLabel;
      case 'created':
        return newestFirstLabel;
      case 'updated':
        return recentlyUpdatedLabel;
    }
    return '';
  };

  const onSortingChange = (selection: string) => {
    switch (selection) {
      case 'name_asc':
        setSort({direction: 'ascending', attribute: 'name'});
        // eslint-disable-next-line deprecation/deprecation
        logEvent('select_tile_sort', {attribute: 'name', direction: 'ascending'});
        break;
      case 'name_desc':
        setSort({direction: 'descending', attribute: 'name'});
        // eslint-disable-next-line deprecation/deprecation
        logEvent('select_tile_sort', {attribute: 'name', direction: 'descending'});
        break;
      case 'created':
        setSort({direction: 'descending', attribute: 'created'});
        // eslint-disable-next-line deprecation/deprecation
        logEvent('select_tile_sort', {attribute: 'created', direction: 'descending'});
        break;
      case 'updated':
        setSort({direction: 'descending', attribute: 'updated'});
        // eslint-disable-next-line deprecation/deprecation
        logEvent('select_tile_sort', {attribute: 'updated', direction: 'descending'});
        break;
      default:
        return;
    }
  };

  return (
    <Menu.Wrapper onSelection={(selection) => onSortingChange(selection)}>
      {({getTriggerProps, getContentProps}) => (
        <>
          <Button
            {...getTriggerProps()}
            hasNoUnderline={true}
            size="small"
            variant="transparent"
            withDropdownIcon
          >
            {getLabelForSortDropdown(currentSortAttribute, currentSortDirection)}
          </Button>
          <Menu.Content {...getContentProps()}>
            <Menu.Segment>
              <Menu.ActionItem
                key="name_asc"
                value="name_asc"
                withRightAccessory={
                  isItemSelected(
                    'name',
                    'ascending',
                    currentSortAttribute,
                    currentSortDirection,
                  ) ? (
                    <UIIcon src={CheckmarkLine} />
                  ) : (
                    <></>
                  )
                }
              >
                {nameAToZLabel}
              </Menu.ActionItem>
              <Menu.ActionItem
                key="name_desc"
                value="name_desc"
                withRightAccessory={
                  isItemSelected(
                    'name',
                    'descending',
                    currentSortAttribute,
                    currentSortDirection,
                  ) ? (
                    <UIIcon src={CheckmarkLine} />
                  ) : (
                    <></>
                  )
                }
              >
                {nameZToALabel}
              </Menu.ActionItem>
            </Menu.Segment>
            <Menu.Segment>
              <Menu.ActionItem
                key="updated"
                value="updated"
                withRightAccessory={
                  isItemSelected(
                    'updated',
                    'descending',
                    currentSortAttribute,
                    currentSortDirection,
                  ) ? (
                    <UIIcon src={CheckmarkLine} />
                  ) : (
                    <></>
                  )
                }
              >
                {recentlyUpdatedLabel}
              </Menu.ActionItem>
              <Menu.ActionItem
                key="created"
                value="created"
                withRightAccessory={
                  isItemSelected(
                    'created',
                    'descending',
                    currentSortAttribute,
                    currentSortDirection,
                  ) ? (
                    <UIIcon src={CheckmarkLine} />
                  ) : (
                    <></>
                  )
                }
              >
                {newestFirstLabel}
              </Menu.ActionItem>
            </Menu.Segment>
          </Menu.Content>
        </>
      )}
    </Menu.Wrapper>
  );
};

const isItemSelected = (
  menuItemSortAttr: SortAttr,
  menuItemSortDir: SortDirection,
  selectedSortAttr: SortAttr,
  selectedSortDir: SortDirection,
): boolean => {
  return menuItemSortAttr == selectedSortAttr && menuItemSortDir == selectedSortDir;
};
