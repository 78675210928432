import React from 'react';

import {useLocation} from 'react-router';

export const useQuery = () => {
  const {search} = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const ROLE_QUERY_PARAM = 'loginAsRole';

export const getRoleLoginParam = (): null | 'work' | 'personal' => {
  const params = new URLSearchParams(window.location.search);
  const role = params.get(ROLE_QUERY_PARAM);
  if (role === 'work' || role === 'personal') {
    return role;
  }
  return null;
};

export const NEW_USER_REGISTRATION_FLAG_QUERY_PARAM = 'newUser';
export const NEW_USER_REGISTRATION_FLAG_QUERY_VALUE = '1';

export const getNewUserRegistrationFlag = (): boolean => {
  const params = new URLSearchParams(window.location.search);
  const flag = params.get(NEW_USER_REGISTRATION_FLAG_QUERY_PARAM);
  return flag === NEW_USER_REGISTRATION_FLAG_QUERY_VALUE;
};

export const VERSION_ID_QUERY_STRING_KEY = 'video_version_id';

export const getCurrentURLWithoutExtraParams = () => {
  // Remove extra params (e.g. email campaign IDs), but keep video version ID param

  const params = new URLSearchParams(window.location.search);
  const videoVersionId = params.get(VERSION_ID_QUERY_STRING_KEY) ?? undefined;

  if (videoVersionId) {
    return (
      window.location.origin +
      window.location.pathname +
      '?' +
      VERSION_ID_QUERY_STRING_KEY +
      '=' +
      videoVersionId
    );
  }

  return window.location.origin + window.location.pathname;
};

export const getInitialVersionId = (): string | undefined => {
  const params = new URLSearchParams(window.location.search);
  const encodedId = params.get(VERSION_ID_QUERY_STRING_KEY) ?? undefined;
  return encodedId ? decodeURIComponent(encodedId) : undefined;
};

export const SEND_TO_ADDON_PURCHASE_QUERY_PARAM = 'product_type';
export const SEND_TO_ADDON_PURCHASE_QUERY_VALUE = 'storage_addon';

const PURCHASE_PAGE_SOURCE_QUERY_PARAM = 'source';
const SEND_TO_ADDON_PURCHASE_PAGE_SOURCE_QUERY_VALUES = [
  'browsemenu',
  'browsenavprompt',
  'storageemail',
];

export const currentUrlHasStorageAddonPurchaseQueryParam = () => {
  const params = new URLSearchParams(window.location.search);
  if (params.get(SEND_TO_ADDON_PURCHASE_QUERY_PARAM) === SEND_TO_ADDON_PURCHASE_QUERY_VALUE) {
    return true;
  }
  const sourceParam = params.get(PURCHASE_PAGE_SOURCE_QUERY_PARAM);
  if (sourceParam && SEND_TO_ADDON_PURCHASE_PAGE_SOURCE_QUERY_VALUES.includes(sourceParam)) {
    return true;
  }
  return false;
};
