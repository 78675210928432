import React from 'react';

import {useIntl} from 'react-intl';
import styled, {css} from 'styled-components';

import {Box} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {AddLine} from '@dropbox/dig-icons/assets';

import {breakpointSmall, color, spacing} from '~/components/styled';
import type {AddMediaClickSourceType} from '~/lib/logging/logger_types';
import type {FocusStyles} from '~/lib/onboarding_v2/use_focus_ring';
import {useFocusRing} from '~/lib/onboarding_v2/use_focus_ring';
import type {
  AllUploadsCompleteHandler,
  BasicFileInfo,
  ChooserFile,
  DirectUpload,
} from '~/lib/uploads/types';
import {STEPS} from '~/state/onboarding_v2';

import {MediaAdder} from '../media_adder';

type UploadTileProps = {
  currentFolderId: string;
  level: number;
  onAddVideoFromDropbox: (
    files: ChooserFile[],
    clickSource: AddMediaClickSourceType,
    level: number,
  ) => Promise<void>;
  onFileUploadComplete: (
    click_source: AddMediaClickSourceType,
    level: number,
    projectId: string,
    videoId: string,
    name: string,
  ) => void;
  onAllUploadsComplete: AllUploadsCompleteHandler;
  onAddVideoError: () => void;
  onFilePick: (files: BasicFileInfo[]) => void;
};

type UploadTileContainerProps = {
  $focusStyles: FocusStyles;
};
export const UploadTileContainer = styled.div<UploadTileContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed var(--dig-color__border__subtle);
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  background: transparent;
  padding: ${spacing('Micro Small')};
  color: ${color('Text Base')};

  ${breakpointSmall(css`
    padding: ${spacing('Micro XLarge')};
  `)}

  &:hover {
    border: 1px dashed var(--color__inverse__standard__border);
  }

  & .dig-Button--standard {
    height: 100%;
  }

  & .dig-Button-content {
    white-space: normal;
    height: 3em;
    border-bottom: 0;

    ${breakpointSmall(css`
      max-width: 200px;
    `)}
  }

  ${(props) => props.$focusStyles};
`;

export const UploadVideoLabelContainer = styled.div`
  flex-grow: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

export const UploadTile = ({
  currentFolderId,
  level,
  onAddVideoFromDropbox,
  onFileUploadComplete,
  onAllUploadsComplete,
  onAddVideoError,
  onFilePick,
}: UploadTileProps) => {
  const intl = useIntl();
  const uploadFileFocusStyles = useFocusRing([STEPS.UPLOAD_FILE]);

  const uploadButtonText = intl.formatMessage({
    defaultMessage: 'Organize files in dedicated project spaces.',
    id: 'LdIVCj',
    description:
      'Text for a button on the browse page grid view that allows you to upload files. It also informs users they can drag and drop files onto the page to upload.',
  });

  const [showMenuContents, setShowMenuContents] = React.useState(false);

  const onClick = () => {
    setShowMenuContents(!showMenuContents);
  };

  return (
    <UploadTileContainer $focusStyles={uploadFileFocusStyles} onClick={onClick} role="gridcell">
      <UploadVideoLabelContainer>
        <Box display="flex" justifyContent="center">
          <UIIcon height={48} src={AddLine} />
        </Box>
        <MediaAdder
          buttonText={uploadButtonText}
          buttonVariant="transparent"
          clickSource="upload_project_tile"
          collapseOnMobile={false}
          currentFolderDepth={level}
          currentFolderId={currentFolderId}
          dropdownPlacement="bottom"
          folderLevel={level}
          loadingState="disabled"
          onAddError={() => onAddVideoError()}
          onAddFromDropbox={async (files) => {
            await onAddVideoFromDropbox(files, 'upload_project_tile', level);
          }}
          onAllUploadsComplete={onAllUploadsComplete}
          onFilePick={onFilePick}
          onFileUploadComplete={(
            {projectId, file: {name}, videoId}: DirectUpload,
            fileId: string,
          ) => {
            onFileUploadComplete('upload_project_tile', level, projectId!, videoId!, name);
          }}
          showMenuContents={showMenuContents}
          withDropdownIcon={false}
          withIconLeft={false}
        />
      </UploadVideoLabelContainer>
    </UploadTileContainer>
  );
};
