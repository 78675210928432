import {useCallback, useMemo} from 'react';

import {useSetAtom} from 'jotai';
import {PAP_Select_ReelMediaAction} from 'pap-events/replay/select_reel_media_action';

import type {reel} from '@dropbox/api-v2-client';

import {useOnShareClick} from '~/components/share_modal/share_modal_common';
import {initializeShareModalAtom, isShareModalOpenAtom} from '~/components/share_modal/share_state';
import type {UploadInfo} from '~/lib/uploads/types';
import {useActionSurface} from '~/lib/use_action_surface';
import {useLinkActions} from '~/lib/use_link_actions';
import {useLoggingClient} from '~/lib/use_logging_client';

type Props = {
  versionSummaries: reel.VersionSummary[];
  upload: UploadInfo;
};

const useShareUploadRow = ({upload, versionSummaries}: Props) => {
  const loggingClient = useLoggingClient();
  const getActionSurface = useActionSurface();

  const logEvent = useMemo(() => {
    // eslint-disable-next-line deprecation/deprecation
    return loggingClient.logEvent.bind(loggingClient);
  }, [loggingClient]);

  const linkActions = useLinkActions({
    // eslint-disable-next-line deprecation/deprecation
    logEvent: loggingClient.logEvent,
    linkItemId: upload.videoId || '',
    linkItemType: 'file',
    versionSummaries: versionSummaries,
    location: 'upload_drawer',
  });

  const {existingLinksPromise} = useOnShareClick({
    getExistingLinks: linkActions.getExistingLinks,
    mediaId: upload.videoId || '',
  });

  const initializeShareModal = useSetAtom(initializeShareModalAtom);
  const setIsShareModalOpen = useSetAtom(isShareModalOpenAtom);

  const handleOpenShareModal = useCallback(async () => {
    // NEW PAP logger
    loggingClient.logPap(
      PAP_Select_ReelMediaAction({actionSurface: getActionSurface(), actionType: 'share'}),
    );

    const loggingLocation = 'upload_drawer';
    const maxVersionNumber = versionSummaries?.reduce((a, b) => Math.max(a, b.version_num), 1) ?? 1;
    const maxVersion = versionSummaries?.find(
      (version) => version.version_num === maxVersionNumber,
    );
    // eslint-disable-next-line deprecation/deprecation
    loggingClient.logEvent('select_reel_share', {
      link_item_type: 'file',
      location: loggingLocation,
      replay_media_type: 'none',
      experiment_variant: 'none',
    });
    const existingLinks = await existingLinksPromise;
    if (existingLinks != null && existingLinks != 'unfetched' && upload.videoId) {
      initializeShareModal({
        id: upload.videoId,
        type: 'file',
        name: upload.file.name,
        thumbnailUrl: maxVersion?.thumbnail_url,
        versionSummaries: versionSummaries ?? [],
        defaultVersion: maxVersionNumber,
        logEvent: logEvent,
        location: loggingLocation,
        isDemo: false,
        isLiveReview: false,
        linkItemType: 'file',
        basicLinks: existingLinks.basicLinks,
        watermarkLinks: existingLinks.watermarkLinks,
        sharingIsRestrictedByTeamPolicy: existingLinks.sharingIsRestrictedByTeamPolicy,
      });
      setIsShareModalOpen(true);
    }
  }, [
    existingLinksPromise,
    getActionSurface,
    initializeShareModal,
    logEvent,
    loggingClient,
    setIsShareModalOpen,
    upload.file.name,
    upload.videoId,
    versionSummaries,
  ]);

  return {
    handleOpenShareModal,
  };
};

export {useShareUploadRow};
