import React from 'react';

import {PAP_Select_TopNavAction} from 'pap-events/replay/select_top_nav_action';
import {useIntl} from 'react-intl';
import styled from 'styled-components';

import {UIIcon} from '@dropbox/dig-icons';
import {GiftLine} from '@dropbox/dig-icons/assets';

import {IconButton} from '~/components/button';
import type {LoggingClient} from '~/lib/logging/logger';
import {useLoggingClient} from '~/lib/use_logging_client';
import {useOnce} from '~/lib/use_once';

declare function Canny(...args: any[]): void;
declare interface C {
  (): void;
  q: unknown[];
}

const ChangelogContainer = styled.div`
  .Canny_Badge {
    position: absolute;
    top: 4px;
    right: 1px;
    border-radius: 10px;
    background-color: var(--dig-color__primary__base);
    padding: 3px;
    border: none;
  }
`;

type ChangelogButtonProps = {
  logEvent: LoggingClient['logEvent'];
  actionSurface: string;
};

let cannyAdded = false;
export const ChangelogButton = ({logEvent, actionSurface}: ChangelogButtonProps) => {
  const intl = useIntl();
  const loggingClient = useLoggingClient();

  React.useEffect(() => {
    if (cannyAdded) {
      return;
    }

    cannyAdded = true;

    (function (w, d, i, s) {
      function l() {
        if (!d.getElementById(i)) {
          const f = d.getElementsByTagName(s)[0],
            e = d.createElement(s) as HTMLScriptElement;
          e.type = 'text/javascript';
          e.async = true;
          e.src = 'https://canny.io/sdk.js';
          f.parentNode?.insertBefore(e, f);
        }
      }
      if ('function' !== typeof (w as any).Canny) {
        const c = function () {
          (c as C).q.push(arguments);
        };
        (c as C).q = [];
        (w as any).Canny = c;
        if (d.readyState === 'complete') {
          l();
        } else {
          w.addEventListener('load', l, false);
        }
      }
    })(window, document, 'canny-jssdk', 'script');

    if (typeof Canny !== 'undefined') {
      Canny('initChangelog', {
        appID: '62b331324c13d90b973cceac',
        position: 'bottom',
        align: 'right',
        theme: 'dark',
      });
    }
  }, []);

  useOnce(() => {
    // eslint-disable-next-line deprecation/deprecation
    logEvent('shown_whats_new_badge');
  });

  return (
    <ChangelogContainer>
      <IconButton
        data-canny-changelog
        onClick={() => {
          // eslint-disable-next-line deprecation/deprecation
          logEvent('select_whats_new_menu');
          loggingClient.logPap(
            PAP_Select_TopNavAction({
              deviceId: loggingClient.deviceId,
              actionElement: 'top_nav',
              actionSurface: actionSurface,
              topNavAction: 'dropdown_menu',
              actionDetail: 'whats_new_menu',
            }),
          );
        }}
        variant="transparent"
      >
        <UIIcon
          aria-label={intl.formatMessage({
            defaultMessage: "What's new in Replay?",
            id: 'gRE6wz',
            description: 'Title for pane showing new things released on Replay',
          })}
          src={GiftLine}
        />
      </IconButton>
    </ChangelogContainer>
  );
};
