import React from 'react';

import {useSetAtom} from 'jotai';
import {FormattedMessage} from 'react-intl';

import {UIIcon} from '@dropbox/dig-icons';
import {LinkLine, SendLine} from '@dropbox/dig-icons/assets';

import {Button} from '~/components/button';
import {useCopyFromBrowserUrl} from '~/components/share_modal/copy_link_utils';
import type {ShareLinkPermission} from '~/components/share_modal/share_modal_common';
import {useOnShareClick} from '~/components/share_modal/share_modal_common';
import {shareSnackbarStateAtom} from '~/components/share_modal/share_state';
import {useReelAppGlobalState} from '~/context';
import {toBooleanAttr} from '~/lib/logging/logger_types';
import {useOnboardingChecklist} from '~/lib/onboarding_v2/use_onboarding_checklist';
import {type FolderShareProps, useFolderShareOnClick} from '~/lib/use_folder_share_onclick';
import {useIsCurrentPageShareFolderPage} from '~/route_path_matches';
import {STEPS} from '~/state/onboarding_v2';

export const ShareFolderButton = (props: FolderShareProps) => {
  const {buttonVariant = 'outline', folderId, isViewOnlyLinkAccess} = props;
  const {onShareClick, getExistingLinks} = useFolderShareOnClick(props);

  const isLoggedIn = useReelAppGlobalState().status === 'logged in';
  const isSharePage = useIsCurrentPageShareFolderPage();
  const {handleCheckIsCurrentStepOpen, handleCompleteStep} = useOnboardingChecklist({
    logEvent: props.logEvent,
  });

  const {existingLinksPromise, refetchLinks} = useOnShareClick({
    getExistingLinks,
    mediaId: folderId,
  });
  const {copyFromBrowserUrl} = useCopyFromBrowserUrl();

  const setShareErrorSnackbar = useSetAtom(shareSnackbarStateAtom);
  const handleShareClick = async (perm: ShareLinkPermission) => {
    const existingLinks = await existingLinksPromise;

    if (existingLinks != null && existingLinks !== 'unfetched') {
      onShareClick(existingLinks, perm);
    } else {
      setShareErrorSnackbar({type: 'link-fetch-failed'});
    }
  };

  if (isLoggedIn && !isSharePage) {
    return (
      <Button
        disabled={existingLinksPromise === 'unfetched'}
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          handleShareClick('review');
          e.preventDefault();
        }}
        onMouseEnter={refetchLinks}
        variant={buttonVariant}
        withIconLeft={<UIIcon src={SendLine} />}
      >
        <FormattedMessage
          defaultMessage="Share"
          description="Button that opens a modal to allow sharing to team members"
          id="zpl64y"
        />
      </Button>
    );
  }

  return (
    <Button
      onClick={() => {
        handleCompleteStep(STEPS.SHARE_FILE);

        // eslint-disable-next-line deprecation/deprecation
        props.logEvent('copy_reel_share_link', {
          access_type: isViewOnlyLinkAccess ? 'view_only' : 'review',
          link_item_type: 'folder',
          is_onboarding_share_step: toBooleanAttr(handleCheckIsCurrentStepOpen(STEPS.SHARE_FILE)),
          replay_media_type: 'none',
          experiment_variant: 'none',
        });
        copyFromBrowserUrl(isViewOnlyLinkAccess);
      }}
      variant={buttonVariant}
      withIconStart={<UIIcon src={LinkLine} />}
    >
      <FormattedMessage
        defaultMessage="Copy link"
        description="Button that copies a link to the shared folder."
        id="ykAGQy"
      />
    </Button>
  );
};
