import {useIntl} from 'react-intl';
import type {IntlShape} from 'react-intl';
import styled from 'styled-components';

import {Text} from '@dropbox/dig-components/typography';
import {UIIcon} from '@dropbox/dig-icons';
import {AddLine, LockLine, WarningLine} from '@dropbox/dig-icons/assets';

import {Button} from '~/components/button';
import {
  DueDateTimeframe,
  getDueDateTimeframe,
  getHoursToDueDate,
} from '~/components/due_date/due_date_utils';
import {useViewport} from '~/components/viewport_context';

import {useReelAppGlobalState} from '../../context';
import {useReelProvisioningEnabled} from '../../lib/utils';

const WrappedIcon = styled.div`
  display: flex;
  margin-right: calc(var(--spacing__base_unit) / 2);
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const getDueDateText = (intl: IntlShape, dueDate?: Date) => {
  if (!dueDate) {
    return intl.formatMessage({
      defaultMessage: 'Add due date',
      id: '3nAS6X',
      description: 'Button that allows a user to set a due date for feedback on a file.',
    });
  }

  const dueDateTimeframe = getDueDateTimeframe(dueDate);
  const dueDateTime = intl.formatTime(dueDate.getTime(), {hour: 'numeric'});
  const hoursToDueDate = getHoursToDueDate(dueDate);

  switch (dueDateTimeframe) {
    case DueDateTimeframe.OVERDUE_MORE_THAN_2_DAYS:
    case DueDateTimeframe.OVERDUE_LESS_THAN_2_DAYS:
      return intl.formatMessage({
        defaultMessage: 'Feedback is overdue',
        id: 'K4TL6i',
        description: 'Text that is shown when the due date to give feedback has passed.',
      });
    case DueDateTimeframe.WITHIN_5_HOURS:
      return intl.formatMessage(
        {
          defaultMessage: 'Feedback due in {hours} {hours, plural, one{hour} other{hours}}',
          id: 'X0450A',
          description: 'Text that is shown when the due date is within 5 hours.',
        },
        {
          hours: hoursToDueDate,
        },
      );
    case DueDateTimeframe.TODAY:
      return intl.formatMessage(
        {
          defaultMessage: 'Feedback due today by {time}',
          id: 'OWMLGT',
          description: 'Text that is shown when the due date to give feedback is today.',
        },
        {
          time: dueDateTime,
        },
      );
    case DueDateTimeframe.TOMORROW:
      return intl.formatMessage(
        {
          defaultMessage: 'Feedback due tomorrow by {time}',
          id: 'PobimB',
          description: 'Text that is shown when the due date to give feedback is tomorrow.',
        },
        {
          time: dueDateTime,
        },
      );
    case DueDateTimeframe.FUTURE:
      const dueDateDay = intl.formatDate(dueDate, {month: 'short', day: 'numeric'});
      return intl.formatMessage(
        {
          defaultMessage: 'Feedback due on {date}',
          id: 'EJhgME',
          description: 'Text that shows what day feedback on a file is due.',
        },
        {
          date: dueDateDay,
        },
      );
  }
};

const getMobileDueDateText = (intl: IntlShape, dueDate: Date) => {
  if (!dueDate) {
    return '';
  }

  const formattedDueDate = intl.formatDate(dueDate, {
    month: 'short',
    day: 'numeric',
  });

  const dueDateText = intl.formatMessage(
    {
      defaultMessage: '(Feedback is due by {date} {emoji})',
      id: 'qWp35N',
      description: 'Text displaying the due date for feedback on a file on small screens.',
    },
    {
      date: formattedDueDate,
      emoji: '🗓️',
    },
  );
  return dueDateText;
};

type DueDateProps = {
  editable: Boolean;
  isLoading: boolean;
  dueDate?: Date;
  isImage?: boolean;
  onClick?: () => void;
};

export const DueDate = (props: DueDateProps) => {
  const {editable, isLoading, dueDate, isImage, onClick} = props;
  const intl = useIntl();
  // eslint-disable-next-line deprecation/deprecation
  const {isSmallScreenSize, isMobileDevice} = useViewport();
  const showUpsellModal = !isMobileDevice;
  const sessionContext = useReelAppGlobalState();
  const isProvisioningEnabled = useReelProvisioningEnabled();
  const isDueDateProvisioned =
    !isProvisioningEnabled ||
    (sessionContext.status === 'logged in' && sessionContext.provisions.deadlines_and_tasks);

  if (isLoading || (!editable && !dueDate)) {
    return null;
  }

  if (isSmallScreenSize && !editable && dueDate) {
    const smallScreenText = getMobileDueDateText(intl, dueDate);
    return <Text>{smallScreenText}</Text>;
  }

  const dueDateText = getDueDateText(intl, dueDate);
  const dueDateTimeframe = dueDate ? getDueDateTimeframe(dueDate) : null;
  const showWarningIcon =
    dueDateTimeframe === DueDateTimeframe.OVERDUE_MORE_THAN_2_DAYS ||
    dueDateTimeframe === DueDateTimeframe.OVERDUE_LESS_THAN_2_DAYS ||
    dueDateTimeframe === DueDateTimeframe.WITHIN_5_HOURS;
  const textIcon = showWarningIcon ? <UIIcon role="presentation" src={WarningLine} /> : null;
  if (!editable && dueDate) {
    const text = (
      <Text style={{display: 'flex'}}>
        <WrappedIcon>{textIcon}</WrappedIcon>
        {dueDateText}
      </Text>
    );
    if (isImage) {
      return (
        <div style={{background: 'var(--dig-color__background__raised)', padding: '5px 6px'}}>
          {text}
        </div>
      );
    }
    return text;
  }

  const buttonIcon = !dueDate ? <UIIcon role="presentation" src={AddLine} /> : textIcon;

  if (onClick) {
    return (
      <ButtonWrapper>
        <Button
          data-safe-to-unmask-name="due-date-button"
          disabled={!showUpsellModal && !isDueDateProvisioned}
          onClick={onClick}
          variant={isImage ? 'opacity' : 'transparent'}
          withIconLeft={buttonIcon}
        >
          {dueDateText}
          {!showUpsellModal && !isDueDateProvisioned && (
            <LockLine color="white" style={{marginLeft: '4px'}} width={20} />
          )}
        </Button>
      </ButtonWrapper>
    );
  }
  return null;
};
