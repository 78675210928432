import type {reel} from '@dropbox/api-v2-client';

import type {Folder} from '~/lib/api';
import type {Branding} from '~/lib/branding';
import type {BrowseFolder} from '~/pages/browse_page/components/common';

export const SortAttributes = ['name', 'updated', 'created'] as const;
export type SortAttr = (typeof SortAttributes)[number];

export const SortDirections = ['descending', 'ascending'] as const;
export type SortDirection = (typeof SortDirections)[number];

export type Sort = {
  direction: SortDirection;
  attribute: SortAttr;
};

export const ViewTypes = ['list', 'tile'] as const;
export type ViewType = (typeof ViewTypes)[number];

export const DndItemTypes = {
  ProjectTile: 'ProjectTile' as const,
  FolderTile: 'FolderTile' as const,
  BulkTiles: 'BulkTiles' as const,
};

export type DndItemType = keyof typeof DndItemTypes;

export type ProjectOrFolderItems =
  | {itemType: typeof DndItemTypes.ProjectTile; projectId: string; projectName: string}
  | {itemType: typeof DndItemTypes.FolderTile; folderId: string; folderName: string}
  | {itemType: typeof DndItemTypes.BulkTiles; folderIds: string[]; projectIds: string[]};

export type DndProjectTile = {
  projectId: string;
  projectName: string;
};

export type DndFolderTile = {
  folderId: string;
  folderName: string;
};

export type DndBulkTiles = {
  folderIds: string[];
  projectIds: string[];
};

export type LeftRailStatus =
  | {
      status: 'closed';
    }
  | {
      status: 'move';
      items: ProjectOrFolderItems;
    }
  | {
      status: 'copy';
      items: ProjectOrFolderItems;
    };

export enum FilterType {
  STATUS = 'status',
  MEDIA_TYPE = 'media_type',
  ADMIN_FOLDER_TYPE = 'admin_folder_type',
}

export type StatusFilterState = {
  includeNoStatus: boolean;
  includeNeedsReview: boolean;
  includeInProgress: boolean;
  includeEditsRequested: boolean;
  includeApproved: boolean;
};

type BaseMediaTypeFilterState = {
  includeAudio: boolean;
  includeVideo: boolean;
  includeImages: boolean;
  includeProjects: boolean;
};

type OptionalMediaTypeFilterState = {
  includePsd: boolean;
  includePdf: boolean;
};

export type MediaTypeFilterState = BaseMediaTypeFilterState & Partial<OptionalMediaTypeFilterState>;

export type AdminTypeFilterState = {
  includeTeamFolders: boolean;
  includeUserRootProjects: boolean;
};

export type FilterStates = StatusFilterState | MediaTypeFilterState | AdminTypeFilterState;

export type FilterState = {
  statusFilterState: StatusFilterState;
  mediaTypeFilterState: MediaTypeFilterState;
  adminTypeFilterState: AdminTypeFilterState;
};

export const FILTER_NONE_STATUS: StatusFilterState = {
  includeNoStatus: false,
  includeNeedsReview: false,
  includeInProgress: false,
  includeEditsRequested: false,
  includeApproved: false,
};

export const FILTER_ALL_STATUS: StatusFilterState = {
  includeNoStatus: true,
  includeNeedsReview: true,
  includeInProgress: true,
  includeEditsRequested: true,
  includeApproved: true,
};

export const ALL_MEDIA_TYPE_FILTER_KEYS: string[] = [
  'includeAudio',
  'includeVideo',
  'includeImages',
  'includeProjects',
  'includePdf',
  'includePsd',
];

export const FILTER_NONE_MEDIA_TYPE: MediaTypeFilterState = {
  includeAudio: false,
  includeImages: false,
  includeProjects: false,
  includeVideo: false,
};

export const FILTER_ALL_MEDIA_TYPE: MediaTypeFilterState = {
  includeAudio: true,
  includeImages: true,
  includeProjects: true,
  includeVideo: true,
};

export const FILTER_NONE_ADMIN_FOLDER_TYPE: AdminTypeFilterState = {
  includeTeamFolders: false,
  includeUserRootProjects: false,
};

export const FILTER_ALL_ADMIN_FOLDER_TYPE: AdminTypeFilterState = {
  includeTeamFolders: true,
  includeUserRootProjects: true,
};

export const FILTER_NONE_FILTER_STATE: FilterState = {
  statusFilterState: FILTER_NONE_STATUS,
  mediaTypeFilterState: FILTER_NONE_MEDIA_TYPE,
  adminTypeFilterState: FILTER_NONE_ADMIN_FOLDER_TYPE,
};

export type PremiumFeatureType =
  | 'custom_branding'
  | 'links_hub'
  | 'archive_to_dbx'
  | 'password_protected_links'
  | 'deadlines_and_tasks'
  | 'lossless_audio'
  | 'version_comparison'
  | 'watermark'
  | 'unlimited_file_uploads'
  | 'team_only_commenting';

export type FolderLink = {
  url: string;
  name: string;
  folder_link_id: string;
};

export type ProjectFolderInfo = {
  description: string;
  name: string;
  projectFolderId: string;
  branding: Branding;
  folderLinks: reel.FolderLink[];
};

export function instanceOfRecipientIdUnionAccountId(
  obj?: reel.recipient_id_union,
): obj is reel.recipient_id_unionAccountId {
  return obj?.['.tag'] === 'account_id';
}

export function instanceOfRecipientIdUnionGroupId(
  obj?: reel.recipient_id_union,
): obj is reel.recipient_id_unionGroupId {
  return obj?.['.tag'] === 'group_id';
}

export function instanceOfFolder(obj?: BrowseFolder): obj is Folder {
  return !!obj && !obj.isUploading && (obj as Folder).id !== undefined;
}
