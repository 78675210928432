import React, {type ReactNode} from 'react';

import {useAtomValue, useSetAtom} from 'jotai';
import type {DropTargetMonitor} from 'react-dnd';
import {useDrop} from 'react-dnd';
import {NativeTypes} from 'react-dnd-html5-backend';
import {defineMessages, FormattedMessage, useIntl} from 'react-intl';
import {useNavigate} from 'react-router';
import styled, {css} from 'styled-components';

import {Spinner} from '@dropbox/dig-components/progress_indicators';
import {Snackbar} from '@dropbox/dig-components/snackbar';
import {Tooltip} from '@dropbox/dig-components/tooltips';
import {Text} from '@dropbox/dig-components/typography';
import {Box} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {AddLine, UploadLine} from '@dropbox/dig-icons/assets';

import {Button} from '~/components/button';
import {EXCLUDED_FILES, MAX_FOLDER_DEPTH} from '~/components/common';
import {InvalidExtensionModal} from '~/components/invalid_extensions_modal';
import {MaxDepthExceededModal} from '~/components/max_depth_exceeded_modal';
import {PaywallModal} from '~/components/paywall_modal';
import {ReelSnackbar} from '~/components/snackbar';
import {useErrorSnackbar} from '~/components/snackbar/error_snackbar';
import {useUpload} from '~/components/upload_context';
import {getExtension, getMediaType, useValidExtensions} from '~/lib/helpers';
import type {BasicFileInfo, DirectUpload, StreamableFile} from '~/lib/uploads/types';
import {UPLOAD_IN_PROGRESS_STATES} from '~/lib/uploads/types';
import {getFolderDepthFromPaths, useIsTeamLocked, useReelProvisioningEnabled} from '~/lib/utils';
import {useBrowseItemsViewContext} from '~/pages/browse_page/browse_items_view_context';
import {useBrowseLogEventMaker} from '~/pages/browse_page/browse_logging_context';
import type {BrowseUploadedFolderProps} from '~/pages/browse_page/components/common';
import {isValidNewFolder, moveItem} from '~/pages/browse_page/components/drag_and_drop_utils';
import {TILE_FOOTER_MIN_HEIGHT} from '~/pages/browse_page/components/tiles/tile';
import {useFoldersSharedState} from '~/pages/browse_page/folders_context';
import {DndItemTypes, type ProjectOrFolderItems} from '~/pages/browse_page/types';

import {useDropboxChooser} from './dropbox_chooser_context';
import {
  dueDateModalCurrentVersionInfoAtom,
  dueDateVersionModalAtom,
} from './due_date/due_date_modal';
import {color, radius} from './styled';
import {useViewport} from './viewport_context';
import {getTasksForItem} from '../lib/api';
import type {MediaSourceType} from '../lib/logging/logger_types';
import {
  useExceededFileQuota,
  useGetFileQuotaText,
  useGetQuotaExceededErrorText,
  useGetRemainingFileQuota,
} from '../lib/provisions';
import {
  isTileDropAtom,
  noItemsSelectedAtom,
  selectedFolderIdsAtom,
  selectedProjectIdsAtom,
} from '../state/browse';
import {currentTaskStateAtom} from '../state/task';

type DropAreaProps = {
  onDrop: (files: BasicFileInfo[], folderUpload?: boolean) => void;
  onFileUploadComplete?: (upload: DirectUpload, videoId: string) => void;
  onAllUploadsComplete?: (
    files: (File | StreamableFile)[],
    folderId: string,
    isFolder: boolean,
  ) => void;
  onCanDropChange?: (canDrop: boolean) => void;
  isProjectEmpty?: boolean;
  folderId?: string;
  isEnabled: Boolean;
};

type TileDropAreaProps = {
  onCanDropChange?: (canDrop: boolean) => void;
  folderId?: string;
  projectId?: string;
  accessLevel?: string;
  videoId?: string;
  name?: string;
  versionNumber?: number;
  videoNsId?: number;
  ownerUid?: string;
  isEnabled: boolean;
  listView?: boolean;
  role?: string;
  ['aria-busy']?: boolean;
  ['data-test-id']?: string;
  onVersionSelected?: (
    fileId: string,
    fileIdType: 'file_id' | 'file_path',
    fileSource: MediaSourceType,
    needsCopy: boolean,
  ) => Promise<{projectId?: string; videoId?: string; videoVersionId?: string}>;
};

type MonitorGetItemResult = {items: DataTransferItem[]};

const Z_INDEX_BASE = 10001;

const DropAreaBorder = styled.div`
  &::before {
    border: 4px dashed ${color('Primary Base')};
    bottom: 0;
    content: '';
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    z-index: ${Z_INDEX_BASE};
  }
`;

interface TileDropAreaBackgroundProps {
  $isListView?: boolean;
  $isFolder?: boolean;
}

const TileDropAreaBackground = styled.div<TileDropAreaBackgroundProps>`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: ${Z_INDEX_BASE};

  &::before {
    content: '';
    z-index: ${Z_INDEX_BASE};
    pointer-events: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: ${color('Primary Base')};
    opacity: ${({$isListView}) => ($isListView ? 0.9 : 0.75)};
    border-radius: ${radius('Medium')};
  }

  &::after {
    z-index: ${Z_INDEX_BASE};
    pointer-events: none;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    background: ${color('Primary Base')};
    ${({$isListView, $isFolder}) =>
      !$isListView &&
      css`
        content: '';
        // Matching fixed heights from the tile designs
        height: ${$isFolder ? TILE_FOOTER_MIN_HEIGHT : '96px'};
        border-radius: 0 0 ${radius('Medium')} ${radius('Medium')};
      `};
  }
`;

const Centered = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index: ${Z_INDEX_BASE};
`;

const DropText = styled.div<TileDropAreaBackgroundProps>`
  position: relative;
  z-index: ${Z_INDEX_BASE + 2};
  color: ${color('Text Base')};

  display: flex;
  align-items: center;
  height: 100%;

  ${({$isListView}) =>
    $isListView
      ? css`
          flex-direction: row;
          justify-content: center;
        `
      : css`
          flex-direction: column;
          justify-content: flex-end;
        `};
`;

const DropIcon = styled.div`
  display: flex;
`;

export const DropArea = ({
  children,
  onDrop,
  onFileUploadComplete = () => {},
  onAllUploadsComplete = () => {},
  onCanDropChange = () => {},
  isProjectEmpty,
  isEnabled,
}: React.PropsWithChildren<DropAreaProps>) => {
  const makeLogEvent = useBrowseLogEventMaker();
  const {uploadProject, uploadState: directUploadState} = useUpload();
  const {uploadState: chooserUploadState} = useDropboxChooser();
  const {openErrorSnackbar} = useErrorSnackbar();
  const {currentFolderId, breadcrumbPath, currentFolderPermission} = useFoldersSharedState();
  const {isMobileDevice} = useViewport();
  const showUpsellModal = !isMobileDevice;
  const [extensionModalOpen, setExtensionModalOpen] = React.useState(false);
  const [pendingFiles, setPendingFiles] = React.useState<File[]>([]);
  const [invalidExtensions, setInvalidExtensions] = React.useState<string[]>([]);
  const [maxDepthErrorModalOpen, setMaxDepthErrorModalOpen] = React.useState(false);
  const [paywallModalOpen, setPaywallModalOpen] = React.useState(false);
  const exceededFileQuota = useExceededFileQuota();
  const provisionsEnabled = useReelProvisioningEnabled();
  const isTeamLocked = useIsTeamLocked();

  const logEvent = React.useMemo(() => makeLogEvent(null), [makeLogEvent]);

  const isDirectUploading = UPLOAD_IN_PROGRESS_STATES.includes(directUploadState);
  const isChooserUploading = UPLOAD_IN_PROGRESS_STATES.includes(chooserUploadState);
  const isUploading = isDirectUploading || isChooserUploading;
  const intl = useIntl();
  const isTileDrop = useAtomValue(isTileDropAtom);
  const validExtensionsList = useValidExtensions();

  // Only show valid files as dropped
  const handleUploadStart = React.useCallback(
    (uploads: DirectUpload[]) => {
      onDrop(
        uploads
          .filter(({canceled}) => !canceled)
          .map((upload) => {
            return {
              ...upload.file,
              name: upload.file.name,
              relativePath: upload.file.relativePath || upload.file.webkitRelativePath,
              fileSize: upload.file.size,
            };
          }),
      );
    },
    [onDrop],
  );

  const promisifyWebkitFn = async (file: any, fn: string): Promise<any> => {
    return await new Promise((resolve, reject) => {
      file[fn](resolve, reject);
    });
  };

  const traverseDirectory = async (
    items: FileSystemEntry | FileSystemDirectoryReader | null,
    inFolder: boolean,
  ): Promise<
    (File & {
      relativePath?: string;
      parentDirectory?: string;
      folderUpload?: boolean;
    })[]
  > => {
    const result = [];
    const entries = inFolder ? await promisifyWebkitFn(items, 'readEntries') : [items];
    for (const file of [...entries]) {
      if (file.isDirectory) {
        result.push(...(await traverseDirectory(file.createReader(), true)));
      } else {
        const item = await promisifyWebkitFn(file, 'file');
        item.relativePath = file.fullPath;
        item.parentDirectory = item.relativePath.split('/')[1];
        item.folderUpload = inFolder;
        result.push(item);
      }
    }
    return result;
  };

  const [{canDrop}, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      async drop({files}: {files: File[]}, monitor) {
        // If another drop target handled this file, let's ignore it.
        if (monitor.didDrop()) {
          return;
        }

        if (showUpsellModal && provisionsEnabled && exceededFileQuota) {
          setPaywallModalOpen(true);
          return;
        }

        if (currentFolderPermission === 'reviewer') {
          openErrorSnackbar(intl.formatMessage(dropAreaStrings.viewOnlyFolderError));
          return;
        }

        if (isTeamLocked) {
          openErrorSnackbar(intl.formatMessage(dropAreaStrings.teamLockedError));
          return;
        }

        if (isUploading) {
          openErrorSnackbar(intl.formatMessage(dropAreaStrings.uploadInProgressError));
          return;
        }

        const hasFolder = files.some(
          (file) => file.type === '' && !getMediaType(getExtension(file.name)),
        );

        let result = [];
        const monitorGetItemResult = monitor.getItem() as MonitorGetItemResult;
        const fileSystemEntries = [...monitorGetItemResult.items]
          .map((item) => item.webkitGetAsEntry())
          .filter((item) => item); // Ensure the item is non-null

        for (const item of fileSystemEntries) {
          const files = await traverseDirectory(item, false);
          result.push(...files);
        }

        result = result.filter((file) => !EXCLUDED_FILES.includes(file.name));

        const folderDepth = getFolderDepthFromPaths(
          result.map((file) => file.relativePath || file.webkitRelativePath),
        );
        const currentFolderDepth = breadcrumbPath ? breadcrumbPath.length : 1;

        if (folderDepth + currentFolderDepth > MAX_FOLDER_DEPTH) {
          // eslint-disable-next-line deprecation/deprecation
          logEvent('shown_max_depth_modal', {
            click_source: 'drag_and_drop',
            media_source: 'direct_upload',
            upload_source: 'folder',
          });
          setMaxDepthErrorModalOpen(true);
          return;
        }

        // Need to check that type is null and file is not a valid extension because some valid extensions come back with a blank type
        const allInvalidExtensions = result
          .filter((file) => !validExtensionsList.includes(getExtension(file.name)))
          .map((file) => getExtension(file.name));

        if (allInvalidExtensions.length === result.length) {
          const errorMessage =
            result.length > 1
              ? intl.formatMessage(dropAreaStrings.multipleFileExtensionError)
              : intl.formatMessage(dropAreaStrings.singleFileExtensionError);
          openErrorSnackbar(errorMessage);
          return;
        } else if (allInvalidExtensions.length) {
          setInvalidExtensions([...new Set(allInvalidExtensions)]);
          setExtensionModalOpen(true);
          setPendingFiles(result);
          return;
        }

        // eslint-disable-next-line deprecation/deprecation
        logEvent('select_add_reel_media', {
          click_source: 'drag_and_drop',
          media_source: 'direct_upload',
          upload_source: hasFolder ? 'folder' : 'file',
        });

        uploadProject({
          files: result,
          currentFolderId,
          onUploadStart: handleUploadStart,
          onFileUploadComplete,
          onAllUploadsComplete,
          clickSource: 'drag_and_drop',
          folderUpload: hasFolder,
        });
      },
      collect: (monitor: DropTargetMonitor) => ({
        canDrop: monitor.isOver({shallow: true}),
      }),
    }),
    [uploadProject, currentFolderId, isUploading, onAllUploadsComplete],
  );

  React.useEffect(() => {
    onCanDropChange(canDrop);
  }, [canDrop, onCanDropChange]);

  const onModalExtensionClose = React.useCallback(
    (cancelled: boolean) => {
      if (isTileDrop) {
        return;
      }

      if (!cancelled) {
        const hasFolder = pendingFiles.some(
          (file) => file.type === '' && !getMediaType(getExtension(file.name)),
        );
        // eslint-disable-next-line deprecation/deprecation
        logEvent('select_add_reel_media', {
          click_source: 'drag_and_drop',
          media_source: 'direct_upload',
          upload_source: hasFolder ? 'folder' : 'file',
        });
        uploadProject({
          files: pendingFiles,
          currentFolderId,
          onUploadStart: handleUploadStart,
          onFileUploadComplete,
          onAllUploadsComplete,
          clickSource: 'drag_and_drop',
          folderUpload: hasFolder,
        });
      }
      setPendingFiles([]);
      setExtensionModalOpen(false);
    },
    [
      isTileDrop,
      pendingFiles,
      logEvent,
      uploadProject,
      currentFolderId,
      handleUploadStart,
      onFileUploadComplete,
      onAllUploadsComplete,
    ],
  );

  const dragAndDropHoverText = intl.formatMessage({
    defaultMessage: 'Drop your files to upload to Dropbox Replay.',
    id: 'Fyvp91',
    description:
      'text in the snackbar explains that uploads start when a file is dropped in the designated area.',
  });

  if (!isEnabled) {
    return <>{children}</>;
  }

  return (
    <div ref={drop} style={{flex: 1}}>
      {canDrop && !isProjectEmpty && (
        <>
          <DropAreaBorder />
          <ReelSnackbar open={true} style={{width: 325}}>
            <Snackbar.Message>
              <Text
                color="standard"
                style={{
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <UIIcon src={UploadLine} />
                {dragAndDropHoverText}
              </Text>
            </Snackbar.Message>
          </ReelSnackbar>
        </>
      )}
      {children}
      <MaxDepthExceededModal open={maxDepthErrorModalOpen} setOpen={setMaxDepthErrorModalOpen} />
      <InvalidExtensionModal
        invalidExtensions={invalidExtensions}
        open={extensionModalOpen}
        requestClose={onModalExtensionClose}
      ></InvalidExtensionModal>
      {showUpsellModal && (
        <PaywallModal
          clickSource="drop_area"
          onClose={() => {
            setPaywallModalOpen(false);
          }}
          open={paywallModalOpen}
        />
      )}
    </div>
  );
};

interface TileDropOverlayProps {
  isListView?: boolean;
  isFolder?: boolean;
  children: ReactNode;
}

export const TileDropOverlay = ({isListView, isFolder, children}: TileDropOverlayProps) => {
  return (
    <TileDropAreaBackground $isFolder={isFolder} $isListView={isListView}>
      <DropText $isFolder={isFolder} $isListView={isListView}>
        <DropIcon>
          <UIIcon src={AddLine} />
        </DropIcon>
        <Box paddingBottom={isListView ? '0' : 'Micro Medium'}>
          <Text isBold size="large">
            {children}
          </Text>
        </Box>
      </DropText>
    </TileDropAreaBackground>
  );
};

export const useTileDropArea = ({
  children,
  onCanDropChange = () => {},
  folderId,
  accessLevel,
  videoId,
  name,
  versionNumber,
  videoNsId,
  ownerUid,
  onVersionSelected,
  projectId,
  isEnabled,
  listView,
}: React.PropsWithChildren<TileDropAreaProps>) => {
  const makeLogEvent = useBrowseLogEventMaker();
  const {uploadProject, uploadState: directUploadState, uploadVersion} = useUpload();
  const {uploadState: chooserUploadState} = useDropboxChooser();
  const {breadcrumbPath, currentFolderPermission} = useFoldersSharedState();
  const [extensionModalOpen, setExtensionModalOpen] = React.useState(false);
  const [pendingFiles, setPendingFiles] = React.useState<File[]>([]);
  const [invalidExtensions, setInvalidExtensions] = React.useState<string[]>([]);
  const [maxDepthErrorModalOpen, setMaxDepthErrorModalOpen] = React.useState(false);
  const exceededFileQuota = useExceededFileQuota();
  const fileQuotaText = useGetFileQuotaText();
  const provisionsEnabled = useReelProvisioningEnabled();
  const {openErrorSnackbar} = useErrorSnackbar();

  const logEvent = React.useMemo(() => makeLogEvent(null), [makeLogEvent]);

  const isDirectUploading = UPLOAD_IN_PROGRESS_STATES.includes(directUploadState);
  const isChooserUploading = UPLOAD_IN_PROGRESS_STATES.includes(chooserUploadState);
  const isUploading = isDirectUploading || isChooserUploading;
  const fileQuotaExceededError = useGetQuotaExceededErrorText();
  const availableFileQuota = useGetRemainingFileQuota();
  const setIsTileDrop = useSetAtom(isTileDropAtom);
  const [currentTileIsLoading, setCurrentTileIsLoading] = React.useState<boolean>();
  const validExtensionsList = useValidExtensions();
  const isTeamLocked = useIsTeamLocked();

  const setDateModalInfo = useSetAtom(dueDateModalCurrentVersionInfoAtom);
  const setShowDueDateVersionModal = useSetAtom(dueDateVersionModalAtom);
  const setTaskState = useSetAtom(currentTaskStateAtom);
  const navigate = useNavigate();
  const intl = useIntl();

  // Only show valid files as dropped
  const handleUploadStart = React.useCallback((uploads: DirectUpload[]) => {
    uploads
      .filter(({canceled}) => !canceled)
      .map((upload) => {
        return {
          ...upload.file,
          name: upload.file.name,
          relativePath: upload.file.relativePath || upload.file.webkitRelativePath,
        };
      });
  }, []);

  const goTofolder = React.useCallback(() => {
    const newUrl = `/folder/${folderId}`;
    navigate(newUrl);
  }, [folderId, navigate]);

  const goToNewVersion = React.useCallback(() => {
    const newUrl = `/project/${projectId}/video/${videoId}`;
    navigate(newUrl);
  }, [navigate, projectId, videoId]);

  const handleAllFilesUploadedSuccess = React.useCallback(() => {
    setCurrentTileIsLoading(false);
    setIsTileDrop(false);
    goTofolder();
  }, [goTofolder, setIsTileDrop]);

  const handleDueDateVersionModal = React.useCallback(async () => {
    if (!videoId) {
      return;
    }
    const updated_task = await getTasksForItem(videoId);
    const async_task_state = {
      isLoading: false,
      videoId: videoId,
      task: updated_task,
    };
    setTaskState(async_task_state);
    if (async_task_state.task?.dueDate && name) {
      setDateModalInfo({
        videoId: videoId,
        projectName: name,
        versionNumber: versionNumber,
        projectId: projectId,
      });
      setShowDueDateVersionModal(true);
    } else {
      goToNewVersion();
    }
  }, [
    videoId,
    setTaskState,
    name,
    setDateModalInfo,
    versionNumber,
    projectId,
    setShowDueDateVersionModal,
    goToNewVersion,
  ]);

  const handleFileDrop = React.useCallback(
    async (
      files: FileList | File[],
      onFileUploadComplete?: (upload: DirectUpload, fileId: string, videoVersionId: string) => void,
    ) => {
      if (videoNsId && files.length === 1 && onVersionSelected) {
        const [file] = files;
        uploadVersion({
          onCreateVersion: (fileId: string) => {
            return onVersionSelected(fileId, 'file_id', 'direct_upload', false);
          },
          file,
          nsId: videoNsId,
          ownerUid,
          onFileUploadComplete,
          clickSource: 'drag_and_drop',
        });
      }
    },
    [onVersionSelected, uploadVersion, videoNsId, ownerUid],
  );

  const promisifyWebkitFn = async (file: any, fn: string): Promise<any> => {
    return await new Promise((resolve, reject) => {
      file[fn](resolve, reject);
    });
  };

  const traverseDirectory = async (
    items: FileSystemEntry | FileSystemDirectoryReader | null,
    inFolder: boolean,
  ): Promise<
    (File & {
      relativePath?: string;
      parentDirectory?: string;
      folderUpload?: boolean;
    })[]
  > => {
    const result = [];
    const entries = inFolder ? await promisifyWebkitFn(items, 'readEntries') : [items];
    for (const file of [...entries]) {
      if (file.isDirectory) {
        result.push(...(await traverseDirectory(file.createReader(), true)));
      } else {
        const item = await promisifyWebkitFn(file, 'file');
        item.relativePath = file.fullPath;
        item.parentDirectory = item.relativePath.split('/')[1];
        item.folderUpload = inFolder;
        result.push(item);
      }
    }
    return result;
  };

  const [{canDrop, onHover}, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      async drop({files}: {files: File[]}, monitor) {
        if (provisionsEnabled && exceededFileQuota) {
          openErrorSnackbar(fileQuotaText);
          return;
        }
        if (currentFolderPermission === 'reviewer') {
          openErrorSnackbar(intl.formatMessage(dropAreaStrings.viewOnlyFolderError));
          return;
        }
        if (isTeamLocked) {
          openErrorSnackbar(intl.formatMessage(dropAreaStrings.teamLockedError));
          return;
        }
        if (isUploading) {
          openErrorSnackbar(intl.formatMessage(dropAreaStrings.uploadInProgressError));
          return;
        }

        setIsTileDrop(true);

        const hasFolder = files.some(
          (file) => file.type === '' && !getMediaType(getExtension(file.name)),
        );

        let result = [];
        const monitorGetItemResult = monitor.getItem() as MonitorGetItemResult;
        const fileSystemEntries = [...monitorGetItemResult.items]
          .map((item) => item.webkitGetAsEntry())
          .filter((item) => item); // Ensure the item is non-null

        for (const item of fileSystemEntries) {
          const files = await traverseDirectory(item, false);
          result.push(...files);
        }

        result = result.filter((file) => !EXCLUDED_FILES.includes(file.name));
        if (provisionsEnabled && availableFileQuota && result.length > availableFileQuota) {
          openErrorSnackbar(fileQuotaExceededError);
          return;
        }

        const folderDepth = getFolderDepthFromPaths(
          result.map((file) => file.relativePath || file.webkitRelativePath),
        );
        const currentFolderDepth = breadcrumbPath ? breadcrumbPath.length : 1;

        if (folderDepth + currentFolderDepth > MAX_FOLDER_DEPTH) {
          // eslint-disable-next-line deprecation/deprecation
          logEvent('shown_max_depth_modal', {
            click_source: 'drag_and_drop',
            media_source: 'direct_upload',
            upload_source: 'folder',
          });
          setMaxDepthErrorModalOpen(true);
          return;
        }

        // Need to check that type is null and file is not a valid extension because some valid extensions come back with a blank type
        const allInvalidExtensions = result
          .filter((file) => !validExtensionsList.includes(getExtension(file.name)))
          .map((file) => getExtension(file.name));

        if (allInvalidExtensions.length === result.length) {
          const errorMessage =
            result.length > 1
              ? intl.formatMessage(dropAreaStrings.multipleFileExtensionError)
              : intl.formatMessage(dropAreaStrings.singleFileExtensionError);
          openErrorSnackbar(errorMessage);
          return;
        } else if (allInvalidExtensions.length) {
          setInvalidExtensions([...new Set(allInvalidExtensions)]);
          setExtensionModalOpen(true);
          setPendingFiles(result);
          return;
        }

        if (result.length === 1 && result[0].folderUpload && videoId) {
          openErrorSnackbar(intl.formatMessage(dropAreaStrings.folderAsVersionError));
          return;
        }

        // eslint-disable-next-line deprecation/deprecation
        logEvent('select_add_reel_media', {
          click_source: 'drag_and_drop',
          media_source: 'direct_upload',
          upload_source: hasFolder ? 'folder' : 'file',
        });

        if (result.length === 1 && !result[0].folderUpload && videoId) {
          setIsVersionsErrorTooltipOpen(false);
          handleFileDrop(result, (upload, videoId, videoVersionId) => {
            handleDueDateVersionModal();
          });
        } else if (result.length > 1 && !!videoId) {
          setIsVersionsErrorTooltipOpen(true);
        }

        if (folderId) {
          uploadProject({
            files: result,
            currentFolderId: folderId,
            onAllUploadsComplete: handleAllFilesUploadedSuccess,
            clickSource: 'drag_and_drop',
            folderUpload: hasFolder,
          });
          setCurrentTileIsLoading(true);
        }
      },
      collect: (monitor: DropTargetMonitor) => ({
        canDrop: monitor.canDrop(),
        onHover: monitor.isOver(),
      }),
    }),
    [uploadProject, folderId, isUploading, videoId, videoNsId],
  );

  React.useEffect(() => {
    onCanDropChange(canDrop);
  }, [canDrop, onCanDropChange]);

  const onModalExtensionClose = React.useCallback(
    (cancelled: boolean) => {
      if (!cancelled) {
        const hasFolder = pendingFiles.some(
          (file) => file.type === '' && !getMediaType(getExtension(file.name)),
        );
        // eslint-disable-next-line deprecation/deprecation
        logEvent('select_add_reel_media', {
          click_source: 'drag_and_drop',
          media_source: 'direct_upload',
          upload_source: hasFolder ? 'folder' : 'file',
        });

        if (folderId) {
          uploadProject({
            files: pendingFiles,
            currentFolderId: folderId,
            onUploadStart: handleUploadStart,
            onAllUploadsComplete: handleAllFilesUploadedSuccess,
            clickSource: 'drag_and_drop',
            folderUpload: hasFolder,
          });
        }
      }
      setPendingFiles([]);
      setExtensionModalOpen(false);
    },
    [
      pendingFiles,
      logEvent,
      uploadProject,
      folderId,
      handleUploadStart,
      handleAllFilesUploadedSuccess,
    ],
  );

  const dragAndDropHoverText = folderId
    ? intl.formatMessage({
        defaultMessage: 'Add to project',
        id: '3LQ0BO',
        description:
          'Text that informs user that the hovered file or folder can be added to project.',
      })
    : intl.formatMessage({
        defaultMessage: 'Add as a new version',
        id: '4CvxYx',
        description: 'Text that informs user that the hovered file or folder can be added.',
      });

  const [isVersionsErrorTooltipOpen, setIsVersionsErrorTooltipOpen] = React.useState(false);
  const tileRef = React.useRef(null);

  if (!isEnabled) {
    return [drop, children] as const;
  }

  const contents = (
    <>
      <Tooltip.Control
        auto
        open={isVersionsErrorTooltipOpen}
        placement="top"
        triggerRef={tileRef}
        variant="rich"
      >
        <Text>
          <FormattedMessage
            defaultMessage="You can’t upload more than one version at a time."
            description="Text informing user that they can't drop more than one file as a version"
            id="ElMH2A"
          />
        </Text>
        <Box display="flex" justifyContent="flex-end" width="100%">
          <Button
            onClick={() => setIsVersionsErrorTooltipOpen(false)}
            size="small"
            variant="outline"
          >
            <FormattedMessage
              defaultMessage="Got it"
              description="Text confirming that user understands they can only upload one version at a time"
              id="8CdJDN"
            />
          </Button>
        </Box>
      </Tooltip.Control>
      <div ref={tileRef}>
        {canDrop && onHover && (
          <TileDropOverlay isFolder={Boolean(folderId)} isListView={listView}>
            {dragAndDropHoverText}
          </TileDropOverlay>
        )}
      </div>

      {isUploading && currentTileIsLoading ? (
        <Centered>
          <Spinner />
        </Centered>
      ) : null}
      {children}
      <MaxDepthExceededModal open={maxDepthErrorModalOpen} setOpen={setMaxDepthErrorModalOpen} />
      <InvalidExtensionModal
        invalidExtensions={invalidExtensions}
        open={extensionModalOpen}
        requestClose={onModalExtensionClose}
      />
    </>
  );

  return [drop, contents] as const;
};

export const TileDropArea = (props: React.PropsWithChildren<TileDropAreaProps>) => {
  const [drop, contents] = useTileDropArea(props);

  return (
    <div ref={drop} role={props.role} style={{flex: 1}}>
      {contents}
    </div>
  );
};

export const useMoveItemDnd = (props: BrowseUploadedFolderProps) => {
  const {
    name,
    folderId,
    parentFolderId,
    onBulkMove,
    onProjectMove,
    onFolderMove,
    updateMoveInProgressSnackbar,
  } = props;
  const {editActionsProps} = useBrowseItemsViewContext();
  const noItemsSelected = useAtomValue(noItemsSelectedAtom);
  const selectedProjectIds = useAtomValue(selectedProjectIdsAtom);
  const selectedFoldersIds = useAtomValue(selectedFolderIdsAtom);

  return useDrop(
    () => ({
      accept: [DndItemTypes.ProjectTile, DndItemTypes.FolderTile],
      drop: (item: ProjectOrFolderItems, monitor) => {
        if (!editActionsProps.editActionsEnabled) {
          return;
        }
        let itemToMove;
        if (noItemsSelected) {
          itemToMove = item;
        } else {
          const folderIdsToMove = selectedFoldersIds;
          const projectIdsToMove = selectedProjectIds;

          if (
            item.itemType === DndItemTypes.FolderTile &&
            !folderIdsToMove.includes(item.folderId)
          ) {
            folderIdsToMove.push(item.folderId);
          } else if (
            item.itemType === DndItemTypes.ProjectTile &&
            !projectIdsToMove.includes(item.projectId)
          ) {
            projectIdsToMove.push(item.projectId);
          }

          itemToMove = {
            itemType: DndItemTypes.BulkTiles,
            folderIds: selectedFoldersIds,
            projectIds: selectedProjectIds,
          };
        }

        moveItem(
          itemToMove,
          parentFolderId!,
          folderId,
          name,
          onBulkMove,
          onProjectMove,
          onFolderMove,
          'grid_or_list',
          editActionsProps.folderTree,
          updateMoveInProgressSnackbar,
          editActionsProps.reelRootFolderId,
        );
      },
      collect: (monitor) => ({
        canDrop:
          monitor.canDrop() && isValidNewFolder(monitor.getItem(), parentFolderId!, folderId),
        onHover: monitor.isOver(),
      }),
    }),
    [folderId, name, onProjectMove, onFolderMove],
  );
};

const dropAreaStrings = defineMessages({
  viewOnlyFolderError: {
    defaultMessage: 'The current folder is view only and does not allow uploads.',
    description: 'Error message when a user tries to upload to a view only folder',
    id: '9FlC46',
  },
  uploadInProgressError: {
    defaultMessage: 'Please wait for previous upload to complete.',
    id: 'xgHlrq',
    description: 'Error message when a user tries to upload a file when there is one in progress',
  },
  multipleFileExtensionError: {
    defaultMessage: 'Files cannot be uploaded. All files have invalid extensions.',
    id: 'OPmOUP',
    description: 'Error message when a user tries to upload a file without a valid extension',
  },
  singleFileExtensionError: {
    defaultMessage: 'File cannot be uploaded. The file has an invalid extension.',
    id: 'JQyo+D',
    description: 'Error message when a user tries to upload a file without a valid extension',
  },
  folderAsVersionError: {
    defaultMessage:
      'Folders cannot be uploaded as versions. Please upload individual files separately.',
    id: '0OHnY5',
    description: 'Error message when a user tries to upload a folder to as a videi',
  },
  teamLockedError: {
    defaultMessage: "Can't upload files because team is disbanded.",
    description: 'Error message when a user tries to upload a file when the team is disbanded',
    id: 'K/5wrs',
  },
});
