export type GDriveView = {
  viewId: string; // see ViewId
  mimeTypes?: string;
  includeFolders: boolean;
  allowSelectFolders: boolean;
};

export const enum MessageFromParentType {
  OpenGdriveFilePicker = 'OpenGdriveFilePicker',
}

export type MessageFromParent = {
  eventType: MessageFromParentType.OpenGdriveFilePicker;
  accessToken: string;
  origin: string;
  views: GDriveView[];
  hideSwitchAccountButton?: boolean;
};

export const enum FilePickerAction {
  PICKED = 'PICKED',
  CANCELED = 'CANCELED',
}

export type FilePickerFileInfo = {
  name: string;
};

export type FilePickerResult =
  | {
      action: FilePickerAction.PICKED;
      fileIds: string[];
      filesInfo: Record<string, FilePickerFileInfo>;
    }
  | {action: FilePickerAction.CANCELED};

export const enum MessageToParentType {
  IframeLoaded = 'IframeLoaded',
  FilePickerResult = 'FilePickerResult',
  SignInWithDifferentAccount = 'SignInWithDifferentAccount',
  UnhandledError = 'UnhandledError',
  UnhandledRejection = 'UnhandledRejection',
}

export type MessageToParent =
  | {eventType: MessageToParentType.IframeLoaded}
  | {eventType: MessageToParentType.FilePickerResult; result: FilePickerResult}
  | {eventType: MessageToParentType.SignInWithDifferentAccount}
  | {eventType: MessageToParentType.UnhandledError; error: unknown}
  | {eventType: MessageToParentType.UnhandledRejection; error: unknown};
