import React from 'react';

import {useAtomValue, useSetAtom} from 'jotai';
import type {LinkItemType} from 'pap-events/enums/link_item_type';
import {PAP_Send_ReplayShareLinkEmail} from 'pap-events/share/send_replay_share_link_email';
import {FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';

import {Tooltip} from '@dropbox/dig-components/tooltips';
import {Text} from '@dropbox/dig-components/typography';
import {UIIcon} from '@dropbox/dig-icons';
import {SendLine} from '@dropbox/dig-icons/assets';

import {Button} from '~/components/button';
import {DropboxAccountTypeahead} from '~/components/dropbox_account_typeahead';
import {getRestrictedContentErrorTextFromType} from '~/components/restricted_content_messages';
import {useSendEmailTypeaheadStrings} from '~/components/share_modal/share_modal_text';
import {
  currentBasicLinkAtom,
  getShareItemAtom,
  getShareModalLoggingInfoAtom,
  isViewOnlyLink,
  shareModalContentsAtom,
  shareSnackbarStateAtom,
} from '~/components/share_modal/share_state';
import type {TeamContactInfo, UserContactInfo} from '~/lib/api';
import {sendShareLinkEmail} from '~/lib/api';
import {
  narrowToExperimentVariantType,
  narrowToReplayMediaType,
  stringToPAPReplayMediaType,
} from '~/lib/logging/log_utils';
import {toBooleanAttr} from '~/lib/logging/logger_types';
import {useOnboardingChecklist} from '~/lib/onboarding_v2/use_onboarding_checklist';
import {useLoggingClient} from '~/lib/use_logging_client';
import {getVideoVersionId} from '~/lib/utils';
import {STEPS} from '~/state/onboarding_v2';

const EmailFieldWrapper = styled.div`
  margin-top: 22px;
`;

type SendEmailTypeaheadProps = {
  isLinkDisabled: boolean;
  isDropboxLoginRequired: boolean;
  setErrorText: (text: string) => void;
  shareRecipients: TeamContactInfo[];
  setShareRecipients: (recipients: TeamContactInfo[]) => void;
  onRequestClose: () => void;
};

export const SendEmailTypeahead = (props: SendEmailTypeaheadProps) => {
  const {
    isLinkDisabled,
    isDropboxLoginRequired,
    setErrorText,
    shareRecipients,
    setShareRecipients,
    onRequestClose,
  } = props;
  const intl = useIntl();
  const text = useSendEmailTypeaheadStrings();
  const shareItem = useAtomValue(getShareItemAtom);
  const currentLinkInfo = useAtomValue(currentBasicLinkAtom);
  const shareModalState = useAtomValue(shareModalContentsAtom);
  const shareModalLoggingInfo = useAtomValue(getShareModalLoggingInfoAtom);
  const logEvent = shareModalLoggingInfo.logEvent;
  const isFolder = shareItem?.type === 'folder';
  const isTeamFolder = shareModalLoggingInfo?.linkItemType === 'team_project';
  const isViewOnly = isViewOnlyLink(shareModalState);
  const [showEmailDisabledTooltip, setShowEmailDisabledTooltip] = React.useState<boolean>(false);
  const sendButtonDisabled = shareRecipients.length === 0;
  const setShareSnackbarState = useSetAtom(shareSnackbarStateAtom);
  const loggingClient = useLoggingClient();
  const params = new URLSearchParams(location.search);
  const variant = params.get('variant');
  const mediaType = params.get('media_type');

  const emailEntryRef = React.useRef(null);

  const {handleCompleteStep, handleCheckIsCurrentStepOpen} = useOnboardingChecklist({
    logEvent: shareModalLoggingInfo.logEvent,
  });

  if (
    shareItem === null ||
    shareModalState?.type !== 'share-home' ||
    (shareModalState?.type === 'share-home' && shareModalState?.tab !== 'original')
  ) {
    return null;
  }

  const handleShareFile = async () => {
    const recipientEmails = shareRecipients
      .map((info) => info.type === 'user' && info.email)
      .filter((email): email is string => !!email);

    // If the link is being created for the first time, log it
    if (currentLinkInfo === undefined) {
      // eslint-disable-next-line deprecation/deprecation
      logEvent('create_reel_link', {
        access_type: isViewOnly ? 'view_only' : 'review',
        link_item_type: shareModalLoggingInfo.linkItemType,
        location: shareModalLoggingInfo.location,
      });
    }

    try {
      const shareItemId =
        shareItem.type === 'file'
          ? getVideoVersionId(shareItem.versionSummaries, shareModalState.selectedVersion) ?? ''
          : shareItem.id;
      await sendShareLinkEmail(shareItemId, isFolder, isViewOnly, recipientEmails);
      // eslint-disable-next-line deprecation/deprecation
      logEvent('send_reel_share_link_email', {
        access_type: isViewOnly ? 'view_only' : 'review',
        link_item_type: shareModalLoggingInfo.linkItemType,
        recipient_count: recipientEmails.length,
        is_onboarding_share_step: toBooleanAttr(handleCheckIsCurrentStepOpen(STEPS.SHARE_FILE)),
        replay_media_type: narrowToReplayMediaType(mediaType),
        experiment_variant: narrowToExperimentVariantType(variant),
      });
      const linkItemType: LinkItemType =
        shareModalLoggingInfo.linkItemType === 'file'
          ? 'file'
          : shareModalLoggingInfo.linkItemType === 'folder'
          ? 'folder'
          : 'project';
      loggingClient.logPap(
        PAP_Send_ReplayShareLinkEmail({
          accessType: isViewOnly ? 'other' : 'reviewer',
          linkItemType: linkItemType,
          recipientCount: recipientEmails.length,
          isOnboardingShareStep: handleCheckIsCurrentStepOpen(STEPS.SHARE_FILE),
          replayMediaType: stringToPAPReplayMediaType(mediaType),
          experimentVariant: variant || undefined,
        }),
      );
      handleCompleteStep(STEPS.SHARE_FILE);
      setShareSnackbarState({
        type: 'send-email-succeeded',
        numEmails: recipientEmails.length,
        shareItem: shareItem,
        shareModalState: shareModalState,
      });
      onRequestClose();
    } catch (e) {
      const tag = e.error && e.error.error && e.error.error['.tag'];
      if (
        tag === 'sharing_is_restricted' ||
        tag === 'rate_limit_reached' ||
        tag === 'dmca_requester' ||
        tag === 'recipients_conflict_with_team_policies'
      ) {
        const requester = e.error.error['dmca_requester'];
        const emailFailedDueToSharingRestrictionsText = getRestrictedContentErrorTextFromType(
          intl,
          tag,
          requester,
        );
        setErrorText(emailFailedDueToSharingRestrictionsText);
      } else {
        setErrorText(text.emailFailedText);
      }
    }
  };

  return (
    <>
      <Tooltip.Control
        auto
        maxWidth={275}
        open={showEmailDisabledTooltip}
        placement="top"
        triggerRef={emailEntryRef}
        variant="basic"
      >
        {text.linkDisabledTooltipText}
      </Tooltip.Control>
      <div
        onMouseEnter={() => setShowEmailDisabledTooltip(isLinkDisabled)}
        onMouseLeave={() => setShowEmailDisabledTooltip(false)}
      >
        <EmailFieldWrapper ref={emailEntryRef}>
          <DropboxAccountTypeahead
            autofocus={false}
            disabled={isLinkDisabled}
            initialContacts={shareRecipients.filter((c) => c.type === 'user') as UserContactInfo[]}
            inlineButton={
              <SendLinkButton
                disabled={sendButtonDisabled || isLinkDisabled}
                onSubmit={handleShareFile}
              />
            }
            inputLabelText={text.emailAriaLabelText}
            inputPlaceholderText={text.emailPlaceholderText}
            // Avoid autofocusing, since most people use the copy link button and not share via email, and
            // autofocusing blocks the copy link button on mobile.
            setContacts={setShareRecipients}
            type="user"
          />
          {!isDropboxLoginRequired && (
            <Text color="faint" size="small" style={{display: 'block', marginTop: '10px'}}>
              {isTeamFolder ? text.teamProjectShareLinkHelpText : text.shareLinkHelpText}
            </Text>
          )}
        </EmailFieldWrapper>
      </div>
    </>
  );
};

type SendLinkButtonProps = {
  disabled: boolean;
  onSubmit: () => void;
};

const SendLinkButton = (props: SendLinkButtonProps) => {
  const {disabled, onSubmit} = props;
  const text = useSendEmailTypeaheadStrings();

  return (
    <Tooltip title={disabled ? text.disabledTooltipText : text.sendTooltipText}>
      <span style={{display: 'inline-block'}} tabIndex={0}>
        <Button
          disabled={disabled}
          onClick={onSubmit}
          variant="transparent"
          withIconStart={<UIIcon role="presentation" size="small" src={SendLine} />}
        >
          <FormattedMessage
            defaultMessage="Send"
            description="Text for the button to send share links via email."
            id="aIRQX5"
          />
        </Button>
      </span>
    </Tooltip>
  );
};
