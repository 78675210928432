import React, {useState} from 'react';

import {hexToHsva, type HsvaColor, hsvaToHex} from '@uiw/color-convert';
import Hue from '@uiw/react-color-hue';
import Saturation from '@uiw/react-color-saturation';
import {FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';

import {useBreakpoint} from '@dropbox/dig-components/hooks';
import {Overlay, type OverlayAnchorRef, type OverlayProps} from '@dropbox/dig-components/overlay';
import {TextInput} from '@dropbox/dig-components/text_fields';
import {Text} from '@dropbox/dig-components/typography';
import {ThemeContainer, ThemeProvider} from '@dropbox/dig-foundations';

import {Button} from '~/components/button';
import {color, radius, spacing} from '~/components/styled';
import {useDebounceAnimationFrame} from '~/lib/use_debounce_animation_frame';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${color('Background Base')};
  border-radius: ${radius('Medium')};
  padding: ${spacing('Micro Medium')};

  .w-color-hue {
    position: relative;
    width: 100%;
    height: ${spacing('Micro Small')} !important;
    margin-top: ${spacing('Micro Large')};
    margin-bottom: ${spacing('Micro Large')};

    > div {
      border-radius: ${radius('XSmall')} !important;
    }
  }
`;

const ColorArea = styled(Saturation)`
  border-radius: ${radius('Medium')} !important;
  aspect-ratio: 1;
  width: 100% !important;
`;

const PointerRoot = styled.div`
  width: ${spacing('Micro XLarge')};
  height: ${spacing('Micro XLarge')};
  border: solid 2px white;
  position: absolute;
  border-radius: ${radius('Circular')};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transform: translate(-50%, -50%);
`;

interface PointerProps {
  prefixCls?: string;
  top?: string;
  left?: string;
  color?: string;
}

const Pointer = ({left, top, color}: PointerProps) => (
  <PointerRoot style={{left, top, backgroundColor: color}} />
);

const ColorInput = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: ${spacing('Micro Small')};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: ${spacing('Micro XSmall')};
  padding-top: ${spacing('Micro Small')};
`;

const ColorInputLabel = styled.div`
  margin-right: ${spacing('Micro Medium')};
`;

interface HexColorPickerProps {
  open: boolean;
  color?: string;
  anchorRef: OverlayAnchorRef;
  onSave?: (hex: string) => void;
  onChangeColor?: (hex: string) => void;
  onClose: () => void;
  isPortaled?: boolean;
  placement?: OverlayProps['placement'];
}

const defaultColor = '#003a97';

export const HexColorPicker: React.FC<HexColorPickerProps> = ({
  open,
  anchorRef,
  color = defaultColor,
  onChangeColor = () => {},
  onClose,
  onSave = () => {},
  isPortaled = true,
  placement,
}) => {
  let defaultHsva = hexToHsva('#003a97');
  let defaultHex = defaultColor;
  try {
    defaultHsva = hexToHsva(color);
    // `color` is a valid color
    defaultHex = color;
  } catch {
    // default to original value
  }

  const [hsva, setHsva] = useState(defaultHsva);
  const [hex, setHex] = useState(defaultHex);
  const breakpoints = useBreakpoint();

  React.useEffect(() => {
    const color = hsvaToHex(hsva);
    if (open && color !== color) {
      onChangeColor(color);
    }
  }, [color, hsva, onChangeColor, open]);

  const updateColorValue = useDebounceAnimationFrame(
    (newHsva: HsvaColor) => {
      const newHex = hsvaToHex(newHsva);
      setHsva(newHsva);
      setHex(newHex);
      onChangeColor(newHex);
    },
    [onChangeColor],
  );

  const handleTextInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    setHex(value);
    try {
      const hsva = hexToHsva(e.currentTarget.value);
      setHsva(hsva);

      onChangeColor(hsvaToHex(hsva));
    } catch {
      // Unable to parse value as hex
    }
  };

  const handleSaveClick = () => {
    onSave(hsvaToHex(hsva));
  };

  const handleCancelClick = () => {
    onChangeColor(color);
    onClose();
  };

  const intl = useIntl();
  const hexText = intl.formatMessage({
    defaultMessage: 'Hex:',
    id: '/O527T',
    description: 'label referring to the hex color selected',
  });

  if (!open) {
    return null;
  }

  return (
    <Overlay
      anchorRef={anchorRef}
      isPortaled={isPortaled}
      offsetDistance={8}
      placement={placement || breakpoints.medium ? 'right-start' : 'top-end'}
    >
      <ThemeProvider mode="bright" overrides="reset" theme="vis2023">
        <ThemeContainer>
          <Container>
            <ColorArea
              hsva={hsva}
              onChange={(newColor) => {
                updateColorValue({...hsva, ...newColor, a: hsva.a});
              }}
              pointer={Pointer}
            />
            <Hue
              hue={hsva.h}
              onChange={(newHue) => {
                updateColorValue({...hsva, ...newHue});
              }}
              pointer={(props) => (
                <Pointer {...props} color={`hsl(${hsva.h || 0}deg 100% 50%)`} top="50%" />
              )}
            />
            <ColorInput>
              <ColorInputLabel>
                <Text id="hex-label" isBold>
                  {hexText}
                </Text>
              </ColorInputLabel>
              <TextInput aria-labelledby="hex-label" onChange={handleTextInputChange} value={hex} />
            </ColorInput>
            <ButtonContainer>
              <Button
                data-testid="color-picker-cancel"
                onClick={handleCancelClick}
                size="small"
                variant="outline"
              >
                <FormattedMessage
                  defaultMessage="Cancel"
                  description="Label for the cancel button on hex color picker, which cancels the color selected"
                  id="YNvuQc"
                />
              </Button>
              <Button
                data-testid="color-picker-save"
                onClick={handleSaveClick}
                size="small"
                style={{backgroundColor: 'var(--dig-color__primary__base)'}}
                variant="primary"
              >
                <FormattedMessage
                  defaultMessage="Save"
                  description="Label for the save button on hex color picker, which saves the color selected"
                  id="k1EPuU"
                />
              </Button>
            </ButtonContainer>
          </Container>
        </ThemeContainer>
      </ThemeProvider>
    </Overlay>
  );
};
