import React from 'react';

import {useAtom, useAtomValue} from 'jotai';

import {Modal} from '@dropbox/dig-components/modal';
import {Tabs} from '@dropbox/dig-components/tabs';

import {BasicHome} from '~/components/share_modal/basic_home';
import {TabTitleContent} from '~/components/share_modal/share_modal_settings';
import {useShareModalStrings} from '~/components/share_modal/share_modal_text';
import {
  getShareItemAtom,
  shareModalContentsAtom,
  watermarkLinksForItemAtom,
} from '~/components/share_modal/share_state';
import {WatermarkHome} from '~/components/share_modal/watermark_home';
import {useWatermarkShareModal} from '~/components/share_modal/watermark_share_modal';
import type {TeamContactInfo} from '~/lib/api';
import {useHasFullAddonAccess} from '~/lib/utils';

import {BasicAddOnUpsellModal} from '../add_on_upsell_modal';
import {useViewport} from '../viewport_context';

export const ShareModalHomeTabs = (props: {
  areAllVersionsVideos: boolean;
  canCreateLinks: boolean;
  onRequestClose: () => void;
  shareRecipients: TeamContactInfo[];
  setShareRecipients: (recipients: TeamContactInfo[]) => void;
  showWatermarkTab: boolean;
}) => {
  const {
    areAllVersionsVideos,
    canCreateLinks,
    onRequestClose,
    shareRecipients,
    setShareRecipients,
    showWatermarkTab,
  } = props;
  const [shareModalState, setShareModalState] = useAtom(shareModalContentsAtom);
  const hasFullAddonAccess = useHasFullAddonAccess();
  const watermarkComponents = useWatermarkShareModal();
  const {isMobileDevice} = useViewport();
  const showUpsellModal = !isMobileDevice;
  const [upsellModalIsOpen, setUpsellModalIsOpen] = React.useState(false);
  const watermarkLinks = useAtomValue(watermarkLinksForItemAtom);

  const shareItem = useAtomValue(getShareItemAtom);
  const text = useShareModalStrings(shareItem?.name ?? '');

  if (!shareModalState || shareModalState.type !== 'share-home') {
    return null;
  }

  return (
    <>
      {showWatermarkTab && shareItem?.type === 'file' ? (
        <Tabs
          onSelection={(value) => {
            if (value === 'original') {
              setShareModalState({
                type: 'share-home',
                tab: 'original',
                selectedPermission: 'review',
                selectedVersion: shareItem.defaultVersion,
              });
            } else if (value === 'watermark') {
              if (showUpsellModal && !hasFullAddonAccess) {
                setUpsellModalIsOpen(true);
              } else if (hasFullAddonAccess || watermarkLinks.length) {
                // Only make the tab selectable if there is add-on access,
                // or if there are existing links
                setShareModalState({type: 'share-home', tab: 'watermark'});
              }
            }
          }}
          selectedTab={shareModalState.tab}
        >
          <Modal.Body>
            <Tabs.Group justified>
              <Tabs.Tab
                data-safe-to-unmask-name="share-modal-original-tab"
                id="original"
                style={{maxWidth: '50%'}}
              >
                <TabTitleContent>{text.originalTitle}</TabTitleContent>
              </Tabs.Tab>
              <Tabs.Tab data-safe-to-unmask-name="share-modal-watermark-tab" id="watermark">
                <TabTitleContent
                  onMouseEnter={watermarkComponents.handleMouseEnterWatermarkTabTitle}
                  onMouseLeave={watermarkComponents.handleMouseLeaveWatermarkTabTitle}
                >
                  {text.watermarkTitle}
                  {!showUpsellModal && watermarkComponents.watermarkTabIcon}
                </TabTitleContent>
              </Tabs.Tab>
            </Tabs.Group>
          </Modal.Body>
          <Tabs.Panel style={{marginTop: 'var(--spacing__unit--2_5)'}} tabId="original">
            <BasicHome
              canCreateLinks={canCreateLinks}
              onRequestClose={onRequestClose}
              setShareRecipients={setShareRecipients}
              shareRecipients={shareRecipients}
            />
          </Tabs.Panel>
          <Tabs.Panel style={{marginTop: 'var(--spacing__unit--2_5)'}} tabId="watermark">
            <WatermarkHome
              areAllVersionsVideos={areAllVersionsVideos}
              canCreateLinks={canCreateLinks}
            />
          </Tabs.Panel>
        </Tabs>
      ) : (
        <BasicHome
          canCreateLinks={canCreateLinks}
          onRequestClose={onRequestClose}
          setShareRecipients={setShareRecipients}
          shareRecipients={shareRecipients}
        />
      )}
      {showUpsellModal ? (
        <BasicAddOnUpsellModal
          clickSource="share_modal"
          onClose={() => {
            setUpsellModalIsOpen(false);
            onRequestClose();
          }}
          open={upsellModalIsOpen}
          variant="watermark"
        />
      ) : (
        watermarkComponents.watermarkPremiumTooltip
      )}
      {watermarkComponents.watermarkDisabledTooltip}
    </>
  );
};
