import React, {type MouseEventHandler} from 'react';

import {PAP_Select_WorkspaceAction} from 'pap-events/replay/select_workspace_action';
import {useIntl} from 'react-intl';
import styled from 'styled-components';

import {Facepile} from '@dropbox/dig-components/avatar';
import {LayerContext} from '@dropbox/dig-components/layer';
import {Tooltip} from '@dropbox/dig-components/tooltips';
import {Text} from '@dropbox/dig-components/typography';

import {ManageAccessModal} from '~/components/manage_access_modal';
import {color} from '~/components/styled';
import type {ShareFolderAccessType} from '~/lib/api';
import {useLoggingClient} from '~/lib/use_logging_client';
import {createGroupAvatarName} from '~/lib/utils';
import type {ShareRecipient} from '~/pages/browse_page/components/common';
import {isUserShareRecipient} from '~/pages/browse_page/components/common';

import {useBrowseLogEventMaker} from '../browse_logging_context';
import {useFoldersSharedState} from '../folders_context';
const MAX_FACEPILE_LENGTH = 3;

const TagText = styled.div`
  display: flex;
  justify-content: center;
`;

const FacepileItem = styled(Facepile.Item)`
  && {
    // Override the stacking facepile for accessibility
    // https://jira.dropboxer.net/browse/DSYS-4097
    margin-left: -1px;
  }
`;

export interface FacepileItemWithToolTip {
  accountId: string;
  displayName: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  abbreviatedName?: string;
  accessLevel?: string;
  profilePhotoUrl?: string;
  countDisplay?: number;
}

export const FacepileItemWithToolTip = ({
  displayName,
  accountId,
  abbreviatedName,
  accessLevel,
  profilePhotoUrl,
  countDisplay,
  onClick,
}: FacepileItemWithToolTip) => {
  const intl = useIntl();
  const editText = intl.formatMessage({
    defaultMessage: 'Can edit',
    id: 'QFHqoc',
    description: 'Hover text over a facepile indicating that a user can edit the current project',
  });
  const reviewText = intl.formatMessage({
    defaultMessage: 'Can view and comment',
    id: 'dPgaps',
    description:
      'Hover text over a facepile indicating that a user has view and comment access to the current project',
  });
  const ownerText = intl.formatMessage({
    defaultMessage: 'Owner',
    id: 'sjN07a',
    description:
      'Hover text over a facepile indicating that a user is the owner of the current project',
  });

  const accessLevelLookup: {[tagValue: string]: string} = {
    admin: editText,
    reviewer: reviewText,
    owner: ownerText,
    none: '',
  };

  return (
    <Tooltip
      id={`tooltip-${accountId}`}
      key={`tooltip-${accountId}`}
      title={
        <>
          <TagText>
            <Text isBold size="small">
              {displayName}
            </Text>
          </TagText>
          {accessLevel ? (
            <TagText>
              <Text size="xsmall">{accessLevelLookup[accessLevel]}</Text>
            </TagText>
          ) : null}
        </>
      }
    >
      <FacepileItem
        alt={`${displayName} ` + (accessLevel ? accessLevelLookup[accessLevel] : '')}
        backgroundColor={countDisplay && countDisplay >= 1 ? 'rgba(166, 145, 113, 0.14)' : ''}
        cursor="pointer"
        onClick={onClick}
        src={profilePhotoUrl}
      >
        {countDisplay ? countDisplay : abbreviatedName}
      </FacepileItem>
    </Tooltip>
  );
};

export type TeamFacepileProps = {
  shareRecipients: ShareRecipient[];
  teamFolderName: string;
  teamFolderId: string;
  settingsChangeCallback?(folderId: string): void;
  accessLevel: ShareFolderAccessType;
  openAddPeopleModal?: (sharedFolderId: string, sharedFolderName: string) => void;
  allowSuperAdminRights?: boolean;
  maxAvatars?: number;
  actionElement: 'toolbar' | 'content-tile' | 'overflow_menu';
};

export const TeamFacepile = ({
  shareRecipients,
  teamFolderName,
  teamFolderId,
  settingsChangeCallback,
  accessLevel,
  openAddPeopleModal,
  allowSuperAdminRights,
  actionElement,
  maxAvatars = MAX_FACEPILE_LENGTH,
}: TeamFacepileProps) => {
  const intl = useIntl();
  const loggingClient = useLoggingClient();
  const facePileOverflowNum = shareRecipients.length - maxAvatars;
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const {currentProjectId} = useFoldersSharedState();

  const makeLogEvent = useBrowseLogEventMaker();
  const logEvent = React.useMemo(
    () => makeLogEvent({projectId: currentProjectId}),
    [currentProjectId, makeLogEvent],
  );

  const closeModal = React.useCallback(() => {
    setModalOpen(false);
  }, []);

  const openModal = React.useCallback((event: React.MouseEvent) => {
    event.preventDefault();
    setModalOpen(true);
  }, []);

  const handleOpenAddPeopleModal = (event: React.MouseEvent) => {
    event.preventDefault();

    openAddPeopleModal?.(teamFolderId, teamFolderName);
    loggingClient.logPap(
      PAP_Select_WorkspaceAction({
        workspaceAction: 'add_people',
        actionElement: actionElement,
      }),
    );
  };

  return (
    <>
      <LayerContext.Provider value={{zIndex: 16}}>
        <Facepile size="small">
          {shareRecipients.slice(0, maxAvatars).map((shareRecipient: ShareRecipient) => {
            if (isUserShareRecipient(shareRecipient)) {
              return (
                <FacepileItemWithToolTip
                  abbreviatedName={shareRecipient.abbreviatedName}
                  accessLevel={shareRecipient.accessLevel}
                  accountId={shareRecipient.accountId}
                  displayName={shareRecipient.displayName!}
                  key={`recipient-${shareRecipient.accountId}`}
                  onClick={openModal}
                  profilePhotoUrl={shareRecipient.profilePhotoUrl}
                />
              );
            } else {
              return (
                <FacepileItemWithToolTip
                  abbreviatedName={createGroupAvatarName(shareRecipient.displayName)}
                  accessLevel={shareRecipient.accessLevel}
                  accountId={shareRecipient.groupId}
                  displayName={shareRecipient.displayName!}
                  key={`recipient-${shareRecipient.groupId}`}
                  onClick={openModal}
                  profilePhotoUrl={undefined}
                />
              );
            }
          })}
          {facePileOverflowNum > 0 ? (
            <FacepileItemWithToolTip
              accountId="avatar_overflow"
              countDisplay={facePileOverflowNum}
              displayName={intl.formatMessage(
                {
                  defaultMessage: '{facePileOverflowNum} others',
                  id: 'B/s71Z',
                  description:
                    'Tooltip text indicating how many "other" users are part of the project. This number will always be greater than 1.',
                },
                {facePileOverflowNum},
              )}
              onClick={openModal}
            />
          ) : null}
          {openAddPeopleModal && accessLevel !== 'reviewer' && (
            <Tooltip
              id="tooltip-add-member"
              key="tooltip-add-member"
              title={
                <TagText>
                  <Text isBold size="small">
                    {intl.formatMessage({
                      defaultMessage: 'Add people',
                      id: 'HTtSnG',
                      description: 'Tooltip text for a button to add people to a project.',
                    })}
                  </Text>
                </TagText>
              }
            >
              <FacepileItem
                alt={intl.formatMessage({
                  defaultMessage: 'Add',
                  id: '63R721',
                  description: 'Aria label for a button to add team members to a project.',
                })}
                colorPalette={{
                  background: color('Background Raised'),
                  foreground: color('Secondary Base'),
                }}
                cursor="pointer"
                onClick={handleOpenAddPeopleModal}
              >
                <Text aria-hidden="true" color="standard">
                  +
                </Text>
              </FacepileItem>
            </Tooltip>
          )}
        </Facepile>
      </LayerContext.Provider>
      {settingsChangeCallback ? (
        <ManageAccessModal
          accessLevel={accessLevel}
          allowSuperAdminRights={allowSuperAdminRights}
          logEvent={logEvent}
          modalType="manage_access_only"
          open={modalOpen}
          requestClose={closeModal}
          settingsChangeCallback={settingsChangeCallback}
          shareRecipients={shareRecipients}
          teamFolderId={teamFolderId}
          teamFolderName={teamFolderName}
        />
      ) : null}
    </>
  );
};
