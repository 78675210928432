// Mostly a copy of metaserver/static/js/flows/utils/bolt.ts with some modifications specific to Replay

import throttle from 'lodash/throttle';

import type {ChannelPayloads} from '~/lib/bolt_client';
import {SignedChannelState, ThunderClient} from '~/lib/bolt_client';
import {getFlowsBoltData} from '~/lib/flows/api';

import {DBX_BUILD_MODE} from '../../context_utils';

export const WORKFLOW_BOLT_ACTIONS = [
  'audio_conversion',
  'image_conversion',
  'move_file',
  'naming_conventions',
  'pdf_conversion',
  'tag',
  'tidy_up',
  'transcribe_media',
  'unzip',
  'video_conversion',
  'watermarking',
  'archive_from_replay',
] as const;

type WorkflowBoltActionsType = (typeof WORKFLOW_BOLT_ACTIONS)[number];

// Utility to validate that the required fields have actually been provided
export const isCompleteFlowsBoltPayload = (
  payload: Partial<FlowsBoltPayload>,
): payload is FlowsBoltPayload => {
  return !!(payload?.revkey && payload.action_type && payload.status);
};

// Interface for messages sent from the backend
// Should be consistent with //dropbox/flows/workflows/notifications:FlowsBoltPayload
export interface FlowsBoltPayload {
  revkey: string;
  action_type: WorkflowBoltActionsType;
  status: 'start' | 'success' | 'fail';
  input_fq_path: string;
  seq_index: number;
  template?: WorkflowBoltActionsType[];
  exit_reason?: string;
  created_fq_path?: string;
  moved_fq_path?: string;
  output_for_tests?: string;
  trigger?: 'file_add' | 'scheduled' | 'apply_to_existing' | 'manual';
}

export namespace FlowsBoltClient {
  let client: ThunderClient | null;

  export async function subscribe(updateCallback: (payloads: Partial<FlowsBoltPayload>[]) => void) {
    if (client) {
      client.unsubscribe();
    }

    const boltData = await getFlowsBoltData();

    if (
      !boltData.app_id ||
      !boltData.revision ||
      !boltData.unique_id ||
      !boltData.token ||
      // Bolt spams devbox with requests
      DBX_BUILD_MODE === 'local'
    ) {
      return;
    }

    const signedChannelState = new SignedChannelState(
      boltData.app_id,
      boltData.unique_id,
      boltData.revision,
      boltData.token,
    );

    const handleUpdate = (rawUpdates: unknown) => {
      // the typing in the bolt library is totally messed up, need to do some casting
      const updates = (rawUpdates as ChannelPayloads[])
        .map((channelPayload) => channelPayload.payloads)
        .reduce((flattened, val) => flattened.concat(val), [])
        .map((rawPayload: any) => rawPayload.payload as Partial<FlowsBoltPayload>);

      updateCallback(updates);
    };

    const handleRefresh = () => {
      // if the token needs to be refreshed, resubscribe to bolt
      subscribe(updateCallback);
    };

    // When connected to devbox, bolt continuously refreshes the bolt connection.
    // While we investigate the cause, let's throttle the rate at which we refresh.

    const TEN_MINUTES_MS = 600000;
    const throttledRefresh = throttle(handleRefresh, TEN_MINUTES_MS);

    client = new ThunderClient([signedChannelState], handleUpdate, throttledRefresh);
    client.start();
  }

  export function unsubscribe() {
    if (client) {
      client.unsubscribe();
      client = null;
    }
  }
}
