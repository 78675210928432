import {privacyConsentModule} from './initialize_privacy_module';

type UserSurveyEvent =
  // replay events
  | 'create_replay_comment'
  | 'create_replay_annotation'
  | 'create_replay_link'
  | 'create_replay_video'
  | 'replay_page_hit'
  | 'click_publish_painted_door'
  | 'replay_shown_links_page';

type UserSurveyEventSurface = 'replay';

/**
 * User survey module that loads and initializes the user survey iframe and
 * exposes functions to trigger events and add attributes
 */

type MessageEventType =
  | 'displayTestSurvey'
  | 'trackEvent'
  | 'addAttribute'
  | 'removeAttribute'
  | 'logout'
  | 'setUserId';

interface OutgoingMessage {
  eventType: MessageEventType;
  eventData: unknown;
}

type SurveyEventType = 'surveyResized' | 'surveyPresented' | 'surveyClosed';

let iframeEl: HTMLIFrameElement | null = null;
let iframeSrc: string | null = null;
let messageQueue: [MessageEventType, unknown][] = [];

function createIframe(src: string) {
  const iframeEl = document.createElement('iframe');

  iframeEl.src = src;
  iframeEl.setAttribute('sandbox', 'allow-scripts allow-same-origin allow-popups');
  iframeEl.setAttribute('class', 'usersurvey-iframe');
  iframeEl.setAttribute('id', 'usersurvey-iframe');
  iframeEl.setAttribute('allowTransparency', 'true');
  iframeEl.setAttribute('height', '0');
  iframeEl.setAttribute('width', '0');
  iframeEl.setAttribute('title', 'User Survey');

  document.body.insertBefore(iframeEl, document.body.childNodes[0]);

  return iframeEl;
}

function resizeIframe(height: string, width: string) {
  if (iframeEl) {
    iframeEl.height = height;
    iframeEl.width = width;
  }
}

function receiveMessage(message: MessageEvent) {
  const eventType: SurveyEventType = message.data.eventType;
  if (message.origin !== window.location.origin || message.source !== iframeEl?.contentWindow) {
    return;
  }
  switch (eventType) {
    case 'surveyResized':
      resizeIframe(message.data.surveyHeight, message.data.surveyWidth);
      break;
    case 'surveyPresented':
      resizeIframe(message.data.surveyHeight, message.data.surveyWidth);
      break;
    case 'surveyClosed':
      resizeIframe('0', '0');
      break;
  }
}

let iframeHasLoaded = false;

export function init(
  userleap_id: string,
  is_csm_team: boolean,
  is_in_team: boolean,
  is_dropboxer: boolean,
) {
  if (!iframeEl) {
    iframeSrc = `/html/user_survey.html`;
    // pointing to reference of createIframe in _local for mocking purposes in tests
    iframeEl = _local.createIframe(iframeSrc);
  }

  iframeEl.addEventListener('load', () => {
    iframeHasLoaded = true;

    // placeholder values. Will be updated with the actual UUID once API changes land.
    sendMessage('setUserId', {
      userId: userleap_id,
    });

    addAttribute('replayLocaleCode', getCurrentLocaleCode());
    addAttribute('isCsmTeam', '' + is_csm_team);
    addAttribute('isDropboxer', '' + is_dropboxer);
    addAttribute('isTeam', '' + is_in_team);
    addAttribute(
      'isMobile',
      // eslint-disable-next-line deprecation/deprecation
      '' + /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.platform),
    );

    trackEvent('replay_page_hit', 'replay');

    // Send any queued messages
    messageQueue.forEach((event) => sendMessage(...event));
    messageQueue = [];
  });

  window.addEventListener('message', receiveMessage);
}

function sendMessage(messageType: MessageEventType, messageData: unknown) {
  // Fallback to finding the iframe just in case
  const userSurveyIFrame =
    iframeEl || (document && (document.getElementById('usersurvey-iframe') as HTMLIFrameElement));
  const userSurveyIFrameSrc = userSurveyIFrame && userSurveyIFrame.src;

  if (
    iframeHasLoaded &&
    userSurveyIFrame &&
    userSurveyIFrame.contentWindow &&
    userSurveyIFrameSrc
  ) {
    const message: OutgoingMessage = {
      eventType: messageType,
      eventData: messageData,
    };
    userSurveyIFrame.contentWindow.postMessage(message, userSurveyIFrameSrc);
  } else {
    // Add message to queue for sending once everything has finished loading
    messageQueue.push([messageType, messageData]);
  }
}

function getCurrentLocaleCode(): string {
  var language = 'unknown';
  if (window.navigator.languages) {
    language = window.navigator.languages[0];
  } else {
    language = window.navigator.language;
  }
  return language.startsWith('en') ? 'en' : 'unknown';
}

// External APIs
export const UserSurvey = {
  init,
  trackEvent,
  addAttribute,
  removeAttribute,
};

function trackWebEvent(event: UserSurveyEvent, eventSurface: UserSurveyEventSurface) {
  // append 'web_' to event names from web to prevent conflating with events from mobile
  const eventName = 'web_' + event;
  sendMessage('trackEvent', {eventName});

  // send a separate event with the surface appended
  const eventNameWithSurface = eventName + '_from_' + eventSurface;
  sendMessage('trackEvent', {
    eventName: eventNameWithSurface,
  });
}

function trackEvent(event: UserSurveyEvent, eventSurface: UserSurveyEventSurface) {
  if (privacyConsentModule.getConsentChoices().analytics) {
    trackWebEvent(event, eventSurface);
  }
}

function addAttribute(key: string, value: string | number) {
  sendMessage('addAttribute', {key, value});
}

function removeAttribute(key: string) {
  sendMessage('removeAttribute', {key});
}

// locally scoped private API functions
const _local = {
  init,
  createIframe,
  receiveMessage,
};

// Export local functions for testing ONLY
export const InternalSurveyFunctionsForTestOnly = _local;
