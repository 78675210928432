import {ReplayError, ReplayErrorCategory, reportException} from '~/lib/error_reporting';

import {type MediaTypeValue} from './api';
import {checkPithosPrivacyConsentFlag} from './environment';
import {LocalStorage, MemoryStorage, UserLocalStorage} from './storage_utils';
import {TimeDisplayFormat} from './time';
import type {GuestUserInfo} from '../context';
import {hasGuestUserInfo} from '../context';
import type {Sort, ViewType} from '../pages/browse_page/types';

export const HAS_USER_ACCEPTED_COOKIES_KEY = 'has_user_accepted_replay_cookies?';
const LOCAL_STORAGE_KEY_UID = 'oauth-uid';
const LOCAL_STORAGE_KEY_DBID = 'oauth-dbid';
const LOCAL_STORAGE_KEY_REFRESH_TOKEN = 'oauth-refresh-token';
const HAS_TOKEN_READ_PERMISSIONS_KEY = 'has_token_read_permissions_key';
const LOCAL_STORAGE_KEY_TIME_DISPLAY_FORMAT = 'time-display-format';
const LOCAL_STORAGE_KEY_AUDIO_TIME_DISPLAY_FORMAT = 'audio-time-display-format';
const LOCAL_STORAGE_KEY_VOLUME_LEVEL = 'volume-level';
const LOCAL_STORAGE_BROWSE_SORT_ATTR = 'browse-sort-attribute';
const LOCAL_STORAGE_BROWSE_SORT_DIRECTION = 'browse-sort-direction';
const LOCAL_STORAGE_CHOOSER_LAST_PATH = 'chooser-last-path';
const REPLAY_SESSION_ID = 'replay-session-id';
const CLICKED_LEFT_NAV_KEY = 'replay-has-clicked-left-nav';
const LOCAL_EDITOR_PAGE_DRAFT_COMMENTS = 'replay-local-editor-page-draft-comments';
const LOCAL_STORAGE_GUEST_SESSION_JWT = 'replay-guest-session-jwt';
export const COLLAB_ONBOARDING_STATUS = 'replay_collab_onboarding_status';
export const DRAWING_COMPOSER_COLOR = 'drawingColor';
export const DRAWING_COMPOSER_STROKE_WIDTH = 'drawingStrokeWidth';
export const LAST_SEEN_VERSION = 'lastSeenVersion';
export const SHOULD_RENDER_LIST_VIEW = 'replayListView';
export const HAS_DISMISSED_SUGGESTED_ITEMS = 'has-dismissed-suggested-items';
export const HAS_VISITED_VERSION_COMPARISON = 'has-visited-version-comparison';
export const HAS_DISMISSED_PROTOOLS_CONNECTION_MODAL = 'has-dismised-protools-connection-modal';
export const PURCHASED_REPLAY_IN_APP = 'purchased-replay-in-app';
export const RELOADED_POST_PURCHASE = 'reloaded-post-purchase';
export const PAGE_LOAD_LAST_REFRESH = 'page-load-last-refresh';

const NECESSARY_DATA_KEYS = [
  HAS_USER_ACCEPTED_COOKIES_KEY,
  LOCAL_STORAGE_KEY_UID,
  LOCAL_STORAGE_KEY_DBID,
  LOCAL_STORAGE_KEY_REFRESH_TOKEN,
  PAGE_LOAD_LAST_REFRESH,
] as const;

const FUNCTIONALITY_DATA_KEYS = [
  LOCAL_STORAGE_KEY_TIME_DISPLAY_FORMAT,
  LOCAL_STORAGE_KEY_AUDIO_TIME_DISPLAY_FORMAT,
  LOCAL_STORAGE_KEY_VOLUME_LEVEL,
  LOCAL_STORAGE_BROWSE_SORT_ATTR,
  LOCAL_STORAGE_BROWSE_SORT_DIRECTION,
  LOCAL_STORAGE_CHOOSER_LAST_PATH,
  COLLAB_ONBOARDING_STATUS,
  DRAWING_COMPOSER_COLOR,
  DRAWING_COMPOSER_STROKE_WIDTH,
  LAST_SEEN_VERSION,
  SHOULD_RENDER_LIST_VIEW,
  HAS_DISMISSED_SUGGESTED_ITEMS,
  HAS_VISITED_VERSION_COMPARISON,
  HAS_DISMISSED_PROTOOLS_CONNECTION_MODAL,
  LOCAL_STORAGE_GUEST_SESSION_JWT,
  PURCHASED_REPLAY_IN_APP,
] as const;

type AnalyticsDataKey = (typeof ANALYTICS_DATA_KEYS)[number];
type FunctionalityDataKey = (typeof FUNCTIONALITY_DATA_KEYS)[number];
type NecessaryDataKey = (typeof NECESSARY_DATA_KEYS)[number];
type DeclinableDataKey = AnalyticsDataKey | FunctionalityDataKey;
type ValidDataKey = NecessaryDataKey | DeclinableDataKey;

const isFunctionalityDataKey = (k: string): k is (typeof FUNCTIONALITY_DATA_KEYS)[number] => {
  // @ts-ignore
  return FUNCTIONALITY_DATA_KEYS.includes(k);
};

const ANALYTICS_DATA_KEYS = [REPLAY_SESSION_ID] as const;

const isAnalyticsDataKey = (k: string): k is (typeof ANALYTICS_DATA_KEYS)[number] => {
  // @ts-ignore
  return ANALYTICS_DATA_KEYS.includes(k);
};

class ReplayMemoryStorage extends MemoryStorage {
  getItems() {
    return Object.entries(this.storage);
  }
}

// Export for testing; DO NOT USE
export const _replayMemoryStorage = new ReplayMemoryStorage();

// Export for testing; DO NOT USE
export const _guestUserInfoMemoryStorage = new ReplayMemoryStorage();

/**
 * Convert a Uint32Array to a 16 character hex string
 */
export function toHex(data: Uint32Array): string {
  return Array.from(data)
    .map((num) => num.toString(16).padStart(8, '0'))
    .join('');
}

const generateRandomId = (): string | null => {
  try {
    const randomValues = crypto.getRandomValues(new Uint32Array(2));
    return toHex(randomValues);
  } catch (e) {
    return null;
  }
};

type CookieChoices<V> = {
  analytics?: V;
  functionality?: V;
};

// Do not use LocalStorage directly. Instead use the get and set methods in the
// ReplayStorage class. They check for user consent and killswitches.
export class _ReplayStorage {
  static GUEST_USER_INFO_COOKIE_NAME =
    process.env.NODE_ENV === 'development' ? 'guestUserInfo' : '__Host-guestUserInfo';

  static PAGE_ENABLED_OVERRIDE_COOKIE_NAME =
    process.env.NODE_ENV === 'development' ? 'allowLoginOverride' : '__Host-allowLoginOverride';

  static PKCE_CODE_VERIFIER_NAME = 'codeVerifier';

  // Storage starts out disabled because users have to accept the use of
  // cookies before we can store anything
  private disabled = {
    analytics: true,
    functionality: true,
  };
  private userId: number | null = null;

  constructor() {
    if (this.getReplaySessionID() === null) {
      this.set(REPLAY_SESSION_ID, generateRandomId());
    }
  }

  set(key: DeclinableDataKey, value: any) {
    const setItem = (disabledFlag: boolean) => {
      if (disabledFlag) {
        // Fall back to memory storage so that if the user accepts cookies
        // later, we still persist their data
        _replayMemoryStorage.setItem(key, value);
        return;
      }
      if (this.userId !== null) {
        UserLocalStorage.set(this.userId, key, value);
      } else {
        LocalStorage.set(key, value);
      }
    };

    if (isFunctionalityDataKey(key)) {
      setItem(this.disabled.functionality);
      return;
    }
    if (isAnalyticsDataKey(key)) {
      setItem(this.disabled.analytics);
      return;
    }
  }

  get(key: ValidDataKey) {
    // Return the value from localStorage, if it exists, since any value
    // already in localStorage was written when the user had accepted cookies.
    // This way we do not have to wait for the user's privacy choices to load
    // before we know where to look for the data in storage.
    const localStorageValue =
      this.userId !== null ? UserLocalStorage.get(this.userId, key) : LocalStorage.get(key);

    if (localStorageValue !== null) {
      return localStorageValue;
    }

    return _replayMemoryStorage.getItem(key) ?? null;
  }

  delete(key: string) {
    if (this.userId) {
      UserLocalStorage.delete(this.userId, key);
    } else {
      LocalStorage.delete(key);
    }
  }

  // Called whenever a logged in user visits the page
  logUserIn(uid: number, accountId: string, refreshToken: string) {
    this.userId = uid;

    // Clear all data except that which was previously stored for this user
    UserLocalStorage.clearOtherUsers([uid]);

    if (!checkPithosPrivacyConsentFlag()) {
      const cookieSetting = this.getCookieSetting();

      if (cookieSetting) {
        this.updateConsent({analytics: true, functionality: true});
      } else if (cookieSetting === false) {
        this.updateConsent({analytics: false, functionality: false});
      }
    }

    LocalStorage.set(LOCAL_STORAGE_KEY_UID, uid.toString());
    LocalStorage.set(LOCAL_STORAGE_KEY_DBID, accountId);
    LocalStorage.set(LOCAL_STORAGE_KEY_REFRESH_TOKEN, refreshToken);

    this.clearGuestUserInfoCookie();
    this.clearGuestSessionJWT();
  }

  resetStorageAndClearGuestCookie() {
    LocalStorage.reset();
    this.clearGuestUserInfoCookie();
  }

  // Called when a user explicitly logs out
  logUserOut(href: string = '/') {
    // Clear all data
    this.resetStorageAndClearGuestCookie();
    // Reload page to reset singletons like Amplitude
    window.location.href = href;
  }

  setupLoggedOut() {
    // Delete these just in case
    LocalStorage.delete(LOCAL_STORAGE_KEY_UID);
    LocalStorage.delete(LOCAL_STORAGE_KEY_DBID);
    LocalStorage.delete(LOCAL_STORAGE_KEY_REFRESH_TOKEN);
  }

  getLoggedInUserInfo(): {
    uid: string | null;
    accountId: string | null;
  } {
    try {
      return {
        uid: LocalStorage.get(LOCAL_STORAGE_KEY_UID),
        accountId: LocalStorage.get(LOCAL_STORAGE_KEY_DBID),
      };
    } catch {
      return {
        uid: null,
        accountId: null,
      };
    }
  }

  getRefreshToken(): {
    refreshToken: string | null;
  } {
    try {
      return {
        refreshToken: LocalStorage.get(LOCAL_STORAGE_KEY_REFRESH_TOKEN),
      };
    } catch {
      return {
        refreshToken: null,
      };
    }
  }

  hasCookieSetting(): boolean {
    return this.getCookieSetting() !== null;
  }

  getCookieSetting(): boolean | null {
    if (checkPithosPrivacyConsentFlag()) {
      return null;
    }

    const setting = LocalStorage.get(HAS_USER_ACCEPTED_COOKIES_KEY);
    if (setting === true || setting === false) {
      return setting;
    }
    return null;
  }

  updateConsent(cookieChoices: CookieChoices<boolean>) {
    // For backwards compatibility only, store a cookie recording the user's
    // blanket acceptance or declination
    if (cookieChoices.analytics && cookieChoices.functionality) {
      LocalStorage.set(HAS_USER_ACCEPTED_COOKIES_KEY, true);
    }
    if (cookieChoices.analytics === false && cookieChoices.functionality === false) {
      LocalStorage.set(HAS_USER_ACCEPTED_COOKIES_KEY, false);
    }

    this.disabled = {
      ...this.disabled,
      ...(cookieChoices.analytics !== undefined ? {analytics: !cookieChoices.analytics} : null),
      ...(cookieChoices.functionality !== undefined
        ? {functionality: !cookieChoices.functionality}
        : null),
    };

    // First, move any accepted cookies from memory to storage
    const transferItem = (key: DeclinableDataKey) => {
      const memoryValue = _replayMemoryStorage.getItem(key);
      if (memoryValue !== undefined) {
        this.set(key, memoryValue);
      }
    };
    if (cookieChoices.analytics) {
      ANALYTICS_DATA_KEYS.forEach(transferItem);
    }
    if (cookieChoices.functionality) {
      FUNCTIONALITY_DATA_KEYS.forEach(transferItem);

      const guestInfoFromMemory = this.getGuestUserInfoFromMemory();
      if (guestInfoFromMemory) {
        this.setGuestUserInfoCookie(guestInfoFromMemory);
      }
    }

    // Second, clear declined cookies from storage
    if (cookieChoices.analytics === false) {
      // Clear everything from storage but preserve the other types of data,
      // thus removing only the analytics data. This is necessary because
      // Amplitude and UserLeap both store data with unpredictable names.
      const necessaryDataToPreserve = NECESSARY_DATA_KEYS.reduce<{[key: string]: any}>(
        (acc, key) => {
          acc[key] = LocalStorage.get(key);
          return acc;
        },
        {},
      );
      const functionalityDataToPreserve = FUNCTIONALITY_DATA_KEYS.reduce<{[key: string]: any}>(
        (acc, key) => {
          acc[key] = this.get(key);
          return acc;
        },
        {},
      );

      localStorage.clear();

      Object.entries(necessaryDataToPreserve).forEach(([k, v]) => {
        LocalStorage.set(k, v);
      });
      Object.entries(functionalityDataToPreserve).forEach(([k, v]) => {
        this.set(k as FunctionalityDataKey, v);
      });
    }
    if (cookieChoices.functionality === false) {
      FUNCTIONALITY_DATA_KEYS.forEach((key) => {
        this.delete(key);
      });
      this.clearGuestUserInfoCookie();
    }
  }

  setGuestUserInfo(info: GuestUserInfo) {
    const {firstName, lastName, email} = info;

    if (!hasGuestUserInfo(info)) {
      return;
    }
    if (this.disabled.functionality) {
      _guestUserInfoMemoryStorage.setItem('firstName', firstName ?? '');
      _guestUserInfoMemoryStorage.setItem('lastName', lastName ?? '');
      _guestUserInfoMemoryStorage.setItem('email', email ?? '');
    } else {
      this.setGuestUserInfoCookie(info);
    }
  }

  getGuestUserInfo(): GuestUserInfo | null {
    const guestUserInfoCookie = this.getGuestUserInfoCookie();

    if (guestUserInfoCookie !== null) {
      return guestUserInfoCookie;
    }

    return this.getGuestUserInfoFromMemory();
  }

  setTimeDisplayFormat(displayFormat: TimeDisplayFormat, mediaType: MediaTypeValue) {
    if (mediaType === 'audio') {
      this.set(LOCAL_STORAGE_KEY_AUDIO_TIME_DISPLAY_FORMAT, displayFormat);
    } else {
      this.set(LOCAL_STORAGE_KEY_TIME_DISPLAY_FORMAT, displayFormat);
    }
  }

  getTimeDisplayFormat(mediaType?: MediaTypeValue): TimeDisplayFormat {
    if (mediaType === 'audio') {
      return this.get(LOCAL_STORAGE_KEY_AUDIO_TIME_DISPLAY_FORMAT) || TimeDisplayFormat.DECIMAL;
    }
    return this.get(LOCAL_STORAGE_KEY_TIME_DISPLAY_FORMAT) || TimeDisplayFormat.STANDARD;
  }

  setBrowseSort({attribute, direction}: Sort) {
    this.set(LOCAL_STORAGE_BROWSE_SORT_ATTR, attribute);
    this.set(LOCAL_STORAGE_BROWSE_SORT_DIRECTION, direction);
  }

  setBrowseViewType(viewType: ViewType) {
    this.set(SHOULD_RENDER_LIST_VIEW, viewType === 'list');
  }

  getBrowseSort(): Sort {
    return {
      attribute: this.get(LOCAL_STORAGE_BROWSE_SORT_ATTR) || 'updated',
      direction: this.get(LOCAL_STORAGE_BROWSE_SORT_DIRECTION) || 'descending',
    };
  }

  getBrowseViewType(): ViewType {
    return this.get(SHOULD_RENDER_LIST_VIEW) ? 'list' : 'tile';
  }

  setChooserLastPath(chooserLastPath: string) {
    this.set(LOCAL_STORAGE_CHOOSER_LAST_PATH, chooserLastPath);
  }

  getChooserLastPath(): string {
    return this.get(LOCAL_STORAGE_CHOOSER_LAST_PATH) || '';
  }

  setPurchasedInReplay() {
    this.set(PURCHASED_REPLAY_IN_APP, true);
  }

  getPurchasedInReplay() {
    return this.get(PURCHASED_REPLAY_IN_APP);
  }

  deletePurchasedInReplay() {
    this.delete(PURCHASED_REPLAY_IN_APP);
  }

  setLocalEditorPageDraftComments(draftComments: string) {
    return LocalStorage.set(LOCAL_EDITOR_PAGE_DRAFT_COMMENTS, draftComments);
  }

  getLocalEditorPageDraftComments(): string {
    return LocalStorage.get(LOCAL_EDITOR_PAGE_DRAFT_COMMENTS);
  }

  setHasTokenReadPermission(hasPermission: boolean) {
    LocalStorage.set(HAS_TOKEN_READ_PERMISSIONS_KEY, hasPermission);
  }

  getHasTokenReadPermission(): boolean {
    return LocalStorage.get(HAS_TOKEN_READ_PERMISSIONS_KEY);
  }

  getHasClickedLeftNav(): boolean {
    return LocalStorage.get(CLICKED_LEFT_NAV_KEY);
  }

  setHasClickedLeftNav(clicked: boolean) {
    LocalStorage.set(CLICKED_LEFT_NAV_KEY, clicked);
  }

  getVolumeLevel() {
    return this.get(LOCAL_STORAGE_KEY_VOLUME_LEVEL) || 1;
  }

  setVolumeLevel(volume: number = 0) {
    return this.set(LOCAL_STORAGE_KEY_VOLUME_LEVEL, volume);
  }

  getGuestSessionJWT(): string | undefined {
    return this.get(LOCAL_STORAGE_GUEST_SESSION_JWT);
  }

  setGuestSessionJWT(jwt: string) {
    this.set(LOCAL_STORAGE_GUEST_SESSION_JWT, jwt);
  }

  clearGuestSessionJWT() {
    this.delete(LOCAL_STORAGE_GUEST_SESSION_JWT);
  }

  getReplaySessionID(): string | null {
    return this.get(REPLAY_SESSION_ID) ?? null;
  }

  setPageEnabledOverride(newStackIsEnabled: boolean) {
    const enabledStr = JSON.stringify(newStackIsEnabled);
    const expirationDate = new Date();
    const twoMinutes = 2 * 60 * 1000;
    expirationDate.setTime(expirationDate.getTime() + twoMinutes);
    document.cookie = `${
      _ReplayStorage.PAGE_ENABLED_OVERRIDE_COOKIE_NAME
    }=${enabledStr};expires=${expirationDate.toUTCString()};path=/;${
      import.meta.env.PROD ? 'secure' : ''
    }`;
  }

  getPageEnabledOverride() {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i += 1) {
      const [key, value] = cookies[i].split('=');
      if (key.trim() === _ReplayStorage.PAGE_ENABLED_OVERRIDE_COOKIE_NAME) {
        return JSON.parse(value);
      }
    }

    // default to false
    return false;
  }

  storeCodeVerifierForPKCE(codeVerifier: string) {
    sessionStorage.setItem(_ReplayStorage.PKCE_CODE_VERIFIER_NAME, codeVerifier);
  }

  retrieveAndClearCodeVerifierForPKCE() {
    const codeVerifier = sessionStorage.getItem(_ReplayStorage.PKCE_CODE_VERIFIER_NAME);
    sessionStorage.clear();
    return codeVerifier;
  }

  getLastPageRefreshTime() {
    try {
      return parseInt(LocalStorage.get(PAGE_LOAD_LAST_REFRESH) ?? '0');
    } catch (error) {
      reportException(
        new ReplayError({
          category: ReplayErrorCategory.InBrowserFailure,
          severity: 'non-critical',
          error: new Error('failed to fetch from storage: ' + error.message),
        }),
      );
    }

    return 0;
  }

  setLastPageRefreshTime(time: number) {
    try {
      LocalStorage.set(PAGE_LOAD_LAST_REFRESH, time.toString());
    } catch (error) {
      reportException(
        new ReplayError({
          category: ReplayErrorCategory.InBrowserFailure,
          severity: 'non-critical',
          error: new Error('failed to update storage: ' + error.message),
        }),
      );
    }
  }

  private getGuestUserInfoFromMemory(): GuestUserInfo | null {
    const info = {
      firstName: _guestUserInfoMemoryStorage.getItem('firstName'),
      lastName: _guestUserInfoMemoryStorage.getItem('lastName'),
      email: _guestUserInfoMemoryStorage.getItem('email'),
    };
    if (hasGuestUserInfo(info)) {
      return info;
    }
    return null;
  }

  private setGuestUserInfoCookie(info: GuestUserInfo) {
    const infoStr = JSON.stringify(info);
    const expirationDate = new Date();
    const twoDays = 2 * 24 * 60 * 60 * 1000;
    expirationDate.setTime(expirationDate.getTime() + twoDays);
    document.cookie = `${
      _ReplayStorage.GUEST_USER_INFO_COOKIE_NAME
    }=${infoStr};expires=${expirationDate.toUTCString()};path=/;${
      import.meta.env.PROD ? 'secure' : ''
    }`;
  }

  private getGuestUserInfoCookie() {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i += 1) {
      const [key, value] = cookies[i].split('=');
      if (key.trim() === _ReplayStorage.GUEST_USER_INFO_COOKIE_NAME) {
        return JSON.parse(value);
      }
    }

    return null;
  }

  private clearGuestUserInfoCookie() {
    const expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() - 1000);
    document.cookie = `${
      _ReplayStorage.GUEST_USER_INFO_COOKIE_NAME
    }=;expires=${expirationDate.toUTCString()};`;
  }
}

export const replayStorage = new _ReplayStorage();
