import React, {Suspense, useEffect, useState} from 'react';

import * as Sentry from '@sentry/react';
import {QueryClientProvider} from '@tanstack/react-query';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import {useAtomValue} from 'jotai';
import {RawIntlProvider, useIntl} from 'react-intl';
import {
  createBrowserRouter,
  Navigate,
  type Params,
  redirect,
  RouterProvider,
} from 'react-router-dom';
import styled, {createGlobalStyle, StyleSheetManager} from 'styled-components';

import {ThemeContainer, ThemeProvider} from '@dropbox/dig-foundations';

import {AppInitializer} from '~/AppInitializer';
import {appStrings} from '~/components/app_strings';
import {CookieSnackBar} from '~/components/cookie_snack_bar';
import {ErrorBoundary} from '~/components/error_boundary';
import {ExtensionProvider} from '~/components/extension_context';
import {OnboardingProvider} from '~/components/onboarding_v2/onboarding_provider';
import {RenderProvider} from '~/components/render_context';
import {ErrorSnackbar} from '~/components/snackbar/error_snackbar';
import {StormcrowExposureLogger} from '~/components/stormcrow_exposure_logger';
import {ConnectedUploadDrawer} from '~/components/upload_drawer/upload_drawer';
import {UploadWrapper} from '~/components/upload_drawer/upload_wrapper';
import {ViewportProvider} from '~/components/viewport_context';
import {lazy} from '~/lib/lazy';

import {LazyDebugPanel} from './components/debug_panel/lazy_debug_panel';
import {MetricsListener} from './components/metrics_listener';
import {PasswordCacheProvider} from './components/password_cache_provider';
import {PostPurchaseListener} from './components/post_purchase_listener';
import type {InitialSessionState, LoggedInContext} from './context';
import {SessionContext, useReelAppGlobalState} from './context';
import {getVideoFromShortLinkQueryConfig} from './lib/api_queries';
import {check} from './lib/checks';
import {checkPithosPrivacyConsentFlag} from './lib/environment';
import {disableFullStory, initializeFullStory, VIDEO_ORG_ID} from './lib/fullstory';
import {GrowthBookProvider} from './lib/growthbook';
import {privacyConsentModule} from './lib/initialize_privacy_module';
import {useMetrics} from './lib/performance_measures';
import {queryClient} from './lib/query_client';
import {StormcrowIsOn, type StormcrowPascal} from './lib/stormcrow';
import {VERSION_ID_QUERY_STRING_KEY} from './lib/url_utils';
import {useHasAccessToReplay} from './lib/utils';
import {BrowsePage} from './pages/browse_page/browse_page';
import {Page} from './pages/components/page';
import {PageMissingPageLazy} from './pages/page_missing_page_lazy';
import {PurchaseFlowModals} from './purchase_flow_modals';
import {
  ADMIN_ROUTE_MATCH,
  ADMIN_ROUTE_USER_MATCH,
  BROWSE_FOLDER_ROUTE_MATCH,
  CREATE_ONBOARDING_V2_VIDEO_ROUTE_MATCH,
  CREATING_PROJECT_FROM_FILE_ROUTE_MATCH,
  SETTINGS_ROUTE_MATCH,
  SHARE_FOLDER_ROUTE_MATCH,
  SHARE_FOLDER_SUBFOLDER_ROUTE_MATCH,
  SHARE_FOLDER_VIDEO_ROUTE_MATCH,
  SHARE_ROUTE_MATCH,
  VERSION_COMPARE_ROUTE_MATCH,
  VERSION_COMPARE_ROUTE_SHARE_MATCH,
  VIDEO_ROUTE_MATCH,
} from './route_path_matches';
import {useE2eFeatureIsOn} from './state/e2e_features';
import {grantBooks} from './state/grantbooks';
import {intlShapeAtom} from './state/i18n';

const GlobalStyle = createGlobalStyle`
  /*
    The embedded layout does not have CSS resets.
    Here we are using bare minimum style resets.
  */
  html, body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: var(--dig-color__background__base);
    font-family: var(--type__body__standard--fontfamily);
    font-size: var(--type__body__standard--fontsize);
    font-weight: var(--type__body__standard--fontweight);
    line-height: var(--type__body__standard--lineheight);
    -webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape while allowing user zoom */
  }
`;

const Body = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Container = styled.section`
  /*
    The most inner element where the content is displayed does not have full width/height.
    To fix, we are making our root container absolute with no z-index to position from body.
  */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--dig-color__background__base);
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const AsyncNoAccessPage = lazy(() =>
  import(/* webpackChunkName: 'landing_page' */ './pages/landing_page/landing_page').then(
    (exps) => ({
      default: exps.NoAccessPage,
    }),
  ),
);

const AsyncSendThroughSignIn = lazy(() =>
  import(/* webpackChunkName: 'landing_page' */ './pages/landing_page/landing_page').then(
    (exps) => ({
      default: exps.SendThroughSignIn,
    }),
  ),
);

const ProtectedZone = ({
  children,
  session,
}: React.PropsWithChildren<{
  session: LoggedInContext;
}>) => {
  const hasEnabledFullStory = StormcrowIsOn.useReplayFullstory();
  const [consentChoices, setConsentChoices] = useState(privacyConsentModule.getConsentChoices());
  const [userMadeConsentChoice, setUserMadeConsentChoice] = useState(
    privacyConsentModule.getHasMadeConsentChoice(),
  );
  const accountId = session.currentAccount.account_id;

  useEffect(() => {
    const handleConsentChange = () => {
      setConsentChoices(privacyConsentModule.getConsentChoices());
      setUserMadeConsentChoice(privacyConsentModule.getHasMadeConsentChoice());
    };

    privacyConsentModule.onConsentChange(handleConsentChange);

    return () => {
      privacyConsentModule.removeConsentChangeListener(handleConsentChange);
    };
  }, []);

  // start full story analytics
  useEffect(() => {
    if (hasEnabledFullStory && consentChoices.analytics && userMadeConsentChoice) {
      initializeFullStory(VIDEO_ORG_ID, accountId || undefined);
    } else {
      disableFullStory();
    }

    return () => {
      disableFullStory();
    };
  }, [accountId, hasEnabledFullStory, consentChoices, userMadeConsentChoice]);

  return children;
};

const ProtectedPage = (props: {children: React.ReactElement}) => {
  const sessionState = useReelAppGlobalState();
  const hasAccess = useHasAccessToReplay();

  if (sessionState.status === 'logged in') {
    return hasAccess ? (
      <ProtectedZone session={sessionState}>{props.children}</ProtectedZone>
    ) : (
      <Suspense fallback={null}>
        <AsyncNoAccessPage handleLogoutClick={sessionState.logout} />
      </Suspense>
    );
  }

  return (
    <Suspense fallback={null}>
      <AsyncSendThroughSignIn />
    </Suspense>
  );
};

const GatedPage = ({gate, children}: React.PropsWithChildren<{gate: StormcrowPascal}>) => {
  const allowed = StormcrowIsOn[`use${gate}`]();
  return allowed ? children : <PageMissingPageLazy />;
};

const AsyncLinksPage = lazy(() =>
  import(/* webpackChunkName: 'links_page' */ './pages/links_page/links_page').then((exps) => ({
    default: exps.LinksPage,
  })),
);

const AsyncDownloadPage = lazy(() =>
  import(/* webpackChunkName: 'download_page' */ './pages/download_page').then((exps) => ({
    default: exps.DownloadPage,
  })),
);

const AsyncPasswordPage = lazy(() =>
  import(/* webpackChunkName: 'password_page' */ './pages/password_page').then((exps) => ({
    default: exps.PasswordEntryPage,
  })),
);

const AsyncErrorPage = lazy(() =>
  import(/* webpackChunkName: 'error_page' */ './pages/error_page').then((exps) => ({
    default: exps.ErrorPage,
  })),
);

const AsyncShareFolderPageWithProviders = lazy(() =>
  import(/* webpackChunkName: 'share_folder_page' */ './pages/browse_page/share_folder_page').then(
    (exps) => ({
      default: exps.ShareFolderPageWithProviders,
    }),
  ),
);

const ShareFolderPageWithProviders = () => {
  return (
    <Suspense fallback={null}>
      <AsyncShareFolderPageWithProviders />
    </Suspense>
  );
};

const AsyncLoginCompletePage = lazy(() =>
  import(/* webpackChunkName: 'login_handler_page' */ './LoginHandlerPages').then((exps) => ({
    default: exps.LoginCompletePage,
  })),
);

const AsyncOauthRedirectForBranchDeploys = lazy(() =>
  import(/* webpackChunkName: 'login_handler_page' */ './LoginHandlerPages').then((exps) => ({
    default: exps.OauthRedirectForBranchDeploys,
  })),
);

const AsyncSettingsPage = lazy(() =>
  import(/* webpackChunkName: 'settings_page' */ './pages/settings_page/settings_page').then(
    (exps) => ({
      default: exps.SettingsPage,
    }),
  ),
);

const viewerBundle = import(
  /* webpackChunkName: 'viewer_page' */
  /* webpackPrefetch: true */
  './pages/viewer_page'
);

const AsyncVideoPage = lazy(() =>
  viewerBundle.then((exps) => ({
    default: exps.ViewerPage,
  })),
);

const AsyncSharePage = lazy(() =>
  viewerBundle.then((exps) => ({
    default: exps.SharePage,
  })),
);

const AsyncCreateVideoRedirectPage = lazy(() =>
  viewerBundle.then((exps) => ({
    default: exps.CreateVideoRedirectPage,
  })),
);

const AsyncVersionComparisonPage = lazy(() =>
  import(/* webpackChunkName: 'version_comparison_page' */ './pages/version_comparison_page').then(
    (exps) => ({default: exps.VersionComparisonPage}),
  ),
);

const AsyncCreateOnboardingVideoRedirectPage = lazy(() =>
  import(
    /* webpackChunkName: 'create_onboarding_video_redirect_page' */ './pages/create_onboarding_video_redirect_page'
  ).then((exps) => ({
    default: exps.CreateOnboardingVideoRedirectPage,
  })),
);

const AsyncAdminUserPage = lazy(() =>
  import(/* webpackChunkName: 'admin_page' */ './pages/admin_console_page/admin_user_page').then(
    (exps) => ({
      default: exps.AdminUserPage,
    }),
  ),
);

const AsyncProToolsConnectModal = lazy(() =>
  import(
    /* webpackChunkName: 'pro_tools_connect_modal' */ './components/pro_tools_connect_modal'
  ).then((exps) => ({
    default: exps.ProToolsConnectModal,
  })),
);

const AsyncPurchasePage = lazy(() =>
  import(/* webpackChunkName: 'purchase_page' */ './pages/purchase/purchase_page').then((exps) => ({
    default: exps.PurchasePage,
  })),
);

const AsyncInAppPurchasePage = lazy(() =>
  import(
    /* webpackChunkName: 'in_app_purchase_page' */ './pages/purchase/in_app_purchase_page'
  ).then((exps) => ({
    default: exps.InAppPurchasePage,
  })),
);

const E2eGetTestUserPage = lazy(() =>
  import(/* webpackChunkName: 'e2e_page' */ './pages/e2e/get_test_user').then((exps) => ({
    default: exps.E2eGetTestUserPage,
  })),
);

const E2eLockTestUserPage = lazy(() =>
  import(/* webpackChunkName: 'e2e_page' */ './pages/e2e/lock_test_user').then((exps) => ({
    default: exps.E2eLockTestUserPage,
  })),
);

const E2eReleaseTestUserPage = lazy(() =>
  import(/* webpackChunkName: 'e2e_page' */ './pages/e2e/release_test_user').then((exps) => ({
    default: exps.E2eReleaseTestUserPage,
  })),
);

const AsyncSegmentationQuizPage = lazy(() =>
  import(/* webpackChunkName: 'segmentation_quiz_page' */ './pages/segmentation_quiz').then(
    (exps) => ({
      default: exps.QuizPage,
    }),
  ),
);

const AsyncSegmentationTeamTypeQuiz = lazy(() =>
  import(/* webpackChunkName: 'team_type_quiz' */ './pages/segmentation_quiz').then((exps) => ({
    default: exps.TeamTypeQuiz,
  })),
);

const AsyncSegmentationStorageTypeQuiz = lazy(() =>
  import(/* webpackChunkName: 'storage_type_quiz' */ './pages/segmentation_quiz').then((exps) => ({
    default: exps.StorageTypeQuiz,
  })),
);

const AsyncSegmentationIndustryTypeQuiz = lazy(() =>
  import(/* webpackChunkName: 'industry_type_quiz' */ './pages/segmentation_quiz').then((exps) => ({
    default: exps.IndustryTypeQuiz,
  })),
);

const AppRoute = ({children}: React.PropsWithChildren<{}>) => (
  <AppPage>
    <Suspense fallback={null}>{children}</Suspense>
  </AppPage>
);

const InitializedApp = ({
  sessionState,
  children,
}: React.PropsWithChildren<{sessionState: InitialSessionState}>) => {
  return (
    <SessionContext.Provider value={sessionState}>
      <PostPurchaseListener />
      <MetricsListener />
      <StormcrowExposureLogger />
      <GrowthBookProvider>
        <ExtensionProvider>
          <ViewportProvider>
            <OnboardingProvider sessionState={sessionState}>
              <UploadWrapper>
                <RenderProvider>
                  <PasswordCacheProvider>
                    <Container>{children}</Container>
                    {checkPithosPrivacyConsentFlag() ? null : <CookieSnackBar />}
                    <ErrorSnackbar />
                    <PurchaseFlowModals sessionState={sessionState} />
                    <ConnectedUploadDrawer />
                    <Suspense fallback={null}>
                      <AsyncProToolsConnectModal />
                    </Suspense>
                    <LazyDebugPanel />
                  </PasswordCacheProvider>
                </RenderProvider>
              </UploadWrapper>
            </OnboardingProvider>
          </ViewportProvider>
        </ExtensionProvider>
      </GrowthBookProvider>
    </SessionContext.Provider>
  );
};

const ErrorPage = () => {
  return (
    <Suspense fallback={null}>
      <AsyncErrorPage errorToReport="OPT_OUT_OF_ERROR_REPORTING" />
    </Suspense>
  );
};

const LocalizedTitlePage = ({
  title,
  children,
}: React.PropsWithChildren<{title: keyof typeof appStrings}>) => {
  const intl = useIntl();
  return <Page title={intl.formatMessage(appStrings[title])}>{children}</Page>;
};

function Layout({children}: React.PropsWithChildren<{}>) {
  // While we wait for translations to load, we use these defaults, which will
  // fall back to the defaultMessage for each string
  const rawIntl = useAtomValue(intlShapeAtom);
  const showDevTools = useE2eFeatureIsOn('show_devtools');

  return (
    <ThemeProvider mode="dark" theme="vis2023">
      <ThemeContainer>
        <StyleSheetManager enableVendorPrefixes>
          <Body>
            {import.meta.env.PROD || !showDevTools ? null : (
              <>
                <ReactQueryDevtools initialIsOpen={false} />
              </>
            )}
            <RawIntlProvider value={rawIntl}>
              <GlobalStyle />
              <ErrorBoundary ErrorHandler={ErrorPage}>{children}</ErrorBoundary>
            </RawIntlProvider>
          </Body>
        </StyleSheetManager>
      </ThemeContainer>
    </ThemeProvider>
  );
}

function AppPage({children}: React.PropsWithChildren<{}>) {
  return (
    <Layout>
      <AppInitializer
        render={(sessionState) => (
          <InitializedApp sessionState={sessionState}>{children}</InitializedApp>
        )}
      />
    </Layout>
  );
}

const sentryBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryBrowserRouter([
  {
    path: '/',
    errorElement: (
      <Layout>
        <ErrorPage />
      </Layout>
    ),
    children: [
      {
        index: true,
        element: (
          <AppRoute>
            <ProtectedPage>
              <LocalizedTitlePage title="homeTitle">
                <BrowsePage routeName="browse-home" />
              </LocalizedTitlePage>
            </ProtectedPage>
          </AppRoute>
        ),
      },
      {
        path: '/onboarding',
        element: (
          <AppRoute>
            <ProtectedPage>
              <LocalizedTitlePage title="segmentationQuizTitle">
                <AsyncSegmentationQuizPage />
              </LocalizedTitlePage>
            </ProtectedPage>
          </AppRoute>
        ),
        children: [
          {
            index: true,
            element: <Navigate replace to="/onboarding/use-case" />,
          },
          {
            element: <AsyncSegmentationTeamTypeQuiz />,
            path: 'use-case',
          },
          {
            path: 'storage-type',
            element: <AsyncSegmentationStorageTypeQuiz />,
          },
          {
            path: 'industry-type',
            element: <AsyncSegmentationIndustryTypeQuiz />,
          },
        ],
      },
      {
        path: '/oauth',
        element: (
          <AppRoute>
            <AsyncLoginCompletePage />
          </AppRoute>
        ),
      },
      {
        path: '/download',
        element: (
          <AppRoute>
            <AsyncDownloadPage />
          </AppRoute>
        ),
      },
      {
        path: '/shared',
        element: (
          <AppRoute>
            <ProtectedPage>
              <LocalizedTitlePage title="sharedTitle">
                <BrowsePage routeName="browse-shared" />
              </LocalizedTitlePage>
            </ProtectedPage>
          </AppRoute>
        ),
      },
      {
        path: BROWSE_FOLDER_ROUTE_MATCH,
        element: (
          <AppRoute>
            <ProtectedPage>
              <LocalizedTitlePage title="browseTitle">
                <BrowsePage routeName="browse-home" />
              </LocalizedTitlePage>
            </ProtectedPage>
          </AppRoute>
        ),
      },
      {
        path: '/shared/folder/:folderId',
        element: (
          <AppRoute>
            <ProtectedPage>
              <LocalizedTitlePage title="browseTitle">
                <BrowsePage routeName="browse-shared" />
              </LocalizedTitlePage>
            </ProtectedPage>
          </AppRoute>
        ),
      },
      {
        path: '/links',
        element: (
          <AppRoute>
            <ProtectedPage>
              <LocalizedTitlePage title="linksTitle">
                <AsyncLinksPage />
              </LocalizedTitlePage>
            </ProtectedPage>
          </AppRoute>
        ),
      },
      {
        path: VIDEO_ROUTE_MATCH,
        element: (
          <AppRoute>
            <ProtectedPage>
              <AsyncVideoPage />
            </ProtectedPage>
          </AppRoute>
        ),
      },
      {
        path: VERSION_COMPARE_ROUTE_MATCH,
        element: (
          <AppRoute>
            <ProtectedPage>
              <AsyncVersionComparisonPage />
            </ProtectedPage>
          </AppRoute>
        ),
      },
      {
        path: VERSION_COMPARE_ROUTE_SHARE_MATCH,
        element: (
          <AppRoute>
            <ProtectedPage>
              <AsyncVersionComparisonPage />
            </ProtectedPage>
          </AppRoute>
        ),
      },
      {
        path: CREATING_PROJECT_FROM_FILE_ROUTE_MATCH,
        element: (
          <AppRoute>
            <ProtectedPage>
              <AsyncCreateVideoRedirectPage />
            </ProtectedPage>
          </AppRoute>
        ),
      },
      {
        path: CREATE_ONBOARDING_V2_VIDEO_ROUTE_MATCH,
        element: (
          <AppRoute>
            <ProtectedPage>
              <AsyncCreateOnboardingVideoRedirectPage />
            </ProtectedPage>
          </AppRoute>
        ),
      },
      {
        path: '/oauth_redirect',
        element: (
          <AppRoute>
            <AsyncOauthRedirectForBranchDeploys />
          </AppRoute>
        ),
      },
      {
        path: '/pw',
        element: (
          <AppRoute>
            <AsyncPasswordPage />
          </AppRoute>
        ),
      },
      {
        path: SHARE_ROUTE_MATCH,

        loader: ({
          request,
          params: {shareToken, videoId = ''},
        }: {
          params: Params<'shareToken' | 'videoId'>;
          request: Request;
        }) => {
          const url = new URL(request.url);
          const videoVersionId = url.searchParams.get(VERSION_ID_QUERY_STRING_KEY);
          const token = check(shareToken, 'share route with no share-token');
          const queryOptions = getVideoFromShortLinkQueryConfig({
            videoId,
            videoVersionId: videoVersionId ?? '',
            shareToken: token,
            grantBook: grantBooks.get(token),
          });
          queryClient.prefetchQuery(queryOptions);
          return null;
        },
        element: (
          <AppRoute>
            <AsyncSharePage />
          </AppRoute>
        ),
      },
      {
        /* Route for subfolder within shared link folder */
        path: SHARE_FOLDER_SUBFOLDER_ROUTE_MATCH,
        element: (
          <AppRoute>
            <ShareFolderPageWithProviders />
          </AppRoute>
        ),
      },
      {
        /* Route for top-level shared link folder; no folder id required */
        path: SHARE_FOLDER_ROUTE_MATCH,
        element: (
          <AppRoute>
            <ShareFolderPageWithProviders />
          </AppRoute>
        ),
      },
      {
        path: SHARE_FOLDER_VIDEO_ROUTE_MATCH,
        element: (
          <AppRoute>
            <AsyncSharePage />
          </AppRoute>
        ),
      },
      {
        path: SETTINGS_ROUTE_MATCH,
        element: (
          <AppRoute>
            <ProtectedPage>
              <AsyncSettingsPage />
            </ProtectedPage>
          </AppRoute>
        ),
      },
      {
        path: ADMIN_ROUTE_MATCH,
        loader: () => {
          return redirect(SETTINGS_ROUTE_MATCH);
        },
      },
      {
        path: ADMIN_ROUTE_USER_MATCH,
        element: (
          <AppRoute>
            <ProtectedPage>
              <AsyncAdminUserPage />
            </ProtectedPage>
          </AppRoute>
        ),
      },
      {
        path: '/buy',
        element: (
          <AppRoute>
            <ProtectedPage>
              <AsyncPurchasePage />
            </ProtectedPage>
          </AppRoute>
        ),
      },
      {
        path: '/buy/checkout',
        element: (
          <AppRoute>
            <ProtectedPage>
              <GatedPage gate="ReplayInAppPurchase">
                <AsyncInAppPurchasePage />
              </GatedPage>
            </ProtectedPage>
          </AppRoute>
        ),
      },
      {
        path: '/e2e/get-test-user',
        element: (
          <AppRoute>
            <E2eGetTestUserPage />
          </AppRoute>
        ),
      },
      {
        path: '/e2e/lock-test-user',
        element: (
          <AppRoute>
            <E2eLockTestUserPage />
          </AppRoute>
        ),
      },
      {
        path: '/e2e/release-test-user',
        element: (
          <AppRoute>
            <E2eReleaseTestUserPage />
          </AppRoute>
        ),
      },
      {
        path: '*',
        element: (
          <AppRoute>
            <PageMissingPageLazy />
          </AppRoute>
        ),
      },
    ],
  },
]);

const App = () => {
  useMetrics();

  useEffect(() => {
    document.documentElement.classList.add('dig-Theme--dark');
  }, []);

  return (
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </React.StrictMode>
  );
};

export default App;
