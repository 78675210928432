import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';

import {Box} from '@dropbox/dig-foundations';
import {PictogramIcon} from '@dropbox/dig-icons';
import {AddLine} from '@dropbox/dig-icons/assets';

import {StylelessButton} from '~/components/button';
import {color, radius, spacing} from '~/components/styled';
import {useIsTeamLocked} from '~/lib/utils';
import {
  type onCreateFolderSuccessFn,
  useCreateProject,
} from '~/pages/browse_page/use_create_project';

const TileWrapper = styled(StylelessButton)`
  color: ${color('Text Base')};

  border-style: dashed;
  border-color: ${color('Border Subtle')};
  border-radius: ${radius('Medium')};

  &:hover:not(:disabled) {
    border-color: ${color('Border Base')};
  }

  &:disabled {
    color: ${color('Disabled Base')};
  }

  &:focus,
  &:focus-visible {
    border-radius: ${radius('Medium')};
    box-shadow: inset 0 0 0 ${spacing('2')} ${color('Utility Focus Ring')};
  }
`;

const TileContainer = styled(Box)`
  cursor: pointer;
  flex-grow: 1;
  padding: 26px 0;
  max-height: 144px;
`;

const TileContent = styled(Box)`
  display: grid;
  grid-template-rows: ${spacing('44')} 1fr;
  align-items: center;
  justify-content: center;
  grid-gap: ${spacing('12')};
  max-width: 200px;
  text-wrap: wrap;
`;

type CreateProjectTileProps = {
  disabled?: boolean;
  onCreateFolderSuccess?: onCreateFolderSuccessFn;
};
const CreateProjectTile = ({disabled, onCreateFolderSuccess}: CreateProjectTileProps) => {
  const isTeamLocked = useIsTeamLocked();
  const {createFolderModal, handleOpenCreateFolderModal} = useCreateProject({
    onCreateFolderSuccess,
    actionElement: 'content_tiles',
  });

  return (
    <>
      <TileWrapper
        disabled={disabled || isTeamLocked}
        onClick={handleOpenCreateFolderModal}
        role="cell"
      >
        <TileContainer
          alignItems="center"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <TileContent>
            <Box display="flex" justifyContent="center" width="100%">
              <PictogramIcon src={AddLine} />
            </Box>

            <FormattedMessage
              defaultMessage="Organize files in dedicated project spaces."
              description="Tile prompting user to create a project"
              id="1VAB0k"
            />
          </TileContent>
        </TileContainer>
      </TileWrapper>
      {createFolderModal}
    </>
  );
};

export {CreateProjectTile};
