// These are just translated to be using the new client SDK
import type {
  comments2,
  comments2_common,
  files,
  media_addon,
  reactions,
  reel,
  stickers,
} from '@dropbox/api-v2-client';
import type {DropboxResponse} from '@dropbox/api-v2-client';
import {DropboxResponseError} from '@dropbox/api-v2-client';

import type {CommentID, ThreadID} from '~/lib/comments/components/types/index';
import type {Stream} from '~/lib/comments/types';
import {replayStorage} from '~/lib/storage';

import {getDefaultUserClient} from '../../client';

export class Comments2Error {
  public readonly code: ErrorCode;
  public readonly message?: string;
  public readonly apiError: any; //TODO: was ApiError

  constructor(code: ErrorCode, message: string | undefined, apiError: any) {
    this.code = code;
    this.message = message;
    this.apiError = apiError;
  }
}

// Keep this in sync with `union Error` in api/spec/private/comments2_routes.Stone
// Should be equivalent to `Exclude<comments2.Error['.tag'], 'other'>`
export const enum ErrorCode {
  FILE_NOT_FOUND = 'file_not_found',
  PERMISSION_DENIED = 'permission_denied',
  BAD_REQUEST = 'bad_request',
}

export type NormalComments2LogEvent = Exclude<Comments2LogEvent, 'comment_error'>;

type PotentialError = DropboxResponseError<any> | Error;

function handleApiError(err: PotentialError, fallbackMessage: string) {
  if (err instanceof DropboxResponseError) {
    let msg = fallbackMessage;
    if (err.error.message) {
      msg += `. Detailed message: ${err.error.message.toString()}`;
    }
    return new Comments2Error(err.error!['.tag'], msg, err);
  }
  return err;
}

function adaptStream(stream: Stream): comments2.Stream {
  if (stream.reel_id) {
    const reelType = {'.tag': 'reel_video'} as comments2.StreamTypeReelVideo;
    return {type: reelType, identifier: {'.tag': 'reel_video_id', reel_video_id: stream.reel_id}};
  }

  const type = {'.tag': stream.type} as comments2.StreamType;

  // right now we only have file streams, so no need to check, but in the future
  // we would need to switch based on the stream.type
  if (stream.linkUrl) {
    return {type, identifier: {'.tag': 'shared_link_details', url: stream.linkUrl}};
  } else {
    return {type, identifier: {'.tag': 'file_path_or_id', file_path_or_id: stream.id}};
  }
}

export interface ReelVideoIdIdentifier {
  video_version_id: string;
  grant_book?: string;
  share_token?: string;
  replay_session_id: string | null;
  guest_session_jwt?: string;
}

// Helper to create a ReelStream object from a comments2.Stream object, as we currently only
// use these types for guest user requests. This also makes sure we always inject the latest
// guest_session_jwt available into the identifier.
function createReelStream(stream: comments2.Stream): reel.Stream {
  const identifier = stream.identifier as comments2.StreamIdentifierReelVideoId;
  return {
    stream_type: {'.tag': 'reel_video_version'},
    identifier: {
      identifier: {
        '.tag': 'video_version_id',
        video_version_id: JSON.stringify({
          ...JSON.parse(identifier.reel_video_id),
          // This ensures that the identifier always include the most up-to-date JWT
          // Otherwise, there are issues where the stream stored in the Comments2Api is stale
          // and doesn't update the video_version_id with the latest expected JWT.
          guest_session_jwt: replayStorage.getGuestSessionJWT(),
        }),
      },
    },
  };
}

export type Comments2LogEvent = Exclude<comments2.Event['.tag'], 'other'>;
export type Comments2LogPerfEvent = Exclude<comments2.PerfEvent, comments2.PerfEventOther>;
export type Comments2LogPerfEventSimpleTags = Exclude<
  comments2.PerfEvent['.tag'],
  'thread_action_completed_ms' | 'comment_action_completed_ms' | 'other'
>;

export class Comments2LoggedOutApi {
  private stream: comments2.Stream;

  constructor(stream: comments2.Stream | Stream) {
    if (stream.type === 'file') {
      this.stream = adaptStream(stream);
    } else {
      this.stream = stream;
    }
  }

  listComments(
    includePermissions: boolean = false,
    cursor?: string,
  ): Promise<comments2.ListThreadsResult> {
    return getDefaultUserClient()
      .comments2LoggedOutListComments({
        stream: this.stream,
        cursor: cursor,
        include_permissions: includePermissions,
      })
      .then((res: DropboxResponse<comments2.ListThreadsResult>) => res.result)
      .catch((err: PotentialError) => {
        throw handleApiError(err, '/logged_out/list_comments did not complete successfully');
      });
  }

  listCommentsBatch(
    previousVersions: Array<comments2.Stream>,
    includePermissions: boolean = false,
  ): Promise<comments2.BatchListThreadsResult> {
    return getDefaultUserClient()
      .comments2LoggedOutListCommentsBatch({
        streams: previousVersions,
        include_permissions: includePermissions,
      })
      .then((res: DropboxResponse<comments2.BatchListThreadsResult>) => res.result)
      .catch((err: PotentialError) => {
        throw handleApiError(err, '/list_comments_batch did not complete successfully');
      });
  }

  createThread(
    comment: comments2.UserSubmittedComment,
    source?: comments2.Source,
    pastStream?: comments2.Stream,
  ): Promise<comments2.AcknowledgementResult> {
    // Only for the "reel_video" case, we're now calling a different endpoint
    if (this.stream.type['.tag'] == 'reel_video') {
      return this.createGuestThread(comment, undefined, pastStream);
    }

    return getDefaultUserClient()
      .comments2LoggedOutAddComment({
        stream: this.stream,
        comment,
        source,
      })
      .then((res: DropboxResponse<comments2.AcknowledgementResult>) => res.result)
      .catch((err: PotentialError) => {
        throw handleApiError(
          err,
          '/logged_out/add_comment (new comment) did not complete successfully',
        );
      });
  }

  replyToThread(
    comment: comments2.UserSubmittedComment,
    threadId: ThreadID,
    pastStream?: comments2.Stream,
  ): Promise<comments2.AcknowledgementResult> {
    // Only for the "reel_video" case, we're now calling a different endpoint
    if (this.stream.type['.tag'] == 'reel_video') {
      return this.createGuestThread(comment, threadId, pastStream);
    }
    return getDefaultUserClient()
      .comments2LoggedOutAddComment({
        stream: pastStream ?? this.stream,
        comment,
        thread_id: threadId,
      })
      .then((res: DropboxResponse<comments2.AcknowledgementResult>) => res.result)
      .catch((err: PotentialError) => {
        throw handleApiError(
          err,
          '/logged_out/add_comment (reply comment) did not complete successfully',
        );
      });
  }

  editComment(
    commentId: string,
    comment: comments2.UserSubmittedComment,
    pastStream?: comments2.Stream,
  ): Promise<comments2.AcknowledgementResult> {
    if (this.stream.type['.tag'] != 'reel_video') {
      return Promise.reject(
        'Only reel_video streams are supported for editComment for logged out users',
      );
    } else if (pastStream && pastStream.type['.tag'] != 'reel_video') {
      return Promise.reject(
        'Only reel_video streams are supported for editComment for logged out users',
      );
    }

    return getDefaultUserClient()
      .reelGuestEditComment({
        stream: pastStream ? createReelStream(pastStream) : createReelStream(this.stream),
        comment_id: commentId,
        comment: this.commentToRequest(comment),
      })
      .then((res: DropboxResponse<reel.EditCommentResult>) => {
        return {id: res.result.comment_id!};
      })
      .catch((err: PotentialError) => {
        throw handleApiError(
          err,
          '/reel/guest_edit_comment (edit comment) did not complete successfully',
        );
      });
  }

  deleteComment(
    commentId: CommentID,
    pastStream?: comments2.Stream,
  ): Promise<comments2.AcknowledgementResult> {
    if (this.stream.type['.tag'] != 'reel_video') {
      return Promise.reject(
        'Only reel_video streams are supported for deleteComment for logged out users',
      );
    } else if (pastStream && pastStream.type['.tag'] != 'reel_video') {
      return Promise.reject(
        'Only reel_video streams are supported for deleteComment for logged out users',
      );
    }

    return getDefaultUserClient()
      .reelGuestDeleteComment({
        stream: pastStream ? createReelStream(pastStream) : createReelStream(this.stream),
        comment_id: commentId,
      })
      .then((res: DropboxResponse<reel.DeleteCommentResult>) => {
        return {id: res.result.comment_id!};
      })
      .catch((err: PotentialError) => {
        throw handleApiError(
          err,
          '/reel/guest_delete_comment (delete comment) did not complete successfully',
        );
      });
  }

  logPerfEvent(
    event: Comments2LogPerfEvent,
    value: number,
    options: Partial<{threadId: ThreadID; commentId: CommentID; oref: string}> = {},
  ) {
    return this.logPerfEventImpl(event, value, options);
  }

  private logPerfEventImpl(
    event: Comments2LogPerfEvent,
    value: number,
    {
      threadId,
      commentId,
      oref,
      errorMessage,
    }: Partial<{threadId: ThreadID; commentId: CommentID; oref: string; errorMessage: string}>,
  ): Promise<DropboxResponse<void>> {
    const opts = {
      event,
      value: value,
      stream: this.stream,
      thread_id: threadId,
      comment_id: commentId,
      error_message: errorMessage,
      oref,
    };
    return getDefaultUserClient()
      .comments2LogPerfEvent(opts)
      .catch((err: PotentialError) => {
        throw handleApiError(err, '/log_perf_event did not complete successfully');
      });
  }

  private commentToRequest(comment: comments2.UserSubmittedComment) {
    const time_sec =
      comment.annotation_data?.['.tag'] === 'video'
        ? (comment.annotation_data as comments2.VideoAnnotationReference).time_sec
        : undefined;
    const out_time_sec =
      comment.annotation_data?.['.tag'] === 'video'
        ? (comment.annotation_data as comments2.VideoAnnotationReference).out_time_sec
        : undefined;

    const guestComment: reel.GuestSubmittedComment = {
      content: comment.content,
      metadata: comment.metadata,
      annotation_data_json: comment.metadata?.reel?.annotation_data,
      page_number: comment.metadata?.reel?.page_number,
      time_sec: time_sec,
      out_time_sec: out_time_sec,
      pin_point: comment.metadata?.reel?.pin_point,
    };

    return guestComment;
  }

  // Internal method to use the new GuestAddComment method instead
  private createGuestThread(
    comment: comments2.UserSubmittedComment,
    thread?: ThreadID,
    pastStream?: comments2.Stream,
  ): Promise<comments2.AcknowledgementResult> {
    return getDefaultUserClient()
      .reelGuestAddComment({
        stream: pastStream ? createReelStream(pastStream) : createReelStream(this.stream),
        comment: this.commentToRequest(comment),
        thread_id: thread,
      })
      .then((res: DropboxResponse<reel.GuestAddCommentResult>) => {
        if (res.result.guest_session_jwt) {
          replayStorage.setGuestSessionJWT(res.result.guest_session_jwt);
        }
        return {id: res.result.comment_id!};
      })
      .catch((err: PotentialError) => {
        throw handleApiError(
          err,
          '/reel/guest_add_comment (new comment) did not complete successfully',
        );
      });
  }
}

export class Comments2Api {
  private stream: comments2.Stream;

  constructor(stream: comments2.Stream | Stream) {
    if (stream.type === 'file') {
      this.stream = adaptStream(stream);
    } else {
      this.stream = stream;
    }
  }

  getStickers(): Promise<stickers.GetStickersResult> {
    return getDefaultUserClient()
      .stickersGetStickers()
      .then((res: DropboxResponse<stickers.GetStickersResult>) => res.result)
      .catch((err: PotentialError) => {
        throw handleApiError(err, '/get_stickers did not complete successfully');
      });
  }

  getMediaFeatures(): Promise<media_addon.GetFeaturesResult> {
    return getDefaultUserClient()
      .mediaAddonGetFeatures()
      .then((res: DropboxResponse<media_addon.GetFeaturesResult>) => res.result)
      .catch((err: PotentialError) => {
        throw handleApiError(err, '/get_features did not complete successfully');
      });
  }

  getFileMetadata(fileId: string, linkUrl?: string): Promise<files.GetFileContentMetadataResult> {
    return getDefaultUserClient()
      .filesGetFileContentMetadata({
        file_path_or_id: fileId,
        url: linkUrl,
      })
      .then((res: DropboxResponse<files.GetFileContentMetadataResult>) => res.result)
      .catch((err: PotentialError) => {
        throw handleApiError(err, '/get_file_content_metadata did not complete successfully');
      });
  }

  checkOnboardingStatus(): Promise<comments2.OnboardingResult> {
    return getDefaultUserClient()
      .comments2CheckOnboardingStatus()
      .then((res: DropboxResponse<comments2.OnboardingResult>) => res.result)
      .catch((err: PotentialError) => {
        throw handleApiError(err, '/check_onboarding_status did not complete successfully');
      });
  }

  createThread(
    comment: comments2.UserSubmittedComment,
    source?: comments2.Source,
  ): Promise<comments2.AcknowledgementResult> {
    return getDefaultUserClient()
      .comments2AddComment({
        stream: this.stream,
        comment,
        source,
      })
      .then((res: DropboxResponse<comments2.AcknowledgementResult>) => res.result)
      .catch((err: PotentialError) => {
        throw handleApiError(err, '/add_comment (new comment) did not complete successfully');
      });
  }

  createCommentInternal(
    comment: reel.UserSubmittedComment,
    video_timestamp?: number,
    video_timestamp_out?: number,
    page_number?: number,
    annotation_drawing_data?: string,
    mention_metadata?: Array<comments2_common.MentionMetadata>,
    is_quick_reaction?: boolean,
    is_pinned_comment?: boolean,
    pinned_label?: string,
    pin_point?: comments2_common.ReelRegion,
  ): Promise<reel.AddCommentResult> {
    const addCommentInternalArgs: reel.AddCommentInternalArg = {
      stream: createReelStream(this.stream),
      comment,
      video_timestamp,
      video_timestamp_out,
      page_number,
      annotation_drawing_data,
      mention_metadata,
      is_quick_reaction,
      is_pinned_comment,
      pinned_label,
      pin_point,
    };

    return getDefaultUserClient()
      .reelAddCommentInternal(addCommentInternalArgs)
      .then((res: DropboxResponse<reel.AddCommentResult>) => res.result)
      .catch((err: PotentialError) => {
        throw handleApiError(err, '/add_comment_internal did not complete successfully');
      });
  }

  deleteComment(
    commentId: CommentID,
    pastStream?: comments2.Stream,
  ): Promise<comments2.AcknowledgementResult> {
    return getDefaultUserClient()
      .comments2DeleteComment({
        stream: pastStream ?? this.stream,
        id: commentId,
      })
      .then((res: DropboxResponse<comments2.AcknowledgementResult>) => res.result)
      .catch((err: PotentialError) => {
        throw handleApiError(err, '/delete_comment did not complete successfully');
      });
  }

  disableCommenting(): Promise<comments2.AcknowledgementResult> {
    return getDefaultUserClient()
      .comments2DisableCommenting({
        stream: this.stream,
      })
      .then((res: DropboxResponse<comments2.AcknowledgementResult>) => res.result)
      .catch((err: PotentialError) => {
        throw handleApiError(err, '/disable_commenting did not complete successfully');
      });
  }

  editComment(
    commentId: CommentID,
    comment: comments2.UserSubmittedComment,
    pastStream?: comments2.Stream,
  ): Promise<comments2.AcknowledgementResult> {
    return getDefaultUserClient()
      .comments2EditComment({
        stream: pastStream ?? this.stream,
        id: commentId,
        comment,
      })
      .then((res: DropboxResponse<comments2.AcknowledgementResult>) => res.result)
      .catch((err: PotentialError) => {
        throw handleApiError(err, '/edit_comment did not complete successfully');
      });
  }

  enableCommenting(): Promise<comments2.AcknowledgementResult> {
    return getDefaultUserClient()
      .comments2EnableCommenting({
        stream: this.stream,
      })
      .then((res: DropboxResponse<comments2.AcknowledgementResult>) => res.result)
      .catch((err: PotentialError) => {
        throw handleApiError(err, '/enable_commenting did not complete successfully');
      });
  }

  listComments(
    includePermissions: boolean = false,
    cursor?: string,
  ): Promise<comments2.ListThreadsResult> {
    return getDefaultUserClient()
      .comments2ListComments({
        stream: this.stream,
        cursor: cursor,
        include_permissions: includePermissions,
      })
      .then((res: DropboxResponse<comments2.ListThreadsResult>) => res.result)
      .catch((err: PotentialError) => {
        throw handleApiError(err, '/list_comments did not complete successfully');
      });
  }

  listThreadsInternal(): Promise<reel.ListThreadsInternalResult> {
    return getDefaultUserClient()
      .reelListThreadsInternal({
        stream: createReelStream(this.stream),
      })
      .then((res: DropboxResponse<reel.ListThreadsInternalResult>) => res.result)
      .catch((err: PotentialError) => {
        throw handleApiError(err, '/list_comments_internal did not complete successfully');
      });
  }

  listCommentsBatch(
    previousVersions: Array<comments2.Stream>,
    includePermissions: boolean = false,
  ): Promise<comments2.BatchListThreadsResult> {
    return getDefaultUserClient()
      .comments2ListCommentsBatch({
        streams: previousVersions,
        include_permissions: includePermissions,
      })
      .then((res: DropboxResponse<comments2.BatchListThreadsResult>) => res.result)
      .catch((err: PotentialError) => {
        throw handleApiError(err, '/list_comments_batch did not complete successfully');
      });
  }

  listThreadsInternalBatch(
    previousVersions: Array<comments2.Stream>,
  ): Promise<reel.ListThreadsInternalBatchResult> {
    return getDefaultUserClient()
      .reelListThreadsInternalBatch({
        streams: previousVersions.map(createReelStream),
      })
      .then((res: DropboxResponse<reel.ListThreadsInternalBatchResult>) => res.result)
      .catch((err: PotentialError) => {
        throw handleApiError(err, '/list_threads_internal_batch did not complete successfully');
      });
  }

  logPerfEvent(event: Comments2LogPerfEvent, value: number) {
    return this.logPerfEventImpl(event, value);
  }

  logSimplePerfEvent(event: Comments2LogPerfEventSimpleTags, value: number) {
    return this.logPerfEvent({'.tag': event} as Comments2LogPerfEvent, value);
  }

  private logPerfEventImpl(
    event: Comments2LogPerfEvent,
    value: number,
  ): Promise<DropboxResponse<void>> {
    const opts = {
      event,
      value: value,
      stream: this.stream,
    };
    return getDefaultUserClient()
      .comments2LogPerfEvent(opts)
      .catch((err: PotentialError) => {
        throw handleApiError(err, '/log_perf_event did not complete successfully');
      });
  }

  markThreadRead(threadId: ThreadID): Promise<comments2.AcknowledgementResult> {
    return getDefaultUserClient()
      .comments2MarkThreadRead({
        stream: this.stream,
        id: threadId,
      })
      .then((res: DropboxResponse<comments2.AcknowledgementResult>) => res.result)
      .catch((err: PotentialError) => {
        throw handleApiError(err, '/mark_thread_read did not complete successfully');
      });
  }

  markThreadUnread(threadId: ThreadID): Promise<comments2.AcknowledgementResult> {
    return getDefaultUserClient()
      .comments2MarkThreadUnread({
        stream: this.stream,
        id: threadId,
      })
      .then((res: DropboxResponse<comments2.AcknowledgementResult>) => res.result)
      .catch((err: PotentialError) => {
        throw handleApiError(err, '/mark_thread_unread did not complete successfully');
      });
  }

  markThreadResolved(
    threadId: ThreadID,
    pastStream?: comments2.Stream,
  ): Promise<comments2.AcknowledgementResult> {
    return getDefaultUserClient()
      .comments2ResolveThread({
        stream: pastStream ?? this.stream,
        id: threadId,
      })
      .then((res: DropboxResponse<comments2.AcknowledgementResult>) => res.result)
      .catch((err: PotentialError) => {
        throw handleApiError(err, '/resolve_thread did not complete successfully');
      });
  }

  markThreadResolvedInternal(
    threadId: ThreadID,
    pastStream?: comments2.Stream,
    versionNum?: number,
  ): Promise<reel.ResolveThreadInternalResponse> {
    const stream = pastStream ?? this.stream;
    return getDefaultUserClient()
      .reelResolveThreadInternal({
        stream: createReelStream(stream),
        comment_id: threadId,
        version_num: versionNum,
      })
      .then((res: DropboxResponse<reel.ResolveThreadInternalResponse>) => res.result)
      .catch((err: PotentialError) => {
        throw handleApiError(err, '/resolve_thread did not complete successfully');
      });
  }

  markThreadUnresolved(
    threadId: ThreadID,
    pastStream?: comments2.Stream,
  ): Promise<comments2.AcknowledgementResult> {
    return getDefaultUserClient()
      .comments2UnresolveThread({
        stream: pastStream ?? this.stream,
        id: threadId,
      })
      .then((res: DropboxResponse<comments2.AcknowledgementResult>) => res.result)
      .catch((err: PotentialError) => {
        throw handleApiError(err, '/unresolve_thread did not complete successfully');
      });
  }

  markThreadUnresolvedInternal(
    threadId: ThreadID,
    pastStream?: comments2.Stream,
  ): Promise<reel.UnresolveThreadInternalResponse> {
    const stream = pastStream ?? this.stream;
    return getDefaultUserClient()
      .reelUnresolveThreadInternal({
        stream: createReelStream(stream),
        comment_id: threadId,
      })
      .then((res: DropboxResponse<reel.UnresolveThreadInternalResponse>) => res.result)
      .catch((err: PotentialError) => {
        throw handleApiError(err, '/unresolve_thread did not complete successfully');
      });
  }

  replyToThread(
    comment: comments2.UserSubmittedComment,
    threadId: ThreadID,
    pastStream?: comments2.Stream | undefined,
  ): Promise<comments2.AcknowledgementResult> {
    return getDefaultUserClient()
      .comments2AddComment({
        stream: pastStream ?? this.stream,
        comment,
        thread_id: threadId,
      })
      .then((res: DropboxResponse<comments2.AcknowledgementResult>) => res.result)
      .catch((err: PotentialError) => {
        throw handleApiError(err, '/add_comment (reply comment) did not complete successfully');
      });
  }

  replyToThreadInternal(
    comment: reel.UserSubmittedComment,
    threadId: ThreadID,
    video_timestamp?: number,
    video_timestamp_out?: number,
    page_number?: number,
    annotation_drawing_data?: string,
    mention_metadata?: Array<comments2_common.MentionMetadata>,
    is_quick_reaction?: boolean,
    is_pinned_comment?: boolean,
    pinned_label?: string,
    pastStream?: comments2.Stream,
  ): Promise<reel.AddCommentResult> {
    const addCommentInternalArgs: reel.AddCommentInternalArg = {
      stream: createReelStream(pastStream || this.stream),
      comment,
      video_timestamp,
      video_timestamp_out,
      page_number,
      annotation_drawing_data,
      mention_metadata,
      is_quick_reaction,
      is_pinned_comment,
      pinned_label,
      parent_thread_id: threadId,
    };

    return getDefaultUserClient()
      .reelAddCommentInternal(addCommentInternalArgs)
      .then((res: DropboxResponse<reel.AddCommentResult>) => res.result)
      .catch((err: PotentialError) => {
        throw handleApiError(err, '/add_comment_internal did not complete successfully');
      });
  }

  subscribe(): Promise<comments2.AcknowledgementResult> {
    return getDefaultUserClient()
      .comments2Subscribe({
        stream: this.stream,
      })
      .then((res: DropboxResponse<comments2.AcknowledgementResult>) => res.result)
      .catch((err: PotentialError) => {
        throw handleApiError(err, '/subscribe did not complete successfully');
      });
  }

  unsubscribe(): Promise<comments2.AcknowledgementResult> {
    return getDefaultUserClient()
      .comments2Unsubscribe({
        stream: this.stream,
      })
      .then((res: DropboxResponse<comments2.AcknowledgementResult>) => res.result)
      .catch((err: PotentialError) => {
        throw handleApiError(err, '/unsubscribe did not complete successfully');
      });
  }
}
export class ReactionsApi {
  listReactionsBatch(
    targets: Array<reactions.TargetReelVideoComment>,
  ): Promise<reactions.ListReactionsBatchResult> {
    return getDefaultUserClient()
      .reactionsListReactionsBatch({
        targets: targets,
      })
      .then((res: DropboxResponse<reactions.ListReactionsBatchResult>) => res.result)
      .catch((err: PotentialError) => {
        throw handleApiError(err, '/list_reactions_batch did not complete successfully');
      });
  }

  addReaction(
    reaction: string,
    reelVideoVersionId: string,
    commentId: string,
    shareToken: string | undefined,
    grantBook: string | undefined,
    replaySessionId: string,
  ): Promise<reactions.ReactionsResult> {
    const target = {
      '.tag': 'reel_video_comment' as const,
      reel_video_version_id: reelVideoVersionId,
      comment_id: commentId,
      share_token: shareToken ?? '',
      grant_book: grantBook ?? '',
      replay_session_id: replaySessionId,
    };
    return getDefaultUserClient()
      .reactionsAddReaction({
        reaction: reaction,
        target: target,
      })
      .then((res: DropboxResponse<reactions.ReactionsResult>) => res.result)
      .catch((err: PotentialError) => {
        throw handleApiError(err, '/add_reaction did not complete successfully');
      });
  }

  removeReaction(
    reaction: string,
    reelVideoVersionId: string,
    commentId: string,
    shareToken: string | undefined,
    grantBook: string | undefined,
    replaySessionId: string,
  ): Promise<reactions.ReactionsResult> {
    const target = {
      '.tag': 'reel_video_comment' as const,
      reel_video_version_id: reelVideoVersionId,
      comment_id: commentId,
      share_token: shareToken ?? '',
      grant_book: grantBook ?? '',
      replay_session_id: replaySessionId,
    };
    return getDefaultUserClient()
      .reactionsRemoveReaction({
        reaction: reaction,
        target: target,
      })
      .then((res: DropboxResponse<reactions.ReactionsResult>) => res.result)
      .catch((err: PotentialError) => {
        throw handleApiError(err, '/remove_reaction did not complete successfully');
      });
  }
}
