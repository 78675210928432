import React from 'react';

import {useSetAtom} from 'jotai';
import {useIntl} from 'react-intl';
import styled from 'styled-components';

import type {reel} from '@dropbox/api-v2-client';
import {Tooltip} from '@dropbox/dig-components/tooltips';
import {Text, Title} from '@dropbox/dig-components/typography';
import {UIIcon} from '@dropbox/dig-icons';
import {ChevronDownLine, ChevronRightLine, CloseLine, FolderLine} from '@dropbox/dig-icons/assets';

import {IconButton} from '~/components/button';
import type {MoveSourceType} from '~/lib/logging/logger_types';
import type {ProjectMoveConfirmationProps} from '~/pages/browse_page/components/browse_page_snackbar';
import {isValidNewFolder, moveItem} from '~/pages/browse_page/components/drag_and_drop_utils';
import {useFoldersSharedState} from '~/pages/browse_page/folders_context';
import type {LeftRailStatus, ProjectOrFolderItems} from '~/pages/browse_page/types';
import {clearBulkActionAtom} from '~/state/bulk_actions';

import {useBrowseItemsViewContext} from '../browse_items_view_context';

const LeftRailWrapper = styled.div<{$showLeftRail: boolean}>`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: calc(100% - 49px); /* 49px offset from the browse page header */
  max-width: 235px;
  width: ${(props) => (props.$showLeftRail ? 235 : 0)}px;
  position: absolute;
  z-index: 2;
  left: 0;
  background-color: #242121;
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 16px;
  transition: width 2s;
  ${(props) => (props.$showLeftRail ? 'transition: width 1s' : '')};
`;

const LeftRailHeader = styled.div`
  display: flex;
  align-items: center;
`;

const CloseButtonStyled = styled(IconButton)`
  margin-left: auto;
`;

const FolderInfoRow = styled.div<{$isValidDestination: boolean}>`
  cursor: ${(props) => (props.$isValidDestination ? 'pointer' : 'not-allowed')};
  &:hover {
    background-color: #3b3838;
  }
`;

const FolderInfoWrapper = styled.div<{$indent: number; $topHeader: boolean}>`
  display: flex;
  align-items: center;
  margin-left: ${(props) => props.$indent * 15 + 20.38}px;
  width: ${(props) => 235 - 2 * 20.38 - props.$indent * 15}px;
  height: 40px;
  flex: 1;
  margin-top: ${(props) => (props.$topHeader ? 20 : 0)}px;
`;

const ChevronIconStyled = styled(IconButton)<{$shouldShow: boolean}>`
  color: #f7f5f2;
  flex-shrink: 0;
  vertical-align: middle;
  margin-left: 8px;
  visibility: ${(props) => (props.$shouldShow ? 'visible' : 'hidden')};
`;

const FolderIconStyled = styled(UIIcon)`
  color: var(--dig-color__border__base);
  margin-left: 2px;
  margin-right: 8px;
  flex-shrink: 0;
`;

const FolderNameWrapper = styled.div`
  display: flex;
  max-width: 170px;
`;

const FolderNameStyled = styled(Text)`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const LeftRailContentWrapper = styled.div`
  overflow: auto;
`;

const HeaderText = styled.div`
  margin-left: 20.38px;
`;

type LeftRailFolderTileProps = {
  children: reel.FolderTreeNode[] | null;
  indent: number;
  isValidDestination: boolean;
  item: ProjectOrFolderItems;
  onChevronClick: (folderId: string, value: boolean) => void;
  onBulkMove: (
    folderIds: string[],
    projectIds: string[],
    destinationFolderId: string,
    destinationFolderName: string,
    moveSource: MoveSourceType,
    showErrorModal: boolean,
    isCopy: boolean,
  ) => void;
  onFolderMove: (
    folderId: string,
    folderName: string,
    destinationFolderId: string,
    destinationFolderName: string,
    moveSource: MoveSourceType,
    showErrorModal: boolean,
    isCopy: boolean,
  ) => void;
  onProjectMove: (
    projectId: string,
    projectName: string,
    destinationFolderId: string,
    destinationFolderName: string,
    moveSource: MoveSourceType,
    isCopy: boolean,
  ) => void;
  setLeftRailStatus: (status: LeftRailStatus) => void;
  tileFolderId: string;
  tileFolderName: string;
  updateMoveInProgressSnackbar: (
    moveProps: ProjectMoveConfirmationProps,
    isError: boolean,
    copyUnsupported?: boolean,
  ) => void;
};

const LeftRailFolderTile = (props: LeftRailFolderTileProps) => {
  const {
    children,
    item,
    setLeftRailStatus,
    indent,
    isValidDestination,
    onChevronClick,
    onBulkMove,
    onFolderMove,
    onProjectMove,
    tileFolderId,
    tileFolderName,
    updateMoveInProgressSnackbar,
  } = props;

  const {currentFolderId, folderTree, reelRootFolderId} = useFoldersSharedState();
  const [isExpanded, setIsExpanded] = React.useState<boolean>(true);

  const handleChevronButtonClick = (event: React.SyntheticEvent<{}>) => {
    event.preventDefault();
    event.stopPropagation();

    onChevronClick(tileFolderId, isExpanded);
    setIsExpanded(!isExpanded);
  };

  const onFolderClick = React.useCallback(async () => {
    if (!isValidDestination) {
      return;
    }
    await moveItem(
      item,
      currentFolderId,
      tileFolderId,
      tileFolderName,
      onBulkMove,
      onProjectMove,
      onFolderMove,
      'left_rail',
      folderTree,
      updateMoveInProgressSnackbar,
      reelRootFolderId,
    );
    setLeftRailStatus({status: 'closed'});
  }, [
    currentFolderId,
    folderTree,
    isValidDestination,
    item,
    onBulkMove,
    onFolderMove,
    onProjectMove,
    reelRootFolderId,
    setLeftRailStatus,
    tileFolderId,
    tileFolderName,
    updateMoveInProgressSnackbar,
  ]);

  return (
    <FolderInfoRow $isValidDestination={isValidDestination} onClick={onFolderClick}>
      {indent >= 1 ? (
        <FolderInfoWrapper $indent={indent - 2} $topHeader={false}>
          <ChevronIconStyled
            $shouldShow={children !== null && children.length > 0}
            onClick={handleChevronButtonClick}
            variant="transparent"
          >
            <UIIcon size="small" src={isExpanded ? ChevronDownLine : ChevronRightLine} />
          </ChevronIconStyled>
          <FolderIconStyled size="large" src={FolderLine} />
          <FolderNameStyled isBold>{tileFolderName}</FolderNameStyled>
        </FolderInfoWrapper>
      ) : (
        <FolderInfoWrapper $indent={indent} $topHeader={true}>
          <FolderNameWrapper>
            <FolderNameStyled isBold>{tileFolderName}</FolderNameStyled>
          </FolderNameWrapper>
          {
            <ChevronIconStyled
              $shouldShow={false}
              onClick={handleChevronButtonClick}
              variant="transparent"
            >
              <UIIcon size="small" src={isExpanded ? ChevronDownLine : ChevronRightLine} />
            </ChevronIconStyled>
          }
        </FolderInfoWrapper>
      )}
    </FolderInfoRow>
  );
};

const LeftFolderRowWithConsumer = (
  props: Omit<LeftRailFolderTileProps, 'setLeftRailStatus' | 'item' | 'isValidDestination'>,
) => {
  const {editActionsProps} = useBrowseItemsViewContext();
  const intl = useIntl();
  const {childTree, currentFolderId} = useFoldersSharedState();

  if (editActionsProps.editActionsEnabled === false) {
    return null;
  }
  if (editActionsProps.leftRailStatus.status === 'closed') {
    return null;
  }

  let itemCount = 1;

  if (editActionsProps.leftRailStatus.items.itemType === 'BulkTiles') {
    itemCount =
      editActionsProps.leftRailStatus.items.folderIds.length +
      editActionsProps.leftRailStatus.items.projectIds.length;
  }

  const itemsMsg = intl.formatMessage(
    {
      defaultMessage: '{itemCount} {itemCount, plural, one{item} other{items}}',
      id: 'pKQbjQ',
      description:
        'A generic term to describe folders and/or projects that will be moved or copied',
    },
    {itemCount},
  );

  const itemDescription =
    editActionsProps.leftRailStatus.items.itemType === 'BulkTiles'
      ? itemsMsg
      : editActionsProps.leftRailStatus.items.itemType === 'ProjectTile'
      ? editActionsProps.leftRailStatus.items.projectName
      : editActionsProps.leftRailStatus.items.folderName;

  const copyMsg = intl.formatMessage(
    {
      defaultMessage: 'Click to copy <bold>{thing_to_copy}</bold> to <bold>{destination}</bold>',
      id: 'wKep5o',
      description:
        'Tooltip that appears when the user hovers over a folder listed in the left sidebar while copying a file or folder',
    },
    {
      bold: (chunks: React.ReactNode) => <b>{chunks}</b>,
      thing_to_copy: itemDescription,
      destination: props.tileFolderName,
    },
  );

  const moveMsg = intl.formatMessage(
    {
      defaultMessage: 'Click to move <bold>{thing_to_move}</bold> to <bold>{destination}</bold>',
      id: 'HTs38E',
      description:
        'Tooltip that appears when the user hovers over a folder listed in the left sidebar while moving a file or folder',
    },
    {
      bold: (chunks: React.ReactNode) => <b>{chunks}</b>,
      thing_to_move: itemDescription,
      destination: props.tileFolderName,
    },
  );

  const isValidDestination = isValidNewFolder(
    editActionsProps.leftRailStatus.items,
    currentFolderId,
    props.tileFolderId,
    childTree,
  );

  if (isValidDestination) {
    return (
      <Tooltip
        placement="right"
        title={editActionsProps.leftRailStatus.status === 'copy' ? copyMsg : moveMsg}
      >
        <div>
          <LeftRailFolderTile
            {...props}
            isValidDestination={isValidDestination}
            item={editActionsProps.leftRailStatus.items}
            setLeftRailStatus={editActionsProps.setLeftRailStatus}
          />
        </div>
      </Tooltip>
    );
  }

  return (
    <LeftRailFolderTile
      {...props}
      isValidDestination={isValidDestination}
      item={editActionsProps.leftRailStatus.items}
      setLeftRailStatus={editActionsProps.setLeftRailStatus}
    />
  );
};

type LeftRailProps = {
  folderTree: reel.FolderTreeNode[];
  indent: number;
  onBulkMove: (
    folderIds: string[],
    projectIds: string[],
    destinationFolderId: string,
    destinationFolderName: string,
    moveSource: MoveSourceType,
    showErrorModal: boolean,
    isCopy: boolean,
  ) => void;
  onProjectMove: (
    projectId: string,
    projectName: string,
    destinationFolderId: string,
    destinationFolderName: string,
    moveSource: MoveSourceType,
    isCopy: boolean,
  ) => void;
  onFolderMove: (
    folderId: string,
    folderName: string,
    destinationFolderId: string,
    destinationFolderName: string,
    moveSource: MoveSourceType,
    showErrorModal: boolean,
    isCopy: boolean,
  ) => void;
  currentProjectId?: string;
  folderFilters?: string[];
  onChevronClick: (folderId: string, value: boolean) => void;
  updateMoveInProgressSnackbar: (
    moveProps: ProjectMoveConfirmationProps,
    isError: boolean,
    copyUnsupported?: boolean,
  ) => void;
};

export const LeftRailContent = (props: LeftRailProps) => {
  const {
    folderTree,
    indent,
    onBulkMove,
    onProjectMove,
    onFolderMove,
    folderFilters,
    onChevronClick,
    updateMoveInProgressSnackbar,
  } = props;

  return (
    <div>
      {folderTree.map((node) => {
        const isCollapsed = folderFilters && folderFilters.includes(node.folder!.id);

        return (
          <div key={node.folder!.id}>
            <LeftFolderRowWithConsumer
              children={node.children ?? null}
              indent={indent}
              key={node.folder!.id}
              onBulkMove={onBulkMove}
              onChevronClick={onChevronClick}
              onFolderMove={onFolderMove}
              onProjectMove={onProjectMove}
              tileFolderId={node.folder!.id}
              tileFolderName={node.folder && node.folder.name ? node.folder.name : 'Untitled'}
              updateMoveInProgressSnackbar={updateMoveInProgressSnackbar}
            />
            {node.children && node.children.length > 0 && !isCollapsed && (
              <LeftRailContent
                folderFilters={folderFilters}
                folderTree={node.children}
                indent={indent + 1}
                onBulkMove={onBulkMove}
                onChevronClick={onChevronClick}
                onFolderMove={onFolderMove}
                onProjectMove={onProjectMove}
                updateMoveInProgressSnackbar={updateMoveInProgressSnackbar}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

type FoldersLeftRailProps = {
  rootFolder?: reel.Folder;
  onBulkMove: (
    folderIds: string[],
    projectIds: string[],
    destinationFolderId: string,
    destinationFolderName: string,
    moveSource: MoveSourceType,
    showErrorModal: boolean,
    isCopy: boolean,
  ) => void;
  onProjectMove: (
    projectId: string,
    projectName: string,
    destinationFolderId: string,
    destinationFolderName: string,
    moveSource: MoveSourceType,
    isCopy: boolean,
  ) => void;
  onFolderMove: (
    folderId: string,
    folderName: string,
    destinationFolderId: string,
    destinationFolderName: string,
    moveSource: MoveSourceType,
    showErrorModal: boolean,
    isCopy: boolean,
  ) => void;
  updateMoveInProgressSnackbar: (
    moveProps: ProjectMoveConfirmationProps,
    isError: boolean,
    copyUnsupported?: boolean,
  ) => void;
};

export const FoldersLeftRail = (props: FoldersLeftRailProps) => {
  const {onBulkMove, onProjectMove, onFolderMove, updateMoveInProgressSnackbar} = props;
  const {breadcrumbPath, currentFolderId, editActionsProps} = useBrowseItemsViewContext();

  const clearBulkAction = useSetAtom(clearBulkActionAtom);

  const handleClose = () => {
    if (editActionsProps.editActionsEnabled) {
      editActionsProps.setLeftRailStatus({status: 'closed'});
      clearBulkAction();
    }
  };

  const [collapsedFolders, setCollapsedFolders] = React.useState<string[]>([]);

  const collapseOrExpandFolder = React.useCallback((folderId: string, shouldCollapse: boolean) => {
    if (shouldCollapse) {
      setCollapsedFolders((prev) => [...prev, folderId]);
    } else {
      setCollapsedFolders((prev) => prev.filter((f) => f !== folderId));
    }
  }, []);
  const intl = useIntl();

  let showLeftRail = false;
  let folderTree: reel.FolderTreeNode[] | null = [];
  let leftRailStatus: LeftRailStatus = {status: 'closed'};
  let railHeaderText = '';

  if (editActionsProps.editActionsEnabled) {
    leftRailStatus = editActionsProps.leftRailStatus;
    folderTree = editActionsProps.folderTree;
    showLeftRail = leftRailStatus.status !== 'closed' && !!currentFolderId;

    const currentProjectId = breadcrumbPath && breadcrumbPath.length > 1 && breadcrumbPath[1].id;

    let itemCount = 1;

    if (leftRailStatus.status !== 'closed' && leftRailStatus.items.itemType === 'BulkTiles') {
      itemCount = leftRailStatus.items.folderIds.length + leftRailStatus.items.projectIds.length;
    }

    const railHeaderMoveText = intl.formatMessage(
      {
        defaultMessage: '{count, plural, one{Move # item to...} other{Move # items to...}}',
        id: 'ZGPvwl',
        description:
          'Header text in the left rail indicating that the current action will move the selected item or items to the selected folder',
      },
      {count: itemCount},
    );

    const railHeaderCopyText = intl.formatMessage(
      {
        defaultMessage: '{count, plural, one{Copy # item to...} other{Copy # items to...}}',
        id: 'xEYqG+',
        description:
          'Header text in the left rail indicating that the current action will copy the selected item or items to the selected folder',
      },
      {count: itemCount},
    );

    // Items on the first level (aka "Home") are an exception to the move/copy
    // rules since they exist outside projects. For now let the user to move them
    // into any folder
    if (breadcrumbPath && breadcrumbPath.length == 1) {
      railHeaderText = railHeaderMoveText;
    } else if (leftRailStatus.status == 'move') {
      folderTree = folderTree!.filter((f) => f.folder && f.folder.id === currentProjectId);
      railHeaderText = railHeaderMoveText;
    } else if (leftRailStatus.status == 'copy') {
      folderTree = folderTree!.filter((f) => f.folder && f.folder.id !== currentProjectId);
      railHeaderText = railHeaderCopyText;
    }
  }

  return (
    <LeftRailWrapper $showLeftRail={showLeftRail}>
      <LeftRailHeader>
        <HeaderText>
          <Title size="small">{railHeaderText}</Title>
        </HeaderText>
        <CloseButtonStyled onClick={handleClose} variant="transparent">
          <UIIcon src={CloseLine} />
        </CloseButtonStyled>
      </LeftRailHeader>
      {showLeftRail && folderTree && (
        <LeftRailContentWrapper>
          <LeftRailContent
            currentProjectId={
              breadcrumbPath && breadcrumbPath.length > 1 ? breadcrumbPath[1].id : ''
            }
            folderFilters={collapsedFolders}
            folderTree={folderTree}
            indent={0}
            onBulkMove={onBulkMove}
            onChevronClick={collapseOrExpandFolder}
            onFolderMove={onFolderMove}
            onProjectMove={onProjectMove}
            updateMoveInProgressSnackbar={updateMoveInProgressSnackbar}
          />
        </LeftRailContentWrapper>
      )}
    </LeftRailWrapper>
  );
};
