import React from 'react';

import {defineMessages, useIntl} from 'react-intl';
import styled, {css} from 'styled-components';

import {Box} from '@dropbox/dig-foundations';

import {EditableInput} from '~/components/folder_header/editable_input';
import {breakpointSmall, spacing} from '~/components/styled';
import {useUpdateProjectMutation} from '~/lib/api_queries';

const PageDescriptionBox = styled(Box)`
  margin-top: ${spacing('4')};

  ${breakpointSmall(css`
    margin-bottom: 0;
  `)};
`;

type EditableDescriptionProps = {
  disabled?: boolean;
  folderDescription: string;
  folderId: string;
  handleFolderDescriptionRename: (newFolderDescription: string) => void;
};

export const EditableDescription = ({
  disabled,
  folderDescription,
  folderId,
  handleFolderDescriptionRename,
}: React.PropsWithChildren<EditableDescriptionProps>) => {
  const intl = useIntl();
  const updateProject = useUpdateProjectMutation();

  const handleRename = async (newFolderDescription: string) => {
    await updateProject.mutateAsync({folderId, description: newFolderDescription});
    handleFolderDescriptionRename(newFolderDescription);
  };

  const renameText = intl.formatMessage(intlMessages.addProjectDescription);
  const tooltipText = intl.formatMessage(
    folderDescription ? intlMessages.editDescriptoinTooltip : intlMessages.addProjectDescription,
  );

  return (
    <PageDescriptionBox alignItems="center" data-testid="editable-description" display="flex">
      <EditableInput
        ariaLabel={renameText}
        disabled={disabled}
        onChangeTextValue={handleRename}
        textComponentPlaceholder={renameText}
        textValue={folderDescription}
        tooltipText={tooltipText}
        type="text"
      />
    </PageDescriptionBox>
  );
};

const intlMessages = defineMessages({
  addProjectDescription: {
    defaultMessage: 'Add project description',
    description: 'Copy prompting user to add a description',
    id: 'c0DbxU',
  },
  editDescriptoinTooltip: {
    defaultMessage: 'Edit description',
    description: 'Tooltip text denoting editing the description',
    id: 'S2+RkU',
  },
});
