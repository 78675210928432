import type {EditorState} from 'draft-js';
import type {IntlShape} from 'react-intl';

export type UserID = string;
export type ThreadID = string;
export type CommentID = string;
export type StreamID = string;

export type CommentsLocalization = {
  intl: IntlShape;
};

export interface HasPending {
  pending?: {
    action: string;
    error?: any;
    originalValue: any;
    pendingValue: any;
  };
}

export interface IUser {
  id: UserID;
  name: {
    display: string;
    initials: string;
    public: string;
  };
  photoUrl?: string;
  isCurrentUser?: boolean;
}

export interface IMentionUser extends IUser {
  email: string;
}

export interface IUserIdIdentifier {
  type: 'id';
  identifier: UserID;
}

export interface IUserEmailIdentifier {
  type: 'email';
  identifier: string;
}

export interface IUserPublicIdIdentifier {
  type: 'user_pid';
  identifier: string;
}

export type IUserIdentifier = IUserIdIdentifier | IUserEmailIdentifier | IUserPublicIdIdentifier;

export interface ILocation {
  start: number;
  end: number;
}

export type Style = 'bold' | 'code' | 'italic' | 'strikethrough' | 'underline';

export interface IStyleMetadata {
  type: 'style';
  location: ILocation;
  style: Style;
}

export interface IEmojiMetadata {
  type: 'emoji';
  location: ILocation;
}

export interface IMentionMetadata {
  type: 'mention';
  location: ILocation;
  user: IUserIdentifier;
}

export interface IStickerMetadata {
  type: 'sticker';
  id: number;
  url: string;
  name: string;
  description: string;
}

export interface IGuestMetadata {
  type: 'guest';
  first_name: string;
  last_name: string;
}

export interface IReelMetadata {
  type: 'reel';
  annotation_data: string;
}

export type ICommentMetadata =
  | IEmojiMetadata
  | IMentionMetadata
  | IStickerMetadata
  | IStyleMetadata
  | IGuestMetadata
  | IReelMetadata;

export interface ICommentSettings {
  canDelete: boolean;
  canEdit: boolean;
}

export interface ICommentContent {
  metadata: ICommentMetadata[];
  text: string;
}

export interface IRevisionKey {
  nsId: number;
  sjId: number;
}

export interface IRevisionInfo {
  isCurrent: boolean;
  revision?: IRevisionKey;
  timestamp?: Date;
}

export interface IComment extends HasPending {
  id: CommentID;
  timestamp: Date;
  threadId: ThreadID;
  author: IUser;
  content: ICommentContent;
  userPermissions: ICommentSettings;
  revisionInfo: IRevisionInfo;
  deleted?: boolean;
}

export interface IVideoAnnotation {
  type: 'video';
  time: number;
  timeSec?: number;
}

export interface IAudioAnnotation {
  type: 'audio';
  time: number;
}

export interface IInteractionAnnotation {
  type: 'rnd' | 'highlight';
  regions: Rectangle[];
}

export interface IImageAnnotation {
  type: 'image';
  label?: number;
  region: Rectangle;
}

export interface IDocumentAnnotation {
  type: 'document';
  label?: number;
  regionType: DocumentAnnotationType;
  regions: DocumentRectangle[];
}

export type CreatableDocumentAnnotationType = 'highlight' | 'rectangle' | 'point';

export type DocumentAnnotationType = CreatableDocumentAnnotationType | 'other';

export type Point = {
  x: number;
  y: number;
};

export type OffsetPercentages = {
  top: number;
  right: number;
  bottom: number;
  left: number;
};

export type Dimensions = {
  width: number;
  height: number;
};

export type PixelDimensions = Dimensions;

export type Rectangle = Point & Dimensions;

export type DocumentRectangle = Rectangle & {
  page: number;
};

export type INumberedAnnotation = IImageAnnotation | IDocumentAnnotation;
export type ITimecodeAnnotation = IVideoAnnotation | IAudioAnnotation;
export type IAnnotation =
  | IAudioAnnotation
  | IVideoAnnotation
  | IDocumentAnnotation
  | IImageAnnotation;

export type IThirdPartySource =
  | 'google_doc'
  | 'google_sheet'
  | 'google_slides'
  | 'microsoft_word'
  | 'microsoft_excel'
  | 'microsoft_powerpoint'
  | 'other';

export interface IThreadResolvedInfoNoDetails {
  type: 'no_details';
}

export interface IThreadResolvedInfoWithDetails {
  type: 'with_details';
  resolver: IUser;
  resolvedTimestamp: Date;
}

export type IThreadResolvedInfo = IThreadResolvedInfoNoDetails | IThreadResolvedInfoWithDetails;

export interface IThread extends HasPending {
  id: ThreadID;
  timestamp: Date;
  read: boolean;
  readonly: boolean;
  isThirdParty: boolean;
  /** @deprecated Use `resolvedInfo` instead. */
  resolved?: boolean;
  resolvedInfo?: IThreadResolvedInfo;
  comments: IComment[];
  annotation?: IAnnotation;
}

// Convenience type (useful for type narrowing)
export interface IThreadWithAnnotation<T extends IAnnotation> extends IThread {
  annotation: T;
}

export type IThreadWithDocumentAnnotation = IThreadWithAnnotation<IDocumentAnnotation>;
export type IThreadWithImageAnnotation = IThreadWithAnnotation<IImageAnnotation>;
export type IThreadWithNumberedAnnotation = IThreadWithAnnotation<INumberedAnnotation>;
export type IThreadWithTimecodeAnnotation = IThreadWithAnnotation<ITimecodeAnnotation>;

export interface IStreamSettings {
  canComment: boolean;
  canModifyThreads: boolean;
  canShowEditor: boolean;
}

export type ICommentsStreamActionsAdapter = ICommentsActionsAdapter & IEditorActionsAdapter;

export interface ICommentStream {
  id: StreamID;
  actionsAdapter: ICommentsStreamActionsAdapter;
  enabled: boolean;
  settings: IStreamSettings;
  threads: IThread[];
}

export interface IEditorActionsAdapter {
  onEditorFocused(threadID?: ThreadID): void;
  onEditorStateChange(editorState: EditorState): void;
}

export type ICommentsActionsAdapter = IBasicCommentsActionsAdapter &
  ITimecodeCommentsActionsAdapter &
  IImageCommentsActionsAdapter &
  IDocumentCommentsActionsAdapter;

export type IBasicCommentsActionsAdapter = {
  // external
  onInteractionWhileCollapsed?(interaction: CollapsedInteraction): void;

  // stream
  onThreadCreated(content: ICommentContent, annotation?: IAnnotation): void;

  // thread
  onThreadActivated(thread: IThread): void;
  onAllThreadsDeactivated(): void;
  onThreadRepliedTo(threadID: ThreadID, content: ICommentContent): void;
  onThreadMarkedAsRead(threadID: ThreadID): void;
  onThreadMarkedAsUnread(threadID: ThreadID): void;
  onThreadResolved(threadID: ThreadID): void;
  onThreadUnresolved(threadID: ThreadID): void;
  onThreadMouseEnter?(threadID: ThreadID): void;
  onThreadMouseLeave?(threadID: ThreadID): void;
  onThreadFocus?(threadID: ThreadID): void;
  onThreadBlur?(threadID: ThreadID): void;

  // comment
  onCommentEdited(commentID: CommentID, content: ICommentContent, threadID: ThreadID): void;
  onCommentDeleted(commentID: CommentID, threadID: ThreadID): void;
  onClickOlderInfo(revisionInfo: IRevisionInfo, threadId: ThreadID): void;

  // mentions
  onMentionsQueryUpdated(updatedQuery: string): void;

  // editor focus
  onEditorFocused(threadID?: ThreadID): void;
};

export type ITimecodeCommentsActionsAdapter = Partial<IBasicCommentsActionsAdapter> & {
  onClickTimeCode(time: number): void;
};

export type IDocumentImageSharedCommentsActionsAdapter = Partial<IBasicCommentsActionsAdapter> & {
  onCommentDraftCancel(): void;
  onEditorStateChange(editorState: EditorState): void;
};

export type IDocumentCommentsActionsAdapter = IDocumentImageSharedCommentsActionsAdapter & {};

export type IImageCommentsActionsAdapter = IDocumentImageSharedCommentsActionsAdapter & {};

export type IAnnotationsActionsAdapter = Partial<IBasicCommentsActionsAdapter> & {};

export interface MentionsMatches {
  // Stores the matches for query strings we've encountered so far
  [query: string]: IMentionUser[];
}

export interface ICommentsContext {
  localization: CommentsLocalization;
}

export interface VisibilityData<T> {
  countAbove: number;
  countBelow: number;
  nearestAbove: T | null;
  nearestBelow: T | null;
}

export interface TrackedItem<T> {
  item: T;
  ref: HTMLElement;
}

export type PreviewPoint = Point & {
  page?: number;
};

export enum CollapsedInteraction {
  LEAVE_COMMENT_CLICK = 'leave_comment_click',
  AVATAR_CLICK = 'avatar_click',
}

export interface Sticker {
  id: number;
  name: string;
  description: string;
  is_live_sticker: boolean;
  /**
   * The base url of this sticker image. Client will have to add ?type=png for
   * static stickers and gif for live stickers.
   */
  base_url: string;
}

export interface StickerPack {
  id: number;
  name: string;
  description: string;
  url: string;
  stickers: Array<Sticker>;
}

// Defined typeguard that can simplify filtering out undefined elements in array
export function isDefined<T>(argument: T | undefined): argument is T {
  return typeof argument !== 'undefined';
}
