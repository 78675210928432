import React from 'react';

import {FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';

import {Modal} from '@dropbox/dig-components/modal';
import {Text, Title} from '@dropbox/dig-components/typography';

import {Button} from '~/components/button';
import type {LoggingClient} from '~/lib/logging/logger';
import type {AddMediaClickSourceType} from '~/lib/logging/logger_types';
import type {
  AllUploadsCompleteHandler,
  BasicFileInfo,
  ChooserFile,
  DirectUpload,
} from '~/lib/uploads/types';
import {ExploreDemoButton} from '~/pages/browse_page/components/explore_demo_button';
import {MediaAdder} from '~/pages/browse_page/components/media_adder';
import {WelcomeVideo} from '~/pages/browse_page/components/welcome_video';

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ModalContent = styled.div`
  padding-left: 8px;
  padding-right: 8px;
`;

const ButtonRow = styled.div`
  display: flex;
  flex-dir: row;
  width: 100%;
  padding-top: 24px;
  padding-bottom: 24px;

  button:first-child {
    margin-right: auto;
  }

  button:not(:first-child):not(:last-child) {
    margin-right: 8px;
  }
`;

const ButtonWrapper = styled.div`
  & .dig-Menu {
    width: 100%;
  }
  & .dig-ClickOutside {
    width: 100%;
  }
`;

type WelcomeModalProps = {
  onRequestClose: (demo?: boolean) => void;
  demoProjectId?: string;
  demoVideoId?: string;
  redirectProjectId?: string;
  redirectVideoId?: string;
  level: number;
  currentFolderId?: string;
  pageSource: 'browse';
  onAddVideoFromDropbox: (
    files: ChooserFile[],
    clickSource: AddMediaClickSourceType,
    level: number,
  ) => Promise<void>;
  onFileUploadComplete: (
    click_source: AddMediaClickSourceType,
    level: number,
    projectId: string,
    videoId: string,
    name: string,
  ) => void;
  onAllUploadsComplete: AllUploadsCompleteHandler;
  onAddVideoError: () => void;
  onFilePick: (files: BasicFileInfo[]) => void;
  logEvent: LoggingClient['logEvent'];
};

export const WelcomeModal = (props: WelcomeModalProps) => {
  const intl = useIntl();
  const uploadButtonText = intl.formatMessage({
    defaultMessage: 'Add video',
    id: 'Vbv3MW',
    description:
      'Button text in the welcome modal. When clicked, the button opens a menu giving the user different options for uploading files.',
  });
  const videoRef = React.useRef<HTMLVideoElement>(null);

  const onRequestClose = (demo?: boolean) => {
    // eslint-disable-next-line deprecation/deprecation
    props.logEvent('select_welcome_modal_dismiss', {
      video_time: videoRef.current ? videoRef.current.currentTime : -1,
    });
    return props.onRequestClose(demo);
  };

  return (
    <Modal
      isCentered
      onRequestClose={() => onRequestClose()}
      open
      shouldCloseOnOverlayClick={false}
      width={600}
    >
      <BodyContainer>
        <WelcomeVideo logEvent={props.logEvent} videoRef={videoRef} />
        <ModalContent>
          <Modal.Header hasBottomSpacing="title-small">
            <Title style={{marginTop: '8px', marginBottom: '6px'}}>
              <FormattedMessage
                defaultMessage="Welcome To Dropbox Replay"
                description="Title text of modal welcoming users to Dropbox Replay for the first time"
                id="LvH9oY"
              />
            </Title>
          </Modal.Header>
          <Modal.Body>
            <Text size="large">
              <FormattedMessage
                defaultMessage="Dropbox Replay is an easier way to share, collect feedback, and review comments on your projects."
                description="Description text that appears in welcome modal indicating different things users are able to do with Replay"
                id="PRxIDD"
              />
            </Text>
            <ButtonRow>
              <Button onClick={() => onRequestClose()} variant="transparent">
                <FormattedMessage
                  defaultMessage="Dismiss"
                  description="Button text to dismiss the Welcome modal."
                  id="qAGDsN"
                />
              </Button>
              <ExploreDemoButton
                markModalDimissed={onRequestClose}
                pageSource={props.pageSource}
                projectId={props.demoProjectId}
                videoId={props.demoVideoId}
              />
              {props.currentFolderId && (
                <ButtonWrapper>
                  {props.redirectProjectId && props.redirectVideoId ? (
                    <Button
                      onClick={() => {
                        (async () => {
                          await onRequestClose();
                          window.location.href = `/project/${props.redirectProjectId}/video/${props.redirectVideoId}`;
                        })();
                      }}
                      variant="primary"
                    >
                      <FormattedMessage
                        defaultMessage="View my file"
                        description="Button text for a button that takes you to your newly uploaded file."
                        id="IvYxdW"
                      />
                    </Button>
                  ) : (
                    <MediaAdder
                      buttonText={uploadButtonText}
                      clickSource="welcome_modal"
                      currentFolderDepth={props.level}
                      currentFolderId={props.currentFolderId}
                      dropdownPlacement="bottom"
                      folderLevel={props.level}
                      loadingState="spinner"
                      onAddError={() => props.onAddVideoError()}
                      onAddFromDropbox={async (files) => {
                        await props.onAddVideoFromDropbox(files, 'welcome_modal', props.level);
                        onRequestClose();
                      }}
                      onAllUploadsComplete={props.onAllUploadsComplete}
                      onFilePick={props.onFilePick}
                      onFileUploadComplete={(
                        {projectId, file: {name}, videoId}: DirectUpload,
                        fileId: string,
                      ) => {
                        props.onFileUploadComplete(
                          'welcome_modal',
                          props.level,
                          projectId!,
                          videoId!,
                          name,
                        );
                        onRequestClose();
                      }}
                    />
                  )}
                </ButtonWrapper>
              )}
            </ButtonRow>
          </Modal.Body>
        </ModalContent>
      </BodyContainer>
    </Modal>
  );
};
