import React from 'react';

import chunk from 'lodash/chunk';
import {PAP_Upload_Batch} from 'pap-events/manual_upload/upload_batch';
import {PAP_Upload_File} from 'pap-events/manual_upload/upload_file';
import {useIntl} from 'react-intl';
import {useLocation} from 'react-router-dom';

import {ChooserModal} from '~/components/chooser_modal';
import {MAX_DOCUMENT_UPLOAD_SIZE, MAX_FOLDER_DEPTH} from '~/components/common';
import {InvalidExtensionModal} from '~/components/invalid_extensions_modal';
import {MaxDepthExceededModal} from '~/components/max_depth_exceeded_modal';
import {useErrorSnackbar} from '~/components/snackbar/error_snackbar';
import {useUploadDrawerUploadsAndProgress} from '~/components/upload_drawer/use_upload_drawer_uploads_and_progress';
import type {CreateProjectsResponse} from '~/lib/api';
import {
  createFoldersFromPaths,
  createProjects,
  deleteProject,
  deleteVersion,
  ProjectCreationStatus,
  sendNewFilesAddedNotifications,
} from '~/lib/api';
import {archiveToDropbox} from '~/lib/flows/api';
import {
  AUDIO_EXTENSIONS,
  getExtension,
  getMediaType,
  isPdfExtension,
  useValidExtensions,
  VALID_CAPTIONS_FILE_EXTENSIONS,
} from '~/lib/helpers';
import type {
  AddMediaClickSourceType,
  AddNewVersionClickSourceType,
} from '~/lib/logging/logger_types';
import listDropboxFolder from '~/lib/uploads/list_dropbox_folder';
import type {
  ChooserFile,
  ChooserUpload,
  MappedChooserUploads,
  UploadsProgress,
} from '~/lib/uploads/types';
import {UploadTypes} from '~/lib/uploads/types';
import {
  FileUploadState,
  MAX_CREATE_PROJECT_BATCH,
  UPLOAD_FINAL_STATES,
  UploadState,
} from '~/lib/uploads/types';
import {useGetDocumentUploadSizeExceededErrorText} from '~/lib/uploads/uploads';
import {generateRandomId, getFolderDepthFromPaths, useReelProvisioningEnabled} from '~/lib/utils';

import {reportBadContextUseError} from '../lib/error_reporting';
import {useGetQuotaExceededErrorText, useGetRemainingFileQuota} from '../lib/provisions';
import {useLoggingClient} from '../lib/use_logging_client';

type SubmitHandler = (
  files: ChooserFile[],
  skipFolderExtensions?: boolean,
  latestVersionsOnly?: boolean,
) => Promise<void>;
type UploadStartHandler = (files: ChooserFile[]) => void;
type AllCompleteHandler = (files: ChooserFile[]) => void;
type CreateVersionHandler = (
  fileId: string,
  uploadId?: string,
) => Promise<{
  projectId?: string;
  videoId?: string;
  videoVersionId?: string;
  videoIdForAmplitude?: string;
  versionIdForAmplitude?: string;
  fileSize?: number;
}>;
type AddCaptionsHandler = (fileId: string) => Promise<void>;

type ChooseUploadsProps = {
  currentFolderId: string;
  currentFolderDepth: number;
  onUploadStart?: UploadStartHandler;
  onAllUploadsComplete?: AllCompleteHandler;
  selectionType: 'file' | 'folder';
  clickSource: AddMediaClickSourceType;
  onUploadsSuccess?: () => void;
  onUploadsFailure?: () => void;
};

type ChooseArchiveFolderProps = {
  allVideoVersionIds: string[];
  latestVideoVersionIds: string[];
  snackbarSuccessHandler: () => void;
  snackbarFailureHandler: () => void;
};

type ChooseVersionUploadProps = {
  onCreateVersion: CreateVersionHandler;
  onUploadStart?: UploadStartHandler;
  onAllUploadsComplete?: AllCompleteHandler;
  clickSource: AddMediaClickSourceType | AddMediaClickSourceType | AddNewVersionClickSourceType;
};

type ChooseCaptionsUploadProps = {
  onCaptionsUpload: AddCaptionsHandler;
};

export type DropboxChooserContext = {
  uploadState: UploadState;
  chooserOpen: boolean;
  setChooserOpen: (open: boolean) => void;
  archiveLatestOnly: boolean;
  setArchiveLatestOnly: (option: boolean) => void;
  chooseProjectUploads: (props: ChooseUploadsProps) => void;
  chooseVersionUpload: (props: ChooseVersionUploadProps) => void;
  chooseCaptionsUpload: (props: ChooseCaptionsUploadProps) => void;
  chooseArchiveFolder: (props: ChooseArchiveFolderProps) => void;
  createProjectsFromDropbox: (
    chooserUploadsProp: Pick<
      ChooseUploadsProps,
      | 'clickSource'
      | 'currentFolderDepth'
      | 'currentFolderId'
      | 'onAllUploadsComplete'
      | 'onUploadStart'
      | 'onUploadsSuccess'
      | 'onUploadsFailure'
    >,
    files: ChooserFile[],
    selectionType: 'file' | 'folder',
    skipFolderExtensions?: boolean,
  ) => Promise<void>;
};

const DropboxChooserContext = React.createContext<DropboxChooserContext | null>(null);

export type UploadType = 'version' | 'project' | 'captions' | 'archive';

export const DropboxChooserProvider = (props: React.PropsWithChildren<{}>) => {
  const location = useLocation();

  const [chooserOpen, setChooserOpen] = React.useState(false);
  const [uploadType, setUploadType] = React.useState<UploadType | null>(null);
  const [uploadState, setUploadState] = React.useState(UploadState.Init);
  const [maxDepthErrorModalOpen, setMaxDepthErrorModalOpen] = React.useState(false);
  const [selectionType, setSelectionType] = React.useState<'file' | 'folder'>('file');
  const [multipleFileUploadEnabled, setMultipleFileUploadEnabled] = React.useState(true);
  const [submitHandler, setSubmitHandler] = React.useState<SubmitHandler>();
  const [uploadPathName, setUploadPathName] = React.useState('');
  const provisionsEnabled = useReelProvisioningEnabled();
  const loggingClient = useLoggingClient();
  const [extensionModalOpen, setExtensionModalOpen] = React.useState(false);
  const [pendingFiles, setPendingFiles] = React.useState<ChooserFile[]>([]);
  const [invalidExtensions, setInvalidExtensions] = React.useState<string[]>([]);
  const [archiveLatestOnly, setArchiveLatestOnly] = React.useState(false);

  const validExtensionsList = useValidExtensions();
  const folderRef = React.useRef<string>();
  const {openErrorSnackbar} = useErrorSnackbar();

  const {
    cancelUpload,
    getLoggingData,
    getUploadsProgress,
    handleUpdateProgress,
    handleUpdateUploads,
    isUploadCanceled,
    updateLoggingData,
    updateMappedUploadAsError,
    updateUpload,
    updateUploadProgress,
    uploads,
  } = useUploadDrawerUploadsAndProgress<MappedChooserUploads>();

  // Close the modal if user navigated away from initial path
  React.useEffect(() => {
    if (location.pathname !== uploadPathName && chooserOpen) {
      setChooserOpen(false);
    }
  }, [location.pathname, uploadPathName, chooserOpen, setChooserOpen]);

  const updateUploadLink = React.useCallback(
    (uploadId: string, projectId: string, videoId: string, versionId?: string) => {
      let link = `/project/${projectId}/video/${videoId}`;

      if (versionId) {
        link += `?video_version_id=${versionId}`;
      }

      updateUpload(uploadId, {
        link,
        projectId,
        versionId,
        videoId,
      });
    },
    [updateUpload],
  );

  const startUpload = React.useCallback(
    (
      onUploadStart: UploadStartHandler,
      files: ChooserFile[],
      type: UploadTypes,
      canCancel?: boolean,
      clickSource?:
        | AddMediaClickSourceType
        | AddMediaClickSourceType
        | AddNewVersionClickSourceType,
      selectionType?: 'file' | 'folder',
    ) => {
      const batchId = generateRandomId();
      const mappedUploads: MappedChooserUploads = {};

      if (files.length > 1) {
        //Batch uploads require only essential attributes; we do not need to include basic logging data in this context
        loggingClient.logPap(
          PAP_Upload_Batch({
            itemCount: files.length,
            isFolder: selectionType === 'folder',
            batchIdString: batchId,
            actionSource: clickSource,
          }),
        );
      }

      files.forEach((file) => {
        const uploadId = generateRandomId();

        updateLoggingData(uploadId, {
          actionSource: clickSource,
          uploadMethod: 'dropbox',
          contentType: selectionType,
          startType: 'initial',
          batchIdString: batchId,
          eventState: 'start',
          uploadId: uploadId,
          fileCategory: getMediaType(getExtension(file.name)),
          fileExtension: getExtension(file.name),
        });
        loggingClient.logPap(PAP_Upload_File(getLoggingData()[uploadId]));

        mappedUploads[uploadId] = {
          canceled: false,
          file,
          mediaSourceType: 'dropbox',
          type,
          uploadId,
          ...(canCancel ? {onCancel: (upload) => cancelUpload(upload.uploadId)} : {}),
        };
      });

      const progress: UploadsProgress = {};

      Object.entries(mappedUploads).forEach(([uploadId]) => {
        progress[uploadId] = {
          percentage: 0,
          status: FileUploadState.Ready,
        };
      });
      handleUpdateUploads(mappedUploads);
      handleUpdateProgress(progress);
      setUploadState(UploadState.Uploading);
      onUploadStart(files);

      return mappedUploads;
    },
    [
      cancelUpload,
      getLoggingData,
      handleUpdateProgress,
      handleUpdateUploads,
      loggingClient,
      updateLoggingData,
    ],
  );

  const intl = useIntl();
  const uploadFolderError = intl.formatMessage({
    defaultMessage:
      'Failed to upload folder. There was an issue fetching the folder contents from Dropbox.',
    id: 'aIH82N',
    description: 'Error message shown when uploading a folder fails.',
  });

  const fileQuotaExceededError = useGetQuotaExceededErrorText();
  const availableFileQuota = useGetRemainingFileQuota();
  const documentSizeExceededError = useGetDocumentUploadSizeExceededErrorText();

  const createProjectsFromDropbox: DropboxChooserContext['createProjectsFromDropbox'] =
    React.useCallback(
      async (chooserUploadsProps, allFiles, selectionType, skipFolderExtensions = false) => {
        const {
          clickSource,
          currentFolderDepth,
          currentFolderId,
          onAllUploadsComplete = () => {},
          onUploadStart = () => {},
          onUploadsFailure = () => {},
          onUploadsSuccess = () => {},
        } = chooserUploadsProps;

        const createProjectsBatch = async (
          uploadsChunk: [string, ChooserUpload][],
          currentFolderId: string,
          fileIdToParentFolderId?: Record<string, string>,
        ) => {
          const files = uploadsChunk.map(([_, upload]) => upload.file);
          let createProjectsResponse: CreateProjectsResponse;
          let batchSuccessful = true;

          const updateProgress = (percentage: number) => {
            const progress: UploadsProgress = {};
            uploadsChunk.forEach(([uploadId]) => {
              progress[uploadId] = {
                ...getUploadsProgress()[uploadId],
                percentage,
                status: FileUploadState.Uploading,
              };
            });
            handleUpdateProgress(progress);
          };

          updateProgress(0);

          try {
            setTimeout(() => {
              if (!createProjectsResponse) {
                const hasErrors = uploadsChunk.some(
                  ([_, upload]) =>
                    getUploadsProgress()[upload.uploadId].status === FileUploadState.Error,
                );
                if (!hasErrors) {
                  updateProgress(35);
                }
              }
            }, 1000);

            try {
              createProjectsResponse = await createProjects({
                files,
                parentFolderId: currentFolderId,
                fileIdToParentFolderId: fileIdToParentFolderId,
                excludeXmp: false,
              });
            } catch (e) {
              if (files.length == 1) {
                // For single files: If the upload fails, try again excluding xmp metadata
                // For multiple files, we don't retry because it results in duplicate files if some files succeeded on the first attempt
                createProjectsResponse = await createProjects({
                  files,
                  parentFolderId: currentFolderId,
                  fileIdToParentFolderId: fileIdToParentFolderId,
                  excludeXmp: true,
                });
              } else {
                uploadsChunk.forEach(([uploadId]) => {
                  const isError = getUploadsProgress()[uploadId].status === FileUploadState.Error;
                  if (isError) {
                    loggingClient.logPap(
                      PAP_Upload_File({
                        ...getLoggingData()[uploadId],
                        fileCategory: 'other',
                        eventState: 'failed',
                        failureType: `${e}`,
                      }),
                    );
                  }
                });
              }
              throw e;
            }

            Object.entries(createProjectsResponse).forEach(
              async ([
                fileId,
                {
                  status,
                  projectId,
                  videoId,
                  videoVersionId,
                  versionIdForAmplitude,
                  videoIdForAmplitude,
                  fileSize,
                },
              ]) => {
                const match = uploadsChunk.find(([_, upload]) => upload.file.id === fileId);

                if (match) {
                  const [uploadId] = match;

                  let fileStatus;
                  if (isUploadCanceled(uploadId)) {
                    fileStatus = FileUploadState.Cancelled;
                    await deleteProject(projectId);
                  } else {
                    fileStatus =
                      status === ProjectCreationStatus.Complete
                        ? FileUploadState.Complete
                        : FileUploadState.Error;
                  }

                  if (fileStatus === FileUploadState.Error) {
                    batchSuccessful = false;
                  }

                  if (fileStatus !== FileUploadState.Cancelled) {
                    updateUploadLink(uploadId, projectId, videoId, videoVersionId);
                    if (fileStatus !== FileUploadState.Error) {
                      loggingClient.logPap(
                        PAP_Upload_File({
                          ...getLoggingData()[uploadId],
                          fileSize: fileSize,
                          eventState: 'success',
                          videoId: videoIdForAmplitude,
                          videoVersionId: versionIdForAmplitude,
                        }),
                      );
                    }
                  }
                  updateUploadProgress(uploadId, {percentage: 100, status: fileStatus});
                }
              },
            );
          } catch (e) {
            uploadsChunk.forEach(([uploadId, upload]) =>
              updateMappedUploadAsError(uploadId, upload, e),
            );
            batchSuccessful = false;
          }

          return batchSuccessful;
        };

        let supportedFiles: ChooserFile[] = [];
        let fileIdToParentFolderId: Record<string, string> = {};

        const files = allFiles.filter(
          (file: any) => !isPdfExtension(file.name) || file.bytes < MAX_DOCUMENT_UPLOAD_SIZE,
        );

        if (files.length < allFiles.length) {
          // eslint-disable-next-line deprecation/deprecation
          loggingClient.logEvent('failed_pdf_upload');
          openErrorSnackbar(documentSizeExceededError);
        }

        if (selectionType == 'folder') {
          setUploadState(UploadState.Starting);
          try {
            const listDropboxFolderResponse = await listDropboxFolder(
              files[0].id,
              validExtensionsList,
            );
            supportedFiles = listDropboxFolderResponse?.supportedFiles ?? [];
            const unsupportedExtensions = listDropboxFolderResponse?.unsupportedExtensions ?? [];

            const folderDepth = getFolderDepthFromPaths(
              supportedFiles.map((file) => file.relativePath),
            );
            if (folderDepth + currentFolderDepth > MAX_FOLDER_DEPTH) {
              // eslint-disable-next-line deprecation/deprecation
              loggingClient.logEvent('shown_max_depth_modal', {
                click_source: clickSource,
                media_source: 'dropbox',
                upload_source: 'folder',
              });
              setMaxDepthErrorModalOpen(true);
              return;
            }
            if (!skipFolderExtensions && unsupportedExtensions.length) {
              setExtensionModalOpen(true);
              setInvalidExtensions(unsupportedExtensions);
              setPendingFiles(files);
              return;
            }
            const fileIdToPaths = Object.fromEntries(
              supportedFiles.map((file) => [file.id, file.relativePath]),
            );
            const createFoldersFromPathsResult =
              Object.keys(fileIdToPaths).length !== 0
                ? await createFoldersFromPaths(currentFolderId, fileIdToPaths)
                : {};
            fileIdToParentFolderId = createFoldersFromPathsResult.file_id_to_parent_folder_id ?? {};
          } catch {
            setUploadState(UploadState.Error);
            openErrorSnackbar(uploadFolderError);
            return;
          }
        }

        const fileInfo = selectionType === 'folder' ? supportedFiles : files;
        const mappedUploads = startUpload(
          onUploadStart,
          fileInfo,
          UploadTypes.PROJECT,
          true,
          clickSource,
          selectionType,
        );
        const sortedEntries = Object.entries(mappedUploads).sort(([uploadIdA], [uploadIdB]) =>
          uploadIdA > uploadIdB ? 1 : -1,
        );
        const chunkedUploads = chunk(sortedEntries, MAX_CREATE_PROJECT_BATCH);
        let allSuccessful = true;
        let allFailed = true;

        for (let i = 0; i < chunkedUploads.length; i++) {
          const batchSuccessful = await createProjectsBatch(
            chunkedUploads[i],
            currentFolderId,
            fileIdToParentFolderId,
          );
          if (batchSuccessful) {
            allFailed = false;
          }
          if (!batchSuccessful) {
            allSuccessful = false;
          }
        }

        if (allFailed) {
          onUploadsFailure();
        } else if (allSuccessful) {
          onUploadsSuccess();
        } else {
          onUploadsFailure();
          onUploadsSuccess();
        }

        setUploadState(allSuccessful ? UploadState.Complete : UploadState.Error);
        onAllUploadsComplete(files);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps -- PR 894
      [
        availableFileQuota,
        fileQuotaExceededError,
        getLoggingData,
        getUploadsProgress,
        handleUpdateProgress,
        loggingClient,
        provisionsEnabled,
        startUpload,
        updateMappedUploadAsError,
        updateUploadLink,
        updateUploadProgress,
        uploadFolderError,
        validExtensionsList,
      ],
    );

  const chooseProjectUploads = React.useCallback(
    ({
      currentFolderId,
      currentFolderDepth,
      onUploadStart = () => {},
      onAllUploadsComplete = () => {},
      selectionType,
      clickSource,
    }: ChooseUploadsProps) => {
      folderRef.current = currentFolderId;
      setUploadType('project');
      setUploadState(UploadState.Init);
      setUploadPathName(location.pathname);
      setMultipleFileUploadEnabled(selectionType === 'file');
      setSelectionType(selectionType);

      setChooserOpen(true);

      setSubmitHandler(() => (files: ChooserFile[], skipFolderExtensions?: boolean) => {
        return createProjectsFromDropbox(
          {
            clickSource,
            currentFolderDepth,
            currentFolderId,
            onAllUploadsComplete,
            onUploadStart,
          },
          files,
          selectionType,
          skipFolderExtensions,
        );
      });
    },
    [createProjectsFromDropbox, location.pathname],
  );

  const onModalExtensionClose = React.useCallback(
    (cancelled: boolean) => {
      if (!cancelled) {
        submitHandler && submitHandler(pendingFiles, true);
      }
      setPendingFiles([]);
      setInvalidExtensions([]);
      setExtensionModalOpen(false);
    },
    [pendingFiles, submitHandler],
  );

  const chooseArchiveFolder = React.useCallback(
    (props: ChooseArchiveFolderProps) => {
      setUploadType('archive');
      setUploadPathName(location.pathname);
      setSelectionType('folder');
      setMultipleFileUploadEnabled(false);
      setSubmitHandler(
        () =>
          (
            folders: ChooserFile[],
            skipFolderExtensions?: boolean,
            latestVersionsOnly?: boolean,
          ) => {
            // eslint-disable-next-line deprecation/deprecation
            loggingClient.logEvent('select_archive_to_dropbox', {
              media_count: latestVersionsOnly
                ? props.latestVideoVersionIds.length
                : props.allVideoVersionIds.length,
            });
            return archiveToDropbox(
              // @ts-ignore - ChooserFile doesn't actually match the type of onSubmit for the chooser
              folders[0].path,
              latestVersionsOnly ? props.latestVideoVersionIds : props.allVideoVersionIds,
            ).then(props.snackbarSuccessHandler, props.snackbarFailureHandler);
          },
      );
      setChooserOpen(true);
      // eslint-disable-next-line deprecation/deprecation
      loggingClient.logEvent('shown_archive_to_dropbox_chooser');
    },
    [location.pathname, loggingClient],
  );

  const chooseVersionUpload = React.useCallback(
    ({
      onCreateVersion,
      onUploadStart = () => {},
      onAllUploadsComplete = () => {},
      clickSource,
    }: ChooseVersionUploadProps) => {
      setUploadType('version');
      setUploadState(UploadState.Init);
      setUploadPathName(location.pathname);
      setMultipleFileUploadEnabled(false);
      setSelectionType('file');

      setChooserOpen(true);

      setSubmitHandler(() => async ([file]: ChooserFile[]) => {
        const mappedUploads = startUpload(
          onUploadStart,
          [file],
          UploadTypes.VERSION,
          true,
          clickSource,
          selectionType,
        );
        const [uploadId] = Object.keys(mappedUploads);

        if (!isUploadCanceled(uploadId)) {
          updateUploadProgress(uploadId, {percentage: 99, status: FileUploadState.Uploading});
        }

        const {
          projectId,
          videoId,
          videoVersionId,
          versionIdForAmplitude,
          videoIdForAmplitude,
          fileSize,
        } = await onCreateVersion(file.id, uploadId);

        if (isUploadCanceled(uploadId) && videoVersionId) {
          await deleteVersion(videoVersionId);
        } else {
          if (projectId && videoId) {
            updateUploadLink(uploadId, projectId, videoId, videoVersionId);
            const uploadStatus = getUploadsProgress()[uploadId].status;

            if (uploadStatus !== FileUploadState.Error) {
              loggingClient.logPap(
                PAP_Upload_File({
                  ...getLoggingData()[uploadId],
                  fileSize: fileSize,
                  eventState: 'success',
                  videoId: videoIdForAmplitude,
                  videoVersionId: versionIdForAmplitude,
                  actionElement: 'version',
                }),
              );
            }
          }
          updateUploadProgress(uploadId, {percentage: 100, status: FileUploadState.Complete});
          onAllUploadsComplete([file]);
          setUploadState(UploadState.Complete);
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- PR 894
    [
      getLoggingData,
      getUploadsProgress,
      location.pathname,
      selectionType,
      startUpload,
      updateUploadLink,
      updateUploadProgress,
    ],
  );

  const chooseCaptionsUpload = React.useCallback(
    ({onCaptionsUpload}: ChooseCaptionsUploadProps) => {
      setUploadType('captions');
      setUploadState(UploadState.Init);
      setUploadPathName(location.pathname);
      setMultipleFileUploadEnabled(false);
      setSelectionType('file');

      setChooserOpen(true);

      setSubmitHandler(() => async ([file]: ChooserFile[]) => {
        const mappedUploads = startUpload(() => {}, [file], UploadTypes.CAPTIONS);
        const [uploadId] = Object.keys(mappedUploads);
        const batchId = generateRandomId();
        updateLoggingData(uploadId, {
          uploadMethod: 'dropbox',
          startType: 'initial',
          batchIdString: batchId,
          eventState: 'start',
          uploadId: uploadId,
          fileCategory: getMediaType(file.name),
          actionElement: UploadTypes.CAPTIONS,
          fileExtension: getExtension(file.name),
        });

        loggingClient.logPap(PAP_Upload_File(getLoggingData()[uploadId]));

        if (!isUploadCanceled(uploadId)) {
          updateUploadProgress(uploadId, {percentage: 95, status: FileUploadState.Uploading});
        }

        try {
          await onCaptionsUpload(file.id);
          loggingClient.logPap(
            PAP_Upload_File({
              ...getLoggingData()[uploadId],
              eventState: 'success',
            }),
          );
        } catch (error) {
          loggingClient.logPap(
            PAP_Upload_File({
              ...getLoggingData()[uploadId],
              fileCategory: 'other',
              eventState: 'failed',
              failureType: `${error}`,
            }),
          );
        }

        if (!isUploadCanceled(uploadId)) {
          updateUploadProgress(uploadId, {percentage: 100, status: FileUploadState.Complete});
        }
        setUploadState(UploadState.Complete);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- PR 894
    [
      getLoggingData,
      getUploadsProgress,
      location.pathname,
      startUpload,
      updateLoggingData,
      updateUploadProgress,
    ],
  );

  // Send notifications to project team members after upload state is final
  React.useEffect(() => {
    (async () => {
      if (uploadType === 'project' && UPLOAD_FINAL_STATES.includes(uploadState)) {
        const completeUploads = Object.values(uploads).filter((upload) => !upload.canceled);
        const [firstNotificationInfo, secondNotificationInfo] = completeUploads
          .slice(0, 2)
          .map((upload) => ({
            title: upload.file.name,
            video_id: upload.videoId,
            is_audio: AUDIO_EXTENSIONS.includes(getExtension(upload.file.name)),
          }));

        await sendNewFilesAddedNotifications(
          folderRef.current ?? '',
          completeUploads.length,
          firstNotificationInfo!,
          secondNotificationInfo,
        );
      }
    })();
  }, [uploadType, uploadState, uploads]);

  const value: DropboxChooserContext = React.useMemo(
    () => ({
      chooseCaptionsUpload,
      chooseProjectUploads,
      chooseVersionUpload,
      chooseArchiveFolder,
      chooserOpen,
      createProjectsFromDropbox,
      setChooserOpen,
      uploadState,
      archiveLatestOnly,
      setArchiveLatestOnly,
    }),
    [
      chooseCaptionsUpload,
      chooseProjectUploads,
      chooseVersionUpload,
      chooseArchiveFolder,
      chooserOpen,
      createProjectsFromDropbox,
      uploadState,
      archiveLatestOnly,
      setArchiveLatestOnly,
    ],
  );

  const extensions =
    uploadType === 'captions' ? VALID_CAPTIONS_FILE_EXTENSIONS : validExtensionsList;
  const showCreateFolder = uploadType === 'archive';

  return (
    <DropboxChooserContext.Provider value={value}>
      <ChooserModal
        extensions={extensions}
        multiSelectEnabled={multipleFileUploadEnabled}
        onSubmit={submitHandler}
        selectionType={selectionType}
        showCreateFolder={showCreateFolder}
        uploadType={uploadType}
      />
      <MaxDepthExceededModal open={maxDepthErrorModalOpen} setOpen={setMaxDepthErrorModalOpen} />
      <InvalidExtensionModal
        invalidExtensions={invalidExtensions}
        open={extensionModalOpen}
        requestClose={onModalExtensionClose}
      ></InvalidExtensionModal>
      {props.children}
    </DropboxChooserContext.Provider>
  );
};

export const useDropboxChooser = () => {
  const dropboxChooserContext = React.useContext(DropboxChooserContext);

  if (dropboxChooserContext === null) {
    const error = new Error('useDropboxChooser must be used within a DropboxChooserProvider');
    reportBadContextUseError(error);
    throw error;
  }

  return dropboxChooserContext;
};
