import React from 'react';

import {useAtom} from 'jotai';
import {useIntl} from 'react-intl';

import {Snackbar} from '@dropbox/dig-components/snackbar';
import {Text} from '@dropbox/dig-components/typography';

import {Button} from '~/components/button';
import type {SendEmailSnackbarState} from '~/components/share_modal/share_state';
import {
  getSelectedVersionNum,
  isViewOnlyLink,
  shareSnackbarStateAtom,
} from '~/components/share_modal/share_state';
import {ReelSnackbar} from '~/components/snackbar';

export const ShareSnackbar = () => {
  const intl = useIntl();
  const [shareSnackbarState, setShareSnackbarState] = useAtom(shareSnackbarStateAtom);
  const open = shareSnackbarState.type !== 'none';
  const onClose = () => setShareSnackbarState({type: 'none'});

  const fetchLinksErrorText = intl.formatMessage({
    defaultMessage: 'Failed to fetch share links',
    id: 'CTmSxJ',
    description: 'Text in a snackbar informing the user that a share action failed',
  });
  const enableLinkErrorText = intl.formatMessage({
    defaultMessage: 'Failed to enable share link',
    id: 'UflCT0',
    description: 'Snackbar text that tells the user a link could not be enabled',
  });
  const disableLinkErrorText = intl.formatMessage({
    defaultMessage: 'Failed to disable share link',
    id: 'E+5BAC',
    description: 'Snackbar text that tells the user a link could not be disabled',
  });

  return (
    <ReelSnackbar onRequestClose={onClose} open={open} timeout={7000}>
      {shareSnackbarState.type === 'link-fetch-failed' && (
        <Snackbar.Message>{fetchLinksErrorText}</Snackbar.Message>
      )}
      {shareSnackbarState.type === 'link-enabled-failed' && (
        <Snackbar.Message>{enableLinkErrorText}</Snackbar.Message>
      )}
      {shareSnackbarState.type === 'link-disabled-failed' && (
        <Snackbar.Message>{disableLinkErrorText}</Snackbar.Message>
      )}
      {shareSnackbarState.type === 'send-email-succeeded' && (
        <SendEmailSnackbar {...shareSnackbarState} />
      )}
      <Snackbar.Actions>
        <Button onClick={onClose} variant="transparent">
          {intl.formatMessage({
            defaultMessage: 'Dismiss',
            id: 'Vrde9D',
            description: 'Button to dismiss snackbar',
          })}
        </Button>
      </Snackbar.Actions>
    </ReelSnackbar>
  );
};

const SendEmailSnackbar = (props: SendEmailSnackbarState) => {
  const intl = useIntl();
  const {numEmails, shareItem, shareModalState} = props;
  const isFile = shareItem.type === 'file';
  const isViewOnly = isViewOnlyLink(shareModalState);
  const maxVersionNumber = isFile
    ? shareItem.versionSummaries?.reduce((a, b) => Math.max(a, b.version_num), 1) ?? 1
    : 1;
  const versionNum = getSelectedVersionNum(shareModalState) ?? maxVersionNumber;

  const nameWithVersion = intl.formatMessage(
    {
      description: 'File name with version number included. For example, "My file - V2".',
      defaultMessage: '{name} - V{versionNum}',
      id: 'xzK/sc',
    },
    {
      name: shareItem?.name,
      versionNum: versionNum,
    },
  );

  return (
    <Snackbar.Message>
      {shareItem.name && numEmails !== 0 ? (
        <Text color="standard">
          {intl.formatMessage(
            {
              defaultMessage:
                'Sent link to <b>{name}</b> to {count, plural, one{# email address} other{# email addresses}}.',
              id: 'DjvYsD',
              description: 'Snackbar message indicating successfully sent share email',
            },
            {
              name: isViewOnly && isFile && versionNum ? nameWithVersion : shareItem.name,
              b: (chunks: React.ReactNode) => <b>{chunks}</b>,
              count: numEmails,
            },
          )}
        </Text>
      ) : (
        <Text color="standard">
          {intl.formatMessage({
            defaultMessage: 'Emailed link.',
            id: '0hC7RW',
            description: 'Snackbar message indicating successfully sent share email',
          })}
        </Text>
      )}
    </Snackbar.Message>
  );
};
