import type {reel} from '@dropbox/api-v2-client';

import {moveFolder, moveItems, moveProject} from '~/lib/api';
import type {MoveSourceType} from '~/lib/logging/logger_types';
import type {ProjectMoveConfirmationProps} from '~/pages/browse_page/components/browse_page_snackbar';
import type {
  DndBulkTiles,
  DndFolderTile,
  DndProjectTile,
  ProjectOrFolderItems,
} from '~/pages/browse_page/types';
import {DndItemTypes} from '~/pages/browse_page/types';

function isProject(item: unknown): item is DndProjectTile {
  return (item as DndProjectTile).projectId !== undefined;
}

function isFolder(item: unknown): item is DndFolderTile {
  return (item as DndFolderTile).folderId !== undefined;
}

function isBulk(item: unknown): item is DndBulkTiles {
  const multipleItem = item as DndBulkTiles;
  return multipleItem.folderIds !== undefined && multipleItem.projectIds !== undefined;
}

export const isValidNewFolder = (
  item: ProjectOrFolderItems,
  oldParentFolderId: string,
  newParentFolderId: string,
  childTree?: reel.FolderTreeNode[] | null,
) => {
  // This check is adequate for project moves
  if (newParentFolderId === oldParentFolderId) {
    return false;
  }

  // For single folder moves
  if (isFolder(item)) {
    return isValidNewFolderForFolder(item.folderId, newParentFolderId, childTree);
  }

  // When there are multiple folders we need to check every folder id
  if (isBulk(item)) {
    return item.folderIds.every((folderId) =>
      isValidNewFolderForFolder(folderId, newParentFolderId, childTree),
    );
  }

  return true;
};

const isValidNewFolderForFolder = (
  folderId: string,
  newParentFolderId: string,
  childTree?: reel.FolderTreeNode[] | null,
) => {
  const newParentIsChild =
    childTree && childTree.length > 0 && findFolderInChildren(childTree, newParentFolderId);
  return newParentFolderId !== folderId && !newParentIsChild;
};

const findFolderInChildren = (tree: reel.FolderTreeNode[], lookupId: string): boolean => {
  for (let i = 0; i < tree.length; i += 1) {
    const folder = tree[i].folder;
    if (folder && folder.id === lookupId) {
      return true;
    }
    const children = tree[i].children;
    if (children && children.length > 0) {
      if (findFolderInChildren(children, lookupId)) return true;
    }
  }

  return false;
};

const generatePathToFolder = (
  tree: reel.FolderTreeNode[],
  lookupId: string,
  folderPath: string[],
): boolean => {
  for (let i = 0; i < tree.length; i += 1) {
    const folder = tree[i].folder;
    if (folder && folder.id === lookupId) {
      folderPath.unshift(folder.id);
      return true;
    }
    const children = tree[i].children;
    if (children && children.length > 0) {
      if (generatePathToFolder(children, lookupId, folderPath) && folder) {
        folderPath.unshift(folder.id);
        return true;
      }
    }
  }

  return false;
};

const isActionACopy = (
  tree: reel.FolderTreeNode[],
  oldParentFolderId: string,
  newParentFolderId: string,
  reelRootFolderId: string,
): boolean => {
  // In the case we're starting from root every action here will be a move
  if (oldParentFolderId == reelRootFolderId) {
    return false;
  }

  const oldParentPath: string[] = [];
  generatePathToFolder(tree, oldParentFolderId, oldParentPath);

  const newParentPath: string[] = [];
  generatePathToFolder(tree, newParentFolderId, newParentPath);

  // Otherwise it's only a move if the files are in different projects
  if (oldParentPath && newParentPath && oldParentPath[0] !== newParentPath[0]) {
    return true;
  }

  return false;
};

export const moveItem = async (
  item: ProjectOrFolderItems,
  oldParentFolderId: string,
  newParentFolderId: string,
  newParentFolderName: string,
  onBulkMove: (
    folderIds: string[],
    projectIds: string[],
    destinationFolderId: string,
    destinationFolderName: string,
    moveSource: MoveSourceType,
    showErrorModal: boolean,
    isCopy: boolean,
  ) => void,
  onProjectMove: (
    projectId: string,
    projectName: string,
    destinationFolderId: string,
    destinationFolderName: string,
    moveSource: MoveSourceType,
    isCopy: boolean,
  ) => void,
  onFolderMove: (
    folderId: string,
    folderName: string,
    destinationFolderId: string,
    destinationFolderName: string,
    moveSource: MoveSourceType,
    showErrorModal: boolean,
    isCopy: boolean,
  ) => void,
  moveSource: MoveSourceType,
  folderTree: reel.FolderTreeNode[] | null,
  updateMoveInProgressSnackbar: (
    moveProps: ProjectMoveConfirmationProps,
    isError: boolean,
    copyUnsupported?: boolean,
  ) => void,
  reelRootFolderId: string,
) => {
  let isCopy = true;
  if (folderTree) {
    isCopy = isActionACopy(folderTree, oldParentFolderId, newParentFolderId, reelRootFolderId);
  }

  if (item.itemType === DndItemTypes.ProjectTile) {
    if (oldParentFolderId && isProject(item)) {
      const snackbarProps: ProjectMoveConfirmationProps = {
        projectOrFolderName: item.projectName,
        itemType: 'project',
        destinationFolderId: item.projectId,
        destinationFolderName: newParentFolderName,
        isCopy,
      };
      updateMoveInProgressSnackbar(snackbarProps, false);

      try {
        await moveProject(item.projectId, oldParentFolderId, newParentFolderId);
        onProjectMove(
          item.projectId,
          item.projectName,
          newParentFolderId,
          newParentFolderName,
          moveSource,
          isCopy,
        );
      } catch (e) {
        updateMoveInProgressSnackbar(snackbarProps, true);
      }
    }
  } else if (item.itemType === DndItemTypes.FolderTile) {
    let showErrorModal = false;
    if (
      oldParentFolderId &&
      isFolder(item) &&
      isValidNewFolder(item, oldParentFolderId, newParentFolderId)
    ) {
      const snackbarProps: ProjectMoveConfirmationProps = {
        projectOrFolderName: item.folderName,
        itemType: 'project',
        destinationFolderId: item.folderId,
        destinationFolderName: newParentFolderName,
        isCopy,
      };
      updateMoveInProgressSnackbar(snackbarProps, false);

      try {
        await moveFolder(item.folderId, oldParentFolderId, newParentFolderId);
      } catch (e) {
        const tag = e.error && e.error.error && e.error.error['.tag'];
        if (tag && tag === 'max_depth_exceeded') {
          showErrorModal = true;
        }
        updateMoveInProgressSnackbar(snackbarProps, true);
      }
      onFolderMove(
        item.folderId,
        item.folderName,
        newParentFolderId,
        newParentFolderName,
        moveSource,
        showErrorModal,
        isCopy,
      );
    }
  } else {
    // Bulk tile move
    let showErrorModal = false;
    if (oldParentFolderId && isValidNewFolder(item, oldParentFolderId, newParentFolderId)) {
      const snackbarProps: ProjectMoveConfirmationProps = {
        itemType: 'bulk',
        destinationFolderId: newParentFolderId,
        destinationFolderName: newParentFolderName,
        folderIds: item.folderIds,
        projectIds: item.projectIds,
        isCopy,
      };
      updateMoveInProgressSnackbar(snackbarProps, false);

      try {
        await moveItems(item.folderIds, item.projectIds, oldParentFolderId, newParentFolderId);

        onBulkMove(
          item.folderIds,
          item.projectIds,
          newParentFolderId,
          newParentFolderName,
          moveSource,
          showErrorModal,
          isCopy,
        );
      } catch (e) {
        const tag = e.error && e.error.error && e.error.error['.tag'];

        if (tag && tag === 'max_depth_exceeded') {
          showErrorModal = true;
        }

        updateMoveInProgressSnackbar(snackbarProps, true);
      }
    }
  }
};

export const previewOptions = {
  offsetX: 2,
  offsetY: 2,
};
