import React, {useRef, useState} from 'react';

export const useResizeObserver = (): [React.RefObject<HTMLDivElement>, DOMRectReadOnly | null] => {
  const [dimensions, setDimensions] = useState<DOMRectReadOnly | null>(null);
  const observerTarget = useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const observeTarget = observerTarget.current;
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        return setDimensions(entry.contentRect);
      });
    });

    if (observeTarget) {
      resizeObserver.observe(observeTarget);
    }

    return () => {
      if (observeTarget) {
        resizeObserver.unobserve(observeTarget);
      }
    };
  }, [observerTarget]);

  return [observerTarget, dimensions];
};

export function fillElementWithText(el: null | HTMLElement, text: string): void {
  if (el === null) {
    return;
  }
  const container = el.firstElementChild as HTMLElement;
  if (container === null) {
    return;
  }
  const NAME_LINE_HEIGHT = parseInt(
    getComputedStyle(el).getPropertyValue(
      // line-height value for dig Text element with
      // size="large" and variant="paragraph"
      '--type__body__large--lineheight_paragraph',
    ),
    10,
  );

  function displayStringIsTooLong(cand: string, nameEl: HTMLElement) {
    container.innerText = cand;
    return nameEl.clientHeight > NAME_LINE_HEIGHT * 2;
  }

  const chars = text.split('');
  for (let i = 0, cand = ''; i < chars.length; i += 1) {
    const c = chars[i];
    cand += c === ' ' ? '\u00a0' : c;
    if (displayStringIsTooLong(cand, el)) {
      // shave off the offending char
      cand = cand.slice(0, cand.length - 2);

      while (displayStringIsTooLong(`${cand}…`, el)) {
        // trim down till it can fit the ellipsis
        cand = cand.slice(0, cand.length - 2);
      }

      // trim trailing whitespace
      cand = `${cand.trim()}…`;
      container.innerText = cand;
      break;
    }
  }
}
