import React from 'react';

import {stormcrowExposures, useStormcrows} from '~/lib/stormcrow';
import {useLoggingClient} from '~/lib/use_logging_client';

export const StormcrowExposureLogger = () => {
  const logger = useLoggingClient();
  const stormcrows = useStormcrows();
  React.useEffect(() => {
    stormcrowExposures.forEach((feature) => {
      const variant = stormcrows[feature];
      if (variant) {
        logger.logStormcrowExposure({
          feature: feature,
          experimentVariant: variant,
        });
      }
    });
  }, [logger, stormcrows]);
  return null;
};
