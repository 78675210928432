import {forwardRef} from 'react';

import {useFormContext, useWatch} from 'react-hook-form';

import type {ChipProps as DigChipProps} from '@dropbox/dig-components/chip';
import {Chip as DigChip} from '@dropbox/dig-components/chip';

export * from '@dropbox/dig-components/chip';

type ChipProps = DigChipProps & {
  name: string;
};

export const ToggleChip = forwardRef<HTMLButtonElement, ChipProps>(({name, ...props}, ref) => {
  const {setValue, control} = useFormContext();
  const value = useWatch({control, name});

  const handleClick = () => {
    setValue(name, !value);
  };

  return (
    <DigChip
      ref={ref}
      {...props}
      aria-checked={value}
      isSelected={value}
      onClick={handleClick}
      role="switch"
      type="button"
    />
  );
});
