import React, {useEffect, useRef, useState} from 'react';

import {PAP_Select_WorkspaceAction} from 'pap-events/replay/select_workspace_action';
import {useIntl} from 'react-intl';
import styled, {css} from 'styled-components';

import {ClickOutside} from '@dropbox/dig-components/click_outside';
import type {ControlPlacement} from '@dropbox/dig-components/tooltips';
import {Tooltip} from '@dropbox/dig-components/tooltips';
import {Truncate} from '@dropbox/dig-components/truncate';
import {UIIcon} from '@dropbox/dig-icons';
import {EditLine} from '@dropbox/dig-icons/assets';

import {StylelessButton} from '~/components/button';
import {useErrorSnackbar} from '~/components/snackbar/error_snackbar';
import {
  color,
  fontFamily,
  fontSize,
  fontWeight,
  ifProp,
  lineHeight,
  radius,
  spacing,
} from '~/components/styled';
import {ReplayTextInput} from '~/components/text_input';
import {MAX_PROJECT_LENGTH} from '~/lib/constants';
import {ReplayError, ReplayErrorCategory, reportException} from '~/lib/error_reporting';
import {useLoggingClient} from '~/lib/use_logging_client';

type EditableInputType = 'title' | 'text';

const textStyles = css`
  font-family: ${fontFamily('Text')};
  font-size: ${fontSize('Text Medium')};
  min-height: ${spacing('Macro Small')};
`;

const titleStyles = css`
  font-family: ${fontFamily('Title')};
  font-size: ${fontSize('Title Medium')};
  font-weight: ${fontWeight('Base')};
  min-height: ${lineHeight('Title Medium')};
`;

const EditableTextInput = styled(ReplayTextInput)<{$type: EditableInputType}>`
  &&& {
    ${({$type}) => ($type === 'text' ? textStyles : titleStyles)}
`;

interface EditableTextButtonProps {
  $isPlaceholder?: boolean;
}

const spacingStyles = css`
  padding: ${spacing('2')};
  margin-left: calc(${spacing('2')} * -1);
`;

const EditableTextButton = styled(StylelessButton)<EditableTextButtonProps>`
  ${spacingStyles};
  border: 2px solid transparent;
  border-radius: ${radius('Small')};

  display: inline-block;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${color('Text Base')};

  ${ifProp(
    '$isPlaceholder',
    css`
      color: ${color('Text Subtle')};
    `,
  )}

  .dig-StylelessButton-content {
    ${textStyles}
  }

  &:hover {
    cursor: text;
    border-color: ${color('Border Subtle')};
  }
`;

const EditableTitle = styled(EditableTextButton)`
  .dig-StylelessButton-content {
    ${titleStyles}
  }
`;

export const RenameFolderIcon = styled(UIIcon)`
  color: ${color('Text Base')};
  height: ${spacing('28')};
  width: ${spacing('28')};
`;

const Root = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing('Micro Small')};

  width: 100%;

  .dig-TextInputContainer {
    border-width: 2px;
    ${spacingStyles};

    &:has(input:focus) {
      border-color: ${color('Utility Focus Ring')};
      box-shadow: none;
    }
  }
`;

type EditableInputProps = {
  hoverIcon?: React.ReactNode;
  ariaLabel?: string;
  disabled?: boolean;
  inputBadge?: React.ReactNode;
  textComponentPlaceholder?: string;
  textValue: string;
  tooltipPlacement?: ControlPlacement;
  tooltipText?: string;
  type: EditableInputType;
  onChangeTextValue: (newText: string) => Promise<void>;
};

export const EditableInput = ({
  onChangeTextValue,
  hoverIcon,
  ariaLabel = '',
  disabled = false,
  inputBadge,
  textComponentPlaceholder = '',
  textValue,
  tooltipPlacement = 'bottom-end',
  tooltipText = '',
  type,
}: EditableInputProps) => {
  const intl = useIntl();
  const loggingClient = useLoggingClient();
  const [isHoveringText, setIsHoveringText] = useState<boolean>(false);
  const [isEditingText, setIsEditingText] = useState<boolean>(false);
  const [editingTextValue, setEditingTextValue] = useState<string>(textValue);

  const textValueRef = useRef(textValue);

  const {openErrorSnackbar} = useErrorSnackbar();

  useEffect(() => {
    setEditingTextValue(textValue);
  }, [textValue]);

  // textValueRef and this effect are used to fix an issue where state was stale in the handleRetextValue
  // callback coming from ClickOutside dig-component
  useEffect(() => {
    textValueRef.current = editingTextValue;
  }, [editingTextValue]);

  const handleOnMouseEnter = () => {
    if (!disabled) {
      setIsHoveringText(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (!disabled) {
      setIsHoveringText(false);
    }
  };

  const handleOnClickText = () => {
    if (!disabled) {
      loggingClient.logPap(
        PAP_Select_WorkspaceAction({
          workspaceAction: 'modify_project',
          actionDetail: type === 'text' ? 'description' : 'title',
        }),
      );
      setIsEditingText(true);
    }
  };

  const handleOnChangeValue = async () => {
    setIsEditingText(false); // Always set to false immediately to disable click outside
    setIsHoveringText(false);

    const newTextValue = textValueRef.current.trim();

    if (newTextValue !== textValue) {
      try {
        await onChangeTextValue(newTextValue);
      } catch (e) {
        reportException(
          new ReplayError({
            category: ReplayErrorCategory.BadDataError,
            severity: 'non-critical',
            error: new Error('Failed to update project title or description'),
          }),
        );
        setEditingTextValue(textValue);
        openErrorSnackbar(
          intl.formatMessage({
            defaultMessage: 'Failed to edit',
            id: 'uRIAi8',
            description: 'There was an error attempting to rename the title of the folder',
          }),
        );
      }
    }
  };

  const rightAccessory = hoverIcon || <RenameFolderIcon size="large" src={EditLine} />;

  if (isEditingText && !disabled) {
    return (
      <Root>
        <ClickOutside
          isActive={true}
          onClickOutside={handleOnChangeValue}
          shouldPropagateMouseEvents
        >
          <EditableTextInput
            $type={type}
            aria-label={ariaLabel}
            maxLength={MAX_PROJECT_LENGTH}
            onChange={(event) => setEditingTextValue(event.target.value)}
            onEnter={handleOnChangeValue}
            value={editingTextValue}
            withRightAccessory={rightAccessory}
          />
        </ClickOutside>
      </Root>
    );
  }

  const TextComponent = type === 'title' ? EditableTitle : EditableTextButton;

  const content = (
    <TextComponent
      $isPlaceholder={!editingTextValue}
      aria-label={ariaLabel}
      data-testid="editable-text-button"
      onClick={handleOnClickText}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
    >
      <Truncate>{editingTextValue || textComponentPlaceholder}</Truncate>
    </TextComponent>
  );

  return (
    <Root>
      {!disabled ? (
        <Tooltip placement={tooltipPlacement} title={tooltipText}>
          {content}
        </Tooltip>
      ) : (
        content
      )}
      {isHoveringText ? rightAccessory : inputBadge}
    </Root>
  );
};
