export const MS_PER_HOUR = 60 * 60 * 1000;
export const MS_PER_DAY = 24 * MS_PER_HOUR;

export const enum DueDateTimeframe {
  OVERDUE_MORE_THAN_2_DAYS,
  OVERDUE_LESS_THAN_2_DAYS,
  WITHIN_5_HOURS,
  TODAY,
  TOMORROW,
  FUTURE, // Due day is any time past tomorrow
}

export function getDueDateTimeframe(dueDate: Date): DueDateTimeframe {
  const currentDate = new Date();
  const twoDaysAgo = new Date(currentDate.getTime() - MS_PER_DAY * 2);
  if (dueDate < twoDaysAgo) {
    return DueDateTimeframe.OVERDUE_MORE_THAN_2_DAYS;
  }

  if (dueDate < currentDate) {
    return DueDateTimeframe.OVERDUE_LESS_THAN_2_DAYS;
  }

  const hoursToDueDate = (dueDate.getTime() - currentDate.getTime()) / MS_PER_HOUR;
  if (hoursToDueDate <= 5) {
    return DueDateTimeframe.WITHIN_5_HOURS;
  }

  const isDueDateToday = dueDate.toDateString() === currentDate.toDateString();
  if (isDueDateToday) {
    return DueDateTimeframe.TODAY;
  }

  const tomorrow = new Date(currentDate.getTime() + MS_PER_DAY);
  const isDueDateTomorrow = dueDate.toDateString() === tomorrow.toDateString();
  if (isDueDateTomorrow) {
    return DueDateTimeframe.TOMORROW;
  }

  return DueDateTimeframe.FUTURE;
}

export function getHoursToDueDate(dueDate: Date): number {
  const currentDate = new Date();
  // Round hours up, because if due date is in 30min, we want to say "Due in 1 hour" not "Due in 0 hours"
  return Math.ceil((dueDate.getTime() - currentDate.getTime()) / MS_PER_HOUR);
}

export function getDaysToDueDate(dueDate: Date): number {
  const currentDate = new Date();
  // Round days up, because if due date is in 12 hours, we want to say "Due in 1 day" not "Due in 0 days"
  return Math.ceil((dueDate.getTime() - currentDate.getTime()) / MS_PER_DAY);
}
