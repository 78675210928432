import React from 'react';

import {useAtom} from 'jotai';
import styled from 'styled-components';

import {Text} from '@dropbox/dig-components/typography';
import {UIIcon} from '@dropbox/dig-icons';
import {ArrowDownLine, ArrowUpLine} from '@dropbox/dig-icons/assets';

import {Button} from '~/components/button';
import {useBrowseLogEventMaker} from '~/pages/browse_page/browse_logging_context';
import type {SortAttr} from '~/pages/browse_page/types';
import {browseSortAtom} from '~/state/browse';

const SortDirectionIcon = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px;
`;

export type SortingControlProps = {
  component: React.ElementType;
  labelText: string;
  attribute: SortAttr;
};

export const SortingControl = ({component: Header, labelText, attribute}: SortingControlProps) => {
  const makeLogEvent = useBrowseLogEventMaker();
  const logEvent = React.useMemo(() => makeLogEvent(null), [makeLogEvent]);

  const [sort, setSort] = useAtom(browseSortAtom);

  const onClick = () => {
    const direction =
      sort.direction === 'descending' && sort.attribute === attribute ? 'ascending' : 'descending';

    // eslint-disable-next-line deprecation/deprecation
    logEvent('select_toggle_sort', {attribute, direction});
    setSort({attribute, direction});
  };

  return (
    <Header
      aria-sort={sort.attribute === attribute ? sort.direction : undefined}
      role="columnheader"
    >
      <Button hasNoUnderline onClick={onClick} variant="transparent">
        <Text isBold={attribute === sort.attribute} variant="label">
          {labelText}
        </Text>
        {attribute === sort.attribute && (
          <SortDirectionIcon>
            <UIIcon
              size="small"
              src={sort.direction === 'descending' ? ArrowDownLine : ArrowUpLine}
            />
          </SortDirectionIcon>
        )}
      </Button>
    </Header>
  );
};

export const NonSortableHeader = ({labelText}: {labelText: string}) => {
  return (
    <Button disabled hasNoUnderline variant="transparent">
      <Text variant="label">{labelText}</Text>
    </Button>
  );
};
