import {useIntl} from 'react-intl';

import type {InitialSessionState} from '~/context';
import {useReelAppGlobalState} from '~/context';
import {DBX_CLIENT_DOMAIN, IS_DBX_DEVBOX} from '~/context_utils';
import {useHasFullAddonAccess, useIsGFAddonUser} from '~/lib/utils';
import {isInWebKit} from '~/use_extensions';

interface QuotaFeaturesInterface {
  file_count_limit: {
    available: number;
    used: number;
    unlimited: boolean;
  };
}

interface FlagFeaturesInterface {
  live_review: boolean;
  pin_comments: boolean;
  links_hub: boolean;
  disable_share_links: boolean;
  deadlines_and_tasks: boolean;
  password_protected_links: boolean;
  archive_to_dbx: boolean;
  transcriptions: boolean;
  version_comparison: boolean;
  other: boolean;
}
export interface ProvisionTierInterface {
  provision_tier: 'basic_no_addon' | 'pro_no_addon' | 'full_access' | 'other';
}
export type FullProvisionsType = QuotaFeaturesInterface &
  FlagFeaturesInterface &
  ProvisionTierInterface;

export const defaultProvisions: FullProvisionsType = {
  provision_tier: 'basic_no_addon',
  file_count_limit: {
    available: 0,
    used: 0,
    unlimited: false,
  },
  live_review: false,
  pin_comments: false,
  links_hub: false,
  disable_share_links: false,
  deadlines_and_tasks: false,
  password_protected_links: false,
  archive_to_dbx: false,
  transcriptions: false,
  version_comparison: false,
  other: false,
};

export type UserMetadataType = {
  REPLAY_SEEN_ADDON_WELCOME_MODAL: boolean;
  REPLAY_SEEN_BETA_WELCOME_MODAL: boolean;
  REPLAY_SEEN_BETA_REMINDER_MODAL: boolean;
  REPLAY_SEEN_BETA_EXPIRED_MODAL: boolean;
  REPLAY_SEEN_FREE_WELCOME_MODAL: boolean;
  REPLAY_DISMISSED_QUOTA_BANNER: number;
  REPLAY_FIRST_LIVE_REVIEW_HOSTED: boolean;
  REPLAY_DISMISSED_NEW_DRAWING_MENU_TOOLTIP: boolean;
  REPLAY_DISMISSED_ONBOARDING_CHECKLIST_COUNT: number;
  REPLAY_EDITOR_PAGE_VOLUME_LEVEL: number;
  REPLAY_HAS_DISMISSED_PINNED_COMMENT_TOOLTIP: boolean;
  REPLAY_SEEN_SETTINGS_HAS_MOVED_TOOLTIP: boolean;
  REPLAY_DISMISSED_COMMENTS_V2_TOOLTIP: boolean;
  REPLAY_SEGMENTATION_QUIZ_VIEW_STATE: string;
  REPLAY_SEEN_ADMIN_MOVED_TOOLTIP: boolean;
  REPLAY_DISMISSED_PRIVATE_COMMENTS_TOOLTIP: boolean;
};

// The user should take the quiz once they come back from the purchase flow
export const SEGMENTATION_QUIZ_PENDING_PURCHASE_STATE = 'pending_purchase';
// The user has taken the quiz
export const SEGMENTATION_QUIZ_VIEWED_STATE = 'viewed';
// Default state, unset
const SEGMENTATION_QUIZ_UNSET = '';

export type SegmentationQuizViewState =
  | typeof SEGMENTATION_QUIZ_UNSET
  | typeof SEGMENTATION_QUIZ_PENDING_PURCHASE_STATE
  | typeof SEGMENTATION_QUIZ_VIEWED_STATE;

export enum USER_METADATA_FIELD {
  'REPLAY_SEEN_ADDON_WELCOME_MODAL',
  'REPLAY_SEEN_BETA_WELCOME_MODAL',
  'REPLAY_SEEN_BETA_REMINDER_MODAL',
  'REPLAY_SEEN_BETA_EXPIRED_MODAL',
  'REPLAY_SEEN_FREE_WELCOME_MODAL',
  'REPLAY_DISMISSED_QUOTA_BANNER',
  'REPLAY_FIRST_LIVE_REVIEW_HOSTED',
  'REPLAY_DISMISSED_NEW_DRAWING_MENU_TOOLTIP',
  'REPLAY_DISMISSED_ONBOARDING_CHECKLIST_COUNT',
  'REPLAY_EDITOR_PAGE_VOLUME_LEVEL',
  'REPLAY_HAS_DISMISSED_PINNED_COMMENT_TOOLTIP',
  'REPLAY_SEEN_SETTINGS_HAS_MOVED_TOOLTIP',
  'REPLAY_DISMISSED_COMMENTS_V2_TOOLTIP',
  'REPLAY_SEGMENTATION_QUIZ_VIEW_STATE',
  'REPLAY_SEEN_ADMIN_MOVED_TOOLTIP',
  'REPLAY_DISMISSED_PRIVATE_COMMENTS_TOOLTIP',
}

export enum PREFERENCE_SETTING_METADATA_FIELD {
  REPLAY_ENABLE_NOTIFICATIONS_PREFERENCE = 'REPLAY_ENABLE_NOTIFICATIONS_PREFERENCE',
  REPLAY_PROJECT_MEMBERSHIP_UPDATE_NOTIFICATION_PREFERENCE = 'REPLAY_PROJECT_MEMBERSHIP_UPDATE_NOTIFICATION_PREFERENCE',
  REPLAY_COMMENT_NOTIFICATION_PREFERENCE = 'REPLAY_COMMENT_NOTIFICATION_PREFERENCE',
  REPLAY_MENTION_AND_THREAD_COMMENT_NOTIFICATION_PREFERENCE = 'REPLAY_MENTION_AND_THREAD_COMMENT_NOTIFICATION_PREFERENCE',
  REPLAY_STATUS_UPDATE_NOTIFICATION_PREFERENCE = 'REPLAY_STATUS_UPDATE_NOTIFICATION_PREFERENCE',
  REPLAY_CONTENT_UPDATE_NOTIFICATION_PREFERENCE = 'REPLAY_CONTENT_UPDATE_NOTIFICATION_PREFERENCE',
  REPLAY_SHARE_LINK_VIEW_NOTIFICATION_PREFERENCE = 'REPLAY_SHARE_LINK_VIEW_NOTIFICATION_PREFERENCE',
  REPLAY_SETTINGS_COMMENTS_SHOW_ICON_ON_HOVER = 'REPLAY_SETTINGS_COMMENTS_SHOW_ICON_ON_HOVER',
  REPLAY_SETTINGS_COMMENTS_PAUSE_WHEN_TYPING = 'REPLAY_SETTINGS_COMMENTS_PAUSE_WHEN_TYPING',
  REPLAY_SETTINGS_COMMENTS_QUICK_REACTIONS = 'REPLAY_SETTINGS_COMMENTS_QUICK_REACTIONS',
  REPLAY_SETTINGS_SHOW_SUGGESTED_FILES = 'REPLAY_SETTINGS_SHOW_SUGGESTED_FILES',
  REPLAY_SETTINGS_COMMAND_ENTER_SUBMISSION_PREFERENCE = 'REPLAY_SETTINGS_COMMAND_ENTER_SUBMISSION_PREFERENCE',
}

export type PreferenceSettingMetadataType = {
  REPLAY_ENABLE_NOTIFICATIONS_PREFERENCE: boolean;
  REPLAY_PROJECT_MEMBERSHIP_UPDATE_NOTIFICATION_PREFERENCE: string;
  REPLAY_COMMENT_NOTIFICATION_PREFERENCE: string;
  REPLAY_MENTION_AND_THREAD_COMMENT_NOTIFICATION_PREFERENCE: string;
  REPLAY_STATUS_UPDATE_NOTIFICATION_PREFERENCE: string;
  REPLAY_CONTENT_UPDATE_NOTIFICATION_PREFERENCE: string;
  REPLAY_SHARE_LINK_VIEW_NOTIFICATION_PREFERENCE: string;
  REPLAY_SETTINGS_COMMENTS_SHOW_ICON_ON_HOVER: string;
  REPLAY_SETTINGS_COMMENTS_PAUSE_WHEN_TYPING: string;
  REPLAY_SETTINGS_COMMENTS_QUICK_REACTIONS: string;
  REPLAY_SETTINGS_SHOW_SUGGESTED_FILES: string;
  REPLAY_SETTINGS_COMMAND_ENTER_SUBMISSION_PREFERENCE: string;
};

export const PreferenceSettingMetadataONValuesMap: {
  [key in PREFERENCE_SETTING_METADATA_FIELD]: string | boolean;
} = {
  REPLAY_ENABLE_NOTIFICATIONS_PREFERENCE: true,
  REPLAY_PROJECT_MEMBERSHIP_UPDATE_NOTIFICATION_PREFERENCE: 'ON',
  REPLAY_COMMENT_NOTIFICATION_PREFERENCE: 'ON',
  REPLAY_MENTION_AND_THREAD_COMMENT_NOTIFICATION_PREFERENCE: 'ON',
  REPLAY_STATUS_UPDATE_NOTIFICATION_PREFERENCE: 'ON',
  REPLAY_CONTENT_UPDATE_NOTIFICATION_PREFERENCE: 'ON',
  REPLAY_SHARE_LINK_VIEW_NOTIFICATION_PREFERENCE: 'ON',
  REPLAY_SETTINGS_COMMENTS_SHOW_ICON_ON_HOVER: 'ON',
  REPLAY_SETTINGS_COMMENTS_PAUSE_WHEN_TYPING: 'ON',
  REPLAY_SETTINGS_COMMENTS_QUICK_REACTIONS: 'ON',
  REPLAY_SETTINGS_SHOW_SUGGESTED_FILES: 'ON',
  REPLAY_SETTINGS_COMMAND_ENTER_SUBMISSION_PREFERENCE: 'ON',
};

export const PreferenceSettingMetadataOFFValuesMap: {
  [key in PREFERENCE_SETTING_METADATA_FIELD]: string | boolean;
} = {
  REPLAY_ENABLE_NOTIFICATIONS_PREFERENCE: false,
  REPLAY_PROJECT_MEMBERSHIP_UPDATE_NOTIFICATION_PREFERENCE: 'OFF',
  REPLAY_COMMENT_NOTIFICATION_PREFERENCE: 'OFF',
  REPLAY_MENTION_AND_THREAD_COMMENT_NOTIFICATION_PREFERENCE: 'OFF',
  REPLAY_STATUS_UPDATE_NOTIFICATION_PREFERENCE: 'OFF',
  REPLAY_CONTENT_UPDATE_NOTIFICATION_PREFERENCE: 'OFF',
  REPLAY_SHARE_LINK_VIEW_NOTIFICATION_PREFERENCE: 'OFF',
  REPLAY_SETTINGS_COMMENTS_SHOW_ICON_ON_HOVER: 'OFF',
  REPLAY_SETTINGS_COMMENTS_PAUSE_WHEN_TYPING: 'OFF',
  REPLAY_SETTINGS_COMMENTS_QUICK_REACTIONS: 'OFF',
  REPLAY_SETTINGS_SHOW_SUGGESTED_FILES: 'OFF',
  REPLAY_SETTINGS_COMMAND_ENTER_SUBMISSION_PREFERENCE: 'OFF',
};

export const useShowFileQuotaInfo = () => {
  return !useHasFullAddonAccess() && !isInWebKit();
};

export const useExceededFileQuota = () => {
  const sessionContext = useReelAppGlobalState();
  const isGFAddonUser = useIsGFAddonUser();
  if (sessionContext.status === 'logged in') {
    if (sessionContext.provisions.file_count_limit.unlimited || isGFAddonUser) {
      return false;
    }
    const fileCountLimit = sessionContext.provisions.file_count_limit;
    return fileCountLimit.used >= fileCountLimit.available;
  }
  return false;
};

export const useGetRemainingFileQuota = () => {
  const sessionContext = useReelAppGlobalState();
  const isGFAddonUser = useIsGFAddonUser();
  if (sessionContext.status === 'logged in') {
    if (sessionContext.provisions.file_count_limit.unlimited || isGFAddonUser) {
      return Infinity;
    }
    const fileCountLimit = sessionContext.provisions.file_count_limit;
    return fileCountLimit.available - fileCountLimit.used;
  }
  return null;
};

export const useGetQuotaExceededErrorText = () => {
  const intl = useIntl();
  const errorText = intl.formatMessage({
    defaultMessage: "Couldn't add files. Number of files exceeds limit.",
    id: 'yGfjep',
    description:
      'Text on upload snackbar that lets users know when they have exceeded their file limit.',
  });

  // TODO: Remove this after errorText string is translated. This is a temporary fix so the string is translated for GA.
  const nonEnglishErrorText = intl.formatMessage({
    defaultMessage: 'You’ve reached your file upload limit.',
    id: 'P4Ib6e',
    description: 'Message informing users they have reached their file upload quota limit',
  });

  return intl.locale.startsWith('en') ? errorText : nonEnglishErrorText;
};

export const useGetFileQuotaText = () => {
  const sessionContext = useReelAppGlobalState();
  const intl = useIntl();
  if (sessionContext.status === 'logged in') {
    const fileCountLimit = sessionContext.provisions.file_count_limit;
    if (fileCountLimit.used < fileCountLimit.available) {
      return intl.formatMessage(
        {
          defaultMessage: 'You have {quotaLeft, plural, one{# upload} other{# uploads}} left',
          id: 'dXw273',
          description: 'Message informing users they have n number of files left',
        },
        {quotaLeft: fileCountLimit.available - fileCountLimit.used},
      );
    } else {
      return intl.formatMessage({
        defaultMessage: 'You’ve reached your file upload limit.',
        id: 'P4Ib6e',
        description: 'Message informing users they have reached their file upload quota limit',
      });
    }
  }
  return '';
};

export type ProvisionUserVariant =
  | 'none'
  | 'basic_individual'
  | 'family_user'
  | 'edu_user'
  | 'paying_individual'
  | 'managed_team_member'
  | 'managed_team_admin'
  | 'self_serve_team_member'
  | 'self_serve_team_admin'
  | 'standalone_free_member'
  | 'standalone_free_admin'
  | 'standalone_paid_member'
  | 'standalone_paid_admin';

export type CtaType = 'learn_more' | 'upgrade' | 'manage_add_on' | 'buy_replay';

export type ProvisionCtaType = {
  ctaLink: string;
  ctaLabel: string;
  ctaType: CtaType;
};

export const getUserVariant = (sessionContext: InitialSessionState): ProvisionUserVariant => {
  if (sessionContext.status === 'logged in') {
    const provisionTier = sessionContext.provisions.provision_tier;
    const isTeamMember = !!sessionContext.currentAccount.is_team_member;
    const isTeamAdmin = !!sessionContext.currentAccount.is_team_admin;
    const isStandaloneUser = !!sessionContext.currentAccount.is_standalone_user;
    const skuName = sessionContext.currentAccount.sku_name;
    const skuFamily = sessionContext.currentAccount.sku_family;
    const isManagedUser = !!sessionContext.currentAccount.is_managed_user;
    const isSelfServeUser = !!sessionContext.currentAccount.is_self_serve_user;

    // Excluded SKUs
    if (skuFamily?.toLocaleLowerCase().includes('edu')) {
      return 'edu_user';
    }
    if (skuName?.toLowerCase().includes('family')) {
      return 'family_user';
    }

    // Team users
    if (isTeamMember && isManagedUser) {
      return isTeamAdmin ? 'managed_team_admin' : 'managed_team_member';
    }
    if (isStandaloneUser && provisionTier === 'full_access') {
      return isTeamAdmin ? 'standalone_paid_admin' : 'standalone_paid_member';
    }
    if (isStandaloneUser) {
      return isTeamAdmin ? 'standalone_free_admin' : 'standalone_free_member';
    }
    if (isTeamMember && isSelfServeUser) {
      return isTeamAdmin ? 'self_serve_team_admin' : 'self_serve_team_member';
    }
    // We should never get here
    if (isTeamMember) {
      return 'none';
    }

    // Individuals
    if (provisionTier === 'full_access') {
      return 'none';
    }

    if (provisionTier === 'pro_no_addon') {
      return 'paying_individual';
    }

    if (provisionTier === 'basic_no_addon') {
      return skuName?.toLowerCase() === 'basic' ? 'basic_individual' : 'paying_individual';
    }
  }
  return 'none';
};

export const useGetUserVariant = (): ProvisionUserVariant => {
  const sessionContext = useReelAppGlobalState();
  return getUserVariant(sessionContext);
};

export const CURRENT_ORIGIN = IS_DBX_DEVBOX
  ? `https://meta-${DBX_CLIENT_DOMAIN}`
  : 'https://www.dropbox.com';
const ENCODED_REPLAY_ORIGIN = encodeURIComponent('https://replay.dropbox.com');
export const SINGLE_PURCHASE_URL = `/buy`;
export const REPLAY_MARKETING_PAGE = `${CURRENT_ORIGIN}/replay`;
export const REPLAY_MARKETING_PAGE_ADDON_PLANK = `${REPLAY_MARKETING_PAGE}#add-on`;
const REPLAY_ADDON_PURCHASE_MODAL_QUERY_STRING = 'modal=manage_addon&addon_family=REPLAY';
export const REPLAY_ADDON_PURCHASE_MODAL_INDIVIDUAL = `${CURRENT_ORIGIN}/account/plan?${REPLAY_ADDON_PURCHASE_MODAL_QUERY_STRING}`;
export const REPLAY_ADDON_PURCHASE_MODAL_TEAM_ADMIN = `${CURRENT_ORIGIN}/team/admin/billing?${REPLAY_ADDON_PURCHASE_MODAL_QUERY_STRING}&return_to=replay`;
const REPLAY_ADDON_PURCHASE_MODAL_QUERY_STRING_PARTIAL_PROVISIONING =
  'showAddonPurchaseForTeam=true';
export const REPLAY_ADDON_PURCHASE_MODAL_TEAM_ADMIN_PARTIAL_PROVISIONING = `${CURRENT_ORIGIN}/e/team/admin/members?${REPLAY_ADDON_PURCHASE_MODAL_QUERY_STRING_PARTIAL_PROVISIONING}&return_to=replay`;
const REPLAY_UXA_PARAMS = `previous_url=${ENCODED_REPLAY_ORIGIN}&extra["param_tk"]=replay`;
export const DROPBOX_VIEW_PLANS_PAGE = `${CURRENT_ORIGIN}/plans?${REPLAY_UXA_PARAMS}`;
export const DROPBOX_VIEW_PLANS_PAGE_WITHOUT_PARAMS = `${CURRENT_ORIGIN}/plans`;
export const DROPBOX_MANAGE_PLANS_PAGE = `${CURRENT_ORIGIN}/account/plan?${REPLAY_UXA_PARAMS}`;
export const REPLAY_URL = 'http://replay.dropbox.com/?login=true';
export const DROPBOX_DECIDE_URL = `${CURRENT_ORIGIN}/team/decide?${REPLAY_UXA_PARAMS}`;
export const BASIC_USER_REDIRECT_URL = `${CURRENT_ORIGIN}/buy/plus?addons=replay&cont=replay-addon&oqa=replay_web_app`;
export const DROPBOX_BILLING_PAGE = `${CURRENT_ORIGIN}/manage/billing?modal=billing_info`;
export const DROPBOX_ADMIN_CONSOLE_BILLING_PAGE = `${CURRENT_ORIGIN}/team/admin/billing?${REPLAY_UXA_PARAMS}&return_to=replay`;
export const DROPBOX_ADMIN_CONSOLE_GROUPS_PAGE = `${CURRENT_ORIGIN}/team/admin/groups?${REPLAY_UXA_PARAMS}&return_to=replay`;
export const DROPBOX_ADMIN_CONSOLE_MEMBERS_PAGE = `${CURRENT_ORIGIN}/team/admin/members?${REPLAY_UXA_PARAMS}&return_to=replay`;
export const DROPBOX_ADMIN_CONSOLE_REPLAY_PAGE = `${CURRENT_ORIGIN}/team/admin/settings/replay?${REPLAY_UXA_PARAMS}&return_to=replay`;
export const DROPBOX_ADMIN_CONSOLE_DASHBOARD_PAGE = `${CURRENT_ORIGIN}/team/admin/dashboard?${REPLAY_UXA_PARAMS}&return_to=replay`;
export const MPE_REPLAY_STANDALONE_CHECKOUT_PAGE = `${CURRENT_ORIGIN}/checkout?key=replay_only&product[]=114&disable_plan_selection=1`;
export const REPLAY_STORAGE_ADDON_CHECKOUT_PAGE = `${CURRENT_ORIGIN}/team/admin/billing?modal=manage_addon&addon_family=replay_storage&quota_gb=1024&return_to=replay`;
export const DROPBOX_MANAGE_ACCOUNT_PAGE = `${CURRENT_ORIGIN}/account?${REPLAY_UXA_PARAMS}`;
export const DROPBOX_TRANSFER_MANAGE_PAGE = `${CURRENT_ORIGIN}/transfer/manage?${REPLAY_UXA_PARAMS}`;

export const useGetProvisionCta = (userVariant: ProvisionUserVariant): ProvisionCtaType => {
  const intl = useIntl();
  const ctaLink = SINGLE_PURCHASE_URL;

  if (isInWebKit()) {
    return {
      ctaLabel: intl.formatMessage({
        defaultMessage: 'Learn more',
        id: 'LviS9r',
        description: 'Button label to direct to help article about the Replay add on',
      }),
      ctaLink: 'https://help.dropbox.com/installs/dropbox-replay-add-on',
      ctaType: 'learn_more',
    };
  }

  switch (userVariant) {
    case 'basic_individual':
      return {
        ctaLabel: intl.formatMessage({
          defaultMessage: 'Upgrade',
          id: 'awjGEW',
          description: 'Button label to upgrade to Dropbox Plus and buy add-on',
        }),
        ctaLink: ctaLink,
        ctaType: 'upgrade',
      };
    case 'family_user':
      return {
        ctaLabel: intl.formatMessage({
          defaultMessage: 'Learn more',
          id: '9WbZiY',
          description: 'Button label to take user to marketing page',
        }),
        ctaLink: ctaLink,
        ctaType: 'learn_more',
      };
    case 'paying_individual':
    case 'self_serve_team_admin':
    case 'standalone_free_admin':
      return {
        // Once strings are translated, show for all languages
        ctaLabel: intl.locale.startsWith('en-')
          ? intl.formatMessage({
              defaultMessage: 'Upgrade',
              id: 'reWagY',
              description: 'Button label for freemium Replay users to upgrade to a paid version',
            })
          : intl.formatMessage({
              defaultMessage: 'Manage Add-Ons',
              id: 'EZnlv4',
              description: 'Button label to purchase the Replay add-on',
            }),
        ctaLink: ctaLink,
        ctaType: 'manage_add_on',
      };
    case 'managed_team_member':
    case 'self_serve_team_member':
    case 'managed_team_admin':
    case 'standalone_free_member':
      return {
        ctaLabel: intl.formatMessage({
          defaultMessage: 'Learn more',
          id: 'APzLYJ',
          description: 'Button label to direct to Replay marketing page',
        }),
        ctaLink: ctaLink,
        ctaType: 'learn_more',
      };
  }
  return {
    ctaLabel: intl.formatMessage({
      defaultMessage: 'Learn more',
      id: 'APzLYJ',
      description: 'Button label to direct to Replay marketing page',
    }),
    ctaLink: ctaLink,
    ctaType: 'learn_more',
  };
};

// Mostly copied over from existing useGetProvisionCta but these will be used specifically
// for add-on upsell modals and use CTA "Buy Replay" everywhere.
export const useGetProvisionCtav2 = (
  userVariant: ProvisionUserVariant,
  isEligible: boolean,
): ProvisionCtaType => {
  const intl = useIntl();
  const ctaLink = SINGLE_PURCHASE_URL;

  if (isInWebKit()) {
    return {
      ctaLabel: intl.formatMessage({
        defaultMessage: 'Learn more',
        id: 'LviS9r',
        description: 'Button label to direct to help article about the Replay add on',
      }),
      ctaLink: 'https://help.dropbox.com/installs/dropbox-replay-add-on',
      ctaType: 'learn_more',
    };
  }

  switch (userVariant) {
    case 'basic_individual':
      return {
        ctaLabel: intl.formatMessage({
          defaultMessage: 'Buy Replay',
          id: '6KQhA4',
          description: 'Button label to upgrade to Dropbox Plus and buy add-on',
        }),
        ctaLink: ctaLink,
        ctaType: 'buy_replay',
      };
    case 'paying_individual':
    case 'self_serve_team_admin':
      if (!isEligible) {
        return {
          ctaLabel: intl.formatMessage({
            defaultMessage: 'Learn more',
            id: 'APzLYJ',
            description: 'Button label to direct to Replay marketing page',
          }),
          ctaLink: ctaLink,
          ctaType: 'learn_more',
        };
      }
      return {
        ctaLabel: intl.formatMessage({
          defaultMessage: 'Buy Replay',
          id: 'fsMbvw',
          description: 'Button label to purchase the Replay add-on',
        }),
        ctaLink: ctaLink,
        ctaType: 'buy_replay',
      };
  }
  return {
    ctaLabel: intl.formatMessage({
      defaultMessage: 'Learn more',
      id: 'APzLYJ',
      description: 'Button label to direct to Replay marketing page',
    }),
    ctaLink: ctaLink,
    ctaType: 'learn_more',
  };
};

export const getReplayGATime = (stormcrows: {replay_ga_time?: string}): Date => {
  const startDateMsStr = stormcrows.replay_ga_time;
  const startDateMs = Number(startDateMsStr);
  const defaultStartDate = new Date(2023, 3, 25);
  if (isNaN(startDateMs)) {
    // Default to 4/25
    return defaultStartDate;
  }

  const startDate = new Date(startDateMs);

  // Be extra cautious here and do a sanity check on the value
  if (startDate.getFullYear() !== 2023) {
    return defaultStartDate;
  }

  return startDate;
};

export const isReplayInGA = (replay_ga_time: string): boolean => {
  const gaTime = getReplayGATime({replay_ga_time});
  return new Date() > gaTime;
};

export const getBetaGracePeriod = (stormcrows: {
  replay_ga_time: string;
  replay_beta_grace_period_end_time: string;
}): {startDate: Date; endDate: Date} => {
  const endDateMsStr = stormcrows.replay_beta_grace_period_end_time;
  const endDateMs = Number(endDateMsStr);
  const startDate = getReplayGATime(stormcrows);

  // Default end to 6/25
  const defaultPeriod = {
    startDate,
    endDate: new Date(2023, 5, 25),
  };

  if (isNaN(endDateMs)) {
    return defaultPeriod;
  }

  const endDate = new Date(endDateMs);

  // Be extra cautious here and do a sanity check on the values
  if (startDate > endDate || endDate.getFullYear() !== 2023) {
    return defaultPeriod;
  }

  return {
    startDate,
    endDate,
  };
};
