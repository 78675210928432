import {useIntl} from 'react-intl';
import styled from 'styled-components';

import {Modal} from '@dropbox/dig-components/modal';
import {Title} from '@dropbox/dig-components/typography';

import {Button} from '~/components/button';
import {useViewport} from '~/components/viewport_context';

type CreateFolderModalProps = {
  open: boolean;
  onFolderCreateSuccess?: (folderId: string, level: number) => void;
  requestClose: (cancel: boolean) => void;
  invalidExtensions: string[];
};

const ModalBody = styled(Modal.Body)`
  padding-bottom: 24px;
`;

const ModalBodyText = styled.div`
  color: var(--color__white);
  margin-bottom: 16px;
`;

const ModalButtons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CreateButton = styled.div`
  padding-left: 8px;
`;

export const InvalidExtensionModal = (props: CreateFolderModalProps) => {
  const {invalidExtensions, requestClose, open} = props;
  // eslint-disable-next-line deprecation/deprecation
  const {isSmallScreenSize} = useViewport();

  const intl = useIntl();

  const projectCreationText = intl.formatMessage(
    {
      defaultMessage:
        'The following files are not supported by Replay and cannot be uploaded: {ext}',
      id: 'sAVNUH',
      description: 'Description for listing out unsupported files',
    },
    {
      ext: invalidExtensions.join(', '),
    },
  );

  const HeaderText = intl.formatMessage({
    defaultMessage: 'Unable to upload one or more files',
    id: 'sFf34g',
    description: 'Heading text in modal for invalid file extensions',
  });

  const continueButtonText = intl.formatMessage({
    defaultMessage: 'Skip these files',
    id: 'PUmKuY',
    description: 'Label on button in extension modal to continue uploading files',
  });

  const cancelButtonText = intl.formatMessage({
    defaultMessage: 'Cancel',
    id: 'xQK2wU',
    description: 'Label on button in extension modal to cancel uploading files',
  });

  return (
    <Modal
      fullScreen={false}
      isCentered={isSmallScreenSize ? false : true}
      onRequestClose={() => {}}
      open={open}
    >
      <Modal.Header hasBottomSpacing="title-small">
        <Title size="medium">{HeaderText}</Title>
      </Modal.Header>
      <ModalBody>
        <ModalBodyText>{projectCreationText}</ModalBodyText>
        <ModalButtons>
          <CreateButton>
            <Button onClick={() => requestClose(true)} size="standard" variant="opacity">
              {cancelButtonText}
            </Button>
          </CreateButton>
          <CreateButton>
            <Button onClick={() => requestClose(false)} size="standard" variant="primary">
              {continueButtonText}
            </Button>
          </CreateButton>
        </ModalButtons>
      </ModalBody>
    </Modal>
  );
};
