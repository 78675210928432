import {Select} from '~/components/form/text_fields';
import {useWatermarkSettingsText} from '~/components/share_modal/share_modal_text';

const WatermarkThemes = ['light', 'dark'];
export type WatermarkThemeType = (typeof WatermarkThemes)[number];

export const ThemeSelector = () => {
  const watermarkSettingsText = useWatermarkSettingsText();
  return (
    <Select id="theme" name="theme">
      <Select.Option value="light">{watermarkSettingsText.lightTheme}</Select.Option>
      <Select.Option value="dark">{watermarkSettingsText.darkTheme}</Select.Option>
    </Select>
  );
};
