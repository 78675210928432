import React from 'react';

import {useIntl} from 'react-intl';

import {Text} from '@dropbox/dig-components/typography';

export const useSendEmailTypeaheadStrings = () => {
  const intl = useIntl();

  return {
    emailPlaceholderText: intl.formatMessage({
      defaultMessage: 'Send link to email addresses',
      id: 'g3O+hV',
      description:
        'Placeholder text for the field used to enter email addresses in the share modal.',
    }),

    shareLinkHelpText: intl.formatMessage({
      defaultMessage:
        'People who receive this link donʼt need a Dropbox account to view or comment.',
      id: 'Qe1gdU',
      description:
        'Text informing users that Dropbox accounts are not needed to access a share link.',
    }),

    teamProjectShareLinkHelpText: intl.formatMessage({
      defaultMessage: 'To give edit permissions to this project, add them to the project instead.',
      id: 'aYnhU4',
      description:
        'Text in modal for creating share links telling users to give edit permissions in a different way, by adding them directly to the project, if that is what they want to do.',
    }),

    emailAriaLabelText: intl.formatMessage({
      defaultMessage: 'Add email addresses',
      id: '8S1v+7',
      description: 'Aria label text for a field for entering email addresses in the share modal.',
    }),

    emailFailedText: intl.formatMessage({
      defaultMessage: 'Email failed to send. Try again, or copy the link instead.',
      id: 'jlov7S',
      description: 'Error text when an email sharing a file fails to send.',
    }),
    linkDisabledTooltipText: intl.formatMessage({
      defaultMessage: "You've disabled this link. Go to settings to re-enable.",
      id: 'JE1IiB',
      description:
        'Tooltip text indicating to a user that they cannot copy a link until it is re-enabled',
    }),
    sendTooltipText: intl.formatMessage({
      defaultMessage: 'Send link',
      id: 'GIFDBR',
      description: 'Tooltip text for the button to send share links via email.',
    }),
    disabledTooltipText: intl.formatMessage({
      defaultMessage: 'Add email addresses to send',
      id: 'WDTswY',
      description: 'Tooltip text when the button to send share links is disabled',
    }),
  };
};

export const useShareModalStrings = (name: string) => {
  const intl = useIntl();

  return {
    shareModalTitle: intl.formatMessage(
      {
        defaultMessage: 'Share "{name}"',
        id: 'e7QKKS',
        description: 'Title for modal containing the share link settings.',
      },
      {
        name: name,
      },
    ),

    linkDisabledTooltipText: intl.formatMessage({
      defaultMessage: "You've disabled this link. Go to settings to re-enable.",
      id: 'JE1IiB',
      description:
        'Tooltip text indicating to a user that they cannot copy a link until it is re-enabled',
    }),

    sharePermissionDescription: intl.formatMessage({
      defaultMessage: 'People with this link can',
      id: 'fcT3Mp',
      description: 'Text preceding a dropdown setting for the share link permissions.',
    }),

    passwordRequiredPermissionDescription: intl.formatMessage({
      defaultMessage: 'People with the link and password can',
      id: 'ZlsnKm',
      description:
        'Text preceding a dropdown setting for the share link permissions when a password is required.',
    }),

    settingsText: intl.formatMessage({
      defaultMessage: 'Settings',
      id: 'lA8Dla',
      description: 'Text describing a link that opens a modal containing the share link settings.',
    }),

    settingsTooltipText: intl.formatMessage({
      defaultMessage: 'Add a password, or enable downloads',
      id: '+dNz5G',
      description: 'Tooltip text for the settings button.',
    }),

    versionSelectorText: intl.formatMessage({
      defaultMessage: 'People with this link can only view',
      id: 'NDQJHk',
      description:
        'Text preceding a dropdown setting for what file version people will be able to view when they receive the share link.',
    }),

    versionTooltipText: intl.formatMessage({
      defaultMessage: 'Each version has a unique view-only link.',
      id: 'QkwdjE',
      description: 'Tooltip text explaining how versions work for view-only share links.',
    }),

    copyLinkText: intl.formatMessage({
      defaultMessage: 'Copy link',
      id: 'r8VpVh',
      description: 'Button text to copy the share link for the video',
    }),

    viewOnlyLinkText: intl.formatMessage({
      defaultMessage: 'view only',
      id: 'DEgp2f',
      description:
        'Text for the share modal dropdown option for setting the share link permissions to view only.',
    }),

    reviewLinkText: intl.formatMessage({
      defaultMessage: 'view and comment',
      id: 'NfUOfC',
      description:
        'Text for the share modal dropdown option for setting the share link permissions to view and comment.',
    }),

    closeShareModalButtonLabel: intl.formatMessage({
      defaultMessage: 'Close share modal',
      id: 'huJK/6',
      description: 'Aria label for the button to close the share modal',
    }),
    originalTitle: intl.formatMessage({
      defaultMessage: 'Original',
      id: 'rHIOpT',
      description: 'Title for a tab displaying non-watermarked (ie original) share links.',
    }),
    watermarkTitle: intl.formatMessage({
      defaultMessage: 'Watermark',
      id: 'iWIAp7',
      description: 'Title for a tab displaying watermarked share links.',
    }),

    sharingIsRestrictedWarning: intl.formatMessage({
      defaultMessage: 'Sharing outside your team is restricted by the admin.',
      id: 'qw4enb',
      description:
        'Warning text for when sharing is restricted for a file/folder by a team policy set by the admin.',
    }),

    downloadsEnabled: intl.formatMessage(
      {
        defaultMessage: 'Downloads are <b>enabled</b>',
        id: 'EVIa5j',
        description: 'Text indicating that downloads are enabled for a share link.',
      },
      {
        b: (chunks: React.ReactNode) => <Text isBold>{chunks}</Text>,
      },
    ),
    downloadsDisabled: intl.formatMessage(
      {
        defaultMessage: 'Downloads are <b>disabled</b>',
        id: 'OyDF4m',
        description: 'Text indicating that downloads are disabled for a share link.',
      },
      {
        b: (chunks: React.ReactNode) => <Text isBold>{chunks}</Text>,
      },
    ),
    manageSettings: intl.formatMessage({
      defaultMessage: 'Manage settings',
      id: 'o6tnXa',
      description: 'Button text to manage the settings for a share link.',
    }),
  };
};

export const useShareSettingStrings = (name: string) => {
  const intl = useIntl();

  return {
    settingsTitle: intl.formatMessage(
      {
        defaultMessage: 'Settings for "{name}"',
        id: 'NGM7TV',
        description: 'Title for modal containing the share link settings.',
      },
      {
        name: name,
      },
    ),

    commentTabTitle: intl.formatMessage({
      defaultMessage: 'View and comment',
      id: 'JOFSgt',
      description: 'Tab title for the view and comment share link settings.',
    }),

    viewTabTitle: intl.formatMessage({
      defaultMessage: 'View-only',
      id: 'eX00U2',
      description: 'Tab title for the view-only share link settings.',
    }),

    passwordToggleTitle: intl.formatMessage({
      defaultMessage: 'Require password',
      id: 'TVI7cV',
      description: 'Title for password setting toggle.',
    }),

    emptyPasswordError: intl.formatMessage({
      defaultMessage: 'Password cannot be empty.',
      id: '0R2H66',
      description: 'Error text for when the password setting is empty.',
    }),

    enableDownloadsToggleTitle: intl.formatMessage({
      defaultMessage: 'Allow downloads',
      id: '+mCFU7',
      description: 'Title for the download setting toggle.',
    }),

    enableLinkTitle: intl.formatMessage({
      defaultMessage: 'Enable link access',
      id: 'QGeR41',
      description: 'Text label for a setting in a modal that enables or disables a share link',
    }),

    linkDisableTitle: intl.formatMessage({
      defaultMessage: 'Disable link',
      id: '1PQcHX',
      description: 'Title for link enable/disable toggle',
    }),

    requireDropboxLoginTitle: intl.formatMessage({
      defaultMessage: 'Require Dropbox login',
      id: 'aSjjfK',
      description:
        'Text label for a setting in the modal that enables viewing of links with/without logging in',
    }),

    shareLinkError: intl.formatMessage({
      defaultMessage: 'There was a problem saving the settings.',
      id: 'BH3XjU',
      description: 'Error text for when the share link settings fail to save.',
    }),
    hidePastCommentsTitle: intl.formatMessage({
      defaultMessage: 'Hide past comments',
      id: 'tzUBDo',
      description: 'Text for a setting that hides comments made before a certain date.',
    }),
  };
};

export const useWatermarkSettingsText = () => {
  const intl = useIntl();

  return {
    customizeTitle: intl.formatMessage({
      defaultMessage: 'Customize',
      id: 'CGw99W',
      description: 'Title for a tab where users can customize a watermark.',
    }),
    settingsTitle: intl.formatMessage({
      defaultMessage: 'Settings',
      id: 'TDQ/MP',
      description: 'Title for a tab where users can change the link settings for a wtaermark link.',
    }),
    displaySectionTitle: intl.formatMessage({
      defaultMessage: 'Display text',
      id: 'cYTLgD',
      description:
        'Title for a settings section where users set what information they would like displayed as text in the watermark.',
    }),
    emailText: intl.formatMessage({
      defaultMessage: 'Recipient email',
      id: '+q8jaG',
      description:
        'Text for the setting controlling whether recipient email is displayed in the watermark.',
    }),
    ipText: intl.formatMessage({
      defaultMessage: 'Viewer IP address',
      id: 'xWQCji',
      description:
        'Text for the setting controlling whether viewer IP (Internet Protocol) address is displayed in the watermark.',
    }),
    dateText: intl.formatMessage({
      defaultMessage: 'View date',
      id: 'gKLU1D',
      description:
        'Text for the setting controlling whether view date is displayed in the watermark.',
    }),
    timeText: intl.formatMessage({
      defaultMessage: 'View time',
      id: 'xAoFMu',
      description:
        'Text for the setting controlling whether view time is displayed in the watermark.',
    }),
    recipientsSectionTitle: intl.formatMessage({
      defaultMessage: 'Recipients',
      id: 'iUykHR',
      description:
        'Title for the form element where recipient names or email addresses are entered.',
    }),
    recipientPlaceholder: intl.formatMessage({
      defaultMessage: 'Name or email address',
      id: 'nY99R4',
      description: 'Placeholder text for the textbox where recipient emails are entered.',
    }),
    recipientAria: intl.formatMessage({
      defaultMessage: 'Add email addressk',
      id: '7F2BiS',
      description:
        'Aria label for email form element controlling which email address will be shown in the watermark.',
    }),
    customTextTitle: intl.formatMessage({
      defaultMessage: 'Custom text',
      id: 'NCgwxG',
      description: 'Title for the form element where custom text for the watermark can be entered.',
    }),
    customTextPlaceholder: intl.formatMessage({
      defaultMessage: 'Add text here',
      id: 'elcVbN',
      description: 'Placeholder text for the custom text watermark setting.',
    }),
    customTextDefault: intl.formatMessage({
      defaultMessage: 'DRAFT',
      id: 'fD0flK',
      description:
        'Default text for the watermark. Describes a draft version of a file that is not yet finalized.',
    }),
    positionSectionTitle: intl.formatMessage({
      defaultMessage: 'Position',
      id: 'ANvN//',
      description:
        'Title for the form element controlling the position of where the watermark is placed on the file.',
    }),
    centeredText: intl.formatMessage({
      defaultMessage: 'Centered',
      id: 'FYblTg',
      description: 'Text for a setting option describing a watermark that is centered on the file.',
    }),
    topText: intl.formatMessage({
      defaultMessage: 'Top',
      id: '8Vrrqv',
      description:
        'Text for a setting option describing a watermark that is placed at the top of a file.',
    }),
    bottomText: intl.formatMessage({
      defaultMessage: 'Bottom',
      id: 'OV77Eo',
      description:
        'Text for a setting option describing a watermark that is placed at the bottom of a file.',
    }),
    topLeftText: intl.formatMessage({
      defaultMessage: 'Top left',
      id: 'Y1EbbD',
      description:
        'Text for a setting option describing a watermark that is placed at the top left of a file.',
    }),
    topRightText: intl.formatMessage({
      defaultMessage: 'Top right',
      id: 'CnRAjy',
      description:
        'Text for a setting option describing a watermark that is placed at the top right of a file.',
    }),
    bottomLeftText: intl.formatMessage({
      defaultMessage: 'Bottom left',
      id: '9PBxMp',
      description:
        'Text for a setting option describing a watermark that is placed at the bottom left of a file.',
    }),
    bottomRightText: intl.formatMessage({
      defaultMessage: 'Bottom right',
      id: '0llD5K',
      description:
        'Text for a setting option describing a watermark that is placed at the bottom right of a file.',
    }),
    themeTitle: intl.formatMessage({
      defaultMessage: 'Theme',
      id: 'Y6nCGS',
      description: 'Text for the form element controlling the color theme of the watermark.',
    }),
    darkTheme: intl.formatMessage({
      defaultMessage: 'Dark',
      id: 'ZDPNey',
      description: 'Text describing a dark color theme.',
    }),
    lightTheme: intl.formatMessage({
      defaultMessage: 'Light',
      id: 'tOXpcA',
      description: 'Text describing a light color theme.',
    }),
    opacityTitle: intl.formatMessage({
      defaultMessage: 'Opacity',
      id: 'YfVh4A',
      description: 'Text for the form element controlling the opacity of the watermark.',
    }),
    opacityAria: intl.formatMessage({
      defaultMessage: 'Opacity value',
      id: '23RDpL',
      description: 'Aria label for the opacity slider',
    }),
    sizeTitle: intl.formatMessage({
      defaultMessage: 'Size',
      id: 'OiP1lR',
      description: 'Text for the form element controlling the size of the watermark.',
    }),
    sizeAria: intl.formatMessage({
      defaultMessage: 'Size value',
      id: 'F9ki4K',
      description: 'Aria label for the form element controlling the size of the watermark.',
    }),
    genericLinkTooltip: intl.formatMessage({
      defaultMessage: "Can't select for generic links.",
      id: '8+iBUK',
      description:
        'Tooltip text letting users know email tracking cannot be turned on for generic links.',
    }),
    trackingLinkTooltip: intl.formatMessage({
      defaultMessage: "Can't deselect for tracking links.",
      id: 'skum46',
      description:
        'Tooltip text letting users know email tracking cannot be turned off for tracking links.',
    }),
    emailError: intl.formatMessage({
      defaultMessage: 'Duplicate links cannot be created for the same email.',
      id: 'YkifHr',
      description: 'Error message indicating that a recipient already has a share link created.',
    }),
    removeDuplicateEmailError: intl.formatMessage({
      defaultMessage: 'Remove recipients with an existing link before saving.',
      id: 'Il3Gvy',
      description:
        'Tooltip message indicating that some recipients need to be removed before saving the link settings.',
    }),
  };
};
