import type {ChangeEventHandler} from 'react';
import {useCallback} from 'react';

import {useFormContext, useWatch} from 'react-hook-form';
import styled from 'styled-components';

import {Slider} from '@dropbox/dig-components/slider';
import {TextInput} from '@dropbox/dig-components/text_fields';

export const StyledSliderRow = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing__unit--1_5);

  & > div:first-child {
    flex: 2 1 auto;
  }

  & > span:last-child {
    display: flex;
    flex: 1 2 auto;
  }
`;

type SliderControlProps = {
  name: string;
  'aria-label': string;
};

export const SliderControl = ({name, 'aria-label': ariaLabel}: SliderControlProps) => {
  const {setValue, control} = useFormContext();
  const value = useWatch({control, name});

  const setControlValue = useCallback((value: number) => setValue(name, value), [name, setValue]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (evt) => {
      const val = evt.currentTarget.value;
      if ((0 <= Number(val) && Number(val) <= 100) || val === '') {
        setControlValue(Number(val));
      }
    },
    [setControlValue],
  );

  return (
    <StyledSliderRow>
      <Slider
        aria-label={ariaLabel}
        defaultValue={value}
        id={name}
        onChange={setControlValue}
        value={value}
      />
      <TextInput aria-label={ariaLabel} inputMode="numeric" onChange={handleChange} value={value} />
    </StyledSliderRow>
  );
};
