import type {Interpolation, RuleSet} from 'styled-components';
import {css} from 'styled-components';

import {vars} from '@dropbox/dig-foundations';

// Breakpoints lifted from `--dig-breakpoint__*`
// CSS variables can't be used in media queries, so we have to hardcode them here
const DIG_BREAKPOINTS = {
  small: 600,
  medium: 1024,
  large: 1600,
  xlarge: 1920,
};

type Breakpoint = keyof typeof DIG_BREAKPOINTS;

// Not exported to force usage of the breakpoint* functions below which
// format better with prettier
const breakpoint = <Props extends object>(
  width: Breakpoint,
  styles: Interpolation<Props>,
) => css<Props>`
  @media screen and (min-width: ${DIG_BREAKPOINTS[width]}px) {
    ${styles}
  }
`;

export const breakpointSmall = <Props extends object>(styles: Interpolation<Props>) =>
  breakpoint('small', styles);
export const breakpointMedium = <Props extends object>(styles: Interpolation<Props>) =>
  breakpoint('medium', styles);
export const breakpointLarge = <Props extends object>(styles: Interpolation<Props>) =>
  breakpoint('large', styles);
export const breakpointXLarge = <Props extends object>(styles: Interpolation<Props>) =>
  breakpoint('xlarge', styles);

export type Spacing = keyof typeof vars.Spacing;
export const spacing = (size: Spacing) => `${vars.Spacing[size]}`;

type Radius = keyof typeof vars.Radius;
export const radius = (radius: Radius) => `${vars.Radius[radius]}`;

export type Color = keyof typeof vars.Color;
export const color = (color: Color) => `${vars.Color[color]}`;

type FontSize = keyof typeof vars.Type.Size;
export const fontSize = (size: FontSize) => `${vars.Type.Size[size]}`;

type FontFamily = keyof typeof vars.Type.Family;
export const fontFamily = (family: FontFamily) => `${vars.Type.Family[family]}`;

type FontWeight = keyof typeof vars.Type.Weight;
export const fontWeight = (weight: FontWeight) => `${vars.Type.Weight[weight]}`;

type LineHeight = keyof typeof vars.Type.Lineheight;
export const lineHeight = (height: LineHeight) => `${vars.Type.Lineheight[height]}`;

type MotionDuration = keyof typeof vars.Motion.Duration;
export const motionDuration = (duration: MotionDuration) => `${vars.Motion.Duration[duration]}`;

type Easing = keyof typeof vars.Motion.Easing;
export const easing = (easing: Easing) => `${vars.Motion.Easing[easing]}`;

export const colorTransition = (...properties: string[]): string =>
  properties
    .map((property) => `${property} ${motionDuration('Fast')} ${easing('Linear')}`)
    .join(', ');

export const ifNotProp = <Props extends Object, S1 extends Interpolation<Props>>(
  prop: keyof Props,
  styles: S1,
): RuleSet<Props> =>
  css<Props>`
    ${(props: Props) => !props[prop] && styles}
  `;

export const ifProp = <
  Props extends object,
  S1 extends Interpolation<Props>,
  S2 extends Interpolation<Props>,
>(
  prop: keyof Props,
  styles: S1,
  elseStyles?: S2,
): RuleSet<Props> /* explicitly set return type to workaround styled-components NoInfer */ =>
  css<Props>`
    ${(props: Props) => (Boolean(props[prop]) ? styles : elseStyles)}
  `;
