import React from 'react';

// eslint-disable-next-line no-restricted-imports -- This is the only place we should be importing growthbook-react
import {
  GrowthBookProvider as _GrowthBookProvider,
  useFeatureIsOn as _useFeatureIsOn,
  useFeatureValue as _useFeatureValue,
  useGrowthBook as _useGrowthBook,
  GrowthBook,
  type InitResponse,
} from '@growthbook/growthbook-react';
import * as Sentry from '@sentry/react';

import {useReelAppGlobalState} from '~/context';

import {ReplayError, ReplayErrorCategory, reportException} from './error_reporting';
import type {LoggingClient} from './logging/logger';
import {useLoggingClient} from './use_logging_client';

export const growthbook = new GrowthBook<ReelFeatures>({
  apiHost: 'https://cdn.dropboxexperiment.com',
  clientKey: 'sdk-CER3PbqP0ZQNOj3',
  enableDevMode: import.meta.env.DEV,
  onFeatureUsage(key, result) {
    Sentry.setTag(`growthbook.${key}`, result.value);
  },
});

let gbInitPromise: Promise<void | InitResponse> | undefined;

/**
 * Define the features and their types here
 */
type ReelFeatures = {
  mp_enablement_2024_05_21_replay_checkout_v1: string;
  replay_2024_07_16_standalone_invite: boolean;
  replay_2024_08_08_standalone_team_creation: boolean;
  replay_2024_09_24_streaming_uploader: boolean;
  replay_2024_10_08_live_review_improvs: boolean;
};

export const initGrowthbook = async (): Promise<void | InitResponse> => {
  if (!gbInitPromise) {
    gbInitPromise = growthbook.init({timeout: 1000}).catch((err) => {
      reportException(
        new ReplayError({
          severity: 'critical',
          category: ReplayErrorCategory.GrowthBookInitError,
          error: err,
        }),
      );
    });
  }
  return gbInitPromise;
};

export const initAndConfigureGrowthbook = async ({
  logger,
  userId,
  userPid,
  email,
}: {
  logger: LoggingClient;
  userPid?: string;
  userId?: number;
  email?: string;
}) => {
  await initGrowthbook();

  await growthbook.setAttributes({
    user_id: userId,
    public_user_id: userPid,
    dbx51_email_regex: email,
  });

  growthbook.setTrackingCallback((experiment, variant) => {
    logger.logStormcrowExposure({
      feature: experiment.key,
      experimentVariant: variant.key,
    });
  });
};

export const GrowthBookProvider: React.FC<React.PropsWithChildren> = ({children}) => {
  const sessionState = useReelAppGlobalState();
  const currentAccount = sessionState.status === 'logged in' ? sessionState.currentAccount : null;
  const guestUserInfo = sessionState.status === 'logged out' ? sessionState.guestUserInfo : null;
  const logger = useLoggingClient();

  React.useEffect(() => {
    initAndConfigureGrowthbook({
      userPid: currentAccount?.user_pid_eci,
      userId: currentAccount?.id,
      email: currentAccount?.email ?? guestUserInfo?.email ?? '',
      logger,
    });
  }, [logger, currentAccount, guestUserInfo]);

  return <_GrowthBookProvider growthbook={growthbook}>{children}</_GrowthBookProvider>;
};

/**
 * Hook to get the type-safe GrowthBook instance
 * @returns {GrowthBook<ReelFeatures>}
 */
export const useGrowthBook = (): GrowthBook<ReelFeatures> => _useGrowthBook<ReelFeatures>();

/**
 * Hook to check if a feature is on
 * @param {string & keyof ReelFeatures} id
 * @returns {boolean}
 */
export const useFeatureIsOn = (id: string & keyof ReelFeatures) =>
  _useFeatureIsOn<ReelFeatures>(id);

/**
 * Hook to check a feature value
 * @param {string & keyof ReelFeatures} id
 * @param fallback
 * @returns
 */
export const useFeatureValue = <V extends ReelFeatures[K], K extends string & keyof ReelFeatures>(
  id: K,
  fallback: V,
) => _useFeatureValue<V>(id, fallback);
