import type React from 'react';
import {useCallback, useEffect, useMemo, useRef} from 'react';

import {AccountLinkState, useLinkState} from './link_flow_state';
import {useReelAppGlobalState} from '../../context';
import {DBX_CLIENT_DOMAIN} from '../../context_utils';
import {getLinkedGoogleToken} from '../api';
import {ReplayError, ReplayErrorCategory, reportException} from '../error_reporting';
import type {LoggingClient} from '../logging/logger';

export const METASERVER_ORIGIN =
  DBX_CLIENT_DOMAIN && DBX_CLIENT_DOMAIN.includes('dev.corp')
    ? `https://meta-${DBX_CLIENT_DOMAIN}`
    : `https://www.dropbox.com`;
const PROFILE_SERVICES_ROUTE = '/profile_services/start_auth_flow';

const getAuthFlowUrl = (userId: number) => {
  const params = new URLSearchParams({
    service: 'google',
    action: 'link_read_files',
    user_id: userId.toString(10),
    is_popup: 'true',
    show_splash: 'true',
  });
  const url = new URL(PROFILE_SERVICES_ROUTE, METASERVER_ORIGIN);
  url.search = params.toString();
  return url.toString();
};

const getAuthFlowFeatures = () => {
  return 'width=600,height=600';
};

interface GDriveTokenResponse {
  type: 'db:profile_service:auth_complete';
  payload: {
    success: boolean;
    access_token?: string;
  };
}

const validateGDriveTokenResponse = (response: unknown): response is GDriveTokenResponse => {
  if (
    response != null &&
    typeof response === 'object' &&
    response.hasOwnProperty('type') &&
    response.hasOwnProperty('payload')
  ) {
    const {type, payload} = response as unknown as {type: unknown; payload: unknown};
    if (type === 'db:profile_service:auth_complete' && typeof payload === 'object') {
      const {success} = payload as unknown as {success: boolean};
      return typeof success === 'boolean';
    }
  }
  return false;
};

type TokenResultHandler = (token?: string) => void;
type WindowRef = React.MutableRefObject<Window | null>;

export const messageHandler = (
  message: MessageEvent<string | Record<string, string>>,
  tokenResultHandler: TokenResultHandler,
): void => {
  if (message.origin !== METASERVER_ORIGIN) {
    return;
  }
  let response;
  try {
    response = typeof message.data === 'string' ? JSON.parse(message.data) : message.data;
  } catch (e) {
    reportException(
      new ReplayError({
        error: e,
        severity: 'non-critical',
        tags: ['gdrive-messageHandler'],
        category: ReplayErrorCategory.BadDataError,
      }),
    );
    return;
  }
  if (validateGDriveTokenResponse(response)) {
    const {
      payload: {success, access_token},
    } = response;
    tokenResultHandler(success && access_token ? access_token : undefined);
  }
};

export const openOauthPopupWindow = (userId: number | undefined): Window | null => {
  if (userId) {
    const url = getAuthFlowUrl(userId);
    const features = getAuthFlowFeatures();
    return window.open(url, 'OAUTH_POPUP', features);
  }
  return null;
};

export const usePopup = (openWindow: () => Window | null, onPopupManuallyClosed: () => void) => {
  const popupWindow: WindowRef = useRef<Window | null>(null);

  const close = useCallback(() => {
    popupWindow.current?.close();
    popupWindow.current = null;
  }, []);

  const open = useCallback(() => {
    close();
    popupWindow.current = openWindow();
    return popupWindow.current != null;
  }, [openWindow, close]);

  const isOpen = useCallback(() => popupWindow.current != null, []);

  // Checks every second to see if the window has been closed.
  const checkPopupClosed = useCallback(() => {
    if (popupWindow.current?.closed) {
      close();
      onPopupManuallyClosed();
    }
  }, [close, onPopupManuallyClosed]);
  useEffect(() => {
    const interval = setInterval(checkPopupClosed, 1000);
    return () => clearInterval(interval);
  }, [checkPopupClosed]);

  return useMemo(() => ({open, close, isOpen}), [open, close, isOpen]);
};

const useFetchToken = (tokenResultHandler: TokenResultHandler) => {
  const cancelRef = useRef<(() => void) | null>(null);
  const cancelFetchToken = useCallback(() => {
    if (cancelRef.current) {
      cancelRef.current();
      cancelRef.current = null;
    }
  }, []);

  const fetchToken = useCallback(async () => {
    cancelFetchToken();
    let isCancelled = false;
    cancelRef.current = () => {
      isCancelled = true;
    };

    const token = await getLinkedGoogleToken();
    if (isCancelled) {
      return;
    }
    tokenResultHandler(typeof token === 'string' && token.length > 0 ? token : undefined);
  }, [tokenResultHandler, cancelFetchToken]);

  return {fetchToken, cancelFetchToken} as const;
};

export const useLinkFlow = ({
  isOpen,
  setGdriveToken,
  loggingClient,
}: {
  isOpen: boolean;
  setGdriveToken: (token: string) => void;
  loggingClient: LoggingClient;
}) => {
  const globalState = useReelAppGlobalState();
  const userId = globalState.status === 'logged in' ? globalState.currentAccount.id : undefined;

  const {state, token, dispatch} = useLinkState(loggingClient, reportException);

  const onMessageResult = useCallback(
    (token?: string) => {
      if (token) {
        dispatch({action: 'TOKEN_FOUND', payload: token});
      } else {
        dispatch({action: 'POPUP_CLOSED'});
      }
    },
    [dispatch],
  );
  const onTokenCheckResult = useCallback(
    (token?: string) => {
      if (token) {
        dispatch({action: 'TOKEN_FOUND', payload: token});
      } else {
        dispatch({action: 'TOKEN_NOT_FOUND'});
      }
    },
    [dispatch],
  );

  const {fetchToken, cancelFetchToken} = useFetchToken(onTokenCheckResult);

  // Adds a listener for postMessages to the main window from the auth popup
  const onMessage = useCallback(
    (message: MessageEvent<string>) => messageHandler(message, onMessageResult),
    [onMessageResult],
  );
  useEffect(() => {
    window.addEventListener('message', onMessage);
    return () => window.removeEventListener('message', onMessage);
  }, [onMessage]);

  // Wrapper around controls that let us open and close popups
  const openWindow = useCallback(() => openOauthPopupWindow(userId), [userId]);
  const onPopupManuallyClosed = useCallback(() => dispatch({action: 'POPUP_CLOSED'}), [dispatch]);
  const popup = usePopup(openWindow, onPopupManuallyClosed);

  const openPopup = useCallback(() => {
    const popupOpenSuccess = popup.open();
    dispatch({
      action: popupOpenSuccess ? 'POPUP_OPENED' : 'POPUP_OPEN_FAILED',
    });
  }, [popup, dispatch]);

  // When the auth window is closed, make a request for the token if it is not present
  useEffect(() => {
    if (token) {
      setGdriveToken(token);
    }
  }, [token, setGdriveToken]);

  // Effect that triggers a token check when necessary, cancelling it if
  // the state changes in the meantime.
  useEffect(() => {
    if (state === AccountLinkState.TOKEN_CHECK) {
      fetchToken();
      return cancelFetchToken;
    }
    return () => {};
  }, [state, fetchToken, cancelFetchToken]);

  // Effect for reconciling modal open state and popup open state
  useEffect(() => {
    if (isOpen && state === AccountLinkState.CLOSED) {
      // When popup opens / state is right, open the popup window
      openPopup();
    } else if (!isOpen && state !== AccountLinkState.CLOSED) {
      // If modal is ever closed, close child window and update state
      popup.close();
      dispatch({action: 'MODAL_CLOSED'});
    } else if (popup.isOpen() && state !== AccountLinkState.POPUP_ACTIVE) {
      // If auth window is still open when we don't expect it to be, close it.
      popup.close();
    }
  }, [isOpen, popup, openPopup, state, dispatch]);

  return {
    state,
    openPopup,
  };
};
