import React from 'react';

import {matchPath, useLocation} from 'react-router';

import {TUTORIALS_ROUTE} from './context_utils';

export type VideoPageRouteParams = {
  videoId: string;
  projectId: string;
};

export const BROWSE_ROUTE_MATCH = '/';

export const BROWSE_FOLDER_ROUTE_MATCH = '/folder/:folderId';

export const OAUTH_ROUTE_MATCH = '/oauth';

export const BROWSE_SHARED_ROUTE_MATCH = '/shared';

export const VIDEO_ROUTE_MATCH = '/project/:projectId/video/:videoId';

export type VersionComparePageRouteParams = {
  projectId: string;
  videoId: string;
};

export type VersionComparePageRouteQueryParams = {
  l: string;
  r: string;
};

export const VERSION_COMPARE_ROUTE_MATCH = '/compare/project/:projectId/video/:videoId';

export const VERSION_COMPARE_ROUTE_SHARE_MATCH =
  '/compare/project/:projectId/video/:videoId/share/:shareToken';

export type SharePageRouteParams = {
  shareToken: string;
  videoId?: string;
};

export const ADMIN_ROUTE_MATCH = '/admin';

export const ADMIN_ROUTE_USER_MATCH = '/admin/user/:userId';

export const SETTINGS_ROUTE_MATCH = '/settings';

export const BUY_ROUTE_MATCH = '/buy';

export type AdminPageRouteParams = {
  userId?: string;
};

export const SHARE_ROUTE_MATCH = '/share/:shareToken';

export const SHARE_FOLDER_VIDEO_ROUTE_MATCH = '/share-folder/:shareToken/video/:videoId';

export type SharedFolderLinkRouteParams = {
  folderId?: string;
  shareToken: string;
};

export const SHARE_FOLDER_ROUTE_MATCH = '/share-folder/:shareToken';

export const SHARE_FOLDER_SUBFOLDER_ROUTE_MATCH = '/share-folder/:shareToken/folder/:folderId';

// TIPS + TRICKS
export const TUTORIALS_ROUTE_MATCH = `/share-folder/${TUTORIALS_ROUTE}`;

export const CREATE_ONBOARDING_V2_VIDEO_ROUTE_MATCH = '/project/onboarding';

export const CREATING_PROJECT_FROM_FILE_ROUTE_MATCH = '/project/creating/:fileId';

export const REDIRECT_TO_LOGIN_ROUTES = [CREATING_PROJECT_FROM_FILE_ROUTE_MATCH];

export const SHARE_TOKEN_ROUTES = [
  // All of these routes have a :shareToken param
  SHARE_ROUTE_MATCH,
  SHARE_FOLDER_VIDEO_ROUTE_MATCH,
  SHARE_FOLDER_ROUTE_MATCH,
  SHARE_FOLDER_SUBFOLDER_ROUTE_MATCH,
  VERSION_COMPARE_ROUTE_SHARE_MATCH,
];

const VIDEO_VIEWER_ROUTES = [
  VIDEO_ROUTE_MATCH,
  SHARE_FOLDER_VIDEO_ROUTE_MATCH,
  SHARE_ROUTE_MATCH,
  VERSION_COMPARE_ROUTE_SHARE_MATCH,
  VERSION_COMPARE_ROUTE_MATCH,
] as const;

export const getVideoViewerRouteTemplate = (
  pathname: string,
): null | (typeof VIDEO_VIEWER_ROUTES)[number] => {
  for (let i = 0; i < VIDEO_VIEWER_ROUTES.length; i++) {
    const matcher = VIDEO_VIEWER_ROUTES[i];
    const matchedPath = matchPath({path: matcher}, pathname);
    if (matchedPath) {
      return matcher;
    }
  }
  return null;
};

export const useIsCurrentPageSharedBrowsePage = () => {
  const {pathname} = useLocation();

  const matchedPath = React.useMemo(
    () => matchPath({path: BROWSE_SHARED_ROUTE_MATCH}, pathname),
    [pathname],
  );

  return Boolean(matchedPath);
};

export const useIsCurrentPageShareFolderPage = () => {
  const {pathname} = useLocation();

  const matchedPath = React.useMemo(
    () =>
      [SHARE_FOLDER_ROUTE_MATCH, SHARE_FOLDER_SUBFOLDER_ROUTE_MATCH].some((path) =>
        matchPath({path}, pathname),
      ),
    [pathname],
  );

  return Boolean(matchedPath);
};

export const useIsCurrentPageVideoInSharedFolder = () => {
  const {pathname} = useLocation();

  const matchedPath = React.useMemo(
    () =>
      matchPath(
        {
          path: SHARE_FOLDER_VIDEO_ROUTE_MATCH,
        },
        pathname,
      ),
    [pathname],
  );

  return Boolean(matchedPath);
};

export const useIsCurrentPageSharePage = () => {
  const {pathname} = useLocation();

  const isShareFolderPage = useIsCurrentPageShareFolderPage();

  const isShareVideoPage = React.useMemo(
    () =>
      [SHARE_ROUTE_MATCH, SHARE_FOLDER_VIDEO_ROUTE_MATCH].some((path) =>
        matchPath({path}, pathname),
      ),
    [pathname],
  );

  return isShareFolderPage || !!isShareVideoPage;
};

export const useIsCurrentPagePartOfAdminConsole = () => {
  const {pathname} = useLocation();

  const isAdminPageRoute = React.useMemo(
    () => [ADMIN_ROUTE_MATCH, ADMIN_ROUTE_USER_MATCH].some((path) => matchPath({path}, pathname)),
    [pathname],
  );

  return isAdminPageRoute;
};

// This hook simply determines if a route should redirect to login automatically
// but doesn't do the check if a user is logged in. It just does the route matching.
// This should be used in conjunction with a check for the logged in status
export const useShouldCurrentRouteRedirectToLogin = () => {
  const {pathname} = useLocation();

  const matchedPath = React.useMemo(
    () => REDIRECT_TO_LOGIN_ROUTES.some((path) => matchPath({path}, pathname)),
    [pathname],
  );

  return Boolean(matchedPath);
};
