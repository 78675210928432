import {useIntl} from 'react-intl';

export const useGetDocumentUploadSizeExceededErrorText = () => {
  const intl = useIntl();
  return intl.formatMessage({
    defaultMessage: 'PDF file must be less than 40MB.',
    id: '2Ts7RU',
    description:
      'Text on upload snackbar that lets users know PDF file uploads have a file size limit of 40MB',
  });
};
