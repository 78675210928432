import {Suspense} from 'react';

import type {BrandingLogoVariant} from '~/lib/branding';
import {lazy} from '~/lib/lazy';

const LogoIcon = lazy(() =>
  import(/* webpackChunkName: 'branding_logos' */ './logos').then((module) => ({
    default: module.LogoIcon,
  })),
);

export interface BrandingLogoIconProps {
  variant: BrandingLogoVariant;
}

export const BrandingLogoIcon = (props: BrandingLogoIconProps) => {
  return (
    <Suspense fallback={null}>
      <LogoIcon {...props} />
    </Suspense>
  );
};
