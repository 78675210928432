import {useEffect, useReducer} from 'react';

import type {reportException} from '../error_reporting';
import {ReplayError, ReplayErrorCategory} from '../error_reporting';
import {enforceExhaustive} from '../helpers';
import type {LoggingClient} from '../logging/logger';

export const enum AccountLinkState {
  CLOSED = 'CLOSED',
  POPUP_ACTIVE = 'POPUP_ACTIVE',
  POPUP_INACTIVE = 'POPUP_INACTIVE',
  TOKEN_CHECK = 'TOKEN_CHECK',
  DONE = 'DONE',
}
type _AccountLinkAction<Action, Payload = void> = Payload extends string
  ? {action: Action; payload: Payload}
  : {action: Action};

export type AccountLinkAction =
  | _AccountLinkAction<'MODAL_CLOSED'>
  | _AccountLinkAction<'POPUP_OPENED'>
  | _AccountLinkAction<'POPUP_OPEN_FAILED'>
  | _AccountLinkAction<'POPUP_CLOSED'>
  | _AccountLinkAction<'TOKEN_FOUND', string>
  | _AccountLinkAction<'TOKEN_NOT_FOUND'>;

const STATE_TRANSITIONS: Record<AccountLinkState, string[]> = {
  [AccountLinkState.CLOSED]: [AccountLinkState.POPUP_ACTIVE, AccountLinkState.POPUP_INACTIVE],
  [AccountLinkState.POPUP_INACTIVE]: [AccountLinkState.CLOSED, AccountLinkState.POPUP_ACTIVE],
  [AccountLinkState.POPUP_ACTIVE]: [
    AccountLinkState.CLOSED,
    AccountLinkState.TOKEN_CHECK,
    AccountLinkState.DONE,
  ],
  [AccountLinkState.TOKEN_CHECK]: [
    AccountLinkState.CLOSED,
    AccountLinkState.DONE,
    AccountLinkState.POPUP_INACTIVE,
  ],
  [AccountLinkState.DONE]: [AccountLinkState.CLOSED],
};

export type ExceptionReporter = typeof reportException;

export const reportInvalidStateTransition = (
  reportException: ExceptionReporter,
  previousState: AccountLinkState,
  currentState: AccountLinkState,
) => {
  if (previousState === currentState) {
    return;
  }
  let err: ReplayError | undefined;
  if (!STATE_TRANSITIONS[previousState]) {
    err = new ReplayError({
      severity: 'critical',
      category: ReplayErrorCategory.GDriveImports,
      message: `Invalid Previous State ${previousState}`,
    });
  } else if (!STATE_TRANSITIONS[previousState].includes(currentState)) {
    err = new ReplayError({
      severity: 'non-critical',
      category: ReplayErrorCategory.GDriveImports,
      message: `Invalid State Transition ${previousState} --> ${currentState}`,
    });
  }
  if (err) {
    reportException(err);
  }
};

export const logEvent = (
  loggingClient: LoggingClient,
  previousState: AccountLinkState,
  currentState: AccountLinkState,
) => {
  if (previousState === currentState) {
    return;
  }

  if (currentState === AccountLinkState.POPUP_ACTIVE) {
    // Case 1: The popup has been opened
    // eslint-disable-next-line deprecation/deprecation
    loggingClient.logEvent('gdrive_account_link_popup_opened');
  }

  if (previousState === AccountLinkState.POPUP_ACTIVE) {
    // Case 2: The popup has been closed in some way
    // eslint-disable-next-line deprecation/deprecation
    loggingClient.logEvent('gdrive_account_link_popup_closed');
  }

  if (currentState === AccountLinkState.DONE) {
    // Case 3: We have successfully obtained a token
    // eslint-disable-next-line deprecation/deprecation
    loggingClient.logEvent('gdrive_account_link_success');
  }

  if (currentState === AccountLinkState.POPUP_INACTIVE) {
    if (previousState !== AccountLinkState.CLOSED) {
      // Case 4: We did a token check, and we still don't have a token :(
      // eslint-disable-next-line deprecation/deprecation
      loggingClient.logEvent('gdrive_account_link_failure');
    } else {
      // Case 5: Our popup was blocked!
      // eslint-disable-next-line deprecation/deprecation
      loggingClient.logEvent('gdrive_account_link_popup_blocked');
    }
  }
};

export const useLinkState = (loggingClient: LoggingClient, reportException: ExceptionReporter) => {
  const [{state, previous, token}, dispatch] = useReducer(
    (
      oldState: {
        state: AccountLinkState;
        previous: AccountLinkState;
        token?: string;
      },
      action: AccountLinkAction,
    ) => {
      const previous = oldState.state;
      let state: AccountLinkState;
      let token = oldState.token;
      switch (action.action) {
        case 'TOKEN_FOUND':
          state = AccountLinkState.DONE;
          token = action.payload;
          break;
        case 'MODAL_CLOSED':
          state = AccountLinkState.CLOSED;
          break;
        case 'POPUP_OPENED':
          state = AccountLinkState.POPUP_ACTIVE;
          break;
        case 'POPUP_OPEN_FAILED':
        case 'TOKEN_NOT_FOUND':
          state = AccountLinkState.POPUP_INACTIVE;
          break;
        case 'POPUP_CLOSED':
          state = AccountLinkState.TOKEN_CHECK;
          break;
        default:
          enforceExhaustive(action);
      }

      if (state === previous) {
        return oldState;
      }
      return {state, token, previous};
    },
    {state: AccountLinkState.CLOSED, previous: AccountLinkState.CLOSED},
  );

  useEffect(() => {
    logEvent(loggingClient, previous, state);
    reportInvalidStateTransition(reportException, previous, state);
  }, [loggingClient, reportException, state, previous]);

  return {state, token, dispatch} as const;
};
