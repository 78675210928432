import React from 'react';

import {useAtomValue} from 'jotai';

import type {ShareFolderAccessType} from '~/lib/api';
import {useGetPapAccessType} from '~/lib/use_get_pap_access_type';
import {DownloadFolderButtonAndModal} from '~/pages/browse_page/components/download/download_folder_button_and_modal';
import {instanceOfFolder} from '~/pages/browse_page/types';
import {foldersAtom, selectedFolderIdsAtom} from '~/state/browse';

export const BulkDownloadFolderButtonAndModal = () => {
  const selectedFolderIds = useAtomValue(selectedFolderIdsAtom);
  const folders = useAtomValue(foldersAtom);
  const selectedFolderId = selectedFolderIds[0]; // We only support single folder download right now
  const accessLevel: ShareFolderAccessType | null = React.useMemo(() => {
    const selectedFolder = folders?.find(
      (folder) => instanceOfFolder(folder) && folder.id === selectedFolderId,
    );
    if (!instanceOfFolder(selectedFolder) || selectedFolder.access_level === undefined) {
      return null;
    }
    return selectedFolder.access_level['.tag'];
  }, [folders, selectedFolderId]);
  const papAccessType = useGetPapAccessType({
    accessLevel: accessLevel,
  });

  if (!selectedFolderId) {
    return null;
  }
  return (
    <DownloadFolderButtonAndModal
      analyticsData={{
        actionElement: 'action_bar',
        folderLevel: 1,
        accessType: papAccessType,
      }}
      buttonVariant="opacity"
      displayType="expanded"
      downloadOptions={[
        {'.tag': 'latest_files'},
        {'.tag': 'approved_files'},
        {'.tag': 'all_files'},
      ]}
      folderId={selectedFolderId}
    />
  );
};
