import React from 'react';

import {useIntl} from 'react-intl';

import {ClickOutside} from '@dropbox/dig-components/click_outside';
import type {OverlayPlacement} from '@dropbox/dig-components/overlay';
import {Tooltip} from '@dropbox/dig-components/tooltips';

type PremiumTooltipProps = {
  triggerRef: React.RefObject<HTMLDivElement>;
  open: boolean;
  placement: OverlayPlacement;
  onClose: () => void;
  hideClickOutside?: boolean;
};

export const PremiumTooltip = ({
  triggerRef,
  open,
  onClose,
  placement,
  hideClickOutside = false,
}: PremiumTooltipProps) => {
  const intl = useIntl();
  const content = (
    <Tooltip.Control
      auto={true}
      maxWidth={275}
      open={open}
      openDelay={500}
      placement={placement}
      triggerRef={triggerRef}
    >
      {intl.formatMessage({
        defaultMessage: 'Dropbox Replay paid feature',
        id: 'yq3/Pd',
        description:
          'Text for a tooltip that appears when a user tries to use a premium feature that can be unlocked with a paid version of Replay',
      })}
    </Tooltip.Control>
  );

  return (
    <>
      {hideClickOutside ? (
        content
      ) : (
        <ClickOutside
          onClickOutside={() => {
            onClose();
          }}
          shouldPropagateMouseEvents
        >
          {content}
        </ClickOutside>
      )}
    </>
  );
};
