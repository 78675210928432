import React from 'react';

import {useAtomValue} from 'jotai';
import {useFormContext, useWatch} from 'react-hook-form';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';

import {FormLabel, FormRow} from '@dropbox/dig-components/form_row';
import {TextInput} from '@dropbox/dig-components/text_fields';
import {Tooltip} from '@dropbox/dig-components/tooltips';
import {Text} from '@dropbox/dig-components/typography';
import {UIIcon} from '@dropbox/dig-icons';
import {WarningLine} from '@dropbox/dig-icons/assets';

import {DropboxAccountTypeahead} from '~/components/dropbox_account_typeahead';
import {ToggleChip} from '~/components/form/chip';
import {PositionSelector} from '~/components/share_modal/position_selector';
import {useWatermarkSettingsText} from '~/components/share_modal/share_modal_text';
import {getShareItemAtom} from '~/components/share_modal/share_state';
import {SliderControl} from '~/components/share_modal/slider_control';
import {ThemeSelector} from '~/components/share_modal/theme_selector';
import {WatermarkPreview} from '~/components/share_modal/watermark_preview';
import type {WatermarkFormValues} from '~/components/share_modal/watermark_types';
import type {UserContactInfo, WatermarkLinkInfo} from '~/lib/api';

const StyledFormRow = styled.div`
  display: flex;
  & > div:first-child {
    gap: var(--spacing__unit--1_5);
    display: flex;
    flex-wrap: wrap;
  }
`;

type CustomizeWatermarkTabProps = {
  currentLinkInfo?: WatermarkLinkInfo;
  hasDuplicateEmail: boolean;
  setHasDuplicateEmail: (hasDuplicateEmail: boolean) => void;
  recipients: UserContactInfo[];
  setRecipients: (recipients: UserContactInfo[]) => void;
  existingWatermarkUsers: UserContactInfo[];
};

export const CustomizeWatermarkTab = (props: CustomizeWatermarkTabProps) => {
  const {
    currentLinkInfo,
    recipients,
    setRecipients,
    hasDuplicateEmail,
    setHasDuplicateEmail,
    existingWatermarkUsers,
  } = props;

  const {register, control} = useFormContext<WatermarkFormValues>();
  const watermarkSettingsText = useWatermarkSettingsText();
  const isEditing = !!currentLinkInfo;
  const [showEmailChipTooltip, setShowEmailChipTooltip] = React.useState<boolean>(false);
  const showEmail = useWatch({control, name: 'showEmail'});
  const [videoWidth, setVideoWidth] = React.useState<number>(0);
  const shareItemInfo = useAtomValue(getShareItemAtom);
  const thumbnailUrl = shareItemInfo?.type === 'file' ? shareItemInfo.thumbnailUrl : undefined;

  React.useEffect(() => {
    if (shareItemInfo?.type === 'file' && shareItemInfo.thumbnailUrl) {
      updateVideoWidth(shareItemInfo.thumbnailUrl, setVideoWidth);
    }
  }, [shareItemInfo]);

  const emailChipRef = React.useRef<HTMLButtonElement>(null);

  return (
    <>
      {thumbnailUrl && (
        <FormRow>
          <WatermarkPreview thumbnailUrl={thumbnailUrl} videoWidth={videoWidth} />
        </FormRow>
      )}
      <FormRow>
        <FormLabel>{watermarkSettingsText.displaySectionTitle}</FormLabel>
        <StyledFormRow>
          <FormRow split>
            <div
              onMouseEnter={() => setShowEmailChipTooltip(isEditing && true)}
              onMouseLeave={() => setShowEmailChipTooltip(false)}
            >
              <ToggleChip disabled={isEditing} name="showEmail" ref={emailChipRef}>
                {watermarkSettingsText.emailText}
              </ToggleChip>
            </div>
            <ToggleChip name="showIp">{watermarkSettingsText.ipText}</ToggleChip>
            <ToggleChip name="showViewDate">{watermarkSettingsText.dateText}</ToggleChip>
            <ToggleChip name="showViewTime">{watermarkSettingsText.timeText}</ToggleChip>
          </FormRow>
        </StyledFormRow>
        <Tooltip.Control
          open={showEmailChipTooltip}
          placement="top"
          triggerRef={emailChipRef}
          variant="basic"
        >
          {showEmail
            ? watermarkSettingsText.trackingLinkTooltip
            : watermarkSettingsText.genericLinkTooltip}
        </Tooltip.Control>
      </FormRow>
      <FormRow>
        {showEmail &&
          (isEditing ? (
            <>
              <FormLabel>{watermarkSettingsText.emailText}</FormLabel>
              {currentLinkInfo?.watermarkInfo?.recipientUser.displayName ? (
                <FormattedMessage
                  defaultMessage="{name} ({email})"
                  description="Text displaying the recipient's name and their email address."
                  id="pJG2jC"
                  values={{
                    name: currentLinkInfo?.watermarkInfo.recipientUser.displayName,
                    email: currentLinkInfo?.watermarkInfo.email,
                  }}
                />
              ) : (
                <Text>{currentLinkInfo?.watermarkInfo.email}</Text>
              )}
            </>
          ) : (
            <DropboxAccountTypeahead
              {...register('recipients')}
              existingContacts={existingWatermarkUsers}
              initialContacts={recipients}
              inputLabelText={watermarkSettingsText.recipientAria}
              inputPlaceholderText={watermarkSettingsText.recipientPlaceholder}
              setContacts={setRecipients}
              setHasDuplicates={setHasDuplicateEmail}
              showDuplicateWarning={true}
              type="user"
            />
          ))}
        {hasDuplicateEmail && (
          <div
            style={{
              color: 'var(--dig-color__warning__on-surface)',
              marginTop: 'var(--spacing__unit--0_5)',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <UIIcon src={WarningLine} style={{marginRight: 'var(--spacing__unit--0_5)'}} />
            {watermarkSettingsText.emailError}
          </div>
        )}
      </FormRow>
      <FormRow>
        <FormLabel htmlFor="custom-text">{watermarkSettingsText.customTextTitle}</FormLabel>
        <TextInput
          id="custom-text"
          {...register('customText')}
          placeholder={watermarkSettingsText.customTextPlaceholder}
        />
      </FormRow>
      <FormRow split>
        <FormRow>
          <FormLabel htmlFor="position">{watermarkSettingsText.positionSectionTitle}</FormLabel>
          <PositionSelector />
        </FormRow>
        <FormRow>
          <FormLabel htmlFor="theme">{watermarkSettingsText.themeTitle}</FormLabel>
          <ThemeSelector />
        </FormRow>
      </FormRow>
      <FormRow split>
        <FormRow>
          <FormLabel htmlFor="opacity">{watermarkSettingsText.opacityTitle}</FormLabel>
          <SliderControl aria-label={watermarkSettingsText.opacityAria} name="opacity" />
        </FormRow>
        <FormRow>
          <FormLabel htmlFor="size">{watermarkSettingsText.sizeTitle}</FormLabel>
          <SliderControl aria-label={watermarkSettingsText.sizeAria} name="size" />
        </FormRow>
      </FormRow>
    </>
  );
};

function updateVideoWidth(url: string, setVideoWidth: (width: number) => void) {
  const img = new Image();
  img.src = url;
  img.onload = function () {
    setVideoWidth(img.width);
  };
}
