import React from 'react';

import {type Atom, atom, type Getter, type PrimitiveAtom, type Setter} from 'jotai';
import {useSetAtom} from 'jotai';

export type Callback<Value> = (
  get: Getter,
  set: Setter,
  newVal: Value | undefined,
  prevVal: Value | undefined,
) => void;

export function useListenerAtom<Value>(
  atom: PrimitiveAtom<Callback<Value>[]>,
  callback: Callback<Value>,
) {
  const setListeners = useSetAtom(atom);
  React.useEffect(() => {
    setListeners((prev) => [...prev, callback]);
    return () =>
      setListeners((prev) => {
        const index = prev.indexOf(callback);
        return [...prev.slice(0, index), ...prev.slice(index + 1)];
      });
  }, [setListeners, callback]);
}

/**
 * Turns a read-only atom into a writeable atom that can be hydrated, useful for testing
 * @param readAtom the atom to make Read/Write
 */
export function hydratable<Value>(readAtom: Atom<Value>) {
  const valueAtom = atom<null | Value>(null);

  return atom(
    (get) => get(valueAtom) || get(readAtom),
    (_get, set, result: Value) => {
      set(valueAtom, result);
    },
  );
}
