import {useRef, useState} from 'react';

import {useAtomValue} from 'jotai';
import {FormattedMessage} from 'react-intl';

import {Tooltip} from '@dropbox/dig-components/tooltips';
import {UIIcon} from '@dropbox/dig-icons';
import {LockLine, WarningLine} from '@dropbox/dig-icons/assets';

import {watermarkLinksForItemAtom} from '~/components/share_modal/share_state';
import {watermarkText} from '~/components/share_modal/watermark_home';
import {useHasFullAddonAccess} from '~/lib/utils';
import {PremiumTooltip} from '~/pages/layout/components/premium_tooltip';

const useWatermarkShareModal = () => {
  const hasFullAddonAccess = useHasFullAddonAccess();
  const watermarkTooltipTriggerRef = useRef(null);
  const [watermarkTooltip, setWatermarkTooltip] = useState<'premium' | 'warn' | 'none'>('none');
  const [tooltipTimeout, setTooltipTimeout] = useState<NodeJS.Timeout>();
  const watermarkLinks = useAtomValue(watermarkLinksForItemAtom);

  const handleMouseEnterWatermarkTabTitle = () => {
    if (!hasFullAddonAccess) {
      if (watermarkLinks.length) {
        // If there are existing links but the user has downgraded, show a warning
        const timeout = setTimeout(() => setWatermarkTooltip('warn'), 200);
        setTooltipTimeout(timeout);
      } else {
        const timeout = setTimeout(() => setWatermarkTooltip('premium'), 600);
        setTooltipTimeout(timeout);
      }
    }
  };

  const handleMouseLeaveWatermarkTabTitle = () => {
    clearTimeout(tooltipTimeout);
    if (watermarkTooltip === 'warn') {
      setWatermarkTooltip('none');
    }
  };

  const watermarkTabIcon = hasFullAddonAccess ? null : (
    <div ref={!hasFullAddonAccess ? watermarkTooltipTriggerRef : null} style={{display: 'flex'}}>
      <UIIcon
        color="white"
        src={watermarkLinks.length ? WarningLine : LockLine}
        style={{marginLeft: 'var(--spacing__unit--1)'}}
      />
    </div>
  );

  const watermarkPremiumTooltip = (
    <PremiumTooltip
      onClose={() => setWatermarkTooltip('none')}
      open={watermarkTooltip === 'premium'}
      placement="right"
      triggerRef={watermarkTooltipTriggerRef}
    />
  );

  const watermarkDisabledTooltip = (
    <Tooltip.Control
      auto
      open={watermarkTooltip === 'warn'}
      placement="right"
      triggerRef={watermarkTooltipTriggerRef}
      variant="basic"
    >
      <FormattedMessage {...watermarkText.disabledTooltip} />
    </Tooltip.Control>
  );

  return {
    handleMouseEnterWatermarkTabTitle,
    handleMouseLeaveWatermarkTabTitle,
    watermarkDisabledTooltip,
    watermarkPremiumTooltip,
    watermarkTabIcon,
  };
};

export {useWatermarkShareModal};
