import React, {useReducer} from 'react';

import {defineMessages, useIntl} from 'react-intl';

import {Box} from '@dropbox/dig-foundations';

import {EditableInput} from '~/components/folder_header/editable_input';
import type {ShareFolderAccessType} from '~/lib/api';
import {useUpdateProjectMutation} from '~/lib/api_queries';
import {PermissionTag} from '~/pages/browse_page/components/header/permission_tag';

type EditableTitleProps = {
  disabled?: boolean;
  folderName: string;
  folderId: string;
  currentFolderPermission?: ShareFolderAccessType;
  handleFolderRename: (newFolderName: string) => void;
};
const EditableTitle = ({
  disabled,
  folderName,
  folderId,
  currentFolderPermission,
  handleFolderRename,
}: React.PropsWithChildren<EditableTitleProps>) => {
  const intl = useIntl();
  const updateProject = useUpdateProjectMutation();

  // When a rename doesn't stick, force input to reinit with original value
  const [inits, reinit] = useReducer((x: number) => x + 1, 0);

  const handleRename = async (newFolderName: string) => {
    if (!newFolderName.trim()) {
      reinit();
      return;
    }

    await updateProject.mutateAsync({folderId, name: newFolderName});
    handleFolderRename(newFolderName);
  };

  const editTitleText = intl.formatMessage(intlMessages.editTitleTooltip);

  return (
    <Box alignItems="center" data-testid="editable-title" display="flex" width="100%">
      <EditableInput
        ariaLabel={editTitleText}
        disabled={disabled}
        inputBadge={
          currentFolderPermission && (
            <PermissionTag currentFolderPermission={currentFolderPermission} />
          )
        }
        key={inits}
        onChangeTextValue={handleRename}
        textValue={folderName}
        tooltipText={editTitleText}
        type="title"
      />
    </Box>
  );
};

const intlMessages = defineMessages({
  editTitleTooltip: {
    defaultMessage: 'Edit title',
    description: 'Tooltip text denoting editing the title',
    id: 'Kcn8q8',
  },
});

export {EditableTitle};
