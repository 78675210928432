import type {WatermarkPositionsType} from '~/components/share_modal/position_selector';
import {PresetType, RangeType, SettingsType} from '~/components/upload_active_sequence_modal';
import type {MediaType} from '~/lib/api';
import type {LoadingValue} from '~/pages/browse_page/components/common';
import {SortAttributes, SortDirections, ViewTypes} from '~/pages/browse_page/types';
import {CommentsFilter} from '~/pages/viewer_page/comments_view_types.d';
import {ONBOARDING_OPEN_SOURCE, ONBOARDING_TYPE, STEPS} from '~/state/onboarding_v2';

import {commentExportFormats} from '../comments_export';

export type SafeForAmplitudeId = `safe_for_amplitude:${string}`;
export type RuntimeBundleVersion = `https://replay.dropbox.com/runtime.${string}.js`;
export type MainBundleVersion = `https://replay.dropbox.com/main.${string}.js`;

export type UserEventAttributes = {
  isDropboxer: boolean;
  isTestUser: boolean;
  isTeamAdmin: boolean;
  teamId: SafeForAmplitudeId | null;
  userAgent: string;
  userId: SafeForAmplitudeId | null;
  activeTeamSize: number;
  skuName: string;
  locale: string;
  stormcrows: Record<string, string>;
  storageEnabled: string;
  provision_tier: string | null;
  provision_user_variant: string | null;
};

export type PAPUserEventAttributes = {
  is_test_user: boolean;
  is_team_admin: boolean;
  team_id?: string;
  user_agent: string;
  user_id?: string;
  active_team_size: string;
  sku_name: string;
  locale: string;
  storage_enabled: string;
  provision_tier: string;
  provision_user_variant: string;
};

export const enum SurfaceType {
  Unknown = 'unknown',
  Browse = 'browse',
  Video = 'video',
  CreateVideo = 'create_video_redirect',
  CreateOnboardingVideo = 'create_onboarding_video_redirect',
  EditorReview = 'editor_review',
  Settings = 'settings',
  SegmentationQuiz = 'segmentation_quiz',
}

const BrowseLocation = [
  'your_work',
  'shared_with_you',
  'shared_folder',
  'your_links',
  'settings_page',
  'admin_page',
  'in_app_purchase_page',
] as const;

export type BrowseLocationType = (typeof BrowseLocation)[number];

export type EventAttributes = {
  userAttributes: UserEventAttributes;
  surface: SurfaceType;
  videoId: SafeForAmplitudeId | null;
  projectId: SafeForAmplitudeId | null;
  reelType: ReelObjectType | null;
  isLiveReview: 'true' | 'false';
  browseLocation: BrowseLocationType | null;
  runtimeBundleVersion: RuntimeBundleVersion | null;
  mainBundleVersion: MainBundleVersion | null;
};

const ShareAccess = ['review', 'view_only'] as const;

const TogglePermission = ['enabled', 'disabled'] as const;

const AddMediaClickSource = [
  'browse_button',
  'empty_state_callout',
  'welcome_modal',
  'dropbox_web',
  'dropbox_web_snackbar',
  'dropbox_previews_prompt',
  'dropbox_previews_open_in_menu',
  'dropbox_desktop',
  'drag_and_drop',
  'upload_project_tile',
  'dropbox_previews_action_bar',
  'dropbox_share_action',
  'folder_upload_browse_button',
  'browse_suggested_item_tile',
] as const;

export type AddMediaClickSourceType = (typeof AddMediaClickSource)[number];

// Complete possible list: https://dropbox.sourcegraphcloud.com/github.com/dropbox-internal/server/-/blob/dropbox/heracles/apps/self_serve_analytics/pap_enrichment/action_element_allowlist.txt
// Subset used for Replay
export type PapActionElement = 'content_tiles' | 'content_tile' | 'overflow_menu' | 'toolbar';

const AddOneDriveMediaClickSource = [
  'project_import',
  'version_import',
  'caption_import',
  'null',
] as const;

export type AddOneDriveMediaClickSourceType = (typeof AddOneDriveMediaClickSource)[number];

const MediaSource = ['dropbox', 'direct_upload', 'gdrive', 'onedrive'] as const;

export type MediaSourceType = (typeof MediaSource)[number];

const EventState = ['canceled', 'failed', 'success', 'start'] as const;

export type EventStateType = (typeof EventState)[number];

const BooleanString = ['true', 'false'] as const;

const OptionalBooleanString = ['true', 'false', 'null'] as const;

const PlayVideoClickSource = ['keyboard_shortcut', 'play_button'] as const;

const PauseVideoClickSource = ['keyboard_shortcut', 'pause_button'] as const;

const NewCommentReadStatus = ['read', 'unread'] as const;

const NewCommentStatus = ['resolved', 'unresolved'] as const;

const UploadSource = ['file', 'folder'] as const;

export type UploadSourceType = (typeof UploadSource)[number];

const ReelPageSource = [
  'home_page',
  'video_page',
  'share_page',
  'links_page',
  'landing_page',
  'landing_page_no_access',
  'landing_page_redirect_only',
  'sign_in_to_access_page',
  'third_party_auth_page',
  'create_video_redirect_page',
  'share_folder_folder_page',
  'share_folder_video_page',
  'settings_page',
  'version_comparison_page',
  'download_page',
  'admin_page',
  'create_onboarding_video_redirect_page',
  'create_video_redirect_page_without_onboarding',
] as const;

export type ReelPageSourceType = (typeof ReelPageSource)[number];

const ShareButtonLocation = [
  'video_page_header',
  'comment_pane',
  'browse_context_menu',
  'browse_list_view',
  'red_carpet',
  'upload_snackbar',
  'upload_modal',
  'upload_drawer',
  'folder',
] as const;

export type ShareButtonLocationType = (typeof ShareButtonLocation)[number];

const AddNewVersionClickSource = ['browse_expanded_menu', 'versions_menu'] as const;

export type AddNewVersionClickSourceType = (typeof AddNewVersionClickSource)[number];

const CaptionFileType = ['srt', 'vtt', 'txt'] as const;

const SidebarState = ['comments', 'transcriptions'] as const;

const NewVersionStatus = [
  'no_status',
  'needs_review',
  'in_progress',
  'approved',
  'edits_requested',
] as const;

export type NewVersionStatusType = (typeof NewVersionStatus)[number];

const MoveSource = ['left_rail', 'grid_or_list'] as const;

export type MoveSourceType = (typeof MoveSource)[number];

const Tour = ['creator', 'collaborator'] as const;

export type TourType = (typeof Tour)[number];

const ProjectAccess = [
  'other',
  'reviewer',
  'admin',
  'owner',
  'super_admin',
  'not_applicable',
] as const;

export type ProjectAccessType = (typeof ProjectAccess)[number];

const CollaboratorSurface = ['modal', 'comments_pane'] as const;

export type CollaboratorSurfaceType = (typeof CollaboratorSurface)[number];

export const QualityOptions = [
  '360P',
  '480P',
  '720P',
  '1080P',
  '2160P',
  '256K',
  '192K',
  '128K',
  'Original',
  'Other',
  'Unknown',
  'FLAC',
] as const;

export const PlaybackRateOptions = [0.5, 1, 1.25, 1.5, 2, 2.5, 3, 4] as const;

export type PlaybackRateOptionsTypes = (typeof PlaybackRateOptions)[number];

export type QualityOptionsTypes = (typeof QualityOptions)[number];

// Map the file's MediaType to its corresponding values for the reel_type and media_type event properties.
export const mapMediaTypeToLoggingType = (mediaType?: MediaType | LoadingValue): ReelMediaType => {
  const mapping: {[key in MediaType]?: ReelMediaType} = {
    video: 'video',
    audio: 'audio',
    image: 'image',
    document: 'document',
    creative_doc: 'creative_document',
  };

  if (mediaType === 'loading' || mediaType === undefined) {
    return 'unknown';
  }
  return mapping[mediaType] || 'unknown';
};

export const LoggingMediaType = [
  'video',
  'audio',
  'image',
  'document',
  'creative_document',
  'unknown',
] as const;

export type ReelMediaType = (typeof LoggingMediaType)[number];

const ReelObject = ['project', 'folder', ...LoggingMediaType] as const;

export type ReelObjectType = (typeof ReelObject)[number];

// Different locations you may post a comment from
const CommentPost = [
  'new_thread',
  'reply',
  'edit',
  'drawing',
  'new_pin_point',
  'pin_point_reply',
] as const;

export type CommentPostType = (typeof CommentPost)[number];

const RedCarpetMode = ['PPRO', 'AEFT', 'FCP', 'MAC', 'unknown'] as const;
const RedCarpetModeOrNone = [...RedCarpetMode, 'NONE'] as const;

export type RedCarpetModeType = (typeof RedCarpetMode)[number];

export const OnboardingType = Object.values(ONBOARDING_TYPE);

export const OnboardingStep = Object.values(STEPS);

export const OnboardingOpenSource = Object.values(ONBOARDING_OPEN_SOURCE);

export const OnboardingStepOrNone = [...OnboardingStep, 'NONE'];

const WatermarkLinkType = ['generic', 'individual'] as const; // Generic has no email, individual has an email

const WatermarkPositionsLogging = [
  'center',
  'top',
  'bottom',
  'top_left',
  'top_right',
  'bottom_left',
  'bottom_right',
  'unknown',
] as const;

export function getWatermarkPositionLoggingType(position: WatermarkPositionsType) {
  switch (position) {
    case 'center':
      return 'center';
    case 'top':
      return 'top';
    case 'bottom':
      return 'bottom';
    case 'top-left':
      return 'top_left';
    case 'top-right':
      return 'top_right';
    case 'bottom-left':
      return 'bottom_left';
    case 'bottom-right':
      return 'bottom_right';
    default:
      return 'unknown';
  }
}

/*
 * Class representing an attribute that we log to Amplitude. This class
 * contains both the union type information for typechecking and an array of
 * strings in memory for dynamic checks and gives us an extra layer of defense
 * against unintentionally logging user data. The validate method will reject
 * any value that is not in the array of acceptable values passed in during
 * instantiation.
 */
class OneOf<U> {
  private options: readonly U[];

  // For typechecking purposes only
  public typeExemplar: U;

  constructor(options: readonly U[]) {
    this.options = options;
    this.typeExemplar = options[0];
  }

  validate(value: any) {
    return this.options.includes(value);
  }
}

class NumberValue {
  // For typechecking purposes only
  public typeExemplar: number = 0;

  validate(value: any) {
    return typeof value === 'number';
  }
}

/**
 * DANGEROUS: DO NOT USE
 *
 * Do NOT log arbitrary string values to Amplitude.
 */
class __DangerousStringValue {
  // For typechecking purposes only
  public typeExemplar: string = '';

  validate(value: any) {
    return typeof value === 'string';
  }
}

const makeBooleanAttr = () => new OneOf<(typeof BooleanString)[number]>(BooleanString);

export const toBooleanAttr = (x: boolean) => (x ? 'true' : 'false');

const makeOptionalBooleanAttr = () =>
  new OneOf<(typeof OptionalBooleanString)[number]>(OptionalBooleanString);

export type LinkItemTypes = (typeof LinkItemTypes)[number];
const LinkItemTypes = ['file', 'folder', 'team_project'] as const;

const MediaViewerControlClickSource = [
  'control_bar_button',
  'keyboard_shortcut',
  'thumbnail_sidebar',
  'page_input_control_bar',
] as const;
export type MediaViewerControlClickSourceType = (typeof MediaViewerControlClickSource)[number];

const CaptionsUploadType = ['from_dropbox', 'direct_upload', 'gdrive', 'onedrive'] as const;

const CaptionsClickSource = ['empty_captions_menu', 'manage_captions_modal'] as const;

const UploadActiveSequenceClickSource = [
  'add_files_menu',
  'add_new_version_menu',
  'context_menu',
] as const;

const IntegrationLinkType = [
  'premiere',
  'lumafusion',
  'wevideo',
  'davinciresolve',
  'integrations_info',
  'finalcutpro',
  'protools',
] as const;

const FilterType = ['status', 'media_type', 'admin_folder_type'] as const;

const FilterOption = [
  'include_no_status',
  'include_needs_review',
  'include_in_progress',
  'include_edits_requested',
  'include_approved',
  'include_audio',
  'include_images',
  'include_psd',
  'include_pdf',
  'include_projects',
  'include_video',
  'include_team_projects',
  'include_team_users',
  'select_all',
  'clear_all',
] as const;
export type FilterOptionChoices = (typeof FilterOption)[number];

const NavbarCloseSource = ['navbar_x_button', 'header_subtitle'] as const;

const NavbarNavigateAction = ['select_breadcrumb', 'select_folder', 'select_file'] as const;

const SelectHelpMenuItemOptions = [
  'help_and_faqs',
  'feedback',
  'contact_support',
  'privacy_policy',
  'cookie_policy',
  'manage_cookies',
  'tutorials',
  'discuss_replay',
] as const;

export type SelectHelpMenuItemOptionsType = (typeof SelectHelpMenuItemOptions)[number];

const CommentViewSource = [
  'comment_marker',
  'comments_pane',
  'comments_overlay',
  'pin_point_comment',
] as const;

export type CommentViewSourceType = (typeof CommentViewSource)[number];

const LinkEnabledState = ['on', 'off'] as const;

const viewviewPreviousCommentsDisabled = ['on', 'off'] as const;

const ReplayModal = [
  'add_folder_link',
  'add_link',
  'add_people',
  'create_project',
  'download',
  'feature_upsell',
  'integration_callout',
  'logo',
  'paywall',
  'post_purchase_success',
  'purchase_replay',
  'share',
  'theme',
] as const;
export type ReplayModalType = (typeof ReplayModal)[number];

const ReplayPurchaseLocation = ['replay_in_app_purchase_page'] as const;
export type ReplayPurchaseLocationType = (typeof ReplayPurchaseLocation)[number];

const ReplayPlan = ['addon_individual', 'addon_team', 'standalone_individual', 'standalone_team'];

// Reason for redirecting a user whose SKU type is supported on the Replay In-app
// Purchase Page to FSS to buy Replay.
const FssRedirectReason = [
  'no_billing_info',
  'payment_requires_popup',
  'experiment_control_variant',
  'experiment_off_variant',
] as const;
export type FssRedirectReasonType = (typeof FssRedirectReason)[number];

const CtaLocation = [
  'links_hub',
  'archive_to_dbx',
  'password_protected_links',
  'deadlines_and_tasks',
  'file_quota_banner',
  'add_files_menu',
  'lossless_audio',
  'version_comparison',
  'transcription',
  'watermark',
  'upload_drawer',
  'custom_branding',
  'file_quota_experiment_banner',
  'unlimited_file_uploads',
  'migration_banner',
  'storage_banner',
  'browse_page_header',
  'marketing_page',
  'fss_upgrade_button',
  'beta_modal',
  'paywall',
  'team_only_commenting',
  'banner',
] as const;

export type CtaLocationLoggingType = (typeof CtaLocation)[number];

const ProvisionUserVariant = [
  'none',
  'basic_individual',
  'family_user',
  'edu_user',
  'paying_individual',
  'managed_team_member',
  'managed_team_admin',
  'self_serve_team_member',
  'self_serve_team_admin',
  'standalone_free_member',
  'standalone_free_admin',
  'standalone_paid_member',
  'standalone_paid_admin',
] as const;

const TotalNumLinkRanges = [
  '0',
  '1_2',
  '3_5',
  '6_10',
  '11_20',
  '21_30',
  '31_50',
  '51_100',
  '101_150',
  '150+',
] as const;

export const getTotalNumLinksRageFromInt = (n: number): (typeof TotalNumLinkRanges)[number] => {
  if (n <= 0) {
    return '0';
  }
  if (n <= 2) {
    return '1_2';
  }
  if (n <= 5) {
    return '3_5';
  }
  if (n <= 10) {
    return '6_10';
  }
  if (n <= 20) {
    return '11_20';
  }
  if (n <= 30) {
    return '21_30';
  }
  if (n <= 50) {
    return '31_50';
  }
  if (n <= 100) {
    return '51_100';
  }
  if (n <= 150) {
    return '101_150';
  }
  return '150+';
};

const DueDateModalSource = [
  'video_page',
  'image_page',
  'share_modal',
  'mobile_context_menu', // On mobile, we hide the due date button in the context menu
] as const;

const SettingToggleState = ['on', 'off'] as const;
const ToggleSettings = [
  'enable_all_notification',
  'project_membership_updates_notification',
  'content_updates_notification',
  'new_comment_notification',
  'mention_and_thread_comment_notification',
  'share_link_view_notification',
  'status_updates_notification',
  'show_suggested_files',
  'keyboard_shortcut_setting',
] as const;
export type ToggleSettingsType = (typeof ToggleSettings)[number];

export const ProToolsConnectionModalReason = ['onboarding', 'manual', 'error', 'other'] as const;

export const ModalDismissSource = ['clicked_outside', 'close_button'] as const;

export const ReplayMediaType = ['video', 'audio', 'image', 'pdf', 'none'] as const;

export const ExperimentVariantType = ['v1', 'v2', 'none'] as const;

export const EventAttributesMap = {
  shown_modal: {
    modal: new OneOf(ReplayModal),
    user_variant: new OneOf(ProvisionUserVariant),
  },

  /* Signin and Onboarding Actions */

  select_sign_in_with_dropbox: undefined,
  select_logged_out_marketing_page: undefined,
  succeed_reel_landing_page_sign_in: undefined,

  shown_welcome_modal: undefined,
  select_welcome_modal_dismiss: {video_time: new NumberValue()},
  select_welcome_modal_open_demo: undefined,
  select_welcome_video_play: undefined,
  welcome_video_played_until_end: undefined,

  succeed_open_replay_demo: undefined,
  select_dismiss_product_tour: {is_demo: makeBooleanAttr(), tour: new OneOf(Tour)},
  succeed_onboarding_step: {
    is_demo: makeBooleanAttr(),
    step: new NumberValue(),
    tour: new OneOf(Tour),
  },
  succeed_complete_product_tour: {is_demo: makeBooleanAttr(), tour: new OneOf(Tour)},

  select_continue_without_login: {surface: new OneOf(CollaboratorSurface)},
  succeed_collab_modal_login: undefined,

  toggle_onboarding_checklist: undefined,
  dismiss_onboarding_checklist: undefined,

  closed_collaborator_overlay: undefined,

  succeed_complete_browse_tour: undefined,
  select_dismiss_browse_tour: undefined,

  /* Add/Remove Media Actions */

  select_add_reel_media: {
    click_source: new OneOf(AddMediaClickSource),
    media_source: new OneOf(MediaSource),
    upload_source: new OneOf(UploadSource),
  },

  create_multiple_reel_media: {
    click_source: new OneOf(AddMediaClickSource),
    media_source: new OneOf(MediaSource),
    folder_level: new NumberValue(),
    media_count: new NumberValue(),
    upload_source: new OneOf(UploadSource),
  },

  create_reel_media: {
    click_source: new OneOf(AddMediaClickSource),
    media_source: new OneOf(MediaSource),
    folder_level: new NumberValue(),
    media_type: new OneOf(LoggingMediaType),
    is_multiple: makeBooleanAttr(),
    is_onboarding_upload_step: makeBooleanAttr(),
  },

  select_new_version: {
    click_source: new OneOf(AddNewVersionClickSource),
    media_source: new OneOf(MediaSource),
  },

  create_new_version: {
    click_source: new OneOf(AddNewVersionClickSource),
    media_source: new OneOf(MediaSource),
    media_type: new OneOf(LoggingMediaType),
  },

  delete_reel_media: {media_count: new NumberValue()},
  delete_version: undefined,

  /* Browse actions */

  shown_reel_page: {
    reel_page_source: new OneOf(ReelPageSource),
    access_type: new OneOf(ProjectAccess),
    email_campaign_id: new NumberValue(),
    referrer: new __DangerousStringValue(),
    red_carpet_mode: new OneOf(RedCarpetModeOrNone),
  },

  select_overflow_menu: undefined,
  select_overflow_menu_add_people: undefined,
  select_overflow_menu_manage_people: undefined,
  select_overflow_menu_transfer_ownership: undefined,
  select_overflow_menu_rename: undefined,
  select_overflow_menu_remove: undefined,
  select_overflow_menu_share: undefined,
  select_overflow_menu_get_metadata: undefined,
  select_overflow_menu_move: undefined,
  select_overflow_menu_copy: undefined,

  select_waffle: undefined,
  select_waffle_item: undefined,

  select_replay_home: undefined,
  select_navigation_item: {item: new OneOf(BrowseLocation)},
  select_storage_setting: undefined,

  shown_whats_new_badge: undefined,
  select_whats_new_menu: undefined,
  select_whats_new_link: {
    release_version: new __DangerousStringValue(),
    link: new __DangerousStringValue(),
  },
  select_account_menu: undefined,
  select_sign_out: undefined,
  select_settings: undefined,

  select_create_folder: undefined,

  select_breadcrumb: undefined,

  select_reel_object: undefined,
  select_toggle_sort: {attribute: new OneOf(SortAttributes), direction: new OneOf(SortDirections)},
  select_tile_sort: {attribute: new OneOf(SortAttributes), direction: new OneOf(SortDirections)},
  select_change_view: {view_type: new OneOf(ViewTypes)},

  rename_reel_video: undefined,

  create_reel_folder: {folder_level: new NumberValue()},
  delete_reel_folder: {folder_count: new NumberValue()},
  rename_reel_folder: undefined,
  succeed_project_move: {
    click_source: new OneOf(MoveSource),
    media_count: new NumberValue(),
  },
  succeed_project_copy: {
    click_source: new OneOf(MoveSource),
    media_count: new NumberValue(),
  },
  succeed_folder_move: {
    click_source: new OneOf(MoveSource),
    folder_count: new NumberValue(),
  },
  succeed_folder_copy: {
    click_source: new OneOf(MoveSource),
    folder_count: new NumberValue(),
  },
  succeed_open_move_rail: undefined,

  create_team_project: undefined,
  succeed_add_members_team_project: {
    access_type: new OneOf(ProjectAccess),
    num_members_added: new NumberValue(),
    num_members_total: new NumberValue(),
  },
  succeed_remove_members_team_project: {
    num_members_removed: new NumberValue(),
    num_members_total: new NumberValue(),
  },

  /* FSS Groups in Manage Access Modal */
  fss_group_permission_added: {
    access_type: new OneOf(ProjectAccess),
    num_members_added: new NumberValue(),
    num_members_total: new NumberValue(),
  },
  fss_group_permission_updated: undefined,
  fss_group_permission_removed: undefined,

  /* SuperAdmin Actions Specifically */
  super_admin_manage_access_modal_opened: undefined,
  super_admin_take_ownership_team_project: undefined,
  super_admin_reassign_ownership_team_project: undefined,

  /* SuperAdmin Transfer Ownership Actions */
  open_reel_admin_transfer_ownership: undefined,
  save_reel_admin_transfer_ownership: undefined,
  cancel_reel_admin_transfer_ownership: undefined,

  /* Sharing Actions */

  select_reel_share: {
    link_item_type: new OneOf(LinkItemTypes),
    location: new OneOf(ShareButtonLocation),
    replay_media_type: new OneOf(ReplayMediaType),
    experiment_variant: new OneOf(ExperimentVariantType),
  },
  create_reel_link: {
    access_type: new OneOf(ShareAccess),
    link_item_type: new OneOf(LinkItemTypes),
    location: new OneOf(ShareButtonLocation),
  },
  create_watermark_share_links: {
    num_links_created: new NumberValue(),
    watermark_link_type: new OneOf(WatermarkLinkType),
    password_setting: new OneOf(TogglePermission),
    logged_out_access: new OneOf(TogglePermission),
    replay_link_access: new OneOf(TogglePermission),
    view_previous_versions_disabled: new OneOf(TogglePermission),
    show_ip: new OneOf(TogglePermission),
    show_view_date: new OneOf(TogglePermission),
    show_view_time: new OneOf(TogglePermission),
    has_custom_text: makeBooleanAttr(),
    watermark_theme: new OneOf(['light', 'dark']),
    opacity: new NumberValue(),
    font_size: new NumberValue(),
    watermark_position: new OneOf(WatermarkPositionsLogging),
  },
  copy_reel_share_link: {
    access_type: new OneOf(ShareAccess),
    link_item_type: new OneOf(LinkItemTypes),
    is_onboarding_share_step: makeBooleanAttr(),
    replay_media_type: new OneOf(ReplayMediaType),
    experiment_variant: new OneOf(ExperimentVariantType),
  },
  send_reel_share_link_email: {
    access_type: new OneOf(ShareAccess),
    link_item_type: new OneOf(LinkItemTypes),
    recipient_count: new NumberValue(),
    is_onboarding_share_step: makeBooleanAttr(),
    replay_media_type: new OneOf(ReplayMediaType),
    experiment_variant: new OneOf(ExperimentVariantType),
  },
  select_reel_share_settings: {
    access_type: new OneOf(ShareAccess),
    link_item_type: new OneOf(LinkItemTypes),
  },
  cancel_reel_share_settings: {
    access_type: new OneOf(ShareAccess),
    link_item_type: new OneOf(LinkItemTypes),
  },
  // Used for non-watermark links
  update_share_link_settings: {
    access_type: new OneOf(ShareAccess),
    password_setting: new OneOf(TogglePermission),
    downloads_permission: new OneOf(TogglePermission),
    logged_out_access: new OneOf(TogglePermission),
    link_item_type: new OneOf(LinkItemTypes),
  },
  // Used for watermark links
  update_watermark_link_settings: {
    watermark_link_type: new OneOf(WatermarkLinkType),
    password_setting: new OneOf(TogglePermission),
    logged_out_access: new OneOf(TogglePermission),
    replay_link_access: new OneOf(TogglePermission),
    view_previous_versions_disabled: new OneOf(TogglePermission),
    show_ip: new OneOf(TogglePermission),
    show_view_date: new OneOf(TogglePermission),
    show_view_time: new OneOf(TogglePermission),
    has_custom_text: makeBooleanAttr(),
    watermark_theme: new OneOf(['light', 'dark']),
    opacity: new NumberValue(),
    font_size: new NumberValue(),
    watermark_position: new OneOf(WatermarkPositionsLogging),
  },
  disable_share_link: {
    access_type: new OneOf(ShareAccess),
    link_item_type: new OneOf(LinkItemTypes),
    is_watermarked: makeBooleanAttr(),
  },
  select_toggle_downloads_enabled: {
    downloads_permission: new OneOf(TogglePermission),
    link_item_type: new OneOf(LinkItemTypes),
  },
  select_toggle_password_required: {
    access_type: new OneOf(ShareAccess),
    link_item_type: new OneOf(LinkItemTypes),
    password_required: new OneOf(TogglePermission),
  },
  select_add_password: {
    access_type: new OneOf(ShareAccess),
    link_item_type: new OneOf(LinkItemTypes),
  },
  select_submit_password: {
    access_type: new OneOf(ShareAccess),
    link_item_type: new OneOf(LinkItemTypes),
  },
  select_edit_password: {
    access_type: new OneOf(ShareAccess),
    link_item_type: new OneOf(LinkItemTypes),
  },
  select_remove_password: {
    access_type: new OneOf(ShareAccess),
    link_item_type: new OneOf(LinkItemTypes),
  },

  shown_upload_snackbar_share: undefined,
  shown_upload_modal_share: undefined,

  shown_watermark_share_page: {
    is_owner: makeBooleanAttr(),
  },

  /* Bulk Actions */
  select_bulk_copy: {folder_count: new NumberValue(), media_count: new NumberValue()},
  select_bulk_delete: {folder_count: new NumberValue(), media_count: new NumberValue()},
  select_bulk_hide: {folder_count: new NumberValue(), media_count: new NumberValue()},
  select_bulk_move: {folder_count: new NumberValue(), media_count: new NumberValue()},
  select_bulk_leave: {folder_count: new NumberValue()},
  select_bulk_version_status: {
    new_version_status: new OneOf(NewVersionStatus),
    media_count: new NumberValue(),
  },

  /* Publish Painted Door Actions */

  select_publish_painted_door_button: {
    reel_page_source: new OneOf(ReelPageSource),
    media_type: new OneOf(LoggingMediaType),
  },

  select_publish_painted_door_tooltip_dismiss: undefined,

  shown_max_depth_modal: {
    click_source: new OneOf(AddMediaClickSource),
    media_source: new OneOf(MediaSource),
    upload_source: new OneOf(UploadSource),
  },

  select_version_status: {
    new_version_status: new OneOf(NewVersionStatus),
  },

  select_version_menu: undefined,
  select_version_different: undefined,
  select_remove_version: undefined,

  shown_version_comparison_page: undefined,

  select_play_button: {
    click_source: new OneOf(PlayVideoClickSource),
    playback_rate: new OneOf(PlaybackRateOptions),
  },
  select_pause_button: {
    click_source: new OneOf(PauseVideoClickSource),
  },

  select_remove_video: undefined,
  select_rename_video: undefined,

  select_enable_repeat_media: {
    media_type: new OneOf(LoggingMediaType),
  },
  select_disable_repeat_media: {
    media_type: new OneOf(LoggingMediaType),
  },
  click_back_chevron: {project_nav_open: makeBooleanAttr()},

  /* Commenting Actions */
  comment_reel_video: {
    timestamp_added: makeBooleanAttr(),
    is_threaded_comment: makeBooleanAttr(),
    comment_length: new NumberValue(),
    contains_annotation: makeBooleanAttr(),
    contains_emoji: makeBooleanAttr(),
    contains_mention: makeBooleanAttr(),
    is_quick_reaction: makeBooleanAttr(),
    is_ranged_comment: makeBooleanAttr(),
    is_pin_point: makeBooleanAttr(),
    contains_page: makeBooleanAttr(),
  },

  select_view_comment_thread: {
    source: new OneOf(CommentViewSource),
    is_ranged_comment: makeBooleanAttr(),
    has_drawing: makeBooleanAttr(),
    is_pin_point: makeBooleanAttr(),
  },

  select_toggle_comment_read_status: {
    new_comment_read_status: new OneOf(NewCommentReadStatus),
  },
  select_toggle_comment_resolution: {
    new_comment_status: new OneOf(NewCommentStatus),
  },

  select_edit_comment: undefined,
  cancel_edit_comment: undefined,
  edit_reel_comment: {
    contains_annotation: makeBooleanAttr(),
  },

  pin_reel_comment: undefined,
  unpin_reel_comment: undefined,
  create_pin_label_comment: undefined,
  update_pin_label_comment: undefined,
  delete_pin_label_comment: undefined,
  delete_reel_comment: undefined,

  select_change_comment_filter: {new_comment_filter: new OneOf(Object.values(CommentsFilter))},
  select_draft_comment_marker: {is_ranged_comment: makeBooleanAttr()},
  select_drag_draft_comment_marker: {is_ranged_comment: makeBooleanAttr()},

  select_post_comment: {
    post_source: new OneOf(CommentPost),
  },
  select_comment_reply: undefined,
  select_toggle_comments_pane: {show: makeBooleanAttr()},
  select_comment_composer: undefined,
  select_comment_emoji_picker: undefined,
  select_comment_mention_picker: undefined,
  select_comment_include_timestamp: undefined,
  select_comment_include_page: undefined,
  select_toggle_drawing_tools: {show: makeBooleanAttr()},
  select_drawing_start: undefined,
  select_drawing_finish: undefined,
  select_toggle_color_picker: {show: makeBooleanAttr()},
  select_toggle_stroke_width_picker: {show: makeBooleanAttr()},
  select_drawing_undo: undefined,
  select_drawing_clear: undefined,

  add_emoji_reaction: undefined,
  remove_emoji_reaction: undefined,

  export_reel_comments: {
    type: new OneOf(commentExportFormats),
    condensed: makeBooleanAttr(),
  },

  view_previous_versions_disabled: {
    enabled_state: new OneOf(viewviewPreviousCommentsDisabled),
  },

  /* Video context menu actions */

  select_keyboard_shortcut_menu: undefined,

  select_reel_download_menu: undefined,

  select_set_thumbnail: undefined,
  select_info_button: undefined,
  select_info_see_more: undefined,

  /* Live review actions */

  shown_live_review_tooltip: undefined,
  select_live_review_tooltip_dismiss: undefined,
  select_live_review_join: undefined,
  succeed_live_review_session_join: {participants: new NumberValue(), is_guest: makeBooleanAttr()},
  fail_live_review_session_join: {is_guest: makeBooleanAttr()},
  succeed_live_review_session_leave: {participants: new NumberValue(), is_guest: makeBooleanAttr()},
  select_live_review_start: undefined,
  succeed_live_review_session_start: {participants: new NumberValue()},
  fail_live_review_session_start: undefined,

  // Media control bar actions
  select_zoom_out: {
    click_source: new OneOf(MediaViewerControlClickSource),
    media_type: new OneOf(LoggingMediaType),
  },
  select_zoom_in: {
    click_source: new OneOf(MediaViewerControlClickSource),
    media_type: new OneOf(LoggingMediaType),
  },
  select_full_screen: {
    is_full_screen: makeBooleanAttr(),
    click_source: new OneOf(MediaViewerControlClickSource),
    media_type: new OneOf(LoggingMediaType),
  },
  select_fit_to_width: {
    click_source: new OneOf(MediaViewerControlClickSource),
    media_type: new OneOf(LoggingMediaType),
  },
  select_fit_to_height: {
    click_source: new OneOf(MediaViewerControlClickSource),
    media_type: new OneOf(LoggingMediaType),
  },
  select_zoom_map: {
    show: makeBooleanAttr(),
    click_source: new OneOf(MediaViewerControlClickSource),
    media_type: new OneOf(LoggingMediaType),
  },

  select_video_quality_menu: undefined,
  select_video_quality: {
    old_quality: new OneOf(QualityOptions),
    new_quality: new OneOf(QualityOptions),
  },
  shown_video_quality: {
    quality: new OneOf(QualityOptions),
  },

  // Captions actions
  select_upload_captions: {
    click_source: new OneOf(CaptionsClickSource),
    upload_type: new OneOf(CaptionsUploadType),
  },
  succeed_upload_captions: {
    click_source: new OneOf(CaptionsClickSource),
    upload_type: new OneOf(CaptionsUploadType),
  },
  select_delete_captions: undefined,
  succeed_delete_captions: undefined,
  select_show_captions: undefined,
  select_switch_captions: undefined,
  select_hide_captions: undefined,

  shown_video_empty_state: undefined,
  select_integration_link: {integration: new OneOf(IntegrationLinkType)},
  failed_reel_access: undefined,

  // GDrive checkpoints
  gdrive_login_modal_shown: undefined,
  gdrive_account_link_modal_shwon: undefined,
  gdrive_account_link_popup_opened: undefined,
  gdrive_account_link_popup_blocked: undefined,
  gdrive_account_link_popup_closed: undefined,
  gdrive_account_link_success: undefined,
  gdrive_account_link_failure: undefined,
  gdrive_picker_opened: undefined,
  gdrive_transfer_complete: undefined,

  // OneDrive events
  select_onedrive_import: {
    click_source: new OneOf(AddOneDriveMediaClickSource),
    event_state: new OneOf(EventState),
    is_logged_in: makeOptionalBooleanAttr(),
  },
  link_dbx_onedrive_account: undefined,
  link_replay_onedrive_account: undefined,
  open_onedrive_file_picker: undefined,
  select_onedrive_file: undefined,

  // Pro Tools events
  shown_protools_connection_modal: {
    connection_reason: new OneOf(ProToolsConnectionModalReason),
  },
  dismiss_protools_connection_modal: {modal_dismiss_source: new OneOf(ModalDismissSource)},
  click_protools_export_comments_button: undefined,
  click_protools_connect_button: undefined,

  // Filter
  filter_changed: {
    filter_type: new OneOf(FilterType),
    filter_option: new OneOf(FilterOption),
    was_enabled: makeBooleanAttr(),
  },

  // Viewer page project navigation
  open_viewer_navbar: undefined,
  close_viewer_navbar: {close_source: new OneOf(NavbarCloseSource)},
  viewer_navbar_navigate_action: {action: new OneOf(NavbarNavigateAction)},

  select_help_menu: undefined,
  select_help_menu_item: {item: new OneOf(SelectHelpMenuItemOptions)},

  // Integrations
  select_upload_active_sequence: {
    click_source: new OneOf(UploadActiveSequenceClickSource),
  },
  select_upload_preset: {
    preset_type: new OneOf(PresetType),
    redCarpetMode: new OneOf(RedCarpetMode),
  },
  select_upload_range: {
    range_type: new OneOf(RangeType),
    redCarpetMode: new OneOf(RedCarpetMode),
  },
  select_upload_active_sequence_confirm: undefined,
  auto_sync_comments: {
    value: makeBooleanAttr(),
    redCarpetMode: new OneOf(RedCarpetMode),
  },
  import_markers_as_comments: {
    value: makeBooleanAttr(),
    redCarpetMode: new OneOf(RedCarpetMode),
  },
  import_comments: {
    redCarpetMode: new OneOf(RedCarpetMode),
  },
  select_import_comments: {
    redCarpetMode: new OneOf(RedCarpetMode),
  },
  upload_active_sequence_complete: undefined,
  open_replay_extension: {redCarpetMode: new OneOf(RedCarpetMode)},
  attempt_premiere_device_flow_sign_in: undefined,
  succeed_premiere_device_flow_sign_in: undefined,
  attempt_premiere_sign_in: undefined,
  succeed_premiere_sign_in: undefined,

  select_fcp_download: undefined,
  select_protools_download: undefined,
  select_fcp_help_url: undefined,
  select_protools_help_url: undefined,

  //After Effects integration
  open_after_effects_extension: undefined,
  select_upload_active_composition: {
    click_source: new OneOf(UploadActiveSequenceClickSource),
  },
  select_upload_queued_compositions: {
    click_source: new OneOf(['add_files_menu']),
  },
  upload_active_composition_complete: undefined,
  upload_queued_compositions_complete: undefined,
  select_upload_composition_settings: {
    settings_type: new OneOf(SettingsType),
  },
  select_upload_composition_confirm: undefined,

  // Quick Reactions
  select_quick_reaction: undefined,
  select_delete_quick_reaction: undefined,

  // Suggested items
  hide_suggested_items: undefined,
  select_import_suggested_item: {
    position_in_suggestions: new NumberValue(),
    media_type: new OneOf(LoggingMediaType),
  },
  succeed_import_suggested_item: {
    position_in_suggestions: new NumberValue(),
    media_type: new OneOf(LoggingMediaType),
  },
  fail_import_suggested_item: {
    position_in_suggestions: new NumberValue(),
    media_type: new OneOf(LoggingMediaType),
  },

  // Links Hub Actions
  select_toggle_link: {
    enabled_state: new OneOf(LinkEnabledState),
  },
  succeed_view_shared_links: {
    total_num_links: new OneOf(TotalNumLinkRanges),
  },

  // Due Date Actions
  select_open_due_date: {
    source: new OneOf(DueDateModalSource),
  },
  add_due_date: undefined,
  edit_due_date: undefined,
  remove_due_date: undefined,

  // archive to Dropbox
  select_archive_to_dropbox: {
    media_count: new NumberValue(),
  },
  shown_archive_to_dropbox_chooser: undefined,

  // provisions
  'shown.alert_banner': {
    message_type: new OneOf([
      'file_limit_info',
      'over_file_limit',
      'near_storage_limit',
      'over_storage_limit',
      'migration_info',
    ]),
    cta_location: new OneOf(CtaLocation),
  },

  'select.cta_link': {
    link_type: new OneOf(['view_plans', 'purchase_addon', 'migration_learn_more', 'buy_replay']),
    cta_location: new OneOf(CtaLocation),
  },
  'shown.storage': {
    location: new OneOf(['account_menu']),
  },
  'select.storage': {
    location: new OneOf(['account_menu']),
  },

  // Beta banner
  select_beta_banner_learn_more: undefined,

  // Settings
  toggle_setting: {
    setting_name: new OneOf(ToggleSettings),
    new_value: new OneOf(SettingToggleState),
  },

  // Transcriptions
  search_transcription: {
    is_owner: makeBooleanAttr(),
  },
  select_transcription_match_navigation: {
    is_owner: makeBooleanAttr(),
  },

  downloaded_transcription: {
    type_of_transcription_file: new OneOf(CaptionFileType),
  },
  toggle_sidebar_state: {showing: new OneOf(SidebarState)},
  copied_transcript: undefined,
  transcription_error: undefined,
  transcription_complete: undefined,

  // Onboarding v2
  shown_onboarding_welcome_modal: {
    onboarding_type: new OneOf(OnboardingType),
  },
  dismiss_onboarding_welcome_modal: {
    onboarding_type: new OneOf(OnboardingType),
  },
  start_onboarding_welcome_modal: {
    onboarding_type: new OneOf(OnboardingType),
  },
  complete_onboarding_step: {
    num_steps_completed: new NumberValue(),
    onboarding_type: new OneOf(OnboardingType),
    percent_completed: new NumberValue(),
    step_completed: new OneOf(OnboardingStep),
  },
  complete_onboarding: {
    onboarding_type: new OneOf(OnboardingType),
    step_completed: new OneOf(OnboardingStep),
  },
  shown_onboarding_checklist: {
    current_step: new OneOf(OnboardingStepOrNone),
    num_steps_completed: new NumberValue(),
    onboarding_type: new OneOf(OnboardingType),
    percent_completed: new NumberValue(),
    source: new OneOf(OnboardingOpenSource),
  },
  click_close_onboarding_checklist: {
    current_step: new OneOf(OnboardingStepOrNone),
    num_steps_completed: new NumberValue(),
    onboarding_type: new OneOf(OnboardingType),
    percent_completed: new NumberValue(),
  },

  // PDF related
  select_sidebar_preview_icon: {
    show: makeBooleanAttr(),
    media_type: new OneOf(LoggingMediaType),
  },
  select_page_navigation: {
    page_num: new NumberValue(),
    click_source: new OneOf(MediaViewerControlClickSource),
    media_type: new OneOf(LoggingMediaType),
  },
  failed_pdf_upload: undefined,

  // Single Purchase URL
  shown_replay_single_purchase_page: {
    cta_location: new OneOf(CtaLocation),
    user_variant: new OneOf(ProvisionUserVariant),
    sku: new __DangerousStringValue(),
    target_user_sku: new __DangerousStringValue(),
  },

  // In-app Purchase Page
  navigate_to_replay_in_app_purchase_page: {
    cta_location: new OneOf(CtaLocation),
    user_variant: new OneOf(ProvisionUserVariant),
    sku: new __DangerousStringValue(),
    target_user_sku: new __DangerousStringValue(),
    replay_plan: new OneOf(ReplayPlan),
  },
  shown_replay_in_app_purchase_page: {
    cta_location: new OneOf(CtaLocation),
    user_variant: new OneOf(ProvisionUserVariant),
    sku: new __DangerousStringValue(),
    target_user_sku: new __DangerousStringValue(),
    replay_plan: new OneOf(ReplayPlan),
  },
  select_replay_in_app_purchase_button: {
    cta_location: new OneOf(CtaLocation),
    user_variant: new OneOf(ProvisionUserVariant),
    sku: new __DangerousStringValue(),
    target_user_sku: new __DangerousStringValue(),
    replay_plan: new OneOf(ReplayPlan),
  },
  upgrade_replay_plan: {
    cta_location: new OneOf(CtaLocation),
    user_variant: new OneOf(ProvisionUserVariant),
    sku: new __DangerousStringValue(),
    target_user_sku: new __DangerousStringValue(),
    event_state: new OneOf(EventState),
    number_of_replay_licenses: new NumberValue(),
    replay_purchase_location: new OneOf(ReplayPurchaseLocation),
    replay_plan: new OneOf(ReplayPlan),
  },
  navigate_to_fss_replay_purchase_flow: {
    cta_location: new OneOf(CtaLocation),
    user_variant: new OneOf(ProvisionUserVariant),
    sku: new __DangerousStringValue(),
    target_user_sku: new __DangerousStringValue(),
    replay_fss_redirect_reason: new OneOf(FssRedirectReason),
    replay_plan: new OneOf(ReplayPlan),
  },
  shown_expired_payment_method: {
    cta_location: new OneOf(CtaLocation),
    user_variant: new OneOf(ProvisionUserVariant),
    sku: new __DangerousStringValue(),
    target_user_sku: new __DangerousStringValue(),
  },
  select_update_payment_method_button: {
    cta_location: new OneOf(CtaLocation),
    user_variant: new OneOf(ProvisionUserVariant),
    sku: new __DangerousStringValue(),
    target_user_sku: new __DangerousStringValue(),
  },
};

type ArgOptions<E extends keyof typeof EventAttributesMap> = {
  [K in keyof (typeof EventAttributesMap)[E]]: (typeof EventAttributesMap)[E][K];
};

// We need this to trick TypeScript into letting us access the typeExemplar
// property on the OneOf and NumberValue classes
type HasExemplar = {
  typeExemplar: string | number;
};

export type AttributeArg<E extends keyof typeof EventAttributesMap> = {
  [K in keyof ArgOptions<E>]: ArgOptions<E>[K] extends HasExemplar
    ? ArgOptions<E>[K]['typeExemplar']
    : undefined;
};
