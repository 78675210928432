import type {IntlShape} from 'react-intl';

export const getRestrictedContentErrorTextFromType = (
  intl: IntlShape,
  type:
    | 'sharing_is_restricted'
    | 'rate_limit_reached'
    | 'dmca_requester'
    | 'email_not_verified'
    | 'users_that_conflict_with_team_policies'
    | 'recipients_conflict_with_team_policies',
  dmca_requester: string | undefined,
) => {
  switch (type) {
    case 'sharing_is_restricted': {
      return intl.formatMessage({
        defaultMessage: 'Sharing is restricted at this time, please contact support for help.',
        id: 'VxocF+',
        description:
          'A message that is when a user issues a sharing attempt but their sharing is banned or the file is taken down',
      });
    }
    case 'rate_limit_reached': {
      return intl.formatMessage({
        defaultMessage:
          'You have reached your limit for sharing in the past 24 hours, please contact support for help.',
        id: 'EcJhnl',
        description:
          'A message that is when a user issues a sharing attempt but they have reached rate limits',
      });
    }
    case 'dmca_requester': {
      return intl.formatMessage(
        {
          defaultMessage:
            "The content you're attempting to share was restricted under DMCA after notification from: {requester}",
          id: '4WRGtw',
          description:
            'A message that is when a user issues a sharing attempt but they have reached rate limits',
        },
        {
          requester: dmca_requester,
        },
      );
    }
    case 'email_not_verified': {
      return intl.formatMessage({
        defaultMessage: 'Verify the email for your Dropbox account to share files.',
        id: '4mjaaE',
        description:
          'A snackbar message that tells users why their attempt at sharing content, either by creating a shared link or by addming members to a project, has failed',
      });
    }
    case 'users_that_conflict_with_team_policies': {
      return intl.formatMessage({
        defaultMessage:
          'Admin has turned off adding people outside team. Contact admin to get approval.',
        id: 'p4uEU1',
        description:
          'A snackbar message for when a user tries to add people to a project but some of the people they are trying to add are restricted by admin policies',
      });
    }
    case 'recipients_conflict_with_team_policies': {
      return intl.formatMessage({
        defaultMessage: 'Admin has turned off sharing outside team. Contact admin to get approval.',
        id: '0kJYY1',
        description:
          'A message for when a user tries to share content through email but some of the people they are trying to share with are restricted by admin policies',
      });
    }
  }
};
