import React from 'react';

import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';

import {Text} from '@dropbox//dig-components/typography';
import {UIIcon} from '@dropbox/dig-icons';
import {
  AudioLine,
  CloudOfflineLine,
  ImageLine,
  PlayLine,
  SpaceFullLine,
  UnlinkLine,
} from '@dropbox/dig-icons/assets';

import {AudioWaveformThumbnail} from '~/components/audio_waveform_thumbnail';
import {StaticSkeletonRectangle} from '~/components/skeleton';
import {MediaType} from '~/lib/api';

const MissingMediaMessage = () => (
  <FormattedMessage
    defaultMessage="Missing media"
    description="Text that appears when the thumbnail preview on a project tile that is not found"
    id="3X1lJ8"
  />
);

const Poster = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ContainPosterWrapperOuter = styled.div`
  height: 100%;
  position: absolute;
  width: 100%;
`;

const ContainPosterWrapperInner = styled.div<{$variant?: 'standard' | 'small'}>`
  position: absolute;
  ${({$variant}) => {
    if ($variant === 'standard') {
      return `
        top: 8px;
        right: 8px;
        bottom: 8px;
        left: 8px;
      `;
    } else {
      return `
        height: 100%;
        width: 100%;
      `;
    }
  }}
`;

const ContainPoster = styled.img`
  height: 100%;
  object-fit: contain;
  width: 100%;
`;

const StyledAudioPoster = styled.div<{$variant?: 'standard' | 'small'}>`
  position: absolute;
  background: ${({$variant}) => ($variant === 'standard' ? '#242121' : '#1e1919')};
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const PosterError = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  color: var(--dig-color__text__base);
  background-color: var(--dig-color__disabled__surface);
`;

const VideoPosterNonMemo = ({
  src,
  loading,
  linksPage,
}: {
  src?: string;
  loading?: boolean;
  linksPage?: boolean;
}) => {
  const [error, setError] = React.useState<boolean>(false);

  if (loading && linksPage) {
    return (
      <PosterError>
        <StaticSkeletonRectangle>
          <UIIcon color="#777471" size="standard" src={PlayLine} />
        </StaticSkeletonRectangle>
      </PosterError>
    );
  }
  if (!src || error) {
    return (
      <PosterError>
        {linksPage ? (
          <StaticSkeletonRectangle>
            <UIIcon color="#777471" size="standard" src={UnlinkLine} />
          </StaticSkeletonRectangle>
        ) : (
          <>
            <UIIcon size="large" src={CloudOfflineLine} />
            <Text size="small" variant="label">
              <MissingMediaMessage />
            </Text>
          </>
        )}
      </PosterError>
    );
  }

  return (
    <Poster
      alt=""
      draggable={false}
      onError={() => {
        setError(true);
      }}
      src={src}
    />
  );
};

const AudioPosterNonMemo = ({
  variant,
  waveformUrl,
  loading,
  linksPage,
}: {
  variant?: 'standard' | 'small';
  waveformUrl?: string;
  loading?: boolean;
  linksPage?: boolean;
}) => {
  if (loading) {
    return (
      <PosterError>
        <StaticSkeletonRectangle>
          <UIIcon color="#777471" size="standard" src={AudioLine} />
        </StaticSkeletonRectangle>
      </PosterError>
    );
  }
  if (!waveformUrl) {
    return (
      <PosterError>
        {linksPage ? (
          <StaticSkeletonRectangle>
            <UIIcon color="#777471" size="standard" src={UnlinkLine} />
          </StaticSkeletonRectangle>
        ) : (
          <>
            <UIIcon size="large" src={SpaceFullLine} />
            <Text size="small" variant="label">
              <MissingMediaMessage />
            </Text>
          </>
        )}
      </PosterError>
    );
  }

  return (
    <StyledAudioPoster $variant={variant} draggable={false}>
      <AudioWaveformThumbnail
        height={variant === 'small' ? 33 : undefined}
        waveformUrl={waveformUrl}
        width={variant === 'small' ? 50 : undefined}
      />
    </StyledAudioPoster>
  );
};

const ImagePosterNonMemo = ({
  src,
  variant = 'standard',
  loading,
  linksPage,
}: {
  src?: string;
  variant?: 'standard' | 'small';
  loading?: boolean;
  linksPage?: boolean;
}) => {
  const [error, setError] = React.useState<boolean>(false);

  if (loading) {
    return (
      <PosterError>
        <StaticSkeletonRectangle>
          <UIIcon color="#777471" size="standard" src={ImageLine} />
        </StaticSkeletonRectangle>
      </PosterError>
    );
  }

  if (!src || error) {
    return (
      <PosterError>
        {linksPage ? (
          <StaticSkeletonRectangle>
            <UIIcon color="#777471" size="standard" src={UnlinkLine} />
          </StaticSkeletonRectangle>
        ) : (
          <>
            <UIIcon size="large" src={CloudOfflineLine} />
            <Text size="small" variant="label">
              <MissingMediaMessage />
            </Text>
          </>
        )}
      </PosterError>
    );
  }

  return (
    <ContainPosterWrapperOuter>
      <ContainPosterWrapperInner $variant={variant}>
        <ContainPoster
          alt=""
          draggable={false}
          onError={() => {
            setError(true);
          }}
          src={src}
        />
      </ContainPosterWrapperInner>
    </ContainPosterWrapperOuter>
  );
};

export const VideoPoster = React.memo(VideoPosterNonMemo);
export const AudioPoster = React.memo(AudioPosterNonMemo);
export const ImagePoster = React.memo(ImagePosterNonMemo);

export const MediaPoster = (props: {mediaType: MediaType; src?: string}) => {
  const {mediaType, src} = props;

  if (mediaType === MediaType.Video) {
    return <VideoPoster src={src} />;
  }

  if (mediaType === MediaType.Audio) {
    return <AudioPoster waveformUrl={src} />;
  }

  return <ImagePoster src={src} />;
};
