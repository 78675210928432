import React from 'react';

import {useStateIfMounted} from '~/lib/use-mounted';

const BAR_SPACING = 1;
const BAR_WIDTH = 1;
const MAX_BAR_HEIGHT = 0.85;
const SCRUBBED_BAR_BG = '#3984ff'; // color_core_accent
const SCRUBBED_BG = 'rgba(27, 39, 51, 0.08)';
const UNSCRUBBED_BAR_BG = '#d8d3cb'; //color_disabled_background

interface AudioWaveformViewProps {
  height?: number;
  onMouseDown?: (event: MouseEvent, elementPercentage: number) => void;
  onMouseMove?: (event: MouseEvent, elementPercentage: number) => void;
  onMouseUp?: (event?: MouseEvent, elementPercentage?: number) => void;
  scrubPercent?: number;
  width?: number;
  waveformHeights: number[];
}

export class AudioWaveformView extends React.Component<AudioWaveformViewProps, {}> {
  private waveformCanvas: HTMLCanvasElement | undefined;

  static defaultProps = {
    scrubPercent: 0,
  };

  componentDidMount() {
    this.drawWaveform();
    document.addEventListener('mouseup', this.handleMouseUp);
    document.addEventListener('mousemove', this.handleMouseMove);
  }

  componentDidUpdate() {
    this.drawWaveform();
  }

  componentWillUnmount() {
    document.removeEventListener('mouseup', this.handleMouseUp);
    document.removeEventListener('mousemove', this.handleMouseMove);
  }

  private drawWaveform() {
    const {waveformCanvas: canvas} = this;
    if (!canvas) {
      return;
    }

    const context = canvas.getContext('2d');
    if (!context) {
      return;
    }

    const {height, scrubPercent, width} = this.props;
    if (!height || !width) {
      return;
    }
    const bars = this.generateWaveformHeights(width);

    // Scale this properly (specifically for HiDPI screens)
    const scale = window.devicePixelRatio || 1;
    canvas.width = width * scale;
    canvas.height = height * scale;
    context.scale(scale, scale);

    // Wipe bg
    context.globalAlpha = 0;
    context.fillStyle = 'black';
    context.fillRect(0, 0, width, height);
    context.globalAlpha = 1;

    const scrubWidth = scrubPercent! * width;
    let i = 0;
    if (scrubWidth) {
      // Draw scrubbed bg
      context.fillStyle = SCRUBBED_BG;
      context.fillRect(0, 0, scrubWidth, height);

      // Draw scrubbed bars
      context.fillStyle = SCRUBBED_BAR_BG;
      for (; i < bars.length; i++) {
        const x = i * (BAR_WIDTH + BAR_SPACING);
        const pct = bars[i];
        const h = pct * height;
        const y = (1 - pct) * height;
        // Intersection
        if (x + BAR_WIDTH >= scrubWidth) {
          context.globalAlpha = 1 - (x + BAR_WIDTH - scrubWidth) / 4;
          context.fillRect(
            i * (BAR_WIDTH + BAR_SPACING),
            (1 - pct) * height,
            BAR_WIDTH,
            pct * height,
          );
          i++;
          break;
        } else {
          context.fillRect(x, y, BAR_WIDTH, h);
        }
      }
    }

    context.globalAlpha = 0.3;
    context.fillStyle = UNSCRUBBED_BAR_BG;
    // Draw the rest of unscrubbed bars
    for (; i < bars.length; i++) {
      const pct = bars[i];
      context.fillRect(i * (BAR_WIDTH + BAR_SPACING), (1 - pct) * height, BAR_WIDTH, pct * height);
    }
  }

  private generateWaveformHeights(width: number) {
    const numBars = Math.floor((width - BAR_SPACING) / (BAR_WIDTH + BAR_SPACING));
    return returnWaveform(this.props.waveformHeights, numBars, MAX_BAR_HEIGHT);
  }

  private getPositionPercentageFromEvent(event: MouseEvent) {
    // Returns percentage as 0.754 vs. 75.4
    const divRectangle = this.waveformCanvas!.getBoundingClientRect();
    const seekPosition = event.pageX - divRectangle.left;
    return seekPosition / divRectangle.width;
  }

  handleMouseMove = (event: MouseEvent) => {
    if (this.props.onMouseMove) {
      this.props.onMouseMove(event, this.getPositionPercentageFromEvent(event));
    }
  };

  handleMouseDown = (event: React.MouseEvent<HTMLElement>) => {
    const nativeEvent = event.nativeEvent;
    if (nativeEvent.button === 0 && this.props.onMouseDown) {
      this.props.onMouseDown(nativeEvent, this.getPositionPercentageFromEvent(nativeEvent));
    }
  };

  handleMouseUp = (event: MouseEvent) => {
    if (this.props.onMouseUp) {
      this.props.onMouseUp(event, this.getPositionPercentageFromEvent(event));
    }
  };

  render() {
    return (
      <div onMouseDown={this.handleMouseDown} style={{width: '100%', height: '100%'}}>
        <canvas
          className="waveform"
          ref={(c: HTMLCanvasElement) => {
            this.waveformCanvas = c;
          }}
          style={{
            width: 'inherit',
            height: 'inherit',
          }}
        />
      </div>
    );
  }
}

class AverageCounter {
  average: number;
  count: number;

  constructor() {
    this.average = 0;
    this.count = 0;
  }

  getAverage() {
    return this.average;
  }

  add(val: number) {
    this.average = (this.average * this.count + val) / (this.count + 1);
    this.count++;
  }

  addArray(array: number[]) {
    const arraySum = array.reduce((sum, el) => sum + el);
    const newCount = this.count + array.length;
    this.average = (this.average * this.count + arraySum) / newCount;
    this.count = newCount;
  }
}

export function returnWaveform(
  rawWaveformBars: number[],
  length: number,
  maxHeight: number = 1,
): number[] {
  const waveform: number[] = [];
  if (rawWaveformBars.length === 0 || length <= 0 || isNaN(length)) {
    return waveform;
  }

  const averageCounterArray: AverageCounter[] = [];
  for (let i = 0; i < length; i++) {
    averageCounterArray.push(new AverageCounter());
  }

  // Keep track of max waveform height
  let currentMaxHeight = 0;
  let previousBar = 0;
  for (let i = 0; i < rawWaveformBars.length; i++) {
    const waveHeight = rawWaveformBars[i];
    const bar = Math.floor((i * length) / rawWaveformBars.length);
    const averageCounter = averageCounterArray[bar];
    averageCounter.add(waveHeight);
    waveform[bar] = averageCounter.getAverage();

    // If finished computing bar average, compare to previous max height
    if (bar !== previousBar) {
      currentMaxHeight = Math.max(currentMaxHeight, waveform[previousBar]);
      previousBar = bar;
    }
  }

  // Capture last bar
  currentMaxHeight = Math.max(currentMaxHeight, waveform[length - 1]);

  // Scale the waveform heights to maxHeight
  return waveform.map((bar) => (bar * maxHeight) / currentMaxHeight);
}

export const AudioWaveformThumbnail = (props: {
  waveformUrl: string;
  height?: number;
  width?: number;
}) => {
  const [waveformHeights, setWaveFormHeights] = useStateIfMounted<number[]>([]);

  React.useEffect(() => {
    const request = new XMLHttpRequest();
    request.open('GET', props.waveformUrl);
    request.setRequestHeader('Content-Type', 'application/json; charset=utf-8');

    // Need a timeout in case the connection is closed while the machine is suspended.
    request.timeout = 120000;
    request.withCredentials = false;
    request.responseType = 'json';

    const onSuccess = () => {
      const {status} = request;
      if ((status >= 200 && status < 300) || status === 304) {
        const result = request.response;
        const heights = result.waveformHeights;
        setWaveFormHeights(heights);
      }
    };
    const onError = () => {};
    request.addEventListener('load', onSuccess);
    request.addEventListener('timeout', onError);
    request.addEventListener('error', onError);
    request.addEventListener('abort', onError);
    request.send();

    return () => {
      request.abort();
    };
  }, [props.waveformUrl, setWaveFormHeights]);

  if (waveformHeights) {
    return (
      <AudioWaveformView
        height={props.height || 108}
        scrubPercent={100}
        waveformHeights={waveformHeights}
        width={props.width || 192}
      />
    );
  }
  return null;
};
