import React from 'react';

import {PAP_Select_ModalAction} from 'pap-events/replay/select_modal_action';
import {defineMessages, FormattedMessage, useIntl} from 'react-intl';
import styled, {css} from 'styled-components';

import {Spinner} from '@dropbox/dig-components/progress_indicators';
import {Text} from '@dropbox/dig-components/typography';
import {Box} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {NoFillLine, ReplaceImageLine} from '@dropbox/dig-icons/assets';

import {useErrorSnackbar} from '~/components/snackbar/error_snackbar';
import {radius, spacing} from '~/components/styled';
import {useUpload} from '~/components/upload_context';
import {useFolderNsIdQuery} from '~/lib/api_queries';
import type {BrandingLogo, BrandingLogoVariant} from '~/lib/branding';
import {useLoggingClient} from '~/lib/use_logging_client';
import {BrandingLogoIcon} from '~/pages/browse_page/components/branding/branding_logo_icon';
import {
  customButtonStyles,
  OptionButton,
  OptionModal,
} from '~/pages/browse_page/components/branding/option_modal';

const LogoGrid = styled(Box)`
  display: grid;
  gap: ${spacing('Macro Small')};
  grid-template-columns: repeat(4, 1fr);
`;

const LogoOptionButton = styled(OptionButton)`
  aspect-ratio: 1;

  svg {
    border-radius: ${radius('Medium')};
    width: 100%;
    height: 100%;
  }
`;

interface TextButtonProps {
  $backgroundUrl?: string;
  $isBackgroundLoading?: boolean;
}

const TextButton = styled(OptionButton)<TextButtonProps>`
  aspect-ratio: 1;

  ${({$backgroundUrl, $isBackgroundLoading}) =>
    $backgroundUrl &&
    css`
      background: url(${$backgroundUrl}) #ffffff center / cover no-repeat;

      ${$isBackgroundLoading
        ? css`
            opacity: 0.5;
          `
        : css`
            ${customButtonStyles}
          `}
    `}
`;

export interface BrandingLogoModalProps {
  open: boolean;
  folderId: string;
  logo: BrandingLogo;
  onLogoSave: (logo: BrandingLogo) => Promise<void>;
  onClose: () => void;
}

type LogoValue = BrandingLogoVariant | 'custom';

export const BrandingLogoModal = ({
  open,
  logo,
  folderId,
  onLogoSave,
  onClose,
}: BrandingLogoModalProps) => {
  const intl = useIntl();
  const nsIdQuery = useFolderNsIdQuery(folderId);
  const nsId = nsIdQuery.data;
  const {openErrorSnackbar} = useErrorSnackbar();
  const loggingClient = useLoggingClient();

  const [isUploadingLogo, setIsUploadingLogo] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [selected, setSelected] = React.useState<LogoValue>(
    logo.type === 'default' ? logo.variant : 'custom',
  );
  const [logoUrl, setLogoUrl] = React.useState<string | undefined>(
    'url' in logo ? logo.url : undefined,
  );
  const [logoFileId, setLogoFileId] = React.useState<string | undefined>(
    'file_id' in logo ? logo.file_id : undefined,
  );
  const uploadContext = useUpload();

  const handleClose = () => {
    loggingClient.logPap(PAP_Select_ModalAction({modal: 'logo', modalAction: 'cancel'}));
    onClose();
  };

  const handleSave = async () => {
    loggingClient.logPap(PAP_Select_ModalAction({modal: 'logo', modalAction: 'save'}));
    setIsSubmitting(true);
    try {
      if (selected === 'custom' && logoFileId) {
        await onLogoSave({
          type: 'custom',
          file_id: logoFileId,
        });
      } else if (selected !== 'custom') {
        await onLogoSave({
          ...logo,
          type: 'default',
          variant: selected as BrandingLogoVariant,
        });
      }
    } catch (error) {
      openErrorSnackbar(
        intl.formatMessage({
          defaultMessage: 'Failed to update your logo.',
          id: 'r+eMbK',
          description: 'Error message when failing to update a project logo',
        }),
      );
    }

    setIsSubmitting(false);
  };

  const handleCustomLogoClick = () => {
    setSelected('custom');
    uploadContext.chooseFiles({
      uploadType: 'logo',
      onFilesPick: async (fileList: FileList) => {
        const file = fileList.length === 1 ? fileList[0] : null;
        if (!file || !nsId) {
          return;
        }
        setIsUploadingLogo(true);

        uploadContext.uploadLogo({
          file,
          nsId,
          onFileUpload: async (fileId) => {
            setLogoUrl(URL.createObjectURL(file));
            setLogoFileId(fileId);
            setIsUploadingLogo(false);
          },
          onFileError: () => {
            setIsUploadingLogo(false);
            openErrorSnackbar(
              intl.formatMessage({
                defaultMessage: "Couldn't upload logo",
                id: 'iJEanr',
                description: 'Error message when a uploading a logo fails.',
              }),
            );
          },
        });
      },
    });
  };

  const buttonProps = (value: LogoValue) => ({
    role: 'option',
    disabled: isSubmitting,
    'aria-selected': selected === value,
    onClick: () => {
      setSelected(value);
    },
  });

  return (
    <OptionModal
      id="branding-logo-modal-title"
      isSaveDisabled={isUploadingLogo}
      isSubmitting={isSubmitting}
      onRequestClose={handleClose}
      onSave={handleSave}
      open={open}
      title={intl.formatMessage(modalStrings.modalHeader)}
    >
      <LogoGrid role="listbox">
        <LogoOptionButton
          aria-label={intl.formatMessage(modalStrings.reelButtonLabel)}
          {...buttonProps('reel')}
        >
          <BrandingLogoIcon variant="reel" />
        </LogoOptionButton>
        <LogoOptionButton
          aria-label={intl.formatMessage(modalStrings.chairButtonLabel)}
          {...buttonProps('director')}
        >
          <BrandingLogoIcon variant="director" />
        </LogoOptionButton>
        <LogoOptionButton
          aria-label={intl.formatMessage(modalStrings.slateButtonLabel)}
          {...buttonProps('slate')}
        >
          <BrandingLogoIcon variant="slate" />
        </LogoOptionButton>
        <LogoOptionButton
          aria-label={intl.formatMessage(modalStrings.cameraButtonLabel)}
          {...buttonProps('camera')}
        >
          <BrandingLogoIcon variant="camera" />
        </LogoOptionButton>

        {(logoUrl || isUploadingLogo) && (
          <TextButton
            aria-labelledby="upload-modal-custom-title"
            {...buttonProps('custom')}
            $backgroundUrl={logoUrl}
            $isBackgroundLoading={isUploadingLogo}
          >
            {isUploadingLogo && (
              <Spinner
                aria-valuetext={intl.formatMessage({
                  defaultMessage: 'Uploading logo',
                  id: 'zIHciE',
                  description: 'Aria label for spinner when uploading a logo',
                })}
              />
            )}
          </TextButton>
        )}

        <TextButton
          aria-labelledby="upload-modal-custom-title"
          {...buttonProps('custom')}
          aria-selected={false}
          disabled={nsId === undefined || isUploadingLogo}
          onClick={handleCustomLogoClick}
        >
          <UIIcon size="standard" src={ReplaceImageLine} />
          <Text color="inherit" id="upload-modal-custom-title">
            <FormattedMessage {...modalStrings.uploadButton} />
          </Text>
        </TextButton>

        <TextButton aria-labelledby="upload-modal-none-title" {...buttonProps('none')}>
          <UIIcon size="standard" src={NoFillLine} />
          <Text id="upload-modal-none-title">
            <FormattedMessage {...modalStrings.unsetLogoButton} />
          </Text>
        </TextButton>
      </LogoGrid>
    </OptionModal>
  );
};

const modalStrings = defineMessages({
  modalHeader: {
    defaultMessage: 'Choose a logo for this project',
    description: 'Copy for modal header to change logo',
    id: '7sqnZ8',
  },
  reelButtonLabel: {
    defaultMessage: 'Film reel',
    description: 'Label for selecting a film reel for a project logo',
    id: 'Vv8foG',
  },
  chairButtonLabel: {
    defaultMessage: 'Director chair',
    description: 'Label for selecting a director chair for a project logo',
    id: 'MKaAnr',
  },
  slateButtonLabel: {
    defaultMessage: 'Film slate',
    description: 'Label for selecting a film slate for a project logo',
    id: 'bhw9ZB',
  },
  cameraButtonLabel: {
    defaultMessage: 'Camera',
    description: 'Label for selecting a camera for a project logo',
    id: 'NAQizW',
  },
  uploadButton: {
    defaultMessage: 'Choose',
    description: 'Button copy for option to choose the uploaded file logo',
    id: 'HFBOH+',
  },
  unsetLogoButton: {
    defaultMessage: 'None',
    description: 'Button copy for setting logo to none',
    id: 'ELa4/2',
  },
});
