import type {ForwardedRef} from 'react';
import React from 'react';

import styled from 'styled-components';

import type {TextInputProps, TextInputRefObject} from '@dropbox/dig-components/text_fields';
import {TextInput} from '@dropbox/dig-components/text_fields';

export type ReplayTextInputProps = {
  inputElementId?: string;
  onEnter?: () => void;
  fontSize?: number;
} & TextInputProps &
  React.InputHTMLAttributes<HTMLInputElement>;

const StyledTextInput = styled(TextInput)<{$fontSize?: number}>`
  &&& {
    ${(props) => (props.$fontSize ? `font-size: ${props.$fontSize}px;` : '')}
  }
`;

/**
 * Wrapped version of DIG text input that focuses the input on mount and exposes an onEnter prop.
 */
export const ReplayTextInput = React.forwardRef(
  (props: ReplayTextInputProps, ref: ForwardedRef<TextInputRefObject>) => {
    const {onEnter, onFocus, onKeyDown, ...rest} = props;

    const handleKeyDown = React.useCallback(
      (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (onKeyDown) {
          onKeyDown(e);
        }
        if (onEnter && e.key === 'Enter') {
          onEnter();
        }
      },
      [onEnter, onKeyDown],
    );

    const handleFocus = React.useCallback(
      (e: React.FocusEvent<HTMLInputElement>) => {
        if (onFocus) {
          onFocus(e);
        }
        e.target.select();
      },
      [onFocus],
    );

    return (
      <StyledTextInput
        autoFocus
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        ref={ref}
        {...rest}
      />
    );
  },
);
