import React, {useState} from 'react';

import {defineMessages, FormattedMessage, useIntl} from 'react-intl';
import styled, {css} from 'styled-components';

import {Text} from '@dropbox/dig-components/typography';
import {Box, type BoxProps} from '@dropbox/dig-foundations';
import {PictogramIcon, SpotIcon} from '@dropbox/dig-icons';
import {CloudUploadLine, CloudUploadPictogram} from '@dropbox/dig-icons/assets';
import {PeacefulFloatSpot} from '@dropbox/dig-illustrations/spot/peaceful-float';

import {useDropboxChooser} from '~/components/dropbox_chooser_context';
import {Loader} from '~/components/loading';
import {breakpointSmall, color, colorTransition, spacing} from '~/components/styled';
import type {ShareFolderAccessType} from '~/lib/api';
import type {DirectUpload} from '~/lib/uploads/types';
import {UPLOAD_IN_PROGRESS_STATES} from '~/lib/uploads/types';

import type {EditActionsDeps} from '../../browse_items_view_context';
import type {NavigationRouteTypes} from '../common';
import {MediaAdder} from '../media_adder';

const Wrapper = styled(Box)`
  margin-top: 0;

  ${breakpointSmall(css`
    margin-top: ${spacing('116')};
  `)}
`;

interface EmptyPageWrapperProps extends BoxProps<'div'> {
  $dragOver: boolean;
}
const EmptyPageWrapper = styled(Box)<EmptyPageWrapperProps>`
  border-style: dashed;
  max-width: calc(${spacing('Macro XLarge')} * 12);
  padding-bottom: ${spacing('60')};
  padding-top: ${spacing('52')};

  ${breakpointSmall(css`
    padding-bottom: ${spacing('76')};
    padding-top: ${spacing('60')};
  `)}

  ${(props) =>
    props.$dragOver &&
    css`
      background-color: ${color('Primary Base')};
      transition: ${colorTransition('background')};
      border: 2px dashed ${color('Text Base')};
    `};
`;

const EmptyShareWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

const ShareTextWrapper = styled.div`
  display: inline-block;
`;

const SpotWrapper = styled.div`
  width: 300px;
  height: 300px;
`;

const LoadingSpinner = styled.div`
  padding-top: 24px;
`;

type EmptyProjectListProps = {
  currentFolderId: string;
  currentPermission: ShareFolderAccessType;
  editActionsDeps: EditActionsDeps;
  level: number;
  routeName: NavigationRouteTypes;
};
const FloatingSurfboardIlloPlaceholder = ({subtitle}: {subtitle: React.ReactNode}) => (
  <EmptyShareWrapper>
    <SpotWrapper>
      <PeacefulFloatSpot />
    </SpotWrapper>
    <ShareTextWrapper>
      <Text>{subtitle}</Text>
    </ShareTextWrapper>
  </EmptyShareWrapper>
);

export const EmptyProjectList = ({
  currentFolderId,
  currentPermission,
  editActionsDeps,
  level,
  routeName,
}: EmptyProjectListProps) => {
  const [dragOver, setDragOver] = useState(false);
  const handleDragOverStart = () => setDragOver(true);
  const handleDragOverEnd = () => setDragOver(false);

  const {uploadState} = useDropboxChooser();
  const intl = useIntl();

  if (editActionsDeps.editActionsEnabled === false) {
    // The user is viewing either an empty folder they cannot add to, either
    // because they are a reviewer on the parent project or because they are
    // viewing via a shared link
    return (
      <FloatingSurfboardIlloPlaceholder
        subtitle={<FormattedMessage {...intlMessages.emptyFolderMessage} />}
      />
    );
  }

  if (routeName === 'browse-shared' && currentPermission === 'none') {
    // The user is viewing the top-level Shared page and there are no
    // videos listed
    return (
      <FloatingSurfboardIlloPlaceholder
        subtitle={<FormattedMessage {...intlMessages.emptySharedMessage} />}
      />
    );
  }

  return (
    <Wrapper alignItems="center" display="flex" justifyContent="center">
      <EmptyPageWrapper
        $dragOver={dragOver}
        alignItems="center"
        borderColor="Border Subtle"
        borderRadius="Medium"
        color="Text Base"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        marginTop="16"
        onDragEnter={handleDragOverStart}
        onDragLeave={handleDragOverEnd}
        width="100%"
      >
        {UPLOAD_IN_PROGRESS_STATES.includes(uploadState) ? (
          <LoadingSpinner>
            <Loader />
          </LoadingSpinner>
        ) : (
          <>
            {dragOver ? (
              <Box textAlign="center">
                <SpotIcon src={CloudUploadPictogram} />
              </Box>
            ) : (
              <>
                <Box textAlign="center">
                  <Text tagName="p">
                    <PictogramIcon src={CloudUploadLine} />
                    <br />
                    <Text isBold>
                      <FormattedMessage {...intlMessages.uploadAreaTitle} />
                    </Text>
                    <br />
                    <FormattedMessage {...intlMessages.uploadAreaOr} />
                  </Text>
                </Box>

                <MediaAdder
                  buttonText={intl.formatMessage(intlMessages.uploadAreaButton)}
                  buttonVariant="transparent"
                  clickSource="empty_state_callout"
                  collapseOnMobile={false}
                  currentFolderDepth={level}
                  currentFolderId={currentFolderId}
                  dropdownPlacement="bottom"
                  folderLevel={level}
                  includeFocusStyles
                  loadingState="disabled"
                  onAddError={editActionsDeps.onAddVideoError}
                  onAddFromDropbox={async (files) => {
                    await editActionsDeps.handleAddFileFromDropbox(
                      files,
                      'empty_state_callout',
                      level,
                    );
                  }}
                  onAllUploadsComplete={editActionsDeps.handleAllUploadsComplete}
                  onFilePick={editActionsDeps.onFilePick}
                  onFileUploadComplete={(
                    {projectId, file: {name}, videoId}: DirectUpload,
                    fileId: string,
                  ) => {
                    editActionsDeps.handleAddFileFromUpload(
                      'empty_state_callout',
                      level,
                      projectId!,
                      videoId!,
                      name,
                    );
                  }}
                  withDropdownIcon
                  withIconLeft={false}
                />
              </>
            )}
          </>
        )}
      </EmptyPageWrapper>
    </Wrapper>
  );
};

const intlMessages = defineMessages({
  uploadAreaTitle: {
    defaultMessage: 'Drop your files here',
    description:
      'Title for area for user to add files. It also informs users they can drag and drop files onto the page to upload.',
    id: '19NUAp',
  },
  uploadAreaOr: {
    defaultMessage: 'or',
    description: 'Word separating title and action to select a file picker source',
    id: 'ftTLgq',
  },
  uploadAreaButton: {
    defaultMessage: 'Upload',
    description:
      'Text for a button that allows you to upload files. It also informs users they can drag and drop files onto the page to upload.',
    id: 'u2ii99',
  },
  emptyFolderMessage: {
    defaultMessage: 'No files have been added to this folder just yet',
    description: 'Message displayed when user is viewing an empty folder',
    id: 'CK3wqv',
  },
  emptySharedMessage: {
    defaultMessage: 'When someone shares a file with you, it will appear here.',
    description: 'Message showing where files will appear',
    id: 'XerswE',
  },
});
