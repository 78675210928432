import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';

import {Text, Title} from '@dropbox/dig-components/typography';
import {UIIcon} from '@dropbox/dig-icons';
import {RotateRightLine} from '@dropbox/dig-icons/assets';
import {LinkBrokenSpot} from '@dropbox/dig-illustrations/spot/link-broken';

import {Button} from '~/components/button';

const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 50px;
`;

const TryAgainButton = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 24px;
`;

const Illustration = styled.div`
  width: 300px;
`;

export const PartialLoadingFailure = () => {
  return (
    <Container>
      <Illustration>
        <LinkBrokenSpot />
      </Illustration>
      <Title size="medium">
        <FormattedMessage
          defaultMessage="Couldn’t load files"
          description="Error message that displays when some of the files in a folder fail to load"
          id="G0VcxR"
        />
      </Title>
      <Text size="large" variant="paragraph">
        <FormattedMessage
          defaultMessage="We weren’t able to load all your files."
          description="Explanation of an error message that displays when some of the files in a folder fail to load"
          id="W3etTQ"
        />
      </Text>
      <Text size="large" variant="paragraph">
        <FormattedMessage
          defaultMessage="Check your internet connection or refresh the page."
          description="Instructions for how to debug an error message that displays when some of the files in a folder fail to load"
          id="FFfnaI"
        />
      </Text>
      <TryAgainButton>
        <Button variant="transparent" withIconStart={<UIIcon src={RotateRightLine} />}>
          <FormattedMessage
            defaultMessage="Try again"
            description="Text for a button that refreshes the page when a folder partially fails to load"
            id="XNwu23"
          />
        </Button>
      </TryAgainButton>
    </Container>
  );
};
