import {useMemo} from 'react';

import {atom, useAtomValue, useSetAtom} from 'jotai';
import {FormattedMessage} from 'react-intl';

import {Snackbar} from '@dropbox/dig-components/snackbar';
import {Text} from '@dropbox/dig-components/typography';
import {UIIcon} from '@dropbox/dig-icons';
import {FailLine} from '@dropbox/dig-icons/assets';

import {Button} from '~/components/button';

import {ReelSnackbar} from './snackbar';

interface ErrorSnackbarAtom {
  isOpen: boolean;
  message?: string;
}

const errorSnackbarAtom = atom<ErrorSnackbarAtom>({
  isOpen: false,
});

export const useErrorSnackbar = () => {
  const setErrorSnackbar = useSetAtom(errorSnackbarAtom);

  return useMemo(() => {
    const openErrorSnackbar = (message: string) => {
      setErrorSnackbar({isOpen: true, message});
    };

    const closeErrorSnackbar = () => {
      setErrorSnackbar({isOpen: false});
    };

    return {
      openErrorSnackbar,
      closeErrorSnackbar,
    };
  }, [setErrorSnackbar]);
};

interface ErrorSnackbarProps {
  timeout?: number;
}

/**
 * Snackbar used to render simple error messages to users. This component is rendered in App.tsx
 * and shouldn't be used directly.
 *
 * To open it, use `openErrorSnackbar` from `useErrorSnackbar` above
 */
export const ErrorSnackbar = ({timeout = 5000}: ErrorSnackbarProps) => {
  const {isOpen, message} = useAtomValue(errorSnackbarAtom);
  const {closeErrorSnackbar} = useErrorSnackbar();

  return (
    <ReelSnackbar onRequestClose={closeErrorSnackbar} open={isOpen} timeout={timeout}>
      <UIIcon src={FailLine} />
      <Snackbar.Message>
        <Text color="standard">{message}</Text>
      </Snackbar.Message>
      <Snackbar.Actions>
        <Button onClick={closeErrorSnackbar} variant="transparent">
          <FormattedMessage
            defaultMessage="Close"
            description="Text to close the folder upload error snackbar."
            id="NU9KDj"
          />
        </Button>
      </Snackbar.Actions>
    </ReelSnackbar>
  );
};
