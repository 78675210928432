import React, {type MouseEventHandler, type ReactNode, useRef} from 'react';

import {PAP_Shown_Modal} from 'pap-events/replay/shown_modal';
import {FormattedMessage, useIntl} from 'react-intl';
import styled, {css} from 'styled-components';

import {Text} from '@dropbox/dig-components/typography';
import {Box, Split, ThemeContainer, ThemeProvider} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {CustomizeLine} from '@dropbox/dig-icons/assets';

import {SkeletonRectangle} from '~/components/skeleton';
import {
  breakpointMedium,
  breakpointSmall,
  color,
  colorTransition,
  ifProp,
  spacing,
} from '~/components/styled';
import {backgroundColorFromTheme, type BrandingTheme} from '~/lib/branding';
import {getContrastThemeMode} from '~/lib/colors';
import {useLoggingClient} from '~/lib/use_logging_client';
import {useHasFullAddonAccess} from '~/lib/utils';
import {
  BrandingThemeModal,
  type BrandingThemeModalProps,
} from '~/pages/browse_page/components/branding/branding_theme_modal';

interface HeaderRowProps {
  $backgroundColor?: string;
  $inFolder?: boolean;
  $canChangeTheme?: boolean;
}

const HeaderRow = styled(Box)<HeaderRowProps>`
  position: relative;
  align-items: flex-start;
  column-gap: ${spacing('Micro Small')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 0;
  padding: ${spacing('Micro XLarge')} ${spacing('Micro Large')};
  top: 0;
  z-index: 3;

  // Background priority:
  // 1 - Theme color
  // 2 - if folder or can change theme, use opacity surface
  // 3 - otherwise, use background base
  ${({$backgroundColor, $inFolder, $canChangeTheme}) => css`
    background: ${$backgroundColor ||
    ($inFolder || $canChangeTheme ? color('Opacity Surface') : color('Background Base'))};
  `}

  ${ifProp(
    '$canChangeTheme',
    css`
      &:not(:has(*:hover)) {
        cursor: pointer;
      }
    `,
  )}

  ${breakpointMedium(css`
    flex-direction: row;
    justify-content: space-between;
  `)}
`;

const ChangeThemeOverlay = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  border: none;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  padding: ${spacing('Macro XSmall')};

  ${breakpointMedium(css`
    align-items: center;
  `)}

  color: ${color('Text Base')};
  background-color: rgba(0, 0, 0, 0.5);
  transition: ${colorTransition('opacity')};
  opacity: 0;

  .js-focus-visible &[data-focus-visible-added],
  &:hover {
    opacity: 1;
  }
`;

const ChangeOverlayLabel = styled.div`
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeaderItemLeft = styled(Box)<{$isSharedView?: boolean}>`
  // expand to fill the space that the flexbox provides (for breadcrumbs)
  flex-grow: 1;
  // Make room for the overlay
  width: 90%;

  ${breakpointSmall(css`
    width: 80%;
  `)}

  // shared view only has 1 action for now (share) which leaves a lot more space to use for long descriptions and titles
  // TODO(sheniff): I'll be revisiting this logic to make it more flexible, not necessarily bound to percentages.
  ${({$isSharedView}) =>
    !$isSharedView &&
    breakpointMedium(
      css`
        max-width: 40%;
      `,
    )}
`;

type HeaderItemRightProps = {$inFolder: boolean};
const HeaderItemRight = styled(Box)<HeaderItemRightProps>`
  ${breakpointMedium(
    ifProp(
      '$inFolder',
      css`
        padding: ${spacing('Macro Medium')} 0 0;
      `,
    ),
  )}
`;

type FolderHeaderProps = {
  theme?: BrandingTheme;
  breadcrumbs: ReactNode;
  children: ReactNode;
  inFolder: boolean;
  isProject?: boolean;
  isSharedView?: boolean;
  loading: boolean;
  rightSection?: ReactNode;
  onThemeChange?: BrandingThemeModalProps['onThemeSave'];
};

export const FolderHeader = ({
  theme,
  breadcrumbs,
  children,
  inFolder,
  isProject,
  isSharedView,
  loading,
  rightSection,
  onThemeChange,
}: FolderHeaderProps) => {
  const intl = useIntl();
  const themeTargetRef = useRef<HTMLButtonElement | null>(null);
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const hasFullAddonAccess = useHasFullAddonAccess();
  const loggingClient = useLoggingClient();
  const canChangeTheme = hasFullAddonAccess && inFolder && theme && onThemeChange;

  const backgroundColor = backgroundColorFromTheme(theme);
  const mode = getContrastThemeMode(backgroundColor);

  const handleChangeThemeClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    if (event.target === themeTargetRef.current) {
      loggingClient.logPap(PAP_Shown_Modal({modal: 'theme'}));
      setIsModalOpen(true);
    }
  };

  const handleThemeModalClose = () => {
    setIsModalOpen(false);
  };

  const handleThemeModalSave = async (logo: BrandingTheme) => {
    if (onThemeChange) {
      await onThemeChange(logo);
    }
    setIsModalOpen(false);
  };

  if (loading) {
    return (
      <ThemeProvider mode={mode} overrides="reset" theme="vis2023">
        <ThemeContainer>
          <HeaderRow
            $backgroundColor={backgroundColor}
            $canChangeTheme={false}
            $inFolder={inFolder}
          >
            <HeaderItemLeft>
              {inFolder && <SkeletonRectangle style={{marginBottom: 12, height: 16, width: 140}} />}
              <Split alignY="center" gap="Micro Medium">
                {inFolder && (
                  <Split.Item>
                    <SkeletonRectangle style={{height: 100, width: 100}} />
                  </Split.Item>
                )}
                <Split.Item>
                  <Box alignItems="center" display="flex" flexDirection="row">
                    <SkeletonRectangle style={{marginBottom: 16, height: 22, width: 120}} />
                  </Box>

                  {isProject ||
                    (inFolder && (
                      <SkeletonRectangle style={{marginBottom: 8, width: 140, height: 20}} />
                    ))}
                  {inFolder && <SkeletonRectangle style={{width: 100, height: 20}} />}
                </Split.Item>
              </Split>
            </HeaderItemLeft>

            <HeaderItemRight $inFolder={inFolder}>
              <SkeletonRectangle style={{height: 24, marginTop: 4, marginBottom: 4, width: 80}} />
            </HeaderItemRight>
          </HeaderRow>
        </ThemeContainer>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider mode={mode} overrides="reset" theme="vis2023">
      <ThemeContainer>
        <HeaderRow
          $backgroundColor={backgroundColor}
          $canChangeTheme={!!canChangeTheme}
          $inFolder={inFolder || isProject} // a project should always show a non-transparent background
          data-testid="folder-header"
        >
          {canChangeTheme && (
            <>
              <ChangeThemeOverlay
                aria-label={intl.formatMessage({
                  defaultMessage: 'Change theme',
                  id: 'pPcSn8',
                  description: 'Label for the button to change the theme',
                })}
                onClick={handleChangeThemeClick}
                ref={themeTargetRef}
              >
                <ChangeOverlayLabel>
                  <UIIcon src={CustomizeLine} />
                  <Text size="small">
                    <FormattedMessage
                      defaultMessage="Change"
                      description="Copy for option to change the theme"
                      id="blEX2m"
                    />
                  </Text>
                </ChangeOverlayLabel>
              </ChangeThemeOverlay>

              <BrandingThemeModal
                onAfterClose={() => themeTargetRef.current?.blur()}
                onClose={handleThemeModalClose}
                onThemeSave={handleThemeModalSave}
                open={isModalOpen}
                theme={theme}
              />
            </>
          )}

          <HeaderItemLeft
            $isSharedView={isSharedView}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            {inFolder && breadcrumbs}
            {children}
          </HeaderItemLeft>

          {rightSection && <HeaderItemRight $inFolder={inFolder}>{rightSection}</HeaderItemRight>}
        </HeaderRow>
      </ThemeContainer>
    </ThemeProvider>
  );
};
