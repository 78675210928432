import styled, {css} from 'styled-components';

import {Box, type BoxProps} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {TeamLine} from '@dropbox/dig-icons/assets';

import {color, ifProp, radius, spacing} from '~/components/styled';
import type {Branding} from '~/lib/branding';
import {BrandingLogoIcon} from '~/pages/browse_page/components/branding/branding_logo_icon';

interface LogoProps extends BoxProps<any> {
  $backgroundUrl?: string;
  $size?: 'small' | 'large';
}

const Logo = styled(Box)<LogoProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1;

  background-color: ${color('Opacity Surface')};

  ${({$size}) =>
    $size === 'small'
      ? css`
          height: ${spacing('28')};
          width: ${spacing('28')};
          border-radius: ${radius('Small')};

          .branding-icon {
            height: ${spacing('28')};
            width: ${spacing('28')};
            border-radius: ${radius('Small')};
          }
        `
      : css`
          border-radius: ${radius('Medium')};
          height: ${spacing('Macro XLarge')};
          width: ${spacing('Macro XLarge')};

          .branding-icon {
            border-radius: ${radius('Medium')};
            height: ${spacing('Macro XLarge')};
            width: ${spacing('Macro XLarge')};
          }
        `}

  ${ifProp(
    '$backgroundUrl',
    ({$backgroundUrl}) => css`
      background: url(${$backgroundUrl}) ${color('Opacity Surface')} center / cover no-repeat;
    `,
  )}
`;

interface ProjectLogoProps extends BoxProps<any> {
  branding?: Branding;
  nullOnEmpty?: boolean;
}

export const ProjectLogo = ({
  branding,
  size = 'small',
  nullOnEmpty,
  ...props
}: ProjectLogoProps) => {
  const logoType = branding?.logo?.type;
  const logoVariant = (logoType == 'default' && branding?.logo?.variant) || 'none';
  const hasNoLogo = !branding || (logoType !== 'custom' && logoVariant == 'none');

  if (hasNoLogo) {
    return nullOnEmpty ? null : (
      <Logo $size={size} {...props}>
        <UIIcon src={TeamLine} />
      </Logo>
    );
  }

  return (
    <Logo $backgroundUrl={logoType === 'custom' ? branding?.logo?.url : ''} $size={size} {...props}>
      {logoType === 'default' && <BrandingLogoIcon variant={logoVariant} />}
    </Logo>
  );
};
