import React from 'react';

import {FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';

import {Modal} from '@dropbox/dig-components/modal';
import {Text, Title} from '@dropbox/dig-components/typography';

import {Button} from '~/components/button';
import type {UnlinkStatus} from '~/components/gdrive_context';
import {getDefaultUserClient} from '~/lib/client';

import {spacing} from './styled';

const ModalBody = styled(Modal.Body)`
  padding-bottom: 24px;
`;

const ModalButtons = styled.div`
  display: flex;
  margin-top: ${spacing('24')};
  justify-content: flex-end;
`;

const CloseButton = styled.div`
  margin-left: ${spacing('12')};
`;

type OneDriveLoginModalProps = {
  open: boolean;
  onClose: (unlinkStatus: UnlinkStatus) => void;
};

export const OneDriveUnlinkModal = ({open, onClose}: OneDriveLoginModalProps) => {
  const intl = useIntl();
  const [isUnlinking, setIsUnlinking] = React.useState(false);

  // Unlink the OneDrive(Outlook) service, so the app is forced to reauthenticate again
  const unlinkApp = async () => {
    setIsUnlinking(true);
    try {
      await getDefaultUserClient().profileServicesUnlink({
        service: {
          '.tag': 'outlook',
        },
      });
      setIsUnlinking(false);
      onClose('succeeded');
    } catch {
      setIsUnlinking(false);
      onClose('failed');
    }
  };

  return (
    <Modal
      isCentered
      modalBaseIndex={100000}
      onRequestClose={() => onClose('cancelled')}
      open={open}
      withCloseButton={intl.formatMessage({
        defaultMessage: 'Close',
        id: 'avbRsR',
        description: 'Text on a button to close a modal',
      })}
    >
      <Modal.Header hasBottomSpacing="title-standard">
        <Title size="medium">
          <FormattedMessage
            defaultMessage="Unlink existing OneDrive account"
            description="Modal title informing users that they need to relink their account."
            id="nqna0t"
          />
        </Title>
      </Modal.Header>
      <ModalBody>
        <Text variant="paragraph">
          <FormattedMessage
            defaultMessage="There was an issue authenticating to your previously linked OneDrive account. Please unlink your OneDrive account, and try again."
            description="Message explaining that a user needs to unlink their OneDrive account."
            id="oqyyTh"
          />
        </Text>
        <ModalButtons>
          <Button isLoading={isUnlinking} onClick={unlinkApp} size="standard" variant="primary">
            <FormattedMessage
              defaultMessage="Unlink"
              description="Button that unlinks a user's OneDrive account."
              id="Euyq3y"
            />
          </Button>
          <CloseButton>
            <Button onClick={() => onClose('cancelled')} size="standard" variant="outline">
              <FormattedMessage
                defaultMessage="Cancel"
                description="Button that closes a modal"
                id="/WEl6m"
              />
            </Button>
          </CloseButton>
        </ModalButtons>
      </ModalBody>
    </Modal>
  );
};
