import type {InfiniteData, QueryClient} from '@tanstack/react-query';

import type {reel} from '@dropbox/api-v2-client';

import type {MediaProjectOrFolderDisplayInfoResult} from './api';
import {isMediaProjectDisplayInfo} from './api';
import {replayApi} from './query_client';

export function patchContentsDisplayInfo(
  queryClient: QueryClient,
  assetPublicId: string,
  patch: Partial<reel.MediaProjectDisplayInfo | reel.FolderDisplayInfo>,
) {
  queryClient.setQueryData<MediaProjectOrFolderDisplayInfoResult>(
    replayApi.folderContentsDisplayInfo(assetPublicId),
    (previous) => {
      if (!previous) {
        return previous;
      }
      return {
        ...previous,
        ...patch,
      };
    },
  );
}

export const updateMediaProjectDisplayInfoVersionStatus = (
  queryClient: QueryClient,
  assetPublicId: string,
  newStatus: number,
) => {
  patchContentsDisplayInfo(queryClient, assetPublicId, {status: newStatus});
};

export const updateMediaProjectDisplayInfoName = (
  queryClient: QueryClient,
  assetPublicId: string,
  newName: string,
) => {
  patchContentsDisplayInfo(queryClient, assetPublicId, {
    name: newName,
  });
};

export const updateMediaProjectDisplayInfoCurrentVersion = (
  queryClient: QueryClient,
  assetPublicId: string,
  newVersionData: {
    thumbnail_url?: string;
    version_num?: number;
    version_summaries?: reel.VersionSummary[];
  },
) => {
  patchContentsDisplayInfo(queryClient, assetPublicId, newVersionData);
};

export const updateFolderDisplayInfoName = (
  queryClient: QueryClient,
  assetPublicId: string,
  newName: string,
) => {
  queryClient.setQueryData<MediaProjectOrFolderDisplayInfoResult>(
    replayApi.folderContentsDisplayInfo(assetPublicId),
    (previous) => {
      if (!previous) {
        return previous;
      }
      if (isMediaProjectDisplayInfo(previous)) {
        return previous;
      }
      return {
        ...previous,
        name: newName,
      };
    },
  );
};

export const updateFolderDisplayInfoNumProjects = (
  queryClient: QueryClient,
  assetPublicId: string,
  numAdded: number,
) => {
  queryClient.setQueryData<MediaProjectOrFolderDisplayInfoResult>(
    replayApi.folderContentsDisplayInfo(assetPublicId),
    (previous) => {
      if (!previous) {
        return previous;
      }
      if (isMediaProjectDisplayInfo(previous)) {
        return previous;
      }
      return {
        ...previous,
        num_projects: (previous.num_projects ?? 0) + numAdded,
      };
    },
  );
};

export const deleteMediaProjectFromListFolderBasicInfo = (
  queryClient: QueryClient,
  parentFolderId: string,
  deletedMediaProjectIds: string[],
) => {
  queryClient.setQueryData<
    InfiniteData<reel.ListFolderBasicInfoContinueResult | reel.ListFolderBasicInfoResult>
  >(replayApi.listFolderBasicInfo(parentFolderId), (previous) => {
    if (!previous) {
      return previous;
    }
    return {
      ...previous,
      pages: previous.pages.map((page) => {
        return {
          ...page,
          projects_with_videos:
            page.projects_with_videos?.filter(
              (pwv) => !deletedMediaProjectIds.includes(pwv.project?.id ?? ''),
            ) ?? [],
        };
      }),
    };
  });
};

export const deleteFolderFromListFolderBasicInfo = (
  queryClient: QueryClient,
  parentFolderId: string,
  deletedFolderIds: string[],
) => {
  queryClient.setQueryData<
    InfiniteData<reel.ListFolderBasicInfoContinueResult | reel.ListFolderBasicInfoResult>
  >(replayApi.listFolderBasicInfo(parentFolderId), (previous) => {
    if (!previous) {
      return previous;
    }
    return {
      ...previous,
      pages: previous.pages.map((page) => {
        return {
          ...page,
          folders: page.folders?.filter((folder) => !deletedFolderIds.includes(folder.id)) ?? [],
        };
      }),
    };
  });
};
