import {useAtom, useAtomValue} from 'jotai';

import {SNACKBAR_TIMEOUT} from '~/components/share_modal/share_modal_common';
import {
  copyRequestAtom,
  currentBasicLinkAtom,
  getSelectedVersionNum,
  getShareItemAtom,
  getShareModalLoggingInfoAtom,
  isViewOnlyLink,
  shareModalContentsAtom,
} from '~/components/share_modal/share_state';
import {addLiveReviewQueryParam, swapLinkOrigin} from '~/lib/api';
import {ReplayError, ReplayErrorCategory, reportException} from '~/lib/error_reporting';
import type {LinkItemTypes} from '~/lib/logging/logger_types';
import {getCurrentURLWithoutExtraParams} from '~/lib/url_utils';
import {useLinkActions} from '~/lib/use_link_actions';
import {isInAdobeExtension} from '~/use_extensions';

export const formatShareLink = (viewOnly: boolean, isLiveReview: boolean, link: string) => {
  let url = new URL(link);
  if (isLiveReview && !viewOnly) {
    url = addLiveReviewQueryParam(url);
  }
  swapLinkOrigin(url);
  return url.toString();
};

export const useCopyFromLinksPage = () => {
  const [copyRequest, updateCopyRequest] = useAtom(copyRequestAtom);

  const copyFromLinksPage = (
    isViewOnly: boolean,
    linkItemType: LinkItemTypes,
    linksPageUrl: string,
  ) => {
    const requestId = copyRequest.requestId + 1;

    updateCopyRequest({
      requestId: requestId,
      request: {
        type: 'in_progress',
        linkType: isViewOnly ? 'viewing' : 'commenting',
      },
    });

    const linkString = `${window.location.origin}/${
      linkItemType === 'file' ? 'share' : 'share-folder'
    }/${linksPageUrl}`;
    try {
      if (isInAdobeExtension()) {
        redCarpetCopyToClipboard(linkString);
      } else {
        copyToClipboard(linkString);
      }
      updateCopyRequest({
        requestId: requestId,
        request: {
          type: 'success',
          linkType: isViewOnly ? 'viewing' : 'commenting',
          result: linkString,
        },
      });
      setTimeout(() => {
        updateCopyRequest({requestId: requestId, request: {type: 'none'}});
      }, SNACKBAR_TIMEOUT);
    } catch (e) {
      reportException(
        new ReplayError({
          error: e,
          severity: 'non-critical',
          tags: ['copy_to_clipboard_failed'],
          category: ReplayErrorCategory.UncaughtException,
        }),
      );
      updateCopyRequest({
        requestId: requestId,
        request: {
          type: 'error',
          error: e,
          linkType: isViewOnly ? 'viewing' : 'commenting',
          dmca_requester: undefined,
        },
      });
    }
  };
  return {copyFromLinksPage};
};

export const useCopyFromBrowserUrl = () => {
  const [copyRequest, updateCopyRequest] = useAtom(copyRequestAtom);

  const copyFromBrowserUrl = (isViewOnly: boolean) => {
    const requestId = copyRequest.requestId + 1;
    updateCopyRequest({
      requestId: requestId,
      request: {
        type: 'in_progress',
        linkType: isViewOnly ? 'viewing' : 'commenting',
      },
    });
    const linkString = getCurrentURLWithoutExtraParams();
    try {
      if (isInAdobeExtension()) {
        redCarpetCopyToClipboard(linkString);
      } else {
        copyToClipboard(linkString);
      }
      updateCopyRequest({
        requestId: requestId,
        request: {
          type: 'success',
          linkType: isViewOnly ? 'viewing' : 'commenting',
          result: linkString,
        },
      });
      setTimeout(() => {
        updateCopyRequest({requestId: requestId, request: {type: 'none'}});
      }, SNACKBAR_TIMEOUT);
    } catch (e) {
      reportException(
        new ReplayError({
          error: e,
          severity: 'non-critical',
          tags: ['copy_to_clipboard_failed'],
          category: ReplayErrorCategory.UncaughtException,
        }),
      );
      updateCopyRequest({
        requestId: requestId,
        request: {
          type: 'error',
          error: e,
          linkType: isViewOnly ? 'viewing' : 'commenting',
          dmca_requester: undefined,
        },
      });
    }
  };
  return {copyFromBrowserUrl};
};

export const useCopyBasicLinkToClipboard = () => {
  const [copyRequest, updateCopyRequest] = useAtom(copyRequestAtom);
  const shareItem = useAtomValue(getShareItemAtom);
  const shareModalState = useAtomValue(shareModalContentsAtom);
  const loggingInfo = useAtomValue(getShareModalLoggingInfoAtom);
  const currentLinkInfo = useAtomValue(currentBasicLinkAtom);
  const isViewOnly = isViewOnlyLink(shareModalState);

  const {fetchLink} = useLinkActions({
    logEvent: loggingInfo.logEvent,
    versionSummaries: shareItem?.type === 'file' ? shareItem.versionSummaries : undefined,
    linkItemId: shareItem?.id ?? '',
    linkItemType: shareItem?.type ?? 'file',
    location: loggingInfo.location,
  });

  const copyLinkToClipboard = async (link?: string) => {
    const requestId = copyRequest.requestId + 1;

    updateCopyRequest({
      requestId: requestId,
      request: {
        type: 'in_progress',
        linkType: isViewOnly ? 'viewing' : 'commenting',
      },
    });

    try {
      let linkString = '';
      if (link) {
        linkString = link;
      } else if (currentLinkInfo?.link) {
        linkString = formatShareLink(isViewOnly, loggingInfo.isLiveReview, currentLinkInfo.link);
      } else {
        // Create link if it doesn't already exist
        linkString = await fetchLink(isViewOnly, getSelectedVersionNum(shareModalState));
      }

      if (isInAdobeExtension()) {
        redCarpetCopyToClipboard(linkString);
      } else {
        copyToClipboard(linkString);
      }
      updateCopyRequest({
        requestId: requestId,
        request: {
          type: 'success',
          linkType: isViewOnly ? 'viewing' : 'commenting',
          result: linkString,
        },
      });
      setTimeout(() => {
        updateCopyRequest({requestId: requestId, request: {type: 'none'}});
      }, SNACKBAR_TIMEOUT);
    } catch (e) {
      let type:
        | 'error'
        | 'irrecoverable_error'
        | 'sharing_is_restricted'
        | 'dmca_requester'
        | 'email_not_verified';
      reportException(
        new ReplayError({
          error: e,
          severity: 'non-critical',
          tags: ['copy_to_clipboard_failed'],
          category: ReplayErrorCategory.UncaughtException,
        }),
      );
      const tag = e.error && e.error.error && e.error.error['.tag'];
      switch (tag) {
        case 'invalid_existing_link_permissions':
        case 'unable_to_create_shared_link':
        case 'unable_to_get_shared_link': {
          type = 'irrecoverable_error';
          break;
        }
        case 'sharing_is_restricted': {
          type = 'sharing_is_restricted';
          break;
        }
        case 'email_not_verified': {
          type = 'email_not_verified';
          break;
        }
        case 'dmca_requester': {
          type = 'dmca_requester';
          break;
        }
        default: {
          type = 'error';
          break;
        }
      }
      updateCopyRequest({
        requestId: requestId,
        request: {
          type: type,
          error: e,
          linkType: isViewOnly ? 'viewing' : 'commenting',
          dmca_requester: type === 'dmca_requester' ? e.error.error['dmca_requester'] : undefined,
        },
      });
    }
  };
  return {copyLinkToClipboard};
};

const redCarpetCopyToClipboard = (linkString: string) => {
  // The version of CEF that Adobe Premiere uses doesn't support navigator.clipboard
  // so we have to use this hack to make link sharing work. :(
  const hiddenInput = document.createElement('input') as HTMLInputElement;
  if (hiddenInput) {
    hiddenInput.style.position = 'absolute';
    hiddenInput.style.left = '-9999px';
    hiddenInput.setAttribute('value', linkString);
    document.body.appendChild(hiddenInput);
    try {
      hiddenInput.focus();
      hiddenInput.select();
      // eslint-disable-next-line deprecation/deprecation
      document.execCommand('copy');
    } catch (e) {
      throw e;
    } finally {
      document.body.removeChild(hiddenInput);
    }
  }
};

const copyToClipboard = (linkString: string) => {
  // Workaround for Safari, where clipboard.writeText **must** be called from a user gesture.
  // Apparently wrapping the action in a setTimeout is considered a user gesture for Safari...
  setTimeout(() => {
    navigator.clipboard.writeText(linkString);
  }, 0);
};
