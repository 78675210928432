import React from 'react';

import {useSetAtom} from 'jotai';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';

import type {OverlayAnchorRef} from '@dropbox/dig-components/overlay';
import {Tooltip} from '@dropbox/dig-components/tooltips';
import {Text, Title} from '@dropbox/dig-components/typography';

import {Button} from '~/components/button';
import {proToolsTooltipWasDismissedAtom} from '~/state/pro_tools';

interface ProToolsTooltipProps {
  triggerRef: OverlayAnchorRef;
  showTooltip: boolean;
}

const StepActions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const ProToolsTooltip = (props: ProToolsTooltipProps) => {
  const {triggerRef, showTooltip} = props;
  const setProToolTooltipWasDismissed = useSetAtom(proToolsTooltipWasDismissedAtom);

  const onClose = React.useCallback(() => {
    setProToolTooltipWasDismissed(true);
  }, [setProToolTooltipWasDismissed]);

  return (
    <>
      <Tooltip.Control
        auto
        maxWidth={290}
        open={showTooltip}
        placement="left"
        triggerRef={triggerRef}
        variant="rich"
      >
        <Title size="medium" tagName="h1">
          <FormattedMessage
            defaultMessage="Add bounced files to Replay"
            description="Header for tooltip highlighting to users that they can create Replay projects using bounced files from Pro Tools"
            id="GosNgy"
          />
        </Title>
        <Text color="standard" tagName="p">
          <FormattedMessage
            defaultMessage="Work on files you bounced from Pro Tools."
            description="Body of tooltip highlighting to users that they can create Replay projects using bounced files from Pro Tools"
            id="ib6LDT"
          />
        </Text>
        <StepActions>
          <Button autoFocus onClick={onClose} variant="outline">
            <FormattedMessage
              defaultMessage="Got it"
              description="Text for button to add bounced files to Replay from a user's local computer"
              id="zjcL7z"
            />
          </Button>
        </StepActions>
      </Tooltip.Control>
    </>
  );
};
