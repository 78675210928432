import type {MouseEventHandler, PropsWithChildren} from 'react';
import React from 'react';

import {FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';

import {Menu} from '@dropbox/dig-components/menu';

import {Button} from '~/components/button';
import type {VersionStatus} from '~/components/status/status';
import {STATUS_UI_ORDER, VERSION_STATUS_PROPERTIES} from '~/components/status/status';
import {color} from '~/components/styled';
import {useReelAppGlobalState} from '~/context';

import {StatusIcon} from './status_icons/status_icon';

type ButtonSize = 'small' | 'standard';
type ButtonType = 'opacity' | 'transparent' | 'outline';

type StatusDropdownCommonProps = {
  /** Current version status */
  versionStatus?: VersionStatus;
  /** Size of button */
  buttonSize: ButtonSize;
  /** Type of button */
  buttonType: ButtonType;
};

type StatusDropdownProps =
  | (StatusDropdownCommonProps & {
      /** Whether or not the status dropdown is currently disabled */
      disabled: false;
      /** Handler for when the status is changed from a menu selection in dropdown */
      onChange: (versionStatus: VersionStatus) => void;
    })
  | (StatusDropdownCommonProps & {
      /** Whether or not the status dropdown is currently disabled */
      disabled: true;
    });

const SmallerPaddingButton = styled(Button)<{$isSmall: boolean}>`
  &.dig-Button--withIconLeft .dig-Button-icon-left-wrapper {
    margin-right: ${({$isSmall}) => ($isSmall ? '-6px' : '4px')};
  }
`;

const FaintOutlineButton = styled(Button)`
  border-color: ${color('Border Subtle')};
`;

const StatusDropdownButton = React.forwardRef<
  HTMLElement,
  PropsWithChildren<{
    disabled: boolean;
    status?: VersionStatus;
    style?: React.CSSProperties;
    buttonSize: ButtonSize;
    buttonType: ButtonType;
    onClick?: MouseEventHandler<HTMLElement>;
  }>
>((props, ref) => {
  // Apply custom styles to the StatusIcon for cases where
  // the animation can be cut off due to space constraints
  const iconStyles: React.CSSProperties = {};
  if (props.buttonType === 'transparent') {
    iconStyles.marginLeft = 0;
  }

  if (props.buttonSize === 'small') {
    iconStyles.width = 16;
    iconStyles.height = 16;
    iconStyles.marginRight = 0;
  }

  return props.buttonType === 'outline' ? (
    <FaintOutlineButton
      disabled={props.disabled}
      onClick={props.onClick}
      ref={ref}
      size={props.buttonSize}
      style={props.style}
      variant={props.buttonType}
      withDropdownIcon
      withIconStart={<StatusIcon introEffect="intro" status={props.status} style={iconStyles} />}
    >
      {props.children}
    </FaintOutlineButton>
  ) : (
    <SmallerPaddingButton
      $isSmall={props.buttonSize === 'small'}
      disabled={props.disabled}
      onClick={props.onClick}
      ref={ref}
      size={props.buttonSize}
      style={props.style}
      variant={props.buttonType}
      withDropdownIcon
      withIconStart={<StatusIcon introEffect="intro" status={props.status} style={iconStyles} />}
    >
      {props.children}
    </SmallerPaddingButton>
  );
});

const StatusLabel = (props: {versionStatus: VersionStatus}) => {
  return (
    <FormattedMessage
      defaultMessage="{status, select, NO_STATUS {No status} NEEDS_REVIEW {Needs review} IN_PROGRESS {In progress} APPROVED {Approved} EDITS_REQUESTED {Edits requested} other {No status}}"
      description="Status dropdown menu text indicating the current status"
      id="lU3vsJ"
      values={{
        status: props.versionStatus,
      }}
    />
  );
};

export const StatusDropdown = (props: StatusDropdownProps) => {
  const {disabled, versionStatus, buttonSize, buttonType} = props;
  const [hoveredState, setHoveredState] = React.useState<VersionStatus | null>(null);
  const onChange = props.disabled ? noop : props.onChange;

  const clearHoveredState = React.useCallback(() => {
    setHoveredState(null);
  }, []);
  const handleHoveredState = React.useCallback(
    (status: VersionStatus) => () => {
      setHoveredState(status);
    },
    [],
  );
  const handleSelection = React.useCallback(
    (val: VersionStatus) => {
      clearHoveredState();
      onChange(val);
    },
    [clearHoveredState, onChange],
  );

  const onboardingTriggerRef = React.createRef<HTMLInputElement>();
  const {status} = useReelAppGlobalState();
  const intl = useIntl();

  const statusLabel = !versionStatus ? (
    intl.formatMessage({
      defaultMessage: 'Change status',
      id: 'kn9FWF',
      description: 'Text on the status dropdown menu that prompts users to select a status.',
    })
  ) : versionStatus === 'NO_STATUS' ? (
    intl.formatMessage({
      defaultMessage: 'Add status',
      id: '+GdYr1',
      description: 'Default text on the status dropdown menu that prompts users to add a status.',
    })
  ) : (
    <StatusLabel data-safe-to-unmask-name="version-status-label" versionStatus={versionStatus} />
  );

  return (
    <>
      {status === 'logged out' ? null : (
        <Menu.Wrapper
          data-testid="status-dropdown"
          isPortaled={false} // Fixes the focus order for keyboard navigation when tabbing out of the menu
          onSelection={handleSelection}
        >
          {({getContentProps, getTriggerProps}) => (
            <>
              <StatusDropdownButton
                {...getTriggerProps()}
                buttonSize={buttonSize}
                buttonType={buttonType}
                disabled={disabled}
                ref={onboardingTriggerRef}
                status={versionStatus}
              >
                <div style={{marginRight: '4px'}}>{statusLabel}</div>
              </StatusDropdownButton>
              <Menu.Content {...getContentProps()} triggerRef={onboardingTriggerRef}>
                <Menu.Segment style={{width: 'max-content'}}>
                  {STATUS_UI_ORDER.map((status) => {
                    if (status === 'NO_STATUS') {
                      return null;
                    }
                    return (
                      <Menu.ActionItem
                        data-safe-to-unmask-name={VERSION_STATUS_PROPERTIES[status].unmaskName}
                        key={VERSION_STATUS_PROPERTIES[status].id}
                        onMouseEnter={handleHoveredState(status)}
                        onMouseLeave={clearHoveredState}
                        value={status}
                        withRightAccessory={
                          <StatusIcon
                            effect={hoveredState == status ? 'loop' : 'stable'}
                            introEffect="intro"
                            status={status}
                          />
                        }
                      >
                        <StatusLabel versionStatus={status} />
                      </Menu.ActionItem>
                    );
                  })}
                </Menu.Segment>
                <Menu.Segment>
                  <Menu.ActionItem
                    data-safe-to-unmask-name="clear-status-button"
                    key={VERSION_STATUS_PROPERTIES.NO_STATUS.id}
                    value="NO_STATUS"
                  >
                    <FormattedMessage
                      defaultMessage="Clear status"
                      description="Text for menu option that clears the workflow status for a file"
                      id="svXXeX"
                    />
                  </Menu.ActionItem>
                </Menu.Segment>
              </Menu.Content>
            </>
          )}
        </Menu.Wrapper>
      )}
    </>
  );
};

function noop() {}
