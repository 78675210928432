import React, {useCallback, useEffect} from 'react';

import {useIntl} from 'react-intl';
import styled from 'styled-components';

import {Checkbox} from '@dropbox/dig-components/controls';
import {FormLabel} from '@dropbox/dig-components/form_row';
import {Menu} from '@dropbox/dig-components/menu';
import {Modal} from '@dropbox/dig-components/modal';
import {Spinner} from '@dropbox/dig-components/progress_indicators';
import {Text} from '@dropbox/dig-components/typography';

import {Button} from '~/components/button';
import {ReplayTextInput} from '~/components/text_input';
import {useFeatureIsOn} from '~/lib/growthbook';
import {createReadableByteStream, nodeStreamToIterator} from '~/lib/streams';
import type {StreamableFile} from '~/lib/uploads/types';

import {useExtensionContext} from './extension_context';
import {useRenderContext} from './render_context';
import {isInAdobeExtension, RedCarpetMode, RenderStatus, SendMessageType} from '../use_extensions';

const Title = styled.span`
  margin-left: 10px;
`;

const InputTextLabel = styled(Text)`
  margin-right: 10px;
  width: 116px;
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  max-width: 429px;
  margin-bottom: 15px;
  width: 100%;
`;

const CheckboxRow = styled(Row)`
  max-width: 500px;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  margin-right: 10px;
  margin-bottom: 10px;
`;

const TextField = styled.div`
  width: 300px;
`;

const SpinnerWrapper = styled.span`
  width: 50px;
`;

const LabelWrapper = styled(FormLabel)`
  margin-left: 10px;
`;

const Separator = styled.hr`
  width: 100%;
  border: none;
`;

export const RangeType = ['ENCODE_ENTIRE', 'ENCODE_IN_TO_OUT', 'ENCODE_WORKAREA'] as const;

export const PresetType = [
  'AIFF 48kHz',
  'Alpha Only',
  'High Quality',
  'High Quality with Alpha',
  'Lossless',
  'Lossless with Alpha',
  'web1080.epr',
  'web720.epr',
  'ReplayMatchSource.epr',
  'ownPresetPath',
  'H.264 - Match Render Settings -  5 Mbps',
  'H.264 - Match Render Settings - 15 Mbps',
  'H.264 - Match Render Settings - 40 Mbps',
] as const;

export const SettingsType = [
  'Best Settings',
  'Current Settings',
  'DV Settings',
  'Draft Settings',
] as const;

const useVideoPresetsPPRO: () => Array<{name: string; value: (typeof PresetType)[number]}> = () => {
  const intl = useIntl();

  return [
    {
      value: 'web1080.epr',
      name: intl.formatMessage({
        defaultMessage: 'Web 1080',
        id: 'ZTpQl0',
        description: 'Web 1080 video rendering preset for Adobe Premiere Pro',
      }),
    },
    {
      value: 'web720.epr',
      name: intl.formatMessage({
        defaultMessage: 'Web 720',
        id: 'R0YbIf',
        description: 'Web 720 video rendering preset for Adobe Premiere Pro',
      }),
    },
    {
      value: 'ReplayMatchSource.epr',
      name: intl.formatMessage({
        defaultMessage: 'Match Source - Adaptive High Bitrate',
        id: 'NSJp5g',
        description:
          'Match Source - Adaptive High Bitrate preset rendering setting for Adobe Premiere Pro',
      }),
    },
    {
      value: 'ownPresetPath',
      name: intl.formatMessage({
        defaultMessage: 'Own Preset Path',
        id: 'zEGJQH',
        description: 'User custom preset rendering setting for Adobe Premiere Pro',
      }),
    },
  ];
};

const useVideoPresetsAEFT = (): Array<{name: string; value: (typeof PresetType)[number]}> => {
  const intl = useIntl();

  return [
    {
      value: 'AIFF 48kHz',
      name: intl.formatMessage({
        defaultMessage: 'AIFF 48kHz',
        id: 'vbwEDc',
        description: 'AIFF 48kHz audio preset for rendering in Adobe After Effects',
      }),
    },
    {
      value: 'Alpha Only',
      name: intl.formatMessage({
        defaultMessage: 'Alpha Only',
        id: '33UOpF',
        description: 'Alpha Only preset for rendering in Adobe After Effects',
      }),
    },
    {
      value: 'High Quality',
      name: intl.formatMessage({
        defaultMessage: 'High Quality',
        id: '36hmBM',
        description: 'High Quality preset for rendering .mov videos in Adobe After Effects',
      }),
    },
    {
      value: 'High Quality with Alpha',
      name: intl.formatMessage({
        defaultMessage: 'High Quality with Alpha',
        id: 'ZxoyYu',
        description:
          'High Quality with Alpha preset for rendering .mov videos in Adobe After Effects',
      }),
    },
    {
      value: 'Lossless',
      name: intl.formatMessage({
        defaultMessage: 'Lossless',
        id: 'zVeCAJ',
        description: 'Lossless preset for rendering high quality videos in Adobe After Effects',
      }),
    },
    {
      value: 'Lossless with Alpha',
      name: intl.formatMessage({
        defaultMessage: 'Lossless with Alpha',
        id: 'Yc/2C1',
        description:
          'Lossless with Alpha preset for rendering high quality videos in Adobe After Effects',
      }),
    },
    {
      value: 'H.264 - Match Render Settings -  5 Mbps',
      name: intl.formatMessage({
        defaultMessage: 'H.264 - Match Render Settings - 5 Mbps',
        id: 'S3S6MB',
        description:
          'H.264 - Match Render Settings - 5 Mbps preset for rendering in Adobe After Effects with H.264 codec and 5 Mbps bitrate',
      }),
    },
    {
      value: 'H.264 - Match Render Settings - 15 Mbps',
      name: intl.formatMessage({
        defaultMessage: 'H.264 - Match Render Settings - 15 Mbps',
        id: 'ie4siP',
        description:
          'H.264 - Match Render Settings - 15 Mbps preset for rendering in Adobe After Effects with H.264 codec and 15 Mbps bitrate',
      }),
    },
    {
      value: 'H.264 - Match Render Settings - 40 Mbps',
      name: intl.formatMessage({
        defaultMessage: 'H.264 - Match Render Settings - 40 Mbps',
        id: 'gfs2Vy',
        description:
          'H.264 - Match Render Settings - 40 Mbps preset for rendering in Adobe After Effects with H.264 codec and 40 Mbps bitrate',
      }),
    },
  ];
};

const useVideoRangesPPRO = (): Array<{name: string; value: (typeof RangeType)[number]}> => {
  const intl = useIntl();

  return [
    {
      value: 'ENCODE_ENTIRE',
      name: intl.formatMessage({
        defaultMessage: 'Entire Sequence',
        id: 'PnoOH8',
        description: 'Renders the entire sequence of the project in Adobe Premiere Pro',
      }),
    },
    {
      value: 'ENCODE_IN_TO_OUT',
      name: intl.formatMessage({
        defaultMessage: 'In to Out',
        id: 'd3pwmc',
        description:
          'Renders the sequence between the in and out points of the project in Adobe Premiere Pro',
      }),
    },
    {
      value: 'ENCODE_WORKAREA',
      name: intl.formatMessage({
        defaultMessage: 'Work Area',
        id: '2IDnDc',
        description:
          'Renders the sequence between the work area of the project in Adobe Premiere Pro',
      }),
    },
  ];
};

const useRenderSettingsAEFT = (): Array<{name: string; value: (typeof SettingsType)[number]}> => {
  const intl = useIntl();

  return [
    {
      value: 'Best Settings',
      name: intl.formatMessage({
        defaultMessage: 'Best Settings',
        id: 'DZN8+y',
        description: 'Uses the best settings for rendering the video in Adobe After Effects',
      }),
    },
    {
      value: 'Current Settings',
      name: intl.formatMessage({
        defaultMessage: 'Current Settings',
        id: 'XifPpN',
        description: 'Uses the current settings for rendering the video in Adobe After Effects',
      }),
    },
    {
      value: 'DV Settings',
      name: intl.formatMessage({
        defaultMessage: 'DV Settings',
        id: 'TixL9q',
        description: 'Uses the DV settings for rendering the video in Adobe After Effects',
      }),
    },
    {
      value: 'Draft Settings',
      name: intl.formatMessage({
        defaultMessage: 'Draft Settings',
        id: 'RWKN0Y',
        description: 'Uses the draft settings for rendering the video in Adobe After Effects',
      }),
    },
  ];
};

const useVideoRangesAEFT = (): Array<{name: string; value: (typeof RangeType)[number]}> => {
  const intl = useIntl();

  return [
    {
      value: 'ENCODE_ENTIRE',
      name: intl.formatMessage({
        defaultMessage: 'Whole Composition',
        id: 'vfX6AS',
        description: 'Renders the entire composition of the project in Adobe After Effects',
      }),
    },
    {
      value: 'ENCODE_WORKAREA',
      name: intl.formatMessage({
        defaultMessage: 'Work Area',
        id: '60qBbM',
        description:
          'Renders the sequence between the work area of the project in Adobe After Effects',
      }),
    },
  ];
};

async function importPolyfill() {
  await import(/* webpackChunkName: "web-streams-polyfill" */ 'web-streams-polyfill/polyfill');
}

type WindowWithCep = {cep: any} & typeof window;

/**
 * See Marker object doc:
 * https://ppro-scripting.docsforadobe.dev/general/marker.html
 */
export interface Marker {
  guid: string;
  name: string;
  comments: string;
  start: {
    seconds: number;
    ticks: number;
  };
  end: {
    seconds: number;
    ticks: number;
  };
  type: 'Comment';
}

export interface MarkersData {
  importMarkers: boolean;
  range: {start: number; end: number};
  rangeType: string;
  markers: Marker[];
}

interface UploadActiveSequenceModalProps {
  open: boolean;
  requestClose: () => void;
  onUpload: (file: StreamableFile | File, syncComments: boolean, markersData: MarkersData) => void;
  onRenderInProgress: () => void;
  onError: (message: string) => void;
  onRenderCanceled: () => void;
}

export const UploadActiveSequence = ({
  open = false,
  requestClose,
  onUpload,
  onRenderInProgress,
  onError,
  onRenderCanceled,
}: UploadActiveSequenceModalProps) => {
  const {
    modalTitlePPRO,
    modalTitleAEFT,
    inputTextName,
    uploadButtonLabel,
    cancelButtonLabel,
    presetLabel,
    settingsLabel,
    rangeLabel,
    renderLocationLabel,
    selectFolderButton,
    selectOwnPresetButtonLabel,
    videoRangesTranslateMapPPRO,
    videoRangesTranslateMapAEFT,
    syncCommentsLabel,
    importMarkersLabel,
    noRequireErrorMessage,
    renderFailureErrorMessage,
    duplicateOutputPathErrorMessage,
    readingFileErrorMessage,
  } = useStrings();
  const {redCarpet, loggingClient, sendMessage} = useExtensionContext();
  const {loggingRedCarpetMode} = useRenderContext();
  const videoPresetsAEFT = useVideoPresetsAEFT();
  const videoPresetsPPRO = useVideoPresetsPPRO();
  const videoRangesPPRO = useVideoRangesPPRO();
  const renderSettingsAEFT = useRenderSettingsAEFT();
  const videoRangesAEFT = useVideoRangesAEFT();
  const videoPresetList =
    redCarpet.mode === RedCarpetMode.PPRO ? videoPresetsPPRO : videoPresetsAEFT;
  const videoRanges = redCarpet.mode === RedCarpetMode.PPRO ? videoRangesPPRO : videoRangesAEFT;
  const modalTitle = redCarpet.mode === RedCarpetMode.PPRO ? modalTitlePPRO : modalTitleAEFT;
  const [videoName, setVideoName] = React.useState<string>();
  const [syncComments, setSyncComments] = React.useState(true);
  const [importMarkers, setImportMarkers] = React.useState(true);
  const [presetSelectedIndex, setPresetSelectedIndex] = React.useState(0);
  const [settingsSelectedIndex, setSettingsSelectedIndex] = React.useState(0);
  const [rangeSelectedIndex, setRangeSelectedIndex] = React.useState(0);
  const [folderPath, setFolderPath] = React.useState<string>();
  const [customPresetPath, setCustomPresetPath] = React.useState<string>();
  const [isRenderInProgress, setIsRenderInProgress] = React.useState(false);
  const uploadButtonEnabled = !!(
    videoName &&
    folderPath &&
    (videoPresetList[presetSelectedIndex].value === 'ownPresetPath' ? customPresetPath : true)
  );
  const streamingUploaderIsOn = useFeatureIsOn('replay_2024_09_24_streaming_uploader');

  let videoRangesTranslateMap = {};

  if (redCarpet.mode === RedCarpetMode.PPRO) {
    videoRangesTranslateMap = videoRangesTranslateMapPPRO;
  } else if (redCarpet.mode === RedCarpetMode.AEFT) {
    videoRangesTranslateMap = videoRangesTranslateMapAEFT;
  }

  useEffect(() => {
    const loadVideoName = async () => {
      const response = await sendMessage?.(SendMessageType.GetActiveSequenceData);
      const data = JSON.parse(response?.data || '{}');

      if (data.name) {
        setVideoName(data.name);
      }
    };

    if (open) {
      loadVideoName();
    }
  }, [open, sendMessage]);

  const onSyncCommentsChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      // eslint-disable-next-line deprecation/deprecation
      loggingClient.logEvent('auto_sync_comments', {
        value: event.target.checked ? 'true' : 'false',
        redCarpetMode: loggingRedCarpetMode,
      });
      setSyncComments((prev) => !prev);
    },
    [setSyncComments, loggingClient, loggingRedCarpetMode],
  );

  const onImportMarkersChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      // eslint-disable-next-line deprecation/deprecation
      loggingClient.logEvent('import_markers_as_comments', {
        value: event.target.checked ? 'true' : 'false',
        redCarpetMode: loggingRedCarpetMode,
      });
      setImportMarkers((prev) => !prev);
    },
    [setImportMarkers, loggingClient, loggingRedCarpetMode],
  );

  const chooseFolderCallback = useCallback(async () => {
    if ('cep' in window) {
      // we are inside PPro panel so we can use CEP (Common Extensibility Platform) API
      // https://jakkrobbit.github.io/CEP-8-Docs/CEPEngine_extensions.js.html
      const windowInExtensionPanel = window as WindowWithCep;
      const result = windowInExtensionPanel.cep.fs.showOpenDialog(false, true, 'Select folder...');
      if (result && result.data && result.data.length > 0) {
        let folderPath: string = result.data[0];
        folderPath = getPath(folderPath);
        if (folderPath) {
          setFolderPath(folderPath);
        }
      }
    }
  }, [setFolderPath]);

  const choosePresetCallback = useCallback(async () => {
    if ('cep' in window) {
      // we are inside PPro panel so we can use CEP (Common Extensibility Platform) API
      // https://jakkrobbit.github.io/CEP-8-Docs/CEPEngine_extensions.js.html
      const windowInExtensionPanel = window as WindowWithCep;
      const result = windowInExtensionPanel.cep.fs.showOpenDialog(
        false,
        false,
        'Select preset file...',
        undefined,
        ['epr'],
      );
      if (result && result.data && result.data.length > 0) {
        let filePath: string = result.data[0];
        filePath = getPath(filePath);
        if (filePath) {
          setCustomPresetPath(filePath);
          if (
            videoPresetList &&
            videoPresetList.length &&
            videoPresetList[videoPresetList.length - 1].value === 'ownPresetPath'
          ) {
            videoPresetList[videoPresetList.length - 1].name = filePath;
          }
        }
      }
    }
  }, [setCustomPresetPath, videoPresetList]);

  const onUploadCallback = useCallback(async () => {
    // eslint-disable-next-line deprecation/deprecation
    loggingClient.logEvent('select_upload_active_sequence_confirm');
    setIsRenderInProgress(true);
    onRenderInProgress?.();
    const presetPath = videoPresetList[presetSelectedIndex];
    const isCustomPreset = presetPath.value === 'ownPresetPath';
    // send message to render active sequence on PPRO
    let data: {outputFilePath: string; jobData: Omit<MarkersData, 'importMarkers'>} | undefined;
    try {
      data = await redCarpet?.sendMessage?.(SendMessageType.RenderActiveSequence, {
        presetFileName: isCustomPreset ? customPresetPath : presetPath.value,
        outputFolderPath: folderPath,
        isCustomPreset: isCustomPreset,
        range: videoRanges[rangeSelectedIndex].value,
        outputFileName: videoName,
      });
    } catch (error) {
      if (error.status === RenderStatus.ERROR) {
        console.error(error.errorMessage);
        onError(renderFailureErrorMessage);
      }
      if (error.status === RenderStatus.CANCELED) {
        onRenderCanceled();
      }
      setIsRenderInProgress(false);
      return;
    }
    if (!window.require) {
      onError(noRequireErrorMessage);
      return;
    }
    if (!data) {
      onError(renderFailureErrorMessage);
      setIsRenderInProgress(false);
      return;
    }
    const outputPath = data.outputFilePath;

    try {
      // We have access to nodejs on the PPro extension if it's enabled on manifest.xml file
      // see for more details:
      // - https://community.adobe.com/t5/exchange-discussions/how-to-access-filesystem-from-panel/m-p/10154224
      // - https://community.adobe.com/t5/premiere-pro-discussions/how-to-enable-nodejs-for-html5-panels/m-p/8334478
      const fs = window.require('fs');
      const path = window.require('path');
      const ext = path.extname(outputPath);

      if (streamingUploaderIsOn) {
        await importPolyfill();
        const stats = await fs.promises.stat(outputPath);
        const stream = async () => {
          const fd = await fs.createReadStream(outputPath, {highWaterMark: 4 * 1024 * 1024});
          return createReadableByteStream(nodeStreamToIterator(fd));
        };

        const name = videoName ? `${videoName}${ext}` : outputPath;
        const file = {
          name: name,
          size: stats.size,
          newStream: stream,
          webkitRelativePath: name,
        };

        setIsRenderInProgress(false);
        onUpload?.(file, syncComments, {importMarkers, ...data.jobData});
      } else {
        const fileData = fs.readFileSync(data.outputFilePath);
        const file = new File(
          [fileData],
          videoName ? `${videoName}${ext}` : data.outputFilePath,
          {},
        );
        setIsRenderInProgress(false);
        onUpload?.(file, syncComments, {importMarkers, ...data.jobData});
      }
    } catch (err) {
      onError(readingFileErrorMessage);
      setIsRenderInProgress(false);
    }
  }, [
    loggingClient,
    onRenderInProgress,
    videoPresetList,
    presetSelectedIndex,
    redCarpet,
    customPresetPath,
    folderPath,
    videoRanges,
    rangeSelectedIndex,
    videoName,
    onError,
    renderFailureErrorMessage,
    onRenderCanceled,
    noRequireErrorMessage,
    onUpload,
    syncComments,
    importMarkers,
    readingFileErrorMessage,
    streamingUploaderIsOn,
  ]);

  const onUploadCompositionCallback = useCallback(async () => {
    // eslint-disable-next-line deprecation/deprecation
    loggingClient.logEvent('select_upload_composition_confirm');
    setIsRenderInProgress(true);
    onRenderInProgress?.();

    if (!window.require) {
      setIsRenderInProgress(false);
      onError(noRequireErrorMessage);
      return;
    }

    const presetPath = videoPresetList[presetSelectedIndex];
    const settingsName = renderSettingsAEFT[settingsSelectedIndex].value;
    let response;

    try {
      response = await sendMessage?.(SendMessageType.RenderActiveComposition, {
        presetName: presetPath.value,
        settingsName: settingsName,
        range: videoRanges[rangeSelectedIndex].value,
        outputFolderPath: folderPath,
        outputFileName: videoName,
      });
    } catch (error) {
      setIsRenderInProgress(false);

      if (error.status === RenderStatus.ERROR) {
        console.error(error.errorMessage);
        onError(renderFailureErrorMessage);
      }
      if (error.status === RenderStatus.CANCELED) {
        onRenderCanceled();
      }

      return;
    }

    const data = JSON.parse(response.data);

    if (data.error) {
      setIsRenderInProgress(false);
      onError(duplicateOutputPathErrorMessage);
      return;
    }

    const fs = window.require('fs');
    if (streamingUploaderIsOn) {
      await importPolyfill();
      const stats = await fs.promises.stat(data.outputFilePath);
      const stream = async () => {
        const fd = await fs.createReadStream(data.outputFilePath, {highWaterMark: 4 * 1024 * 1024});
        return createReadableByteStream(nodeStreamToIterator(fd));
      };

      const file = {
        name: data.fileName,
        size: stats.size,
        newStream: stream,
        webkitRelativePath: data.fileName,
      };

      setIsRenderInProgress(false);
      onUpload?.(file, syncComments, {
        importMarkers,
        range: {
          start: data.range.start,
          end: data.range.end,
        },
        rangeType: data.rangeType,
        markers: data.markers,
      });
    } else {
      fs.readFile(data.outputFilePath, (err: any, fileData: any) => {
        if (err) {
          setIsRenderInProgress(false);
          onError(renderFailureErrorMessage);
          return;
        }

        const file = new File([fileData], data.fileName);
        setIsRenderInProgress(false);
        onUpload?.(file, syncComments, {
          importMarkers,
          range: {
            start: data.range.start,
            end: data.range.end,
          },
          rangeType: data.rangeType,
          markers: data.markers,
        });
      });
    }
  }, [
    loggingClient,
    onRenderInProgress,
    videoPresetList,
    presetSelectedIndex,
    renderSettingsAEFT,
    settingsSelectedIndex,
    onError,
    noRequireErrorMessage,
    sendMessage,
    videoRanges,
    rangeSelectedIndex,
    folderPath,
    videoName,
    renderFailureErrorMessage,
    onRenderCanceled,
    duplicateOutputPathErrorMessage,
    onUpload,
    syncComments,
    importMarkers,
    streamingUploaderIsOn,
  ]);

  const onVideoNameChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setVideoName(event.currentTarget.value);
    },
    [setVideoName],
  );

  const onPresetSelect = useCallback(
    (selection: number) => {
      setPresetSelectedIndex(selection);
      if (selection < videoPresetList.length) {
        // eslint-disable-next-line deprecation/deprecation
        loggingClient.logEvent('select_upload_preset', {
          preset_type: videoPresetList[selection].value,
          redCarpetMode: loggingRedCarpetMode,
        });
      }
    },
    [videoPresetList, loggingClient, loggingRedCarpetMode],
  );

  const onSettingsSelect = useCallback(
    (selection: number) => {
      setSettingsSelectedIndex(selection);
      if (selection < renderSettingsAEFT.length) {
        // eslint-disable-next-line deprecation/deprecation
        loggingClient.logEvent('select_upload_composition_settings', {
          settings_type: renderSettingsAEFT[selection].value,
        });
      }
    },
    [renderSettingsAEFT, loggingClient],
  );

  const onRangeSelect = useCallback(
    (selection: number) => {
      setRangeSelectedIndex(selection);
      if (selection < videoRanges.length) {
        // eslint-disable-next-line deprecation/deprecation
        loggingClient.logEvent('select_upload_range', {
          range_type: videoRanges[selection].value,
          redCarpetMode: loggingRedCarpetMode,
        });
      }
    },
    [setRangeSelectedIndex, videoRanges, loggingClient, loggingRedCarpetMode],
  );

  return (
    <Modal isCentered open={open}>
      <Modal.Header hasBottomSpacing="title-standard">
        <Modal.Title>
          <Title>{modalTitle}</Title>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ModalContainer>
          <Row>
            <InputTextLabel htmlFor="upload-sequence-video-name" tagName="label">
              {inputTextName}
            </InputTextLabel>
            <TextField>
              <ReplayTextInput
                id="upload-sequence-video-name"
                onChange={onVideoNameChanged}
                placeholder={inputTextName}
                value={videoName}
              />
            </TextField>
          </Row>
          <Row>
            <InputTextLabel htmlFor="upload-sequence-preset" tagName="label">
              {presetLabel}
            </InputTextLabel>
            <Menu.Wrapper id="upload-sequence-preset" onSelection={onPresetSelect}>
              {({getContentProps, getTriggerProps}) => (
                <>
                  <Button {...getTriggerProps()} id="vrt-click" variant="opacity" withDropdownIcon>
                    {presetSelectedIndex < videoPresetList.length && presetSelectedIndex >= 0
                      ? truncate(
                          videoPresetList[presetSelectedIndex].name || selectOwnPresetButtonLabel,
                        )
                      : ''}
                  </Button>
                  <Menu.Content {...getContentProps()} placement="bottom-end">
                    <Menu.Segment>
                      {videoPresetList.map((value, index) => (
                        <Menu.SelectItem
                          key={index}
                          selected={presetSelectedIndex === index}
                          value={index}
                        >
                          {value.value === 'ownPresetPath' ? (
                            <Button onClick={choosePresetCallback} variant="transparent">
                              {customPresetPath || selectOwnPresetButtonLabel}
                            </Button>
                          ) : (
                            value.name
                          )}
                        </Menu.SelectItem>
                      ))}
                    </Menu.Segment>
                  </Menu.Content>
                </>
              )}
            </Menu.Wrapper>
          </Row>

          {redCarpet.mode === RedCarpetMode.AEFT && (
            <Row>
              <InputTextLabel htmlFor="upload-composition-settings" tagName="label">
                {settingsLabel}
              </InputTextLabel>
              <Menu.Wrapper id="upload-composition-settings" onSelection={onSettingsSelect}>
                {({getContentProps, getTriggerProps}) => (
                  <>
                    <Button
                      {...getTriggerProps()}
                      id="vrt-click"
                      variant="opacity"
                      withDropdownIcon
                    >
                      {settingsSelectedIndex < renderSettingsAEFT.length &&
                      settingsSelectedIndex >= 0
                        ? truncate(renderSettingsAEFT[settingsSelectedIndex].name)
                        : ''}
                    </Button>
                    <Menu.Content {...getContentProps()} placement="bottom-end">
                      <Menu.Segment>
                        {renderSettingsAEFT.map((value, index) => (
                          <Menu.SelectItem
                            key={index}
                            selected={settingsSelectedIndex === index}
                            value={index}
                          >
                            {value.name}
                          </Menu.SelectItem>
                        ))}
                      </Menu.Segment>
                    </Menu.Content>
                  </>
                )}
              </Menu.Wrapper>
            </Row>
          )}

          <Row>
            <InputTextLabel htmlFor="upload-sequence-range" tagName="label">
              {rangeLabel}
            </InputTextLabel>
            <Menu.Wrapper id="upload-sequence-range" onSelection={onRangeSelect}>
              {({getContentProps, getTriggerProps}) => (
                <>
                  <Button {...getTriggerProps()} variant="opacity" withDropdownIcon>
                    {rangeSelectedIndex < videoRanges.length && rangeSelectedIndex >= 0
                      ? videoRangesTranslateMap[
                          videoRanges[rangeSelectedIndex]
                            .value as keyof typeof videoRangesTranslateMap
                        ]
                      : ''}
                  </Button>
                  <Menu.Content {...getContentProps()} placement="bottom-end">
                    <Menu.Segment>
                      {videoRanges.map((value, index) => (
                        <Menu.SelectItem
                          key={index}
                          selected={rangeSelectedIndex === index}
                          value={index}
                        >
                          {
                            videoRangesTranslateMap[
                              value.value as keyof typeof videoRangesTranslateMap
                            ]
                          }
                        </Menu.SelectItem>
                      ))}
                    </Menu.Segment>
                  </Menu.Content>
                </>
              )}
            </Menu.Wrapper>
          </Row>
          <Row>
            <InputTextLabel htmlFor="upload-sequence-render-location" tagName="label">
              {renderLocationLabel}
            </InputTextLabel>
            <Button
              id="upload-sequence-render-location"
              onClick={chooseFolderCallback}
              variant="transparent"
            >
              {truncate(folderPath || selectFolderButton)}
            </Button>
          </Row>
          <Row>
            <Separator />
          </Row>
          {isInAdobeExtension() && (
            <CheckboxRow>
              <CheckboxWrapper>
                <Checkbox
                  aria-label={syncCommentsLabel}
                  checked={syncComments}
                  id="sync-comments-checkbox"
                  onChange={onSyncCommentsChange}
                  type="checkbox"
                />
                <LabelWrapper htmlFor="sync-comments-checkbox">{syncCommentsLabel}</LabelWrapper>
              </CheckboxWrapper>
              <CheckboxWrapper>
                <Checkbox
                  aria-label={importMarkersLabel}
                  checked={importMarkers}
                  id="import-markers-checkbox"
                  onChange={onImportMarkersChange}
                  type="checkbox"
                />
                <LabelWrapper htmlFor="import-markers-checkbox">{importMarkersLabel}</LabelWrapper>
              </CheckboxWrapper>
            </CheckboxRow>
          )}
        </ModalContainer>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={requestClose} size="standard" variant="opacity">
          {cancelButtonLabel}
        </Button>
        <Button
          disabled={!uploadButtonEnabled}
          onClick={
            isRenderInProgress
              ? noop
              : redCarpet.mode === RedCarpetMode.PPRO
              ? onUploadCallback
              : onUploadCompositionCallback
          }
          size="standard"
          variant="primary"
        >
          {isRenderInProgress ? (
            <SpinnerWrapper>
              <Spinner monochromatic size="small" />
            </SpinnerWrapper>
          ) : (
            uploadButtonLabel
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const useStrings = () => {
  const intl = useIntl();
  return {
    // Keep this label in sync with src/pages/viewer_page/components/redcarpet/import_comments_modal.tsx
    syncCommentsLabel: intl.formatMessage({
      defaultMessage: 'Auto sync comments as markers',
      id: 'kt8emA',
      description:
        'Label on checkbox for auto sync comments as markers in upload active sequence modal for redcarpet',
    }),
    importMarkersLabel: intl.formatMessage({
      defaultMessage: 'Import markers as comments',
      id: 'j+CyaL',
      description:
        'Label on checkbox for import markers as comments in upload active sequence modal for redcarpet',
    }),
    modalTitlePPRO: intl.formatMessage({
      defaultMessage: 'Upload Active Sequence',
      id: 'ETD0EP',
      description: 'Upload active sequence modal title',
    }),
    modalTitleAEFT: intl.formatMessage({
      defaultMessage: 'Upload Active Composition',
      id: '0CZ1Hb',
      description: 'Upload active composition modal title',
    }),
    inputTextName: intl.formatMessage({
      defaultMessage: 'Name',
      id: 'LfgkR3',
      description: 'Label for the name input text in upload active sequence modal',
    }),
    presetLabel: intl.formatMessage({
      defaultMessage: 'Preset',
      id: 'gtrIEP',
      description: 'Label for the preset dropdown in upload active sequence modal',
    }),
    settingsLabel: intl.formatMessage({
      defaultMessage: 'Settings',
      id: 'MZPtjL',
      description: 'Label for the settings dropdown in upload active composition modal',
    }),
    selectOwnPresetButtonLabel: intl.formatMessage({
      defaultMessage: 'Select your own preset...',
      id: 'crftrt',
      description: 'Label for the select own preset button in upload active sequence modal',
    }),
    rangeLabel: intl.formatMessage({
      defaultMessage: 'Range',
      id: '1jo1Qt',
      description: 'Label for the range dropdown in upload active sequence modal',
    }),
    renderLocationLabel: intl.formatMessage({
      defaultMessage: 'Render location',
      id: 'bENi4W',
      description: 'Label for the render location field in upload active sequence modal',
    }),
    selectFolderButton: intl.formatMessage({
      defaultMessage: 'Select Folder ...',
      id: 'BwbuL5',
      description: 'Label for the select folder button in upload active sequence modal',
    }),
    uploadButtonLabel: intl.formatMessage({
      defaultMessage: 'Upload',
      id: 'SBwT6v',
      description: 'Label for upload button in upload active sequence modal',
    }),
    cancelButtonLabel: intl.formatMessage({
      defaultMessage: 'Cancel',
      id: 'R8WxYB',
      description: 'Label for cancel button in upload active sequence modal',
    }),
    videoRangesTranslateMapPPRO: {
      ENCODE_ENTIRE: intl.formatMessage({
        defaultMessage: 'Entire Sequence',
        id: 'turqzQ',
        description:
          'Text for a range option for rendering the active sequense in Replay extension for Premiere Pro',
      }),
      ENCODE_IN_TO_OUT: intl.formatMessage({
        defaultMessage: 'In to Out',
        id: 'bl3nMh',
        description:
          'Text for a range option for rendering the active sequense in Replay extension for Premiere Pro',
      }),
      ENCODE_WORKAREA: intl.formatMessage({
        defaultMessage: 'Work Area',
        id: 'NFk9iJ',
        description:
          'Text for a range option for rendering the active sequense in Replay extension for Premiere Pro',
      }),
    },
    videoRangesTranslateMapAEFT: {
      ENCODE_ENTIRE: intl.formatMessage({
        defaultMessage: 'Entire Composition',
        id: '6IeM+q',
        description:
          'Text for a range option for rendering the active composition in Replay extension for After Effects',
      }),
      ENCODE_WORKAREA: intl.formatMessage({
        defaultMessage: 'Work Area',
        id: '2IZsio',
        description:
          'Text for a range option for rendering the active composition in Replay extension for After Effects',
      }),
    },
    noRequireErrorMessage: intl.formatMessage({
      defaultMessage: 'Error reading files, please reload the extension and try again',
      id: 'qbSlAs',
      description: 'snackbar error message when the "require" object does not exist',
    }),
    renderFailureErrorMessage: intl.formatMessage({
      defaultMessage: 'Error while rendering, please reload the extension and try again',
      id: '0xmuYq',
      description: 'snackbar error message when the rendering fails',
    }),
    readingFileErrorMessage: intl.formatMessage({
      defaultMessage: 'Error reading file, please reload the extension and try again',
      id: 'qs55AF',
      description: 'snackbar error message when the reading of a file fails',
    }),
    duplicateOutputPathErrorMessage: intl.formatMessage({
      defaultMessage:
        'Another item in the render queue has the same output location and file name. Please choose a different location, name, or remove the conflicting item from the queue and try again.',
      id: 'x4AenF',
      description:
        'snackbar error message when the output path of the active composition is also defined in the queue by another composition',
    }),
  };
};

const truncate = (text: string, maxLength = 36) => {
  if (text.length > maxLength) {
    return `...${text.substring(-maxLength + 3)}`;
  }
  return text;
};

const getPath = (fullPath: string) => {
  return fullPath.replace('file://', '');
};

function noop() {}
