import {type IntlShape} from 'react-intl';

import type {reel} from '@dropbox/api-v2-client';

import {useReelAppGlobalState} from '~/context';
import {isInAdobeExtension, isInWebKit} from '~/use_extensions';

import type {ShareFolderAccessType} from './api';
import {getCurrentUser} from './client';
import {
  AMPLIFY_HOSTNAME,
  CurrentEnvironment,
  getCurrentEnvironment,
  isMainAmplifyBranch,
} from './environment';
import type {MainBundleVersion, RuntimeBundleVersion} from './logging/logger_types';
import {getBetaGracePeriod, isReplayInGA} from './provisions';
import {StormcrowIsOn, StormcrowValue, useStormcrows} from './stormcrow';
import {
  getNewUserRegistrationFlag,
  NEW_USER_REGISTRATION_FLAG_QUERY_PARAM,
  NEW_USER_REGISTRATION_FLAG_QUERY_VALUE,
} from './url_utils';

export type PageContextType = `campaign-${string}` | 'none';

const REFERRER_QUERY_STRING_KEY = 'referrer';

// Does the user/session (for logged out sessions) have access to the app?
export const useHasAccessToReplay = () => {
  const sessionState = useReelAppGlobalState();
  const featureFlag = StormcrowValue.useReelFeatureFlag();
  // Purchasing the add-on overrides the Stormcrow
  if (
    sessionState.status === 'logged in' &&
    sessionState.provisions.provision_tier === 'full_access'
  ) {
    return true;
  }
  return Boolean(featureFlag && featureFlag !== 'OFF');
};

export const useHasFullAddonAccess = () => {
  // Checks if user has full access to the Replay Add-on
  const sessionContext = useReelAppGlobalState();
  const stormcrows = useStormcrows();
  const hasFullAddonAccess =
    sessionContext.status === 'logged in' &&
    sessionContext.provisions.provision_tier === 'full_access';
  // Give legacy users (GF_ADDON indefinitely, or GF_BETA during the grace period) full access to features
  const isGracePeriodOver = useIsBetaGracePeriodOver();
  const provisioningValue = stormcrows.reel_enable_provisioning;
  const hasLegacyAccess =
    provisioningValue === 'GF_ADDON' || (provisioningValue === 'GF_BETA' && !isGracePeriodOver);
  return hasFullAddonAccess || hasLegacyAccess;
};

/**
 * This function checks to make sure SuperAdmin features are even allowed from
 * the frontend. It checks:
 *
 *   1. the stormcrow is gated on
 *   2. the user is actually on a team
 *   3. the user is actually a team admin
 *
 * However, SuperAdmin actions are conditional on the type of object that is being selected.
 */
export const useAreSuperAdminFeaturesEnabled = () => {
  const sessionContext = useReelAppGlobalState();
  const isNormalDropboxTeamAdmin =
    sessionContext.status === 'logged in' &&
    sessionContext.currentAccount.is_team_member === true &&
    sessionContext.currentAccount.is_team_admin === true;
  return isNormalDropboxTeamAdmin;
};

export const useAreFSSGroupsSupported = () => {
  const sessionContext = useReelAppGlobalState();
  const groupSupport = StormcrowIsOn.useReplayFssGroupSupport();
  return sessionContext.status === 'logged in' && groupSupport;
};

export const useIsTeamLocked = () => {
  const sessionContext = useReelAppGlobalState();
  return sessionContext.status === 'logged in' && sessionContext.currentAccount.is_team_locked;
};

export const useIsProvisioningEnabled = () => {
  const value = StormcrowValue.useReelEnableProvisioning();
  const isBetaUser = value === 'GF_BETA';
  const isGracePeriodOver = useBetaGracePeriod().endDate < new Date();
  return value === 'ON' || (isBetaUser && isGracePeriodOver);
};

export const useIsGFAddonUser = () => {
  return StormcrowValue.useReelEnableProvisioning() === 'GF_ADDON';
};

export const useIsUserGrandfatheredFromBeta = () =>
  StormcrowValue.useReelEnableProvisioning() === 'GF_BETA';

export const useBetaGracePeriod = () =>
  getBetaGracePeriod({
    replay_ga_time: StormcrowValue.useReplayGaTime() ?? '',
    replay_beta_grace_period_end_time: StormcrowValue.useReplayBetaGracePeriodEndTime() ?? '',
  });

export const useIsBetaGracePeriodOver = () => {
  const betaPeriod = useBetaGracePeriod();
  const currentDate = new Date();
  return betaPeriod.endDate < currentDate;
};

export const useIsLiveCollabDisabled = StormcrowIsOn.useReplayDisableLiveCollab;

export const useIsReplayInGA = () => isReplayInGA(StormcrowValue.useReplayGaTime() ?? '');

export const useArchiveToDropboxEnabled = () => {
  const isFeatureOn = StormcrowIsOn.useFlowsArchiveFromReplay();
  const isReplayInGA = useIsReplayInGA();
  return isFeatureOn && isReplayInGA;
};

export const useReelProvisioningEnabled = () => useIsProvisioningEnabled() && !isInWebKit();

export const usePaginatedFolderResults = (): boolean => {
  const browsePerformanceAssignment = StormcrowValue.useReplayBrowsePerformance();
  return browsePerformanceAssignment === 'V2';
};

export const useIsPartialProvisioningSelfServeEnabled =
  StormcrowIsOn.usePartialAddonManagementSelfServe;

export const useIsMemberRequestEnabled = StormcrowIsOn.useReplayAddonMemberRequest;

export const LOCALHOST_CORS_PROXY = import.meta.env['VITE_PROXY_HOST'];

export const getPlaylistUrl = (url: string): string => {
  if (import.meta.env['STORYBOOK']) {
    return '/error.m3u8';
  }
  if (LOCALHOST_CORS_PROXY) {
    // we rely on local running proxy to bypass Preview's CORS allow-list
    return LOCALHOST_CORS_PROXY + '/proxy_master_playlist/' + btoa(url);
  }
  return url;
};

export const getCaptionsUrl = (url: string): string => {
  if (LOCALHOST_CORS_PROXY) {
    // we rely on local running proxy to bypass Preview's CORS allow-list
    return LOCALHOST_CORS_PROXY + '/proxy_srt_vtt/' + btoa(url);
  }
  return url;
};

export const getOauthRedirectUrlAndState = () => {
  const environment = getCurrentEnvironment();
  // As Dropbox OAuth urls can't include wildcards, we must bounce traffic about a bit for branch deploys...
  if (environment === CurrentEnvironment.Amplify && !isMainAmplifyBranch()) {
    const [_branch, ...hostnameParts] = window.location.hostname.split('.');
    const mainHostname = `main.${hostnameParts.join('.')}`;
    return {
      redirect_url: `https://${mainHostname}/oauth_redirect`,
      redirect_state: btoa(window.location.href),
    };
  }

  let redirectUrl = `${window.location.origin}/oauth`;
  if (getNewUserRegistrationFlag()) {
    redirectUrl = `${redirectUrl}?${NEW_USER_REGISTRATION_FLAG_QUERY_PARAM}=${NEW_USER_REGISTRATION_FLAG_QUERY_VALUE}`;
  }

  return {
    redirect_url: redirectUrl,
    redirect_state: `${btoa(window.location.href.slice(window.location.origin.length))}`,
  };
};

export const getOauthUrlFromRedirect = (state: string, code: string) => {
  const url = new URL(atob(state));
  if (isMainAmplifyBranch() || url.origin.endsWith(AMPLIFY_HOSTNAME)) {
    const next = new URL('/oauth', url);
    next.searchParams.append('code', code);
    next.searchParams.append('state', btoa(url.pathname + url.search + url.hash));
    return next.toString();
  }
  // We should never get here; it implies someone has manually crafted a URL
  // that doesn't point to a branch deploy.
  return '';
};

export const getReferrer = (): string | undefined => {
  const params = new URLSearchParams(window.location.search);
  return params.get(REFERRER_QUERY_STRING_KEY) ?? undefined;
};

export const getSelectedCommentIdFromUrl = (): string | undefined => {
  const hash = window.location.hash.slice(1);
  // Replay's location hash is a sequence of name value pairs just like the search values.
  // The URLSearchParams constructor does not need a starting ? so this creates a usable URLSearchParams object.
  const hashParams = new URLSearchParams(hash);
  return hashParams.get('comment_id') ?? hashParams.get('cid') ?? undefined;
};

export const generateRandomId = (): string => {
  const uint32 = window.crypto.getRandomValues(new Uint32Array(1))[0];
  return uint32.toString(16);
};

const EMAIL_REGEX = /^['&A-Za-z0-9\._%+-]+@([A-Za-z0-9-]+\.)+[A-Za-z]{2,15}$/i;

export const isValidEmail = (email: string | null | undefined): boolean =>
  EMAIL_REGEX.test(email ?? '');

export const getPageContext = (): PageContextType => {
  const params = new URLSearchParams(window.location.search);
  const campaignId = params.get('campaign');
  const pageContext: PageContextType = !!campaignId ? `campaign-${campaignId}` : 'none';
  return pageContext;
};

let runtimeBundleVersion: RuntimeBundleVersion | null = null;
export function setRuntimeBundleVersion(setThis: RuntimeBundleVersion) {
  runtimeBundleVersion = setThis;
}
export function getRuntimeBundleVersion(): RuntimeBundleVersion | null {
  return runtimeBundleVersion;
}

let mainBundleVersion: MainBundleVersion | null = null;
export function setMainBundleVersion(setThis: MainBundleVersion) {
  mainBundleVersion = setThis;
}
export function getMainBundleVersion(): MainBundleVersion | null {
  return mainBundleVersion;
}

export function formatBytes(bytes: number, intl: IntlShape): string {
  // https://tc39.es/ecma402/#table-sanctioned-single-unit-identifiers
  const sizes = ['byte', 'kilobyte', 'megabyte', 'gigabyte', 'terabyte', 'petabyte'];

  // Number formatter outputs decimal bytes, but we want base 2 bytes
  const base1024 = Math.floor(Math.log(Math.max(bytes, 1)) / Math.log(1024));
  const ind = Math.min(base1024, sizes.length - 1);
  const input = bytes / Math.pow(1024, ind);
  return intl.formatNumber(input, {
    style: 'unit',
    maximumFractionDigits: 2,
    unit: sizes[ind],
    unitDisplay: 'short',
  });
}

export function getFolderDepthFromPaths(paths: string[]) {
  const pathDepths = paths.map(
    (path) =>
      path.startsWith('/') ? path.substring(1).split('/').length - 1 : path.split('/').length - 1, // Subtract the file name portion of the path from the length
  );
  return Math.max(...pathDepths);
}

type CEPWindow = Window & {
  cep: {
    util: {
      openURLInDefaultBrowser(url: string): void;
    };
  };
};

const hasCEPEnvironment = (w: Window): w is CEPWindow => {
  // we are inside PPro or Aeft panel so we can use CEP (Common Extensibility Platform) API
  // https://jakkrobbit.github.io/CEP-8-Docs/CEPEngine_extensions.js.html
  return typeof (w as CEPWindow).cep?.util?.openURLInDefaultBrowser === 'function';
};

export const openURLInExternalBrowser = (url: string): boolean => {
  if (isInAdobeExtension() && hasCEPEnvironment(window)) {
    window.cep.util.openURLInDefaultBrowser(url);
    return true;
  } else {
    window.open(url, '_blank', 'noreferrer');
    return true;
  }
};

export function getVideoVersionId(
  versionSummaries: Array<reel.VersionSummary>,
  versionNumber: number,
) {
  return versionSummaries.find((summary) => summary.version_num === versionNumber)
    ?.video_version_id;
}

export const createGroupAvatarName = (groupName: string): string => {
  const words = groupName.trim().split(/\s+/);
  if (words.length === 1) {
    return words[0][0].toUpperCase(); // if only one word, return first letter
  }
  // Otherwise, return the first letters of first and last word
  return (words[0][0] + words[words.length - 1][0]).toUpperCase();
};

/**
 * Helper to determine the permissions available for current user, given the following parameters.
 * @param accessLevel the granted level of access for the asset
 * @param ownerUid [Optional] the file owner's user ID
 * @returns an object with the following properties:
 *  isOwner: whether the current user is the owner of the asset
 *  canEdit: whether the current user can edit the asset
 *  canCreateShareLinks: whether the current user can create share links for the asset
 */
export function getCurrentUserPermissions(accessLevel: ShareFolderAccessType, ownerUid?: string) {
  const currentUser = getCurrentUser();

  const ownerIdMatchesMedia =
    currentUser === undefined ? false : ownerUid === String(currentUser.id);

  const isOwner = ownerIdMatchesMedia || accessLevel === 'owner' || accessLevel === 'super_admin';

  const canEdit = (isOwner && accessLevel !== 'reviewer') || accessLevel === 'admin';

  const canCreateShareLinks = isOwner || accessLevel == 'admin';

  return {
    isOwner,
    canEdit,
    canCreateShareLinks,
  };
}
