import React, {useCallback, useEffect, useMemo} from 'react';

import {useAtom, useAtomValue} from 'jotai';
import ReactDOM from 'react-dom';
import {defineMessages, FormattedMessage, useIntl} from 'react-intl';
import styled, {css} from 'styled-components';

import type {TextColorType} from '@dropbox/dig-components/typography';
import {Text, Title} from '@dropbox/dig-components/typography';
import {UIIcon} from '@dropbox/dig-icons';
import {CheckmarkCircleFill, ChevronDownLine, CloseLine} from '@dropbox/dig-icons/assets';

import {Button, IconButton} from '~/components/button';
import {
  AVAILABLE_STEPS,
  useOnboardingChecklist,
} from '~/lib/onboarding_v2/use_onboarding_checklist';
import {useLoggingClient} from '~/lib/use_logging_client';
import type {ONBOARDING_TYPE, STEPS} from '~/state/onboarding_v2';
import {
  completedStepsAtom,
  currOpenStepAtom,
  isChecklistOpenAtom,
  onboardingTypeAtom,
  stepsToDoAtom,
} from '~/state/onboarding_v2';

const CHECKLIST_WIDTH = 290;
const CHECKLIST_TRIGGER_OFFSET = 6.5;
const TRANSITION_ANIMATION = 'all 0.5s ease-out 0s';

type ChecklistContainerProps = {
  $posX: number;
  $posY: number;
  $isOpen: boolean;
};
const ChecklistContainer = styled.div<ChecklistContainerProps>`
  background-color: var(--dig-color__background__base);
  border: 1px solid var(--dig-color__border__base);
  box-sizing: border-box;
  opacity: 0;
  padding: var(--spacing__unit--4) var(--spacing__unit--3) var(--spacing__unit--3);
  position: absolute;
  transition: all 0.1s ease-out 0s;
  width: ${CHECKLIST_WIDTH}px;
  z-index: -1;

  ${(props) => css`
    transform: translateX(${props.$posX}px)
      translateY(${props.$posY + CHECKLIST_TRIGGER_OFFSET - 15}px);

    ${props.$isOpen &&
    css`
      opacity: 1;
      transform: translateX(${props.$posX}px)
        translateY(${props.$posY + CHECKLIST_TRIGGER_OFFSET}px);
      z-index: 9999;
    `}
  `}
`;

const CloseContainer = styled.div<{$isOpen: boolean}>`
  display: flex;
  justify-content: flex-end;
  margin-left: var(--spacing__unit--1);
  margin-top: calc(-1 * var(--spacing__unit--2_5));
  width: 100%;
  visibility: ${({$isOpen}) => ($isOpen ? 'visible' : 'hidden')};
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: var(--spacing__unit--1);
  right: var(--spacing__unit--1);
`;

const ChecklistTitle = styled(Title)`
  margin-bottom: var(--spacing__unit--2);
  margin-top: 0;
`;

const StepContainer = styled.div`
  background-color: var(--dig-color__background__raised);
  border-radius: 8px;
  margin-bottom: 10px;
  overflow: hidden;
  padding: var(--spacing__unit--1);
`;

type StepCheckmarkProps = {
  $isCompleted: boolean;
};
const StepCheckmark = styled(UIIcon)<StepCheckmarkProps>`
  color: ${(props) =>
    props.$isCompleted ? 'var(--dig-color__primary__base)' : 'var(--dig-color__disabled__base)'};
  margin-left: -var(--spacing__unit--0_5);
`;

const StepContent = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 30px 1fr 30px;
`;

type StepIconContainerProps = {
  $adjustClosedIcon?: boolean;
};
const StepIconContainer = styled.div<StepIconContainerProps>`
  display: flex;
  height: 100%;
  transform: translateY(0);
  transition: ${TRANSITION_ANIMATION};

  ${(props) =>
    props.$adjustClosedIcon &&
    css`
      transition-delay: 0.5s;
      transform: translateY(var(--spacing__unit--0_5));
    `}
`;

type StepTitleProps = {
  $isOpen: boolean;
};
const StepTitle = styled(Text)<StepTitleProps>`
  transition: ${TRANSITION_ANIMATION};

  ${(props) =>
    props.$isOpen &&
    css`
      transition-delay: 0.5s;
    `}
`;

type StepDescriptionProps = {
  $isOpen: boolean;
};
const StepDescription = styled.div<StepDescriptionProps>`
  max-height: 0;
  overflow: hidden;
  transition: ${TRANSITION_ANIMATION};

  ${(props) =>
    props.$isOpen &&
    css`
      max-height: 500px;
      transition-delay: 0.5s;
    `}
`;

const StepAction = styled.div`
  margin-top: var(--spacing__unit--1);
`;

const StepsCompleted = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;

const StepsCompletedTitle = styled(Title)`
  margin-bottom: var(--spacing__unit--0_5);
  margin-top: var(--spacing__unit--2);
`;

const StepsCompletedText = styled(Text)`
  margin-bottom: var(--spacing__unit--2_5);
`;

const StepsCompletedButton = styled(Button)`
  background-color: var(--dig-color__secondary__base);
  color: var(--dig-color__secondary__on-base);
`;

type StepChevronIconProps = {
  $isOpen: boolean;
};
const StepChevronIcon = styled(UIIcon)<StepChevronIconProps>`
  transition: ${TRANSITION_ANIMATION};

  ${(props) =>
    props.$isOpen &&
    css`
      max-height: 500px;
      transition-delay: 0.5s;
    `}
`;

const intlMessages = defineMessages({
  percentComplete: {
    defaultMessage: '{percentComplete}% complete',
    id: 'HoVyqN',
    description: 'Button to open checklist that says what % complete',
  },
  getStarted: {
    defaultMessage: 'Get started',
    id: 'pExSYg',
    description: 'Title for checklist',
  },
  completedTitle: {
    defaultMessage: 'Great work',
    id: 'Te3tEL',
    description: 'Subtext title congratulating user for completing all steps',
  },
  tempCompletedTitle: {
    defaultMessage: 'Onboarding completed',
    id: 'g7Tlue',
    description: 'Temp subtext title congratulating user for completing all steps',
  },
  completedDescription: {
    defaultMessage: 'Now that you’re an expert, why not explore other Replay tips and tricks?',
    id: 's3BZeh',
    description: 'Subtext congratulating user for completing all steps',
  },
  completeExplore: {
    defaultMessage: 'Explore',
    id: '7gktCN',
    description: 'Button copy encouraging user to explore tips and tricks',
  },
});

type Props = {
  triggerRef: React.RefObject<any>;
};

const Checklist = ({triggerRef}: Props) => {
  const intl = useIntl();
  const completedSteps = useAtomValue(completedStepsAtom);
  const stepsToDo = useAtomValue(stepsToDoAtom);
  const isChecklistOpen = useAtomValue(isChecklistOpenAtom);
  const onboardingType = useAtomValue(onboardingTypeAtom);
  const [currOpenStep, setCurrOpenStep] = useAtom(currOpenStepAtom);
  const loggingClient = useLoggingClient();
  const {handleToggleChecklist, percentComplete} = useOnboardingChecklist({
    // eslint-disable-next-line deprecation/deprecation
    logEvent: loggingClient.logEvent.bind(loggingClient),
  });

  const getChecklistPosition = () => {
    let posX = 0;
    let posY = 0;
    if (triggerRef.current) {
      const {left, top, height, right} = triggerRef.current?.getBoundingClientRect();
      const tooltipLocation = right || 0;
      const availableTooltipSpace = window.innerWidth - tooltipLocation;
      if (availableTooltipSpace - CHECKLIST_WIDTH > 0) {
        posX = left || 0;
      } else {
        posX = right - CHECKLIST_WIDTH;
      }
      posY = (top || 0) + (height || 0);
    }
    return {posX, posY};
  };

  // Portal this component
  const el = useMemo(() => document.createElement('div'), []);
  useEffect(() => {
    document.body.appendChild(el);
    return () => {
      document.body.removeChild(el);
    };
  }, [el]);

  const handleOnClickCloseButton = useCallback(() => {
    handleToggleChecklist();
    // eslint-disable-next-line deprecation/deprecation
    loggingClient.logEvent('click_close_onboarding_checklist', {
      current_step: currOpenStep || 'NONE',
      num_steps_completed: completedSteps.length,
      onboarding_type: onboardingType as ONBOARDING_TYPE,
      percent_completed: percentComplete,
    });
  }, [
    completedSteps,
    currOpenStep,
    handleToggleChecklist,
    loggingClient,
    onboardingType,
    percentComplete,
  ]);

  const handleCollapseStep = useCallback(
    (stepClicked: STEPS) => {
      let nextOpenStep = stepClicked;
      if (currOpenStep === stepClicked) {
        const currIndex = stepsToDo.indexOf(stepClicked);

        // if the next step is out of the array, default to first step
        const nextIndex = currIndex === stepsToDo.length - 1 ? 0 : currIndex + 1;
        nextOpenStep = stepsToDo[nextIndex];
      }
      setCurrOpenStep(nextOpenStep);
    },
    [currOpenStep, setCurrOpenStep, stepsToDo],
  );

  const {posX, posY} = getChecklistPosition();

  return ReactDOM.createPortal(
    <ChecklistContainer $isOpen={isChecklistOpen} $posX={posX} $posY={posY} tabIndex={1}>
      <CloseContainer $isOpen={isChecklistOpen}>
        <CloseButton onClick={handleOnClickCloseButton} variant="borderless">
          <UIIcon src={CloseLine} />
        </CloseButton>
      </CloseContainer>

      {/* TEMP: Remove this false var when we have tips and tricks folder */}
      {stepsToDo.length === completedSteps.length && false ? (
        <StepsCompleted>
          <StepsCompletedTitle tabIndex={1}>
            <FormattedMessage {...intlMessages.completedTitle} />
          </StepsCompletedTitle>

          <StepsCompletedText size="large" tabIndex={1}>
            <FormattedMessage {...intlMessages.completedDescription} />
          </StepsCompletedText>

          <StepsCompletedButton tabIndex={1} variant="primary">
            <FormattedMessage {...intlMessages.completeExplore} />
          </StepsCompletedButton>
        </StepsCompleted>
      ) : (
        <>
          <ChecklistTitle tabIndex={1}>
            <FormattedMessage
              {...(stepsToDo.length === completedSteps.length
                ? intlMessages.tempCompletedTitle
                : intlMessages.getStarted)}
            />
          </ChecklistTitle>

          <div>
            {stepsToDo.map((step, i) => {
              const stepInfo = AVAILABLE_STEPS[step];
              const isCompleted = completedSteps.includes(step);
              const $isOpen = currOpenStep === step;

              let titleColor = 'disabled';
              let numberColor = 'var(--dig-color__disabled__base)';
              if ($isOpen) {
                titleColor = 'standard';
                numberColor = 'var(--dig-color__text__base)';
              } else if (isCompleted) {
                titleColor = 'faint';
                numberColor = 'var(--dig-color__text__subtle)';
              }

              return (
                <StepContainer key={`${step}--step--${i}`}>
                  {stepInfo.aboveContent && (
                    <StepDescription $isOpen={$isOpen} tabIndex={1}>
                      {stepInfo.aboveContent}
                    </StepDescription>
                  )}

                  <StepContent>
                    <StepIconContainer $adjustClosedIcon={!$isOpen || !stepInfo.description}>
                      <StepCheckmark $isCompleted={isCompleted} src={CheckmarkCircleFill} />
                    </StepIconContainer>

                    <div>
                      <StepTitle
                        $isOpen={$isOpen}
                        color={titleColor as TextColorType}
                        isBold
                        tabIndex={1}
                      >
                        <FormattedMessage {...stepInfo.title} />
                      </StepTitle>

                      {stepInfo.description && (
                        <StepDescription $isOpen={$isOpen} tabIndex={1}>
                          <Text size="small">
                            <FormattedMessage {...stepInfo.description} />
                          </Text>
                          {stepInfo.actionButton && (
                            <StepAction>{stepInfo.actionButton}</StepAction>
                          )}
                        </StepDescription>
                      )}
                    </div>

                    <StepIconContainer>
                      <IconButton
                        aria-label={intl.formatMessage(stepInfo.title)}
                        data-safe-to-unmask-name={stepInfo.dataSafeToUnmaskName}
                        onClick={() => handleCollapseStep(step)}
                        variant="borderless"
                      >
                        <StepChevronIcon
                          $isOpen={$isOpen}
                          color={numberColor}
                          src={ChevronDownLine}
                        />
                      </IconButton>
                    </StepIconContainer>
                  </StepContent>
                </StepContainer>
              );
            })}
          </div>
        </>
      )}
    </ChecklistContainer>,
    el,
  );
};

export {Checklist};
