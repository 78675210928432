import {atom} from 'jotai';
import {focusAtom} from 'jotai-optics';

import {ItemType} from '~/components/common';
import type {ModifyItemSnackbarProps} from '~/components/modify_item_snackbar';
import {getStatusFromId} from '~/components/status/status';
import {convertReelMediaTypeToMediaType} from '~/lib/api';
import {getCurrentUser} from '~/lib/client';
import {isDefined} from '~/lib/comments/components/types';
import {replayStorage} from '~/lib/storage';
import type {ModifyItemSnackbarInfo} from '~/pages/browse_page/browse_page';
import type {BrowsePageSnackbarProps} from '~/pages/browse_page/components/browse_page_snackbar';
import type {BrowseFolder, BrowseProject} from '~/pages/browse_page/components/common';
import {isUploadedFolder, isUploadedProject} from '~/pages/browse_page/components/common';
import {
  filterStateIsForAdmin,
  filterStateIsForMediaType,
  filterStateIsForStatus,
  folderShouldRenderBasedOnFilters,
  maybeReplaceSortAttributes,
  projectShouldRenderBasedOnFilters,
} from '~/pages/browse_page/filter_utils';
import {
  innerSortFolders,
  innerSortProjects,
  innerSortTeamInfoItems,
} from '~/pages/browse_page/sort_helpers';
import type {FilterStates, Sort, ViewType} from '~/pages/browse_page/types';
import {
  FILTER_NONE_ADMIN_FOLDER_TYPE,
  FILTER_NONE_MEDIA_TYPE,
  FILTER_NONE_STATUS,
} from '~/pages/browse_page/types';
import {BulkAction} from '~/state/bulk_actions';

import type {BrowseTeamInfoItem} from '../pages/admin_console_page/common';
import {BrowseSnackbarStatus} from '../pages/browse_page/components/browse_page_snackbar';

type LoadingBrowseState = {
  accessLevel: null;
  isRoot: null;
  folders: null;
  projects: null;
  teamInfoItems: null;
  snackbar: BrowsePageSnackbarProps;
  hasMorePages: null;
  hasFetchError: null;
};

type LoadedBrowseState = {
  accessLevel: string;
  isRoot: boolean;
  folders: BrowseFolder[];
  projects: BrowseProject[];
  teamInfoItems: BrowseTeamInfoItem[];
  snackbar: BrowsePageSnackbarProps;
  hasMorePages: boolean;
  hasFetchError: boolean;
};

type BrowseState = LoadingBrowseState | LoadedBrowseState;

type BrowseItem = {
  selectable: boolean;
  selected: boolean;
  actions: BulkAction[];
};

type FolderItem = BrowseItem & {
  itemType: ItemType.TEAM_PROJECT | ItemType.FOLDER;
};
type ProjectItem = BrowseItem & {
  itemType: ItemType.PROJECT;
};

type FolderItemMap = Map<string, FolderItem>;
type ProjectItemMap = Map<string, ProjectItem>;

const notFoundDefault: BrowseItem = {selectable: false, selected: false, actions: []};

const updatedSelectionList = (selections: string[], id: string, selected: boolean) => {
  const newSelections = [...selections];
  const isSelected = selections.includes(id);

  if (isSelected && !selected) {
    newSelections.splice(selections.indexOf(id), 1);
  } else if (!isSelected && selected) {
    newSelections.push(id);
  }

  return newSelections;
};

const selectionListFromRange = (
  selectedIds: string[],
  sortedIds: string[],
  previousRangeBId: string | null,
  inNewRange: boolean,
  inPreviousRange: boolean,
  newRangeEndpoints: string[],
  previousRangeEndpoints: (string | null)[],
) => {
  sortedIds.forEach((id) => {
    const isNewEndpoint = newRangeEndpoints.includes(id);
    const isPreviousEndpoint = previousRangeEndpoints.includes(id);

    if (isNewEndpoint) {
      inNewRange = !inNewRange;
    }
    if (isPreviousEndpoint) {
      inPreviousRange = !inPreviousRange;
    }

    const shouldDeselectPreviousRange = previousRangeBId && (inPreviousRange || isPreviousEndpoint);
    const shouldSelectNewRange = inNewRange || isNewEndpoint;

    const selected =
      shouldSelectNewRange || (selectedIds.includes(id) && !shouldDeselectPreviousRange);

    selectedIds = updatedSelectionList(selectedIds, id, selected);
  });

  return {
    selectedIds,
    inNewRange,
    inPreviousRange,
  };
};

export const browseLoadingState = {
  accessLevel: null,
  isRoot: null,
  folders: null,
  projects: null,
  teamInfoItems: null,
  snackbar: {status: BrowseSnackbarStatus.HIDDEN} as const,
  hasMorePages: null,
  hasFetchError: null,
};

/** Atom that holds browse state containing information about parent folder,
 * list of folders and projects within that folder, and some view information
 * such as the view type, snackbar state, and sorting setting */
export const browseAtom = atom<BrowseState>(browseLoadingState);

export const inRootAtom = focusAtom(browseAtom, (optic) => optic.prop('isRoot'));
export const foldersAtom = focusAtom(browseAtom, (optic) => optic.prop('folders'));
export const projectsAtom = focusAtom(browseAtom, (optic) => optic.prop('projects'));
export const hasMorePagesAtom = focusAtom(browseAtom, (optic) => optic.prop('hasMorePages'));
export const hasFetchErrorAtom = focusAtom(browseAtom, (optic) => optic.prop('hasFetchError'));
export const teamInfoItemsAtom = focusAtom(browseAtom, (optic) => optic.prop('teamInfoItems'));

export const browseSnackbarAtom = focusAtom(browseAtom, (optic) => optic.prop('snackbar'));
export const hideBrowseSnackbarAtom = atom(null, (_, set) => {
  set(browseSnackbarAtom, {
    status: BrowseSnackbarStatus.HIDDEN,
  });
});

const modifyItemsSnackbarPropsAtom = atom<ModifyItemSnackbarProps>({type: 'hidden'});
export const hideModifyItemsSnackbarAtom = atom(null, (_, set) => {
  set(modifyItemsSnackbarPropsAtom, {
    type: 'hidden',
  });
});

export const modifyItemsSnackbarAtom = atom(
  (get) => get(modifyItemsSnackbarPropsAtom),
  (_get, set, modifyItemSnackbarInfo: ModifyItemSnackbarInfo) => {
    set(modifyItemsSnackbarPropsAtom, {
      ...modifyItemSnackbarInfo,
      onRequestClose: () => {
        set(hideModifyItemsSnackbarAtom);
      },
    });
  },
);

export const isTileDropAtom = atom(false);

/** Atom that holds filter state */
const browseFiltersPrimitiveAtom = atom({
  mediaTypeFilterState: FILTER_NONE_MEDIA_TYPE,
  statusFilterState: FILTER_NONE_STATUS,
  adminTypeFilterState: FILTER_NONE_ADMIN_FOLDER_TYPE,
});

export const browseFiltersAtom = atom(
  (get) => get(browseFiltersPrimitiveAtom),
  (get, set, filters: FilterStates) => {
    const prevFilters = get(browseFiltersAtom);

    set(deselectAllItemsAtom);

    if (filterStateIsForMediaType(filters)) {
      set(browseFiltersPrimitiveAtom, {
        ...prevFilters,
        mediaTypeFilterState: filters,
      });
    } else if (filterStateIsForStatus(filters)) {
      set(browseFiltersPrimitiveAtom, {
        ...prevFilters,
        statusFilterState: filters,
      });
    } else if (filterStateIsForAdmin(filters)) {
      set(browseFiltersPrimitiveAtom, {
        ...prevFilters,
        adminTypeFilterState: filters,
      });
    }
  },
);

/** Atom that sets the Replay storage value when updating sort */
const browseSortPrimitiveAtom = atom(replayStorage.getBrowseSort());
export const browseSortAtom = atom(
  (get) => {
    const {direction: currentSortDirection, attribute: currentSortAttribute} =
      get(browseSortPrimitiveAtom);
    const viewType = get(browseViewTypeAtom);
    const sortAttributesToUse = maybeReplaceSortAttributes(
      currentSortAttribute,
      currentSortDirection,
      viewType,
    );
    const storedValue = get(browseSortPrimitiveAtom);
    return sortAttributesToUse || storedValue;
  },
  (get, set, sort: Sort) => {
    set(browseSortPrimitiveAtom, sort);
    replayStorage.setBrowseSort(sort);
  },
);

/** Atom that sets the Replay storage value when updating view type */
const browseViewTypePrimitiveAtom = atom(replayStorage.getBrowseViewType());
export const browseViewTypeAtom = atom(
  (get) => get(browseViewTypePrimitiveAtom),
  (get, set, viewType: ViewType) => {
    set(browseViewTypePrimitiveAtom, viewType);
    replayStorage.setBrowseViewType(viewType);
  },
);

const previousRangeAIdAtom = atom<string | null>(null);
const previousRangeBIdAtom = atom<string | null>(null);

/** Atom that holds array of selected folder ids */
export const selectedFolderIdsAtom = atom<string[]>([]);
/** Atom that holds array of selected project ids */
export const selectedProjectIdsAtom = atom<string[]>([]);

/** Atom that holds selection state for folder, if not found (during deletion, etc) then force it as unselectable */
export const folderSelectionStateAtom = atom(
  (get) => (folderId: string) => get(folderItemMapAtom).get(folderId) || notFoundDefault,
  (get, set, {folderId, selected}: {folderId: string; selected: boolean}) => {
    const selectedIds = get(selectedFolderIdsAtom);
    const newSelectedIds = updatedSelectionList(selectedIds, folderId, selected);

    set(selectedFolderIdsAtom, newSelectedIds);
    if (selected) {
      set(previousRangeAIdAtom, folderId);
      set(previousRangeBIdAtom, null);
    }
  },
);

/** Atom that holds selection state for project, if not found (during deletion, etc) then force it as unselectable */
export const projectSelectionStateAtom = atom(
  (get) => (projectId: string) => get(projectItemMapAtom).get(projectId) || notFoundDefault,
  (get, set, {projectId, selected}: {projectId: string; selected: boolean}) => {
    const selectedIds = get(selectedProjectIdsAtom);
    const newSelectedIds = updatedSelectionList(selectedIds, projectId, selected);

    set(selectedProjectIdsAtom, newSelectedIds);
    if (selected) {
      set(previousRangeAIdAtom, projectId);
      set(previousRangeBIdAtom, null);
    }
  },
);

/** Read-only atom that holds sorted folders for the current folder */
export const sortedFoldersAtom = atom((get) => {
  const {folders} = get(browseAtom);
  const sort = get(browseSortAtom);

  return folders === null ? null : innerSortFolders(folders, sort.direction, sort.attribute);
});

/** Read-only atom that holds sorted projects for the current folder */
export const sortedProjectsAtom = atom((get) => {
  const {projects} = get(browseAtom);
  const sort = get(browseSortAtom);

  return projects === null ? null : innerSortProjects(projects, sort.direction, sort.attribute);
});

export const sortedTeamInfoItemsAtom = atom((get) => {
  const {teamInfoItems} = get(browseAtom);
  const sort = get(browseSortAtom);

  return teamInfoItems === null
    ? null
    : innerSortTeamInfoItems(teamInfoItems, sort.direction, sort.attribute);
});

/** Read-only atom that holds map of folder ids to selectable state */
export const folderItemMapAtom = atom((get) => {
  const {accessLevel, isRoot, folders} = get(browseAtom);
  const selectedFolderIds = get(selectedFolderIdsAtom);
  const folderItemMap: FolderItemMap = new Map();
  const filters = get(browseFiltersAtom);

  folders?.filter(isUploadedFolder)?.forEach((folder) => {
    const folderAccessLevel = folder.access_level ? folder.access_level!['.tag'] : 'none';
    const isOwner =
      folderAccessLevel === 'owner' ||
      folderAccessLevel === 'none' ||
      folderAccessLevel === 'super_admin';
    const isReviewer = accessLevel === 'reviewer';

    // All root folders are team projects
    const itemType = isRoot ? ItemType.TEAM_PROJECT : ItemType.FOLDER;

    const isVisibleBasedOnFilters = folderShouldRenderBasedOnFilters(filters);

    // Build up list of possible actions for folder
    const actions: BulkAction[] = [];

    if (!isRoot && !isReviewer) {
      actions.push(BulkAction.COPY_TO_PROJECT, BulkAction.MOVE_TO_FOLDER);
    }
    if ((!isRoot && !isReviewer) || (isRoot && isOwner)) {
      actions.push(BulkAction.DELETE);
    }
    if (isRoot && !isOwner) {
      actions.push(BulkAction.LEAVE_TEAM_PROJECT);
    }
    if (isRoot && (isOwner || folderAccessLevel === 'reviewer' || folderAccessLevel === 'admin')) {
      actions.push(BulkAction.FOLDER_DOWNLOAD);
    }

    // Use list of selected ids to determine if folder is selected
    const selected = selectedFolderIds.includes(folder.id);
    const selectable = actions.length > 0 && isVisibleBasedOnFilters;

    folderItemMap.set(folder.id, {
      itemType,
      selectable,
      selected,
      actions,
    });
  });

  return folderItemMap;
});

/** Read-only atom that holds map of project ids to selectable state */
export const projectItemMapAtom = atom((get) => {
  const {accessLevel, isRoot} = get(browseAtom);
  const projects = get(projectsAtom);
  const selectedProjectIds = get(selectedProjectIdsAtom);
  const projectItemMap: ProjectItemMap = new Map();
  const filters = get(browseFiltersAtom);

  if (!projects) {
    return projectItemMap;
  }

  projects.filter(isUploadedProject).forEach(({project, videos}) => {
    const currentVideo = videos?.[0];

    if (!project || !currentVideo) {
      return;
    }

    const currentUser = getCurrentUser();
    const isOwner =
      currentUser === undefined ? false : currentVideo.owner_uid === String(currentUser.id);
    const isEditor =
      isOwner || accessLevel == 'admin' || accessLevel == 'owner' || accessLevel == 'super_admin';
    const isReviewer = accessLevel === 'reviewer';
    const itemsSelectedCount = get(itemsSelectedCountAtom);
    const hasMultipleVideoVersions =
      currentVideo?.version_summaries &&
      currentVideo.version_summaries.length > 1 &&
      currentVideo.version_summaries?.every((video) => video.media_type?.['.tag'] === 'video');

    const mediaType = currentVideo.media_type
      ? convertReelMediaTypeToMediaType(currentVideo.media_type)
      : undefined;
    const isVisibleBasedOnFilters = projectShouldRenderBasedOnFilters(
      getStatusFromId(currentVideo.status || 0),
      filters,
      mediaType,
    );

    // Build up list of possible actions for project
    const actions: BulkAction[] = [BulkAction.UPDATE_VERSION_STATUS];

    if (isRoot && isEditor) {
      actions.push(BulkAction.MOVE_TO_PROJECT);
    }
    if (!isRoot && isEditor) {
      actions.push(BulkAction.COPY_TO_PROJECT, BulkAction.MOVE_TO_FOLDER);
    }

    // Temporary fix for archving auth issue
    // TODO: Allow archiving of files if user is admin
    if (isOwner) {
      actions.push(BulkAction.ARCHIVE_TO_DROPBOX);
    }

    if (isEditor && !isReviewer) {
      actions.push(BulkAction.DELETE);
    }
    if (!isEditor && !isReviewer) {
      actions.push(BulkAction.HIDE);
    }
    if (itemsSelectedCount === 1 && hasMultipleVideoVersions) {
      actions.push(BulkAction.COMPARE_VERSIONS);
    }
    if (isOwner || isEditor || isReviewer) {
      actions.push(BulkAction.ASSET_DOWNLOAD);
    }

    // Use list of selected ids to determine if project is selected
    const selected = selectedProjectIds.includes(project.id);
    const selectable = actions.length > 0 && isVisibleBasedOnFilters;

    projectItemMap.set(project.id, {
      itemType: ItemType.PROJECT,
      selectable,
      selected,
      actions,
    });
  });

  return projectItemMap;
});

/** Read-only atom that holds only selectable IDs for folders and projects */
const selectableItemIdsAtom = atom((get) => {
  const folderItemMap = get(folderItemMapAtom);
  const projectItemMap = get(projectItemMapAtom);
  const folderIds = [...folderItemMap].filter(([, {selectable}]) => selectable).map(([id]) => id);
  const projectIds = [...projectItemMap].filter(([, {selectable}]) => selectable).map(([id]) => id);

  return {folderIds, projectIds};
});

/** Read-only atom to get all the version ids for selected projects */
export const selectedVersionIdsAtom = atom((get) => {
  const projectIds = get(selectedProjectIdsAtom);
  const allProjects = get(projectsAtom);

  if (projectIds.length === 0 || !allProjects) {
    return [];
  }

  const selectedProjects = projectIds
    .map((projectId) =>
      allProjects.filter(isUploadedProject).find(({project}) => project?.id === projectId),
    )
    .filter(isDefined);

  const versionIds = selectedProjects
    .map((selectedProject) => selectedProject.videos?.[0].id)
    .filter(isDefined);

  return versionIds;
});

export const selectedVideoIdAtom = atom((get) => {
  let videoId: string | undefined;
  const projectIds = get(selectedProjectIdsAtom);
  const allProjects = get(projectsAtom);

  if (projectIds.length === 0 || !allProjects) {
    return videoId;
  }

  const singularProjectId = projectIds[0];

  const proj = allProjects
    .filter(isUploadedProject)
    .find(({project}) => project?.id == singularProjectId);

  if (proj && proj.videos) {
    const videos = proj.videos;
    const video = videos[0];
    videoId = video.video_id;
  }
  return videoId;
});

export const selectedAllVideoVersionsIdsAtom = atom((get) => {
  const projectIds = get(selectedProjectIdsAtom);
  const allProjects = get(projectsAtom);

  if (projectIds.length === 0 || !allProjects) {
    return [];
  }

  const selectedProjects = projectIds
    .map((projectId) =>
      allProjects.filter(isUploadedProject).find(({project}) => project?.id === projectId),
    )
    .filter(isDefined);

  // These are going to be sorted by newest version to oldest
  const allVersions = selectedProjects
    .flatMap((selectedProject) =>
      selectedProject.videos?.[0].version_summaries
        ?.sort((summary1, summary2) => summary2.version_num - summary1.version_num)
        ?.map((video) => video.video_version_id),
    )
    .filter(isDefined);

  return allVersions;
});

/** Read-only atom that holds the number of items that are selected */
export const itemsSelectedCountAtom = atom((get) => {
  const selectedFolderIds = get(selectedFolderIdsAtom);
  const selectedProjectIds = get(selectedProjectIdsAtom);

  return selectedFolderIds.length + selectedProjectIds.length;
});

/** Read-only atom that holds whether or not 0 items are selected */
export const noItemsSelectedAtom = atom((get) => get(itemsSelectedCountAtom) === 0);

/** Read-only atom that holds whether or not all selectable items are selected */
export const allItemsSelectedAtom = atom((get) => {
  const {folderIds, projectIds} = get(selectableItemIdsAtom);

  const selectableItemsCount = folderIds.length + projectIds.length;

  return get(itemsSelectedCountAtom) === selectableItemsCount;
});

/** Write-only atom that sets a range selection based on last selected item and current */
export const selectRangeAtom = atom(null, (get, set, rangeBId: string) => {
  const previousRangeAId = get(previousRangeAIdAtom);
  const previousRangeBId = get(previousRangeBIdAtom);
  const selectableItemIds = get(selectableItemIdsAtom);

  const sortedFolders = get(sortedFoldersAtom) || [];
  const sortedProjects = get(sortedProjectsAtom) || [];
  const sortedFolderIds = sortedFolders
    .filter(isUploadedFolder)
    .map((folder) => folder.id)
    .filter((folderId) => selectableItemIds.folderIds.includes(folderId));
  const sortedProjectIds = sortedProjects
    .filter(isUploadedProject)
    .map(({project}) => project)
    .filter(isDefined)
    .map(({id}) => id)
    .filter((projectId) => selectableItemIds.projectIds.includes(projectId));

  // Default last selected item to the first item in list (to match Dropbox.com)
  const rangeAId = previousRangeAId || [...sortedFolderIds, ...sortedProjectIds][0];

  // Loop through all IDs and select the range from rangeAId <-> rangeBId,
  // while deselecting any IDs that might have been in previous range
  const newRangeEndpoints = [rangeAId, rangeBId];
  const previousRangeEndpoints = [rangeAId, previousRangeBId];

  const selectedFolderIds = get(selectedFolderIdsAtom);
  const selectedProjectsIds = get(selectedProjectIdsAtom);
  let inNewRange = false;
  let inPreviousRange = false;

  const {
    selectedIds: newSelectedFolderIds,
    inNewRange: nowInNewRange,
    inPreviousRange: nowInPreviousRange,
  } = selectionListFromRange(
    selectedFolderIds,
    sortedFolderIds,
    previousRangeBId,
    inNewRange,
    inPreviousRange,
    newRangeEndpoints,
    previousRangeEndpoints,
  );

  inNewRange = nowInNewRange;
  inPreviousRange = nowInPreviousRange;

  const {selectedIds: newSelectedProjectIds} = selectionListFromRange(
    selectedProjectsIds,
    sortedProjectIds,
    previousRangeBId,
    inNewRange,
    inPreviousRange,
    newRangeEndpoints,
    previousRangeEndpoints,
  );

  set(selectedFolderIdsAtom, newSelectedFolderIds);
  set(selectedProjectIdsAtom, newSelectedProjectIds);

  // Update the new range B id to the current end of range
  set(previousRangeBIdAtom, rangeBId);
});

/** Write-only atom to deselect all items */
export const deselectAllItemsAtom = atom(null, (get, set) => {
  set(selectedFolderIdsAtom, []);
  set(selectedProjectIdsAtom, []);
});

/** Write-only atom to deselect all items */
export const selectAllItemsAtom = atom(null, (get, set) => {
  const {folderIds, projectIds} = get(selectableItemIdsAtom);

  set(selectedFolderIdsAtom, folderIds);
  set(selectedProjectIdsAtom, projectIds);
});

/** Write-only atom to select or deselect all items based on current selection */
export const toggleSelectAllItemAtom = atom(null, (get, set) => {
  const noItemsSelected = get(noItemsSelectedAtom);

  set(noItemsSelected ? selectAllItemsAtom : deselectAllItemsAtom);
});
