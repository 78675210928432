import type {
  AdminTypeFilterState,
  FilterState,
  FilterStates,
  MediaTypeFilterState,
  SortAttr,
  SortDirection,
  StatusFilterState,
  ViewType,
} from './types';
import {
  ALL_MEDIA_TYPE_FILTER_KEYS,
  FILTER_NONE_ADMIN_FOLDER_TYPE,
  FILTER_NONE_STATUS,
} from './types';
import type {VersionStatus} from '../../components/status/status';
import type {MediaType} from '../../lib/api';
import {BrowseTeamInfoType} from '../admin_console_page/common';

export const filterStateIsForMediaType = (
  filterState: FilterStates,
): filterState is MediaTypeFilterState => {
  return filterState.hasOwnProperty('includeProjects');
};

export const filterStateIsForStatus = (
  filterState: FilterStates,
): filterState is StatusFilterState => {
  return filterState.hasOwnProperty('includeNoStatus');
};

export const filterStateIsForAdmin = (
  filterState: FilterStates,
): filterState is AdminTypeFilterState => {
  return filterState.hasOwnProperty('includeTeamFolders');
};

export const projectShouldRenderBasedOnFilters = (
  status: VersionStatus,
  filterState: FilterState,
  mediaType?: MediaType,
): boolean => {
  const activeFilters = getActiveFilters(filterState);

  // If no filters are applied, we know the project can be rendered.
  if (activeFilters.length == 0) return true;

  // Otherwise, determine if the project should be rendered.
  const statusFilterApplied = filterListContainsStatusFilter(activeFilters);
  const mediaTypeFilterApplied = filterListContainsMediaTypeFilter(activeFilters);
  // If both filter types are active, we need to check both.
  if (statusFilterApplied && mediaTypeFilterApplied) {
    return (
      doesProjectPassStatusFilter(activeFilters, status) &&
      doesProjectPassMediaTypeFilter(activeFilters, mediaType)
    );
  }
  // Otherwise, we only care if one passes (at this point, we only have either status or media type filters selected, not both.)
  else {
    return (
      (statusFilterApplied && doesProjectPassStatusFilter(activeFilters, status)) ||
      (mediaTypeFilterApplied && doesProjectPassMediaTypeFilter(activeFilters, mediaType))
    );
  }
};

export const folderShouldRenderBasedOnFilters = (filterState: FilterState) => {
  const activeFilters = getActiveFilters(filterState);
  // If no media type filters are active, render the folder/project
  if (!filterListContainsMediaTypeFilter(activeFilters)) return true;
  // Otherwise, only show the folder/project if includeProjects is active.
  if (activeFilters.includes('includeProjects')) {
    return true;
  }
  return false;
};

export const adminFolderShouldRenderBasedOnFilters = (
  filterState: FilterState,
  itemType: BrowseTeamInfoType,
) => {
  const activeFilters = getActiveFilters(filterState);
  const adminFilterApplied = filterListContainsAdminFilter(activeFilters);
  if (!adminFilterApplied) {
    // If there is no admin filter applied, then we want to just default to
    // selecting everything
    return true;
  }

  return doesAdminFolderPassFilter(activeFilters, itemType);
};

const getActiveFilters = (filterState: FilterState): string[] => {
  const activeStatusFilters = Object.keys(filterState.statusFilterState).filter(
    (key) => !!filterState.statusFilterState[key as keyof StatusFilterState],
  );
  const activeMediaTypeFilters = Object.keys(filterState.mediaTypeFilterState).filter(
    (key) => !!filterState.mediaTypeFilterState[key as keyof MediaTypeFilterState],
  );
  const activeAdminFolderTypeFilters = Object.keys(filterState.adminTypeFilterState).filter(
    (key) => !!filterState.adminTypeFilterState[key as keyof AdminTypeFilterState],
  );
  return activeStatusFilters.concat(activeMediaTypeFilters, activeAdminFolderTypeFilters);
};

const doesProjectPassMediaTypeFilter = (
  activeFilters: string[],
  mediaType?: MediaType,
): boolean => {
  switch (mediaType) {
    case 'video':
      if (activeFilters.includes('includeVideo')) {
        return true;
      }
      break;
    case 'audio':
      if (activeFilters.includes('includeAudio')) {
        return true;
      }
      break;
    case 'image':
      if (activeFilters.includes('includeImages')) {
        return true;
      }
      break;
    case 'creative_doc':
      if (activeFilters.includes('includePsd')) {
        return true;
      }
      break;
    case 'document':
      if (activeFilters.includes('includePdf')) {
        return true;
      }
      break;
  }
  return false;
};

const doesProjectPassStatusFilter = (activeFilters: string[], status: VersionStatus): boolean => {
  switch (status) {
    case 'NO_STATUS':
      if (activeFilters.includes('includeNoStatus')) {
        return true;
      }
      break;
    case 'NEEDS_REVIEW':
      if (activeFilters.includes('includeNeedsReview')) {
        return true;
      }
      break;
    case 'IN_PROGRESS':
      if (activeFilters.includes('includeInProgress')) {
        return true;
      }
      break;
    case 'EDITS_REQUESTED':
      if (activeFilters.includes('includeEditsRequested')) {
        return true;
      }
      break;
    case 'APPROVED':
      if (activeFilters.includes('includeApproved')) {
        return true;
      }
      break;
    default:
      return false;
  }
  return false;
};

const doesAdminFolderPassFilter = (
  activeFilters: string[],
  teamInfoType: BrowseTeamInfoType,
): boolean => {
  switch (teamInfoType) {
    case BrowseTeamInfoType.TeamProject:
      if (activeFilters.includes('includeTeamFolders')) {
        return true;
      }
      break;
    case BrowseTeamInfoType.User:
      if (activeFilters.includes('includeUserRootProjects')) {
        return true;
      }
      break;
    default:
      return false;
  }
  return false;
};

const filterListContainsMediaTypeFilter = (filterList: string[]): boolean => {
  return filterList.some((filter) => ALL_MEDIA_TYPE_FILTER_KEYS.includes(filter));
};

const filterListContainsStatusFilter = (filterList: string[]): boolean => {
  for (const filter of filterList) {
    if (FILTER_NONE_STATUS.hasOwnProperty(filter)) return true;
  }
  return false;
};

const filterListContainsAdminFilter = (filterList: string[]): boolean => {
  for (const filter of filterList) {
    if (FILTER_NONE_ADMIN_FOLDER_TYPE.hasOwnProperty(filter)) return true;
  }
  return false;
};

export const maybeReplaceSortAttributes = (
  currentSortAttribute: SortAttr,
  currentSortDirection: SortDirection,
  viewType: ViewType,
): {attribute: SortAttr; direction: SortDirection} | undefined => {
  // Name (asc or desc) is supported in both views
  if (currentSortAttribute === 'name') return undefined;

  if (['created', 'updated'].includes(currentSortAttribute)) {
    if (viewType === 'list') return undefined;
    // Created, updated, viewed are only supported in grid view if descending
    if (currentSortDirection == 'descending') return undefined;
    return {attribute: currentSortAttribute, direction: 'descending'};
  }

  // Otherwise, default to name (a to z)
  return {attribute: 'name', direction: 'ascending'};
};
